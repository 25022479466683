<template>
    <div >

        <div class="availability_rows" >
            <div class="availability_row">
                <div class="availability_sidebar" :class="showPropertyItem ? 'availability_sidebar--scroller-full' : 'availability_sidebar--scroller-resize'" ></div>
                <div
                    class="main_calendar__holder--content-scroll"
                    ref="calendar_scroll_container"
                    @scroll="onScroll">
                    <div
                        v-for="date in dates">
                    </div>
                </div>
            </div>
        </div>
<!--        <div class="availability_calendar__container&#45;&#45;load_more" >
            <button v-if="showLoadMore" class="animated fadeIn">
                <i class="fa fa-forward" aria-hidden="true" @click="loadMoreEvent"></i>
            </button>
        </div>-->
    </div>


</template>

<script>
    import {debounce} from 'lodash'
    import {EventBus, GE_CALENDAR_LOAD_ADD_MONTH, GE_CALENDAR_LOAD_AVAILABILITY} from "@/shared/EventBus";

    export default {
        name: "PropertyCalendarScroller",
        props: {
            dates: {
                type: Array
            },
            showPropertyItem:{
                type:Boolean,
                default:true
            }
        },
        data(){
            return{
                showLoadMore:false,
            }
        },
        methods: {
            onScroll($event) {
                this.showMoreHandler()
                debounce(function() {
                    this.$emit('scroll', $event.target.scrollLeft)
                }, 90).call(this);

                // debounce(function() => {
                //     this.$emit('scroll', $event.target.scrollLeft)
                // }, 70).call(this);
            },
            loadMoreEvent() {
                EventBus.$emit(GE_CALENDAR_LOAD_ADD_MONTH, {unitId: null})
                EventBus.$emit(GE_CALENDAR_LOAD_AVAILABILITY)

            },
            showMoreHandler() {
                if (this.$refs.calendar_scroll_container) {
                    if (this.$refs.calendar_scroll_container.scrollWidth === this.$refs.calendar_scroll_container.clientWidth) {
                        return this.showLoadMore = true
                    }

                    if (this.$refs.calendar_scroll_container.scrollLeft === (this.$refs.calendar_scroll_container.scrollWidth - this.$refs.calendar_scroll_container.clientWidth)) {
                        return this.showLoadMore = true
                    }
                }

                return this.showLoadMore = false
            },
        },
        mounted(){
            this.showMoreHandler()
        }
    }
</script>

<style scoped>
    .no-border {
        border-bottom: none!important
    }

</style>
