<template>
    <b-card>
        <div class="d-flex justify-content-start align-items-center" v-if="loading">
            <b-spinner variant="primary"></b-spinner>
        </div>
        <div class="d-flex justify-content-between" v-else>
            <div class="d-flex flex-column justify-content-between">
                <div class="header-2">{{ $t('PAID_RESERVATIONS_WITH_NO_INVOICE') }}</div>
                <b-link :to="openLink()"
                        target= '_blank'
                        class="header-1">{{ total_reservation }}
                </b-link>
            </div>
        </div>
    </b-card>
</template>

<script>


import {getReservationCount} from "@/services/dashboard";
import {
    STATUS_CONFIRMED,
    RES_STATUS_CANCELED,
    RES_STATUS_PAID
} from "@/shared/constants";
import routes from '@/router/routeNames'
import moment from 'moment'

export default {
    name: "DashboardReservationsNoInvoice",
    data() {
        return {
            RES_STATUS_CANCELED,
            STATUS_CONFIRMED,
            routes: routes,
            RES_STATUS_PAID,
            loading: false,
            total_reservation: 0,
            canceled_reservation: 0
        }
    },
    props: {
        company: {
            type: Number,
        },
    },
    methods: {
        openLink(){
            return {
                name: routes.RN_RESERVATION_ACCOMMODATION_LIST,
                query:{
                payment_status: RES_STATUS_PAID,
                    has_invoice_owner: 0,
                    has_invoice_guest: 0,
                    date:'check_out',
                    from:this.defaultStart,
                    to:this.defaultEnd,
                    status: [RES_STATUS_CANCELED,STATUS_CONFIRMED]
            }
            }
        },
        getData() {

            this.loading = true
            let req = {
                company: this.company,
                payment_status: RES_STATUS_PAID,
                has_invoice_owner: 0,
                has_invoice_guest: 0,
                check_out_from_date: this.defaultStart,
                check_out_to_date: this.defaultEnd,
                status: [RES_STATUS_CANCELED, STATUS_CONFIRMED]
            }

            getReservationCount(req).then(response => {
                this.total_reservation = response.data.total
                this.loading = false
            })
        }
    },
    computed: {
        defaultStart() {
            return moment().startOf('year').format('YYYY-MM-DD')
        },
        defaultEnd() {
            return moment().endOf('year').format('YYYY-MM-DD')
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>

</style>
