<template>
    <div>
        <div class="availability_calendar mb-5" id="calendar_warapper">
            <div class="sticky_date">
                <b-form class="filter pb-0" :class="totalRows  > 10 ? 'mb-0' : 'mb-4'" @submit.prevent="search">
                    <div class="d-flex justify-content-between ">

                        <b-row class="d-flex justify-content-start" style="width: 65rem">
                            <template v-if="cities_count > 1">
                                <b-col lg="2" class="mb-3" v-if="preloadCityList">
                                    <label>{{ $t("CITY") }}</label>
                                    <app-select mode="multiselect" :options="cityList" v-model="city"></app-select>
                                </b-col>
                                <b-col v-else lg="2" class="mb-3">
                                    <label>{{ $t("CITY") }}</label>
                                    <app-select mode="search" :search="searchCity" v-model="city"
                                                :search-clear="true"></app-select>
                                </b-col>
                            </template>
                            <b-col lg="2" class="mb-3" v-if="property_count > 1">
                                <label>{{ $t('PROPERTY') }}</label>

                                <app-select
                                    v-if="preloadPropertyList || (propertyList.length > 0 && propertyList.length <= DEFAULT_PAGINATION_LIMIT)"
                                    mode="multiselect" :options="propertyList" set-first
                                    v-model="property"></app-select>
                                <app-select v-else mode="search" :search="searchProperty"
                                            v-model="property"></app-select>

                            </b-col>
                            <b-col md="6" lg="2" class="w-30" v-if="destinationTagOptions.length > 0">
                                <label>{{ $t('DESTINATION_TAG') }}</label>
                                <app-select value-field="id" text-field="label" v-model="destination_tags"
                                            :options="destinationTagOptions" mode="multiselect"></app-select>
                            </b-col>
                            <b-col v-if="customTagOptions.length > 0" md="6" lg="2" class="w-30">
                                <label>{{ $t('CUSTOM_TAG') }}</label>
                                <app-select value-field="id" text-field="label" v-model="custom_tags"
                                            :options="customTagOptions" mode="multiselect"></app-select>
                            </b-col>

                            <app-access-control md="6" lg="2" class="w-30" @AccessControlEvent="addToAccessControlCounter()"
                                                :access_control_context="{function:MAIN_CALENDAR_FILTER_GROUP, key: AC_MAIN_CALENDAR}">
                                <label>{{ $t("GROUP") }}</label>
                                <app-select mode="search" text-field="title" value-field="id" :search="searchFilter"
                                            v-model="group">

                                </app-select>
                            </app-access-control>


                            <b-col sm="12" lg="3" class="mb-4"
                                   v-if="cities_count > 1 || property_count > 1 || destination_tags.length > 0 || custom_tags.length > 0">
                                <app-button-submit class="filter-submit-wrapper" :inline="true" :loading="loading"
                                                   variant="primary"
                                                   button_type="search">{{ $t("SEARCH") }}
                                </app-button-submit>
                                <app-button-reset :loading="loading_reset" @click="resetForm"
                                                  class="filter-submit-wrapper ml-3" :inline="true"></app-button-reset>
                            </b-col>
                        </b-row>

                        <div class="d-flex justify-content-end">
                            <b-row>

                                <b-col cols="12" class="d-flex justify-content-end">
                                    <b-form-group class="mr-3 display-settings">
                                        <label>{{ $t('DISPLAY') }}</label>
                                        <app-select :search-empty-item="false" v-model="display" value-field="value"
                                                    text-field="text" set-first :options="display_options"></app-select>
                                    </b-form-group>
                                    <i class="fa fa-chevron-left availability_calendar--load-more "
                                       @click="loadMoreBack"
                                       aria-hidden="true"></i>
                                    <b-form-group class="mr-2 ml-2">
                                        <label>{{ $t('MONTH') }}</label>
                                        <app-month-picker :value="selectMonth"
                                                          @input="updateSelectMonth"></app-month-picker>
                                    </b-form-group>

                                    <i class="fa fa-chevron-right availability_calendar--load-more mr-3"
                                       aria-hidden="true"
                                       @click="loadMore"></i>
                                    <b-form-group class="width-period">
                                        <label>{{ $t('PERIOD') }}</label>
                                        <app-date-range-picker :max-date-diff="90" :value="dateRange"
                                                               @input="updateRange"></app-date-range-picker>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-form>
                <div v-if="propertyAvailabilityData.length > 0 && totalRows > 10"
                     class="d-flex mt-1  mb-3  align-items-end justify-content-end">
                    <div class="d-flex align-items-end">


                        <div class="d-flex align-items-center">
                            <span class="ml-3 mr-2">{{ $t("SHOWING") }}</span>
                            <app-select v-model="perPage" :search-empty-item="false"
                                        :options="computedLimitList"></app-select>
                            <span class="ml-2" v-html='$t("_RESULTS", {number: totalRows})'></span>
                        </div>
                    </div>
                </div>
                <property-calendar-dates :selected-units="selected_unit" v-if="propertyAvailabilityData.length > 0"
                                         @updateScrollLeft="updateScroll" :load-more="load_more"
                                         @showLoadMore="showLoadMore=$event" @scroll="handleHorizontalScroll"
                                         :showPropertyItem="showPropertyItem" :scrollLeftPosition="scrollLeftPosition"
                                         :showLoading="showLoading"

                                         :dates="dateArray"></property-calendar-dates>
                <app-no-data v-if="propertyAvailabilityData.length === 0 && loaded" :show-tip="false">

                    <app-button v-if="empty_initial_list" button_type="new" @click="property_aside = true">
                        {{ $t('NEW_PROPERTY') }}
                    </app-button>
                </app-no-data>
            </div>

            <property-calendar-wrapper v-if="propertyAvailabilityData.length > 0" :showPropertyItem="showPropertyItem"
                                       :dateRange="dateRange"
                                       :shiftKeyValue="shiftKeyVal" :scrollLeftPosition="scrollLeftPosition"
                                       :date-array="dateArray"
                                       :key="propertyAvailabilityObject.id+'_'+randomInt+'_'+key"
                                       :property="propertyAvailabilityObject"
                                       :selected-units="selected_unit"
                                       :display="display"
                                       :showLoading="showLoading"
                                       v-for="(propertyAvailabilityObject, key) in propertyAvailabilityData">
            </property-calendar-wrapper>
            <b-pagination
                :disabled="showLoading"
                v-if="propertyAvailabilityData.length > 0 && totalRows > 10"
                @change="updatePagination"
                class="app_pagination mt-3"
                size="sm"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                :hide-goto-end-buttons="true">
                <template v-slot:prev-text>
                    <i class="fa fa-angle-left" aria-hidden="true"></i>
                </template>

                <template v-slot:next-text>
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                </template>
            </b-pagination>
            <div class="mt-3 availability_calendar-selectedContainer animated fadeIn" v-if="selected_unit.length">
                <div>
                    <h4 v-if="selected_unit.length > 1">{{ $t("ITEMS_SELECTED", {value: selected_unit.length}) }}</h4>
                    <h4 v-else-if="selected_unit.length === 1">{{ $t("ITEM_SELECTED") }}</h4>
                    <app-button @click="deselect" class="action_button" variant="link">{{ $t("DESELECT") }}
                    </app-button>
                </div>

                <div class="flex-grow-1 d-flex justify-content-end">
                    <app-button v-if="company.id === 1482" @click="openTagMapping" CLASS="mr-3">{{ $t('TAG') }}
                    </app-button>

                    <app-button :disabled="selected_unit.length === 0" @click="groupEditAside = true"
                                class="pull-right mr-3">{{ $t('EDIT') }}
                    </app-button>
                    <app-button
                        v-if="checkPermission(C_RESERVATION_ACCOMMODATION_NEW) && selected_unit.length === 1"
                        @click="newReservationAside = true" button_type="new" class="pull-right mr-3">
                        {{ $t('NEW_RESERVATION') }}
                    </app-button>
                </div>
            </div>


        </div>
        <calendar-edit-form
            mode="calendar-item-edit"
            :root-unit="aside_data.unit"
            :rate-plans="aside_data.rate_plans"
            :los="aside_data.los"
            :occupancy="aside_data.occupancy"
            :elements="view_options"
            :property-id="property ? (typeof property === 'object' ? property.id : (!isNaN(property) ? property : null)) : null"
            :single-property="true">
        </calendar-edit-form>
        <!--
        <calendar-aside mode="main_calendar" :rate-plans="aside_data.rate_plans" :elements="view_options"
                        :occupancy="aside_data.occupancy"
                        :los="aside_data.los" :root-unit="aside_data.unit"></calendar-aside>
        -->
        <app-aside v-model="open_new_res">
            <template slot="header">
                {{ $t('NEW_RESERVATION') }}
            </template>
            <new-accommodation-reservation-form :redirect="false" :property-id="resData.property"
                                                :unit-id="resData.unit" :dateRange="resData.dateRange"
                                                :availableUnitList="availableUnitList"
                                                :onlyAvailableUnit="resData.dateRange ? true : false"
                                                @created="createdReservation(resData.property)">
            </new-accommodation-reservation-form>
        </app-aside>
        <app-aside v-model="aside_reservation" :widths="['col-lg-12','col-sm-12', '100%']" full-width
                   header-styles="position: sticky; top: 0; z-index: 999; background-color:#fff">
            <template slot="header">
                <reservation-accommodation-header :reservation="reservation"></reservation-accommodation-header>
            </template>

            <reservation-details v-if="reservation.id" :reservation-object="reservation"
                                 @saved="updateData">
            </reservation-details>
        </app-aside>
        <app-aside v-model="advanced_filter" :widths="['col-lg-3', 'col-sm-12', '40%']">
            <template slot="header">
                {{ $t('AVAILABILITY_AND_QUOTE') }}
            </template>
            <advanced-filter :custom-tag-options="customTagOptions" :destination-tag-options="destinationTagOptions"
                             :loader="advanced_loader" @advancedSearch="searchAdvanced"></advanced-filter>
        </app-aside>
        <app-aside :widths="['col-sm-12', '40%']" v-model="property_aside">
            <template slot="header">{{ $t('NEW_PROPERTY') }}</template>
            <property-wizard></property-wizard>
        </app-aside>
        <app-aside :main-classes="['p-0']" v-model="groupEditAside"
                   :widths="['col-lg-4', 'col-md-5', 'col-sm-12', '40%']">
            <template slot="header">{{ $t("EDIT") }}</template>
            <calendar-edit-form
                mode="calendar-edit"
                :selected-units="selected_unit_ids"
                :property-id="selectedPropertyList.length === 1 ? selectedPropertyList[0] : null"
                :single-property="selectedPropertyList.length === 1"
                :contingent="selected_contingent_item"
                :periods="[dateRange]"
                @openNewReservation="groupEditAside = false">
            </calendar-edit-form>
            <!--
            <main-calendar-group-edit
                mode="main_calendar"
                :default-selected-units="selected_unit_ids"
                :default_date_range="dateRange"
                :property-list="selectedPropertyList"
                :selected_contingent_item="selected_contingent_item"
            >
            </main-calendar-group-edit>
            -->

        </app-aside>
        <app-aside :widths="['col-lg-6','col-sm-12', '40%']" v-model="tagAside">
            <template slot="header">
                {{ $t('TAG') }}
            </template>
            <tag-mapping @refreshData="fetchData({
                    start: dateRange.start,
                    end: dateRange.end,
                    actions:['rate_default']
                }, false, true)" :entity_id="entity_id" :object_type_id="OBJECT_TYPE_UNIT"></tag-mapping>
        </app-aside>
    </div>

</template>

<script>

import moment from 'moment'
import PropertyCalendarItem from "@/components/property/calendar/PropertyCalendarItem";
import PropertyCalendarDates from "@/components/property/calendar/PropertyCalendarDates";
import PropertyCalendarScroller from "@/components/property/calendar/PropertyCalendarScroller";
import AppButton from "@/components/app/AppButton/AppButton";
import loading from 'vue-full-loading'
import AppAside from "@/components/app/form/AppAside";
import {OBJECT_TYPE_UNIT} from '@/shared/constants';


import {
    EventBus,
    GE_CALENDAR_LOAD_ADD_MONTH,
    GE_CALENDAR_LOAD_AVAILABILITY,
    GE_CALENDAR_OPEN_AVAILABILITY,
    GE_CALENDAR_UPDATE_AVAILABILITY_LIST,
    GE_CALENDAR_OPEN_RESERVATIONS,
    GE_CALENDAR_OPEN_AVAILABILITY_PROPERTY,
    GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR,
    GE_CALENDAR_REFRESH_DATA,
    GE_CALENDAR_LOAD_PRICES,
    GE_CALENDAR_LOAD_RESTRICTIONS,
    GE_OPEN_NEW_ACC_RES_ASIDE,
    GE_CALENDAR_ADVANCED_FILTER,
    GE_CALENDAR_SELECT_UNIT,
    GE_CALENDAR_SELECT_ALL_UNIT,
    GE_CALENDAR_OPEN_ROOM_STATUS,
    GE_CALENDAR_OPEN_PRICES, GE_CALENDAR_OPEN_RESTRICTIONS,
} from "@/shared/EventBus";
import {
    C_PROPERTY_CALENDAR_AVAILIBILITY_V,
    MAIN_CALENDAR_FILTER_CITY,
    MAIN_CALENDAR_FILTER_PREDEFINED_CITY,
    C_PROPERTY_CALENDAR_PRICES_V,
    C_PROPERTY_CALENDAR_RESTRICTIONS_V,
    C_PROPERTY_CALENDAR_RM_SUGGESTION_V,
    C_PROPERTY_CALENDAR_ROOM_STATUS_V,
    R_PROPERTY_NEW,
    C_RESERVATION_ACCOMMODATION_NEW,
    C_RESERVATION_ACCOMMODATION_BOOKING_SITE
} from "@/shared/component_permission";
import InboxReservation from "@/components/inbox/InboxReservation";
import CalendarAside from "@/components/unit/calendar_opt/Aside/Calendar/CalendarAside";
import ReservationAccommodationHeader from "@/components/reservation/accommodation/ReservationAccommodationHeader";
import ReservationDetails from "@/components/reservation/accommodation/ReservationDetails";
import PropertyCalendarWrapper from "@/components/property/calendar/PropertyCalendarWrapper";
import PropertyCalendarAside from "@/components/calendar/aside/PropertyCalendarAside";
import {AC_FEATURE_SETUP, AC_MAIN_CALENDAR} from "@/mixins/AccessControl/AccessControlEnumeration";
import {fetchAvailability, getUnitAvailableList} from "@/services/unit";
import NewAccommodationReservationForm
    from "@/components/reservation/accommodation/forms/NewAccommodationReservationForm";
import CalendarFilter from "@/components/calendar/CalendarFilter";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
import CalendarYearCell from "@/components/property/calendar/CalendarYearCell";
import PropertyCalendarDateCell from "@/components/property/calendar/PropertyCalendarDateCell";
import {fetchAccommodationReservation} from "@/services/accommodation_reservation";
import AdvancedFilter from "@/components/property/calendar/Aside/AdvancedFilter";
import AppNoData from "@/components/app/AppNoData";
import PropertyWizard from "@/components/property/new/PropertyWizard";
import {
    DEFAULT_PAGINATION_LIMIT,
    LS_PRESETS,
    MOMENT_LOCALE_MAPPINGS,
    TAG_TYPE_CUSTOM,
    TAG_TYPE_DESTINATION
} from "@/shared/constants";
import MainCalendarGroupEdit from "@/components/unit/calendar_opt/Aside/Bulk/MainCalendarGroupEdit";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import {toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {getCityList} from "@/services/location";
import AppAccessControl from "@/components/app/AppAccessControl";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import AppMonthPicker from "@/components/app/datetime/AppMonthPicker";
import {getPresets, getUser} from "@/services/user";
import {getPropertyList} from "@/services/property";
import {fetchAccessControlData} from "@/services/access";
import {getAllTags} from "@/services/tag";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import TagMapping from "@/components/tag/TagMapping";
import {getRmFilterUnit} from "@/services/revenue_manager";
import {MAIN_CALENDAR_FILTER_GROUP} from "@/shared/component_permission";
import CalendarEditForm from "@/components/calendar/aside/CalendarEditForm";

export default {
    name: "CalendarWrapper",
    components: {
        CalendarEditForm,
        TagMapping,
        AppButtonReset,
        AppMonthPicker,
        AppAccessControl,
        AppDatePicker,
        AppSelect,
        MainCalendarGroupEdit,
        PropertyWizard,
        AppNoData,
        AdvancedFilter,
        PropertyCalendarDateCell,
        CalendarYearCell,
        AppDateRangePicker,
        AppButtonSubmit,
        CalendarFilter,
        NewAccommodationReservationForm,
        PropertyCalendarAside,
        PropertyCalendarWrapper,
        ReservationDetails,
        ReservationAccommodationHeader,
        CalendarAside,
        InboxReservation,
        AppAside,
        loading,
        AppButton, PropertyCalendarScroller, PropertyCalendarDates, PropertyCalendarItem,
    },

    data() {

        return {
            MAIN_CALENDAR_FILTER_GROUP,
            OBJECT_TYPE_UNIT,
            entity_id: [],
            tagAside: false,
            cityList: [],
            DEFAULT_PAGINATION_LIMIT,
            MAIN_CALENDAR_FILTER_CITY, AC_MAIN_CALENDAR, MAIN_CALENDAR_FILTER_PREDEFINED_CITY,
            limitList: [10, 20, 50],
            showLoading: false,
            loaded: false,
            loading: false,
            units: {},
            availability: {},
            filter: {
                currentPage: 0,
                limit: 5,
                sortBy: "id",
                sortDirection: "ASC",
            },
            group: null,
            total: 0,
            last_page: null,
            loadAdditionalMonths: 0,
            aside_reservation: false,
            reservation: {},
            shiftKeyVal: false,
            rootUnit: null,
            C_PROPERTY_CALENDAR_AVAILIBILITY_V: C_PROPERTY_CALENDAR_AVAILIBILITY_V,
            R_PROPERTY_NEW,
            C_RESERVATION_ACCOMMODATION_NEW,

            openCalendarAside: false,
            dragPeriod: {
                start: null,
                end: null,
            },
            propertyAvailabilityData: [],
            open_new_res: false,
            resData: {
                unit: 0,
                property: null,
                dateRange: null
            },
            availableUnitList: [],
            dateRange: {
                start: null,
                end: null
            },
            dateArray: [],
            load_more: {
                value: false,
                load: false
            },
            calendarWrapper: null,
            randomInt: null,
            showLoadMore: false,
            showWrapper: false,
            scrollLeftPosition: 0,
            first_next_date: null,
            first_back_date: null,
            aside_data: {
                unit: null,
                los: [],
                occupancy: []
            },
            edit_aside: false,
            advanced_filter: false,
            advanced_loader: false,
            empty_initial_list: false,
            property_aside: false,
            selected_unit: [],
            advanced_res_request: null,
            show_reservation: false,
            groupEditAside: false,
            display_options: [
                {text: this.$t("RATES"), value: 0},
                {text: this.$t("MIN_STAY"), value: 1},
            ],
            display: 0,
            newReservationAside: false,
            selectDate: null,
            selectMonth: null,
            city: null,
            showPropertyItem: true,
            currentPage: 1,
            totalRows: 0,
            perPage: 50,
            preloadPropertyList: false,
            preloadCityList: false,
            property: null,
            propertyList: [],
            ac_response_value: true,
            destinationTagOptions: [],
            customTagOptions: [],
            destination_tags: [],
            custom_tags: [],
            cities_count: 0,
            property_count: 0,
            loading_reset: false,
            reset: 0,
            access_control_fetch_key: AC_MAIN_CALENDAR,
            access_control_components: 1,

        }
    },
    mixins: [getErrorMessage, AccessControlComponent],
    computed: {
        computedLimitList() {
            return this.limitList.sort((a, b) => a - b).map(el => {
                return {
                    id: el,
                    name: el
                }
            })
        },
        selected_unit_ids() {
            return this.selected_unit.map(el => {
                return el.unit
            })
        },
        selected_contingent_item() {
            return this.selected_unit.find(el => el.contingent_item === true) ? true : false
        },

        selectedPropertyList() {
            let select_property_ids = []
            if (this.propertyAvailabilityData.length > 0) {
                let propAvailabilityList = this.propertyAvailabilityData.filter(el => el.hasOwnProperty('units') && Object.keys(el.units).length > 0)
                if (propAvailabilityList.length === 0) {
                    return select_property_ids
                }
                for (const propAvailabilityObject of propAvailabilityList) {
                    for (const [key, value] of Object.entries(propAvailabilityObject.units)) {
                        if (this.selected_unit.find(el => el.unit === (Number(key)))) {
                            select_property_ids.push(Number(propAvailabilityObject.id))
                        }
                    }
                }
                return select_property_ids
            }
        },
        showMoreBack() {
            return this.scrollLeftPosition === 0 ? true : false
        },
        defaultLocale() {
            if (MOMENT_LOCALE_MAPPINGS.hasOwnProperty(this.$i18n.locale)) {
                return MOMENT_LOCALE_MAPPINGS[this.$i18n.locale]
            }
            return 'en'
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },

        lastIndexDate() {
            return this.dateArray.length > 0 ? this.dateArray.length - 1 : null
        },
        view_options() {
            let options = []
            if (this.availibilityViewPermission) {
                options.push(C_PROPERTY_CALENDAR_AVAILIBILITY_V)
            }
            if (this.roomStatusViewPermission) {
                options.push(C_PROPERTY_CALENDAR_ROOM_STATUS_V)
            }
            if (this.pricesViewPermission) {
                options.push(C_PROPERTY_CALENDAR_PRICES_V)
            }
            if (this.restrictionsViewPermission) {
                options.push(C_PROPERTY_CALENDAR_RESTRICTIONS_V)
            }
            return options
        },
        availibilityViewPermission() {
            return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_AVAILIBILITY_V)
        },
        pricesViewPermission() {
            return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_PRICES_V)
        },
        restrictionsViewPermission() {
            return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_RESTRICTIONS_V)
        },
        rmSuggestionViewPermission() {
            return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_RM_SUGGESTION_V)
        },
        roomStatusViewPermission() {
            return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_ROOM_STATUS_V)
        },

    },
    methods: {
        searchFilter(value) {
            const req = {
                title: value,
                page: 1,
                perPage: 100,
                company: this.company.id
            }
            return getRmFilterUnit(req).then(response => ({data: response.data.items}))
        },
        openTagMapping() {
            this.entity_id = this.selected_unit.length > 0 ? this.selected_unit.filter(el => el.contingent_item === false).map(el => {
                return el.unit
            }) : []
            if (Number(this.company.id) === 1482) {
                this.tagAside = true
            }

        },
        searchProperty(value) {
            let request = {name: value, company_id: this.company.id}
            if (this.city !== null) {
                const city = typeof this.city === 'object' && this.city.hasOwnProperty('id') ? this.city.id : this.city
                request = {...request, ...{city}}
            }
            return getPropertyList(request).then(response => {
                return {data: response.data.items}
            })
        },
        updatePagination(value) {

            this.currentPage = value
            this.fetchData({
                start: this.dateRange.start,
                end: this.dateRange.end,
                actions: ['rate_default']
            }, false, true)
        },
        updateSelectMonth(value) {
            if (value) {
                this.dateRange.start = moment(value).startOf('month').format("YYYY-MM-DD"),
                    this.dateRange.end = moment(value).endOf('month').format("YYYY-MM-DD"),
                    this.selectMonth = value
                this.fetchData({start: this.dateRange.start, end: this.dateRange.end}, true, true)

            }
        },
        deselect() {
            this.selected_unit = []
        },
        searchCity(value) {
            return getCityList({
                name: value,
            })
        },
        updateScroll($event) {
            this.scrollLeftPosition = $event
        },
        onScroll($event) {
            this.scrollLeftPos = $event.target.scrollLeft
        },

        setDateArray() {

            this.dateArray = [];
            let currentDate = moment(this.dateRange.start);
            const stopDate = moment(this.dateRange.end);
            while (currentDate <= stopDate) {
                this.dateArray.push(moment(currentDate))
                currentDate = moment(currentDate).add(1, 'days');
            }

        },
        updateRange(value) {
            this.loadAdditionalMonths = 0
            this.dateRange.start = value.start
            this.dateRange.end = value.end
            this.fetchData(value, false, true)
        },
        loadMore() {

            this.selectMonth = moment(this.selectMonth).add(1, 'months').format("YYYY-MM-DD")
            this.dateRange.start = moment(this.selectMonth).startOf('month').format("YYYY-MM-DD")
            this.dateRange.end = moment(this.selectMonth).endOf('month').format("YYYY-MM-DD")
            this.showWrapper = false
            this.first_next_date = this.dateRange.start
            this.first_back_date = null
            this.loaded = false
            this.fetchData({start: this.dateRange.start, end: this.dateRange.end}, true, true)
        },
        loadMoreBack() {

            this.selectMonth = moment(this.selectMonth).subtract(1, 'months').format("YYYY-MM-DD")
            this.dateRange.start = moment(this.selectMonth).startOf('month').format("YYYY-MM-DD")
            this.dateRange.end = moment(this.selectMonth).endOf('month').format("YYYY-MM-DD")
            this.first_back_date = this.dateRange.start
            this.first_next_date = null
            this.showWrapper = false
            this.loaded = false
            this.fetchData({start: this.dateRange.start, end: this.dateRange.end}, true, true)
        },
        handleHorizontalScroll($event) {
            this.scrollLeftPosition = $event
        },
        updateData(data) {
            this.aside_reservation = false
            this.fetchDataByProperty({property_id: data.property.id})
        },
        openNewReservation(request) {
            if (request && typeof request === 'object') {
                this.resData.property = request.hasOwnProperty('property') ? request.property : null
                this.resData.dateRange = request.hasOwnProperty('dateRange') ? request.dateRange : null
                this.resData.unit = request.hasOwnProperty('unit_id') ? request.unit_id : null
            } else {
                if (this.selected_unit.length === 1) {
                    this.resData.unit = this.selected_unit[0] ? this.selected_unit[0].unit : null
                    if (this.resData.unit) {
                        const propertyIndex = this.propertyAvailabilityData.findIndex(el => Object.keys(el.units).map(key => parseInt(key)).includes(this.resData.unit))
                        if (propertyIndex < 0) {
                            return
                        }
                        this.resData.property = this.propertyAvailabilityData[propertyIndex] ? this.propertyAvailabilityData[propertyIndex].id : null
                    }

                }

            }
            if (this.resData.dateRange) {
                getUnitAvailableList({
                    property: this.resData.property,
                    date_from: this.resData.dateRange ? this.resData.dateRange.start : null,
                    date_to: this.resData.dateRange ? this.resData.dateRange.end : null
                }).then(response => {
                    this.availableUnitList = response.data
                    if (!this.resData.unit && response.data.length > 0) {
                        this.resData.unit = response.data[0].id
                    }

                })
            }
            this.open_new_res = true

        },
        createdReservation(property_id) {
            this.open_new_res = false
            this.fetchDataByProperty({
                property_id: property_id,
            })
        },

        fetchData: function (requestObject, lm, global_loader) {

            this.load_more = lm ? {value: true, load: false} : {value: false, load: false}
            this.selected_unit = []
            let req = {
                date_from: requestObject.start,
                date_to: requestObject.end,
                order_by: this.filter.sortBy,
                order_by_direction: this.filter.sortDirection,
                property: requestObject.hasOwnProperty('property') ? requestObject.property : null,
                company: this.company.id,
                city: this.city ? typeof this.city === 'object' && this.city.hasOwnProperty('id') ? this.city.id : this.city : null,
                perPage: requestObject.hasOwnProperty('perPage') ? requestObject.perPage : this.perPage,
                currentPage: this.currentPage,
                actions: this.display === 1 ? ['restriction'] : ['rate_default'],
            }

            if (this.destination_tags.length > 0) {
                req = {...req, ...{destination: this.destination_tags}}
            }
            if (this.custom_tags.length > 0) {
                req = {...req, ...{custom_tag: this.custom_tags}}
            }
            if (this.property) {
                req = {...req, ...{property: typeof this.property === 'object' && this.property.hasOwnProperty('id') ? this.property.id : this.property}}
            }
            if (this.group && typeof this.group === 'object' && this.group.hasOwnProperty('id')) {
                req = {...req, ...{filter_id: this.group.id}}

            }
            if (global_loader) {
                this.showLoading = true
            } else {
                if (this.reset === 1) {
                    this.loading = false
                    this.loading_reset = true
                } else {
                    this.loading = true
                    this.loading_reset = false
                }

            }
            fetchAvailability(req).then(response => {

                if (!response.data || !response.data.hasOwnProperty('items')) {
                    this.totalRows = 0
                    this.propertyAvailabilityData = []
                    return
                }
                this.setRandomInt()
                this.totalRows = response.data.total
                this.propertyAvailabilityData = response.data.items
                this.dateRange.end = requestObject.end
                this.setDateArray()
                this.showWrapper = true
                this.loading = false

            }).finally(() => {
                this.show_reservation = false
                this.loaded = true
                this.load_more = lm ? {value: true, load: true} : {value: false, load: false}
                this.showLoading = false
                this.loading = false
                this.loading_reset = false
                this.reset = 0

            })
        },
        fetchDataByProperty(requestObject) {
            this.select_unit = []
            this.load_more = {value: false, load: false}
            this.open_new_res = false
            this.loading = true
            let req = {
                unit: requestObject.unit,
                date_from: this.dateRange.start,
                date_to: this.dateRange.end,
                order_by: this.filter.sortBy,
                order_by_direction: this.filter.sortDirection,
                company: this.company.id,
                currentPage: this.currentPage,
                actions: this.display === 1 ? ['restriction'] : ['rate_default'],
            }
            fetchAvailability(req).then(response => {
                if (response.data && response.data.hasOwnProperty('items') && response.data.items.length > 0) {
                    response.data.items.forEach(responseItem => {
                        const propertyIndex = this.propertyAvailabilityData.findIndex(el => Object.keys(el.units).map(key => parseInt(key)).includes(requestObject.unit))
                        if (propertyIndex < 0 && response.data.items.length !== 1) {
                            return
                        }
                        this.$set(this.propertyAvailabilityData, propertyIndex, responseItem)
                    })
                }


            }).finally(() => {
                this.loading = false
                this.show_reservation = false

            })
        },
        search() {
            this.loaded = false
            this.showWrapper = false
            let req = {
                start: this.dateRange.start,
                end: this.dateRange.end,
                city: this.city,
                actions: this.display === 1 ? ['restriction'] : ['rate_default'],
                perPage: this.perPage,
                company: this.company.id
            }
            if (this.property) {
                req = {...req, ...{property: typeof this.property === 'object' && this.property.hasOwnProperty('id') ? this.property.id : this.property}}
            }
            this.fetchData(req, false)
        },
        getRange(date) {
            const start = moment(date.start).add(-7, 'days').format("YYYY-MM-DD")
            const end = moment(date.end).add(+7, 'days').format("YYYY-MM-DD")
            return {
                start,
                end
            }
        },
        searchAdvanced(request) {
            this.selected_unit = []
            this.loaded = false
            this.advanced_loader = true
            let period_range = this.dateRange
            if (request.check_in) {
                period_range = this.getRange({start: request.check_in, end: request.check_out})
                this.dateRange = period_range
            }
            this.showWrapper = false
            let req = {
                ...{
                    date_from: period_range.start,
                    date_to: period_range.end,
                    availability_quote_filter: true,
                    actions: this.display === 1 ? ['restriction'] : ['rate_default'],
                    perPage: this.perPage
                }, ...request
            }

            this.setDateArray()
            fetchAvailability(req).then(response => {
                this.setRandomInt()
                if (typeof response.data === 'object' && response.data.hasOwnProperty('items')) {
                    this.propertyAvailabilityData = response.data.items
                    this.totalRows = response.data.total
                } else {
                    this.propertyAvailabilityData = []
                    this.totalRows = 0
                }
                if (this.propertyAvailabilityData.length === 1) {
                    let propertyFilteredData = this.propertyAvailabilityData.filter(el => el.hasOwnProperty('units') && Object.keys(el.units).length === 1)
                    if (propertyFilteredData.length === 1) {
                        const propertyObjectData = propertyFilteredData[0]
                        const unit_list = Object.keys(propertyObjectData.units)
                        this.advanced_res_request = {
                            property: propertyObjectData.id,
                            dateRange: {
                                start: request.check_in,
                                end: request.check_out,
                            },
                            unit: unit_list[0]
                        }
                        setTimeout(() => {
                            this.selected_unit.push({
                                unit: Number(this.advanced_res_request.unit),
                                contingent_item: false
                            })
                            this.show_reservation = true
                        }, 200)
                    }

                }
                this.showWrapper = true
                this.advanced_filter = false

            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 3000
                })

            }).finally(() => {
                this.advanced_loader = false
                this.loaded = true
            })
        },
        getCities() {
            getCityList({
                user_cities: true,
            }).then(response => {
                this.cityList = response.data
            })
        },
        setDate() {

            this.dateRange.start = moment().format("YYYY-MM-DD")
            this.dateRange.end = moment().add(90, 'day').format("YYYY-MM-DD")

        },
        shiftKeyDown(e) {
            if (e.key === 'Shift') {
                this.shiftKeyVal = true
            }
        },
        shiftKeyUp(e) {
            if (e.key === 'Shift') {
                this.shiftKeyVal = false
            }
        },
        setRandomInt() {
            this.randomInt = Math.floor(Math.random() * 10)
        },
        getUnitAditionalData(property, unit) {
            let propertyDataList = this.propertyAvailabilityData.filter(el => el.id === property.id)
            for (const propertyData of propertyDataList) {
                if (propertyData && propertyData.hasOwnProperty('units') && Object.keys(propertyData.units).length > 0) {
                    let unitData = propertyData.units[unit.id]
                    if (typeof unitData === 'object' && Object.keys(unitData).length > 0) {
                        return unitData && unitData.hasOwnProperty('additional_data') ? unitData.additional_data : null
                    }
                }
            }

            return null
        },
        getTagList(type) {

            getAllTags(type, {company: this.company.id}).then(response => {
                if (type === TAG_TYPE_DESTINATION) {
                    this.destinationTagOptions = response.data
                }
                if (type === TAG_TYPE_CUSTOM) {
                    this.customTagOptions = response.data
                }
            })
        },
        resetForm() {
            this.property = null,
                this.city = null,
                this.custom_tags = []
            this.destination_tags = []
            this.group = null
            this.reset = 1
            this.search()

        }
    },
    mounted() {
        let request = {
            context: {
                company: this.$store.getters['user/getCompany']
            },
            data: []
        }
        request.data.push({
            uid: C_RESERVATION_ACCOMMODATION_BOOKING_SITE,
            function: C_RESERVATION_ACCOMMODATION_BOOKING_SITE
        })
        fetchAccessControlData(AC_FEATURE_SETUP, request).then(response => {
            this.ac_response_value = response.data[C_RESERVATION_ACCOMMODATION_BOOKING_SITE].visible
        })

        let storagePresets = localStorage.getItem(LS_PRESETS)
        if (storagePresets) {
            storagePresets = JSON.parse(storagePresets)
        } else {
            getPresets().then(response => {
                localStorage.setItem(LS_PRESETS, JSON.stringify(response.data))
                storagePresets = localStorage.getItem(LS_PRESETS)

            })
        }
        if (storagePresets && typeof storagePresets === 'object') {
            if (storagePresets.hasOwnProperty('properties')) {
                this.property_count = storagePresets.properties
                if (this.property_count === 0) {
                    this.empty_initial_list = true
                }
                if (this.property_count === 1) {
                    this.showPropertyItem = false
                }
                if (this.property_count <= DEFAULT_PAGINATION_LIMIT) {
                    this.property = null
                    this.preloadPropertyList = true

                    getPropertyList({company_id: this.company.id}).then(response => {
                        this.$nextTick(() => {
                            const data = response.data && response.data.hasOwnProperty('items') ? response.data.items : []
                            this.propertyList = data.map(el => {
                                return {id: el.id, name: el.name}
                            })
                        })

                    })
                }
            }

            if (storagePresets.hasOwnProperty('cities')) {
                this.cities_count = storagePresets.cities
                if (storagePresets.cities <= DEFAULT_PAGINATION_LIMIT) {
                    this.getCities()
                    this.preloadCityList = true
                }
            }
        }


        window.addEventListener('keydown', this.shiftKeyDown)

        window.addEventListener('keyup', this.shiftKeyUp)

        EventBus.$on(GE_CALENDAR_OPEN_RESERVATIONS, (reservation) => {
            this.aside_reservation = false
            fetchAccommodationReservation(reservation.id, {withOwner: true}).then(response => {
                this.reservation = response.data
                this.aside_reservation = true
            })

        })
        EventBus.$on(GE_CALENDAR_LOAD_AVAILABILITY, (data) => {
            this.fetchDataByProperty({unit: data.unitId})
        })
        EventBus.$on(GE_CALENDAR_REFRESH_DATA, (data) => {
            this.fetchDataByProperty({unit: data.unitId})
        })
        EventBus.$on(GE_CALENDAR_LOAD_PRICES, (data) => {
            this.fetchDataByProperty({unit: data.unitId})
        })

        EventBus.$on(GE_CALENDAR_LOAD_RESTRICTIONS, (data) => {
            this.fetchDataByProperty({unit: data.unitId})
        })

        EventBus.$on(GE_OPEN_NEW_ACC_RES_ASIDE, (data) => {
            this.openNewReservation(data)
        })
        EventBus.$on(GE_CALENDAR_ADVANCED_FILTER, () => {
            this.advanced_filter = true
        })

        EventBus.$on(GE_CALENDAR_LOAD_RESTRICTIONS, (data) => {
            this.fetchDataByProperty({unit: data.unitId})
        })
        EventBus.$on('ge_open_tag_mapping', (data) => {


            if (this.company.id === 1482 ) {
                this.tagAside = true
                this.entity_id = [data.unit]
            }
        })
        EventBus.$on(GE_CALENDAR_OPEN_AVAILABILITY_PROPERTY, (data) => {

            if (data && Object.keys(data).length > 0) {
                const property = data.hasOwnProperty('property') ? data.property : null
                const unit = data.hasOwnProperty('rootUnit') ? data.rootUnit : null
                let additional_data = this.getUnitAditionalData(property, unit)

                if (unit && additional_data) {
                    this.aside_data.los = [additional_data.default_los]
                    this.aside_data.occupancy = [additional_data.default_occupancy]
                    this.aside_data.rate_plans = [{id: additional_data.primary_rate_plan}]
                    this.aside_data.unit = unit
                    const request = {
                        unitId: data.unitId,
                        dateRange: data.dateRange,
                        contigentMode: data.contigentMode,
                        contigentUnit: data.contigentUnit,
                        tab: data.tab
                    }
                    this.edit_aside = true
                    setTimeout(() => {
                        EventBus.$emit(GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR, request)
                    }, 300)
                }
            }
        })

        EventBus.$on(GE_OPEN_NEW_ACC_RES_ASIDE, (data) => {
            this.openNewReservation(data)
        })
        EventBus.$on(GE_CALENDAR_SELECT_UNIT, (data) => {

            if (data.value) {
                this.selected_unit.push({unit: data.unit, contingent_item: data.contingent_item})
            } else {
                let index = this.selected_unit.findIndex(el => el.unit === data.unit && data.contingent_item === el.contingent_item)
                if (index >= 0) {
                    this.selected_unit.splice(index, 1)
                }
            }
            this.$emit('selectUnit', this.selected_unit)
        })
        EventBus.$on(GE_CALENDAR_SELECT_ALL_UNIT, (data) => {
            if (data.value) {
                if (this.propertyAvailabilityData.length > 0) {
                    this.propertyAvailabilityData.forEach(el => {
                        if (el.hasOwnProperty('units') && Object.keys(el.units).length > 0) {
                            for (const [unit_id, unitDataObject] of Object.entries(el.units)) {
                                this.selected_unit.push({unit: Number(unit_id), contingent_item: false})
                            }
                        }
                    })
                }
            } else {
                this.selected_unit = []
            }
            this.$emit('selectUnit', this.selected_unit)
        })
    },
    watch: {
        load_more: {
            handler(object) {
                if (object.value && object.load) {
                    if (this.first_next_date) {
                        let indexDate = this.dateArray.findIndex(el => {
                            return el.format('YYYY-MM-DD') === this.first_next_date
                        })
                        let calculatedPosition = 45.92 * indexDate  // 45.92 cell width
                        setTimeout(() => {
                            this.scrollLeftPosition = calculatedPosition
                            document.querySelector('.main_calendar__holder--content-scroll').scrollLeft = calculatedPosition
                        }, 300)
                    } else if (this.first_back_date) {
                        let indexDate = this.dateArray.findIndex(el => {
                            return el.format('YYYY-MM-DD') === this.first_back_date
                        })
                        let calculatedPosition = 45.92 * indexDate // 45.92 cell width
                        setTimeout(() => {
                            this.scrollLeftPosition = calculatedPosition - document.querySelector('.main_calendar__holder--content-scroll').clientWidth
                            document.querySelector('.main_calendar__holder--content-scroll').scrollLeft = this.scrollLeftPosition
                        }, 300)
                    }
                }
            }
        },
        shiftKeyVal(val) {

            if (val === false) {
                this.dragPeriod.start = null
                this.dragPeriod.end = null
                this.currentDragDate = null
            }
        },

        newReservationAside: {
            handler(value) {
                if (value) {
                    this.openNewReservation(this.advanced_res_request)
                }

            }
        },
        dateRange: {
            handler(date) {
                if (typeof date === 'object' && date.hasOwnProperty('start')) {
                    this.selectMonth = moment(date.start).format('YYYY-MM')
                }
            }, immediate: true,
            deep: true
        },
        display: {
            async handler(value) {
                this.$nextTick(() => {
                    if (value === 1) {
                        // actions
                        this.fetchData({
                            start: this.dateRange.start,
                            end: this.dateRange.end,
                            actions: ['restriction']
                        }, false, true)
                    } else {
                        this.fetchData({
                            start: this.dateRange.start,
                            end: this.dateRange.end,
                            actions: ['rate_default']
                        }, false, true)
                    }
                })

            }

        },
        perPage: {
            handler(value) {
                this.fetchData({
                    perPage: value,
                    start: this.dateRange.start,
                    end: this.dateRange.end,
                    actions: ['rate_default']
                }, false, true)
            }
        },
        city: {
            handler(value) {

                let city = null
                if (typeof value === 'object' && value.hasOwnProperty('id')) {
                    city = value.id
                } else {
                    city = value.length > 0 ? value : null
                }
                let req = {company_id: this.company.id}
                if (city) {
                    req = {...req, ...{city}}
                }
                getPropertyList(req).then(response => {
                    this.$nextTick(() => {
                        const data = response.data && response.data.hasOwnProperty('items') ? response.data.items : []
                        if (data.length <= DEFAULT_PAGINATION_LIMIT) {

                            this.propertyList = data.map(el => {
                                return {id: el.id, name: el.name}
                            })
                        } else {
                            this.propertyList = []
                        }
                    })
                })
            }
        }

    },
    async created() {
        this.dateRange.start = moment().format("YYYY-MM-DD")
        this.dateRange.end = moment().add(90, 'day').format("YYYY-MM-DD")
        await this.setDateArray()
        this.getTagList(TAG_TYPE_CUSTOM)
        this.getTagList(TAG_TYPE_DESTINATION)
        this.fetchData({
            start: this.dateRange.start,
            end: this.dateRange.end,
            actions: ['rate_default']
        }, false, false)
    },

    beforeDestroy() {

        EventBus.$off(GE_CALENDAR_LOAD_AVAILABILITY)
        EventBus.$off(GE_CALENDAR_UPDATE_AVAILABILITY_LIST)
        EventBus.$off(GE_CALENDAR_LOAD_ADD_MONTH)
        EventBus.$off(GE_CALENDAR_REFRESH_DATA)
        EventBus.$off(GE_CALENDAR_LOAD_PRICES)
        EventBus.$off(GE_CALENDAR_LOAD_RESTRICTIONS)
        EventBus.$off(GE_CALENDAR_OPEN_AVAILABILITY_PROPERTY)
        EventBus.$off(GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR)
        EventBus.$off(GE_CALENDAR_OPEN_ROOM_STATUS)
        EventBus.$off(GE_CALENDAR_OPEN_PRICES)
        EventBus.$off(GE_CALENDAR_OPEN_RESTRICTIONS)
        EventBus.$off(GE_OPEN_NEW_ACC_RES_ASIDE)
        EventBus.$off(GE_CALENDAR_SELECT_ALL_UNIT)
        window.removeEventListener("keyup", this.shiftKeyUp)
        window.removeEventListener("keydown", this.shiftKeyDown)


    }

}
</script>

<style scoped>
.res-no-border {
    box-shadow: none;
}

.res-no-border > div {
    border: none !important;
    padding-left: 0;
}

.no-border {
    border-bottom: none !important
}

.width-period {
    width: 13.5rem
}

.w-30 {
    width: 30rem;
}

.display-settings {
    width: 7.8rem;
}
</style>
