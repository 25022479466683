<template>
    <form v-if="activation_rules.city && activation_rules.country">
        <b-row class="mt-4" v-if="activation_rules.city.length > 0">
            <b-col>
                <label>{{ $t('MARKETPLACE_ACTIVATION_RULES.CITY_REQUIREMENTS') }}</label>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <span v-for="(city, key) in activation_rules.city" class="mr-2">
                    {{city}}
                    <span v-if="key+1 !== activation_rules.city.length" class="ml-2">|</span>
                </span>
            </b-col>
        </b-row>
        <b-row class="mt-4" v-if="activation_rules.country.length > 0">
            <b-col>
                <label>{{$t('MARKETPLACE_ACTIVATION_RULES.COUNTRY_REQUIREMENTS')}}</label>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <span v-for="(country, key) in activation_rules.country" class="mr-2">
                    {{country}}
                    <span v-if="key+1 !== activation_rules.country.length" class="ml-2">|</span>
                </span>
            </b-col>
        </b-row>
        <b-row v-if="activation_rules.unit !== null" class="mt-4">
            <b-col v-if="activation_rules.unit.min_number > 0">
                <label>{{$t('NUMBER_OF_UNITS')}}: </label>
                <span v-if="!website_activation.validation.validation" class="ml-2">{{website_activation.validation.count}} / {{activation_rules.unit.min_number}}
                    <i class="fa fa-circle m-0 main_calendar_test_indicator-close ml-2" ></i></span>
                <span v-else class="ml-2">{{website_activation.validation.count}} / {{activation_rules.unit.min_number}}
                    <i class="fa fa-circle m-0 main_calendar_test_indicator-open ml-2" ></i></span>
            </b-col>
        </b-row>
        <div v-if="tos.lang">
            <b-row class="mt-4">
                <b-col>
                    <label>{{$t('TERMS_OF_SERVICE')}}</label>
                    <b-form-textarea
                        v-model="tos.lang"
                        max-rows="20"
                        :disabled="true"
                    ></b-form-textarea>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <b-checkbox
                        v-model="acceptTos"
                        @change="accept($event)"
                        switch>
                        <label class="ml-0 pl-0">{{$t('ACCEPT_TERMS_OF_SERVICE')}}</label>
                    </b-checkbox>
                </b-col>
            </b-row>
        </div>
        <b-row class="mt-4">
            <b-col>
<!--                <b-tooltip v-if="!(acceptTos && website_activation.validation.validation)" target="sendRequestButton"-->
<!--                           :title="$t('VALIDATION_AND_TOS_FAILED')"></b-tooltip>-->
                <b-tooltip v-if="!acceptTos" target="sendRequestButton"
                           :title="$t('TOS_NOT_ACCEPTED')"></b-tooltip>
                <b-tooltip v-else-if="!website_activation.validation.validation" target="sendRequestButton"
                           :title="$t('VALIDATION_FAILED')"></b-tooltip>
                <app-button :disabled="!(acceptTos && website_activation.validation.validation)"
                            @click="confirmation_dialog_state=true"
                            id="sendRequestButton"
                            :loading="loading"
                >{{$t('SEND')}}</app-button>
            </b-col>
        </b-row>
        <app-confirmation-dialog
            v-if="confirmation_dialog_state"
            :show="confirmation_dialog_state"
            @confirm="send"
            @cancel="confirmation_dialog_state=false"
            :title="$t('SEND_REQUEST_TITLE')">
            {{$t('SEND_REQUEST_MSG', {website_name: websiteObject[0].name})}}
        </app-confirmation-dialog>
    </form>
</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {getMarketplaceSetupValidation, sendRequestEmail, sendRequestForMarketplace} from "@/services/marketplace";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {MARKETPLACE_WEBSITE_ACTIVATION, MARKETPLACE_TOS} from "@/shared/constants";
import website from "@/store/modules/website/website";

export default {
    name: "MarketplaceRequestForm",
    components:{AppButton, AppConfirmationDialog},
    mixins:[getErrorMessage],
    props:{
        websiteObject:{
            type: Array,
            default: []
        }
    },
    data(){
        return{
            website_activation: [],
            activation_rules: [],
            tos: [],
            acceptTos: false,
            loading: false,
            confirmation_dialog_state: false
        }
    },
    computed: {
        last(){
            return Object.keys(this.activation_rules.city).length-1;
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods:{
        send(){
            this.loading = true
            sendRequestForMarketplace({
                website_id: this.websiteObject[0].id,
                status_id: this.website_activation.workflow.initial_status,
                workflow: this.website_activation.workflow.id,
                company_id: this.company.id
            }).then(response => {
                this.loading = false
                notifySuccess()
                this.$emit('requestSent')
                sendRequestEmail({company_id: this.company.id, website_id: this.websiteObject[0].id})
            }, error => {
                this.loading = false
                this.showErrorMessages(error)
            })
        },
        accept(event){
            this.$nextTick(() => {
                this.acceptTos = event
            })
        },
    },
    mounted() {
        getMarketplaceSetupValidation({website_id: this.websiteObject[0].id, company_id: this.websiteObject[0].company}).then(response => {
            response.data.forEach(el => {
                el.id === MARKETPLACE_WEBSITE_ACTIVATION ? this.activation_rules = el.activation_rules : ''
                el.id === MARKETPLACE_WEBSITE_ACTIVATION ? this.website_activation = el : ''
                if (el.id === MARKETPLACE_TOS){
                    this.tos = el
                    el.lang === null ? this.acceptTos = true : ''
                }
            })
        })
    },
}
</script>

<style scoped>

</style>
