<template>
    <div v-if="los.length === 0 || occupancy.length === 0">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else class="text-muted">
        <form @submit.prevent="save" v-if="Object.keys(priceItems).length > 0">
            <b-row>
                <b-col>
                    <table style="width: 100%" class="table table-sm">
                        <thead>
                        <tr>
                            <th>{{$t("LENGTH_OF_STAY")}}</th>
                            <th>{{$t("OCCUPANCY")}}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="losItem in los">
                            <tr :key="losItem.id + '_' + occupancyItem.id"
                                v-for="occupancyItem in occupancy">
                                <td :class="losItem.default && occupancyItem.default ? 'bg-light' : ''">
                                    {{losItem.min_stay}}
                                    <span v-if="losItem.max_stay"> - {{losItem.max_stay}}</span>
                                    <span v-else>+</span>
                                </td>
                                <td :class="losItem.default && occupancyItem.default ? 'bg-light' : ''">
                                    {{occupancyItem.min_guests}}
                                    <span v-if="occupancyItem.max_guests"> - {{occupancyItem.max_guests}}</span>
                                    <span v-else>+</span>
                                </td>
                                <td v-if="objectHas(priceItems, `${losItem.id}.${occupancyItem.id}`)">
                                    <app-number-input :prepend="currency"
                                                      v-model="priceItems[losItem.id][occupancyItem.id]">
                                    </app-number-input>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
            <b-row class="pt-3">
                <b-col>
                    <app-submit :c_permission="c_edit" :disabled="dateRanges.length === 0 || !canBeSaved"
                                :loading="saving"></app-submit>
                </b-col>
            </b-row>
        </form>
    </div>
</template>

<script>
    import {EventBus, GE_CALENDAR_LOAD_LOS_AND_OCCUPANCY, GE_CALENDAR_LOAD_PRICES} from "@/shared/EventBus";
    import AppSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {toast} from '@/shared/plugins/toastr'
    import InputPriceFormElement from '@/components/unit/calendar/forms/price/InputPriceFormElement'
    import AppValidationDescription from '@/components/app/form/AppValidationDescription'
    import {setUnitPrice} from "@/services/unit";
    import {C_PROPERTY_CALENDAR_PRICES_E} from "@/shared/component_permission";
    import {each, has, cloneDeep, isEqual} from 'lodash'
    import AppNumberInput from "@/components/app/form/AppNumberInput";

    export default {
        name: "CalendarAsideRates",
        props: {
            unit: {
                type: Object
            },
            los: {
                type: Array
            },
            dateRanges: {
                type: Array
            },
            occupancy: {
                type: Array
            },
            defaultValues: {
                type: Object
            },
            ratePlan: {
                type: Number,
                default: null
            }
        },
        components: {
            AppNumberInput,
            AppSubmit, InputPriceFormElement, AppValidationDescription
        },
        mixins: [getErrorMessage],
        data() {
            return {
                loading: false,
                priceItems: {},
                nonEditablePriceItems: {},
                form_invalid: false,
                saving: false,
                c_edit: C_PROPERTY_CALENDAR_PRICES_E
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            property() {
                return this.$store.getters[`property/getProperty`]
            },
            currency() {
                return this.company.hasOwnProperty('working_currency') ? this.company.working_currency.code : null
            },
            canBeSaved() {
                let val = false
                if (isEqual(this.priceItems, this.nonEditablePriceItems)) {
                    return val
                }

                Object.keys(this.priceItems).forEach(losId => {
                    Object.keys(this.priceItems[losId]).forEach(occupancyId => {
                        if (Number(this.priceItems[losId][occupancyId]) !== null) {
                            val = true
                        }
                    })
                })

                return val
            }
        },
        methods: {
            objectHas(object, path) {
                return has(object, path)
            },
            setDefaultValues() {

                if (this.defaultValues && this.defaultValues.hasOwnProperty('price')) {
                    each(this.priceItems, (losList, losId) => {
                        each(losList, (occupancyValue, occId) => {
                            let valueFiltered = this.defaultValues.price.find(item => {
                                return item.los_id === Number(losId) && item.occupancy_id === Number(occId)
                            })

                            if (valueFiltered) {
                                this.priceItems[losId][occId] = valueFiltered.amount ? valueFiltered.amount.toString() : null
                            }
                        })
                    })

                    this.nonEditablePriceItems = cloneDeep(this.priceItems)
                }
            },
            save() {
                let new_pricing = []
                Object.keys(this.priceItems).forEach(losId => {
                    Object.keys(this.priceItems[losId]).forEach(occupancyId => {
                        if (Number(this.priceItems[losId][occupancyId]) > 0) {
                            new_pricing.push({
                                los: losId,
                                occupancy: occupancyId,
                                amount: Number(this.priceItems[losId][occupancyId])
                            })
                        }
                    })

                })

                if (new_pricing.length === 0) {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NO_CHANGE'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return
                }


                let pricing = []
                this.dateRanges.forEach(dateRange => {
                    pricing.push({
                        date_from: dateRange.start,
                        date_to: dateRange.end,
                        pricing: new_pricing
                    })
                })


                this.saving = true
                setUnitPrice(this.unit.id, {
                    pricing: pricing
                }).then(() => {

                        EventBus.$emit(GE_CALENDAR_LOAD_PRICES, {
                            value: null,
                            unitId: this.unit.id
                        })
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })
                        this.nonEditablePriceItems = cloneDeep(this.priceItems)
                        this.saving = false
                    },
                    (error) => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                        this.saving = false
                    }
                )
            },
            populateData() {

                this.los.forEach(losItem => {
                    if (!this.priceItems.hasOwnProperty(losItem.id)) {
                        this.$set(this.priceItems, losItem.id, {})
                    }

                    this.occupancy.forEach(occItem => {
                        this.$set(this.priceItems[losItem.id], occItem.id, null)
                    })
                })

                this.setDefaultValues()
                this.loading = false
            }
        },
        watch: {
            defaultValues: {
                handler() {
                    this.setDefaultValues()
                },
                deep: true
            },
            los:{
                handler(){
                    this.populateData()
                },
                deep: true
            },
            occupancy:{
                handler(){
                    this.populateData()
                },
                deep: true
            }

        },
        created() {
            if (!this.los.length || !this.occupancy.length) {
                EventBus.$emit(GE_CALENDAR_LOAD_LOS_AND_OCCUPANCY, {
                    unitId: this.unit.id,
                    show: null
                })
            } else {
                this.populateData()
            }
        }
    }
</script>

<style scoped>

</style>
