<template>
    <b-form @submit.prevent="save">
        <b-row>
            <b-col lg="6" class="mb-4">
                <label class="label">Booking window</label>
                <app-select  v-model="object.booking_window"
                            :options="booking_windows"></app-select>
            </b-col>
            <b-col lg="6" class="mb-4">
                <label class="label">{{ $t('CONDITION') }}</label>
                <app-select v-model="object.condition" text-field="title" value-field="id"
                            :options="conditions"></app-select>
            </b-col>
            <b-col lg="6" class="mb-4">
                <label class="label">{{ $t('ACTION') }}</label>
                <app-select v-model="object.action" :options="actions" text-field="title" value-field="id"></app-select>
            </b-col>
            <b-col lg="6" class="mb-4">
                <label class="label">Maximum Increase</label>
                <app-number-input v-model="object.maximum_increase"></app-number-input>
            </b-col>
            <b-col lg="6" class="mb-4">
                <label class="label">Maximum Decrease</label>
                <app-number-input v-model="object.maximum_decrease "></app-number-input>
            </b-col>
            <b-col lg="6" class="mb-4">
                <label class="label" >Day Interval Forward</label>
                <app-number-input v-model="object.day_interval_forward"></app-number-input>
            </b-col>
            <b-col lg="6" class="mb-4">
                <label class="label">Day Interval Backward</label>
                <app-number-input v-model="object.day_interval_backward"></app-number-input>
            </b-col>
            <b-col lg="6" class="mb-4">
                <label class="label">Priority</label>
                <app-number-input v-model="object.priority"></app-number-input>
            </b-col>
            <b-col lg="6" class="mb-4">
                <label class="label">Value</label>
                <app-number-input v-model="object.value"></app-number-input>
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col lg="6">
                <app-button-submit :loading="loader"></app-button-submit>
            </b-col>
            <b-col lg="6" v-if="initialValue.hasOwnProperty('id') && initialValue.id !== null">
                <app-button-delete @click="confirmatioDialog =true" class="pull-right"></app-button-delete>
            </b-col>
        </b-row>
        <app-confirmation-dialog @confirm="deleteAction" :show="confirmatioDialog" @cancel="confirmatioDialog = false"></app-confirmation-dialog>
    </b-form>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {cloneDeep} from 'lodash'
import {
    getActions,
    getActionSetup,
    getBookingWindowSetup,
    getConditions,
    getRevenueBookingWindow, setActionSetup
} from "@/services/revenue_manager";
import {toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {EventBus} from "@/shared/EventBus";

export default {
    name: "RevenueManagerSetupActionForm",
    props: {
        action_setup_object:{
            type:Object
        },
        mode:{
            type:String,
            default:'company'
        },
        object_id:{
            type:Number,
        },
        type_action:{
            type:Number
        }
    },
    mixins:[getErrorMessage],
    data() {
        return {
            object: {
                action: null,
                booking_window: null,
                condition: null,
                maximum_increase:null,
                maximum_decrease:null,
                day_interval_forward:null,
                day_interval_backward:null,
                priority:null,
                value:null
            },
            conditions: [],
            actions: [],
            booking_windows: [],
            loader:false,
            confirmatioDialog:false,
            initialValue:{}
        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    components: {AppConfirmationDialog, AppButtonDelete, AppButtonSubmit, AppNumberInput, AppSelect},
    methods: {
        deleteAction(){
            let request = {
                action_setup:[]
            }
            if(this.mode ==='unit'){
                request = {...request,...{unit:this.object_id}}
            } else if(this.mode === 'property'){
                request = {...request,...{property:this.object_id}}
            }else {
                request = {...request,...{company:this.object_id}}
            }
            if(this.initialValue.hasOwnProperty('id') && this.initialValue.id !== null){
                this.object.id = this.initialValue.id
            }
            this.object.delete = true
            request.action_setup.push(this.object)
            setActionSetup(request).then(response=>{
                this.$emit('close')
                EventBus.$emit('ge_reload_action_table')
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })
            }, error=>{
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 3000
                })
            }).finally(()=>{
                this.confirmatioDialog = false
            })
        },

        save() {
            this.loader = true
            let request = {
                type:this.type_action,
                action_setup:[]
            }
            if(this.mode ==='unit'){
                request = {...request,...{unit:this.object_id}}
            } else if(this.mode === 'property'){
                request = {...request,...{property:this.object_id}}
            }else {
                request = {...request,...{company:this.object_id}}
            }
            if(this.initialValue.hasOwnProperty('id') && this.initialValue.id !== null){
                this.object.id = this.initialValue.id
            }
            request.action_setup.push(this.object)
            setActionSetup(request).then(response=>{
                this.$emit('close')
                EventBus.$emit('ge_reload_action_table')
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })
            }, error=>{
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 3000
                })
            }).finally(()=>{
                this.loader = false
            })

        },
        setData(actionSetup){
            this.initialValue = cloneDeep(actionSetup)
            this.object.action = actionSetup.action
            this.object.booking_window = actionSetup.booking_window
            this.object.condition = actionSetup.condition
            this.object.maximum_increase = actionSetup.maximum_increase
            this.object.maximum_decrease = actionSetup.maximum_decrease
            this.object.priority = actionSetup.priority
            this.object.value = actionSetup.value
            this.object.day_interval_forward = actionSetup.day_interval_forward
            this.object.day_interval_be= actionSetup.day_interval_forward
        }
    },
    watch:{
        action_setup_object:{
            handler(actionSetup){
                if(actionSetup){
                    this.setData(actionSetup)
                }
                getConditions({booking_window_setup_type:this.type_action}).then(response => {
                    this.conditions = response.data
                })
                getActions({booking_window_setup_type:this.type_action}).then(response => {
                    this.actions = response.data
                })
                getRevenueBookingWindow({company: this.company.id,type:this.type_action}).then(response => {
                    if (Array.isArray(response.data) && response.data.length > 0) {
                        return response.data.forEach(bookingWindow => {
                            if (bookingWindow.hasOwnProperty('child_booking_windows') && bookingWindow.child_booking_windows.length > 0) {

                                bookingWindow.child_booking_windows.forEach(child => {

                                    this.booking_windows.push({
                                        id: child.id,
                                        name: child.interval_from + '-' + child.interval_to,
                                        type:bookingWindow.booking_window_setup.id
                                    })
                                })
                            }

                        })
                    }

                })
            },immediate:true
        }
    },

}
</script>

<style scoped>

</style>
