import Vue from 'vue';

export const EventBus = new Vue();

// global event names
export const GE_OPEN_OWNER_WIZARD = 'gl_owner_wizard'
export const GE_OPEN_UNIT_WIZARD = 'gl_unit_wizard'
export const GE_OPEN_NEW_ACC_RES_ASIDE = 'gl_new_accomodation_res_aside'
export const GE_OPEN_AJPES_MONTHLY_REPORT_ASIDE = 'gl_ajpes_monthly_report'
export const GE_LOADER_SHOW = 'gl_loader_show'
export const GE_LOADER_HIDE = 'gl_loader_hide'
export const GE_TABLE_SEARCH = 'gl_table_search'
export const GE_TABLE_REFRESH_SEARCH = 'gl_table_refresh_search'
export const GE_TABLE_UPDATE_ROW = 'gl_table_update_row'
export const GE_TABLE_DESELECT_ALL = 'gl_table_deselect_all'

export const GE_ACTIVATE_DEMO_SUBSCRIPTION = 'gl_activate_demo_subscription'
export const GE_EXTEND_DEMO_SUBSCRIPTION = 'gl_extend_demo_subscription'
export const GE_SUBSCRIPTION_CONTRACT = 'gl_subscription_contract'
export const GE_SUBSCRIPTION_PAYMENT_TYPE = 'gl_subscription_payment_type'
export const GE_SUBSCRIPTION_INVOICE_CREATION_SETUP = 'gl_subscription_invoice_creation_setup'
export const GE_SUBSCRIPTION_CALCULATION_CREATE = 'gl_subscription_calculation_create'

// cm
export const GE_UPDATE_DATA_CHANNEL_MANAGER = 'gl_update_data-channel_manager'
export const GE_UPDATE_UNIT_CHANNEL_MANAGER = 'gl_update_unit_channel_manager'
export const GE_UPDATE_EXT_RATE_PLAN_CHANNEL_MANAGER = 'gl_update_ext_rate_plan_channel_manager'
export const GE_UPDATE_CM_SETUP_PARAMETERS = 'gl_update_cm_setup_parameters'

export const GE_OPEN_HOMEAWAY_4_2_DISTRIBUTION_CREATION_WIZARD = 'gl_homeaway_4_2_distribution_creation_wizard'

export const GE_ADDITIONAL_SETUP_WIZARD_FINISHED = 'gl_additional_setup_finished'
export const GE_SHOW_SUGGESTIONS_ASIDE = 'gl_additional_setup_finished'


// validation error handling
export const V_ERROR = 'validation_error'


// calendar global events
export const GE_CALENDAR_OPEN_RESERVATIONS = 'gl_calendar_open_reservations'
export const GE_CALENDAR_SCROLL_POSITION = 'gl_calendar_scroll_position'
export const GE_CALENDAR_SHOW_MORE = 'gl_calendar_show_more'
export const GE_CALENDAR_OPEN_AVAILABILITY = 'gl_calendar_open_aside_availability'
export const GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR = 'gl_calendar_open_aside_availability_sidebar'
export const GE_CALENDAR_LOAD_AVAILABILITY = 'gl_calendar_load_availability'
export const GE_CALENDAR_OPEN_ROOM_STATUS = 'gl_calendar_open_room_status'
export const GE_CALENDAR_LOAD_ROOM_STATUS = 'gl_calendar_load_room_status'
export const GE_CALENDAR_LOAD_LOS_AND_OCCUPANCY = 'gl_calendar_load_los_and_occupancy'
export const GE_CALENDAR_LOAD_PRICES = 'gl_calendar_load_prices'
export const GE_CALENDAR_OPEN_PRICES = 'gl_calendar_open_prices'
export const GE_CALENDAR_OPEN_RESTRICTIONS = 'gl_calendar_open_restrictions'
export const GE_CALENDAR_LOAD_RESTRICTIONS = 'gl_calendar_load_restrictions'
export const GE_CALENDAR_LOAD_ADD_MONTH = 'gl_calendar_load_add_month'
export const GE_CALENDAR_LOAD_BACK_MONTH = 'gl_calendar_load_back_month'
export const GE_UPDATE_QUICK_UNIT_LIST = 'ge_update_quick_unit_list'
export const GE_CALENDAR_REFRESH_DATA = 'ge_calendar_refresh_data'
export const GE_CALENDAR_OPEN_DISPLAY_SETTINGS_ASIDE = 'ge_calendar_open_display_settings_aside'
export const GE_CALENDAR_OPEN_GROUP_EDIT_ASIDE = 'ge_calendar_open_group_edit_aside'
export const GE_CALENDAR_OPEN_SYNC_ASIDE = 'ge_calendar_open_sync_aside'
export const GE_CALENDAR_OPEN_SYNC_LOADING = 'ge_calendar_open_sync_loading'
export const GE_CALENDAR_SELECT_UNIT = 'gl_calendar_select_unit'
export const GE_CALENDAR_SELECT_ALL_UNIT = 'gl_calendar_select_all_unit'
export const GE_CALENDAR_SINGLE_UNIT = 'gl_calendar_single_unit'


export const GE_CALENDAR_OPEN_PRICING = 'gl_calendar_open_pricing'

export const GE_CALENDAR_OPEN_AVAILABILITY_PROPERTY = 'gl_calendar_open_aside_availability_property'
export const GE_CALENDAR_UPDATE_AVAILABILITY_LIST = 'gl_calendar_update_availability_list'
export const GE_CALENDAR_ADVANCED_FILTER = 'gl_calendar_advanced_filter'
export const GE_CALENDAR_ADVANCED_FILTER_SEARCH = 'gl_calendar_advanced_filter_search'

export const GE_CALENDAR_OPEN_IMPORT_EXPORT_PRICE_LIST_ASIDE = 'ge_calendar_open_import_export_price_list_aside'

export const GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_DRAG_CURRENT_PROP = 'ge_calendar_cross_component_availability_mixin_handle_drag_current_prop'
export const GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_DRAG_PERIOD_START = 'ge_calendar_cross_component_availability_mixin_handle_drag_period_start'
export const GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_UNIT_ROW_INDEX = 'ge_calendar_cross_component_availability_mixin_handle_unit_row_index'

export const GE_SYSTEM_FINANCE_SETTINGS = 'gl_system_finance_settings'

// rate plan global events
export const GE_RATE_PLAN_NRF_SIDE_PANEL  = 'gl_rate_plan_nrf_side_panel'
export const GE_RATE_PLAN_COPY_SIDE_PANEL  = 'gl_rate_plan_copy_side_panel'
export const GE_RATE_PLAN_NEW_SIDE_PANEL = 'gl_rate_plan_new_side_panel'
export const GE_RATE_PLAN_COPY_RESTRICTIONS_WIZARD_SIDE_PANEL = 'gl_rate_plan_copy_restrictions_wizard_side_panel'

//revenue manager
export const GE_REVENUE_MANAGER_BOOKING_WINDOW_TYPE_NEW = 'gl_revenue_manager_booking_window_type_new'
export const GE_REVENUE_MANAGER_BOOKING_WINDOW_NEW = 'gl_revenue_manager_booking_window_new'

export const GE_REVENUE_MANAGER_ACTION_NEW = 'gl_revenue_manager_action_new'
export const GE_MAIN_NAVIGATION_AC_RELOAD = 'gl_main_navigation_ac_reload'
export const GE_REVENUE_MANAGER_FILTER_NEW = 'gl_revenue_manager_filter_new'

// cancellation policies
export const GE_CANCELLATION_POLICY_NEW = 'gl_cancellation_policy_new'
export const GE_CANCELLATION_POLICY_APPLY = 'gl_cancellation_policy_apply'
