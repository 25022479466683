<template>
    <div v-if="loaded && property" class="read-only">
        <div class="items" v-if="mode=== 'inbox'">
            <div class="item">
                <div class="item-label">{{ $t("STAY_FEE") }}:</div>
                <div v-if="threadObject.details.total_stay_price" class="item-content">
                    {{ threadObject.details.total_stay_price }}
                </div>
            </div>
            <div class="item">
                <div class="item-label">{{ $t("SERVICE_FEE") }}:</div>
                <div class="item-content" v-if="threadObject.details.total_host_fee">
                    {{ threadObject.details.total_host_fee }}
                </div>
            </div>
            <div class="item">
                <div class="item-label">{{ $t("TOTAL") }}:</div>
                <div class="item-content" v-if="threadObject.details.total_price">
                    {{ threadObject.details.total_price }}
                </div>
            </div>
        </div>
        <form-header v-if="mode === 'inbox'">{{ $t('GENERAL') }}</form-header>
        <div class="d-flex justify-content-start mb-2">
            <app-access-control tag="div"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{function: C_RESERVATION_ACCOMMODATION_INFORMATION_GUEST_BACKOFFICE, key: access_control_fetch_key}">
                <template v-slot:default="props">
                    <b-tooltip v-if="props.messages.length"
                               class="mb-5"
                               triggers="hover"
                               :title="props.messages[0].message"
                               :target="'backofficeLink_'+C_RESERVATION_ACCOMMODATION_INFORMATION_GUEST_BACKOFFICE">
                    </b-tooltip>
                    <a :href="backofficeLink" target="_blank"
                       :id="'backofficeLink_'+C_RESERVATION_ACCOMMODATION_INFORMATION_GUEST_BACKOFFICE">{{ $t('GUEST_BACKOFFICE') }}</a>

                    <b-tooltip v-if="props.messages.length"
                               class="mb-5"
                               triggers="hover"
                               :title="props.messages[0].message"
                               :target="'copyBackofficeLink_'+C_RESERVATION_ACCOMMODATION_INFORMATION_GUEST_BACKOFFICE">
                    </b-tooltip>
                    <app-button variant="link"
                                button_type="copy"
                                class="action_button ml-2"
                                :show_text="false"
                                :disabled="props.disabled"
                                :id="'copyBackofficeLink_'+C_RESERVATION_ACCOMMODATION_INFORMATION_GUEST_BACKOFFICE"
                                @click="copyToClipboard(backofficeLink)"
                    ></app-button>
                </template>
            </app-access-control>
            <app-access-control class="b-l-1 ml-2 pl-2" tag="div"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{function: C_RESERVATION_ACCOMMODATION_INFORMATION_GUEST_BACKOFFICE, key: access_control_fetch_key}">
                <template v-slot:default="props">
                    <b-tooltip v-if="props.messages.length"
                               class="mb-5"
                               triggers="hover"
                               :title="props.messages[0].message"
                               :target="'checkInInfoLink_'+C_RESERVATION_ACCOMMODATION_INFORMATION_GUEST_BACKOFFICE">
                    </b-tooltip>
                    <a :href="checkInInfoLink" target="_blank"
                       :id="'checkInInfoLink_'+C_RESERVATION_ACCOMMODATION_INFORMATION_GUEST_BACKOFFICE">{{ $t('CHECK_IN_INFO') }}</a>

                    <b-tooltip v-if="props.messages.length"
                               class="mb-5"
                               triggers="hover"
                               :title="props.messages[0].message"
                               :target="'copyCheckInInfoLink_'+C_RESERVATION_ACCOMMODATION_INFORMATION_GUEST_BACKOFFICE">
                    </b-tooltip>
                    <app-button variant="link"
                                button_type="copy"
                                class="action_button ml-2"
                                :show_text="false"
                                :disabled="props.disabled"
                                :id="'copyCheckInInfoLink_'+C_RESERVATION_ACCOMMODATION_INFORMATION_GUEST_BACKOFFICE"
                                @click="copyToClipboard(checkInInfoLink)"
                    ></app-button>
                </template>
            </app-access-control>

            <app-access-control class="b-l-1 ml-2 pl-2" tag="div"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{function: C_RESERVATION_ACCOMMODATION_INFORMATION_GUEST_BACKOFFICE, key: access_control_fetch_key}">
                <template v-slot:default="props" v-if="paymentLink">
                    <b-tooltip v-if="props.messages.length"
                               class="mb-5"
                               triggers="hover"
                               :title="props.messages[0].message"
                               :target="'paymentLink_'+C_RESERVATION_ACCOMMODATION_INFORMATION_GUEST_BACKOFFICE">
                    </b-tooltip>
                    <a :href="paymentLink" target="_blank"
                       :id="'paymentLink_'+C_RESERVATION_ACCOMMODATION_INFORMATION_GUEST_BACKOFFICE">{{ $t('PAYMENT_TYPE_PAYMENT_LINK') }}</a>

                    <b-tooltip v-if="props.messages.length"
                               class="mb-5"
                               triggers="hover"
                               :title="props.messages[0].message"
                               :target="'copyPaymentLink_'+C_RESERVATION_ACCOMMODATION_INFORMATION_GUEST_BACKOFFICE">
                    </b-tooltip>
                    <app-button variant="link"
                                button_type="copy"
                                class="action_button ml-2"
                                :show_text="false"
                                :disabled="props.disabled"
                                :id="'copyPaymentLink_'+C_RESERVATION_ACCOMMODATION_INFORMATION_GUEST_BACKOFFICE"
                                @click="copyToClipboard(paymentLink)"
                    ></app-button>
                </template>
            </app-access-control>

        </div>
        <div class="items">
            <div v-if="notes" class="item">
                <div class="item-label">{{ $t("NOTE") }}:</div>
                <div class="item-content">{{ notes[RES_NOTE_TYPE_NOTE].content }}</div>
            </div>

            <div v-if="notes" class="item">
                <div class="item-label">{{ $t("HOST_NOTE") }}:</div>
                <div class="item-content">{{ notes[RES_NOTE_TYPE_HOST].content }}</div>
            </div>
        </div>
        <div class="items">

            <div v-if="acm" class="item">
                <div class="item-label">{{ $t("ACCOUNT_MANAGER") }}:</div>
                <div class="item-content">{{ acm.name }}</div>
            </div>

            <div class="item" v-if="mode === 'reservation'">
                <div class="item-label">{{ $t("UNIT") }}:</div>
                <div class="item-content">{{ unit.name }}</div>
            </div>

            <div class="item">
                <div class="item-label">{{ $t("ADDRESS") }}:</div>
                <div class="item-content">{{ locationString }}</div>
            </div>

            <div class="item" v-if="unit && unit.hasOwnProperty('key_pickup') && unit.key_pickup.address">
                <div class="item-label">{{ $t("KEY_PICKUP") }}:</div>
                <div class="item-content">{{ keyLocationString }}</div>
            </div>

            <div class="item" v-if="unit && unit.hasOwnProperty('fpm')">
                <div class="item-label">{{ $t("FPM") }}:</div>
                <div class="item-content">
                    {{ unit.fpm ? $t('YES') : $t('NO') }}
                </div>
            </div>
        </div>

        <form-header>{{ $t('CONTACTS') }}</form-header>

        <div class="items">
            <div class="item" v-if="reservationContacts">
                <div class="item-label">{{ $t('RESERVATIONS') }} (email):</div>
                <div class="item-content">
                    <ul>
                        <li :key="contact.id+'_contact_email_'+index"
                            v-for="(contact,index) in reservationContacts['email']">
                            {{ contact.description }} {{ contact.description ? '/' : '' }} {{ contact.contact }}
                        </li>
                    </ul>
                </div>
            </div>

            <div class="item" v-if="reservationContacts">
                <div class="item-label">{{ $t('RESERVATIONS') }} (tel):</div>
                <div class="item-content">
                    <ul>
                        <li :key="contact.id+'_contact_phone_'+index"
                            v-for="(contact,index) in reservationContacts['phone']">
                            {{ contact.description }} {{ contact.description ? '/' : '' }} {{ contact.contact }}
                        </li>
                    </ul>
                </div>
            </div>

            <div class="item" v-if="checkInContacts">
                <div class="item-label">{{ $t('GUEST_CHECKIN') }} (email):</div>
                <div class="item-content">
                    <ul>
                        <li :key="contact.id+'check_in_email'" v-for="contact in checkInContacts['email']">
                            {{ contact.description }} {{ contact.description ? '/' : '' }} {{ contact.contact }}
                        </li>
                    </ul>
                </div>
            </div>

            <div class="item" v-if="checkInContacts">
                <div class="item-label">{{ $t('GUEST_CHECKIN') }} (tel):</div>
                <div class="item-content">
                    <ul>
                        <li :key="contact.id+'check_in_phone_'+index"
                            v-for="(contact,index) in checkInContacts['phone']">
                            {{ contact.description }} {{ contact.description ? '/' : '' }} {{ contact.contact }}
                        </li>
                    </ul>
                </div>
            </div>

            <div class="item">
                <div class="item-label">{{ $t("OWNER_CONFIRMED_USE_OF_CONTACT_ON_CHECKIN_DAY") }}:</div>
                <div class="item-content">
                    {{ ownerConfirmedUseOfContactOnCheckinDay ? $t('YES') : $t('NO') }}
                </div>
            </div>
        </div>

        <form-header>{{ $t('PROPERTY_POLICIES_AND_AMENITIES') }}</form-header>

        <div class="items">
            <div class="item">
                <div class="item-label">{{ $t('ALLOWED_IN_PROPERTY') }}:</div>
                <div class="item-content">
                    {{ generateStringFromObject({children: property.details.children, cot: property.details.cot}) }}
                </div>
            </div>

            <div class="item">
                <div class="item-label">{{ $t('PAYMENT_METHOD') }}:</div>
                <div class="item-content">
                    {{ generateStringFromObject(property.details.payment_options) }}
                </div>
            </div>

            <div class="item">
                <div class="item-label">{{ $t('BAGGAGE_DROPOFF') }}:</div>
                <div class="item-content">
                    {{ generateStringFromObject(property.details.baggage_dropoff) }}
                </div>
            </div>

            <div class="item">
                <div class="item-label">{{ $t('PETS') }}:</div>
                <div class="item-content">{{ property.details.pets.label }}</div>
            </div>

            <div class="item">
                <div class="item-label">{{ $t('PARKING') }}:</div>
                <div class="item-content">{{ property.details.parking.label }}</div>
            </div>

            <div class="item">
                <div class="item-label">{{ $t('WIFI') }}:</div>
                <div class="item-content">{{ property.details.wifi.label }}</div>
            </div>

            <div class="item">
                <div class="item-label">{{ $t('CHECKIN') }}:</div>
                <div class="item-content">{{ property.details.property_hours.checkin.from | time }} -
                    {{ property.details.property_hours.checkin.to | time }}
                </div>
            </div>

            <div class="item">
                <div class="item-label">{{ $t('CHECKOUT') }}:</div>
                <div class="item-content">{{ property.details.property_hours.checkout.from | time }} -
                    {{ property.details.property_hours.checkout.to | time }}
                </div>
            </div>

            <div class="item">
                <div class="item-label">{{ $t('OTHER') }}:</div>
                <div class="item-content">{{ property.details.property_description }}</div>
            </div>
        </div>

        <form-header v-if="unit && unit.hasOwnProperty('details')">{{ $t('UNIT_DETAILS') }}</form-header>

        <div class="items" v-if="unit && unit.hasOwnProperty('details')">
            <div class="item">
                <div class="item-label">{{ $t('TYPE') }}:</div>
                <div class="item-content">{{ unit.details.unit_type.label }}</div>
            </div>

            <div class="item">
                <div class="item-label">{{ $t('SIZE') }}:</div>
                <div class="item-content">{{ unit.details.size }} m<sup>2</sup></div>
            </div>

            <div class="item">
                <div class="item-label">{{ $t('NUMBER_OF_BEDROOMS') }}:</div>
                <div class="item-content">{{ unit.details.number_bedrooms }}</div>
            </div>

            <div class="item">
                <div class="item-label">{{ $t('FLOOR_LEVEL') }}:</div>
                <div class="item-content">{{ unit.details.floor }}</div>
            </div>

            <div class="item">
                <div class="item-label">{{ $t('MAX_GUESTS') }}:</div>
                <div class="item-content">
                    {{ maxGuestsString }}
                </div>
            </div>
        </div>
        <template v-if="reservationId">
            <form-header>{{ $t('CANCELLATION_POLICY') }}</form-header>
            <div class="items">
                <div class="item">
                    <div class="item-label">{{ $t('NAME') }}:</div>
                    <div class="item-content">{{ cancellationPolicy.label }}</div>
                </div>
                <div class="item">
                    <div class="item-label">{{ $t('FOC_RESERVATION_CANCELATION') }}:</div>
                    <div class="item-content">{{ cancellationPolicy.foc ? $t('YES') : $t('NO') }}</div>
                </div>

                <div class="item">
                    <div class="item-label">{{ $t('CANCELLATION_CONDITIONS') }}:</div>
                    <div class="item-content">
                        <ul>
                            <li :key="indexCondition+'_cancellation_policy'"
                                v-for="(condition, indexCondition) in cancellationPolicy.cancellation_conditions">
                                {{ condition }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>

    </div>
</template>

<script>
import {
    fetchAccommodationReservationInfo,
    fetchAccommodationReservationNotes,
    getInventoryInfo
} from "@/services/accommodation_reservation";
import {RES_NOTE_TYPE_HOST, RES_NOTE_TYPE_NOTE} from "@/shared/constants";
import FormHeader from "@/components/app/form/FormHeader";
import AppButton from "@/components/app/AppButton/AppButton";
import AppAccessControl from "@/components/app/AppAccessControl";
import {AC_ACCOMMODATION_RESERVATION_INFORMATION} from "@/mixins/AccessControl/AccessControlEnumeration";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {C_RESERVATION_ACCOMMODATION_INFORMATION_GUEST_BACKOFFICE} from "@/shared/component_permission";
import {toast} from "@/shared/plugins/toastr";

const EVENT_RES = 10
const EVENT_CHECKIN = 11

export default {
    name: "InformationModule",
    components: {AppAccessControl, FormHeader, AppButton},
    mixins: [AccessControlComponent],
    props: {
        reservationId: {
            type: Number,
        },
        unitId: {
            type: Number
        },
        propertyId: {
            type: Number
        },
        threadObject: {
            type: Object
        },
        mode: {
            type: String,
            default: 'reservation',
            validator: value => {
                return (['reservation', 'inbox']).includes(value)
            }
        },
    },
    data() {
        return {
            RES_NOTE_TYPE_NOTE,
            RES_NOTE_TYPE_HOST,
            data: null,
            property: null,
            unit: null,
            cancellationPolicy: null,
            acm: null,
            loaded: false,
            backofficeLink: null,
            checkInInfoLink: null,
            paymentLink: null,
            notes: null,
            contactCategories: [],
            access_control_fetch_key: AC_ACCOMMODATION_RESERVATION_INFORMATION,
            C_RESERVATION_ACCOMMODATION_INFORMATION_GUEST_BACKOFFICE,
            access_control_components: 2,
            ownerConfirmedUseOfContactOnCheckinDay: null,
        }
    },
    computed: {
        locationString() {
            return this.property ? `${this.property.location.address}, ${this.property.location.city.name}, ${this.property.location.country.name} (${this.property.location.latitude || ''}; ${this.property.location.longitude || ''})` : null
        },
        keyLocationString() {
            return this.unit && this.unit.key_pickup.address
                ? `${this.unit.key_pickup.address}, (${this.unit.key_pickup.latitude || ''}; ${this.unit.key_pickup.longitude || ''})`
                : null
        },
        maxGuestsString() {
            return this.unit && this.unit.details
                ? `${this.unit.details.max_guests} (${this.unit.details.max_adults} ${this.$t('ADULTS')}, ${this.unit.details.max_children} ${this.$t('CHILDREN')}, ${this.unit.details.max_babies} ${this.$t('BABIES')})`.toLowerCase()
                : ''
        },
        reservationContacts() {
            return this.contactCategories.find(category => category.event_id === EVENT_RES)
        },
        checkInContacts() {
            return this.contactCategories.find(category => category.event_id === EVENT_CHECKIN)
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods: {
        generateStringFromObject(object) {
            const filtered = Object.entries(object).filter(([key, value]) => value)
            return filtered.reduce((acc, [key, value], index) => {
                acc += value ? this.$t(key.toUpperCase()) : ''
                if (index !== filtered.length - 1) {
                    acc += ', '
                }
                return acc
            }, '')
        },
        copyToClipboard(link){
            if (window.clipboardData && window.clipboardData.setData) {
                return this.clipboardData.setData("Text", link)
            }
            else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
                let input = document.createElement("input")
                document.body.appendChild(input)
                input.visibility = 'hidden'
                input.value = link
                input.style.position = "fixed"
                input.focus()
                input.select()
                try {
                    document.execCommand("copy")  // Security exception may be thrown by some browsers.
                    toast({
                        message: this.$t('NOTIFICATIONS.COPY_TO_CLIPBOARD_SUCCESS.MESSAGE'),
                        type: 'success',
                        timeout: 3000
                    })
                    return true
                }
                catch (ex) {
                    console.warn("Copy to clipboard failed.", ex)
                    toast({
                        message: this.$t('NOTIFICATIONS.COPY_TO_CLIPBOARD_FAIL.MESSAGE'),
                        type: 'success',
                        timeout: 3000
                    })
                    return false
                }
                finally {
                    document.body.removeChild(input)
                }
            }
        },
    },
    created() {
        if (this.reservationId) {
            fetchAccommodationReservationNotes(this.reservationId).then(response => {
                this.notes = response.data
                    .filter(note => note.id === RES_NOTE_TYPE_HOST || note.id === RES_NOTE_TYPE_NOTE)
                    .reduce((acc, note) => {
                        acc[note.id] = note
                        return acc
                    }, {})
            })

            fetchAccommodationReservationInfo(this.reservationId).then(response => {
                this.property = response.data.property
                this.unit = response.data.unit
                this.acm = response.data.account_manager
                this.backofficeLink = response.data.backoffice_link
                this.cancellationPolicy = response.data.cancellation_policy
                this.contactCategories = response.data.contact_categories
                this.ownerConfirmedUseOfContactOnCheckinDay = response.data.owner_confirmed_use_of_contact_on_checkin_day
                this.checkInInfoLink = response.data.checkin_info_pdf_link
                this.paymentLink = response.data.payment_link
            }, error => {

            }).finally(() => {
                this.loaded = true
            })
        }
        if (this.unitId) {
            getInventoryInfo({unit: this.unitId}).then(response => {
                this.property = response.data.property
                this.unit = response.data.unit
                this.acm = response.data.account_manager
                this.contactCategories = response.data.contact_categories
                this.ownerConfirmedUseOfContactOnCheckinDay = response.data.owner_confirmed_use_of_contact_on_checkin_day
            }).finally(() => {
                this.loaded = true
            })
        }

        if (this.propertyId) {
            getInventoryInfo({property: this.propertyId}).then(response => {
                this.property = response.data.property
                this.unit = response.data.unit
                this.acm = response.data.account_manager
                this.contactCategories = response.data.contact_categories || []
                this.ownerConfirmedUseOfContactOnCheckinDay = response.data.owner_confirmed_use_of_contact_on_checkin_day
            }).finally(() => {
                this.loaded = true
            })
        }

        this.access_control_general_context = {
            company: this.company.id,
            reservation: this.reservationId,
        }
    }
}
</script>

<style scoped>
</style>
