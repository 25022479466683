<template>
    <div>
        <template>
            <b-row class="mb-4">
                <user-access-filter @search="search"
                                    @reset="reset"
                                    :loading="loading">
                </user-access-filter>
            </b-row>

            <b-row>
                <user-access-table :filter="filter"
                                   :userUnit="userUnit"
                                   @isLoading="isLoading"
                                   @destroy="destroy"
                                   @getUserData="getUserData">
                </user-access-table>
            </b-row>



        </template>
        <template>
            <b-row>
                <user-access-existing-user-form :userData="userData"
                                                @addExistingUser="addExistingUser">
                </user-access-existing-user-form>
            </b-row>
        </template>
    </div>
</template>

<script>
    import UserAccessFilter from "@/components/property/UserAccess/UserAccessFilter";
    import UserAccessTable from "@/components/property/UserAccess/UserAccessTable";
    import UserAccessExistingUserForm from "@/components/property/UserAccess/UserAccessExistingUserForm";
    import {getExistingUserAccessList} from "@/services/property";

    export default {
        name: "ExistingUserAccessForm",
        components: {
            UserAccessFilter,
            UserAccessTable,
            UserAccessExistingUserForm
        },
        data() {
            return {
                filter: {},
                userData: null,
                userUnit: null,
                loading: false
            }
        },
        props: {
            user: {
                type: Object,
                default: null
            }
        },
        watch: {
            user: {
                handler(value) {
                    if (value) {
                        this.getUserUnitData(value)
                    }
                },
                immediate: true
            }
        },
        methods: {
            search(value) {
                this.filter = value
                this.loading = true
            },
            reset() {
                this.userData = null
                this.filter = {}
            },
            isLoading(value) {
                this.loading = value
            },
            getUserData(userData) {
                this.userData = userData
            },
            addExistingUser(data) {
                this.$emit('addExistingUser', data)
                this.userData = null
            },
            getUserUnitData(user) {
                getExistingUserAccessList(this.$route.params.id, {
                    id: user.id,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    username: user.username
                }).then(res => {
                    this.userUnit = res.data.items
                })
            },
            destroy(data) {
                this.$emit('destroy', data)
            }
        }
    }
</script>

<style scoped>

</style>
