<template>
    <div>
        <div v-if="loading" class="d-flex justify-content-center">
            <b-spinner variant="primary"></b-spinner>
        </div>
        <div v-else>
                <property-setup-distribution-form  :distribution="distributionObject" :unitList="units" :setupParameter="setupParameterByDistribution(distributionObject.id)">
                </property-setup-distribution-form>
        </div>

    </div>

</template>
<script>
    import SetupForm from "@/components/channel_manager/setup/SetupForm";
    import {getUnitList} from "@/services/unit";
    import {getSetupParameterList} from "@/services/channel_manager";
    import PropertySetupDistributionForm from "@/components/channel_manager/setup/PropertySetupDistributionForm";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {AIRBNB} from "@/shared/constants";
    import _filter from 'lodash'
    export default {
        name: "Index",
        components: {
            AppButtonSubmit,
            PropertySetupDistributionForm,
            SetupForm
        },
        data() {
            return {
                units: [],
                level: 'property',
                distribution_id: this.$route.params.distribution_id,
                property: this.$route.params.id,
                setupParameterList: [],
                loading: true
            }
        },
        computed:{
            distributionObject() {
                return this.$store.getters['channel_manager/getDistributionById'](this.distribution_id)
            },
        },
        methods:{
            setupParameterByDistribution(distribution_id){
                if(distribution_id === AIRBNB){
                    let setup = this.setupParameterList.find(item=>item.distribution === distribution_id)
                    let units=[]
                    let options = []
                    if(setup.hasOwnProperty('parameters')){
                        setup.parameters.forEach((parameter, index) => {
                            if(parameter.parameter_id === 13){
                                options = parameter.options
                                if(typeof parameter.values.unit !=='undefined'){
                                    units.push({
                                        parameter_id:parameter.parameter_id,
                                        id:parameter.values.unit.id,
                                        value:parameter.values.unit.value
                                    })
                                }
                            }
                        })
                    }
                    let responseObject = {
                        parameter_id:13,
                        options:options,
                        values:{
                            unit:units
                        }
                    }
                    return    responseObject
                } else {
                    return this.setupParameterList.find(el => el.distribution === distribution_id)
                }

            },
            save(){

            },
            getSetupParameterList(distribution_id) {

                getSetupParameterList({
                    level: this.level,
                    distribution: [distribution_id],
                    property: this.property,
                    unit: this.units.map(item => item.id)
                }).then(res => {
                    this.setupParameterList = res.data
                }).finally(()=> {
                    this.loading = false
                })
            }
        },
        watch: {
            '$route.params.distribution_id': {
              async  handler(val) {
                  this.loading = true
                    this.distribution_id = val
                    getUnitList({property_id: this.$route.params.id, limit: 1000}).then(response =>{
                        this.units = response.data.items.map(item => {
                            return {id: item.id, name: item.name}
                        })
                      this.getSetupParameterList(val)

                    })
                },
                immediate:true
            }
        },
    }
</script>

<style scoped>

</style>
