<template>
    <div class="position-relative" v-if="galleryListComputed && hasPermissionView" >
        <b-row>
            <app-access-control tag="b-col" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{function:c_permission_e, key: ac_key}">
                <template v-slot:default="props">
                    <gallery-upload :disabled="props.disabled" @uploadSuccess="fetchList" :ObjectId="ObjectId"
                                     :DocumentTypeId="DocumentTypeId" :document-type="DocumentType"></gallery-upload>
                </template>
            </app-access-control>
        </b-row>
        <gallery-copy
            @saveCopyItems="duplicateDocuments"
            v-if="CopyComponent"
            :list="selectedDocumentList"
            :state="copy_sidebar_state"
            @closeAside="copy_sidebar_state=false"
            :object-list="CopyObjects"></gallery-copy>

        <app-aside v-if="taggable && imageTaggingEnabled" :widths="['col-lg-3', 'col-sm-12', '30%']" v-model="tag_sidebar_state">
            <template slot="header">{{$t("TAG_IMAGES")}}
                <div class="header-info" v-if="selectedDocumentList.length > 1">{{$t("ITEMS_SELECTED", {value: selectedDocumentList.length})}}</div>
                <div class="header-info" v-else-if="selectedDocumentList.length === 1">{{$t("ITEM_SELECTED")}}</div>
            </template>
            <tag-form :tag_type="tag_type" :object_type="object_type" :entity="selectedDocumentList"
                      @tagSaved="closeTagAside">
            </tag-form>
        </app-aside>

        <gallery :images="galleryList" :index="index" @close="index = null">
            <template #close>
                <i class="fa fa-close" style="color: white"></i>
            </template>
        </gallery>
        <b-form v-if="galleryListComputed.length > 0" @submit.prevent="submit">
            <b-row class="mb-3 mt-2">
                <b-col>
                <b-form-checkbox @change="selectAll"
                            class="document-checkbox" v-model="checkedAll">
                    {{this.selectedDocumentList.length ===
                    this.galleryListFull.length ? $t("DESELECT") : $t("SELECT_ALL")}}
                </b-form-checkbox>
                </b-col>
            </b-row>
            <div v-if="galleryAc">
                <draggable v-model="galleryListComputed" @change="changeOrder" class="row" >
                    <GalleryItem ref="galleryItem"
                                 :delete-disabled="galleryDis"
                                 :key="img.id" v-for="(img, imgIndex) in galleryListComputed"
                                 @imageClicked="index=imgIndex"
                                 @textfieldEdit="updateTextfield($event, img.id)"
                                 @removeDocument="removeItemFromList(img.id)"
                                 @copyCheck="editCheckedList(img.id, $event)"
                                 @removeTag="fetchList"
                                 :textAreaShow="!imageTaggingEnabled"
                                 :showCheckbox="CopyComponent"
                                 :checkboxSelectedList="selectedDocumentList"
                                 :disabled="actionState || !hasPermissionEdit"
                                 :DocumentTypeId="DocumentTypeId"
                                 :isPropertyGallery="setIsPropGallery(img.id)"
                                 :item="img">
                    </GalleryItem>
                </draggable>
            </div>

            <b-row class="mb-3 ml-1" v-if="!imageTaggingEnabled">
                <b-col>
                    <save-button :access_control_context="{function:C_PROPERTY_GALLERY_DESCRIPTION,key:ac_key}"
                                 @AccessControlEvent="addToAccessControlCounter()" :disabled="!textEdited"
                                 :c_permission="c_permission_e"></save-button>
                </b-col>
            </b-row>

            <div class="mt-3 availability_calendar-selectedContainer animated fadeIn" v-if="selectedDocumentList.length">
                <div>
                    <h4 v-if="selectedDocumentList.length > 1">{{$t("ITEMS_SELECTED", {value: selectedDocumentList.length})}}</h4>
                    <h4 v-else-if="selectedDocumentList.length === 1">{{$t("ITEM_SELECTED")}}</h4>
                    <app-button @click="deselect"  class="action_button" variant="link">{{$t("DESELECT")}}
                    </app-button>
                </div>
                <div class="flex-grow-1 d-flex justify-content-end">
                    <app-access-control tag="span"  :access_control_context="{function:c_permission_e,key:ac_key}"
                                        @AccessControlEvent="addToAccessControlCounter()">
                        <template v-slot:default="props">
                            <b-tooltip v-if="disabledActionBarItem || props.disabled" target="deleteButton" placement="top"
                                       :title="props.messages[0].message"></b-tooltip>
                            <app-button id="deleteButton" :disabled="disabledActionBarItem || props.disabled" @click="triggerDeleteDialog" class="pull-right mr-3" v-if="hasPermissionEdit">
                                {{ $t("DELETE") }}</app-button>
                        </template>
                    </app-access-control>
                    <app-button :disabled="selectedDocumentList.length === 0" @click="downloadSelected" class="pull-right mr-3">
                        {{ $t("DOWNLOAD") }}</app-button>
                    <app-access-control tag="span"  :access_control_context="{function:c_permission_e,key:ac_key}"
                                        @AccessControlEvent="addToAccessControlCounter()">
                        <template v-slot:default="props">
                            <b-tooltip v-if="disabledActionBarItem || props.disabled" target="tagButton" placement="top"
                                       :title="props.messages[0].message"></b-tooltip>
                    <app-button id="tagButton" :disabled="disabledActionBarItem || props.disabled" class="pull-right mr-3" @click="tag_sidebar_state=true"
                                v-if="taggable && imageTaggingEnabled && hasPermissionEdit">
                        {{ $t("TAG") }}</app-button>
                        </template>
                    </app-access-control>
                    <app-access-control tag="span"  :access_control_context="{function:c_permission_e,key:ac_key}"
                                        @AccessControlEvent="addToAccessControlCounter()">
                        <template v-slot:default="props">
                            <b-tooltip v-if="disabledActionBarItem || props.disabled" target="untagButton" placement="top"
                                       :title="props.messages[0].message"></b-tooltip>
                    <app-button id="untagButton" :disabled="disabledActionBarItem || props.disabled" class="pull-right mr-3" @click="triggerDeleteTag"
                                v-if="taggable && imageTaggingEnabled && hasPermissionEdit && removeTagEnabled">
                        {{ $t("REMOVE_TAG") }}</app-button>
                        </template>
                    </app-access-control>
                    <app-access-control tag="span"  :access_control_context="{function:c_permission_e,key:ac_key}"
                                        @AccessControlEvent="addToAccessControlCounter()">
                        <template v-slot:default="props" v-if="CopyObjects.length > 0">
                            <b-tooltip v-if="disabledActionBarItem || props.disabled" target="copyButton" placement="top"
                                       :title="props.messages[0].message"></b-tooltip>
                            <app-button id="copyButton" @click="copy_sidebar_state=true" class="pull-right mr-3" :disabled="disabledActionBarItem || props.disabled"
                                    v-if="CopyComponent && hasPermissionEdit">
                            {{ $t("COPY") }}</app-button>
                        </template>
                    </app-access-control>
                    <app-access-control tag="span"  :access_control_context="{function:c_permission_button,key:ac_key}"
                                        @AccessControlEvent="addToAccessControlCounter()">
                        <template v-slot:default="props" v-if="hasPermission">
                            <b-tooltip v-if="disabledActionBarItem || props.disabled" target="propertyButton" placement="top"
                                       :title="props.messages[0].message"></b-tooltip>
                            <app-button id="propertyButton" :disabled="selectedDocumentList.length === 0 || props.disabled" @click="addToPropertyGallery" class="pull-right mr-3">
                                {{ $t("PROPERTY_GALLERY") }}</app-button>
                        </template>
                    </app-access-control>
                </div>
            </div>
            <app-aside v-model="propertyGalleryAside" :widths="['col-lg-12','col-sm-12', '100%']" full-width>
                <template slot="header">
                    <div class="header-caption">{{$t('PROPERTY_GALLERY')}}</div>
                    <h2 class="property-wrapper-padding">{{ propertyInfo.name }}</h2>
                </template>
                <template v-slot="props">
                    <tagged-gallery-property
                        @closeAsideModal="propertyGalleryAside=false"
                        :ac_key="ac_key_a"
                        :c_permission_e="c_permission_e"
                        :c_permission_v="c_permission_v"
                    ></tagged-gallery-property>
                </template>
            </app-aside>
            <app-confirmation-dialog
                :delete_title="true"
                :show="deleteDialogState"
                @confirm="deleteSelected"
                @cancel="deleteDialogState=false">
            </app-confirmation-dialog>
            <app-confirmation-dialog
                v-if="imageTaggingEnabled"
                :show="deleteCustomTagConfirmDialog"
                :title="$t('REMOVE_TAG_CONFIRMATION')"
                :message="$t('REMOVE_TAG/S_MSG')"
                @confirm="deleteTag"
                @cancel="deleteCustomTagConfirmDialog=false">
            </app-confirmation-dialog>
        </b-form>
    </div>
</template>

<script>
    import VueGallery from 'vue-gallery';
    import GalleryCopy from '@/components/app/gallery/GalleryCopy'
    import GalleryUpload from '@/components/app/gallery/GalleryUpload'
    import SaveButton from '@/components/app/AppButton/AppButtonSubmit'
    import DownloadButton from '@/components/app/AppDownload'
    import AppButton from '@/components/app/AppButton/AppButton'
    import GalleryItem from '@/components/app/gallery/GalleryItem'
    import TaggedGalleryProperty from '@/components/app/gallery/TaggedGalleryProperty'
    import {
        getDocumentList,
        deleteDocument,
        updateDocumentList,
        downloadListOfDocuments,
        copyDocuments,
        deleteDocumentList
    } from '@/services/document/index'
    import {createEntityTagsList, removeTagsForEntityList} from '@/services/tag/index'
    import {getProperty} from '@/services/property/index'
    import {fetchCompany} from "@/services/company";
    import {notifySuccess, toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import draggable from 'vuedraggable'
    import AppAside from '@/components/app/form/AppAside'
    import TagForm from '@/components/app/tag/TagForm'
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {
        C_PROPERTY_GALLERY_DESCRIPTION,
        C_PROPERTY_NAVIGATION_RATE_MANAGER_V,
        C_PROPERTY_GALLERY_E,
    } from "@/shared/component_permission";
    import {
        OBJECT_TYPE_DOCUMENT,
        TAG_PROPERTY_GALLERY,
    } from "@/shared/constants";
    import {AC_PROPERTY_GALLERY} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {fetchAccessControlData} from "@/services/access";
    import {EventBus, GE_LOADER_HIDE, GE_LOADER_SHOW} from "@/shared/EventBus";


    export default {
        name: "Gallery",

        components: {
            "AppConfirmationDialog": () => import("@/components/app/form/AppConfirmationDialog"),
            AppAccessControl,
            'gallery': VueGallery,
            GalleryItem,
            TaggedGalleryProperty,
            GalleryUpload,
            draggable,
            SaveButton,
            DownloadButton,
            GalleryCopy,
            AppButton,
            AppAside,
            TagForm,
        },
        mixins: [getErrorMessage,AccessControlComponent],
        props: {
            item: {},
            entity: {
                type: Array
            },
            ObjectId: {
                type: Number
            },
            DocumentTypeId: {
                type: Number
            },
            DocumentType: {
                type: String
            },
            CopyComponent: {
                type: Boolean,
                default: false
            },
            CopyObjects: {
                type: Array,
                default: () => {
                    return []
                }
            },
            c_permission_v: {
                default: null,
                type: Number
            },
            c_permission_e: {
                default: null,
                type: Number
            },
            tag_type: {
                type: Number,
                default: null
            },
            tag_permission_v: {
                type: Number,
                default: null
            },
            object_type: {
                type: Number,
                default: null
            },
            objectName: {
                type: String,
                default: "documents"
            },
            ac_key:{
                type:String
            },
            ac_key_a:{
                type:String
            },
            c_permission_active: {
                default: null,
                type: Number
            },
            c_permission_button:{
                default: null,
                type: Number
            },
            checkboxSelectedList: {
                type: Array,
            },
        },
        data() {
            return {
                deleteDialogState: false,
                tag: null,
                index: null,
                galleryListFull: [],
                selectedDocumentList: [],
                propertyInfo: [],
                companyInfo: [],
                copy_sidebar_state: false,
                tag_sidebar_state: false,
                actionState: false,
                textEdited: false,
                propertyGalleryAside: false,
                isPropertyGallery: false,
                taggedImages: [],
                hideElement: false,
                checkedAll: false,
                C_PROPERTY_GALLERY_DESCRIPTION,
                C_PROPERTY_NAVIGATION_RATE_MANAGER_V,
                access_control_components: 1,
                AC_PROPERTY_GALLERY,
                C_PROPERTY_GALLERY_E,
                galleryAc: false,
                galleryDis: false,
                loader: false,
                deleteCustomTagConfirmDialog: false,
            }
        },
        computed: {
            removeTagEnabled(){
                let check = false
                this.galleryListFull.forEach(item => {
                    if (item.hasOwnProperty('id') && this.selectedDocumentList.length && this.selectedDocumentList.includes(item.id)) {
                        if (item.hasOwnProperty('tag') && item.tag.id !== null) {
                            check = true
                        }
                    }
                })
                return check
            },
            disabledActionBarItem() {
                return this.selectedDocumentList.length === 0
            },
            galleryList() {
                let arrTemp = []
                this.galleryListFull.forEach(function (arrayItem) {
                    arrTemp.push(arrayItem.route)
                });
                return arrTemp
            },
            galleryListComputed: {
                get() {
                    return this.galleryListFull
                },
                set(value) {
                    this.galleryListFull = value
                }
            },
            hasPermissionView() {
                if (this.c_permission_v === null) {
                    return true
                }
                return this.$store.getters['user/getPermission'](this.c_permission_v)
            },
            hasPermissionEdit() {
                if (this.c_permission_e === null) {
                    return true
                }
                return this.checkPermission(this.c_permission_e)
            },
            taggable() {
                return this.object_type > 0 && this.tag_type > 0 && (this.tag_permission_v === null || this.checkPermission(this.tag_permission_v))
            },
            taggedImageIds() {
                return this.taggedImages ? this.taggedImages.map(el => el.id) : []
            },
            hasPermission(){
                if (this.c_permission_button === null) {
                    return true
                }
                return this.$store.getters['user/getPermission'](this.c_permission_button)
            },
            imageTaggingEnabled(){
                if (this.companyInfo && this.companyInfo.hasOwnProperty('gallery_tagging_enabled')) {
                    return this.companyInfo.gallery_tagging_enabled
                }
                return false
            }
        },
        methods: {
            triggerDeleteTag() {
                this.deleteCustomTagConfirmDialog=true
            },
            deleteTag() {
                removeTagsForEntityList(this.object_type, this.tag_type, this.selectedDocumentList).then(() => {
                    notifySuccess()
                    EventBus.$emit(GE_LOADER_SHOW)
                    this.fetchList()
                    this.deselect()
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.deleteCustomTagConfirmDialog = false
                    EventBus.$emit(GE_LOADER_HIDE)
                })
            },
            triggerDeleteDialog() {
                if (!this.deleteDisabled) {
                    this.deleteDialogState=true
                }
            },
            deleteSelected() {
                this.deleteDialogState= false,
                this.actionState = true
                deleteDocumentList(this.ObjectId, this.DocumentTypeId, this.selectedDocumentList).then(response => {
                    this.galleryListFull = response.data
                    this.selectedDocumentList = []
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.actionState = false
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                    this.actionState = false
                })
            },
            removeItemFromList(id) {
                deleteDocument(this.ObjectId, this.DocumentTypeId, id).then((response) => {
                        this.galleryListFull = response.data
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }
                )
            },
            updateList(data) {
                this.galleryListFull = data
            },
            setIsPropGallery(imgId){
                return this.taggedImageIds.includes(imgId)
            },
            updateTextfield(value, img_id) {
                this.galleryListFull.forEach((item, index) => {
                    if (item.id === img_id) {
                        this.galleryListFull[index].description = value
                        this.textEdited = true
                    }
                })
            },
            downloadSelected() {
                this.actionState = true
                downloadListOfDocuments(this.ObjectId, this.DocumentTypeId, this.selectedDocumentList, this.objectName).then(() => {
                    this.actionState = false
                    this.selectedDocumentList = []
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                    this.actionState = false
                })
            },
            getDataForAC(){
                return {
                    key: AC_PROPERTY_GALLERY,
                    context: {company: this.$store.getters['user/getCompany'], property: this.$route.params.id},
                    data: [
                        {uid: `gallery_items`, function: this.c_permission_e},
                    ]
                }
            },
            fetchList() {
                this.taggedImages = []
                getDocumentList(this.ObjectId, this.DocumentTypeId).then((response) => {
                    if(response.data.length < 1){
                        this.access_control_components = 0
                    }
                    this.galleryListFull = response.data
                    this.galleryListFull.forEach(element => {
                        if (element.hasOwnProperty('property_gallery') && element.property_gallery) {
                            this.taggedImages.push({
                                "id": element.id
                            })
                        }
                    })
                })
            },
            changeOrder(data) {
                this.submit(false)
            },
            submit(notifySuccess = true) {
                this.galleryListFull.forEach((item, index) => {
                    this.galleryListFull[index].order_number = index + 1
                })
                updateDocumentList(this.ObjectId, this.DocumentTypeId, {list: this.galleryListFull}).then((response) => {
                        this.galleryListFull = response.data

                        if (notifySuccess) {
                            toast({
                                'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                                'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                                'type': 'success',
                                'timeout': 3000
                            })
                        }
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    })
            },
            editCheckedList(docId, event) {
                if (!this.selectedDocumentList.includes(docId) && event) {
                    this.selectedDocumentList.push(docId)
                    return
                }
                if (this.selectedDocumentList.includes(docId) && !event) {
                    let index = this.selectedDocumentList.indexOf(docId)
                    if (index !== -1) this.selectedDocumentList.splice(index, 1)
                }
            },
            selectAll() {
                if (this.selectedDocumentList.length === this.galleryListFull.length) {
                    this.selectedDocumentList = []
                } else {
                    this.galleryListFull.forEach(element => {
                        if (!this.selectedDocumentList.includes(element.id)) {
                            this.selectedDocumentList.push(element.id)
                        }
                    })
                }
            },
            duplicateDocuments(request) {
                copyDocuments(this.ObjectId, this.DocumentTypeId, request).then((response) => {
                    this.galleryListFull = response.data
                    this.selectedDocumentList = []
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                })
            },
            deselect(){
                this.selectedDocumentList = []
            },
            addToPropertyGallery(){
                createEntityTagsList(OBJECT_TYPE_DOCUMENT, TAG_PROPERTY_GALLERY, this.selectedDocumentList).then(() => {
                    this.propertyGalleryAside = true
                    this.selectedDocumentList.forEach(element => {
                        this.taggedImages.push({
                            'id': element
                        })
                    })
                })
            },
            openPropertyGallery(){
                this.propertyGalleryAside = true
            },
            closeTagAside(){
                this.tag_sidebar_state = false
                this.deselect()
                this.fetchList()
                EventBus.$emit(GE_LOADER_SHOW)
                setTimeout(() => {
                    EventBus.$emit(GE_LOADER_HIDE)
                }, 1000)
            },
        },
        watch: {
            ObjectId: {
                handler() {
                    this.fetchList()
                },
                immediate: true
            },
            propertyGalleryAside:{
                handler(){
                    if (this.propertyGalleryAside === false){
                        this.fetchList()
                        this.deselect()
                        this.checkedAll = false
                        EventBus.$emit(GE_LOADER_SHOW)
                        setTimeout(() => {
                            EventBus.$emit(GE_LOADER_HIDE)
                        }, 1000)
                    }
                }
            },
            selectedDocumentList:{
                handler(){
                    if (this.selectedDocumentList.length) {
                        this.access_control_components = 5
                    } else {
                        this.access_control_components = 1
                    }
                    if (this.selectedDocumentList.length && this.selectedDocumentList.length === this.galleryListFull.length){
                        this.checkedAll = true
                    } else {
                        this.checkedAll = false
                    }
                }
            }
        },
        created(){
            this.access_control_general_context = {
                property: this.$route.params.id,
                company: this.$store.getters['user/getCompany']
            }
            this.access_control_fetch_key = this.ac_key
            getProperty(this.$route.params.id).then((response) => {
                this.propertyInfo = response.data
            })
            fetchCompany(this.$store.getters['user/getCompany']).then((response) => {
                this.companyInfo = response.data
                if (this.imageTaggingEnabled) {
                    this.access_control_components = 1
                }
            })
            fetchAccessControlData(AC_PROPERTY_GALLERY, this.getDataForAC()).then(response => {
                this.galleryDis = response.data.gallery_items.disabled
                this.galleryAc = response.data.gallery_items.visible
            })
        }
    }
</script>

<style scoped>
    .list-complete-item {
        padding: 4px;
        margin-top: 4px;
        border: solid 1px;
        transition: all 1s;
    }

    .list-complete-enter, .list-complete-leave-active {
        opacity: 0;
    }

    .document-checkbox{
        left: 1rem;
    }

</style>
