<template>
    <div class="mini_calendar">
        <h6 class="month_header mb-0 text-capitalize">
            {{ monthDateStart.format('MMMM, YYYY') }}
        </h6>

        <div class="weekday">
            <div>{{ $t("DATE.DAYS.MONDAY") }}</div>
            <div>{{ $t("DATE.DAYS.TUESDAY") }}</div>
            <div>{{ $t("DATE.DAYS.WEDNESDAY") }}</div>
            <div>{{ $t("DATE.DAYS.THURSDAY") }}</div>
            <div>{{ $t("DATE.DAYS.FRIDAY") }}</div>
            <div>{{ $t("DATE.DAYS.SATURDAY") }}</div>
            <div>{{ $t("DATE.DAYS.SUNDAY") }}</div>
        </div>

        <div class="datelist">
            <year-calendar-item-cell-test
                v-for="(day, index) in cellObjectList"
                v-if="day.showCell"
                :key="index"
                :day="day"
                :unit="unitData"
                :isContigent="isContigent"
                :isFirstMonth="isFirstMonth"
                :highlighted="isHighlighted(day)"
                @drag="multiComponentDrag"
                @currentDrag="multiComponentDragCurrent"
                @startEndDrag="dragStartEnd"
                @showReservation="showReservation(day)"
                @mouseEnterOnDayBefore="mouseEnterOnDayBefore(day)"
                @mouseLeaveOnDayBefore="mouseLeaveOnDayBefore(day)"
                @showReservationOnDayBefore="showReservationOnDayBefore(day)"
                :show_channel_data="show_channel_data"
                @mouseEnter="mouseEnter(day)"
                @mouseLeave="mouseLeave(day)"
                @mouseEnterNote="mouseEnterNote(day)"
                @mouseLeaveNote="mouseLeaveNote(day)"
                :noteHighLight="isNoteHighlighted(day)"
            ></year-calendar-item-cell-test>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import {MIN_STAY, SALES_ENABLED} from "@/shared/constants";
import YearCalendarItemCellTest from "@/components/unit/calendar_opt/Table/YearCalendar/YearCalendarItemCellTest";
import {DISTRIBUTION_PHOTO} from "@/services/endpoints";
import i18n from "@/locale";
import {
    EventBus,
    GE_CALENDAR_OPEN_AVAILABILITY_PROPERTY,
    GE_CALENDAR_OPEN_RESERVATIONS
} from "@/shared/EventBus";
import {fetchNote} from "@/services/unit";
import {AvailabilityScrollMixin} from "@/mixins/calendar/AvailabilityScrollMixin";

export default {
    name: "YearCalendarItemTest",
    mixins: [AvailabilityScrollMixin],
    components: {YearCalendarItemCellTest},
    props: {
        year: {
            type: Number
        },
        month: {
            type: Number
        },
        firstMonth: {
            type: String
        },
        unit: {
            type: Object
        },
        property: {
            type: Object
        },
        isContigent: {
            type: Boolean
        },
        availability: {
            type: Object
        },
        reservations: {
            type: Array
        },
        show_channel_data: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            MIN_STAY,
            SALES_ENABLED,
            days: [],
            cellObjectList: [],
        }
    },
    methods: {
        mouseEnter(day){
            day.showResPopup.enter = true
            setTimeout(()=>{
                day.showResPopup.show = day.showResPopup.enter

            },500)
        },
        mouseLeave(day){
            day.showResPopup.enter = false
            day.showResPopup.show = false
        },
        showReservation(day){
            day.showResPopup.enter = false
            day.showResPopup.show = false
            if (this.shiftKeyValue) {
                return
            }
            EventBus.$emit(GE_CALENDAR_OPEN_RESERVATIONS, day.reservation)
        },
        mouseEnterOnDayBefore(day){
            day.showResPopup.enter = false
            day.showResPopup.show = false
            day.showResPopupOnDayBefore.enter = true
            setTimeout(()=>{
                day.showResPopupOnDayBefore.show = day.showResPopupOnDayBefore.enter

            },500)
        },
        mouseLeaveOnDayBefore(day){
            day.showResPopup.enter = false
            day.showResPopup.show = false
            day.showResPopupOnDayBefore.enter = false
            day.showResPopupOnDayBefore.show = false
        },
        showReservationOnDayBefore(day){
            day.showResPopup.enter = false
            day.showResPopup.show = false
            day.showResPopupOnDayBefore.enter = false
            day.showResPopupOnDayBefore.show = false
            if (this.shiftKeyValue || !day.hasReservationOnDayBefore || !day.reservationOnDayBefore) {
                return
            }
            EventBus.$emit(GE_CALENDAR_OPEN_RESERVATIONS, day.reservationOnDayBefore)
        },
        getTranslations(){
            return {
                RESERVATION_CODE: this.$t('RESERVATION_CODE'),
                SALES_CHANNEL:this.$t('SALES_CHANNEL'),
                GUEST:this.$t('GUEST'),
                ADULTS:this.$t('ADULTS'),
                CHILDREN:this.$t('CHILDREN'),
                BABIES:this.$t('BABIES'),
                TOTAL:this.$t('TOTAL'),
                ARRIVAL_TIME:this.$t('ARRIVAL_TIME'),
                BOOKED_AT:this.$t('BOOKED_AT'),
                UPDATED_AT:this.$t('UPDATED_AT'),
                NOTE:this.$t('NOTE'),
                CLOSED_AT:this.$t('CLOSED_AT'),
                USER:this.$t('USER'),
                TAG:this.$t('TAG')
            }
        },
        isHighlighted(day) {
            return (this.shiftKeyValue && this.dragPeriod && this.dragPeriod.start && this.currentDragDate)
                &&
                (
                    (day.date >= this.dragPeriod.start && day.date <= this.currentDragDate) ||
                    (day.date <= this.dragPeriod.start && day.date >= this.currentDragDate)
                )
        },
        getCellObjectList(dates) {
            if (Array.isArray(dates) && dates.length > 0) {
                return dates.map((date, cellIndex) => {
                    const dayBefore = date.clone().subtract(1, 'days')
                    const dayAfter = date.clone().add(1, 'days')
                    const dayBeforeF = dayBefore.format('YYYY-MM-DD')
                    const dayAfterF = dayAfter.format('YYYY-MM-DD')
                    const dateF = date.format('YYYY-MM-DD')
                    const note = typeof this.availabilityForUnit[dateF] === 'object' && !this.availabilityForUnit[dateF].availability.available && this.availabilityForUnit[dateF].hasOwnProperty('noteObject') ? this.availabilityForUnit[dateF].noteObject : null
                    const reservationDataObject = this.isContigent ? {} : this.getCellObjectReservationData(date, dayBefore, dayAfter, cellIndex)
                    const colspan = this.contigent ? 1 : Math.min(7, this.getCellObjectColumnSpan(reservationDataObject, date, cellIndex,))
                    const showCell = this.calculateShowCell(reservationDataObject, date, dateF, colspan)
                    const column = cellIndex + 1
                    const isCheckInThisWeek = reservationDataObject ? reservationDataObject.reservation ?
                        moment(reservationDataObject.reservation.check_in_date).isoWeek() === date.isoWeek()
                        && moment(reservationDataObject.reservation.check_in_date).format('MM') === date.format('MM')
                        : false : false
                    const isCheckOutThisWeek = reservationDataObject ? reservationDataObject.reservation ?
                        moment(reservationDataObject.reservation.check_out_date).isoWeek() === date.isoWeek()
                        && moment(reservationDataObject.reservation.check_out_date).format('MM') === date.format('MM')
                        : false : false
                    const hasReservationOnDayBefore = reservationDataObject ? reservationDataObject.hasReservationOnDayBefore : 0

                    return {
                        date,
                        showCell,
                        colspan,
                        isCheckInThisWeek,
                        isCheckOutThisWeek,
                        popup_title: reservationDataObject.reservation ? reservationDataObject.reservation.check_in_date + ' - ' + reservationDataObject.reservation.check_out_date : null,
                        hasReservationOnDayBefore: reservationDataObject ? reservationDataObject.hasReservationOnDayBefore : 0,
                        hasReservationOnDayAfter: reservationDataObject ? reservationDataObject.hasReservationOnDayAfter : 0,
                        reservationOnDayBefore: reservationDataObject ? reservationDataObject.reservationOnDayBefore : null,
                        reservation: reservationDataObject.reservation ? reservationDataObject.reservation : null,
                        showResPopupOnDayBefore: {show: false, enter: false},
                        showResPopup: {show: false, enter: false},
                        showNotePopup: {show: false, enter: false},
                        note:this.formatNote(note),
                        note_highlight: this.setNoteKey(this.availabilityForUnit[dateF], note),
                        availabilityObject: this.availabilityForUnit[dateF],
                        // unit:this.unit.id,
                        // isContigent: this.isContigent,
                        // showNumericSpan: this.showNumSpan(this.availabilityForUnit[dateF]),
                        // showOpenIndicator:this.showGreenIndicator(this.availabilityForUnit[dateF])
                        widthGuest: this.widthGuest(hasReservationOnDayBefore, reservationDataObject.reservation, colspan, column),
                        show_channel_data: this.show_channel_data,
                        translations: this.getTranslations(),
                    }
                })
            } else {
                return []
            }
        },
        mouseEnterNote(day){
            day.showNotePopup.enter = true
            setTimeout(()=>{

                if(day.showNotePopup.enter && day.note_highlight.key && !this.shiftKeyValue && !this.isContigent){
                    this.cellObjectList.forEach(el =>{
                        if(el.note_highlight.key === day.note_highlight.key){
                            el.note_highlight.value = true
                        } else {
                            el.note_highlight.value = false
                        }
                    })
                    if(day.note && day.note.hasOwnProperty('period_start') && day.note.hasOwnProperty('period_end')){
                        fetchNote(this.unit.id,{start:day.note.period_start,end:day.note.period_end}).then(response=>{
                            day.note.us = response.data.created_by
                            if(response.data){
                                day.note = this.formatNote(response.data)
                                day.showNotePopup.show = day.showNotePopup.enter && day.note_highlight.key !== null
                            }
                        })
                    }
                }
            },500)
        },
        mouseLeaveNote(day){
            day.showNotePopup.enter = false
            day.showNotePopup.show = false

            this.cellObjectList.forEach(el =>{
                el.note_highlight.value = false
            })
        },
        isNoteHighlighted(data){
            const object =  {key:null, value:null}
            if(data.note && this.cellObjectList.length > 0){
                this.cellObjectList.forEach(el => {

                    if(el.note === data.note ) {
                        object.key = data.note.period_start + '_' + data.note.period_end + '_' + this.unit.id
                        object.value = data.showNotePopup.show
                    }
                })
            }
            return object

        },
        formatNote(note){
            if(note){
                note.timestamp_formatted = note.hasOwnProperty('timestamp') ? this.formatTimeStamp(note.timestamp) : null
                note.period_start_foramtted =this.formatDate(note.period_start)
                note.period_end_foramtted =this.formatDate(note.period_end)
            }

            return note
        },
        setNoteKey(av, note){
            return note && !av.available ? {key:this.unit.id + '_' + note.period_start  + '_' +note.period_end,value:false} : {key:null, value:false}
        },
        widthGuest(hasReservationOnDayBefore, reservation, colspan, column) {
            let width = 0
            if (reservation && reservation.num_nights === 1) {
                width = (colspan * 2.5) + 0.2
                return width
            } else if (column === 1 && reservation) {
                width = colspan * 2.5 + 0.8
                return width
            } else {
                width = colspan * 2.5
                return width
            }
        },
        getCellObjectColumnSpan({reservation, hasReservationOnDay}, date, cellIndex) {
            if (reservation && reservation.hasOwnProperty('num_nights')) {
                let checkInDate = moment(reservation.check_in_date)
                let checkOutDate = moment(reservation.check_out_date)
                const endOfMonth = moment(date).endOf('month')
                const startOfMonth = moment(date).startOf('month')

                if (checkInDate.format('YYYY-MM-DD') < startOfMonth.format('YYYY-MM-DD')) {
                    checkInDate = moment(startOfMonth)
                }

                const remainingDays = checkOutDate.diff(checkInDate, 'days')
                // calculated days till end of week + start day
                const daysUntilWeekend = 7 - checkInDate.isoWeekday() + 1


                if (checkInDate.format('YYYY-MM-DD') === endOfMonth.format('YYYY-MM-DD')) return 1

                const daysUntilMonth = endOfMonth.diff(date, 'days') + 1
                if (date.isoWeekday() === 1 && date === checkOutDate) {
                    return 1
                }

                if (date.isoWeekday() === 1 && date < checkOutDate) {
                    let day_diff = checkOutDate.diff(date, 'days')
                    if (daysUntilMonth < day_diff){
                        return daysUntilMonth
                    }
                    if (day_diff > 7) {
                        return 7
                    } else {
                        return day_diff
                    }
                }

                if (date.isoWeekday() === 7) {
                    return 1
                }
                if (reservation.num_nights > daysUntilWeekend) {
                    if (daysUntilMonth < daysUntilWeekend) return daysUntilMonth
                    if (remainingDays < daysUntilWeekend) return remainingDays
                    return daysUntilWeekend;
                }
                if (reservation.num_nights > daysUntilMonth) {
                    if (remainingDays < daysUntilMonth) return remainingDays
                    return daysUntilMonth;
                }

                if (remainingDays < reservation.num_nights) return remainingDays
                return reservation.num_nights
            }

            if (hasReservationOnDay && !reservation) {
                return 0
            }

            return 1
        },
        calculateShowCell(reservation, date, dateF, colspan) {
            if (!this.availabilityForUnit.hasOwnProperty(dateF)) {
                return false
            }
            if (this.isContigent) {
                return true
            }
            if (colspan > 0) {
                if (this.availabilityForUnit.hasOwnProperty(dateF) && this.availabilityForUnit[dateF].hasOwnProperty('reservation') && this.availabilityForUnit[dateF].reservation.length === 0) {
                    return true
                }
                if (reservation && reservation.reservation) {
                    if (date.format('YYYY-MM-DD') === reservation.reservation.check_in_date || date.isoWeekday() === 1) {
                        return true
                    }

                    const startOfMonth = moment(date).startOf('month')
                    if (date.format('YYYY-MM-DD') === startOfMonth.format('YYYY-MM-DD')) {
                        return true
                    }
                }
                return !!(!reservation.hasReservationOnDay && (reservation && !reservation.reservation));
            }
            return true
        },
        getCellObjectReservationData(date, dayBefore, dayAfter, cellIndex) {
            const reservationDataObject = {
                reservation: null,
                reservationOnDayBefore: null,
                hasReservationOnDayBefore: null,
                hasReservationOnDayAfter: null,
                hasReservationOnDay: null,
                hasOneNightReservation: null
            }

            let reservationOnDayAfter = this.reservations.find(r => dayAfter >= moment(r.check_in_date) && dayAfter < moment(r.check_out_date))
            let reservation = this.reservations.find(r => date >= moment(r.check_in_date) && date < moment(r.check_out_date))
            if (reservation) {
                if (reservation.distribution) {
                    reservation.channel_icon = DISTRIBUTION_PHOTO(reservation.distribution)
                } else {
                    reservation.channel_icon = DISTRIBUTION_PHOTO(0)
                }
                reservation.formatted_name = `${(reservation.first_name ? reservation.first_name.substr(0, 1) + '. ' : '')}${reservation.last_name}`
                reservation.formated_name_count = reservation.formatted_name.length
                reservation.formatted_check_in_time = this.formatTime(reservation.check_in_time)
                reservation.formatted_check_in_date = this.formatDate(reservation.check_in_date)
                reservation.formatted_check_out_date = this.formatDate(reservation.check_out_date)
                reservation.formatted_created_date = this.formatDate(reservation.created_date) + ' ' + this.formatTime(reservation.created_date)
                reservation.formatted_updated_date = this.formatDate(reservation.updated_date) + ' ' + this.formatTime(reservation.updated_date)
            }

            // only relevant (and shown) for reservations with checkout on monday or on first of month
            let reservationOnDayBefore = this.reservations.find(r => dayBefore >= moment(r.check_in_date) && dayBefore < moment(r.check_out_date))
            if (reservationOnDayBefore && (date.isoWeekday() === 1 || date.format('D') === '1')) {
                if (reservationOnDayBefore.distribution) {
                    reservationOnDayBefore.channel_icon = DISTRIBUTION_PHOTO(reservationOnDayBefore.distribution)
                } else {
                    reservationOnDayBefore.channel_icon = DISTRIBUTION_PHOTO(0)
                }
                reservationOnDayBefore.formatted_name = `${(reservationOnDayBefore.first_name ? reservationOnDayBefore.first_name.substr(0, 1) + '. ' : '')}${reservationOnDayBefore.last_name}`
                reservationOnDayBefore.formated_name_count = reservationOnDayBefore.formatted_name.length
                reservationOnDayBefore.formatted_check_in_time = this.formatTime(reservationOnDayBefore.check_in_time)
                reservationOnDayBefore.formatted_check_in_date = this.formatDate(reservationOnDayBefore.check_in_date)
                reservationOnDayBefore.formatted_check_out_date = this.formatDate(reservationOnDayBefore.check_out_date)
                reservationOnDayBefore.formatted_created_date = this.formatDate(reservationOnDayBefore.created_date) + ' ' + this.formatTime(reservationOnDayBefore.created_date)
                reservationOnDayBefore.formatted_updated_date = this.formatDate(reservationOnDayBefore.updated_date) + ' ' + this.formatTime(reservationOnDayBefore.updated_date)
            }

            reservationDataObject.reservation = reservation
            reservationDataObject.reservationOnDayBefore = (date.isoWeekday() === 1 || date.format('D') === '1') ? reservationOnDayBefore : null
            reservationDataObject.hasReservationOnDayBefore = !!reservationOnDayBefore
            reservationDataObject.hasReservationOnDayAfter = !!reservationOnDayAfter
            reservationDataObject.hasReservationOnDay = !!reservation

            return reservationDataObject
        },
        showNumSpan(availabilityObject) {
            const available = availabilityObject.available ? 1 : 0
            if (this.isContingentContainerRow) {
                const available_contingent = availabilityObject.available_count > 1 || (availabilityObject.available_count > 0 && !availabilityObject.open_for_sale) ? true : false
                return {
                    available_count: available_contingent,
                    room_status: availabilityObject.available_count === 0 && availabilityObject.open_for_sale
                }
            } else if (this.contigent.length === 0 && this.isRoot) {
                return {
                    available_count: available && !availabilityObject.open_for_sale,
                    room_status: !available && availabilityObject.open_for_sale
                }
            } else {
                return {
                    available_count: false,
                    room_status: false
                }
            }
        },
        formatTime(value) {
            let format = i18n.t('DATE.HOUR_MIN_FORMAT')

            if (format === null) {
                format = "HH:mm"
            }

            if (value) {
                if (typeof value === 'number') {
                    return moment.unix(value).format(format)
                } else if (typeof value === 'string') {
                    if ((new Date(value)).getTime() > 0) {
                        return moment(value).format(format)
                    } else {
                        return moment(value, "HH:mm").format(format)
                    }
                }
            } else {
                return value
            }
        },
        formatDate(value) {
            const year = true
            if (!value) return

            let format = (year) ? i18n.t('DATE.DATE_FORMAT') : i18n.t('DATE.DATE_FORMAT_SANS_YEAR')

            if (format === null) {
                format = "YYYY-MM-DD"
            }

            if (typeof value === 'number') {
                return moment.unix(value).format(format)
            }

            return moment(String(value)).format(format)
        },
        formatTimeStamp(value) {
            let format = i18n.t('DATE.TIMESTAMP_FORMAT')
            if (format === null) {
                format = "YYYY-MM-DD HH:mm"
            }
            return moment(String(value)).format(format)
        },
        getUnitNote(availabilityObject) {
            let noteObject = null
            if (availabilityObject.hasOwnProperty('note')) {
                noteObject = availabilityObject.note
            }
            return noteObject
        },
        getRoomStatus(restriction) {
            return typeof restriction === 'object' && Object.keys(restriction).length > 0 && restriction.hasOwnProperty(SALES_ENABLED) && restriction[SALES_ENABLED].hasOwnProperty('value') ? parseInt(restriction[SALES_ENABLED].value) : null
        },
        getMinStay(restriction) {
            return typeof restriction === 'object' && Object.keys(restriction).length > 0 && restriction.hasOwnProperty(MIN_STAY) && restriction[MIN_STAY].hasOwnProperty('value') ? parseInt(restriction[MIN_STAY].value) : null
        },
        openAvailabilityAside() {
            if (this.dragPeriod.start > this.dragPeriod.end) {
                this.dragPeriod = {
                    start: this.dragPeriod.end,
                    end: this.dragPeriod.start
                }
            }
            let eventData = {
                unitId: this.isContigent ? this.parentUnit.id : this.unit.id,
                rootUnit: this.isContigent ? this.parentUnit : this.unit,
                contigentMode: this.isContigent,
                contigentUnit: null,
                property: this.property,
                tab: 0,
                dateRange: {
                    start: this.dragPeriod.start.format("YYYY-MM-DD"),
                    end: this.dragPeriod.end.format("YYYY-MM-DD")
                }
            }
            EventBus.$emit(GE_CALENDAR_OPEN_AVAILABILITY_PROPERTY, eventData)
            this.dragPeriod.start = null
            this.dragPeriod.end = null
            this.currentDragDate = null
        },
    },
    computed: {
        monthDateStart() {
            return moment().set({year: this.year, month: this.month}).startOf('month')
        },
        isFirstMonth() {
            return this.monthDateStart.format("YYYY-MM") === this.firstMonth
        },
        availabilityForUnit() {
            return Object.entries(this.availability).reduce((acc, [date, value]) => {
                acc[date] = {
                    ...value,
                    available: !!value.availability.available,
                    available_count: value.availability.available_count,
                    noteObject: this.getUnitNote(value.availability),
                    min_stay: value.hasOwnProperty('restrictions') ? this.getMinStay(value.restrictions) : null,
                    open_for_sale: value.hasOwnProperty('restrictions') ? this.getRoomStatus(value.restrictions) : null,
                    reservation: this.reservations.filter(r => date >= r.check_in_date && date < r.check_out_date).map(reservation => {
                        return reservation.unit
                    }),
                }

                return acc
            }, {})
        },
        unitData() {
            return {
                ...this.unit || {},
                data: [],
                reservation: []
            }
        },
    },
    mounted() {
        this.days = [...Array(this.monthDateStart.daysInMonth())].map((_, i) => {
            return this.monthDateStart.clone().add(i, 'day')
        })
        this.cellObjectList = this.getCellObjectList(this.days)
    },
    watch: {
        availability: {
            immediate: false,
            deep: true,
            handler(value) {
                if (Object.keys(value).length && this.days.length) {
                    this.cellObjectList = []
                    this.days = [...Array(this.monthDateStart.daysInMonth())].map((_, i) => {
                        return this.monthDateStart.clone().add(i, 'day')
                    })
                    this.cellObjectList = this.getCellObjectList(this.days)
                }
            }
        },
        shiftKeyValue(val) {
            if (val === false) {
                this.dragPeriod.start = null
                this.dragPeriod.end = null
                this.currentDragDate = null
            }
        },
    }
}
</script>

<style scoped>

</style>
