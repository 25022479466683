<template>
    <b-form @submit.prevent="save" autocomplete="off" v-if="formLoaded">
        <b-row>
            <b-col sm="12">
                <b-form-group>
                    <label>{{$t('PROPERTY')}}</label>
                    <app-select mode="search"
                                v-model="property"
                                :search="searchProperty"
                                :search-empty-item="false">
                    </app-select>
                </b-form-group>
            </b-col>
            <b-col sm="12">
                <b-form-group>
                    <label>{{$t('UNITS')}}</label>
                    <app-select mode="multiselect"
                                v-model="units"
                                :options="unit_list"
                                :disabled="!property || !property.id || unit_list.length < 1">
                    </app-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group>
                    <b-checkbox v-model="copy_restrictions"
                                :unchecked-value="0"
                                :value="1"
                                switch>
                        {{$t('COPY_RESTRICTIONS')}}
                    </b-checkbox>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button-submit :loading="loading"
                                   :disabled="units.length < 1">
                    {{$t('COPY')}}
                </app-button-submit>
            </b-col>
        </b-row>
        <app-confirmation-dialog :show="displayConfirmationDialog"
                                 :title="$t('COPY_RATE_PLANS_CONFIRMATION_TITLE')"
                                 @confirm="copy"
                                 @cancel="displayConfirmationDialog = false">
            {{$t('COPY_RATE_PLANS_TO_UNITS_CONFIRMATION_MSG')}}
        </app-confirmation-dialog>
    </b-form>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {getProperty, getPropertyList} from "@/services/property";
import {getUnitList} from "@/services/unit";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {copyRatePlanList} from "@/services/pricing";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {notifySuccess} from "@/shared/plugins/toastr";
export default {
    name: "copy-rate-plan-list-form",
    components: {AppConfirmationDialog, AppButtonSubmit, AppSelect},
    mixins: [getErrorMessage],
    data() {
        return {
            property: null,
            units: [],
            copy_restrictions: 0,
            unit_list: [],
            formLoaded: false,
            loading: false,
            displayConfirmationDialog: false
        }
    },
    props: {
        ratePlanList: {
            type: Array,
            default() {
                return []
            }
        },
        propertyId: {
            type: Number,
            default: null
        },
        unitId: {
            type: Number,
            default: null
        }
    },
    watch: {
        property: {
            handler(value) {
                if (value && value.id) {
                    this.units = []
                    this.searchUnitList(value.id)
                } else {
                    this.unit_list = []
                    this.units = []
                }
            },
        },
    },
    computed: {
        company() {
            return this.$store.getters['user/getCompany']
        }
    },
    methods: {
        searchProperty(value) {
            return getPropertyList({name: value, company_id: this.company}).then(response => {
                return {data: response.data.items}
            })
        },
        searchUnitList(value) {
            let request = {
                property_id: value,
                contigent: 0,
                limit: 999,
            }
            if (this.unitId) {
                request.excluding_id = this.unitId
            }
            getUnitList(request).then(response => {
                this.unit_list = response.data.items
            }, () => {
                this.unit_list = []
            })
        },
        save() {
            this.displayConfirmationDialog = true
        },
        copy() {
            let request = {
                rate_plan_list: this.ratePlanList,
                unit_list: this.units,
                copy_restrictions: this.copy_restrictions
            }
            copyRatePlanList(request).then(response => {
                notifySuccess()
                this.$emit('copiedRatePlans', response.data)
            }, error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.displayConfirmationDialog = false
            })
        }
    },
    created() {
        if (this.propertyId) {
            let request = {
                property_id: this.propertyId,
                contigent: 0,
                limit: 999,
            }
            if (this.unitId) {
                request.excluding_id = this.unitId
            }
            getUnitList(request).then(response => {
                if (response.data.items.length > 0) {
                    getProperty(this.propertyId).then(response => {
                        this.property = response.data
                    }).finally(() => this.formLoaded = true)
                } else {
                    this.formLoaded = true
                }
            })
        } else {
            this.formLoaded = true
        }
    }
}
</script>

<style scoped>

</style>
