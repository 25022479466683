<template>
    <div class="mini_calendar_holder">
        <year-calendar-item :key="monthNumber" :unit="unit" :year="year" :month="monthNumber -1"
                            :isContigent="isContigent"
                            v-for="monthNumber in 12" :availability="availability" :room-status="room_status"
                            :reservations="reservations" :ac_response_value="ac_response_value">

        </year-calendar-item>
        <loading :show="showLoading" :overlay="overlay"></loading>
    </div>
</template>

<script>
    import YearCalendarItem from "@/components/unit/calendar_opt/Table/YearCalendar/YearCalendarItem";
    import moment from 'moment'
    import {fetchCalendarData} from '@/services/unit/index'
    import {has, get, size} from 'lodash'
    import {DISTRIBUTION_PHOTO} from "@/services/endpoints";
    import {C_RESERVATION_ACCOMMODATION_BOOKING_SITE} from "@/shared/component_permission";
    import {fetchAccessControlData} from "@/services/access";
    import {AC_FEATURE_SETUP} from "@/mixins/AccessControl/AccessControlEnumeration";
    import loading from 'vue-full-loading'

    export default {
        name: "YearCalendarHolder",
        components: {YearCalendarItem, loading},
        props: {
            year: {
                type: Number
            },
            unit: {
                type: Object
            },
            isContigent: {
                type: Boolean
            },
        },
        data() {
            return {
                availability: {},
                room_status: {},
                reservations: [],
                ac_response_value:false,
                showLoading:false,
                overlay: true,
            }
        },
        computed: {
            periodDateStart() {
                return moment().set({year: this.year, month: 0}).startOf('month').format("YYYY-MM-DD")
            },
            periodDateEnd() {
                return moment().set({year: this.year, month: 11}).endOf('month').format("YYYY-MM-DD")
            }
        },
        methods: {
            fetch(id) {
                this.showLoading = true
                let params = {
                    date_from: this.periodDateStart,
                    date_to: this.periodDateEnd,
                    actions: ['availability', 'reservation'],
                    contigent_mode: this.isContigent ? 1 : 0
                }

                fetchCalendarData(id, params).then(response => {

                    if (has(response, 'data.period_list') && get(response, 'data.period_list').length) {

                        response.data.period_list.forEach(period => {
                            if (has(period, 'data.availability') && get(period, 'data.availability') && size(get(period, 'data.availability'))) {
                                let list = {}
                                let object = get(period, 'data.availability')
                                let currentDate = moment(period.from)
                                const stopDate = moment(period.to)
                                while (currentDate <= stopDate) {
                                    list[currentDate.format("YYYY-MM-DD")] = object
                                    currentDate = moment(currentDate).add(1, 'days');
                                }


                                this.availability = {...this.availability, ...list}
                            }

                            if (has(period, 'data.open_for_sale') && get(period, 'data.open_for_sale') && size(get(period, 'data.open_for_sale'))) {
                                let list = {}
                                let ratePlanKey = Object.keys(get(period, 'data.open_for_sale'))[0];
                                let openForSalesValue = get(period, 'data.open_for_sale.' + ratePlanKey)
                                let currentDate = moment(period.from)
                                const stopDate = moment(period.to)
                                while (currentDate <= stopDate) {
                                    list[currentDate.format("YYYY-MM-DD")] = openForSalesValue
                                    currentDate = moment(currentDate).add(1, 'days');
                                }


                                this.room_status = {...this.room_status, ...list}
                            }

                            if (has(period, 'data.reservation') && get(period, 'data.reservation') && size(get(period, 'data.reservation'))) {
                                let list = {}
                                let reservations = period.data.reservation
                                reservations.forEach(reservation => {
                                    if (reservation.hasOwnProperty("id")) {
                                        list[reservation.id] = period.data.reservation[0]
                                        this.reservations.push(reservation)
                                        // this.reservation = {...this.reservation, ...reservation}
                                    }
                                })
                            }
                        })
                    }
                }).finally(() => {
                    this.showLoading = true
                })
            },
        },
        mounted() {
            let request = {
                context: {
                    company: this.$store.getters['user/getCompany']
                },
                data: []
            }
            request.data.push({
                uid: C_RESERVATION_ACCOMMODATION_BOOKING_SITE,
                function: C_RESERVATION_ACCOMMODATION_BOOKING_SITE
            })
            fetchAccessControlData(AC_FEATURE_SETUP, request).then(response => {
                this.ac_response_value = response.data[C_RESERVATION_ACCOMMODATION_BOOKING_SITE].visible
            })
        },
        watch: {
            'unit.id': {
                handler(id) {
                    this.availability = {}
                    this.room_status = {}
                    this.reservations = []
                    this.fetch(id)
                },
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>
