<template>
    <general-layout :company="company">
        <template v-slot:body>
            <b-row>
                <b-col v-if="checkPermission(C_TOTAL_INCOME_V)" class="col-xxl-6">
                    <dashboard-income :company="company.id"></dashboard-income>
                </b-col>
                <b-col v-if="checkPermission(C_NIGHTS_V)" class="col-xxl-6">
                    <dashboard-number-of-nights :company="company.id"></dashboard-number-of-nights>
                </b-col>
                <b-col v-if="checkPermission(C_CHECKIN_RESERVATIONS_V)" cols="12">
                    <dashboard-checkin-reservation-list
                        :company="company"></dashboard-checkin-reservation-list>
                </b-col>
                <b-col v-if="checkPermission(C_CHECKOUT_RESERVATIONS_V)" cols="12">
                    <dashboard-checkout-reservation-list
                        :company="company"></dashboard-checkout-reservation-list>
                </b-col>
                <b-col v-if="checkPermission(C_LATEST_RESERVATIONS_V)" cols="12">
                    <dashboard-latest-reservation-list
                        :company="company"></dashboard-latest-reservation-list>
                </b-col>
            </b-row>
        </template>
        <template v-slot:sidebar>
            <b-row>
                <b-col v-if="checkPermission(C_QUICK_ACTIONS_GRID_V)">
                    <quick-actions-grid></quick-actions-grid>
                </b-col>
            </b-row>
            <b-row>
                <b-col v-if="checkPermission(C_DASHBOARD_CONTRACT_V)">
                    <app-access-control tag="span"
                                        :access_control_context="{function: C_DASHBOARD_CONTRACT,key: AC_DASHBOARD}"
                                        @AccessControlEvent="addToAccessControlCounter()">
                        <document-action-grid></document-action-grid>
                    </app-access-control>
                </b-col>
            </b-row>
            <b-row>
                <b-col v-if="checkPermission(C_DASHBOARD_PROPERTY_DOCUMENT)">
                    <app-access-control tag="span"
                                        :access_control_context="{function: C_DASHBOARD_PROPERTY_DOCUMENT,key: AC_DASHBOARD}"
                                        @AccessControlEvent="addToAccessControlCounter()">
                        <property-pdf-list></property-pdf-list>
                    </app-access-control>
                </b-col>
            </b-row>
        </template>
    </general-layout>
</template>

<script>
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {
        C_CHECKIN_RESERVATIONS_V,
        C_CHECKOUT_RESERVATIONS_V, C_DASHBOARD_CONTRACT, C_DASHBOARD_CONTRACT_V, C_LATEST_RESERVATIONS_V, C_DASHBOARD_PROPERTY_DOCUMENT,
        C_NIGHTS_V, C_QUICK_ACTIONS_GRID_V,
        C_TOTAL_INCOME_V
    } from "@/shared/component_permission";
    import {AC_DASHBOARD} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {ACCESS_CONTROL_DASHBOARD} from "@/services/endpoints";
    import DashboardIncome from "@/components/dashboard/shared/DashboardIncome";
    import DashboardNumberOfNights from "@/components/dashboard/shared/DashboardNumberOfNights";
    import DashboardCheckinReservationList from "@/components/dashboard/shared/DashboardCheckinReservationList";
    import DashboardCheckoutReservationList from "@/components/dashboard/shared/DashboardCheckoutReservationList";
    import DashboardLatestReservationList from "@/components/dashboard/shared/DashboardLatestReservationList";
    import QuickActionsGrid from "@/components/dashboard/QuickActionsGrid";
    import DocumentActionGrid from "@/components/dashboard/DocumentActionGrid";
    import PropertyPdfList from "@/components/dashboard/shared/PropertyPdfList";
    import GeneralLayout from "@/components/dashboard/layout/GeneralLayout";
    import AppAccessControl from "@/components/app/AppAccessControl";

    export default {
        name: "GeneralDashboard",
        components: {
            AppAccessControl,
            GeneralLayout,
            DocumentActionGrid,
            PropertyPdfList,
            QuickActionsGrid,
            DashboardLatestReservationList,
            DashboardCheckoutReservationList,
            DashboardCheckinReservationList, DashboardNumberOfNights, DashboardIncome
        },
        props: {
            company: {
                type: Object,
            },
            property: {
                type: Number,
                default: null
            }
        },
        mixins: [AccessControlComponent],
        data() {
            return {
                C_TOTAL_INCOME_V,
                C_NIGHTS_V,
                C_CHECKIN_RESERVATIONS_V,
                C_CHECKOUT_RESERVATIONS_V,
                C_LATEST_RESERVATIONS_V,
                C_QUICK_ACTIONS_GRID_V,
                C_DASHBOARD_CONTRACT,
                C_DASHBOARD_CONTRACT_V,
                AC_DASHBOARD,
                ACCESS_CONTROL_DASHBOARD,
                access_control_fetch_key: AC_DASHBOARD,
                access_control_components: 1,
                C_DASHBOARD_PROPERTY_DOCUMENT
            }
        },
        created() {
            this.access_control_general_context = {
                company: this.$store.getters['user/getCompany']
            }

        },
    }
</script>

<style scoped>

</style>
