<template>
    <div v-if="loading" class="d-flex justify-content-start">
        <b-spinner variant="primary"></b-spinner>
    </div>
    <b-form v-else autocomplete="false" @submit.prevent="save">
        <b-row>
            <b-col>
                <form-header>
                    {{ $t('CONNECTION') }}
                </form-header>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="6" class="mb-4">
                <label>{{ $t('TYPE') }}</label>
                <app-select :options="connectionTypeOptions"
                            :search-empty-item="false"
                            v-model="connectionType.values.unit.value"
                ></app-select>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <form-header>
                    {{ $t('LOCATION_OVERRIDE') }}
                </form-header>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-4">
                <b-checkbox  v-model="checkedLocationOverride" switch>
                    {{ $t('ENABLE_DISABLE') }}
                </b-checkbox>
            </b-col>
        </b-row>

        <template v-if="checkedLocationOverride">
            <b-row>
                <b-col md="6" class="mb-4">
                    <app-input-control>
                        <template v-slot:input="data">
                            <b-row>
                                <label class="mr-2 ml-2">{{ $t('LATITUDE') }}</label>
                            </b-row>
                            <b-form-input :state="data.state" lazy
                                          v-model="locationOverride.latitude"></b-form-input>
                            <validation-description
                                :show="$v.locationOverride.latitude.$dirty && $v.locationOverride.latitude.$invalid">
                                <span v-if="!$v.locationOverride.latitude.required">{{ $t('V.REQUIRED') }}. </span>
                                <span v-else-if="!$v.locationOverride.latitude.decimal">{{ $t('V.NUMERIC') }}. </span>
                                <span v-else>{{ $t('V.VALUE_BETWEEN', {min: -90, max: 90}) }}</span>
                            </validation-description>
                        </template>
                    </app-input-control>

                </b-col>
                <b-col md="6" class="mb-4">
                    <app-input-control>
                        <template v-slot:input="data">
                            <b-row>
                                <label class="mr-2 ml-2">{{ $t('LONGITUDE') }}</label>
                            </b-row>
                            <b-form-input :state="data.state" lazy
                                          v-model="locationOverride.longitude"></b-form-input>
                            <validation-description
                                :show="$v.locationOverride.longitude.$dirty && $v.locationOverride.longitude.$invalid">
                                <span v-if="!$v.locationOverride.longitude.required">{{ $t('V.REQUIRED') }}. </span>
                                <span v-else-if="!$v.locationOverride.longitude.decimal">{{ $t('V.NUMERIC') }}. </span>
                                <span v-else>{{ $t('V.VALUE_BETWEEN', {min: -180, max: 180}) }}</span>
                            </validation-description>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6" class="mb-4">
                    <app-input-control>
                        <template v-slot:input="data">
                            <b-row>
                                <label class="mr-2 ml-2">{{ $t('ADDRESS') }}</label>
                            </b-row>
                            <b-form-input :state="data.state" type="text"
                                          v-model="locationOverride.address"></b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="6" class="mb-4">
                    <app-input-control>
                        <template v-slot:input="data">
                            <b-row>
                                <label class="mr-2 ml-2">{{ $t('APARTMENT') }}</label>
                            </b-row>
                            <b-form-input :state="data.state" type="text"
                                          v-model="locationOverride.apt"></b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6" class="mb-4">
                    <app-input-control>
                        <template v-slot:input="data">
                            <b-row>
                                <label class="mr-2 ml-2">{{ $t('ZIP') }}</label>
                            </b-row>
                            <b-form-input :state="data.state" type="text"
                                          v-model="locationOverride.postal_number"></b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="6" class="mb-4">
                    <app-input-control>
                        <template v-slot:input="data">
                            <b-row>
                                <label class="mr-2 ml-2">{{ $t('CITY') }}</label>
                            </b-row>
                            <b-form-input :state="data.state" type="text"
                                          v-model="locationOverride.city"></b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6" class="mb-4">
                    <app-input-control>
                        <template v-slot:input="data">
                            <b-row>
                                <label class="mr-2 ml-2">{{ $t('COUNTRY_CODE') }}</label>
                            </b-row>
                            <b-form-input :state="data.state" type="text"
                                          v-model="locationOverride.country_code"></b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
        </template>
        <b-row>
            <b-col md="6">
                <submit-button :loading="save_loading" :disabled="$v.$error === true"></submit-button>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import SubmitButton from '@/components/app/AppButton/AppButtonSubmit'
import ValidationDescription from '@/components/app/form/AppValidationDescription'
import FormHeader from "@/components/app/form/FormHeader";
import GMap from '@/components/app/map/GoogleMap'
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppInputControl from "@/components/app/AppInputControl";
import AppButton from "@/components/app/AppButton/AppButton";
import {
    getSetupParameterList,
} from "@/services/channel_manager";
import {LOCATION_OVERRIDE, CONNECTION_TYPE} from "@/shared/constants";
import {toast} from "@/shared/plugins/toastr";
import {between, decimal, required} from "vuelidate/lib/validators";

export default {
    name: "AirBnbUnitSetupForm",
    mixins: [getErrorMessage],
    props: {
        unit: {
            type: Object
        },
        distribution_id: {
            type: Number
        },
        save_loading: {
            type: Boolean
        }
    },
    data() {
        return {
            connectionTypeOptions: [],
            connectionType: null,
            locationOverride: {
                latitude: null,
                longitude: null,
                address: null,
                apt: null,
                postal_number: null,
                city: null,
                country_code: null,
            },
            checkedLocationOverride: false,
            loading: true,
            requestData:[]
        }
    },
    components: {
        AppInputControl,
        AppSelect,
        FormHeader,
        SubmitButton,
        GMap,
        AppButton,
        ValidationDescription
    },
    validations() {
        return this.validationRules
    },
    computed: {
        validationRules () {
            let rulesObject = {}
            if (this.checkedLocationOverride === true) {
                rulesObject.locationOverride = {
                    latitude: {
                        required,
                        decimal,
                        between: between(-90, 90)
                    },
                    longitude: {
                        required,
                        decimal,
                        between: between(-180, 180)
                    },
                }
            }
            return rulesObject
        }
    },
    methods: {
        getParameterList() {
            getSetupParameterList({
                level: 'unit',
                distribution: [this.distribution_id],
                unit: [this.unit.id]
            }).then(response => {
                if (response.data) {
                    response.data[0].parameters.forEach(el => {
                        if (el.parameter_id === LOCATION_OVERRIDE) {
                            this.checkedLocationOverride = false
                            if (el.values.unit && el.values.unit.value) {
                                let data = JSON.parse(el.values.unit.value)
                                this.locationOverride.latitude = data.lat || ''
                                this.locationOverride.longitude = data.lng || ''
                                this.locationOverride.city = data.city || ''
                                this.locationOverride.address = data.street || ''
                                this.locationOverride.postal_number = data.zipcode || ''
                                this.locationOverride.country_code = data.country_code || ''
                                this.locationOverride.apt = data.apt || ''
                                this.checkedLocationOverride = true
                            }
                        }
                        if (el.parameter_id === CONNECTION_TYPE && el.values.unit) {
                            this.connectionType = el
                            if (el.options) {
                                this.connectionTypeOptions = el.options
                            }
                        }
                    })
                }
            }).finally(() => {
                this.loading = false
            })
        },
        prepareConnectionTypeRequest() {
            if (this.connectionType && this.connectionType.values && this.connectionType.values.unit && this.connectionType.values.unit.value !== null) {
                return String(this.connectionType.values.unit.value)
            }
            return null
        },
        prepareLocationOverrideRequest() {
            if (this.checkedLocationOverride === false) {
                return null
            }
            return JSON.stringify({
                lat: this.locationOverride.latitude,
                lng: this.locationOverride.longitude,
                street: this.locationOverride.address,
                apt: this.locationOverride.apt,
                zipcode: this.locationOverride.postal_number,
                city: this.locationOverride.city,
                country_code: this.locationOverride.country_code,
            })
        },
        prepareRequest() {
            return {
                data: [
                    {
                        parameter: CONNECTION_TYPE,
                        distribution: this.distribution_id,
                        unit: [{
                            id: this.unit.id,
                            value: this.prepareConnectionTypeRequest(),
                        }]
                    },
                    {
                        parameter: LOCATION_OVERRIDE,
                        distribution: this.distribution_id,
                        unit: [{
                            id: this.unit.id,
                            value: this.prepareLocationOverrideRequest(),
                        }]
                    },
                ]
            }
        },
        save() {
            this.$v.$touch();
            if ((this.$v.$error)) {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('VALIDATION_FAILED'),
                    'type': 'error',
                    'timeout': 6000
                })
                return
            }
            let request = this.prepareRequest()
            this.$emit('updateParameter', request)
        },
    },
    created() {
        this.getParameterList()
    },
}
</script>

<style scoped>

</style>
