<template>
    <b-dropdown
        @shown="focusInput"
        @hidden="$emit('hidden')"
        :size="size" no-caret
        :id="component_id"
        :variant="variant"
        :boundary="boundary"
        :popper-opts="popperOpts"

        @show="scrollIntoView"
        :disabled="disabled" :toggle-class="(state === false) ? 'border-danger invalid-icon' : ''">
        <template slot="button-content">
            <span>
                <slot name="text" :item="value">
                    {{text}}
                </slot>
            </span>
            <template>

                <template v-if="state !== false && !disabled">
                    <i v-if="hasValue && SearchClear" @click.prevent="reset"
                       class="fa fa-times fa-fw dropdown_field--delete"
                       aria-hidden="true"></i>
                    <i v-else class="fa fa-chevron-down fa-fw" aria-hidden="true"></i>
                </template>



            </template>
        </template>

        <b-dropdown-form  v-if="!SearchOnCreate" @submit.prevent="onSearch">
            <b-input :ref="component_input_id" :id="component_input_id" class="dropdown_field--input mt-2"
                     size="default"
                     v-model="keyword"
                     @input="onSearch"></b-input>

            <p class="text-center" v-if="no_results">
                {{$t("NO_RESULTS_FOUND")}}
            </p>
        </b-dropdown-form>

        <b-dropdown-group class="dropdown_field--options">
            <b-dropdown-item v-if="SearchEmptyItem && !SearchClear && hasValue"
                             :active="isActive(emptyItem)"
                             @click="reset()">
                <span style="visibility: hidden">{{$t('CLEAR')}}</span>
            </b-dropdown-item>

            <b-dropdown-item
                :ref="`dropdownItem-${optionItem[ValueField]}`"
                :key="optionIndex"
                v-for="(optionItem, optionIndex) in list"
                :active="isActive(optionItem)"
                @click="change(optionItem)">
                <slot name="text" :item="optionItem">
                    {{getOptionLabel(optionItem)}}
                </slot>
            </b-dropdown-item>
        </b-dropdown-group>
    </b-dropdown>
</template>

<script>
    import _debounce from 'lodash/debounce'
    import _isEqual from 'lodash/isEqual'

    export default {
        name: "AppSelectSearch",
        props: {
            value: {
                default: null
            },
            ValueField: {
                type: String
            },
            TextField: {
                type: String
            },
            variant: {
                type: String
            },
            search: {
                type: Function
            },
            minKeywordLength: {
                type: Number
            },
            SearchOnCreate: {
                type: Boolean
            },
            SearchInput: {
                type: Boolean
            },
            disabled: {
                type: Boolean
            },
            size: {
                type: String
            },
            SearchClear: {
                type: Boolean
            },
            SearchEmptyItem: {
                type: Boolean
            },
            boundary: {
                type: String
            },
            state: {
                default: null
            },
            setFirst: {
                type: Boolean,
            },
            popperOpts:{
                type: Object
            }
        },
        data() {
            return {
                keyword: null,
                initial: true,
                searching: false,
                no_results: false,
                list: []
            }
        },
        computed: {
            component_id() {
                return 'list_search_' + this._uid
            },
            component_input_id() {
                return 'list_search_input_' + this._uid
            },
            text() {
                if (typeof this.value === 'object' && this.value !== null && this.value.hasOwnProperty(this.TextField)) {
                    return this.value[this.TextField]
                }
                return ''
            },
            hasValue() {
                return this.value !== null && (this.value.hasOwnProperty(this.ValueField) && this.value[this.ValueField] !== null)
            },
            emptyItem() {
                return {
                    [this.ValueField]: null,
                    [this.TextField]: null
                }
            }
        },

        methods: {
            focusInput() {
                if (!this.SearchOnCreate) {
                    this.$nextTick(() => {

                        this.$refs[this.component_input_id].focus()
                    })
                }
            },
            getData() {
                this.searching = true
                this.initial = false

                this.search(this.keyword).then(response => {
                    this.list = response.data || []
                    this.searching = false
                    this.no_results = this.list.length === 0

                    if (this.list.length === 1 && this.setFirst) {
                        this.$emit('input', this.list[0])
                    }

                }, () => {
                    this.searching = false
                })
            },
            onSearch: _debounce(function () {
                if (this.keyword.length >= this.minKeywordLength) {
                    this.getData()
                }
            }, 500),
            isActive(item) {
                if (!this.value) {
                    return false
                }

                if (_isEqual(item[this.ValueField], this.value[this.ValueField])) {
                    return true

                }
                return false

            },
            getOptionLabel(item) {
                if (item[this.TextField]) {
                    return item[this.TextField]
                }
                return item
            },
            change(item) {
                this.keyword = null
                if (!this.SearchOnCreate) {
                    this.list = []
                }
                this.$emit('input', item)
            },
            reset() {
                this.keyword = null
                this.$emit('input', this.emptyItem)

                if (!this.SearchOnCreate) {
                    this.list = []
                }
            },
            scrollIntoView(){
                if(this.value &&  this.$refs["dropdownItem-" + this.value[this.ValueField]] && this.$refs["dropdownItem-" + this.value[this.ValueField]].length){
                    this.$nextTick(() => {
                        this.$refs["dropdownItem-" + this.value[this.ValueField]][0].$el.scrollIntoView()
                    });
                }
            }
        },
        created() {
            if (this.SearchOnCreate) {
                this.getData()
            }
        }
    }
</script>

<style scoped>

</style>
