import { render, staticRenderFns } from "./RestrictionApplyToAllDropdown.vue?vue&type=template&id=546808e2&scoped=true&"
import script from "./RestrictionApplyToAllDropdown.vue?vue&type=script&lang=js&"
export * from "./RestrictionApplyToAllDropdown.vue?vue&type=script&lang=js&"
import style0 from "./RestrictionApplyToAllDropdown.vue?vue&type=style&index=0&id=546808e2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "546808e2",
  null
  
)

export default component.exports