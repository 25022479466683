<template>
    <div v-if="formData">
        <div v-if="formData.type==='bool'">
            <b-checkbox
                switch
                v-model="formData.value">{{ itemType.label }}
            </b-checkbox>
        </div>

        <div v-if="formData.type === 'select_bool'">
            <label>{{ formData.label }}</label>
            <app-select mode="select"
                        v-model="formData.value"
                        :options="formData.allowed_values"
                        value-field="id"
                        text-field="label">
            </app-select>
        </div>

        <div v-if="formData.type==='select'">
            <label>{{ formData.label }}</label>
            <app-select mode="select"
                        v-model="formData.value"
                        :options="formData.allowed_values"
                        value-field="id"
                        :search-empty-item="SearchEmptyItem"
                        text-field="label">
            </app-select>
        </div>

        <div v-if="formData.type==='multiselect'">
            <label>{{ formData.label }}</label>
            <app-select mode="multiselect"
                        v-model="formData.value"
                        :options="formData.allowed_values"
                        value-field="id"
                        text-field="label">
            </app-select>
        </div>

        <div v-if="formData.type==='lingual'">
            <app-translation-input
                :languages="supported_languages" v-model="formData.content"
                :label="$t('NAME')"
                :lang_id="formData.value">
            </app-translation-input>
        </div>

        <div v-if="formData.type==='multilingual'">
            <app-translation-input
                :languages="supported_languages" v-model="formData.value"
                :label="formData.label"
                component="textarea"
                :lang_id="formData.value">
            </app-translation-input>
        </div>

        <div v-if="formData.type==='lingual_placeholder'">
            <app-translation-input
                :languages="supported_languages"
                v-model="formData.value"
                :label="formData.label"
                component="textarea"
                :placeholder-list="formData.allowed_values"
                :placeholder-options="{collapsable: false}"
                @insertPlaceholder="insertLingualPlaceholder"
                :lang_id="formData.lang_id">
            </app-translation-input>
        </div>

        <div v-if="formData.type==='text'">
            <label>{{ formData.label }}</label>
            <b-form-input type="text" v-model="formData.value"></b-form-input>
        </div>

        <div v-if="formData.type==='number'">
            <label>{{ formData.label }}</label>
            <b-form-input type="number" step="0.01" v-model="formData.value"></b-form-input>
        </div>

        <div v-if="formData.type==='textarea'">
            <label>{{ formData.label }}</label>
            <b-form-textarea
                rows="4"
                v-model="formData.value">
            </b-form-textarea>
        </div>

        <div v-if="formData.type==='placeholder_text'">
            <label>{{ formData.label }}</label>
            <div class="mb-1">
                <b-badge :key="placeholderIndex"
                            class="mt-1 mb-1 mr-1 p-2 cursor-pointer" variant="primary"
                            pill
                            v-for="(placeholder, placeholderIndex) in formData.allowed_values"
                            @click="insertPlaceholder(placeholder.value)">
                    {{ placeholder.label }}
                </b-badge>
            </div>
            <b-form-input
                v-model="formData.value"
                @blur="getCurrentPosition($event)">
            </b-form-input>
        </div>

        <div v-if="formData.type === 'custom'">
            <b-row>
                <b-col v-for="(item, index) in formData.objects" lg="6" md="6">
                    <distribution-reservation-parameter-type-item :itemType="item"
                                                                  @update="setObjectItem($event, index)"
                                                                  :supported_languages="supported_languages">
                    </distribution-reservation-parameter-type-item>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>

export default {
    name: "DistributionReservationParameterTypeItem",
    components: {
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
        "AppTranslationInput": () => import("@/components/app/translation/AppTranslationInput"),
        "AppButton": () => import("@/components/app/AppButton/AppButton")
    },
    props: {
        itemType: {
            type: Object,
            default: null
        },
        supported_languages: {
            type: Array,
            default() {
                return []
            }
        },
        SearchEmptyItem: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            subjectFocusLocation: null,
            formData: null,
        }
    },
    methods: {
        getCurrentPosition(e) {
            this.subjectFocusLocation = e.target.selectionStart
        },
        insertPlaceholder(value) {
            if (this.subjectFocusLocation !== null) {
                if (this.itemType.value) {
                    this.itemType.value = this.itemType.value.substring(0, this.subjectFocusLocation) + value + this.itemType.value.substring(this.subjectFocusLocation, this.itemType.value.length)
                } else {
                    this.itemType.value = value
                }
                this.subjectFocusLocation += value.length
            } else {
                if (this.itemType.value) {
                    this.itemType.value += value
                } else {
                    this.itemType.value = value
                }
            }
        },
        insertLingualPlaceholder(args) {
            const currentValue = this.formData.value || ''
            this.formData.value = `${currentValue.slice(0, args.focus_location)}${args.placeholder_value}${currentValue.slice(args.focus_location)}`
        },
        setObjectItem(objectItem, index) {
            this.$set(this.formData.objects, index, objectItem)
        },
    },
    watch: {
        itemType: {
            handler(value) {
                this.formData = value
            },
            immediate: true,
            deep: true,
        },
        formData: {
            handler(value) {
                if (value) {
                    this.$emit('update', value)
                }
            },
            immediate: true,
            deep: true,
        }
    },
}
</script>

<style scoped>

</style>
