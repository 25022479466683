import { render, staticRenderFns } from "./TableRestrictionNumberCell.vue?vue&type=template&id=156bf879&scoped=true&"
import script from "./TableRestrictionNumberCell.vue?vue&type=script&lang=js&"
export * from "./TableRestrictionNumberCell.vue?vue&type=script&lang=js&"
import style0 from "./TableRestrictionNumberCell.vue?vue&type=style&index=0&id=156bf879&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "156bf879",
  null
  
)

export default component.exports