import {
    createWebsitePage, deleteWebsitePage,
    fetchWebsite,
    fetchWebsitePage,
    getWebsitePageList,
    updateWebsitePage
} from "@/services/direct_booking/website";
import Vue from 'vue';
import i18n from "@/locale";
import {WEBSITE_PAGE_TYPE_CUSTOM} from "@/shared/constants";

const state = {
    website: {},
    navigationPages: [],
    currentPage: null,
    newPageAsideState: false,
    activateUnits: false,
    unitTypeData: false,
    amenityCategory: false,
    newAmenityCategory: false,
    newUnitTypeData:false,
    newUnitTypeAside: false
}

const getters = {
    getWebsite(state) {
        return state.website
    },
    getNavigationPages(state) {
        return state.navigationPages.map(page => ({
            ...page,
            formattedLabel: page.type.id === WEBSITE_PAGE_TYPE_CUSTOM ? `${i18n.t('CUSTOM')} (${page.path})` : page.type.label
        }))
    },
    getCurrentPage(state) {
        return state.currentPage
    }
}

const mutations = {
    setWebsite(state, payload) {
        state.website = payload
        if (payload.name) {
            state.website.website = payload.name
        }
    },
    setNavigationPages(state, payload) {
        state.navigationPages = payload
    },
    setCurrentPage(state, payload) {
        state.currentPage = payload
    },
    setNewPageAsideState(state, payload) {
        state.newPageAsideState = payload
    },
    setActivationAsideState(state, payload){
        state.activateUnits = payload
    },
    setNewAmenityCategoryAsideState(state, payload){
        state.newAmenityCategory = payload
    },
    setUnitTypeData(state, payload){
        state.unitTypeData = payload
    },
    setAmenityCategory(state, payload){
        state.amenityCategory = payload
    },
    setNewUnitTypeData(state, payload){
        state.newUnitTypeData = payload
    },
    setNewUnitTypeAside(state, payload){
        state.newUnitTypeAside = payload
    },
    setLogo(state, payload) {
        state.website.logo_id = payload.id
        state.website.logo_url = payload.url
    },
    setHeroImage(state, payload) {
        state.website.hero_image_id = payload.id
        state.website.hero_image_url = payload.url
    },
}

const actions = {
    actionSetWebsite({commit}, id) {
        return Promise.all([
            fetchWebsite(id),
            getWebsitePageList(id, {per_page: 9999})
        ]).then(([websiteResponse, websitePagesResponse]) => {
            commit('setWebsite', websiteResponse.data)
            commit('setNavigationPages', websitePagesResponse.data.items)
            return websiteResponse
        })
    },
    actionSetCurrentPage({commit, state}, id) {
        return fetchWebsitePage(state.website.id, id).then(response => {
            commit('setCurrentPage', response.data)
            return response
        })
    },
    actionUpdateCurrentPage({commit, state}, data) {
        return updateWebsitePage(state.website.id, state.currentPage.id, data).then(response => {
            commit('setCurrentPage', response.data)
            // Update navigation page entry for this page
            if (state.navigationPages.length) {
                const pageIndex = state.navigationPages.findIndex(el => el.id === response.data.id)
                if (pageIndex > -1) {
                    Vue.set(state.navigationPages, pageIndex, response.data)
                }
            }
            return response
        })
    },
    actionCreatePage({commit, state}, data) {
        return createWebsitePage(state.website.id, data).then(response => {
            // Update navigation pages with new entry
            state.navigationPages.push(response.data)
            return response
        })
    },
    actionDeletePage({commit, state}, data) {
        return deleteWebsitePage(state.website.id, state.currentPage.id, data).then(() => {
            if (state.navigationPages.length) {
                const pageIndex = state.navigationPages.findIndex(el => el.id === state.currentPage.id)
                if (pageIndex > -1) {
                    state.navigationPages.splice(pageIndex, 1)
                }
                commit('setCurrentPage', null)
            }
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
