<template>
    <div>
        <app-table-v2 class="reservation_table" id="companyTable" :fields="fields"
                      :filter="filter" :show-limit="true" :provider="getCompanyListData"
                      @busyToggled="toggleBusy"
                      :selectable="true"
                      :http="{methods:'GET', url:COMPANY_LIST_URL}"
                      :exportColumns="exportColumns"
        >
            <template v-slot:expiration_date="data">
                {{ (data.item.expiration_date) ? data.item.expiration_date  : '' | date}}
            </template>
            <template v-slot:action_buttons="data">
                <app-button v-if="changeCompanyPermission"
                            variant="link"
                            button_type="switch"
                            :show_text="false"
                            @click="switchToCompany(data.item.id)">
                </app-button>

                <app-button variant="link"
                            button_type="edit"
                            :show_text="false"
                            :to="{name: routeNames.RN_COMPANY_BASIC, params:{id: data.item.id}}">
                </app-button>
            </template>
            <template v-slot:tableNoData="props">
                <app-no-data :show-tip="false" :heading="$t('NO_SEARCH_RESULTS')">
                </app-no-data>
            </template>
        </app-table-v2>
    </div>

</template>

<script>
    import routeNames from "@/router/routeNames";
    import {fetchCompany, getCompanyList} from "@/services/company";
    import moment from 'moment'
    import {isEmpty} from 'lodash'
    import {toast} from "@/shared/plugins/toastr";
    import AppSearchData from "@/components/app/AppSearchData";
    import AppNoData from "@/components/app/AppNoData";
    import {F_COMPANY_SWITCH} from "@/shared/function_permission";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import AppPagination from "@/components/app/filter/AppPagination";
    import AppTableV2 from "@/components/app/AppTableV2";
    import {EventBus, GE_TABLE_SEARCH} from "@/shared/EventBus";
    import {COMPANY_LIST_URL} from "@/shared/constants";

    export default {
        name: "CompanyListTable",
        components: {AppTableV2, AppPagination, AppButton, AppNoData, AppSearchData},
        mixins: [getErrorMessage],
        props: {
            filter: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                routeNames,
                tableData: [],
                fields: [
                    {key: 'id', label: 'ID'},
                    {key: 'name', label: this.$t('NAME')},
                    {key: 'tenant_type', label: this.$t('TYPE')},
                    {key: 'workflow', label: this.$t('WORKFLOW')},
                    {key: 'country', label: this.$t('COUNTRY')},
                    {key: 'city', label: this.$t('CITY')},
                    {key: 'address', label: this.$t('ADDRESS')},
                    {key: 'package', label: this.$t('PACKAGE')},
                    {key: 'expiration_date', label: this.$t('ACCOUNT_EXPIRATION_DATE')},
                    {key: 'action_buttons', label: '', class: 'text-right'},
                ],
                exportColumns:[
                    {value: 'id', text: 'ID'},
                    {value: 'name', text: this.$t('NAME')},
                    {value: 'tenant_type', text: this.$t('TYPE')},
                    {value: 'workflow', text: this.$t('WORKFLOW')},
                    {value: 'country', text: this.$t('COUNTRY')},
                    {value: 'city', text: this.$t('CITY')},
                    {value: 'address', text: this.$t('ADDRESS')},
                    {value: 'package', text: this.$t('PACKAGE')},
                    {value: 'expiration_date', text: this.$t('ACCOUNT_EXPIRATION_DATE')},
                ],
                isBusy: false,
                totalRows: 0,
                searchDone: false,
                lastPage: 0,
                COMPANY_LIST_URL
            }
        },
        computed: {
            currentPage: {
                get() {
                    return this.filter.page
                },
                set(value) {
                    this.filter.page = value
                }
            },
            changeCompanyPermission() {
                return this.$store.getters['user/getPermission'](F_COMPANY_SWITCH)
            }
        },
        watch: {
            filter() {
                EventBus.$emit(GE_TABLE_SEARCH)
            }

        },
        methods: {
            toggleBusy($event){
                this.$emit('busyToggled', $event)
            },

            getCompanyListData(filter){
                let req = {
                        ...this.filter,
                        ...{
                            page: filter.currentPage,
                            limit: filter.perPage,
                        }
                }
                return getCompanyList(req)
            },
            getFormattedDate(value) {
                return new moment(value).format(this.$t('DATE.DATE_FORMAT'))
            },
            switchToCompany(company_id) {
                fetchCompany(company_id).then(response => {
                    this.$store.dispatch('user/actionSetCurrentCompany', response.data)
                }).catch(error => {
                    this.showErrorMessages(error)
                })

                this.$store.dispatch('user/actionSetCurrentCompanyId', company_id).then(() => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('COMPANY_TEMP_CHANGE'),
                        'type': 'success',
                        'timeout': 4000
                    })
                })
            }
        },

    }
</script>

<style scoped>

</style>
