<template>
    <div>
        <app-table-v2 class="reservation_table" id="propertyTable" :fields="getTableFields" :filter="filter" :provider="getPropertyListData"
                      :search-on-create="!hasFilter"
                      @busyToggled="toggleBusy"
                      :selectable="selectable"
                      :http="{methods:'POST', url:PROPERTY_LIST_URL}"
                      :exportColumns="computedExportColumns"
        >
            <template v-slot:id="data">
                <small v-if="companyHasAdsIntegrated === true && data.item.hasOwnProperty('external_id') && data.item.external_id" :title="$t('EXTERNAL_ID')" class="text-muted d-block">
                    {{data.item.external_id}}
                </small>
                {{data.item.id}}
            </template>
            <template v-slot:categorization="data">
                <template v-if="data.item.categorization > 0">
                    <i :key="numberCat" v-for="(numberCat) of data.item.categorization" class="fa fa-star-o fa-fw"
                       aria-hidden="true"></i>
                </template>
                <template v-else>
                    &nbsp;
                </template>
            </template>
            <template v-slot:distributions="data">
                <div class="d-flex flex-wrap">
                    <div v-for="distribution in data.item.distributions" style="width: 20px; cursor:pointer" v-b-tooltip.hoover placment.bottom :title="getMessage(distribution)">
                        <b-tooltip
                                    placement="top"
                                    triggers="hover"
                                    :title="getMessage(distribution)"
                                    :target="data.item.id + 'distribution' + distribution.id"
                        >
                        </b-tooltip>
                        <b-img style="width: 18px;" :src="'data:image/png;base64,'+ distribution.icon"
                               alt="logo" :id="data.item.id + 'distribution' + distribution.id"></b-img>

                    </div>
                </div>
            </template>
            <template v-slot:edit="data">
                <app-button v-if="isSetupReservationProcessDistributionPropertyRoute || isSetupFeesPlatformFeeDistributionPropertyRoute"
                            variant="link"
                            button_type="edit"
                            :show_text="false"
                            @click="openAside(data)"
                            >
                </app-button>
                <app-button v-else-if="setup_parameter && setup_parameter !== SETUP_PARAMETER_DATA_TRANSITION_AFTER_EVENT"
                            variant="link"
                            button_type="edit"
                            :show_text="false"
                            @click="openAsideParameter(data)">
                </app-button>
                <app-button v-else-if="setup_parameter && setup_parameter === SETUP_PARAMETER_DATA_TRANSITION_AFTER_EVENT"
                            variant="link"
                            button_type="edit"
                            :show_text="false"
                            @click="openDataTransition(data)">
                </app-button>
                <app-button v-else variant="link"
                            button_type="edit"
                            :show_text="false"
                            :to="{name: routeNames.RN_PROPERTY_OVERVIEW, params:{id: data.item.id}}">
                </app-button>
            </template>
            <template v-slot:tableNoData="props">
                <app-no-data v-if="empty_initial_list" :show-tip="false">
                    <new-button v-if="checkPermission(R_PROPERTY_NEW)" button_type="new" variant="primary" class="pull-right ml-3" @click="aside=true">{{ $t("PROPERTY_NEW") }}
                    </new-button>
                    <app-button
                        :access_control_context="{function: C_PROPERTY_IMPORT_FROM_OTA, key: access_control_fetch_key}"
                        @AccessControlEvent="addToAccessControlCounter()"
                        class="pull-right ml-3"
                        @click="importPropertyModalState = true">
                        {{ $t("IMPORT_FROM_OTA") }}
                    </app-button>
               </app-no-data>
                <app-no-data v-else :show-tip="false" :heading="$t('NO_SEARCH_RESULTS')"></app-no-data>
            </template>
            <template v-slot:total_units_count="data" v-if="checkPermission(R_PROPERTY_UNIT_LIST)">
                <b-link
                        :to="{name: routeNames.RN_PROPERTY_UNIT_LIST, params: {id: data.item.id}}"
                        target="_blank">
                    {{ data.item.total_units_count }}
                </b-link>
            </template>

            <!--
            <template v-slot:selectedContainerOptions="data">
                <template v-if="companyHasAdsIntegrated === true">
                    <app-button @click="exportUnitsModal = true" class="pull-right ml-3 mr-3">{{$t('EXPORT') + ': ' + $t('UNITS').toLowerCase()}}</app-button>

                    <b-modal :title="$t('EXPORT') + ': ' + $t('UNITS').toLowerCase()" v-model="exportUnitsModal">
                        <b-checkbox :checked="true" @change="updateUnitsSelectAllExport">
                            {{$t("SELECT_ALL")}}
                        </b-checkbox>
                        <hr class="p-0 m-0 mb-1 mt-1">
                        <b-checkbox-group class="two_col_checkbox_group" v-model="exportUnitsActionSelected" stacked
                                          :options="computedExportUnitsColumns"></b-checkbox-group>
                        <template v-slot:modal-footer>
                            <div class="w-100 d-flex justify-content-between align-items-center">
                                <div v-if="data.totalRows > 1 && data.totalRows > data.selected.length">
                                    <b-checkbox v-model="selectAllForUnitsExport">
                                        {{$t("APPLY_TO_ALL_NUMBER", {number: data.totalRows})}}
                                    </b-checkbox>
                                    <small class="text-muted">
                                        {{$t('EXPORT_MAX_LIMIT_WARNING', {num: maxUnitsExportLimit})}} ({{$t('UNITS').toLowerCase()}})
                                    </small>
                                </div>
                                <div>
                                    <b-checkbox v-model="downloadUnitsPdf">
                                        Pdf
                                    </b-checkbox>
                                    <b-checkbox v-model="downloadUnitsXls">
                                        Xls
                                    </b-checkbox>
                                </div>
                                <app-button :disabled="disabledUnitsExport" :loading="exportUnitsLoading" @click="downloadUnitsExport(data.selected)">
                                    {{$t('EXPORT')}}
                                </app-button>
                            </div>
                        </template>
                    </b-modal>
                </template>
            </template>
            -->
        </app-table-v2>
        <b-modal
            v-model="importPropertyModalState"
            :title="$t('IMPORTANT')">
            <p>{{ $t("IMPORT_FROM_DISTRIBUTION", {"distribution": "Booking.com"}) }}</p>
            <ul>
                <li>{{ $t("IMPORT_PROPERTY_FROM_BOOKING_DISCLAIMER_1") }}</li>
                <li>{{ $t("IMPORT_PROPERTY_ADDITIONAL_COSTS_DISCLAIMER") }}</li>
            </ul>
            <app-button
                @click="importFromDistribution(BOOKING)">
                {{ $t("IMPORT") }}
            </app-button>
            <br/>
            <br/>
            <p>{{ $t("IMPORT_FROM_DISTRIBUTION", {"distribution": "AirBnB"}) }}</p>
            <ul>
                <li>{{ $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_1") }}</li>
                <li>{{
                        $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_2", {
                            "min_image_width": 2048,
                            "min_image_height": 1536
                        })
                    }}
                </li>
                <li>{{ $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_3") }}</li>
                <li>{{ $t("IMPORT_PROPERTY_ADDITIONAL_COSTS_DISCLAIMER") }}</li>
            </ul>
            <app-button
                @click="importFromDistribution(AIRBNB)">
                {{ $t("IMPORT") }}
            </app-button>
            <template slot="modal-footer">
                <template slot="modal-ok">
                </template>
            </template>
        </b-modal>
        <app-aside
            v-model="importPropertyWizardState"
            :widths="['col-lg-12', '100%']">
            <template slot="header">{{ $t('IMPORT_FROM_OTA') }}</template>
            <import-property-wizard
                @importProperty="closeImportWizard"
                @close="closeImportWizard"
                :distribution="importDistribution"
                :entity="importEntity">
            </import-property-wizard>
        </app-aside>

        <app-aside :widths="['col-sm-12', '40%']" v-model="aside">
            <template slot="header">{{$t('NEW_PROPERTY')}}</template>
            <property-wizard></property-wizard>
        </app-aside>
        <app-aside v-model="showSetupParameters">
            <template slot="header">
                <app-object-header
                    :name="asideHeader"
                    :label="$t('OWNER')">
                </app-object-header>
            </template>
            <template v-if="channelList.length" slot="header_action_button">
                <div class="aside__header--action_button col-4 mr-3">
                    <label class="label">{{ $t('CHANNEL') }}</label>
                    <app-select mode="select" :options="channelList" v-model="selectedChannel" value-field="id" text-field="label" :search-empty-item="false"></app-select>
                </div>
            </template>
            <reservation-setup-parameter :parameter_id="setup_parameter" :property_id="propertyId" :context_company="company.id" @saved="showSetupParameters=false"></reservation-setup-parameter>
        </app-aside>
        <app-aside v-model="dataTransitionSidebar">
            <template slot="header">
                <app-object-header
                    :name="asideHeader"
                    :label="$t('OWNER')">
                </app-object-header>
            </template>
            <reservation-process-data-transition :parameter_id="setup_parameter" :property_id="propertyId" @saved="dataTransitionSidebar=false"></reservation-process-data-transition>
        </app-aside>
        <app-aside
            v-model="showAside"
            :widths="isSetupFeesPlatformFeeDistributionPropertyRoute ? ['col-lg-12','col-sm-12', '100%'] : ['col-lg-5', 'col-sm-12', '40%']"
            :full-width="isSetupFeesPlatformFeeDistributionPropertyRoute">
            <template slot="header">
                <app-object-header
                    :name="asideHeader"
                    :label="$t('PROPERTY')">
                </app-object-header>
            </template>
            <template v-if="channelList.length" slot="header_action_button">
                <div class="aside__header--action_button col-4 mr-3">
                    <label class="label">{{ $t('CHANNEL') }}</label>
                    <app-select mode="select" :options="channelList" v-model="selectedChannel" value-field="id" text-field="label" :search-empty-item="false"></app-select>
                </div>
            </template>
            <distribution-reservation-sidebar
                v-if="isSetupReservationProcessDistributionPropertyRoute"
                :distribution_id="selectedChannel ? null : distribution_id"
                :distribution_context="selectedChannel ? distribution_id : null"
                :channel_id="selectedChannel"
                @saved="showAside=false"
                :property_id="propertyId">
            </distribution-reservation-sidebar>
            <platform-fee-edit-form
                v-else-if="isSetupFeesPlatformFeeDistributionPropertyRoute"
                :distribution-id="selectedChannel ? null : distribution_id"
                :channel-id="selectedChannel"
                :channel-manager-distribution-id="selectedChannel ? distribution_id : null"
                preset-current-year
                :object-id="propertyId"
                :object-type-id="OBJECT_TYPE_PROPERTY">
            </platform-fee-edit-form>
        </app-aside>
    </div>
</template>

<script>
    import routeNames from '@/router/routeNames'
    import NewButton from '@/components/app/AppButton/AppButton'
    import {C_PROPERTY_IMPORT_FROM_OTA, R_PROPERTY_NEW, R_PROPERTY_UNIT_LIST} from "@/shared/route_permission";
    import {R_PROPERTY_BASIC, R_PROPERTY_OVERVIEW} from '@/shared/route_permission'
    import AppNoData from "@/components/app/AppNoData";
    import AppSearchData from "@/components/app/AppSearchData";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {TableRule} from "@/mixins/Table/TableRule";
    import {EventBus, GE_TABLE_SEARCH} from "@/shared/EventBus";
    import {cloneDeep, has, get} from 'lodash'
    import {getPropertyList} from "@/services/property";
    import AppPagination from "@/components/app/filter/AppPagination";
    import AppTable from "@/components/app/AppTable";
    import {
        AIRBNB,
        BOOKING,
        DEFAULT_PAGINATION_LIMIT,
        DISTRIBUTION_MANAGERS,
        LS_PRESETS, OBJECT_TYPE_PROPERTY,
        PROPERTY_LIST_URL,
        SETUP_PARAMETER_DATA_TRANSITION_AFTER_EVENT
    } from "@/shared/constants";
    import AppTableV2 from "@/components/app/AppTableV2";
    import {downloadGenericExportFile} from "@/services/document";
    import AppObjectHeader from "@/components/app/AppObjectHeader";
    import DistributionReservationSidebar
        from "@/components/channel_manager/distribution/DistributionReservationSidebar";
    import AppAside from "@/components/app/form/AppAside"
    import {API_CONFIG} from "@/services/config";
    import {UNIT_LIST_URL} from "@/services/endpoints";
    import {getDistribution, getDistributionChannelList} from "@/services/distribution";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import ReservationSetupParameter from "@/components/reservation/setup/ReservationSetupParameter";
    import ReservationProcessDataTransition from "@/components/reservation/setup/ReservationProcessDataTransition";
    import {AC_PROPERTY_IMPORT} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {getPresets} from "@/services/user";
    import PropertyWizard from "@/components/property/new/PropertyWizard";
    import ImportPropertyWizard from "@/components/property/import/ImportPropertyWizard";
    import PlatformFeeEditForm from "@/components/fee/platform_fee/PlatformFeeEditForm";

    export default {
        name: "PropertyListTable",
        components: {
            PlatformFeeEditForm,
            ReservationProcessDataTransition,
            ReservationSetupParameter,
            AppSelect,
            DistributionReservationSidebar,
            AppObjectHeader, AppTableV2, AppTable, AppPagination, AppButton, AppSearchData, AppNoData,
            AppAside, NewButton,PropertyWizard, ImportPropertyWizard},
        mixins: [TableRule, AccessControlComponent],
        props:{
            hasFilter:{
                type: Boolean,
                default: true
            },
            empty_initial_list:{
                type:Boolean,
                default:false
            },
            distribution_id: {
                type: Number,
                default: null
            },
            selectable:{
                type: Boolean,
                default: true
            },
            setup_parameter: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                routeNames,
                C_PROPERTY_IMPORT_FROM_OTA,
                R_PROPERTY_NEW,
                R_PROPERTY_UNIT_LIST,
                fields: [
                    {key: 'id', label: this.$i18n.t('ID')},
                    {key: 'name', label: this.$i18n.t('NAME')},
                    {key: 'property_type.label', label: this.$i18n.t('TYPE')},
                    {key: 'total_units_count', label: this.$t('UNITS')},
                    {key: 'categorization', label: this.$i18n.t('CATEGORIZATION')},
                    {key: 'location.country.name', label: this.$i18n.t('COUNTRY')},
                    {key: 'location.city.name', label: this.$i18n.t('CITY')},
                    {key: 'account_manager.name', label: this.$i18n.t('ACCOUNT_MANAGER')},
                    {key: 'status.label', label: this.$i18n.t('STATUS')},
                    {key: 'distributions', label: this.$i18n.t('DISTRIBUTION')},
                ],
                custom_fields: [
                    {key: 'edit', label: '', class: 'text-right', permission: R_PROPERTY_OVERVIEW},
                ],
                filter: {
                    page: 1,
                    limit: DEFAULT_PAGINATION_LIMIT,
                    company_id: null,
                    status_id: [],
                    categorization: [],
                    property_type: null,
                    country: null,
                    city: null,
                    name: null,
                    address: null,
                    account_manager: null,
                    filter:0,
                },
                list: [],
                total: null,
                lastPage: null,
                dataLoaded: false,
                loading: false,
                PROPERTY_LIST_URL,
                exportColumns: [
                    {value: 'id', text: this.$i18n.t('ID')},
                    {value: 'name', text: this.$i18n.t('NAME')},
                    {value: 'property_type.label', text: this.$i18n.t('TYPE')},
                    {value: 'total_units_count', text: this.$i18n.t('UNITS')},
                    {value: 'categorization', text: this.$i18n.t('CATEGORIZATION')},
                    {value: 'location.country.name', text: this.$i18n.t('COUNTRY')},
                    {value: 'location.city.name', text: this.$i18n.t('CITY')},
                    {value: 'account_manager.name', text: this.$i18n.t('ACCOUNT_MANAGER')},
                    {value: 'external_id', text: this.$i18n.t('EXTERNAL_ID')},
                    {value: 'status.label', text: this.$i18n.t('STATUS')},
                ],
                exportUnitsModal: false,
                exportUnitsActionSelected: [],
                selectedObjectsForUnitsExport: [],
                selectAllForUnitsExport: false,
                maxUnitsExportLimit: 99999,
                exportUnitsLoading: false,
                downloadUnitsPdf: false,
                downloadUnitsXls: false,
                showAside: false,
                propertyId: null,
                asideHeader: null,
                distribution: { id: null, name: null},
                selectedChannel: null,
                channelList: [],
                showSetupParameters: false,
                dataTransitionSidebar: false,
                SETUP_PARAMETER_DATA_TRANSITION_AFTER_EVENT,
                access_control_components: 1,
                access_control_fetch_key: AC_PROPERTY_IMPORT,
                aside: false,
                importPropertyModalState: false,
                importPropertyWizardState: false,
                BOOKING,
                AIRBNB,
                importDistribution: null,
                importEntity: null,
                OBJECT_TYPE_PROPERTY,
            }
        },
        computed: {
            company_id() {
                return this.$store.getters['user/getCompany']
            },
            getTableFields() {
                return this.getFields(this.list, {fields: this.fields, custom_fields: this.custom_fields})
            },
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            companyHasAdsIntegrated() {
                return !!(this.company && this.company.hasOwnProperty('ads_integrated') && this.company.ads_integrated)
            },
            computedExportColumns() {
                let data = this.exportColumns
                if (!this.companyHasAdsIntegrated) {
                    data = data.filter(el => el.value !== 'external_id')
                }
                return data
            },
            disabledUnitsExport() {
                return (!this.downloadUnitsPdf && !this.downloadUnitsXls) || this.exportUnitsActionSelected.filter(item => item.startsWith('property.') === false).length === 0
            },
            computedExportUnitsColumns() {
                let data = [
                    {value: 'property.id', text: this.$i18n.t('PROPERTY') + ': ' + this.$i18n.t('ID').toLowerCase()},
                    {value: 'property.name', text: this.$i18n.t('PROPERTY') + ': ' + this.$i18n.t('NAME').toLowerCase()},
                    {value: 'property.external_id', text: this.$i18n.t('PROPERTY') + ': ' + this.$i18n.t('EXTERNAL_ID').toLowerCase()},
                    {value: 'id', text: this.$i18n.t('ID'),},
                    {value: 'name', text: this.$i18n.t('NAME')},
                    {value: 'type', text: this.$i18n.t('TYPE')},
                    {value: 'unit_type.name', text: this.$i18n.t('GROUP_NAME')},
                    {value: 'contigent.is_contigent', text: this.$i18n.t('NUMBER_OF_UNITS')},
                    {value: 'contigent.parent', text: this.$i18n.t('PARENT_UNIT')},
                    {value: 'max_guests', text: this.$i18n.t('MAX_GUESTS')},
                    {value: 'number_bedrooms', text: this.$i18n.t('NUMBER_OF_BEDROOMS')},
                    {value: 'external_id', text: this.$i18n.t('EXTERNAL_ID')},
                ]
                if (!this.companyHasAdsIntegrated) {
                    data = data.filter(el => el.value !== 'property.external_id')
                    data = data.filter(el => el.value !== 'external_id')
                }
                return data
            },
            selectedExportUnitsColumns() {
                let data = []
                if (this.exportUnitsActionSelected.length) {
                    this.computedExportUnitsColumns.map(el => {
                        this.exportUnitsActionSelected.forEach(item => {
                            if (item === el.value) {
                                data.push({
                                    key: el.value,
                                    label: el.text
                                })
                            }
                        })
                    })
                }
                return data
            },
            isSetupReservationProcessDistributionPropertyRoute() {
                return this.$route.name === routeNames.RN_SYSTEM_DISTRIBUTION_PROPERTY
            },
            isSetupFeesPlatformFeeDistributionPropertyRoute() {
                return this.$route.name === routeNames.RN_SETUP_FEES_PLATFORM_FEE_DISTRIBUTION_PROPERTY
            },
        },
        methods: {
            importPropertyTermsConfirmed() {
                this.importPropertyModalState = false
                this.importPropertyWizardState = true
            },
            importFromDistribution(distributionId) {
                this.importDistribution = distributionId
                this.importPropertyTermsConfirmed()
            },
            closeImportWizard() {
                this.importPropertyWizardState = false
                this.importDistribution = null
                this.importEntity = null
                this.$router.replace({"query": null})
            },
            getMessage(distribution) {
                let message = distribution.message + ' ' + this.$i18n.t('ACTIVE')
                return message
            },
            getPropertyListData(filter){
                this.mapToRequest(filter)
                let req = {
                    ...this.filter,
                    ...{
                        company_id: this.company.id,
                        page: filter.currentPage,
                        limit: filter.perPage,
                    }
                }
                let provider =  getPropertyList(req)
                provider.then(response =>{
                    this.list = response.data.items
                })
                return provider
            },
            mapToRequest(filter) {

                this.filter.page = 1
                if (has(filter, 'name')) {
                    this.filter.name = filter.name
                }
                if (has(filter, 'address')) {
                    this.filter.address = filter.address
                }
                if (has(filter, 'status_id')) {
                    this.filter.status_id = filter.status_id
                }
                if (has(filter, 'account_manager.id')) {
                    this.filter.account_manager = filter.account_manager.id
                }
                if (has(filter, 'owner.id')) {
                    this.filter.owner = filter.owner.id
                }
                if (has(filter, 'categorization')) {
                    this.filter.categorization = filter.categorization
                }
                if (has(filter, 'property_type')) {
                    this.filter.property_type = filter.property_type
                }
                if (has(filter, 'city.id')) {
                    this.filter.city = filter.city.id
                }
                if (has(filter, 'country.id')) {
                    this.filter.country = filter.country.id
                }
            },
            toggleBusy($event) {
                this.loading = $event
                EventBus.$emit('propertyListLoading', $event)
            },
            updateUnitsSelectAllExport($event) {
                if ($event) {
                    this.exportUnitsActionSelected = this.computedExportUnitsColumns.map(el => el.value)
                } else {
                    this.exportUnitsActionSelected = []
                }
            },
            downloadGenericUnitsFile(filetype) {
                this.exportUnitsLoading = true
                let filter = {
                    page: 1,
                    limit: this.maxUnitsExportLimit,
                    company_id: this.company.id,
                    property_id: !this.selectAllForUnitsExport ? this.selectedObjectsForUnitsExport.map(el => el.id) : null,
                    account_manager: this.filter.account_manager,
                    // unit filter
                    order_by: 'property',
                    contigent: 1,
                }

                downloadGenericExportFile({
                    fields: this.selectedExportUnitsColumns,
                    data: [],
                    filter: filter,
                    url: API_CONFIG.API_URL + UNIT_LIST_URL,
                    methods: 'POST'
                }, filetype).finally(() => {
                    this.exportUnitsLoading = false
                    this.selectedObjectsForUnitsExport = []
                    this.selectAllValue = false
                    this.exportUnitsModal = false
                })
            },
            downloadUnitsExport(selected) {
                this.getActionFilterForUnitsExport(selected)
                if (this.downloadUnitsXls) {
                    this.downloadGenericUnitsFile('xlsx')
                }
                if (this.downloadUnitsPdf) {
                    this.downloadGenericUnitsFile('pdf')
                }
            },
            getActionFilterForUnitsExport(selected) {
                if (!this.selectAllForUnitsExport) {
                    this.selectedObjectsForUnitsExport = selected
                }
            },
            openAside(data) {
                this.showAside = true
                this.asideHeader = data.item.name
                this.propertyId = data.item.id
            },
            openAsideParameter(data){
                this.showSetupParameters = true
                this.asideHeader = data.item.name
                this.propertyId = data.item.id
            },
            openDataTransition(data){
                this.dataTransitionSidebar = true
                this.asideHeader = data.item.name
                this.propertyId = data.item.id
            },
            getChannelList() {
                getDistributionChannelList({distribution_id: this.distribution_id}).then(response => {
                    this.channelList = response.data
                    this.selectedChannel = this.channelList[Object.keys(this.channelList)[0]].id
                })
            },
            setFromStoragePresets(storagePresets) {
                if (storagePresets && typeof storagePresets === 'object') {
                    if (storagePresets.hasOwnProperty('properties') && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                        if (storagePresets.properties === 0){
                            this.empty_initial_list = true
                        }
                        this.hasFilter = false
                    }
                }
            },
        },
        watch: {
            exportUnitsModal: {
                handler(value) {
                    if (value) {
                        this.exportUnitsActionSelected = this.computedExportUnitsColumns.map(el => el.value)
                    }
                }
            },
            filter: {
                handler() {
                    this.filter.company_id = this.company_id
                    EventBus.$emit(GE_TABLE_SEARCH)
                },
                deep: true
            },
            "$route.query": {
                handler(queryParams) {
                    const queryParamKeys = Object.keys(queryParams)
                    if (queryParamKeys.length > 0) {
                        if (queryParamKeys.indexOf("import") >= 0 && Number(queryParams.import) === 1 && queryParamKeys.indexOf("one_time_error") < 0) {
                            const distributionId = queryParamKeys.indexOf("distribution") >= 0 ? Number(queryParams.distribution) : null
                            if (distributionId === AIRBNB) {
                                const hostId = queryParamKeys.indexOf("host") >= 0 ? queryParams.host : null
                                if (hostId) {
                                    this.importDistribution = AIRBNB
                                    this.importEntity = hostId
                                    this.importPropertyWizardState = true
                                }
                            }
                        }
                    }
                },
                immediate: true,
            },
            distribution_id: {
                handler(value) {
                    if (!!value) {
                        if (DISTRIBUTION_MANAGERS.indexOf(value) >= 0) {
                            this.getChannelList()
                        } else {
                            this.selectedChannel = null
                            this.channelList = []
                        }
                    } else {
                        this.selectedChannel = null
                        this.channelList = []
                    }
                },
                immediate: true,
            },
        },
        mounted(){
            EventBus.$on("PropertyFilterSearch", filter => {
                this.filter = cloneDeep(filter)
                this.mapToRequest(filter)
            })
        },
        beforeDestroy() {
            EventBus.$off('PropertyFilterSearch')
        },
        created(){
            let storagePresets = localStorage.getItem(LS_PRESETS)
            if (storagePresets) {
                storagePresets = JSON.parse(storagePresets)
                this.setFromStoragePresets(storagePresets)
            } else {
                getPresets().then(response => {
                    localStorage.setItem(LS_PRESETS, JSON.stringify(response.data))
                    this.setFromStoragePresets(response.data)
                })
            }
            this.access_control_general_context = {
                company: this.company_id
            }
        }

    }
</script>

<style scoped>
</style>
