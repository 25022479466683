<template>
    <div >
        <b-form @submit.prevent="saveRootBookingWindow">
            <b-row>
                <b-col lg="3">
                    <label class="label">{{$t('NAME')}}</label>
                    <b-input @change="updateRootList"  v-model="rootBookingWindow.name"></b-input>
                </b-col>
                <b-col lg="3">
                    <label class="label">{{$t('TYPE')}}</label>
                    <app-select @change="updateRootList"  v-model="rootBookingWindow.booking_window_setup" text-field="title" value-field="id" :options="type_options"></app-select>
                </b-col>
                <b-col lg="3">
                    <label class="label">Interval from</label>
                    <app-number-input @change="updateRootList" v-model="rootBookingWindow.interval_from"></app-number-input>
                </b-col>

                <b-col lg="3">
                    <label class="label">Interval to</label>
                    <app-number-input @change="updateRootList"  v-model="rootBookingWindow.interval_to"></app-number-input>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col lg="6">
                    <app-button-submit :loading="loader"></app-button-submit>
                </b-col>
                <b-col lg="6" v-if="mode === 'edit'">
                    <app-button-delete class="pull-right" @click="confirmDialog = true"></app-button-delete>
                </b-col>

            </b-row>
        </b-form>
        <template v-if="mode === 'edit'|| (mode === 'new' && saved)">
            <form-header>Child booking windows</form-header>
            <b-form @submit.prevent="saveChildWindow">
                <b-row>
                    <b-col>
                        <table class="table">
                            <thead>
                            <tr>

                                <th class="table-header-action" scope="col">Interval from</th>
                                <th class="table-header-action" scope="col">Interval to</th>
                                <th class="table-header-actions" scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr :key="index" v-for="(childBookingWindow,index) of bookingWindowChildList">

                                <td>
                                    <app-number-input @change="setUpdateList(childBookingWindow)" v-model="childBookingWindow.interval_from"></app-number-input>
                                </td>
                                <td>
                                    <app-number-input @change="setUpdateList(childBookingWindow)" v-model="childBookingWindow.interval_to"></app-number-input>
                                </td>
                                <td>
                                    <app-button @click="removeRow(index)" class="pull-right" button_type="delete" :show_text="false" variant="link"></app-button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <app-number-input  v-model="interval_from" ></app-number-input>
                                </td>
                                <td>
                                    <app-number-input   v-model="interval_to" ></app-number-input>
                                </td>
                                <td>
                                    <app-button :disabled="interval_from === null || interval_to === null" @click="addNewItem" class="pull-right" button_type="new">{{$t('ADD_ITEM')}}</app-button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col>

                </b-row>
                <b-row class="mt-3">
                    <b-col>
                        <app-button-submit :loading="loader_children"></app-button-submit>
                    </b-col>
                </b-row>
            </b-form>
        </template>

        <app-confirmation-dialog    :show="confirmDialog" @cancel="confirmDialog=false" @confirm="deleteRoot"></app-confirmation-dialog>
    </div>

</template>

<script>
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {
    getBookingWindowSetup,
    getBookingWindowSetupType,
    getChildBookingWindow, setChildBookingWindow,
    setRootBookingWindow
} from "@/services/revenue_manager";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import AppButton from "@/components/app/AppButton/AppButton";
import FormHeader from "@/components/app/form/FormHeader";

import AppSelect from "@/components/app/AppSelect/AppSelect";
import {toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {EventBus} from "@/shared/EventBus";
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";


export default {
    name: "RootBookingWindow",
    components: {
        AppConfirmationDialog,
        AppButtonDelete, AppSelect, FormHeader, AppButton, AppNumberInput, AppButtonSubmit},
    mixins:[getErrorMessage],
    props: {
        root_booking_window_id: {
            type: Number,

        },
        mode:{
            type:String,
            default:'new'
        }
    },
    data() {
        return {
            confirmDialog:false,
            bookingWindowObject: {},
            loader: false,
            rootBookingWindow:{
                name:null,
                booking_window_setup :null,
                interval_from:null,
                interval_to: null
            },
            bookingChildren:{},
            bookingChildrenNew:{},
            interval_from: null,
            interval_to: null,
            type_options:[],
            saved:false,
            loader_children:false,
            fetch_data:false,
            bookingWindowChildList:[],
            initialBookingWindowChild:[],
            initialBookingWindowChildIds:[],
            deletedObject:[],
            saveObjectList:[],
            edit_root:false,
            id:null

        }
    },
    computed: {

        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods: {
        deleteRoot(){
            let request = {
                company:this.company.id,
                booking_windows:[]
            }
            let object = this.rootBookingWindow
            object.id =  this.root_booking_window_id ? this.root_booking_window_id : null
            object.delete = true
            request.booking_windows.push(object)
            setRootBookingWindow(request).then(response =>{
                this.saved = response.data
                this.$emit('closeAside')
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })
                EventBus.$emit('gl_reload_booking_windows')
            }, error=>{
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 3000
                })
            }).finally(()=>{
                this.confirmDialog = false
            })
        },
        updateRootList(){
            this.edit_root = true
        },
        setUpdateList(object){
           this.saveObjectList.push(object)
        },
        removeRow(index){
            this.deletedObject.push(this.bookingWindowChildList[index])

            this.bookingWindowChildList.splice(index,1)
        },
        addNewItem(){
            const object = {id:null, interval_from: this.interval_from, interval_to: this.interval_to}
            this.saveObjectList.push(object)
            this.bookingWindowChildList.push(object)
            this.interval_from = null
            this.interval_to = null
        },

        saveRootBookingWindow() {
            this.loader = true
            let request = {
                company:this.company.id,
                booking_windows:[]
            }
            let object = this.rootBookingWindow
            object.id =  this.root_booking_window_id ? this.root_booking_window_id : this.id
            object.delete = false
            request.booking_windows.push(object)

            setRootBookingWindow(request).then(response =>{

                if(Array.isArray(response.data) && response.data.length === 1){
                  this.id = response.data[0]
                }
                this.saved = true
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })
                EventBus.$emit('gl_reload_booking_windows')
            }, error=>{
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 3000
                })
            }).finally(()=>{
                this.loader = false
            })
        },
        saveChildWindow() {
            let request = {
                company:this.company.id,
                booking_windows: []
            }
            if (this.deletedObject.length > 0) {
                this.deletedObject.forEach(object=>{
                    request.booking_windows.push({
                        id: object.id,
                        booking_window_setup: this.rootBookingWindow.booking_window_setup,
                        interval_from: object.interval_from,
                        interval_to: object.interval_to,
                        delete:true
                    })
                })
            }
            if (this.saveObjectList.length > 0) {
                const delete_ids = this.deletedObject.map(el=>{
                    return el.id
                })
                for (const object of this.saveObjectList) {
                    if(!delete_ids.includes(object.id)){
                        request.booking_windows.push(
                            {   id:object.id,
                                name:null,
                                booking_window_setup: this.rootBookingWindow.booking_window_setup,
                                interval_from:object.interval_from,
                                interval_to:object.interval_to,
                                delete:false
                            })
                    }
                }
                this.loader_children = true
                const root_id = this.mode === 'new' ? this.id : this.root_booking_window_id
                setChildBookingWindow(root_id, request).then(response => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                    EventBus.$emit('gl_reload_booking_windows')
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.getMessages(error),
                        'type': 'error',
                        'timeout': 3000
                    })
                }).finally(() => {
                    this.loader_children = false
                })
            }
        }
    },
    watch: {
        root_booking_window_id: {
            handler(value) {
                if (value) {
                    getChildBookingWindow(value).then(response => {
                        this.bookingWindowObject = response.data
                        this.rootBookingWindow.name = response.data.title
                        this.rootBookingWindow.interval_from = response.data.interval_from
                        this.rootBookingWindow.interval_to = response.data.interval_to
                        this.rootBookingWindow.booking_window_setup = response.data.booking_window_setup.id
                        this.bookingWindowChildList = Object.keys(this.bookingWindowObject).length > 0 && this.bookingWindowObject.hasOwnProperty('child_booking_windows') ? this.bookingWindowObject.child_booking_windows : []
                        this.initialBookingWindowChildIds = Object.keys(this.bookingWindowObject).length > 0 && this.bookingWindowObject.hasOwnProperty('child_booking_windows') ? this.bookingWindowObject.child_booking_windows.map(item=>{
                            return item.id
                        }) : []
                        this.initialBookingWindowChild  = Object.keys(this.bookingWindowObject).length > 0 && this.bookingWindowObject.hasOwnProperty('child_booking_windows') ? this.bookingWindowObject.child_booking_windows : []
                        if(this.bookingWindowObject.hasOwnProperty('child_booking_windows') && this.bookingWindowObject.child_booking_windows.length > 0){
                            this.bookingWindowObject.child_booking_windows.forEach((child, index)=>{
                                this.bookingChildren[index] = child
                            })
                        }
                        this.bookingChildrenNew['interval_from'] = null
                        this.bookingChildrenNew['interval_to'] = null
                        this.fetch_data = true
                    })
                }
            }, immediate: true
        },

    },
    created(){
        getBookingWindowSetup({company:this.company.id}).then(response=>{
            this.type_options = response.data.items
        })
    }
}
</script>

<style scoped>

</style>
