<template>
    <b-card class="main-section-card">
        <payment-order-invoice-filter
            :payment-order-id="paymentOrder.id">
        </payment-order-invoice-filter>
        <payment-order-invoice-list
            :search-on-create="false"
            :payment-order-id="paymentOrder.id"
            :payment-order="paymentOrder"
            @paymentOrderRemoved="paymentOrderRemoved">
        </payment-order-invoice-list>
    </b-card>
</template>

<script>
import PaymentOrderInvoiceFilter from "@/components/finance/invoices/PaymentOrderInvoiceFilter";
import PaymentOrderInvoiceList from "@/components/finance/invoices/PaymentOrderInvoiceList";
import {getManualInvoiceSetupList} from "@/services/finance/setup";
import {ICL_INVOICE_LIST} from "@/shared/constants";
import AppButton from "@/components/app/AppButton/AppButton";
import {F_INVOICE_CREATE} from "@/shared/function_permission";
import InvoiceForm from "@/components/reservation/accommodation/forms/InvoiceForm";
import AppAside from "@/components/app/form/AppAside";
import {EventBus} from "@/shared/EventBus";

export default {
    name: "Index",
    components: {InvoiceForm, AppButton, PaymentOrderInvoiceList, PaymentOrderInvoiceFilter, AppAside},
    data() {
        return {
            F_INVOICE_CREATE,
            ICL_INVOICE_LIST,
            setupList: [],
            asidePanelState: false,
        }
    },
    props: {
        paymentOrderId: {
            type: Number
        },
        paymentOrder: {
            type: Object
        }
    },
    methods: {
        fetchSetup() {
            getManualInvoiceSetupList({creation_location: ICL_INVOICE_LIST}).then(response => {
                this.setupList = response.data
            }).catch(error => {
                // this.showErrorMessages(error)
            })
        },
        paymentOrderRemoved(){
            this.$emit('paymentOrderRemoved')
        }
    },
    created() {
        this.fetchSetup()
    }
}
</script>

<style scoped>

</style>
