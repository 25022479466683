// ROUTES

// PROPERTY
export const R_DASHBOARD = 1423
export const R_PROPERTY = 1313
export const R_PROPERTY_NEW = 1425
export const R_PROPERTY_LIST = 1299
export const R_PROPERTY_OVERVIEW = 1426
export const R_PROPERTY_BASIC = 1427
export const R_PROPERTY_LOCATION = 1428
export const R_PROPERTY_DETAILS = 1429
export const R_PROPERTY_POI = 1430
export const R_PROPERTY_BOOKING_SITES = 1431
export const R_PROPERTY_POLICIES = 1515
export const R_PROPERTY_CANCELATION_POLICIES = 1432
export const R_PROPERTY_AMENTIES = 1433
export const R_PROPERTY_ACTION_SETUP = 1434
export const R_PROPERTY_DESCRIPTION = 1435
export const R_PROPERTY_GALLERY = 1436
export const R_PROPERTY_DECLINED_CC_ACTIONS = 1437
export const R_PROPERTY_CRM = 1438
export const R_PROPERTY_CM = 1439
export const R_PROPERTY_CONTACTS = 1650
export const R_PROPERTY_CM_DISTRIBUTION = 1442
export const R_PROPERTY_CM_DISTRIBUTION_MAPPING = 1443
export const R_PROPERTY_OWNER = 1440
export const R_PROPERTY_OWNER_NEW = 1444
export const R_PROPERTY_OWNER_LIST = 1445
export const R_PROPERTY_OWNER_BASIC = 1446
export const R_PROPERTY_OWNER_CONTACTS = 1447
export const R_PROPERTY_OWNER_DOCUMENTS = 1448
export const R_PROPERTY_OWNER_USER = 1449
export const R_PROPERTY_UNIT = 1441
export const R_PROPERTY_UNIT_NEW = 1450
export const R_PROPERTY_UNIT_LIST = 1451
export const R_PROPERTY_UNIT_BASIC = 1452
export const R_PROPERTY_UNIT_LOCATION = 1453
export const R_PROPERTY_UNIT_GALLERY = 1454
export const R_PROPERTY_UNIT_AMENITY = 1455
export const R_PROPERTY_UNIT_HEADLINE = 1456
export const R_PROPERTY_UNIT_DESCRIPTIONS = 1457
export const R_PROPERTY_UNIT_COMMISIONS = 1458
export const R_PROPERTY_UNIT_FEES = 1459
export const R_PROPERTY_UNIT_DAMAGE_DEPOSIT = 1460
export const R_PROPERTY_UNIT_KEY_PICKUP = 1461
export const R_PROPERTY_UNIT_RATE_PLANS = 1462
export const R_PROPERTY_UNIT_BULK_CALENDAR = 1789

export const R_PROPERTY_CALENDAR = 1466
export const R_PROPERTY_CALENDAR_DEV = 1978
export const R_PROPERTY_CALENDAR_DEV_TEST = 2116
export const R_PROPERTY_PROMOTIONS = 1516
export const R_PROPERTY_CM_DISTRIBUTION_QUEUE = 1526
export const R_PROPERTY_CM_DISTRIBUTION_QUEUE_2 = 1968
export const R_PROPERTY_CM_DISTRIBUTION_SETUP = 1534
export const R_PROPERTY_UNIT_CONTINGENT_SETUP = 1554
export const R_PROPERTY_UNIT_DEFAULT_RESTRICTIONS = 1555
export const R_PROPERTY_BE = 1602
export const R_PROPERTY_GUEST_CHECKIN = 1738
export const R_PROPERTY_ICAL = 1739
export const R_PROPERTY_USER_ACCESS = 1765
export const R_PROPERTY_OWNER_RATE_MANAGER = 1860
export const R_PROPERTY_TRANSFERS = 1945
export const R_PROPERTY_EVENT_TEMPLATES = 1970
export const R_PROPERTY_REVIEW = 2040
export const R_PROPERTY_DEFAULT_VALUE = 2085
export const R_PROPERTY_SETTINGS = 2525

export const C_PROPERTY_IMPORT_FROM_OTA = 2512

// USER
export const R_USER = 1424
export const R_USER_BASIC = 1592
export const R_USER_ACCOUNT = 1593
export const R_USER_LIST = 1636
export const R_USER_ROLES = 1637
export const R_USER_NEW = 1638
export const R_USER_NOTIFICATIONS = 1988
export const R_USER_CASH_REGISTER_SETUP = 2003

// COMPANY
export const R_COMPANY_NEW = 1571
export const R_COMPANY_LIST = 1572

export const R_COMPANY = 1570
export const R_COMPANY_BASIC = 1573
export const R_COMPANY_CONTACTS = 1574
export const R_COMPANY_FINANCE = 1575
export const R_COMPANY_SUBSCRIPTION = 1597
export const R_COMPANY_INVOICE_LIST = 1598
export const R_COMPANY_WHITELABEL = 2308
export const R_COMPANY_SETTINGS = 2330

// GUEST
export const R_GUEST_LIST = 1601

// RESERVATION
export const R_RESERVATION = 1606

export const R_RESERVATION_ACCOMMODATION = 1607
export const R_RESERVATION_ACCOMMODATION_LIST = 1608
export const R_RESERVATION_ACCOMMODATION_GENERAL = 1613
export const R_RESERVATION_ACCOMMODATION_GUESTS = 1621
export const R_RESERVATION_ACCOMMODATION_NOTES = 1625
export const R_RESERVATION_ACCOMMODATION_INVOICES = 1629
export const R_RESERVATION_ACCOMMODATION_CC = 1825
export const R_RESERVATION_ACCOMMODATION_FINANCE = 1908
export const R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD = 1917
export const R_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES = 1940
export const R_RESERVATION_ACCOMMODATION_INBOX = 1990
export const R_RESERVATION_ACCOMMODATION_SCHEDULER = 2347
export const R_RESERVATION_WEBSITE_EDIT = 2259
export const R_RESERVATION_ACCOMMODATION_SETUP = 2365
export const R_RESERVATION_ACCOMMODATION_DETAILS_NEW = 2502
export const R_RESERVATION_ACCOMMODATION_EDIT_NEW = 2503

export const R_RESERVATION_ACTIVITY = 1609
export const R_RESERVATION_ACTIVITY_LIST = 1610

export const R_RESERVATION_TRANSFER = 1611
export const R_RESERVATION_TRANSFER_LIST = 1612


// SETUP
export const R_SETUP = 1630
export const R_SETUP_FINANCE_VAT = 1631
export const R_SETUP_FINANCE_ITEM_DESCRIPTION = 1642
export const R_SETUP_FINANCE_INVOICE_TYPES = 1646
export const R_SETUP_FINANCE_INVOICE_CREATION = 1752
export const R_SETUP_FINANCE_CE_FIXED = 1773
export const R_SETUP_GENERAL = 1833
export const R_SETUP_FINANCE_PARTNERS = 2062
export const R_SETUP_REGISTER_SETUP = 2077

export const R_RESERVATION_PROCESS = 2366
export const R_RESERVATION_PROCESS_DISTRIBUTION = 2355
export const R_RESERVATION_PROCESS_DISTRIBUTION_PROPERTY = 2385
export const R_RESERVATION_PROCESS_DISTRIBUTION_UNIT = 2384
export const R_RESERVATION_PROCESS_DISTRIBUTION_RATE_PLAN = 2383
export const R_RESERVATION_PROCESS_OWNER_STATEMENT = 2367
export const R_RESERVATION_PROCESS_OWNER_STATEMENT_OWNER = 2368
export const R_RESERVATION_PROCESS_OWNER_STATEMENT_COMPANY = 2369
export const R_RESERVATION_PROCESS_OWNER_STATEMENT_PROPERTY = 2370
export const R_RESERVATION_PROCESS_CURRENCY = 2371
export const R_RESERVATION_PROCESS_CURRENCY_RATE_PLAN = 2372
export const R_RESERVATION_PROCESS_CURRENCY_COMPANY = 2373
export const R_RESERVATION_PROCESS_CURRENCY_PROPERTY = 2374
export const R_RESERVATION_PROCESS_NOTE_SETUP = 2375
export const R_RESERVATION_PROCESS_NOTE_SETUP_OWNER = 2376
export const R_RESERVATION_PROCESS_NOTE_SETUP_COMPANY = 2377
export const R_RESERVATION_PROCESS_NOTE_SETUP_PROPERTY = 2378
export const R_RESERVATION_PROCESS_NOTE_SETUP_RATE_PLAN  = 2392
export const R_RESERVATION_PROCESS_DATA_TRANSITION = 2379
export const R_RESERVATION_PROCESS_DATA_TRANSITION_OWNER = 2380
export const R_RESERVATION_PROCESS_DATA_TRANSITION_COMPANY = 2381
export const R_RESERVATION_PROCESS_DATA_TRANSITION_PROPERTY = 2382

export const R_SETUP_SYSTEM_EVENTS = 2393
export const R_SETUP_SYSTEM_EVENTS_SYSTEM = 2394
export const R_SETUP_SYSTEM_EVENTS_PRESET = 2395
export const R_SETUP_SYSTEM_EVENTS_PROPERTY_OVERVIEW = 2396

// FINANCE
// INVOICE
export const R_FINANCE_INVOICE_LIST = 1815
export const R_FINANCE_OWNER_INVOICE_LIST = 1859
export const R_FINANCE_PAYMENT_ORDER_LIST = 1913
export const R_FINANCE_PAYMENT_MESSAGE = 1991
export const R_FINANCE_CALCULATION = 2023
export const R_FINANCE_TRANSACTIONS = 2304


export const R_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES = 1822
export const R_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICIES = 1823
export const R_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS = 1824
export const R_SETUP_TAGS_COUNTRY = 2166
export const R_SETUP_TAGS_CUSTOM = 2167
export const R_SETUP_TAGS_COUNTRY_LIST = 2168
export const R_SETUP_TAGS_COUNTRY_GENERAL = 2169
export const R_SETUP_TAGS_REGION_LIST = 2170
export const R_SETUP_TAGS_REGION_GENERAL = 2171
export const R_SETUP_TAGS_CITY = 2172


//integrations
export const R_INTEGRATIONS = 1969
export const R_INTEGRATIONS_CM = 1971
export const R_INTEGRATIONS_CM_DASHBOARD = 1972
export const R_INTEGRATIONS_CM_LOG = 1973
export const R_INTEGRATIONS_CM_SETUP = 1974
export const R_INTEGRATIONS_PG = 2176
export const R_INTEGRATIONS_PG_LIST = 2177

// Rate Manager
export const R_RATE_MANAGER_OVERVIEW = 1976


// inbox
export const R_INBOX = 1985

// CALENDAR
export const R_CALENDAR = 2010
export const R_CALENDAR_NEW = 2495
export const R_CALENDAR_MULTI_UNIT_VIEW = 2496
export const R_CALENDAR_MONTHLY_VIEW = 2497
export const R_CALENDAR_YEARLY_VIEW = 2501


//review
export const R_REVIEW_ACCOMMODATION = 2037
export const R_REVIEW_ACTIVITY = 2038
export const R_REVIEW_TRANSFER = 2039

// inbox
export const R_HEALTH_MONITOR = 2114

//Direct Booking
export const R_DIRECT_BOOKING = 2149
export const R_DIRECT_BOOKING_WEBSITE = 2150
export const R_DIRECT_BOOKING_WEBSITE_LIST = 2152
export const R_DIRECT_BOOKING_SETUP = 2151
export const R_DIRECT_BOOKING_WEBSITE_PAGES = 2161
export const R_DIRECT_BOOKING_WEBSITE_OVERVIEW = 2162
export const R_DIRECT_BOOKING_WEBSITE_GENERAL = 2163
export const R_DIRECT_BOOKING_WEBSITE_RESERVATIONS = 2164
export const R_DIRECT_BOOKING_WEBSITE_UNITS = 2165
export const R_DIRECT_BOOKING_WEBSITE_AMENITY_CATEGORIES = 2202
export const R_DIRECT_BOOKING_WEBSITE_AMENITIES = 2203
export const R_DIRECT_BOOKING_WEBSITE_TAG = 2214
export const R_DIRECT_BOOKING_PROMO_CODES_PROMOTIONS = 2274
export const R_DIRECT_BOOKING_PROMO_CODES_CATEGORY = 2275
export const R_DIRECT_BOOKING_PROMO_CODES = 2273
export const R_DIRECT_BOOKING_WEBSITE_PROMO_CODES = 2278
export const R_DIRECT_BOOKING_MARKETPLACE = 2297
export const R_DIRECT_BOOKING_MARKETPLACE_LIST = 2298
export const R_DIRECT_BOOKING_MARKETPLACE_OVERVIEW = 2299
export const R_DIRECT_BOOKING_WEBSITE_UNIT_TABLE = 2314
export const R_DIRECT_BOOKING_APPEARANCE_VIEW = 2344

// Revenue Manager
export const R_REVENUE_MANAGER = 2153
export const R_REVENUE_MANAGER_BOOKING_WINDOW = 2155
export const R_REVENUE_MANAGER_SETUP = 2156
export const R_REVENUE_MANAGER_BOOKING_WINDOW_LIST = 2157
export const R_REVENUE_MANAGER_BOOKING_WINDOW_SETUP = 2158

//Appearance
export const R_APPEARANCE = 2328
export const R_SETUP_WHITELABEL_GENERAL= 2484
export const R_SETUP_WHITELABEL_BOOKER_TOOLS =2485
export const R_SETUP_WHITELABEL_GUEST_BACKOFFICE = 2486

//Guest Backoffice
export const R_SETUP_GUEST_BACKOFFICE = 2490
export const F_SETUP_GUEST_BACKOFFICE = 2491

// System settings fee
export const R_SETUP_FEES = 2562
export const R_SETUP_FEES_PLATFORM_FEE = 2563
export const R_SETUP_FEES_PLATFORM_FEE_DISTRIBUTION_PROPERTY = 2565
export const R_SETUP_FEES_PLATFORM_FEE_DISTRIBUTION_UNIT = 2566
