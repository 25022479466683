<template>
    <form @submit.prevent="search" class="filter" autocomplete="off">
        <b-row>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("EVENT") }}</label>
                <app-select mode="multiselect"
                            :options="templateEvents"
                            text-field="label"
                            v-model="filter.event">
                </app-select>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("CHANNEL") }}</label>
                <app-select mode="multiselect"
                            :options="contactChannels"
                            text-field="label"
                            v-model="filter.contact_channel">
                </app-select>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("DISTRIBUTION") }}</label>
                <app-select
                    v-model="filter.distribution"
                    :options="distributionOptions"
                    mode="multiselect">
                </app-select>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("SALES_CHANNEL") }}</label>
                <app-select
                    v-model="filter.channel"
                    :options="channelOptions"
                    mode="multiselect">
                </app-select>
            </b-col>
<!--            <b-col xl="2" lg="4" md="6" class="mb-3">-->
<!--                <label>{{ $t("PROPERTY") }}</label>-->
<!--                <app-select-->
<!--                    mode="search"-->
<!--                    :search-on-create="preloadPropertyList"-->
<!--                    :search="searchProperty"-->
<!--                    v-model="property">-->
<!--                </app-select>-->
<!--            </b-col>-->

            <b-col md="2" class="mb-3">
                <label>{{ $t('PROPERTY') }}</label>
                <app-select :mode="searchMode" v-model="property[searchMode]" :options="propertyOptions"
                            :search="searchProperty"></app-select>
            </b-col>

            <b-col xl="4" lg="4" md="6" class="mb-3">
                <app-submit class="filter-submit-wrapper" :inline="true" :disabled="loading" :loading="loaderSubmit"
                            variant="primary"
                            button_type="search">{{ $t("SEARCH") }}
                </app-submit>

                <app-button-reset @click="resetForm" :disabled="loading" :loading="loaderReset" :inline="true"
                                  class="pl-2 ml-2"></app-button-reset>
            </b-col>
        </b-row>
    </form>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {getPropertyList} from "@/services/property";
import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import {getChannelList, getDistributionList} from "@/services/distribution";
import {getEvents} from "@/services/event";
import {getContactChannelList} from "@/services/owner";
import {DEFAULT_PAGINATION_LIMIT, LS_PRESETS, MOBILE_APP} from "@/shared/constants";
import {cloneDeep} from "lodash";

export default {
    name: "EventTemplateFilter",
    components: {AppButtonReset, AppSubmit, AppSelect},
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            preloadPropertyList: false,
            loaderReset: false,
            loaderSubmit: false,
            filter: {
                property: [],
                channel: [],
                contact_channel: [],
                distribution: [],
                company: null,
                event: [],
                level: 'property'
            },
            channelOptions: [],
            distributionOptions: [],
            templateEvents: [],
            contactChannels: [],
            searchMode: "search",
            property: {
                search: {id: null, name: null},
                multiselect: []
            },
            propertyOptions: [],
            initialProperty: {},
        }
    },
    methods: {
        searchProperty(value) {
            if(this.searchMode==="multiselect"){
                getPropertyList({name: value, company_id: this.company.id}).then(res => {
                    this.propertyOptions = res.data.items
                })
            } else {
                return getPropertyList({name: value, company_id: this.company.id}).then(res => {
                    return {data: res.data.items}
                })
            }
        },
        getDistributions() {
            getDistributionList({company: this.company.id, for_reservation: 0, sorted: 1}).then(res => {
                this.distributionOptions = res.data
            })
        },
        getChannels() {
            getChannelList({with_icons: 0}).then(res => {
                this.channelOptions = res.data
            })
        },
        getTemplates(){
            getEvents({company: this.company.id}).then(res => {
                this.templateEvents = res.data
            }, () => {
                this.templateEvents = []
            })
        },
        getContactChannels(){
            let req = {
                event: this.filter.event
            }

            if(this.filter.event.length===0){
                req.with_events = true
            }

            getContactChannelList(req).then ( res => {
                this.contactChannels = res.data
            }, () => {
                this.contactChannels = []
            })
        },
        resetForm() {
            this.loaderReset = true
            this.reset = 1
            this.property = cloneDeep(this.initialProperty)
            this.filter.property = []
            this.filter.channel = []
            this.filter.contact_channel = []
            this.filter.distribution = []
            this.filter.ompany = null
            this.filter.event = null
            this.$emit('search', {...this.filter})
        },
        search() {
            this.reset = 0
            this.filter.property = this.property.search.id ? this.property.search.id : this.property.multiselect
            this.$emit('search', {...this.filter})
        }
    },
    watch: {
        // property: {
        //     handler(data) {
        //         if (data.hasOwnProperty('id')) {
        //             this.filter.property = data.id
        //         }
        //     },
        // },
        loading: {
            handler(value) {
                if (this.reset) {
                    this.loaderReset = value
                } else {
                    this.loaderSubmit = value
                }
            }
        },
        'filter.event': {
            handler() {
                this.getContactChannels()
            }
        }
    },
    computed: {
        disabledSearch() {
            return this.loading
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    created() {
        this.initialProperty = cloneDeep(this.property);
        this.getDistributions()
        this.getChannels()
        this.getTemplates()
        this.getContactChannels()
        this.filter.company = this.company.id

        let storagePresets = localStorage.getItem(LS_PRESETS)
        if (storagePresets) {
            storagePresets = JSON.parse(storagePresets)

            if (storagePresets.hasOwnProperty('properties') && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                this.searchMode = "multiselect"
                this.searchProperty()
            }
        }
    }
}
</script>

<style scoped>

</style>
