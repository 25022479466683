import http from '../../http'
import {
    DB_WEBSITE_URL,
} from '@/services/endpoints'

export function getWebsiteCompanyList(websiteId, params) {
    return http.request({
        url: DB_WEBSITE_URL + '/' + websiteId + '/company-filter',
        method: 'get',
        params: params
    })
}

export function getWebsitePropertyList(websiteId, params) {
    return http.request({
        url: DB_WEBSITE_URL + '/' + websiteId + '/property-filter',
        method: 'get',
        params: params
    })
}

export function getWebsiteUnitList(websiteId, params) {
    return http.request({
        url: DB_WEBSITE_URL + '/' + websiteId + '/unit-filter',
        method: 'get',
        params: params
    })
}

export function getWebsiteList(params) {
    return http.request({
        url: DB_WEBSITE_URL + '/list',
        method: 'get',
        params: params
    })
}

export function getWebsitePageContentSetupData(params) {
    return http.request({
        url: `${DB_WEBSITE_URL}/page-content-setup`,
        method: "GET",
        params
    })
}

export function fetchWebsite(id) {
    return http.request({
        url: DB_WEBSITE_URL + '/' + id,
        method: 'get',
    })
}

export function getActiveUnitList(distribution_id, params) {
    return http.request({
        url: DB_WEBSITE_URL + '/' + distribution_id + '/units',
        method: 'get',
        params: params
    })
}

export function setActivationUnit(distribution_id, data) {
    return http.request({
        url: DB_WEBSITE_URL + '/' + distribution_id + '/unit-activation',
        method: 'post',
        data: data
    })
}

export function updateUnitActivation(id, data) {
    return http.request({
        url: DB_WEBSITE_URL + '/' + id + '/unit-activation',
        method: 'put',
        data: data
    })
}

export function getWebsiteSalesChannel(id) {
    return http.request({
        url: DB_WEBSITE_URL + '/' + id + '/sales-channel',
        method: 'get',
    })
}

export function getWebsiteWizardSetup() {
    return http.request({
        url: `${DB_WEBSITE_URL}/wizard-setup`,
        method: 'GET'
    })
}

export function createWebsite(data) {
    return http.request({
        url: DB_WEBSITE_URL,
        method: "POST",
        data
    })
}

export function updateWebsite(id, data) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${id}`,
        method: "PUT",
        data
    })
}

export function createWebsitePageDefaultContent(id) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${id}/pages/default-content`,
        method: "POST"
    })
}

export function getWebsitePageList(id, params) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${id}/pages`,
        method: "GET",
        params
    })
}

export function fetchWebsiteNavigation(websiteId) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/navigation`,
        method: "GET"
    })
}

export function updateWebsiteNavigation(websiteId, data) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/navigation`,
        method: "PUT",
        data
    })
}

export function fetchWebsitePage(websiteId, pageId) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/pages/${pageId}`,
        method: "GET"
    })
}

export function updateWebsitePage(websiteId, pageId, data) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/pages/${pageId}`,
        method: "PUT",
        data
    })
}

export function createWebsitePage(websiteId, data) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/pages`,
        method: "POST",
        data
    })
}

export function deleteWebsitePage(websiteId, pageId) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/pages/${pageId}`,
        method: "DELETE",
    })
}

export function fetchWebsitePageContent(websiteId, pageId) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/pages/${pageId}/content`,
        method: "GET"
    })
}

export function updateWebsitePageContent(websiteId, pageId, data) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/pages/${pageId}/content`,
        method: "PUT",
        data
    })
}

export function saveDestinationTag(tagId) {
    return http.request({
        url: DB_WEBSITE_URL + '/' + tagId + '/save-destination',
        method: 'post'
    })
}

export function getWebsiteTags(params) {
    return http.request({
        url: DB_WEBSITE_URL + '/website-tags',
        method: 'get',
        params: params
    })
}

export function getAmenityCategories(websiteId, params) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/amenity-category`,
        method: "get",
        params: params
    })
}

export function getAmenityCategory(websiteId, categoryId) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/amenity-category/${categoryId}`,
        method: "get",
    })
}

export function getUnitType(websiteId) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/unit-type`,
        method: "get",
    })
}

export function copyAmenities(websiteId) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/amenity-category/copy`,
        method: "post",
    })
}

export function copyUnitType(websiteId) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/unit-type/copy`,
        method: "post",
    })
}

export function saveAmenityCategory(websiteId, data) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/amenity-category`,
        method: "post",
        data
    })
}

export function updateAmenityCategory(websiteId, categoryId, data) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/amenity-category/${categoryId}`,
        method: "put",
        data
    })
}

export function saveAmenity(websiteId, categoryId, data) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/amenity-category/${categoryId}/amenity`,
        method: "post",
        data
    })
}

export function mapAmenity(websiteId, data) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/amenity-category`,
        method: "put",
        data
    })
}

export function updateAmenity(websiteId, categoryId, amenityId, data) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/amenity-category/${categoryId}/amenity/${amenityId}`,
        method: "put",
        data
    })
}

export function getAmenity(websiteId, categoryId, amenityId) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/amenity-category/${categoryId}/${amenityId}`,
        method: "get",
    })
}

export function getAmenityMapping(websiteId, searchParams) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/amenity-category/map-source`,
        method: "get",
        params: searchParams,
    })
}

export function saveUnitType(id, data) {
    return http.request({
        url: DB_WEBSITE_URL + '/' + id + '/unit-type',
        method: 'post',
        data: data
    })
}

export function getMappedUnitTypes(id, unit_type_id) {
    return http.request({
        url: DB_WEBSITE_URL + '/' + id + '/unit-type/' + unit_type_id,
        method: 'get'
    })
}

export function getBtUnitTypeList(id) {
    return http.request({
        url: DB_WEBSITE_URL + '/' + id + '/unit-type/map-source',
        method: 'get'
    })
}

export function saveUnitTypeMapping(id, data) {
    return http.request({
        url: DB_WEBSITE_URL + '/' + id + '/unit-type/map',
        method: 'post',
        data: data
    })
}

export function getTagList(websiteId, searchParams) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/tag`,
        method: "get",
        params: searchParams,
    })
}

export function saveTagList(websiteId, data) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/tag`,
        method: "post",
        data
    })
}

export function fetchUnitRatePlan(distribution_id, params) {
    return http.request({
        url: DB_WEBSITE_URL + '/' + distribution_id + '/unit-rate-plan',
        method: 'get',
        params: params
    })
}

export function clearCache(id) {
    return http.request({
        url: DB_WEBSITE_URL + '/' + id + '/clear-cache',
        method: 'post'
    })
}

export function fetchPageFilters(id) {
    return http.request({
        url: DB_WEBSITE_URL + '/' + id + '/page-filters',
        method: 'get'
    })
}

export function updatePageFilters(id, data) {
    return http.request({
        url: DB_WEBSITE_URL + '/' + id + '/page-filters',
        method: 'put',
        data: data
    })
}

export function fetchAmenities(id, params) {
    return http.request({
        url: DB_WEBSITE_URL + '/' + id + '/amenities',
        method: 'get',
        params: params
    })
}

export function uploadOrFetchWebsiteDocument(websiteId, documentTypeId, data) {
    return http.request({
        url: `${DB_WEBSITE_URL}/${websiteId}/document/${documentTypeId}`,
        method: 'POST',
        data
    })
}

export function getUnitTransitionStatus(params){
    return http.request({
        url: DB_WEBSITE_URL + '/unit-transitions',
        method: 'get',
        params: params
    })
}

export function changeUnitStatus(data){
    return http.request({
        url:DB_WEBSITE_URL + '/unit-transitions',
        method: 'put',
        data: data
    })
}

export function getUnitStatuses(params){
    return http.request({
        url: DB_WEBSITE_URL + '/unit-statuses',
        method: 'get',
        params: params
    })
}

export function getUnitSetupParameter(id, unit_id){
    return http.request({
        url: DB_WEBSITE_URL + '/' + id + '/' + unit_id + '/setup-parameter',
        method: 'get'
    })
}

export function saveUnitSetupParameter(id, unit_id, data){
    return http.request({
        url: DB_WEBSITE_URL + '/' + id + '/' + unit_id + '/setup-parameter',
        method: 'post',
        data: data
    })
}

export function deleteCache(data) {
    return http.request({
        url: `adminapi/response-cache/invalidate`,
        method: "delete",
        data: data
    })
}
