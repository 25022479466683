<template>
    <div>
        <app-table-v2
            :search-on-create="false"
            :provider="getProvider"
            :limit-list="[DEFAULT_PAGINATION_LIMIT, 20, 50, 100]"
            @busyToggled="toggleBusy"
            :fields="fields"
            :search-event="EVENT_TABLE_SEARCH"
            :refresh-event="EVENT_TABLE_REFRESH"
            :filter="filter"
            :selectable="false"
        >
            <template v-slot:name="data">
                <b-link v-if="checkPermission(R_PROPERTY_OVERVIEW) && data.item.id"
                        :to="{name: routeNames.RN_PROPERTY_OVERVIEW, params: {id: data.item.id}}"
                        target="_blank">
                    {{ data.item.name }}
                </b-link>
                <template v-else>
                    {{ data.item.name }}
                </template>
            </template>

            <template v-slot:city="data">
                <small class="text-muted d-block">
                    {{ data.item.country.name }}
                </small>

                {{ data.item.city.name }}
            </template>

            <template v-slot:HEAD_customSelectableCheckbox="data">
                <div class="tableSelectableCheckbox" v-if="hasCheckBoxCount > 0">
                    <b-checkbox v-model="selectAllValue"
                                @change="selectAll($event)"
                                :inline="true"
                                size="sm"
                    ></b-checkbox>
                </div>
            </template>

            <template v-slot:customSelectableCheckbox="data">
                <div class="tableSelectableCheckbox" v-if="edit_enabled[data.item.id]">
                    <b-checkbox @change="setSelectedItem($event, data.item)"
                                v-model="selectedIds"
                                :value="data.item.id"
                                :inline="true"
                                size="sm"
                    ></b-checkbox>
                </div>
            </template>

            <template v-slot:container_selected_item="data">
                <div class="selectedContainer animated fadeIn" v-if="selected.length">
                    <div>
                        <h4 v-if="selected.length > 1">{{$t("ITEMS_SELECTED", {value: selected.length})}}</h4>
                        <h4 v-else-if="selected.length === 1">{{$t("ITEM_SELECTED")}}</h4>
                        <app-button class="action_button" variant="link" @click="deselectItems">{{$t("DESELECT")}}</app-button>
                    </div>

                    <div class="flex-grow-1 d-flex justify-content-end">
                        <slot v-bind="{selected: selectedIds}" name="selectedContainer">

                            <app-button @click="groupRatesActivation(selected)" class="mr-3">
                                {{ $t('ACTIVATE_RATES') }}
                            </app-button>
                            <app-button @click="groupMinStayActivation(selected)" class="mr-3">
                                {{ $t('ACTIVATE_MIN_STAY') }}
                            </app-button>

                        </slot>
                    </div>
                </div>
            </template>

            <template v-slot:rates_activated="data">
                <b-tooltip v-if="ac_response_active_rates || data.item.unit_count === 0" triggers="hover"
                           :target="data.item.id+'_rates'"
                           :title="data.item.unit_count === 0 ? $t('PROPERTY_ACTIVATION_NOT_POSSIBLE') : $t('ACTIVATION_COMPANY_RM')"
                ></b-tooltip>
                <b-tooltip v-else-if="!edit_enabled[data.item.id]" triggers="hover"
                           :target="data.item.id+'_rates'"
                           :title="editDisabledMessage"
                ></b-tooltip>
                <div :id="data.item.id+'_rates'" class="d-inline-block">
                    <b-form-checkbox :disabled="ac_response_active_rates || data.item.unit_count === 0 || !edit_enabled[data.item.id]" switch
                                     :unchecked-value="false" :value="true"
                                     @change="activationConfirmation(data.item, ACTIVE_REVENUE_MANAGER_RATES, $event)"
                                     v-model="rates_activated[data.item.id]"
                                     class="d-inline-block"
                    ></b-form-checkbox>
                </div>
                <app-button v-if="rates_activated[data.item.id] && !(ac_response_active_rates || data.item.unit_count === 0)"
                            variant="link"
                            button_type="edit"
                            @click="openPropertyRatesActivationAside(data.item)"
                            :show_text="false"
                            class="d-inline-block"
                ></app-button>
            </template>

            <template v-slot:min_stay_activated="data">
                <b-tooltip v-if="ac_response_active_min_stay || data.item.unit_count === 0" triggers="hover"
                           :target="data.item.id+'_min_stay'"
                           :title="data.item.unit_count === 0 ? $t('PROPERTY_ACTIVATION_NOT_POSSIBLE') : $t('ACTIVATION_COMPANY_RM')"
                ></b-tooltip>
                <b-tooltip v-else-if="!edit_enabled[data.item.id]" triggers="hover"
                           :target="data.item.id+'_min_stay'"
                           :title="editDisabledMessage"
                ></b-tooltip>
                <div :id="data.item.id+'_min_stay'" class="d-inline-block">
                    <b-form-checkbox :disabled="ac_response_active_min_stay || data.item.unit_count === 0 || !edit_enabled[data.item.id]" switch
                                     :unchecked-value="false" :value="true"
                                     @change="activationConfirmation(data.item, ACTIVE_REVENUE_MANAGER_MINIMUM_STAY, $event)"
                                     v-model="min_stay_activated[data.item.id]"
                    ></b-form-checkbox>
                </div>
            </template>

            <template v-slot:unit_actions="data">
                <template v-if="!data.item.rates_activated && !data.item.min_stay_activated">
                    <b-tooltip :target="'secured_value'+data.item.id">
                        {{ $t('ACTIVATION_UNIT_RM') }}
                    </b-tooltip>
                    <b-tooltip :target="'deviation'+data.item.id">
                        {{ $t('ACTIVATION_UNIT_RM') }}
                    </b-tooltip>
                    <b-tooltip :target="'competitors'+data.item.id">
                        {{ $t('ACTIVATION_UNIT_RM') }}
                    </b-tooltip>
                </template>
                <template v-else>
                    <b-tooltip :target="'secured_value'+data.item.id" triggers="hover">
                        {{ $t('SECURED_VALUES') }}
                    </b-tooltip>
                    <b-tooltip :target="'deviation'+data.item.id" triggers="hover">
                        {{ $t('DEVIATION') }}
                    </b-tooltip>
                    <b-tooltip :target="'competitors'+data.item.id" triggers="hover">
                        {{ $t('COMPETITORS') }}
                    </b-tooltip>
                </template>

                <app-button :disabled="!data.item.rates_activated && !data.item.min_stay_activated" class="mr-3"
                            variant="link" @click="openUnitAction(data.item,0)"
                >{{ data.item.unit_count }} ({{ data.item.unit_count_rates_activated }} / {{ data.item.unit_count_min_stay_activated }})
                </app-button>

                <span :id="'competitors'+data.item.id" class="pull-right">
                    <app-button :disabled="!data.item.rates_activated && !data.item.min_stay_activated" variant="link"
                                :show_text="false" button_type="compress_arrows" :font_awesome_5="true"
                                @click="openUnitAction(data.item,4)"
                    ></app-button>
                </span>
                <span v-if="company.workflow_id === WORKFLOW_DIRECT_BOOKER" :id="'deviation'+data.item.id" class="pull-right">
                      <app-button :disabled="!data.item.rates_activated && !data.item.min_stay_activated"
                                  :show_text="false" button_type="file" variant="link"
                                  @click="openUnitAction(data.item, 2)"
                                  class="action_button mr-1"
                      ></app-button>
                </span>
                <span :id="'secured_value'+data.item.id" class="pull-right">
                     <app-button :disabled="!data.item.rates_activated && !data.item.min_stay_activated" variant="link"
                                 button_type="key" :show_text="false"
                                 @click="openUnitAction(data.item, company.workflow_id === WORKFLOW_DIRECT_BOOKER ? 3 : 2)"
                     ></app-button>
                </span>
            </template>

            <template v-slot:edit="data">
                <app-button variant="link"
                            button_type="edit"
                            @click="openPropertyAside(data.item)"
                            :show_text="false"
                ></app-button>
            </template>
        </app-table-v2>

        <app-aside v-model="activationRateAside" :widths="['col-lg-12','col-sm-12', '100%']" full-width>
            <template v-slot:header v-if="activationObject.property">
                <div class="property-wrapper mb-0 p-no-border">
                    <div class="card-header">
                        <div class="header-caption mb-0"> {{ $t('ACTIVATION_RATES') }}</div>
                        <h2 class="property-wrapper-padding mb-1">
                            {{ activationObject.property.name }}
                        </h2>
                    </div>
                </div>
            </template>
            <revenue-manager-setup-rates-activation v-if="activationObject.property"
                                                    @close="activationRateAside = false"
                                                    :mode="activationObject.property.rates_activated === null ? 'new' : 'edit'"
                                                    :property_ids="[activationObject.property.id]"
                                                    :submodule="ACTIVE_REVENUE_MANAGER_RATES"
                                                    :read_only="!edit_enabled[activationObject.property.id]"
                                                    :read_only_message="editDisabledMessage"
            ></revenue-manager-setup-rates-activation>
        </app-aside>
        <app-aside v-model="activationMinStayAside" :widths="['col-lg-12','col-sm-12', '100%']" full-width>
            <template v-slot:header v-if="activationObject.property">
                <div class="property-wrapper mb-0 p-no-border">
                    <div class="card-header">
                        <div class="header-caption mb-0"> {{ $t('ACTIVATION_MIN_STAY') }}</div>
                        <h2 class="property-wrapper-padding mb-1">
                            {{ activationObject.property.name }}
                        </h2>
                    </div>
                </div>
            </template>
            <revenue-manager-setup-rates-activation v-if="activationObject.property"
                                                    @close="activationMinStayAside = false"
                                                    :mode="activationObject.property.min_stay_activated === null ? 'new' : 'edit'"
                                                    :property_ids="[activationObject.property.id]"
                                                    :submodule="ACTIVE_REVENUE_MANAGER_MINIMUM_STAY"
                                                    :read_only="!edit_enabled[activationObject.property.id]"
                                                    :read_only_message="editDisabledMessage"
            ></revenue-manager-setup-rates-activation>
        </app-aside>

        <app-aside v-model="activationRatesGroupAside" :widths="['col-lg-3']">
            <template v-slot:header>
                {{ $t('ACTIVATION_RATES') }}
            </template>
            <revenue-manager-setup-group-activation :submodule="ACTIVE_REVENUE_MANAGER_RATES"
                                                    :property_ids="selectedIds"
                                                    @close="activationRatesGroupAside = false"
            ></revenue-manager-setup-group-activation>
        </app-aside>
        <app-aside v-model="activationMinStayGroupAside" :widths="['col-lg-3']">
            <template v-slot:header>
                {{ $t('ACTIVATION_MIN_STAY') }}
            </template>
            <revenue-manager-setup-group-activation :submodule="ACTIVE_REVENUE_MANAGER_MINIMUM_STAY"
                                                    :property_ids="selectedIds"
                                                    @close="activationMinStayGroupAside = false"
            ></revenue-manager-setup-group-activation>
        </app-aside>

        <app-confirmation-dialog :title="$t('DEACTIVATION_CONFIRMATION_RATES')"
                                 :show="activationRatesConfirmationDialog"
                                 @cancel="activationRatesConfirmationDialog = false"
                                 @confirm="deactivateRatesRevenueManager"
        >{{ $t('DEACTIVATE_RM_AUTOMATIC_RATES') }}
        </app-confirmation-dialog>
        <app-confirmation-dialog :title="$t('DEACTIVATION_CONFIRMATION_MIN_STAY')"
                                 :show="activationMinStayConfirmationDialog"
                                 @cancel="activationMinStayConfirmationDialog = false"
                                 @confirm="deactivateMinStayRevenueManager"
        >{{ $t('DEACTIVATE_RM_AUTOMATIC_MIN_STAY') }}
        </app-confirmation-dialog>

        <app-aside v-model="propertyAside" :widths="['col-lg-12','col-sm-12', '100%']" full-width>
            <template slot="header" v-if="selectedProperty">
                <div class="header-caption">{{ $t('REVENUE_MANAGER') }}</div>
                <div class="d-flex justify-content-between">
                    <h2>{{ selectedProperty ? selectedProperty.name : '' }}</h2>
                </div>
            </template>
            <revenue-manager-property-action-form v-if="selectedProperty"
                                                  :propertyId="selectedProperty.id"
                                                  :property-object="selectedProperty"
                                                  :read_only="!edit_enabled[selectedProperty.id]"
                                                  :read_only_message="editDisabledMessage"
            ></revenue-manager-property-action-form>
        </app-aside>

        <app-aside v-model="unitActionAside" :widths="['col-lg-12','col-sm-12', '100%']" full-width>
            <template slot="header">
                <div class="header-caption">{{ $t('REVENUE_MANAGER') }}</div>
                <div class="d-flex justify-content-between">
                    <h2>{{ selectedProperty ? selectedProperty.name : '' }}</h2>
                </div>
            </template>
            <template slot="header_action_button">
                <div class="aside__header--action_button col-3 mr-3" v-if="unitList.length > 0">
                    <div class="header-caption pl-0 pl-md-2 mt-3 mt-md-0">{{ $t('UNIT') }}</div>
                    <app-select v-model="selectedUnit"
                                :options="unitList"
                                :disabled="unitList.length === 1"
                                :search-empty-item="false"
                                mode="select"
                                text-field="title"
                    ></app-select>
                </div>
            </template>
            <b-row v-if="company.workflow_id === WORKFLOW_DIRECT_BOOKER && selectedProperty && unitList.length > 1">
                <b-col>
                    <div class="pull-right">
                        <b-tooltip v-if="!edit_enabled[selectedProperty.id]" triggers="hover"
                                   :title="editDisabledMessage"
                                   target="rm_copy_unit_setup_button"
                        ></b-tooltip>
                        <app-button id="rm_copy_unit_setup_button"
                                    :disabled="!edit_enabled[selectedProperty.id]"
                                    @click="copyUnitSetupActionAside = true"
                        >{{ $t('COPY') }}</app-button>
                    </div>
                </b-col>
            </b-row>
            <revenue-manager-unit-action-form :preview-tab="previewTab"
                                              :property-object="selectedProperty"
                                              :unit-object="getUnitObjectById(selectedUnit)"
                                              :read_only="selectedProperty ? !edit_enabled[selectedProperty.id] : false"
                                              :read_only_message="editDisabledMessage"
            ></revenue-manager-unit-action-form>
        </app-aside>

        <app-aside v-model="copyUnitSetupActionAside">
            <template v-slot:header>
                <div class="header-caption">{{ $t('REVENUE_MANAGER') }}</div>
                <div class="d-flex justify-content-between">
                    <h2>{{ $t('COPY_UNIT_SETUP') }}</h2>
                </div>
            </template>
            <revenue-manager-unit-copy-setup-form :unit-object="getUnitObjectById(selectedUnit)"
                                                  :unit-list="unitList"
                                                  :company="company"
                                                  @close="copyUnitSetupActionAside = false"
            ></revenue-manager-unit-copy-setup-form>
        </app-aside>
    </div>
</template>

<script>
import {
    ACTIVE_REVENUE_MANAGER_RATES,
    ACTIVE_REVENUE_MANAGER_MINIMUM_STAY,
    ACTIVE_REVENUE_MANAGER_RATES_EXTERNAL,
    DEFAULT_PAGINATION_LIMIT,
    WORKFLOW_DIRECT_BOOKER,
    DIRECT_BOOKER_COMPANY,
    STATUS_PROPERTY_IN_ACQUISITION,
    STATUS_PROPERTY_COMPLETE_ACQUISITION,
    STATUS_PROPERTY_ACQUISITION_ON_HOLD,
    STATUS_PROPERTY_UPDATE,
    STATUS_PROPERTY_UPDATE_COMPLETE,
    OBJECT_TYPE_PROPERTY,
} from "@/shared/constants";
import AppTableV2 from "@/components/app/AppTableV2";
import {
    getRevenueManagerSetupPropertyList,
    saveRevenueManagerSetup,
    searchRmUnits
} from "@/services/revenue_manager";
import {EventBus} from "@/shared/EventBus";
import AppButton from "@/components/app/AppButton/AppButton";
import {AC_REVENUE_MANAGER_SETUP_PROPERTY} from "@/mixins/AccessControl/AccessControlEnumeration";
import {fetchAccessControlData} from "@/services/access";
import {fetchStatusList} from "@/services/status";
import {
    F_REVENUE_MANAGER_COMPANY_ACTIVATION_MIN_STAY,
    F_REVENUE_MANAGER_COMPANY_ACTIVATION_RATES,
} from "@/shared/component_permission";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {toast} from "@/shared/plugins/toastr";
import AppAside from "@/components/app/form/AppAside";
import RevenueManagerSetupRatesActivation
    from "@/components/revenue_manager/setup/properties/RevenueManagerSetupRatesActivation";
import RevenueManagerSetupGroupActivation
    from "@/components/revenue_manager/setup/properties/RevenueManagerSetupGroupActivation";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import RevenueManagerUnitActionForm from "@/components/revenue_manager/setup/unit/RevenueManagerUnitActionForm";
import RevenueManagerUnitCopySetupForm from "@/components/revenue_manager/setup/unit/RevenueManagerUnitCopySetupForm";
import RevenueManagerPropertyActionForm
    from "@/components/revenue_manager/setup/properties/RevenueManagerPropertyActionForm";
import RevenueManagerUnitsTable from '@/components/revenue_manager/unit/RevenueMangerUnitsTable'
import RevenueManagerSimilarUnitList
    from "@/components/revenue_manager/similar_listing/unit/RevenueManagerSimilarUnitList";
import {R_PROPERTY_OVERVIEW} from "@/shared/route_permission";
import routeNames from '@/router/routeNames'

export default {
    name: "RevenueManagerSetupPropertyList",
    components: {
        RevenueManagerSimilarUnitList,
        RevenueManagerPropertyActionForm,
        AppSelect,
        RevenueManagerSetupGroupActivation,
        AppConfirmationDialog,
        AppButton,
        AppTableV2,
        AppAside,
        RevenueManagerSetupRatesActivation,
        RevenueManagerUnitCopySetupForm,
        RevenueManagerUnitActionForm,
        RevenueManagerUnitsTable
    },
    props: {
        filter: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            routeNames,
            R_PROPERTY_OVERVIEW,
            DEFAULT_PAGINATION_LIMIT,
            WORKFLOW_DIRECT_BOOKER,
            EVENT_TABLE_SEARCH: 'gl_revenue_setup_search_property',
            EVENT_TABLE_REFRESH: 'gl_revenue_setup_refresh_property',
            fields: [
                {key: 'customSelectableCheckbox', label: ''},
                {key: 'id', label: this.$i18n.t('ID')},
                {key: 'name', label: this.$i18n.t('NAME')},
                {key: 'type.label', label: this.$i18n.t('TYPE')},
                {key: 'city', label: this.$i18n.t('CITY')},
                {key: 'rates_activated', label: this.$i18n.t('RATES')},
                {key: 'min_stay_activated', label: this.$i18n.t('MIN_STAY')},
                {
                    key: 'unit_actions',
                    label: this.$t('UNITS'),
                    class: 'text-center',
                    thClass: 'text-center',
                    thStyle: 'width:16%'
                },
                {key: 'edit', label: '', class: 'text-right'},
            ],
            ac_response_active_rates: false,
            ac_response_active_min_stay: false,
            editable_status_list: [
                STATUS_PROPERTY_IN_ACQUISITION,
                STATUS_PROPERTY_COMPLETE_ACQUISITION,
                STATUS_PROPERTY_ACQUISITION_ON_HOLD,
                STATUS_PROPERTY_UPDATE,
                STATUS_PROPERTY_UPDATE_COMPLETE,
            ],
            property_status_list: [],
            edit_enabled: {},
            rates_activated: {},
            min_stay_activated: {},
            activationMinStayConfirmationDialog: false,
            activationRatesConfirmationDialog: false,
            activationObject: {
                property: null,
                value: null
            },
            list: [],
            selected: [],
            selectedIds: [],
            activationRateAside: false,
            activationMinStayAside: false,
            activationMinStayGroupAside: false,
            ACTIVE_REVENUE_MANAGER_RATES,
            ACTIVE_REVENUE_MANAGER_MINIMUM_STAY,
            activationRatesGroupAside: false,
            copyUnitSetupActionAside: false,
            unitActionAside: false,
            unitList: [],
            selectedUnit: null,
            selectedProperty: null,
            previewTab: null,
            propertyAside: false,
            selectAllValue: false,
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        editDisabledMessage() {
            let status_list = this.property_status_list.filter(item => this.editable_status_list.includes(Number(item.id))).sort((a, b) => (a.label.localeCompare(b.label))).map(item => item.label)
            let status_list_string = status_list.length ? "'" + status_list.join("', '") + "'" : ''
            return this.$t("ACTION_ENABLED_IN_STATUS", {value: status_list_string})
        },
        hasCheckBoxCount() {
            let return_value = 0
            if (this.list.length) {
                this.list.forEach(property => {
                    if (this.edit_enabled[property.id] === true) {
                        return_value = return_value + 1
                    }
                })
            }
            return return_value
        },
    },
    methods: {
        openPropertyAside(property) {
            this.selectedProperty = property
            this.propertyAside = true
        },
        groupRatesActivation(selected) {
            this.selected = selected
            this.selectedIds = selected.map(el => {
                return el.id
            })
            if (this.selected.length > 1) {
                this.activationRatesGroupAside = true
            } else if (this.selected.length === 1) {
                this.activationRateAside = true
                this.activationObject = {
                    property: selected[0],
                    value: true
                }
            }
        },
        groupMinStayActivation(selected) {
            this.selected = selected
            this.selectedIds = selected.map(el => {
                return el.id
            })
            if (this.selected.length > 1) {
                this.activationMinStayGroupAside = true
            } else if (this.selected.length === 1) {
                this.activationMinStayAside = true
                this.activationObject = {
                    property: selected[0],
                    value: true
                }
            }
        },
        deactivateRatesRevenueManager() {
            let request = {
                setup_list: []
            }
            if (this.selected.length > 0) {
                for (const property_id of this.selected) {
                    if (this.company.id === DIRECT_BOOKER_COMPANY) {
                        request.setup_list.push({
                            setup_parameter: ACTIVE_REVENUE_MANAGER_RATES_EXTERNAL,
                            property: property_id,
                            unit: null,
                            company: null,
                            value: 0
                        })
                    } else {
                        request.setup_list.push({
                            setup_parameter: ACTIVE_REVENUE_MANAGER_RATES,
                            property: property_id,
                            unit: null,
                            company: null,
                            value: 0
                        })
                    }
                }
            } else {
                if (this.company.id === DIRECT_BOOKER_COMPANY) {
                    request.setup_list.push({
                        setup_parameter: ACTIVE_REVENUE_MANAGER_RATES_EXTERNAL,
                        property: this.activationObject.property.id,
                        unit: null,
                        company: null,
                        value: 0
                    })
                } else {
                    request.setup_list.push({
                        setup_parameter: ACTIVE_REVENUE_MANAGER_RATES,
                        property: this.activationObject.property.id,
                        unit: null,
                        company: null,
                        value: 0
                    })
                }
            }
            saveRevenueManagerSetup(request).then(() => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
                EventBus.$emit(this.EVENT_TABLE_REFRESH)
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
            }).finally(() => {
                this.selected = []
                this.activationRatesConfirmationDialog = false
                this.selectAllValue = false
            })
        },
        deactivateMinStayRevenueManager() {
            let request = {
                setup_list: []
            }
            if (this.selected.length > 0) {
                for (const property_id of this.selected) {
                    request.setup_list.push({
                        setup_parameter: ACTIVE_REVENUE_MANAGER_MINIMUM_STAY,
                        property: property_id,
                        unit: null,
                        company: null,
                        value: 0
                    })
                }
            } else {
                request.setup_list.push({
                    setup_parameter: ACTIVE_REVENUE_MANAGER_MINIMUM_STAY,
                    property: this.activationObject.property.id,
                    unit: null,
                    company: null,
                    value: 0
                })
            }
            saveRevenueManagerSetup(request).then(() => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
                EventBus.$emit(this.EVENT_TABLE_REFRESH)
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
            }).finally(() => {
                this.selected = []
                this.activationMinStayConfirmationDialog = false
                this.selectAllValue = false
            })
        },
        openUnitAction(property, tab) {
            this.selectedProperty = property
            this.previewTab = tab
            this.unitActionAside = true
        },
        openPropertyRatesActivationAside(property) {
            this.activationRateAside = true
            this.activationObject = {
                property: property,
                value: true
            }
        },
        async activationConfirmation(property, mode, value) {
            await this.$nextTick()
            this.activationObject = {
                property,
                value
            }
            if (mode === ACTIVE_REVENUE_MANAGER_RATES) {
                this.rates_activated[property.id] = !value
                if (this.rates_activated[property.id] === false) {
                    this.activationRateAside = true
                } else {
                    this.activationRatesConfirmationDialog = true
                }
            }
            if (mode === ACTIVE_REVENUE_MANAGER_MINIMUM_STAY) {
                this.min_stay_activated[property.id] = !value
                if (this.min_stay_activated[property.id] === false) {
                    this.activationMinStayAside = true
                } else {
                    this.activationMinStayConfirmationDialog = true
                }
            }
        },
        toggleBusy($event) {
            this.$emit('busyToggled', $event)
        },
        getProvider(filter) {
            this.selected = []
            this.selectedIds = []
            this.selectAllValue = false
            let req = {
                ...this.filter,
                ...{
                    page: filter.currentPage,
                    limit: filter.perPage,
                    order_by: filter.sortBy,
                    order_by_direction: filter.sortDirection,
                    company: this.company.id
                }
            }
            const provider = getRevenueManagerSetupPropertyList(req)
            provider.then(response => {
                this.list = response.data.items
                this.edit_enabled = {}
                this.rates_activated = {}
                this.min_stay_activated = {}
                if (response.data.items.length > 0) {
                    for (const propertyObject of response.data.items) {
                        const propertyStatus = propertyObject.hasOwnProperty('status') && propertyObject.status ? propertyObject.status : null
                        const property_status_id = typeof propertyStatus === 'object' && propertyStatus.hasOwnProperty('id') ? propertyStatus.id : null
                        const edit_enabled = this.company.workflow_id !== WORKFLOW_DIRECT_BOOKER || (property_status_id && this.editable_status_list.includes(Number(property_status_id)))
                        const rates_activated = propertyObject.rates_activated === 1
                        const min_stay_activated = propertyObject.min_stay_activated === 1
                        this.$set(this.edit_enabled, propertyObject.id, edit_enabled)
                        this.$set(this.rates_activated, propertyObject.id, rates_activated)
                        this.$set(this.min_stay_activated, propertyObject.id, min_stay_activated)
                    }
                }
            })
            return provider
        },
        getUnitObjectById(unit_id) {
            return this.unitList.length > 0 ? this.unitList.find(el => el.id === unit_id) : {}
        },
        setSelectedItem($event, property) {
            let index = this.selectedIds.indexOf(property.id)
            if (index >= 0) {
                this.selected.splice(index, 1)
                this.selectedIds.splice(index, 1)
            }
            if ($event) {
                this.selected.push(property)
                this.selectedIds.push($event)
                if (this.selectedIds.length === this.hasCheckBoxCount) {
                    this.selectAllValue = true
                }
            } else {
                this.selectAllValue = false
            }
        },
        selectAll($event) {
            this.list.forEach(property => {
                if (this.edit_enabled[property.id] === true) {
                    this.selected = []
                    this.selectedIds = []
                }
            })
            if ($event) {
                this.list.forEach(property => {
                    if (this.edit_enabled[property.id] === true) {
                        this.selected.push(property)
                        this.selectedIds.push(property.id)
                    }
                })
            }
        },
        deselectItems() {
            this.selected = []
            this.selectedIds = []
            this.selectAllValue = false
        },
    },
    beforeDestroy() {
        EventBus.$off(this.EVENT_TABLE_SEARCH)
        EventBus.$off(this.EVENT_TABLE_REFRESH)
    },
    watch: {
        filter: {
            handler(filterObject) {
                EventBus.$emit(this.EVENT_TABLE_SEARCH)
            }, deep: true
        },
        selectedProperty: {
            handler(object) {
                this.unitList = []
                if (object) {
                    searchRmUnits({property: object.id, contigent: 0, perPage: 1000}).then(response => {
                        this.unitList = response.data.items
                        this.selectedUnit = response.data.items.length > 0 ? response.data.items[0].id : null
                    })
                }
            }
        }
    },
    created() {
        const request = {
            data: [
                {
                    uid: F_REVENUE_MANAGER_COMPANY_ACTIVATION_RATES,
                    function: F_REVENUE_MANAGER_COMPANY_ACTIVATION_RATES,
                    key: AC_REVENUE_MANAGER_SETUP_PROPERTY
                },
                {
                    uid: F_REVENUE_MANAGER_COMPANY_ACTIVATION_MIN_STAY,
                    function: F_REVENUE_MANAGER_COMPANY_ACTIVATION_MIN_STAY,
                    key: AC_REVENUE_MANAGER_SETUP_PROPERTY
                },
            ],
            key: AC_REVENUE_MANAGER_SETUP_PROPERTY,
            context: {company: this.company.id, property: this.filter.property}
        }
        fetchAccessControlData(AC_REVENUE_MANAGER_SETUP_PROPERTY, request).then(response => {
            this.ac_response_active_rates = response.data[F_REVENUE_MANAGER_COMPANY_ACTIVATION_RATES].visible
            this.ac_response_active_min_stay = response.data[F_REVENUE_MANAGER_COMPANY_ACTIVATION_MIN_STAY].visible
        })
        fetchStatusList(OBJECT_TYPE_PROPERTY).then(response => {
            this.property_status_list = response.data
        }, () => {
            this.property_status_list = []
        })
    }
}
</script>

<style scoped>
.p-no-border {
    box-shadow: none;
}

.p-no-border > div {
    border: none !important;
    padding-left: 0;
}

.pointer-rm {
    cursor: pointer;
}
</style>
