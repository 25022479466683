<template>
    <div>
        <b-form @submit.prevent="submit">
            <b-row>
                <b-col>
                    <form-header>{{ $t('UNIT_CALENDAR') }}</form-header>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mb-4">
                    <b-form-checkbox
                        inline
                        id="select_all_year"
                        v-model="select_all_year"
                        name="select_all_year"
                        aria-describedby="years"
                        aria-controls="years"
                        @change="toggleYears"
                        :checked="true"
                        :unchecked-value="false">
                        {{ $t("YEAR") }} ({{ $t("SELECT_ALL") }})
                    </b-form-checkbox>
                    <b-form-checkbox-group
                        id="year-checkbox"
                        class="ml-4"
                        stacked
                        value-field="id"
                        text-field="name"
                        v-model="years"
                        :options="yearsComputed"
                        name="years"
                    ></b-form-checkbox-group>
                </b-col>

            </b-row>

            <!--
            <b-row>
                <b-col>
                    <form-header>{{ $t("CALENDAR_SYNC_OPTIONS") }}</form-header>
                </b-col>
            </b-row>

            <b-row>

                <b-col>
                    <b-form-checkbox
                        id="syncPrices"
                        v-model="sync_prices"
                        name="syncAvailability-1"
                        :value="true"
                        :unchecked-value="false">
                        {{ $t("PRICES") }}
                    </b-form-checkbox>
                </b-col>

            </b-row>
            <b-row>
                <b-col>
                    <b-form-checkbox
                        id="syncRoomStatus"
                        v-model="sync_room_status"
                        name="syncRoomStatus-1"
                        :value="true"
                        :unchecked-value="false">
                        {{ $t("ROOM_STATUS") }}
                    </b-form-checkbox>

                </b-col>

            </b-row>
            <b-row v-if="restrictions !== null">
                <b-col class="mb-4">
                    <b-form-checkbox
                        inline
                        id="select_all_restrictions"
                        v-model="select_all_restrictions"
                        name="select_all_restrictions-1"
                        @change="toggleRestrictions"
                        :value="true"
                        :unchecked-value="false">
                        {{ $t("RESTRICTIONS") }} ({{ $t("SELECT_ALL") }})
                    </b-form-checkbox>
                    <b-form-checkbox-group
                        id="restriction-checkbox"
                        class="ml-4"
                        stacked
                        value-field="id"
                        text-field="label"
                        v-model="sync_restrictions"
                        :options="restrictions"
                        name="flavour-1"
                    ></b-form-checkbox-group>
                </b-col>
            </b-row>
            -->

            <b-row>
                <b-col>
                    <app-button-submit>{{ $t('SAVE') }}</app-button-submit>
                </b-col>
            </b-row>
        </b-form>

    </div>

</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import moment from 'moment';
import FormHeader from "@/components/app/form/FormHeader";
import AppButton from "@/components/app/AppButton/AppButton";

export default {
    name: "BulkCalendarSaveForm",
    components: {AppButton, FormHeader, AppButtonSubmit, AppSelect},
    props: {
        restrictions: {
            default: () => {
                return []
            },
            type: Array | Object
        },
    },
    data() {
        return {
            years: [],
            sync: null,
            loading: false,
            date_range: {start: null, end: null},
            select_all_restrictions: true,
            sync_prices: true,
            sync_room_status: true,
            sync_restrictions: [1, 2, 3, 4, 5, 6],
            saving: false,
            select_all_year: false,
            fields: [
                {key: 'date', label: this.$t('SELECTED_PERIODS')},
                {key: 'delete', label: "", class: 'text-right'},
            ]
        }
    },
    computed: {

        yearsComputed() {
            let years = []
            years.push({
                id: (Number(new moment().format('YYYY'))).toString(),
                name: Number(new moment().format('YYYY'))
            })
            for (let i = 0; i < 2; i++) {
                years.push({
                    id: (Number(new moment().format('YYYY')) + 1 + i).toString(),
                    name: Number(new moment().format('YYYY')) + 1 + i
                })
            }

            return years

        },
    },
    methods: {

        submit() {
            let prepareRequest = {
                years: this.years,
                sync_options: {
                    unit_status: this.sync_room_status,
                    restriction: this.sync_restrictions,
                    rate: this.sync_prices
                },
            }
            this.$emit('openConfirmationSave', prepareRequest)
        },
        toggleRestrictions() {
            this.sync_restrictions = []
            if (!this.select_all_restrictions) {
                Object.keys(this.restrictions).forEach(key => {
                    this.sync_restrictions.push(this.restrictions[key].id)
                })
            }
        },
        toggleYears() {
            this.years = []
            if (!this.select_all_year) {
                Object.keys(this.yearsComputed).forEach(key => {
                    this.years.push(this.yearsComputed[key].id)
                })
            }
        }
    },
    watch: {
        years(newVal, oldVal) {
            // Handle changes in individual flavour checkboxes
            if (newVal.length === 0) {
                this.select_all_year = false
            } else if (newVal.length === this.yearsComputed.length) {

                this.select_all_year = true
            } else {
                this.select_all_year = false
            }
        }
    },

    created() {
        for (let i = 0; i < 2; i++) {
            this.years.push(
                (Number(new moment().format('YYYY')) + 1 + i).toString()
            )
        }
    }
}
</script>

<style scoped>

</style>
