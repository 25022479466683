<template>
    <div v-if="!formLoading">
        <b-form v-if="headlineObject !== null" @submit.prevent="updateHeadline">
            <app-translation-list class="mb-4"
                                  :language-list="languageList"
                                  :translations="headlineObject.translations !== null ? headlineObject.translations : {}"
                                  @update="setTranslations">
            </app-translation-list>
            <b-row>
                <app-button-submit :loading="saving">
                </app-button-submit>
            </b-row>
        </b-form>
    </div>
    <div class="d-flex justify-content-start align-items-center mb-4" v-else>
        <b-spinner variant="primary"></b-spinner>
    </div>
</template>

<script>
import AppTranslationList from "@/components/app/translation/AppTranslationList";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {fetchHeadline, updateHeadline} from "@/services/distribution";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";

export default {
    name: "HeadlineTranslationsForm",
    components: {AppButtonSubmit, AppTranslationList},
    mixins: [getErrorMessage],
    props: {
        headline: {
            type: Number,
        },
        languageList: {
            type: Array,
            default() {
                return []
            },
        },
    },
    data() {
        return {
            headlineObject: null,
            formLoading: false,
            saving: false,
        }
    },
    methods: {
        updateHeadline() {
            let request = {
                object_id: this.headlineObject.object_id,
                type_id: this.headlineObject.type_id,
                name: this.headlineObject.name,
            }

            if (this.headlineObject.translations !== null && Object.keys(this.headlineObject.translations).length > 0) {
                let requestFieldName = 'headline_translations'
                for (const [lang, value] of Object.entries(this.headlineObject.translations)) {
                    if (!request.hasOwnProperty(requestFieldName)) {
                        request[requestFieldName] = {}
                    }
                    request[requestFieldName][lang] = value
                }
            }

            updateHeadline(this.headlineObject.id, request).then(() => {
                notifySuccess()
                this.$emit('update')
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.saving = false
            })
        },
        fetchHeadline() {
            this.formLoading = true
            fetchHeadline(this.headline).then((response) => {
                this.headlineObject = response.data
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.formLoading = false
            })
        },
        setTranslations(translations) {
            if (translations !== null && typeof translations === 'object') {
                if (this.headlineObject.translations === null || typeof this.headlineObject.translations !== 'object') {
                    this.$set(this.headlineObject, 'translations', {})
                }
                for (const [lang, value] of Object.entries(translations)) {
                    this.$set(this.headlineObject.translations, lang, value)
                }
            }
        },
    },
    created() {
        this.fetchHeadline()
    },
}
</script>

<style scoped>

</style>
