<template>

        <div class="mb-4"  style="border: 0" >
            <div class="header-caption">{{$t('EDIT_SELECTED_DATES')}}</div>

            <h2 v-if="countDiffDays === 0"> {{formatDateRange.end}} </h2>

            <h2 v-else>
                {{formatDateRange.start}} - {{formatDateRange.end}}
            </h2>
            <div class="header-info">
               <template>
                   {{this.property.name}}
               </template>
                <template v-if="showUnitType">
                    {{rootUnit.unit_type.name ? " | " + rootUnit.unit_type.name  : ''}}
                </template>

                <template v-if="!contigentMode && contigentUnit">
                   {{unitName ?  " | " + unitName : ''}}
                </template>

            </div>
        </div>

</template>

<script>
    import moment from 'moment'
    export default {
        name: "CalendarAsideHeader",
        props:{
            dateRange:{
                type:Object
            },
            property:{
                type:Object,
            },
            rootUnit:{
              type:Object
            },
            contigentMode:{
                type:Boolean
            },
            contigentUnit:{
                type:Object
            },
            countDiffDays:{
                type:Number
            }
        },
        computed:{
            formatDateRange(){
                let end = moment(this.dateRange.end).format("D.M.YYYY")
                let start = moment(this.dateRange.start).format("D.M.")
                return {
                    start,
                    end
                }
            },
            unitName(){
                return (this.contigentUnit && this.contigentUnit.hasOwnProperty('name')) ? this.contigentUnit.name : this.rootUnit.name
            },
            showUnitType(){
                return this.rootUnit.unit_type.name !== null
            }

        }
    }
</script>

<style scoped>

</style>
