<template>
    <div>
        <b-row>
            <b-col class="mb-3 d-flex justify-content-end align-items-baseline">
                <app-button button_type="new" variant="primary" @click="edit({})"
                            :c_permission="C_SETUP_FINANCE_ITEM_DESCRIPTION_NEW">
                    {{ $t("NEW_ITEM") }}
                </app-button>
            </b-col>
        </b-row>

        <invoice-article-filter @search="search" :loading="loading"></invoice-article-filter>
        <invoice-article-list v-if="tableItems.length" :tableItems="tableItems" @edit="edit" :loading="loading"></invoice-article-list>
        <app-no-data v-else-if="!tableItems.length && !loading"></app-no-data>
        <app-pagination :listCount="total" v-model="filter.page" :last-page="lastPage"></app-pagination>

        <app-aside v-model="sidebarState">
            <template slot="header">{{ sidebarHeader }}</template>
            <invoice-article-form :article-object="articleObject"
                            @created="create"
                            @updated="update"
                            @deleted="_delete">
            </invoice-article-form>
        </app-aside>
    </div>
</template>

<script>
    import {getArticleList} from "@/services/finance/setup";
    import FormHeader from "@/components/app/form/FormHeader";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppAside from "@/components/app/form/AppAside";
    import AppPagination from "@/components/app/filter/AppPagination";
    import InvoiceArticleFilter from "@/components/finance/setup/invoice_articles/InvoiceArticleFilter";
    import InvoiceArticleList from "@/components/finance/setup/invoice_articles/InvoiceArticleList";
    import InvoiceArticleForm from "@/components/finance/setup/invoice_articles/InvoiceArticleForm";
    import AppNoData from "@/components/app/AppNoData";
    import {C_SETUP_FINANCE_ITEM_DESCRIPTION_NEW} from "@/shared/component_permission";

    export default {
        name: "Index",
        components: {
            AppNoData,
            InvoiceArticleForm,
            InvoiceArticleList,
            InvoiceArticleFilter,
            AppPagination, AppButton, FormHeader, AppAside},
        data() {
            return {
                tableItems: [],
                loading: false,
                articleObject: null,
                sidebarState: false,
                total: null,
                lastPage: null,
                filter: {page: 1},
                C_SETUP_FINANCE_ITEM_DESCRIPTION_NEW
            }
        },
        computed: {
            companyId() {
                return this.$store.getters['user/getCompany']
            },
            sidebarHeader() {
                return this.articleObject && this.articleObject.id ? this.$t('EDIT_ITEM') : this.$t('NEW_ITEM')
            }
        },
        methods: {
            edit(articleObject) {
                this.articleObject = articleObject
                this.articleObject.company = this.companyId
                this.sidebarState = true
            },
            create(object) {
                this.tableItems.unshift(object)
                this.sidebarState = false
            },
            update(object) {
                this.$set(this.tableItems, this.tableItems.findIndex(x => x.id === object.id), object)
                this.sidebarState = false
            },
            _delete(objectId) {
                this.tableItems.splice(this.tableItems.findIndex(x => x.id === objectId), 1)
                this.sidebarState = false
            },
            getList() {
                this.loading = true
                getArticleList({company: this.companyId, ...this.filter}).then(response => {
                    this.total = response.data.total
                    this.lastPage = response.data.last_page
                    this.tableItems = response.data.items
                }).finally(() => this.loading = false)
            },
            search(filter) {
                this.filter = {...filter}
            }
        },
        watch: {
            filter: {
                handler() {
                    this.getList()
                },
                immediate: true,
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>
