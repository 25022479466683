<template>
    <b-row>

        <b-col sm="12" lg="12" :key="unit.id" v-for="unit in unitList">
            <unit-product-management  @AccessControlEvent="addToAccessControlCounter()"
                                     :key="unit.id"
                                      :mappingUnitData="getUnitMappingById(unit.id)"
                                     :unit="unit"
                                      :ical-export-links="icalExportLinks[unit.id]"
                                      :ical-import-object="icalValue[unit.id]"
                                      :ical-table-key="icalTableKey"
                                      @refreshTable="getParameterList"
                                      :unit-status="unitStatusList.hasOwnProperty(unit.id) ? unitStatusList[unit.id] : null"
            >
            </unit-product-management>

        </b-col>
    </b-row>

</template>

<script>

    import UnitProductManagement from "@/components/channel_manager/product_management/UnitProductManagement";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {AC_CHANNEL_MANAGER_UNIT} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {getUnitsICal} from "@/services/property";
    import {getObjectStatusList, getSetupParameterList} from "@/services/channel_manager";
    import {OBJECT_TYPE_UNIT} from "@/shared/constants";

    export default {
        components: {UnitProductManagement},
        name: "unit-wrapper",
        props:{
            unitList:{
                type:Array
            },
            unitMappingList:{
                type:Array
            }
        },
        computed:{
            property() {
                return this.$store.getters['property/getProperty']
            },
        },
        methods:{
          dataFetched(value){
              this.data_fetched = value
          },
            getUnitMappingById(unit_id){
               let unitMappingObject = this.unitMappingList.find(el=> el.unit_id === unit_id)
                if(typeof unitMappingObject === 'undefined'){
                   return {}
                }
                return unitMappingObject
            },
            getParameterList(){
                getSetupParameterList({
                    level: 'unit',
                    distribution: [this.$route.params.distribution_id],
                    property: this.property.id,
                    setup_parameter: this.SETUP_PARAMETER_IMPORT_ICAL,
                    unit: this.unitIdList
                }).then(response => {
                    this.icalTableKey += 1
                    if (response.data){
                        response.data[0].parameters.forEach(el => {
                            if(el.parameter_id === 100 && el.values.unit){
                                let data = JSON.parse(el.values.unit.value)
                                data.forEach(obj => {obj.loading = false})
                                this.icalValue[el.values.unit.id] = data
                            }
                        })
                    }
                })
            },
            getUnitListStatus() {
                const request = {
                    object: this.unitIdList,
                    object_type: OBJECT_TYPE_UNIT,
                    distribution: this.$route.params.distribution_id,
                }
                getObjectStatusList(request).then((response)  => {
                    response.data.forEach((objectStatusItem) => {
                        let currentStatus = {
                            id: objectStatusItem.hasOwnProperty('current_status') ? objectStatusItem.current_status.id : null,
                            name: objectStatusItem.hasOwnProperty('current_status') ? objectStatusItem.current_status.name : null,
                            status_eid: objectStatusItem.hasOwnProperty('current_status') ? objectStatusItem.current_status.status_eid : null,
                        }
                        this.$set(this.unitStatusList, objectStatusItem.object_id,currentStatus)
                    })
                })
            },
        },
        mixins:[AccessControlComponent],
        data(){
          return {
              data_fetched:false,
              access_control_fetch_key: AC_CHANNEL_MANAGER_UNIT,
              AC_CHANNEL_MANAGER_UNIT,
              showDeactivateDialog: false,
              icalExportLinks: [],
              unitIdList: [],
              icalValue: [],
              icalTableKey: 0,
              unitStatusList: {},
          }
        },
        created() {
            getUnitsICal(this.property.id).then(res => {
                res.data.forEach(el => {
                    this.$set(this.icalExportLinks, el.id, el.export_enabled === 1 ? el.ical : '')
                })
            })
            this.unitList.forEach(el => {
                this.unitIdList.push(el.id)
            })
            this.getParameterList()
            this.getUnitListStatus()
        },
        watch:{

            unitList:{
                handler(list){

                    this.access_control_components = list.length
                },
                immediate:true
            }

        }
    }
</script>

<style scoped>

</style>
