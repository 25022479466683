<template>
    <b-form @submit.prevent="save">

        <b-row class="mb-2">
            <b-col lg="12" class="mb-4">
                <label>{{ $t('TITLE') }}</label>
                <b-input v-model="title"></b-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="6">
                <app-button-submit :loading="loading"></app-button-submit>
            </b-col>
        </b-row>

    </b-form>

</template>

<script>
import FormHeader from "@/components/app/form/FormHeader";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
import {createRmFilterUnit, rmFilterSetUnits} from "@/services/revenue_manager";
import {toast} from "@/shared/plugins/toastr";
export default {
    name: "RevenueManagerGroupFilterForm",
    components: {AppButtonDelete, AppButtonSubmit, FormHeader},
    props:{
        unit_ids:{
            type:Array,
            default(){
                return []
            }
        }
    },
    data(){
        return {
            loading:false,
            title:null
        }
    },
    methods:{
        save(){
            this.loading = true
            createRmFilterUnit({title:this.title}).then(response=>{

                rmFilterSetUnits(response.data,{units:this.unit_ids}).then(response =>{
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.$emit('close')
                },error=>{
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                }).finally(()=>{
                    this.loading = false
                })
            })
        }
    }
}
</script>

<style scoped>

</style>
