import http from "@/services/http";
import {STATUS_LIST_URL, API_URL} from "@/services/endpoints";

export function fetchStatusList(object_type_id) {
    return http.request({
        url: STATUS_LIST_URL(object_type_id) + '/list',
        method: 'get',
    })
}

export function getStatusList(object_type) {
    return http.request({
        url: STATUS_LIST_URL(),
        method: 'get',
        params: {object_type}
    })
}

export function getStatusLogs(object_type_id, params = {page: 1, limit: 15}) {
    return http.request({
        url: STATUS_LIST_URL(object_type_id) + '/logs',
        method: 'get',
        params: params
    })
}

export function getInvoiceStatusList(params) {
    return http.request({
        url: STATUS_LIST_URL('invoice'),
        method: 'GET',
        params
    })
}

export function fetchCmStatusList(params) {
    return http.request({
        url: API_URL + '/cm-status',
        method: 'GET',
        params: params
    })
}
