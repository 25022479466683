<template>

    <div>
        <tr class="header-days">
            <td v-if="checkPermission(permission)">{{CurrentMonthLabel}}</td>
            <td v-else style="width: 5%"></td>
            <td :key="dayNumberIndex" v-for="(dayNumber, dayNumberIndex) in CurrentMonthDays">{{dayNumber}}</td>
        </tr>
        <tr>
            <td class="commission-value">
                <b-input-group class="commission-copy-group" v-if="checkPermission(permission)">
                    <b-form-input :disabled="disabled_commission_input" class="commission-copy-input" type="text"
                                  v-model="monthCommissionValue"></b-form-input>
                    <b-input-group-append>
                        <b-btn style="min-width: unset" :disabled="disabled_commission_input" variant="primary" @click="copyMonthCommission(month)">+</b-btn>
                    </b-input-group-append>
                </b-input-group>
                <span v-else>
                    {{CurrentMonthLabel}}
                </span>
            </td>
            <td :key="dayNumberIndex" class="commission-value" v-for="(dayNumber, dayNumberIndex) in CurrentMonthDays">
                <b-input :disabled="disabled_commission_input" class="commissionInput" type="number" step="0.01" @change="updateValue(dayNumber, $event)"
                         :value="findValueForDay(dayNumber)"></b-input>
            </td>
        </tr>
    </div>


</template>

<script>
    import _findKey from 'lodash/findKey'

    export default {
        name: "commission-row",
        props: {
            month: {
                type: Number,
                default: 28

            },
            year: {
                type: Number

            },
            commissions: {
                type: Array,
            },
            save:{
                type:Boolean
            },
            commission_type:{
                type:String
            },
            permission: {
                type: Number,
                required: true
            },
            disabled_commission_input: {
                type: Boolean
            }
        },
        data() {
            return {
                monthCommissionValue: null,
            }
        },

        computed: {
            CurrentMonthDays() {
                return new Date(this.year, this.month, 0).getDate()
            },
            CurrentMonthLabel() {
                let locale = this.$i18n.locale;
                let newDate = new Date(this.year, this.month, 0)
                let month = newDate.toLocaleString(locale, {month: "long"})
                return month;
            }
        },
        methods: {
            findValueForDay(day) {

                let newMonth = this.month > 9 ? this.month.toString() : ("0" + this.month.toString())
                day = day < 10 ? day = "0" + day : day
                let date = this.year.toString() + "-" + newMonth + "-" + day

                let newElementKey = _findKey(this.commissions, (el) => {
                    return el.label === date
                })
                if (typeof newElementKey === "undefined") {
                    return null
                }
                return this.commissions[newElementKey].value
            },
            updateValue(day, value) {
                let newMonth = this.month > 9 ? this.month.toString() : ("0" + this.month.toString())
                day = day < 10 ? day = "0" + day : day
                let date = this.year.toString() + "-" + newMonth + "-" + day
                let newElementKey = _findKey(this.commissions, (el) => {
                    return el.label === date
                })
                if (typeof newElementKey === "undefined") {
                    return null
                }
                this.commissions[newElementKey].value = value
            },
            copyMonthCommission(currentMonth) {
                let valueCopy = this.monthCommissionValue;
                this.commissions.forEach(function (element, index) {
                    let objDate = new Date(element.label);
                    let month = objDate.getMonth() + 1
                    if (currentMonth === month) {

                        element.value = valueCopy;
                    }
                });
                this.monthCommissionValue = null
            },

        },
        watch:{
            year:{
                handler(val){
                        this.monthCommissionValue = null
                },
                immediate: true
            },
            save:{
                handler(val){
                    this.monthCommissionValue = null
                },
                immediate:true
            },
            commission_type:{
                handler(val){
                    this.monthCommissionValue = null
                },
                immediate:true
            }
        }
    }
</script>

<style lang="scss" scoped>

    td {
        font-size: .7em;
        padding: .4em .4em;
    }

    .header-days {
        /*background: #6998af14;*/
        font-weight: 600;
        color: #313131;
        font-size: 1em;
        background-color: rgba(50,145,148,0.1);
    }

    .header-days td {
        padding: .3em .3em;
    }

    .commission-copy-group {
        width: 5rem;
    }

    input {
        font-size: 1.1em;
        padding: .2em .4em;
        /*width: 3em;*/
        text-align: center;
        min-width: 2rem;
    }
    .commission-value{
        padding: .2rem .3rem!important;

        & button {
            min-width: unset;
            padding: 0 .3rem;
        }

    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
</style>
