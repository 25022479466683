<template>
<form>
    <b-tabs v-model="activeTab" nav-class="tab_navigation-horizontal"
            content-class="tab_content-horizontal" pills
            justified class="mb-1">
        <b-tab :title="$t('HEADLINE')" v-if="hasPermission(C_PROPERTY_CM_PM_UNIT_HEADLINE_V)">
            <b-row class="mb-4">
                <b-col>
                    <unit-headline-form :unit-id="unit_id" :distribution-id="distribution_id" @save="$emit('save')"></unit-headline-form>
                </b-col>
            </b-row>
        </b-tab>
        <b-tab :title="$t('DESTINATION')" v-if="hasPermission(C_UNIT_ACTIVATION_DESTINATION_V)">
            <b-row class="pt-0">
                <b-col>
                    <destination-tagging-form :unit_id="unit_id"
                                              :distribution_id="distribution_id"
                                              :company_id="company.id"
                                              @destinationSaved="$emit('save')"
                    ></destination-tagging-form>
                </b-col>
            </b-row>
        </b-tab>
        <b-tab :title="$t('TAG')" v-if="hasPermission(C_UNIT_ACTIVATION_TAG_V)">
            <b-row>
                <b-col lg="6">
                    <app-tag-entity-form :selected-items="[unit_id]"
                                         :tag-types="[TAG_TYPE_CUSTOM]"
                                         :object-type="OBJECT_TYPE_UNIT"
                                         @saved="$emit('save')"
                    ></app-tag-entity-form>
                </b-col>
            </b-row>
        </b-tab>
        <b-tab :title="$t('BOOK_OPTION')" v-if="hasPermission(C_UNIT_ACTIVATION_BOOK_OPTIONS_V)">
            <b-row>
                <b-col>
                    <b-form-checkbox switch unchecked-value="0" value="1" v-model.number="instant_booking">{{$t('INSTANT_BOOKING_ENABLED')}}</b-form-checkbox>
                    <b-form-checkbox switch unchecked-value="0" value="1" v-model.number="inquiry">{{$t('INQUIRY_ENABLED')}}</b-form-checkbox>
                </b-col>
            </b-row>
            <b-row class="mt-4" v-if="hasPermission(C_UNIT_ACTIVATION_BOOKING_OPTIONS_SAVE_V)">
                <b-col>
                    <app-button :loading="saveLoading" @click="save">{{$t('SAVE')}}</app-button>
                </b-col>
            </b-row>
        </b-tab>
    </b-tabs>
</form>
</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";
import FormHeader from "@/components/app/form/FormHeader";
import UnitHeadlineForm from "@/components/channel_manager/headline/UnitHeadlineForm";
import DestinationTaggingForm from "@/components/direct_booking/website/unit_activation/DestinationTaggingForm";
import CustomTaggingForm from "@/components/direct_booking/website/unit_activation/CustomTaggingForm";
import AppTagEntityForm from "@/components/app/tag/AppTagEntityForm";
import {EventBus} from "@/shared/EventBus";
import {getUnitSetupParameter, saveUnitSetupParameter} from "@/services/direct_booking/website";
import {WEBSITE_SETUP_PARAMETER_INQUIRY, WEBSITE_SETUP_PARAMETER_INSTANT_BOOKING} from "@/shared/constants";
import {TAG_TYPE_CUSTOM, OBJECT_TYPE_UNIT} from "@/shared/constants";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {C_PROPERTY_CM_PM_UNIT_HEADLINE_V,C_UNIT_ACTIVATION_TAG_V,C_UNIT_ACTIVATION_DESTINATION_V,C_UNIT_ACTIVATION_BOOK_OPTIONS_V, C_UNIT_ACTIVATION_BOOKING_OPTIONS_SAVE_V} from "@/shared/component_permission";

export default {
    name: "UnitEditForm",
    mixins:[getErrorMessage],
    props:{
        distribution_id:{
            type:Number,
            default: null
        },
        unit_id:{
            type: Number,
            default: null
        }
    },
    components:{AppButton,FormHeader, UnitHeadlineForm, DestinationTaggingForm, CustomTaggingForm, AppTagEntityForm},
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        website(){
            return this.$store.getters['website/getWebsite']
        },
        hasPermission() {
            return this.$store.getters['user/getPermission']
        }
    },
    data(){
        return{
            setup_parameter: [],
            saveLoading: false,
            inquiry: null,
            instant_booking: null,
            activeTab: 0,
            TAG_TYPE_CUSTOM,
            OBJECT_TYPE_UNIT,
            C_PROPERTY_CM_PM_UNIT_HEADLINE_V,
            C_UNIT_ACTIVATION_TAG_V,
            C_UNIT_ACTIVATION_DESTINATION_V,
            C_UNIT_ACTIVATION_BOOK_OPTIONS_V,
            C_UNIT_ACTIVATION_BOOKING_OPTIONS_SAVE_V
        }
    },
    methods:{
        getData(){
            getUnitSetupParameter(this.website.id, this.unit_id).then(response => {
                response.data.forEach(el => {
                    el.setup_parameter_id === WEBSITE_SETUP_PARAMETER_INSTANT_BOOKING ? this.instant_booking = el.value_bool : ''
                    el.setup_parameter_id === WEBSITE_SETUP_PARAMETER_INQUIRY ? this.inquiry = el.value_bool : ''
                })
            })
        },
        save(){
            let data = [
                {
                    setup_parameter_id: WEBSITE_SETUP_PARAMETER_INSTANT_BOOKING,
                    value_bool: this.instant_booking
                },
                {
                    setup_parameter_id: WEBSITE_SETUP_PARAMETER_INQUIRY,
                    value_bool: this.inquiry
                }
            ]
            this.saveLoading = true
            saveUnitSetupParameter(this.website.id, this.unit_id, {data: data}).then(response => {
                notifySuccess()
                this.getData()
                this.saveLoading = false
                this.$emit('save')
            }, error => {
                this.saveLoading = false
                this.showErrorMessages(error)
            })
        }
    },
    created() {
        this.getData()
    },
}
</script>

<style scoped>

</style>
