<template>
    <div class="main_calendar_test__holder--content--rateplan_restriction_list main_calendar_test-bb ">

        <template v-for="restrictionType in restrictions">
            <div :key="restrictionType.id" v-if="restrictionType.id !== SALES_ENABLED"
                 class="d-flex justify-content-start align-items-center animated fadeIn">
                <div class="main_calendar_test__holder--sidebar-h2">{{restrictionType.label}}</div>
                <span class="d-inline-block"  v-if="showTooltip(restrictionType.id)" :id="'tooltip-target_rest_inherit-'+ratePlan.id+'-'+restrictionType.id">
                <b-tooltip  custom-class="my-tooltip-class" :target="'tooltip-target_rest_inherit-'+ratePlan.id+'-'+restrictionType.id" >
                    {{$t('RESTRICTION_NOT_ALLOWED_CHANGE', {rate_plan_name:ratePlan.parent_rate_plan.name})}}
                </b-tooltip>
                           <i  class=" fa fa-question-circle-o ml-1 pull-right tooltip-help  mr-2 mt-1" @click="show = true"></i>
                </span>
            </div>
        </template>
    </div>
</template>

<script>
import {
    CLOSED_ON_ARRIVAL,
    CLOSED_ON_DEPARTURE,
    MAX_STAY,
    MIN_STAY,
    RELEASE_PERIOD,
    SALES_ENABLED
} from "@/shared/constants";
    import {DISTRIBUTION_PHOTO} from "@/services/endpoints"
    import {has} from 'lodash'

    export default {
        name: "TableRestrictionTypeRatePlanSidebar",
        props: {
            ratePlan: {
                type: Object
            }
        },
        data() {
            return {
                SALES_ENABLED
            }
        },
        methods:{
            showTooltip(restriction_id){
                if(restriction_id === MIN_STAY){
                    return this.ratePlan.inherit_minimum_stay
                }
                if(restriction_id === MAX_STAY){
                    return this.ratePlan.inherit_maximum_stay
                }
                if(restriction_id === RELEASE_PERIOD){
                    return this.ratePlan.inherit_release_period
                }
                if(restriction_id === CLOSED_ON_ARRIVAL){
                    return this.ratePlan.inherit_check_in_days
                }
                if(restriction_id === CLOSED_ON_DEPARTURE){
                    return this.ratePlan.inherit_check_out_days
                }

                return  false
            }
        },
        computed: {
            restrictions() {
                return this.$store.getters['calendar/getRestrictions']
            },
            mainColumn() {
                return {
                    'gridColumn': '1 / 1',
                    'gridRow': `1 / span ${Object.keys(this.restrictions).length - 1}`,
                }
            },
            distributionPhotoUrl() {
                if (has(this.ratePlan, 'distribution')) {
                    return DISTRIBUTION_PHOTO(this.ratePlan.distribution)
                }
                return DISTRIBUTION_PHOTO(0)
            },
            showOnlyIcon() {
                return this.ratePlan.name.length > (Object.keys(this.restrictions).length * 6)
            },
            ratePlanTooltipText() {
                if (this.showOnlyIcon) {
                    return this.ratePlan.name + ' - ' + this.ratePlan.distribution_name
                }
                return this.ratePlan.distribution_name
            },
            ratePlanNameFormatted() {
                if (this.showOnlyIcon) {
                    return this.ratePlan.name.substring(0, (Object.keys(this.restrictions).length * 6) - 3) + '...'
                }
                return this.ratePlan.name
            }
        }
    }
</script>

<style scoped>

</style>
