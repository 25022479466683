<template>
    <b-form @submit.prevent="save">
        <b-row class="main-name">
            <b-col>
                <label>{{$t('NAME')}}</label>
                <b-input v-model="label" type="text"></b-input>
            </b-col>
        </b-row>
        <app-translation-list :language-list="translateList" @update="languageList"></app-translation-list>
        <b-row class="save-button">
            <b-col>
                <app-button @click="save">{{ $t("SAVE") }}</app-button>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>

import AppButton from "@/components/app/AppButton/AppButton";
import AppTranslationList from "@/components/app/translation/AppTranslationList";
import {getTranslateList} from "@/services/system";
import {saveUnitType} from "@/services/direct_booking/website";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";

export default {
    name: "WebsiteNewUnitType",
    components:{AppButton, AppTranslationList},

    mixins: [getErrorMessage],

    props:{
        websiteId:{
            type: Number,
            default: null
        }
    },

    data(){
        return{
            label: null,
            type:{}
        }
    },

    methods:{
        save(){
            saveUnitType(this.websiteId, {label: this.label, translation: this.type}).then(response => {
                notifySuccess()
                this.$emit('closeUnitType')
            }).catch(error => {
                this.showErrorMessages(error)
            })
        },
        languageList(value){
            this.type = {...value}
        }
    },
    computed:{
        website(){
            return this.$store.getters['website/getWebsite']
        },
        translateList() {
            return this.website.supported_languages.filter(el => el !== "en")
        }
    },
}
</script>

<style scoped>

.main-name{
    padding-bottom: 1.5rem;
}

.save-button{
    padding-top: 1rem;
}

</style>
