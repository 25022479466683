<template>
    <div>
        <app-table-v2 search-event="reloadWebsitePromoCodes" :provider="getData" :fields="fields"
                      :show-limit="true"
                      selectable
                      @busyToggled="toggleBusy">
            <template v-slot:value="data">
                <p v-if="data.item.value_fixed === null" class="mb-0">{{data.item.value_percentage + ' %'}}</p>
                <p v-else class="mb-0">{{data.item.value_fixed}}</p>
            </template>

            <template v-slot:code="data">
                <small class="text-muted d-block">
                    {{data.item.label}}
                </small>
                <b-link :to="{name: routeNames.RN_DIRECT_BOOKING_PROMO_CODES_LIST}">
                    {{data.item.code}}
                </b-link>
            </template>

            <template v-slot:dates_for="data">
                <p v-if="data.item.for_dates.from && data.item.for_dates.to" class="mb-0">{{data.item.for_dates.from | date}} — {{data.item.for_dates.to | date}}</p>
                <p v-else class="mb-0"></p>
            </template>

            <template v-slot:dates_valid="data">
                <p v-if="data.item.valid_dates.from && data.item.valid_dates.to" class="mb-0">{{data.item.valid_dates.from | date}} — {{data.item.valid_dates.to | date}}</p>
                <p v-else class="mb-0"></p>
            </template>

            <template v-slot:category="data">
                <b-link :to="{name: routeNames.RN_DIRECT_BOOKING_PROMO_CODES_CATEGORY}">
                    {{data.item.category.label}}
                </b-link>
            </template>

            <template v-slot:available_count="data">
                <p v-if="data.item.available_count" class="mb-0">{{data.item.available_count + '/' + data.item.total_count}}</p>
            </template>

            <template v-slot:active="data">
                <b-checkbox
                    switch
                    :id="'activate_key_' + data.item.id"
                    @change="editItem(data.item, $event)"
                    v-model="data.item.active">
                </b-checkbox>
            </template>

            <template v-slot:selectedContainer="data">
                <app-button @click="activatePromo(data.selected)"
                            class="mr-3"
                            :loading="loading"
                >{{$t('ACTIVATE')}}</app-button>
                <app-button @click="deactivatePromo(data.selected)"
                            :disabled="loading"
                >{{$t('DEACTIVATE')}}</app-button>
            </template>

        </app-table-v2>
        <app-aside v-model="new_promo_code">
            <template slot="header">
                {{$t('NEW_PROMO_CODE')}}
            </template>
            <promo-code-new-form @saved="newCodeAdded($event)"></promo-code-new-form>
        </app-aside>
        <app-confirmation-dialog
            :show="confirmation_dialog_state"
            @confirm="continueAction"
            @cancel="cancelAction"
            :title="$t('PROMO_CODES')">
            {{ $t('PROMO_DEACTIVATE_MSG') }}
        </app-confirmation-dialog>
    </div>
</template>

<script>

import AppTableV2 from "@/components/app/AppTableV2";
import AppButton from "@/components/app/AppButton/AppButton";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import AppAside from "@/components/app/form/AppAside";
import PromoCodeNewForm from "@/components/direct_booking/promo_codes/list/PromoCodeNewForm";
import routeNames from "@/router/routeNames";
import {PROMO_CODE_WEBSITE} from "@/shared/constants";
import {createPromoCodeFilter, deletePromoCodeFilter, getPromoCodes} from "@/services/promo_codes";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {EventBus} from "@/shared/EventBus";

export default {
    name: "WebsitePromoCodeTable",
    components:{AppTableV2, AppButton, AppConfirmationDialog, AppAside, PromoCodeNewForm},
    mixins:[getErrorMessage],
    props:{
        filter:{
            type: Object
        }
    },
    data(){
        return{
            confirmation_dialog_state: false,
            promoId: null,
            new_promo_code: false,
            selectedData: [],
            loading: false,
            fields: [
                {key: 'id', label: this.$t('ID')},
                {key: 'code', label: this.$t('CODE')},
                {key: 'category', label: this.$t('CATEGORY')},
                {key: 'dates_valid', label: this.$t('VALID_THROUGH')},
                {key: 'dates_for', label: this.$t('FOR_DATES')},
                {key: 'value', label: this.$t('DISCOUNT')},
                {key: 'available_count', label: this.$t('AVAILABLE')},
                {key: 'active', label: this.$t("ACTIVE"),tdClass: 'action_button_w'}
            ],
            routeNames
        }
    },
    computed:{
        website(){
            return this.$store.getters['website/getWebsite']
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods:{
        getData(){
            return getPromoCodes({website: this.website.id, ...this.filter, company: this.company.id})
        },
        toggleBusy($event){
            this.$emit('busyToggled', $event)
        },
        continueAction(){
            deletePromoCodeFilter({promo_id: this.selectedData, parameter_id:PROMO_CODE_WEBSITE, object_id: [this.website.id]}).then(response => {
                notifySuccess()
                this.confirmation_dialog_state = false
                EventBus.$emit('reloadWebsitePromoCodes')
            }, error => {
                this.confirmation_dialog_state = false
                this.showErrorMessages(error)
            })
        },
        cancelAction(){
            this.confirmation_dialog_state = false
            EventBus.$emit('reloadWebsitePromoCodes')
        },
        editItem(data, event){
            this.$nextTick(() => {
                if (event){
                    createPromoCodeFilter({promo_id: [data.id], parameter_id: PROMO_CODE_WEBSITE, object_id: this.website.id}).then(response => {
                        notifySuccess()
                        EventBus.$emit('reloadWebsitePromoCodes')
                    },error => {
                        this.showErrorMessages(error)
                    })
                }else{
                    this.selectedData = []
                    this.selectedData.push(data.id)
                    this.confirmation_dialog_state = true
                }
            })

        },
        newCodeAdded(data){
            this.new_promo_code = false
            createPromoCodeFilter({promo_id: [data.id], parameter_id: PROMO_CODE_WEBSITE, object_id: this.website.id}).then(response => {
                EventBus.$emit('reloadWebsitePromoCodes')
            }, error => {
                this.showErrorMessages(error)
            })
        },
        deactivatePromo(selected){
            this.selectedData = []
            selected.forEach(el => {this.selectedData.push(el.id)})
            this.confirmation_dialog_state = true
        },
        activatePromo(selected){
            this.loading = true
            this.selectedData = []
            selected.forEach(el => {this.selectedData.push(el.id)})
            createPromoCodeFilter({promo_id: this.selectedData, parameter_id: PROMO_CODE_WEBSITE, object_id: this.website.id}).then(response => {
                notifySuccess()
                EventBus.$emit('reloadWebsitePromoCodes')
                this.loading = false
            }, error => {
                this.loading = false
                this.showErrorMessages(error)
            })
        }
    },
    mounted() {
        EventBus.$on('newWebsitePromoCode', () => {
            this.new_promo_code = true
        })
    },
    watch:{
        filter: {
            handler() {
                EventBus.$emit('reloadWebsitePromoCodes')
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
