<template>
    <div>
       <div v-if="data_loaded" >
           <guest-form-evisitor  v-if="formData.connection_id === EVisitorCro"
                                 :formData="formData" :guestObject="guestObject" :save_loading = "save_loading" :checkin_loading="checkin_loading"
                                 @saveGuest="update" @registerGuest="register"
                                 @deleteGuest="deleteGuest"
                                 @deletePhoto="deleteGuestPhoto"
                                 @showDeleteDialog="updateDeleteDialog"
                                 :show-delete-dialog="showDeleteDialog"
           >
           </guest-form-evisitor>
           <guest-form-id-reader v-else-if="formData.connection_id === IdReaderSrb"
                                 :formData="formData" :guestObject="guestObject" :save_loading = "save_loading" :checkin_loading="checkin_loading"
                                 @saveGuest="update" @registerGuest="register"
                                 @deleteGuest="deleteGuest"
                                 @deletePhoto="deleteGuestPhoto"
                                 @showDeleteDialog="updateDeleteDialog"
                                 :show-delete-dialog="showDeleteDialog"
           >

           </guest-form-id-reader>
           <guest-form-ajpes v-else-if="formData.connection_id === AjpesSlo"
                             :formData="formData" :guestObject="guestObject"  :save_loading = "save_loading" :checkin_loading="checkin_loading"
                             @saveGuest="update" @registerGuest="register"
                             @deleteGuest="deleteGuest"
                             @deletePhoto="deleteGuestPhoto"
                             @showDeleteDialog="updateDeleteDialog"
                             :show-delete-dialog="showDeleteDialog"
           >

           </guest-form-ajpes>

       </div>
        <div v-else class="center">
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
        </div>
    </div>


</template>

<script>

    import {getGuestById, updateGuest, registerGuest, deleteGuest, deleteGuestImage} from '@/services/guest/index'
    import {EVisitorCro, AjpesSlo, IdReaderSrb} from "@/shared/constants";
    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import GuestFormEvisitor from "@/components/guest/forms/form_per_connection/GuestFormEvisitor";
    import GuestFormIdReader from "@/components/guest/forms/form_per_connection/GuestFormIdReader";
    import GuestFormAjpes from "@/components/guest/forms/form_per_connection/GuestFormAjpes";
    import {GUEST_IDENTIFICATION_PHOTO} from "@/shared/constants";


    export default {
        name: "guest-form",
        props: {
            guest_id: {
                type: Number
            },
            formData:{
                type:Object
            }
        },
        mixins: [getErrorMessage],
        data() {
            return {
                GUEST_IDENTIFICATION_PHOTO,

                guestObject: {},
                data_loaded: false,
                showDeleteDialog: false,
                EVisitorCro,
                AjpesSlo,
                IdReaderSrb,
                loading:false,
                save_loading:false,
                checkin_loading:false,
            }
        },
        components: {
            GuestFormAjpes,
            GuestFormIdReader,
            GuestFormEvisitor,


        },

        methods: {
            updateDeleteDialog(value){
                this.showDeleteDialog = value
            },
            deleteGuest(id) {
                deleteGuest(id).then(() => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })

                    this.$emit('updateGuest')
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.getMessages(error),
                        'type': 'error',
                        'timeout': 3000
                    })
                }).finally(()=>{
                    this.showDeleteDialog = false
                })
            },
            fetchGuest(id) {
                getGuestById(id).then(response => {
                        this.guestObject = response.data
                        this.data_loaded = true
                    }
                )
            },
            update(data) {
                this.save_loading = true
                data.guestObject.register_guest = 0
                updateGuest(data.guestObject.id, data.guestObject).then(() => {
                    this.save_loading = false
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                    this.$emit('updateGuest')
                }, error => {
                    this.showErrorMessages(error, data.validation)
                    this.save_loading = false
                })
            },
            register(data) {
                let request  = data.guestObject
                request.register_guest = 1
                this.checkin_loading = true
                updateGuest(data.guestObject.id, request).then(() => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                    this.$emit('updateGuest')
                }, error => {
                    this.showErrorMessages(error, data.validation)
                }).finally(()=>{
                    this.checkin_loading = false
                })
            },
            deleteGuestPhoto(id){
                deleteGuestImage(this.guestObject.id, id).then(()=>{
                    this.fetchGuest(this.guestObject.id)
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                }, error=>{
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.getMessages(error),
                        'type': 'error',
                        'timeout': 3000
                    })
                })
            }
        },
        watch: {
            guest_id: {
                async  handler(value) {
                    await this.fetchGuest(value)
                },
                immediate: true,
            }
        }
    }
</script>

<style scoped>
</style>
