<template>
    <form @submit.prevent="search" @reset.prevent="reset" class="filter">
        <b-row class="align-items-end">
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('NAME')}} / {{$t('PERSONAL_ID_NUMBER_ABBREVIATED')}}</label>
                <b-input v-model="filter.search"></b-input>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{$t("ENTITY_TYPE")}}</label>
                <app-select mode="multiselect" :options="invoiceeTypeList" text-field="label"
                            v-model="filter.invoicee_type" :search-clear="true">
                </app-select>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{$t("CLIENT_TYPE")}}</label>
                <app-select mode="multiselect" :options="partnerTypeList" text-field="label"
                            v-model="filter.partner_type" :search-clear="true">
                </app-select>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{$t("COUNTRY")}}</label>
                <app-select mode="search" v-model="country"
                            :search="searchCountry" :search-clear="true"></app-select>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{$t("CITY")}}</label>
                <app-select mode="search" v-model="city" :disabled="!country.id"
                            :search="searchCity" :search-clear="true"></app-select>
            </b-col>

            <b-col xl="4" lg="4" md="6" class="mb-3">
                <app-button-submit :inline="true" :loading="loading" variant="primary" button_type="search">
                    {{$t("SEARCH") }}
                </app-button-submit>
                <app-button-reset :disabled="loading" :inline="true" class="pl-2 ml-2"></app-button-reset>
            </b-col>
        </b-row>
    </form>
</template>

<script>
    import {EventBus} from "@/shared/EventBus";
    import {cloneDeep} from "lodash";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {getCityList, getCountries} from "@/services/location";
    import {getInvoiceeTypeList, getPartnerTypeList} from "@/services/finance/partner";

    export default {
        name: "PartnerFilter",
        components: {AppSelect, AppButtonReset, AppButtonSubmit},
        data() {
            return {
                initialFilter: null,
                filter: {
                    search: null,
                    partner_type: [],
                    invoicee_type: [],
                },
                country: {id: null, name: null},
                city: {id: null, name: null},
                loading: false,
                partnerTypeList: [],
                invoiceeTypeList: []
            }
        },
        methods: {
            search() {
                EventBus.$emit('searchPartners', {...this.filter, country: this.country.id, city: this.city.id})
            },
            reset() {
                this.filter = cloneDeep(this.initialFilter)
                this.country = this.city = {id: null, name: null}
                this.search()
            },
            searchCountry(value) {
                return getCountries({
                    name: value
                })
            },
            searchCity(value) {
                return getCityList({
                    name: value,
                    country: this.country.id
                })
            },
        },
        created() {
            Promise.all([
                getPartnerTypeList(),
                getInvoiceeTypeList()
            ]).then(([partnerResponse, invoiceeResponse]) => {
                this.partnerTypeList = partnerResponse.data
                this.invoiceeTypeList = invoiceeResponse.data
            })
        },
        mounted() {
            this.initialFilter = cloneDeep(this.filter)
            EventBus.$on('loading', (value) => {
                this.loading = value
            })
        },
        beforeDestroy() {
            EventBus.$off('loading')
        }
    }
</script>

<style scoped>

</style>
