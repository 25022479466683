<template>
    <b-card body-class="d-flex">
        <div class="d-flex flex-grow-1 align-items-start flex-column">
            <span class="header-2 mb-5">{{$t("SHORTCUTS")}}</span>
            <div class="d-flex flex-grow-1 flex-wrap align-items-end">

                <app-button v-if="checkPermission(R_PROPERTY_NEW)" button_type="new" class="mr-3" variant="primary" @click="property_aside=true">{{
                    $t("PROPERTY_NEW") }}
                </app-button>

                <app-button v-if="checkPermission(C_RESERVATION_ACCOMMODATION_NEW)" button_type="new" class="mr-3" variant="primary" @click="res_aside=true">{{
                    $t("NEW_RESERVATION") }}
                </app-button>

                <app-button v-if="checkPermission(R_USER_NEW)" button_type="new" class="mr-2" variant="primary" @click="user_aside=true">{{
                    $t("USER_NEW") }}
                </app-button>
            </div>

        </div>
        <app-aside :widths="['col-sm-12', '40%']" v-model="property_aside">
            <template slot="header">{{$t('NEW_PROPERTY')}}</template>
            <property-wizard></property-wizard>
        </app-aside>
        <app-aside v-model="res_aside">
            <template slot="header">
                {{$t('NEW_RESERVATION')}}
            </template>
            <new-accommodation-reservation-form @created="()=>res_aside=false">
            </new-accommodation-reservation-form>
        </app-aside>

        <app-aside v-model="user_aside">
            <template slot="header">{{$t("USER_NEW")}}</template>
            <user-new-form
                @addUser="getNewUser">
            </user-new-form>
        </app-aside>
    </b-card>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppAside from "@/components/app/form/AppAside";
    import PropertyWizard from "@/components/property/new/PropertyWizard";
    import NewAccommodationReservationForm
        from "@/components/reservation/accommodation/forms/NewAccommodationReservationForm";
    import UserNewForm from "@/components/user/new/UserNewForm";
    import routeNames from "@/router/routeNames";
    import {R_USER_NEW, R_PROPERTY_NEW} from "@/shared/route_permission";
    import {C_RESERVATION_ACCOMMODATION_NEW} from '@/shared/component_permission'

    export default {
        name: "QuickActionsGrid",
        components: {UserNewForm, NewAccommodationReservationForm, AppButton, AppAside, PropertyWizard},
        data() {
            return {
                property_aside: false,
                res_aside: false,
                user_aside: false,
                R_USER_NEW,
                R_PROPERTY_NEW,
                C_RESERVATION_ACCOMMODATION_NEW,
            }
        },
        methods: {
            getNewUser(user) {
                this.user_aside=false
                //this.$router.push({name: routeNames.RN_USER_ROLES, params: {id: user.id}})
            },
        }
    }
</script>

<style scoped>

</style>
