import axios from 'axios'
import {API_CONFIG} from './config'
import store from '../store/store';
import router from "@/router/index";
import routeName from '@/router/routeNames.js'
import {EventBus, V_ERROR} from "@/shared/EventBus";
import {BOOKER_TOOLS_DOMAIN, TestDomainLoginPageHr} from "@/shared/constants";


const http = axios.create({
    baseURL: API_CONFIG.API_URL,
    headers: {
        "Content-Type": "application/json",
    }
})

// in case when success response type is Blob and error response type is application/json
http.interceptors.response.use(
    response => { return response; },
    error => {
        if (
            error.request.responseType === 'blob' &&
            error.response.data instanceof Blob &&
            error.response.data.type &&
            error.response.data.type.toLowerCase().indexOf('json') !== -1
        )
        {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = () => {
                    error.response.data = JSON.parse(reader.result);
                    reject(error);
                };

                reader.onerror = () => {
                    reject(error);
                };

                reader.readAsText(error.response.data);
            });
        }
        return Promise.reject(error);
    }
);

http.interceptors.request.use(
    config => {
        config.headers.Authorization = store.getters['auth/getToken'] !== '' ? 'Bearer ' + store.getters['auth/getToken'] : ''
        return config
    }, error => Promise.reject(error))

http.interceptors.response.use((response) => {
        if (response.config.method !== 'get') {
            EventBus.$emit(V_ERROR, [])
        }

        return response.data
    },
    error => {

        if (error.response.status === 403) {
            if (router.currentRoute.name !== routeName.RN_LOGIN && router.currentRoute.name !== routeName.RN_W_LOGIN) {
                let domain = window.location.host;
                if (domain !== BOOKER_TOOLS_DOMAIN) {
                    router.replace({name: routeName.RN_W_LOGIN, query: {redirect: router.currentRoute.fullPath}})
                } else {
                    router.replace({name: routeName.RN_LOGIN, query: {redirect: router.currentRoute.fullPath}})
                }
            } else {
                router.replace({name: routeName.RN_LOGIN})
            }

        }

        if (error.response.config.method !== 'get') {
            EventBus.$emit(V_ERROR, error.response.data.error_list || [])
        }

        return Promise.reject(error)
    }
)

export default http
