<template>
    <b-row>
        <b-form>
            <b-col xl="12" class="mb-3 mt-3">

                <app-select :label="$t('UNITS')" mode="multiselect" text-field="name"  :options="options"  v-model="unit_list">
                </app-select>
            </b-col>
            <b-col xl="12" class="mb-3">
                <app-select :label="$t('YEAR')" v-model.number="selectedYear" :options="years" text-field="text" value-field="value">
                </app-select>

            </b-col>
            <b-col xl="2" class="mb-3">
                <make-suggestions
                    :disabled="disableMakeSuggestion"
                    :loading="generateSuggestions"
                    variant="primary"
                    @click="getSuggestions"
                >
                    {{$t('MAKE')}}
                </make-suggestions>

            </b-col>
        </b-form>

    </b-row>
</template>

<script>
    import moment from 'moment'
    import {getRmAcquisitionSuggestions} from '@/services/pricing/index'
    import Multiselect from 'vue-multiselect'
    import MakeSuggestions from '@/components/app/AppButton/AppButton'
    import {toast} from "@/shared/plugins/toastr";

    import {getRmUnitList, getRmFilter} from '@/services/pricing/index'
    import {REPORT_TYPE_ACQUISITION} from "@/shared/constants";
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import AppInputControl from "@/components/app/AppInputControl";
    import AppSelect from "@/components/app/AppSelect/AppSelect";

    export default {
        name: "rm-sugg-price-acquisition-form",
        components: {
            AppSelect,
            AppInputControl,
            Multiselect,
            MakeSuggestions
        },
        props: {
            show: {
                type: Boolean,
                default: false
            }
        },
        mixins: [getErrorMessage],
        data() {
            return {
                options: [],
                unit_list: [],

                years: [
                    {value: null, text: '-'},
                    {value: '2019', text: '2019'},
                    {value: '2020', text: '2020'},
                    {value: '2021', text: '2021'},
                ],
                selectedYear: null,
                generateSuggestions: false,
                loading: false,
                data_loaded: false
            }
        },
        computed: {

            occupancyList() {
                return this.$store.getters['pricing/getOccupancyList']
            },
            losList() {
                return this.$store.getters['pricing/getLosList']
            },
            priceList() {
                return this.$store.getters['pricing/getPriceList']
            },
            disableMakeSuggestion(){
                return   this.unit_list.length === 0 || !this.selectedYear
            }
        },

        methods: {
            getSuggestions() {
                this.$emit('loadSuggestion', true)

                let endpoint_list = []
                let period_data_list = []
                let suggestions = []

                for (let periodId of this.$store.getters['pricing/getPeriodList']) {
                    let period = this.$store.getters['pricing/getPeriodById'](periodId)
                    let object = {
                        unit: this.$route.params.unit_id,
                        unit_list: this.unit_list,
                        date_from: this.formatDate(period.period_start),
                        date_to: this.formatDate(period.period_end)
                    }
                    period_data_list.push(object)
                }

                period_data_list.forEach(period_data => {
                    let endpoint = getRmAcquisitionSuggestions(period_data)
                    endpoint_list.push(endpoint)
                })
                Promise.all(endpoint_list)
                    .then(
                        results => {
                           results.forEach(res => {
                               if(res){
                                suggestions.push(res.data)
                               }
                           })
                            if(suggestions.length === 0){
                                toast({
                                    'message': this.$t('NO_SUGGESTIONS'),
                                    'type': 'error',
                                    'timeout': 6000
                                })
                                this.$emit('loadSuggestion', false)
                                return
                            }
                            this.$store.dispatch('pricing/actionSetRmSuggestion', suggestions)
                            this.$emit('loadSuggestion', false)
                            this.unit_list = []
                            this.selectedYear = null
                        }, error =>{
                          if(error){
                              toast({
                                  'title': "Action  fail",
                                  'message': this.getMessages(error),
                                  'type': 'error',
                                  'timeout': 6000
                              })

                          } else {
                              toast({
                                  'title': "Action  fail",
                                  'message': 'Server error',
                                  'type': 'error',
                                  'timeout': 6000
                              })
                          }
                                this.$emit('loadSuggestion', false)
                                this.unit_list = []
                                this.selectedYear = null
                        }
                    )
            },
            formatDate(value) {
                if (value) {
                    value = value + '/' + this.selectedYear
                    let date = new Date(value)
                    return moment(date).format('Y-MM-DD')
                }
            },
            getRmList(unit_id) {
                let filter_id = null
                let params = {unit: Number(unit_id)}
                this.loading = true

                getRmFilter(params).then(response => {
                    for (let data of response.data) {
                        filter_id = data.id
                    }
                    if (filter_id) {

                        getRmUnitList({rate_manager_filter: filter_id, rm_working_unit:Number(unit_id), report_type:REPORT_TYPE_ACQUISITION,order_by:"name", order_type:"asc"}).then(response => {
                            this.options = response.data
                            this.loading = false
                            this.data_loaded = true
                        }, () => {
                            this.options = []
                            this.loading = false
                            this.data_loaded = true
                        })
                    } else{
                        this.options = []
                        this.loading = false
                        this.data_loaded = true
                    }
                }, () => {
                    this.options = []
                    this.loading = false
                    this.data_loaded = true
                })
            }

        },
        watch: {
            '$route.params.unit_id': {
                handler(val) {
                    this.data_loaded = false
                    if (this.show && !this.loading) {
                        this.getRmList(val)
                    }

                },
                immediate: true
            },
            show(val) {
                if (val && !this.data_loaded && !this.loading) {
                    this.getRmList(this.$route.params.unit_id)
                }
            }
        }
    }
</script>

