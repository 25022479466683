<template>
    <b-form @submit.prevent="save" v-if="category">
        <b-row class="mt-3 mb-3">
            <b-col>
                <app-translation-input :label="$t('NAME')"
                                       v-model="category.label"
                                       :lang_id="category.lang_id"
                                       :languages="langList"
                ></app-translation-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button @click="save" :loading="loader">{{$t('SAVE')}}</app-button>
            </b-col>
            <b-col>
                <app-button-delete @click="deleteCategory" class="pull-right align-self-end"></app-button-delete>
            </b-col>
        </b-row>
        <app-confirmation-dialog
            :show="confirmation_dialog_state"
            :title="category.label"
            @cancel="confirmation_dialog_state = false">
            <p>{{$t('DELETE_PROMO_CATEGORY', {value: number_of_promo})}}</p>
            <template v-slot:modal-footer>
                <app-button @click="change" :disabled="disabled">{{$t('ASSIGN_CATEGORY')}}</app-button>
                <app-button @click="continueAction" class="mr-2 ml-2" :loading="confirmLoad" :disabled="disabled">{{$t('CONFIRM')}}</app-button>
                <app-button @click="confirmation_dialog_state = false" :disabled="disabled">{{$t('CANCEL')}}</app-button>
            </template>
        </app-confirmation-dialog>
        <app-aside :widths="['col-lg-4', '10%']" v-model="changeCategory">
            <template slot="header">
                {{$t('EDIT_PROMO_CODES')}}
            </template>
            <promo-category-assign @categoryAssigned="assignedCategory" :selected-category="categoryId" :selected-codes="promoList"></promo-category-assign>
        </app-aside>
    </b-form>
</template>

<script>

import AppTranslationInput from "@/components/app/translation/AppTranslationInput";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import AppButton from "@/components/app/AppButton/AppButton";
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
import AppAside from "@/components/app/form/AppAside";
import PromoCategoryAssign from "@/components/direct_booking/promo_codes/category/PromoCategoryAssign";
import {deleteCategory, fetchPromoCategory, getPromoCodes, updatePromoCategory} from "@/services/promo_codes";
import {getTranslateList} from "@/services/system";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {EventBus} from "@/shared/EventBus";

export default {
    name: "PromoCategoryEdit",
    mixins:[getErrorMessage],
    props:{
        categoryId:{
            type: Number,
            default: null
        }
    },
    components:{AppTranslationInput, AppButton, AppButtonDelete, AppConfirmationDialog, AppAside, PromoCategoryAssign},
    data(){
        return{
            category: [],
            langList: [],
            promoList: [],
            confirmation_dialog_state: false,
            number_of_promo: null,
            loader: false,
            changeCategory: false,
            confirmLoad: false,
            disabled: false
        }
    },
    methods:{
        save(){
            this.loader = true
            updatePromoCategory(this.categoryId, {...this.category}).then(response => {
                notifySuccess()
                this.loader = false
                EventBus.$emit('reloadPromoCategory')
                this.$emit('categoryChanged')
            }, error => {
                this.loader = false
                this.showErrorMessages(error)
            })
        },
        deleteCategory(){
            this.confirmation_dialog_state = true
        },
        continueAction(){
            this.confirmLoad = true
            this.disabled = true
            deleteCategory(this.categoryId).then(response => {
                notifySuccess()
                this.disabled = false
                this.confirmation_dialog_state = false
                EventBus.$emit('reloadPromoCategory')
                this.$emit('categoryChanged')
                this.confirmLoad = false
            }, error => {
                this.confirmLoad = false
                this.disabled = false
                this.showErrorMessages(error)
            })
        },
        assignedCategory(){
            deleteCategory(this.categoryId).then(response => {
                notifySuccess()
                EventBus.$emit('reloadPromoCategory')
                this.$emit('categoryChanged')
                this.changeCategory = false
            }, error => {
                this.changeCategory = false
                this.showErrorMessages(error)
            })
        },
        change(){
            this.changeCategory = true
            this.confirmation_dialog_state = false
        }
    },
    created() {
        fetchPromoCategory(this.categoryId).then(response => {
            this.category = response.data
        })
        getTranslateList().then(response => {
            this.langList = response.data
        })
        getPromoCodes({category: this.categoryId}).then(response => {
            this.number_of_promo = response.data.total
            response.data.items.forEach(el => {
                this.promoList.push(el.id)
            })
        })
    }
}
</script>

<style scoped>

</style>
