<template>
    <div>
        <app-table-v2 search-event="reloadMarketplaceGeneralTable" :provider="getData" :fields="fields"
                      :show-limit="true"
                      :search-on-create="true"
                      selectable
                      @busyToggle="busyToggle">
            <template v-slot:activation="data">
                <b-badge v-for="request in data.item.request_list"
                         variant="primary" pill
                         class="mr-1"
                         style="cursor: pointer"
                         @click="resolveRequest(request, data.item)"
                         v-if="request.product_status_count > 0"
                >{{request.label}}: {{request.product_status_count}}</b-badge>
            </template>
            <template v-slot:slug="data">
                <b-link :href="'https://'+data.item.slug"
                        target="_blank"
                >{{data.item.slug}}</b-link>
            </template>
            <template v-slot:marketplace="data">
                <b-checkbox
                    v-model="data.item.marketplace"
                    switch
                    :disabled="!checkPermission(MARKETPLACE_ACTIVATION)"
                    @change="editMarketplace(data.item, $event)">
                </b-checkbox>
            </template>
            <template v-slot:logo="data">
                <b-img v-if="data.item.logo" :src="data.item.logo"
                       height="15px" style="max-width: 140px"></b-img>
            </template>
            <template v-slot:edit="data">
<!--                <b-tooltip v-if="data.item.marketplace_edit" target="marketplace-table-edit"-->
<!--                           :title="$t('NOT_ACTIVATED_FOR_MARKETPLACE')"></b-tooltip>-->
                <app-button
                    @click="editMarkAction(data.item)"
                    variant="link"
                    :disabled="data.item.marketplace_edit"
                    button_type="edit"
                    id="marketplace-table-edit"
                    :show_text="false">
                </app-button>
            </template>
            <template v-slot:selectedContainer="data">
                <app-button @click="deactivateBulk(data.selected)" class="ml-3">{{$t('DEACTIVATE')}}</app-button>
            </template>
        </app-table-v2>
        <app-confirmation-dialog
            :show="confirmation_dialog_state"
            @confirm="continueAction"
            @cancel="cancelAction"
            :title="$t('MARKETPLACE')">
            {{ $t('MARKETPLACE_DEACTIVATE') }}
        </app-confirmation-dialog>
        <app-aside v-model="activationWizard" :widths="['col-sm-12', '50%']">
            <template slot="header"><app-object-header :label="websiteSlug" :name="$t('MARKETPLACE_ACTIVATION')"></app-object-header></template>
            <template v-slot="props">
                <marketplace-activation-wizard :website-id="websiteId" :distribution-id="websiteDistribution"></marketplace-activation-wizard>
            </template>
        </app-aside>
        <app-aside v-model="editAside">
            <template slot="header"><app-object-header :label="websiteSlug" :name="$t('MARKETPLACE_SETTING')"></app-object-header></template>
            <template v-slot="props">
                <marketplace-edit-general-tab :website-id="websiteId" :distribution-id="websiteDistribution" @editSaved="editDone"></marketplace-edit-general-tab>
            </template>
        </app-aside>
        <app-aside v-model="resolveRequestAside">
            <template slot="header"><app-object-header :label="websiteSlug" :name="$t('PARTNER_STATUS')" :description="statusName"></app-object-header></template>
            <template v-slot="props">
                <marketplace-resolve-request-form :website-id="websiteId"
                                                  :distribution-id="websiteDistribution"
                                                  :status_eid="status_eid"
                                                  :website_slug="websiteSlug"
                                                  @noRequest="closeRequestAside"
                ></marketplace-resolve-request-form>
            </template>
        </app-aside>
    </div>
</template>

<script>

import AppTableV2 from "@/components/app/AppTableV2";
import AppButton from "@/components/app/AppButton/AppButton";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import MarketplaceActivationWizard from "@/components/direct_booking/marketplace/MarketplaceActivationWizard";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import AppAside from "@/components/app/form/AppAside";
import MarketplaceEditGeneralTab from "@/components/direct_booking/marketplace/general/MarketplaceEditGeneralTab";
import MarketplaceResolveRequestForm
    from "@/components/direct_booking/marketplace/general/MarketplaceResolveRequestForm";
import MarketplaceEditCompaniesForm from "@/components/direct_booking/marketplace/general/MarketplaceEditCompaniesForm";
import {getWebsiteList} from "@/services/direct_booking/website";
import {createMarketplace, deleteMarketplace} from "@/services/marketplace";
import {notifySuccess} from "@/shared/plugins/toastr";
import {EventBus} from "@/shared/EventBus";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {ACCEPTED_EID, DECLINED_EID, PENDING_EID} from "@/shared/constants";
import {MARKETPLACE_ACTIVATION} from "@/shared/component_permission";

export default {
    name: "MarketplaceGeneralTable",
    components:{
        AppTableV2,
        AppButton,
        AppConfirmationDialog,
        MarketplaceActivationWizard,
        AppObjectHeader,
        AppAside,
        MarketplaceEditGeneralTab,
        MarketplaceResolveRequestForm,
        MarketplaceEditCompaniesForm
    },
    mixins:[getErrorMessage],
    data(){
        return{
            testActivation: 0,
            confirmation_dialog_state: false,
            deleteId: [],
            websiteId: null,
            websiteSlug: null,
            websiteDistribution: null,
            activationWizard: false,
            busyToggle: false,
            editAside: false,
            resolveRequestAside: false,
            companiesEdit: false,
            fields:[
                {key: 'id', label: this.$t('ID'),},
                {key: 'website', label: this.$t('NAME')},
                {key: 'slug', label: this.$t('SLUG')},
                {key: 'activation', label: this.$t('PARTNER_STATUS')},
                {key: 'marketplace', label: this.$t('MARKETPLACE')},
                {key: 'logo', label: ''},
                {key: 'edit', label: '', class: 'text-right'},
            ],
            activatedMessage: this.$t('MARKETPLACE_EDIT_DISABLED'),
            statusName: null,
            declineEdit: false,
            status_eid: null,
            MARKETPLACE_ACTIVATION,
        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods:{
        getData(filter){
            let provider
            let req = {
                perPage: 999,
                order_by: filter.sortBy,
                company: this.company.id
            }

            provider = getWebsiteList(req)
            provider.then(response => {
                if (response.data.items && response.data.items.length > 0) this.$emit('showButton', true)
                else this.$emit('showButton', false)
            })
            return provider
        },
        editMarketplace(item, event){
            this.$nextTick(() => {
                if (event){
                    this.websiteId = null
                    this.websiteId = item.id
                    this.websiteSlug = null
                    this.websiteSlug = item.slug
                    this.websiteDistribution = item.distribution.id
                    this.activationWizard = true
                }else {
                    this.deleteId = []
                    this.deleteId.push(item.id)
                    this.confirmation_dialog_state = true
                }
            })
        },
        continueAction(){
            this.busyToggle = false
            deleteMarketplace({website_id: this.deleteId}).then(response => {
                notifySuccess()
                this.busyToggle = false
                this.confirmation_dialog_state = false
                EventBus.$emit('reloadMarketplaceGeneralTable')
            }, error => {
                this.busyToggle = false
                this.confirmation_dialog_state = false
                this.showErrorMessages(error)
            })
        },
        cancelAction(){
            this.confirmation_dialog_state = false
            EventBus.$emit('reloadMarketplaceGeneralTable')
        },
        deactivateBulk(selected){
            this.deleteId = []
            selected.forEach(el => this.deleteId.push(el.id))
            this.confirmation_dialog_state = true
        },
        editMarkAction(item){
            this.websiteId = null
            this.websiteId = item.id
            this.websiteSlug = null
            this.websiteSlug = item.slug
            this.websiteDistribution = item.distribution.id
            this.editAside = true
        },
        editDone(){
            this.editAside = false
            EventBus.$emit('reloadMarketplaceGeneralTable')
        },
        resolveRequest(request, item){
            if (request.status_eid === PENDING_EID){
                this.websiteId = item.id
                this.websiteSlug = item.slug
                this.websiteDistribution = item.distribution.id
                this.statusName = request.label
                this.resolveRequestAside = true
                this.status_eid = request.status_eid
            }
            if (request.status_eid === ACCEPTED_EID){
                this.websiteId = item.id
                this.websiteSlug = item.slug
                this.websiteDistribution = item.distribution.id
                this.statusName = request.label
                this.resolveRequestAside = true
                this.status_eid = request.status_eid
            }
            if (request.status_eid === DECLINED_EID){
                this.websiteId = item.id
                this.websiteSlug = item.slug
                this.websiteDistribution = item.distribution.id
                this.statusName = request.label
                this.resolveRequestAside = true
                this.status_eid = request.status_eid
            }
        },
        closeRequestAside(){
            this.resolveRequestAside = false
        },
        closeCompaniesEditAside(){
            this.companiesEdit = false
        },
        closeDeclineEditAside(){
            this.declineEdit = false
        }
    },
    watch:{
        activationWizard:{
            handler(){
                if (this.activationWizard === false){
                    EventBus.$emit('reloadMarketplaceGeneralTable')
                }
            }
        },
        resolveRequestAside:{
            handler(value){
                if (!value){
                    EventBus.$emit('reloadMarketplaceGeneralTable')
                }
            }
        },
        companiesEdit:{
            handler(value){
                if (!value){
                    EventBus.$emit('reloadMarketplaceGeneralTable')
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
