<template>
    <form @submit.prevent="search" @reset.prevent="resetForm" class="filter" autocomplete="off">
        <b-row>
            <b-col md="2" class="mb-3">
                <label>{{ $t("NAME") }}</label>
                <b-input v-model="filter.name" type="text"></b-input>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{ $t('ACCOUNT_MANAGER') }}</label>
                <app-select mode="search" :search-on-create="true"
                            :search="searchAccManager" v-model="account_manager"
                            :search-clear="false"></app-select>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{ $t('PROPERTY') }}</label>
                <app-select mode="search" :search="searchProperty"
                            v-model="property"></app-select>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{ $t('UNIT') }}</label>
                <app-select :mode="searchMode" v-model="unit[searchMode]" :options="unitOptions"
                            :search="searchUnit"></app-select>
            </b-col>

            <b-col md="2" class="mb-3" v-if="$route.name === routeNames.RN_SYSTEM_DISTRIBUTION_OWNER">
                <label>{{ $t('FILTER') }}</label>
                <app-select mode="select" :options="cmDistributionStatusList" text-field="label"
                            v-model="filter.unit_active"></app-select>
            </b-col>

            <b-col md="3" class="mb-3">
                <app-button-submit class="filter-submit-wrapper" :inline="true" :disabled="loading"
                                   :loading="loaderSubmit" variant="primary" button_type="search">
                    {{ $t("SEARCH") }}
                </app-button-submit>
                <app-button-reset :loading="loaderReset" :disabled="loading" :inline="true"
                                  class="pl-2 ml-2"></app-button-reset>
            </b-col>
        </b-row>
    </form>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppSubmit from "@/components/app/AppSubmit";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import {cloneDeep} from "lodash";
import routeNames from '@/router/routeNames'
import {getPropertyList} from "@/services/property";
import {getUnitList} from "@/services/unit";
import {getList} from "@/services/acc_manager";

export default {
    name: "OwnerFilter",
    components: {AppButtonReset, AppSubmit, AppButtonSubmit, AppSelect},
    props: {
        distribution: {
            type: Number,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            routeNames,
            filter: {
                page: 1,
                perPage: 10,
                name: null,
                company: null,
                property: null,
                unit: null,
                account_manager: null,
                unit_active: null,
                distribution: null
            },
            initialFilter: {},
            initialUnit: {},
            reset: 0,
            property: {id: null, name: null},
            account_manager: {id: null, name: null},
            unit: {
                search: {id: null, name: null},
                multiselect: []
            },
            cmDistributionStatusList: [
                {id: 0, label: this.$t('INACTIVE')},
                {id: 1, label: this.$t('ACTIVE')}
            ],
            loaderSubmit: false,
            loaderReset: false,
            searchMode: "search",
            unitOptions: []
        }
    },
    watch: {
        loading: {
            handler(value) {
                if (this.reset) {
                    this.loaderReset = value
                } else {
                    this.loaderSubmit = value
                }
            }
        },
        'property.id': {
            handler(value) {
                this.unit = cloneDeep(this.initialUnit)
                if (value) {
                    this.searchUnit(null, "multiselect")
                    this.searchMode = "multiselect"
                } else {
                    this.searchMode = "search"
                }
            }
        },
        distribution: {
            handler() {
                this.search()
            }
        }
    },
    methods: {
        search() {
            this.reset = 0
            this.filter.property = this.property.id
            this.filter.unit = this.unit.search.id ? this.unit.search.id : this.unit.multiselect
            this.filter.distribution_id = this.distribution
            this.filter.account_manager = this.account_manager.id
            this.$emit('search', {...this.filter})
        },
        resetForm() {
            this.reset = 1
            this.filter = cloneDeep(this.initialFilter)
            this.unit = cloneDeep(this.initialUnit)
            this.property = {id: null, name: null}
            this.account_manager = {id: null, name: null}
            this.$emit('search', {...this.filter})
        },
        toggleBusy($event) {
            this.loading = $event
        },
        searchProperty(value) {
            return getPropertyList({
                name: value,
                company_id: this.company.id,
                account_manager: this.account_manager.id
            }).then(response => {
                return {data: response.data.items}
            })
        },
        searchUnit(value, type = null) {
            if (type === "multiselect") {
                getUnitList({
                    company_id: this.company.id,
                    property_id: this.property.id,
                    limit: 20,
                }).then(response => {
                    this.unitOptions = response.data.items
                })
            } else {
                return getUnitList({
                    company_id: this.company.id,
                    name: value,
                    property_id: this.property.id,
                    limit: 20,
                }).then(response => {
                    return {data: response.data.items}
                })
            }
        },
        searchAccManager(value) {
            return getList({
                company_id: this.company.id,
            })
        },
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    created() {
        this.filter.distribution_id = this.distribution
        this.filter.company = this.$store.getters[`user/getCompany`];
        if (this.$route.name === routeNames.RN_SYSTEM_DISTRIBUTION_OWNER) {
            this.filter.unit_active = 1
        }
        this.initialFilter = cloneDeep(this.filter);
        this.initialUnit = cloneDeep(this.unit);
    }

}
</script>

<style scoped>

</style>
