<template>
    <b-form @submit.prevent="save" autocomplete="off">
        <b-row>
            <b-col sm="12">
                <b-form-group>
                    <app-input-control :error-object="validationErrorObject.linked_value">
                        <template v-slot:input="data">
                            <label>{{$t('LINKED_VALUE')}}
                                <a class="cursor-pointer" v-if="seasonalRateMaskEnabled" @click="toggleSeasonalRateMask">({{$t('SEASONAL')}})</a>
                            </label>
                            <b-input-group >
                                <b-form-input type="number" :state="data.state"
                                              v-model="mask_setup.length > 1 || yearPeriod ? '' : linked_value"  :disabled="mask_setup.length > 0 || yearPeriod" :step="0.01"></b-form-input>
                                <b-input-group-append>
                                    <app-select v-model="linked_type"
                                                :options="relationTypeOptions"
                                                :search-empty-item="false">
                                    </app-select>
                                </b-input-group-append>
                            </b-input-group>
                            <period-rate-mask-list  :mask-setup="mask_setup"
                                                    v-if="seasonalRateMaskEnabled && seasonalRateMaskVisible === true"
                                                    @update="setMaskSetup">
                            </period-rate-mask-list>
                        </template>
                    </app-input-control>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button-submit :loading="loading">
                    {{$t('SAVE')}}
                </app-button-submit>
            </b-col>
        </b-row>
        <app-confirmation-dialog :show="displayConfirmationDialog"
                                 :title="$t('SAVE_CONFIRMATION')"
                                 @confirm="saveConfirmation"
                                 @cancel="displayConfirmationDialog = false">
            {{$t('CHANGE_LINKED_VALUE_CONFIRMATION_MSG')}}
        </app-confirmation-dialog>
    </b-form>
</template>

<script>
import PeriodRateMaskList from "@/components/pricing/rate_plan/period/PeriodRateMaskList";
import AppInputControl from "@/components/app/AppInputControl";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppAccessControl from "@/components/app/AppAccessControl";
import {
    ACCOMMODATION_PRICING_RATE_PLAN_LINKED_TYPE_VALIDATION_FAIL,
    ACCOMMODATION_PRICING_RATE_PLAN_LINKED_VALUE_PERC_VALIDATION_FAIL,
    ACCOMMODATION_PRICING_RATE_PLAN_LINKED_VALUE_VALIDATION_FAIL
} from "@/shared/error_codes";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {
    updateRatePlanListLinkedValue
} from "@/services/pricing";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {notifySuccess} from "@/shared/plugins/toastr";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {AC_RATE_PLAN_FORM} from "@/mixins/AccessControl/AccessControlEnumeration";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {
    C_UNIT_RATE_PLAN_MASK_SETUP
} from "@/shared/component_permission";
import Distribution from "@/components/pricing/rate_plan/fields/Distribution";
import {fetchAccessControlData} from "@/services/access";
import moment from "moment";
export default {
    name: "change-rate-plan-linked-value-form",
    components: {
        Distribution,
        AppConfirmationDialog, AppButtonSubmit, AppSelect, AppInputControl, PeriodRateMaskList, AppAccessControl},
    mixins: [getErrorMessage, AccessControlComponent],
    data() {
        return {
            AC_RATE_PLAN_FORM,
            C_UNIT_RATE_PLAN_MASK_SETUP,
            access_control_fetch_key: AC_RATE_PLAN_FORM,
            access_control_components: 1,
            mask_setup: [],
            linked_value: null,
            linked_type: 'perc',
            relationTypeOptions: [
                {id: 'perc', name: '%'},
                {id: 'fix', name: 'fix'}
            ],
            validationErrorObject: {
                linked_value: [
                    ACCOMMODATION_PRICING_RATE_PLAN_LINKED_VALUE_PERC_VALIDATION_FAIL,
                    ACCOMMODATION_PRICING_RATE_PLAN_LINKED_VALUE_VALIDATION_FAIL,
                    ACCOMMODATION_PRICING_RATE_PLAN_LINKED_TYPE_VALIDATION_FAIL
                ],
            },
            loading: false,
            displayConfirmationDialog: false,
            seasonalRateMaskEnabled: false,
            seasonalRateMaskVisible: false,
        }
    },
    props: {
        ratePlanList: {
            type: Array,
            default() {
                return []
            }
        },
        distribution: {
            type: Array,
            default() {
                return []
            }
        },
        unitId: {
            type: Number,
            default: null
        }
    },
    computed:{
        firstDayCurrentYear() {
            return moment().startOf('year').format('MM/DD')
        },
        endDayCurrentYear() {
            return moment().endOf('year').format('MM/DD')
        },
        yearPeriod(){
            if (
                this.mask_setup.length === 1 &&
                (((this.mask_setup[0].period_start > this.firstDayCurrentYear) &&
                        (this.mask_setup[0].period_end < this.endDayCurrentYear)) ||
                    ((this.mask_setup[0].period_start === this.firstDayCurrentYear) &&
                        (this.mask_setup[0].period_end < this.endDayCurrentYear)) ||
                    ((this.mask_setup[0].period_start > this.firstDayCurrentYear) &&
                        (this.mask_setup[0].period_end === this.endDayCurrentYear)))
            ){
                return true
            }
            return false
        },
    },
    methods: {
        toggleSeasonalRateMask() {
            this.seasonalRateMaskVisible = !this.seasonalRateMaskVisible
        },
        save() {
            this.loading = true
            this.displayConfirmationDialog = true
            this.loading = false
        },
        saveConfirmation() {
            let request = {
                rate_plan_list: this.ratePlanList,
                linked_value: this.linked_value,
                linked_type: this.linked_type,
                unit: this.unitId,
            }

            if (this.seasonalRateMaskEnabled && this.seasonalRateMaskVisible && this.mask_setup.length) {
                request.mask_setup = this.prepareMaskSetup(this.mask_setup)
            }

            updateRatePlanListLinkedValue(request).then(() => {
                notifySuccess()
                this.$emit('linkedValueSaved')
            }, error => {
                this.showErrorMessages(error, this.validationErrorObject)
            }).finally(() => {
                this.displayConfirmationDialog = false

            })
        },
        setMaskSetup(maskSetup){
            this.mask_setup = maskSetup
        },
        prepareMaskSetup(){
            let maskSetup = []
            this.mask_setup.forEach(maskSetupPeriod => {
                maskSetup.push({
                    period_start: maskSetupPeriod.period_start,
                    period_end: maskSetupPeriod.period_end,
                    value_type_id: this.linked_type === 'fix' ? 2 : 1,
                    value: maskSetupPeriod.value ? maskSetupPeriod.value : this.linked_value
                })
            })
            return maskSetup

        },
        setSeasonalRateMaskEnabled() {
            if (this.distribution.length) {
                let request = {
                    data: []
                }
                this.distribution.forEach(distribution => {
                    request.data.push({
                        uid: distribution,
                        function: C_UNIT_RATE_PLAN_MASK_SETUP,
                        distribution: distribution,
                        unit: this.unitId,
                    })
                })
                fetchAccessControlData(AC_RATE_PLAN_FORM, request).then(response => {
                    let seasonalRateMaskDisabled = false
                    this.distribution.forEach(distribution => {
                        seasonalRateMaskDisabled = seasonalRateMaskDisabled ? seasonalRateMaskDisabled : (response.data[distribution].disabled === true)
                    })
                    this.seasonalRateMaskEnabled = !seasonalRateMaskDisabled
                })
            }
        }
    },
    watch:{
        'mask_setup':{
            handler(value){
                if(this.mask_setup.length > 0){
                    this.linked_value = null
                }
            }
        },
    },
    mounted() {
        this.setSeasonalRateMaskEnabled()
    },

}
</script>

<style scoped>

</style>
