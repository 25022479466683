import http from "@/services/http";
import {VALIDATION_URL} from "@/services/endpoints";

export function getValidations(params) {
    return http.request({
        url: VALIDATION_URL,
        method: 'GET',
        params
    })
}
