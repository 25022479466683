<template>
    <div v-if="hasPermission">

        <crm-form></crm-form>
    </div>
</template>

<script>
    import CrmForm from '@/components/property/crm/PropertyCrmForm'
    import {C_PROPERTY_CRM_V} from "@/shared/component_permission";

    export default {
        name: "Index",
        components: {
            CrmForm
        },
        computed:{
            hasPermission() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_CRM_V)
            }
        }
    }
</script>

<style scoped>

</style>
