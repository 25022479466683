<template>
    <form @submit.prevent="setList" class="filter" autocomplete="off"
          @reset.prevent="resetForm">
        <b-row>
            <b-col md="2" class="mb-3">
                <label>{{$t('NAME')}}</label>
                <b-input v-model="filter.name" type="text"></b-input>
            </b-col>
            <b-col md="2" class="mb-3" v-if="!isSetupReservationProcessDistributionUnitRoute && !isSetupFeesPlatformFeeDistributionUnitRoute">
                <label>{{$t('TYPE')}}</label>
                <app-select mode="search" :search="searchType" text-field="label" v-model="filter.type"
                            :search-on-create="true" :search-clear="true"></app-select>

            </b-col>

            <b-col md="2" class="mb-3" v-if="isSetupReservationProcessDistributionUnitRoute || isSetupFeesPlatformFeeDistributionUnitRoute">
                <label>{{$t('ACCOUNT_MANAGER')}}</label>
                <app-select  mode="search" :search-on-create="true"
                            :search="searchAccManager" v-model="filter.account_manager"
                            :search-clear="false"></app-select>
            </b-col>

            <b-col md="2" class="mb-3" v-if="isSetupReservationProcessDistributionUnitRoute || isSetupFeesPlatformFeeDistributionUnitRoute">
                <label>{{$t('PROPERTY')}}</label>
                <app-select mode="search" :search="searchProperty" :search-clear="true"
                            v-model="property"></app-select>
            </b-col>

            <b-col md="2" class="mb-3" v-if="!isSetupReservationProcessDistributionUnitRoute && !isSetupFeesPlatformFeeDistributionUnitRoute">
                <label>{{$t('OWNER')}}</label>
                <app-select mode="search" :search="searchPropertyOwners" text-field="name" v-model.number="filter.owner"
                            :search-on-create="true" :search-clear="true"></app-select>
            </b-col>

            <b-col md="2" class="mb-3" v-if="isSetupReservationProcessDistributionUnitRoute || isSetupFeesPlatformFeeDistributionUnitRoute">
                <label>{{$t('OWNER')}}</label>
                <app-select mode="search" :search="searchOwners" text-field="full_name" :search-clear="true"
                            v-model="filter.owner"></app-select>
            </b-col>

<!--            <b-col md="2" class="mb-3" v-if="isSetupReservationProcessDistributionUnitRoute || isSetupFeesPlatformFeeDistributionUnitRoute">-->
<!--                <label>{{$t('FILTER')}}</label>-->
<!--                <app-select mode="select" :options="cmDistributionStatusList" text-field="label"-->
<!--                            v-model="filter.cm_distribution_status"></app-select>-->
<!--            </b-col>-->

            <b-col md="3" class="mb-3 d-flex align-items-baseline">
                <app-button-submit class="filter-submit-wrapper" :inline="true" :disabled="loading" :loading="loaderSubmit" variant="primary"
                                   button_type="search">{{$t("SEARCH") }}
                </app-button-submit>
                <app-button-reset :inline="true"  class="pl-2 ml-2" :disabled="loading" :loading="loaderReset" ></app-button-reset>
            </b-col>
        </b-row>
    </form>

</template>

<script>
import {getOwnerList, getPropertyOwners} from '@/services/owner/index'
    import {getUnitTypeList} from '@/services/unit/index'
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
import {EventBus, GE_TABLE_REFRESH_SEARCH} from '@/shared/EventBus'
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
    import routeNames from '@/router/routeNames'
    import {getPropertyList} from "@/services/property";
import {getList} from "@/services/acc_manager";
import {cloneDeep} from "lodash";
import {getDistribution} from "@/services/distribution";

    export default {
        name: "UnitFilter",
        props: {
            property_id: {
                type: Number,
                default: null
            },
            distribution: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                routeNames,
                unitTypeList: [],
                ownerList: [],
                loading: false,
                property: {id: null, name: null},
                initialFilter: null,
                filter: {
                    page: 1,
                    limit: 10,
                    company_id: null,
                    name: '',
                    type: {id: null, label: null},
                    owner: {id: null, name: null},
                    cm_distribution_status: null,
                    account_manager: {
                        id: null,
                        name: null
                    },
                },
                cmDistributionStatusList: [
                    {id: 0, label: this.$t('INACTIVE')},
                    {id: 1, label: this.$t('ACTIVE')}
                ],
                reset:0,
                searchOwnerOnCreate: true,
                loaderReset:false,
                loaderSubmit:false

            }
        },
        components: {
            AppButtonReset,
            AppSelect,
            AppButtonSubmit
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            isSetupReservationProcessDistributionUnitRoute() {
                return this.$route.name === routeNames.RN_SYSTEM_DISTRIBUTION_UNIT
            },
            isSetupFeesPlatformFeeDistributionUnitRoute() {
                return this.$route.name === routeNames.RN_SETUP_FEES_PLATFORM_FEE_DISTRIBUTION_UNIT
            },
        },
        methods: {
            setList() {
                this.reset = 0
                EventBus.$emit("SearchPropertyUnitList", this.formatRequest())
                // this.loading = true
                // this.$store.commit('setSearchFilterItem', {item: 'page', value: 1})
                // this.$store.dispatch('actionSearch').then(() => {
                //     this.loading = false
                //     this.$emit('dataFetched', true)
                // }, () => {
                //     this.loading = false
                //     this.$emit('dataFetched', true)
                // })
            },
            formatRequest() {
                return {
                    page: this.filter.page,
                    limit: this.filter.limit,
                    property_id: this.property_id || this.property.id,
                    contigent: 0,
                    company_id: this.company.id,
                    name: this.filter.name,
                    type: this.filter.type.id,
                    owner: this.filter.owner.id,
                    account_manager: this.filter.account_manager.id,
                    distribution: this.distribution,
                    cm_distribution_status: this.filter.cm_distribution_status
                }
            },
            resetForm() {
                this.reset = 1
                this.filter = cloneDeep(this.initialFilter)
                EventBus.$emit("SearchPropertyUnitList", this.formatRequest())
            },
            searchType(value) {
                return getUnitTypeList(Number(this.$route.params.id))
            },
            searchPropertyOwners(value) {
                return getPropertyOwners(Number(this.$route.params.id))

            },
            searchOwners(value) {
                return getOwnerList({name: value, company: this.company.id, property: this.property.id, limit: 20})
            },
            searchProperty(value){
                return getPropertyList({name: value, company_id: this.$store.getters['user/getCompany'], account_manager: this.filter.account_manager}).then(response => {
                    return {data: response.data.items}
                })
            },
            searchAccManager(value) {
                return getList({
                    company_id: this.company.id,
                })
            },
        },
        created() {
            EventBus.$on('SearchPropertyUnitListLoading', $event => {
                if(this.reset){
                    this.loaderReset  = $event
                } else {
                    this.loaderSubmit = $event
                }
                this.loading = $event
            })

            if (this.isSetupReservationProcessDistributionUnitRoute || this.isSetupFeesPlatformFeeDistributionUnitRoute) {
                this.searchOwnerOnCreate = false
                this.filter.cm_distribution_status = 1;
            }
            this.initialFilter = cloneDeep(this.filter)
        },
        watch: {
            '$route.params.distribution_id': {
                handler(value) {
                    if (value) {
                        this.setList()
                    }
                },
                immediate: true
            },
            distribution:{
                handler() {
                    this.setList()
                }
            }
        },
        mounted(){
            this.setList()
        }
    }
</script>

<style>

</style>
