<template>
    <div class="property-wrapper mb-0 no-border">
        <div class="card-header">
            <div class="header-caption">{{$t("UPDATE_CHANGES")}}</div>
            <h2 class="property-wrapper-padding mb-2">
                {{property.name}}
            </h2>
            <div class="header-info">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PropertyStatusMessageHeader",
        computed:{
            property() {
                return this.$store.getters['property/getProperty']
            },
        }
    }
</script>

<style scoped>
    .no-border > div {
        border: none !important;
        padding-left: 0;
    }
</style>
