<template>
    <b-form @submit.prevent="save" v-if="loaded">
        <b-row>
            <b-col xl="6" lg="12" md="6" sm="6" class="mb-4">
                <label>{{$t('PROPERTY')}}</label>
                <app-select mode="search"
                            v-model="property"
                            :search="searchProperty"
                            :search-on-create="preloadPropertyList" set-first :search-empty-item="false"></app-select>
            </b-col>
            <b-col xl="6" lg="12" md="6" sm="6" class="mb-4">
                <app-input-control :error-object="validation.unit">
                    <template v-slot:input="data">
                        <label>{{$t('UNIT')}}</label>
                        <app-select :disabled="!unitList.length" :options="unitList" :state="data.state" set-first
                                    :search-empty-item="false" @input="calculatePrice()"
                                    v-model="formData.unit.id"></app-select>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row v-if="checkPermission(C_RESERVATION_ACCOMMODATION_ADD_EXISTING_USER)">
            <b-col xl="6" lg="12" md="12" class="mb-4">
                <b-checkbox v-model="existing_user" switch>{{ $t('ADD_EXISTING_GUEST') }}</b-checkbox>
            </b-col>
        </b-row>
        <b-row v-if="existing_user">
            <b-col xl="6" lg="12" md="6" sm="6" class="mb-4">
                <label>{{ $t('GUEST') }}</label>
                <app-select mode="search"
                            :search="searchUserGuest"
                            v-model="guest"
                            @input="mapGuest($event)">
                    <template v-slot:text="data">
                        {{ data.item.first_name }} {{ data.item.last_name }}
                    </template>
                </app-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="6" lg="12" md="6" sm="6" class="mb-4">
                <app-input-control :error-object="validation.first_name">
                    <template v-slot:input="data">
                        <label>{{$t('FIRST_NAME')}}</label>
                        <b-input v-model="formData.guest.first_name" :state="data.state"></b-input>
                    </template>
                </app-input-control>
            </b-col>
            <b-col xl="6" lg="12" md="6" sm="6" class="mb-4">
                <label>{{$t('LAST_NAME')}}</label>
                <app-input-control :error-object="validation.last_name">
                    <template v-slot:input="data">
                        <b-input v-model="formData.guest.last_name" :state="data.state"></b-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="6" lg="12" md="6" sm="6" class="mb-4">
                <app-input-control :error-object="validation.email">
                    <template v-slot:input="data">
                        <label>{{$t('EMAIL')}}</label>
                        <b-input v-model="formData.guest.email" :state="data.state"></b-input>
                    </template>
                </app-input-control>
            </b-col>
            <b-col xl="6" lg="12" md="6" sm="6" class="mb-4">
                <app-input-control :error-object="validation.phone">
                    <template v-slot:input="data">
                        <label>{{$t('PHONE')}}</label>
                        <b-input v-model="formData.guest.phone" :state="data.state"></b-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="6" lg="12" md="6" sm="6" class="mb-4">
                <app-input-control :error-object="validation.country">
                    <template v-slot:input="data">
                        <label>{{$t('COUNTRY')}}</label>
                        <app-select mode="search"
                                    v-model="formData.guest.country"
                                    :search="searchCountry"
                                    :search-clear="true" :state="data.state">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="6" lg="12" md="6" sm="6" class="mb-4">
                <app-input-control :error-object="validation.period">
                    <template v-slot:input="data">
                        <label>{{$t("PERIOD")}}</label>
                        <app-date-range-picker v-model="period" :state="data.state"></app-date-range-picker>
                    </template>
                </app-input-control>
            </b-col>
            <app-access-control tag="b-col" xl="6" lg="12" md="6" sm="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: C_RESERVATION_ACCOMMODATION_BOOKING_SITE}">
                <app-input-control :error-object="validation.channel">
                    <template v-slot:input="data">
                        <label>{{$t('SALES_CHANNEL')}}</label>
                        <app-select :options="channelList" v-model="formData.channel.id" :state="data.state"
                                    :search-empty-item="false"></app-select>
                    </template>
                </app-input-control>
            </app-access-control>
        </b-row>
        <b-row>
            <b-col xl="6" lg="12" md="6" sm="6" class="mb-4"  v-if="show_offline_reservation_distribution">
                <b-row>
                    <b-col class="mb-1">
                        <i id="offline_reservation"
                           class="fa fa-question-circle-o tooltip-help pull-right mr-1"></i>

                        <b-tooltip target="offline_reservation" triggers="hover" placement="top">
                            {{ $t('OFFLINE_RESERVATION_DESCRIPTION') }}
                        </b-tooltip>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-checkbox
                            v-model="formData.offline_reservation"
                            switch>
                            <label>{{ $t('OFFLINE_RESERVATION') }}</label>
                        </b-checkbox>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <app-access-control tag="b-col" xl="6" lg="12" md="6" sm="6" class="mb-4" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{key: access_control_fetch_key, function: C_RESERVATION_ACCOMMODATION_CHARGE_PROFILE}">
                <app-input-control :error-object="validation.rate_plan">
                    <template v-slot:input="data">
                        <label>{{ $t('RATE_PLAN') }}</label>
                        <app-select mode="search"
                                    :search="searchRatePlan"
                                    v-model="formData.rate_plan"
                                    :disabled="formData.unit.id === null"
                                    :state="data.state">
                        </app-select>
                    </template>
                </app-input-control>
            </app-access-control>
        </b-row>
        <b-row>
            <b-col :xl="checkPermission(C_RESERVATION_ACCOMMODATION_NOTE_PRIVATE_E) ? `6` : `12`" class="mb-4">
                <label>{{$t('NOTE')}}</label>
                <b-form-textarea
                    no-resize
                    v-model="formData.notes[0].content"
                    :rows="2">
                </b-form-textarea>
            </b-col>
            <b-col xl="6" lg="12" md="6" sm="6" class="mb-4" v-if="checkPermission(C_RESERVATION_ACCOMMODATION_NOTE_PRIVATE_E)">
                <label>{{$t('PRIVATE_NOTE')}}</label>
                <b-form-textarea
                    no-resize
                    v-model="formData.notes[1].content"
                    :rows="2">
                </b-form-textarea>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="4" lg="12" md="4" sm="4" class="mb-4">
                <app-input-control :error-object="validation.adult_no">
                    <template v-slot:input="data">
                        <label>{{$t("ADULTS")}}</label>
                        <app-number-input v-model="formData.adult_no" :controls="true" :min="1" :nullable="false" @input="calculatePrice()"
                                          :state="data.state"></app-number-input>
                    </template>
                </app-input-control>
            </b-col>
            <b-col xl="4" lg="12" md="4" sm="4"  class="mb-3">
                <app-input-control :error-object="validation.child_no">
                    <template v-slot:input="data">
                        <label>{{$t("CHILDREN")}}</label>
                        <app-number-input v-model="formData.child_no" :controls="true" :min="0" :nullable="false" @input="calculatePrice()"
                                          :state="data.state"></app-number-input>
                    </template>
                </app-input-control>
            </b-col>
            <b-col xl="4" lg="12" md="4" sm="4"  class="mb-4">
                <app-input-control :error-object="validation.babies_no">
                    <template v-slot:input="data">
                        <label>{{$t("BABIES")}}</label>
                        <app-number-input v-model="formData.baby_no" :controls="true" :min="0" :nullable="false" @input="calculatePrice()"
                                          :state="data.state"
                        ></app-number-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="6" lg="12" md="12" class="mb-4" >
                <label>{{$t("STATUS")}}</label>
                <app-input-control :error-object="validation.status">
                    <template v-slot:input="data">
                        <b-form-group>
                            <b-form-radio v-for="(status, index) in statusList" :key="index"
                                          v-model="formData.status.id"
                                          :value="status.id" inline size="md">
                                {{ status.label }}
                            </b-form-radio>
                        </b-form-group>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="6" lg="12" md="6" sm="6" class="mb-4">
                <app-input-control :error-object="validation.currency">
                    <template v-slot:input="data">
                        <label>{{$t("CURRENCY")}}</label>
                        <app-select mode="search" :search="searchCurrency" search-on-create text-field="code"
                                    :search-empty-item="false"
                                    v-model="formData.channel_currency" :state="data.state">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="6" lg="12" md="6" sm="6" class="mb-4">
                <label>{{$t("EXCHANGE_RATE_SUGGESTION")}}</label>
                <app-select :options="exchangeRateList" :disabled="!exchangeRateList.length"
                            v-model="currencyValueSuggestion" @input="formData.currency_value = $event.value"
                            value-field="value" return-type="object" text-field="value" :search-empty-item="false">
                    <template v-slot:text="data">
                        {{getExchangeRateText(data.item)}}
                    </template>
                </app-select>
            </b-col>
            <b-col xl="6" lg="12" md="6" sm="6" class="mb-4">
                <app-input-control :error-object="validation.currency_value">
                    <template v-slot:input="data">
                        <label>{{$t("EXCHANGE_RATE")}}</label>
                        <app-number-input v-model="formData.currency_value" :state="data.state"
                                          :disabled="formData.channel_currency.id === company.main_currency.id || loadingCurrency"
                                          allow-decimal :min="0" :decimals="9">
                        </app-number-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="6" lg="12" md="6" sm="6" class="mb-4">
                <template>
                    <label>{{ $t("UNIT_PRICE") }}</label>
                    <app-number-input v-model="per_day" :prepend="formData.channel_currency.code"
                                      @input="calculateTotal" allow-decimal align="right" :min="0" :nullable="false">
                    </app-number-input>
                </template>
            </b-col>
            <b-col xl="6" lg="12" md="6" sm="6" class="mb-4">
                <app-input-control :error-object="validation.total">
                    <template v-slot:input="data">
                        <label>{{$t("TOTAL")}}</label>
                        <app-number-input v-model="formData.total" :prepend="formData.channel_currency.code"
                                          allow-decimal align="right" :state="data.state" :min="0" :nullable="false">
                        </app-number-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row v-if="checkPermission(C_RESERVATION_ACCOMMODATION_SEND_NOTIFICATION)">
            <b-col md="6" class="mb-4">
                <b-checkbox v-model="formData.send_notifications" switch >{{ $t('SEND_NOTIFICATION') }}</b-checkbox>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button-submit :loading="saving" :disabled="loading"></app-button-submit>
            </b-col>
        </b-row>
        <b-modal :title="$t('SEND_RESERVATION_NOTIFICATION')" v-model="send_notifications_modal">
            <b-checkbox :checked="false" @change="updateSelectAll">
                {{$t("SELECT_ALL")}}
            </b-checkbox>
            <hr class="p-0 m-0 mb-1 mt-1">
            <b-checkbox-group v-model="sendActionSelected" stacked :options="sendActions"></b-checkbox-group>
            <template v-slot:modal-footer>
                <div class="w-100 d-flex justify-content-between align-items-center">
                    <app-button @click="saveReservation()" :loading="sendActionLoading"
                                :disabled="sendActionSelected.length === 0 || sendActionSelected.length > maxSendLimit"
                                class="mr-3">

                        {{$t('SEND')}}
                    </app-button>
                </div>
            </template>
        </b-modal>
    </b-form>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {
    CHANNEL_OWNER_RESERVATION, CO_RECIPIENT_TYPE_ACCOUNT_MANAGER, CO_RECIPIENT_TYPE_AGENCY,
    CONTACT_ACTION_RESERVATION_ARRIVAL_TIME, CONTACT_ACTION_RESERVATION_NEW,
    CONTACT_ACTION_RESERVATION_UPDATE, CONTACT_RECIPENT_GUEST,
    CONTACT_RECIPENT_PDF,
    DEFAULT_PAGINATION_LIMIT,
    LS_PRESETS,
    OBJECT_TYPE_ACCOMMODATION_RESERVATION, RATE_PLAN_CATEGORY_DISTRIBUTION,
    RES_NOTE_TYPE_NOTE,
    RES_NOTE_TYPE_PRIVATE,
    RES_STATUS_FILTERED_LIST,
    WORKFLOW_BOOKER_TOOLS
} from "@/shared/constants";
import {getProperty, getPropertyList} from "@/services/property";
import {getUnitList} from "@/services/unit";
import {getCountries} from "@/services/location";
import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
import {getChannelList} from "@/services/distribution";
import {fetchStatusList} from "@/services/status";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import AppButton from "@/components/app/AppButton/AppButton";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {
    calculateAccommodationReservationPrice,
    createAccommodationReservation, groupNotify
} from "@/services/accommodation_reservation";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {getCurrencyList} from "@/services/finance";
import {notifySuccess, toast} from "@/shared/plugins/toastr";
import {
    AR_CHANNEL,
    AR_CHANNEL_CURRENCY, AR_CHANNEL_TOTAL,
    AR_GUEST_COUNTRY,
    AR_GUEST_EMAIL,
    AR_GUEST_FIRST_NAME,
    AR_GUEST_LAST_NAME, AR_GUEST_PHONE, AR_NUM_ADULTS, AR_NUM_BABIES, AR_NUM_CHILDREN, AR_PERIOD, AR_STATUS,
    AR_UNIT, AR_CURRENCY_VALUE, AR_RATE_PLAN
} from "@/shared/error_codes";
import AppInputControl from "@/components/app/AppInputControl";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import AppAccessControl from "@/components/app/AppAccessControl";
import {
    AC_ACCOMMODATION_RESERVATION_GENERAL,
    AC_ACCOMMODATION_RESERVATION_NEW
} from "@/mixins/AccessControl/AccessControlEnumeration";
import routeNames from "@/router/routeNames";
import {
    C_RESERVATION_ACCOMMODATION_ADD_EXISTING_USER,
    C_RESERVATION_ACCOMMODATION_BOOKING_SITE,
    C_RESERVATION_ACCOMMODATION_CM_STATUS,
    C_RESERVATION_ACCOMMODATION_DISTRIBUTION, C_RESERVATION_ACCOMMODATION_SEND_NOTIFICATION,
    C_RESERVATION_ACCOMMODATION_NOTE_PRIVATE_E, C_RESERVATION_ACCOMMODATION_CHARGE_PROFILE
} from "@/shared/component_permission";
import {F_SET_DEFAULT_CHANNEL} from "@/shared/function_permission";
import {fetchCurrencyExchangeRates} from "@/services/finance/currency_exchange";
import moment from 'moment';
import AppSearch from "@/components/app/search/AppSearch";
import {getGuestList} from "@/services/guest";
import {getUserList} from "@/services/user";
import {cloneDeep} from 'lodash';
import {fetchAccessControlData} from "@/services/access";
import {getPricingRatePlanList, getRatePlanList} from "@/services/pricing";

export default {
    name: "NewAccommodationReservationFormTest",
    components: {
        AppSearch,
        AppAccessControl,
        AppInputControl, AppButtonSubmit, AppButton, AppNumberInput, AppDateRangePicker, AppSelect
    },
    mixins: [getErrorMessage, AccessControlComponent],
    props: {
        propertyId: {
            type: Number,
            default: null,
        },
        unitId:{
            type:Number,
            default: null,
        },
        redirect: {
            type: Boolean,
            default: true
        },
        dateRange:{
            type:Object
        },
        availableUnitList:{
            type:Array
        },
        onlyAvailableUnit:{
            type:Boolean,
            default:false
        },
        reusedReservation:{
            type:Object,
            default:null
        }
    },
    data() {
        return {
            preloadPropertyList: false,
            unitList: [],
            statusList: [],
            channelList: [],
            property: {id: null, name: null},
            maxSendLimit: 20,
            formData: {
                unit: {id: null, name: null},
                channel: {id: null, name: null},
                offline_reservation: null,
                guest: {
                    country: {id: null, name: null}
                },
                check_in_date: null,
                check_out_date: null,
                status: {id: null, label: null},
                channel_currency: {id: null, code: null},
                currency_value: null,
                notes: [
                    {
                        id: RES_NOTE_TYPE_NOTE,
                        content: null
                    },
                    {
                        id: RES_NOTE_TYPE_PRIVATE,
                        content: null
                    }
                ],
                adult_no: 1,
                child_no: 0,
                baby_no: 0,
                send_notifications: false,
                rate_plan: {id: null, name: null},
            },
            per_day: 0,
            guest: {},
            existing_user: false,
            loaded: false,
            loading: false,
            saving: false,
            send_notifications_modal: false,
            reservation_id: null,
            validation: {
                unit: AR_UNIT,
                first_name: AR_GUEST_FIRST_NAME,
                last_name: AR_GUEST_LAST_NAME,
                country: AR_GUEST_COUNTRY,
                email: AR_GUEST_EMAIL,
                phone: AR_GUEST_PHONE,
                adult_no: AR_NUM_ADULTS,
                child_no: AR_NUM_CHILDREN,
                babies_no: AR_NUM_BABIES,
                status: AR_STATUS,
                currency: AR_CHANNEL_CURRENCY,
                total: AR_CHANNEL_TOTAL,
                channel: AR_CHANNEL,
                period: AR_PERIOD,
                currency_value: AR_CURRENCY_VALUE,
                rate_plan: AR_RATE_PLAN,
            },
            access_control_fetch_key: AC_ACCOMMODATION_RESERVATION_NEW,
            access_control_components: 2,
            access_control_general_context: null,
            C_RESERVATION_ACCOMMODATION_BOOKING_SITE,
            C_RESERVATION_ACCOMMODATION_NOTE_PRIVATE_E,
            C_RESERVATION_ACCOMMODATION_ADD_EXISTING_USER,
            C_RESERVATION_ACCOMMODATION_SEND_NOTIFICATION,
            exchangeRateList: [],
            currencyValueSuggestion: {},
            loadingCurrency: false,
            sendActionLoading: false,
            selectAllForSend: false,
            sendActions: [
                // {
                //     value: 'acm_reservation',
                //     text: this.$t('RESERVATION_TO_ACCOUNT_MANAGER'),
                //     request: {
                //         reservation: [],
                //         actionList: [CONTACT_ACTION_RESERVATION_UPDATE],
                //         recipientTypeList: [CO_RECIPIENT_TYPE_ACCOUNT_MANAGER],
                //     }
                // },
                {
                    value: 'contact_list_reservation',
                    text: this.$t('RESERVATION_TO_CONTACT_LIST'),
                    request: {
                        reservation: [],
                        actionList: [CONTACT_ACTION_RESERVATION_UPDATE],
                        recipientTypeList: [CONTACT_RECIPENT_PDF],
                    }
                },
                // {
                //     value: 'agency_reservation',
                //     text: this.$t('RESERVATION_TO_AGENCY'),
                //     request: {
                //         reservation: [],
                //         actionList: [CONTACT_ACTION_RESERVATION_UPDATE],
                //         recipientTypeList: [CO_RECIPIENT_TYPE_AGENCY],
                //     }
                // },
                {
                    value: 'guest_confirmation_mail',
                    text: this.$t('CONFIRMATION_TO_GUEST'),
                    request: {
                        filter: [],
                        actionList: [CONTACT_ACTION_RESERVATION_NEW],
                        recipientTypeList: [CONTACT_RECIPENT_GUEST],
                    }
                },
            ],
            sendActionSelected: [],
            show_offline_reservation_distribution: false,
            C_RESERVATION_ACCOMMODATION_CHARGE_PROFILE,
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        period: {
            get() {
                return {
                    start: this.formData.check_in_date,
                    end: this.formData.check_out_date
                }
            },
            set({start, end}) {
                this.formData.check_in_date = start
                this.formData.check_out_date = end
            }
        },
    },
    watch: {
        'property.id': {
            handler(value) {
                if(!this.reusedReservation){
                    this.formData.unit = {id: null, name: null}
                }
                this.unitList = []
                if (value) {
                    if(this.onlyAvailableUnit){
                        this.unitList = this.availableUnitList
                    } else {
                        this.loading = true
                        getUnitList({property_id: value, limit: 1000}).then(res => {
                            this.unitList = res.data.items
                        }).finally(() => this.loading = false)
                    }
                    if(!this.reusedReservation){
                        this.formData.unit.id = this.unitId ? this.unitId : null
                    }
                } else {
                    this.unitList = []
                }
            }, immediate: true
        },
        'formData.channel_currency': {
            handler(newObject, previousObject) {
                if (newObject.id === this.company.main_currency.id) {
                    this.exchangeRateList = []
                    this.formData.currency_value = 1
                    return
                }

                if (newObject.id !== previousObject.id) {
                    this.exchangeRateList = []
                    this.currencyValueSuggestion = {}
                    this.formData.currency_value = null
                    this.loadingCurrency = true
                    fetchCurrencyExchangeRates(newObject.id, {
                        company: this.company.id,
                        with_fixed: true
                    }).then(response => {
                        this.exchangeRateList = response.data
                        if (this.exchangeRateList.length) {
                            this.currencyValueSuggestion = this.exchangeRateList[0]
                        } else {
                            this.formData.currency_value = null
                        }
                    }, error => (this.showErrorMessages(error))).finally(() => this.loadingCurrency = false)
                }
            }
        },
        currencyValueSuggestion(object) {
            if (object.value) {
                this.formData.currency_value = object.value
            }
        },
        'formData.currency_value': {
            handler(value) {
                if ((value && Number(value) !== Number(this.currencyValueSuggestion.value)) || !value) {
                    this.currencyValueSuggestion = {}
                }
            }
        },
        'period': {
            handler(value) {
                if(value && value.start) {
                    this.calculatePrice()
                }
            }
        },
        dateRange: {
            handler(object) {
                if (object && object.hasOwnProperty('start') && object.hasOwnProperty('end')) {
                    this.formData.check_in_date = object.start
                    this.formData.check_out_date = object.end
                }
            }, immediate: true,
            deep: true
        },
        existing_user: {
            handler(value) {
                if(!value){
                    this.resetGuest()
                }
            }
        },
        send_notifications_modal:{
            handler(value) {
                if(!value){
                    this.saving = false
                }
            }
        },
        'formData.channel.id': {
            handler() {
                this.fetchAccessControl()
            }
        },
        'formData.unit.id': {
            handler(newValue, oldValue) {
                if (oldValue !== null) {
                    this.formData.rate_plan = {id: null, name: null}
                }
                if (newValue !== null) {
                    this.calculatePrice()
                }
            },
        },
    },
    methods: {
        save() {
            this.saving = true

            if(this.formData.send_notifications){
                this.send_notifications_modal = true
            } else {
                this.saveReservation()
            }
        },
        saveReservation(){
            this.sendActionLoading = true
            let reqItem = {
                actionList : [],
                recipientTypeList : [],
            }
            for (let index = 0; index < this.sendActionSelected.length; index++) {
                const selectedItem = this.sendActionSelected[index]
                let requestItem = this.sendActions.find(el => {
                    return el.value === selectedItem
                })

                reqItem.actionList.push(...requestItem.request.actionList)
                reqItem.recipientTypeList.push(...requestItem.request.recipientTypeList)
            }

            createAccommodationReservation({...this.formData, company: this.company.id,...reqItem}).then(response => {
                notifySuccess()
                this.$emit('created', response.data)
                if (this.redirect) {
                    this.$router.push({
                        name: routeNames.RN_RESERVATION_ACCOMMODATION_LIST,
                        query: {reservation: response.data.id, reservation_code: response.data.reservation_code,tab: 'general'}
                    })
                }
            }).catch(error => {
                this.showErrorMessages(error, this.validation)
            }).finally(() => {
                this.saving = false
                this.sendActionLoading = false
                this.send_notifications_modal = false
            })
        },
        searchProperty(value) {
            return getPropertyList({name: value, company_id: this.company.id}).then(response => {
                return {data: response.data.items}
            })
        },
        searchUserGuest(value){
            return getUserList({name: value, company: this.company.id,is_guest:true, is_agency_guest:true}).then(response => {
                return {data: response.data.items}
            })
        },
        searchCountry(value) {
            return getCountries({
                name: value
            })
        },
        searchChannel() {
            return getChannelList({with_icons: 0, for_reservation: 1}).then(response => {
                return {
                    data: response.data.map(el => {
                        return {id: el.id, name: el.name}
                    })
                }
            })
        },
        searchCurrency() {
            return getCurrencyList()
        },
        getExchangeRateText(item) {
            if (this.loadingCurrency) return ''
            const text = item.exchange_channel && item.exchange_channel.name
                ? `${item.exchange_channel.name} ${moment(item.date).format(this.$t('DATE.DATE_FORMAT'))}`
                : this.$t('FIXED_EXCHANGE_RATE')
            return item.value ? `${item.value} (${text})` : this.$t('CUSTOM')
        },
        calculatePrice() {
            if (this.formData.check_in_date && this.formData.check_out_date && this.formData.unit.id &&
                (this.formData.adult_no || this.formData.child_no || this.formData.baby_no)) {

                let req = {
                    check_in: this.formData.check_in_date,
                    check_out: this.formData.check_out_date,
                    adults: this.formData.adult_no + this.formData.child_no,
                    children: this.formData.baby_no,
                    unit_id: this.formData.unit.id,
                }

                calculateAccommodationReservationPrice(req).then(response => {
                    this.formData.total = response.data.total_with_discount
                })
            }
        },
        calculateTotal(value){
            if(this.formData.check_in_date && this.formData.check_out_date && value){
                let checkIn = moment(this.formData.check_in_date);
                let checkOut  = moment(this.formData.check_out_date);
                let num_of_days = checkOut.diff(checkIn,'days')

                this.formData.total = num_of_days * value
            }
        },
        mapGuest(value) {
            if (value && value.id) {
                this.formData.guest = {
                    id: value.id,
                    country: {
                        id: value.country.id,
                        name: value.country.name,
                    },
                    first_name: value.first_name,
                    last_name: value.last_name,
                    email: value.email,
                    phone: value.phone
                }
            }
            else {
                this.resetGuest()
            }
        },
        resetGuest(){
            this.formData.guest = {
                id: null,
                country: {
                    id: null,
                    name: null,
                },
                first_name: null,
                last_name: null,
                email: null,
                phone: null
            }
            this.guest = {}
        },
        setActions(){
            this.sendActions = [
                {
                    value: 'guest_confirmation_mail',
                    text: this.$t('CONFIRMATION_TO_GUEST'),
                    request: {
                        filter: [],
                        actionList: [CONTACT_ACTION_RESERVATION_NEW],
                        recipientTypeList: [CONTACT_RECIPENT_GUEST],
                    }
                },
            ]
        },
        updateSelectAll($event){
            if ($event) {
                this.sendActionSelected = this.sendActions.map(el => el.value)
            } else {
                this.sendActionSelected = []
            }
        },
        fetchAccessControl() {
            let request = {
                key: AC_ACCOMMODATION_RESERVATION_NEW,
                context: {
                    company: this.company.id,
                    channel: this.formData.channel.id
                },
                data: []
            }

            request.data.push({
                uid: C_RESERVATION_ACCOMMODATION_DISTRIBUTION,
                function: C_RESERVATION_ACCOMMODATION_DISTRIBUTION,
            })
            fetchAccessControlData(AC_ACCOMMODATION_RESERVATION_NEW, request).then(response => {
                this.show_offline_reservation_distribution = response.data[C_RESERVATION_ACCOMMODATION_DISTRIBUTION].visible
            })
        },
        searchRatePlan(ratePlanName) {
            const request = {
                name: ratePlanName,
                company: this.company.id,
                contingent_unit: this.formData.unit.id,
                category_ids: RATE_PLAN_CATEGORY_DISTRIBUTION,
                perPage: 1000,
            }
            return getRatePlanList(request).then((response) => {
                return {
                    data: response.data.items.map((ratePlan) => ({
                            id: ratePlan.id,
                            name: ratePlan.distribution.id !== null ? ratePlan.name + ' (' + ratePlan.distribution.name + ')' : ratePlan.name
                        })
                    )
                }
            })
        },
    },
    created() {
        this.access_control_general_context = {
            company: this.company.id
        }
        /*       this.loading = true*/
        this.formData.channel_currency = this.company.working_currency
        this.formData.unit.id = this.unitId

        if (this.propertyId) {
            getProperty(this.propertyId).then(response => {
                this.property = response.data
            })
        }

        let storagePresets = localStorage.getItem(LS_PRESETS)
        if (storagePresets) {
            storagePresets = JSON.parse(storagePresets)
            if (storagePresets.hasOwnProperty('properties') && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                this.preloadPropertyList = true
            }
        }

        fetchStatusList(OBJECT_TYPE_ACCOMMODATION_RESERVATION).then(response => {
            this.statusList = response.data.filter(status => !RES_STATUS_FILTERED_LIST.includes(Number(status.id)))
            this.formData.status.id = this.statusList[0].id
            this.loaded = true
        }, error => this.showErrorMessages(error))

        this.searchChannel().then(response => {
            this.channelList = response.data
            if (this.checkPermission(F_SET_DEFAULT_CHANNEL)) {
                const presetChannel = response.data.find(el => el.id === CHANNEL_OWNER_RESERVATION)
                if (presetChannel) {
                    this.formData.channel = presetChannel
                    this.setActions()
                }
            }
        })

        if(this.reusedReservation){
            this.formData = {...this.formData,...this.reusedReservation}

            this.property.id = this.reusedReservation.property.id
            this.property.name = this.reusedReservation.property.name
            this.formData.unit.id = this.reusedReservation.unit.id
            this.formData.unit.name = this.reusedReservation.unit.name
            this.formData.rate_plan.id = this.reusedReservation.rate_plan.id
            this.formData.rate_plan.name = this.reusedReservation.rate_plan.name

            this.check_in_date = null
            this.check_out_date = null

            this.guest = {...this.reusedReservation.guest}
            if(this.reusedReservation.guest.id){
                this.existing_user = true
            }
        }
    }
}
</script>

<style scoped>
.custom-number-input{
    max-width: 3rem;
}
</style>
