<template>
    <b-form @submit.prevent="save" v-if="owner !== null && hasPermissionView">
        <b-row>
            <b-col sm="12" md="6" lg="4" class="mb-4">
                <label>{{$t('FIRST_NAME_LAST_NAME')}}</label>
                <b-form-input v-model="owner.name" type="text" id="name"></b-form-input>
                <app-validation-description :show="$v.owner.name.$dirty && $v.owner.name.$invalid">
                    {{$t("FIELD_MANDATORY")}}
                </app-validation-description>
            </b-col>
            <b-col sm="12" md="6" lg="4" class="mb-4">
                <label>{{$t('VAT_NUMBER')}}</label>
                <b-form-input v-model="owner.oib" type="text" id="oib"></b-form-input>
            </b-col>
            <b-col sm="12" md="6" lg="4" class="mb-4">

                <label>{{$t('COUNTRY')}}</label>
                <app-select mode="search" :search="searchCountry" v-model="owner.location.country"
                            :search-clear="true"></app-select>
                <app-validation-description
                    :show="$v.owner.location.country.id.$dirty && $v.owner.location.country.id.$invalid">
                    {{$t("FIELD_MANDATORY")}}
                </app-validation-description>
            </b-col>
            <b-col sm="12" md="6" lg="4" class="mb-4">

                <label>{{$t('ADDRESS')}}</label>
                <b-form-input v-model="owner.location.address" type="text" id="address"></b-form-input>
                <app-validation-description
                    :show="$v.owner.location.address.$dirty && $v.owner.location.address.$invalid">
                    {{$t("FIELD_MANDATORY")}}
                </app-validation-description>
            </b-col>
            <b-col sm="12" md="6" lg="4" class="mb-4">
                <label>{{$t('ZIP')}}</label>
                <b-form-input v-model="owner.location.zip" type="text" id="postal_code"></b-form-input>
                <app-validation-description :show="$v.owner.location.zip.$dirty && $v.owner.location.zip.$invalid">
                    {{$t("FIELD_MANDATORY")}}
                </app-validation-description>
            </b-col>
            <b-col sm="12" md="6" lg="4" class="mb-4">
                <label>{{$t('CITY')}}</label>
                <b-form-input v-model="owner.location.city" type="text" id="city"></b-form-input>
                <app-validation-description :show="$v.owner.location.city.$dirty && $v.owner.location.city.$invalid">
                    {{$t("FIELD_MANDATORY")}}
                </app-validation-description>
            </b-col>
            <b-col sm="12" md="6" lg="4" class="mb-4">
                <label>{{$t('REGISTRATION_NUMBER')}}</label>
                <b-form-input v-model="owner.registration_number" type="text" id="registration_number"></b-form-input>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <SubmitBasicOwner  :access_control_context="{function:c_permission_e,key:AC_OWNER}"
                                   @AccessControlEvent="addToAccessControlCounter()"  :c_permission="c_permission_e"></SubmitBasicOwner>
            </b-col>

            <app-access-control  @AccessControlEvent="addToAccessControlCounter()" :access_control_context="{function:c_permission_e, key: AC_OWNER}">
                <template v-slot:default="props">
                    <b-tooltip v-if="props.messages.length"
                               class="mb-5"
                               triggers="hover"
                               :title="props.messages[0].message" :target="'key_'+ownerObject.id">
                    </b-tooltip>
                    <delete-owner :c_permission="c_permission_e"
                                  :disabled="props.disabled"
                                  :button_type="deleteState ? 'loading': 'delete'"
                                  @click="deleteDialog=true"
                                  class="pull-right"></delete-owner>
                    <OwnerDeleteDialog :show="deleteDialog" @confirm="deleteOwner" :delete_title="true"
                                       @cancel="deleteDialog=false"></OwnerDeleteDialog>
                </template>

            </app-access-control>

        </b-row>
    </b-form>
</template>

<script>
    import SubmitBasicOwner from '@/components/app/AppButton/AppButtonSubmit'
    import DeleteOwner from '@/components/app/AppButton/AppButtonDelete'
    import OwnerCountrySelect from '@/components/location/CountrySelect'
    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import OwnerDeleteDialog from '@/components/app/form/AppConfirmationDialog'
    import AppValidationDescription from '@/components/app/form/AppValidationDescription'
    import routeNames from '@/router/routeNames'
    import {C_OWNER_BASIC_E, C_OWNER_BASIC_V} from "@/shared/component_permission";
    import {required} from 'vuelidate/lib/validators'
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {getCountries} from "@/services/location";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {AC_OWNER} from "@/mixins/AccessControl/AccessControlEnumeration";
    import AppAccessControl from "@/components/app/AppAccessControl";

    export default {
        name: "OwnerBasicForm",
        components: {
            AppAccessControl,
            AppSelect,
            SubmitBasicOwner, OwnerCountrySelect, DeleteOwner, OwnerDeleteDialog, AppValidationDescription
        },
        mixins: [getErrorMessage,AccessControlComponent],
        data() {
            return {
                c_permission_v: C_OWNER_BASIC_V,
                c_permission_e: C_OWNER_BASIC_E,
                owner: null,
                deleteState: false,
                deleteDialog: false,
                access_control_fetch_key:AC_OWNER,
                AC_OWNER,
                access_control_components:2
            }
        },
        methods: {
            updateCountry(value) {
                this.owner.location.country = value
            },
            changeVat(value) {
                this.owner.vat_system = Number(value)
            },
            changeSelfIssue(value) {
                this.owner.self_issue = Number(value)
            },
            searchCountry(value) {
                return getCountries({
                    name: value
                })
            },
            save() {
                this.$v.$touch()
                if ((this.$v.$error)) {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('VALIDATION_FAILED'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return
                }

                let object = {
                    propertyId: this.$route.params.id,
                    ownerId: this.$route.params.owner_id,
                    data: {
                        name: this.owner.name,
                        oib: this.owner.oib,
                        registration_number: this.owner.registration_number,
                        location: {
                            address: this.owner.location.address,
                            city: this.owner.location.city,
                            zip: this.owner.location.zip,
                            country: {
                                id: this.owner.location.country.id
                            }
                        },
                    }
                }

                this.$store.dispatch('owner/actionUpdateOwner', object).then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 6000
                        })
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.getMessages(error),
                            'type': 'error',
                            'timeout': 2000
                        })
                    }
                )
            },
            deleteOwner() {
                this.deleteDialog = false

                let object = {
                    propertyId: this.$route.params.id,
                    ownerId: this.$route.params.owner_id
                }
                this.$router.push({name: routeNames.RN_PROPERTY_OWNER_LIST})
                this.$store.dispatch('owner/actionDeleteOwner', object).then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 6000
                        })

                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.getMessages(error),
                            'type': 'error',
                            'timeout': 2000
                        })
                    }
                )
            }
        },
        computed: {
            hasPermissionView() {
                return this.$store.getters['user/getPermission'](this.c_permission_v)
            },
            ownerObject() {
                return this.$store.getters['owner/getOwner']
            }
        },
        validations: {
            owner: {
                name: {
                    required
                },
                location: {
                    address: {
                        required
                    },
                    city: {
                        required
                    },
                    zip: {
                        required
                    },
                    country: {
                        id: {
                            required
                        }
                    }
                }
            }
        },
        watch: {
            ownerObject: {

                handler(value) {
                    this.owner = {...{}, ...value}
                },
                immediate: true,
                deep: true
            }
        },
        created(){
            this.access_control_general_context = {
                property: this.$route.params.id,
                company: this.$store.getters['user/getCompany']
            }
        }
    }
</script>

<style scoped>

</style>
