<template>
    <div>
        <b-row>
            <b-col>
                <p>{{$t('COMMISSION_COPY_TEXT')}}</p>
                <p class="text-danger">{{$t('COMMISSION_COPY_TEXT_WARNING')}}</p>
            </b-col>
        </b-row>
        <b-form @submit.prevent="submitCopyCommissions" class="ml-3">
            <b-row>
                <b-col cols="12" class="mb-4">
                    <label>{{$t("UNIT")}}</label>
                    <app-select v-model="unit" mode="search" :search="searchUnits"></app-select>
                </b-col>
                <b-col cols="12" class="mb-4">
                    <label>{{$t("FROM_YEAR")}}</label>
                    <app-select v-model="yearFrom" :options="yearsFromComputed"></app-select>
                    <validation-description :show="$v.yearFrom.$dirty && $v.yearFrom.$invalid">
                        {{$t('FIELD_MANDATORY')}}
                    </validation-description>
                </b-col>
                <b-col cols="12" class="mb-4">
                    <label>{{$t("TO_YEAR")}}</label>
                    <app-select mode="multiselect" v-model="yearTo" :options="yearsToComputed"></app-select>
<!--                    <app-select v-model="yearTo" :options="yearsComputed"></app-select>-->
                    <validation-description :show="$v.yearTo.$dirty && $v.yearTo.$invalid">
                        {{$t('FIELD_MANDATORY')}}
                    </validation-description>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mb-4">
                    <b-form-checkbox id="checkbox1"
                                     v-model="selectedApplyAll">
                        {{$t('APPLY_TO_ALL_UNITS_OF_PROPERTY')}}
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <app-button-submit  :loading="loading" :c_permission="c_permission_e" :disabled="$v.$invalid">{{$t('COPY')}}</app-button-submit>
                </b-col>
            </b-row>
        </b-form>

    </div>
</template>

<script>
    import ValidationDescription from '@/components/app/form/AppValidationDescription'
    import {required} from 'vuelidate/lib/validators'
    import {copyUnitCommissions} from "@/services/unit";
    import {toast} from '@/shared/plugins/toastr'
    import {C_UNIT_COMMISSION_E} from "@/shared/component_permission";
    import AppButtonSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import moment from 'moment';
    import {getUnitList} from '@/services/unit/index'
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import FormHeader from "@/components/app/form/FormHeader";

    export default {
        name: "copy-commissions-form",
        props: ['selectedYear', 'selectedCommissionType'],
        mixins: [getErrorMessage],
        data() {
            return {
                c_permission_e: C_UNIT_COMMISSION_E,
                yearFrom: null,
                yearTo: [],
                unit: {id: null, name: null},
                selectedApplyAll: 0,
                loading:false,
            }
        },
        components: {
            AppButtonSubmit,
            AppSelect,
            ValidationDescription,
            FormHeader
        },
        computed: {
            yearsFromComputed() {
                return [...Array(4)].map((a, b) => {
                    return {
                        id: (Number(new moment().format('YYYY')) - 1 + b).toString(),
                        name: Number(new moment().format('YYYY')) - 1 + b
                    }
                })
            },
            yearsToComputed() {
                return [...Array(3)].map((a, b) => {
                    return {
                        id: (Number(new moment().format('YYYY')) + b).toString(),
                        name: Number(new moment().format('YYYY')) + b
                    }
                })
            },
            company_id(){
                return  this.$store.getters['user/getCompany']
            }
        },
        validations: {
            yearFrom: {
                required
            },
            yearTo: {
                required
            },
            unit: {
                id: {
                    required,
                }
            }
        },

        methods: {
            submitCopyCommissions() {
                this.loading = true
                this.$v.$touch()

                if ((this.$v.$error)) {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('VALIDATION_FAILED'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return
                }

                copyUnitCommissions(this.$route.params.unit_id, {
                    year_from: this.yearFrom,
                    year_to: this.yearTo,
                    select_unit: this.unit.id,
                    change_all_units: this.selectedApplyAll ? 1 : 0
                }).then(() => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.$emit('close')

                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                }).finally(()=>{
                    this.loading = false
                })

            },
            searchUnits(keyword) {
                return getUnitList({name: keyword,company_id:this.company_id}).then(response => {
                    return {data: response.data.items}
                })
            }
        }
    }
</script>

<style scoped>

</style>
