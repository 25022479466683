<template>
    <div>
        <b-card class="main-section-card">
            <div slot="header">
                <h2>{{ $t("PAYMENT_ORDERS") }}</h2>
                <app-button
                    button_type="new" variant="primary"
                    class="pull-right"
                    @click="paymentOrderPartnersSideFormState = true">
                    {{ $t('PAYMENT_ORDER_NEW') }}
                </app-button>
            </div>
            <payment-order-filter @search="search"
                                  :loading="loading"
                                  :invoice-status-list="invoiceStatusList">
            </payment-order-filter>
            <payment-order-table :filter="filter"
                                 @isLoading="isLoading"
                                 @editPaymentOrder="editPaymentOrder">
            </payment-order-table>
            <app-aside :widths="['col-lg-12','col-sm-12', '100%']"
                       v-model="paymentOrderPartnersSideFormState">
                <template slot="header">{{ $t('PARTNERS') }}</template>
                <payment-order-partners-side-form @generatedPaymentOrder="generatedPaymentOrder">
                </payment-order-partners-side-form>
            </app-aside>
            <app-aside :widths="['100%']"
                       v-model="paymentOrderSideFormState"
                       full-width
                       class="align-content-center"
                       header-styles="position: sticky; top: 0; z-index: 999; background-color:#fff">
                <template slot="header">{{ $t('PAYMENT_ORDER') }}</template>
                <div style="overflow-y: auto; overflow-x: hidden;">
                    <b-tabs nav-class="tab_navigation-horizontal tab-nav tab-bulk-calendar mt-5"
                            content-class="tab_content-horizontal"
                            pills justified
                            v-model="pricingSetupTab">
                        <b-tab :title="$t('GENERAL')">
                            <payment-order-general-side-form
                                :payment-order="paymentOrder"
                                :payment-order-id="paymentOrderId"
                                :invoice-status-list="invoiceStatusList"
                                @paymentOrderSaved="paymentOrderSaved"
                                @updatePaymentOrder="paymentOrderSaved">
                            </payment-order-general-side-form>
                        </b-tab>
                        <b-tab :title="$t('INVOICES')">
                            <payment-order-invoice-side-form
                                :payment-order="paymentOrder"
                                :payment-order-id="paymentOrderId"
                                @paymentOrderRemoved="paymentOrderRemoved"
                            ></payment-order-invoice-side-form>
                        </b-tab>
                    </b-tabs>
                </div>
            </app-aside>
        </b-card>
    </div>
</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";
import PaymentOrderFilter from "@/components/finance/payment_orders/list/PaymentOrderFilter";
import PaymentOrderTable from "@/components/finance/payment_orders/list/PaymentOrderTable";
import AppAside from "@/components/app/form/AppAside";
import PaymentOrderPartnersSideForm from "@/components/finance/payment_orders/forms/PaymentOrderPartnersSideForm";
import PaymentOrderGeneralSideForm from "@/components/finance/payment_orders/forms/PaymentOrderGeneralSideForm";
import PaymentOrderInvoiceSideForm from "@/components/finance/payment_orders/forms/PaymentOrderInvoiceSideForm";
import {getInvoiceStatusList} from "@/services/status";
import {EventBus} from "@/shared/EventBus";
import {fetchPaymentOrder} from "@/services/finance";

export default {
    name: "Index",
    components: {
        AppButton,
        PaymentOrderFilter,
        PaymentOrderTable,
        AppAside,
        PaymentOrderPartnersSideForm,
        PaymentOrderGeneralSideForm,
        PaymentOrderInvoiceSideForm
    },
    data() {
        return {
            filter: {},
            invoiceStatusList: [],
            loading: false,
            paymentOrderPartnersSideFormState: false,
            paymentOrderSideFormState: false,
            paymentOrderId: null,
            pricingSetupTab: 1,
            paymentOrder: null
        }
    },
    methods: {
        search(filter) {
            this.loading = true
            this.filter = filter
        },
        isLoading(value) {
            this.loading = value
        },
        editPaymentOrder(paymentOrderId, tab) {
            this.paymentOrderId = paymentOrderId
            fetchPaymentOrder(this.paymentOrderId).then(response => {
                this.paymentOrder = response.data
            }).finally(() => {
                this.paymentOrderSideFormState = true
                this.pricingSetupTab = tab
            })
        },
        paymentOrderSaved() {
            this.paymentOrderSideFormState = false
            EventBus.$emit('reloadPaymentOrderTable')
        },
        paymentOrderRemoved() {
            EventBus.$emit('reloadPaymentOrderTable')
        },
        generatedPaymentOrder() {
            this.paymentOrderPartnersSideFormState = false
            EventBus.$emit('reloadPaymentOrderTable')
        }
    },
    created() {
        getInvoiceStatusList({option: 'payment_order'}).then(response => {
            this.invoiceStatusList = response.data
        })
    }
}
</script>

<style scoped>

</style>
