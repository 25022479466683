<template>
    <div v-if="mainUnit" v-show="loaded">

        <div class="main_calendar_test__container d-flex">
            <div class="main_calendar_test__holder">
                <div class="main_calendar_test__holder--sidebar">
                    <table-year-cell :show-unit-type="showHeader" :unit="mainUnit" :unit-type="unit.unit_type.name"
                                     :contigents="contigentUnits"
                                     :scroll-left-position="scrollLeftPosition"
                                     :date-list="dateArray" @yearAside="openYearAside"
                                     :revenue-manager-options="revenueManagerOptions"
                                     @openRevenueManagerSetup="openRevenueManagerSetup">

                    </table-year-cell>
                    <div class="main_calendar_test-bb sticky-div"></div>
                    <template v-if="previewAvailability && mainUnit">
                        <table-availability-sidebar :unit="mainUnit" :is-root="true"
                                                    :contigents="contigentUnits"
                                                    :show="showAvailability"
                                                    :class="{'main_calendar_test-bb': !showAvailability}"
                                                    @show="showAvailabilityEvent"></table-availability-sidebar>
                        <table-availability-sidebar v-if="showAvailability" :key="'av_side_' + contUnit.id"
                                                    v-for="(contUnit, contUnitIndex) in additional_data.contigent"
                                                    :class="{'main_calendar_test-bb': contUnitIndex === additional_data.contigent.length -1}"
                                                    :unit="contUnit" :is-root="false"></table-availability-sidebar>
                    </template>
                    <template v-if="previewPrices">
                        <table-rates-sidebar
                            :additional-data="additional_data"
                            :expand_los_occ="expand_los_occ"
                            @pricingSetup="handlePricingSetupOpen"
                            @updateRatePlanListPricing="setRatePlanListPrices"
                            :rate-plans="nonPrimaryRatePlans"
                            :show="showRates"
                            :unit="unit"
                            :unselected="removeRatePlanList"
                            :show-platform-fee-disclaimer="showPlatformFeeDisclaimer"
                            :class="{'main_calendar_test-bb': !showRates}">
                        </table-rates-sidebar>
                        <table-rates-los-and-occupancy-sidebar
                            v-if="showRates && (additional_data.los.length > 1 || additional_data.occupancy.length > 1)"
                            :los="additional_data.los"
                            :occupancy="additional_data.occupancy"></table-rates-los-and-occupancy-sidebar>
                        <div v-for="ratePlan in ratePlanPricingPreview"
                             v-if="showRates && !removeRatePlanList.includes(ratePlan.id)">
                            <table-rate-plan-header :occupancy="additional_data.occupancy" :los="additional_data.los"
                                                    @remove="removeRateByRatePlan" :rate-plan="ratePlan"
                                                    :show-platform-fee="showPlatformFee"
                                                    @editRP="editRatePlan(ratePlan.id)"></table-rate-plan-header>

                            <table-rate-plan-sidebar-item :key="'pricing_'+ratePlan.id" :rate-plan="ratePlan"
                                                          :los="additional_data.los"
                                                          :occupancy="additional_data.occupancy"
                                                          :class="{'main_calendar_test-bb': true}"
                            ></table-rate-plan-sidebar-item>
                        </div>
                    </template>
                    <template v-if="previewRestrictions">
                        <table-restriction-sidebar
                            :unit="unit"
                            :unselected="removeRatePlanForRestriction"
                            @click="handleRestrictionData"
                            :show="showRestrictions"
                            :rate-plans="nonPrimaryRatePlans"
                            @updateRatePlanListRestrictions="setRatePlanListRestrictions"
                        ></table-restriction-sidebar>
                        <template v-if="showRestrictions">
                            <table-restriction-type-list-sidebar
                            ></table-restriction-type-list-sidebar>
                            <template v-for="(rPlan, ratePlanIndexPr2) in ratePlanRestrictionPreview"
                                      v-if="!removeRatePlanForRestriction.includes(rPlan.id)">
                                <table-rate-plan-header :show_type="false" @remove="removeRestrictionByRatePlan"
                                                        @editRP="editRatePlan(rPlan.id)"
                                                        :show-platform-fee="false"
                                                        :rate-plan="rPlan"></table-rate-plan-header>
                                <table-restriction-type-rate-plan-sidebar :rate-plan="rPlan"
                                                                          :key="'trtrps_' + rPlan.id"
                                                                          :class="{'main_calendar_test-bb': ratePlanIndexPr2 !== ratePlanRestrictionPreview.length -1}"
                                ></table-restriction-type-rate-plan-sidebar>
                            </template>
                        </template>
                    </template>
                </div>
                <div class="main_calendar_test__holder--content"
                     ref="calendar_container">
                    <table-dates :unit="mainUnit" :scroll-left-position="scrollLeftPosition"
                                 :date-list="dateArray"></table-dates>

                    <table-scroll-row :scroll-left-position="scrollLeftPosition"

                                      :date-list="dateArray" :unit="mainUnit"></table-scroll-row>
                    <template v-if="previewAvailability">
                        <table-availability-content :dates="dateArray"
                                                    :unit="mainUnit"
                                                    :contigent="contigentUnits"
                                                    :is-root="true"
                                                    :scroll-left-position="scrollLeftPosition"
                                                    :availability="calendar_values.availability"
                                                    :reservations="getReservationsForUnit(calendar_values.reservation, this.unit.id, true)"
                                                    :shiftKeyValue="shiftKeyValue"
                                                    :add-months="loadAdditionalMonths"
                                                    :class="{'main_calendar_test-bb': !showAvailability}"
                                                    :date-range="dateRange"></table-availability-content>
                        <template v-if="contigentUnits.length && showAvailability">
                            <table-availability-content v-if="additional_data.contigent.length"
                                                        v-show="showAvailability"
                                                        :dates="dateArray"
                                                        :unit="contUnit"
                                                        :scroll-left-position="scrollLeftPosition"
                                                        :contigent="contigentUnits"
                                                        :availability="calendar_values.availability"
                                                        :reservations="getReservationsForUnit(calendar_values.reservation, contUnit.id, false)"
                                                        :shiftKeyValue="shiftKeyValue"
                                                        :is-root="false"
                                                        :add-months="loadAdditionalMonths"
                                                        :key="'av_cont_' + contUnit.id"
                                                        :date-range="dateRange"
                                                        :class="{'main_calendar_test-bb': contUnitIndex === additional_data.contigent.length -1}"
                                                        v-for="(contUnit, contUnitIndex) in additional_data.contigent">
                            </table-availability-content>
                        </template>
                    </template>
                    <template v-if="previewPrices">
                        <table-rates-default-los-and-occupancy-content
                            :scroll-left-position="scrollLeftPosition"
                            :shiftKeyValue="shiftKeyValue"
                            :los="additional_data.los"
                            :occupancy="additional_data.occupancy"
                            :pricing="calendar_values.pricing"
                            :suggestions="calendar_values.suggestion"
                            :unit="mainUnit"
                            :ratePlan="mainUnit.rate_plan"
                            :date-range="dateRange"
                            :date-list="dateArray"
                            :pricing-defaults="calendar_values.pricing_defaults"
                        ></table-rates-default-los-and-occupancy-content>
                        <template v-if="showRates">
                            <table-rates-los-and-occupancy-content
                                v-if="additional_data.los.length > 1 || additional_data.occupancy.length > 1"
                                :scroll-left-position="scrollLeftPosition"
                                :shiftKeyValue="shiftKeyValue"
                                :los="additional_data.los"
                                :occupancy="additional_data.occupancy"
                                :pricing="calendar_values.pricing"
                                :suggestions="calendar_values.suggestion"
                                :unit="mainUnit"
                                :ratePlan="mainUnit.rate_plan"
                                :date-range="dateRange"
                                :date-list="dateArray"
                                :pricing-defaults="calendar_values.pricing_defaults">
                            </table-rates-los-and-occupancy-content>
                            <div v-for="ratePlan in ratePlanPricingPreview"
                                 v-if="!removeRatePlanList.includes((ratePlan.id))">
                                <div v-if="additional_data.los.length > 1 || additional_data.occupancy.length > 1"
                                     class="main_calendar_test main_calendar_test-empty-row main_calendar_test-bb"></div>
                                <table-rates-los-and-occupancy-content
                                    :scroll-left-position="scrollLeftPosition"
                                    :shiftKeyValue="shiftKeyValue"
                                    :key="'trlaocc_'+ratePlan.id"
                                    :los="additional_data.los"
                                    :occupancy="additional_data.occupancy"
                                    :pricing="calendar_values.pricing"
                                    :unit="mainUnit"
                                    :ratePlan="ratePlan.id"
                                    :date-range="dateRange"
                                    :date-list="dateArray"
                                    :class="{'main_calendar_test-bb':true}"
                                    :pricing-defaults="calendar_values.pricing_defaults"
                                >
                                </table-rates-los-and-occupancy-content>
                            </div>
                        </template>
                    </template>
                    <template v-if="previewRestrictions">
                        <table-restriction-content-min-stay
                            :date-list="dateArray"
                            :scroll-left-position="scrollLeftPosition"
                            :shiftKeyValue="shiftKeyValue"
                            :date-range="formattedFullDateRange"
                            :restrictions="calendar_values.restriction"
                            :suggestions="calendar_values.suggestion"
                            :unit="mainUnit"
                            :ratePlan="mainUnit.rate_plan"
                        ></table-restriction-content-min-stay>
                        <template v-if="showRestrictions">
                            <table-restriction-content :date-list="dateArray"
                                                       :scroll-left-position="scrollLeftPosition"
                                                       :shiftKeyValue="shiftKeyValue"
                                                       :date-range="formattedFullDateRange"
                                                       :restrictions="calendar_values.restriction"
                                                       :suggestions="calendar_values.suggestion"
                                                       :unit="mainUnit"
                                                       :ratePlan="mainUnit.rate_plan"></table-restriction-content>
                            <template v-for="(rPlan, ratePlanIndexPr2) in ratePlanRestrictionPreview"
                                      v-if="!removeRatePlanForRestriction.includes(rPlan.id)">
                                <div
                                    class="main_calendar_test main_calendar_test-empty-row main_calendar_test-bb"></div>
                                <table-restriction-content
                                    :shiftKeyValue="shiftKeyValue"
                                    :scroll-left-position="scrollLeftPosition"
                                    :key="'trc_'+ rPlan.id"
                                    :class="{'main_calendar_test-bb': ratePlanIndexPr2 !== ratePlanRestrictionPreview.length -1}"
                                    :date-list="dateArray"
                                    :date-range="formattedFullDateRange"
                                    :restrictions="calendar_values.restriction"
                                    :unit="mainUnit"
                                    :ratePlan="rPlan.id"
                                    :rate-plan-object="rPlan"
                                ></table-restriction-content>
                            </template>
                        </template>
                    </template>
                    <div class="border-0 main_calendar_test__holder--content-scroll"></div>
                </div>
            </div>
        </div>
        <app-aside v-if="checkPermission(C_UNIT_CALENDAR_OCCUPANCY_E) || checkPermission(C_UNIT_CALENDAR_LOS_E)"
                   :widths="['col-lg-5', 'col-sm-12', '50%']" v-model="pricingSetupAside"
                   @input="onClosePricingSetupAside">
            <template slot="header"><app-object-header :name="$t('EDIT_RANGES')"></app-object-header></template>
            <div>
                <b-tabs nav-class="tab_navigation-horizontal" content-class="tab_content-horizontal" pills
                        v-model="pricingSetupTab" justified>
                    <b-tab v-if="checkPermission(C_UNIT_CALENDAR_LOS_E)" :title="$t('LENGTH_OF_STAY')">
                        <unit-los-list @update="getAddData" :los-list="additional_data.los"
                                       :unit="unit"></unit-los-list>
                    </b-tab>
                    <b-tab v-if="checkPermission(C_UNIT_CALENDAR_OCCUPANCY_E)" :title="$t('OCCUPANY_PERSONS')">
                        <unit-occupancy-list @update="getAddData" :unit="unit"
                                             :occupancy-list="additional_data.occupancy"></unit-occupancy-list>
                    </b-tab>
                </b-tabs>
            </div>
        </app-aside>
        <app-aside v-if="previewSuggestions && selectedSuggestion && selectedSuggestionReportId"
                   :widths="['col-lg-6', 'col-sm-12', '50%']" v-model="suggestionSetupAside">
            <template slot="header">{{ unit.unit_type.name }}</template>
            <rate-manager-suggestion-table
                select-all
                :initial-search="{unit: unit.id, report: selectedSuggestionReportId, status: [RM_SUGGESTION_STATUS_ACTIVE, RM_SUGGESTION_STATUS_CHANGED]}"
                :omitted-fields="['unit']">
            </rate-manager-suggestion-table>
        </app-aside>
        <calendar-edit-form
            mode="calendar-item-edit"
            :root-unit="unit"
            :rate-plans="additional_data.rate_plan"
            :los="additional_data.los"
            :occupancy="additional_data.occupancy"
            :elements="elements"
            :property-id="property && property.hasOwnProperty('id') ? property.id : null"
            :single-property="true"
            :root-units="rootUnitList">
        </calendar-edit-form>
        <app-aside v-if="checkPermission(C_UNIT_CALENDAR_YEARLY_VIEW_V)" :widths="['col-lg-12','col-sm-12', '100%']"
                   full-width
                   v-model="yearlyAside">
            <template slot="header">
                <div class="header-caption">{{ $t('YEAR_CALENDAR') }} - {{ bulkData.currentYear }}</div>
                <div class="d-flex justify-content-between">
                    <h2>{{ unit.property.name }}</h2>
                </div>
            </template>
            <template slot="header_action_button">
                <div class="aside__header--action_button col-2 mr-3">
                    <label class="label">{{ $t('UNIT') }}</label>
                    <app-select mode="select" :options="unitList" v-model="selectedUnit.id"></app-select>
                </div>
            </template>
            <year-calendar-holder :unit="bulkData.unit" :is-contigent="bulkData.is_contigent"
                                  :year="bulkData.currentYear"></year-calendar-holder>
        </app-aside>
        <app-aside v-model="ratePlanAside">
            <template slot="header">{{ $t('RATE_PLAN') }}</template>
            <update-rate-plan-form :id="editRateId" @ratePlanSaved="updateRateSuccess($event)"></update-rate-plan-form>
        </app-aside>
        <app-aside :widths="['col-lg-12','col-sm-12', '100%']" full-width
                   v-model="revenueManagerSetupPanelState">
            <template slot="header">
                <div class="header-caption">{{ $t('REVENUE_MANAGER') }}</div>
                <div class="d-flex justify-content-between">
                    <h2>{{ unit.unit_type.name }}</h2>
                </div>
            </template>
            <revenue-manager-unit-action-form
                :preview-tab="previewTab"
                :property-object="revenueManagerProperty"
                :unit-object="revenueManagerUnit">
            </revenue-manager-unit-action-form>
        </app-aside>
        <app-recalculate-rates-modal
            v-model="modalShow"
            @confirm="updateLosOccupancy"
            @cancel="modalShow = false"
            :loading="loader">
        </app-recalculate-rates-modal>
    </div>
</template>
<script>
import moment from 'moment'
import TableAvailabilitySidebar from "@/components/unit/calendar_opt/Table/Availability/TableAvailabilitySidebar";
import TableDates from "@/components/unit/calendar_opt/Table/Dates/TableDates";
import TableAvailabilityContent from "@/components/unit/calendar_opt/Table/Availability/TableAvailabilityContent";
import TableRatesSidebar from "@/components/unit/calendar_opt/Table/Rates/TableRatesSidebar";
import TableRatesLosAndOccupancySidebar
    from "@/components/unit/calendar_opt/Table/Rates/TableRatesLosAndOccupancySidebar";
import TableRatesLosAndOccupancyContent
    from "@/components/unit/calendar_opt/Table/Rates/TableRatesLosAndOccupancyContent";
import TableRateplanSidebar from "@/components/unit/calendar_opt/Table/Rates/TableRateplanSidebar";
import TableRatePlanSidebarItem from "@/components/unit/calendar_opt/Table/Rates/TableRatePlanSidebarItem";
import TableRestrictionSidebar from "@/components/unit/calendar_opt/Table/Restrictions/TableRestrictionSidebar";
import {DIRECT_BOOKER_COMPANY, SALES_ENABLED, WORKFLOW_DIRECT_BOOKER} from "@/shared/constants";
import TableRestrictionContent from "@/components/unit/calendar_opt/Table/Restrictions/TableRestrictionContent";
import TableRestrictionTypeListSidebar
    from "@/components/unit/calendar_opt/Table/Restrictions/TableRestrictionTypeListSidebar";
import TableRestrictionTypeRatePlanSidebar
    from "@/components/unit/calendar_opt/Table/Restrictions/TableRestrictionTypeRatePlanSidebar";
import TableUnitStatusSidebar from "@/components/unit/calendar_opt/Table/UnitStatus/TableUnitStatusSidebar";
import TableUnitStatusContent from "@/components/unit/calendar_opt/Table/UnitStatus/TableUnitStatusContent";
import {
    C_PROPERTY_CALENDAR_AVAILIBILITY_V,
    C_PROPERTY_CALENDAR_PRICES_V,
    C_PROPERTY_CALENDAR_RESTRICTIONS_V,
    C_PROPERTY_CALENDAR_RM_SUGGESTION_V,
    C_PROPERTY_CALENDAR_ROOM_STATUS_V,
    C_UNIT_CALENDAR_OCCUPANCY_E,
    C_UNIT_CALENDAR_LOS_E,
    C_UNIT_CALENDAR_YEARLY_VIEW_V,
    C_PROPERTY_CALENDAR_REVENUE_MANAGER_SETUP,
    C_UNIT_CALENDAR_PLATFORM_FEE_V,
    C_UNIT_CALENDAR_PLATFORM_FEE_DISCLAIMER,
} from "@/shared/component_permission";
import {
    EventBus,
    GE_CALENDAR_LOAD_PRICES,
    GE_CALENDAR_LOAD_RESTRICTIONS,
    GE_CALENDAR_OPEN_AVAILABILITY,
    GE_CALENDAR_OPEN_PRICING,
    GE_CALENDAR_LOAD_AVAILABILITY,
    GE_CALENDAR_REFRESH_DATA,
    GE_SHOW_SUGGESTIONS_ASIDE,
    GE_CALENDAR_LOAD_ROOM_STATUS, GE_LOADER_SHOW, GE_LOADER_HIDE
} from "@/shared/EventBus";
import AppAside from "@/components/app/form/AppAside";
import UnitLosList from "@/components/unit/calendar/forms/los/UnitLosList";
import UnitOccupancyList from "@/components/unit/calendar/forms/occupancy/UnitOccupancyList";
import CalendarAside from "@/components/unit/calendar_opt/Aside/Calendar/CalendarAside";
import AppButton from "@/components/app/AppButton/AppButton";
import TableDateEmptyRow from "@/components/unit/calendar_opt/Table/Dates/TableDateEmptyRow";

import {CalendarMixin} from '@/mixins/calendar/CalendarMixin'
import RateManagerSuggestionTable from "@/components/rate_manager/suggestions/RateManagerSuggestionTable";
import {RM_SUGGESTION_STATUS_ACTIVE, RM_SUGGESTION_STATUS_CHANGED} from "@/shared/constants";
import TableScrollRow from "@/components/unit/calendar_opt/Table/Dates/TableScrollRow";
import TableYearCell from "@/components/unit/calendar_opt/Table/Dates/TableYearCell";
import YearCalendarHolder from "@/components/unit/calendar_opt/Table/YearCalendar/YearCalendarHolder";
import TableRatesDefaultLosAndOccupancyContent
    from "@/components/unit/calendar_opt/Table/Rates/TableRatesDefaultLosAndOccupancyContent";
import TableRestrictionContentMinStay
    from "@/components/unit/calendar_opt/Table/Restrictions/TableRestrictionContentMinStay";
import TableRatePlanHeader from "@/components/unit/calendar_opt/Table/Rates/TableRatePlanHeader";
import UpdateRatePlanForm from "@/components/pricing/rate_plan/UpdateRatePlanForm";
import {getUnitList} from "@/services/unit";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import RevenueManagerUnitActionForm from "@/components/revenue_manager/setup/unit/RevenueManagerUnitActionForm";
import {getRevenueManagerSetupPropertyList, searchRmUnits} from "@/services/revenue_manager";
import {
    setPriceFromLosOccupancy,
} from "@/services/pricing";
import {notifySuccess} from "@/shared/plugins/toastr";
import {cloneDeep} from "lodash";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import CalendarEditForm from "@/components/calendar/aside/CalendarEditForm";
import AppRecalculateRatesModal from "@/components/app/modal/AppRecalculateRatesModal";

export default {
    name: "MainCalendarTable",
    mixins: [CalendarMixin],
    components: {
        AppRecalculateRatesModal,
        CalendarEditForm,
        AppSelect,
        UpdateRatePlanForm,
        TableRatePlanHeader,
        TableRestrictionContentMinStay,
        TableRatesDefaultLosAndOccupancyContent,
        YearCalendarHolder,
        TableYearCell,
        TableScrollRow,
        RateManagerSuggestionTable,
        TableDateEmptyRow,
        AppButton,
        CalendarAside,
        UnitOccupancyList,
        UnitLosList,
        TableUnitStatusContent,
        TableUnitStatusSidebar,
        TableRestrictionTypeRatePlanSidebar,
        TableRestrictionTypeListSidebar,
        TableRestrictionContent,
        TableRestrictionSidebar,
        TableRatePlanSidebarItem,
        TableRateplanSidebar,
        TableRatesLosAndOccupancyContent,
        TableRatesLosAndOccupancySidebar,
        TableRatesSidebar,
        TableAvailabilityContent,
        TableDates,
        TableAvailabilitySidebar,
        AppAside,
        RevenueManagerUnitActionForm,
        AppObjectHeader
    },
    props: {
        unit: {
            type: Object
        },
        elements: {
            type: Array
        },
        dateRange: {
            type: Object
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        property: {
            type: Object
        },
        scrollLeftPosition: {
            type: Number
        },
        loadAdditionalMonths: {
            type: Number,
            default: 0
        },
        dateArray: {
            type: Array,
            default() {
                return []
            }
        },
        rootUnitList: {
            type: Array,
            default: () => {
                return null
            },
        },
    },
    data() {
        return {
            los_list: [],
            occupancy_list: [],
            shownRatePlanList: [],
            shownRatePlanRestrictionList: [],
            showAvailability: false,
            showRates: false,
            showRestrictions: false,
            SALES_ENABLED,
            C_UNIT_CALENDAR_OCCUPANCY_E,
            C_UNIT_CALENDAR_LOS_E,
            C_UNIT_CALENDAR_YEARLY_VIEW_V,
            pricingSetupAside: false,
            suggestionSetupAside: false,
            selectedSuggestion: null,
            selectedSuggestionReportId: null,
            pricingSetupTab: 1,
            restrictionListLoaded: false,
            rateListLoaded: false,
            shiftKeyValue: false,
            RM_SUGGESTION_STATUS_ACTIVE,
            RM_SUGGESTION_STATUS_CHANGED,
            resData: {
                unit: 0,
                property: null,
                dateRange: null
            },
            bulkData: {},
            yearlyAside: false,
            removeRatePlanList: [],
            removeRatePlanForRestriction: [],
            ratePlanAside: false,
            editRateId: null,
            expand_los_occ: true,
            unitList: [],
            selectedUnit: {id: null, name: null},
            revenueManagerSetupPanelState: false,
            previewTab: null,
            revenueManagerProperty: {},
            revenueManagerUnit: {},
            revenueManagerOptions: {},
            modalShow: false,
            previous_los_list: [],
            previous_occupancy_list: [],
            relevantChanges: false,
            loader: false,
            updatedRangesList: {},
        }
    },
    computed: {
        ratePlanPricingPreview() {
            if (this.shownRatePlanList.length === 0) {
                return []
            }
            return this.additional_data.rate_plan.filter(item => this.shownRatePlanList.includes(item.id) && this.mainUnit.rate_plan !== item.id)
        },
        ratePlanRestrictionPreview() {
            if (this.shownRatePlanRestrictionList.length === 0) {
                return []
            }
            return this.nonPrimaryRatePlans.filter(item => this.shownRatePlanRestrictionList.includes(item.id) && this.mainUnit.rate_plan !== item.id)
        },
        previewAvailability() {
            return this.elements.includes(C_PROPERTY_CALENDAR_AVAILIBILITY_V)
        },
        previewRoomStatus() {
            return this.elements.includes(C_PROPERTY_CALENDAR_ROOM_STATUS_V)
        },
        previewPrices() {
            return this.elements.includes(C_PROPERTY_CALENDAR_PRICES_V)
        },
        previewRestrictions() {
            return this.elements.includes(C_PROPERTY_CALENDAR_RESTRICTIONS_V)
        },
        previewSuggestions() {
            return this.elements.includes(C_PROPERTY_CALENDAR_RM_SUGGESTION_V)
        },
        formattedFullDateRange() {
            if (this.loadAdditionalMonths) {
                return {
                    start: this.dateRange.start,
                    end: moment(this.dateRange.end).add(this.loadAdditionalMonths, 'months').format("YYYY-MM-DD"),
                }
            }
            return this.dateRange
        },
        loadDisplayedValues() {
            let returnValues = []
            let actions = []
            returnValues[this.calendarActions.additional_data] = true
            if (this.previewAvailability) {
                actions.push(this.calendarActions.availability)
                actions.push(this.calendarActions.reservation)
            }
            if (this.previewSuggestions) {
                actions.push(this.calendarActions.standard_report)
                actions.push(this.calendarActions.on_the_fly_report)
            }
            if ((this.previewRoomStatus || this.previewAvailability) && !returnValues.includes(this.calendarActions.availability)) {
                //       actions.push(this.calendarActions.availability)
            }
            if (this.previewPrices && this.rateListLoaded && !returnValues.includes(this.calendarActions.rate)) {
                actions.push(this.calendarActions.rate)
            }
            if (this.previewPrices && !actions.includes(this.calendarActions.rate)) {
                actions.push(this.calendarActions.rate_default)
            }
            if (this.previewRestrictions && this.restrictionListLoaded && !returnValues.includes(this.calendarActions.restriction)) {
                actions.push(this.calendarActions.restriction)
            }
            if (this.previewRestrictions && !actions.includes(this.calendarActions.restriction)) {
                actions.push(this.calendarActions.restriction_min_stay)
            }
            if (this.shownRatePlanList.length || this.shownRatePlanRestrictionList.length) {
                returnValues[this.calendarActions.rate_plan] = [...this.shownRatePlanList, ...this.shownRatePlanRestrictionList]
            }
            returnValues["contigent_mode"] = 1
            returnValues["rate_plan"] = [...[this.unit.primary_rate_plan.id], ...this.shownRatePlanList, ...this.shownRatePlanRestrictionList]
            return {
                actions: actions,
                ...returnValues
            }
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        showPlatformFee() {
            return this.checkPermission(C_UNIT_CALENDAR_PLATFORM_FEE_V) && this.company.id === DIRECT_BOOKER_COMPANY
        },
        showPlatformFeeDisclaimer() {
            return this.checkPermission(C_UNIT_CALENDAR_PLATFORM_FEE_DISCLAIMER) && this.company.id === DIRECT_BOOKER_COMPANY
        },
    },
    methods: {
        onClosePricingSetupAside() {
            if (this.relevantChanges === true) {
                this.modalShow = true
            }
        },
        prepareRequest(overrideAll) {
            return {
                previous_los_list: this.previous_los_list,
                previous_occupancy_list: this.previous_occupancy_list,
                override_all: overrideAll
            }
        },
        updateLosOccupancy(payload) {
            if (!payload.recalculate) {
                this.modalShow = false
            } else {
                this.loader = true
                let request = this.prepareRequest(payload.override_all)
                setPriceFromLosOccupancy(this.unit.id, request)
                    .then(() => {
                            EventBus.$emit(GE_LOADER_SHOW)
                            notifySuccess()
                        },
                        error => {
                            this.showErrorMessages(error)
                        }).finally(() => {
                    EventBus.$emit(GE_CALENDAR_REFRESH_DATA, {unitId: this.unit.id})
                    EventBus.$emit(GE_LOADER_HIDE)
                    this.loader = false
                })
            }
            this.relevantChanges = false
        },
        updateRateSuccess(rate_plan_id) {
            this.ratePlanAside = false
            this.fetchData({
                date_from: this.dateRange.start,
                date_to: this.dateRange.end,
                params: {
                    actions: [this.calendarActions.restriction, this.calendarActions.rate],
                    additional_data: true,
                    rate_plan: [rate_plan_id]
                }
            })
        },
        editRatePlan(value) {
            this.editRateId = value
            this.ratePlanAside = true
        },
        removeRateByRatePlan(rate_plan_id) {
            this.removeRatePlanList.push(rate_plan_id)
        },
        removeRestrictionByRatePlan(rate_plan_id) {
            this.removeRatePlanForRestriction.push(rate_plan_id)
        },
        openYearAside(data) {
            this.bulkData = data
            this.yearlyAside = true
            this.selectedUnit.id = data.unit.id
        },
        showAvailabilityEvent() {
            this.showAvailability = !this.showAvailability
        },
        getAddData(payload) {
            if (payload.hasOwnProperty("los_list")) {
                this.$set(this.updatedRangesList, "los_list", payload.los_list)
            }
            if (payload.hasOwnProperty("occupancy_list")) {
                this.$set(this.updatedRangesList, "occupancy_list", payload.occupancy_list)
            }
            this.relevantChanges = this.relevantChanges || payload.changes.price_relevant_change
            this.fetchData({
                date_from: this.formattedFullDateRange.start,
                date_to: this.formattedFullDateRange.end,
                params: {
                    additional_data: true
                }
            })
        },
        handleRestrictionData() {
            this.showRestrictions = !this.showRestrictions
            if (this.showRestrictions && !this.restrictionListLoaded) {
                this.fetchData({
                    date_from: this.formattedFullDateRange.start,
                    date_to: this.formattedFullDateRange.end,
                    params: {
                        actions: [this.calendarActions.restriction],
                        additional_data: true,
                        rate_plan: [...[this.unit.primary_rate_plan.id], ...this.shownRatePlanRestrictionList]
                    }
                })
            }
        },
        setRatePlanListPrices(value) {
            this.removeRatePlanList = []
            this.shownRatePlanList = value
            this.showRates = true
            this.fetchData({
                date_from: this.formattedFullDateRange.start,
                date_to: this.formattedFullDateRange.end,
                params: {
                    actions: [this.calendarActions.rate],
                    rate_plan: value
                }
            })
        },
        setRatePlanListRestrictions(value) {
            this.shownRatePlanRestrictionList = value
            this.removeRatePlanForRestriction = []
            this.fetchData({
                date_from: this.formattedFullDateRange.start,
                date_to: this.formattedFullDateRange.end,
                params: {
                    actions: [this.calendarActions.restriction],
                    additional_data: true,
                    rate_plan: this.shownRatePlanRestrictionList,
                    reset: false
                }
            })
        },
        handlePricingSetupOpen(tab) {
            this.pricingSetupTab = tab
            this.pricingSetupAside = true
        },
        getReservationsForUnit(reservations, unit, isRoot) {
            return isRoot && this.contigentUnits.length > 0 ? [] : reservations.filter(res => res.unit === unit)
        },
        openRevenueManagerSetup(unitId) {
            this.previewTab = this.company.workflow_id === WORKFLOW_DIRECT_BOOKER ? 3 : 2
            searchRmUnits({unit: unitId}).then((response) => {
                this.revenueManagerUnit = response.data.items.length === 1 ? response.data.items[0] : null
            }).finally(() => {
                this.revenueManagerSetupPanelState = true
            })
        },
        shiftKeyDown(e) {
            if (e.key === 'Shift') {
                this.shiftKeyValue = true
            }
        },
        shiftKeyUp(e) {
            if (e.key === 'Shift') {
                this.shiftKeyValue = false
            }
        },
    },
    watch: {
        selectedUnit: {
            handler(object) {
                let unitObject = this.unitList.find(unit => {
                    return unit.id === object.id
                })
                this.bulkData.unit = unitObject
                if (unitObject) {
                    this.bulkData.is_contigent = !(unitObject.contigent.is_contigent === 1)
                    this.selectedUnit.id = object.id
                }
            },
            immediate: true,
            deep: true
        },
        dateRange: {
            handler(value) {
                this.fetchData({
                    date_from: value.start,
                    date_to: value.end,
                    params: this.loadDisplayedValues,
                    reset: false
                })
            },
            deep: true
        },
        additional_data: {
            handler(object) {
                if (object.hasOwnProperty('los') && object.hasOwnProperty('occupancy')) {
                    if (!this.updatedRangesList.hasOwnProperty("los_list")) {
                        this.previous_los_list = cloneDeep(this.additional_data.los)
                    }
                    if (!this.updatedRangesList.hasOwnProperty("occupancy_list")) {
                        this.previous_occupancy_list = cloneDeep(this.additional_data.occupancy)
                    }
                    if (object.los.length === 1 && object.occupancy.length === 1) {
                        this.expand_los_occ = false
                        return
                    }
                }
                this.expand_los_occ = true
            },
            immediate: true,
            deep: true
        },
        modalShow: {
            handler(value) {
                if (!value) {
                    if (this.updatedRangesList.hasOwnProperty("los_list")) {
                        this.previous_los_list = this.updatedRangesList.los_list
                    }
                    if (this.updatedRangesList.hasOwnProperty("occupancy_list")) {
                        this.previous_occupancy_list = this.updatedRangesList.occupancy_list
                    }
                    this.relevantChanges = false
                }
            },
        },
    },
    created() {
        this.loaded = false
        this.fetchData({
            date_from: this.dateRange.start,
            date_to: this.dateRange.end,
            params: this.loadDisplayedValues,
        })
    },
    mounted() {
        window.addEventListener('keydown', this.shiftKeyDown)

        window.addEventListener('keyup', this.shiftKeyUp)

        EventBus.$on(GE_CALENDAR_LOAD_AVAILABILITY, (data) => {
            if (data.hasOwnProperty('unitId') && data.unitId === this.unit.id) {
                this.fetchData({
                    date_from: this.dateRange.start,
                    date_to: this.dateRange.end,
                    params: {
                        actions: [this.calendarActions.availability],
                        contigent_mode: 1
                    },
                    reset: false
                })
            }
        })

        EventBus.$on(GE_CALENDAR_REFRESH_DATA, (data) => {
            if (data.hasOwnProperty('unitId') && data.unitId === this.unit.id) {
                this.fetchData({
                    date_from: this.dateRange.start,
                    date_to: this.dateRange.end,
                    params: {
                        actions: [this.calendarActions.availability, this.calendarActions.reservation, this.calendarActions.rate, this.calendarActions.restriction],
                        rate_plan: [...[this.unit.primary_rate_plan.id], ...this.shownRatePlanList],
                        contigent_mode: 1
                    },
                    reset: true
                })
            }
        })

        EventBus.$on(GE_CALENDAR_OPEN_PRICING, (data) => {
            if (data.unitId === this.unit.id) {
                if (data.hasOwnProperty('show')) {
                    if (data.show !== null) {
                        this.showRates = data.show
                    }
                } else {
                    this.showRates = !this.showRates
                }
                if (this.showRates && !this.rateListLoaded) {
                    this.fetchData({
                        date_from: this.formattedFullDateRange.start,
                        date_to: this.formattedFullDateRange.end,
                        params: {
                            additional_data: true,
                            actions: [this.calendarActions.rate],
                            rate_plan: [...[this.unit.primary_rate_plan.id], ...this.shownRatePlanList]
                        },
                        reset: false
                    })
                }
            }
        })

        EventBus.$on(GE_CALENDAR_LOAD_PRICES, (data) => {
            if (data.unitId === this.unit.id) {
                this.fetchData({
                    date_from: this.formattedFullDateRange.start,
                    date_to: this.formattedFullDateRange.end,
                    params: {
                        actions: [this.calendarActions.rate],
                        rate_plan: [...[this.unit.primary_rate_plan.id], ...this.shownRatePlanList]
                    },
                    reset: false
                })
            }
        })

        EventBus.$on(GE_CALENDAR_LOAD_RESTRICTIONS, (data) => {
            if (data.unitId === this.unit.id) {
                this.fetchData({
                    date_from: this.formattedFullDateRange.start,
                    date_to: this.formattedFullDateRange.end,
                    params: {
                        actions: [this.calendarActions.restriction],
                        rate_plan: [...[this.unit.primary_rate_plan.id], ...this.shownRatePlanRestrictionList]
                    },
                    reset: false
                })
            }
        })

        EventBus.$on(GE_SHOW_SUGGESTIONS_ASIDE, (data) => {
            if (data.unitId === this.unit.id) {
                this.selectedSuggestion = data.suggestion
                this.selectedSuggestionReportId = data.reportId
                this.suggestionSetupAside = true
            }
        })

        if (this.checkPermission(C_PROPERTY_CALENDAR_REVENUE_MANAGER_SETUP)) {
            const revenueManagerPropertiesSetupRequest = {
                property: this.property && typeof this.property === 'object' && this.property.hasOwnProperty('id') && this.property.id ? this.property.id : this.$route.params.id,
                perPage: 1000,
            }
            getRevenueManagerSetupPropertyList(revenueManagerPropertiesSetupRequest).then(response => {
                if (response.data.items.length === 1) {
                    const propertyObject = response.data.items[0]
                    this.revenueManagerProperty = propertyObject
                    const ratesActivated = propertyObject.rates_activated === 1
                    const minStayActivated = propertyObject.min_stay_activated === 1
                    this.$set(this.revenueManagerOptions, 'enabled', true)
                    this.$set(this.revenueManagerOptions, 'rates_activated', ratesActivated)
                    this.$set(this.revenueManagerOptions, 'min_stay_activated', minStayActivated)
                }
            })
        }

        if (this.rootUnitList && Array.isArray(this.rootUnitList) && this.rootUnitList.length > 0) {
            this.unitList = this.rootUnitList.map(unit => {
                return {
                    ...unit, ...{name: unit.unit_type && unit.unit_type.name ? unit.unit_type.name : unit.name}
                }
            })
        } else {
            getUnitList({property_id: this.property.id, limit: 1000, contigent: 0}).then(response => {
                this.unitList = response.data.items.map(item => {
                    return {
                        ...item, ...{name: item.unit_type.name}
                    }
                })
            }, () => {
                this.unitList = []
            })
        }
    },
    beforeDestroy() {
        EventBus.$off(GE_CALENDAR_OPEN_AVAILABILITY)
        EventBus.$off(GE_CALENDAR_LOAD_ROOM_STATUS)
        EventBus.$off(GE_CALENDAR_REFRESH_DATA)
        window.removeEventListener("keyup", this.shiftKeyUp)
        window.removeEventListener("keydown", this.shiftKeyDown)
    },
}
</script>

<style scoped>

</style>
