<template>
    <b-form @submit.prevent="save" v-if="checkPermission(C_COMPANY_BASIC_V)">
        <b-row>
            <b-col md="6" lg="4" class="mb-4">
                <app-input-control :error-object="validation.name">
                    <template v-slot:input="data">
                        <label>{{$t('NAME')}}</label>
                        <b-form-input
                            type="text"
                            v-model="formData.name" :state="data.state">
                        </b-form-input>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="4" class="mb-4">
                <app-input-control :error-object="validation.person_type">
                    <template v-slot:input="data">
                        <label>{{$t('ENTITY_TYPE')}}</label>
                        <app-select mode="search" :search="searchPersonType"
                                    search-on-create
                                    :search-empty-item="false"
                                    text-field="label"
                                    v-model="formData.person_type"
                                    :state="data.state">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="4" class="mb-4">
                <app-input-control :error-object="validation.type">
                    <template v-slot:input="data">
                        <label>{{$t('TYPE')}}</label>
                        <app-select mode="search"
                                    v-model="formData.tenant_type"
                                    :search="searchTenantType"
                                    text-field="label"
                                    :search-on-create="true" disabled :state="data.state"></app-select>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="4" class="mb-4">
                <app-input-control :error-object="validation.registration_number">
                    <template v-slot:input="data">
                        <label>{{$t('REGISTRATION_NUMBER')}}</label>
                        <b-form-input
                            :state="data.state"
                            type="text"
                            v-model="formData.registration_number">
                        </b-form-input>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="4" class="mb-4">
                <app-input-control :error-object="validation.agency_id">
                    <template v-slot:input="data">
                        <label>{{$t('AGENCY_ID')}}</label>
                        <b-form-input
                            :state="data.state"
                            type="text"
                            v-model="formData.agency_id_code">
                        </b-form-input>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="4" class="mb-4">
                <app-input-control :error-object="validation.legal_representative">
                    <template v-slot:input="data">
                        <label>{{$t('LEGAL_REPRESENTATIVE')}}</label>
                        <b-form-input
                            :state="data.state"
                            type="text"
                            v-model="formData.legal_representative">
                        </b-form-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <form-header>{{$t('FINANCE')}}</form-header>
            </b-col>
        </b-row>

        <b-row>
            <b-col md="6" lg="3" class="mb-4">
                <app-input-control :error-object="validation.vat_id">
                    <template v-slot:input="data">
                        <label>{{$t('VAT_NUMBER')}}</label>
                        <b-form-input :state="data.state" type="text" v-model="formData.vat_id"></b-form-input>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="3" class="mb-4">
                <app-input-control :error-object="validation.billing_account">
                    <template v-slot:input="data">
                        <label>{{ $t('BILLING_ACCOUNT') }}</label>
                        <b-form-input
                            type="text"
                            v-model="formData.billing_account" :state="data.state">
                        </b-form-input>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="3" class="mb-4">
                <app-input-control :error-object="validation.iban">
                    <template v-slot:input="data">
                        <label>IBAN</label>
                        <b-form-input
                            type="text"
                            v-model="formData.iban" :state="data.state">
                        </b-form-input>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="3" class="mb-4">
                <app-input-control :error-object="validation.payout_account">
                    <template v-slot:input="data">
                        <label>{{ $t('PAYOUT_ACCOUNT') }}</label>
                        <b-form-input
                            type="text"
                            v-model="formData.payout_account" :state="data.state">
                        </b-form-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>

        <b-row>
            <b-col md="6" lg="4" class="mb-4">
                <app-input-control :error-object="validation.bic">
                    <template v-slot:input="data">
                        <label>{{ $t('SWIFT') }}</label>
                        <b-form-input
                            type="text"
                            v-model="formData.bic" :state="data.state">
                        </b-form-input>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="4" lg="1" class="mb-4">
                <app-input-control :error-object="validation.vat_system">
                    <template v-slot:input="data">
                        <label>{{ $t('VAT') }}</label>
                        <b-form-checkbox
                            :state="data.state"
                            switch
                            v-model="formData.vat_system"
                            :value="1"
                            :unchecked-value="0"
                            @input="formData.vat_perc = $event ? 0 : null">
                        </b-form-checkbox>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="4" lg="1" v-if="formData.vat_system" class="mb-4">
                <app-input-control :error-object="validation.vat_perc">
                    <template v-slot:input="data">
                        <label>{{$t('VAT_PERCENTAGE')}}</label>
                            <app-number-input
                                prepend="%"
                                :state="data.state"
                                allow-decimal
                                v-model="formData.vat_perc">
                            </app-number-input>

                    </template>
                </app-input-control>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <form-header>{{$t('LOCATION')}}</form-header>
            </b-col>
        </b-row>

        <b-row>
            <b-col md="6" lg="3" class="mb-4">
                <app-input-control :error-object="validation.address">
                    <template v-slot:input="data">
                        <label>{{$t('ADDRESS')}}</label>
                        <b-form-input
                            :state="data.state"
                            type="text"
                            v-model="formData.address">
                        </b-form-input>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="3" class="mb-4">
                <app-input-control :error-object="validation.city">
                    <template v-slot:input="data">
                        <label>{{$t('CITY')}}</label>
                        <app-select :state="data.state" mode="search" v-if="formData.city" v-model="formData.city" search-clear
                                    :search="searchCity"></app-select>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="3" class="mb-4">
                <app-input-control :error-object="validation.country">
                    <template v-slot:input="data">
                        <label>{{$t('COUNTRY')}}</label>
                        <b-input disabled :value="formData.country.name" :state="data.state"></b-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>

        <b-row>
            <b-col md="6" class="mb-4">
                <app-button-submit :loading="updating">{{$t('SAVE')}}</app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import FormHeader from "@/components/app/form/FormHeader";
    import {getCityMapping, getCountries} from "@/services/location";
    import {getTenantTypeList, updateCompany} from "@/services/company";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {notifySuccess, toast} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {cloneDeep, isEqual} from "lodash";
    import {C_COMPANY_BASIC_V} from "@/shared/component_permission";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {
        COM_ADDRESS_VALIDATION_FAIL,
        COM_AGENCY_ID_CODE_VALIDATION_FAIL,
        COM_BILLING_ACCOUNT_VALIDATION_FAIL, COM_PAYOUT_ACCOUNT_VALIDATION_FAIL,
        COM_CITY_VALIDATION_FAIL,
        COM_COUNTRY_VALIDATION_FAIL,
        COM_IBAN_VALIDATION_FAIL,
        COM_LEGAL_REPRESENTATIVE_VALIDATION_FAIL,
        COM_NAME_VALIDATION_FAIL,
        COM_REGISTRATION_NUMBER_VALIDATION_FAIL,
        COM_TYPE_VALIDATION_FAIL,
        COM_VAT_ID_VALIDATION_FAIL, COM_VAT_PERCENTAGE_VALIDATION_FAIL, COM_VAT_SYSTEM_VALIDATION_FAIL,
        COM_BIC_VALIDATION_FAIL
    } from "@/shared/error_codes";
    import AppInputControl from "@/components/app/AppInputControl";
    import {PERSON_TYPE_LIST} from "@/shared/constants";
    import AppNumberInput from "@/components/app/form/AppNumberInput";

    export default {
        name: "CompanyBasicForm",
        components: {AppNumberInput, AppInputControl, AppSelect, AppButtonSubmit, FormHeader},
        mixins: [getErrorMessage],
        props: {
            company: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                updating: false,
                initialFormData: {},
                C_COMPANY_BASIC_V,
                formData: null,
                validation: {
                    name: COM_NAME_VALIDATION_FAIL,
                    country: COM_COUNTRY_VALIDATION_FAIL,
                    city: COM_CITY_VALIDATION_FAIL,
                    address: COM_ADDRESS_VALIDATION_FAIL,
                    type: COM_TYPE_VALIDATION_FAIL,
                    vat_id: COM_VAT_ID_VALIDATION_FAIL,
                    registration_number: COM_REGISTRATION_NUMBER_VALIDATION_FAIL,
                    agency_id: COM_AGENCY_ID_CODE_VALIDATION_FAIL,
                    legal_representative: COM_LEGAL_REPRESENTATIVE_VALIDATION_FAIL,
                    billing_account: COM_BILLING_ACCOUNT_VALIDATION_FAIL,
                    payout_account: COM_PAYOUT_ACCOUNT_VALIDATION_FAIL,
                    iban: COM_IBAN_VALIDATION_FAIL,
                    vat_perc: COM_VAT_PERCENTAGE_VALIDATION_FAIL,
                    vat_system: COM_VAT_SYSTEM_VALIDATION_FAIL,
                    bic: COM_BIC_VALIDATION_FAIL,
                },
            }
        },
        computed: {
            personTypeList() {
                return Array.prototype.map.call(PERSON_TYPE_LIST, el => {
                    return {id: el.id, label: this.$t(el.label)}
                })
            }
        },
        watch: {
            company: {
                handler(val) {
                    this.formData = cloneDeep(val)
                },
                deep: true,
                immediate: true
            },
            'formData.city': {
                handler(value) {
                    if (value && value.hasOwnProperty('id')) {
                        if (value.hasOwnProperty('country')) {
                            this.formData.country = value.country
                        }
                    }
                    if(value && value.hasOwnProperty('id') && !value.id ) {
                        this.formData.country.id = null
                        this.formData.country.name = null
                    }
                },
                immediate: true
            },
        },
        methods: {
            searchCity(value) {
                return getCityMapping({
                    name: value,
                })
            },
            searchCountry(value) {
                return getCountries({
                    name: value
                })
            },
            searchTenantType() {
                return getTenantTypeList()
            },
            searchPersonType() {
                return Promise.resolve({data: this.personTypeList})
            },
            save() {

                if (!isEqual(this.initialFormData, this.formData)) {
                    this.updating = true
                    updateCompany(this.company.id, this.formData).then(res => {

                        this.$emit('update', res.data)
                        this.initialFormData = cloneDeep(this.formData)
                        notifySuccess()
                    }, error => {
                        this.showErrorMessages(error, this.validation)
                    }).finally(() => this.updating = false)
                } else {
                    toast({
                        'title': '',
                        'message': this.$t('NO_CHANGE'),
                        'type': 'error',
                        'timeout': 3000
                    })
                }
            }
        },
        created() {
            this.initialFormData = cloneDeep(this.formData)
        }
    }
</script>

<style scoped>

</style>
