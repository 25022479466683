<template>
    <tr>
        <td>
            <app-date-range-picker format="DD.MM" :min-date="firstDayCurrentYear" :max-date="endDayCurrentYear" v-model="period"
                                   @input="onPeriodInput"></app-date-range-picker>
        </td>
        <td class="text-right" v-if="addButton">
            <app-new-button :disabled="!period.start || !period.end" button_type="new"
                            @click="save"
                            :loading="loading">
                {{$t('ADD_ITEM')}}
            </app-new-button>
        </td>
    </tr>
</template>

<script>
    import AppNewButton from '@/components/app/AppButton/AppButton'
    import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
    import moment from 'moment'

    export default {
        name: "period-item",
        data() {
            return {
                period: {
                    start: null,
                    end: null
                }
            }
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            addButton: {
                type: Boolean,
                default: true,
            },
        },
        components: {
            AppDateRangePicker,
            AppNewButton,
        },
        computed: {
            firstDayCurrentYear() {
                return moment().startOf('year').format('YYYY-MM-DD')
            },
            endDayCurrentYear() {
                return moment().endOf('year').format('YYYY-MM-DD')
            }
        },
        watch: {
            loading: {
                handler(value) {
                    if (!value) {
                        this.period.start = null
                        this.period.end = null
                    }
                },
                immediate: true
            }
        },
        methods: {
            save() {
                let period = {
                    start: this.formatDate(this.period.start),
                    end: this.formatDate(this.period.end)
                }
                this.$emit('save', period)
            },
            formatDate(value) {
                if (value) {
                    let date = new Date(value)
                    return moment(date).format('MM/DD')
                }
            },
            onPeriodInput() {
                if (this.addButton) {
                    return
                }

                this.save()
            },
        }


    }
</script>

<style scoped>

</style>
