<template>
    <div @input="state=null" @click="handleStateOnClick">
        <slot name="input" :state="state"></slot>
        <template v-if="(messageSlotHasContent || typeof inputErrorMessage === 'string') && state === false">
            <small class="d-flex text-danger">
                <slot name="message">
                    <div v-html="parseNewLine(inputErrorMessage)"></div>
                </slot>
            </small>
        </template>

        <template v-else-if="Array.isArray(inputErrorMessage) && state === false">
            <div class="d-flex flex-column">
                <small class="text-danger" v-for="(message, index) in inputErrorMessage">
                    <span v-html="parseNewLine(message)"></span>
                    <!--                        <br v-if="index < inputErrorMessage.length-1">-->
                </small>
            </div>
        </template>
    </div>
</template>

<script>
    import {EventBus, V_ERROR} from "@/shared/EventBus";
    import {isNumber as _isNumber} from 'lodash'
    import {toast} from "@/shared/plugins/toastr";
    import AppValidationDescription from "@/components/app/form/AppValidationDescription";
    import {textFormatters} from "@/mixins/shared/helpers";

    function isObject(object) {
        return object === Object(object) && Object.prototype.toString.call(object) !== '[object Array]'
    }

    export default {
        name: "AppInputControl",
        components: {AppValidationDescription},
        mixins: [textFormatters],
        props: {
            errorObject: {
                validator(errorObject) {
                    return typeof errorObject === 'object'
                },
                default() {
                    return {}
                }
            },
            listeningForEvent: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                state: null,
                inputErrorMessage: null,
            }
        },
        computed: {
            messageSlotHasContent() {
                return !!this.$scopedSlots.message
            }
        },
        watch: {
            listeningForEvent: {
                handler(value) {
                    this.errorListener(value)
                },
            }
        },
        methods: {
            parseNewLine(string) {
              return this.$options.filters.newLineToLineBreak(string)
            },
            handleStateOnClick(){
                let element = event.srcElement.nodeName.toLowerCase()
                if(element !== 'input'){
                    this.state = null
                }
            },
            getMatchedErrorMessages(errorCollection, errorList) {
                let errorMessages = {input: [], toast: []}
                if (Array.isArray(errorList) && errorList.length) {
                    if (Array.isArray(errorCollection)) {
                        errorCollection.forEach(error => {
                            let messageObject = this.findMessage(error, errorList)
                            if (messageObject) {
                                errorMessages['input'].push(messageObject)
                            }
                        })
                    } else if (_isNumber(errorCollection) || (isObject(errorCollection) && errorCollection.hasOwnProperty('code'))) {
                        let messageObject = this.findMessage(errorCollection, errorList)
                        if (messageObject) {
                            errorMessages['input'].push(messageObject)
                        }
                    } else {
                        for (const [type, typeErrors] of Object.entries(errorCollection)) {
                            // the list of errors per type should be an array (this can be changed later)
                            if (Array.isArray(typeErrors)) {
                                if (type === 'input' || type === 'toast') {
                                    typeErrors.forEach(error => {
                                        let messageObject = this.findMessage(error, errorList)
                                        if (messageObject) {
                                            errorMessages[type].push(messageObject)
                                        }
                                    })
                                }
                            }
                        }
                    }

                }
                return errorMessages
            },
            findMessage(error, errorList) {
                const serverErrorCodes = errorList.map(list => Number(list.code))
                let index = -999
                if (_isNumber(error)) {
                    index = serverErrorCodes.findIndex((code) => code === Number(error))
                } else if (error.hasOwnProperty('code')) {
                    index = serverErrorCodes.findIndex((code) => code === Number(error.code))
                }

                if (index > -1) {
                    this.state = false
                    if (_isNumber(error) || !error.hasOwnProperty('message')) {
                        return {
                            code: errorList[index].code || 'N/A',
                            message: errorList[index].message
                        }
                    } else if (error.hasOwnProperty('message')) {

                        if (Array.isArray(error.message) && error.message.length > 0) {
                            let translation = []
                            error.message.forEach(el => {
                                if (el.hasOwnProperty('text') && el.hasOwnProperty('value')) {
                                    if (isObject(el.value)) {
                                        translation.push(this.$t(el.text, {...el.value}))
                                    } else {
                                        translation.push(this.$t(el.text, {value: el.value}))
                                    }
                                } else {
                                    translation.push(this.$t(el))
                                }
                            })
                            return {
                                code: errorList[index].code || 'N/A',
                                message: translation
                            }

                        } else if (isObject(error.message) && error.message.hasOwnProperty('text') && error.message.hasOwnProperty('value')) {
                            if (isObject(error.message.value)) {
                                return {
                                    code: errorList[index].code || 'N/A',
                                    message: this.$t(error.message.text, {...error.message.value})
                                }
                            } else {
                                return {
                                    code: errorList[index].code || 'N/A',
                                    message: this.$t(error.message.text, {value: error.message.value})
                                }
                            }

                        } else if (error.hasOwnProperty('message') && !Array.isArray(error.message)) {
                            let message = error.message
                            return {
                                code: errorList[index].code || 'N/A',
                                message: typeof message === 'string' && message ? this.$t(message) : ''
                            }
                        }
                    }
                } else {
                    return false
                }
            },
            displayErrorMessages(errorMessages) {
                if (errorMessages.input.length) {
                    if (errorMessages.input.length > 1) {
                        this.inputErrorMessage = errorMessages.input.reduce((result, current, index) => {
                            result += this.sanitizeText(current.message)
                            if (errorMessages.length - 1 !== index) {
                                result += "<br>"
                            }
                            return result
                        }, "");
                    } else {
                        this.inputErrorMessage = this.sanitizeText(errorMessages.input[0].message)
                    }
                }

                if (errorMessages.toast.length) {
                    toast({
                        title: this.$t('NOTIFICATIONS.ERROR'),
                        message: this.formatErrorMessages(errorMessages.toast),
                        type: 'error',
                        timeout: 6000
                    })
                }
            },
            formatErrorMessages(errorMessages) {
                let msg = ""
                errorMessages.forEach((m, i) => {
                    msg += 'Code: ' + m.code + '<br>'
                    if (Array.isArray(m.message)) {
                        m.message.forEach(el => {
                            msg += el ? this.sanitizeText(el) + '<br>' : '';
                        })
                    } else {
                        msg += (m.message) ? this.sanitizeText(m.message) + '<br>' : '';
                    }

                    if (i < errorMessages.length - 1) {
                        msg += '<br>'
                    }
                })
                return msg.trim()
            },
            errorListener(value) {
                if (value === true) {
                    EventBus.$on(V_ERROR, errorList => {
                        this.state = null
                        this.inputErrorMessage = null
                        this.displayErrorMessages(this.getMatchedErrorMessages(this.errorObject, errorList))
                    })
                } else {
                    EventBus.$off(V_ERROR)
                }
            }
        },
        beforeDestroy() {
            EventBus.$off(V_ERROR)
        },
        mounted() {
            this.errorListener(this.listeningForEvent)
        }
    }
</script>

<style scoped>

</style>
