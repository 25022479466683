<template>
    <form @submit.prevent="search" @reset.prevent="reset" class="filter">
        <b-row class="align-items-end">
            <b-col md="2" class="mb-3">
                <label>{{ $t('FROM_CURRENCY') }}</label>
                <app-select :options="currencyList"
                            v-model="filter.source_currency.id">
                </app-select>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{ $t('TO_CURRENCY') }}</label>
                <app-select :options="currencyList"
                            v-model="filter.destination_currency.id">
                </app-select>
            </b-col>

            <b-col class="mb-3">
                <app-button-submit :inline="true" :loading="loading" variant="primary" button_type="search">
                    {{$t("SEARCH") }}
                </app-button-submit>
                <app-button-reset :disabled="loading" :inline="true"  class="pl-2 ml-2" ></app-button-reset>

            </b-col>

            <b-col class="mb-3">
                <slot>

                </slot>
            </b-col>
        </b-row>
    </form>
</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {getCurrencyList} from "@/services/finance";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";

    export default {
        name: "FixedExchangeRateFilter",
        components: {AppButtonReset, AppSelect, AppButtonSubmit},
        props: {
            loading: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                filter: {page: 1, source_currency: {}, destination_currency: {}},
                currencyList: []
            }
        },
        methods: {
            search() {
                this.$emit('search', this.filter)
            },
            reset() {
                this.filter = {page: 1, source_currency: {}, destination_currency: {}}
                this.search()
            }
        },
        created() {
            getCurrencyList().then(response => {
                this.currencyList = response.data
            })
        }
    }
</script>

<style scoped>

</style>
