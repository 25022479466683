<template>
    <b-form @submit.prevent="search" class="filter">
        <b-row>
            <b-col md="2">
                <label>{{$t("_DATE")}}</label>
                <app-select :options="dateOptions" v-model="dateType" :search-empty-item="false"></app-select>
            </b-col>

            <b-col md="2" class="mb-1">
                <label>{{$t("FROM")}}</label>
                <app-date-picker :disabled="!dateType" v-model="date_from"
                                 :max-date="date_to"></app-date-picker>
            </b-col>

            <b-col md="2" class="mb-1">
                <label>{{$t("TO")}}</label>
                <app-date-picker :disabled="!dateType" v-model="date_to" :min-date="date_from"
                                 :from-date="date_from"></app-date-picker>
            </b-col>

            <b-col md="2" class="mb-1">
                <label>{{$t('DISTRIBUTION')}}</label>
                <app-select v-model="filter.distribution"
                            :options="distributionOptions"
                            mode="multiselect">
                </app-select>
            </b-col>

            <b-col md="2">
                <label>{{$t("AMOUNT")}}</label>
                <app-number-input v-model="filter.amount_from" allow-decimal :placeholder="$t('FROM')"></app-number-input>
            </b-col>

            <b-col md="2">
                <label>&zwnj;</label>
                <app-number-input v-model="filter.amount_to" allow-decimal :placeholder="$t('TO')"></app-number-input>
            </b-col>
        </b-row>

        <b-row>
            <b-col md="4" class="mb-3">
                <app-button-submit
                    class="filter-submit-wrapper"
                    :inline="true"
                    :disabled="loading || (!date_from || !date_to)"
                    :loading=loading
                    variant="primary"
                    button_type="search">{{ $t("SEARCH") }}
                </app-button-submit>
                <app-button-reset   @click="resetForm" :disabled="loading" :inline="true"  class="pl-2 ml-2">
                </app-button-reset>
            </b-col>
            <b-col class="mb-3">
                <app-button class="pull-right filter-submit-wrapper"
                            :disabled="loading || (!date_from || !date_to)" :loading="autoMapLoading"
                            @click="searchAutoMapSuggestions">Autoconnect
                </app-button>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import AppDatePicker from "@/components/app/datetime/AppDatePicker";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {cloneDeep} from "lodash";
    import {getDistributionList} from "@/services/distribution";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppNumberInput from "@/components/app/form/AppNumberInput";
    import {EventBus} from "@/shared/EventBus";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";

    export default {
        name: "PaymentMessageReservationFilter",
        components: {AppButtonReset, AppButton, AppNumberInput, AppButtonSubmit, AppSelect, AppDatePicker},
        props: {
            loading: Boolean
        },
        data() {
            return {
                filter: {
                    distribution: [],
                    amount_from: null,
                    amount_to: null,
                    company: null,
                },
                distributionOptions: [],
                dateType: 'check_in',
                date_from: new Date().toJSON().slice(0, 10),
                date_to: null,
                autoMapLoading:false

            }
        },
        computed: {
            dateOptions() {
                return [
                    {id: 'check_in', name: this.$t('CHECK_IN')},
                    {id: 'check_out', name: this.$t('CHECK_OUT')}
                ]
            },
            company() {
                return this.$store.getters['user/getCurrentCompany']
            }
        },
        beforeDestroy() {
            EventBus.$off('loaderAutoMap')
        },
        mounted() {
            EventBus.$on('loaderAutoMap',(value)=>{
                this.autoMapLoading = value
            })
        },
        methods: {
            search() {
                this.$emit('loading', true)
                EventBus.$emit('searchReservations', {
                    ...this.filter,
                    [`${this.dateType}_from_date`]: this.date_from,
                    [`${this.dateType}_to_date`]: this.date_to,
                })
            },
            searchAutoMapSuggestions() {
                EventBus.$emit('searchAutoMapSuggestions', {
                    ...this.filter,
                    [`${this.dateType}_from_date`]: this.date_from,
                    [`${this.dateType}_to_date`]: this.date_to,
                })
            },
            resetForm() {
                this.date_from = new Date().toJSON().slice(0, 10)
                this.date_to = null
                this.dateType = 'check_in'
                this.filter = cloneDeep(this.initialFilter)
            },
        },
        created() {
            this.filter.company = this.company.id
            this.initialFilter = cloneDeep(this.filter)
            getDistributionList({company: this.company.id, for_reservation: 1, sorted: 1}).then(res => {
                this.distributionOptions = res.data
            })
        }
    }
</script>

<style scoped>

</style>
