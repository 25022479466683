<template>
    <nav class="sidebar-nav">
        <VuePerfectScrollbar class="scroll-area" :settings="psSettings" @ps-scroll-y="scrollHandle">
            <ul class="nav">
                <template v-for="(item, index) in navItems">
                    <template v-if="item.title">
                        <SidebarNavTitle :key="index" :name="item.name" :classes="item.class" :wrapper="item.wrapper"/>
                    </template>
                    <template v-else-if="item.divider">
                        <SidebarNavDivider :key="index" :classes="item.class"/>
                    </template>
                    <template v-else-if="item.label">
                        <SidebarNavLabel :key="index" :name="item.name" :url="item.url" :icon="item.icon"
                                         :label="item.label" :classes="item.class"/>
                    </template>
                    <template v-else>
                        <template v-if="item.children">
                            <!-- First level dropdown -->
                            <SidebarNavDropdown ref="navDropdownListHolder" :key="index"
                                                :name="item.name" :url="item.url" :icon="item.icon">
                                <template v-for="(childL1, index1) in item.children">
                                    <template v-if="childL1.children">
                                        <!-- Second level dropdown -->
                                        <SidebarNavDropdown ref="navDropdownListHolder"
                                                            :key="index1" :name="childL1.name" :url="childL1.url"
                                                            :icon="childL1.icon">
                                            <li :key="index2" class="nav-item"
                                                v-for="(childL2, index2) in childL1.children">
                                                <SidebarNavLink :name="childL2.name" :url="childL2.url"
                                                                :icon="childL2.icon" :badge="childL2.badge"
                                                                :variant="childL2.variant"
                                                                @click="handleNavLinkClick"
                                                                :attributes="childL2.attributes"/>
                                            </li>
                                        </SidebarNavDropdown>
                                    </template>
                                    <template v-else>
                                        <SidebarNavItem :key="index1" :classes="item.class">
                                            <SidebarNavLink :name="childL1.name" :url="childL1.url" :icon="childL1.icon"
                                                            :badge="childL1.badge" :variant="childL1.variant"
                                                            @click="handleNavLinkClick"
                                                            :attributes="childL1.attributes"/>
                                        </SidebarNavItem>
                                    </template>
                                </template>
                            </SidebarNavDropdown>
                        </template>
                        <template v-else>
                            <SidebarNavItem :key="index" :classes="item.class">
                                <SidebarNavLink @click="handleNavLinkClick" :name="item.name" :url="item.url"
                                                :icon="item.icon" :badge="item.badge"
                                                :variant="item.variant" :attributes="item.attributes"/>
                            </SidebarNavItem>
                        </template>
                    </template>
                </template>
            </ul>
            <slot></slot>
        </VuePerfectScrollbar>
    </nav>
</template>

<script>
    import SidebarNavDivider from '@/components/app/navigation/SidebarNav/SidebarNavDivider'
    import SidebarNavDropdown from '@/components/app/navigation/SidebarNav/SidebarNavDropdown'
    import SidebarNavLink from '@/components/app/navigation/SidebarNav/SidebarNavLink'
    import SidebarNavTitle from '@/components/app/navigation/SidebarNav/SidebarNavTitle'
    import SidebarNavItem from '@/components/app/navigation/SidebarNav/SidebarNavItem'
    import SidebarNavLabel from '@/components/app/navigation/SidebarNav/SidebarNavLabel'
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import {isEqual, has} from 'lodash'

    export default {
        name: 'SidebarNav',
        components: {
            SidebarNavDivider,
            SidebarNavDropdown,
            SidebarNavLink,
            SidebarNavTitle,
            SidebarNavItem,
            SidebarNavLabel,
            VuePerfectScrollbar
        },
        props: {
            navItems: {
                type: Array,
                required: true,
                default: () => []
            }
        },
        data() {
            return {
                activeUrl: null
            }
        },
        computed: {
            psSettings: () => {
                // ToDo: find better rtl fix
                return {
                    maxScrollbarLength: 200,
                    minScrollbarLength: 40,
                    suppressScrollX: getComputedStyle(document.querySelector('html')).direction !== 'rtl',
                    wheelPropagation: false,
                    interceptRailY: styles => ({...styles, height: 0})
                }
            },
            resolvedUrlElements() {
                if (this.activeUrl !== null) {
                    return this.activeUrl.resolved.fullPath.split('/')
                }
                return []
            }
        },
        methods: {
            // eslint-disable-next-line
            scrollHandle(evt) {
            },
            handleNavLinkClick(url) {
                this.activeUrl = this.$router.resolve(url)
            }
        },
        watch: {
            activeUrl() {

                if(this.$refs.navDropdownListHolder && this.$refs.navDropdownListHolder.length > 0){
                    this.$refs.navDropdownListHolder.forEach((item, index) => {
                        if (item.url && typeof item.url === 'string') {

                            if (!this.resolvedUrlElements.includes(item.url.replace('/', ''))) {
                                this.$refs.navDropdownListHolder[index].$el.classList.remove('open')
                            }
                        }
                    })
                }
            }
        }
    }
</script>

<style scoped lang="css">
    .scroll-area {
        position: absolute;
        height: 100%;
        margin: auto;
    }
</style>
