<template>
    <div>
        <b-form @submit.prevent="updatePromotion">
            <b-row class="mb-3">
                <b-col sm="6">
                    <b-form-group>
                        <label>{{$t('NAME')}}</label>
                        <b-form-input v-model="name" type="text" id="name_type" name="name_type"
                                      @change="$v.name.$touch"
                                      :state="$v.name.$dirty && $v.name.$invalid ? false : null"
                        ></b-form-input>
                        <validation-description :show="$v.name.$dirty && $v.name.$invalid">
                            Min input: 5 characters
                        </validation-description>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>{{$t('DISCOUNT')}}</label>
                        <b-form-input id="discount" name="discount" v-model="discount"
                                      @change="$v.discount.$touch"
                        ></b-form-input>
                        <validation-description :show="$v.discount.$dirty && $v.discount.$invalid">
                            {{$t('FIELD_NUMERIC')}}
                        </validation-description>
                    </b-form-group>
                </b-col>

                <b-col sm="2">
                    <b-form-group>
                        <label> {{$t('TYPE')}}</label>
                        <app-select :options="discountTypeOptions" v-model="discountType"
                                    @change="$v.discountType.$touch"></app-select>
                        <validation-description :show="$v.discountType.$dirty && $v.discountType.$invalid">
                            {{$t('FIELD_MANDATORY')}}
                        </validation-description>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>{{$t('BOOK_FROM_TO')}}</label>
                        <app-date-range-picker v-model="bookDate" :layout=" {default: 1, lg: 1}"></app-date-range-picker>
                        <validation-description :show="$v.bookDate.$dirty && $v.bookDate.$invalid">
                            {{$t('FIELD_MANDATORY')}}
                        </validation-description>
                    </b-form-group>
                </b-col>

                <b-col sm="6">
                    <b-form-group>
                        <label>{{$t('CHECK_IN_FROM_TO')}}</label>
                        <app-date-range-picker v-model="checkInDate" :layout=" {default: 1, lg: 1}"></app-date-range-picker>
                        <validation-description :show="$v.checkInDate.$dirty && $v.checkInDate.$invalid">
                            {{$t('FIELD_MANDATORY')}}
                        </validation-description>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>{{$t('MIN_DAYS_BEFORE_CHECK_IN')}}</label>
                        <b-form-input
                                id="min_days_advance"
                                name="min_days_advance"
                                v-model="minDaysAdvance"
                                @change="$v.minDaysAdvance.$touch"
                        ></b-form-input>
                        <validation-description :show="$v.minDaysAdvance.$dirty && $v.minDaysAdvance.$invalid">
                            {{$t('FIELD_MANDATORY')}}
                        </validation-description>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>{{$t('MIN_STAY_FROM_RATE_PLAN')}}</label>
                        <app-select :options="ratePlanList" v-model="ratePlan" :disabled="minStayValidate"></app-select>
                        <validation-description :show="$v.ratePlan.$dirty && $v.ratePlan.$invalid">
                            {{$t('FIELD_MANDATORY')}}
                        </validation-description>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>{{$t('MIN_STAY')}}</label>
                        <b-form-input
                                id="min_stay"
                                name="min_stay"
                                :disabled="ratePlanValidate"
                                v-model="minStay"
                        ></b-form-input>
                        <validation-description :show="$v.minStay.$dirty && $v.minStay.$invalid">
                            {{$t('FIELD_NUMERIC')}}
                        </validation-description>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="6">
                    <promotion-submit></promotion-submit>
                </b-col>
                <b-col sm="6">
                    <promotion-delete :loading="deleteState" @click="showDeleteDialog=true"
                                      class="pull-right"></promotion-delete>
                </b-col>
            </b-row>

            <promotion-delete-dialog :show="showDeleteDialog" @confirm="deletePromotion"
                                     @cancel="showDeleteDialog=false" :delete_title="true">
            </promotion-delete-dialog>
        </b-form>
    </div>
</template>

<script>
    import PromotionSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import PromotionDelete from '@/components/app/AppButton/AppButtonDelete'
    import PromotionDeleteDialog from '@/components/app/form/AppConfirmationDialog'
    import {toast} from '@/shared/plugins/toastr'
    import {required, minLength, minValue, numeric, integer} from 'vuelidate/lib/validators'
    import ValidationDescription from '@/components/app/form/AppValidationDescription'
    import {getValidationRule} from '@/mixins/validation/getValidationRule'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import requiredIf from "vuelidate/src/validators/requiredIf";
    import moment from 'moment'
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";

    export default {
        name: "promotion-early-booker-update-form",
        props: ['promotionItem', 'ratePlanList'],
        data() {
            return {
                selectedBookDate: {
                    start: null,
                    end: null
                },
                discountTypeOptions: [
                    {id: 1, name: '%'},
                    {id: 2, name: 'fix'},

                ],

                showDeleteDialog: false,
                deleteState: false,

                id: this.promotionItem.id,
                name: this.promotionItem.name,
                discount: this.promotionItem.discount,
                discountType: this.promotionItem.discount_type,
                minDaysAdvance: this.promotionItem.min_days_advance,
                minStay: this.promotionItem.min_stay,
                ratePlan: this.promotionItem.min_stay_from_rate_plan,
                bookDate: {
                    start: this.promotionItem.book_from,
                    end: this.promotionItem.book_to,
                },
                checkInDate: {
                    start: this.promotionItem.check_in_from,
                    end: this.promotionItem.check_in_to,
                }
            }
        },
        mixins: [getValidationRule, getErrorMessage],
        computed: {

            inputState() {
                if (!this.selectedValue) {
                    return {
                        type: 'is-danger',
                        message: 'Date required.',
                    };
                }
                return {
                    type: 'is-primary',
                    message: '',
                };
            },
            minStayValidate() {
                if (!this.minStay) {
                    return false;
                } else {
                    return true
                }
            },
            ratePlanValidate() {
                if (!this.ratePlan) {
                    return false
                } else {
                    return true
                }
            },
        },
        validations: {
            discount: {
                required, integer
            },
            discountType: {
                required,
            },
            minDaysAdvance: {
                required,
                numeric
            },
            name: {
                required,
                minLength: minLength(5),
            },
            checkInDate: {
                start: {required},
                end: {required}
            },
            minStay: {
                required: requiredIf(function () {
                    return !this.ratePlan
                }),
                numeric
            },
            ratePlan: {
                required: requiredIf(function () {
                    return !this.minStay
                }),
            },
            bookDate: {
                required
            }

        },
        components: {
            AppDateRangePicker,
            PromotionSubmit,
            PromotionDelete,
            PromotionDeleteDialog,
            ValidationDescription,

            AppSelect

        },

        methods: {
            updatePromotion() {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    toast({
                        'title': "",
                        'message': this.$t('VALIDATION_FAILED'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return

                }
                let data = {
                    name: this.name,
                    discount: this.discount,
                    discount_type: this.discountType,
                    amount_relation_type_id: this.discountType,
                    book_date_from: moment(this.bookDate.start).format('YYYY-MM-DD'),
                    book_date_to: moment(this.bookDate.end).format('YYYY-MM-DD'),
                    checkin_date_from: moment(this.checkInDate.start).format('YYYY-MM-DD'),
                    checkin_date_to: moment(this.checkInDate.end).format('YYYY-MM-DD'),
                    min_stay: this.minStay,
                    min_stay_rate_plan_id: this.ratePlan,
                    min_days_advance: this.minDaysAdvance,
                }

                this.$store.dispatch('property/actionPropertyPromotion', {
                    id: this.$route.params.id,
                    promotionId: this.id,
                    data: data
                })
                    .then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 2000
                        })
                        this.$emit('promotionSaved')
                    }, error => {
                        toast({
                            'title': "Action fail",
                            'message': this.getMessages(error),
                            'type': 'error',
                            'timeout': 2000
                        })
                    })
            },
            deletePromotion() {
                this.$store.dispatch('property/actionDeletePropertyPromotion', {promotionId: this.id})
                    .then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.DELETE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.DELETE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 2000
                        })
                        this.$emit('promotionSaved')
                        this.showDeleteDialog = false
                    }, error => {
                        toast({
                            'title': "Action fail",
                            'message': this.getMessages(error),
                            'type': 'error',
                            'timeout': 2000
                        })
                    })
            },

        }

    }
</script>

<style scoped>

</style>
