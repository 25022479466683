<template>
    <div >
        <template v-if="isContigent">
            <div class="availability_row availability_calendar--background_gray">
               <property-calendar-content
                        :ac_response_value="ac_response_value"
                        :display="display"
                        :key="unit.id"
                        :is-root="isContigent"
                        :property="property"
                        :scrollLeftPosition="scrollLeftPosition"
                        :shiftKeyValue="shiftKeyValue"
                        :contigent="isContigent"
                        :unit="unit"
                        :parent-unit="unit"
                        :unit-data="unitData[unit.id]"
                        :reservations="reservations[unit.id]"
                        :dates="dates"
                        @startEndDrag="dragStartEnd(date)"
                        @drag="drag(date)"
                        @currentDrag="dragCurrent(date)">
               </property-calendar-content>
            </div>

            <div v-show="showUnit && unit.show" class="availability_row ">

                <property-calendar-content
                        :ac_response_value="ac_response_value"
                        :display="display"
                        :key="unit.id"
                        :property="property"
                        :scrollLeftPosition="scrollLeftPosition"
                        :shiftKeyValue="shiftKeyValue"
                        :contigent="false"
                        :unit="unit"
                        :parent-unit="unit"
                        :unit-data="unitData[unit.id]"
                        :reservations="reservations[unit.id]"
                        :dates="dates"
                        @startEndDrag="dragStartEnd(date)"
                        @drag="drag(date)"
                        @currentDrag="dragCurrent(date)">
                </property-calendar-content>
            </div>

            <div v-show="showUnit" class="availability_row" :key="'ontingent_'+contigentUnit.id" v-for="contigentUnit in unit.contigent">
               <property-calendar-content
                        :ac_response_value="ac_response_value"
                        :display="display"
                        :property="property"
                        :contigent="false"
                        :scrollLeftPosition="scrollLeftPosition"
                        :shiftKeyValue="shiftKeyValue"
                        :unit="contigentUnit"
                        :parent-unit="unit"
                        :unit-data="unitData[contigentUnit.id]"
                        :reservations="reservations[contigentUnit.id]"
                        :dates="dates"
                        @startEndDrag="dragStartEnd(date)"
                        @drag="drag(date)"
                        @currentDrag="dragCurrent(date)">
               </property-calendar-content>
            </div>
        </template>

        <template v-else>
            <div class="availability_row availability_calendar--background_gray">
                <property-calendar-content
                        :ac_response_value="ac_response_value"
                        :display="display"
                        :property="property"
                        :is-root="true"
                        :scrollLeftPosition="scrollLeftPosition"
                        :shiftKeyValue="shiftKeyValue"
                        :contigent="false"
                        :unit="unit"
                        :key="unit.id"
                        :parent-unit="unit"
                        :reservations="reservations[unit.id]"
                        :unit-data="unitData[unit.id]"
                        :dates="dates"
                        @startEndDrag="dragStartEnd(date)"
                        @drag="drag(date)"
                        @currentDrag="dragCurrent(date)">
                </property-calendar-content>
            </div>
        </template>
    </div>
</template>

<script>

    import PropertyCalendarSidebarItem from "@/components/property/calendar/PropertyCalendarSidebarItem";
    import PropertyCalendarContent from "@/components/property/calendar/PropertyCalendarContent";
    import PropertyCalendarSidebarContigentItem
        from "@/components/property/calendar/PropertyCalendarSidebarContigentItem";
    import {EventBus, GE_CALENDAR_OPEN_AVAILABILITY_PROPERTY} from "@/shared/EventBus";

    export default {
        name: "PropertyCalendarItem",
        components: {PropertyCalendarSidebarContigentItem, PropertyCalendarContent, PropertyCalendarSidebarItem},
        props: {
            ac_response_value:{
                type:Boolean,
                default:true
            },
            unit: {
                type: Object
            },
            unitData:{
                type:Object,
            },
            reservations: {
                type: Object
            },
            dates: {
                type: Array
            },
            scrollLeftPosition: {
                type: Number,
                default: 0
            },
            shiftKeyValue: {
                type: Boolean,
                default: false
            },
            showContigentList:{
                type:Array,

            },
            property:{
                type:Object
            },
            display: {
                type:Number,
                default:0
            },
        },
        data() {
            return {
                dragPeriod:{
                    start:null,
                    end:null,
                },
                showUnit:false
            }
        },
        computed: {
            isContigent() {
                if(this.unit.contigent){
                    return Object.keys(this.unit.contigent).length > 0
                }
            },

        },
        methods:{

            dragCurrent(date) {
                this.currentDragDate = date
            },
            drag(date) {
                if (!this.dragPeriod.start) {
                    this.dragPeriod.start = date
                } else if (!this.dragPeriod.end) {
                    this.dragPeriod.end = date
                    this.openAvailabilityAside()
                }

            },
            dragStartEnd(date) {
                this.dragPeriod.start = date
                this.dragPeriod.end = date
                this.openAvailabilityAside()
            },

        },
        watch: {
            shiftKeyValue(val) {

                if (val === false) {
                    this.dragPeriod.start = null
                    this.dragPeriod.end = null
                    this.currentDragDate = null
                }
            },
            showContigentList:{
                handler(list){
                    if(list.length > 0){
                        let index =   list.findIndex(el => {
                            return el.id === this.unit.id
                        })
                        if(index > -1){
                            this.showUnit = list[index].value
                        }
                    }
                }, deep:true
            }
        }


    }
</script>

<style scoped>

</style>
