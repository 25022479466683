<template>

    <b-form @submit.prevent="save">
        <b-row>
            <b-col md="4" class="mb-4">
                <label>{{$t('ACCOUNT_MANAGER')}}</label>
                <app-select v-model="account_manager" :options="acc_manager_options"></app-select>
                <!--                <account-manager-select-field v-model="account_manager"></account-manager-select-field>-->
            </b-col>


            <app-access-control tag="b-col" md="4" class="mb-3" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{function:C_PROPERTY_CRM_CUSTOMER_GROUP_V,company: this.company.id, key:AC_CRM}">
                <app-select :label="$t('CUSTOMER_GROUP')" v-model="crm" :options="client_list"></app-select>
            </app-access-control>

            <b-col md="4" class="mb-4">
                <label>{{$t('COMMUNICATION_LANGUAGE')}}</label>
                <app-select v-model="communication_language" :options="language_list"></app-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <submit-button :button_type="saveState ? 'loading' : 'save'"
                               v-if="checkPermission(c_permission_e)"></submit-button>
            </b-col>
        </b-row>
    </b-form>

</template>

<script>
    import AccountManagerSelectField from '@/components/account_manager/AccountManagerSelectField'
    import SubmitButton from '@/components/app/AppButton/AppButtonSubmit'
    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {C_PROPERTY_CRM_CUSTOMER_GROUP_V, C_PROPERTY_CRM_E} from "@/shared/component_permission";
    import {getList as accountManagerList} from '@/services/acc_manager/index'
    import {getClientList} from '@/services/property/index'
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {getSystemLanguageList} from '@/services/system/index'
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {AC_CRM, AC_UNIT_GENERAL} from "@/mixins/AccessControl/AccessControlEnumeration";
    export default {
        name: "PropertyCrmForm",
        components: {
            AppAccessControl,
            AppSelect,
            AccountManagerSelectField, SubmitButton
        },
        mixins: [getErrorMessage,AccessControlComponent],
        data() {
            return {
                saveState: false,
                C_PROPERTY_CRM_CUSTOMER_GROUP_V,AC_CRM,
                c_permission_e: C_PROPERTY_CRM_E,
                acc_manager_options: [],
                client_list: [],
                language_list:[],
                access_control_fetch_key: AC_CRM,
                access_control_components: 1,
            }
        },
        computed: {
            account_manager: {
                get() {
                    return this.$store.getters.getItem('property.basic.account_manager.id')
                },
                set(val) {
                    this.$store.commit('setItem', {path: 'property.basic.account_manager.id', value: val})
                }
            },
            crm: {
                get() {
                    return this.$store.getters.getItem('property.basic.crm_client_type.id')
                },
                set(val) {
                    this.$store.commit('setItem', {path: 'property.basic.crm_client_type.id', value: val})
                }
            },
            communication_language:{
                get(){
                    return this.$store.getters.getItem('property.basic.communication_language')
                },
                set(val){
                    this.$store.commit('setItem', {path: 'property.basic.communication_language', value: val})
                }
            },
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
        },
        methods: {
            save() {
                let saveObject = {
                    crm_client_type: {
                        id: this.crm
                    },
                    account_manager: {
                        id: this.account_manager
                    },
                    communication_language:this.communication_language
                }

                this.saveState = true
                this.$store.dispatch('property/actionUpdatePropertyBasic', {
                    id: this.$route.params.id,
                    data: saveObject
                }).then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }
                ).finally(this.saveState = false)
            },
        },
        created() {
            accountManagerList({
                company_id: this.$store.getters['user/getCompany']
            }).then(response => {
                    this.acc_manager_options = response.data
                }, () => {
                    this.acc_manager_options = []
                }
            )

            getClientList(this.$store.getters['user/getCompany']).then(response => {
                this.client_list = response.data
            }, () => {
                this.client_list = []
            })
            getSystemLanguageList().then(response=> {
                this.language_list = response.data
            },()=>{
                this.language_list =[]
            })

            this.access_control_general_context = {
                company: this.company.id
            }
        }
    }
</script>

<style scoped>

</style>
