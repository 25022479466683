<template>
    <b-card v-cloak class="property-wrapper" v-if="website.id">
        <template slot="header">
            <div class="d-flex justify-content-between mb-0">
                <div>
                    <div class="header-caption">{{ $t('WEBSITE') }}</div>
                    <h2 class="property-wrapper-padding">{{ website.slug }}</h2>
                    <b-link :href="'https://'+website.slug" target="_blank" class="ml-2">
                        <i class="fa fa-globe fa-lg" style="line-height: 0" aria-hidden="true"></i>
                    </b-link>
                </div>

                <div v-if="routeIsPageDetails && websitePageRoutes.length">
                    <div class="header-caption pl-0 pl-md-2 mt-3 mt-md-0">{{ $t('PAGE') }}</div>
                    <app-quick-link :value="$route.params.page_id" :routes="websitePageRoutes"></app-quick-link>
                </div>
                <app-button
                    v-if="routeIsGeneral && checkPermission(C_WEBSITE_CLEAR_PAGE_CACHE)"
                    variant="primary"
                    class="ml-3"
                    :loading="delete_cache"
                    @click="deleteCache">
                    {{ $t('CLEAR_CACHE') }}
                </app-button>
                <app-button
                    v-if="routeIsPageList"
                    button_type="new"
                    variant="primary"
                    class="pull-right mt-4"
                    @click="$store.commit('website/setNewPageAsideState', true)">
                    {{ $t("NEW_PAGE") }}
                </app-button>
                <div v-if="routeIsUnits" class="pull-right mt-4">
                    <app-button
                        v-if="routeIsUnits"
                        variant="primary"
                        @click="$store.commit('website/setActivationAsideState', true)">
                        {{ $t('ACTIVATE') }}
                    </app-button>
                    <app-button
                        v-if="routeIsUnits && hasPermission"
                        variant="primary"
                        class="ml-3"
                        :loading="clear_cache_loading"
                        @click="clearCache">
                        {{ $t('CLEAR_SEARCH_CACHE') }}
                    </app-button>
                    <app-button
                        v-if="routeIsUnits && hasActivationRulesPermission"
                        variant="primary"
                        class="ml-3"
                        @click="openUnitActivationRules">
                        {{ $t('ACTIVATION_RULES') }}
                    </app-button>
                </div>
                <div v-if="routeIsUnitType" class="pull-right mt-4">
                    <app-button
                        v-if="this.$store.state.website.newUnitTypeData && checkPermission(C_WEBSITE_TYPE_NEW_V)"
                        button_type="new"
                        class="mr-3"
                        @click="$store.commit('website/setNewUnitTypeAside', true)">
                        {{ $t('NEW_UNIT_TYPE') }}
                    </app-button>
                    <app-button
                        v-if="this.$store.state.website.unitTypeData"
                        variant="primary"
                        @click="copyUnitType">
                        {{ $t('COPY_UNIT_TYPE') }}
                    </app-button>
                </div>
                <div v-if="routeIsAmenity" class="pull-right mt-4">
                    <app-button v-if="routeIsAmenityMapping"
                                button_type="new"
                                class="mr-3"
                                @click="$store.commit('website/setNewAmenityCategoryAsideState', true)">
                        {{ $t('NEW_AMENITY_CATEGORY') }}
                    </app-button>
                    <app-button
                        v-if="this.$store.state.website.amenityCategory"
                        variant="primary"
                        :loading="loading"
                        :access_control_context="{function:C_WEBSITE_COPY_AMENITY_CATEGORIES,key:AC_WEBSITE}"
                        @AccessControlEvent="addToAccessControlCounter()"
                        @click="confirmation_dialog_state=true">
                        {{ $t('COPY_CATEGORIES') }}
                    </app-button>
                </div>
                <app-button
                    v-if="routeIsPromoCodes"
                    button_type="new"
                    variant="primary"
                    class="pull-right mt-4"
                    @click="newWebsitePromoCode">
                    {{ $t("NEW_PROMO_CODE") }}
                </app-button>
            </div>

            <Navigation
                :key="$route.params.page_id"
                :context="navigationCAContext"
                :ac-fetch-key="AC_WEBSITE_PAGES"
                :back="backButton"
                :nav-elements="navigation">
            </Navigation>
        </template>
        <app-confirmation-dialog
            :show="confirmation_dialog_state"
            @confirm="copyAmenities"
            @cancel="confirmation_dialog_state=false"
            :title="$t('COPY_CATEGORIES')">
            {{ $t('COPY_AMENITY_CATEGORIES_CONFIRMATION_TITLE') }}
        </app-confirmation-dialog>
        <app-transition transition-type="slide" v-if="website.id">
            <router-view v-cloak></router-view>
        </app-transition>
    </b-card>
</template>

<script>
import routeNames from "@/router/routeNames";
import Navigation from "@/components/app/navigation/Navigation";
import AppTransition from '@/components/app/transition/AppTransition'
import AppQuickLink from "@/components/app/AppQuickLink";
import AppButton from "@/components/app/AppButton/AppButton";
import routeName from "@/router/routeNames";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {clearCache, copyAmenities, deleteCache} from "@/services/direct_booking/website";
import {EventBus, GE_LOADER_HIDE, GE_LOADER_SHOW} from "@/shared/EventBus";
import {
    C_WEBSITE_COPY_AMENITY_CATEGORIES,
    C_CLEAR_CACHE_PERMISSION, C_WEBSITE_AMENITY_CATEGORY_NEW_V, C_WEBSITE_TYPE_NEW_V, C_UNIT_ACTIVATION_ROLES_PERMISSION,
    C_WEBSITE_CLEAR_PAGE_CACHE
} from "@/shared/component_permission";
import {AC_WEBSITE} from "@/mixins/AccessControl/AccessControlEnumeration";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {AC_WEBSITE_PAGES} from "@/mixins/AccessControl/AccessControlEnumeration";
import {WEBSITE_PAGES_FILTERS} from "@/shared/component_permission";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {SERVICE_DIRECT_BOOKING_ID} from "@/shared/constants";
import {fetchAccessControlData} from "@/services/access";

export default {
    name: "Index",
    components: {
        AppConfirmationDialog,
        AppButton,
        AppQuickLink,
        Navigation,
        AppTransition,
    },
    mixins: [AccessControlComponent, getErrorMessage, AccessControlComponent],
    data() {
        return {
            loading: false,
            delete_cache: false,
            copyingUnitType: false,
            confirmation_dialog_state: false,
            clear_cache_loading: false,
            C_WEBSITE_COPY_AMENITY_CATEGORIES,
            C_WEBSITE_AMENITY_CATEGORY_NEW_V,
            C_WEBSITE_CLEAR_PAGE_CACHE,
            // SERVICE_DIRECT_BOOKING_ID,
            AC_WEBSITE, C_WEBSITE_TYPE_NEW_V,
            access_control_fetch_key: AC_WEBSITE,
            access_control_components: 1,
            filtersVisible: false,
            AC_WEBSITE_PAGES,
            websiteNavigation: [
                {route: {name: routeNames.RN_DIRECT_BOOKING_WEBSITE_GENERAL}},
                {route: {name: routeNames.RN_DIRECT_BOOKING_WEBSITE_PAGES}},
                {route: {name: routeNames.RN_DIRECT_BOOKING_WEBSITE_NAVIGATION}},
                {route: {name: routeNames.RN_DIRECT_BOOKING_APPEARANCE}},
                {route: {name: routeNames.RN_DIRECT_BOOKING_WEBSITE_GALLERY}},
                {
                    label: this.$t('MAPPING'),
                    children: [
                        {route: {name: routeNames.RN_DIRECT_BOOKING_WEBSITE_UNIT_TYPE}},
                        {route: {name: routeNames.RN_DIRECT_BOOKING_WEBSITE_AMENITY_CATEGORIES}},
                        {route: {name: routeNames.RN_DIRECT_BOOKING_WEBSITE_AMENITIES}},
                        {route: {name: routeNames.RN_DIRECT_BOOKING_WEBSITE_TAGS}},
                    ]
                },
                {route: {name: routeNames.RN_DIRECT_BOOKING_WEBSITE_INTEGRATIONS}},
                {route: {name: routeNames.RN_DIRECT_BOOKING_WEBSITE_UNIT_ACTIVATION}},
                {route: {name: routeNames.RN_DIRECT_BOOKING_WEBSITE_RESERVATION}},
                {route: {name: routeNames.RN_DIRECT_BOOKING_WEBSITE_PROMO_CODES}},
            ],
            websitePageNavigation: [
                // {route: {name: routeNames.RN_DIRECT_BOOKING_WEBSITE_PAGE_OVERVIEW}},
                {route: {name: routeNames.RN_DIRECT_BOOKING_WEBSITE_PAGE_GENERAL}},
                {route: {name: routeNames.RN_DIRECT_BOOKING_WEBSITE_PAGE_CONTENT}},
                {
                    route: {name: routeNames.RN_DIRECT_BOOKING_WEBSITE_PAGE_FILTERS},
                    access_control_context: {
                        function: WEBSITE_PAGES_FILTERS,
                        key: AC_WEBSITE_PAGES,
                    }
                }
            ]
        }
    },
    computed: {
        website() {
            return this.$store.getters['website/getWebsite']
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        navigationPages() {
            return this.$store.getters['website/getNavigationPages']
        },
        navigation() {
            if (this.routeIsPageDetails) {
                return this.websitePageNavigation
            } else {
                return this.websiteNavigation
            }
        },
        backButton() {
            if (this.routeIsPageDetails) {
                return {name: routeNames.RN_DIRECT_BOOKING_WEBSITE_PAGES}
            } else {
                return null //{name: routeNames.RN_DIRECT_BOOKING_WEBSITE_LIST}
            }
        },
        routeIsPageDetails() {
            return this.$route.path.includes('pages/')
        },
        routeIsPageList() {
            return this.$route.name === routeNames.RN_DIRECT_BOOKING_WEBSITE_PAGES
        },
        routeIsGeneral(){
            return this.$route.name === routeNames.RN_DIRECT_BOOKING_WEBSITE_GENERAL
        },
        routeIsUnits() {
            return this.$route.name === routeNames.RN_DIRECT_BOOKING_WEBSITE_UNIT_ACTIVATION
        },
        routeIsUnitType() {
            return this.$route.name === routeName.RN_DIRECT_BOOKING_WEBSITE_UNIT_TYPE
        },
        routeIsAmenityMapping() {
            return this.$route.name === routeName.RN_DIRECT_BOOKING_WEBSITE_AMENITY_CATEGORIES && this.checkPermission(C_WEBSITE_AMENITY_CATEGORY_NEW_V) && this.$store.state.website.amenityCategory
        },
        routeIsPromoCodes() {
            return this.$route.name === routeNames.RN_DIRECT_BOOKING_WEBSITE_PROMO_CODES
        },
        websitePageRoutes() {
            return this.navigationPages.map(page => ({
                id: page.id,
                name: this.$route.name,
                label: page.formattedLabel,
                params: {page_id: page.id}
            }))
        },
        routeIsAmenity() {
            return this.$route.name === routeNames.RN_DIRECT_BOOKING_WEBSITE_AMENITY_CATEGORIES || this.$route.name === routeNames.RN_DIRECT_BOOKING_WEBSITE_AMENITIES
        },
        hasPermission() {
            return this.$store.getters['user/getPermission'](C_CLEAR_CACHE_PERMISSION)
        },
        hasActivationRulesPermission(){
            return this.$store.getters['user/getPermission'](C_UNIT_ACTIVATION_ROLES_PERMISSION)
        },
        navigationCAContext() {
            const pageId = this.$route.params.page_id
            return pageId ? {page_id: pageId} : null
        }
    },
    methods: {
        copyAmenities() {
            this.loading = true;
            copyAmenities(Number(this.$route.params.id)).then(() => {
                notifySuccess()
                EventBus.$emit('reloadWebsiteCategoryTable')
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.loading = false
                this.confirmation_dialog_state = false
            })
        },
        copyUnitType() {
            EventBus.$emit('copyWebsiteUnitType')
        },
        clearCache() {
            this.clear_cache_loading = true
            clearCache(this.website.id).then(response => {
                notifySuccess()
                this.clear_cache_loading = false
            }, error => {
                this.showErrorMessages(error)
                this.clear_cache_loading = false
            })
        },
        deleteCache(){
            this.delete_cache = true
            deleteCache({website: this.website.id, service: SERVICE_DIRECT_BOOKING_ID}).then(() =>{
                notifySuccess()
            }, error =>{
                this.showErrorMessages(error)
            }).finally(() => {
                this.delete_cache = false
            })
        },

        newWebsitePromoCode() {
            EventBus.$emit('newWebsitePromoCode')
        },
        openUnitActivationRules(){
            EventBus.$emit('editUnitActivationRules')
        }
    },
    watch: {
        '$route.params.id': {
            handler(id) {
                EventBus.$emit(GE_LOADER_SHOW)
                return this.$store.dispatch('website/actionSetWebsite', id).finally(() => EventBus.$emit(GE_LOADER_HIDE));
            },
            immediate: true
        }
    },
    created() {
        this.access_control_general_context = {
            company: this.company.id
        }
    },
    beforeDestroy() {
        this.$store.commit('website/setUnitTypeData', false)
        this.$store.commit('website/setAmenityCategory', false)
    }
}
</script>

<style scoped>

</style>
