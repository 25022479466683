<template>
    <div class="main_calendar_test__holder--content--restriction_list main_calendar_test-bb  " :style="style">
        <template v-for="restrictionType in restrictions">
            <div :key="restrictionType.id" v-if="restrictionType.id !== SALES_ENABLED"
                 class="d-flex justify-content-start align-items-center animated fadeIn">
                <div class="main_calendar_test__holder--sidebar-h2">{{restrictionType.label}}</div>
            </div>
        </template>
    </div>
</template>

<script>
    import {SALES_ENABLED} from "@/shared/constants";

    export default {
        name: "TableRestrictionTypeListSidebar",
        data() {
            return {
                SALES_ENABLED
            }
        },
        computed: {
            restrictions() {
                return this.$store.getters['calendar/getRestrictions']
            },
            style() {
                return {}
            }
        }
    }
</script>

<style scoped>

</style>
