<template>
    <b-form @submit.prevent="save" v-if="promo && categories">
        <b-row class="mt-3 mb-3">
            <b-col lg="6" class="mb-3">
                <app-input-control :error-object="validation.code">
                    <template v-slot:input="data">
                        <label>{{$t('CODE')}}</label>
                        <b-form-input type="text"
                                      :state="data.state"
                                      v-model="promo.code"
                        ></b-form-input>
                    </template>
                </app-input-control>
            </b-col>
            <b-col lg="6" class="mb-3">
                <app-translation-input :label="$t('NAME')"
                                       v-model="promo.label"
                                       :lang_id="promo.lang_id"
                                       :languages="langList"
                ></app-translation-input>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{$t('VALID_THROUGH')}}</label>
                <app-date-range-picker v-model="periodValid"></app-date-range-picker>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{$t('FOR_DATES')}}</label>
                <app-date-range-picker v-model="periodUse"></app-date-range-picker>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{$t('DISCOUNT')}}</label>
                <app-input-control :error-object="validation.value">
                    <template v-slot:input="data">
                        <b-input-group>
                            <b-form-input :state="data.state" type="number" v-if="valueSelected === 1" v-model="promo.value_percentage"></b-form-input>
                            <b-form-input :state="data.state" type="number" v-else v-model="promo.value_fixed"></b-form-input>
                            <b-input-group-append>
                                <app-select :search-empty-item="false"
                                            v-model="valueSelected"
                                            :options="valueType"></app-select>
                            </b-input-group-append>
                        </b-input-group>
                    </template>
                </app-input-control>
            </b-col>
            <b-col lg="6" class="mb-3">
                <label>{{$t('AVAILABLE')}}</label>
                <b-form-input type="number"
                              v-model="promo.available_count"
                ></b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button @click="save" :loading="loader">{{$t('SAVE')}}</app-button>
            </b-col>
            <b-col>
                <app-button-delete @click="deleteCode" class="pull-right align-self-end"></app-button-delete>
            </b-col>
        </b-row>
        <app-confirmation-dialog
            :show="confirmation_dialog_state"
            @confirm="continueAction"
            @cancel="confirmation_dialog_state=false"
            :title="$t('PROMO_DELETE')">
            <p>{{$t('DELETE_DEFAULT_MSG')}}</p>
        </app-confirmation-dialog>
    </b-form>
</template>

<script>
import {deletePromoCode, fetchPromoCategories, fetchPromoCode, updatePromoCode} from "@/services/promo_codes";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import AppTranslationInput from "@/components/app/translation/AppTranslationInput";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import AppButton from "@/components/app/AppButton/AppButton";
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import AppInputControl from "@/components/app/AppInputControl";
import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
import {notifySuccess} from "@/shared/plugins/toastr";
import {EventBus} from "@/shared/EventBus";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {getTranslateList} from "@/services/system";
import {
    PROMO_CODE_CATEGORY_ID_VALIDATION_FAILED,
    PROMO_CODE_CODE_VALUE_VALIDATION_FAILED,
    PROMO_CODE_VALUES_REQUIRED_VALIDATION_FAILED,
    PROMO_CODE_VALUES_RANGE_VALIDATION_FAILED,
    PROMO_CODE_FIX_VALUES_RANGE_VALIDATION_FAILED
} from "@/shared/error_codes";

export default {
    name: "PromoCodeEditForm",
    components:{
        AppObjectHeader,
        AppTranslationInput,
        AppSelect,
        AppDatePicker,
        AppButton,
        AppButtonDelete,
        AppConfirmationDialog,
        AppInputControl,
        AppDateRangePicker
    },
    mixins:[getErrorMessage],
    props:{
        promoId:{
            type: Number,
            default: null
        }
    },
    data(){
        return{
            promo:null,
            categories: null,
            loader: false,
            langList: [],
            confirmation_dialog_state: false,
            validation:{
                code: PROMO_CODE_CODE_VALUE_VALIDATION_FAILED,
                category: PROMO_CODE_CATEGORY_ID_VALIDATION_FAILED,
                value: [
                    PROMO_CODE_VALUES_REQUIRED_VALIDATION_FAILED,
                    PROMO_CODE_VALUES_RANGE_VALIDATION_FAILED,
                    PROMO_CODE_FIX_VALUES_RANGE_VALIDATION_FAILED
                ],
            },
            valueType:[
                {id: 1, name: '%'},
                {id: 2, name: 'fix'}
            ],
            valueSelected: 1,
        }
    },
    computed:{
        periodUse: {
            get() {
                return {
                    start: this.promo.for_dates.from,
                    end: this.promo.for_dates.to
                }
            },
            set({start, end}) {
                this.promo.for_dates.from = start
                this.promo.for_dates.to = end
            }
        },
        periodValid: {
            get() {
                return {
                    start: this.promo.valid_dates.from,
                    end: this.promo.valid_dates.to
                }
            },
            set({start, end}) {
                this.promo.valid_dates.from = start
                this.promo.valid_dates.to = end
            }
        },
    },
    methods:{
        save(){
            this.loader = true
            const promotion = this.promo
            updatePromoCode(this.promoId, {...promotion}).then(response => {
                notifySuccess()
                this.$emit('updateSuccess')
                EventBus.$emit('reloadPromoCodes')
                this.loader = false
            }, error => {
                this.loader = false
                this.showErrorMessages(error, this.validation)
            })
        },
        deleteCode(){
            this.confirmation_dialog_state = true
        },
        continueAction(){
            deletePromoCode(this.promoId).then(response => {
                notifySuccess()
                this.confirmation_dialog_state = false
                this.$emit('updateSuccess')
                EventBus.$emit('reloadPromoCodes')
            }, error => {
                this.confirmation_dialog_state = false
                this.showErrorMessages(error)
            })
        }
    },
    created() {
        fetchPromoCode(this.promoId).then(response => {
            this.promo = response.data
            response.data.value_percentage ? this.valueSelected = 1 : this.valueSelected = 2
        }, error => {
            this.showErrorMessages(error)
        })
        fetchPromoCategories().then(response => {
            this.categories = response.data
        }, error => {
            this.showErrorMessages(error)
        })
        getTranslateList().then(response => {
            this.langList = response.data
        })
    },
    watch:{
        valueSelected:{
            handler(value){
                if (value === 1){
                    this.promo.value_fixed = null
                }else{
                    this.promo.value_percentage = null
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
