<template>
    <b-form v-if="booking_windows.length > 0" @submit.prevent="save">
        <template v-for="(bookingWindow,index) in booking_windows">
            <form-header>
                {{ bookingWindow.interval_from }} - {{ bookingWindow.interval_to }}
            </form-header>
            <b-row>
                <b-col>
                    <table class="table b-table">
                        <thead>
                            <th v-if="type_action === ACTION_TYPE_MIN_PRICE" width="25%">{{ $t('CONDITION') }}</th>
                            <th width="25%">{{ $t('ACTION') }}</th>
                            <template v-if="type_action === ACTION_TYPE_MIN_PRICE">
                                <th>{{ $t("LOWER_LIMIT") }} (%)</th>
                                <th>{{ $t("UPPER_LIMIT") }} (%)</th>
                                <th>{{ $t('RECOMMENDATION_DAY_OFFSET') }}</th>
<!--                                <th width="15%">{{$t('DAY_INTERVAL_BACKWARD') }}</th>-->
                            </template>
                            <th>{{ $t('PRIORITY') }}</th>
                            <th>{{ $t('VALUE') }}
                                <template v-if="type_action === ACTION_TYPE_MIN_PRICE">
                                    (%)
                                </template>
                            </th>
                            <th></th>
                        </thead>
                        <tbody>
                        <tr :key="indexAction"
                            v-for="(actionSetup,indexAction) in getActionByBookingWindow(bookingWindow.id)">
                            <td v-if="type_action === ACTION_TYPE_MIN_PRICE">
                                <app-select @change="updateList(actionSetup)"
                                            v-model="actionSetup.condition"
                                            :options="conditions"
                                            text-field="title"
                                            value-field="id"
                                            :disabled="read_only"
                                ></app-select>
                            </td>
                            <td>
                                <app-select @change="updateList(actionSetup)"
                                            v-model="actionSetup.action"
                                            :options="actions"
                                            text-field="title"
                                            value-field="id"
                                            :disabled="read_only"
                                ></app-select>
                            </td>
                            <template v-if="type_action === ACTION_TYPE_MIN_PRICE">
                                <td>
                                    <app-number-input @change="updateList(actionSetup)"
                                                      v-model="actionSetup.maximum_decrease"
                                                      :disabled="read_only"
                                    ></app-number-input>
                                </td>
                                <td>
                                    <app-number-input @change="updateList(actionSetup)"
                                                      v-model="actionSetup.maximum_increase"
                                                      :disabled="read_only"
                                    ></app-number-input>
                                </td>
                                <td>
                                    <app-number-input @change="updateList(actionSetup)"
                                                      v-model="actionSetup.day_interval_forward"
                                                      :disabled="read_only"
                                    ></app-number-input>
                                </td>
<!--                                <td>-->
<!--                                    <app-number-input @change="updateList(actionSetup)"-->
<!--                                                      v-model="actionSetup.day_interval_backward"-->
<!--                                                      :disabled="read_only"-->
<!--                                    ></app-number-input>-->
<!--                                </td>-->
                            </template>
                            <td>
                                <app-number-input @change="updateList(actionSetup)"
                                                  v-model="actionSetup.priority"
                                                  :disabled="read_only"
                                ></app-number-input>
                            </td>
                            <td>
                                <app-number-input @change="updateList(actionSetup)"
                                                  v-model="actionSetup.value"
                                                  :disabled="read_only"
                                ></app-number-input>
                            </td>
                            <td>
                                <app-button @click="removeRowAction(actionSetup.id)"
                                            class="pull-right"
                                            button_type="delete"
                                            variant="link"
                                            :show_text="false"
                                            :disabled="read_only"
                                ></app-button>
                            </td>
                        </tr>
                        <tr>
                            <td v-if="type_action === ACTION_TYPE_MIN_PRICE">
                                <app-select v-model="condition[bookingWindow.id]"
                                            :options="conditions"
                                            text-field="title"
                                            value-field="id"
                                            :disabled="read_only"
                                ></app-select>
                            </td>
                            <td>
                                <app-select v-model="action[bookingWindow.id]"
                                            :options="actions"
                                            text-field="title"
                                            value-field="id"
                                            :disabled="read_only"
                                ></app-select>
                            </td>
                            <template v-if="type_action === ACTION_TYPE_MIN_PRICE">
                                <td width="5%">
                                    <app-number-input v-model="maximum_decrease[bookingWindow.id]" :disabled="read_only"></app-number-input>
                                </td>
                                <td width="5%">
                                    <b-input v-model="maximum_increase[bookingWindow.id]" :disabled="read_only"></b-input>
                                </td>
                                <td width="10%">
                                    <b-input v-model="day_interval_forward[bookingWindow.id]" :disabled="read_only"></b-input>
                                </td>
<!--                                <td width="10%">-->
<!--                                    <b-input v-model="day_interval_backward[bookingWindow.id]" :disabled="read_only"></b-input>-->
<!--                                </td>-->
                                <td width="2%">
                                    <b-input v-model="priority[bookingWindow.id]" :disabled="read_only"></b-input>
                                </td>
                                <td width="10%">
                                    <b-input v-model="value[bookingWindow.id]" :disabled="read_only"></b-input>
                                </td>
                            </template>
                            <template v-if="type_action === ACTION_TYPE_MIN_STAY">
                                <td>
                                    <b-input v-model="priority[bookingWindow.id]" :disabled="read_only"></b-input>
                                </td>
                                <td>
                                    <b-input v-model="value[bookingWindow.id]" :disabled="read_only"></b-input>
                                </td>
                            </template>
                            <td>
                                <app-button @click="addAction(bookingWindow.id)"
                                            :disabled="read_only || !action[bookingWindow.id]"
                                            class="pull-right"
                                            button_type="new"
                                >{{ $t('ADD_ITEM') }}
                                </app-button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
        </template>

        <b-row>
            <b-col>
                <b-tooltip v-if="read_only" triggers="hover"
                           :target="'submitButton'"
                           :title="read_only_message"
                ></b-tooltip>
                <app-button-submit :id="'submitButton'"
                                   :disabled="read_only || action_save_list.length === 0"
                                   :loading="loader"
                ></app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButton from "@/components/app/AppButton/AppButton";
import {
    getActions,
    getActionSetup,
    getChildBookingWindow,
    getConditions,
    setActionSetup,
} from "@/services/revenue_manager";
import FormHeader from "@/components/app/form/FormHeader";
import {EventBus} from "@/shared/EventBus";
import {toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {ACTION_TYPE_MIN_PRICE, ACTION_TYPE_MIN_STAY} from "@/shared/constants";

export default {
    name: "RevenueManagerSetupActionFormNew",
    components: {FormHeader, AppButton, AppSelect, AppNumberInput, AppButtonDelete, AppButtonSubmit},
    data() {
        return {
            booking_windows: [],
            actions: [],
            object: {},
            loader: false,
            confirmatioDialog: false,
            initialValue: [],
            conditions: [],
            fetchData: false,
            action_setup_list: [],
            new_action_key: 0,
            condition: {},
            action: {},
            maximum_increase: {},
            maximum_decrease: {},
            day_interval_forward: {},
            day_interval_backward: {},
            priority: {},
            value: {},
            new_ids: [],
            remove_action_list: [],
            action_save_list: [],
            ACTION_TYPE_MIN_PRICE,
            ACTION_TYPE_MIN_STAY
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    mixins: [getErrorMessage],
    props: {
        rootBookingWindow: {
            type: Object,
            default() {
                return {}
            }
        },
        object_id: {
            type: Number
        },
        mode: {
            type: String,
            default: 'company'
        },
        type_action: {
            type: Number
        },
        read_only: {
            type: Boolean,
            default: false
        },
        read_only_message: {
            type: String
        },
    },
    methods: {
        updateList(setupAction) {
            setupAction.delete = false
            this.action_save_list.push(setupAction)
        },
        disabledAddRow(booking_window_id) {
            return this.condition[booking_window_id] === null && this.action[booking_window_id] === null
        },
        updateMaximumIncrease(booking_window_id, value) {
            this.$set(this.maximum_increase, booking_window_id, value)
        },
        save() {
            this.loader = true
            let request = {
                type: this.type_action,
                action_setup: []
            }
            if (this.mode === 'unit') {
                request = {...request, ...{unit: this.object_id}}
            } else if (this.mode === 'property') {
                request = {...request, ...{property: this.object_id}}
            } else {
                request = {...request, ...{company: this.object_id}}
            }

            const actionList = this.action_save_list.filter(el => el[this.mode] === this.object_id)
            let list = []
            if (actionList.length > 0) {
                for (const actionSetup of actionList) {
                    let deleteItem = this.remove_action_list.find(el => el.id === actionSetup.id)
                    list.push(
                        {
                            maximum_increase: actionSetup.maximum_increase,
                            maximum_decrease: actionSetup.maximum_decrease,
                            priority: actionSetup.priority,
                            value: actionSetup.value,
                            day_interval_backward: actionSetup.day_interval_backward,
                            day_interval_forward: actionSetup.day_interval_forward,
                            action: actionSetup.action,
                            condition: actionSetup.condition,
                            booking_window: actionSetup.booking_window,
                            id: this.new_ids.includes(actionSetup.id) ? null : actionSetup.id,
                            delete: !this.new_ids.includes(actionSetup.id) ? !!(deleteItem && Object.keys(deleteItem).length > 0) : false

                        }
                    )
                }
            }

            request.action_setup = list
            setActionSetup(request).then(response => {
                this.$emit('close')
                EventBus.$emit('ge_reload_action_table')
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 3000
                })
            }).finally(() => {
                this.loader = false
            })
        },
        addAction(booking_window_id) {
            const actionByBookingWindowList = this.getActionByBookingWindow(booking_window_id)
            const priority = actionByBookingWindowList.length + 1
            this.new_action_key += 1
            const id = this.new_action_key + '_' + this.new_action_key
            let insertObject = {
                condition: this.type_action === ACTION_TYPE_MIN_PRICE ? this.condition[booking_window_id] : this.conditions.length > 0 ? this.conditions[0].id : null,
                action: this.action[booking_window_id],
                maximum_increase: this.maximum_increase[booking_window_id],
                maximum_decrease: this.maximum_decrease[booking_window_id],
                day_interval_forward: this.day_interval_forward[booking_window_id],
                day_interval_backward: this.day_interval_backward[booking_window_id],
                priority: this.priority[booking_window_id] ? this.priority[booking_window_id] : priority,
                value: this.value[booking_window_id],
                delete: false
            }
            insertObject = {...insertObject, ...{id, booking_window: booking_window_id}}
            if (this.mode === 'unit') {
                insertObject = {...insertObject, ...{unit: this.object_id, type: this.type_action}}
            } else if (this.mode === 'property') {
                insertObject = {...insertObject, ...{property: this.object_id, type: this.type_action}}
            } else {
                insertObject = {...insertObject, ...{company: this.object_id, type: this.type_action}}
            }

            this.action_setup_list.push(insertObject)
            this.action_save_list.push(insertObject)
            this.condition[booking_window_id] = null
            this.action[booking_window_id] = null
            this.maximum_increase[booking_window_id] = null
            this.maximum_decrease[booking_window_id] = null
            this.day_interval_forward[booking_window_id] = null
            this.day_interval_backward[booking_window_id] = null
            this.priority[booking_window_id] = null
            this.value[booking_window_id] = null
            this.new_ids.push(id)
        },
        removeRowAction(id) {
            const index = this.action_setup_list.findIndex(el => el.id === id)
            this.remove_action_list.push(this.action_setup_list[index])
            this.action_save_list.push(this.action_setup_list[index])
            this.action_setup_list.splice(index, 1)
        },
        getActionByBookingWindow(booking_window_id) {
            return this.action_setup_list.filter(el => el.booking_window === booking_window_id)
        },
        getProvider(value, booking_window_ids) {
            let request = {
                company: this.company.id
            }
            if (this.mode === 'unit') {
                request = {...request, ...{unit: value, type: this.type_action}}
            } else if (this.mode === 'property') {
                request = {...request, ...{property: value, type: this.type_action}}
            } else {
                request = {...request, ...{company: value, type: this.type_action}}
            }
            if (booking_window_ids.length > 0) {
                request = {...request, ...{booking_window: booking_window_ids}}
            }
            getActionSetup(request).then(response => {
                const data = response.data.items.filter(el => el[this.mode] === this.object_id)
                let list = {}
                for (const actionObject of data) {
                    list[actionObject.booking_window] = actionObject
                }
                this.initialValue = data
                this.action_setup_list = data
            }).finally(() => {

            })
        },
    },
    watch: {
        object_id: {
            handler(value) {
                if (value && Object.keys(this.rootBookingWindow).length > 0) {
                    getChildBookingWindow(this.rootBookingWindow.id).then(response => {
                        if (response.data && response.data.hasOwnProperty('child_booking_windows') && response.data.child_booking_windows.length > 0) {

                            this.booking_windows = response.data.child_booking_windows
                            for (const bW of response.data.child_booking_windows) {
                                this.maximum_increase[bW.id] = null
                            }
                            if (response.data.child_booking_windows.length > 0) {
                                const booking_window_ids = response.data.child_booking_windows.map(el => {
                                    return el.id
                                })
                                this.getProvider(value, booking_window_ids)
                            }
                        }
                    })
                }
            }, immediate: true
        },/*
        rootBookingWindow: {
            handler(bookingWindowObject) {
                if(bookingWindowObject){

                }
            }, immediate: true
        }*/
    },
    created() {
        getConditions({booking_window_setup_type: this.type_action}).then(response => {
            this.conditions = response.data
        })
        getActions({booking_window_setup_type: this.type_action}).then(response => {
            this.actions = response.data
        })
    }
}
</script>

<style scoped>

</style>
