import routeNames from '@/router/routeNames'

export default {
    items: [
        {
            name: 'SIDEBAR.DASHBOARD',
            url: '/dashboard',
            icon: 'fa fa-tachometer'
        },
        {
            name: 'INVENTORY',
            url: '/property',
            icon: 'fa fa-th-list',
            children: [
                {
                    name: 'PROPERTIES',
                    url: {name: routeNames.RN_PROPERTY},
                    icon: 'icon-home'
                },
                // {
                //     name: "Activities",
                //     url: '/404',
                //     icon: 'fa fa-bicycle'
                // },
                // {
                //     name: "Transfers",
                //     url: '/404',
                //     icon: 'fa fa-bus'
                // },
            ]
        },
        // {
        //     name: 'CALENDAR_TEST',
        //     url: '/calendar-old',
        //     icon: 'fa fa-calendar',
        // },
        {
            name: 'CALENDAR',
            url: '/calendar',
            icon: 'fa fa-calendar',
        },
        {
            icon: 'fa fa-line-chart',
            name: 'REVENUE_MANAGER',
            url: {name: routeNames.RN_REVENUE_MANAGER_ACTIVATION}
        },
        {
            icon: 'fa fa-line-chart',
            name: 'REVENUE_MANAGER',
            url: {name: routeNames.RN_REVENUE_MANAGER},
            children: [
                {
                    name: 'SETUP',
                    url: {name: routeNames.RN_REVENUE_MANAGER_SETUP_ACTION},
                    icon: 'fa fa-wrench',

                },
                {
                    name:'COMPETITORS',
                    url:{name:routeNames.RN_REVENUE_MANAGER_SIMILAR_LISTING},
                    icon: 'fas compress-arrows-alt'
                },
                {
                    name: 'BOOKING_WINDOWS',
                    url: {name: routeNames.RN_REVENUE_MANAGER_BOOKING_WINDOW_LIST},
                    icon: 'fas arrows-alt-h'
                },
            ]
        },
        {
            icon: 'fa fa-book',
            name: 'RESERVATIONS',
            url: '/reservation',
            children: [
                {
                    icon: 'fa fa-hotel',
                    name: 'ACCOMMODATION',
                    caption:'RESERVATIONS',
                    url: {name: routeNames.RN_RESERVATION_ACCOMMODATION},

                },
                {
                    icon: 'fa fa-car',
                    name: 'TRANSFERS',
                    caption:'TRANSFER_RESERVATIONS',
                    url: {name: routeNames.RN_RESERVATION_TRANSFER_LIST},

                },
                {
                    icon: 'fas hiking',
                    name: 'ACTIVITIES',
                    caption: 'ACTIVITY_RESERVATIONS',
                    url: {name: routeNames.RN_RESERVATION_ACTIVITY_LIST},
                },
                // {
                //     icon: 'fa fa-bicycle',
                //     name: "Activities",
                //     url: '/404',
                //
                // },
                // {
                //     icon: 'fa fa-car',
                //     name: "Transfers",
                //     url: '/404',
                // }
            ]
        },
        {
            icon: 'fa fa-retweet',
            name: 'DIRECT_BOOKING',
            url: {name: routeNames.RN_DIRECT_BOOKING_ACTIVATION}
        },
        {
            icon: 'fa fa-retweet',
            name: 'DIRECT_BOOKING',
            url: {name: routeNames.RN_DIRECT_BOOKING},
            children: [
                {
                    name: 'WEBSITES',
                    url: {name: routeNames.RN_DIRECT_BOOKING_WEBSITE_ROOT},
                    icon: 'fa fa-globe',
                },
                {
                    name: 'DESTINATIONS',
                    url: {name: routeNames.RN_SETUP_DESTINATION_TAG},
                    icon: 'fa fa-flag',
                },
                // {
                //     name: 'TAGS',
                //     url: {name: routeNames.RN_SETUP_CUSTOM_TAG},
                //     icon: 'fa fa-tag',
                // },
                {
                    name: 'LOYALTY_PROGRAM',
                    url: {name: routeNames.RN_DIRECT_BOOKING_PROMO_CODES},
                    icon: 'fa fa-trophy',
                },
                {
                    name: 'B2B',
                    url: {name: routeNames.RN_DIRECT_BOOKING_MARKETPLACE},
                    icon: 'fa fa-shopping-basket',
                },
                // {
                //     name: 'SETUP',
                //     url: {name: routeNames.RN_DIRECT_BOOKING_SETUP},
                //     icon: 'fa fa-wrench',
                // },
            ]
        },
        {
            name:'REVIEWS',
            url:'/review',
            icon: 'fa fa-star-o',
            children:[
                {
                    name:'ACCOMMODATION',
                    url:'/review/accommodation/list',
                    icon: 'fa fa-hotel',
                },
                {
                    icon: 'fa fa-car',
                    name: 'TRANSFERS',
                    url:'/review/transfer/list',
                },
                {
                    icon: 'fas hiking',
                    name: 'ACTIVITIES',
                    url:'/review/activity/list',
                },

            ]
        },

        {
            name: 'INBOX',
            url: '/inbox',
            icon: 'fa fa-envelope-o',
        },
        {
            name: 'GUEST_BOOK',
            url: '/guest-list',
            icon: 'fa fa-users',
        },
        {
            name: 'FINANCE',
            url: '/finance',
            icon: 'fa fa-money',
            children: [
                {
                    name: 'OWNER_STATEMENTS',
                    url: {name: routeNames.RN_OWNER_CALCULATION},
                    icon: 'fa fa-calculator',
                },
                {
                    name: 'INVOICES',
                    url: {name: routeNames.RN_INVOICE_LIST},
                    icon: 'fas file-invoice',
                },
                {
                    name: 'INVOICES',
                    url: {name: routeNames.RN_OWNER_INVOICE_LIST},
                    icon: 'fas file-invoice',
                },
                {
                    name: 'PAYMENT_ORDERS',
                    url: {name: routeNames.RN_PAYMENT_ORDER_LIST},
                    icon: 'fas receipt',
                },
                {
                    name: 'PAYMENT_MESSAGES',
                    url: {name: routeNames.RN_PAYMENT_MESSAGE},
                    icon: 'fa fa-book',
                },
                {
                    name:'TRANSACTIONS',
                    url:{name: routeNames.RN_TRANSACTION_LIST},
                    icon: 'fas exchange-alt',
                }
            ]
        },

        {
            icon: 'fa fa-link',
            name: 'INTEGRATIONS',
            url: '/integrations',
            children: [
                {
                    name: 'CHANNEL_MANAGER',
                    url: {name: routeNames.RN_CHANNEL_MANAGER},
                    icon: 'fas project-diagram',
                },
                {
                    name: 'PAYMENT_GATEWAYS',
                    url: {name: routeNames.RN_PAYMENT_GATEWAY},
                    icon: 'fa fa-credit-card',
                },
            ]
        },
        {
            icon: 'fa fa-cogs',
            name: 'SYSTEM',
            url: "/system",
            children: [
                {
                    icon: 'fa fa-sitemap',
                    name: 'ORGANIZATIONS',
                    url: {name: routeNames.RN_COMPANY_ROOT},
                },
                {
                    icon: 'fa fa-user',
                    name: 'USERS',
                    url: {name: routeNames.RN_USER_ROOT},
                },
                {
                    icon: 'fa fa-wrench',
                    name: 'SETTINGS',
                    url: {name: routeNames.RN_SETUP}
                },
                {
                    icon: 'fa fa-heartbeat',
                    name: 'HEALTH_MONITOR',
                    url: {name: routeNames.RN_HEALTH_MONITOR}
                },
                // {
                //     icon: 'fa fa-paint-brush',
                //     name: 'APPEARANCE',
                //     url:{name: routeNames.RN_SYSTEM_APPEARANCE}
                // }
                // {
                //     icon: 'fa fa-handshake-o',
                //     name: "Roles",
                //     url: '/404',
                // },
                // {
                //     icon: 'fa fa-address-book',
                //     name: "Users",
                //     url: '/404',
                // },
                // {
                //     icon: 'fa fa-key',
                //     name: "Permissions",
                //     url: '/404',
                // },
                // {
                //     icon: 'fa fa-shopping-bag',
                //     name: "Packages",
                //     url: '/404',
                // },
                // {
                //     icon: 'fa fa-arrows',
                //     name: "Workflows",
                //     url: '/404',
                // },
                // {
                //     icon: 'fa fa-clock-o',
                //     name: "Subscriptions",
                //     url: '/404',
                // }
            ]
        },
    ]
}
