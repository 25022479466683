<template>
    <div class="mini_calendar--cell position-relative mini_calendar--availability-cell"
         :style="{ 'grid-column': columnPosition + '/ span ' + day.colspan } "
         :class="{ 'main_calendar_test__bg-hover': highlighted, 'main_calendar_test__bg-hover_note':day.note_highlight.value }"
    >
        <div v-if="day.reservation"
             class=" mini_calendar--status mini_calendar--background_primary cell"
             :class="{
                    'mini_calendar--status-start-res-before':day.reservation.check_in_date === availabilityItemKey && day.isCheckOutThisWeek,
                    'mini_calendar--status-start-res_before-no-check-out':day.reservation.check_in_date === availabilityItemKey && !day.isCheckOutThisWeek,
                    'mini_calendar--status-end':day.isCheckOutThisWeek,
                    'mini_calendar--reservation':day.isCheckOutThisWeek && !day.isCheckInThisWeek,
                    'mini_calendar--status-start-res_before-no-check-out-colspan-0':day.colspan === 0,
                    'mini_calendar--status-start-res_before-no-check-out-colspan-1':day.colspan === 1,
                    'mini_calendar--status-start-res_before-no-check-out-colspan-2':day.colspan === 2,
                    'mini_calendar--status-start-res_before-no-check-out-colspan-3':day.colspan === 3,
                    'mini_calendar--status-start-res_before-no-check-out-colspan-4':day.colspan === 4,
                    'mini_calendar--status-start-res_before-no-check-out-colspan-5':day.colspan === 5,
                    'mini_calendar--status-start-res_before-no-check-out-colspan-6':day.colspan === 6,
                    'mini_calendar--status-start-res_before-no-check-out-colspan-7':day.colspan === 7,
                    }"
             :id="`popover-reservation-${day.reservation.id}-${day.date.format('YYYY-MM-DD')}`"
             @click="$emit('showReservation', day)"
             @mouseenter.stop="$emit('mouseEnter', day)"
             @mouseleave="$emit('mouseLeave', day)"
        >

            <div v-if="isCheckIn || isFirstDayOfFirstMonth" class="reservation-colspan-cell justify-content-start">
                <div class="main_calendar_test-guest--icon">
                    <b-img fluid :src="day.reservation.channel_icon"></b-img>
                </div>
            </div>

            <div v-for="colspanDay in calcColspanDays" class="reservation-colspan-cell justify-content-end align-items-center text-center" style="font-size: 0.625rem">
                {{  colspanDay }}
            </div>

            <b-popover
                v-if="day.showResPopup && day.showResPopup.show"
                container="my-container_calendar"
                class="calendar-popover"
                :show.sync="day.showResPopup.show"
                :no-fade="true"
                placement="left"
                :id="`popover-reservation-id-${day.reservation.id}-${day.date.format('YYYY-MM-DD')}`"
                :target="`popover-reservation-${day.reservation.id}-${day.date.format('YYYY-MM-DD')}`">
                <template v-slot:title>{{day.reservation.formatted_check_in_date}} - {{day.reservation.formatted_check_out_date}}
                </template>
                <b-list-group>
                    <p class="d-flex justify-content-between align-items-center">
                        {{day.translations.RESERVATION_CODE }}: {{day.reservation.distribution_booking_id }}
                    </p>
                    <p v-if="show_channel_data" class="d-flex justify-content-between align-items-center">
                        {{day.translations.SALES_CHANNEL}}: {{ day.reservation.channel.name }}
                    </p>
                    <p class="d-flex justify-content-between align-items-center">
                        {{day.translations.GUEST }}: {{ day.reservation.first_name }} {{day.reservation.last_name }}
                    </p>
                    <p class="d-flex justify-content-between align-items-center">
                        {{day.translations.ADULTS }} +18 / {{day.translations.CHILDREN }} 3-18 / {{ day.translations.BABIES }} 0-3:
                        {{day.reservation.adult_no }} / {{day.reservation.child_no ? day.reservation.child_no : 0}} /
                        {{day.reservation.baby_no ? day.reservation.baby_no : 0 }}
                    </p>
                    <p class="d-flex justify-content-between align-items-center">
                        {{day.translations.TOTAL }}: {{day.reservation.channel_total }}
                    </p>
                    <p v-if="day.reservation.check_in_time"
                       class="d-flex justify-content-between align-items-center">
                        {{ day.translations.ARRIVAL_TIME }}: {{ day.reservation.formatted_check_in_time}}
                    </p>
                    <p class="d-flex justify-content-between align-items-center">
                        {{day.translations.BOOKED_AT }}: {{day.reservation.formatted_created_date }}
                    </p>
                    <p class="d-flex justify-content-between align-items-center">
                        {{day.translations.TAG }}: {{ day.reservation.tags }}
                    </p>
                </b-list-group>
            </b-popover>
        </div>
        <div v-if="startOfWeekOrMonth && (!day.reservation || isCheckIn || isCheckOut)" class="justify-content-start pull-left"
             :class="{
                    'mini_calendar--status':day.hasReservationOnDayBefore && (!day.reservation || isCheckIn),
                    'mini_calendar--background_primary ':day.hasReservationOnDayBefore && (!day.reservation || isCheckIn),
                    'mini_calendar--status-end_of_res':day.hasReservationOnDayBefore && (!day.reservation || isCheckIn)}"
             :id="day.hasReservationOnDayBefore && day.reservationOnDayBefore && day.reservationOnDayBefore.id ? `popover-reservation-${day.reservationOnDayBefore.id}-${day.date.format('YYYY-MM-DD')}` : ''"
             @click="$emit('showReservationOnDayBefore', day)"
             @mouseenter.stop="$emit('mouseEnterOnDayBefore', day)"
             @mouseleave="$emit('mouseLeaveOnDayBefore', day)">

            <b-popover
                v-if="day.showResPopupOnDayBefore && day.showResPopupOnDayBefore.show && day.hasReservationOnDayBefore && day.reservationOnDayBefore && day.reservationOnDayBefore.id"
                container="my-container_calendar"
                class="calendar-popover"
                :show.sync="day.showResPopupOnDayBefore.show"
                :no-fade="true"
                placement="left"
                :id="`popover-reservation-id-${day.reservationOnDayBefore.id}-${day.date.format('YYYY-MM-DD')}`"
                :target="`popover-reservation-${day.reservationOnDayBefore.id}-${day.date.format('YYYY-MM-DD')}`">
                <template v-slot:title>{{day.reservationOnDayBefore.formatted_check_in_date}} - {{day.reservationOnDayBefore.formatted_check_out_date}}
                </template>
                <b-list-group>
                    <p class="d-flex justify-content-between align-items-center">
                        {{day.translations.RESERVATION_CODE }}: {{day.reservationOnDayBefore.distribution_booking_id }}
                    </p>
                    <p v-if="show_channel_data" class="d-flex justify-content-between align-items-center">
                        {{day.translations.SALES_CHANNEL}}: {{ day.reservationOnDayBefore.channel.name }}
                    </p>
                    <p class="d-flex justify-content-between align-items-center">
                        {{day.translations.GUEST }}: {{ day.reservationOnDayBefore.first_name }} {{day.reservationOnDayBefore.last_name }}
                    </p>
                    <p class="d-flex justify-content-between align-items-center">
                        {{day.translations.ADULTS }} +18 / {{day.translations.CHILDREN }} 3-18 / {{ day.translations.BABIES }} 0-3:
                        {{day.reservationOnDayBefore.adult_no }} / {{day.reservationOnDayBefore.child_no ? day.reservationOnDayBefore.child_no : 0}} /
                        {{day.reservationOnDayBefore.baby_no ? day.reservationOnDayBefore.baby_no : 0 }}
                    </p>
                    <p class="d-flex justify-content-between align-items-center">
                        {{day.translations.TOTAL }}: {{day.reservationOnDayBefore.channel_total }}
                    </p>
                    <p v-if="day.reservationOnDayBefore.check_in_time"
                       class="d-flex justify-content-between align-items-center">
                        {{ day.translations.ARRIVAL_TIME }}: {{ day.reservationOnDayBefore.formatted_check_in_time}}
                    </p>
                    <p class="d-flex justify-content-between align-items-center">
                        {{day.translations.BOOKED_AT }}: {{day.reservationOnDayBefore.formatted_created_date }}
                    </p>
                    <p class="d-flex justify-content-between align-items-center">
                        {{day.translations.TAG }}: {{ day.reservationOnDayBefore.tags }}
                    </p>
                </b-list-group>
            </b-popover>
        </div>

        <div
            v-if="!day.reservation"
            :id="`popover-note-${day.note_highlight.key}-${availabilityItemKey}`"
            @click.shift.exact="$emit('drag', day.date)"
            @click.exact="$emit('startEndDrag', day.date)"
            @mouseover.shift="$emit('currentDrag', day.date)"
            @mouseenter.stop="$emit('mouseEnterNote', day.date)"
            @mouseleave="$emit('mouseLeaveNote', day.date)"

            class="justify-content-between flex-column"
        >
            <div class="justify-content-between" style="line-height: 0.75rem; width: 100%">
                <div v-if="isAvailable && isContigent && contigentLabel !== ''"
                     class="availability_calendar_numeric-indicator ml-1 flex align-self-start justify-content-start">
                    {{ contigentLabel }}
                </div>
                <div v-else-if="isAvailable && !isOpenForSale"
                     class="availability_calendar_numeric-indicator flex align-self-start justify-content-start">
                    <i class="fa fa-circle ml-1 flex availability_calendar_indicator-open" style="font-size: 0.3rem"></i>
                </div>
                <div v-else
                     class="availability_calendar_numeric-indicator ml-1 pb-1  flex align-self-start">
                </div>

                <div class="availability_calendar_numeric-indicator mr-1 pb-1 flex align-self-end justify-content-end">
                    <i v-if="!isAvailable && isOpenForSale" class="fa fa-circle ml-1 flex availability_calendar_indicator-open" style="font-size: 0.3rem" ></i>
                </div>
            </div>

            <div class="justify-content-center" style="line-height: 0.75rem;">
                <template v-if="isAvailable && isOpenForSale">
                    <span v-if="defaultRates !== ''"
                          class="justify-content-center">
                      {{ defaultRates }}
                    </span>
                    <span v-else-if="minStay !== ''"
                          class="justify-content-center">
                      {{ minStay }}
                    </span>
                    <i v-else
                       class="fa fa-circle justify-content-center availability_calendar_indicator-open" style="font-size: 0.75rem"></i>
                </template>
                <i v-else class="fa fa-circle justify-content-center availability_calendar_indicator-close" style="font-size: 0.75rem"></i>
            </div>

            <div class="align-self-end mr-1" style="line-height: 0.75rem; font-size: 0.625rem;">
                {{ day.date.format('DD') }}
            </div>

        </div>

        <b-popover
            v-if="!day.reservation && !isAvailable && isOpenForSale && day.note && day.note_highlight && day.note_highlight.key && day.showNotePopup && day.showNotePopup.show"
            container="my-container_calendar_note"
            class="calendar-popover"
            :show.sync="day.showNotePopup.show"
            :no-fade="true"
            placement="left"
            :target="`popover-note-${day.note_highlight.key}-${availabilityItemKey}`">
            <template v-slot:title> {{ day.note.period_start_foramtted }} - {{ day.note.period_end_foramtted }}</template>
            <b-list-group>
                <p class="d-flex justify-content-between align-items-center">
                    {{day.translations.NOTE}}: {{day.note.note}}
                </p>
                <p>{{day.translations.USER}}: {{day.note.user_first_name}} {{day.note.user_last_name}}</p>
                <p class="d-flex justify-content-between align-items-center">
                    {{day.translations.CLOSED_AT}}: {{day.note.timestamp_formatted}}
                </p>
            </b-list-group>
        </b-popover>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: "YearCalendarItemCellTest",
    props: {
        unit: {
            type: Object
        },
        day: {
            type: Object
        },
        isFirstMonth: {
            type: Boolean,
            default: false
        },
        isContigent: {
            type: Boolean,
            default: false
        },
        highlighted: {
            type: Boolean,
            default: false
        },
        noteHighLight:{
            type:Object
        },
        show_channel_data: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            show: false,
        }
    },
    computed: {
        startOfWeekOrMonth() {
            return (this.day.date.isoWeekday() === 1 || this.day.date.format('D') === '1') && this.day.hasReservationOnDayBefore
        },
        calcColspan() {
            if (this.day.colspan === 1) return 0
            return this.day.colspan - 1
        },
        calcColspanDays() {
            let currentDate = moment(this.day.date.format('YYYY-MM-DD'))
            let return_list = []
            if (!this.isCheckIn && !this.isFirstDayOfFirstMonth) {
                return_list.push(moment(currentDate).format('D'))
            }

            if (this.calcColspan > 0) {
                for (let increment = 0; increment < this.calcColspan; increment++) {
                    currentDate = moment(currentDate).add(1, 'day')
                    return_list.push(moment(currentDate).format('D'))
                }
            }
            return return_list
        },
        isOpenForSale() {
            return (this.day.availabilityObject && this.day.availabilityObject.hasOwnProperty('open_for_sale') && this.day.availabilityObject.open_for_sale !== 0);
        },
        isAvailable() {
            if (this.isContigent) {
                if (this.day.availabilityObject && this.day.availabilityObject.hasOwnProperty('available_unit') && this.day.availabilityObject.available_unit.length) {
                    return true
                }
                return !!(this.day.availabilityObject && this.day.availabilityObject.hasOwnProperty('available_count') && this.day.availabilityObject.available_count);
            } else {
                if (this.day.availabilityObject && this.day.availabilityObject.hasOwnProperty('available_unit') && this.day.availabilityObject.available_unit.includes(this.unit.id)) {
                    return true
                }
                return !!(this.day.availabilityObject && this.day.availabilityObject.hasOwnProperty('available') && this.day.availabilityObject.available);
            }
        },
        isCheckIn() {
            if (this.day.reservation) {
                return this.day.reservation.check_in_date === this.availabilityItemKey
            }
            return false
        },
        isCheckOut() {
            if (this.day.reservation) {
                return this.day.reservation.check_out_date === (moment(this.availabilityItemKey).add(1, 'day').format("YYYY-MM-DD"))
            }
            return false
        },
        availabilityItemKey() {
            return this.day.date.format("YYYY-MM-DD")
        },
        columnPosition() {
            return this.day.date.isoWeekday()
        },
        contigentLabel() {
            if (this.isContigent && this.day.availabilityObject && this.day.availabilityObject.hasOwnProperty('available_unit')) {
                return this.day.availabilityObject.available_unit.length > 0 ? this.day.availabilityObject.available_unit.length : ''
            }
            if (this.isContigent && this.day.availabilityObject && this.day.availabilityObject.hasOwnProperty('available_count')) {
                return this.day.availabilityObject.available_count > 0 ? this.day.availabilityObject.available_count : ''
            }
            return ''
        },
        defaultRates() {
            return (this.day.availabilityObject && this.day.availabilityObject.hasOwnProperty('default_rates') && this.day.availabilityObject.default_rates !== null) ? this.day.availabilityObject.default_rates : '';
        },
        pricingDefaults() {
            return (this.day.availabilityObject && this.day.availabilityObject.hasOwnProperty('pricing_defaults') && this.day.availabilityObject.pricing_defaults !== null) ? this.day.availabilityObject.pricing_defaults : '';
        },
        minStay() {
            return (this.day.availabilityObject && this.day.availabilityObject.hasOwnProperty('min_stay') && this.day.availabilityObject.min_stay !== null) ? this.day.availabilityObject.min_stay : '';
        },
        isFirstDayOfFirstMonth() {
            return (this.isFirstMonth && this.day.date.format('D') === '1')
        },
    }
}
</script>

<style scoped>

.mini_calendar--cell {
    cursor: pointer;
}

</style>
