<template>
    <b-nav-item-dropdown
        v-show="show"
        no-caret
        :extra-toggle-classes="activeClass" left>
        <template slot="button-content">
            <span v-html="label"></span>
            <i class="fa fa-chevron-down fa-fw nav-dropdown-chevron" aria-hidden="true"></i>
        </template>
        <navigation-dropdown-item @show="showDropdown" :key="index" :item="dropdownItem"
                                  @AccessControlEvent="$emit('AccessControlEvent', dropdownItem)"
                                  :access_control_context="dropdownItem.hasOwnProperty('access_control_context') ? dropdownItem.access_control_context : null"
                                  v-for="(dropdownItem, index) in navElement.children"></navigation-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
    import NavigationDropdownItem from "@/components/app/navigation/NavigationDropdownItem";

    export default {
        name: "NavigationDropdown",
        components: {NavigationDropdownItem},
        props: ["navElement"],
        data() {
            return {
                show: false
            }
        },
        methods: {
            showDropdown(value) {
                if (value) {
                    this.show = true
                }
            },
        },
        computed: {
            label() {
                if (this.navElement.icon) {
                    return '<i class="' + this.navElement.icon + '"></i> ' + this.navElement.label
                } else {
                    return this.navElement.label
                }
            },
            activeClass() {
                // if it is the same route we will add active, if not, we will also remove highlighting the clicked route
                let routeFound = this.navElement.children.find(item => item.route.name === this.$route.name)
                return typeof routeFound === 'undefined' ? '' : 'active'
            }
        },
    }
</script>

<style lang="scss">

</style>
