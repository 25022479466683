<template>
    <div>
        <b-card v-cloak
                :class="root_unit_count === 0 ? 'main-section-card' : 'property-wrapper'">
            <template slot="header">
                <h2 class="property-wrapper-padding">{{ $t('CALENDAR') }}</h2>
                <app-button v-if="checkPermission(MAIN_CALENDAR_LOG_V) && property_count > 0 && root_unit_count > 0"
                            @click="aside_log =true" class="pull-right ml-3">
                    {{ $t('OVERVIEW_SALE_TERMS_CHANGE_LOG') }}
                </app-button>
                <app-button
                    v-if="checkPermission(C_RESERVATION_ACCOMMODATION_NEW) && property_count > 0 && root_unit_count > 0"
                    button_type="new" class="ml-3 pull-right" variant="primary" @click="res_aside=true">{{
                        $t("NEW_RESERVATION")
                    }}
                </app-button>
                <app-button
                    v-if="checkPermission(MAIN_CALENDAR_AVAILABILITY_QUOTE) && property_count > 0 && root_unit_count > 0 && $route.name === routeNames.RN_CALENDAR_MULTI_UNIT_VIEW && display_work_mode === MULTI_UNIT_VIEW "
                    class="pull-right" @click="openAdvancedFilter">{{ $t('AVAILABILITY_AND_QUOTE') }}
                </app-button>
                <Navigation  v-if="property_count > 0 && root_unit_count > 0"
                            :nav-elements="navElements">
                </Navigation>
            </template>

            <app-no-data v-if="property_count === 0" :heading="$t('MODULE_NOT_ACTIVE')"
                         :tip="$t('MODULE_NEED_PROPERTIES')" :show-tip="true">
                <new-button v-if="checkPermission(R_PROPERTY_NEW)" button_type="new" variant="primary"
                            class="pull-right ml-3" @click="property_aside = true">
                    {{ $t('NEW_PROPERTY') }}
                </new-button>
                <app-button
                    :access_control_context="{function: C_PROPERTY_IMPORT_FROM_OTA, key: access_control_fetch_key}"
                    @AccessControlEvent="addToAccessControlCounter()"
                    class="pull-right ml-3"
                    @click="importPropertyModalState = true">
                    {{ $t("IMPORT_FROM_OTA") }}
                </app-button>

            </app-no-data>
            <app-no-data v-if="property_count === 1 && root_unit_count === 0 && property"
                         :heading="$t('MODULE_NOT_ACTIVE')"
                         :tip="$t('MODULE_NEED_UNITS')" :show-tip="true">
                <app-button button_type="new"
                            @click="unit_aside = true">
                    {{ $t('NEW_UNIT') }}
                </app-button>
            </app-no-data>
            <app-no-data v-if="property_count > 1 && root_unit_count === 0"
                         :heading="$t('MODULE_NOT_ACTIVE')" :show-tip="false"
            >
                <app-button v-if="checkPermission(R_PROPERTY_LIST)" variant="link"
                            :button_type="null"
                            class="action_button"
                            @click="$emit('close')"
                            :to="{name: routes.RN_PROPERTY_LIST}">
                    {{ $t('MODULE_NEED_UNITS') }}
                </app-button>
            </app-no-data>
            <app-aside v-model="res_aside">
                <template slot="header">
                    {{ $t('NEW_RESERVATION') }}
                </template>
                <new-accommodation-reservation-form-test @created="createdReservation"
                                                         :redirect="false"
                                                         :property-id="propertyId"
                                                         :unit-id="unitId">
                </new-accommodation-reservation-form-test>
            </app-aside>

            <router-view></router-view>
            <b-modal
                v-model="importPropertyModalState"
                :title="$t('IMPORTANT')">
                <p>{{ $t("IMPORT_FROM_DISTRIBUTION", {"distribution": "Booking.com"}) }}</p>
                <ul>
                    <li>{{ $t("IMPORT_PROPERTY_FROM_BOOKING_DISCLAIMER_1") }}</li>
                    <li>{{ $t("IMPORT_PROPERTY_ADDITIONAL_COSTS_DISCLAIMER") }}</li>
                </ul>
                <app-button
                    @click="importFromDistribution(BOOKING)">
                    {{ $t("IMPORT") }}
                </app-button>
                <br/>
                <br/>
                <p>{{ $t("IMPORT_FROM_DISTRIBUTION", {"distribution": "AirBnB"}) }}</p>
                <ul>
                    <li>{{ $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_1") }}</li>
                    <li>{{
                            $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_2", {
                                "min_image_width": 2048,
                                "min_image_height": 1536
                            })
                        }}
                    </li>
                    <li>{{ $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_3") }}</li>
                    <li>{{ $t("IMPORT_PROPERTY_ADDITIONAL_COSTS_DISCLAIMER") }}</li>
                </ul>
                <app-button
                    @click="importFromDistribution(AIRBNB)">
                    {{ $t("IMPORT") }}
                </app-button>
                <template slot="modal-footer">
                    <template slot="modal-ok">
                    </template>
                </template>
            </b-modal>
        </b-card>
        <app-aside :widths="['col-lg-12','col-sm-12', '100%']" full-width v-model="aside_log">
            <template v-slot:header>
                {{ $t('OVERVIEW_SALE_TERMS_CHANGE_LOG') }}
            </template>
            <logs :revenue-manager-log="revenue_manager_log"></logs>
        </app-aside>
        <app-aside v-model="property_aside" :widths="['col-sm-12', '40%']">
            <template slot="header">{{ $t('NEW_PROPERTY') }}</template>
            <property-wizard></property-wizard>
        </app-aside>
        <app-aside v-model="unit_aside" :widths="['col-sm-12', '40%']">
            <template slot="header">{{ $t('NEW_UNIT') }}</template>
            <unit-wizard :property="propertyData" :property-route="false"></unit-wizard>
        </app-aside>
        <app-aside
            v-model="importPropertyWizardState"
            :widths="['col-lg-12', '100%']">
            <template slot="header">{{ $t('IMPORT_FROM_OTA') }}</template>
            <import-property-wizard
                @importProperty="closeImportWizard"
                @close="closeImportWizard"
                :distribution="importDistribution"
                :entity="importEntity">
            </import-property-wizard>
        </app-aside>
    </div>
</template>

<script>
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import PropertyWizard from "@/components/property/new/PropertyWizard";
import UnitWizard from "@/components/unit/new/UnitWizard";
import PropertyCalendarWrapperTest from "@/components/property/calendar/PropertyCalendarWrapperTest";
import CalendarAside from "@/components/unit/main_calendar/Aside/Calendar/CalendarAside";
import AppAside from "@/components/app/form/AppAside";
import CalendarWrapperTest from "@/components/calendar/CalendarWraperTest";
import AppNoData from "@/components/app/AppNoData";
import AppAccessControl from "@/components/app/AppAccessControl";
import {
    MAIN_CALENDAR_V,
    MAIN_CALENDAR_AVAILABILITY_QUOTE,
    C_RESERVATION_ACCOMMODATION_NEW,
    MAIN_CALENDAR_LOG_V
} from "@/shared/component_permission";
import AppButton from "@/components/app/AppButton/AppButton";
import NewButton from '@/components/app/AppButton/AppButton';
import ImportPropertyWizard from "@/components/property/import/ImportPropertyWizard";
import {
    EventBus,
    GE_CALENDAR_ADVANCED_FILTER,
    GE_CALENDAR_LOAD_ADD_MONTH,
    GE_CALENDAR_LOAD_AVAILABILITY,
    GE_CALENDAR_LOAD_PRICES,
    GE_CALENDAR_LOAD_RESTRICTIONS,
    GE_CALENDAR_OPEN_AVAILABILITY_PROPERTY,
    GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR,
    GE_CALENDAR_REFRESH_DATA,
    GE_CALENDAR_SELECT_ALL_UNIT,
    GE_CALENDAR_SINGLE_UNIT,
    GE_CALENDAR_UPDATE_AVAILABILITY_LIST,
    GE_OPEN_NEW_ACC_RES_ASIDE,
    GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_DRAG_CURRENT_PROP,
    GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_DRAG_PERIOD_START,
    GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_UNIT_ROW_INDEX,
} from "@/shared/EventBus";
import {
    DEFAULT_UNIT_NUMBER_LIMIT,
    LS_PRESETS,
    AIRBNB,
    BOOKING,
    MONTHLY_VIEW,
    MULTI_UNIT_VIEW,
    MIN_WINDOW_WIDTH
} from "@/shared/constants";
import NewAccommodationReservationFormTest
    from "@/components/reservation/accommodation/forms/NewAccommodationReservationFormTest";
import LogFilter from "@/components/logs/LogFilter";
import LogTable from "@/components/logs/LogTable";
import Logs from "@/components/logs/Logs";
import Navigation from "@/components/app/navigation/Navigation";
import routeNames from "@/router/routeNames";
import {getPresets} from "@/services/user";
import {getProperty, getPropertyList} from "@/services/property";
import {C_PROPERTY_IMPORT_FROM_OTA, R_PROPERTY_NEW, R_PROPERTY_LIST} from "@/shared/route_permission";
import {AC_PROPERTY_IMPORT} from "@/mixins/AccessControl/AccessControlEnumeration";
import routes from '@/router/routeNames'

export default {
    name: "TestIndex",
    components: {
        Navigation,
        Logs,
        LogTable,
        LogFilter,
        NewAccommodationReservationFormTest,
        AppButton,
        AppAccessControl,
        AppNoData,
        CalendarWrapperTest, CalendarAside, PropertyCalendarWrapperTest, AppAside,
        PropertyWizard, UnitWizard, NewButton, ImportPropertyWizard
    },
    mixins: [AccessControlComponent],
    data() {
        return {
            routeNames,
            routes,
            empty_initial_list: false,
            showPropertyItem: true,
            MAIN_CALENDAR_V,
            MAIN_CALENDAR_AVAILABILITY_QUOTE,
            selected_unit: [],
            C_RESERVATION_ACCOMMODATION_NEW,
            C_PROPERTY_IMPORT_FROM_OTA,
            R_PROPERTY_NEW,
            R_PROPERTY_LIST,
            property_count: null,
            root_unit_count: 0,
            res_aside: false,
            aside_log: false,
            unit_aside: false,
            MAIN_CALENDAR_LOG_V,
            MULTI_UNIT_VIEW,
            MIN_WINDOW_WIDTH,
            revenue_manager_log: false,
            display_work_mode: MULTI_UNIT_VIEW,
            navElements: [
                {route: {name: routeNames.RN_CALENDAR_MULTI_UNIT_VIEW}},
                {route: {name: routeNames.RN_CALENDAR_SINGLE_UNIT}}
            ],
            property: null,
            propertyData: null,
            propertyId: null,
            unitId: null,
            importPropertyModalState: false,
            importPropertyWizardState: false,
            BOOKING,
            AIRBNB,
            importDistribution: null,
            importEntity: null,
            property_aside: false,
            access_control_components: 1,
            access_control_fetch_key: AC_PROPERTY_IMPORT,
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCompany']
        }
    },
    methods: {
        createdReservation(reservation) {
            this.res_aside = false
            EventBus.$emit(GE_CALENDAR_REFRESH_DATA, {unitId: reservation.unit.id})
        },
        openAdvancedFilter() {
            EventBus.$emit(GE_CALENDAR_ADVANCED_FILTER)
        },
        redirectToCalendarModeView(storagePresets) {
            if (storagePresets && typeof storagePresets === 'object') {
                this.property_count = storagePresets.hasOwnProperty('properties') ? storagePresets.properties : 0
                this.root_unit_count = storagePresets.hasOwnProperty('root_units') ? storagePresets.root_units : 0

                if (this.property_count === 0) {
                    this.empty_initial_list = true
                }
                if (this.property_count === 1) {
                    this.showPropertyItem = false
                }
                if (this.property_count === 1 && this.root_unit_count === 0) {
                    getProperty(storagePresets.hasOwnProperty('single_property_id') && storagePresets.single_property_id).then((response) => {
                        this.propertyData = response.data
                    })
                    getPropertyList({company_id: this.company.id}).then(response => {
                        const items = response.data && response.data.hasOwnProperty('items') ? response.data.items : []
                        if (items && items.length === 1) {
                            this.property = items.find(property => true)
                        }
                    })
                }
                if (this.root_unit_count === 1 || window.innerWidth <= MIN_WINDOW_WIDTH) {
                    if (this.$router.currentRoute.name !== routeNames.RN_CALENDAR_SINGLE_UNIT && this.$router.currentRoute.name !== routeNames.RN_CALENDAR_MULTI_UNIT_VIEW) {
                        this.$router.push({name: routeNames.RN_CALENDAR_SINGLE_UNIT})
                    }
                }
                else if (this.root_unit_count > 1 || this.root_unit_count === 0) {
                    if (this.$router.currentRoute.name !== routeNames.RN_CALENDAR_SINGLE_UNIT && this.$router.currentRoute.name !== routeNames.RN_CALENDAR_MULTI_UNIT_VIEW) {
                        this.$router.push({name: routeNames.RN_CALENDAR_MULTI_UNIT_VIEW})
                    }
                }

                if (this.root_unit_count > DEFAULT_UNIT_NUMBER_LIMIT) {
                    this.display_work_mode = MULTI_UNIT_VIEW
                } else if (this.root_unit_count >= 1 && this.root_unit_count <= DEFAULT_UNIT_NUMBER_LIMIT) {
                    this.display_work_mode = MONTHLY_VIEW
                }
            }
        },
        onResize() {
            if (window.innerWidth <= MIN_WINDOW_WIDTH && this.$router.currentRoute.name !== routeNames.RN_CALENDAR_SINGLE_UNIT) {
                this.$router.replace({
                    name: routeNames.RN_CALENDAR_SINGLE_UNIT
                })
            }
        },
        importPropertyTermsConfirmed() {
            this.importPropertyModalState = false
            this.importPropertyWizardState = true
        },
        importFromDistribution(distributionId) {
            this.importDistribution = distributionId
            this.importPropertyTermsConfirmed()
        },
        closeImportWizard() {
            this.importPropertyWizardState = false
            this.importDistribution = null
            this.importEntity = null
            this.$router.replace({"query": null})
        },
    },
    mounted() {
        EventBus.$on('gl_advanced_filter_result', data => {
            this.selected_unit.push(Number(data.unit))
        })
        let storagePresets = localStorage.getItem(LS_PRESETS)
        if (storagePresets) {
            storagePresets = JSON.parse(storagePresets)
            this.redirectToCalendarModeView(storagePresets)
        } else {
            getPresets().then(response => {
                localStorage.setItem(LS_PRESETS, JSON.stringify(response.data))
                this.redirectToCalendarModeView(response.data)
            })
        }
        EventBus.$on(GE_CALENDAR_SINGLE_UNIT, (data) => {
            this.propertyId = data.propertyId
            this.unitId = data.unitId
        })
    },
    created() {
        window.addEventListener("resize", this.onResize);
        this.access_control_general_context = {
            company: this.company
        }
    },
    beforeDestroy() {
        EventBus.$off(GE_CALENDAR_LOAD_AVAILABILITY)
        EventBus.$off(GE_CALENDAR_UPDATE_AVAILABILITY_LIST)
        EventBus.$off(GE_CALENDAR_LOAD_ADD_MONTH)
        EventBus.$off(GE_CALENDAR_REFRESH_DATA)
        EventBus.$off(GE_CALENDAR_LOAD_PRICES)
        EventBus.$off(GE_CALENDAR_LOAD_RESTRICTIONS)
        EventBus.$off(GE_CALENDAR_OPEN_AVAILABILITY_PROPERTY)
        EventBus.$off(GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR)
        EventBus.$off(GE_OPEN_NEW_ACC_RES_ASIDE)
        EventBus.$off(GE_CALENDAR_SELECT_ALL_UNIT)
        EventBus.$off(GE_CALENDAR_SINGLE_UNIT)
        EventBus.$off(GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_DRAG_CURRENT_PROP)
        EventBus.$off(GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_DRAG_PERIOD_START)
        EventBus.$off(GE_CALENDAR_CROSS_COMPONENT_AVAILABILITY_MIXIN_HANDLE_UNIT_ROW_INDEX)
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
    watch: {
        '$route.query': {
            handler(value) {
                if (value.hasOwnProperty('revenue_manager_log')) {
                    this.aside_log = true
                    this.revenue_manager_log = true
                }
            }, immediate: true
        }
    },
}
</script>

<style scoped>

@media (max-width: 1200px) {
    .property-wrapper > .card-header {
        display: none;
    }
}

</style>
