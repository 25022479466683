<template>
    <div>
        <template v-if="isContigent">
            <div class="availability_row availability_calendar--background_gray">
                <property-calendar-sidebar-contigent-item :showPropertyItem="showPropertyItem" :display="display" :selected-units="selectedUnits" :property="property" :dateRange="dateRange" @show="showContingent"
                                                          :unit="unit"></property-calendar-sidebar-contigent-item>

            </div>

            <div v-show="showContigent" class="availability_row " v-if="unit.show">
                <property-calendar-sidebar-item :showPropertyItem="showPropertyItem" :display="display" :selected-units="selectedUnits" :property="property" :dateRange="dateRange" :unit="unit" :contingent_item="true"></property-calendar-sidebar-item>

            </div>
            <div v-show="showContigent" class="availability_row" :key="contigentUnit.id+'_cs'" v-for="contigentUnit in unit.contigent">
                <property-calendar-sidebar-item :showPropertyItem="showPropertyItem" :display="display" :selected-units="selectedUnits" :property="property" :dateRange="dateRange":unit="contigentUnit"
                                                :contingent_item="true"></property-calendar-sidebar-item>

            </div>
        </template>
        <template v-else>
            <div class="availability_row availability_calendar--background_gray">
                <property-calendar-sidebar-item :showPropertyItem="showPropertyItem" :display="display"  :selected-units="selectedUnits" :property="property" :dateRange="dateRange" :unit="unit"></property-calendar-sidebar-item>
            </div>
        </template>

    </div>
</template>

<script>
    import PropertyCalendarSidebarItem from "@/components/property/calendar/PropertyCalendarSidebarItem";
    import PropertyCalendarSidebarContigentItem
        from "@/components/property/calendar/PropertyCalendarSidebarContigentItem";
    import PropertyCalendarContent from "@/components/property/calendar/PropertyCalendarContent";

    export default {
        name: "PropertySideBarUnitItem",
        components: {PropertyCalendarContent, PropertyCalendarSidebarContigentItem, PropertyCalendarSidebarItem},
        props: {
            display:{
                type:Number,
                default:0
            },
            selectedUnits:{
                type:Array,
                default(){
                    return []
                }
            },
            unit: {
                type: Object
            },

            dates: {
                type: Array
            },
            scrollLeftPosition: {
                type: Number,
                default: 0
            },
            shiftKeyValue: {
                type: Boolean,
                default: false
            },
            dateRange:{
                type:Object,
            },
            property:{
                type:Object
            },
            showPropertyItem:{
                type:Boolean,
                default:true
            }
        },
        data() {
            return {
                showContigent: false,
                dragPeriod: {
                    start: null,
                    end: null,
                }
            }
        },
        computed: {
            isContigent() {
                return Object.keys(this.unit.contigent).length > 0
            }
        },
        methods:{
          showContingent(value){
              this.showContigent = value
          }
        },
        watch: {

            showContigent: {
                handler(value) {

                    this.$emit('showContigent', {value, id: this.unit.id})
                },

            }
        }
    }
</script>

<style scoped>

</style>
