<template>
    <b-form>
        <b-row class="mb-4">
            <b-col class="profile-image-container mb-2">
                <div class="profile-image"
                     :style="{backgroundImage: 'url('+connectData.user_details.profile_photo+')'}">
                </div>
            </b-col>
        </b-row>
        <b-row v-if="connectData.hasOwnProperty('connect_scopes') && connectData.connect_scopes.length">
            <b-col class="mb-4">
                <div class="read-only mb-4">
                    <div class="items">
                        <div class="item">
                            <div class="item-label">{{ $t("FIRST_NAME_LAST_NAME") }}:</div>
                            <div class="item-content">
                                {{ connectData.user_details.first_name }} {{ connectData.user_details.last_name }}
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-label">{{ $t("EMAIL") }}:</div>
                            <div class="item-content">
                                {{ connectData.user_details.email }}
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-label">{{ $t("PHONE") }}:</div>
                            <div class="item-content">
                                {{ connectData.user_details.phone }}
                            </div>
                        </div>
                        <div class="item" v-if="connectData.expires_at">
                            <div class="item-label">{{ $t("EXPIRES_AT") }}:</div>
                            <div class="item-content">
                                {{ connectData.expires_at|timestamp }}
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row v-if="connectData.hasOwnProperty('connect_scopes') && connectData.connect_scopes.length">
            <b-col sm="6" class="mb-4">
                <b-form-checkbox class="mb-2" :key="index" v-for="(item,index) in connectDataForm.connect_scopes"
                                 :disabled="item.append === ''" v-model="item.connected" :unchecked-value="false"
                                 :value="true">
                    {{ item.label }}
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row v-if="connectData.hasOwnProperty('connect_scopes') && connectData.connect_scopes.length && connectData.connect_scopes.length !== disabled_scopes_count">
            <b-col>
                <app-button :disabled="scope_list.length === 0 || connectData.connect_scopes.length === disabled_scopes_count" @click="connectProperty">
                    {{ $t('CONNECT') }}
                </app-button>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";
import {cloneDeep} from "lodash"

export default {
    name: "AirbnbConnectDataForm",
    components: {AppButton},
    props: {
        connectData: {
            type: Object
        }
    },
    data() {
        return {
            link_replace: null,
            link: null,
            scope_list: [],
            disabled_scopes_count: 0,
            connectDataForm: {},
        }
    },
    methods: {
        connectProperty() {
            window.open(this.link_replace, "_blank");
        },
    },
    watch: {
        'connectDataForm': {
            handler(object) {
                this.scope_list = []
                if (!object.hasOwnProperty('connect_scopes')) {
                    return
                }
                if (object.connect_scopes.length) {
                    object.connect_scopes.forEach(item => {
                        if (item.hasOwnProperty('connected') && item.hasOwnProperty('append')) {
                            if (item.connected && item.append !== "") {
                                this.scope_list.push(item.append)
                            }
                        }
                    })
                }
            }, immediate: true,
            deep: true
        },
        scope_list: {
            handler(list) {
                if (list.length > 0 && this.link) {
                    let link = this.link.replace('[scope_list]', list)
                    this.link_replace = link.replace(',', '')
                } else {
                    this.link_replace = null
                }
            }, immediate: true
        }
    },
    created() {
        this.connectDataForm = cloneDeep(this.connectData)
        if (this.connectData.hasOwnProperty('connect_template')) {
            this.link = this.connectData.connect_template
        }
        this.disabled_scopes_count = 0
        if (this.connectData.hasOwnProperty('connect_scopes') && this.connectData.connect_scopes.length) {
            this.connectData.connect_scopes.forEach(item => {
                if (item.hasOwnProperty('append') && item.append === "") {
                    this.disabled_scopes_count++
                }
            })
        }
        this.scope_list = []
    }

}
</script>

<style scoped>
.profile-image-container {
    width: fit-content;
}

.profile-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid rgba(50, 146, 148, 0.5);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
</style>
