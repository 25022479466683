<template>

    <div class="availability_calendar_wrapper" :class="{'availability_calendar_wrapper-loader':showLoading}">

        <div ref="availability_unit_type" class="availability_property_wrapper availability_property_wrapper-full " v-if="showProperty" >
            <div class="availability_rows">
                <property-side-bar-unit-item-test :showPropertyItem="showPropertyItem"   :display="display"
                                                  :selected-units="selectedUnits"  :property="property" :dateRange="dateRange"
                                                  :show-platform-fee-disclaimer="showPlatformFeeDisclaimer"
                                                  @showContigent="showContigent" :key="unit.id" v-for="unit in units" :unit="unit">
                </property-side-bar-unit-item-test>
            </div>
        </div>

        <div class="availability_wrapper"  ref="calendar_scroller">
            <div class="availability_rows">
                <property-calendar-item-test
                    :display_work_mode="display_work_mode"
                    :show_channel_data="show_channel_data"
                    :display="display"
                    :scrollLeftPosition="scrollLeftPosition"
                    :shiftKeyValue="shiftKeyValue"
                    :key="unit.id"
                    :unit="unit"
                    :dates="dateArray"
                    :unit-data="unit_data"
                    :reservations="reservations"
                    :showContigentList="objectList"
                    :property="property"
                    :isFirstMonth="isFirstMonth"
                    :isCheckIn="isCheckIn"
                    :currentDragDateProp="currentDragDateProp"
                    :dragPeriodStartProp="dragPeriodStartProp"
                    :clickedUnitRowIndex="clickedUnitRowIndex"
                    v-for="unit in units"></property-calendar-item-test>
            </div>
        </div>

    </div>

</template>

<script>
import _ from 'lodash'
import PropertySideBarUnitItemTest from "@/components/property/calendar/PropertySideBarUnitItemTest";
import PropertyCalendarItemTest from "@/components/property/calendar/PropertyCalendarItemTest";
import {C_UNIT_CALENDAR_PLATFORM_FEE_DISCLAIMER} from "@/shared/component_permission";
import {DIRECT_BOOKER_COMPANY} from "@/shared/constants";

export default {
    name: "PropertyCalendarWrapperTest",
    components: {
        PropertySideBarUnitItemTest,
        PropertyCalendarItemTest,
    },
    data() {
        return {
            units: {},
            unit_data:{},
            reservations: {},
            objectList:[],
        }
    },
    props: {
        show_channel_data:{
            type:Boolean,
            default:true
        },
        display: {
            type:Number,
            default:0
        },
        selectedUnits:{
            type:Array,
            default(){
                return []
            }
        },
        showLoading:{
            type:Boolean,
            default:false
        },
        property: {
            type: Object
        },
        dateArray: {
            type: Array,
            default: []
        },
        scrollLeftPosition: {
            type: Number,
            default: 0
        },
        shiftKeyValue:{
            type:Boolean,
            default:true
        },
        dateRange:{
            type:Object,
        },
        showPropertyItem:{
            type:Boolean,
            default:true
        },
        display_work_mode:{
            type: Number,
            required: true
        },
        isFirstMonth:{
            type:Boolean,
            default:false
        },
        isCheckIn: {
            type: Boolean,
            default: false
        },
        clickedUnitRowIndex:{
            type:Number,
            default:null
        },
        currentDragDateProp: {
            type: String,
            default: null
        },
        dragPeriodStartProp: {
            type: String,
            default: null
        },
    },
    computed: {
        showProperty(){
            return this.property.hasOwnProperty('units') && Object.keys(this.property.units).length > 0
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        showPlatformFeeDisclaimer() {
            return this.checkPermission(C_UNIT_CALENDAR_PLATFORM_FEE_DISCLAIMER) && this.company.id === DIRECT_BOOKER_COMPANY
        },
    },
    methods: {
        showContigent(object){
            if(this.objectList.length > 0 ){
                let index =  this.objectList.findIndex(el=>{
                    return object.id === el.id
                })
                if(index > -1){
                    this.objectList[index].value = object.value
                } else {
                    this.objectList.push((object))
                }
            } else {
                this.objectList.push((object))
            }
        },
        formatResponse(items) {
            this.units = {...this.units, ...this.formatUnitList(items)}
        },
        formatUnitList(list) {
            let formattedList = {}

            _.forEach(list, (value, key) => {
                let newObject = {
                    id: value["id"],
                    name: value["name"],
                    unit_type: {name: value["type"]},
                    property_id: this.property.id,
                    primary_rate_plan: value.additional_data && value.additional_data.hasOwnProperty('primary_rate_plan') ? value.additional_data.primary_rate_plan : null,
                    los: value.additional_data && value.additional_data.hasOwnProperty('default_los') ? value.additional_data.default_los : {},
                    occupancy: value.additional_data && value.additional_data.hasOwnProperty('default_occupancy') ? value.additional_data.default_occupancy : {},
                    tags: value.additional_data && value.additional_data.hasOwnProperty('tags') ? value.additional_data.tags : [],
                    contigent_index: value.hasOwnProperty('contigent_index') ? value.contigent_index : null,
                    index: value.hasOwnProperty('index') ? value.index : null,
                    show: value["show"],
                    platform_fee_set: value.additional_data && value.additional_data.hasOwnProperty('platform_fee_set') ? value.additional_data.platform_fee_set : false,
                }
                if (_.has(value, ["contigent"])) {
                    newObject["contigent"] = this.formatUnitList(value["contigent"])
                }

                formattedList[key] = newObject

                if (_.has(value, ["reservation"])) {
                    this.formatReservationList(value)
                }
                if (_.has(value, ["data"])) {
                    this.formatDataList(value)
                }
            });

            return formattedList
        },
        formatReservationList(object) {
            let reservationObjectList = {}
            if(typeof object.reservation  === 'object' && Object.keys(object.reservation).length > 0){
                reservationObjectList = object.reservation
            }
            this.reservations[object.id] = reservationObjectList
        },
        formatDataList(object) {
            this.unit_data[object.id] =  object.data
        },
        formatRatesList(object){
            let rateObject = {}
            if(object.default_rates  && typeof object.default_rates  === 'object' && Object.keys(object.default_rates).length > 0){
                rateObject = object.default_rates
            }
            this.rates[object.id] =  rateObject
        },
        formatRestrictionList(object){
            let restrictionObject = {}
            if(typeof object.restrictions  === 'object' && Object.keys(object.restrictions).length > 0){
                restrictionObject = object.restrictions
            }
            this.restrictions[object.id] =  restrictionObject
        },
    },
    watch: {
        property: {
            handler(object) {
                this.formatResponse(object.units)
            },
            immediate: true,
            deep: true
        },
        scrollLeftPosition: {
            handler(val) {
                this.$nextTick(() => {
                    if (this.$refs.calendar_scroller) {
                        if (Array.isArray(this.$refs.calendar_scroller)) {
                            this.$refs.calendar_scroller.forEach(el => {
                                el.scrollLeft = val;
                            })
                        } else {
                            this.$refs.calendar_scroller.scrollLeft = val;
                        }
                    }
                })
            }
        },
    },
}
</script>

<style scoped>
.res-no-border {
    box-shadow: none;
}

.res-no-border > div {
    border: none !important;
    padding-left: 0;
}
</style>
