<template>
    <form @submit.prevent="search" @reset.prevent="resetForm" class="filter">
        <b-row style="align-items: flex-end">

            <b-col md="2" class="mb-3">
                <label>{{$t('NAME')}}</label>
                <b-input v-model="filter.name" type="text"></b-input>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{$t("TYPE")}}</label>
                <app-select mode="search"
                            text-field="label"
                            v-model="tenant"
                            :search="searchTenantType"
                            :search-on-create="true">
                </app-select>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{$t("WORKFLOW")}}</label>
                <app-select mode="search"
                            text-field="label"
                            v-model="workflow"
                            :search="searchWorkflow"
                            :search-on-create="true">
                </app-select>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{$t("COUNTRY")}}</label>
                <app-select mode="search" v-model="country"
                            :search="searchCountry" :search-clear="true"></app-select>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{$t("CITY")}}</label>
                <app-select mode="search" v-model="city"
                            :search="searchCity" :search-clear="true"></app-select>

            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{$t("PACKAGE")}}</label>
                <app-select mode="multiselect" :options="packageList" text-field="label"
                            v-model="filter.package"></app-select>

            </b-col>

            <b-col md="2">

                    <label>{{$t('ACCOUNT_EXPIRATION_DATE')}}</label>
                    <app-date-range-picker v-model="period" placement="left"></app-date-range-picker>

            </b-col>

            <b-col md="3" class="d-flex  align-items-baseline">
                <app-button-submit class="filter-submit-wrapper" :inline="true" :disabled="loading" :loading="loaderSubmit" variant="primary"
                                   button_type="search">
                    {{$t("SEARCH") }}
                </app-button-submit>
                <app-button-reset  :disabled="loading" :loading="loaderReset" :inline="true"  class="pl-2 ml-2" ></app-button-reset>
            </b-col>

        </b-row>
    </form>
</template>

<script>

    import CountrySelect from "@/components/location/CountrySelect";
    import CitySelect from "@/components/location/CitySelect";
    import {pickBy, cloneDeep} from 'lodash'
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {getTenantTypeList} from "@/services/company";
    import {getCityList, getCountries} from "@/services/location";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {getWorkflowList} from "@/services/workflow";
    import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
    import {getSubscriptionPackageList} from "@/services/subscription";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";

    export default {
        name: "CompanyFilter",
        components: {AppButtonReset, AppDateRangePicker, AppSelect, AppButtonSubmit, CitySelect, CountrySelect},
        props: {
            loading: {
                type: Boolean,
            }
        },
        data() {
            return {
                filter: {
                    page: 1,
                    limit: 10,
                    name: null,
                    country: null,
                    city: null,
                    tenant: null,
                    workflow: null,
                    expiration_from_date: null,
                    expiration_to_date: null,
                    package: [],
                },
                options: [{value: null, text: '-'}],
                packageList: [],
                country: {},
                city: {},
                tenant: {},
                workflow: {},
                initialFilter: {},
                period: {
                    end: null,
                    start: null
                },
                reset:0,
                loaderReset:false,
                loaderSubmit:false
            }
        },
        watch: {
            country(value) {
                this.city = {}
                this.filter.country = value.hasOwnProperty('id') ? value.id : null
            },
            city(value) {
                this.filter.city = value.hasOwnProperty('id') ? value.id : null
            },
            tenant(value) {
                this.filter.tenant = value.hasOwnProperty('id') ? value.id : null
            },
            workflow(value) {
                this.filter.workflow = value.hasOwnProperty('id') ? value.id : null
            },
            period(value) {
                if (value) {
                    this.filter.expiration_from_date = value.start
                    this.filter.expiration_to_date = value.end
                }
            },
            loading:{
                handler(value){
                    if (this.reset){
                        this.loaderReset = value
                    } else {
                        this.loaderSubmit = value
                    }
                }
            }
        },
        methods: {
            search() {
                this.reset =0
                this.$emit('search', pickBy(this.filter, item => item !== null))
            },
            searchCountry(value) {
                return getCountries({
                    name: value
                })
            },
            searchCity(value) {
                return getCityList({
                    name: value,
                    country: this.country.id
                })
            },
            resetForm() {
                this.reset = 1
                this.filter = cloneDeep(this.initialFilter);
                this.city = this.country = this.tenant = this.workflow = {}
                this.period = {
                    start: null,
                    end: null
                }
                this.$emit('search', this.filter)
            },
            searchTenantType() {
                return getTenantTypeList()
            },
            searchWorkflow() {
                return getWorkflowList()
            }
        },
        created() {
            this.initialFilter = cloneDeep(this.filter)

            getSubscriptionPackageList().then(response => {
                this.packageList = response.data
            })
        }
    }
</script>

<style scoped>

</style>
