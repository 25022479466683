<template>
    <tr v-if="!showNewRow">
        <td colspan="2" class="text-center">
            <app-button button_type="new"
                        :message="tooltip_message"
                        :disabled="disabled"
                        @click="showNewRow=true">
                {{$t('NEW_UNIT')}}
            </app-button>
        </td>
    </tr>
    <tr v-else>
        <td style="width: 100%">
            <b-input-group>
                <b-form-input type="number" v-model="number_of_units"></b-form-input>
                <b-input-group-append><b-input-group-text>{{$t("NUMBER_OF_UNITS")}}</b-input-group-text></b-input-group-append>
            </b-input-group>

            <validation-description :show="$v.number_of_units.$dirty && $v.number_of_units.$invalid">
                {{$t('FIELD_MANDATORY')}}
            </validation-description>

        </td>
        <td class="text-right">
            <app-button :disabled="$v.number_of_units.$invalid"  @click="addContingents">{{$t('SAVE')}}</app-button>
        </td>
    </tr>
</template>

<script>
    import AppButton from '@/components/app/AppButton/AppButton'
    import ValidationDescription from '@/components/app/form/AppValidationDescription'
    import {
        required, minValue
    } from 'vuelidate/lib/validators'

    export default {
        name: "contingent-new-item",
        props:{
            disabled:{
                type:Boolean,
                default:false
            },
            tooltip_message:{
                type:String
            }
        },
        data() {
            return {
                number_of_units: null,
                deleteDialogShow: false,
                showNewRow: false
            }
        },
        methods: {
            addContingents() {
                this.$emit('addContingents', this.number_of_units)
                this.showNewRow = false
            }

        },
        components: {
            AppButton,
            ValidationDescription
        },
        validations: {
            number_of_units: {
                required,
                minValue: minValue(1),
            }
        }
    }
</script>

<style scoped>

</style>
