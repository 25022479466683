<template>
    <div :key="'distribution_list_overview_' + distributionList.length">

        <b-form @submit.prevent="save">
<b-row>
    <b-col cols="12" :key="distribution.id" v-for="distribution in _distributionList">
        <b-card class="repeater-1 w-100" >
            <div slot="header">
                <repeater-header class="mb-3">
                    {{distribution.name}}
                </repeater-header>
            </div>
            <common-setup-form  @AccessControlEvent="addToAccessControlCounter()"  :key="distribution.id" :distribution="distribution" :setupParameter="setupParameterByDistribution(distribution.id)"></common-setup-form>
        </b-card>

    </b-col>
</b-row>

            <b-row v-if="distributionList.length">
                    <b-col>
                        <app-button-submit v-if="!loading" :loading="save_loading"></app-button-submit>
                    </b-col>
            </b-row>
        </b-form>

        <b-row v-if="companyCreatedHomeAwayStandardDistributionList && companyCreatedHomeAwayStandardDistributionList.length > 0"
               class="mt-4">
            <b-col cols="12"
                   v-for="distribution in companyCreatedHomeAwayStandardDistributionList"
                   :key="distribution.id">
                <b-card class="repeater-1 w-100" >
                    <div slot="header">
                        <repeater-header class="mb-3 d-flex align-items-center">
                            {{ distribution.name }}
                            <app-button class="action_button"
                                        :show_text="false"
                                        variant="link"
                                        button_type="edit"
                                        :key="'edit_distribution_details_' + distribution.id"
                                        :access_control_context="{function: INTEGRATIONS_CM_DISTRIBUTION_SETUP_EDIT, distribution: distribution.id, key: access_control_fetch_key}"
                                        @AccessControlEvent="addToAccessControlCounter()"
                                        @click="editSetup(distribution)">
                            </app-button>
                            <app-access-control tag="span" class="ml-1"
                                                :access_control_context="{function: CM_SETUP_DISTRIBUTION_HOME_AWAY_4_2_SETUP_ADVERTISERS_CONTENT_INDEX, distribution: distribution.id, key: access_control_fetch_key}"
                                                @AccessControlEvent="addToAccessControlCounter()" :key="'home_away_advertisers_index_' + distribution.id">
                                <b-link :href="homeAwayRootFileUrlList[distribution.id]" target="_blank"
                                        v-if="homeAwayRootFileUrlList[distribution.id]">
                                    <i class="fa fa-globe" aria-hidden="true">
                                    </i>
                                </b-link>
                            </app-access-control>
                            <app-access-control tag="span" class="ml-1"
                                                :access_control_context="{function: CM_SETUP_DISTRIBUTION_HOME_AWAY_4_2_SETUP_PROPERTY_HIERARCHY, distribution: distribution.id, key: access_control_fetch_key}"
                                                @AccessControlEvent="addToAccessControlCounter()" :key="'home_away_property_hierarchy_' + distribution.id">
                                <b-link :href="homeAwayPropertyHierarchyFileUrlList[distribution.id]" target="_blank"
                                        v-if="homeAwayPropertyHierarchyFileUrlList[distribution.id]">
                                    <i class="fa fa-globe" aria-hidden="true">
                                    </i>
                                </b-link>
                            </app-access-control>

                        </repeater-header>
                    </div>
                    <common-setup-form @AccessControlEvent="addToAccessControlCounter()"
                                       :key="'distribution_overview_' + distribution.id"
                                       :distribution="distribution"
                                       :setup-parameter="setupParameterByDistribution(distribution.id)"
                                       :company-created-distribution="true"
                                       @homeAwayRootFileUrl="setHomeAwayRootFileUrl"
                                       @homeAwayPropertyHierarchyFileUrl="setHomeAwayPropertyHierarchyFileUrl"
                                       @integrateDistribution="integrateDistribution">
                    </common-setup-form>
                </b-card>

            </b-col>
        </b-row>

        <app-aside v-model="distributionSetupPanelState">
            <template slot="header">
                <app-object-header :label="distribution !== null ? distribution.name : ''"
                                   :name="integrate ? $t('INTEGRATION') : $t('EDIT')">
                </app-object-header>
            </template>
            <distribution-setup-form :distribution="distribution !== null ? Number(distribution.id) : 0"
                                     :company="Number(this.company_id)"
                                     :integrate="integrate"
                                     @AccessControlEvent="addToAccessControlCounter()"
                                     @setupSaved="distributionSetupSaved"
                                     @reload="fetchDistributionList"
                                     @close="distributionSetupPanelState = false">
            </distribution-setup-form>
        </app-aside>

        <app-aside v-model="homeAwayDistributionCreationPanelState"
                   :widths="['col-lg-12', 'col-sm-12', '100%']">
            <template slot="header">
                {{ $t('NEW_DISTRIBUTION') }}
            </template>
            <home-away-standard-distribution-creation-wizard @distributionCreated="homeAwayStandardDistributionCreated">
            </home-away-standard-distribution-creation-wizard>
        </app-aside>

    </div>

</template>

<script>
    import SetupForm from "@/components/channel_manager/setup/SetupForm";
    import {ACCESS_CONTROL_CM_SETUP_PARAMETER} from "@/services/endpoints";
    import {
        getSetupParameterList,
        updateDistributionSetupParameters,
        updateSetupParameters
    } from "@/services/channel_manager";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import RepeaterHeader from "@/components/app/form/RepeaterHeader";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {
        EventBus, GE_OPEN_HOMEAWAY_4_2_DISTRIBUTION_CREATION_WIZARD,
        GE_UPDATE_CM_SETUP_PARAMETERS,
    } from "@/shared/EventBus";
    import {BOOKING, CM_COMMUNICATION_TYPE_HOME_AWAY, TRIP_ADVISOR} from "@/shared/constants";
    import TripAdvisorSetupForm from "@/components/channel_manager/setup/general_setup/TripAdvisor";
    import CommonSetupForm from "@/components/channel_manager/setup/general_setup/CommonSetupForm";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import TripAdvisor from "@/components/channel_manager/setup/general_setup/TripAdvisor";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";

    import {
        AC_CHANNEL_MANAGER_SETUP_PARAMETER,
        AC_CHANNEL_MANAGER_UNIT
    } from "@/mixins/AccessControl/AccessControlEnumeration";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {
        CM_SETUP_DISTRIBUTION,
        CM_SETUP_DISTRIBUTION_HOME_AWAY_4_2_SETUP_ADVERTISERS_CONTENT_INDEX,
        CM_SETUP_DISTRIBUTION_HOME_AWAY_4_2_SETUP_PROPERTY_HIERARCHY,
        INTEGRATIONS_CM_DISTRIBUTION_SETUP_EDIT
    } from "@/shared/component_permission";
    import {getIntegrationDistributionList} from "@/services/distribution";
    import {has} from "lodash";
    import HomeAwayStandardDistributionCreationWizard
        from "@/components/channel_manager/wizard/HomeAwayStandardDistributionCreationWizard";
    import AppAside from "@/components/app/form/AppAside";
    import AppButton from "@/components/app/AppButton/AppButton";
    import DistributionSetupForm from "@/components/channel_manager/setup/general_setup/DistributionGeneralSetupForm";
    import AppObjectHeader from "@/components/app/AppObjectHeader";
    export default {
        name: "Index",
        components: {
            AppObjectHeader,
            DistributionSetupForm,
            AppButton,
            HomeAwayStandardDistributionCreationWizard,
            AppAccessControl,
            CommonSetupForm, AppButtonSubmit, RepeaterHeader, AppSelect, SetupForm,
            TripAdvisorSetupForm, AppAside

        },
        mixins: [getErrorMessage, AccessControlComponent],
        data() {
            return {
                customSetupDistributions: [
                    TRIP_ADVISOR
                ],
                distributionList: [],
                distribution_id: null,
                setupParameterList: [],
                requestData: [],
                units: [],
                loading: true,
                save_loading: false,

                CM_SETUP_DISTRIBUTION,
                INTEGRATIONS_CM_DISTRIBUTION_SETUP_EDIT,
                CM_SETUP_DISTRIBUTION_HOME_AWAY_4_2_SETUP_ADVERTISERS_CONTENT_INDEX,
                CM_SETUP_DISTRIBUTION_HOME_AWAY_4_2_SETUP_PROPERTY_HIERARCHY,

                setup:null,
                hide:false,
                AC_CHANNEL_MANAGER_SETUP_PARAMETER,
                access_control_fetch_key: AC_CHANNEL_MANAGER_SETUP_PARAMETER,
                homeAwayDistributionCreationPanelState: false,
                distribution: null,
                distributionSetupPanelState: false,
                integrate: false,
                homeAwayRootFileUrlList: [],
                homeAwayPropertyHierarchyFileUrlList: [],
            }
        },
        methods: {
            save() {
                this.save_loading = true
                updateSetupParameters({data: this.requestData}).then(() => {
                    notifySuccess();
                    this.fetchDistributionList()
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.save_loading = false
                })

            },
            getSetupParameterList(distribution_ids) {
                this.loading = true
                getSetupParameterList({
                    level: 'company',
                    distribution: distribution_ids,
                    company: this.company_id
                }).then(response => {
                    this.setup = response.data
                    this.setupParameterList = response.data
                }).finally(() => {
                    this.loading = false
                })
            },
            setHide(value){
                this.hide= value
            },

            fetchDistributionList(){
                this.access_control_components = 0
                getIntegrationDistributionList({company:this.company_id}).then(response => {
                        this.distributionList = response.data
                        this.access_control_components = this.distributionList.length
                        let distribution_ids = []
                        if(this.distributionList.length){
                            for (let distribution of this.distributionList) {
                                if ((distribution.company_id !== null && Number(distribution.company_id) === Number(this.company_id))
                                    && (distribution.communication_type !== null && Number(distribution.communication_type) === CM_COMMUNICATION_TYPE_HOME_AWAY)) {
                                    this.access_control_components += 2
                                }
                                distribution_ids.push(distribution.id)
                            }
                            this.getSetupParameterList(distribution_ids)
                        }
                })
            },
            setupParameterByDistribution(distribution_id) {
                return this.setupParameterList.find(el => el.distribution === distribution_id)
            },
            tag(distribution){
               return !this.customSetupDistributions.includes(distribution.id) ? 'b-col' : 'span'
            },
            _has(object, path) {
                return has(object, path)
            },
            homeAwayStandardDistributionCreated() {
                this.homeAwayDistributionCreationPanelState = false
                this.fetchDistributionList()
            },
            integrateDistribution(args) {
                this.distribution = args.distribution
                this.integrate = true
                if (args.integrationSetup) {
                    this.distributionSetupPanelState = true
                } else {
                    let requestObject = {
                        distribution: this.distribution !== null ? this.distribution.id : null,
                        company: this.company_id,
                        integrate: this.integrate,
                    }
                    updateDistributionSetupParameters(requestObject).then(() => {
                        notifySuccess()
                        this.fetchDistributionList()
                    }, (error) => {
                        this.showErrorMessages(error)
                    }).finally(() => {})
                }
            },
            editSetup(distribution) {
                this.distribution = distribution
                this.integrate = false
                this.distributionSetupPanelState = true
            },
            distributionSetupSaved(args = null) {
                this.fetchDistributionList()
                let closePanel = true
                if (args !== null && typeof args === 'object' && args.hasOwnProperty('close_panel')) {
                    closePanel = args.close_panel
                }
                if (closePanel) {
                    this.distributionSetupPanelState = false
                }
            },
            setHomeAwayRootFileUrl(args) {
                this.$set(this.homeAwayRootFileUrlList, args.distribution, args.url)
            },
            setHomeAwayPropertyHierarchyFileUrl(args) {
                this.$set(this.homeAwayPropertyHierarchyFileUrlList, args.distribution, args.url)
            },

        },

        computed: {

            company_id() {
                return this.$store.getters['user/getCompany']
            },
            distributionObject() {
                return this.$store.getters['channel_manager/getDistributionById'](this.distribution_id)
            },
            user(){
                return this.$store.getters['user/getUser']
            },
            _distributionList() {
                let distributionList = []
                this.distributionList.forEach((distribution => {
                    if (((distribution.company_id === null || Number(distribution.company_id) !== Number(this.company_id))
                        || (distribution.communication_type === null || Number(distribution.communication_type) !== CM_COMMUNICATION_TYPE_HOME_AWAY)) && distribution.id !== BOOKING) {
                        distributionList.push(distribution)
                    }
                }))
                return distributionList
            },
            companyCreatedHomeAwayStandardDistributionList() {
                let distributionList = []
                this.distributionList.forEach((distribution => {
                    if (((distribution.company_id !== null && Number(distribution.company_id) === Number(this.company_id))
                        && (distribution.communication_type !== null && Number(distribution.communication_type) === CM_COMMUNICATION_TYPE_HOME_AWAY)) || distribution.id === BOOKING) {
                        distributionList.push(distribution)
                    }
                }))
                return distributionList
            },
        },
        mounted() {
            EventBus.$on(GE_UPDATE_CM_SETUP_PARAMETERS, (data) => {

                let requestObject = {
                    distribution: data.distribution,
                    parameter: data.parameter_id,
                    company: {
                        id: Number(this.company_id),
                        value: data.value
                    }
                }
                if (this.requestData.length > 0) {

                    let requestItem = this.requestData.find(el => el.parameter === requestObject.parameter && el.distribution === requestObject.distribution)

                    if (typeof requestItem !== 'undefined' && this._has(requestItem, 'company.value')){
                        requestItem.company.value = data.value
                    } else {

                        this.requestData.push(requestObject)
                    }
                } else {

                    this.requestData.push(requestObject)
                }
            })

            EventBus.$on(GE_OPEN_HOMEAWAY_4_2_DISTRIBUTION_CREATION_WIZARD, () => {
                this.homeAwayDistributionCreationPanelState = true
            })
        },
        beforeDestroy() {
            EventBus.$off(GE_UPDATE_CM_SETUP_PARAMETERS)
            EventBus.$off(GE_OPEN_HOMEAWAY_4_2_DISTRIBUTION_CREATION_WIZARD)
        },
        created() {
            this.fetchDistributionList()
            this.access_control_general_context = {
                company: this.company_id
            }
        }
    }
</script>

<style scoped>

</style>
