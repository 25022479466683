<template>
    <form @submit.prevent="search" class="filter" autocomplete="off">
        <b-row>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('RESERVATION_CODE')}}</label>
                <b-input v-model="filter.reservation_code" type="text"></b-input>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('PARTNER')}}</label>
                <app-select v-model="selectedCompany"
                            :options="companySelect"
                            mode="multiselect"
                            :disabled="false"
                            :set-first="false"
                            text-field="name">
                </app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('PROPERTY')}}</label>
                <app-select :search-empty-item="false" mode="multiselect" text-field="group_name" :options="propertyList" v-model="propertyMulti"
                            v-if="propertyVisible"></app-select>
                <app-select :search-empty-item="false" mode="search" text-field="group_name" :search="searchProperty" set-first
                            v-model="property" v-else></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('UNIT')}}</label>
                <app-select
                    :options="unitList"
                    v-model="filter.unit"
                    :disabled="unitDisabled"
                    :search-empty-item="false">
                </app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6">
                <label>{{$t("_DATE")}}</label>
                <app-select :options="dateOptions" v-model="dateOptionValue"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("FROM")}}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="fromDate" :max-date="toDate"></app-date-picker>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("TO")}}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="toDate" :min-date="fromDate"
                                 :from-date="fromDate"></app-date-picker>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("SALES_CHANNEL")}}</label>
                <app-select :disabled="channelIsDisabled"
                            :set-first="channelIsDisabled"
                            v-model="filter.channel"
                            :options="channelList"
                            text-field="BOOKING_SOURCE_LABEL"
                            mode="multiselect">
                </app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3" v-if="isDBWorkflow">
                <label>{{$t("PAYMENT_STATUS")}}</label>
                <app-select v-model="filter.payment_status"
                            :options="arFinanceStatusList"
                            text-field="label"
                            mode="multiselect">
                </app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("STATUS")}}</label>
                <app-select v-model="filter.status"
                            :options="arStatusList"
                            text-field="label"
                            mode="multiselect">
                </app-select>
            </b-col>
            <b-col xl="4" lg="4" md="6" class="mb-3">
                <app-submit class="filter-submit-wrapper" :inline="true" :disabled="loading" :loading="loaderSubmit"
                            variant="primary"
                            button_type="search">{{ $t("SEARCH") }}
                </app-submit>

                <app-button-reset @click="resetForm" :disabled="loading" :loading="loaderReset" :inline="true"
                                  class="pl-2 ml-2"></app-button-reset>
            </b-col>
        </b-row>
    </form>
</template>

<script>
import AppSubmit from "@/components/app/AppButton/AppButtonSubmit"
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import AppSelect from "@/components/app/AppSelect/AppSelect"
import {cloneDeep} from "lodash";
import {getStatusList} from "@/services/status";
import {
    DEFAULT_PAGINATION_LIMIT,
    LS_PRESETS,
    OBJECT_TYPE_ACCOMMODATION_RESERVATION,
    OBJECT_TYPE_ACCOMMODATION_RESERVATION_FINANCE, WORKFLOW_DIRECT_BOOKER
} from "@/shared/constants";
import {getUnitList} from "@/services/unit";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import {listHelpers} from "@/mixins/shared/helpers";
import {
    C_RESERVATION_ACCOMMODATION_FILTER_SELF_ISSUE,
    C_RESERVATION_ACCOMMODATION_FILTER_OWNER,
    C_RESERVATION_ACCOMMODATION_FILTER_INVOICE,
    C_RESERVATION_ACCOMMODATION_FILTER_ADVANCE,
    C_RESERVATION_ACCOMMODATION_FILTER_COST_CENTER, C_RESERVATION_ACCOMMODATION_FILTER_ACCOUNT_MANAGER,
} from '@/shared/component_permission'
import {getOwnerList} from "@/services/owner";
import {
    getWebsiteSalesChannel,
    getWebsiteCompanyList,
    getWebsitePropertyList, getWebsiteUnitList,
} from "@/services/direct_booking/website";



export default {
    name: "WebsiteReservationFilter",
    components: {AppButtonReset, AppSubmit, AppDatePicker, AppSelect},
    mixins: [listHelpers],
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            propertyList: [],
            propertyVisible: false,
            companySelect:[],
            property: {id: null, group_name: null},
            propertyMulti: null,
            owner: {id: null, full_name: null},
            companyList: [],
            selectedCompany:null,
            unitDisabled: true,
            filter: {
                property: [],
                reservation_code: null,
                status: [],
                payment_status: [],
                unit: [],
                channel: [],
                company: [],
                website: this.$store.getters['website/getWebsite'].id,
                calculation_item: null,
                reservation: null,
                self_issue: null,
                cost_center: null,
                owner: null,
                is_advance: null,
                invoice: null,
            },
            contain_invoice: [],
            initialFilter: null,
            arStatusList: [],
            arFinanceStatusList: [],
            unitList: [],
            preloadedUnitList: [],
            channelList: [],
            dateOptions: [
                {id: 'check_in', name: this.$t('CHECK_IN')},
                {id: 'check_out', name: this.$t('CHECK_OUT')},
                {id: 'insert', name: this.$t('BOOKED_AT')},
                {id: 'status_update', name: this.$t('LAST_STATUS_CHANGE')},
            ],
            orderByOption : null,
            sortDescOption : null,
            dateOptionValue: null,
            fromDate: null,
            toDate: null,
            reset: 0,
            loaderReset: false,
            loaderSubmit: false,
            channelIsDisabled: true,
            C_RESERVATION_ACCOMMODATION_FILTER_SELF_ISSUE,
            C_RESERVATION_ACCOMMODATION_FILTER_OWNER,
            C_RESERVATION_ACCOMMODATION_FILTER_INVOICE,
            C_RESERVATION_ACCOMMODATION_FILTER_ADVANCE,
            C_RESERVATION_ACCOMMODATION_FILTER_COST_CENTER,
            C_RESERVATION_ACCOMMODATION_FILTER_ACCOUNT_MANAGER
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        isDBWorkflow() {
            return this.company.workflow_id === WORKFLOW_DIRECT_BOOKER
        },
        containInvoiceOptions() {
            return [
                {id: 'has_invoice_owner', label: this.$t('NO_OWNER_INVOICE')},
                {id: 'has_invoice_guest', label: this.$t('NO_GUEST_INVOICE')},
            ]
        },
        dateObject() {
            return this.dateOptionValue ? {
                [`${this.dateOptionValue}_from_date`]: this.fromDate,
                [`${this.dateOptionValue}_to_date`]: this.toDate,
            } : {}
        },
        setFirstCompany(){
            return this.companySelect.length === 1;
        }
    },
    methods: {
        search() {
            this.reset = 0
            if (this.selectedCompany !== null){
                this.filter.company.push(...this.selectedCompany)
            }
            if (this.property.id !== null && this.property.group_name !== null){
                this.filter.property.push(this.property.id)
            }
            if (this.propertyMulti !== null){
                this.filter.property.push(...this.propertyMulti)
            }
            this.$emit('search', {
                filter: {...this.filter, ...this.dateObject},
                sort: {orderByOption: this.orderByOption, sortDescOption: this.sortDescOption}
            })
        },
        resetForm() {
            this.reset = 1
            if (Object.keys(this.$route.query).length > 0) {
                this.$router.replace({query: {}})
            }
            this.filter.property = []
            this.filter.status = []
            this.filter.payment_status = []
            this.filter.unit = []
            this.filter.calculation_item = null
            this.filter.reservation = null
            this.filter.self_issue = null
            this.filter.cost_center = null
            this.filter.is_advance = null
            this.filter.invoice = null
            this.filter.reservation_code = null
            this.filter.reservation = this.filter.calculation_item = this.filter.invoice = null
            this.propertyMulti = null
            this.property = {id: null, group_name: null}
            this.owner = {id: null, full_name: null}
            this.filter.status = this.contain_invoice = []
            this.selectedCompany = []
            this.filter.company = []
            this.dateOptionValue = null
            this.orderByOption = null
            this.sortDescOption = null
            this.$emit('search', {
                filter: {...this.filter, ...this.dateObject},
                sort: {orderByOption: this.orderByOption, sortDescOption: this.sortDescOption}
            })
        },
        searchProperty(value) {
            return getWebsitePropertyList(this.$route.params.id, {name: value, company: this.selectedCompany})
        },
    },
    watch: {
        selectedCompany:{
            handler(value){
                getWebsitePropertyList(this.$route.params.id, {company: value}).then(response => {
                    this.propertyList = response.data
                })
            }
        },
        property: {
            handler(data) {
                if (this.property.id === null && this.property.group_name === null){
                    this.unitDisabled = true
                }else{
                    this.unitDisabled = false
                    getWebsiteUnitList(this.$route.params.id,{property_id: data.id}).then(response => {
                        this.unitList = response.data.items
                    })
                }
            },
        },
        propertyMulti:{
            handler(data){
                if (this.propertyMulti === null){
                    this.unitDisabled = true
                }else{
                    this.unitDisabled = false
                    getWebsiteUnitList(this.$route.params.id,{property_id: data}).then(response => {
                        this.unitList = response.data.items
                    })
                }
            }
        },
        companyList: {
            handler(value){
                if (value && value.id){
                    this.selectedCompany = value.id
                }else{
                    this.selectedCompany = []
                }
            }
        },
        dateOptionValue(value, oldValue) {
            if (value) {
                if (value === 'check_in') {
                    this.sortDescOption = true
                    this.orderByOption = 'check_in_from_date'
                }
                if (value === 'check_out') {
                    this.sortDescOption = true
                    this.orderByOption = 'check_out_from_date'
                }
                if (value === 'insert') {
                    this.sortDescOption = true
                    this.orderByOption = 'created_date'
                }
                if (value === 'status_update') {
                    this.sortDescOption = true
                    this.orderByOption = 'status_updated_date'
                }
            }

            if (oldValue !== null) {
                this.fromDate = null
                this.toDate = null
            }

        },
        '$route.query': {
            handler(queryParams) {
                const queryParamKeys = Object.keys(queryParams)
                if (queryParamKeys.length > 0) {
                    for (const [key, value] of Object.entries(queryParams)) {
                        if (Object.keys(this.filter).includes(key)) {
                            if (Array.isArray(this.filter[key])) {
                                this.$set(this.filter, key, this.filter[key].concat(value))
                            } else {
                                this.filter[key] = value
                            }
                        }
                    }

                    if (queryParamKeys.includes('date')) {
                        this.dateOptionValue = queryParams.date
                        this.fromDate = queryParams.from
                        this.toDate = queryParams.to
                    }

                    if (queryParamKeys.includes('company')){
                        this.companyList = JSON.parse(queryParams.company)
                        this.selectedCompany = this.companyList.id
                    }

                    if (queryParamKeys.includes('has_invoice_owner')) {
                        this.contain_invoice.push('has_invoice_owner')
                    }

                    if (queryParamKeys.includes('has_invoice_guest')) {
                        this.contain_invoice.push('has_invoice_guest')
                    }

                    if (queryParamKeys.includes('invoice_status')) {
                        this.filter['invoice_status'] = queryParams.invoice_status
                    }
                    this.search()
                }
            },
            immediate: true
        },
        loading: {
            handler(value) {
                if (this.reset) {
                    this.loaderReset = value
                } else {
                    this.loaderSubmit = value
                }
            }
        }
    },
    created() {
        this.initialFilter = cloneDeep(this.filter)

        getStatusList([OBJECT_TYPE_ACCOMMODATION_RESERVATION, OBJECT_TYPE_ACCOMMODATION_RESERVATION_FINANCE]).then(res => {
            this.arStatusList = res.data.filter(status => status.object_type.id === OBJECT_TYPE_ACCOMMODATION_RESERVATION)
            this.arFinanceStatusList = res.data.filter(status => status.object_type.id === OBJECT_TYPE_ACCOMMODATION_RESERVATION_FINANCE)
        })

        getWebsiteCompanyList(this.$route.params.id).then(response => {
            this.companySelect = response.data
            if (response.data.length === 1){
                const properties = JSON.parse(localStorage.getItem('presets')).properties
                if (properties < 11){
                    this.propertyVisible = true
                }

            }
        })

        getWebsiteSalesChannel(this.$route.params.id).then(response => {
            this.channelList = [response.data]
            if (response.data <= 1){
                this.channelIsDisabled = false
            }
        })

        getWebsitePropertyList(this.$route.params.id).then(response => {
            this.propertyList = response.data
        })
    }
}
</script>

<style scoped>

</style>
