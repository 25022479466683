<template>
    <div>
        <b-form v-if="loadData" @submit.prevent="save">
            <b-row>

                <b-col md="2" class="mb-4">
                    <app-input-control :error-object="validation.cash_register">
                        <template v-slot:input="data">
                            <label>{{ $t('CASH_REGISTER') }}</label>
                            <app-select :state="data.state" :options="cashRegisterList" v-model="formData.cash_register">
                            </app-select>
                        </template>
                    </app-input-control>

                </b-col>
                <b-col md="2" class="mb-4">
                    <app-input-control :error-object="validation.name">
                        <template v-slot:input="data">
                            <label>{{ $t('NAME') }}</label>
                            <b-input :state="data.state" v-model="formData.name" ></b-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="2" class="mb-4">
                    <label>{{ $t('COUNTRY') }}</label>
                    <app-select mode="search" v-model="country" disabled
                                :search="searchCountry" :search-clear="true"></app-select>


                </b-col>
                <b-col md="2" class="mb-4">
                    <app-input-control :error-object="validation.city">
                        <template v-slot:input="data">
                            <label>{{ $t('CITY') }}</label>
                            <app-select :state="data.state" mode="search" :search="searchCity" :search-clear="true" v-model="city">
                            </app-select>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="2" class="mb-4">
                    <app-input-control :error-object="validation.address">
                        <template v-slot:input="data">
                            <label>{{ $t('ADDRESS') }}</label>
                            <b-input :state="data.state" v-model="formData.address" ></b-input>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="2" class="mb-4">
                    <app-input-control :error-object="validation.vat_number">
                        <template v-slot:input="data">
                            <label>{{ $t('VAT_NUMBER') }}</label>
                            <b-input :state="data.state" v-model="formData.vat_number" ></b-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="2" class="mb-4">
                    <app-input-control :error-object="validation.iban">
                        <template v-slot:input="data">
                            <label>{{ $t('IBAN') }}</label>
                            <b-input :state="data.state" v-model="formData.iban" ></b-input>
                        </template>
                    </app-input-control>

                </b-col>
                <b-col md="2" class="mb-4">
                    <app-input-control :error-object="validation.language">
                        <template v-slot:input="data">
                            <label>{{ $t('LANGUAGE') }}</label>
                            <app-select  :state="data.state"  :options="systemLanguageList" v-model="formData.language">
                            </app-select>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="2" class="mb-4">
                    <app-input-control :error-object="validation.working_users">
                        <template v-slot:input="data">
                            <label>{{ $t('WORKING_USERS') }}</label>
                            <app-select     :state="data.state" :options="workingUserList" v-model="working_user.id">
                            </app-select>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="2" class="mb-4">
                    <app-input-control :error-object="validation.user_vat_number">
                        <template v-slot:input="data">
                            <label>{{ $t('USER_VAT_NUMBER') }}</label>
                            <b-input :state="data.state" v-model="working_user.vat_number" ></b-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="2" class="mb-4">
                    <app-input-control :error-object="validation.certificate">
                        <template v-slot:input="data">
                            <label>{{ $t('CERTIFICATE') }}</label>
                            <b-form-file id="file-certificate"  :state="data.state"  accept=".p12" :value="file_data" @change="updateFile($event)"></b-form-file>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="2" class="mb-4">
                    <app-input-control :error-object="validation.certificate_password">
                        <template v-slot:input="data">
                            <label>{{ $t('CERTIFICATE_PASSWORD') }}</label>
                            <b-input type="password" :state="data.state" v-model="formData.certificate_password">
                            </b-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="2" class="mb-4">
                    <app-input-control :error-object="validation.in_vat_system">
                        <template v-slot:input="data">
                            <label>{{ $t('IN_VAT_SYSTEM') }}</label>
                            <app-select :state="data.state" text-field="label" :options="inVatOptions" v-model="formData.in_vat_system">
                            </app-select>
                        </template>
                    </app-input-control>

                </b-col>
                <b-col md="2" class="mb-4">
                    <label>{{ $t('REGISTRATION_NUMBER') }}</label>
                    <b-input v-model="formData.registration_number">
                    </b-input>
                </b-col>
                <b-col md="2" class="mb-4">
                    <label>{{ $t('SWIFT') }}</label>
                    <b-input v-model="formData.swift">
                    </b-input>
                </b-col>
                <b-col md="2" class="mb-4">
                    <label>{{ $t('SHARE_CAPITAL') }}</label>
                    <b-input v-model="formData.share_capital">
                    </b-input>
                </b-col>
                <b-col md="2" class="mb-4">
                    <label>{{ $t('COURT_REGISTER') }}</label>
                    <b-input v-model="formData.court_register">
                    </b-input>
                </b-col>
                <b-col md="2" class="mb-4">
                    <label>{{ $t('BOARD_MEMBERS') }}</label>
                    <b-input v-model="formData.board_members">
                    </b-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="6" class="mb-4">
                    <app-button-submit :c_permission="C_USER_CASH_REGISTER_SETUP_SAVE"  :loading="saving"></app-button-submit>
                </b-col>
            </b-row>
        </b-form>
        <div v-if="propertyList.length">

            <user-cash-register-entity-setup-form :propertyList="propertyList" :cash_register_setup_id="initialData.id">

            </user-cash-register-entity-setup-form>
        </div>
    </div>


</template>

<script>
    import AppInputControl from "@/components/app/AppInputControl";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {fileHelpers} from "@/mixins/shared/helpers";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {
        getCashRegisterList,
        createCashRegisterSetup,
        fetchCashRegisterSetup,
        updateCashRegisterSetup
    } from "@/services/finance";
    import {getCityList, getCountries} from "@/services/location";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {
        CR_ADDRESS_EMPTY,
        CR_CASH_REGISTER_EMPTY, CR_CERTIFICATE_EMPTY, CR_CERTIFICATE_PASSWORD_EMPTY,
        CR_CITY_EMPTY,
        CR_COMPANY_VAT_ID_EMPTY, CR_IN_VAT_SYSTEM_EMPTY, CR_INVALID_IBAN, CR_LANGUAGE_EMPTY,
        CR_NAME_EMPTY, CR_WORKING_USER_EMPTY, CR_WORKING_USER_VAT_NUMBER_EMPTY
    } from "@/shared/error_codes";
    import {getSystemLanguageList} from "@/services/system";
    import {propertyQuickSearch} from "@/services/property";
    import RepeaterHeader from "@/components/app/form/RepeaterHeader";
    import FormHeader from "@/components/app/form/FormHeader";
    import UserCashRegisterEntitySetupForm from "@/components/user/UserCashRegisterEntitySetupForm";
    import {C_USER_CASH_REGISTER_SETUP_SAVE} from '@/shared/component_permission';
    import {SLOVENIA} from "@/shared/constants";

    export default {
        name: "UserCashRegisterSetupForm",
        components: {
            UserCashRegisterEntitySetupForm,
            FormHeader, RepeaterHeader, AppButtonSubmit, AppSelect, AppInputControl},
        mixins: [fileHelpers, getErrorMessage],
        props:{
            userProp:{
                type:Object
            },
        },
        data() {
            return {
                formData: {
                    cash_register:null,
                    name:null,
                    address:null,
                    city:null,
                    vat_number:null,
                    iban:null,
                    language:null,
                    working_users:null,
                    certificate:null,
                    certificate_password:null,
                    swift:null,
                    share_capital:null,
                    court_register:null,
                    board_members:null,
                    company:null,
                    in_vat_system: null
                },
                country:{
                    id:SLOVENIA,
                    name:'Slovenia'
                },
                initialData:{},
                validation:{
                    cash_register:{
                        input:[CR_CASH_REGISTER_EMPTY]
                    },
                    name:{
                        input:[CR_NAME_EMPTY]
                    },
                    address:{
                        input:[CR_ADDRESS_EMPTY]
                    },
                    city:{
                        input:[CR_CITY_EMPTY]
                    },
                    vat_number:{
                        input:[CR_COMPANY_VAT_ID_EMPTY]
                    },
                    iban:{
                        input:[CR_INVALID_IBAN]
                    },
                    language:{
                        input:[CR_LANGUAGE_EMPTY]
                    },
                    working_users:{
                        input:[CR_WORKING_USER_EMPTY]
                    },
                    certificate:{
                        input:[CR_CERTIFICATE_EMPTY]
                    },
                    certificate_password:{
                        input:[CR_CERTIFICATE_PASSWORD_EMPTY]
                    },
                    in_vat_system:{
                        input:[CR_IN_VAT_SYSTEM_EMPTY]
                    },
                    user_vat_number:{
                        input:[CR_WORKING_USER_VAT_NUMBER_EMPTY]
                    }
                },
                file_data:null,
                city:{},
                working_user:{
                    id:null,
                    vat_number:null
                },
                propertyList:[],
                cashRegisterList:[],
                cityList:[],
                systemLanguageList:[],
                inVatOptions:[
                    {id:1, label:this.$t('YES')},
                    {id:0, label:this.$t('NO')}
                ],
                saving:false,
                loadData:false,
                disabledSaveEntity:false,
                savingEntity:false,
                C_USER_CASH_REGISTER_SETUP_SAVE

            }
        },
        computed:{
            languages() {
                return Object.keys(this.$i18n.messages).map(item => {
                    return {
                        text: item,
                        value: item
                    }
                })
            },
            company_id(){
                return this.$store.getters['user/getCompany']
            },
            workingUserList(){
              return [{id:this.userProp.id, name: this.userProp.first_name+' '+this.userProp.last_name}]
            }
        },
        methods: {
            searchCountry(value) {
                return getCountries({
                    name: value
                })
            },

            updateFile(e){
             let file = e.target.files[0]
             this.toBase64(file).then(encodedFile => {
                 encodedFile = encodedFile.replace(/^.*,/, '')
                 this.formData.certificate = encodedFile
             })
            },
            save() {

                this.formData.company = this.company_id
                this.formData.city = this.city.hasOwnProperty('id') ? this.city.id : null
                this.formData.working_users = [this.working_user]
                this.saving = true
                if(this.initialData.id !== null){
                    this.formData.cash_register_setup = this.initialData.id
                    updateCashRegisterSetup(this.formData).then(()=>{
                        notifySuccess()
                    }).catch(error => {
                        this.showErrorMessages(error, this.validation)
                    }).finally(() => this.saving = false)
                } else {
                    createCashRegisterSetup(this.formData).then(()=>{
                        notifySuccess()
                    }).catch(error => {
                        if(error.response.data.hasOwnProperty('data')){
                            this.initialData.id = error.response.data.data
                        }
                        this.showErrorMessages(error, this.validation)
                    }).finally(() => this.saving = false)
                }

            },

            getCashRegister(){
                getCashRegisterList().then(response => {
                    this.cashRegisterList = response.data
                }, ()=> {
                    this.cashRegisterList = []
                })
            },
            searchCity(value) {
                return getCityList({
                    name: value,
                    country:this.country.id
                })
            },
            getSystemLanguage(){
                getSystemLanguageList().then(response =>{
                    this.systemLanguageList = response.data
                })
            },
            fetch(){
                this.loadData = false
                fetchCashRegisterSetup({user:this.userProp.id, company:this.company_id}).then(response=>{
                    this.initialData = response.data
                    if(!this.initialData.id ){
                        return
                    }
                    this.setFormData(this.initialData)
                }).finally(()=>{
                    this.loadData = true
                })
            },
            setFormData(data){
                this.formData.company = data.company.id
                this.formData.name = data.name
                this.formData.address = data.address
                this.formData.city = data.city.id
                this.formData.vat_number = data.vat_number
                this.formData.registration_number = data.registration_number
                this.formData.swift = data.swift
                this.formData.share_capital = data.share_capital
                this.formData.court_register = data.court_register
                this.formData.board_members = data.board_members
                this.formData.language = data.language
                this.formData.in_vat_system = data.in_vat_system
                this.formData.cash_register = data.cash_register.id
                this.formData.iban = data.iban
                this.city.id = data.city.id
                this.city.name = data.city.name
                if(data.working_users.length){
                    this.working_user.id = data.working_users[0].id
                }
            }
        },
        watch:{
          'initialData':{
              handler(object){
                  if(object.hasOwnProperty('id') && object.id !== null){
                      propertyQuickSearch({user:this.userProp.id}).then(response =>{
                        this.propertyList = response.data.items

                      })
                  }
              },immediate:true
          }
        },
        created(){
            this.getCashRegister()
            this.getSystemLanguage()
            this.fetch()
        }
    }
</script>

<style scoped>

</style>
