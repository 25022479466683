<template>
    <b-form @submit.prevent="save" class="guest_backoffice_setup">
        <div v-for="category in categories" v-if="categories.length > 0 && Object.keys(parameters).length > 0">
            <form-header>{{ category.label }}</form-header>
            <template v-if="category.hasOwnProperty('subcategories')">
                <b-row v-for="subcategory in category.subcategories">
                    <b-col xxl="2" xl="3" lg="4" md="6" class="mb-4" v-for="parameterId in subcategory.parameters"
                           v-if="parameters[parameterId].type === 'bool' && checkIfItemIsVisible(parameters[parameterId])">
                        <label>{{ parameters[parameterId].label }}</label>
                        <app-select
                            :options="yesNoOptions"
                            v-model="parameters[parameterId].value"
                            @input="setAdditionalItemData(parameters[parameterId])"/>
                    </b-col>
                </b-row>
            </template>
            <template v-else>
                <b-row>
                    <b-col xxl="2" xl="3" lg="4" md="6" class="mb-4" v-for="parameterId in category.parameters"
                           v-if="parameters[parameterId].type === 'bool' && checkIfItemIsVisible(parameters[parameterId])">
                        <label>{{ parameters[parameterId].label }}</label>
                        <app-select
                            :options="yesNoOptions"
                            v-model="parameters[parameterId].value"
                            @input="setAdditionalItemData(parameters[parameterId])"/>
                    </b-col>
                </b-row>
            </template>
        </div>
        <b-row v-if="Object.keys(parameters).length > 0">
            <b-col>
                <app-button-submit
                    :inline="true" :loading="saving">
                    {{ $t("SAVE") }}
                </app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {notifySuccess} from "@/shared/plugins/toastr";
import {listHelpers} from "@/mixins/shared/helpers";
import {getPropertyFeatureSetup, setPropertyFeatureSetup} from "@/services/property";


const SHOW_FOOTER = 153;
const SHOW_FOOTER_LOGO = 161;
const SHOW_FOOTER_COMPANY_DATA = 162;

const paramConstants = {
    SHOW_FOOTER, SHOW_FOOTER_LOGO,
    SHOW_FOOTER_COMPANY_DATA
}

export default {
    name: "PropertySettingsForm",
    mixins: [getErrorMessage, listHelpers],
    components: {
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "FormHeader": () => import("@/components/app/form/FormHeader"),
        "AppButtonSubmit": () => import("@/components/app/AppButton/AppButtonSubmit"),
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
        "draggable": () => import("vuedraggable"),
        "AppTranslationInput": () => import("@/components/app/translation/AppTranslationInput"),
        "AppWysiwygEditor": () => import("@/components/app/AppWysiwygEditor"),
        "AppInputControl": () => import("@/components/app/AppInputControl"),
    },
    props: {
        entity_type: {
            type: String,
            default: 'property',
            validator: value => {
                return (["system", "company", "property", "unit"]).includes(value)
            },
        },
    },
    data() {
        return {
            ...paramConstants,
            parameters: {},
            categories: [],
            hide_items: [
                {
                    id: SHOW_FOOTER,
                    items: [SHOW_FOOTER_LOGO, SHOW_FOOTER_COMPANY_DATA]
                }
            ],
            saving: false,
            disableTextArea: {},
            cursorField: 'subject',
        }
    },
    computed: {
        computedItems() {
            return this.items
        },
        entityId() {
            let entity_id = null
            if (this.entity_type !== 'system') {
                if (this.entity_type === 'company') {
                    entity_id = this.company.id
                } else if (this.entity_type === 'property') {
                    entity_id = this.$route.params.id
                } else if (this.entity_type === 'unit') {
                    entity_id = this.$route.params.unit_id
                }
            }
            return entity_id
        },
        property() {
            return this.$store.getters['property/getProperty']
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods: {
        getData() {
            getPropertyFeatureSetup(this.property.id).then(response => {
                this.categories = (response.data.categories);
                this.parameters = this.prepareParameters(response.data.parameters)
                response.data.parameters.forEach(item => {
                    this.setAdditionalItemData(item)
                })
            })
        },
        prepareParameters(parameters) {
            let parametersObject = {}
            parameters.forEach(parameter => {
                this.$set(parametersObject, parameter.id, parameter)
            })
            return parametersObject
        },
        setAdditionalItemData(item) {
            if (item.type === 'bool') {
                let hideItem = this.hide_items.find((hide_item) => item.id === hide_item.id)
                if (hideItem && hideItem.items && hideItem.items.length) {
                    if (item.value === 0) {
                        hideItem.items.forEach(hide_item_id => {
                            if (this.parameters.hasOwnProperty(hide_item_id)) {
                                this.$set(this.parameters[hide_item_id], "visible", 0)
                            }
                        })
                    } else if (item.value === 1 || item.value === null) {
                        hideItem.items.forEach(hide_item_id => {
                            if (this.parameters.hasOwnProperty(hide_item_id)) {
                                this.$set(this.parameters[hide_item_id], "visible", 1)
                            }
                        })
                    }
                }
            }
        },
        checkIfItemIsVisible(item) {
            if (item.hasOwnProperty('visible')) {
                return item.visible
            }
            return true
        },
        save() {
            this.saving = true
            let requestObject = {items: []}
            for (const [key, parameter] of Object.entries(this.parameters)) {
                requestObject.items.push({id: parameter.id, value: parameter.value})
            }
            setPropertyFeatureSetup(this.property.id, requestObject).then(response => {
                this.$emit('update', response.data)
                notifySuccess()
                this.categories = (response.data.categories);
                this.parameters = this.prepareParameters(response.data.parameters)
                response.data.parameters.forEach(item => {
                    this.setAdditionalItemData(item)
                })
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => this.saving = false)
        },
    },

    created() {
        this.getData()
    }
}
</script>

<style>
.guest_backoffice_setup .ql-disabled {
    background-color: #e4e7ea;
    opacity: 0.7;
}
</style>
