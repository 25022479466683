<template>
    <div>
        <app-table-v2 search-event="reloadPromoCodes" :provider="getData" :fields="fields"
                      :show-limit="true"
                      selectable
                      @busyToggled="toggleBusy">

                <template v-slot:code="data">
                    <small class="text-muted d-block">
                        {{data.item.label}}
                    </small>
                    {{data.item.code}}
                </template>

                <template v-slot:value="data">
                    <p v-if="data.item.value_fixed === null" class="mb-0">{{data.item.value_percentage + ' %'}}</p>
                    <p v-else class="mb-0">{{data.item.value_fixed}}</p>
                </template>

                <template v-slot:dates_for="data">
                    <p v-if="data.item.for_dates.from && data.item.for_dates.to" class="mb-0">{{data.item.for_dates.from | date}} — {{data.item.for_dates.to | date}}</p>
                    <p v-else class="mb-0"></p>
                </template>

                <template v-slot:dates_valid="data">
                    <p v-if="data.item.valid_dates.from && data.item.valid_dates.to" class="mb-0">{{data.item.valid_dates.from | date}} — {{data.item.valid_dates.to | date}}</p>
                    <p v-else class="mb-0"></p>
                </template>

                <template v-slot:category="data">
                    <p class="mb-0">{{data.item.category.label}}</p>
                </template>

                <template v-slot:promo_filter="data">
                    <div v-if="data.item.promo_filter">
                        <b-badge
                            variant="primary" pill
                            v-for="item in data.item.promo_filter"
                            class="mr-1"
                            style="cursor: pointer">
                            {{ item[0].slug }}
                            <i @click="showDeleteCustomTagDialog(item,data)"
                               class="fa fa-times fa-fw ml-1"
                               aria-hidden="true"></i>
                        </b-badge>
                    </div>
                </template>

                <template v-slot:available_count="data">
                    <p v-if="data.item.available_count" class="mb-0">{{data.item.available_count + '/' + data.item.total_count}}</p>
                </template>

                <template v-slot:edit="data">
                    <app-button
                        variant="link"
                        button_type="edit"
                        :show_text="false"
                        @click="openEdit(data)"
                    ></app-button>
                </template>

                <template v-slot:selectedContainer="data">
                    <app-button @click="deleteSelected(data.selected)">{{$t('DELETE')}}</app-button>
                    <app-button @click="openListEdit(data.selected)" class="ml-3">{{$t('EDIT')}}</app-button>
                    <app-button @click="openApply(data.selected)" class="ml-3">{{$t('APPLY_ON_WEBSITE')}}</app-button>
                </template>
        </app-table-v2>
        <app-aside v-model="editAside">
            <template slot="header">
                {{$t('PROMO_CODE')}}
            </template>
            <promo-code-edit-form :promo-id="promo_id" @updateSuccess="closeEdit"></promo-code-edit-form>
        </app-aside>
        <app-aside v-model="newAside">
            <template slot="header">
                {{$t('NEW_PROMO_CODE')}}
            </template>
            <promo-code-new-form @saved="newCodeAdded"></promo-code-new-form>
        </app-aside>
        <app-aside v-model="editListAside">
            <template slot="header">
                {{$t('EDIT_PROMO_CODES')}}
            </template>
            <promo-code-list-edit-form @codesEdited="editListAside = false" :selected-list="editSelectedList"></promo-code-list-edit-form>
        </app-aside>
        <app-aside v-model="applyWebsite" :widths="['col-lg-3', '20%']">
            <template slot="header">
                {{$t('APPLY_ON_WEBSITE')}}
            </template>
            <promo-code-apply-on-website :object-ids="applyData" @websiteApplied="applyWebsite = false"></promo-code-apply-on-website>
        </app-aside>
        <app-confirmation-dialog
            :show="confirmation_dialog_state"
            @confirm="continueAction"
            @cancel="confirmation_dialog_state=false"
            :title="$t('PROMO_CODES')">
            {{ $t('PROMO_DELETE_MSG') }}
        </app-confirmation-dialog>
        <app-confirmation-dialog :show="deleteWebsiteApplied"
                                 :delete_title="true"
                                 @confirm="deleteWebsite()"
                                 @cancel="deleteWebsiteApplied = false">
        </app-confirmation-dialog>
    </div>
</template>

<script>

import AppTableV2 from "@/components/app/AppTableV2";
import {deleteListPromoCodes, deletePromoCodeFilter, getPromoCodes} from "@/services/promo_codes";
import AppButton from "@/components/app/AppButton/AppButton";
import AppAside from "@/components/app/form/AppAside";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import PromoCodeEditForm from "@/components/direct_booking/promo_codes/list/PromoCodeEditForm";
import PromoCodeNewForm from "@/components/direct_booking/promo_codes/list/PromoCodeNewForm";
import PromoCodeListEditForm from "@/components/direct_booking/promo_codes/list/PromoCodeListEditForm";
import PromoCodeApplyOnWebsite from "@/components/direct_booking/promo_codes/list/PromoCodeApplyOnWebsite";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import {EventBus} from "@/shared/EventBus";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {notifySuccess} from "@/shared/plugins/toastr";
import {PROMO_CODE_WEBSITE} from "@/shared/constants";

export default {
    name: "PromoCodesTable",
    components: {AppButton, AppTableV2, AppAside, PromoCodeEditForm, AppObjectHeader, PromoCodeNewForm, AppConfirmationDialog, PromoCodeListEditForm, PromoCodeApplyOnWebsite},
    mixins:[getErrorMessage],
    props:{
        filter:{
            type: Object
        }
    },
    data(){
        return{
            editAside: false,
            editListAside: false,
            promo_id: null,
            codeName: null,
            newAside: false,
            applyWebsite:false,
            confirmation_dialog_state: false,
            deleteWebsiteApplied: false,
            websiteId:[],
            promoCodes:[],
            deleteSelectedList: [],
            editSelectedList: [],
            applyData: [],
            fields: [
                {key: 'id', label: this.$t('ID')},
                {key: 'code', label: this.$t('CODE')},
                {key: 'category', label: this.$t('CATEGORY')},
                {key: 'dates_valid', label: this.$t('VALID_THROUGH')},
                {key: 'dates_for', label: this.$t('FOR_DATES')},
                {key: 'promo_filter', label: this.$t('WEBSITE_APPLIED')},
                {key: 'value', label: this.$t('DISCOUNT')},
                {key: 'available_count', label: this.$t('AVAILABLE')},
                {key: 'edit', label: '', class: 'text-right'}
            ],
        }
    },

    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },

    methods:{
        getData(){
            let req = {...this.filter, company: this.company.id}
            return getPromoCodes(req)
        },
        openEdit(data){
            this.editAside = true
            this.promo_id = data.item.id
            this.codeName = data.item.code
        },
        closeEdit(){
            this.editAside = false
        },
        toggleBusy($event) {
            this.$emit('busyToggled', $event)
        },
        newCodeAdded(){
            this.newAside = false
            EventBus.$emit('reloadPromoCodes')
        },
        deleteSelected(selected){
            this.deleteSelectedList = []
            selected.forEach(el => this.deleteSelectedList.push(el.id))
            this.confirmation_dialog_state = true
        },
        continueAction(){
            deleteListPromoCodes({list: this.deleteSelectedList}).then(response => {
                notifySuccess()
                this.confirmation_dialog_state = false
                EventBus.$emit('reloadPromoCodes')
            }, error => {
                this.confirmation_dialog_state = false
                this.showErrorMessages(error)
            })
        },
        openListEdit(selected){
            this.editSelectedList = []
            this.editListAside = true
            selected.forEach(el => this.editSelectedList.push(el.id))
        },
        openApply(selected){
            this.applyData = []
            this.applyWebsite = true
            selected.forEach(el => this.applyData.push(el.id))
        },
        showDeleteCustomTagDialog(item,data){
            this.websiteId = []
            this.promoCodes = []
            this.websiteId.push(item[0].id)
            this.promoCodes.push(data.item.id)
            this.deleteWebsiteApplied = true
        },
        deleteWebsite(){
            deletePromoCodeFilter({promo_id: this.promoCodes, parameter_id:PROMO_CODE_WEBSITE, object_id: this.websiteId}).then(response => {
                notifySuccess()
                this.deleteWebsiteApplied = false
                EventBus.$emit('reloadPromoCodes')
            }, error => {
                this.deleteWebsiteApplied = false
                this.showErrorMessages(error)
            })
        }
    },
    watch:{
        filter: {
            handler() {
                EventBus.$emit('reloadPromoCodes')
            },
            deep: true
        },
    },
    created() {},
    mounted() {
        EventBus.$on('newPromoCode', () => {
            this.newAside = !this.newAside
        })
    }
}
</script>

<style scoped>

</style>
