import http from '../http'
import {
    LOCATION_CITIES_URL,
    LOCATION_CITY_MAPPING_URL,
    LOCATION_COUNTIES_URL,
    LOCATION_COUNTRIES_URL,
    LOCATION_MICROLOCATION_URL,
    LOCATION_REGIONS_URL
} from '@/services/endpoints'


export function getCityList(searchParams) {
    return http.request({
        url: LOCATION_CITIES_URL,
        method: 'get',
        params: searchParams
    })
}

export function getCountries(searchParams) {
    return http.request({
        url: LOCATION_COUNTRIES_URL,
        method: 'get',
        params: searchParams,
    })
}

// search param is city (id)
export function getMicrolocations(searchParams) {
    return http.request({
        url: LOCATION_MICROLOCATION_URL,
        method: 'get',
        params: searchParams
    })
}

// search param is country (id)
export function getCounties(searchParams) {
    return http.request({
        url: LOCATION_COUNTIES_URL,
        method: 'get',
        params: searchParams
    })
}
// search param is county (id)
export function getRegions(searchParams) {
    return http.request({
        url: LOCATION_REGIONS_URL,
        method: 'get',
        params: searchParams
    })
}
// search param is county (id)
export function getCityMapping(searchParams) {
    return http.request({
        url: LOCATION_CITY_MAPPING_URL,
        method: 'get',
        params: searchParams
    })
}
