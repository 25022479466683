<template>
    <div>
        <b-form @submit.prevent="save" v-if="property && owner !== null && hasPermissionView">
            <b-row>
                <app-access-control sm="12" md="6" lg="4"  @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{function:c_permission_e, key: AC_OWNER_FINANCE}">
                    <template v-slot:default="props">
                        <label>{{$t('ENTITY_TYPE')}}</label>
                        <app-select :has-message="true" :message="getAcMessage(props)" :disabled="props.disabled" v-model="owner.person_type.id" :options="entityTypeOptions"></app-select>

                        <app-validation-description
                            :show="$v.owner.person_type.id.$dirty && $v.owner.person_type.id.$invalid">
                            {{$t("FIELD_MANDATORY")}}
                        </app-validation-description>
                    </template>
                </app-access-control>

                <app-access-control sm="12" md="6" lg="4"  @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{function:c_permission_e, key: AC_OWNER_FINANCE}">
                    <template v-slot:default="props">

                        <b-tooltip v-if="props.messages.length"
                                   triggers="hover"
                                   :title="props.messages[0].message" target="iban_id">
                        </b-tooltip>
                        <b-form-group>
                            <label>IBAN</label>
                            <span id="iban_id" class="d-flex">
                            <b-form-input :disabled="props.disabled" v-model="owner.iban" type="text" ></b-form-input>
                            </span>
                        </b-form-group>
                    </template>
                </app-access-control>

                <app-access-control sm="12" md="6" lg="4"  @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{function:C_OWNER_FINANCE_EXTERNAL_ID_E, key: AC_OWNER_FINANCE}">
                    <template v-slot:default="props">

                        <b-form-group>
                            <label>External ID</label>
                            <app-select v-model="financePartnerMapping" mode="search" :disabled="props.disabled" :search="searchFinancePartnerList">
                                <template v-slot:text="data">
                                    {{fullName(data.item)}}
                                </template>
                            </app-select>
                        </b-form-group>
                    </template>
                </app-access-control>

<!--                <b-col sm="12" md="6" lg="4" v-if="cashRegisterSetup">
                    <b-form-group>
                        <label>External ID</label>
                        <app-select v-model="financePartnerMapping" mode="search" :disabled="!checkPermission(C_OWNER_FINANCE_EXTERNAL_ID_E)" :search="searchFinancePartnerList">
                            <template v-slot:text="data">
                                {{fullName(data.item)}}
                            </template>
                        </app-select>
                    </b-form-group>
                </b-col>-->
            </b-row>
            <b-row class="mb-3">

                <app-access-control sm="6" md="4" lg="2"  @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{function:c_permission_e, key: AC_OWNER_FINANCE}">
                    <template v-slot:default="props">
                        <b-checkbox :disabled="props.disabled" unchecked-value="0" value="1" v-model="owner.self_issue" switch>
                            {{$t('SELF_ISSUE')}}
                        </b-checkbox>
                    </template>
                </app-access-control>
                <app-access-control sm="6" md="4" lg="2"  @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{function:c_permission_e, key: AC_OWNER_FINANCE}">
                    <template v-slot:default="props">
                        <b-checkbox :disabled="props.disabled" unchecked-value="0" value="1" v-model="owner.vat_system" switch>
                            {{$t('REGISTERED_FOR_VAT')}}
                        </b-checkbox>
                    </template>
                </app-access-control>
            </b-row>

            <b-row>
                <b-col>
                    <SubmitFinanceOwner  :loading="loader" :c_permission="c_permission_e"></SubmitFinanceOwner>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>

    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {
        C_OWNER_FINANCE_E,
        C_OWNER_FINANCE_V,
        C_OWNER_FINANCE_EXTERNAL_ID_V,
        C_OWNER_FINANCE_EXTERNAL_ID_E
    } from "@/shared/component_permission"
    import {toast} from '@/shared/plugins/toastr'
    import SubmitFinanceOwner from '@/components/app/AppButton/AppButtonSubmit'

    import OwnerDeleteDialog from '@/components/app/form/AppConfirmationDialog'
    import AppValidationDescription from '@/components/app/form/AppValidationDescription'
    import {required} from 'vuelidate/lib/validators'
    import {
        getCashRegisterSetup,
        getFinancePartnerList,
        updateFinancePartnerMapping,
        createFinancePartnerMapping
    } from "@/services/finance";
    import MultiSelect from 'vue-multiselect'
    import _debounce from 'lodash/debounce'
    import {cloneDeep} from "lodash";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {AC_OWNER_FINANCE} from "@/mixins/AccessControl/AccessControlEnumeration";


    export default {
        name: "index",
        mixins: [getErrorMessage,AccessControlComponent],
        data() {
            return {
                c_permission_v: C_OWNER_FINANCE_V,
                c_permission_e: C_OWNER_FINANCE_E,
                C_OWNER_FINANCE_EXTERNAL_ID_E,
                AC_OWNER_FINANCE,
                owner: null,
                deleteState: false,
                deleteDialog: false,
                cashRegisterSetup: false,
                options: [],
                loading: false,
                changeFinancePartner: false,
                financePartnerMapping: null,
                mappingId: null,
                entityTypeOptions: [
                    {id: 1, name: this.$t('NATURAL_ENTITY')},
                    {id: 2, name: this.$t('LEGAL_ENTITY')}
                ],
                loader:false,
                access_control_components:5,
                access_control_fetch_key:AC_OWNER_FINANCE
            }
        },

        components: {
            AppAccessControl,
            AppSelect,
            OwnerDeleteDialog, AppValidationDescription, SubmitFinanceOwner, MultiSelect
        },
        computed: {
            hasPermissionView() {
                return this.$store.getters['user/getPermission'](this.c_permission_v)
            },
            property() {
                return this.$store.getters['property/getProperty']
            },
            ownerObject() {
                return this.$store.getters['owner/getOwner']
            }
        },
        methods: {
            getAcMessage(props){
                return props.messages.length > 0 ? props.messages[0].message:''
            },
            searchFinancePartnerList(search) {
                return getFinancePartnerList({
                    search,
                    company: this.property.company.id,
                    results: 100,
                    invoice_type: 1
                })
            },
            onSearch: _debounce(function (search) {
                if (search === "") {
                    return
                }
                this.loading = true
                getFinancePartnerList({
                    search,
                    company: this.property.company.id,
                    results: 100,
                    invoice_type: 1
                }).then(response => {
                    this.options = response.data
                    this.loading = false
                })
            }, 500),
            fullName(dataObject){
              return dataObject ? (dataObject.name + ' ' + dataObject.last_name  + (dataObject.oib ? ' ('+ dataObject.oib +')' : '')) : null

            },

            updateCountry(value) {
                this.owner.location.country = value
            },
            save() {
                this.$v.$touch()
                if ((this.$v.$error)) {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('VALIDATION_FAILED'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return
                }

                if (this.checkPermission(C_OWNER_FINANCE_EXTERNAL_ID_E) && this.financePartnerMapping && this.mappingId !== this.financePartnerMapping.id) {
                    this.updateFinancePartner()
                } else{
                    this.updateOwner()
                }

            },
            updateOwner(){
                this.loader = true
                let object = {
                    propertyId: this.property.id,
                    ownerId: this.$route.params.owner_id,
                    data: {
                        iban: this.owner.iban,
                        self_issue: this.owner.self_issue,
                        vat_system: this.owner.vat_system,
                        finance_eid: this.owner.finance_eid,
                        person_type: {
                            id: this.owner.person_type.id
                        }
                    }
                }

                this.$store.dispatch('owner/actionUpdateOwner', object).then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 2000
                        })
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.getMessages(error),
                            'type': 'error',
                            'timeout': 7000
                        })
                    }
                ).finally(()=>{
                    this.loader = false
                })
            },
            updateFinancePartner() {
                this.loader = true
                let client = {
                    entity_id: this.owner.id,
                    company: this.property.company.id,
                }

                updateFinancePartnerMapping(this.financePartnerMapping.id, client)
                    .then(response => {
                        this.financePartnerMapping = response.data
                        this.updateOwner()
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.getMessages(error),
                            'type': 'error',
                            'timeout': 7000
                        })
                    }).finally(()=>{
                    this.loader = false
                })
            },
            getFinanceData() {
                if (this.checkPermission(C_OWNER_FINANCE_EXTERNAL_ID_V) === false) {
                    this.cashRegisterSetup = false
                    return
                }
                this.financePartnerMapping = null
                getCashRegisterSetup({company: this.property.company.id})
                    .then(response => {
                            if (response.data.id) {
                                this.cashRegisterSetup = true


                                getFinancePartnerList({
                                    company: this.property.company.id,
                                    invoicee_type: 1,
                                    entity: this.owner.id
                                })
                                    .then(response => {
                                        if (response.data !== null) {

                                            for (let data of response.data) {
                                                this.mappingId = data.id
                                                this.financePartnerMapping = data
                                            }
                                        }
                                    }, () => {
                                        this.mappingId = null
                                        this.financePartnerMapping = null
                                    })
                            }
                        },
                        () => {
                            this.cashRegisterSetup = false
                        }
                    )
            },
            updateValue(value) {
                this.financePartnerMapping = value
            }
        },
        validations: {
            owner: {
                person_type: {
                    id: {
                        required
                    }
                },
            }
        },
        watch: {
            ownerObject: {
                handler(value) {
                    this.owner = cloneDeep(value)
                    this.getFinanceData()
                },
                immediate: true,
                deep: true
            },
            property: {
                handler() {
                    this.getFinanceData()
                },
                immediate: true,
                deep: true
            }
        },
        created(){
            this.access_control_general_context = {
                property: this.$route.params.id,
                company: this.$store.getters['user/getCompany']
            }
        }
    }
</script>

<style scoped>

</style>
