import http from '../http'
import {ACC_MANAGER, ACC_MANAGER_LIST} from "@/services/endpoints";



export function getList(params = null) {
    return http.request({
        url: ACC_MANAGER_LIST,
        method: 'get',
        params: params
    })
}

export function unlinkPropertiesAccountManager(id){
    return http.request({
        url: `${ACC_MANAGER}/${id}/unlink-properties`,
        method: 'POST',
    })
}
