<template>
    <b-form @submit.prevent="save" v-if="setupData">
        <b-row>
            <b-col md="6" lg="4" class="mb-4">
                <label>{{ $t('NAME') }}</label>
                <app-input-control :error-object="validationObject.name">
                    <template v-slot:input="data">
                        <b-input v-model="formData.name" :state="data.state"></b-input>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="4" class="mb-4">
                <label>{{ $t('SLUG') }}</label>
                <app-input-control :error-object="validationObject.slug">
                    <template v-slot:input="data">
                        <b-form-input v-model="formData.slug" :state="data.state"></b-form-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="4" lg="2" class="mb-4">
                <label>{{ setupData[SUPPORTED_LANGUAGES].label }}</label>
                <app-input-control :error-object="validationObject[SUPPORTED_LANGUAGES]">
                    <template v-slot:input="data">
                        <app-select
                            mode="multiselect" :state="data.state"
                            v-model=formData[SUPPORTED_LANGUAGES] @input="onSupportedLangInput"
                            :options="allowedLanguageList">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="4" lg="2" class="mb-4">
                <label>{{ setupData[DEFAULT_LANGUAGE].label }}</label>
                <app-input-control :error-object="validationObject[DEFAULT_LANGUAGE]">
                    <template v-slot:input="data">
                        <app-select
                            :disabled="!supportedLanguageList.length" :state="data.state"
                            v-model="formData[DEFAULT_LANGUAGE]" set-first
                            :options="supportedLanguageList"
                            :search-empty-item="false">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="4" lg="2" class="mb-4">
                <label>{{ setupData[CURRENCY].label }}</label>
                <app-input-control :error-object="validationObject[CURRENCY]">
                    <template v-slot:input="data">
                        <app-select
                            v-model="formData[CURRENCY].id" text-field="currency_code" :state="data.state"
                            :options="setupData[CURRENCY].allowed_values" :search-empty-item="false"
                            @input="emptyExchangeRate(formData[CURRENCY])">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>
            <b-col md="4" lg="2" class="mb-4" v-if="company.working_currency.id !== formData[CURRENCY].id">
                <label>{{ $t('EXCHANGE_RATE') }}</label>
                <b-form-input type="number"
                              step="0.000001"
                              v-model="formData[CURRENCY].exchange_rate">
                </b-form-input>
            </b-col>

            <b-col md="4" lg="2" class="mb-4">
                <label>{{ setupData[LOCATION_TOP_LEVEL].label }}</label>
                <app-input-control :error-object="validationObject[LOCATION_TOP_LEVEL]">
                    <template v-slot:input="data">
                        <app-select
                            :state="data.state" text-field="label"
                            v-model="formData[LOCATION_TOP_LEVEL]" set-first
                            :options="setupData[LOCATION_TOP_LEVEL].allowed_values"
                            :search-empty-item="false">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="4" lg="2" class="mb-4 align-self-center">
                <b-form-checkbox unchecked-value="0" value="1" v-model.number="formData[INSTANT_BOOK_ENABLED]" switch>
                    {{ setupData[INSTANT_BOOK_ENABLED].label }}
                </b-form-checkbox>
                <b-form-checkbox unchecked-value="0" value="1" v-model.number="formData[INQUIRY_ENABLED]"
                                 switch>
                    {{ setupData[INQUIRY_ENABLED].label }}
                </b-form-checkbox>
            </b-col>
            <b-col md="4" lg="2" class="mb-4 align-self-center">
                <b-form-checkbox unchecked-value="0" value="1"
                                 v-model.number="formData[CREDIT_CARD_ENABLED]" switch>
                    {{ setupData[CREDIT_CARD_ENABLED].label }}
                </b-form-checkbox>
                <b-form-checkbox unchecked-value="0" value="1"
                                 v-model.number="formData[PROMO_CODE_ENABLED]" switch>
                    {{ setupData[PROMO_CODE_ENABLED].label }}
                </b-form-checkbox>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <form-header>{{ $t('KEY_VISUALS') }}</form-header>
            </b-col>
        </b-row>

        <b-row>
            <b-col sm="12" md="6" lg="4" class="mb-4">
                <app-input-control class="mb-2" :error-object="validationObject[TITLE]">
                    <template v-slot:input="data">
                        <app-translation-input :languages="supportedLanguages" v-model="formData[TITLE].en"
                                               :label=setupData[TITLE].label
                                               :lang_id="formData[TITLE].id"></app-translation-input>
                    </template>
                </app-input-control>
            </b-col>

            <b-col sm="12" md="6" lg="4" class="mb-4">
                <app-input-control class="mb-2" :error-object="validationObject[SUBTITLE]">
                    <template v-slot:input="data">
                        <app-translation-input :languages="supportedLanguages" v-model="formData[SUBTITLE].en"
                                               :label=setupData[SUBTITLE].label
                                               :lang_id="formData[SUBTITLE].id"></app-translation-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>


        <b-row>
            <b-col sm="12" md="6" lg="4" class="mb-4">
                <app-image-input :tooltip="$t('TOOLTIP_WEBSITE_WIZARD_HERO')" :label="$t('HERO_IMAGE')"
                                 :image="formData.hero_image_id"
                                 :preview="formData.hero_image_url"
                                 @input="formData.hero_image_id = $event"
                                 :enable-delete="checkPermission(C_WEBSITE_E)"></app-image-input>
            </b-col>
            <b-col sm="12" md="6" lg="4" class="mb-4">
                <app-image-input :label="$t('LOGO')"
                                 :image="formData.logo_id"
                                 :preview="formData.logo_url"
                                 @input="formData.logo_id = $event"
                                 :enable-delete="checkPermission(C_WEBSITE_E)"></app-image-input>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <form-header>{{ $t('SEARCH') }}</form-header>
            </b-col>
        </b-row>

        <b-row>
            <b-col md="6" lg="3" class="mb-4">
                <b-form-checkbox unchecked-value="0" value="1" v-model.number="formData[LAYOUT_PAGE_IN_NEW_TAB_ENABLED]"
                                 switch>
                    {{ setupData[LAYOUT_PAGE_IN_NEW_TAB_ENABLED].label }}
                </b-form-checkbox>
                <b-form-checkbox unchecked-value="0" value="1"
                                 v-model.number="formData[LAYOUT_LEAD_DESCRIPTION_ENABLED]" switch>
                    {{ setupData[LAYOUT_LEAD_DESCRIPTION_ENABLED].label }}
                </b-form-checkbox>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <form-header>{{ $t('DESCRIPTIONS') }}</form-header>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-row>
                    <b-col md="6" lg="3" class="mb-4">
                        <label>{{ $t('LEAD_DESCRIPTION') }}</label>
                        <app-select
                            mode="multiselect"
                            text-field="label"
                            v-model=formData[LISTING_DESCRIPTIONS].lead_description
                            :options="setupData[LISTING_DESCRIPTIONS].allowed_values">
                        </app-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6" lg="3" class="mb-4">
                        <label>{{ $t('DESCRIPTION') }}</label>
                        <app-select
                            mode="multiselect"
                            text-field="label"
                            v-model=formData[LISTING_DESCRIPTIONS].description
                            :options="setupData[LISTING_DESCRIPTIONS].allowed_values">
                        </app-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6" lg="3" class="mb-4">
                        <label>{{ $t('HOUSE_RULES') }}</label>
                        <app-select
                            mode="multiselect"
                            text-field="label"
                            v-model=formData[LISTING_DESCRIPTIONS].house_rules
                            :options="setupData[LISTING_DESCRIPTIONS].allowed_values">
                        </app-select>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <form-header>{{ $t('PLUGIN_MODE') }}</form-header>
            </b-col>
        </b-row>

        <b-row>
            <b-col md="4" lg="2" class="mb-4">
                <b-form-checkbox unchecked-value="0" value="1" v-model.number="formData[PLUGIN_ENABLED]" switch>
                    {{ setupData[PLUGIN_ENABLED].label }}
                </b-form-checkbox>
                <b-form-checkbox unchecked-value="0" value="1" v-model.number="formData[PLUGIN_HEADER_ENABLED]" switch>
                    {{ setupData[PLUGIN_HEADER_ENABLED].label }}
                </b-form-checkbox>
                <b-form-checkbox unchecked-value="0" value="1" v-model.number="formData[PLUGIN_FOOTER_ENABLED]" switch>
                    {{ setupData[PLUGIN_FOOTER_ENABLED].label }}
                </b-form-checkbox>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <form-header>Checkout</form-header>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="6" lg="2" class="mb-2">
                <b-form-checkbox unchecked-value="0" value="1"
                                 v-model.number="formData[WEBSITE_SETUP_PARAMETER_CREDIT_CARD_ADDRESS_ENABLED]" switch>
                    {{ setupData[WEBSITE_SETUP_PARAMETER_CREDIT_CARD_ADDRESS_ENABLED].label }}
                </b-form-checkbox>
            </b-col>
            <b-col md="6" lg="2" class="mb-2">
                <b-form-checkbox v-if="Number(formData[WEBSITE_SETUP_PARAMETER_CREDIT_CARD_ADDRESS_ENABLED]) === 1"
                                 unchecked-value="0" value="1"
                                 v-model.number="formData[WEBSITE_SETUP_PARAMETER_CREDIT_CARD_ADDRESS_REQUIRED]" switch>
                    {{ setupData[WEBSITE_SETUP_PARAMETER_CREDIT_CARD_ADDRESS_REQUIRED].label }}
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="6" md="6" xl="4" class="mb-4">
                <div class="d-flex justify-content-between align-items-center">
                    <label>{{ setupData[REQUIRED_AGREEMENT_ELEMENTS].label }}</label>
                    <app-button button_type="new"
                                @click="formData[REQUIRED_AGREEMENT_ELEMENTS].push({lang_id: null, value: null})">
                        {{ $t('NEW_ITEM') }}
                    </app-button>
                </div>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="6" xl="4" class="mb-4 d-flex flex-column"
                   v-for="(element, index) in formData[REQUIRED_AGREEMENT_ELEMENTS]">

                <app-translation-input
                    component="textarea"
                    :label="`#${index+1}`"
                    context-label="ELEMENT"
                    v-model="element.value"
                    :lang_id="element.lang_id"
                    :languages="supportedLanguages">
                    <template #icons>
                        <i class="fa fa-trash-o action_button mr-3" style="cursor:pointer"
                           @click="formData[REQUIRED_AGREEMENT_ELEMENTS].splice(index, 1)"></i>
                    </template>
                </app-translation-input>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <form-header>{{ $t('CONTACT') }}</form-header>
            </b-col>
        </b-row>

        <b-row>
            <b-col md="6" lg="4" class="mb-4">
                <label>{{ $t('WORKING_HOURS') }}</label>
                <b-form-textarea
                    :placeholder="workingHoursPlaceholder"
                    rows="4"
                    v-model="formData[WORKING_HOURS]">
                </b-form-textarea>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="d-flex align-items-end mb-4">
                <label class="mr-3">{{ $t('MAP_LOCATION') }}</label>
                <b-form-checkbox
                    v-model="includeLocation" @input="onIncludeLocationInput"
                    :value="1" :unchecked-value="0">
                </b-form-checkbox>
            </b-col>
        </b-row>

        <b-row v-if="includeLocation">
            <b-col md="4" lg="2" class="mb-4">
                <app-input-control :error-object="validationObject.lat">
                    <template v-slot:input="data">
                        <label>{{ $t('LATITUDE') }}</label>
                        <b-form-input :state="data.state" lazy
                                      v-model="formData[COORDINATES].lat"></b-form-input>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="4" lg="2" class="mb-4">
                <app-input-control :error-object="validationObject.lng">
                    <template v-slot:input="data">
                        <label>{{ $t('LONGITUDE') }}</label>
                        <b-form-input :state="data.state" lazy
                                      v-model="formData[COORDINATES].lng"></b-form-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>

        <b-row v-if="includeLocation">
            <b-col class="mb-4">
                <google-map
                    :lat="formData[COORDINATES].lat || 33.410963"
                    :lng="formData[COORDINATES].lng || -40.92738"
                    :initial-zoom="3"
                    @gmapLocationChange="updateFormLocation">
                </google-map>
            </b-col>
        </b-row>

        <b-row>
            <b-col md="6" lg="4">
                <div class="d-flex justify-content-between align-items-center">
                    <form-header>{{ setupData[SOCIAL_MEDIA].label }}</form-header>
                    <app-button button_type="new"
                                @click="addItemToList(formData[SOCIAL_MEDIA],
                                {id: null, value: null, visible: true},
                                `social-media-item-${formData[SOCIAL_MEDIA].length}`)">
                        {{ $t('NEW_ITEM') }}
                    </app-button>
                </div>
            </b-col>
        </b-row>

        <draggable v-model="formData[SOCIAL_MEDIA]" handle=".handle">
            <div v-for="(socialMediaItem, index) in formData[SOCIAL_MEDIA]" :key="index" class="mb-2">
                <b-row :class="{'mb-4': index === formData[SOCIAL_MEDIA].length - 1}">
                    <b-col lg="6" xl="4">
                        <div class="d-flex align-items-center">
                            <b-button block variant="outline-primary" class="text-left"
                                      @click="socialMediaItem.visible=!socialMediaItem.visible">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <i class="fa fa-bars mr-2 handle" style="cursor: move"></i>

                                        <b-badge pill variant="primary" class="mr-1 number-badge">
                                            {{ index + 1 }}
                                        </b-badge>

                                        <template v-if="socialMediaItem.id">
                                            {{ socialMediaObjects[socialMediaItem.id].label }}
                                        </template>
                                    </div>

                                    <i :class="`fa fa-caret-${socialMediaItem.visible ? 'up' : 'down'}`"></i>
                                </div>
                            </b-button>

                            <app-button
                                class="ml-2"
                                button_type="delete"
                                :show_text="false"
                                variant="link"
                                @click="formData[SOCIAL_MEDIA].splice(index, 1)">
                            </app-button>
                        </div>
                    </b-col>
                </b-row>

                <b-collapse v-model="socialMediaItem.visible" :id="`social-media-item-${index}`">
                    <b-row class="mt-3 ml-0 ml-md-1 mr-md-1">
                        <b-col lg="6" xl="2" class="mb-3">
                            <label>{{ $t('PLATFORM') }}</label>
                            <app-select
                                v-model="socialMediaItem.id" text-field="label" :search-empty-item="false"
                                :options="setupData[SOCIAL_MEDIA].allowed_values">
                            </app-select>
                        </b-col>

                        <b-col lg="6" xl="4" class="mb-3">
                            <label>Handle</label>
                            <b-input-group
                                :prepend="socialMediaItem.id ? `${socialMediaObjects[socialMediaItem.id].base_url}/` : null">
                                <b-form-input v-model="socialMediaItem.value"
                                              :disabled="!socialMediaItem.id"></b-form-input>
                            </b-input-group>
                        </b-col>
                    </b-row>
                </b-collapse>
            </div>
        </draggable>

        <b-row>
            <b-col>
                <app-button-submit v-if="checkPermission(C_WEBSITE_E)" :loading="saving"></app-button-submit>
            </b-col>
        </b-row>

        <loading :show="loading" :overlay="overlay"></loading>
    </b-form>
</template>

<script>
import AppInputControl from "@/components/app/AppInputControl";
import {
    UNIT_DATA_INVALID_LATITUDE_VALIDATION_FAILED, UNIT_DATA_INVALID_LONGITUDE_VALIDATION_FAILED,
    UNIT_DATA_LATITUDE_VALIDATION_FAILED, UNIT_DATA_LONGITUDE_VALIDATION_FAILED,
    WEBSITE_CURRENCY,
    WEBSITE_DEFAULT_LANGUAGE, WEBSITE_LOCATION_TOP_LEVEL,
    WEBSITE_NAME,
    WEBSITE_NAME_ALREADY_EXISTS,
    WEBSITE_SUBDOMAIN,
    WEBSITE_SUBDOMAIN_ALREADY_EXISTS, WEBSITE_SUPPORTED_LANGUAGES, WEBSITE_TITLE
} from "@/shared/error_codes";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {
    TYPE_WEBSITE_FAVICON,
    TYPE_WEBSITE_HERO_IMAGE,
    TYPE_WEBSITE_LOGO,
    WEBSITE_SETUP_PARAMETER_CREDIT_CARD_ADDRESS_ENABLED,
    WEBSITE_SETUP_PARAMETER_CREDIT_CARD_ADDRESS_REQUIRED,
} from "@/shared/constants";
import {getWebsiteWizardSetup, updateWebsite, uploadOrFetchWebsiteDocument} from "@/services/direct_booking/website";
import AppTranslationList from "@/components/app/translation/AppTranslationList";
import AppButton from "@/components/app/AppButton/AppButton";
import FormHeader from "@/components/app/form/FormHeader";
import AppImageInput from "@/components/app/AppImageInput";
import GoogleMap from "@/components/app/map/GoogleMap";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {createDocument, deleteDocument} from "@/services/document";
import {notifySuccess, toast} from "@/shared/plugins/toastr";
import _cloneDeep from "lodash/cloneDeep";
import {EventBus, V_ERROR} from "@/shared/EventBus";
import AppTranslationInput from "@/components/app/translation/AppTranslationInput";
import Loading from "vue-full-loading/src/Loading";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {C_WEBSITE_E} from "@/shared/component_permission";

const TITLE = 31
const SUBTITLE = 32
const SOCIAL_MEDIA = 33
const SUPPORTED_LANGUAGES = 37
const DEFAULT_LANGUAGE = 42
const PLUGIN_HEADER_ENABLED = 55
const PLUGIN_ENABLED = 56
const CURRENCY = 58
const LOCATION_TOP_LEVEL = 60
const LAYOUT_LEAD_DESCRIPTION_ENABLED = 61
const PLUGIN_FOOTER_ENABLED = 62
const LISTING_DESCRIPTIONS = 63
const LAYOUT_PAGE_IN_NEW_TAB_ENABLED = 65
const INSTANT_BOOK_ENABLED = 78
const COORDINATES = 74
const WORKING_HOURS = 75
const INQUIRY_ENABLED = 72
const CREDIT_CARD_ENABLED = 73
const PROMO_CODE_ENABLED = 79
const REQUIRED_AGREEMENT_ELEMENTS = 91


const paramConstants = {
    TITLE,
    SUBTITLE,
    SUPPORTED_LANGUAGES,
    DEFAULT_LANGUAGE,
    CURRENCY,
    INSTANT_BOOK_ENABLED,
    LOCATION_TOP_LEVEL,
    COORDINATES,
    WORKING_HOURS,
    PLUGIN_HEADER_ENABLED,
    PLUGIN_ENABLED,
    PLUGIN_FOOTER_ENABLED,
    LAYOUT_LEAD_DESCRIPTION_ENABLED,
    LISTING_DESCRIPTIONS,
    LAYOUT_PAGE_IN_NEW_TAB_ENABLED,
    INQUIRY_ENABLED,
    CREDIT_CARD_ENABLED,
    PROMO_CODE_ENABLED,
    REQUIRED_AGREEMENT_ELEMENTS,
    SOCIAL_MEDIA
}

export default {
    name: "WebsiteGeneralForm",
    mixins: [getErrorMessage],
    components: {
        AppTranslationInput,
        AppButtonSubmit,
        GoogleMap, AppImageInput, FormHeader, AppButton, AppSelect, AppInputControl, AppTranslationList, Loading,
        "draggable": () => import("vuedraggable")
    },
    data() {
        return {
            initialWebsiteData: null,
            TYPE_WEBSITE_HERO_IMAGE,
            TYPE_WEBSITE_LOGO,
            WEBSITE_SETUP_PARAMETER_CREDIT_CARD_ADDRESS_REQUIRED,
            WEBSITE_SETUP_PARAMETER_CREDIT_CARD_ADDRESS_ENABLED,
            C_WEBSITE_E,
            loading: true,
            saving: false,
            overlay: true,
            setupData: null,
            includeLocation: 0,
            ...paramConstants,
            supportedLanguages: [],
            successfulStepFormData: {},
            exchangeRateVisible: false,
            temp: null,
            formData: {
                template: 1,
                name: null,
                slug: null,
                [WEBSITE_SETUP_PARAMETER_CREDIT_CARD_ADDRESS_REQUIRED]: null,
                [WEBSITE_SETUP_PARAMETER_CREDIT_CARD_ADDRESS_ENABLED]: null,
                [SUPPORTED_LANGUAGES]: null,
                [DEFAULT_LANGUAGE]: null,
                [CURRENCY]: null,
                [INSTANT_BOOK_ENABLED]: 1,
                [LOCATION_TOP_LEVEL]: null,
                [TITLE]: {id: null, en: null},
                [SUBTITLE]: {id: null, en: null},
                [COORDINATES]: {lat: null, lng: null},
                [WORKING_HOURS]: null,
                [INQUIRY_ENABLED]: null,
                [CREDIT_CARD_ENABLED]: null,
                [PROMO_CODE_ENABLED]: null,
                [LISTING_DESCRIPTIONS]: {
                    lead_description: null,
                    description: null,
                    house_rules: null
                },
                [REQUIRED_AGREEMENT_ELEMENTS]: [],
                logo_id: null,
                logo_url: null,
                hero_image_id: null,
                hero_image_url: null,
                favicon_id: null,
                [SOCIAL_MEDIA]: []
            },
            validationObject: {
                name: {
                    input: [WEBSITE_NAME, WEBSITE_NAME_ALREADY_EXISTS],
                },
                slug: {
                    input: [WEBSITE_SUBDOMAIN, WEBSITE_SUBDOMAIN_ALREADY_EXISTS],
                },
                [SUPPORTED_LANGUAGES]: {
                    input: [WEBSITE_SUPPORTED_LANGUAGES]
                },
                [DEFAULT_LANGUAGE]: {
                    input: [WEBSITE_DEFAULT_LANGUAGE]
                },
                [CURRENCY]: {
                    input: [WEBSITE_CURRENCY]
                },
                [LOCATION_TOP_LEVEL]: {
                    input: [WEBSITE_LOCATION_TOP_LEVEL]
                },
                [TITLE]: {
                    input: [WEBSITE_TITLE]
                },
                lat: {
                    input: [UNIT_DATA_LATITUDE_VALIDATION_FAILED, UNIT_DATA_INVALID_LATITUDE_VALIDATION_FAILED],
                },
                lng: {
                    input: [UNIT_DATA_LONGITUDE_VALIDATION_FAILED, UNIT_DATA_INVALID_LONGITUDE_VALIDATION_FAILED],
                }
            },
            workingHoursPlaceholder: 'Mon – Fri  //  00:00 – 00:00\r\nSat – Sun  //   00:00 – 00:00',
        }
    },
    computed: {
        allowedLanguageList() {
            return this.setupData[SUPPORTED_LANGUAGES].allowed_values.map(lang => ({
                id: lang, name: this.$t(`LANGUAGES.${lang}`)
            }))
        },
        supportedLanguageList() {
            return this.formData[SUPPORTED_LANGUAGES].map(e => ({id: e, name: this.$t(`LANGUAGES.${e}`)}))
        },
        website() {
            return this.$store.getters['website/getWebsite']
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        socialMediaObjects() {
            return this.setupData[SOCIAL_MEDIA].allowed_values.reduce((result, current) => {
                result[current.id] = current
                return result
            }, {})
        }
    },
    watch: {
        website: {
            immediate: true,
            handler(websiteData) {
                this.initialWebsiteData = _cloneDeep(websiteData)
            }
        }
    },
    methods: {
        addItemToList(list, item, elementId) {
            list.forEach(i => i.visible = false)
            list.push(item)
            this.$nextTick(() => {
                document.getElementById(elementId).scrollIntoView()
            })
        },
        onSupportedLangInput(input) {
            if (!input.length) this.formData[DEFAULT_LANGUAGE] = null
            this.supportedLanguages = this.formData[SUPPORTED_LANGUAGES].filter(e => e !== 'en')
        },
        onIncludeLocationInput() {
            this.formData[COORDINATES] = {lat: null, lng: null}
        },
        updateFormLocation(data) {
            this.formData[COORDINATES].lat = parseFloat(data.latitude)
            this.formData[COORDINATES].lng = parseFloat(data.longitude)
            EventBus.$emit(V_ERROR, [])
        },
        populateFormData() {
            this.formData = _cloneDeep({...this.formData, ...this.website})
            this.formData.name = this.formData.website
            this.supportedLanguages = this.formData[SUPPORTED_LANGUAGES].filter(e => e !== 'en')
            if (this.formData[COORDINATES] && this.formData[COORDINATES].lat && this.formData[COORDINATES].lng) {
                this.includeLocation = 1
            }
            this.formData[SOCIAL_MEDIA] = this.formData[SOCIAL_MEDIA].map(el => ({...el, visible: false}))
        },
        save() {
            this.saving = true
            this.handleImageSaving(this.formData.hero_image_id, this.formData.logo_id).then(() => {
                return updateWebsite(this.website.id, this.formData)
            }).then(response => {
                notifySuccess()
                this.$store.commit('website/setWebsite', response.data)
                this.populateFormData()
            }).catch(error => {
                this.showErrorMessages(error, this.validationObject)
            }).finally(() => this.saving = false)

        },
        handleImageSaving(heroImage, logo) {
            const promiseList = []

            if (heroImage && this.initialWebsiteData.hero_image_id !== heroImage) {
                const fileData = new FormData()
                fileData.append('document', heroImage)
                promiseList.push(uploadOrFetchWebsiteDocument(this.website.id, TYPE_WEBSITE_HERO_IMAGE, fileData))
            } else if (!heroImage && this.initialWebsiteData.hero_image_id) {
                promiseList.push(deleteDocument(this.website.id, TYPE_WEBSITE_HERO_IMAGE, this.initialWebsiteData.hero_image_id))
            }

            if (logo && this.initialWebsiteData.logo_id !== logo) {
                const fileData = new FormData()
                fileData.append('document', logo)
                promiseList.push(uploadOrFetchWebsiteDocument(this.website.id, TYPE_WEBSITE_LOGO, fileData))
                promiseList.push(uploadOrFetchWebsiteDocument(this.website.id, TYPE_WEBSITE_FAVICON, fileData))
            } else if (!logo && this.initialWebsiteData.logo_id) {
                promiseList.push(deleteDocument(this.website.id, TYPE_WEBSITE_LOGO, this.initialWebsiteData.logo_id))
                if (this.initialWebsiteData.favicon_id) {
                    promiseList.push(deleteDocument(this.website.id, TYPE_WEBSITE_FAVICON, this.initialWebsiteData.favicon_id))
                }
            }

            return Promise.all(promiseList.map(p => p.catch(e => this.showErrorMessages(e))))
        },
        getSetup() {
            getWebsiteWizardSetup().then(response => {
                this.setupData = response.data
            }).finally(() => {
                this.loading = true
            })
        },
        emptyExchangeRate(data) {
            if (this.company.working_currency.id === data.id) {
                data.exchange_rate = null
            }
        },
    },
    created() {
        this.loading = true
        this.getSetup()
        this.populateFormData()
    },
}
</script>

<style scoped>

</style>
