<template>
    <b-form @submit.prevent="save" autocomplete="off" v-if="subscription.loaded && formLoaded">
        <b-row>
            <b-col lg="12" class="mb-3" v-if="checkPermission(C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_FEE_PERCENT_E)">
                <label>{{ $t('SUBSCRIPTION_TYPE') }}</label>
                <app-select :search-empty-item="false"
                            :disabled="signContract === false"
                            :options="subscriptionPackageTypeOptions"
                            v-model="subscriptionPackageType"
                            value-field="value"
                            text-field="text" set-first></app-select>
            </b-col>

            <b-col lg="12" class="mb-3">
                <app-input-control :error-object="validationErrorObject.expiration_date">
                    <template v-slot:input="data">
                        <label>{{ $t('EXPIRATION_DATE') }}</label>
                        <app-date-picker v-model="expirationDate"
                                         :min-date="currentDate"
                                         :state="data.state">
                        </app-date-picker>
                    </template>
                </app-input-control>
            </b-col>

            <b-col lg="12" class="mb-3" v-if="checkPermission(C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_FEE_PERCENT_E) && subscriptionPackageType === SUBSCRIPTION_PACKAGE_FLAT_PER_UNIT">
                <app-input-control :error-object="validationErrorObject.flat_unit_fee">
                    <template v-slot:input="data">
                        <label>{{ $t('PER_UNIT_FLAT_FEE') }}</label>
                        <app-number-input :append="currency"
                                          v-model="subscription.flat_unit_fee"
                                          align="right"
                                          allow-decimal
                                          :state="data.state">
                        </app-number-input>
                    </template>
                </app-input-control>
            </b-col>

            <b-col lg="12" class="mb-3" v-if="checkPermission(C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_FEE_PERCENT_E) && subscriptionPackageType === SUBSCRIPTION_PACKAGE_PER_RESERVATION">
                <app-input-control :error-object="validationErrorObject.fee_percent">
                    <template v-slot:input="data">
                        <label>{{ $t('COMMISSION') }}</label>
                        <app-number-input append="%"
                                          v-model="subscription.fee_percent"
                                          align="right"
                                          allow-decimal
                                          :state="data.state">
                        </app-number-input>
                    </template>
                </app-input-control>
            </b-col>

<!--            <b-col lg="12" class="mb-3" v-if="checkPermission(C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_CANCELLATION_FEE_E) && subscriptionPackageType === SUBSCRIPTION_PACKAGE_PER_RESERVATION>
                <app-input-control :error-object="validationErrorObject.fee_cancel_amount">
                    <template v-slot:input="data">
                        <label>{{ $t('CANCEL_FEE_AMOUNT') }}</label>
                        <app-number-input v-model="subscription.fee_cancel_amount"
                                          :append="currency"
                                          align="right"
                                          allow-decimal
                                          :state="data.state">
                        </app-number-input>
                    </template>
                </app-input-control>
            </b-col>-->

            <b-col lg="12" class="mb-3" v-if="checkPermission(C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_MAX_YEARLY_AMOUNT_E)">
                <app-input-control :error-object="validationErrorObject.max_amount">
                    <template v-slot:input="data">
                        <label>{{ $t('MAX_YEARLY_AMOUNT') }}</label>
                        <app-number-input v-model="subscription.max_amount"
                                          :append="currency"
                                          align="right"
                                          allow-decimal
                                          :state="data.state">
                        </app-number-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <app-button-submit v-if="checkPermission(C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_E)"
                                   class="filter-submit-wrapper"
                                   :inline="true"
                                   variant="primary"
                                   :loading="loading">
                </app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import AppInputControl from "@/components/app/AppInputControl";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import {
    SU_CANCELLATION_FEE_AMOUNT_CANNOT_BE_LESS_THAN_ZERO,
    SU_CANCELLATION_FEE_AMOUNT_MUST_BE_NUMERIC, SU_EXPIRATION_DATE_NOT_VALID,
    SU_FEE_PERCENT_IS_MANDATORY,
    SU_FEE_PERCENT_MUST_BE_GREATER_THAN_ZERO,
    SU_FEE_PERCENT_MUST_BE_NUMERIC,
    SU_FEE_PERCENT_CANNOT_BE_GREATER_THAN_HUNDRED,
    SU_MAX_AMOUNT_CANNOT_BE_LESS_THAN_ZERO,
    SU_MAX_AMOUNT_MUST_BE_NUMERIC
} from "@/shared/error_codes";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {extendContract, fetchActiveSubscription, fetchSubscriptionPackage, signContract} from "@/services/company";
import {
    SUBSCRIPTION_PACKAGE_DEMO,
    SUBSCRIPTION_PACKAGE_PER_RESERVATION,
    SUBSCRIPTION_PACKAGE_FLAT_PER_UNIT,
} from "@/shared/constants";
import moment from "moment";
import {notifySuccess} from "@/shared/plugins/toastr";
import {
    C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_E,
    C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_CANCELLATION_FEE_E,
    C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_FEE_PERCENT_E,
    C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_MAX_YEARLY_AMOUNT_E
} from "@/shared/component_permission";

export default {
    name: "ContractDetailsForm",
    components: {AppNumberInput, AppInputControl, AppDatePicker, AppButtonSubmit, AppSelect},
    mixins: [getErrorMessage],
    props: {
        subscriptionId: {
            type: Number,
        },
        currency: {
            type: String,
        },
        company: {
            type: Number,
        },
        signContract: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            expirationDate: null,
            subscriptionPackageType: SUBSCRIPTION_PACKAGE_PER_RESERVATION,
            subscriptionPackageTypeResponseList: {},
            subscriptionPackageTypeOptions: [
                {text: this.$t("RESERVATION_FEE"), value: SUBSCRIPTION_PACKAGE_PER_RESERVATION},
                {text: this.$t("PER_UNIT_FLAT_FEE"), value: SUBSCRIPTION_PACKAGE_FLAT_PER_UNIT},
            ],
            subscription: {
                loaded: false,
                subscription_package_id: null,
                expiration_date: null,
                invoice_due_date: null,
                flat_unit_fee: null,
                fee_percent: null,
                fee_cancel_amount: null,
                max_amount: null,
                active: null,
            },
            validationErrorObject: {
                expiration_date: {
                    input: [SU_EXPIRATION_DATE_NOT_VALID],
                },
                flat_unit_fee: {
                    input: [
                        SU_FEE_PERCENT_IS_MANDATORY,
                        SU_FEE_PERCENT_MUST_BE_NUMERIC,
                        SU_FEE_PERCENT_MUST_BE_GREATER_THAN_ZERO,
                        SU_FEE_PERCENT_CANNOT_BE_GREATER_THAN_HUNDRED,
                    ],
                },
                fee_percent: {
                    input: [
                        SU_FEE_PERCENT_IS_MANDATORY,
                        SU_FEE_PERCENT_MUST_BE_NUMERIC,
                        SU_FEE_PERCENT_MUST_BE_GREATER_THAN_ZERO,
                        SU_FEE_PERCENT_CANNOT_BE_GREATER_THAN_HUNDRED,
                    ],
                },
                fee_cancel_amount: {
                    input: [
                        SU_CANCELLATION_FEE_AMOUNT_MUST_BE_NUMERIC,
                        SU_CANCELLATION_FEE_AMOUNT_CANNOT_BE_LESS_THAN_ZERO,
                    ],
                },
                max_amount: {
                    input: [
                        SU_MAX_AMOUNT_MUST_BE_NUMERIC,
                        SU_MAX_AMOUNT_CANNOT_BE_LESS_THAN_ZERO,
                    ],
                },
            },
            loading: false,
            packageLoaded: false,
            subscriptionLoaded: false,
            SUBSCRIPTION_PACKAGE_PER_RESERVATION,
            SUBSCRIPTION_PACKAGE_FLAT_PER_UNIT,
            C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_FEE_PERCENT_E,
            C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_CANCELLATION_FEE_E,
            C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_MAX_YEARLY_AMOUNT_E,
            C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_E,
        }
    },
    computed: {
        formLoaded() {
            return this.packageLoaded && this.subscriptionLoaded
        },
        currentDate() {
            return moment(new Date()).format('YYYY-MM-DD')
        },
    },
    watch: {
        subscription: {
            handler(value) {
                if (value !== null && this.signContract && Number(value.active) === 1 && Number(value.subscription_package_id) === SUBSCRIPTION_PACKAGE_DEMO) {
                    this.fetchPackage()
                }
            },
            immediate: true,
            deep: true,
        },
        subscriptionPackageType: {
            handler(value) {
                if (value !== null && this.signContract) {
                    if (this.subscriptionPackageTypeResponseList.hasOwnProperty(value)) {
                        this.subscription.flat_unit_fee = this.subscriptionPackageTypeResponseList[value].flat_unit_fee
                        this.subscription.fee_percent = this.subscriptionPackageTypeResponseList[value].fee_percent
                        this.subscription.fee_cancel_amount = this.subscriptionPackageTypeResponseList[value].fee_cancel_amount
                        this.subscription.subscription_package_id = this.subscriptionPackageTypeResponseList[value].subscription_package_id
                    } else {
                        this.fetchPackage()
                    }
                }
            }
        },
    },
    methods: {
        save() {
            this.loading = true

            let requestData = {
                expiration_date: this.expirationDate,
                package: this.subscription && this.subscription.subscription_package_id ? this.subscription.subscription_package_id : null,
                flat_unit_fee: this.subscription ? this.subscription.flat_unit_fee : null,
                fee_percent: this.subscription ? this.subscription.fee_percent : null,
                fee_cancel_amount: this.subscription ? this.subscription.fee_cancel_amount : null,
                max_amount: this.subscription ? this.subscription.max_amount : null,
            }

            if (this.subscriptionPackageType === SUBSCRIPTION_PACKAGE_FLAT_PER_UNIT) {
                requestData.fee_percent = null
                requestData.fee_cancel_amount = null
            } else if (this.subscriptionPackageType === SUBSCRIPTION_PACKAGE_PER_RESERVATION) {
                requestData.flat_unit_fee = null
            }

            if (this.signContract) {
                this.signSubscriptionContract(requestData)
            } else {
                this.extendSubscriptionContract(requestData)
            }
        },
        signSubscriptionContract(requestData) {
            signContract(this.company, requestData).then(() => {
                notifySuccess()
                this.$emit('contractSaved')
            }, error => {
                this.showErrorMessages(error, this.validationErrorObject)
            }).finally(() => {
                this.loading = false
            })
        },
        extendSubscriptionContract(requestData) {
            extendContract(this.company, this.subscriptionId, requestData).then(() => {
                notifySuccess()
                this.$emit('contractSaved')
            }, error => {
                this.showErrorMessages(error, this.validationErrorObject)
            }).finally(() => {
                this.loading = false
            })
        },
        fetchPackage() {
            fetchSubscriptionPackage(this.company, this.subscriptionPackageType).then(response => {
                this.subscription.flat_unit_fee = response.data.flat_unit_fee
                this.subscription.fee_percent = response.data.fee_percent
                this.subscription.fee_cancel_amount = response.data.fee_cancel_amount
                this.subscription.subscription_package_id = response.data.id

                this.$set(this.subscriptionPackageTypeResponseList, this.subscriptionPackageType, {
                    flat_unit_fee: this.subscription.flat_unit_fee,
                    fee_percent: this.subscription.fee_percent,
                    fee_cancel_amount: this.subscription.fee_cancel_amount,
                    subscription_package_id: this.subscription.subscription_package_id,
                })
            }, () => {

            }).finally(() => {
                this.packageLoaded = true
            })
        },
        fetchSubscription() {
            fetchActiveSubscription(this.company, this.subscriptionId).then(response => {
                this.subscriptionPackageType = response.data.hasOwnProperty('flat_unit_fee') && response.data.flat_unit_fee !== null ? SUBSCRIPTION_PACKAGE_FLAT_PER_UNIT : SUBSCRIPTION_PACKAGE_PER_RESERVATION
                this.subscription = response.data
                this.subscription.loaded = !(Number(this.subscription.active !== 1)
                    || (this.subscription.subscription_package_id && Number(this.subscription.subscription_package_id === SUBSCRIPTION_PACKAGE_DEMO) && !this.signContract)
                    || (this.subscription.subscription_package_id && Number(this.subscription.subscription_package_id !== SUBSCRIPTION_PACKAGE_DEMO) && this.signContract));
            }, () => {
                this.subscription.loaded = false
            }).finally(() => {
                this.subscriptionLoaded = true
            })
        },
    },
    created() {
        this.fetchSubscription()
        if (!this.signContract) {
            this.packageLoaded = true
        }
    },
}
</script>

<style scoped>

</style>
