<template>
    <b-form v-if="user" @submit.prevent="save">
        <b-row>
            <b-col lg="12" md="12" class="col-xxl-12">
                <b-card no-body>
                    <repeater-header>
                        {{$t("USERNAME")}}: {{user.username}}
                    </repeater-header>
                    <div class="pl-3 mb-3 border_not_last d-flex">
                        <div class="text-lowercase mr-2">{{$t("ROLES")}}:</div><span :key="index" v-for="(role, index) in user.role">{{ role.name }}</span>
                    </div>
                    <b-list-group class="repeater-2">
                        <b-list-group-item class="list-group-item-title font-weight-bold">
                            <form-header header-class="d-flex justify-content-between mb-0">
                                <span>{{user.first_name}} {{user.last_name}}</span>
                                <b-form-checkbox :checked="allChecked()"
                                                 :unchecked-value="0"
                                                 :value="1"
                                                 inline
                                                 class="m-0 p-0"
                                                 @change="selectAll($event)">
                                </b-form-checkbox>
                            </form-header>
                        </b-list-group-item>
                        <b-list-group-item v-for="(unit, unitIndex) in user.units" :key="unitIndex">
                            <b-form-checkbox @change="addChangeLogByIndex(unitIndex, $event)"
                                             :id="'user_' + user.id + '_unit_' + unit.id"
                                             :name="'user_' + user.id + '_unit_' + unit.id"
                                             v-model="unit.value"
                                             :unchecked-value="0"
                                             :value="1">
                                {{unit.name}}
                            </b-form-checkbox>
                        </b-list-group-item>
                    </b-list-group>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-4">
                <submit-button class="mt-3" :loading="saving"></submit-button>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import FormHeader from "@/components/app/form/FormHeader";
    import RepeaterHeader from "@/components/app/form/RepeaterHeader";
    import SubmitButton from "@/components/app/AppButton/AppButtonSubmit";
    import {toast} from "@/shared/plugins/toastr";
    import {updateUserAccessUnits} from "@/services/property";

    export default {
        name: "UserAccessExistingUserForm",
        components: {
            FormHeader,
            RepeaterHeader,
            SubmitButton
        },
        data() {
            return {
                editList: [],
                user: null,
                saving: false
            }
        },
        props: {
            userData: {
                type: Object,
                default: null
            }
        },
        watch: {
            userData: {
                handler(value) {
                    this.user = value;
                }
            }
        },
        methods: {
            save() {
                if (this.editList.length === 0) {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NO_CHANGE'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return
                }
                this.saving = true
                updateUserAccessUnits(this.$route.params.id, {userUnits: JSON.stringify(this.editList)}).then((res) => {
                    this.editList = []
                    this.$emit('addExistingUser', res.data)
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                }, error => {
                    this.showErrorMessages(error);
                }).finally(() => {
                    this.saving = false
                })
            },
            addChangeLogByIndex(unitIndex, event) {
                let deleted = event === 1 ? 0 : 1
                let unitId = this.user.units[unitIndex].id
                let userId = this.user.id
                // find index if there is already the same element in array and remove it
                let index = this.editList.findIndex(element => element.user_id === userId && element.unit_id === unitId)
                if (index > -1) {
                    this.editList.splice(index, 1)
                } else {
                    // adding to update
                    this.editList.push({user_id: userId, unit_id: unitId, deleted: deleted})
                }
            },
            selectAll(event) {
                let forLength = this.user.units.length
                for (let i = 0; i < forLength; i++) {
                    if (this.user.units[i].value !== event) {
                        this.addChangeLogByIndex(i, event)
                        this.user.units[i].value = event
                    }
                }
            },
            allChecked() {
                return this.userData.units.findIndex(element => element.value === 0) < 0 ? 1 : 0
            },
        }
    }
</script>

<style scoped>
    .border_not_last span:not(:last-child) {
        border-right: 1px solid lightgray;
        margin-right: .6rem;
        padding-right: .5rem;
    }
</style>
