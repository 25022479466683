<template>
    <div>
        <distribution-reservation-list></distribution-reservation-list>
    </div>
</template>

<script>

export default {
    name: "Index",
    components: {
        "DistributionReservationList": () => import("@/components/channel_manager/distribution/DistributionReservationList"),
    }
}
</script>

<style scoped>

</style>
