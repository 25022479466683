<template>
    <b-card>
        <template slot="header">
            <div class="header-caption">Setup</div>
        </template>
    </b-card>
</template>

<script>
    export default {
        name: "Index"
    }
</script>

<style scoped>

</style>
