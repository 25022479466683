<template>
    <div class="d-flex flex-column justify-content-end main_calendar--cell"
         :class="{'main_calendar-br_green': Number(date.format('D')) === date.daysInMonth()}">
        <div v-if="showMonth"
             class="main_calendar__date_header--year animated fadeIn ml-1">
            {{date.format('MMMM')}}
        </div>


        <div class="main_calendar__date_header--date ml-1" :class="isWeekend">{{date | dayNumber}}</div>
        <div class="main_calendar__date_header--day ml-1" :class="isWeekend">{{date | dayString}}</div>
    </div>
</template>

<script>
    export default {
        name: "TableDateCell",
        props: {
            date: {
                type: Object
            },
            index: {
                type: Number
            },
            position: {
                type: Number
            }
        },
        computed: {
            isWeekend() {
                if ([0,6].includes(this.date.day())) {
                    return 'main_calendar__date_header--weekend'
                }
                return ''
            },
            pixelsWide() {
                return 2.87 * 16
            },
            myStartingPos() {
                return this.pixelsWide * this.index
            },
            myEndingPos() {
                return this.pixelsWide * (this.index + 1)
            },

            calculatedPosition() {
                return this.index !== 1 && this.myStartingPos <= (this.position + this.pixelsWide) && this.myEndingPos >= (this.position + this.pixelsWide)
            },

            showMonth() {
                return this.index === 0 || this.date.date() === 1 || this.calculatedPosition
            }
        }
    }
</script>

<style scoped>

</style>
