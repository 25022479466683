<template>
    <form @submit.prevent="search" class="filter">
        <b-row>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t('RESERVATION_CODE')}}</label>
                <b-input v-model="filter.reservation_code" type="text"></b-input>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t('STATUS') }}</label>
                <app-select mode="multiselect"

                            :options="statusList"
                            v-model="filter.status"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t('NUMBER') }} / {{ $t('APPROVAL_CODE') }}</label>
                <b-input v-model="filter.order_number_approval_code" type="text"></b-input>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("TYPE") }}</label>
                <app-select mode="multiselect" value-field="id" text-field="label" :options="transactionTypeOptions" v-model="filter.type"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("PERIOD") }}</label>
                <app-date-range-picker  v-model="period" ></app-date-range-picker>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>Owner/guest invoice</label>
                <app-select mode="multiselect" :options="containInvoiceOptions" text-field="label"
                            v-model="contain_invoice"></app-select>
            </b-col>
            <b-col xl="4" lg="4" md="6" class="mb-3">
                <app-button-submit class="filter-submit-wrapper" :inline="true" :disabled="loading" :loading="loaderSubmit"
                            variant="primary"
                            button_type="search">{{ $t("SEARCH") }}
                </app-button-submit>

                <app-button-reset @click="resetForm" :disabled="loading" :loading="loaderReset" :inline="true"
                                  class="pl-2 ml-2"></app-button-reset>
            </b-col>
        </b-row>

    </form>
</template>

<script>
function formatHasInvoiceList(list) {
    return list.reduce((acc, val) => {
        acc[val] = 0
        return acc
    }, {})
}
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import {getTransactionStatus, getTransactionType} from "@/services/finance/transaction";
export default {
    name: "TransactionFilter",
    props:{
        loading:{
            type:Boolean,
            default:false
        }
    },
    components: {AppButtonReset, AppButtonSubmit, AppDateRangePicker, AppSelect},
    data() {
        return {
            filter:{
                reservation_code:null,
                status:[],
                order_number_approval_code:null,
                type:[],
            },
            contain_invoice:[],
            period:{
                start:null,
                end:null
            },
            transactionTypeOptions:[],
            statusList:[],
            loaderReset:false,
            loaderSubmit:false,
            reset:0
        }
    },
    computed:{
        containInvoiceOptions() {
            return [
                {id: 'has_invoice_owner', label: this.$t('NO_OWNER_INVOICE')},
                {id: 'has_invoice_guest', label: this.$t('NO_GUEST_INVOICE')},
            ]
        },

    },
    methods: {
        search(){
            this.reset = 0

            if(this.period.start !== null){

               const created_interval = {from:this.period.start,to:this.period.end}
                this.$emit('search',{...this.filter,...{created_interval},...formatHasInvoiceList(this.contain_invoice)})
                return
            }
            this.$emit('search',{...this.filter,...formatHasInvoiceList(this.contain_invoice)})
        },
        resetForm() {
            this.reset = 1
            this.filter.reservation_code = null,
            this.filter.status = [],
            this.filter.order_number_approval_code = null,
            this.filter.type = [],
            this.contain_invoice = []
            this.period.start = null
            this.period.end = null


            this.$emit('searchData', {})
        }
    },
    created() {
        getTransactionStatus().then(response=>{
            this.statusList = response.data
        }, () => {
            this.statusList = []
        })
        getTransactionType().then(response=>{
            this.transactionTypeOptions = response.data
        }, () => {
            this.transactionTypeOptions = []
        })
    },
    watch:{
    loading: {
        handler(value) {
            if (this.reset) {
                this.loaderReset = value
            } else {
                this.loaderSubmit = value
            }
        }
    }
    }
}
</script>

<style scoped>

</style>
