<template>
    <div>
        <b-form>
            <label>{{$t('API_KEY')}}</label>
            <b-form-input
                class="col-lg-6"
                type="text"
                v-model="api_key" :disabled="true">
            </b-form-input>
        </b-form>
    </div>
</template>

<script>

export default {
    name: "Index",
    data(){
        return{
            api_key: null
        }
    },
    computed:{
        website() {
            return this.$store.getters['website/getWebsite']
        }
    },
    created() {
        this.api_key = this.website.api_key
    }
}
</script>

<style scoped>

</style>
