<template>
    <div v-if="invoiceData" class="read-only">
        <div class="items">
            <div class="item">
                <div class="item-label">{{$t('TYPE')}}:</div>
                <div class="item-content">{{invoiceData.invoice_type.name}}</div>
            </div>

            <div class="item">
                <div class="item-label">{{$t('CITY')}}:</div>
                <div class="item-content">{{invoiceData.city.name}}</div>
            </div>

            <div class="item">
                <div class="item-label">{{$t('DELIVERY_DATE')}}:</div>
                <div class="item-content">{{invoiceData.delivery_date | date}}</div>
            </div>

            <div class="item">
                <div class="item-label">{{$t('DUE_DATE')}}:</div>
                <div class="item-content">{{invoiceData.due_date | date}}</div>
            </div>

            <div class="item">
                <div class="item-label">{{$t('DATE_CREATED')}}:</div>
                <div class="item-content">{{invoiceData.ts_created | date}}</div>
            </div>
        </div>

        <form-header v-if="statusList.length">{{$t('STATUS')}}</form-header>

        <div class="items">
            <div class="item">
                <div class="item-label">{{$t('CURRENT_STATUS')}}:</div>
                <div class="item-content">{{invoiceData.status.label}}</div>
            </div>

            <div class="item" v-if="statusList.length">
                <app-button v-for="(status, index) in statusList"
                            :class="index ? 'ml-3' : ''" :loading="changingStatus"
                            @click="openConfirmationDialog(status, invoiceData)">
                    {{status.transition_label}}
                </app-button>
            </div>
        </div>

        <form-header>{{$t('CLIENT')}}</form-header>

        <div class="items">
            <div class="item">
                <div class="item-label">{{$t('NAME')}}:</div>
                <div class="item-content">{{`${invoiceData.invoicee.name} ${invoiceData.invoicee.last_name}`}}</div>
            </div>

            <div class="item">
                <div class="item-label">{{$t('EMAIL')}}:</div>
                <div class="item-content">{{invoiceData.invoicee.email}}</div>
            </div>

            <div class="item">
                <div class="item-label">{{$t('PERSONAL_ID_NUMBER')}}:</div>
                <div class="item-content">{{invoiceData.invoicee.oib}}</div>
            </div>

            <div class="item">
                <div class="item-label">{{$t('ADDRESS')}}:</div>
                <div class="item-content">{{invoiceData.invoicee.address}}</div>
            </div>

            <div class="item">
                <div class="item-label">{{$t('CITY')}}:</div>
                <div class="item-content">{{invoiceData.invoicee.city.label}}</div>
            </div>
        </div>

        <form-header>{{$t('ITEMS')}}</form-header>

        <b-table :items="Object.values(invoiceData.items)" :fields="itemFields" small responsive foot-clone tfoot-tr-class="text-right">
            <template v-slot:unit_price="data">{{data.item.unit_price.toFixed(2)}}</template>
            <template v-slot:discount="data">{{data.item.discount.toFixed(2)}}</template>
            <template v-slot:vat_amount="data">{{data.item.vat_amount.toFixed(2)}}</template>
            <template v-slot:quantity="data">{{data.item.quantity}} {{data.item.unit_of_measurement}}</template>
            <template v-slot:gross="data">{{data.item.gross.toFixed(2)}}</template>

            <template v-for="field in itemFields" v-slot:[getFootSlotName(field)]="data">
                {{''}}
            </template>

            <template v-slot:FOOT_unit_price="data">
                {{$t('TOTAL')}}:
            </template>

            <template v-slot:FOOT_discount="data">
                {{invoiceData.discount.toFixed(2)}}
            </template>

            <template v-slot:FOOT_vat_amount="data">
                {{invoiceData.tax.toFixed(2)}}
            </template>

            <template v-slot:FOOT_gross="data">
                {{invoiceData.gross.toFixed(2)}}
            </template>
        </b-table>

        <app-status-change-confirmation-dialog
            v-model="dialogState"
            :current="invoiceObject.status && invoiceObject.status.label"
            :next="nextStatusLabel" @confirm="callAction(dialogAction, invoiceObject.id)" @cancel="dialogState=false">
        </app-status-change-confirmation-dialog>
    </div>
</template>

<script>
    import {cancelInvoice, fetchInvoice, payInvoice} from "@/services/finance";
    import FormHeader from "@/components/app/form/FormHeader";
    import {getInvoiceStatusList} from "@/services/status";
    import {INVOICE_STATUS_CANCELED, INVOICE_STATUS_PAID} from "@/shared/constants";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import AppStatusChangeConfirmationDialog from "@/components/app/AppStatusChangeConfirmationDialog";

    const actions = {
        [INVOICE_STATUS_CANCELED]: cancelInvoice,
        [INVOICE_STATUS_PAID]: payInvoice,
    }

    export default {
        name: "InvoiceDetails",
        components: {AppStatusChangeConfirmationDialog, AppButton, FormHeader},
        props: {
            invoiceId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                invoiceData: null,
                itemFields: [
                    {key: 'id', label: this.$t('ID')},
                    {key: 'description', label: this.$t('DESCRIPTION')},
                    {key: 'quantity', label: this.$t('QUANTITY')},
                    {key: 'unit_price', label: this.$t('UNIT_PRICE'), tdClass: 'text-right'},
                    {key: 'discount', label: this.$t('DISCOUNT'), tdClass: 'text-right'},
                    {key: 'vat_amount', label: this.$t('VAT'), tdClass: 'text-right'},
                    {key: 'gross', label: this.$t('AMOUNT'), tdClass: 'text-right'},
                ],
                dialogState: false,
                dialogAction: null,
                invoiceObject: {},
                nextStatusLabel: null,
                changingStatus: false,
                statusList: []
            }
        },
        methods: {
            getFootSlotName(field) {
                return `FOOT_${field.key}`
            },
            openConfirmationDialog(statusObject, invoiceObject) {
                this.dialogAction = statusObject.id
                this.nextStatusLabel = statusObject.label
                this.invoiceObject = invoiceObject
                this.dialogState = true
            },
            callAction(action, id) {
                this.dialogState = false
                this.changingStatus = true
                actions[action](id).then(() => {
                    notifySuccess()
                    this.$emit('close')
                }, (error) => {
                    this.showErrorMessages(error)
                }).finally(() => this.changingStatus = false)
            },
        },
        created() {
            getInvoiceStatusList({invoice: this.invoiceId}).then(response => {
                this.statusList = response.data
            })

            fetchInvoice(this.invoiceId).then(response => {
                this.invoiceData = response.data
            })
        }
    }
</script>

<style scoped>

</style>
