<template>
    <span>
        <delete-policy @click="deleteDialogState=true" class="pull-right" :loading="deletePolicyState"></delete-policy>
        <cancelation-delete-dialog :show="deleteDialogState" @confirm="deletePolicy"
                                   @cancel="deleteDialogState=false" :delete_title="true"></cancelation-delete-dialog>
    </span>
</template>

<script>
    import DeletePolicy from '@/components/app/AppButton/AppButtonDelete'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import CancelationDeleteDialog from '@/components/app/form/AppConfirmationDialog'
    import {toast} from '@/shared/plugins/toastr'

    export default {
        name: "CancelationPolicyItemDelete",
        props: {
            policy: {
                type: Object,
                required: true
            }
        },
        components: {
            DeletePolicy, CancelationDeleteDialog
        },
        mixins: [getErrorMessage],
        data() {
            return {
                deletePolicyState: false,
                deleteDialogState: false,
            }
        },
        methods: {
            deletePolicy() {
                this.deleteDialogState = false
                this.deletePolicyState = false

                this.$emit("deletePolicy")
            }
        }
    }
</script>

<style scoped>

</style>
