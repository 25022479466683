<template>
    <b-input-group :prepend="currency">
        <b-form-input type="number" :value="value" @input="changeValue"></b-form-input>
    </b-input-group>
</template>

<script>
    export default {
        name: "InputPriceFormElement",
        props: {

            occupancy_id: {
                type: Number
            },
            los_id: {
                type: Number
            },
            currency: {
                type: String,
                default: 'EUR'
            },
            value: {
                type: String
            }
        },

        methods: {
            changeValue(val) {

                this.$emit('input', val)
            }
        }
    }
</script>

<style scoped>

</style>
