<template>
    <b-form-group class="mb-0" v-if="isVisible">
        <b-tooltip v-if="hasMessage" :target="component_id"
                   placement="top"
                   :title="message"></b-tooltip>
        <label v-if="label">{{label}}</label>
        <span :id="component_id">
        <app-select-list
            :variant="variant"
            :class="computedClass"
            :value="value"
            :size="size"
            :label="label"
            :boundary="boundary"
            :search-input="SearchInput"
            :popperOpts="popperOpts"
            :value-field="ValueField"
            :text-field="TextField"
            :options="options"
            @input="eventInput"
            @hidden="patchTabIndex()"
            :disabled="disabled"
            :state="state"
            :SearchEmptyItem="SearchEmptyItem"
            :set-first="setFirst"
            :return-type="returnType"
            :sanitize-text-value="sanitizeTextValue"
            v-if="mode === 'select'">
            <template v-slot:text="data">
                <slot name="text" :item="data.item"></slot>
            </template>
        </app-select-list>

        <app-multi-select
            :variant="variant"
            :class="computedClass"
            :value="value"
            :size="size"
            :label="label"
            :boundary="boundary"
            :popperOpts="popperOpts"
            :value-field="ValueField"
            :text-field="TextField"
            :options="options"
            :group="group"
            :group-select-all="groupSelectAll"
            :disabled="disabled"
            :state="state"
            :search-input="SearchInput"
            :set-first="setFirst"
            @input="eventInput"
            @hidden="patchTabIndex()"
            v-else-if="mode === 'multiselect'">
            <template v-slot:text>
                <slot name="text"></slot>
            </template>
        </app-multi-select>

        <app-select-search
            :variant="variant"
            :class="computedClass"
            :value="value"
            :size="size"
            :label="label"
            :boundary="boundary"
            :popperOpts="popperOpts"
            :value-field="ValueField"
            :text-field="TextField"
            :options="options"
            @input="eventInput"
            @hidden="patchTabIndex()"
            :search="search"
            :min-keyword-length="minKeywordLength"
            :SearchOnCreate="SearchOnCreate"
            :SearchClear="SearchClear"
            :SearchEmptyItem="SearchEmptyItem"
            :disabled="disabled"
            :state="state"
            :set-first="setFirst"
            v-else-if="mode === 'search'">
            <template v-slot:text="data">
                <slot name="text" :item="data.item"></slot>
            </template>
        </app-select-search>
        </span>

    </b-form-group>

</template>

<script>
    import AppSelectList from '@/components/app/AppSelect/components/AppSelectList'
    import AppSelectSearch from '@/components/app/AppSelect/components/AppSelectSearch'
    import AppMultiSelect from '@/components/app/AppSelect/components/AppMultiSelect'
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";

    export default {
        name: "AppSelect",
        components: {
            AppSelectList, AppSelectSearch, AppMultiSelect
        },
        mixins: [AccessControlComponent],
        props: {
            options: {
                type: Array | Object,
                default: () => {
                    return []
                }
            },
            value: {
                default: null
            },
            ValueField: {
                type: String,
                default: 'id'
            },
            TextField: {
                type: String,
                default: 'name'
            },
            variant: {
                type: String,
                default: 'default'
            },
            search: {
                type: Function,
            },
            SearchInput: {
                type: Boolean,
                default: false
            },
            SearchOnCreate: {
                type: Boolean,
                default: false
            },
            mode: {
                type: String,
                default: 'select',
                validator: value => {
                    return (['select', 'multiselect', 'search']).includes(value) // multisearch = not implemented
                }
            },
            size: {
                type: String,
                default: 'lg'
            },
            group: {
                type: String
            },
            disabled: {
                type: Boolean,
                default: false
            },
            SearchClear: {
                type: Boolean,
                default: false
            },
            SearchEmptyItem: {
                type: Boolean,
                default: true
            },
            boundary: {
                type: String,
                default: 'viewport'
            },
            minKeywordLength: {
                type: Number,
                default: 0,
            },
            access_control_context: {
                type: Object,
                default: null
            },
            label: {
                type: String,
                default: null
            },
            state: {
                default: null
            },
            setFirst: {
                type: Boolean,
                default: false
            },
            returnType: {
                type: String,
            },
            hasMessage:{
                type:Boolean,
                default:false
            },
            message:{
                type:String
            },
            sanitizeTextValue: {
                type: Boolean,
                default: true,
            },
            noTabIndex: {
                type: Boolean,
                default: false,
            },
            groupSelectAll: {
                type: Boolean,
                default: false,
            },
        },
        data(){
          return {
              component_id:null
          }
        },
        computed: {
            isVisible() {
                return this.AccessControlVisible
            },
            computedClass() {
                return {
                    'dropdown_field': true,
                    'w-100': true
                }
            },
            popperOpts() {
                return {
                    positionFixed: true,
                    modifiers: {
                        preventOverflow: {
                            boundariesElement: this.boundary
                        }
                    }
                }
            }
        },
        methods: {
            eventInput(data) {
                this.$emit('input', data)
            },
            patchTabIndex() {
                // [Exclude <b-dropdown> from tab flow](https://github.com/bootstrap-vue/bootstrap-vue/issues/3729)
                // Fix dropdown button tabindex. We can't do this using bootstrap vue API. Set tabindex="-1"
                if (this.noTabIndex === true) {
                    this.$nextTick(() => {
                        this.$el.querySelectorAll('.btn[aria-haspopup="true"]').forEach(btn => {
                            btn.setAttribute('tabindex', '-1');
                        });
                    });
                }
            }
        },
        created() {
            if (this.component_id === null) {
                this.component_id = 'AppSelect_' + this._uid
            }
        },
        mounted() {
            this.patchTabIndex();
        },
        updated() {
            this.patchTabIndex();
        }
    }
</script>

<style scoped>

</style>
