<template>
    <div>
        <form v-if="mode==='property_calendar'">
            <b-row v-if="units.length > 1">
                <b-col class="mb-4">
                    <label>{{ $t("UNIT") }}</label>
                    <app-select mode="multiselect" v-model="selectedUnits" :options="units"></app-select>
                </b-col>
            </b-row>

            <div class="table-wrapper">
                <table class="table">
                    <thead>
                    <tr>
                        <th class="table-header-period-range" scope="col">{{ $t('PERIOD') }}</th>
                        <th class="table-header-actions text-right" scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <period-item :key="period.id + '_' + period.deleted" v-for="period in datePeriodList"
                                 v-show="period.deleted !== 1" :period="period" :disabled="true"
                                 @delPeriod="removePeriod" ref="period_items" :disabled-dates="disabledDates"></period-item>
                    <period-item-new @save="addPeriod"
                                     :max-date="maxDate"
                                     :from-date="fromDate"
                                     :loading="loading_add_new" :disabled-dates="disabledDates"></period-item-new>
                    </tbody>
                </table>
            </div>
            <template v-if="datePeriodList.filter(el=> el.deleted !== 1).length > 0 && selectedUnits.length > 0">
                <b-form>
                    <b-row>
                        <b-col>
                            <label>{{ $t('WEEKDAY_APPLY_CHANGES_TO') }}</label>
                        </b-col>
                    </b-row>
                    <b-row class="mb-4">
                        <b-col class="d-flex">
                            <b-form-checkbox v-model="selected_days_all" :inline="true" :value="true"
                                             :unchecked-value="false" class="b-r-1 pr-1 mr-1">{{ $t("SELECT_ALL") }}
                            </b-form-checkbox>
                            <b-form-checkbox-group v-model="selected_days" :options="options"
                                                   name="" class="ml-0"></b-form-checkbox-group>
                        </b-col>
                    </b-row>
                </b-form>
                <b-row>
                    <b-col>

                        <b-tabs v-model="tab" nav-class="tab_navigation-horizontal" content-class="tab_content-horizontal"
                                pills align="left">

                            <b-tab :title="$t('AVAILABILITY_AND_UNIT_STATUS')"
                                   :key="0">
                                <template v-if="checkPermission(C_PROPERTY_CALENDAR_BULK_AVAILABILITY_E)">
                                    <form-header header-class="mb-4">
                                        {{ $t('AVAILABILITY') }}
                                    </form-header>
                                    <main-calendar-group-availability-form :units="selectedUnits"
                                                                           :periods="datePeriodList"
                                                                           :allowed-weekday="selected_days"></main-calendar-group-availability-form>
                                </template>
                                <template v-if="checkPermission(C_PROPERTY_CALENDAR_BULK_SALES_PERIODS_E)">
                                    <form-header>
                                        {{ $t('ROOM_STATUS') }}
                                    </form-header>
                                    <main-calendar-group-room-status-form :units="selectedUnits"
                                                                          :periods="datePeriodList"
                                                                          :allowed-weekday="selected_days"
                                    ></main-calendar-group-room-status-form>
                                </template>
                            </b-tab>
                            <b-tab v-if="checkPermission(C_PROPERTY_CALENDAR_BULK_RATES_E)" :title="$t('RATES')" :key="1">
                                <main-calendar-group-rates-form
                                    :selected_unit_ids="selectedUnits"
                                    :periods="datePeriodList"
                                    :allowed-weekday="selected_days"
                                    :units="units_with_los_occupancy"
                                    :rates="rates"
                                ></main-calendar-group-rates-form>
                            </b-tab>
                        </b-tabs>

                    </b-col>
                </b-row>
            </template>


        </form>
        <form v-if="mode==='main_calendar'" >
            <b-row v-if="units.length > 0">
                <b-col class="mb-3">
                    <h4>
                        {{$t("ITEMS_SELECTED", {value: units.length})}}
                    </h4>
                </b-col>
            </b-row>

            <div class="table-wrapper">
                <table class="table">
                    <thead>
                    <tr>
                        <th class="table-header-period-range" scope="col">{{ $t('PERIOD') }}</th>
                        <th class="table-header-actions text-right" scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <period-item :key="period.id + '_' + period.deleted" v-for="period in datePeriodList"
                                 v-show="period.deleted !== 1" :period="period" :disabled="true"
                                 @delPeriod="removePeriod" ref="period_items" :disabled-dates="disabledDates"></period-item>
                    <period-item-new @save="addPeriod"
                                     :max-date="maxDate"
                                     :from-date="fromDate"
                                     :loading="loading_add_new" :disabled-dates="disabledDates"></period-item-new>
                    </tbody>
                </table>
            </div>
            <template v-if="datePeriodList.length > 0 && selectedUnits.length > 0">
                <b-form>
                    <b-row>
                        <b-col>
                            <label>{{ $t('WEEKDAY_APPLY_CHANGES_TO') }}</label>
                        </b-col>
                    </b-row>
                    <b-row class="mb-4">
                        <b-col class="d-flex">
                            <b-form-checkbox v-model="selected_days_all" :inline="true" :value="true"
                                             :unchecked-value="false" class="b-r-1 pr-1 mr-1">{{ $t("SELECT_ALL") }}
                            </b-form-checkbox>
                            <b-form-checkbox-group v-model="selected_days" :options="options"
                                                   name="" class="ml-0"></b-form-checkbox-group>
                        </b-col>
                    </b-row>
                </b-form>
                <b-row>
                    <b-col>

                        <b-tabs v-model="tab" nav-class="tab_navigation-horizontal" content-class="tab_content-horizontal"
                                pills align="left">

                            <b-tab :title="!selected_contingent_item || checkPermission(C_PROPERTY_CALENDAR_BULK_SALES_PERIODS_E) ? $t('AVAILABILITY_AND_UNIT_STATUS') : $t('AVAILABILITY')"
                                   :key="0">
                                <template  v-if="checkPermission(C_PROPERTY_CALENDAR_BULK_AVAILABILITY_E)">
                                    <form-header header-class="mb-4">
                                        {{ $t('AVAILABILITY') }}
                                    </form-header>
                                    <main-calendar-group-availability-form :units="selectedUnits"
                                                                           :periods="datePeriodList"
                                                                           :allowed-weekday="selected_days"></main-calendar-group-availability-form>
                                </template>
                                <template v-if="checkPermission(C_PROPERTY_CALENDAR_BULK_SALES_PERIODS_E) && !selected_contingent_item">
                                    <form-header>
                                        {{ $t('ROOM_STATUS') }}
                                    </form-header>
                                    <main-calendar-group-room-status-form :units="selectedUnits"
                                                                          :periods="datePeriodList"
                                                                          :allowed-weekday="selected_days"
                                    ></main-calendar-group-room-status-form>
                                </template>
                            </b-tab>
                            <b-tab v-if="checkPermission(C_PROPERTY_CALENDAR_BULK_RATES_E) && !selected_contingent_item" :title="$t('RATES')" :key="1">
                                                     <main-calendar-group-rates-form
                                                         :selected_unit_ids="selectedUnits"
                                                         :periods="datePeriodList"
                                                         :allowed-weekday="selected_days"
                                                         :units="units_with_los_occupancy"
                                                         :rates="rates"
                                                     ></main-calendar-group-rates-form>
                            </b-tab>
                        </b-tabs>

                    </b-col>
                </b-row>
            </template>
        </form>
    </div>

</template>

<script>

import {getErrorMessage} from '@/mixins/error/getErrorMessage'

import moment from 'moment'
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
import AppButton from "@/components/app/AppButton/AppButton";
import MainCalendarGroupRoomStatusForm
    from "@/components/unit/calendar_opt/Aside/Bulk/MainCalendarGroupRoomStatusForm";
import MainCalendarGroupRatesForm from "@/components/unit/calendar_opt/Aside/Bulk/MainCalendarGroupRatesForm";
import {cloneDeep,isEqual,differenceWith} from 'lodash'
import {
    C_PROPERTY_CALENDAR_BULK_RATES_E,
    C_PROPERTY_CALENDAR_BULK_SALES_PERIODS_E,
    C_PROPERTY_CALENDAR_BULK_AVAILABILITY_E,
} from "@/shared/component_permission";
import PeriodItem from "@/components/app/Period/PeriodItem";
import PeriodItemNew from "@/components/app/Period/PeriodItemNew";
import FormHeader from "@/components/app/form/FormHeader";
import MainCalendarGroupAvailabilityForm
    from "@/components/unit/calendar_opt/Aside/Bulk/MainCalendarGroupAvailabilityForm";
import {getCalendarValues} from "@/services/property";
import {getUnitList} from "@/services/unit";


export default {
    name: "MainCalendarGroupEdit",
    components: {
        MainCalendarGroupAvailabilityForm,
        FormHeader,
        PeriodItemNew,
        PeriodItem,
        MainCalendarGroupRatesForm,
        MainCalendarGroupRoomStatusForm, AppButton, AppDateRangePicker, AppSelect
    },
    props: {

        defaultSelectedUnits: {
            type: Array
        },
        default_date_range: {
            default: () => {
                return {id: 'index_0', start: null, end: null, deleted: 0}
            },
            type: Object
        },
        propertyList: {
            type: Array
        },
        unit_options:{
            type:Array
        },
        mode:{
            type:String,
            default:'property_calendar'
        },
        selected_contingent_item:{
            type:Boolean,
            default:false
        }
    },
    mixins: [getErrorMessage],
    data() {
        return {
            fields: [
                {key: "period", label: this.$t('PERIOD')},
                {key: "options", label: "", class: "text-right"}
            ],
            loading_add_new: false,
            C_PROPERTY_CALENDAR_BULK_RATES_E,
            C_PROPERTY_CALENDAR_BULK_SALES_PERIODS_E,
            C_PROPERTY_CALENDAR_BULK_AVAILABILITY_E,
            selectedUnits: [],
            datePeriodList: [],
            tab: null,
            selected_days: [],
            selected_days_all: true,
            rates:[],
            units:[],
            units_with_los_occupancy:[]
        }
    },
    computed: {

        maxDate() {
            const currentYear = new Date().getFullYear()
            let maxDate = (currentYear + 3) + '-12-31'
            return new Date(maxDate)
        },
        fromDate() {
            let periodList = this.datePeriodList.filter(el=>el.deleted !== 1)
            if (periodList.length > 0) {
                let lastItem = periodList[periodList.length - 1]
                if (lastItem.end) {
                    return new moment(lastItem.end).add(1, 'day').toDate()
                }
            }

            return undefined
        },
        options() {
            let data = []
            for (const value of [1, 2, 3, 4, 5, 6, 0]) {
                data.push({text: this.getWeekday(value), value: Number(value)})
            }

            return data
        },
        disabledDates() {
            let dateList = this.datePeriodList.filter(item => item.deleted !== 1 && item.start && item.end)
            return dateList.map(item => {
                return {
                    start: moment(item.start).toDate(),
                    end: moment(item.end).toDate(),
                }
            })
        },
    },
    methods: {
        getWeekday(number) {
            switch (Number(number)) {
                case 1:
                    return this.$t('DATE.DAYS.MONDAY')
                case 2:
                    // code block
                    return this.$t('DATE.DAYS.TUESDAY')
                case 3:
                    return this.$t('DATE.DAYS.WEDNESDAY')
                case 4:
                    return this.$t('DATE.DAYS.THURSDAY')
                case 5:
                    return this.$t('DATE.DAYS.FRIDAY')
                case 6:
                    return this.$t('DATE.DAYS.SATURDAY')
                case 0:
                    return this.$t('DATE.DAYS.SUNDAY')
            }
        },
        addPeriod(period) {
            this.loading_add_new = true
            let period_id = 'index_' + this.datePeriodList.length
            this.datePeriodList.push({
                id: period_id,
                start: period.start,
                end: period.end,
                deleted: 0,
            })
            this.$nextTick(() => this.loading_add_new = false)
        },
        removePeriod(period) {
            let index = this.datePeriodList.findIndex(element => element.id === period.id)
            if (index > -1) {
                this.$set(this.datePeriodList[index], 'deleted', 1)
            }
        },
    },
    watch: {
        selected_days_all: {
            handler(value) {
                if (value) {
                    this.selected_days = [1, 2, 3, 4, 5, 6, 0]
                } else {
                    this.selected_days = []
                }
            }, immediate: true
        },

        selectedUnits:{
            handler(unit_ids){
                getUnitList({contigent:0,id:unit_ids, limit: 1000}).then(res => {
                    this.units = res.data.items
                })
                this.$nextTick(()=>{
                    if(unit_ids.length > 0){
                        const  periods = this.datePeriodList.map(item=>{
                            return {
                                start:item.start,
                                end:item.end
                            }
                        })
                        getCalendarValues({show:['rates','units'],unit:unit_ids,periods}).then(response => {
                            if(response.data && response.data.hasOwnProperty('rates')){
                                this.rates = response.data.rates
                            }
                            if(response.data && response.data.hasOwnProperty('units')){
                                this.units_with_los_occupancy = response.data.units
                            }
                        })
                    }
                })

            }
        },
        datePeriodList:{
          handler(dateList){
              const periods = dateList.filter(el=>el.deleted !== 1)
              if(periods.length > 0 && this.selectedUnits.length >0){

                  getCalendarValues({show:['rates','units'],unit:this.selectedUnits,periods}).then(response => {
                      if(response.data && response.data.hasOwnProperty('rates')){
                          this.rates = response.data.rates
                      }
                      if(response.data && response.data.hasOwnProperty('units')){
                          this.units_with_los_occupancy = response.data.units
                      }
                  })
              }
          }
        },
    },
    mounted() {

        if (this.default_date_range.start !== null && this.default_date_range.end !== null) {

            this.datePeriodList = [
                {
                    id: 'index_0',
                    start: this.default_date_range.start,
                    end: this.default_date_range.end,
                    deleted: 0
                }
            ]
        }
        this.selectedUnits = cloneDeep(this.defaultSelectedUnits)
    }
}
</script>

<style scoped>

</style>
