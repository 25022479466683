<template>
    <b-form @submit.prevent="save" autocomplete="off">
        <b-row>
            <b-col md="12" class="mb-4">
                <app-input-control :error-object="validationObject.input">
                    <template v-slot:input="data">
                        <label>{{ $t('MESSAGE_NUMBER') }}</label>
                        <b-form-input
                            type="text"
                            v-model="paymentNumber"
                            :state="data.state">
                        </b-form-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-4">
                <app-button-submit :loading="loader">{{ $t('SAVE') }}</app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {editPaymentMessage} from "@/services/finance/payment_message";
import {cloneDeep} from "lodash";
import {PM_MESSAGE_NUMBER_NOT_VALID} from "@/shared/error_codes";
import AppInputControl from "@/components/app/AppInputControl";

export default {
    name: "PaymentMessageNumber",
    mixins: [getErrorMessage],
    components: {
        AppSelect,
        AppNumberInput,
        AppButtonSubmit,
        AppInputControl
    },
    data() {
        return {
            paymentMessage: null,
            paymentNumber: null,
            loader: false,
            validationObject: {
                input: [PM_MESSAGE_NUMBER_NOT_VALID]
            },
        }
    },
    props: {
        paymentMessageData: {
            type: Object
        },
    },
    methods: {
        save() {
            this.loader = true
            editPaymentMessage(this.paymentMessage.id, {message_number: this.paymentNumber}).then((response) => {
                notifySuccess()
                this.$emit('paymentNumberSaved', response.data)
            }).catch(error => {
                this.showErrorMessages(error, this.validationObject)
            }).finally(() => {
                this.loader = false
            })
        }
    },
    created() {
        this.paymentMessage = cloneDeep(this.paymentMessageData)
        this.paymentNumber = this.paymentMessageData.message_number
    }
}
</script>

<style scoped>

</style>
