<template>
    <form @submit.prevent="save">
        <b-row class="text-muted">
            <b-col class="col-6">
                <b-form-group>
                    <b-form-radio-group
                            stacked
                            class="main_calendar-custom-radio"
                            v-model="value"
                            :options="options"
                            name="room_status"
                    ></b-form-radio-group>
                </b-form-group>
            </b-col>

        </b-row>
        <b-row>
            <b-col>
                <app-button-submit :disabled="saveDisabled"
                                   :loading="loading_button"></app-button-submit>
            </b-col>
        </b-row>
    </form>
</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {saveGroupRoomStatus} from "@/services/pricing/restriction";
    import {EventBus, GE_CALENDAR_LOAD_AVAILABILITY, GE_CALENDAR_LOAD_ROOM_STATUS} from "@/shared/EventBus";
    import {toast} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";

    export default {
        name: "MainCalendarGroupRoomStatusForm",
        components: {AppButtonSubmit},
        mixins: [getErrorMessage],
        props: {
            units: {
                type: Array
            },
            periods: {
                type: Array
            },
            allowedWeekday:{
                type:Array
            }
        },
        data() {
            return {
                value: null,
                loading_button: false,

            }
        },
        computed: {
            options() {
                return [
                    {text: this.$t("OPEN_UNIT"), value: 1},
                    {text: this.$t("CLOSE_UNIT"), value: 0}
                ]
            },
            saveDisabled() {
                return this.value === null || this.periods.filter(item => item.deleted !== 1 && item.start && item.end).length === 0 || this.units.length === 0 || this.allowedWeekday.length === 0
            }
        },
        methods: {
            save() {
                this.loading_button = true
                saveGroupRoomStatus({
                    periods: this.periods.filter(el=>el.deleted !== 1).map(el => {

                        return {
                            from: el.start,
                            to: el.end
                        }
                    }),
                    allowed_weekday:this.allowedWeekday,
                    value: this.value,
                    unit: this.units
                }).then(() => {
                    this.value = null
                    this.units.forEach(unitId => {
                        EventBus.$emit(GE_CALENDAR_LOAD_AVAILABILITY, {unitId: unitId})
                    })
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 6000
                    })
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 10000
                    })
                }).finally(() => {
                    this.loading_button = false
                })
            }
        }
    }
</script>

<style scoped>

</style>
