<template>
    <div>

        <b-card class="main-section-card">
            <div slot="header">
                <h2>{{$t('HEALTH_MONITOR')}}</h2>
            </div>
            <health-monitor-filter></health-monitor-filter>
            <health-monitor-table></health-monitor-table>
        </b-card>
    </div>
</template>

<script>
    import HealthMonitorFilter from "@/components/health_monitor/HealthMonitorFilter";
    import HealthMonitorTable from "@/components/health_monitor/HealthMonitorTable";
    export default {
        name: "Index",
        components: {HealthMonitorTable, HealthMonitorFilter}
    }
</script>

<style scoped>

</style>
