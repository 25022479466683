<template>
    <div>
        <template v-if="isContigent">
            <div class="availability_row availability_calendar--background_gray">
                <property-calendar-content-test
                    :display_work_mode="display_work_mode"
                    :show_channel_data="show_channel_data"
                    :display="display"
                    :key="unit.id"
                    :is-root="isContigent"
                    :property="property"
                    :scrollLeftPosition="scrollLeftPosition"
                    :shiftKeyValue="shiftKeyValue"
                    :contigent="isContigent"
                    :unit="unit"
                    :parent-unit="unit"
                    :unit-data="unitData[unit.id]"
                    :reservations="reservations[unit.id]"
                    :dates="dates"
                    :isFirstMonth="isFirstMonth"
                    :currentDragDateProp="currentDragDateProp"
                    :dragPeriodStartProp="dragPeriodStartProp"
                    :clickedUnitRowIndex="clickedUnitRowIndex"
                    :definedUnitRowIndex="getDefinedUnitRowIndex(unit, false)">
                </property-calendar-content-test>
            </div>

            <div v-show="showUnit && unit.show" class="availability_row ">

                <property-calendar-content-test
                    :display_work_mode="display_work_mode"
                    :show_channel_data="show_channel_data"
                    :display="display"
                    :key="unit.id"
                    :property="property"
                    :scrollLeftPosition="scrollLeftPosition"
                    :shiftKeyValue="shiftKeyValue"
                    :contigent="false"
                    :unit="unit"
                    :parent-unit="unit"
                    :unit-data="unitData[unit.id]"
                    :reservations="reservations[unit.id]"
                    :dates="dates"
                    :isFirstMonth="isFirstMonth"
                    :currentDragDateProp="currentDragDateProp"
                    :dragPeriodStartProp="dragPeriodStartProp"
                    :clickedUnitRowIndex="clickedUnitRowIndex"
                    :definedUnitRowIndex="getDefinedUnitRowIndex(unit, true)">
                </property-calendar-content-test>
            </div>

            <div v-show="showUnit" class="availability_row" :key="'contingent_'+contigentUnit.id" v-for="contigentUnit in unit.contigent">
                <property-calendar-content-test
                    :display_work_mode="display_work_mode"
                    :show_channel_data="show_channel_data"
                    :display="display"
                    :property="property"
                    :contigent="false"
                    :scrollLeftPosition="scrollLeftPosition"
                    :shiftKeyValue="shiftKeyValue"
                    :unit="contigentUnit"
                    :parent-unit="unit"
                    :unit-data="unitData[contigentUnit.id]"
                    :reservations="reservations[contigentUnit.id]"
                    :dates="dates"
                    :isFirstMonth="isFirstMonth"
                    :currentDragDateProp="currentDragDateProp"
                    :dragPeriodStartProp="dragPeriodStartProp"
                    :clickedUnitRowIndex="clickedUnitRowIndex"
                    :definedUnitRowIndex="getDefinedUnitRowIndex(contigentUnit, true)">
                </property-calendar-content-test>
            </div>
        </template>

        <template v-else>
            <div class="availability_row availability_calendar--background_gray">
                <property-calendar-content-test
                    :display_work_mode="display_work_mode"
                    :show_channel_data="show_channel_data"
                    :display="display"
                    :property="property"
                    :is-root="true"
                    :scrollLeftPosition="scrollLeftPosition"
                    :shiftKeyValue="shiftKeyValue"
                    :contigent="false"
                    :unit="unit"
                    :key="unit.id"
                    :parent-unit="unit"
                    :reservations="reservations[unit.id]"
                    :unit-data="unitData[unit.id]"
                    :dates="dates"
                    :isFirstMonth="isFirstMonth"
                    :currentDragDateProp="currentDragDateProp"
                    :dragPeriodStartProp="dragPeriodStartProp"
                    :clickedUnitRowIndex="clickedUnitRowIndex"
                    :definedUnitRowIndex="getDefinedUnitRowIndex(unit, false)">
                </property-calendar-content-test>
            </div>
        </template>
    </div>
</template>

<script>

import PropertyCalendarContentTest from "@/components/property/calendar/PropertyCalendarContentTest";

export default {
    name: "PropertyCalendarItemTest",
    components: {
        PropertyCalendarContentTest,
    },
    props: {
        show_channel_data:{
            type:Boolean,
            default:true
        },
        unit: {
            type: Object
        },
        unitData:{
            type:Object,
        },
        reservations: {
            type: Object
        },
        dates: {
            type: Array
        },
        scrollLeftPosition: {
            type: Number,
            default: 0
        },
        shiftKeyValue: {
            type: Boolean,
            default: false
        },
        showContigentList:{
            type:Array,
        },
        property:{
            type:Object
        },
        display: {
            type:Number,
            default:0
        },
        display_work_mode:{
            type: Number,
            required: true
        },
        isFirstMonth: {
            type: Boolean,
            default: false
        },
        clickedUnitRowIndex:{
            type:Number,
            default:null
        },
        currentDragDateProp: {
            type: String,
            default: null
        },
        dragPeriodStartProp: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            showUnit:false
        }
    },
    computed: {
        isContigent() {
            if(this.unit.contigent){
                return Object.keys(this.unit.contigent).length > 0
            }
        }
    },
    methods: {
        getDefinedUnitRowIndex(unit, for_contigent_index = false) {
            let index = null
            if (for_contigent_index && unit.hasOwnProperty('contigent_index') && unit.contigent_index !== null) {
                index = unit.contigent_index
            } else if (!for_contigent_index && unit.hasOwnProperty('index') && unit.index !== null) {
                index = unit.index
            }
            return index
        },
    },
    watch: {
        showContigentList:{
            handler(list){
                if(list.length > 0){
                    let index =   list.findIndex(el => {
                        return el.id === this.unit.id
                    })
                    if(index > -1){
                        this.showUnit = list[index].value
                    }
                }
            }, deep:true
        }
    }
}
</script>

<style scoped>

</style>
