<template>
    <div>
        <b-form @submit.prevent="save">
            <b-row>
                <b-col md="6" class="mb-4">
                    <app-input-control :error-object="validation.name">
                        <template v-slot:input="data">
                            <label>{{$t('NAME')}}</label>
                            <b-input type="text" v-model="formData.name" :state="data.state"></b-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="6" class="mb-4">
                    <label>{{$t('DISABLE_FOR_ROLES')}}</label>
                    <app-select :options="rolesList" mode="multiselect" v-model="formData.disabled_roles">

                    </app-select>
                </b-col>
            </b-row>


            <b-row>
                <b-col md="6" xl="3" class="mb-4">
                    <app-input-control :error-object="validation.system_invoice_type">
                        <template v-slot:input="data">
                            <label>{{$t('SYSTEM_INVOICE_TYPE')}}</label>
                            <app-select mode="search" :search="getSystemInvoiceTypeList" search-on-create
                                        text-field="label"
                                        v-model="formData.system_invoice_type"
                                        :state="data.state"></app-select>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" xl="3" class="mb-4">
                    <app-input-control>
                        <template v-slot:input="data">
                            <label>{{$t('RECIPIENT')}}</label>
                            <app-select mode="search" :search="getInvoiceeTypeList" search-on-create
                                        text-field="label"
                                        v-model="formData.invoicee_type"
                                        :state="data.state"></app-select>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" xl="3" class="mb-4">
                    <app-input-control>
                        <template v-slot:input="data">
                            <label>{{$t('AVAILABLE_CLIENT_TYPES')}}</label>
                            <app-select mode="multiselect" :options="partnerTypeList"
                                        text-field="label"
                                        v-model="formData.available_partner_types"
                                        :state="data.state"></app-select>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" xl="3" class="mb-4">
                    <app-input-control>
                        <template v-slot:input="data">
                            <label>{{$t('PRIMARY_CLIENT_TYPE')}}</label>
                            <app-select :options="availablePartnerTypeList"
                                        text-field="label" :disabled="!availablePartnerTypeList.length"
                                        v-model="formData.primary_partner_type.id"
                                        :state="data.state"></app-select>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" xl="3" class="mb-4">
                    <app-input-control>
                        <template v-slot:input="data">
                            <label>{{ $t('CASH_REGISTER') }}</label>
                            <app-select v-model="formData.for_cr"
                                        :options="APP_SELECT_OPTIONS"
                                        :state="data.state"></app-select>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" xl="3" class="mb-4">
                    <app-input-control>
                        <template v-slot:input="data">
                            <label>{{$t('CONTACT')}}</label>
                            <app-select v-model="formData.for_contact"
                                        :options="APP_SELECT_OPTIONS"
                                        :state="data.state"></app-select>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" xl="3" class="mb-4">
                    <app-input-control>
                        <template v-slot:input="data">
                            <label>{{$t('PRIMARY')}}</label>
                            <app-select v-model="formData.is_primary"
                                        :options="APP_SELECT_OPTIONS"
                                        :state="data.state"></app-select>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" xl="3" class="mb-4">
                    <app-input-control>
                        <template v-slot:input="data">
                            <label>{{$t('ADVANCE')}}</label>
                            <app-select v-model="formData.is_avans"
                                        :options="APP_SELECT_OPTIONS"
                                        :state="data.state"></app-select>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" xl="3" class="mb-4">
                    <app-input-control>
                        <template v-slot:input="data">
                            <label>{{$t('DUE_DATE')}}</label>
                            <app-number-input v-model="formData.due_date_interval" controls :min="0"
                                              :state="data.state"></app-number-input>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" xl="3" class="mb-4">
                    <app-input-control>
                        <template v-slot:input="data">
                            <label>{{$t('SELF_ISSUE')}}</label>
                            <app-select v-model="formData.is_for_self_issue"
                                        :options="APP_SELECT_OPTIONS"
                                        :state="data.state"></app-select>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" xl="3" class="mb-4">
                    <app-input-control>
                        <template v-slot:input="data">
                            <label>{{$t('CREDIT_CARD')}}</label>
                            <app-select v-model="formData.is_cc_data_required"
                                        :options="APP_SELECT_OPTIONS"
                                        :state="data.state"></app-select>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" xl="3" class="mb-4">
                    <app-input-control :error-object="validation.city_of_invoice_var">
                        <template v-slot:input="data">
                            <label>{{$t('INVOICE_CITY_SOURCE')}}</label>
                            <app-select :options="cityVarOptions"
                                        v-model="formData.city_of_invoice_var"
                                        :state="data.state"
                                        :search-empty-item="false" text-field="label" set-first></app-select>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" xl="3" class="mb-4">
                    <app-input-control :error-object="validation.invoice_number_var">
                        <template v-slot:input="data">
                            <label>{{$t('INVOICE_NUMBER_SOURCE')}}</label>
                            <app-select :options="numberVarOptions"
                                        v-model="formData.invoice_number_var"
                                        :state="data.state"
                                        :search-empty-item="false" text-field="label" set-first></app-select>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="12" class="mb-2">
                    <app-input-control :error-object="validation.invoice_number_template">
                        <template v-slot:input="data">
                            <label>{{$t('INVOICE_NUMBER_TEMPLATE')}}</label>
                            <b-input :value="numberTemplatePreview" :state="data.state" disabled></b-input>
                        </template>

                        <template v-slot:message>
                            {{$t('V.REQUIRED')}} & {{$t('V.MUST_CONTAIN', {value: $t('NUMBER')})}}
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="mb-4">
                    <div>
                        <app-button :key="key" button-class="placeholder" variant="secondary"
                                    v-for="(value, key) in numberPlaceholders"
                                    @click="insertNumberPlaceholder(value, key)">
                            {{ key.length > 1 ? $t(key) : key }}
                        </app-button>
                        <app-button button-class="placeholder" class="pull-right" @click="clearNumberTemplate">
                            {{$t('CLEAR')}}
                        </app-button>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="mb-4">
                    <app-button-submit :c_permission="C_SETUP_FINANCE_INVOICE_TYPES_E"
                                       :loading="saving"></app-button-submit>
                </b-col>

                <b-col md="6">
                    <app-button-delete
                        v-if="invoiceTypeObject.id"
                        :c_permission="C_SETUP_FINANCE_INVOICE_TYPES_E"
                        class="pull-right"
                        @click="deleteDialogState=true">
                    </app-button-delete>

                    <app-confirmation-dialog
                        :show="deleteDialogState"
                        @confirm="_delete"
                        :delete_title="true"
                        @cancel="deleteDialogState=false">
                    </app-confirmation-dialog>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
    import {cloneDeep} from "lodash";
    import AppNumberInput from "@/components/app/form/AppNumberInput";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {C_SETUP_FINANCE_INVOICE_TYPES_E} from "@/shared/component_permission";
    import {
        getSystemInvoiceTypeList, createInvoiceType, updateInvoiceType, deleteInvoiceType
    } from "@/services/finance/setup";
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import {APP_SELECT_OPTIONS} from "@/shared/constants";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {
        INVOICE_TYPE_INVOICE_CITY_SOURCE_REQUIRED,
        INVOICE_TYPE_INVOICE_NUMBER_SOURCE_REQUIRED, INVOICE_TYPE_INVOICE_NUMBER_TEMPLATE,
        INVOICE_TYPE_NAME_REQUIRED,
        SYSTEM_INVOICE_TYPE_REQUIRED
    } from "@/shared/error_codes";
    import AppInputControl from "@/components/app/AppInputControl";
    import {getInvoiceeTypeList, getPartnerTypeList} from "@/services/finance/partner";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {getUserRolesList} from "@/services/user";

    const INVOICE_NUMBER = '{invoice_number}';
    const YEAR = '{year}';
    const INVOICE_TYPE = '{invoice_type}';
    const DELIMITER_DASH = '{delimiter_dash}';
    const DELIMITER_SLASH = '{delimiter_slash}';
    const DELIMITER_BACKSLASH = '{delimiter_backslash}';

    const PLACEHOLDER_LIST = {
        'NUMBER': INVOICE_NUMBER,
        'YEAR': YEAR,
        'INVOICE_TYPE': INVOICE_TYPE,
        '-': DELIMITER_DASH,
        '/': DELIMITER_SLASH,
        '\\': DELIMITER_BACKSLASH
    }

    export default {
        name: "InvoiceTypeForm",
        components: {
            AppButton,
            AppInputControl,
            AppSelect,
            AppButtonSubmit,
            AppNumberInput,
            AppButtonDelete,
            AppConfirmationDialog
        },
        mixins: [getErrorMessage],
        props: {
            invoiceTypeObject: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                C_SETUP_FINANCE_INVOICE_TYPES_E,
                formData: null,
                saving: false,
                deleteDialogState: false,
                systemInvoiceTypeList: [],
                partnerTypeList: [],
                rolesList:[],
                numberTemplatePreview: '',
                validation: {
                    name: INVOICE_TYPE_NAME_REQUIRED,
                    system_invoice_type: SYSTEM_INVOICE_TYPE_REQUIRED,
                    city_of_invoice_var: INVOICE_TYPE_INVOICE_CITY_SOURCE_REQUIRED,
                    invoice_number_var: INVOICE_TYPE_INVOICE_NUMBER_SOURCE_REQUIRED,
                    invoice_number_template: INVOICE_TYPE_INVOICE_NUMBER_TEMPLATE
                },
                getSystemInvoiceTypeList,
                getInvoiceeTypeList,
                APP_SELECT_OPTIONS,
                numberPlaceholders: PLACEHOLDER_LIST
            }
        },
        computed: {
            companyId() {
                return this.$store.getters['user/getCompany']
            },
            availablePartnerTypeList() {
                return this.partnerTypeList.filter(partner => this.formData.available_partner_types.includes(partner.id))
            },
            cityVarOptions() {
                return [{id: 'company', label: this.$t('COMPANY')}]
            },
            numberVarOptions() {
                return [{id: 'register_setup_id', label: this.$t('CASH_REGISTER_SETUP')}]
            }
        },
        methods: {
            save() {
                this.saving = true
                const savePromise = !this.invoiceTypeObject.id
                    ? createInvoiceType(this.formData)
                    : updateInvoiceType(this.invoiceTypeObject.id, this.formData);
                savePromise.then(response => {
                    !this.invoiceTypeObject.id ? this.$emit('created', response.data) : this.$emit('updated', response.data)
                    notifySuccess()
                }).catch(error => {
                    this.showErrorMessages(error, this.validation)
                }).finally(() => this.saving = false)
            },
            _delete() {
                this.saving = true
                deleteInvoiceType(this.invoiceTypeObject.id).then(() => {
                    this.$emit('deleted', this.invoiceTypeObject.id)
                    notifySuccess()
                }).catch(error => {
                    this.showErrorMessages(error, this.validation)
                }).finally(() => {
                    this.saving = false
                    this.deleteDialogState = false
                })
            },
            insertNumberPlaceholder(value, key) {
                this.formData.invoice_number_template = this.formData.invoice_number_template + value
                this.numberTemplatePreview += key.length > 1 ? `{${this.$t(key)}}` : key
            },
            clearNumberTemplate() {
                this.formData.invoice_number_template = this.numberTemplatePreview = ''
            },
            prettifyNumberTemplate(template) {
                if (template) {
                    for (const [key, value] of Object.entries(this.numberPlaceholders)) {
                        template = template.replace(value, key.length > 1 ? `{${this.$t(key)}}` : key)
                    }
                    return template
                } else {
                    return ''
                }
            }
        },
        created() {
            if (this.invoiceTypeObject.id) {
                this.formData = cloneDeep(this.invoiceTypeObject)
                this.numberTemplatePreview = this.prettifyNumberTemplate(this.formData.invoice_number_template)
            } else {
                this.formData = {
                    invoicee_type: {id: null, label: null},
                    primary_partner_type: {id: null, label: null},
                    system_invoice_type: {id: null, label: null},
                    available_partner_types: [],
                    invoice_number_template: '',
                    company: this.companyId,
                    disabled_roles:[]
                }
            }

            getPartnerTypeList().then(res => {
                this.partnerTypeList = res.data
            })

            getUserRolesList({company_id: this.$store.getters[`user/getCompany`]}).then(response => {
                this.rolesList = response.data;
            }, () => {

            })
        }
    }
</script>

<style scoped>

</style>
