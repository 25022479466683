<template>
    <b-card no-body>
        <b-card-header header-class="border-bottom-0 d-flex flex-wrap justify-content-between align-items-center">
            <div>
                <span class="header-2">{{$t("TRANSACTIONS")}}</span>
            </div>
            <div>
                <template
                    v-if="reservation.chd_id && allowedActionObject && allowedActionObject.hasOwnProperty('initial') && allowedActionObject.initial.length">
                    <app-button :key="actionIndex" v-for="(action, actionIndex) in allowedActionObject.initial"
                                class="mb-3 ml-3"
                                @click="showTransactionForm({action})">
                        {{ $t(permissionActionMap.initial[action].t) }}
                    </app-button>
                </template>

                <app-button v-if="reservation.chd_id && checkPermission(C_RESERVATION_ACCOMMODATION_CC_V)"
                            class="mb-3 ml-3"
                            @click="showCCData">
                    {{ $t('SHOW_CC_DATA') }}
                </app-button>

                <app-button class="mb-3 ml-3" :icon="!reservation.chd_id ? 'new' : null"
                            v-if="checkPermission(C_RESERVATION_ACCOMMODATION_CC_E)"
                            @click="editCCData">
                    {{ reservation.chd_id ? $t('EDIT_CC_DATA') : $t('CC_DATA')}}
                </app-button>

                <app-access-control tag="span" class="mb-3 ml-3" @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{key: access_control_fetch_key, function: C_RESERVATION_ACCOMMODATION_CC_INVALID}">
                    <app-button @click="invalidateCC" :loading="reporting">
                        {{$t('INVALIDATE_CC')}}
                    </app-button>
                </app-access-control>

                <app-access-control tag="span" class="mb-3 ml-3" @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{key: access_control_fetch_key, function: C_RESERVATION_ACCOMMODATION_CC_CANCEL}">
                    <app-button @click="cancelReservation" :loading="reporting">
                        {{$t('CANCEL_RESERVATION')}}
                    </app-button>
                </app-access-control>
            </div>
        </b-card-header>

        <b-card-body>
            <a-r-transaction-table
                v-if="transactionList.length && allowedActionObject && loadedTransactions && loadedValues"
                :table-data="transactionList"
                :reservation="reservation"
                :allowed-actions="allowedActionObject.transaction"
                :loading="loading"
                @openForm="showTransactionForm">
            </a-r-transaction-table>
            <app-no-data v-if="loadedTransactions && loadedValues && !loading && !transactionList.length"
                         :show-tip="false"></app-no-data>

        </b-card-body>

        <app-aside v-model="ccPanelState">
            <template slot="header">
                {{ccPanelHeader}}
            </template>
            <credit-card-form :object-type="OBJECT_TYPE_ACCOMMODATION_RESERVATION"
                              :object-id="reservation.id"
                              :chd-id="reservation.chd_id"
                              :edit="edit"
                              @created="(id)=>{this.$emit('update', {chd_id: id});this.ccPanelState=false}"
                              @updated="(id)=>{this.$emit('update', {chd_id: id});this.ccPanelState=false}"
                              @close="ccPanelState=false"></credit-card-form>
        </app-aside>

        <app-aside v-model="transactionPanelState">
            <div slot="header">{{transactionHeader}}</div>
            <transaction-form :transaction-object="transactionObject"
                              :object-type="OBJECT_TYPE_ACCOMMODATION_RESERVATION"
                              :object-id="reservation.id"
                              @close="()=>{this.transactionPanelState=false;getData(); this.$emit('reloadScheduler')}">
            </transaction-form>
        </app-aside>

        <app-confirmation-dialog
            :show="reportDialogState"
            @confirm="report"
            @cancel="reportDialogState=false">
            {{$t('CONTINUE_DIALOG')}}
        </app-confirmation-dialog>
    </b-card>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import {
        C_RESERVATION_ACCOMMODATION_CC_CANCEL,
        C_RESERVATION_ACCOMMODATION_CC_E,
        C_RESERVATION_ACCOMMODATION_CC_INVALID,
        C_RESERVATION_ACCOMMODATION_CC_V
    } from "@/shared/component_permission";
    import CreditCardForm from "@/components/finance/credit_card/CreditCardForm";
    import AppAside from "@/components/app/form/AppAside";
    import ARTransactionTable from "@/components/reservation/accommodation/list/ARTransactionTable";
    import TransactionForm from "@/components/finance/credit_card/TransactionForm";
    import {
        getAccommodationReservationTransactionList,
        getAccommodationReservationTransactionValuesList, reportAccommodationReservation
    } from "@/services/accommodation_reservation";
    import {fetchAccessControlData} from "@/services/access";
    import {
        AC_ACCOMMODATION_RESERVATION_REPORTING,
        AC_ACCOMMODATION_RESERVATION_TRANSACTION
    } from "@/mixins/AccessControl/AccessControlEnumeration";
    import {
        F_TRANSACTION_AUTHORIZE,
        F_TRANSACTION_CAPTURE,
        F_TRANSACTION_PURCHASE,
        F_TRANSACTION_REFUND,
        F_TRANSACTION_VOID,
        F_TRANSACTION_PAYMENT_ORDER,
    } from "@/shared/function_permission";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import AppNoData from "@/components/app/AppNoData";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import {notifySuccess, toast} from "@/shared/plugins/toastr";
    import {
        OBJECT_TYPE_ACCOMMODATION_RESERVATION,
        PG_ACTION_PURCHASE,
        PG_ACTION_AUTHORIZE,
        PG_ACTION_PAYMENT_ORDER,
        PG_ACTION_CAPTURE,
        PG_ACTION_REFUND,
        PG_ACTION_VOID,
    } from "@/shared/constants";

    const permissionActionMap = {
        initial: {
            [F_TRANSACTION_PURCHASE]: {id: PG_ACTION_PURCHASE, t: 'PURCHASE'},
            [F_TRANSACTION_AUTHORIZE]: {id: PG_ACTION_AUTHORIZE, t: 'AUTHORIZE'},
            [F_TRANSACTION_PAYMENT_ORDER]: {id: PG_ACTION_PAYMENT_ORDER, t: 'PAYMENT_REQUEST'},
        },
        transaction: {
            [F_TRANSACTION_CAPTURE]: {id: PG_ACTION_CAPTURE, t: 'CAPTURE'},
            [F_TRANSACTION_REFUND]: {id: PG_ACTION_REFUND, t: 'REFUND'},
            [F_TRANSACTION_VOID]: {id: PG_ACTION_VOID, t: 'VOID'},
        }
    }

    const INVALID_CC_CARD = 1
    const INVALID_CC_CARD_CANCEL = 2

    function generateACRequest(data) {
        let requestData = [];

        data.forEach(item => {
            for (const [key, value] of Object.entries(permissionActionMap.transaction)) {
                requestData.push({
                    uid: item.id,
                    transaction: item.id,
                    function: key
                })
            }
        })
        for (const [key, value] of Object.entries(permissionActionMap.initial)) {
            requestData.push({
                uid: null,
                function: key
            })
        }

        return requestData
    }

    export default {
        name: "CardModule",
        components: {
            AppConfirmationDialog,
            AppAccessControl,
            AppNoData, TransactionForm, ARTransactionTable, CreditCardForm, AppButton, AppAside
        },
        mixins: [getErrorMessage, AccessControlComponent],
        props: {
            reservation: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                C_RESERVATION_ACCOMMODATION_CC_V,
                C_RESERVATION_ACCOMMODATION_CC_E,
                OBJECT_TYPE_ACCOMMODATION_RESERVATION,
                ccPanelState: false,
                edit: false,
                transactionPanelState: false,
                transactionObject: {},
                transactionList: [],
                transactionValuesList: [],
                allowedActionObject: null,
                transactionHeader: '',
                permissionActionMap,
                loading: false,
                loadedTransactions: false,
                loadedValues: false,
                access_control_fetch_key: AC_ACCOMMODATION_RESERVATION_REPORTING,
                access_control_components: 2,
                C_RESERVATION_ACCOMMODATION_CC_INVALID,
                C_RESERVATION_ACCOMMODATION_CC_CANCEL,
                reportDialogState: false,
                reportAction: null,
                reporting: false,
            }
        },
        computed: {
            ccPanelHeader() {
                return this.edit && this.reservation.chd_id ? this.$t('EDIT_CC_DATA')
                    : this.edit ? this.$t('CREATE_CC_DATA')
                        : this.$t('CC_DATA')
            },
            company() {
                return this.$store.getters['user/getCompany']
            },
        },
        methods: {
            showCCData() {
                this.edit = false
                this.ccPanelState = true
            },
            editCCData() {
                this.edit = true
                this.ccPanelState = true
            },
            showTransactionForm({transaction = {}, action}) {
                const key = transaction.id ? 'transaction' : 'initial'
                this.transactionHeader = `${this.$t(permissionActionMap[key][action].t)} ${transaction.order_number || ''}`

                let recommendedValues
                if (transaction.id) {
                    recommendedValues = this.transactionValuesList.find(el => el.action === permissionActionMap[key][action].id && el.transaction === transaction.id) || {}
                } else {
                    recommendedValues = this.transactionValuesList.find(el => el.action === permissionActionMap[key][action].id) || {}
                }

                this.transactionObject = {
                    pg_action: permissionActionMap[key][action].id,
                    amount: recommendedValues.amount || transaction.amount || 0,
                    transaction: transaction.id,
                    next_status: recommendedValues.next_status || null
                }
                this.transactionPanelState = true
            },
            getActions(transactionList, permissionList) {
                let actionObject = {transaction: {}, initial: []}

                actionObject.initial = permissionList
                    .filter(item => !item.uid && item.visible === true && item.disabled === false)
                    .map(item => item.function)

                transactionList.forEach(transaction => {
                    actionObject.transaction[transaction.id] = permissionList
                        .filter(item => item.uid === transaction.id && item.visible === true && item.disabled === false)
                        .map(item => item.function)
                })

                return actionObject
            },
            getData() {
                this.loading = true
                this.transactionList = []
                this.allowedActionObject = null
                this.access_control_general_context = {reservation: this.reservation.id, company: this.company}

                getAccommodationReservationTransactionList(this.reservation.id).then(response => {
                    this.transactionList = response.data
                    return this.transactionList
                }).then(transactionList => {
                    return fetchAccessControlData(AC_ACCOMMODATION_RESERVATION_TRANSACTION, {data: generateACRequest(transactionList), context: this.access_control_general_context})
                }).then(acResponse => {
                    this.allowedActionObject = this.getActions(this.transactionList, acResponse.data)
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.loading = false
                    this.loadedTransactions = true
                })

                getAccommodationReservationTransactionValuesList(this.reservation.id).then(response => {
                    this.transactionValuesList = response.data
                }, error => this.showErrorMessages(error)).finally(() => this.loadedValues = true)
            },
            invalidateCC() {
                this.reportDialogState = true
                this.reportAction = INVALID_CC_CARD
            },
            cancelReservation() {
                this.reportDialogState = true
                this.reportAction = INVALID_CC_CARD_CANCEL
            },
            report() {
                this.reporting = true
                reportAccommodationReservation(this.reservation.id, {
                    report_action: this.reportAction
                }).then(() => {
                    notifySuccess()
                }, error => {
                    toast({message: this.$t('NOTIFICATIONS.ERROR'), type: 'error'})
                }).finally(() => {
                    this.reportDialogState = false
                    this.reporting = false
                })
            }
        },
        created() {
            this.getData()
        }
    }
</script>

<style scoped>

</style>
