<template>
    <div>
        <property-reservation-setup :setup_parameter="SETUP_PARAMETER_OWNER_STATEMENT_SETUP"></property-reservation-setup>
    </div>
</template>

<script>


import {SETUP_PARAMETER_OWNER_STATEMENT_SETUP} from "@/shared/constants";

export default {
    name: "Index",
    components: {
        "PropertyReservationSetup": () => import("@/components/property/reservation_setup/PropertyReservationSetup")
    },
    data() {
        return {
            SETUP_PARAMETER_OWNER_STATEMENT_SETUP
        }
    },
}
</script>

<style scoped>

</style>
