<template>
    <b-col xs="6" sm="6" md="4" lg="2" class="mt-2">
        <b-card header-class="p-0" body-class="p-0">
            <div v-if="item.type.indexOf('image') !== -1" slot="header" :class="imageThumbRoute ? 'image-item' : 'document-item'"
                 :title="item.description"
                 :style="{ backgroundImage: 'url(' + imageThumbRoute + ')' }">
                <div class="image-loading" v-if="loading">
                    <b-spinner variant="primary"></b-spinner>
                </div>
                <template>
                    <i @click="clickedImg" class="fa fa-search-plus fa-shading fa-fw p-1 m-1 image-zoom"
                       aria-hidden="true"></i>
                    <div class="imgTag" v-if="isPropertyGallery">
                        <b-badge variant="primary">{{ $t("PROPERTY_GALLERY") }}</b-badge>
                    </div>
                </template>
                <template v-if="!editDisabled">
                    <i @click="triggerDeleteDialog" :id="'delete_img'+item.id"
                       :class="deleteDisabled ?'disable-delete':''" v-b-popover.hover="item.name"
                       class="fa fa-shading fa-times fa-fw p-1 m-1 image-delete"
                       aria-hidden="true"></i>
                    <b-form-checkbox @input="emmitChecked" :checked="selected"
                                     class="document-checkbox mr-0"></b-form-checkbox>
                </template>
            </div>
            <div v-else slot="header" class="document-item">
                <div class="image-loading" v-if="loading">
                    <b-spinner variant="primary"></b-spinner>
                </div>

                <i class="fa fa-file-text-o fa-4x fa-fw document-dummy-icon" aria-hidden="true"></i>
                <i @click="triggerDeleteDialog" v-b-popover.hover="item.name"
                   class="fa fa-shading fa-times fa-fw p-1 m-1 image-delete"
                   aria-hidden="true"></i>
                <b-form-checkbox :disabled="!editDisabled" @input="emmitChecked" :checked="selected"
                                 class="document-checkbox mr-0"></b-form-checkbox>
            </div>
            <b-card-body class="p-1 text-container" v-if="textAreaShow">
                <b-form-textarea :name="'img_' + item.id" :id="'img_' + item.id" rows="3"
                                 :disabled="disabled || loading"
                                 :value.sync="item.description" @input="changeTextField"></b-form-textarea>
            </b-card-body>
            <b-card-body class="p-1" v-if="!textAreaShow && item.tag && item.tag.id">
                <b-badge variant="primary">
                    {{item.tag.name}}
                            <i v-if="!deleteDisabled" @click="triggerDeleteTag" :id="'delete_tag'+item.tag.id"
                               class="fa fa-shading fa-times fa-fw p-0 m-0 tag-delete"
                               aria-hidden="true"> </i>
                </b-badge>
            </b-card-body>

            <p class="unit-name-style" v-if="unitName">{{ unitName }}</p>
        </b-card>
        <app-confirmation-dialog
            :delete_title="true"
            :show="deleteDialogState"
            @confirm="removeItem"
            @cancel="deleteDialogState=false">
        </app-confirmation-dialog>
        <app-confirmation-dialog
            :show="deleteCustomTagConfirmDialog"
            :title="$t('REMOVE_TAG_CONFIRMATION')"
            :message="$t('REMOVE_TAG_MSG')"
            @confirm="deleteTag"
            @cancel="deleteCustomTagConfirmDialog=false">
        </app-confirmation-dialog>
    </b-col>
</template>

<script>
import {API_CONFIG} from '@/services/config'
import {OBJECT_TYPE_DOCUMENT, TYPE_PROPERTY_PHOTO, TYPE_UNIT_PHOTO, TAG_TYPE_IMAGE} from "@/shared/constants";
import {removeTagsForEntityList} from "@/services/tag"
import {notifySuccess} from "@/shared/plugins/toastr";
import {EventBus, GE_LOADER_HIDE, GE_LOADER_SHOW} from "@/shared/EventBus";

export default {
    name: "GalleryItem",
    components: {
        "AppConfirmationDialog": () => import("@/components/app/form/AppConfirmationDialog"),
        "TagForm": () => import("@/components/app/tag/TagForm")
    },
    props: {
        item: {},
        disabled: {
            type: Boolean,
            default: false
        },
        deleteDisabled: {
            type: Boolean,
            default: false
        },
        acMessage: {
            type: "String",
        },
        editDisabled: {
            type: Boolean,
            default: false
        },
        DocumentTypeId: {
            type: Number,
        },
        checkboxSelectedList: {
            type: Array,
        },
        textAreaShow: {
            type: Boolean,
            default: true
        },
        isPropertyGallery: {
            type: Boolean,
            default: false
        },
        unitName: {
            type: String
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loadingState: false,
            deleteDialogState: false,
            deleteCustomTagConfirmDialog: false
        }
    },
    computed: {
        selected() {
            return this.checkboxSelectedList.includes(this.item.id)
        },
        imageThumbRoute() {
            if (this.DocumentTypeId === TYPE_UNIT_PHOTO || this.DocumentTypeId === TYPE_PROPERTY_PHOTO) {
                return this.item.hasOwnProperty('thumb') && this.item.thumb ? this.item.thumb : this.item.thumbnail
            }
            return this.item.route
        }
    },
    methods: {
        removeItem() {
            this.$emit('removeDocument')
            this.deleteDialogState = false
        },
        clickedImg() {
            this.$emit('imageClicked')
        },
        changeTextField(val) {
            this.$emit('textfieldEdit', val)
        },
        emmitChecked(event) {
            this.$emit('copyCheck', event)
        },
        triggerDeleteDialog() {
            if (!this.deleteDisabled) {
                this.deleteDialogState=true
            }
        },
        triggerDeleteTag() {
            this.deleteCustomTagConfirmDialog=true
        },
        deleteTag() {
            removeTagsForEntityList(OBJECT_TYPE_DOCUMENT, TAG_TYPE_IMAGE, [this.item.id]).then(() => {
                notifySuccess()
                EventBus.$emit(GE_LOADER_SHOW)
                this.$emit('removeTag')

            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.deleteCustomTagConfirmDialog = false
                EventBus.$emit(GE_LOADER_HIDE)
            })
        },
    }
}
</script>

<style scoped lang="scss">
.image-item {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    width: 100%;
    overflow: hidden;
    min-height: 10rem;
    cursor: pointer;
}

.image-zoom {
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    cursor: pointer;
}

.imgTag {
    position: absolute;
    left: 5px;
    bottom: 5px;
}

.image-delete {
    position: absolute;
    color: white;
    cursor: pointer;
    right: 0;
    top: 0;
}

.tag-delete {
    color: white;
    cursor: pointer;
}

.document-checkbox {
    position: absolute;
    right: 0;
    padding: 0;
    bottom: 5px;
    display: inline;
}

.document-item i {
    color: black;
}

.document-item {
    position: relative;
    min-height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
}


.fa-shading {
    text-shadow: 0 0 8px black;
}

.disable-delete {
    color: #cccccc;
    text-shadow: 0 0 1px black
}

.unit-name-style {
    color: var(--color-text-primary);
    opacity: 0.7;
    left: 0 !important;
    font-size: 13px;
    margin-top: 0.5rem !important;
    line-height: 16px;
}

.image-loading {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
