<template>
    <div>
        <router-view v-if="!loading && currentPageData" :pageData="currentPageData"></router-view>
    </div>
</template>

<script>
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import routeNames from "@/router/routeNames";
import {EventBus, GE_LOADER_HIDE, GE_LOADER_SHOW} from "@/shared/EventBus";

export default {
    name: "Index",
    mixins: [getErrorMessage],
    components: {
        "AppTransition": () => import("@/components/app/transition/AppTransition"),
        "AppAside": () => import("@/components/app/form/AppAside")
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        currentPageData() {
            return this.$store.getters['website/getCurrentPage']
        }
    },
    watch: {
        '$route.params.page_id': {
            immediate: true,
            handler(pageId) {
                this.loading = true
                EventBus.$emit(GE_LOADER_SHOW)
                this.$store.dispatch('website/actionSetCurrentPage', pageId).then(() => {
                }, error => {
                    this.showErrorMessages(error)
                    this.$router.replace({name: routeNames.RN_DIRECT_BOOKING_WEBSITE_PAGES})
                }).finally(() => {
                    EventBus.$emit(GE_LOADER_HIDE)
                    this.loading = false
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
