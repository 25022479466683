<template>
    <div v-if="list.length > 0 && hasPermissionView">
        <b-table responsive :items="list" hover :fields="fields">

            <template v-slot:edit="data">
                <edit-contact :c_permission="c_permission_e" variant="link" :show_text="false" button_type="edit"
                              @click="editContact(data.item)"></edit-contact>
            </template>
        </b-table>
        <hr>

        <app-aside v-model="contactPanelState">
            <template slot="header">{{$t('CONTACT')}}</template>
            <ContactForm @closeConcatAside="contactPanelState=false" :contact="contactObjectEdit"></ContactForm>
        </app-aside>


        <!-- New Form -->
        <contacts-list :object_type="OBJECT_TYPE_OWNER" :object_id="parseInt($route.params.owner_id)"></contacts-list>
    </div>
</template>

<script>
    import AppAside from '@/components/app/form/AppAside'
    import ContactForm from '@/components/owner/contact/OwnerContactForm'
    import editContact from '@/components/app/AppButton/AppButton'
    import {C_OWNER_CONTACT_V, C_OWNER_CONTACT_E} from "@/shared/component_permission";
    import {OBJECT_TYPE_OWNER} from '@/shared/constants'
    import OwnerNewContactList from '@/components/contacts/ContactForm'
    import EditNewContact from '@/components/app/AppButton/AppButton'
    import NewContactForm from '@/components/contacts/ContactForm'
    import NewContactButton from '@/components/app/AppButton/AppButton'
    import ContactsList from "@/components/contacts/ContactsList";


    export default {
        name: "Index",
        components: {
            ContactsList,
            AppAside, ContactForm, editContact, OwnerNewContactList, EditNewContact,
            NewContactForm, NewContactButton
        },
        props: {
            property: {
                type: Object
            }
        },
        data() {
            return {
                c_permission_e: C_OWNER_CONTACT_E,
                contactPanelState: false,
                contactObjectEdit: null,
                stateLoad: false,
                fields: [
                    {key: 'contact_type.label', label: this.$t('TYPE')},
                    {key: 'name', label: this.$t('NAME')},
                    {key: 'phone', label: this.$t('PHONE')},
                    {key: 'phone2', label: this.$t('PHONE') + " 2"},
                    {key: 'email', label: 'Email'},
                    {key: 'edit', label: '', tdClass: 'text-right'},
                ],
                OBJECT_TYPE_OWNER,
                newContactList: []

            }
        },
        methods: {
            editContact(contactObject) {
                this.contactObjectEdit = contactObject
                this.contactPanelState = true
            }
        },
        computed: {
            list() {
                return this.$store.getters['owner/getOwnerContactList']
            },
            hasPermissionView() {
                return this.$store.getters['user/getPermission'](C_OWNER_CONTACT_V)
            }
        },
        watch: {
            '$route.params.owner_id': {
                handler(value) {
                    this.$store.dispatch('owner/actionSetOwnerContactList', {
                        propertyId: this.$route.params.id,
                        ownerId: value
                    })
                },
                immediate: true
            }
        }
    }
</script>


<style scoped>
    th.description-width {
        width: 25% !important;
    }
</style>
