export const TableRule = {
    methods:{
        getFields(list, {fields, custom_fields = [] , exception_fields = []}) {
            let list_field = []
            if (list.length > 0) {
                fields.forEach(el => {

                    let obj=exception_fields.find(element => element.key === el.key)

                    if(typeof obj === "undefined") {
                        if (_.hasIn(list[0], el.key)) {
                            list_field.push(el)
                        }
                    } else {
                        if (_.hasIn(list[0], el.key)  || obj.key===el.key) {
                            list_field.push(el)
                        }
                    }

                    // if (_.hasIn(list[0], el.key  || (obj !== undefined && obj.key===el.key))) {
                    //     list_field.push(el)
                    // }
                })
            }

            return [...list_field, ...custom_fields.filter(item => item.hasOwnProperty('permission') ? this.checkPermission(item.permission) : true)]
        }
    }
}
