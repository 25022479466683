<template>

    <form @submit.prevent="search" @reset.prevent="resetForm" class="filter" autocomplete="off">
        <b-row>
            <b-col lg="2" md="6" v-if="!hasProperty">
                <label>{{$t('DISTRIBUTION')}}</label>
                <app-select  v-model="filter.distribution"
                             :options="distributionOptions">
                </app-select>
            </b-col>
            <b-col lg="2" md="6">
                <b-form-group>
                    <label>{{$t('STATUS')}}</label>
                    <app-select text-field="label"
                                mode="multiselect"
                                v-model="filter.status"
                                :options="statusList">
                    </app-select>
                </b-form-group>
            </b-col>
            <b-col lg="2" md="6">
                <b-form-group>
                    <label>{{$t('ACTION')}}</label>
                    <app-select text-field="label"
                                mode="multiselect"
                                v-model="filter.connection_action"
                                :options="actionList">
                    </app-select>
                </b-form-group>
            </b-col>
            <b-col lg="2" md="6">
                <label>{{$t('SYNCHRONIZATION_TIME')}} <span class="text-lowercase">{{$t("FROM")}}</span></label>
                <app-date-picker :value="formatUnixtime(filter.ts_insert.from)" @input="updateInsertFrom($event)"></app-date-picker>
            </b-col>
            <b-col lg="2" md="6">
                <label>{{$t('SYNCHRONIZATION_TIME')}} <span class="text-lowercase">{{$t("TO")}}</span></label>
                <app-date-picker :value="formatUnixtime(filter.ts_insert.to)"  @input="updateInsertTo($event)"></app-date-picker>
            </b-col>
            <b-col lg="2" md="6" v-if="!hasProperty">
                <b-form-group>
                    <label>{{$t('PROPERTY')}}</label>
                    <app-select mode="search" :search-on-create="preloadPropertyList" :search="searchProperty"
                                v-model="property"></app-select>
                </b-form-group>
            </b-col>
            <b-col lg="2" md="6">
                <label>{{$t('UNIT')}}</label>
                <app-select text-field="label"
                            v-model="filter.units"
                            mode="multiselect"
                            :options="unitList"
                            :disabled="!filter.property"
                            @input="populateRatePlanFilter">
                </app-select>
            </b-col>
            <b-col lg="2" md="6">
                <label>{{$t('RATE_PLAN')}}</label>
                <app-select text-field="label"
                            v-model="filter.rate_plans"
                            mode="multiselect"
                            :options="selectedUnits"
                            group="rate_plans"
                            :disabled="!filter.units.length">
                </app-select>
            </b-col>
            <b-col lg="2" md="6">
                <b-form-group>
                    <label>{{$t('SYNCHRONIZATION_PERIOD')}}</label>
                    <app-date-range-picker v-model="filter.period"></app-date-range-picker>
                </b-form-group>
            </b-col>

            <b-col>
                <label>&zwnj;</label>
                <b-form-group>
                    <app-button-submit :disabled="disabledSearch" :inline="true" :loading="loaderSubmit" variant="primary" button_type="search">{{
                        $t("SEARCH") }}
                    </app-button-submit>
                    <app-button-reset :disabled="disabledSearch" :loading="loaderReset" :inline="true"  class="pl-2 ml-2"  type="reset"></app-button-reset>
                </b-form-group>
            </b-col>
        </b-row>
    </form>

</template>

<script>
    import {getQueueFilterData} from "@/services/channel_manager";
    import AppDropdownCheckbox from '@/components/app/form/AppDropdownCheckbox'
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
    import AppDatePicker from "@/components/app/datetime/AppDatePicker";
    import {DEFAULT_PAGINATION_LIMIT, LS_PRESETS} from "@/shared/constants";
    import {getPropertyList} from "@/services/property";
    import {getUnitList} from "@/services/unit";
    import {getDistributionList} from "@/services/distribution";
    import moment from 'moment'
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
    export default {
        name: "CmQueueFilter",
        components: {
            AppButtonReset,
            AppDatePicker,
            AppDateRangePicker,
            AppSelect,
            AppDropdownCheckbox,
            AppButtonSubmit
        },
        data() {
            return {
                loadFilterData:false,
                preloadPropertyList:false,
                propertyList:[],
                property:{},
                statusList: [],
                actionList: [],
                unitList: [],
                selectedUnits: [],
                distributionOptions: [],
                change_get_insert_to:false,
                change_get_insert_from:false,
                filter: {
                    property: null,
                    distribution: null,
                    perPage: 10,
                    company:this.$store.getters['user/getCompany'],
                    connection_action: [],
                    status: [],
                    units: [],
                    rate_plans: [],
                    ts_insert: {
                        from: null,
                        to: null
                    },
                    period: {
                        start: null,
                        end: null
                    }
                },
                filterReset:false,
                loaderReset:false,
                loaderSubmit:false,
                use_search:false
            }
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            hasProperty:{
                type:Boolean,
                default:false
            }
        },

        watch: {

            property: {
                handler(object) {
                    if (object.hasOwnProperty('id')) {
                        this.filter.property = object.id
                        if(!this.hasProperty){
                             this.populateFilter(object.id,this.filter.distribution)
                        }
                    } else {
                        this.filter.property = null
                        this.unitList = []
                    }

                }
            },

            '$route.params.distribution_id':{
                handler(value){
                    this.filter.distribution = value
                    if(this.use_search){
                        this.search()
                    } else {
                        this.resetFilterData()
                        this.$emit('clean')
                    }
                }, immediate:true
            },
            'filter.distribution':{
                handler(value){
                    let property_id = this.filter.property ? this.filter.property : (this.$route.params.id ?  this.$route.params.id : '')
                        this.populateFilter(property_id,value)
                }, immediate:true
            },
            '$route.params.id':{
                handler(value){
                    this.filter.property = value
                }, immediate:true
            },
            loading:{
                handler(value){
                    if(this.filterReset){
                        this.loaderReset = value
                    } else {
                        this.loaderSubmit = value
                    }

                }
            },
        },
        computed:{
            disabledSearch(){

                return this.loading || !this.filter.distribution
            }
        },
        methods: {
            updateInsertFrom(value){
                if(this.$route.query.hasOwnProperty('date') && this.$route.query.date === value && !this.change_get_insert_from){
                    return
                }
                this.change_get_insert_from = true
                this.filter.ts_insert.from =  moment(value, 'YYYY-MM-DD H:i').unix();
            },
            updateInsertTo(value){
                if(this.$route.query.hasOwnProperty('date') && this.$route.query.date === value && !this.change_get_insert_to){
                    return
                }
                this.change_get_insert_to = true
                this.filter.ts_insert.to =  moment(value, 'YYYY-MM-DD H:i').unix();
            },
            formatUnixtime(value){
                if(!value){
                    return null
                }
                return moment.unix(value).format('YYYY-MM-DD')
            },
            setGetParameters() {
                let queryValue = this.$route.query
                if (queryValue.hasOwnProperty('distribution')) {
                    this.filter.distribution = queryValue.distribution
                }
                if (queryValue.hasOwnProperty('status')) {
                    this.filter.status = []
                    if (this.statusList.length > 0) {
                        if(Array.isArray(queryValue.status)){
                            if(queryValue.status.length){
                                queryValue.status.forEach(el => {
                                    this.filter.status.push(Number(el))
                                })
                            }
                        } else {
                            this.filter.status.push(Number(queryValue.status))
                        }
                    }
                }
                if(queryValue.hasOwnProperty('action')){
                    this.filter.connection_action = []
                    if (this.actionList.length > 0) {
                        if(Array.isArray(queryValue.action) && queryValue.action.length){
                             queryValue.action.forEach(el=>{
                                 this.filter.connection_action.push(Number(el))
                             })
                        } else {
                            this.filter.connection_action.push(Number(queryValue.action))
                        }
                    }
                }
                if(queryValue.hasOwnProperty('time_from') && queryValue.hasOwnProperty('date') && queryValue.hasOwnProperty('time_to')){
                    let date_from = queryValue.date + ' '+queryValue.time_from
                    let unix_date_from =  moment(date_from, 'YYYY-MM-DD H:i').unix();
                    let date_to = queryValue.date + ' '+queryValue.time_to
                    if(queryValue.time_to === '00:00' || queryValue.time_to === '01:00' || queryValue.time_to === '02:00'){
                        let date_next =  moment(queryValue.date).add('1',"days").format('YYYY-MM-DD')
                        date_to = date_next + ' '+queryValue.time_to
                    }
                    let unix_date_to =  moment(date_to, 'YYYY-MM-DD H:i').unix();
                    this.filter.ts_insert.from = unix_date_from
                    this.filter.ts_insert.to = unix_date_to
                }
                if(this.filter.distribution && this.filter.ts_insert.from){
               this.search()
                } else if( queryValue.hasOwnProperty('filter') && queryValue.filter) {
                    this.search()
                }
            },

            search() {
                this.use_search = true
                this.filterReset = 0
                this.$emit('search', {...this.filter, page: 1})
            },
            resetFilterData(){
                this.filter.connection_action = []
                this.filter.status =[]
                this.filter.units =[]
                this.filter.rate_plans = []
                this.filter.ts_insert.from = null
                this.filter.ts_insert.to = null
                this.filter.period.start = null
                this.filter.period.end = null
                if(!this.hasProperty){
                    this.filter.property = null
                    this.property = {}
                }
            },
            resetForm() {
                this.resetFilterData()
                this.filterReset = 1
                this.$emit('search', {...this.filter, page: 1})
            },
            populateFilter(property_id, distribution_id) {
                this.unitList = []
                getQueueFilterData({
                    property: property_id,
                    distribution: distribution_id
                }).then(response => {
                    this.statusList = response.data['status_list']
                    this.actionList = response.data['action_list']
                    this.unitList = response.data['unit_list']
                    this.setGetParameters()
                })
            },
            populateRatePlanFilter(unit_id_list) {
                this.selectedUnits = this.unitList.filter(unit => unit_id_list.includes(unit.id) && unit.rate_plans.length > 0);
                let rps = this.selectedUnits.map(unit => unit.rate_plans.map(rp => rp.id)).flat();
                this.filter.rate_plans = this.filter.rate_plans.filter(rp => rps.includes(rp));
            },
            searchProperty(value) {
                return getPropertyList({name: value, company_id: this.$store.getters['user/getCompany']}).then(response => {
                    return {data: response.data.items}
                })
            },
            searchUnitList(value) {
                getUnitList({property_id: value}).then(response => {
                    this.unitList = response.data.items
                })
            },
        },
        created() {
            getDistributionList({company: this.$store.getters['user/getCompany'],  sorted:1}).then(res => {
                this.distributionOptions = res.data
            })
            let storagePresets = localStorage.getItem(LS_PRESETS)
            if (storagePresets) {
                storagePresets = JSON.parse(storagePresets)
                if (storagePresets.hasOwnProperty('properties') && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                    this.preloadPropertyList = true
                }
            }
        }
    }
</script>

<style scoped>

</style>
