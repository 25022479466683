<template>
    <div>
        <b-form @submit.prevent="save" autocomplete="off" v-if="formLoaded && setup !== null">
            <template v-if="paymentGateway.id === PCIBOOKINGUPG">
                <b-row>
                    <b-col class="mb-4">
                        <app-input-control :error-object="validationErrorObject.api_key"
                                           :key="'payment_gateway_pci_api_key_' + '_' + company + '_' + paymentGateway.id">
                            <template v-slot:input="data">
                                <label>{{ $t('API_KEY') }}</label>
                                <b-form-input type="text"
                                              v-model="setup.login_data.api_key"
                                              :state="data.state">
                                </b-form-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <app-input-control :error-object="validationErrorObject.payment_gateway"
                                           :key="'payment_gateway_name_' + company + '_' + paymentGateway.id">
                            <template v-slot:input="data">
                                <label>{{ $t('PAYMENT_GATEWAY') }}</label>
                                <app-select :options="pciBookingGatewayList"
                                            :search-empty-item="false"
                                            value-field="Name"
                                            text-field="DisplayName"
                                            v-model="setup.login_data.payment_gateway"
                                            :state="data.state">
                                </app-select>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
                <!-- credentials -->
                <b-row v-if="setup.login_data.payment_gateway !== null && gatewayCredentials.length > 0"
                       v-for="credentialName in gatewayCredentials">
                    <b-col class="mb-4">
                        <label>{{ credentialName }}</label>
                        <b-form-input type="text"
                                      v-model="setup.login_data.credentials[credentialName]">
                        </b-form-input>
                    </b-col>
                </b-row>
            </template>
            <template v-else-if="paymentGateway.id === STRIPE">
                <b-row>
                    <b-col class="mb-4">
                        <app-input-control :error-object="validationErrorObject.connection_token"
                                           :key="'connection_token_' + company + '_' + paymentGateway.id">
                            <template v-slot:input="data">
                                <label>{{ $t('CONNECTION_TOKEN') }}</label>
                                <b-form-input type="text"
                                              v-model="setup.login_data.connection_token"
                                              :state="data.state">
                                </b-form-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
            </template>
            <template v-else>
                <b-row>
                    <b-col class="mb-4">
                        <app-input-control :error-object="validationErrorObject.connection_key"
                                           :key="'connection_key_' + company + '_' + paymentGateway.id">
                            <template v-slot:input="data">
                                <label>{{ $t('CONNECTION_KEY') }}</label>
                                <b-form-input type="text"
                                              v-model="setup.login_data.connection_key"
                                              :state="data.state">
                                </b-form-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <app-input-control :error-object="validationErrorObject.connection_token"
                                           :key="'connection_token_' + company + '_' + paymentGateway.id">
                            <template v-slot:input="data">
                                <label>{{ $t('CONNECTION_TOKEN') }}</label>
                                <b-form-input type="text"
                                              v-model="setup.login_data.connection_token"
                                              :state="data.state">
                                </b-form-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
            </template>
            <!-- additional setup: automatic charge parameters-->
            <b-row v-if="checkPermission(INTEGRATIONS_PG_SETUP_AUTOMATIC_CHARGE_SETUP_AUTOMATIC_CHARGE)">
                <b-col>
                    <b-form-checkbox :value="1"
                                     :unchecked-value="0"
                                     v-model="setup.automatic_charge">
                        {{ $t('AUTOMATIC_CHARGE_ON_RESERVATION') }}
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row v-if="checkPermission(INTEGRATIONS_PG_SETUP_AUTOMATIC_CHARGE_SETUP_AUTOMATIC_CHARGE_CRON)">
                <b-col class="mb-4">
                    <b-form-checkbox :value="1"
                                     :unchecked-value="0"
                                     v-model="setup.automatic_charge_cron">
                        {{ $t('PERIODIC_AUTOMATIC_CHARGE') }}
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <app-button-submit :loading="saving"></app-button-submit>
                </b-col>
            </b-row>
            <app-confirmation-dialog :show="showActivateConfirmationDialog"
                                     :title="$t('ACTIVATION_CONFIRMATION')"
                                     @confirm="activateSetup"
                                     @cancel="showActivateConfirmationDialog = false">
                {{
                    $t('PAYMENT_GATEWAY_ACTIVATE_SETUP_DEACTIVATION_CONFIRMATION_MSG', {
                        payment_provider: paymentGateway.name,
                        payment_provider_currently_active: currentlyActivePaymentGateway
                    })
                }}
            </app-confirmation-dialog>
        </b-form>
    </div>
</template>

<script>
import {PCIBOOKINGUPG, STRIPE} from '@/shared/constants'
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppInputControl from "@/components/app/AppInputControl";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {
    activatePaymentGatewaySetup,
    fetchPaymentGatewaySetup, getPaymentGatewayList,
    pciBookingGetPaymentGatewayList, updatePaymentGatewaySetup
} from "@/services/payment_gateway";
import {
    INTEGRATIONS_PG_SETUP_AUTOMATIC_CHARGE_SETUP_AUTOMATIC_CHARGE,
    INTEGRATIONS_PG_SETUP_AUTOMATIC_CHARGE_SETUP_AUTOMATIC_CHARGE_CRON,
} from "@/shared/component_permission";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {notifySuccess} from "@/shared/plugins/toastr";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {
    PG_SETUP_LOGIN_DATA_API_KEY_NOT_SET,
    PG_SETUP_LOGIN_DATA_CONNECTION_KEY_NOT_SET,
    PG_SETUP_LOGIN_DATA_CONNECTION_TOKEN_NOT_SET,
    PG_SETUP_LOGIN_DATA_PAYMENT_GATEWAY_NOT_SET
} from "@/shared/error_codes";

export default {
    name: "PaymentGatewayEditForm",
    components: {AppConfirmationDialog, AppSelect, AppInputControl, AppButtonSubmit},
    mixins: [getErrorMessage],
    props: {
        paymentGateway: {
            type: Object,
            default() {
                return {}
            }
        },
        company: {
            type: Number
        },
    },
    data() {
        return {
            setup: {
                id: null,
                payment_gateway: null,
                login_data: {
                    connection_key: null,
                    connection_token: null,
                    api_key: null,
                    payment_gateway: null,
                    credentials: {},
                    certificate: null,
                },
                company: null,
                transaction_type: null,
                automatic_charge: 0,
                automatic_charge_cron: 0,
            },
            initialGatewayName: null,
            PCIBOOKINGUPG,
            STRIPE,
            pciBookingGatewayList: [],
            gatewayCredentials: [],
            gatewayListLoaded: false,
            setupLoaded: false,
            saving: false,
            showActivateConfirmationDialog: false,
            currentlyActivePaymentGateway: '',
            validationErrorObject: {
                api_key: {
                    input: [PG_SETUP_LOGIN_DATA_API_KEY_NOT_SET],
                },
                payment_gateway: {
                    input: [PG_SETUP_LOGIN_DATA_PAYMENT_GATEWAY_NOT_SET],
                },
                connection_key: {
                    input: [PG_SETUP_LOGIN_DATA_CONNECTION_KEY_NOT_SET],
                },
                connection_token: {
                    input: [PG_SETUP_LOGIN_DATA_CONNECTION_TOKEN_NOT_SET],
                },
            },
            INTEGRATIONS_PG_SETUP_AUTOMATIC_CHARGE_SETUP_AUTOMATIC_CHARGE,
            INTEGRATIONS_PG_SETUP_AUTOMATIC_CHARGE_SETUP_AUTOMATIC_CHARGE_CRON,
        }
    },
    computed: {
        formLoaded() {
            return this.setupLoaded && this.gatewayListLoaded
        }
    },
    watch: {
        'setup.login_data.payment_gateway': {
            handler(value) {
                if (value) {
                    if (this.paymentGateway.setup_id !== null && this.initialGatewayName !== value) {
                        this.setup.login_data.credentials = {}
                    }
                    this.initialGatewayName = value
                    this.getCredentials(value)
                }
            },
        },
        immediate: true,
    },
    methods: {
        save() {
            this.saving = true
            let requestObject = this.getRequestObject()
            if (this.paymentGateway.setup_id === null) {
                let payment_gateway_list_error = false
                let display_confirmation = false
                getPaymentGatewayList({company: this.company}).then(response => {
                    let paymentGatewayList = response.data
                    paymentGatewayList.forEach(paymentGateway => {
                        if (paymentGateway.setup_id !== null) {
                            this.currentlyActivePaymentGateway = paymentGateway.name
                            display_confirmation = true
                        }
                    })
                }, () => {
                    payment_gateway_list_error = true
                }).finally(() => {
                    if (!payment_gateway_list_error) {
                        if (display_confirmation) {
                            this.showActivateConfirmationDialog = true
                            this.saving = false
                        } else {
                            activatePaymentGatewaySetup(requestObject).then(response => {
                                notifySuccess()
                                this.setup = response.data
                                this.$emit('setupSaved')
                            }, error => {
                                this.showErrorMessages(error, this.validationErrorObject)
                            }).finally(() => {
                                this.saving = false
                            })
                        }
                    }
                })
            } else {
                updatePaymentGatewaySetup(this.paymentGateway.setup_id, requestObject).then(response => {
                    notifySuccess()
                    this.setup = response.data
                    this.$emit('setupSaved')
                }, error => {
                    this.showErrorMessages(error, this.validationErrorObject)
                }).finally(() => {
                    this.saving = false
                })
            }
        },
        activateSetup() {
            let requestObject = this.getRequestObject()
            activatePaymentGatewaySetup(requestObject).then(response => {
                notifySuccess()
                this.setup = response.data
                this.$emit('setupSaved')
            }, error => {
                this.showErrorMessages(error, this.validationErrorObject)
            }).finally(() => {
                this.showActivateConfirmationDialog = false
            })
        },
        fetchSetup() {
            this.setupLoaded = false
            fetchPaymentGatewaySetup(this.paymentGateway.setup_id).then(response => {
                this.setup = response.data
                if (this.setup.login_data && this.setup.login_data.payment_gateway) {
                    this.initialGatewayName = this.setup.login_data.payment_gateway
                }
            }, () => {
                this.setup = null
            }).finally(() => {
                this.setupLoaded = true
            })
        },
        getCredentials(gateway_name) {
            let index = this.pciBookingGatewayList.findIndex((el) => el.Name === gateway_name)
            if (index >= 0) {
                this.gatewayCredentials = this.pciBookingGatewayList[index].CredentialsNames
            }
        },
        getRequestObject() {
            let requestObject = {
                payment_gateway: this.setup.payment_gateway && this.setup.payment_gateway.hasOwnProperty('id') ? this.setup.payment_gateway.id : this.paymentGateway.id,
                company: this.setup.company && this.setup.company.hasOwnProperty('id') ? this.setup.company.id : this.company,
                login_data: this.setup.login_data,
                automatic_charge: this.setup.automatic_charge,
                automatic_charge_cron: this.setup.automatic_charge_cron,
            }
            if (this.setup.transaction_type && this.setup.transaction_type.hasOwnProperty('id')) {
                requestObject.transaction_type = this.setup.transaction_type.id
            }

            return requestObject
        },
    },
    created() {
        if (this.paymentGateway.id === PCIBOOKINGUPG) {
            this.gatewayListLoaded = false
            pciBookingGetPaymentGatewayList().then(response => {
                this.pciBookingGatewayList = response.data
            }, () => {
                this.pciBookingGatewayList = []
            }).finally(() => {
                this.gatewayListLoaded = true
                if (this.paymentGateway.setup_id !== null) {
                    this.fetchSetup()
                } else {
                    this.setupLoaded = true
                }
            })
        } else {
            this.gatewayListLoaded = true
            if (this.paymentGateway.setup_id !== null) {
                this.fetchSetup()
            } else {
                this.setupLoaded = true
            }
        }
    },
}
</script>

<style scoped>

</style>
