<template>
    <div v-if="parameter && template">
        <template v-if="parameter.condition.event.value !== 'reservation_paid_out_by_distribution'">
            <reservation-process-data-transition-payment :parameter="parameter" :template="template" @delete="$emit('delete')">
            </reservation-process-data-transition-payment>
        </template>
        <template v-if="parameter.condition.event.value === 'reservation_paid_out_by_distribution'">
            <reservation-process-data-transition-distribution-payment :parameter="parameter" :template="template" @delete="$emit('delete')">
            </reservation-process-data-transition-distribution-payment>
        </template>
    </div>
</template>

<script>



import {cloneDeep} from "lodash";

export default {
    name: "ReservationProcessDataTransitionForm",
    components: {
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
        "ReservationProcessDataTransitionPayment": () => import("@/components/reservation/setup/ReservationProcessDataTransitionPayment"),
        "ReservationProcessDataTransitionDistributionPayment": () => import("@/components/reservation/setup/ReservationProcessDataTransitionDistributionPayment")
    },
    props: {
        parameter: {
            type: Object,
            default: null
        },
        template: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            saving: false,
            event: {value: null, label: null}
        }
    },
    methods: {},
    watch: {
        "parameter.condition.event.value":
            {
                handler(value) {
                    this.$emit('resetData')
                }
            }
    }
}
</script>

<style scoped>

</style>
