<template>
    <b-tabs v-model="tab" nav-class="tab_navigation-horizontal tab-nav tab-bulk-calendar mt-5"
            content-class="tab_content-horizontal"
            pills justified>
        <b-tab :title="$t('RATES')">
            <div :key="componentRatesKey">
                <app-access-control @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{function: F_REVENUE_MANAGER_PROPERTY_RATES_V, property: propertyId, key: AC_REVENUE_MANAGER_PROPERTY}">
                    <b-row>
                        <b-col>
                            <b-tooltip v-if="read_only" triggers="hover"
                                       :target="propertyId+'_automatic_rates_setup_deactivate'"
                                       :title="read_only_message"
                            ></b-tooltip>
                            <app-button :id="propertyId+'_automatic_rates_setup_deactivate'"
                                        :disabled="read_only"
                                        @click="deactivateConfirmation(ACTION_TYPE_MIN_PRICE)"
                                        class="pull-right"
                            >{{ $t('DEACTIVATE') }}
                            </app-button>
                        </b-col>
                    </b-row>
                    <revenue-manager-setup-action-table mode="property"
                                                        :object_id="propertyId"
                                                        :type_action="ACTION_TYPE_MIN_PRICE"
                                                        :read_only="read_only"
                                                        :read_only_message="read_only_message"
                    ></revenue-manager-setup-action-table>
                </app-access-control>

                <app-access-control @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{function: F_REVENUE_MANAGER_PROPERTY_ACTIVATION_RATES, property: propertyId, key: AC_REVENUE_MANAGER_PROPERTY}">
                    <app-no-data :heading="$t('MODULE_NOT_ACTIVE')">
                        <b-tooltip v-if="read_only" triggers="hover"
                                   :target="propertyId+'_automatic_rates_setup_activate'"
                                   :title="read_only_message"
                        ></b-tooltip>
                        <app-button :id="propertyId+'_automatic_rates_setup_activate'"
                                    :disabled="read_only"
                                    :loading="loaderRates"
                                    @click="activationRateAside = true"
                                    class="mt-5"
                        >{{ $t('ACTIVATE') }}
                        </app-button>
                    </app-no-data>
                </app-access-control>

                <app-access-control tag="span" @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{function: F_REVENUE_MANAGER_COMPANY_ACTIVATION_RATES, property: propertyId, key: AC_REVENUE_MANAGER_PROPERTY}">
                    <template v-slot:default="props">
                        <app-no-data :heading="$t('MODULE_NOT_ACTIVE')" :tip="$t('MODULE_NEED_ACTIVATE_RM')"
                                     :show-tip="true">
                            <app-button class="mt-5"
                                        :to="{name: routeNames.RN_REVENUE_MANAGER_SETUP_ACTION_PRICE}"
                            >{{ $t('ACTIVATE') }}
                            </app-button>
                        </app-no-data>
                    </template>
                </app-access-control>
            </div>
        </b-tab>

        <b-tab :title="$t('MIN_STAY')">
            <div :key="componentMinStayKey">
                <app-access-control @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{function: F_REVENUE_MANAGER_PROPERTY_MIN_STAY_V, property: propertyId, key: AC_REVENUE_MANAGER_PROPERTY}">
                    <b-row>
                        <b-col>
                            <b-tooltip v-if="read_only" triggers="hover"
                                       :target="propertyId+'_automatic_min_stay_setup_deactivate'"
                                       :title="read_only_message"
                            ></b-tooltip>
                            <app-button :id="propertyId+'_automatic_min_stay_setup_deactivate'"
                                        :disabled="read_only"
                                        @click="deactivateConfirmation(ACTION_TYPE_MIN_STAY)"
                                        class="pull-right"
                            >{{ $t('DEACTIVATE') }}
                            </app-button>
                        </b-col>
                    </b-row>
                    <revenue-manager-setup-action-table mode="property"
                                                        :object_id="propertyId"
                                                        :type_action="ACTION_TYPE_MIN_STAY"
                                                        :read_only="read_only"
                                                        :read_only_message="read_only_message"
                    ></revenue-manager-setup-action-table>
                </app-access-control>

                <app-access-control @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{function: F_REVENUE_MANAGER_PROPERTY_ACTIVATION_MIN_STAY, property: propertyId, key: AC_REVENUE_MANAGER_PROPERTY}">
                    <app-no-data :heading="$t('MODULE_NOT_ACTIVE')">
                        <b-tooltip v-if="read_only" triggers="hover"
                                   :target="propertyId+'_automatic_min_stay_setup_activate'"
                                   :title="read_only_message"
                        ></b-tooltip>
                        <app-button :id="propertyId+'_automatic_min_stay_setup_activate'"
                                    :disabled="read_only"
                                    :loading="loaderMinStay"
                                    @click="activationMinStayAside = true"
                                    class="mt-5"
                        >{{ $t('ACTIVATE') }}
                        </app-button>
                    </app-no-data>
                </app-access-control>

                <app-access-control tag="span" @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{function: F_REVENUE_MANAGER_COMPANY_ACTIVATION_MIN_STAY, property: propertyId, key: AC_REVENUE_MANAGER_PROPERTY}">
                    <template v-slot:default="props">
                        <app-no-data :heading="$t('MODULE_NOT_ACTIVE')" :tip="$t('MODULE_NEED_ACTIVATE_RM')"
                                     :show-tip="true">
                            <app-button class="mt-5"
                                        :to="{name: routeNames.RN_REVENUE_MANAGER_SETUP_ACTION_MIN_STAY}"
                            >{{ $t('ACTIVATE') }}
                            </app-button>
                        </app-no-data>
                    </template>
                </app-access-control>
            </div>
        </b-tab>

        <app-confirmation-dialog :show="confirmationDeactivate"
                                 @confirm="deactivate"
                                 :title="deactivateConfirmationTitle"
                                 @cancel="confirmationDeactivate = false"
        >{{ $t('DEACTIVATE_RM_PROPERTY_MESSAGE') }}
        </app-confirmation-dialog>

        <app-aside v-model="activationRateAside">
            <template v-slot:header>
                {{ $t('ACTIVATION_RATES') }}
            </template>
            <revenue-manager-setup-rates-activation @close="reload(ACTIVE_REVENUE_MANAGER_RATES)"
                                                    :mode="propertyObject.rates_activated === null ? 'new' : 'edit'"
                                                    :property_ids="[propertyObject.id]"
                                                    :submodule="ACTIVE_REVENUE_MANAGER_RATES"
            ></revenue-manager-setup-rates-activation>
        </app-aside>
        <app-aside v-model="activationMinStayAside">
            <template v-slot:header>
                {{ $t('ACTIVATION_MIN_STAY') }}
            </template>
            <revenue-manager-setup-rates-activation @close="reload(ACTIVE_REVENUE_MANAGER_MINIMUM_STAY)"
                                                    :mode="propertyObject.min_stay_activated === null ? 'new' : 'edit'"
                                                    :property_ids="[propertyObject.id]"
                                                    :submodule="ACTIVE_REVENUE_MANAGER_MINIMUM_STAY"
            ></revenue-manager-setup-rates-activation>
        </app-aside>
    </b-tabs>
</template>

<script>
import RevenueManagerSetupActionTable from "@/components/revenue_manager/setup/RevenueManagerSetupActionTable";
import {
    ACTION_TYPE_MIN_STAY,
    ACTION_TYPE_MIN_PRICE,
    ACTIVE_REVENUE_MANAGER_RATES,
    ACTIVE_REVENUE_MANAGER_MINIMUM_STAY,
    ACTIVE_REVENUE_MANAGER_RATES_EXTERNAL,
    DIRECT_BOOKER_COMPANY,
} from "@/shared/constants";
import AppAccessControl from "@/components/app/AppAccessControl";
import AppButton from "@/components/app/AppButton/AppButton";
import AppNoData from "@/components/app/AppNoData";
import routeNames from "@/router/routeNames";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {
    F_REVENUE_MANAGER_COMPANY_ACTIVATION_RATES,
    F_REVENUE_MANAGER_COMPANY_ACTIVATION_MIN_STAY,
    F_REVENUE_MANAGER_PROPERTY_ACTIVATION_RATES,
    F_REVENUE_MANAGER_PROPERTY_ACTIVATION_MIN_STAY,
    F_REVENUE_MANAGER_PROPERTY_RATES_V,
    F_REVENUE_MANAGER_PROPERTY_MIN_STAY_V
} from "@/shared/component_permission";
import {AC_REVENUE_MANAGER_PROPERTY} from "@/mixins/AccessControl/AccessControlEnumeration";
import {saveRevenueManagerSetup} from "@/services/revenue_manager";
import {toast} from "@/shared/plugins/toastr";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import RevenueManagerSetupRatesActivation
    from "@/components/revenue_manager/setup/properties/RevenueManagerSetupRatesActivation";
import AppAside from "@/components/app/form/AppAside";

export default {
    name: "RevenueManagerPropertyActionForm",
    components: {
        RevenueManagerSetupRatesActivation,
        AppConfirmationDialog, AppNoData, AppButton, AppAccessControl, RevenueManagerSetupActionTable, AppAside
    },
    mixins: [getErrorMessage, AccessControlComponent],
    props: {
        propertyId: {
            type: Number,
        },
        propertyObject: {
            type: Object,
            default() {
                return {}
            }
        },
        read_only: {
            type: Boolean,
            default: false
        },
        read_only_message: {
            type: String
        },
    },
    data() {
        return {
            access_control_components: 3,
            access_control_fetch_key: AC_REVENUE_MANAGER_PROPERTY,
            AC_REVENUE_MANAGER_PROPERTY,
            ACTION_TYPE_MIN_PRICE,
            ACTION_TYPE_MIN_STAY,
            ACTIVE_REVENUE_MANAGER_RATES,
            ACTIVE_REVENUE_MANAGER_MINIMUM_STAY,
            F_REVENUE_MANAGER_COMPANY_ACTIVATION_RATES,
            F_REVENUE_MANAGER_COMPANY_ACTIVATION_MIN_STAY,
            F_REVENUE_MANAGER_PROPERTY_RATES_V,
            F_REVENUE_MANAGER_PROPERTY_MIN_STAY_V,
            F_REVENUE_MANAGER_PROPERTY_ACTIVATION_RATES,
            F_REVENUE_MANAGER_PROPERTY_ACTIVATION_MIN_STAY,
            DIRECT_BOOKER_COMPANY,
            routeNames,
            tab: null,
            componentKey: 11,
            componentRatesKey: 101,
            componentMinStayKey: 10,
            loaderRates: false,
            loaderMinStay: false,
            action_type: null,
            confirmationDeactivate: false,
            activationRateAside: false,
            activationMinStayAside: false,
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        deactivateConfirmationTitle() {
            if (this.action_type === ACTION_TYPE_MIN_STAY) {
                return this.$t('DEACTIVATION_CONFIRMATION_RATES')
            }
            if (this.action_type === ACTION_TYPE_MIN_PRICE) {
                return this.$t('DEACTIVATION_CONFIRMATION_MIN_STAY')
            }
            return ''
        },
    },
    methods: {
        reload(type) {
            if (type === ACTIVE_REVENUE_MANAGER_RATES) {
                this.componentRatesKey += 1
                this.activationRateAside = false;
            }
            if (type === ACTIVE_REVENUE_MANAGER_MINIMUM_STAY) {
                this.activationMinStayAside = false;
                this.componentMinStayKey += 1
            }
        },
        deactivateConfirmation(type) {
            this.action_type = type
            this.confirmationDeactivate = true
        },
        deactivate() {
            if (this.action_type === ACTION_TYPE_MIN_STAY) {
                this.activateMinStayProperty(0)
            } else if (this.action_type === ACTION_TYPE_MIN_PRICE) {
                this.activateRateProperty(0)
            }
        },
        activateMinStayProperty(value) {
            this.loaderMinStay = true
            const request = {
                setup_list: [
                    {
                        setup_parameter: ACTIVE_REVENUE_MANAGER_MINIMUM_STAY,
                        property: this.propertyId,
                        unit: null,
                        company: null,
                        value: value
                    },
                ]
            }
            saveRevenueManagerSetup(request).then(response => {
                this.componentMinStayKey += 1
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })

            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
            }).finally(() => {
                this.loaderMinStay = false
                this.confirmationDeactivate = false
            })
        },
        activateRateProperty(value) {
            this.loaderRates = true
            const request = {
                setup_list: []
            }
            if (this.company.id === DIRECT_BOOKER_COMPANY) {
                request.setup_list.push({
                    setup_parameter: ACTIVE_REVENUE_MANAGER_RATES_EXTERNAL,
                    property: this.propertyId,
                    unit: null,
                    company: null,
                    value: value
                })
            } else {
                request.setup_list.push({
                    setup_parameter: ACTIVE_REVENUE_MANAGER_RATES,
                    property: this.propertyId,
                    unit: null,
                    company: null,
                    value: value
                })
            }
            saveRevenueManagerSetup(request).then(response => {
                this.componentRatesKey += 1
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
            }).finally(() => {
                this.loaderRates = false
                this.confirmationDeactivate = false
            })
        },
    },
    created() {
        this.access_control_general_context = {
            company: this.company.id,
            property: this.propertyId
        }
    }
}
</script>

<style scoped>

</style>
