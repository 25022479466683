<template>
            <accommodation-reservation-form-edit
                @created="setLoaded(C_RESERVATION_ACCOMMODATION_GENERAL_E)"
                :reservation="reservation"
                @update="update"
                @saved="saved"
                @reloadScheduler="setLoaded(R_RESERVATION_ACCOMMODATION_SCHEDULER, false)"
                @reloadFinance="setLoaded(R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD, false)"
                :object-type="OBJECT_TYPE_ACCOMMODATION_RESERVATION">
            </accommodation-reservation-form-edit>
</template>

<script>
import {fetchAccommodationReservation} from "@/services/accommodation_reservation";
import {OBJECT_TYPE_ACCOMMODATION_RESERVATION} from "@/shared/constants";
import {
    R_RESERVATION_ACCOMMODATION_SCHEDULER,
    R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD
} from '@/shared/route_permission';
import {fetchAccessControlData} from "@/services/access";
import {AC_ACCOMMODATION_RESERVATION_NAVIGATION} from "@/mixins/AccessControl/AccessControlEnumeration";
import {C_RESERVATION_ACCOMMODATION_GENERAL_E} from "@/shared/component_permission";

const permissions = {
    C_RESERVATION_ACCOMMODATION_GENERAL_E,
    R_RESERVATION_ACCOMMODATION_SCHEDULER,
    R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD
}

export default {
    name: "ReservationDetailsNew",
    components: {
        'AccommodationReservationFormEdit': () =>import('@/components/reservation/accommodation/forms/AccommodationReservationFormEdit')
    },
    props: {
        reservationId: {
            type: Number,
        },
        reservationObject:{
            type:Object
        },
    },
    data() {
        return {
            OBJECT_TYPE_ACCOMMODATION_RESERVATION,
            reservation: null,
            ...permissions,
            activeTab: 0,
            accessControlData: null,
            loaded: {},
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    watch: {
        // '$route.params.id': {
        //     handler(value) {
        //         fetchAccommodationReservation(value).then(res => {
        //             this.reservation = res.data
        //         })
        //     },
        //     immediate: true
        // },
    },
    methods: {
        update(data) {
            this.reservation = {...this.reservation, ...data}
            this.$emit('update', this.reservation)
        },
        saved(data){
            this.reservation = {...this.reservation, ...data}
            this.$emit('saved', this.reservation)
        },
        hasPermission(permissionId) {
            //todo move permission check to access control
            return this.checkPermission(permissionId) && this.accessControlData[permissionId].visible
        },
        setLoaded(tabPermission, value = true) {
            this.$set(this.loaded, tabPermission, value)
        },
    },
    created() {
        fetchAccessControlData(AC_ACCOMMODATION_RESERVATION_NAVIGATION, {
            key: AC_ACCOMMODATION_RESERVATION_NAVIGATION,
            context: {reservation: this.reservationId || this.reservationObject.id},
            data: Object.entries(permissions).map(([k, v]) => ({uid: v, function: v}))
        }).then(response => {
            this.accessControlData = response.data
        })

        if(this.reservationId){
            fetchAccommodationReservation(this.reservationId, {withOwner: true}).then(response => {
                this.reservation = response.data
                this.$emit('update', this.reservation)
            })
        } else if(!this.reservationId && this.reservationObject){
            this.reservation = this.reservationObject
            this.$emit('update', this.reservation)
        }
    }
}
</script>

<style scoped>

</style>
