<template>
    <div>
        <tr class="header-days">
            <td>
                {{ monthLabel }}
            </td>
            <td :key="dayNumberIndex"
                v-for="(dayNumber, dayNumberIndex) in numberOfDaysInMonth">
                {{ dayNumber }}
            </td>
        </tr>
        <tr>
            <td class="commission-value">
                <b-input-group class="commission-copy-group">
                    <b-form-input
                        :disabled="editDisabled"
                        class="commission-copy-input"
                        type="number"
                        :step="step"
                        min="0"
                        no-wheel
                        v-model="monthCommissionValue">
                    </b-form-input>
                    <b-input-group-append>
                        <b-btn
                            style="min-width: unset"
                            :disabled="editDisabled || (!!monthCommissionValue && monthCommissionValue < 0)"
                            variant="primary"
                            @click="setValueForEntireMonth">
                            +
                        </b-btn>
                    </b-input-group-append>
                </b-input-group>
            </td>
            <td :key="dayNumberIndex"
                class="commission-value"
                v-for="(dayNumber, dayNumberIndex) in numberOfDaysInMonth">
                <b-input
                    :disabled="editDisabled"
                    class="commission-input"
                    type="number"
                    :step="step"
                    min="0"
                    no-wheel
                    @change="updateValue(dayNumber, $event)"
                    :value="findValuesForDay(dayNumber).value">
                </b-input>
            </td>
        </tr>
    </div>
</template>

<script>
export default {
    name: "AppCommissionRow",
    props: {
        commissions: {
            type: Object,
            required: true,
            validator: (value) => {
                return value && [365, 366].indexOf(Object.keys(value).length) > -1
            },
        },
        year: {
            type: Number,
            required: true,
        },
        month: {
            type: Number,
            required: true,
            validator: (value) => {
                return Number.isInteger(value) && value >= 1 && value <= 12
            },
        },
        editDisabled: {
            type: Boolean,
            default: false,
        },
        step: {
            type: Number,
            default: 0.01,
        },
    },
    data() {
        return {
            monthCommissionValue: null,
        }
    },
    computed: {
        monthLabel() {
            return (new Date(this.year, this.month, 0)).toLocaleString(this.$i18n.locale, {month: "long"})
        },
        numberOfDaysInMonth() {
            return new Date(this.year, this.month, 0).getDate()
        },
    },
    methods: {
        findValuesForDay(day) {
            const month = this.month > 9 ? this.month.toString() : "0" + this.month.toString()
            day = day > 9 ? day.toString() : "0" + day.toString()
            const date = this.year.toString() + "-" + month + "-" + day
            return this.commissions.hasOwnProperty(date) ? this.commissions[date] : {
                value: null,
                value_type: null,
            }
        },
        updateValue(day, value) {
            const month = this.month > 9 ? this.month.toString() : "0" + this.month.toString()
            day = day > 9 ? day.toString() : "0" + day.toString()
            const date = this.year.toString() + "-" + month + "-" + day
            const currentValues = this.commissions.hasOwnProperty(date) ? this.commissions[date] : null
            if (currentValues && currentValues.value === value) {
                return
            }
            this.$emit("update", {
                date: date,
                values: {
                    value: value,
                    value_type: !!currentValues ? currentValues.value_type : null,
                },
            })
        },
        setValueForEntireMonth() {
            for (let day = 1; day <= this.numberOfDaysInMonth; day++) {
                this.updateValue(day, this.monthCommissionValue)
            }
            this.$nextTick(() => {
                this.monthCommissionValue = null
            })
        },
    },
}
</script>

<style lang="scss" scoped>

td {
    font-size: .7em;
    padding: .4em .4em;
}

.header-days {
    /*background: #6998af14;*/
    font-weight: 600;
    color: #313131;
    font-size: 1em;
    background-color: rgba(50, 145, 148, 0.1);
}

.header-days td {
    padding: .3em .3em;
}

.commission-copy-group {
    width: 5rem;
}

input {
    font-size: 1.1em;
    padding: .2em .4em;
    text-align: center;
    min-width: 2rem;
    width: 2.51rem;
}

.commission-value {
    padding: .2rem .3rem !important;

    & button {
        min-width: unset;
        padding: 0 .3rem;
    }

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>
