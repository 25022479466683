<template>
    <b-dropdown :toggle-class="(state === false) ? 'border-danger invalid-icon' : ''"
                :size="size" no-caret :id="component_id"
                :name="component_id"
                :variant="variant" :disabled="disabled"

                :popper-opts="popperOpts"
                @shown="focusInput"
                @hidden="$emit('hidden')"
                :boundary="boundary">
        <template slot="button-content">
            <div class="text-holder" >
                <slot name="text" :item="value">
                    <span v-html="text"></span>
                </slot>
            </div>
            <i v-if="state !== false && !disabled" class="fa fa-chevron-down fa-fw" aria-hidden="true"></i>
        </template>
        <b-dropdown-form v-if="showSearch">
            <b-input autofocus :ref="component_input_id" :id="component_input_id" class="dropdown_field--input mt-2"
                     size="default"
                     v-model="keyword"></b-input>
        </b-dropdown-form>
        <div class="dropdown_field--options">
            <b-dropdown-group>
                <b-dropdown-item v-if="SearchEmptyItem"
                                 :active="isActive(emptyItem)"
                                 @click="reset()">
                    <span style="visibility: hidden">{{$t('CLEAR')}}</span>
                </b-dropdown-item>
            </b-dropdown-group>
            <template v-for="(optionItem, optionIndex) in options_data">
                <template v-if="Array.isArray(optionItem)">
                    <b-dropdown-group header-classes="pb-1 pt-1" header-variant="light" :header="optionIndex">
                        <b-dropdown-item
                                :ref="`dropdownItem-${optionGroupItem[ValueField]}`"
                                v-for="(optionGroupItem, optionGroupName) in optionItem"
                                :key="optionGroupItem[ValueField]"
                                :active="isActive(optionGroupItem)"
                                @click="change(optionGroupItem)">
                            <div v-html="getOptionLabel(optionGroupItem)"></div>
                        </b-dropdown-item>
                    </b-dropdown-group>
                </template>
                <b-dropdown-group v-else>
                    <b-dropdown-item :key="optionItem[ValueField]"
                                     :ref="`dropdownItem-${optionItem[ValueField]}`"
                                     :active="isActive(optionItem)"
                                     @click="change(optionItem)">
                        <slot name="text" :item="optionItem">
                            <span v-html="getOptionLabel(optionItem)"></span>
                        </slot>
                    </b-dropdown-item>
                </b-dropdown-group>

            </template>
        </div>

    </b-dropdown>
</template>

<script>
import _cloneDeep from "lodash/cloneDeep"
import {textFormatters} from '@/mixins/shared/helpers'

    export default {
        name: "AppSelectList",
        props: {
            value: {
                default: null
            },
            options: {
                type: Array | Object
            },
            ValueField: {
                type: String
            },
            TextField: {
                type: String
            },
            variant: {
                type: String
            },
            size: {
                type: String
            },
            disabled: {
                type: Boolean
            },
            boundary: {
                type: String
            },
            state: {
                default: null
            },
            SearchEmptyItem: {
                type: Boolean
            },
            SearchInput: {
                type: Boolean
            },
            setFirst: {
                type: Boolean,
            },
            returnType: {
                type: String,
                default: 'value'
            },
            popperOpts: {
                type: Object,
                default: () => {
                    return {
                        positionFixed: true,
                        modifiers: {
                            preventOverflow: {
                                boundariesElement: 'scrollParent'
                            }
                        }
                    }
                }
            },
            sanitizeTextValue: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                keyword: ""
            }
        },
        mixins: [textFormatters],
        computed: {
            emptyItem() {
                return {
                    [this.ValueField]: null,
                    [this.TextField]: null
                }
            },
            component_input_id() {
                return 'list_search_auto_input_' + this._uid
            },
            component_id() {
                return 'list_select_' + this._uid
            },
            showSearch() {
                if (this.SearchInput) {
                    if (Array.isArray(this.options)) {
                        return this.options.length > 10
                    } else {
                        let number = 0
                        Object.keys(this.options).forEach(element => {
                            if (Array.isArray(this.options[element])) {
                                number += this.options[element].length
                            }
                        })
                        return number > 10
                    }
                } else {
                    return false
                }

            },
            text() {
                let node = undefined;

                if (Array.isArray(this.options_data)) {
                    node = this.options_data.find(item => item[this.ValueField] == this.value)
                } else {

                    Object.keys(this.options_data).forEach(objectKey => {
                        if (!node && this.options_data.hasOwnProperty(objectKey)) {

                            node = this.options_data[objectKey].find(item => item[this.ValueField] == this.value)
                        }
                    })
                }

                if (typeof node === 'undefined') {
                    if (this.value && typeof this.value === 'object' && this.value[this.TextField]) {
                        return this.sanitizeTextValue && typeof this.value[this.TextField] === 'string' ? this.sanitizeText(this.value[this.TextField]) : this.value[this.TextField]
                    } else {
                        return ''
                    }

                }
                return this.sanitizeTextValue ? this.sanitizeText(node[this.TextField]) : node[this.TextField]
            },
            options_data() {

                return this.getFilteredList(this.options)
            }
        },
        watch: {
            options_data: {
                immediate: true,
                handler() {
                    if (this.setFirst) {
                        if (Array.isArray(this.options_data) && this.options_data.length === 1) {
                            this.change(this.options_data[0])
                        } else if (Object(this.options_data) && Object.prototype.toString.call(this.options_data) !== '[object Array]') {
                            if (Object.keys(this.options_data).length === 1) {
                                if (Object.keys(this.options_data)[0].length) {
                                    this.change(this.options_data[Object.keys(this.options_data)[0]][0])
                                }
                            }
                        }
                    }
                }
            },
        },
        methods: {
            getOptionLabel(item) {
                if (item.hasOwnProperty(this.TextField)) {
                    return this.sanitizeTextValue ? this.sanitizeText(item[this.TextField]) : item[this.TextField]
                }
                return this.sanitizeTextValue && typeof item === 'string' ? this.sanitizeText(item) : item
            },
            change(item) {
                if (typeof item[this.ValueField] !== 'undefined') {
                    if (this.returnType === 'object') {
                        return this.$emit('input', _cloneDeep(item))
                    } else {
                        return this.$emit('input', item[this.ValueField])
                    }
                }
                return this.$emit('input', item)
            },
            reset() {
                this.$emit('input', this.emptyItem[this.ValueField])
            },
            isActive(currentItem) {
                if (currentItem[this.ValueField] == this.value) {
                    return true
                }
                return false
            },

            focusInput() {
                this.keyword = ""
                this.$nextTick(() => {
                    if (this.showSearch) {
                        this.$refs[this.component_input_id].focus()
                    }
                    if (this.value && this.$refs["dropdownItem-" + this.value] && this.$refs["dropdownItem-" + this.value].length) {
                        this.$refs["dropdownItem-" + this.value][0].$el.scrollIntoView()
                    }
                });

            },
            getFilteredList(list) {
                if (this.keyword) {
                    if (Array.isArray(list)) {
                        return list.filter(el => el[this.TextField].toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1)
                    } else {

                        return Object.keys(list).reduce((map, category) => {
                            let items = []
                            if (Array.isArray(list[category])) {
                                items = list[category].filter(el => el[this.TextField].toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1)
                            }

                            if (items.length) {
                                return {
                                    ...map,
                                    [category]: items
                                }
                            } else {
                                return map
                            }
                        }, {})
                    }
                } else {
                    return list
                }
            }
        }
    }
</script>

<style scoped>

</style>
