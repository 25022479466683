<template>
    <div class="main_calendar_test__holder--content--pricing_list  animated fadeIn" v-if="pricing">

                <div ref="calendar_scroll_container" class="main_calendar_test__holder--content_dates main_calendar_test__holder--content--pricing_list-item main_calendar_test--background_gray main_calendar_test-bb main_calendar_test-br_green " :style="`grid-template-columns: repeat(${dateList.length}, 2.87rem)`">
                    <table-rates-cell
                        :key="`rp_${ratePlan}_los_${losDefault.id}_occ_${occupancyDefault.id}_day_${dateIndex}`"
                        v-for="(date, dateIndex) in dateList"
                        :cell-data="getObjectData(date)"
                        :highlighted="isHighlighted(date)"
                        @startEndDrag="dragStartEnd(date)"
                        @drag="drag(date)"
                        @currentDrag="dragCurrent(date)"
                        :default-price="true">
                    </table-rates-cell>
                </div>
    </div>
</template>

<script>
    import TableRatesCell from "@/components/unit/calendar_opt/Table/Rates/TableRatesCell";
    import {EventBus, GE_CALENDAR_OPEN_PRICES} from "@/shared/EventBus";
    import {CalendarRowMixin} from "@/mixins/calendar/CalendarRowMixin";
    import {C_PROPERTY_CALENDAR_PRICES_STARTING_PRICES} from "@/shared/component_permission";

    export default {
        name: "TableRatesDefaultLosAndOccupancyContent",
        mixins:[CalendarRowMixin],
        components: {TableRatesCell},
        props: {
            scrollLeftPosition:{
                type: Number
            },
            los: {
                type: Array
            },
            occupancy: {
                type: Array
            },
            dateList: {
                type: Array
            },
            pricing: {
                type: Object
            },
            suggestions: {
                type: Object
            },
            unit: {
                type: Object
            },
            dateRange: {
                type: Object
            },
            ratePlan: {
                type: Number
            },
            shiftKeyValue: {
                type: Boolean
            },
            pricingDefaults: {
                type: Object,
                default() {
                    return null
                },
            },
        },
        data() {
            return {
                currentDragDate: null,
                dragPeriod: {
                    start: null,
                    end: null
                }
            }
        },
        methods: {
            isHighlighted(date){
                return (this.shiftKeyValue && this.dragPeriod.start && this.currentDragDate)
                    && ((date >= this.dragPeriod.start && date <= this.currentDragDate) ||
                        (date <= this.dragPeriod.start && date >= this.currentDragDate)
                    )
            },
            dragCurrent(date) {
                this.currentDragDate = date
            },
            drag(date) {
                if (this.ratePlan === this.unit.rate_plan) {
                    if (!this.dragPeriod.start) {
                        this.dragPeriod.start = date
                    } else if (!this.dragPeriod.end) {
                        this.dragPeriod.end = date
                        this.openAside()
                    }
                }


            },
            dragStartEnd(date) {
                this.dragPeriod.start = date
                this.dragPeriod.end = date
                this.openAside()
            },
            openAside() {
                if (this.ratePlan === this.unit.rate_plan) {
                    if (this.dragPeriod.start > this.dragPeriod.end) {
                        this.dragPeriod = {
                            start: this.dragPeriod.end,
                            end: this.dragPeriod.start
                        }
                    }

                    EventBus.$emit(GE_CALENDAR_OPEN_PRICES, {
                        unitId: this.unit.id,
                        dateRange: {
                            start: this.dragPeriod.start.format("YYYY-MM-DD"),
                            end: this.dragPeriod.end.format("YYYY-MM-DD")
                        }
                    })
                }
                this.dragPeriod.start = null
                this.dragPeriod.end = null
                this.currentDragDate = null
            },
            getObjectData(date){
                let price = this.getPrice(date)
                const startingPrice = this.getStartingPrice(date)
                return {
                    price,
                    starting_price: startingPrice,
                    lastDayOfMonthClass: {'main_calendar_test-br_green': date.date() === date.daysInMonth()},
                    los:this.losDefault,
                    occupancy:this.occupancyDefault
                }
            },
            getPrice(date){

                if(!this.pricing || Object.keys(this.pricing).length === 0){
                    return null
                }
                let pricingItem = this.pricing[date.format("YYYY-MM-DD")]

                if (!pricingItem) {
                    return null
                }
                let pricingObject = pricingItem[`${this.ratePlan}-${this.losDefault.id}-${this.occupancyDefault.id}`]
                return pricingObject ? pricingObject : null
            },

            getStartingPrice(date) {
                if (!this.pricingDefaults || Object.keys(this.pricingDefaults).length === 0 || !this.checkPermission(C_PROPERTY_CALENDAR_PRICES_STARTING_PRICES)) {
                    return null
                }
                let pricingItem = this.pricingDefaults[date.format("YYYY-MM-DD")]

                if (!pricingItem) {
                    return null
                }

                const amount = pricingItem[`${this.losDefault.id}-${this.occupancyDefault.id}`]
                return amount ? amount : null
            }
        },
        computed:{
            losDefault(){
                const losDefault =  this.los.find(el => el.default)
                return   losDefault ? losDefault : {id:null}
            },
            occupancyDefault(){
                const occupancyDefault =  this.occupancy.find(el => el.default)
                return occupancyDefault ? occupancyDefault : {id:null}
            }
        },
        watch: {
            shiftKeyValue(val) {
                if (val === false) {
                    this.dragPeriod.start = null
                    this.dragPeriod.end = null
                    this.currentDragDate = null
                }
            }
        },
        mounted(){
        }
    }
</script>

<style scoped>

</style>
