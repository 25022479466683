import http from '../../http'
import {
    FINANCE_SETUP_URL,
    INVOICE_CREATION_SETUP_URL,
    INVOICE_ITEM_CREATION_SETUP_URL,
    INVOICE_TYPE_URL,
    INVOICE_URL
} from "@/services/endpoints";

// MANUAL INVOICE SETUP
export function getManualInvoiceSetupList(params) {
    return http.request({
        url: INVOICE_URL + '/setup',
        method: 'GET',
        params
    })
}


// VAT RATES
export function getVatList(params) {
    return http.request({
        url: FINANCE_SETUP_URL + '/vat-list',
        method: 'GET',
        params
    })
}

export function createVatRate(data) {
    return http.request({
        url: FINANCE_SETUP_URL + '/vat',
        method: 'POST',
        data
    })
}

export function updateVatRate(id, data) {
    return http.request({
        url: FINANCE_SETUP_URL + '/vat/' + id,
        method: 'PUT',
        data
    })
}

export function deleteVatRate(id) {
    return http.request({
        url: FINANCE_SETUP_URL + '/vat/' + id,
        method: 'DELETE'
    })
}

// ARTICLES
export function getArticleList(params) {
    return http.request({
        url: FINANCE_SETUP_URL + '/article-list',
        method: 'GET',
        params
    })
}

export function createArticle(data) {
    return http.request({
        url: FINANCE_SETUP_URL + '/article',
        method: 'POST',
        data
    })
}

export function updateArticle(id, data) {
    return http.request({
        url: FINANCE_SETUP_URL + '/article/' + id,
        method: 'PUT',
        data
    })
}

export function deleteArticle(id) {
    return http.request({
        url: FINANCE_SETUP_URL + '/article/' + id,
        method: 'DELETE'
    })
}

export function getPaymentType(params) {
    return http.request({
        url: FINANCE_SETUP_URL + '/payment-type',
        method: 'GET',
        params
    })
}


//INVOICE TYPES
export function getSystemInvoiceTypeList() {
    return http.request({
        url: FINANCE_SETUP_URL + '/system-invoice-type',
        method: 'GET'
    })
}

export function getInvoiceTypeList(params) {
    return http.request({
        url: INVOICE_TYPE_URL,
        method: 'GET',
        params
    })
}

export function createInvoiceType(data) {
    return http.request({
        url: INVOICE_TYPE_URL,
        method: 'POST',
        data
    })
}

export function updateInvoiceType(id, data) {
    return http.request({
        url: INVOICE_TYPE_URL + '/' + id,
        method: 'PUT',
        data
    })
}

export function deleteInvoiceType(id) {
    return http.request({
        url: INVOICE_TYPE_URL + '/' + id,
        method: 'DELETE'
    })
}

// INVOICE CREATION SETUP
export function getInvoiceCreationOptions() {
    return http.request({
        url: INVOICE_CREATION_SETUP_URL + '/options',
        method: 'GET'
    })
}

export function getInvoiceCreationSetupList(params) {
    return http.request({
        url: INVOICE_CREATION_SETUP_URL,
        method: 'GET',
        params
    })
}

export function fetchInvoiceCreationSetup(id) {
    return http.request({
        url: INVOICE_CREATION_SETUP_URL + '/' + id,
        method: 'GET',
    })
}

export function createInvoiceCreationSetup(data) {
    return http.request({
        url: INVOICE_CREATION_SETUP_URL,
        method: 'POST',
        data
    })
}

export function updateInvoiceCreationSetup(id, data) {
    return http.request({
        url: INVOICE_CREATION_SETUP_URL + '/' + id,
        method: 'PUT',
        data
    })
}

export function deleteInvoiceCreationSetup(id) {
    return http.request({
        url: INVOICE_CREATION_SETUP_URL + '/' + id,
        method: 'DELETE'
    })
}

// INVOICE ITEM CREATION SETUP
export function createInvoiceItemCreationSetup(data) {
    return http.request({
        url: INVOICE_ITEM_CREATION_SETUP_URL,
        method: 'POST',
        data
    })
}

export function updateInvoiceItemCreationSetup(id, data) {
    return http.request({
        url: INVOICE_ITEM_CREATION_SETUP_URL + '/' + id,
        method: 'PUT',
        data
    })
}

export function deleteInvoiceItemCreationSetup(id) {
    return http.request({
        url: INVOICE_ITEM_CREATION_SETUP_URL + '/' + id,
        method: 'DELETE'
    })
}
export function createRegisterSetup(id){
    return http.request({
        url: 'adminapi/company/'+id+'/register-setup',
        method: 'POST',
    })
}

