<template>
    <b-card class="main-section-card">
        <div slot="header">
            <h2>{{ $t("INVOICES") }}</h2>
        </div>
        <owner-invoice-filter @selectableChanged="selectable=$event"></owner-invoice-filter>
        <owner-invoice-list ></owner-invoice-list>
    </b-card>
</template>

<script>
    import OwnerInvoiceFilter from "@/components/finance/invoices/OwnerInvoiceFilter";
    import OwnerInvoiceList from "@/components/finance/invoices/OwnerInvoiceList";
    export default {
        name: "OwnerIndex",
        components: {OwnerInvoiceList, OwnerInvoiceFilter},

    }
</script>

<style scoped>

</style>
