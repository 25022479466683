<template>
    <b-button class="app-button-link" size="sm" :variant="variant" :to="to" :disabled="isDisabled">
        <i :class="icon" aria-hidden="true"></i>
        <slot></slot>
    </b-button>
</template>

<script>
    export default {
        name: "AppButtonLink",
        props: {
            to: Object,
            disabled: {
                type: Boolean,
                default: false
            },
            variant: {
                type: String,
                default: "success"
            },
            button_type: {
                type: String,
                default: "edit"
            },
            r_permission: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                icons: {
                    edit: "fa fa-pencil-square-o fa-fw",
                    new: "fa fa-plus-square-o fa-fw",
                    loading: "fa fa-spinner fa-spin fa-fw'",
                    connect:"fa fa-dot-circle-o fa-fw"
                }
            }
        },
        computed: {
            icon() {
                if (this.icons[this.button_type]) {
                    return this.icons[this.button_type]
                }
                return this.icons.save
            },
            hasPermission() {
                if (this.r_permission !== null) {
                    return this.$store.getters['user/getPermission'](this.r_permission)
                }
                return true
            },
            isDisabled() {
                return this.disabled || !this.hasPermission
            }
        }
    }
</script>

<style scoped>
    .app-button-link {
        white-space: nowrap;
    }
</style>
