<template>
    <form @submit.prevent="search" @reset.prevent="resetForm" class="filter">
        <b-row>
            <b-col md="2" class="mb-3">
                <label>{{$t('RECIPIENT')}}</label>
                <app-select mode="search"
                            :search="searchPartner"
                            v-model="partner">
                    <template v-slot:text="data">
                        {{data.item.name}} {{data.item.last_name}}
                    </template>
                </app-select>
            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{$t('IBAN')}}</label>
                <b-input type="text"
                v-model="filter.partner_iban"></b-input>
            </b-col>
            <b-col md="6">
                <app-button-submit class="filter-submit-wrapper" :inline="true" :disabled="loading" :loading="loaderSubmit" variant="primary" button_type="search">
                    {{$t("SEARCH") }}
                </app-button-submit>
                <app-button-reset :disabled="loading" :loading="loaderReset" :inline="true"  class="pl-2 ml-2"  ></app-button-reset>
            </b-col>
        </b-row>
    </form>
</template>

<script>
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {cloneDeep} from "lodash";
import {EventBus} from "@/shared/EventBus";
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import {getPartnerList} from "@/services/finance/partner";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import AppNumberInput from "@/components/app/form/AppNumberInput";

export default {
    name: "PaymentOrderInvoiceFilter",
    components: {AppNumberInput, AppButtonReset, AppDatePicker, AppSelect, AppButtonSubmit},
    data() {
        return {
            filter: {
                status: [],
                invoice_subtype: [],
                payment_message: null,
                partner_iban: null
            },
            status: {},
            partner: {},
            initialFilter: {},
            loading: false,
            reset:0,
            loaderReset:false,
            loaderSubmit:false
        }
    },
    props: {
        paymentOrderId: {
            type: Number
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    watch: {
        partner(value) {
            if (value) {
                this.filter.partner = value.id
            }
        },
    },
    methods: {
        search() {
            this.reset = 0
            EventBus.$emit('invoiceSearch', {...this.filter, company: this.company.id})
        },
        resetForm() {
            this.reset =1
            if (Object.keys(this.$route.query).length > 0) {
                this.$router.replace({query: {}})
            }
            this.partner = {}
            this.filter = cloneDeep(this.initialFilter)
            EventBus.$emit('invoiceSearch', {...this.filter,  company: this.company.id})
        },
        searchPartner(value) {
            return getPartnerList({
                search: value,
                company: this.company.id,
                results: 20,
                payment_order: this.paymentOrderId
            })
        },
    },
    created() {
        this.initialFilter = cloneDeep(this.filter)
        EventBus.$on('invoiceLoading', (value) => {
            if(this.reset){
                this.loaderReset = value
            } else {
                this.loaderSubmit = value
            }
            this.loading = value
        })
    },
    beforeDestroy() {
        EventBus.$off('invoiceLoading')
    }
}
</script>

<style scoped>

</style>
