import Amenity from '@/store/modules/unit/amenity'
import Pricing from '@/store/modules/unit/pricing'
import {
    GET_UNIT_ITEM,
    ACTION_SET_UNIT_ITEM,
    SET_UNIT_ITEM,
    SET_UNIT_DESCRIPTIONS,
    GET_UNIT_DESCRIPTIONS,
    ACTION_SET_UNIT_DESCRIPTIONS,
    SET_UNIT_DAMAGE_DEPOSIT,
    GET_UNIT_DAMAGE_DEPOSIT,
    ACTION_SET_UNIT_DAMAGE_DEPOSIT,
    ACTION_SAVE_UNIT_DESCRIPTIONS,
    ACTION_SAVE_UNIT_DAMAGE_DEPOSIT,
    ACTION_DELETE_UNIT_DAMAGE_DEPOSIT,

} from '@/store/store_types'

import {
    getUnit,
    getUnitDescriptionList,
    setUnitDescriptions,
    getUnitFeeList,
    getUnitDamageDeposit,
    setUnitDamageDeposit,
    deleteUnitDamageDeposit,
    updateUnit,
    createUnit,
    getUnitPaginatedList, updateUnitKeyPickUp
} from '@/services/unit/index'

import _get from 'lodash/get'
import _set from 'lodash/set'
import {
    UNIT_DESCRIPTION,
    SPACE_DESCRIPTION,
    GUEST_ACCESS_AND_INVENTORY,
    HOUSE_RULES,
    GUEST_INTERACTION,
    POOL,
    GETTING_THERE,
    NOTES
} from '@/shared/constants'


const state = {
    basic: {},
    descriptions: [],
    damageDeposit: {
        id: null, collectPaymentType: null,
        collectPaymentEvent: null,
        returnPaymentType: null,
        returnPaymentEvent: null,
        amount: null,
    },
    search: {
        list: {
            list_count: 0,
            list_items: [],
            last_page: 0
        },
        filter: {
            page: 1,
            limit: 10,
            property_id: null,
            contigent: 0,
            company_id: null,
            name: '',
            type: null,
            owner: null
        }
    }
}

const getters = {
    getUnit(state) {
        return state.basic
    },
    [GET_UNIT_ITEM](state) {
        return payload => _get(state, payload, null)
    },
    [GET_UNIT_DESCRIPTIONS](state) {
        return state.descriptions;
    },

    [GET_UNIT_DAMAGE_DEPOSIT](state) {
        return state.damageDeposit;
    },
    getSearchFilter(state) {
        return state.search.filter
    },
    getSearchList(state) {
        return state.search.list.list_items
    },
    getSearchListCount(state) {
        return state.search.list.list_count
    },
    getSearchListLastPage(state) {
        return state.search.list.last_page
    },
    getSystemNonRef(state) {
        return state.basic.system_non_ref
    }

}

const mutations = {
    setUnit(state, payload) {
        state.basic = payload
    },
    [SET_UNIT_ITEM](state, payload) {
        _set(state, payload.path, payload.value)
    },
    [SET_UNIT_DESCRIPTIONS](state, payload) {
        state.descriptions = payload
    },
    [SET_UNIT_DAMAGE_DEPOSIT](state, payload) {
        state.damageDeposit = payload
    },
    setSearchFilterItem(state, {item, value}) {
        state.search.filter[item] = value
    },
    setSearchList(state, list) {
        state.search.list.list_items = list
    },
    setSearchListCount(state, count) {
        state.search.list.list_count = count
    },
    setSearchListLastPage(state, page) {
      state.search.list.last_page = page
    },
    setSystemNonRef(state, payload) {
        state.basic.system_non_ref = payload
    }
}

const actions = {
    actionSetUnit({commit}, id) {
        return new Promise((resolve, reject) => {
            getUnit(id).then(response => {
                commit('setUnit', response.data)
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    actionCreateUnit({commit}, payload) {
        return new Promise((resolve, reject) => {
            createUnit(payload).then(response => {
                commit('setUnit', response.data)
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    actionUpdateUnit({commit}, payload) {
        return new Promise((resolve, reject) => {
            updateUnit(payload.id, payload.data).then(response => {
                if (!response.data.hasOwnProperty("open_occupancy_sidebar") || !response.data.open_occupancy_sidebar) {
                    commit('setUnit', response.data)
                }
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    actionUpdateUnitKeyPickup({commit}, payload) {
        return new Promise((resolve, reject) => {
            updateUnitKeyPickUp(payload.id, payload.data).then(response => {
                commit('setUnit', response.data)
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    actionDeleteUnitKeyPickup({commit, state}, payload) {

          state.basic.key_pickup = payload.data.key_pickup
        return new Promise((resolve, reject) => {

            updateUnit(payload.id, state.basic).then(response => {
                commit('setUnit', response.data)
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    [ACTION_SET_UNIT_ITEM]({commit}, payload) {
        commit(SET_UNIT_ITEM, payload)
    },
    [ACTION_SET_UNIT_DESCRIPTIONS]({commit}, id) {

        return new Promise((resolve, reject) => {
            getUnitDescriptionList(id).then(response => {
                commit(SET_UNIT_DESCRIPTIONS, response.data)
                resolve(response);  // Let the calling function know that http is done. You may send some data back
            }, error => {
                // http failed, let the calling function know that action did not work out
                reject(error);
            })
        })
    },

    [ACTION_SET_UNIT_DAMAGE_DEPOSIT]({commit}, id) {


        return new Promise((resolve, reject) => {
            getUnitDamageDeposit(id).then(response => {
                commit(SET_UNIT_ITEM, {path: 'damageDeposit', value: response.data})
                resolve(response);  // Let the calling function know that http is done. You may send some data back
            }, error => {
                // http failed, let the calling function know that action did not work out
                reject(error);
            })
        })
    },
    [ACTION_SAVE_UNIT_DESCRIPTIONS]({commit}, id) {
        return new Promise((resolve, reject) => {
            let data = {
                summary: '',
                space: '',
                guest_access_and_inventory: '',
                house_rules: '',
                getting_there: '',
                notes: '',
                pool: '',
                guest_interaction: ''

            }

            if (state.descriptions.length > 0) {

                state.descriptions.forEach(function (element) {

                    if (element.name === UNIT_DESCRIPTION) {
                        data.summary = element.value
                    }
                    if (element.name === SPACE_DESCRIPTION) {
                        data.space = element.value
                    }
                    if (element.name === GUEST_ACCESS_AND_INVENTORY) {
                        data.guest_access_and_inventory = element.value
                    }
                    if (element.name === HOUSE_RULES) {
                        data.house_rules = element.value
                    }
                    if (element.name === GUEST_INTERACTION) {
                        data.guest_interaction = element.value
                    }
                    if (element.name === POOL) {
                        data.pool = element.value
                    }
                    if (element.name === GETTING_THERE) {
                        data.getting_there = element.value
                    }
                    if (element.name === NOTES) {
                        data.notes = element.value
                    }
                })
            }
            setUnitDescriptions(id, data).then(response => {
                commit(SET_UNIT_ITEM, {path: 'descriptions', value: response.data})
                resolve(response);  // Let the calling function know that http is done. You may send some data back
            }, error => {
                // http failed, let the calling function know that action did not work out
                reject(error);
            })
        })
    },
    [ACTION_SAVE_UNIT_DAMAGE_DEPOSIT]({commit}, {id, payload}) {
        return new Promise((resolve, reject) => {
            // let data = {
            //     collect_payment_type: state.damageDeposit.collectPaymentType.value,
            //     collect_payment_event_value: state.damageDeposit.collectPaymentEvent.value,
            //     return_payment_type: state.damageDeposit.returnPaymentType.value,
            //     return_payment_event_value: state.damageDeposit.returnPaymentEvent.value,
            //     amount: state.damageDeposit.amount,
            // }
            setUnitDamageDeposit(id, payload).then(response => {
                commit(SET_UNIT_ITEM, {path: 'damageDeposit', value: response.data})
                resolve(response);  // Let the calling function know that http is done. You may send some data back
            }, error => {
                // http failed, let the calling function know that action did not work out
                reject(error);
            })
        })
    },
    [ACTION_DELETE_UNIT_DAMAGE_DEPOSIT]({commit}, id) {
        return new Promise((resolve, reject) => {
            let post = {
                id: _get(state, ['damageDeposit', 'id'])
            }

            deleteUnitDamageDeposit(id, post).then(response => {
                commit(SET_UNIT_ITEM, {path: 'damageDeposit', value: response.data})
                resolve(response);  // Let the calling function know that http is done. You may send some data back
            }, error => {
                // http failed, let the calling function know that action did not work out
                reject(error);
            })
        })
    },
    actionSearch({commit, state}) {
        return new Promise((resolve, reject) => {
            getUnitPaginatedList(state.search.filter)
                .then((response) => {
                    commit('setSearchList', response.data.items)
                    commit('setSearchListCount', response.data.total)
                    commit('setSearchListLastPage', response.data.last_page)
                    resolve(response)
                }).catch(error => {
                reject(error)
            })
        })
    },
    actionResetSearch({commit}) {
        commit("setSearchFilterItem", {item: "page", value: 1})
        commit("setSearchFilterItem", {item: "limit", value: 20})
        commit("setSearchFilterItem", {item: "name", value: null})
        commit("setSearchFilterItem", {item: "type", value: null})
        commit("setSearchFilterItem", {item: "owner", value: null})
    }
}


export default {
    state,
    getters,
    mutations,
    actions,
    modules: {
        amenity: Amenity,
        pricing: Pricing,
    }
}
