<template>
<form>
    <b-row class="mb-4 mt-4">
        <b-col lg="6">
            <app-input-control :error-object="validation.name">
                <template v-slot:input="data">
                    <label>{{$t('NAME')}}</label>
                    <b-input v-model="name" :state="data.state"></b-input>
                </template>
            </app-input-control>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <app-button @click="save" :loading="loading">{{$t('SAVE')}}</app-button>
        </b-col>
    </b-row>
</form>
</template>

<script>

import AppButton from "@/components/app/AppButton/AppButton";
import AppInputControl from "@/components/app/AppInputControl";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {saveThemeColorCategory} from "@/services/appearance";
import {APPEARANCE_CATEGORY_NAME_EMPTY} from "@/shared/error_codes";

export default {
    name: "AppearanceNewColorCategory",
    mixins:[getErrorMessage],
    components:{AppButton, AppInputControl},
    data(){
        return {
            name: null,
            loading: false,
            validation:{
                name: APPEARANCE_CATEGORY_NAME_EMPTY
            }
        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods:{
        save(){
            this.loading = true
            saveThemeColorCategory({company_id: this.company.id, name: this.name}).then(response => {
                notifySuccess()
                this.loading = false
                this.$emit('category-saved')
            }, error => {
                this.loading = false
                this.showErrorMessages(error, this.validation)
            })
        }
    }
}
</script>

<style scoped>

</style>
