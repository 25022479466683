import http from '../http'
import {USER_LIST_URL, USER_URL} from '@/services/endpoints'

export function getUser() {
    return http.request({
        url: USER_URL,
        method: 'get'
    })
}

export function fetchUserById(id) {
    return http.request({
        url: USER_URL + '/' + id,
        method: 'get'
    })
}

export function updateUser(id, data) {
    return http.request({
        url: USER_URL + '/' + id,
        method: 'put',
        data: data
    })
}

export function deleteUser(id) {
    return http.request({
        url: USER_URL + '/' + id,
        method: 'delete'
    })
}

export function updateUserPassword(data) {
    return http.request({
        url: USER_URL + '/password',
        method: 'put',
        data: data
    })
}

export function getPermissions() {
    return http.request({
        url: USER_URL + '/permission',
        method: 'get'
    })
}
export function getUserList(params) {
    return http.request({
        url: USER_LIST_URL,
        method: 'get',
        params
    })
}
export function getUserRolesList(params) {
    return http.request({
        url: USER_URL + '/roles',
        method: 'get',
        params
    })
}

export function getPossibleRoles(params) {
    return http.request({
        url: USER_URL + '/possible-roles',
        method: 'get',
        params
    })
}

export function updateUserRoles(id, data) {
    return http.request({
        url: USER_URL + '/' + id + '/roles',
        method: 'put',
        data: data
    })
}

export function addNewUser(data) {
    return http.request({
        url: USER_URL,
        method: 'post',
        data: data
    })
}

export function getPresets() {
    return http.request({
        url: USER_URL + '/presets',
        method: 'get',
    })
}

export function setProfilePicture(id, data) {
    return http.request({
        url: USER_URL + '/' + id + '/profile-picture',
        method: 'post',
        data: data
    })
}

export function updateUserNotifications(id, data) {
    return http.request({
        url: USER_URL + '/' + id + '/notifications',
        method: 'put',
        data: data
    })
}

export function getUserNotifications(id,params) {
    return http.request({
        url: USER_URL + '/' + id + '/notifications',
        method: 'get',
        params
    })
}

export function sendUserNotifications(id, data) {
    return http.request({
        url: USER_URL + '/' + id + '/send-notifications',
        method: 'post',
        data: data
    })
}

