<template>
    <div>
        <app-no-data v-if="property.unit_count_list.total_count === 0" :heading="$t('MODULE_NOT_ACTIVE')" :tip="$t('MODULE_NEED_UNITS')" :show-tip="true">
            <app-button button_type="new" @click="unitAside=true">
                {{ $t('NEW_UNIT') }}
            </app-button>
        </app-no-data>

        <div v-else-if="isActive !== null">
            <app-button class="pull-right mb-3"
                        :disabled="loading"
                        :c_permission="C_PROPERTY_BE_E"
                        @click="toggleBookingEngine(isActive)">
                {{ beButtonText }}
            </app-button>

            <app-button v-if="isActive > 0" class="pull-right mb-3 mr-3"
                        :disabled="loading"
                        :c_permission="C_PROPERTY_BE_E"
                        @click="displayParameterForm('property', property)">
                {{ $t('EDIT') }}
            </app-button>

            <b-table v-if="isActive && tableItems.length"
                     :hover="true"
                     :responsive="true"
                     :fields="tableFields"
                     :items="tableItems">
                <template v-slot:booking_form="data">
                     <span v-if="data.item.value">
                    {{data.item.link}}
                     </span>
                </template>
                <template  v-slot:status="data">
                    {{ data.item.value > 0 ? $t('ACTIVE') : $t('INACTIVE') }}
                </template>

                <template v-slot:activate="data">
                    <app-button variant="link" :c_permission="C_PROPERTY_BE_E" @click="toggleUnit(data.item)"
                                :disabled="loading">
                        {{ data.item.value > 0 ? $t('DEACTIVATE') : $t('ACTIVATE') }}
                    </app-button>
                </template>

                <template v-slot:action_buttons="data">
                    <app-button v-if="data.item.value > 0" variant="link"
                                button_type="copy"
                                :show_text="false"
                                @click="copyToClipboard(data.item.link)">
                    </app-button>

                    <app-button v-if="data.item.value > 0" variant="link"
                                button_type="edit"
                                :show_text="false"
                                @click="displayParameterForm('unit', data.item)">
                    </app-button>
                </template>

            </b-table>

            <app-aside v-model="asidePanelState">

                <template slot="header">{{ editingObjectName }}</template>

                <b-form v-if="formSchema.length" @submit.prevent="save(formData)">
                    <b-row>
                        <b-col md="4" v-for="(param, index) in formSchema" :key="index" v-if="formData[param.paramId]">
                            <b-form-group>
                                <label> {{ param.label }}</label>
                                <component v-if="editingObject.type === 'unit'" :is="param.fieldType"
                                           v-bind="omit(param, 'label')"
                                           v-model="formData[param.paramId].unit[editingObject.id].value">
                                </component>

                                <component v-else :is="param.fieldType" v-bind="omit(param, 'label')"
                                           v-model="formData[param.paramId].property.value">
                                </component>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <app-button-submit :c_permission="C_PROPERTY_BE_E"></app-button-submit>
                </b-form>
            </app-aside>

            <b-modal ok-only ok-variant="secondary" :ok-title="$t('CLOSE')" v-model="modalState" scrollable>
                <div> {{ modalLink }}</div>
            </b-modal>
        </div>

        <app-aside :widths="['col-sm-12', '40%']" v-model="unitAside">
            <template slot="header">{{$t('NEW_UNIT')}}</template>
            <unit-wizard v-if="property" :property="property"></unit-wizard>
        </app-aside>
    </div>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {
        getBESetupParameters,
        getBEStatus,
        toggleBEStatus,
        toggleUnitStatus,
        updateBESetupParameters
    } from "@/services/booking_engine";
    import {getUnitList} from "@/services/unit";
    import {BE_SP_ACTIVE, BE_SP_DISPLAY_THEME, SP_VALUE_TEXT} from "@/shared/constants";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {toast} from "@/shared/plugins/toastr";
    import AppAside from "@/components/app/form/AppAside";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {C_PROPERTY_BE_E, C_PROPERTY_BE_V} from "@/shared/component_permission";
    import {omit} from 'lodash'
    import AppNoData from "@/components/app/AppNoData";
    import UnitWizard from "@/components/unit/new/UnitWizard";

    export default {
        name: "Index",
        components: {UnitWizard, AppNoData, AppButtonSubmit, AppButton, AppAside, AppSelect},
        mixins: [getErrorMessage],
        data() {
            return {
                isActive: null,
                link: null,
                modalLink: null,
                unitList: [],
                unitActiveValues: [],
                tableFields: [
                    {key:'id',label:'Id'},
                    {key: 'name', label: this.$t('NAME')},
                    {key:'booking_form',label:'Booking form'},
                    {key: 'status', label: this.$t('STATUS')},
                    {key: 'activate', label: ''},
                    {key: 'action_buttons', label: '', class: 'text-right'}
                ],
                loading: false,
                asidePanelState: false,
                modalState: false,
                formData: {},
                formSchema: [],
                editingObject: null,
                BE_SP_DISPLAY_THEME,
                C_PROPERTY_BE_E,
                C_PROPERTY_BE_V,
                omit,
                unitAside: false,
            }
        },
        computed: {
            property() {
                return this.$store.getters['property/getProperty']
            },
            beButtonText() {
                return this.isActive
                    ? this.$t('DEACTIVATE') + ' Booking engine'
                    : this.$t('ACTIVATE') + ' Booking engine'
            },
            unitIdList() {
                return this.unitList.length ? this.unitList.map(unit => unit.id) : []
            },
            tableItems() {
                if (this.unitList.length &&
                    this.unitActiveValues.length &&
                    this.unitList.length === this.unitActiveValues.length) {

                    return Object.values(this.unitList.concat(this.unitActiveValues).reduce((acc, x) => {
                        acc[x.id] = Object.assign(acc[x.id] || {}, x)
                        return acc
                    }, {}))
                } else {
                    return []
                }
            },
            defaultOptions() {
                return [
                    {name: this.$t('YES'), id: 1},
                    {name: this.$t('NO'), id: 0}
                ]
            },
            /*schemaChunks() {
                if (this.formSchema.length && Object.keys(this.formData).length) {
                    return chunk(this.formSchema, 3)
                } else {
                    return []
                }
            },*/
            editingObjectName() {
                return this.editingObject ? this.editingObject.name : ''
            },

        },
        watch: {
            '$route.params.id': {
                handler(value) {
                    if (this.property.unit_count_list.total_count > 0) {
                        this.loading = true
                        getUnitList({property_id: value, contigent: 0, limit: 1000})
                            .then(res => {
                                this.unitList = res.data.items.map(item => {
                                    return {id: item.id, name: item.name}
                                })
                                return getBEStatus({property: value})
                            })
                            .then(res => {
                                let unitList = this.unitList.map(item => {
                                    return {id: item.id, name: item.name, link: !res.data.link ? null  : res.data.link.replace('{UNIT_ID}',item.id)}
                                })
                                this.unitList = unitList
                                this.isActive = res.data.active
                                this.link = res.data.link
                            })
                            .catch(error => {
                                toast({
                                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                                    'message': this.getMessages(error),
                                    'type': 'error',
                                    'timeout': 2000
                                })
                            })
                            .finally(() => this.loading = false)
                    }
                },
                immediate: true
            },
            isActive(value) {
                // get active parameter for all object types if BE is active
                if (value && value > 0) {
                    this.loading = true
                    getBESetupParameters({
                        property: this.$route.params.id,
                        unit: this.unitIdList,
                        setup_parameter: BE_SP_ACTIVE
                    })
                        .then(res => {
                            // map Active parameter values to Unit object
                            this.unitActiveValues = res.data[0].values.unit
                        })
                        .catch(error => {
                            toast({
                                'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                                'message': this.getMessages(error),
                                'type': 'error',
                                'timeout': 2000
                            })
                        })
                        .finally(() => this.loading = false)
                }
            }
        },
        methods: {
            copyToClipboard(link) {
                if (window.clipboardData && window.clipboardData.setData) {
                    return this.clipboardData.setData("Text", link)
                }
                else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
                    let input = document.createElement("input")
                    document.body.appendChild(input)
                    input.visibility = 'hidden'
                    input.value = link
                    input.style.position = "fixed"
                    input.focus()
                    input.select()
                    try {
                        document.execCommand("copy")  // Security exception may be thrown by some browsers.
                        toast({
                            message: this.$t('NOTIFICATIONS.COPY_TO_CLIPBOARD_SUCCESS.MESSAGE'),
                            type: 'success',
                            timeout: 3000
                        })
                        return true
                    }
                    catch (ex) {
                        console.warn("Copy to clipboard failed.", ex)
                        toast({
                            //title: this.$t('NOTIFICATIONS.COPY_TO_CLIPBOARD_FAIL.TITLE'),
                            message: this.$t('NOTIFICATIONS.COPY_TO_CLIPBOARD_FAIL.MESSAGE'),
                            type: 'success',
                            timeout: 3000
                        })
                        return false
                    }
                    finally {
                        document.body.removeChild(input)
                    }
                }
            },
            toggleBookingEngine(active) {
                this.loading = true
                toggleBEStatus({property: this.$route.params.id, activate: active > 0 ? 0 : 1})
                    .then(() => {
                        return getBEStatus({property: this.$route.params.id})
                    })
                    .then(res => {
                        this.isActive = res.data.active
                        this.link = res.data.link
                    })
                    .catch(error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.getMessages(error),
                            'type': 'error',
                            'timeout': 2000
                        })
                    })
                    .finally(() => this.loading = false)
            },
            toggleUnit(unit) {
                this.loading = true
                toggleUnitStatus(unit.id, {activate: unit.value > 0 ? 0 : 1})
                    .then(() => {
                        let unitIndex = this.unitActiveValues.findIndex(x => x.id === unit.id)
                        this.unitActiveValues[unitIndex].value = unit.value > 0 ? 0 : 1
                    })
                    .catch(error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 3000
                        })
                    })
                    .finally(() => this.loading = false)
            },
            displayParameterForm(objectType, {id, name}) {
                this.formData = {}
                this.editingObject = {id, name, type: objectType}
                getBESetupParameters({[objectType]: id, ignored: [BE_SP_ACTIVE]}).then(res => {

                    if (objectType === 'unit') {
                        res.data.forEach(param => {
                            if (param.values.unit) {
                                this.$set(this.formData, param.parameter_type.id, {
                                    parameter: param.parameter_type.id,
                                    unit: {
                                        [param.values.unit[0].id]: param.values.unit[0]
                                    }
                                })
                            }
                        })
                    } else {
                        res.data.forEach(param => {
                            this.$set(this.formData, param.parameter_type.id, {
                                parameter: param.parameter_type.id,
                                property: {
                                    id: this.$route.params.id,
                                    value: param.values.property
                                }
                            })
                        })
                    }

                    this.formSchema = res.data.reduce((acc, p) => {
                        if (objectType === 'property' || (objectType === 'unit' && p.values.unit)) {
                            let schema = {}
                            schema.paramId = p.parameter_type.id
                            schema.label = p.parameter_type.label
                            schema.fieldType = (p.value_type.id !== SP_VALUE_TEXT) ? 'app-select' : 'b-form-textarea'
                            schema.options = p.value_type.options
                                ? p.value_type.options.map(v => {
                                    return {id: v, name: v}
                                })
                                : this.defaultOptions
                            acc.push(schema)
                        }
                        return acc
                    }, [])

                    this.asidePanelState = true
                })
            },
            showLinkModal(unit) {
                this.modalLink = this.link.replace('{UNIT_ID}', unit.id)
                this.modalState = true
            },
            save(data) {
                let requestData = Object.values(data)
                if (this.editingObject.type === 'unit') {
                    requestData = requestData.map(p => {
                        return {
                            parameter: p.parameter,
                            unit: Object.values(p.unit)
                        }
                    })
                }

                updateBESetupParameters({data: requestData}).then(() => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                }).catch(error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 2000
                    })
                })
            }
        }
    }
</script>

<style scoped>

</style>
