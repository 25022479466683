<template>

    <tr>
        <td>
            <app-select v-model="values.condition_type"
                        value-field="id"
                        text-field="label"
                        :search-empty-item="false"
                        :options="selectConditionType">
            </app-select>
        </td>
        <td>
            <app-select v-model="values.day"
                        value-field="id"
                        text-field="label"
                        :search-empty-item="false"
                        :options="selectDay">
            </app-select>
        </td>
        <td>
            <app-select v-model="values.day_type"
                        value-field="id"
                        text-field="label"
                        :search-empty-item="false"
                        :options="selectDayType">
            </app-select>
        </td>
        <td>
            <app-select v-model="values.charge"
                        value-field="id"
                        text-field="label"
                        :search-empty-item="false"
                        :options="selectCharge">
            </app-select>
        </td>
        <td class="text-right">
            <new-condition-button :c_permission="!system_policy ? C_PROPERTY_CANCELATION_POLICY_E : C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_NEW"
                                  @click="$emit('saveCondition', values)"
                                  :disabled="disabledSaveButton"
                                  :loading="loading">
                {{$t('ADD_ITEM')}}
            </new-condition-button>
        </td>
    </tr>
</template>

<script>

    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import NewConditionButton from '@/components/app/AppButton/AppButton'
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_NEW, C_PROPERTY_CANCELATION_POLICY_E} from "@/shared/component_permission";

    export default {
        name: "CancelationConditionItemNew",
        props: {
            policy: {
                type: Object,
                required: true
            },
            system_policy: {
                type: Boolean,
                default: false
            },
            selectConditionType: {
                type: Array
            },
            selectDay: {
                type: Array
            },
            selectDayType: {
                type: Array
            },
            selectCharge: {
                type: Array
            },
            loading: {
                type: Boolean
            }
        },
        components: {
            AppSelect,
            NewConditionButton
        },
        mixins: [getErrorMessage],
        data() {
            return {
                values: {
                    condition_type: null,
                    day: null,
                    day_type: null,
                    charge: null,
                },
                C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_NEW,
                C_PROPERTY_CANCELATION_POLICY_E
            }
        },
        watch: {
            loading: {
                handler(value) {
                    if (!value) {
                        this.values.condition_type = null
                        this.values.day = null
                        this.values.day_type = null
                        this.values.charge = null
                    }
                }
            }
        },
        computed: {
            disabledSaveButton() {
                return this.values.charge === null || this.values.condition_type === null || this.values.day === null || this.values.day_type === null
            }
        }
    }
</script>

<style scoped>

</style>
