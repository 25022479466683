<template>
    <b-form @submit.prevent="save" v-if="categories">
        <b-row class="mb-3 mt-3">
            <b-col>
                <label>{{$t('CATEGORY')}}</label>
                <app-select :options="categories" :search-empty-item="false" v-model="changedCategory"></app-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button @click="save" :loading="loader">{{$t('SAVE')}}</app-button>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {fetchPromoCategories, updatePromoCodeCategory} from "@/services/promo_codes";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";

export default {
    name: "PromoCategoryAssign",
    components: {AppButton, AppSelect},
    mixins:[getErrorMessage],
    props:{
        selectedCategory:{
            type: Number,
            default: null
        },
        selectedCodes:{
            type: Array,
            default: null
        }
    },
    data(){
        return{
            categories:[],
            changedCategory: null,
            loader: false
        }
    },
    methods:{
        save(){
            this.loader = true
            updatePromoCodeCategory({list: this.selectedCodes, category: this.changedCategory}).then(response => {
                notifySuccess()
                this.loader = false
                this.$emit('categoryAssigned')
            }, error => {
                this.loader = false
                this.showErrorMessages(error)
            })
        }
    },
    created() {
        fetchPromoCategories().then(response => {
            this.categories = response.data
        })
    },
    watch:{
        categories:{
            handler(){
                this.categories.forEach((el, index) => {
                    if (el.id === this.selectedCategory){
                        this.categories.splice(index, 1)
                    }
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
