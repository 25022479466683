<template>
    <div>
        <div class="table-wrapper">
            <table class="table">
                <thead>
                <tr>
                    <th class="table-header-period-range" scope="col">{{$t('PERIOD')}}</th>
                    <th class="table-header-actions text-right" scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <period-item :key="period.id + '_' + period.deleted" v-for="period in periods_data_list"
                             v-show="period.deleted !== 1" :period="period"
                             @delPeriod="removePeriod" ref="period_items"></period-item>
                <period-item-new v-if="checkPermission(C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_NEW)" @save="addPeriod"
                                 :loading="loading_add_new"
                                 :add-button="false"
                ></period-item-new>
                </tbody>
            </table>
        </div>
        <b-row>
            <b-col class="mt-4">
                <submit-button :c_permission="C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_E" @click="confirmation_dialog_state=true"></submit-button>
            </b-col>
        </b-row>

        <app-confirmation-dialog
            :show="confirmation_dialog_state"
            @confirm="save"
            @cancel="confirmation_dialog_state=false"
            :title="$t('SALES_PERIODS_UPDATE_CONFIRMATION_TITLE')">
            {{$t('SYSTEM_SALES_PERIODS_UPDATE_CONFIRMATION_MSG')}}
        </app-confirmation-dialog>
    </div>
</template>

<script>
    import PeriodItem from "@/components/unit/bulk_calendar/period/PeriodItem";
    import PeriodItemNew from "@/components/unit/bulk_calendar/period/PeriodItemNew";
    import SubmitButton from "@/components/app/AppButton/AppButtonSubmit";
    import AppConfirmationDialog from '@/components/app/form/AppConfirmationDialog';
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {toast} from "@/shared/plugins/toastr";
    import {updateSystemPeriodList} from "@/services/pricing";
    import {C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_E, C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_NEW} from "@/shared/component_permission";

    export default {
        name: "SalesPeriods",
        components: {
            PeriodItem,
            PeriodItemNew,
            SubmitButton,
            AppConfirmationDialog
        },
        mixins: [getErrorMessage],
        data() {
            return {
                C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_E,
                C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_NEW,
                periods_data_list: [],
                loading_add_new: false,
                confirmation_dialog_state: false,
                saving: false,
            }
        },
        props: {
            salesPeriod: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        watch: {
            'salesPeriod.sales_periods': {
                immediate: true,
                deep: true,
                handler(periods) {
                    if (periods && periods.length) {
                        this.periods_data_list = JSON.parse(JSON.stringify(periods))
                        this.periods_data_list.forEach(period_data => {
                            this.$set(period_data, 'deleted', 0)
                        })
                    }
                }
            }
        },
        methods: {
            addPeriod(period) {
                this.loading_add_new = true
                let period_id = 'index_' + this.periods_data_list.length
                this.periods_data_list.push({
                    id: period_id,
                    period_start: period.start,
                    period_end: period.end,
                    deleted: 0
                })
                this.$nextTick(() => this.loading_add_new = false)
            },
            removePeriod(period) {
                let index = this.periods_data_list.findIndex(element => element.id === period.id)
                if (index > -1) {
                    this.$set(this.periods_data_list[index], 'deleted', 1)
                }
            },
            save() {
                this.saving = true
                let period_list = []
                if (this.$refs.period_items) {
                    this.$refs.period_items.forEach(period_item => {
                        let period_data = period_item.$data.selectedPeriod
                        let periodObject = {
                            id: period_data.id,
                            period_start: period_data.range.start,
                            period_end: period_data.range.end,
                            deleted: period_data.deleted
                        }
                        if (isNaN(period_data.id)) {
                            if (period_data.deleted === 0) {
                                period_list.push(periodObject)
                            }
                        } else {
                            period_list.push(periodObject)
                        }
                    })
                }
                updateSystemPeriodList(this.salesPeriod.id, {period_list: JSON.stringify(period_list)})
                    .then(response => {
                            this.$emit("periodSaved", response.data)
                            toast({
                                'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                                'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                                'type': 'success',
                                'timeout': 5000
                            })
                        },
                        error => {
                            this.showErrorMessages(error)
                        }).finally(() => {
                    this.saving = false
                    this.confirmation_dialog_state = false
                })
            }
        }
    }
</script>

<style scoped>
    .table-wrapper {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }
    .table-header-period-range {
        width: 75%;
    }
    .table-header-actions {
        width: 25%;
    }
</style>
