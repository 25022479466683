<template>

    <b-card>
        <div class="header-2 mb-4">{{ $t("INVOICES_FOR_CHECK") }}</div>
        <div v-if="showData">
            <label>{{ $t("NO_DATA_FOR_PREVIEW") }}</label>
        </div>
        <div v-else>
            <div>
                <div class="d-flex justify-content-start align-items-center mb-4" v-if="loading">
                    <b-spinner variant="primary"></b-spinner>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-column justify-content-between">
                        <label>{{ $t("TOTAL_NON_FISCAL") }}</label>
                    </div>
                    <b-link @click="open(1)"
                            :to="{name: routes.RN_INVOICE_LIST, query:{
                               invoice_type: [INVOICE_TYPE_FISCAL],
                               status: INVOICE_STATUS_CREATED,
                                }}"
                            target= '_blank'
                    >{{ non_fiscal }}
                    </b-link>
                </div>

            </div>
            <div>
                <div class="d-flex justify-content-start align-items-center" v-if="loading">
                    <b-spinner variant="primary"></b-spinner>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-column justify-content-between">
                        <label>{{ $t("TOTAL_PROCESSING_INVOICES") }}</label>
                    </div>
                    <b-link @click="open(2)"
                            :to="{name: routes.RN_INVOICE_LIST, query:{
                               status: INVOICE_STATUS_PROCESSING,
                                }}"
                            target= '_blank'
                    >{{ processing }}
                    </b-link>
                </div>
            </div>
            <div>
                <div class="d-flex justify-content-start align-items-center" v-if="loading">
                    <b-spinner variant="primary"></b-spinner>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-column justify-content-between">
                        <label>{{ $t("TOTAL_UNPROCESSING_INVOICES") }}</label>
                    </div>
                    <b-link @click="open(3)"
                            :to="{name: routes.RN_INVOICE_LIST, query:{
                               status: INVOICE_STATUS_CREATED,
                                }}"
                            target= '_blank'
                    >{{ unprocessed }}
                    </b-link>
                </div>
            </div>
        </div>
    </b-card>

</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";
import routeNames from "@/router/routeNames";
import {
    INVOICE_STATUS_CREATED,
    INVOICE_STATUS_PROCESSING, INVOICE_TYPE_FISCAL
} from "@/shared/constants";
import {getInvoiceForCheckCount} from "@/services/dashboard";
import routes from "@/router/routeNames";

export default {
    name: "DashboardInvoiceCheck",
    components: {AppButton},
    props: {
        company: {
            type: Number,
        }
    },
    data() {
        return {
            routes: routes,
            INVOICE_TYPE_FISCAL,
            INVOICE_STATUS_PROCESSING,
            INVOICE_STATUS_CREATED,
            loading: false,
            non_fiscal: 0,
            processing: 0,
            unprocessed: 0,
        }
    },
    methods: {
        open(value) {
            if (value === 1) {
                this.$router.replace({
                    name: routeNames.RN_INVOICE_LIST,
                    query: {
                        invoice_type: INVOICE_TYPE_FISCAL,
                        status: [INVOICE_STATUS_CREATED],
                    }
                })
            } else if (value === 2) {
                this.$router.replace({
                    name: routeNames.RN_INVOICE_LIST,
                    query: {
                        status: [INVOICE_STATUS_PROCESSING],
                    }
                })
            } else if (value === 3) {
                this.$router.replace({
                    name: routeNames.RN_INVOICE_LIST,
                    query: {
                        status: [INVOICE_STATUS_CREATED],
                    }
                })
            }
        },
        getData() {

            this.loading = true
            let reqFiscal = {
                company: this.company,
                invoice_type: INVOICE_TYPE_FISCAL,
                status: INVOICE_STATUS_CREATED,
            }
            getInvoiceForCheckCount(reqFiscal).then(response => {
                this.non_fiscal = response.data.total
            })

            let reqProcessing = {
                company: this.company,
                status: INVOICE_STATUS_PROCESSING,
            }
            getInvoiceForCheckCount(reqProcessing).then(response => {
                this.processing = response.data.total
            })

            let reqUnProcessed = {
                company: this.company,
                status: INVOICE_STATUS_CREATED,
            }
            getInvoiceForCheckCount(reqUnProcessed).then(response => {
                this.unprocessed = response.data.total
                this.loading = false
            })
        }
    },
    computed: {
        showData() {
            return this.non_fiscal === 0 && this.processing === 0 && this.unprocessed === 0
        }
    },
    mounted() {

        this.getData()
    },
}
</script>

<style scoped>

</style>
