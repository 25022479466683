<template>
    <b-card class="main-section-card">
        <div slot="header">
            <h2 >{{ $t("TRANSFERS") }}</h2>
        </div>
        <transfer-list-filter></transfer-list-filter>
        <b-row>
            <b-col>
                <transfer-list-table></transfer-list-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
    import TransferListTable from "@/components/transfer/list/TransferListTable";
    import TransferListFilter from "@/components/transfer/list/TransferListFilter";
    export default {
        name: "Index",
        components: {TransferListFilter, TransferListTable}
    }
</script>

<style scoped>

</style>
