<template>
    <div>
        <app-access-control tag="div" @AccessControlEvent="addToAccessControlCounter()"
                            :access_control_context="{function:C_UNIT_DAMAGE_DEPOSIT_E, key: AC_UNIT_DAMAGE_DEPOSIT}">
            <template v-slot:default="props">
        <form-header>
            <b-tooltip v-if="props.messages.length"
                       class="mb-5"
                       triggers="hover"
                       :title="props.messages[0].message" :target="'key_'+C_UNIT_DAMAGE_DEPOSIT_E">
            </b-tooltip>
            <span :id="'key_'+C_UNIT_DAMAGE_DEPOSIT_E" class="d-flex">
            <b-checkbox :unchecked-value="false" :value="true" v-model="appChecked" @change="deleteDamageDeposit"
                        switch :disabled="props.disabled">{{$t('DMG_DEPOSIT')}}
            </b-checkbox>
            </span>
        </form-header>




        <div v-if="appChecked">
            <b-form @submit.prevent="saveDamageDeposit">
                <b-row>
                    <b-col md="4" class="mb-4">
                        <label>{{data.collectPaymentType.label}}</label>
                        <app-select v-model="data.collectPaymentType.value" text-field="value"
                                    :options="data.collectPaymentType.list"></app-select>
                    </b-col>
                    <b-col md="4" class="mb-4">
                        <label>{{data.collectPaymentEvent.label}}</label>
                        <app-select v-model="data.collectPaymentEvent.value" text-field="value"
                                    :options="data.collectPaymentEvent.list"></app-select>
                    </b-col>
                    <b-col md="4" class="mb-4">
                        <b-form-group>
                            <label>{{$t('AMOUNT')}}</label>
                            <b-form-input v-model="data.amount" type="text"
                                          id="dmgDepositAmount"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="4" class="mb-4">
                        <label>{{data.returnPaymentType.label}}</label>
                        <app-select v-model="data.returnPaymentType.value" text-field="value"
                                    :options="data.returnPaymentType.list"></app-select>
                    </b-col>
                    <b-col md="4" class="mb-4">
                        <label>{{data.returnPaymentEvent.label}}</label>
                        <app-select v-model="data.returnPaymentEvent.value" text-field="value"
                                    :options="data.returnPaymentEvent.list"></app-select>
                    </b-col>
                </b-row>
               <dmg-deposit-submit  :disabled="props.disabled" class="mb-2"></dmg-deposit-submit>
            </b-form>
        </div>
            </template>
        </app-access-control>
    </div>
</template>
<script>
    import {
        ACTION_SAVE_UNIT_DAMAGE_DEPOSIT,
        ACTION_DELETE_UNIT_DAMAGE_DEPOSIT
    } from "@/store/store_types";
    import DmgDepositSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {cloneDeep} from 'lodash'
    import ValidationDescription from '@/components/app/form/AppValidationDescription'
    import {required} from 'vuelidate/lib/validators'
    import {C_UNIT_DAMAGE_DEPOSIT_E} from "@/shared/component_permission";
    import FormHeader from "@/components/app/form/FormHeader";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {AC_UNIT_DAMAGE_DEPOSIT} from "@/mixins/AccessControl/AccessControlEnumeration";
    export default {
        name: "Index",
        data() {
            return {
                appChecked: null,
                C_UNIT_DAMAGE_DEPOSIT_E,
                data: null,
                AC_UNIT_DAMAGE_DEPOSIT,
                access_control_fetch_key:AC_UNIT_DAMAGE_DEPOSIT
            }
        },
        props:{
          damageDeposit:{
              type:Object
          }
        },
        mixins: [getErrorMessage,AccessControlComponent],
        components: {
            AppAccessControl,
            AppSelect,
            FormHeader,
            DmgDepositSubmit,
            ValidationDescription
        },
        methods: {
            saveDamageDeposit() {

                this.$v.$touch();
                if ((this.$v.$error)) {
                    toast({
                        'title': "",
                        'message': this.$t('VALIDATION_FAILED'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return
                }


                this.$store.dispatch(ACTION_SAVE_UNIT_DAMAGE_DEPOSIT, {
                    id: this.$route.params.unit_id,
                    payload: {
                        collect_payment_type: this.data.collectPaymentType.value,
                        collect_payment_event_value: this.data.collectPaymentEvent.value,
                        return_payment_type: this.data.returnPaymentType.value,
                        return_payment_event_value: this.data.returnPaymentEvent.value,
                        amount: this.data.amount,
                    }
                }).then(response => {

                    this.appChecked = response.data.id !== null
                    this.data = cloneDeep(response.data)
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                })
            },
            deleteDamageDeposit(val) {

                if (!val && this.data.id !== null) {
                    this.$store.dispatch(ACTION_DELETE_UNIT_DAMAGE_DEPOSIT, this.$route.params.unit_id).then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })
                        this.appChecked = false
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                        this.appChecked = false
                    })
                }
            }
        },
        watch: {
            damageDeposit:{
                handler(object){
                    this.data = cloneDeep(object)
                    if(object && object.id !== null){
                        this.access_control_components = 2
                        this.appChecked = true
                    } else {
                        this.access_control_components = 1
                        this.appChecked = false
                    }
                },
                immediate:true
            },
            '$route.params.unit_id': {
                handler(value) {

                    this.access_control_general_context = {
                        unit: value,
                        company: this.$store.getters['user/getCompany']
                    }
                },
                immediate: true
            }

        },
        validations: {
            data: {
                collectPaymentType: {
                    value: {
                        required
                    }
                },
                collectPaymentEvent: {
                    value: {
                        required
                    }
                },
                returnPaymentType: {
                    value: {
                        required
                    }
                },
                returnPaymentEvent: {
                    value: {
                        required
                    }
                },
                amount: {
                    required
                }
            }

        },

    }
</script>
