<template>
    <div v-if="hasPermission">
        <b-table responsive hover :fields="fields" :items="fee_list" :sort-by="sortBy" no-sort-reset>
            <template v-slot:fee.charge_type="data">
                {{data.item.fee.charge_type ? chargeType(data.item.fee.charge_type).label : ''}}
            </template>

            <template v-slot:fee.included_in_price="data">
                <i v-if="data.item.fee.included_in_price > 0" class="fa fa-check" aria-hidden="true"></i>
                <i v-else-if="data.item.fee.included_in_price === 0" class="fa fa-times" aria-hidden="true"></i>
            </template>
            <template v-slot:fee.amount="data">
                <div v-if="data.item.fee.amount">
                    {{data.item.fee.amount}}
                    {{data.item.fee.is_fixed}}
                    <template v-if="chargeType(data.item.fee.charge_type).is_fixed">
                        {{getCompanyInfo.working_currency.name}}
                    </template>
                    <template v-else-if="!chargeType(data.item.fee.charge_type).is_fixed">
                        %
                    </template>

                </div>
            </template>

            <template v-slot:edit="data">

                <app-edit       :access_control_context="{function:C_UNIT_FEE_E,key:AC_UNIT_FEES}"
                                @AccessControlEvent="addToAccessControlCounter()" variant="link" :show_text="false" button_type="edit" :c_permission="c_permission_e"
                          @click="editUnitFee(data.item)"></app-edit>
            </template>
        </b-table>
        <app-aside v-model="feeAsidePanelState">
            <template slot="header">{{$t('ADDITIONAL_FEE')}}</template>
            <FeeForm @feeSaved="updateList" :item="this.feeItemEditing" :charge_type_list="charge_type_list"
            ></FeeForm>
        </app-aside>
    </div>


</template>

<script>
    import FeeForm from '@/components/unit/fee/UnitFeeForm'
    import AppAside from '@/components/app/form/AppAside'
    import AppEdit from '@/components/app/AppButton/AppButton'
    import {C_UNIT_FEE_V, C_UNIT_FEE_E} from "@/shared/component_permission";
    import {getUnitFeeList} from '@/services/unit/index'
    import {GET_UNIT_ITEM} from "@/store/store_types";
    import {AC_UNIT_FEES} from "@/mixins/AccessControl/AccessControlEnumeration";
    import AppAccessControl from "@/components/app/AppAccessControl";

    export default {
        name: "Index",
        mixins:[AppAccessControl],
        data() {
            return {
                sortBy: 'label',
                sortDesc: false,
                fields: [
                    {key: 'label', label: this.$t('TYPE')},
                    {key: 'fee.included_in_price', label: this.$t('INCLUDED_IN_PRICE')},
                    {key: 'fee.charge_type', label: this.$t('PAYMENT_TYPE')},
                    {key: 'fee.amount', label: this.$t('AMOUNT')},
                    {key: 'edit', label: '', tdClass: 'text-right'},
                ],
                fee_list: [],
                charge_type_list: [],
                data_loaded: false,
                feeItemEditing: null,
                feeAsidePanelState: false,
                c_permission_e: C_UNIT_FEE_E,
                C_UNIT_FEE_E,
                AC_UNIT_FEES,
                access_control_fetch_key:AC_UNIT_FEES
            }
        },
        components: {
            AppAside,
            FeeForm,
            AppEdit
        },
        computed: {

            getCompanyInfo() {
                return this.$store.getters[GET_UNIT_ITEM]('basic.company')
            },
            hasPermission() {
                return this.$store.getters['user/getPermission'](C_UNIT_FEE_V)
            },
        },
        methods: {
            updateList() {
                this.feeAsidePanelState = false
                this.getFeeList(Number(this.$route.params.unit_id))
            },
            editUnitFee(id) {
                this.feeAsidePanelState = true;
                this.feeItemEditing = id;
            },
            chargeType(key) {
                return this.charge_type_list.find(data => data.key === key);
            },
            getFeeList(unitId) {
                getUnitFeeList(unitId).then(response => {
                    this.fee_list = response.data.feeList
                    this.charge_type_list = response.data.chargeTypeList
                    this.access_control_components = this.fee_list.length
                })
            }
        },

        watch: {
            '$route.params.unit_id': {
                handler(val) {
                    this.access_control_general_context = {
                        unit: val,
                        company: this.$store.getters['user/getCompany']
                    }
                    this.getFeeList(val)
                },
                immediate: true
            }
        }
    }
</script>
<style>
</style>
