<template>
    <div>
        <component v-if="customSetupDistributions.includes(distribution.id)" :is="'Distribution'+distribution.id+'SetupForm'"
                   @updateParameter="save" :distribution="distribution" :setupParameter="setupParameter" :unitList="unitList"
                   :save_loading="save_loading">
        </component>
    </div>

</template>

<script>
    import {EXPEDIA, BOOKING} from "@/shared/constants";
    import ExpediaSetupForm from "@/components/channel_manager/setup/form_per_distribution/ExpediaSetupForm";
    import BookingSetupForm from "@/components/channel_manager/setup/form_per_distribution/BookingSetupForm";
    import {updateSetupParameters} from "@/services/channel_manager";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";

    export default {
        name: "PropertySetupDistributionForm",
        components: {
            'Distribution3SetupForm':ExpediaSetupForm,
            'Distribution4SetupForm':BookingSetupForm,
        },
        mixins: [getErrorMessage],
        props:{
            distribution:{
                type:Object
            },
            unitList:{
                type:Array
            },
            setupParameter:{
                type:Object
            }
        },
        data(){
            return {
                save_loading:false,
                customSetupDistributions:[
                    EXPEDIA,
                    BOOKING,
                ]
            }
        },
        methods:{
            save(request){
                this.save_loading = true
                updateSetupParameters(request).then(() => {
                    notifySuccess();
                }, error =>{
                    this.showErrorMessages(error)
                }).finally(()=>{
                    this.save_loading = false
                })
            },
        }
    }
</script>

<style scoped>

</style>
