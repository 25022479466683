<template>
    <component v-bind="$attrs" :is="tag" v-if="isVisible" >
        <slot :disabled="isDisabled" :messages="messages">
        </slot>
    </component>
</template>

<script>
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";

    export default {
        name: "app-access-control",
        mixins: [AccessControlComponent],
        props: {
            access_control_context: {
                type: Object,
                default: null
            },

            tag:{
                type: String,
                default: 'b-col'
            }
        },
        computed: {
            isVisible() {
                return this.AccessControlVisible
            },
            isDisabled(){
                return this.AccessControlDisabled
            },
            messages() {
                return this.AccessControlMessage
            }
        },
        watch: {
            isVisible() {
                this.$emit('updated', {visible: this.isVisible, disabled: this.isDisabled, messages: this.messages})
            }
        }
    }
</script>

<style scoped>

</style>
