<template>
    <div>
        <unit-filter v-if="table" v-show="property.unit_count_list.total_count > 0" :property_id="property.id"></unit-filter>
        <unit-list-table @ready="table=true" :search-on-create="true"></unit-list-table>
        <app-aside :widths="['col-sm-12', '40%']" v-model="aside">
            <template slot="header">{{$t('NEW_UNIT')}}</template>
            <unit-wizard v-if="property" :property="property"></unit-wizard>
        </app-aside>
    </div>
</template>

<script>
    import UnitFilter from '@/components/unit/list/UnitFilter'
    import UnitListTable from '@/components/unit/list/UnitListTable'
    import AppAside from '@/components/app/form/AppAside'
    import UnitWizard from "@/components/unit/new/UnitWizard";
    import {EventBus, GE_OPEN_UNIT_WIZARD} from "@/shared/EventBus";

    export default {
        name: "Index",
        components: {
            UnitWizard, UnitFilter, UnitListTable, AppAside
        },
        data() {
            return {
                aside: false,
                table: false
            }
        },
        computed: {
            property() {
                return this.$store.getters['property/getProperty']
            }
        },
        mounted() {
            EventBus.$on(GE_OPEN_UNIT_WIZARD, () => {
                this.aside = true
            })
        }
    }
</script>

<style scoped>

</style>
