<template>
    <div v-if="formLoading">
        <b-spinner
            variant="primary">
        </b-spinner>
    </div>
    <div v-else class="text-muted">
        <template v-if="singleProperty && sameLosOccupancy">
            <form @submit.prevent="save(false)"
                  v-if="rateItems.hasOwnProperty(unitId) && Object.keys(rateItems[unitId]).length > 0">
                <b-row>
                    <b-col>
                        <div v-if="singleUnit && lockVisible && !(los.length > 1 || occupancy.length > 1)"
                             class="action_button pointer mb-4" style="cursor: pointer!important"
                             @click="lockPanelState = true">
                            {{ $t("LOCK") }}
                        </div>
                        <span v-if="singleUnit && lockVisible && (los.length > 1 || occupancy.length > 1)"
                              class="action_button pointer mb-4" style="cursor: pointer!important"
                              @click="lockPanelState = true">
                            {{ $t("LOCK") }}
                        </span>

                        <b-form-checkbox
                            v-if="sameLosOccupancyRelation && (los.length > 1 || occupancy.length > 1)"
                            v-model="calculateRates"
                            class="pull-right mb-4"
                            switch
                            inline
                            id="enable_calculate"
                            name="enable_calculate"
                            :value="true"
                            :unchecked-value="false">
                            {{ $t("APPLY_INCREASE_DECREASE") }}
                        </b-form-checkbox>
                    </b-col>
                </b-row>

                <table style="width: 100%" class="table">
                    <thead>
                    <tr>
                        <th v-if="los.length > 1">{{ $t("LENGTH_OF_STAY") }}</th>
                        <th v-if="occupancy.length >1">{{ $t("OCCUPANY_PERSONS") }}</th>
                        <th class="text-center">{{ $t("PRICE") }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="losItem in los">
                        <tr :key="losItem.id + '_' + occupancyItem.id"
                            v-for="occupancyItem in occupancy">
                            <td v-if="los.length > 1"
                                :class="sameDefaultLosOccupancy && losItem.default && occupancyItem.default ? 'bg-light' : ''">
                                {{ losItem.min_stay }}
                                <span
                                    v-if="losItem.max_stay && losItem.max_stay > losItem.min_stay"> - {{
                                        losItem.max_stay
                                    }}
                                        </span>
                                <span v-else-if="!losItem.max_stay">+</span>
                            </td>
                            <td v-if="occupancy.length > 1"
                                :class="sameDefaultLosOccupancy && losItem.default && occupancyItem.default ? 'bg-light' : ''">
                                {{ occupancyItem.min_guests }}
                                <span
                                    v-if="occupancyItem.max_guests > occupancyItem.min_guests"> - {{
                                        occupancyItem.max_guests
                                    }}
                                        </span>
                            </td>
                            <td class="mb-0 mt-0 pb-2 pt-2"
                                v-if="_has(rateItems[unitId], `${losItem.id}.${occupancyItem.id}`)">
                                <app-number-input
                                    :min="1"
                                    v-if="losItem.default === 1 && occupancyItem.default === 1"
                                    :prepend="currency"
                                    @change="calculateRateDependencyList"
                                    v-model="rateItems[unitId][losItem.id][occupancyItem.id]">
                                </app-number-input>
                                <app-number-input
                                    :min="1"
                                    v-else
                                    :prepend="currency"
                                    v-model="rateItems[unitId][losItem.id][occupancyItem.id]">
                                </app-number-input>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>

                <b-row class="pt-3 mb-4" v-if="checkPermission(C_PROPERTY_CALENDAR_PRICES_E)">
                    <b-col class="d-flex justify-content-between">
                        <app-button
                            @click="lockModal = true"
                            v-if="lockVisible"
                            :disabled="saveDisabled">
                            {{ $t("SAVE") }}
                        </app-button>
                        <app-button-submit
                            v-else
                            :disabled="saveDisabled"
                            :loading="saving">
                        </app-button-submit>
                    </b-col>

                </b-row>
            </form>
        </template>
        <template v-else>
            <form @submit.prevent="saveRatesForAllLosesOccupancies">
                <div>
                    <b-row>
                        <b-col lg="12" md="12" class="mb-4">
                            <label>{{ $t("SET_PRICE_FOR_ALL_LOSES_OCCUPANCIES") }}</label>
                            <app-number-input :min="1"
                                              :prepend="currency"
                                              v-model="rate">
                            </app-number-input>
                        </b-col>
                    </b-row>
                    <b-row class="mb-4">
                        <b-col>
                            <app-button-submit
                                :loading="saving"
                                :disabled="saveDisabled">
                            </app-button-submit>
                        </b-col>
                    </b-row>
                </div>
            </form>
        </template>
        <hr class="horizontal-line">
        <b-form @submit.prevent="saveRelativePrice">
            <div>
                <b-row>
                    <b-col lg="12" md="12" class="mb-4">
                        <label>{{ $t("INCREASE_DECREASE_CURRENT_AMOUNT_BY") }}</label>
                        <b-input-group>
                            <b-input
                                type="number"
                                v-model="value">
                            </b-input>
                            <b-input-group-append>
                                <app-select
                                    v-model="relativeValueType"
                                    :options="relativeValueTypeOptions"
                                    :search-empty-item="false"
                                    value-field="value"
                                    text-field="text">
                                </app-select>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <app-button-submit
                            :disabled="saveRelativePriceDisabled"
                            :loading="savingRelative">
                        </app-button-submit>
                    </b-col>
                </b-row>
            </div>
        </b-form>

        <app-aside v-model="lockPanelState" full-width :widths="['col-lg-12']" v-if="singleUnit && unit">
            <template v-slot:header>
                <div class="property-wrapper mb-0 no-border">
                    <div class="card-header">
                        <div class="header-caption">{{ $t("LOCK") }}</div>
                        <h2 class="property-wrapper-padding mb-2">
                            {{ unit.name }}
                        </h2>
                    </div>
                </div>
            </template>
            <los-lock-form :unit_id="unit.id" :los-list="los" :occupancy-list="occupancy"></los-lock-form>
        </app-aside>

        <b-modal :title="$t('LOCK_CONFIRMATION')" v-model="lockModal" v-if="singleUnit && unit">
            <b-form>
                <b-row class="mb-2">
                    <b-col lg="12" class="mb-4">
                        <label class="label">{{ $t("LOCK_UNTIL") }}</label>
                        <app-date-picker :min-date="new Date()" v-model="lockUntil"></app-date-picker>
                    </b-col>
                </b-row>
            </b-form>
            <template v-slot:modal-footer>
                <app-button
                    :loading="saving"
                    @click="save(false)">
                    {{ $t("SAVE") }}
                </app-button>
                <app-button
                    :loading="savingWithLock"
                    @click="save(true)"
                    class="ml-3">
                    {{ $t("SAVE_AND_LOCK") }}
                </app-button>
            </template>
        </b-modal>

    </div>
</template>

<script>
import {cloneDeep, each, has, isEqual} from "lodash";
import {AC_CALENDAR_EDIT} from "@/mixins/AccessControl/AccessControlEnumeration";
import {C_PROPERTY_CALENDAR_PRICES_E, C_REVENUE_MANAGER_LOCK_PRICE_V} from "@/shared/component_permission";
import {fetchAccessControlData} from "@/services/access";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import AppButton from "@/components/app/AppButton/AppButton";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppAside from "@/components/app/form/AppAside";
import LosLockForm from "@/components/logs/LosLockForm";
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import {notifySuccess, toast} from "@/shared/plugins/toastr";
import {saveGroupPricesRelative, savePriceForAllLosesAndOccupancies, setUnitPrice} from "@/services/unit";
import {
    EventBus,
    GE_CALENDAR_LOAD_PRICES,
    GE_CALENDAR_REFRESH_DATA
} from "@/shared/EventBus";
import {setPriceLock} from "@/services/pricing";
import {FIX, PERC} from "@/shared/constants";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";

export default {
    name: "CalendarEditRatesForm",
    components: {AppDatePicker, LosLockForm, AppButtonSubmit, AppButton, AppNumberInput, AppSelect, AppAside,},
    mixins: [getErrorMessage],
    props: {
        unit: {
            type: Object,
        },
        selectedUnitList: {
            type: Array,
            default: () => {
                return []
            }
        },
        unitList: {
            type: Array,
            default: () => {
                return []
            }
        },
        losList: {
            type: Object,
            default: () => {
                return null
            },
        },
        occupancyList: {
            type: Object,
            default: () => {
                return null
            },
        },
        periods: {
            type: Array,
            default: () => {
                return []
            },
        },
        allowedWeekday: {
            type: Array,
            default: () => {
                return []
            },
        },
        rates: {
            type: Array,
            default: () => {
                return []
            },
        },
        defaultValues: {
            type: Object,
            default: () => {
                return null
            },
        },
        singleUnit: {
            type: Boolean,
            default: true,
        },
        singleProperty: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            rateItems: {},
            initialRateItems: {},
            rate: null,
            value: null,
            relativeValueType: PERC,
            relativeValueTypeOptions: [
                {value: PERC, text: '%'},
                {value: FIX, text: 'fix'},
            ],
            unitId: null,
            applyToUnitList: [],
            calculateRates: false,
            formLoading: false,
            saving: false,
            savingWithLock: false,
            savingRelative: false,
            lockVisible: false,
            lockPanelState: false,
            lockUntil: null,
            lockModal: false,
            C_PROPERTY_CALENDAR_PRICES_E,
        }
    },
    computed: {
        los() {
            if (!this.sameLosOccupancy) {
                return []
            }

            let unitIdList = this.losList ? Object.keys(this.losList) : []
            const unitId = this.unitId ? this.unitId : (unitIdList.length > 0 ? unitIdList[0] : null)
            return this.losList[unitId]
        },
        occupancy() {
            if (!this.sameLosOccupancy) {
                return []
            }

            let unitIdList = this.losList ? Object.keys(this.losList) : []
            const unitId = this.unitId ? this.unitId : (unitIdList.length > 0 ? unitIdList[0] : null)
            return this.occupancyList[unitId]
        },
        sameDefaultLosOccupancy() {
            if (this.singleUnit) {
                return true
            }
            if (!this.sameLosOccupancy) {
                return false
            }
            let unitIdList = this.losList ? Object.keys(this.losList) : []
            if (unitIdList.length === 0 || unitIdList.length !== this.selectedUnitList.length) {
                return false
            }
            const firstUnitId = unitIdList[0]
            const compareAgainstLos = this.losList[firstUnitId].find(los => los.default === 1)
            const compareAgainstOccupancy = this.occupancyList && this.occupancyList.hasOwnProperty(firstUnitId) ? this.occupancyList[firstUnitId].find(occupancy => occupancy.default === 1) : null
            if (!compareAgainstLos || !compareAgainstOccupancy) {
                return false
            }
            let same = true
            for (const unitId in this.losList) {
                if (unitId !== firstUnitId) {
                    const los = this.losList[unitId].find(losItem => losItem.default === 1)
                    const occupancy = this.occupancyList.hasOwnProperty(unitId) ? this.occupancyList[unitId].find(occupancyItem => occupancyItem.default === 1) : null
                    if (
                        !los || !occupancy ||
                        compareAgainstLos.min_stay !== los.min_stay || compareAgainstLos.max_stay !== los.max_stay ||
                        compareAgainstOccupancy.min_guests !== occupancy.min_guests || compareAgainstOccupancy.max_guests !== occupancy.max_guests
                    ) {
                        same = false
                    }
                    if (!same) {
                        break
                    }
                }
            }
            return same
        },
        sameLosOccupancy() {
            if (this.singleUnit) {
                return true
            }
            if (!this.singleProperty) {
                return false
            }
            let unitIdList = this.losList ? Object.keys(this.losList) : []
            if (unitIdList.length === 0 || unitIdList.length !== this.selectedUnitList.length) {
                return false
            }
            const firstUnitId = unitIdList[0]
            const compareAgainstLos = this.losList[firstUnitId]
            const compareAgainstOccupancy = this.occupancyList && this.occupancyList.hasOwnProperty(firstUnitId) ? this.occupancyList[firstUnitId] : null
            if (!compareAgainstOccupancy) {
                return false
            }
            let same = true
            for (const unitId in this.losList) {
                if (unitId !== firstUnitId) {
                    const los = this.losList[unitId]
                    const occupancy = this.occupancyList.hasOwnProperty(unitId) ? this.occupancyList[unitId] : null
                    if (compareAgainstLos.length !== los.length || !occupancy || occupancy.length !== compareAgainstOccupancy.length) {
                        same = false
                    }
                    if (!same) {
                        break
                    }
                    for (let i = 0; i < compareAgainstLos.length; i++) {
                        let match = false
                        for (let j = 0; j < los.length; j++) {
                            if (match) {
                                continue
                            }
                            if (compareAgainstLos[i].min_stay === los[j].min_stay && compareAgainstLos[i].max_stay === los[j].max_stay) {
                                match = true
                            }
                        }
                        if (!match) {
                            same = false
                            break
                        }
                    }
                    if (!same) {
                        break
                    }
                    for (let i = 0; i < compareAgainstOccupancy.length; i++) {
                        let match = false
                        for (let j = 0; j < occupancy.length; j++) {
                            if (match) {
                                continue
                            }
                            if (compareAgainstOccupancy[i].min_guests === occupancy[j].min_guests && compareAgainstOccupancy[i].max_guests === occupancy[j].max_guests) {
                                match = true
                            }
                        }
                        if (!match) {
                            same = false
                            break
                        }
                    }
                    if (!same) {
                        break
                    }
                }
            }
            return same
        },
        sameLosOccupancyRelation() {
            if (this.singleUnit) {
                return true
            }
            if (!this.sameLosOccupancy) {
                return false
            }
            let unitIdList = Object.keys(this.losList)
            const firstUnitId = unitIdList[0]
            const compareAgainstLos = this.losList[firstUnitId]
            const compareAgainstOccupancy = this.occupancyList[firstUnitId]
            for (const unitId in this.losList) {
                if (unitId !== firstUnitId) {
                    const los = this.losList[unitId]
                    const occupancy = this.occupancyList[unitId]
                    for (let i = 0; i < compareAgainstLos.length; i++) {
                        let match = false
                        for (let j = 0; j < los.length; j++) {
                            if (match) {
                                continue
                            }
                            if (compareAgainstLos[i].min_stay === los[j].min_stay && compareAgainstLos[i].max_stay === los[j].max_stay && ((compareAgainstLos[i].default === los[j].default && los[j].default === 1) || (compareAgainstLos[i].discount === los[j].discount && compareAgainstLos[i].discount_type === los[j].discount_type))) {
                                match = true
                            }
                        }
                        if (!match) {
                            return false
                        }
                    }
                    for (let i = 0; i < compareAgainstOccupancy.length; i++) {
                        let match = false
                        for (let j = 0; j < occupancy.length; j++) {
                            if (match) {
                                continue
                            }
                            if (compareAgainstOccupancy[i].min_guests === occupancy[j].min_guests && ((compareAgainstOccupancy[i].default === occupancy[j].default && occupancy[j].default === 1) || (compareAgainstOccupancy[i].discount === occupancy[j].discount && compareAgainstOccupancy[i].discount_type === occupancy[j].discount_type))) {
                                match = true
                            }
                        }
                        if (!match) {
                            return false
                        }
                    }
                }
            }
            return true
        },
        unitOptions() {
            if (this.selectedUnitList.length < 2) {
                return []
            }
            let list = []
            this.selectedUnitList.forEach(unitId => {
                const unit = this.unitList.find(unit => {
                    return unit.id === unitId
                })
                if (unit) {
                    list.push({
                        id: unit.id,
                        name: unit.name,
                    })
                }
            })
            return list
        },
        unitOptionsExcludingSelected() {
            return this.unitOptions.filter(unit => unit.id !== this.unitId)
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        currency() {
            return this.company.hasOwnProperty('working_currency') ? this.company.working_currency.code : null
        },
        saveDisabled() {
            if (this.allowedWeekday.length === 0 || this.periods.length === 0) {
                return true
            }
            if (this.sameLosOccupancy) {
                if (isEqual(this.rateItems[this.unitId], this.initialRateItems[this.unitId])) {
                    return true
                }
                let valid = true
                const rateItems = this.rateItems[this.unitId]
                Object.keys(rateItems).forEach(losId => {
                    Object.keys(rateItems[losId]).forEach(occupancyId => {
                        if (rateItems[losId][occupancyId] === null || isNaN(rateItems[losId][occupancyId])) {
                            valid = false
                        }
                    })
                })
                if (!valid) {
                    return true
                }
            } else {
                if (this.rate === null) {
                    return true
                }
            }

            return false
        },
        saveRelativePriceDisabled() {
            return this.value === null || this.periods.length === 0 || this.selectedUnitList.length === 0 || this.allowedWeekday.length === 0
        },
        defaultRate() {
            if (!this.los || !this.occupancy) {
                return null
            }

            let defaultLos = this.los.find(el => {
                return el.default
            })

            let defaultOccupancy = this.occupancy.find(el => {
                return el.default
            })
            let rate = {
                los: defaultLos,
                occupancy: defaultOccupancy,
                amount: 0
            }

            if (has(this.rateItems[this.unitId], [defaultLos.id, defaultOccupancy.id])) {
                rate.amount = this.rateItems[this.unitId][defaultLos.id][defaultOccupancy.id]
            }

            return rate
        },
    },
    watch: {
        singleUnit: {
            handler(value) {
                this.setApplyToUnitList()
                this.setLockOptionVisible()
                this.setRateItems()
                if (value) {
                    this.calculateRates = true
                }
            },
            immediate: true,
        },
        losList: {
            handler() {
                this.setRateItems()
            },
            deep: true,
        },
        occupancyList: {
            handler() {
                this.setRateItems()
            },
            deep: true,
        },
        rates: {
            handler() {
                if (!this.singleUnit) {
                    this.setRateItems()
                }
            },
            deep: true,
            immediate: true,
        },
        defaultValues: {
            handler() {
                if (this.singleUnit) {
                    this.setRateItems()
                }
            },
            deep: true,
            immediate: true,
        },
        unit: {
            handler(value) {
                this.unitId = value && value.hasOwnProperty("id") && value.id ? value.id : null
                /*if (this.unitId) {
                    this.setLockOptionVisible()
                }*/
            },
            deep: true,
            immediate: true,
        },
        unitId: {
            handler() {
                this.setApplyToUnitList()
            },
            immediate: true,
        },
        selectedUnitList: {
            handler() {
                this.setApplyToUnitList()
                this.rate = null
            },
            deep: true,
            immediate: true,
        },
        sameLosOccupancy: {
            handler() {
                this.setApplyToUnitList()
            },
            immediate: true,
        },
        sameLosOccupancyRelation: {
            handler(value) {
                if (!value && !this.singleUnit) {
                    this.calculateRates = false
                } else if (value) {
                    this.calculateRates = true
                }
            },
            immediate: true,
        },
        periods: {
            handler() {
                this.rate = null
            },
            deep: true,
        },
    },
    methods: {
        save(lock) {
            let rates = []
            const rateItems = this.rateItems[this.unitId]
            Object.keys(rateItems).forEach(losId => {
                Object.keys(rateItems[losId]).forEach(occupancyId => {
                    if (Number(rateItems[losId][occupancyId]) > 0) {
                        rates.push({
                            los: losId,
                            occupancy: occupancyId,
                            amount: Number(rateItems[losId][occupancyId])
                        })
                    }
                })
            })

            if (rates.length === 0) {
                toast({
                    "title": this.$t("NOTIFICATIONS.SAVE_ERROR.TITLE"),
                    "message": this.$t("NO_CHANGE"),
                    "type": 'error',
                    "timeout": 6000
                })
                return
            }

            let pricing = []
            this.periods.forEach(period => {
                pricing.push({
                    date_from: period.start,
                    date_to: period.end,
                    pricing: rates
                })
            })

            let request = {
                pricing: pricing,
                allowed_weekday: this.allowedWeekday
            }
            if (!this.singleUnit && this.sameLosOccupancy && this.applyToUnitList.length > 0) {
                request.apply_to_units = this.applyToUnitList
            }

            this.saving = !lock
            this.savingWithLock = lock

            setUnitPrice(this.unit.id, request).then(() => {
                if (lock) {
                    this.saveLock()
                }
                EventBus.$emit(GE_CALENDAR_LOAD_PRICES, {
                    value: null,
                    unitId: this.unit.id
                })
                EventBus.$emit(GE_CALENDAR_REFRESH_DATA, {
                    value: null,
                    unitId: this.unitId
                })

                if (this.applyToUnitList.length > 0 && !this.singleUnit) {
                    this.applyToUnitList.forEach(unitId => {
                        const unit = this.getUnitObject(unitId)
                        if (unit) {
                            EventBus.$emit(GE_CALENDAR_LOAD_PRICES, {
                                unitId: unitId,
                                value: null
                            })
                            EventBus.$emit(GE_CALENDAR_REFRESH_DATA, {
                                unitId: unitId,
                                value: null
                            })
                        }
                    })
                }

                notifySuccess()

                if (request.hasOwnProperty("apply_to_units") && Array.isArray(request.apply_to_units) && request.apply_to_units.length > 0) {
                    request.apply_to_units.forEach(unitId => {
                        for (const losId in this.rateItems[this.unitId]) {
                            if (!this.rateItems[this.unitId].hasOwnProperty(losId)) {
                                continue
                            }
                            const correspondingLosId = this.getCorrespondingLosId(losId, unitId)
                            if (!correspondingLosId) {
                                continue
                            }
                            for (const occupancyId in this.rateItems[this.unitId][losId]) {
                                if (!this.rateItems[this.unitId][losId].hasOwnProperty(occupancyId)) {
                                    continue
                                }
                                const correspondingOccupancyId = this.getCorrespondingOccupancyId(occupancyId, unitId)
                                if (!correspondingOccupancyId) {
                                    continue
                                }
                                this.$set(this.rateItems[unitId][correspondingLosId], correspondingOccupancyId, this.rateItems[this.unitId][losId][occupancyId])
                            }
                        }
                    })
                }
                this.initialRateItems = cloneDeep(this.rateItems)
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.saving = false
                this.savingWithLock = false
                this.lockModal = false
            })
        },
        saveLock() {
            let request = {
                items: [],
            }
            if (this.periods.length > 0) {
                for (const dateObject of this.periods) {
                    for (const losObject of this.los) {
                        for (const occupancyObject of this.occupancy) {
                            let requestObject = {
                                lock_period_start: dateObject.start,
                                lock_period_end: dateObject.end,
                                lock_until_date: this.lockUntil,
                                los: losObject.id,
                                occupancy: occupancyObject.id,
                                set_lock: 1,
                            }
                            request.items.push(requestObject)
                        }
                    }
                }
            }
            setPriceLock(this.unit.id, request).then(() => {
                notifySuccess()
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.lockUntil = null
                this.lockModal = false
            })
        },
        saveRelativePrice() {
            this.savingRelative = true
            const unitIdList = this.singleUnit ? [this.unitId] : this.selectedUnitList
            saveGroupPricesRelative({
                periods: this.periods.map(el => {
                    return {
                        from: el.start,
                        to: el.end
                    }
                }),
                allowed_weekday: this.allowedWeekday,
                value_type: this.relativeValueType,
                value: this.value,
                unit: unitIdList
            }).then(() => {
                this.calculateNewRatesByRelativeChange(this.unitId, this.value, this.relativeValueType)
                this.value = null
                unitIdList.forEach(unitId => {
                    EventBus.$emit(GE_CALENDAR_LOAD_PRICES, {
                        value: null,
                        unitId: unitId
                    })
                })
                notifySuccess()
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.savingRelative = false
            })
        },
        saveRatesForAllLosesOccupancies() {
            this.saving = true
            let request = {
                periods: this.periods.map(el => {
                    return {
                        from: el.start,
                        to: el.end
                    }
                }),
                allowed_weekday: this.allowedWeekday,
                amount: this.rate,
                unit: this.selectedUnitList,
            }
            savePriceForAllLosesAndOccupancies(request).then(() => {
                this.selectedUnitList.forEach(unitId => {
                    EventBus.$emit(GE_CALENDAR_LOAD_PRICES, {
                        value: null,
                        unitId: unitId
                    })
                })
                notifySuccess()
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.saving = false
            })
        },
        setRateItems() {
            this.formLoading = true
            this.setRateItemKeys()
            if (this.singleUnit) {
                if (this.defaultValues && this.defaultValues.hasOwnProperty("price")) {
                    each(this.rateItems[this.unitId], (losList, losId) => {
                        each(losList, (occupancyValue, occupancyId) => {
                            let rateItem = this.defaultValues.price.find(item => {
                                return item.los_id === Number(losId) && item.occupancy_id === Number(occupancyId)
                            })
                            if (rateItem) {
                                this.rateItems[this.unitId][losId][occupancyId] = rateItem.amount ? rateItem.amount.toString() : null
                            }
                        })
                    })

                    this.initialRateItems = cloneDeep(this.rateItems)
                }
            } else if (this.sameLosOccupancy) {
                const unitId = this.unitId ? this.unitId : Object.keys(this.losList)[0]

                each(this.rateItems[this.unitId], (losList, losId) => {
                    let los = this.losList[unitId].find(losItem => {
                        return losItem.id === Number(losId)
                    })
                    each(losList, (occupancyValue, occupancyId) => {
                        let occupancy = this.occupancyList[unitId].find(occupancyItem => {
                            return occupancyItem.id === Number(occupancyId)
                        })
                        let rateItem = this.rates.find(item => {
                            return item.los.min_stay === los.min_stay && item.los.max_stay === los.max_stay && item.occupancy.min_guests === occupancy.min_guests && item.occupancy.max_guests === occupancy.max_guests
                        })
                        if (rateItem) {
                            this.rateItems[this.unitId][losId][occupancyId] = rateItem.amount ? rateItem.amount.toString() : null
                        }
                    })
                })

                this.initialRateItems = cloneDeep(this.rateItems)
            }
            this.$nextTick(() => {
                this.formLoading = false
            })
        },
        setRateItemKeys() {
            if (this.sameLosOccupancy && this.losList && typeof this.losList === "object" && Object.keys(this.losList).length > 0) {
                for (const unitId in this.losList) {
                    if (!this.losList.hasOwnProperty(unitId)) {
                        continue
                    }
                    const los = this.losList[unitId]
                    const occupancy = this.occupancyList[unitId]
                    this.$set(this.rateItems, unitId, {})
                    if (!los || !occupancy || !Array.isArray(los) || !Array.isArray(occupancy)) {
                        continue
                    }
                    los.forEach(losItem => {
                        if (!this.rateItems[unitId].hasOwnProperty(losItem.id)) {
                            this.$set(this.rateItems[unitId], losItem.id, {})
                        }
                        occupancy.forEach(occupancyItem => {
                            this.$set(this.rateItems[unitId][losItem.id], occupancyItem.id, null)
                        })
                    })
                }
            } else {
                this.rateItems = {}
            }
        },
        setLockOptionVisible() {
            if (this.singleUnit) {
                const request = {
                    key: AC_CALENDAR_EDIT,
                    context: {
                        company: this.company.id
                    },
                    data: [{
                        uid: C_REVENUE_MANAGER_LOCK_PRICE_V,
                        function: C_REVENUE_MANAGER_LOCK_PRICE_V,
                        unit: this.unitId
                    }]
                }

                fetchAccessControlData(AC_CALENDAR_EDIT, request).then((response) => {
                    if (response.data) {
                        this.lockVisible = response.data[C_REVENUE_MANAGER_LOCK_PRICE_V].visible
                    }
                })
            } else {
                this.lockVisible = false
            }
        },
        _has(object, path) {
            return has(object, path)
        },
        calculateRateDependencyList() {
            if (!this.calculateRates) {
                return
            }
            each(this.rateItems[this.unitId], (losList, losId) => {
                let losObject = this.los.find(el => {
                    return el.id === Number(losId)
                })
                each(losList, (occupancyValue, occId) => {
                    let occupancyObject = this.occupancy.find(el => {
                        return el.id === Number(occId)
                    })
                    if (occupancyObject && losObject) {
                        if (!this.rateItems[this.unitId].hasOwnProperty(losId)) {
                            this.$set(this.rateItems[this.unitId], losId, {})
                        }
                        this.$set(this.rateItems[this.unitId][losId], occId, this.calculateRateDependency(losObject, occupancyObject, this.defaultRate.amount))
                    }
                })
            })
        },
        calculateRateDependency(los, occupancy, value) {
            if (this.defaultRate.los.id === los.id && this.defaultRate.occupancy.id === occupancy.id) {
                return value
            }

            let newValue = null
            if (occupancy.discount && occupancy.discount_type) {
                if (occupancy.discount_type === PERC) {
                    newValue = Number(value) + (Number(value) / 100) * Number(occupancy.discount);
                }
                if (occupancy.discount_type === FIX) {
                    newValue = Number(value) + Number(occupancy.discount);
                }
            } else {
                newValue = value
            }

            let amount = !newValue ? value : newValue

            if (los.discount && los.discount_type) {
                if (los.discount_type === PERC) {
                    amount = Number(amount) + (Number(amount) / 100) * Number(los.discount);
                }
                if (los.discount_type === FIX) {
                    amount = Number(amount) + Number(los.discount);
                }
            }
            return Math.ceil(amount)
        },
        calculateNewRatesByRelativeChange(unitId, value, valueType) {
            let relativeChangeInWholePeriod = true
            if (this.allowedWeekday.length < 7) {
                let dayList = []
                this.periods.forEach(period => {
                    let startDate = moment(period.start)
                    let endDate = moment(period.end)

                    let date = startDate.clone()

                    while (date.isSameOrBefore(endDate)) {
                        let day = date.isoWeekday() % 7
                        if (!dayList.indexOf(day) < 0) {
                            dayList.push(day)
                        }
                        date.addDays(1)
                        if (dayList.length === 7) {
                            break
                        }
                    }
                })
                dayList.forEach(day => {
                    if (this.allowedWeekday.indexOf(day) < 0) {
                        relativeChangeInWholePeriod = false
                    }
                })
            }

            if (!this.sameLosOccupancy) {
                this.rate = this.calculateAmountByRelativeChange(value, valueType, this.rate)
                return
            }

            each(this.initialRateItems[unitId], (losList, losId) => {
                each(losList, (occupancyValue, occupancyId) => {
                    if (!relativeChangeInWholePeriod || occupancyValue === null) {
                        this.$set(this.rateItems[unitId][losId], occupancyId, null)
                    } else if (occupancyValue) {
                        this.$set(this.rateItems[unitId][losId], occupancyId, this.calculateAmountByRelativeChange(value, valueType, occupancyValue))
                    }
                })
            })
        },
        calculateAmountByRelativeChange(value, valueType, amount) {
            if (Number.isNaN(amount)) {
                return null
            }
            if (valueType === FIX) {
                return Math.ceil(Number(amount) + Number(value))
            } else {
                return Math.ceil(Number(amount) * (1 + Number(value) / 100))
            }
        },
        getCorrespondingLosId(losId, unitId) {
            const los = this.losList[this.unitId].find(los => {
                return Number(los.id) === Number(losId)
            })
            const losList = this.losList[unitId]
            const correspondingLos = losList.find(losItem => {
                return losItem.min_stay === los.min_stay && losItem.max_stay === los.max_stay
            })
            return correspondingLos ? correspondingLos.id : null
        },
        getCorrespondingOccupancyId(occupancyId, unitId) {
            const occupancy = this.occupancyList[this.unitId].find(occupancy => {
                return Number(occupancy.id) === Number(occupancyId)
            })
            const occupancyList = this.occupancyList[unitId]
            const correspondingOccupancy = occupancyList.find(occupancyItem => {
                return occupancyItem.min_guests === occupancy.min_guests && occupancyItem.max_guests === occupancy.max_guests
            })
            return correspondingOccupancy ? correspondingOccupancy.id : null
        },
        setApplyToUnitList() {
            this.applyToUnitList = []
            if (this.singleUnit || !this.sameLosOccupancy || this.unitOptionsExcludingSelected.length === 0) {
                return
            }

            this.unitOptionsExcludingSelected.forEach(unit => {
                this.applyToUnitList.push(unit.id)
            })
        },
        getUnitObject(unitId) {
            return this.unitList.find(unit => {
                return unit.id === unitId
            })
        },
    },
    created() {
        // this.setLockOptionVisible()
        this.setRateItems()
        if (this.sameLosOccupancyRelation || this.singleUnit) {
            this.calculateRates = true
        }
    }
}
</script>

<style scoped>
.horizontal-line {
    margin-bottom: 2.15rem;
}
</style>
