<template>
    <div>
        <app-table-v2 id="queueLogTable" class="reservation_table" :fields="fields" :provider="getTableData"   @busyToggled="toggleBusy">
            <template v-slot:ts_start="data">
                {{ data.item.ts_start || '/'}}
            </template>
            <template v-slot:property.name="data">
                <b-link :to="getPropertyLink(data.item)" target="_blank">{{data.item.property.name}}</b-link>
            </template>
            <template v-slot:ts_end="data">
                {{ data.item.ts_end || '/'}}
            </template>
            <template v-slot:indicator="data">
                <app-indicator :indicator_type="rootQueueIndicator(data.item)"></app-indicator>
            </template>
            <template v-slot:edit="data">
                <app-button variant="link"
                            button_type="edit"
                            :show_text="false"
                            @click="showDetailsChildren(data.item)">
                </app-button>
            </template>
            <template v-slot:HEAD_selectableCheckbox="data">
               <div  class="tableSelectableCheckbox" v-if="hasCheckBox">
                    <b-checkbox   size="sm" v-model="selectAllValue" @change="selectAll($event)" :inline="true"></b-checkbox>
                </div>
            </template>
            <template v-slot:selectableCheckbox="data">
                <div  class="tableSelectableCheckbox" v-if="hasCheckBox && showArchiveSelect(data.item)">
                    <b-checkbox  @change="setSelectedItem($event, data.item)" v-model="selectedIds"
                                :value="data.item['id']" size="sm" :inline="true"></b-checkbox>
                </div>
            </template>

            <template v-slot:container_selected_item="data">
                <div class="selectedContainer animated fadeIn" v-if="selectedIds.length">
                    <div>
                        <h4 v-if="selectedIds.length > 1">{{$t("ITEMS_SELECTED", {value: selectedIds.length})}}</h4>
                        <h4 v-else-if="selectedIds.length === 1">{{$t("ITEM_SELECTED")}}</h4>
                        <app-button class="action_button" variant="link">{{$t("DESELECT")}}</app-button>
                    </div>

                    <div class="flex-grow-1 d-flex justify-content-end">
                        <slot v-bind="{selected:selectedIds}" name="selectedContainer">

                            <app-button

                                @click="archiveModal=true">
                                {{$t('ARCHIVE')}}
                            </app-button>

                        </slot>
                    </div>
                </div>
                    <app-confirmation-dialog :title="$t('ARCHIVE_CONFIRMATION')" :show="archiveModal" @confirm="archiveQueueLog" @cancel="archiveModal=false">
                        {{$t('ARCHIVE_CONFIRMATION_TEXT')}}
                    </app-confirmation-dialog>

            </template>

            <template v-slot:tableNoData="props">
                <app-no-data :show-tip="false" :heading="$t('NO_SEARCH_RESULTS')"></app-no-data>
            </template>
        </app-table-v2>

        <app-aside v-model="asidePanelState">
            <template slot="header">{{ asideHeader }}</template>
            <queue-details :detailsData="detailsTableData"></queue-details>
        </app-aside>

    </div>
</template>

<script>

    import {updateQueueList, getQueueDetails, getQueueListData} from "@/services/channel_manager"
    import {C_PROPERTY_CM_PM_QUEUE_E, C_PROPERTY_CM_PM_QUEUE_V} from "@/shared/component_permission"
    import AppButton from '@/components/app/AppButton/AppButton'
    import AppAside from "@/components/app/form/AppAside"
    import TreeView from "@/components/channel_manager/synchronization_log/tree_view/TreeView";
    import AppNoData from "@/components/app/AppNoData";
    import AppSearchData from "@/components/app/AppSearchData";
    import QueueDetails from "@/components/channel_manager/synchronization_log/QueueDetails";
    import {
        CM_QUEUE_STATUS_ERROR,
        CM_QUEUE_STATUS_WARNING, CM_QUEUE_TYPE_EXECUTABLE,
    } from "@/shared/constants";
    import AppIndicator from "@/components/app/indicators/AppIndicator";
    import routeNames from "@/router/routeNames";
    import AppTableV2 from "@/components/app/AppTableV2";
    import {EventBus, GE_TABLE_SEARCH} from "@/shared/EventBus";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {has} from "lodash";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";

    export default {
        name: "QueueList2",
        components: {
            AppConfirmationDialog,
            AppTableV2,
            AppIndicator,
            QueueDetails,
            AppSearchData,
            AppNoData,
            TreeView,
            AppButton,
            AppAside,
        },
        props: {
            showProperty:{
                type:Boolean,
                default:false
            },
            filter:{
                type:Object
            }
        },
        data() {
            return {
                allFields: [
                    {key:'selectableCheckbox',label:''},
                    {key: 'id', label: 'Id'},
                    {key: 'status.label', label: this.$t('STATUS')},
                    {key: 'action.label', label: this.$t('ACTION')},
                    {key:'property.name', label:this.$t('PROPERTY')},
                    {key: 'ts_insert', label: this.$t('SYNCHRONIZATION_TIME')},
                    {key: 'ts_start', label: this.$t('SYNCHRONIZATION_START')},
                    {key: 'ts_end', label: this.$t('SYNCHRONIZATION_END')},
                    {key: 'indicator', label: '', class:'p-0'},
                    {key: 'edit', label: '', class: 'text-right pl-2'},
                    {key: 'request', label: this.$t('REQUEST')},
                    {key: 'response', label: this.$t('RESPONSE')},

                ],
                detailsTableData: [],
                perPage: 20,
                c_permission_view: C_PROPERTY_CM_PM_QUEUE_V,
                asidePanelState: false,
                modalOpen: false,
                modalData: null,
                currentPage: 1,
                isJSON: false,
                asideHeader: '',
                selectedIds:[],
                selectAllValue:false,
                archiveLoader:false,
                list:[],
                showCheckBox:false,
                archiveModal:false
            }
        },
        computed: {
            fields() {
                let fields = this.allFields
                if(!this.hasCheckBox){
                  fields = this.allFields.filter(field => field.key !== "selectableCheckbox")
                }
                if(this.showProperty){
                    return fields.filter(field => field.key !== "response" && field.key !== "request" )
                } else {
                    return fields.filter(field => field.key !== "response" && field.key !== "request" && field.key !== 'property.name')
                }

            },
            detailsFields() {
                return this.allFields.filter(field => field.key !== "root" && field.key !== 'children' && field.key !== 'action.label')
            },
            hasCheckBox(){
                if(this.list.length){
                    for(let itemObject of  this.list){
                        if((itemObject.status.id === CM_QUEUE_STATUS_ERROR || itemObject.status.id === CM_QUEUE_STATUS_WARNING) && itemObject.queue_type === CM_QUEUE_TYPE_EXECUTABLE){
                            return true
                        }
                    }
                }
                return false
            },
        },
        methods: {
            showArchiveSelect(item){
                if(!this._has(item, 'status.id') || !this._has(item, 'queue_type')){
                    return false
                }
                if((item.status.id === CM_QUEUE_STATUS_ERROR || item.status.id === CM_QUEUE_STATUS_WARNING) && item.queue_type === CM_QUEUE_TYPE_EXECUTABLE){
                    return true
                } else {
                    return false
                }
            },

            toggleBusy($event) {
                this.loading = $event
                this.$emit('busyToggled', $event)
            },
         selectAll($event) {

            this.list.forEach(el => {
                if((el.status.id === CM_QUEUE_STATUS_ERROR || el.status.id === CM_QUEUE_STATUS_WARNING) && el.queue_type === CM_QUEUE_TYPE_EXECUTABLE) {
                    this.selectedIds = []
                }
            })
             if ($event) {
                this.list.forEach(el => {
                    if((el.status.id === CM_QUEUE_STATUS_ERROR || el.status.id === CM_QUEUE_STATUS_WARNING) && el.queue_type === CM_QUEUE_TYPE_EXECUTABLE){
                      this.selectedIds.push(el.id)
                    }
                })
             }
            },
            getTableData(filter) {

                let req = {
                    ...this.filter,
                    ...{
                        page: filter.currentPage,
                        perPage: filter.perPage,
                    }
                }
                let dataProvider =   getQueueListData(req)
                dataProvider.then(response =>{
                    this.list = response.data.items
                })

                return dataProvider
            },
            getPropertyLink(queueLog){
                return {
                    name:routeNames.RN_PROPERTY_CHANNEL_MANAGER_SYNCHRONIZATION_LOG,
                    params:{
                        id:queueLog.property.id,
                        distribution_id:this.filter.distribution
                    },
                    query:{
                        action: this.filter.connection_action,
                        filter:1
                    }
                }
            },
            rootQueueIndicator(item){
                return !item.queue_id ? 'indicator-warning' : null
            },

            filterId(item) {
                return getQueueDetails({children_queue:item.id})
            },

            showDetailsChildren(data) {
                this.asideHeader = this.$t('DETAILS')
                this.detailsTableData = []
                this.filterId(data).then(result => {
                    result.data.forEach(item => this.detailsTableData.push(item))
                    this.asidePanelState = true
                })

            },
            setSelectedItem($event, el) {
                let index = this.selectedIds.indexOf(el[this.primaryKey])
                if (index >= 0) {
                    this.selectedIds.splice(index, 1)
                }
                if ($event) {
                    this.selectedIds.push($event)
                }
            },
            showModal(data) {
                this.modalData = []
                this.modalOpen = true

                if (data) {
                    if (data.toString().startsWith("<?xml version=")) {
                        this.isJSON = false
                        this.modalData = data
                    } else if (data.length > 0) {
                        this.isJSON = true
                        try {
                            this.modalData = JSON.parse(data)
                        } catch (e) {
                            this.isJSON = false
                            this.modalData = data
                        }
                    }
                }
            },
            _has(object, path) {
                return has(object, path)
            },
            archiveQueueLog(){
                this.archiveLoader = true
                let request = {
                    ids:this.selectedIds.map(el => Number(el))
                }
                updateQueueList(request).then(()=>{
                    notifySuccess();
                    EventBus.$emit(GE_TABLE_SEARCH)
                    this.selectedIds = []
                }).finally(()=>{
                    this.archiveLoader = false
                    this.archiveModal = false
                })
            },
            disabledArchiveCheckBox(item){
                return item.status.id === 21
            }
        },
        watch:{
            filter: {
                handler() {
                    EventBus.$emit(GE_TABLE_SEARCH)
                },
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>
