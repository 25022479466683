<template>
    <div>
        <b-row>
            <b-col cols="12" class="col-xxl-6" :key="index" v-for="(distribution, index) in distributionList">

                <distribution-reservation-item :distribution="distribution"></distribution-reservation-item>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {getDistributionList} from "@/services/distribution";


export default {
    name: "DistributionReservationList",
    components: {
        "DistributionReservationItem":() => import("@/components/channel_manager/distribution/DistributionReservationItem")
    },
    data() {
        return {
            distributionList: []
        }
    },
    methods: {
        setData() {
            getDistributionList({company: this.company.id, for_reservation_process: 1}).then(response => {
                    this.distributionList = response.data
                }
            )
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    created() {
        this.setData()
    }
}
</script>

<style scoped>

</style>
