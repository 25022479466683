<template>
    <b-form v-if="fetchData" @submit.prevent="save">
        <b-form-checkbox

            v-model="enableCalculate"
            @change="updateEnableCalculate"
            class="pull-right mb-4"
            switch
            inline
            id="enable_calculate"
            name="enable_calculate"
            :value="true"
            :unchecked-value="false">
            {{ $t('APPLY_INCREASE_DECREASE') }}
        </b-form-checkbox>
        <table class="table">
            <thead>
            <tr>
                <th v-if="losList.length > 1" class="table-header-action" scope="col">{{ $t("LENGTH_OF_STAY") }}
                </th>
                <th v-if="occupancyList.length > 1" class="table-header-action" scope="col">
                    {{ $t("OCCUPANY_PERSONS") }}
                </th>
                <th class="table-header-action" scope="col">{{ $t('DAY_OF_WEEK') }}</th>

                <th class="table-header-action text-center"  style="min-width:7rem;" scope="col">{{ $t('ADJUSTMENT') }}</th>

                <th class="table-header-actions" scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <template v-for="item in this.data">

                <tr :key="item.los.id + '_' + item.occupancy.id">
                    <td width="17%" v-if="losList.length > 1"
                        :class="item.los.default && item.occupancy.default ? 'bg-light' : ''">
                        {{ item.los.min_stay }}
                        <span v-if="item.los.min_stay === item.los.max_stay"></span>
                        <span v-else-if="item.los.max_stay"> - {{ item.los.max_stay }}</span>
                        <span v-else>+</span>
                    </td>
                    <td width="17%" v-if="occupancyList.length > 1"
                        :class="item.los.default && item.occupancy.default ? 'bg-light' : ''">
                        {{ item.occupancy.min_guests }}

                        <span v-if="item.occupancy.min_guests === item.occupancy.max_guests"></span>
                        <span v-else-if="item.occupancy.max_guests"> - {{ item.occupancy.max_guests }}</span>
                        <span v-else>+</span>
                    </td>
                    <td width="29%" w class="mb-0 mt-0 pb-2 pt-2" >
                        <app-select mode="multiselect" text-field="name" value-field="id" :options="day_options"
                                    @input="updateSelectDays(item.los.id, item.occupancy.id, $event)"
                                    v-model="root_day_list[item.los.id+'_'+item.occupancy.id]"></app-select>
                    </td>

                    <td  class="mb-0 mt-0 pb-2 pt-2">
                        <app-number-input v-if="item.los.default && item.occupancy.default" prepend="%" allow-decimal
                                          :disabled="disabledRoot(item.los.id,item.occupancy.id)"
                                          v-model="root_percentage[item.los.id+'_'+item.occupancy.id]"></app-number-input>
                        <app-number-input v-else prepend="%" allow-decimal
                                          :disabled="disabledRoot(item.los.id,item.occupancy.id)"
                                          v-model="root_percentage[item.los.id+'_'+item.occupancy.id]"></app-number-input>
                    </td>
                    <td class="text-right mb-0 mt-0 pb-2 pt-2" style="width: 15%">
                        <app-access-control class="ml-3" tag="span" @AccessControlEvent="addToAccessControlCounter()"
                                            :access_control_context="{function:C_UNIT_CALENDAR_DAILY_ADJUSTMENT_SAVE,key:AC_BULK_CALENDAR_DAILY_ADJUSTMENT}">
                            <template  v-slot:default="props">
                                <b-tooltip v-if="props.messages.length"
                                           triggers="hover"
                                           :title="props.messages[0].message" :target="'tooltip-target-daily_adjustment-add'+item.los.id+'_'+item.occupancy.id">
                                </b-tooltip>
                                <app-button
                                    :id="'tooltip-target-daily_adjustment-add'+item.los.id+'_'+item.occupancy.id"
                                    @click="addNewRow(item.los.id,item.occupancy.id,root_day_list[item.los.id+'_'+item.occupancy.id])"
                                    :disabled="disabledRoot(item.los.id,item.occupancy.id) || props.disabled"
                                    button_type="new">{{ $t('ADD_ITEM') }}
                                </app-button>

                            </template>
                        </app-access-control>

                    </td>
                </tr>
                <tr v-if="item.hasOwnProperty('children') && item.children.length > 0" :key="child.key"
                    v-for="(child, index) in item.children">
                    <td v-if="losList.length > 1"></td>
                    <td v-if="occupancyList.length > 1"></td>
                    <td>{{ getDayString(day_list[child.key]) }}</td>

                    <td class="mb-0 mt-0 pb-2 pt-2">
                        <app-number-input prepend="%" allow-decimal
                                          v-if="item.los.default && item.occupancy.default"
                                          @change="updateDefaultChildPercentage(item.los,item.occupancy,child.key, $event)"
                                          v-model="percentage[child.key]"></app-number-input>
                        <app-number-input prepend="%" allow-decimal
                                          v-else
                                          v-model="percentage[child.key]"></app-number-input>

                    </td>
                    <td class="text-right mb-0 mt-0 pb-2 pt-2">
                        <app-button  @click="removeRow(item.los.id,item.occupancy.id, child.key)"
                                    button_type="delete" variant="link" :show_text="false" ></app-button>

                    </td>
                </tr>
            </template>
            </tbody>
        </table>

        <b-row class="mt-4">
            <b-col>
                <app-access-control class="ml-3" tag="span" @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{function:C_UNIT_CALENDAR_DAILY_ADJUSTMENT_SAVE,key:AC_BULK_CALENDAR_DAILY_ADJUSTMENT}">
                    <template  v-slot:default="props">
                        <b-tooltip v-if="props.messages.length"
                                   triggers="hover"
                                   :title="props.messages[0].message" target="tooltip-target-daily_adjustment-save">
                        </b-tooltip>

                        <app-button-submit id="tooltip-target-daily_adjustment-save"
                                           :disabled="props.disabled"
                                           @click="saveAside=true" class="pull-left" :loading="loader"></app-button-submit>
                    </template>
                </app-access-control>

            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import AppNumberInput from "@/components/app/form/AppNumberInput";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButton from "@/components/app/AppButton/AppButton";
import FormHeader from "@/components/app/form/FormHeader";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
import {getWeeklyAdjustmentPrice, saveWeeklyAdjustmentPrice} from "@/services/pricing";
import {toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from '@/mixins/error/getErrorMessage'
import {each} from "lodash";
import {FIX, PERC} from "@/shared/constants";
import AppAccessControl from "@/components/app/AppAccessControl";
import {C_UNIT_CALENDAR_DAILY_ADJUSTMENT_SAVE} from "@/shared/component_permission";
import {AC_BULK_CALENDAR_DAILY_ADJUSTMENT} from "@/mixins/AccessControl/AccessControlEnumeration";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";

export default {
    name: "DailyAdjustmentForm",
    components: {AppAccessControl, AppButtonDelete, AppButtonSubmit, FormHeader, AppButton, AppSelect, AppNumberInput},
    mixins: [getErrorMessage,AccessControlComponent],
    props: {
        period: {
            type: Object,
            default() {
                return {}
            }
        },
        losList: {
            type: Array,
            default() {
                return []
            }
        },
        occupancyList: {
            type: Array,
            default() {
                return []
            }
        },
        unit_id: {
            type: Number
        },
        property_id:{
            type:Number
        }
    },
    data() {
        return {
            access_control_fetch_key: AC_BULK_CALENDAR_DAILY_ADJUSTMENT,
            C_UNIT_CALENDAR_DAILY_ADJUSTMENT_SAVE,
            AC_BULK_CALENDAR_DAILY_ADJUSTMENT,
            enableCalculate: true,
            los: [],
            occupancy: [],
            day_options: [
                {id: 1, name: this.$t('DATE.DAYS.MONDAY')},
                {id: 2, name: this.$t('DATE.DAYS.TUESDAY')},
                {id: 3, name: this.$t('DATE.DAYS.WEDNESDAY')},
                {id: 4, name: this.$t('DATE.DAYS.THURSDAY')},
                {id: 5, name: this.$t('DATE.DAYS.FRIDAY')},
                {id: 6, name: this.$t('DATE.DAYS.SATURDAY')},
                {id: 7, name: this.$t('DATE.DAYS.SUNDAY')},
            ],
            root_percentage: {},
            root_day_list: {},
            percentage: {},
            day_list: {},
            data: [],
            key_for_save: [],
            delete_pricing_list: [],
            loader: false,
            fetchData:false,
            default_day_list:[]
        }
    },
    methods: {
        disabledRoot(los_id, occupancy_id) {
            return typeof this.root_day_list[los_id + '_' + occupancy_id] === 'undefined' || this.root_day_list[los_id + '_' + occupancy_id].length === 0
        },
        updateDefaultPercentage(los_id, occupancy_id, value) {

            if (!this.enableCalculate) {
                return null
            }
            const defaultDayList = this.root_day_list[los_id + '_' + occupancy_id]
            for (const occupancyObject of this.occupancyList) {
                for (const losObject of this.losList) {
                    const dayList = this.root_day_list[losObject.id + '_' + occupancyObject.id]
                    if (typeof dayList !== 'undefined' && dayList && dayList.length === defaultDayList.length && dayList.every(item => defaultDayList.includes(item))) {
                        this.root_percentage[losObject.id + '_' + occupancyObject.id] = value
                    }
                }
            }
        },
        updateSelectDays(los_id, occupancy_id, dayList) {
            if (!this.enableCalculate) {
                return null
            }
            const occupancyObject = this.occupancyList.find(el => el.id === occupancy_id)
            const losObject = this.losList.find(el => el.id === los_id)

            if (losObject.default && occupancyObject.default) {
                return
            }
            const defaultLos = this.losList.find(el => el.default === 1)
            const defaultOccupancy = this.occupancyList.find(el => el.default === 1)
           let days =[]
            dayList.forEach(day=>{
                days.push(day)
            })
            days = days.sort()

            const defaultRootDayList = this.root_day_list[defaultLos.id + '_' + defaultOccupancy.id]
            if(defaultRootDayList && defaultRootDayList.length > 0){
                const default_root_percentage = this.root_percentage[defaultLos.id+'_'+defaultOccupancy.id]
                if (typeof dayList !== 'undefined' && typeof defaultRootDayList !== 'undefined' && dayList && dayList.length === defaultRootDayList.length && dayList.every(item => defaultRootDayList.includes(item))) {
                    this.root_percentage[losObject.id + '_' + occupancyObject.id] = default_root_percentage
                    return
                }
            }

            const day_List_key = days.toString().replaceAll(',','_')
            const child_default_key = defaultLos.id + '_' + defaultOccupancy.id+'_'+day_List_key
            const default_percentage = this.percentage[child_default_key]
            if(default_percentage){
                this.root_percentage[losObject.id + '_' + occupancyObject.id] = default_percentage
                return
            }
            this.root_percentage[losObject.id + '_' + occupancyObject.id] = null
        },

        updateDefaultChildPercentage(los, occupancy, childKey, value) {
            if (value !== null) {

                if (!this.enableCalculate) {
                    return null
                }
                const defaultDayList = this.day_list[childKey] ? this.day_list[childKey].map(Number): []
                const day_key = defaultDayList.toString().replaceAll(',','_')
                for (const occupancyObject of this.occupancyList) {
                    for (const losObject of this.losList) {
                        const newChildKey = losObject.id + '_'+occupancyObject.id + '_'+ day_key
                            this.$set(this.percentage, newChildKey, value)
                    }
                }
                if(Object.keys(this.root_day_list).length > 0){
                    for(const[key,rootDays] of Object.entries(this.root_day_list)){
                        if(rootDays.length && rootDays.every(item=>defaultDayList.includes(item))){
                            this.$set(this.root_percentage,key,value)
                        }
                    }
                }

            }
        },

        prepareSaveRequest() {
            let request = {}
            let pricing_list = []
            const data_list_with_value = this.data.filter(el => el.hasOwnProperty('children') && el.children.length > 0)
            for (const data_value of data_list_with_value) {
                if (data_value.children.length > 0) {
                    for (const value of data_value.children) {
                        const days = this.day_list[value.key].sort()
                        let reqObject = {
                            sales_period: this.period.id,
                            los: value.los_id,
                            occupancy: value.occupancy_id,
                            value_percentage: Number(this.percentage[value.key]),
                            weekday_list: days.toString().replaceAll(",", "")
                        }
                        if (value.hasOwnProperty('id') && value.id !== null) {
                            reqObject = {...reqObject, ...{id: value.id}}
                        }
                        pricing_list.push(reqObject)

                    }
                }
            }
            if (pricing_list.length > 0) {
                request = {...request, ...{pricing_list}}
            }
            if (this.delete_pricing_list.length > 0) {
                request = {...request, ...{delete_pricing_list: this.delete_pricing_list}}
            }

            return request
        },
        setDataAC(response){
            let component = 0
            if(this.occupancyList.length > 1 && this.losList.length > 1){
                component = this.losList.length + this.occupancyList.length
            } else {
                if(this.losList.length  > 1){
                    component = this.losList.length
                }
                if(this.occupancyList.length > 1){
                    component += this.occupancyList.length
                }
            }

            this.access_control_components = component === 0  ? 1 + response.data.length : component + 1

        },

        parseResponse(response) {
            this.setDataAC(response)
            this.fetchData = true

            if (response.data && response.data.length) {
                for (const weeklyAdjustmentObject of response.data) {
                    if (this.data.length > 0) {
                        let object = this.data.find(el => Number(el.los.id) === Number(weeklyAdjustmentObject.los_id) && Number(el.occupancy.id) === Number(weeklyAdjustmentObject.occupancy_id))

                        if (object && Object.keys(object).length > 0) {
                            const days = weeklyAdjustmentObject.weekday_list.split("")
                            const childrenKey = this.getChildKeyString(weeklyAdjustmentObject.los_id, weeklyAdjustmentObject.occupancy_id, days)
                            object.children.push({
                                key: childrenKey,
                                id: weeklyAdjustmentObject.id,
                                los_id: weeklyAdjustmentObject.los_id,
                                occupancy_id: weeklyAdjustmentObject.occupancy_id,
                                days: days
                            })
                            this.$set(this.percentage, childrenKey, weeklyAdjustmentObject.value_percentage)
                            this.day_list[childrenKey] = days
                            if(this.defaultLos.id === weeklyAdjustmentObject.los_id && this.defaultOccupancy.id === weeklyAdjustmentObject.occupancy_id){
                                this.default_day_list.push(days)
                            }

                        }
                    }
                }
            }
        },
        save() {
            const request = this.prepareSaveRequest()
            if (Object.keys(request).length === 0) {
                toast({
                    'title': '',
                    'message': this.$t('NO_CHANGE'),
                    'type': 'error',
                    'timeout': 3000
                })
                return
            }
            request.sales_period = this.period.id
            this.loader = true
            saveWeeklyAdjustmentPrice(this.unit_id, request).then(response => {
                this.data = []
                for (const losItem of this.losList) {
                    for (const occupancyItem of this.occupancyList) {
                        const key = losItem.id + '_' + occupancyItem.id
                        this.data.push({
                            key,
                            los: losItem,
                            occupancy: occupancyItem,
                            percentage: null,
                            children: []
                        })
                    }
                }
                this.parseResponse(response)
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 3000
                })

            }).finally(() => {
                this.loader = false
            })
        },

        getDayString(days_array) {
            let day_name = []
            if (days_array.length > 0) {
                days_array.forEach(day_id => {
                    const dayObject = this.day_options.find(el => Number(el.id) === Number(day_id))
                    if (dayObject && dayObject.hasOwnProperty('name')) {
                        day_name.push(dayObject.name)
                    }

                })
            }
            return day_name.length > 0 ? day_name.join(', ') : ''
        },
        getChildKeyString(los_id, occupancy_id, days) {
            const day_key = days.length > 0 ? days.toString().replaceAll(",", "_") : '_'
            return los_id + '_' + occupancy_id + '_' + day_key
        },
        convertElementArrayToInt(array) {

            array = array.map(function (x) {
                return parseInt(x, 10);
            });
            return array

        },
        addNewRow(los_id, occupancy_id, days) {

            if (this.data.length > 0) {
                const root_key = los_id + '_' + occupancy_id
                days =days.sort()
                let rootItem = this.data.find(el => el.key === root_key)
                if (rootItem && rootItem.hasOwnProperty('children')) {
                    for (const child of rootItem.children) {
                        if (child.hasOwnProperty('days')) {
                            for (const day of days) {
                                const child_active_days = this.convertElementArrayToInt(child.days)
                                if (child_active_days.includes(day)) {
                                    const dayObject = this.day_options.find(el => Number(el.id) === Number(day))

                                    toast({
                                        'title': '',
                                        'message': this.$t('DAY_ALREADY_SELECTED', {day: dayObject.name}),
                                        'type': 'error',
                                        'timeout': 3000
                                    })
                                    return
                                }
                            }
                        }

                    }
                    const childKey = this.getChildKeyString(los_id, occupancy_id, days)
                    this.$set(this.percentage, childKey, this.root_percentage[root_key])

                    this.day_list[childKey] = this.root_day_list[root_key].sort()
                    rootItem.children.push({
                        id: null,
                        key: childKey,
                        los_id,
                        occupancy_id,
                        days: this.root_day_list[root_key],
                    })

                    this.key_for_save.push(childKey)
                }
            if(this.defaultLos.id === los_id && this.defaultOccupancy.id === occupancy_id){
                this.default_day_list.push(this.root_day_list[root_key])
            }

                this.$set(this.root_percentage, root_key, null)
                this.$set(this.root_day_list, root_key, [])
                if(los_id === this.defaultLos.id && occupancy_id === this.defaultOccupancy.id){
                    this.updateEnableCalculate(this.enableCalculate)
                }
            }
        },
        removeRow(los_id, occupancy_id, childKey) {
            if (this.data.length > 0) {
                const key = los_id + '_' + occupancy_id
                let rootItem = this.data.find(el => el.key === key)
                if (rootItem && rootItem.hasOwnProperty('children')) {
                    const childIndex = rootItem.children.findIndex(el => el.key === childKey)
                    if (childIndex > -1) {
                        if (rootItem.children[childIndex].id !== null) {

                            this.delete_pricing_list.push(rootItem.children[childIndex].id)
                        }
                        if(rootItem.children[childIndex].days.length > 0 && los_id === this.defaultLos.id && occupancy_id === this.defaultOccupancy.id){
                            this.default_day_list.forEach((el,index)=>{
                                if(el === rootItem.children[childIndex].days){
                                    this.default_day_list.splice(index,1)
                                }
                            })
                        }
                        rootItem.children.splice(childIndex, 1)
                        delete this.percentage[childKey]
                    }
                }
            }
        },
        updateEnableCalculate(value){
            if(value){
                if(this.default_day_list.length > 0){
                    for(const defaultDays of this.default_day_list){
                        let days =  defaultDays.map(Number)
                        days = days.toString().replaceAll(',','_')
                        const defaultKey = this.defaultLos.id+'_'+this.defaultOccupancy.id+'_'+days
                        const default_value = this.percentage[defaultKey]

                        for(const losObject of this.losList){
                            for(const occupancyObject of this.occupancyList){
                                const childKey = losObject.id+'_'+occupancyObject.id+'_'+days
                                if(this.percentage[childKey]){
                                    this.$set(this.percentage,childKey,default_value)
                                }
                                const rootKey = losObject.id+'_'+occupancyObject.id
                                if(this.root_percentage[rootKey]){
                                    if(Object.keys(this.root_day_list).length > 0){
                                        for(const[key,rootDays] of Object.entries(this.root_day_list)){
                                            if(rootDays.length && rootDays.every(item=>defaultDays.includes(item))){
                                                this.$set(this.root_percentage,rootKey,default_value)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        },
        setData() {
            for (const losItem of this.losList) {
                for (const occupancyItem of this.occupancyList) {
                    const key = losItem.id + '_' + occupancyItem.id
                    this.data.push({
                        key,
                        los: losItem,
                        occupancy: occupancyItem,
                        percentage: null,
                        children: []
                    })
                }
            }

            getWeeklyAdjustmentPrice(this.unit_id, {sales_period: this.period.id}).then(response => {
                this.parseResponse(response)
            })


        }

    }
    ,
    computed: {


        defaultLos(){
            return this.losList.find(el => el.default === 1)
        },
        defaultOccupancy(){
            return this.occupancyList.find(el => el.default === 1)
        },
        dataList() {
            let data = []
            if (this.los.length > 0 && this.occupancyList.length > 0) {
                for (const losItem of this.los) {
                    for (const occupancyItem of this.occupancyList) {
                        data.push({losItem, occupancyItem})
                    }
                }
            }
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    watch:{
        property_id:{
            handler(value){
                this.access_control_general_context ={
                    property:value,
                    company:this.company.id
                }
            },immediate:true
        },
        root_day_list:{
         handler(list) {

         }  ,deep:true
        }
    },
    created() {
        this.setData()

    }
}
</script>

<style scoped>

</style>
