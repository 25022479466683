<template>
    <b-form @submit.prevent="save">
        <div class="languageDiv">
            <app-translation-input :label="$t('NAME')"
                                   :context-label="$t('UNIT_TYPE')"
                                   v-model="unitType.label"
                                   :languages="website.supported_languages"
                                   :lang_id="lang_id"
                                   class="editName"
            ></app-translation-input>
            <b-row>
                <b-col class="mb-4">
                    <app-button :loading="saveLoader" @click="save">{{$t('SAVE')}}</app-button>
                </b-col>
                <b-col class="mb-4">
                    <app-button-delete @click="deleteType" :loading="deleteItem"
                                       class="pull-right"></app-button-delete>
                </b-col>
            </b-row>
        </div>
        <table class="table">
            <thead>
            <tr>
                <th class="table-header-period-range" scope="col">{{ $t('MAPPING') }}</th>
                <th class="table-header-actions text-right" scope="col"></th>
            </tr>
            </thead>
        </table>
        <app-item-list v-for="type in mappedTypes" :key="type.id"
                       :item-list="true" v-if="type.deleted === 0" :item-object="type" @deleteItem="removeType"></app-item-list>
        <app-item-list :new-item="false"></app-item-list>
        <b-row class="mb-2">
            <b-col cols="8">
                <app-select class="addItems" v-model="selectedType" mode="multiselect" :options="btUnitType" text-field="label"></app-select>
            </b-col>
            <b-col class="text-right pr-4" cols="4">
                <app-button button_type="new"
                            @click="addToMapping">
                    {{ $t('ADD_ITEM') }}
                </app-button>
            </b-col>
        </b-row>
        <app-button :loading="saveLoader" @click="saveMapping">{{$t('SAVE')}}</app-button>
        <app-confirmation-dialog
            :show="confirmation_dialog_state"
            @confirm="continueAction"
            @cancel="cancelAction"
            :title="$t('DELETE_TITLE')">
            <p>{{$t('DELETE_MESSAGE',{name})}}</p>
        </app-confirmation-dialog>
    </b-form>
</template>

<script>
import {getBtUnitTypeList, getMappedUnitTypes, saveUnitTypeMapping} from "@/services/direct_booking/website";
import AppTranslationInput from "@/components/app/translation/AppTranslationInput";
import AppButton from "@/components/app/AppButton/AppButton";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppItemList from "@/components/app/AppItemList";
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getTranslateList, updateTranslations} from "@/services/system";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";

export default {
    name: "WebsiteEditUnitType",
    components: {AppTranslationInput, AppButton, AppItemList, AppSelect, AppButtonDelete, AppConfirmationDialog},
    mixins: [getErrorMessage],
    props:{
        websiteId:{
            type: Number,
            default: null
        },
        unitType:{
            type: Object,
            default: null
        }
    },
    data(){
        return{
            mappedTypes: [],
            tag: {},
            btUnitType: [],
            selectedType: [],
            usedMapTypes: [],
            lang_id: this.unitType.lang_id,
            saveLoader: false,
            deleteItem: false,
            confirmation_dialog_state: false,
            name: this.unitType.label,
        }
    },
    computed: {
        website() {
            return this.$store.getters['website/getWebsite']
        }
    },
    methods:{
        save(){
            this.saveLoader = true
            updateTranslations(this.lang_id, {"en": this.unitType.label }).then(response => {
                notifySuccess()
                this.saveLoader = false
                this.$emit('translationSaved')
            }, error => {
                this.saveLoader = false
                this.showErrorMessages(error)
            })
        },
        removeType(value){
            let index = this.mappedTypes.findIndex((obj => obj.id === value.id))
            this.mappedTypes[index].deleted = 1
        },
        addToMapping(){
            const list = this.btUnitType.filter(el => this.selectedType.find(type => el.id === type))
            this.mappedTypes.push(...list)
            this.selectedType = []
        },
        saveMapping(){
            this.mappedTypes.forEach(el => {
                el.eid = this.unitType.id
                if (el.check_bool === true){
                    this.usedMapTypes.push(el)
                }
            })
            this.saveLoader = true
            saveUnitTypeMapping(this.websiteId, {list: this.mappedTypes}).then(response => {
                notifySuccess()
                this.saveLoader = false
                this.$emit('removeMapping', this.usedMapTypes)
                this.$emit('mappingSaved')
            }, error => {
                this.saveLoader = false
                this.showErrorMessages(error)
            })
        },
        deleteType(){
            this.confirmation_dialog_state = true
        },
        continueAction(){
            this.deleteItem = true
            saveUnitTypeMapping(this.websiteId, {delete_type: true, unit_type: this.unitType.id}).then(response => {
                notifySuccess()
                this.confirmation_dialog_state = false
                this.deleteItem = false
                this.$emit('removeMapping', this.usedMapTypes)
                this.$emit('mappingSaved')
            }, error => {
                this.deleteItem = false
                this.confirmation_dialog_state = false
                this.showErrorMessages(error)
            })
        },
        cancelAction(){
            this.confirmation_dialog_state = false
        }
    },
    created() {
        getMappedUnitTypes(this.websiteId, this.unitType.id).then(response => {
            this.mappedTypes = response.data
        })
        getBtUnitTypeList(this.websiteId).then(response => {
            this.btUnitType = response.data
        })
    }
}
</script>

<style scoped>

.editName{
    padding-bottom: 1rem;
}

.languageDiv{
    padding-bottom: 1rem;
}

.addItems{
    padding-bottom: 1rem;
}

</style>
