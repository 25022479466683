<template>
    <div class="main_calendar--cell d-flex align-items-center justify-content-center year-sticky_test ">
            <div :id="tooltipTarget" class="main_calendar_test__holder--sidebar-h1 mt-4 pl-3 ml-0" style="width: 21rem;">{{shortUnitName}}</div>
        <div v-if="revenueManagerSetupLinkVisible"
             style="text-align: center">
            <span @click="$emit('yearAside', {unitType, unit,currentYear,is_contigent:contigents.length > 0})" :class="classList" style="margin-top: -1.2rem;" class="font-weight-bold calendar-year">{{currentYear}}</span>
            <app-button :disabled="revenueManagerSetupLinkDisabled"
                        v-if="revenueManagerSetupLinkVisible"
                        :id="'revenue_manager_setup_link_' + unit.id"
                        variant="link"
                        button_type="key"
                        :show_text="false"
                        @click="$emit('openRevenueManagerSetup', unit.id)">
            </app-button>
        </div>
        <span v-else @click="$emit('yearAside', {unitType, unit,currentYear,is_contigent:contigents.length > 0})" :class="classList" style="margin-top: -1.2rem;" class="font-weight-bold calendar-year">{{currentYear}}</span>
        <b-tooltip v-if="showShortName" placement="bottom" :target="tooltipTarget" triggers="hover">
            {{ unitType }}
        </b-tooltip>
        <b-tooltip v-if="revenueManagerSetupLinkVisible"
                   :target="'revenue_manager_setup_link_' + unit.id"
                   triggers="hover">
            {{ revenueManagerSetupLinkDisabled ? $t('ACTIVATION_UNIT_RM') : $t('SECURED_VALUES') }}
        </b-tooltip>
    </div>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppAside from "@/components/app/form/AppAside";
    import {C_UNIT_CALENDAR_YEARLY_VIEW_V} from '@/shared/component_permission'

    export default {
        name: "TableYearCell",
        components: {AppAside, AppButton},
        props: {
            unit: {
                type: Object
            },
            unitType: {
                type: String
            },
            contigents: {
                type: Array
            },
            dateList: {
                type: Array
            },
            scrollLeftPosition: {
                type: Number
            },
            showUnitType:{
                type:Boolean,
                default:false
            },
            revenueManagerOptions: {
                type: Object,
                default() {
                    return null
                },
            },
        },
        data() {
            return {
                currentYear: null,
                yearlyAside: false,
                C_UNIT_CALENDAR_YEARLY_VIEW_V: C_UNIT_CALENDAR_YEARLY_VIEW_V,
                shortNameCharacters:52,
            }
        },
        computed: {
            pixelsWide() {
                return 2.87 * 16
            },
            currentIndex() {
                return parseInt(this.scrollLeftPosition / this.pixelsWide)
            },
            classList() {
                if (this.checkPermission(C_UNIT_CALENDAR_YEARLY_VIEW_V)) {
                    return ['main_calendar-color_action', 'main_calendar--poiner']
                }
                return 'text-muted'
            },
            showShortName() {
                return this.unitType.length > this.shortNameCharacters
            },
            shortUnitName() {
                if (this.showShortName) {
                    return this.unitType.substr(0, this.shortNameCharacters) + '...'
                } else {
                    return this.unitType
                }
            },
            tooltipTarget() {
                return `tooltip_full_unit_type_name-${this.unit.id}`
            },
            revenueManagerSetupLinkVisible() {
                return this.revenueManagerOptions !== null && this.revenueManagerOptions.hasOwnProperty('enabled') && this.revenueManagerOptions.enabled
            },
            revenueManagerSetupLinkDisabled() {
                return this.revenueManagerOptions === null || (
                    (!this.revenueManagerOptions.hasOwnProperty('rates_activated') || !this.revenueManagerOptions.rates_activated) &&
                    (!this.revenueManagerOptions.hasOwnProperty('min_stay_activated') || !this.revenueManagerOptions.min_stay_activated)
                )
            },
        },
        methods: {
            calculateYear() {
                if (this.dateList.length > 0 && this.dateList.indexOf(this.currentIndex) === -1) {
                    let currYearMoment = this.dateList[this.currentIndex]
                    this.currentYear = currYearMoment ? parseInt(currYearMoment.format("YYYY")) : ''
                }
            }
        },
        watch: {
            scrollLeftPosition: {
                handler() {
                    this.calculateYear()
                },
                immediate: true
            },
            dateList:{
                handler(dates){
                    this.calculateYear()
                },deep:true
            }
        }
    }
</script>

<style scoped>
.calendar-year{
    font-size: .75rem;
    font-weight: 700;
    color: #52728b;

}
</style>
