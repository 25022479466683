<template>
    <div v-if="object" class="inbox_res">
        <div class="header b-b-1 mb-1 pb-3">
            <h6>{{$t("PROPERTY")}}</h6>
            <h5 v-if="_has(object, 'name')">{{object.name}}</h5>
            <ul>
                <li v-if="_has(object, 'property_type.label')">
                    {{$t("TYPE")}}: {{object.property_type.label}}
                </li>
            </ul>
        </div>
        <div class="inbox-items">
            <div class="item">
                <div>{{$t("ADDRESS")}}</div>
                <div>
                    <template v-if="_get(object, 'location.address')">{{object.location.address}}
                        {{object.location.address_no}}
                    </template>
                </div>
            </div>
            <div class="item">
                <div>{{$t("CITY")}}</div>
                <div>
                    <template v-if="_get(object, 'location.postal_number')">{{object.location.postal_number}}
                    </template>
                    <template v-if="_get(object, 'location.city.name')">, {{object.location.city.name}}</template>
                </div>
            </div>
            <div class="item">
                <div>{{$t("CHECKIN")}}</div>
                <div>
                    <template v-if="_get(object, 'property_hours.checkin.from')">
                        {{object.property_hours.checkin.from}} - {{object.property_hours.checkin.to}}
                    </template>
                </div>
            </div>
            <div class="item">
                <div>{{$t("CHECKOUT")}}</div>
                <div>
                    <template v-if="_get(object, 'property_hours.checkout.from')">
                        {{object.property_hours.checkout.from}} - {{object.property_hours.checkout.to}}
                    </template>
                </div>
            </div>
            <div class="item">
                <div>{{$t("PARKING")}}</div>
                <div>
                    <template v-if="_get(object, 'details.parking.label')">
                        {{object.details.parking.label}}
                    </template>
                </div>
            </div>
            <div class="item">
                <div>{{$t("WIFI")}}</div>
                <div>
                    <template v-if="_get(object, 'details.wifi.label')">
                        {{object.details.wifi.label}}
                    </template>
                </div>
            </div>
            <div class="item">
                <div>{{$t("PETS")}}</div>
                <div>
                    <template v-if="_get(object, 'details.pets.label')">
                        {{object.details.pets.label}}
                    </template>
                </div>
            </div>
            <div class="item">
                <div>{{$t("COT")}}</div>
                <div>
                    <template v-if="object.details.cot === 1">{{$t("YES")}}</template>
                    <template v-else>{{$t("NO")}}</template>
                </div>
            </div>
            <div class="item">
                <div>{{$t("CHILDREN")}}</div>
                <div>
                    <template v-if="object.details.children === 1">{{$t("YES")}}</template>
                    <template v-else>{{$t("NO")}}</template>
                </div>
            </div>
            <div class="item">
                <div>{{$t("BAGGAGE_DROPOFF")}}</div>
                <div>
                    <div>
                        {{$t("BEFORE_CHECKIN")}}:
                        <template v-if="object.baggage_dropoff.before_checkin === 1">{{$t("YES")}}</template>
                        <template v-else>{{$t("NO")}}</template>
                    </div>
                    <div>
                        {{$t("AFTER_CHECKOUT")}}:

                        <template v-if="object.baggage_dropoff.after_checkout === 1">{{$t("YES")}}</template>
                        <template v-else>{{$t("NO")}}</template>

                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {getProperty} from "@/services/property";
    import {cloneDeep, has, get} from 'lodash'

    export default {
        name: "InboxProperty",
        props: {
            property: {
                type: Object | Number
            }
        },
        data() {
            return {
                object: null
            }
        },
        methods: {
            fetch(id) {
                getProperty(id).then(response => {
                    this.object = cloneDeep(response.data)
                })
            },
            _has(object, path) {
                return has(object, path)
            },
            _get(object, path) {
                return get(object, path)
            }
        },
        watch: {
            property: {
                handler(val) {
                    if (typeof val === 'number') {
                        this.fetch(val)
                    } else {
                        this.object = cloneDeep(val)
                    }
                },
                immediate: true,
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>
