<template>
    <b-form @submit.prevent="save">
        <b-row :key="objectType.id" v-for="objectType in list">
            <b-col :key="objectEvent.id" md="6" lg="4" v-for="objectEvent in objectType.object_event">
                <b-card class="mb-3" body-class="p-0">
                    <b-list-group>
                        <b-list-group-item class="list-group-item-title font-weight-bold">
                            {{objectEvent.event_label}}
                            <template v-if="objectEvent.object.length <= 1">
                                <template v-for="object in objectEvent.object">
                                    <b-form-checkbox
                                        :key="object.id"
                                        :id="`contact_${contact.id}_event_${objectEvent.event_id}_object_${object.id}`"
                                        :name="`contact_${contact.id}_event_${objectEvent.event_id}_object_${object.id}`"
                                        :unchecked-value="false"
                                        :value="true"
                                        inline
                                        class="pull-right m-0 p-0"
                                        v-model="object.value">
                                    </b-form-checkbox>
                                </template>
                            </template>
                            <template v-else>
                                <b-form-checkbox :unchecked-value="false"
                                                 :value="true"
                                                 inline
                                                 class="pull-right m-0 p-0"
                                                 v-model="objectEvent.value"
                                                 @change="selectAll($event, objectType.id, objectEvent.event_id)">
                                </b-form-checkbox>
                            </template>
                        </b-list-group-item>
                        <template v-if="objectEvent.object.length > 1">
                            <b-list-group-item :key="object.id" v-for="object in objectEvent.object">
                                <b-form-checkbox
                                    :id="`contact_${contact.id}_event_${objectEvent.event_id}_object_${object.id}`"
                                    :name="`contact_${contact.id}_event_${objectEvent.event_id}_object_${object.id}`"
                                    :unchecked-value="false"
                                    :value="true"
                                    class="d-flex align-items-end"
                                    @change="setGroupSelected(objectType.id, objectEvent.event_id)"
                                    v-model="object.value">
                                    {{object.label}}
                                </b-form-checkbox>
                            </b-list-group-item>
                        </template>
                    </b-list-group>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button-submit :c_permission="cPermissionE" :disabled="notChanged" :loading="saving"></app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {cloneDeep, isEqual} from 'lodash'
    import {updateContactMapping} from '@/services/contacts/index'
    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'

    export default {
        name: "AppContactObjectTypeList",
        components: {AppButtonSubmit},
        mixins: [getErrorMessage],
        props: {
            objectType: {
                type: Array
            },
            contact: {
                type: Object
            },
            cPermissionE: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                list: [],
                saving: false
            }
        },
        computed: {
            notChanged() {
                return isEqual(this.list, this.objectType)
            }
        },
        methods: {
            updateList() {

            },
            selectAll($event, objectTypeId, eventId) {
                let firstIndex = this.list.findIndex(item => {
                    return objectTypeId === item.id
                })
                if (firstIndex >= 0 && this.list[firstIndex].hasOwnProperty('object_event')) {
                    let secondIndex = this.list[firstIndex].object_event.findIndex(item => {
                        return eventId === item.event_id
                    })
                    if (secondIndex >= 0 && this.list[firstIndex].object_event[secondIndex].hasOwnProperty('object')) {
                        this.list[firstIndex].object_event[secondIndex].object.forEach(item => {
                            item.value = $event
                        })
                    }
                }
            },
            setGroupSelected(objectTypeId, eventId) {
                let objectTypeIndex = this.list.findIndex(item => {
                    return objectTypeId === item.id
                })
                if (this.list[objectTypeIndex].hasOwnProperty('object_event')) {
                    let objectEventIndex = this.list[objectTypeIndex].object_event.findIndex(item => {
                        return eventId === item.event_id
                    })
                    this.$nextTick(() => {
                        let list = this.list[objectTypeIndex].object_event[objectEventIndex].object
                        if (list.some(e => e.value === false)) {
                            this.$set(this.list[objectTypeIndex].object_event[objectEventIndex], 'value', false)
                        } else {
                            this.$set(this.list[objectTypeIndex].object_event[objectEventIndex], 'value', true)
                        }
                    })
                }
            },
            getListWithGroupSelected(loadedList) {
                loadedList.forEach((objectType, objectTypeIndex) => {
                    if (objectType.hasOwnProperty('object_event')) {
                        objectType.object_event.forEach((objectEvent, objectEventIndex) => {
                            if (objectEvent.object.length > 1) {
                                if (objectEvent.object.some(e => e.value === false)) {
                                    loadedList[objectTypeIndex].object_event[objectEventIndex].value = false
                                } else {
                                    loadedList[objectTypeIndex].object_event[objectEventIndex].value = true
                                }
                            }
                        })
                    }
                })
                return loadedList
            },
            save() {
                let listRequest = []
                this.list.forEach(item => {
                    item.object_event.forEach(eventItem => {
                        let itemFormulated = eventItem.object.filter(objectItem => objectItem.value === true).map(objectItem => {
                            return {
                                object: objectItem.id,
                                object_type: item.id,
                                event: eventItem.event_id
                            }
                        })
                        if (itemFormulated.length > 0) {

                            listRequest = [...listRequest, ...itemFormulated]
                        }
                    })
                })

                if (listRequest.length === 0) {
                    // kakica
                }

                this.saving = true
                updateContactMapping(this.contact.id, {event_object: listRequest}).then(response => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.$emit('reload')
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                }).finally(() => this.saving = false)
            }
        },
        watch: {
            objectType: {
                handler(val) {
                    this.list = cloneDeep(this.getListWithGroupSelected(val))
                },
                immediate: true,
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>
