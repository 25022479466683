<template>
    <b-card>
        <div class="d-flex justify-content-start align-items-center" v-if="loading">
            <b-spinner variant="primary"></b-spinner>
        </div>
        <div class="d-flex justify-content-between" v-else>
            <div class="d-flex flex-column justify-content-between">
                <div class="header-2">{{$t("NIGHTS_SOLD")}}</div>
                <div class="header-1">{{value}}</div>
            </div>
            <i class="fa fa-moon-o fa-fw large-icon " aria-hidden="true"></i>
        </div>
    </b-card>
</template>

<script>
    import {getNights} from "@/services/dashboard";

    export default {
        name: "DashboardNumberOfNights",
        props: {
            company: {
                type: Number,
            },
            property: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                loading: false,
                value: 0
            }
        },
        methods: {
            getData() {

                this.loading = true
                getNights(this.company, this.property ? this.property : null).then(response => {
                    if (response.data.hasOwnProperty('nights')) {
                        this.value = response.data.nights
                    }
                    this.loading = false
                })
            }
        },
        watch: {
            property: {
                handler() {
                    this.getData()
                }
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>

</style>
