<template>
    <b-form @submit.prevent="save" autocomplete="off" v-if="paymentOrder" >
        <b-row>
            <b-col md="3" class="mb-4">
                <label>{{$t('LABEL')}}</label>
                <b-form-input
                    type="text"
                    :value="initialPaymentOrder.name"
                    disabled>
                </b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="3" class="mb-4">
                <label>{{$t('PAYOUT')}}</label>
                <app-number-input :value="initialPaymentOrder.amount"
                                  disabled
                                  allow-decimal>
                </app-number-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="3" class="mb-4">
                <label>{{$t('INVOICES')}}</label>
                <app-number-input :value="initialPaymentOrder.invoice_count"
                                  :controls="false"
                                  disabled>
                </app-number-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="3" class="mb-4">
                <label>{{$t('CREATED_AT')}}</label>
                <b-form-input
                    type="text"
                    :value="initialPaymentOrder.timestamp_created"
                    disabled>
                </b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="3" class="mb-4">
                <label>{{$t('STATUS')}}</label>
                <app-select
                    mode="select"
                    :options="invoiceStatusList"
                    text-field="label"
                    value-field="id"
                    v-model="initialPaymentOrder.status"
                    :search-empty-item="false"
                    :disabled="initialStatus === PAYMENT_ORDER_STATUS_CANCELLED"
                    >
                </app-select>
            </b-col>
        </b-row>
        <b-row class="ml-1">
            <div class="d-flex flex-grow-1 flex-wrap align-items-end">
                <app-button-submit :disabled="initialStatus === PAYMENT_ORDER_STATUS_CANCELLED" class="mr-3 mt-3">{{$t('SAVE')}}</app-button-submit>
                <app-button
                    v-if="checkPermission(C_FINANCE_PAYMENT_ORDER_CHECK) && initialStatus === PAYMENT_ORDER_STATUS_CREATED"
                    class="mr-3 mt-3"
                    @click="checkPaymentOrder"
                    :loading="checkPaymentOrderLoading">
                    {{ $t("CHECK") }}
                </app-button>
                <app-button
                    v-if="checkPermission(C_FINANCE_PAYMENT_ORDER_REGENERATE) && initialStatus=== PAYMENT_ORDER_STATUS_CREATED"
                    class="mr-3 mt-3"
                    @click="regeneratePaymentOrder"
                    :loading="generatePaymentOrderLoading">
                    {{ $t("GENERATE") }}
                </app-button>
            </div>
        </b-row>
    </b-form>
</template>

<script>
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppNumberInput from "@/components/app/form/AppNumberInput";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {checkPaymentOrder, editPaymentOrder, regeneratePaymentOrder} from "@/services/finance";
    import {notifySuccess, toast} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {C_FINANCE_PAYMENT_ORDER_CHECK, C_FINANCE_PAYMENT_ORDER_REGENERATE} from "@/shared/component_permission";
    import {PAYMENT_ORDER_STATUS_CANCELLED, PAYMENT_ORDER_STATUS_CREATED} from "@/shared/constants";
    import {cloneDeep} from "lodash";

    export default {
        name: "PaymentOrderGeneralForm",
        mixins: [getErrorMessage],
        components: {
            AppButton,
            AppSelect,
            AppNumberInput,
            AppButtonSubmit
        },
        data() {
            return {
                checkPaymentOrderLoading: false,
                generatePaymentOrderLoading: false,
                PAYMENT_ORDER_STATUS_CREATED,
                PAYMENT_ORDER_STATUS_CANCELLED,
                C_FINANCE_PAYMENT_ORDER_CHECK,
                C_FINANCE_PAYMENT_ORDER_REGENERATE,
                initialStatus: null,
                initialPaymentOrder: null
            }
        },
        props: {
            paymentOrderId: {
                type: Number
            },
            invoiceStatusList: {
                type: Array,
                default() {
                    return []
                }
            },
            paymentOrder: {
                type: Object
            }
        },
        methods: {
            save() {
                editPaymentOrder(this.paymentOrderId, {status: this.initialPaymentOrder.status}).then(() => {
                    notifySuccess()
                    this.$emit('paymentOrderSaved')
                }, error => {
                    this.showErrorMessages(error)
                })
            },
            regeneratePaymentOrder(){
                this.generatePaymentOrderLoading = true
                regeneratePaymentOrder(this.paymentOrderId).then(() => {
                    notifySuccess()
                    this.$emit('updatePaymentOrder')
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.generatePaymentOrderLoading = false
                })
            },
            checkPaymentOrder() {
                this.checkPaymentOrderLoading = true
                checkPaymentOrder(this.paymentOrderId).then((response) => {
                    const responseType = response.type
                    if (responseType === "application/json") {
                        toast({
                            title: this.$t("NOTIFICATIONS.PAYMENT_ORDER_CHECK_SUCCESS.TITLE"),
                            message: this.$t("NOTIFICATIONS.PAYMENT_ORDER_CHECK_SUCCESS.MESSAGE"),
                            type: "success",
                            timeout: 6000,
                        })
                        return
                    }
                    toast({
                        title: this.$t("NOTIFICATIONS.PAYMENT_ORDER_CHECK_FAIL.TITLE"),
                        message: this.$t("NOTIFICATIONS.PAYMENT_ORDER_CHECK_FAIL.MESSAGE"),
                        type: "error",
                        timeout: 6000,
                    })
                    let blob = new Blob([response], {type: 'application/vnd.ms-excel'});
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${this.paymentOrderId}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }, (error) => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.checkPaymentOrderLoading = false
                })
            },
        },
        created() {
                this.initialStatus= cloneDeep(this.paymentOrder.status)
                this.initialPaymentOrder= cloneDeep(this.paymentOrder)
        }
    }
</script>

<style scoped>

</style>
