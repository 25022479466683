<template>
    <b-form v-if="options !== null" @submit.prevent="save">
        <b-row>
            <b-col>
                <b-form-group>
                    <label>{{$t('TYPE')}}</label>
                    <app-select v-model="room.type_id" :options="options.rooms" text-field="label"></app-select>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group>
                    <label>{{$t('QUANTITY')}}</label>
                    <b-form-input v-model="room.amount" @change="change=true" type="number"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="d-flex justify-content-between">
                <room-submit :loading="saving" :disabled="saveDisabled"></room-submit>
                <room-delete @click="showDialog=true" classes="pull-right text-danger"
                             v-if="room.id !== null"></room-delete>
            </b-col>
        </b-row>
        <room-delete-dialog :show="showDialog" @confirm="deleteRoom"
                            @cancel="showDialog=false" :delete_title="true"></room-delete-dialog>
    </b-form>
</template>

<script>
    import RoomSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import RoomDelete from '@/components/app/AppButton/AppButtonDelete'
    import {getUnitRoomAndInventoryOptions} from '@/services/unit/index'
    import RoomDeleteDialog from '@/components/app/form/AppConfirmationDialog'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {toast} from '@/shared/plugins/toastr'
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {cloneDeep, isEqual} from 'lodash'

    export default {
        name: "UnitAmenityRoomForm",
        mixins: [getErrorMessage],
        components: {
            AppSelect,
            RoomSubmit, RoomDelete, RoomDeleteDialog
        },
        props: {
            room_id: {
                type: Number,
                default: null
            },
            data: {
                type: Object,
                default: () => {
                    return {
                        id: null, type_id: null, label: null, amount: null, inventory: []
                    }
                }
            }
        },
        data() {
            return {
                showDialog: false,
                room: {
                    id: null, type_id: null, label: null, amount: null, inventory: []
                },
                saving: false,
                change: false
            }
        },
        computed: {
            saveDisabled() {
                return isEqual(this.room, this.$store.getters['amenity/getRoom'](this.room_id)) || !this.room.type_id || !this.room.amount
            },
            options() {
                return this.$store.getters['amenity/getAmenityOptions']
            }
        },
        methods: {
            save() {
                if (this.room.id !== null) {
                    this.updateRoom()
                } else {
                    this.createRoom()
                }
            },
            createRoom() {
                this.saving = true
                this.$store.dispatch('amenity/actionCreateUnitRoom', {
                    unit_id: this.$route.params.unit_id,
                    data: this.room
                }).then((response) => {
                        this.$emit('createRoom', response.data.id)
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })
                        this.saving = false
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                        this.saving = false
                    }
                )
            },
            updateRoom() {
                this.saving = true
                this.$store.dispatch('amenity/actionUpdateUnitRoom', {
                    unit_id: this.$route.params.unit_id,
                    room_id: this.room.id,
                    data: this.room
                }).then(() => {
                        this.saving = false
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })

                    }, error => {
                        this.saving = false
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }
                )
            },
            deleteRoom() {
                this.$emit("deleteRoom")
            }
        },
        watch: {
            room_id() {
                this.change = false
                this.room = JSON.parse(JSON.stringify(this.$store.getters['amenity/getRoom'](this.room_id)))
            }
        },
        created() {
            if (this.room_id !== null) {
                this.room = cloneDeep(this.$store.getters['amenity/getRoom'](this.room_id))
                this.change = false
            }
        }
    }
</script>

<style scoped>

</style>
