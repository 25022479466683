<template>
    <b-card v-if="userData" class="property-wrapper">
        <template slot="header">
            <div class="header-caption">{{$t('USER')}}</div>
            <h2 class="property-wrapper-padding"> {{userData.first_name}} {{userData.last_name}}</h2>
            <navigation :ac-fetch-key="AC_USER_NAVIGATION" :navElements="navElements"></navigation>
        </template>

        <page-transition transition-type="slide">
            <router-view :userProp="userData"
                 :object_id="parseInt($route.params.id)"
                 v-cloak
                 @update="userData = $event">
            </router-view>
        </page-transition>

    </b-card>
</template>

<script>
    import UserBasicForm from '@/components/user/UserBasicForm'
    import UserPasswordForm from '@/components/user/UserPasswordForm'
    import UserRolesForm from '@/components/user/UserRolesForm'
    import PageTransition from "@/components/app/transition/AppTransition";
    import Navigation from "@/components/app/navigation/Navigation";
    import routeNames from "@/router/routeNames";
    import {fetchUserById} from "@/services/user";
    import UserNotificationsForm from "../../components/user/UserNotificationsForm";
    import {AC_USER_NAVIGATION} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {R_USER_CASH_REGISTER_SETUP} from "@/shared/route_permission";

    export default {
        name: "User",
        components: {
            Navigation,
            PageTransition,
            UserBasicForm, UserPasswordForm, UserRolesForm,UserNotificationsForm
        },
        data() {
          return {
              AC_USER_NAVIGATION,
              userData: null,
              navElements: [
                  {
                      route: {name: routeNames.RN_USER_BASIC},
                  },
                  {
                      route: {name: routeNames.RN_USER_ACCOUNT},
                  },
                  {
                      route: {name: routeNames.RN_USER_ROLES},
                  },
                  {
                      route: {name: routeNames.RN_USER_NOTIFICATION},
                  },
                  {
                      route: {name:routeNames.RN_USER_CASH_REGISTER_SETUP},
                      access_control_context: {
                          user: this.$route.params.id,
                          function: R_USER_CASH_REGISTER_SETUP,
                          key: AC_USER_NAVIGATION
                      }
                  }
              ],
              pageHeader: 'USER PROFILE'
          }
        },
        watch: {
            '$route.params.id': {
                handler(val) {
                    fetchUserById(val).then(res => {
                        this.userData = res.data
                    })
                },
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>
