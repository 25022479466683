<template>
    <b-container>
        <b-row v-if="loading">
            <b-col class="d-flex justify-content-center">
                <b-spinner></b-spinner>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col cols="12">
                <b-card no-body>
                    <b-card-header>
                        <b-row>
                            <b-col cols="3" class="mt-3">
                                <b-img fluid src="img/brand/logo-color.png" alt="logo"></b-img>
                            </b-col>
                            <b-col class="text-right mt-3">
                                <b-dropdown variant="link" size="sm" :text="text('LANGUAGE')">
                                    <b-dropdown-item :exact="true"
                                                     :to="{query: {lang:'hr',  token: this.$route.query.token}}">
                                        <i class="flag-icon flag-icon-hr"></i>
                                        Hrvatski
                                    </b-dropdown-item>
                                    <b-dropdown-item :exact="true"
                                                     :to="{query: {lang:'en', token: this.$route.query.token}}">
                                        <i class="flag-icon flag-icon-us"></i>
                                        English
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-col>
                        </b-row>
                    </b-card-header>
                    <b-card-body
                        v-if="acceptedTransition || Number(property.status.id) === STATUS_PROPERTY_UPDATE_COMPLETE ||
                    Number(property.status.id) === STATUS_ACTIVE">
                    <h4 class="mb-5">{{property.name}}</h4>
                    <p>
                        {{text("PUBLIC_PROPERTY_RESET_TEXT_1")}}
                    </p>
                    <p>
                        {{text("PUBLIC_PROPERTY_RESET_TEXT_SUCCESS_1")}}
                    </p>
                    <p>
                        {{text("PUBLIC_PROPERTY_RESET_TEXT_3", accManagerTranslateValues)}}
                    </p>
                    <p>
                        {{text("PUBLIC_PROPERTY_RESET_TEXT_4")}}
                    </p>
                    <p v-html="text('PUBLIC_PROPERTY_RESET_TEXT_SUCCESS_2')"></p>
                    <p>
                        {{text("PUBLIC_PROPERTY_RESET_TEXT_5")}}
                    </p>
                    </b-card-body>
                    <b-card-body v-else>
                        <h4 class="mb-5">{{property.name}}</h4>
                        <p>
                            {{text("PUBLIC_PROPERTY_RESET_TEXT_1")}}
                        </p>
                        <p>
                            {{text("PUBLIC_PROPERTY_RESET_TEXT_2")}}

                        </p>
                        <p class="mt-5 mb-5 text-center">
                            <b-form @submit.prevent="save">
                                <submit-button :loading="button_loading" button-class="pt-3 pb-3 pl-5 pr-5">
                                    {{text("ACCEPT")}}
                                </submit-button>
                            </b-form>
                        </p>
                        <p>
                            {{text("PUBLIC_PROPERTY_RESET_TEXT_3", accManagerTranslateValues)}}
                        </p>
                        <p>
                            {{text("PUBLIC_PROPERTY_RESET_TEXT_4")}}
                        </p>
                        <p>
                            {{text("PUBLIC_PROPERTY_RESET_TEXT_5")}}
                        </p>


                    </b-card-body>
                    <b-card-footer>
                        <b-row>
                            <b-col>
                                {{text("ACCOUNT_MANAGER")}}: {{property.account_manager.name}}
                            </b-col>
                            <b-col class="text-center">
                                <i class="fa fa-envelope-o fa-fw" aria-hidden="true"></i> E-mail:
                                <a :href="'mailto:' + this.property.account_manager.email">{{this.property.account_manager.email}}</a>
                            </b-col>
                            <b-col class="text-right">
                                <i class="fa fa-phone fa-fw" aria-hidden="true"></i> {{text("PHONE")}}:
                                <a :href="'phone:' + this.property.account_manager.phone">{{this.property.account_manager.phone}}</a>
                            </b-col>
                        </b-row>

                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import {getProperty, setStatus} from "@/services/property";

    import SubmitButton from '@/components/app/AppButton/AppButtonSubmit'
    import routes from '@/router/routeNames'
    import {
        STATUS_ACTIVE,
        STATUS_PROPERTY_UPDATE,
        STATUS_PROPERTY_UPDATE_COMPLETE,
        STATUS_TRANSITION_UPDATE_COMPLETE
    } from "@/shared/constants";

    export default {
        name: "PropertyReset",
        components: {
            SubmitButton
        },
        data() {
            return {
                transitionAccepted: false,
                property: null,
                loading: true,
                button_loading: false,
                language: 'en',
                STATUS_PROPERTY_UPDATE: STATUS_PROPERTY_UPDATE,
                STATUS_PROPERTY_UPDATE_COMPLETE: STATUS_PROPERTY_UPDATE_COMPLETE,
                STATUS_ACTIVE: STATUS_ACTIVE,
            }
        },
        computed: {
            accManagerTranslateValues() {
                return {
                    acc_manager_name: this.property.account_manager.name,
                }

            },
            acceptedTransition() {
                return this.transitionAccepted ? true : localStorage.getItem('userAcceptStatusTransition')
            }
        },
        methods: {
            async getToken() {
                await this.$store.dispatch('auth/actionMagicLinkLogin', this.$route.query.token).then(() => {
                    this.$store.dispatch('user/actionSetUser')
                    this.$store.dispatch('user/actionSetUserPermissions')
                }).then(() => {
                    this.fetchData()
                }).catch(() => {
                    //this.goTo404()
                })
            },
            async fetchData() {
                await getProperty(this.$route.params.id).then(response => {
                    this.property = response.data,
                        this.loading = false

                    if (![STATUS_PROPERTY_UPDATE, STATUS_PROPERTY_UPDATE_COMPLETE, STATUS_ACTIVE].includes(Number(this.property.status.id))) {
                        this.goTo404()
                    }
                }, () => {
                    this.loading = false
                    this.goTo404()
                })
            },
            save() {
                this.button_loading = true
                localStorage.setItem('userAcceptStatusTransition', true)
                this.transitionAccepted = true

                setStatus(this.property.id, STATUS_TRANSITION_UPDATE_COMPLETE, {message: 'changed by owner'}).then(() => {
                    this.fetchData()
                    this.button_loading = false
                })
            },
            text(name, placeholders = {}) {
                return this.$t(name, this.language, placeholders)
            },
            goTo404() {
                this.$router.replace({name: routes.RN_404})
            }
        },
        watch: {
            '$route.query.lang': {
                handler(lang) {
                    if (lang) {
                        this.language = lang
                    } else {
                        this.$router.replace({query: {lang: 'en', token: this.$route.query.token}})
                    }
                },
                immediate: true
            },
            '$route.query.token': {
                async handler(token) {
                    if (token) {
                        await this.getToken()
                    } else {
                        this.goTo404()
                    }

                },
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>
