<template>
    <form @submit.prevent="search" class="filter" autocomplete="off">
        <b-row>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('PROPERTY')}}</label>
                <app-select mode="search" :search-on-create="preloadPropertyList" :search="searchProperty"
                            v-model="property"></app-select>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('UNIT')}}</label>
                <app-select :disabled="!property.id" :options="unit_list" v-model="filter.unit"></app-select>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('RESERVATION_CODE')}}</label>
                <b-input v-model="filter.reservation" type="text"></b-input>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('FIRST_NAME')}}</label>
                <b-input v-model="filter.first_name" type="text"></b-input>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t('LAST_NAME')}}</label>
                <b-input v-model="filter.last_name" type="text"></b-input>
            </b-col>


        </b-row>

        <b-row>
            <b-col xl="2" lg="4" md="6">
                <label>{{$t("_DATE")}}</label>
                <app-select :options="dateOptions" v-model="dateOptionValue"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("FROM")}}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="fromDate" :max-date="toDate"></app-date-picker>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("TO")}}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="toDate" :min-date="fromDate" :from-date="fromDate"></app-date-picker>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("STATUS")}}</label>
                <app-select v-model="filter.status" :options="status_options"></app-select>
            </b-col>

            <b-col>
                <app-submit class="filter-submit-wrapper" :inline="true" :loading="loaderSubmit" :disabled="disabledSearch" variant="primary"
                            button_type="search">{{ $t("SEARCH") }}
                </app-submit>
                <app-button-reset @click="resetForm" :disabled="disabledSearch" :loading="loaderReset"  :inline="true"  class="pl-2 ml-2"   type="reset"></app-button-reset>
            </b-col>
        </b-row>
    </form>
</template>

<script>

    import AppSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {getPropertyList} from "@/services/property";
    import {getUnitList} from "@/services/unit";
    import {pickBy, cloneDeep} from 'lodash'
    import AppDatePicker from "@/components/app/datetime/AppDatePicker";
    import {DEFAULT_PAGINATION_LIMIT, LS_PRESETS} from "@/shared/constants";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";

    export default {
        name: "guest-filter",
        props: {
            loading: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                preloadPropertyList: false,
                status_options: [
                    {id: 1, name: this.$t('REGISTERED')},
                    {id: 0, name: this.$t('NOT_REGISTERED')}
                ],
                dateOptions: [
                    {id: 'check_in', name: this.$t('CHECK_IN')},
                    {id: 'check_out', name: this.$t('CHECK_OUT')}
                ],
                dateObject: {},
                dateOptionValue: null,
                fromDate: null,
                toDate: null,
                property: {
                    id: null,
                    name: null
                },
                unit_list: [],
                filter: {
                    property: null,
                    unit: null,
                    reservation: null,
                    first_name: null,
                    last_name: null,
                    status: null,
                    lang: this.$i18n.locale,
                },

                initialFilter: {
                    property: null,
                    unit: null,
                    check_in_date: null,
                    check_out_date: null,
                    reservation: null,
                    guest_name: null,
                    status: null
                },
                resetFilter:0,
                loaderReset:false,
                loaderSubmit:false
            }
        },
        components: {
            AppButtonReset,
            AppDatePicker,
            AppSelect, AppSubmit
        },
        computed: {
            company() {
                return this.$store.getters['user/getCompany']
            },
            disabledSearch(){
                return this.loaderReset || this.loading
            }
        },
        methods: {

            searchProperty(value) {
                return getPropertyList({name: value, company_id: this.company}).then(response => {
                    return {data: response.data.items}
                })
            },
            searchUnitList(value) {
                getUnitList({property_id: value}).then(response => {
                    this.unit_list = response.data.items
                })
            },
            search() {
                this.resetFilter = 0
                this.$emit('search', {...this.filter, ...this.dateObject, company: this.company})
            },
            resetForm() {
                this.filter = cloneDeep(this.initialFilter)
                this.filter.status = null
                this.property = {}
                this.filter.company = this.company
                this.dateObject = {}
                this.dateOptionValue = null
                this.resetFilter = 1
                this.$emit('search', {...this.filter,  company: this.company})
            }
        },
        watch: {
            property: {
                handler(data) {
                    if (data.id) {
                        this.filter.property = data.id
                        this.searchUnitList(data.id)
                    } else {
                        this.filter.property = null
                        this.unit_list = []
                    }

                },
                immediate: true,
                deep: true
            },
            fromDate(value) {
                if (this.dateOptionValue) {
                    this.dateObject[this.dateOptionValue + '_from_date'] = value
                }
            },
            toDate(value) {
                if (this.dateOptionValue) {
                    this.dateObject[this.dateOptionValue + '_to_date'] = value
                }
            },
            dateOptionValue(value, oldValue) {
                if (oldValue !== undefined) {
                    this.fromDate = null
                    this.toDate = null
                    this.dateObject = {}
                }
            },
            loading:{
                handler(value){
                    if(this.resetFilter){
                        this.loaderReset = value
                    } else {
                        this.loaderSubmit = value
                    }
                }
            }
        },
        created() {
            let storagePresets = localStorage.getItem(LS_PRESETS)
            if (storagePresets) {
                storagePresets = JSON.parse(storagePresets)
                if (storagePresets.hasOwnProperty('properties') && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                    this.preloadPropertyList = true
                }
            }
        }
    }
</script>

<style scoped>

</style>
