<template>
    <div>
        <website-reservation-filter @search="setFilter" :loading="loading"></website-reservation-filter>
        <accommodation-reservation-table @busyToggled="toggleBusy" :filter="filter" :sort="sort" :website="true"></accommodation-reservation-table>
    </div>
</template>

<script>
import AccommodationReservationTable from "@/components/reservation/accommodation/list/AccommodationReservationTable";
import WebsiteReservationFilter from "@/components/direct_booking/website/reservation/WebsiteReservationFilter";
import Pagination from '@/components/app/filter/AppPagination'
import AppButton from "@/components/app/AppButton/AppButton";
import InformationModule from "@/components/reservation/accommodation/modules/InformationModule";


export default {
    name: "Index",
    components: {
        InformationModule,
        AppButton,
        Pagination,
        AccommodationReservationTable,
        WebsiteReservationFilter
    },
    data() {
        return {
            filter: null,
            loading: false,
            sort: null,
            reservationSidebarState: false,
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    methods: {
        setFilter(values) {
            this.sort = values.sort
            this.filter = values.filter
        },
        toggleBusy($event) {
            this.loading = $event
        }
    }
}
</script>

<style scoped>

</style>
