<template>
    <div>
        <app-no-data v-if="noData"></app-no-data>
        <b-row v-else-if="showGrid">
            <b-col v-for="data in website" cols="12" class="dashboard-wrapper col-xxl-6">
                <b-card>
                    <b-row>
                        <b-col md="6" lg="9" class="d-flex align-items-start flex-column">
                            <div class="header-2 mb-2">
                                <b-img v-if="data.logo" style="width: 54px;" :src="data.logo"
                                       alt="logo"></b-img>
                                <b-img v-else style="width: 54px;" :src="data.icon"
                                       alt="icon"></b-img>
                                <span class="ml-3">{{data.name}}
                                    <b-link :href="'https://'+data.slug"
                                            target="_blank"
                                            class="ml-2">
                                        <i class="fa fa-globe" aria-hidden="true"></i>
                                    </b-link>
                                </span>
                            </div>
                            <div>
                                <form>
                                    <b-row>
                                        <b-col>
                                            <div class="header-info">
                                                {{$t('ACTIVE_PARTNERS_WEBSITE', {value: data.active_company_count})}}
                                                | {{$t('ACTIVE_UNITS_WEBSITE', {value: data.active_unit_count})}}
                                            </div>
                                        </b-col>
                                    </b-row>
                                </form>
                            </div>
                            <div class="d-flex no-wrap mt-4 col-lg-48">
                                <div class="item">
                                    <div class="item-content">
                                        <span v-if="!data.visible">{{data.desc.slice(0,200)}}
                                            <b-link v-if="!data.visible  && data.desc.length > 200" @click="activateReadMore(data)">
                                                <b>...{{$t('SHOW_MORE')}}</b>
                                            </b-link>
                                        </span>

                                        <span v-if="data.visible">{{data.desc}}
                                            <b-link v-if="data.visible  && data.desc.length > 200" @click="data.visible = false">
                                                <b>{{$t('SHOW_LESS')}}</b>
                                            </b-link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                        <b-col class="bl-lg-show">
                            <b-row class="pb-5 d-flex ml-5 pl-5">
                                <b-col>
                                    <b-badge variant="primary">{{data.current_status}}</b-badge>
                                </b-col>
                            </b-row>
                            <b-row class="mt-5">
                                <b-col>
                                    <app-button v-if="!data.active" id="test-tool" @click="openRequest(data)" class="mt-4">
                                        {{$t('PARTNERSHIP_REQUEST')}}
                                    </app-button>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <app-aside v-model="request">
            <template slot="header"><app-object-header :label="website_name" :name="$t('ACTIVATION_REQUEST')"></app-object-header></template>
            <template v-slot="props">
                <marketplace-request-form :website-object="websiteObject" @requestSent="closeRequest"></marketplace-request-form>
            </template>
        </app-aside>
        <app-aside v-model="desc">
            <template slot="header"><app-object-header :name="$t(formType)"></app-object-header></template>
            <template v-slot="props">
                <marketplace-descriptive-form :form-type="formType"
                                              :company-id="company_id"
                                              :website-id="website_id"
                ></marketplace-descriptive-form>
            </template>
        </app-aside>
    </div>
</template>

<script>

import {getMarketplaceList} from "@/services/marketplace";
import AppButton from "@/components/app/AppButton/AppButton";
import ChartDoughnut from "@/components/app/Charts/ChartDoughnut";
import AppAside from "@/components/app/form/AppAside";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import AppNoData from "@/components/app/AppNoData";
import MarketplaceRequestForm from "@/components/direct_booking/marketplace/list/MarketplaceRequestForm";
import MarketplaceDescriptiveForm from "@/components/direct_booking/marketplace/list/MarketplaceDescriptiveForm";

export default {
    name: "MarketplaceGridList",
    components:{
        AppButton,
        ChartDoughnut,
        AppNoData,
        AppAside,
        AppObjectHeader,
        MarketplaceRequestForm,
        MarketplaceDescriptiveForm
    },
    data(){
        return {
            website: [],
            dataCollection: null,
            showGrid: false,
            noData: false,
            request: false,
            websiteObject: [],
            desc: false,
            formType: null,
            company_id: null,
            website_id: null,
            readMore: false,
            website_name: null,
        }
    },
    computed:{
        customStyle() {
            return {
                height: '60px',
                position: 'relative',
                width: '60px'
            }
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods:{
        getData(){
            getMarketplaceList({company: this.company.id}).then(response => {
                response.data.length > 0 ? this.showGrid = true : this.noData = true
                this.website = response.data
            })
        },
        fillChartData(value) {
            return {
                labels: [this.$t("ACTIVE"), this.$t('INACTIVE')],
                datasets: [
                    {
                        label: 'Data One',
                        backgroundColor: [
                            '#fb6b41',
                            '#e8e8e8'
                        ],
                        data: [value.active, value.inactive]
                    }
                ]
            }
        },
        openRequest(data){
            this.websiteObject = []
            this.websiteObject.push(data)
            this.website_name = data.name
            this.request = true
        },
        closeRequest(){
            this.request = false
            this.getData()
        },
        openForm(type, data){
            this.formType = type
            this.desc = true
            this.website_id = data.id
            this.company_id = data.company
        },
        calculatePercentage(active){
            if (active){
                return 100
            }else{
                return 0
            }
        },
        activateReadMore(data){
            data.visible = true
        }
    },
    created() {
        this.getData()
    }
}
</script>

<style scoped>

.item-content > span {
    display: inline-block;
    min-width: 100px;
}

</style>
