<template>
    <div>
        <b-form @submit.prevent="submitForm" v-if="contactData && contactChannelList.length && contactTypeList.length">
            <b-row>
                <b-col sm="12" class="mb-3">
                    <label>{{$t('CHANNEL')}}</label>
                    <app-select v-model="contactData.channel.id" :options="contactChannelList" text-field="label"></app-select>

                </b-col>
                <b-col sm="12" class="mb-3">
                    <label>{{$t('CONTACT')}}</label>
                    <b-input id="contact" v-model="contactData.contact" type="text"></b-input>
                </b-col>
                <b-col sm="12" class="mb-3">
                    <label>{{$t('DESCRIPTION')}}</label>
                    <b-input id="description" v-model="contactData.description" type="text"
                    ></b-input>
                </b-col>
                <b-col>
                    <b-form-group>
                        <label>{{$t('USE_FOR')}}</label>
                        <b-form-checkbox-group id="checkbox-group-2" v-model="selected" name="flavour-2">
                            <b-form-checkbox :key="index" v-for="(contactType, index) in contactTypeList"
                                             :value="contactType.contact_event.id">
                                {{contactType.contact_event.label}}
                            </b-form-checkbox>

                        </b-form-checkbox-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col>
                    <new-contact-submit :disabled="disabledSave" :loading="saveState"
                                        @click="submitForm"></new-contact-submit>
                </b-col>
                <b-col v-if="!isNew">
                    <new-contact-delete :loading="deleteState" @click="deleteDialogState=true"
                                        classes="pull-right text-danger"></new-contact-delete>
                </b-col>
            </b-row>

            <contact-delete-dialog :show="deleteDialogState" @confirm="deleteContact"
                                   @cancel="deleteDialogState=false" :delete_title="true">
            </contact-delete-dialog>

        </b-form>
        <div v-else class="center">
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
        </div>

    </div>
</template>

<script>
    import NewContactSubmit from '@/components/app/AppButton/AppButton'
    import NewContactDelete from '@/components/app/AppButton/AppButtonDelete'
    import ContactDeleteDialog from '@/components/app/form/AppConfirmationDialog'
    import {
        createNewContact,
        deleteNewContact,
        getContactChannelList,
        getContactTypeList, getNewOwnerContactList,
        updateNewContact
    } from "@/services/owner";
    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {required} from 'vuelidate/lib/validators'
    import ValidationDescription from '@/components/app/form/AppValidationDescription'
    import AppSelect from "@/components/app/AppSelect/AppSelect";

    export default {
        name: "ContactForm",
        props: {
            newContact: {
                type: Object,
            },
            object_type: {
                type: Number,
                required: true
            },
            object_id: {
                type: Number,
                required: true
            }
        },
        mixins: [getErrorMessage],
        data() {
            return {
                deleteDialogState: false,
                contactData: Object.assign({
                    id: null,
                    contact: null,
                    description: null,
                    channel: {
                        id: null,
                        label: null,
                    }
                }, this.newContact),
                selected: [], // Must be an array reference!
                saveState: false,
                deleteState: false,
                channel: null,
                email_type: 1,
                phone_type: 2,
                contactTypeList: [],
                contactChannelList: []
            }
        },
        components: {
            AppSelect,
            NewContactSubmit, NewContactDelete, ContactDeleteDialog, ValidationDescription
        },
        computed: {
            isNew() {
                return this.newContact === null
            },
            disabledSave() {

                if (this.contactData.description === '' || this.contactData.description === null) {
                    return true
                }
                if (this.contactData.contact === null || this.contactData.contact === '') {
                    return true
                }
                if (this.contactData.channel.id === null || this.contactData.channel.id === '') {
                    return true
                }
                if (this.selected.length === 0) {
                    return true
                }
                return false
            }
        },

        methods: {
            submitForm() {
                this.saveState = true
                if (this.isNew) {
                    this.addNewContact()
                } else if (!this.isNew) {
                    this.updateNewContact()
                }
            },
            deleteContact() {
                this.deleteState = true
                deleteNewContact(this.contactData.id).then(() => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.DELETE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.DELETE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                    this.deleteDialogState = false
                    this.getList().then(res => {
                        this.$emit('closeNewContactAside', res.data)
                    })
                }, error => {
                    this.deleteState = false
                    toast({
                        'title': this.$t('NOTIFICATIONS.DELETE_ERROR.TITLE'),
                        'message': this.getMessages(error),
                        'type': 'error',
                        'timeout': 2000
                    })
                })

            },
            addNewContact() {
                let contactObject = {
                    contact_channel: this.contactData.channel.id,
                    contact_value: this.contactData.contact,
                    description: this.contactData.description,
                    object_type: this.object_type,
                    object: this.object_id,
                    contact_event: this.selected
                }

                createNewContact(contactObject)
                    .then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 2000
                        })
                        return this.getList()
                    })
                    .then(res => {
                        this.$emit('closeNewContactAside', res.data)
                    })
                    .catch(error => {
                        this.saveState = false
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 2000
                        })
                    })

            },
            updateNewContact() {

                let contactObject = {
                    contact_channel: this.contactData.channel.id,
                    contact_value: this.contactData.contact,
                    description: this.contactData.description,
                    contact_event: this.selected
                }

                updateNewContact(this.contactData.id, contactObject)
                    .then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 2000
                        })
                        return this.getList()
                    })
                    .then((res) => {
                        return this.$emit('closeNewContactAside', res.data)
                    })
                    .catch(error => {
                        this.saveState = false
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 2000
                        })
                    })
            },
            getList() {
                return getNewOwnerContactList({
                    object_type: this.object_type,
                    object: this.object_id
                })
            }
        },
        created() {
            if (this.newContact) {
                if (this.newContact.contact_event.length > 0) {
                    for (let event of this.newContact.contact_event) {
                        this.selected.push(event.id)
                    }
                }
            }

            getContactTypeList({object_type: this.object_type}).then(res => {
                this.contactTypeList = res.data
            })

            getContactChannelList({object_type: this.object_type}).then(res => {
                this.contactChannelList = res.data
            })
        },
        validations: {
            contactData: {
                name: {
                    required
                }
            }
        },
    }
</script>

<style scoped>
    .center {
        position: absolute;
        top: 50%;
        left: 50%;
    }
</style>
