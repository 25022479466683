<template>
    <div>
        <b-form @submit.prevent="save">
            <b-row>
                <b-col md="8" class="mb-4">
                    <label>{{ $t('NAME') }}</label>
                    <b-input type="text" v-model="formData.name"></b-input>
                </b-col>

                <b-col md="4" class="mb-4">
                    <label>{{ $t('UNIT_OF_MEASUREMENT') }}</label>
                    <b-input type="text" v-model="formData.unit"></b-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="mb-4">
                    <app-button-submit :c_permission="C_SETUP_FINANCE_ITEM_DESCRIPTION_E" :loading="saving"></app-button-submit>
                </b-col>

                <b-col md="6">
                    <app-button-delete
                        v-if="articleObject.id"
                        :c_permission="C_SETUP_FINANCE_ITEM_DESCRIPTION_E"
                        class="pull-right"
                        @click="deleteDialogState=true">
                    </app-button-delete>

                    <app-confirmation-dialog
                        :show="deleteDialogState"
                        @confirm="_delete"
                        :delete_title="true"
                        @cancel="deleteDialogState=false">
                    </app-confirmation-dialog>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
    import {cloneDeep} from "lodash";
    import AppNumberInput from "@/components/app/form/AppNumberInput";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {C_SETUP_FINANCE_ITEM_DESCRIPTION_E} from "@/shared/component_permission";
    import {createArticle, updateArticle, deleteArticle} from "@/services/finance/setup";
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";

    export default {
        name: "InvoiceItemForm",
        components: {AppButtonSubmit, AppNumberInput, AppButtonDelete, AppConfirmationDialog},
        mixins: [getErrorMessage],
        props: {
            articleObject: {
                type: Object,
                default: () => {return {}}
            }
        },
        data() {
            return {
                C_SETUP_FINANCE_ITEM_DESCRIPTION_E,
                formData: null,
                saving: false,
                deleteDialogState: false
            }
        },
        methods: {
            save() {
                this.saving = true
                const savePromise = !this.articleObject.id
                    ? createArticle(this.formData)
                    : updateArticle(this.articleObject.id, this.formData);
                savePromise.then(response => {
                    !this.articleObject.id ? this.$emit('created', response.data) : this.$emit('updated', response.data)
                    notifySuccess()
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => this.saving = false)
            },
            _delete() {
                this.saving = true
                deleteArticle(this.articleObject.id).then(() => {
                    this.$emit('deleted', this.articleObject.id)
                    notifySuccess()
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.saving = false
                    this.deleteDialogState = false
                })
            }
        },
        created() {
            this.formData = cloneDeep(this.articleObject)
        }
    }
</script>

<style scoped>

</style>
