<template>
    <div v-if="loaded && hasPermissionView && formData">
        <b-form @submit.prevent="saveDetails">
            <b-row>
                <b-col>
                    <form-header>{{$t("RESTRICTIONS")}}</form-header>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="3" class="mb-3">
                    <h6>{{$t('ALLOWED_IN_PROPERTY')}}</h6>
                    <b-checkbox id="childrenCheckbox" name="childrenCheckbox" :unchecked-value="0" :value="1"
                                v-model="formData.details.children" switch>
                        {{$t('CHILDREN')}}
                    </b-checkbox>
                    <b-checkbox id="cot" name="cot" :unchecked-value="0" :value="1" v-model="formData.details.cot"
                                switch>
                        {{$t('COT')}}
                    </b-checkbox>
                </b-col>
                <b-col md="3" class="mb-3">
                    <h6>{{$t('PAYMENT_METHOD')}}</h6>
                    <b-checkbox id="pm_cash" name="pm_cash" :unchecked-value="0" :value="1"
                                v-model="formData.payment_options.cash" switch :disabled="!checkPermission(C_PROPERTY_DETAILS_PAYMENT_METHOD)">
                        {{$t('CASH')}}
                    </b-checkbox>
                    <b-checkbox id="pm_cc" name="pm_cc" :unchecked-value="0" :value="1" :disabled="!checkPermission(C_PROPERTY_DETAILS_PAYMENT_METHOD)"
                                v-model="formData.payment_options.credit_card" switch>
                        {{$t('CREDIT_CARD')}}
                    </b-checkbox>

                </b-col>
                <b-col md="3" class="mb-3">
                    <h6>{{$t('RECEIVE_RESERVATIONS')}}</h6>
                    <b-checkbox id="via_email" name="via_email" :unchecked-value="0" :value="1"
                                v-model="formData.recieve_reservations_via.email"
                                switch>
                        Email
                    </b-checkbox>

                    <b-checkbox id="via_sms" name="via_sms" :unchecked-value="0" :value="1"
                                v-model="formData.recieve_reservations_via.sms" switch>
                        Sms
                    </b-checkbox>

                    <b-checkbox id="via_phone" name="via_phone" :unchecked-value="0" :value="1"
                                v-model="formData.recieve_reservations_via.phone"
                                switch>
                        {{$t('PHONE')}}
                    </b-checkbox>


                </b-col>
                <b-col md="3" class="mb-3">
                    <h6>{{$t('BAGGAGE_DROPOFF')}}</h6>

                    <b-checkbox :unchecked-value="0" :value="1" v-model="formData.baggage_dropoff.before_checkin"
                                switch>
                        {{$t('BEFORE_CHECKIN')}}
                    </b-checkbox>

                    <b-checkbox :unchecked-value="0" :value="1" v-model="formData.baggage_dropoff.after_checkout"
                                switch>
                        {{$t('AFTER_CHECKOUT')}}
                    </b-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <form-header>{{$t("AMENITIES")}}</form-header>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="3">
                    <b-form-group>
                        <label>{{$t('PETS')}}</label>
                        <app-select v-model="formData.details.pets.id" text-field="label"
                                    :options="options.pets"></app-select>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group>
                        <label>{{$t('PARKING')}}</label>
                        <app-select v-model="formData.details.parking.id" text-field="label"
                                    :options="options.parking"></app-select>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group>
                        <label>{{$t('WIFI')}}</label>
                        <app-select v-model="formData.details.wifi.id" text-field="label"
                                    :options="options.wifi"></app-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <form-header>{{$t("CHECKIN")}}</form-header>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="3">
                    <b-form-group>

                        <label>{{$t('CHECKIN_FROM')}}</label>
                        <timepicker v-model="formData.property_hours.checkin.from" :minute-step="30"></timepicker>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group>
                        <label>{{$t('CHECKIN_TO')}}</label>
                        <timepicker v-model="formData.property_hours.checkin.to" :minute-step="30"></timepicker>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <form-header>{{$t("CHECKOUT")}}</form-header>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="3">
                    <b-form-group>
                        <label>{{$t('CHECKOUT_FROM')}}</label>
                        <timepicker v-model="formData.property_hours.checkout.from" :minute-step="30"></timepicker>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group>
                        <label>{{$t('CHECKOUT_TO')}}</label>
                        <timepicker v-model="formData.property_hours.checkout.to" :minute-step="30"></timepicker>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <save-details-button
                        :access_control_context="{function:C_PROPERTY_DETAILS_E,key:AC_PROPERTY_DETAILS_KEY}"
                        @AccessControlEvent="addToAccessControlCounter()"
                        :button_type="saveState ? 'loading' : 'save'">
                    </save-details-button>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>

    import SaveDetailsButton from '@/components/app/AppButton/AppButtonSubmit'
    import Timepicker from '@/components/app/datetime/TimePicker'
    import {getAmenityOptionList} from '@/services/property'
    import WarningMessage from '@/components/app/form/AppValidationDescription'
    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {
        C_PROPERTY_DETAILS_V,
        C_PROPERTY_DETAILS_E,
        C_PROPERTY_DETAILS_PAYMENT_METHOD
    } from "@/shared/component_permission";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {cloneDeep} from "lodash";
    import FormHeader from "@/components/app/form/FormHeader";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {AC_PROPERTY_DETAILS_KEY} from "@/mixins/AccessControl/AccessControlEnumeration";

    export default {
        name: "Index",
        components: {
            FormHeader,
            AppSelect,
            SaveDetailsButton, timepicker: Timepicker, WarningMessage
        },
        mixins: [getErrorMessage,AccessControlComponent],
        data() {
            return {
                loaded: false,
                options: null,
                saveState: false,
                c_permission_v: C_PROPERTY_DETAILS_V,
                c_permission_e: C_PROPERTY_DETAILS_E,
                C_PROPERTY_DETAILS_PAYMENT_METHOD,
                C_PROPERTY_DETAILS_E,
                formData: null,
                access_control_components:1,
                access_control_fetch_key:AC_PROPERTY_DETAILS_KEY,
                AC_PROPERTY_DETAILS_KEY
            }
        },
        methods: {
            saveDetails() {
                this.$v.$touch()

                if (this.$v.$error) {
                    toast({
                        'title': "",
                        'message': this.$t('VALIDATION_FAILED'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return
                }

                this.$store.dispatch('property/actionUpdatePropertyBasic', {
                    id: this.$route.params.id, data: this.formData
                }).then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })
                    }, error => {

                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }
                ).finally(() => this.saveState = false)
            },
            populateFormData() {
                this.formData = cloneDeep({
                    details: this.$store.getters.getItem('property.basic.details'),
                    baggage_dropoff: this.$store.getters.getItem('property.basic.baggage_dropoff'),
                    property_hours: this.$store.getters.getItem('property.basic.property_hours'),
                    recieve_reservations_via: this.$store.getters.getItem('property.basic.recieve_reservations_via'),
                    payment_options: this.$store.getters.getItem('property.basic.payment_options'),
                })
            }
        },
        computed: {
            hasPermissionView() {
                return this.$store.getters['user/getPermission'](this.c_permission_v)
            },
            property() {
                return this.$store.getters['property/getProperty']
            }
        },
        validations: {
            formData: {
                details: {}
            }
        },
        mounted() {
            getAmenityOptionList(this.$route.params.id).then(response => {
                this.options = response.data
                this.loaded = true
            }, () => {
                this.options = null
                this.loaded = false
            })
        },
        created() {
            if (this.property.hasOwnProperty('id')) {
                this.populateFormData()
            } else {
                this.$store.dispatch('property/actionSetProperty', this.$route.params.id).then(() => {
                    this.populateFormData()
                })
            }
            this.access_control_general_context = {
                property: this.$route.params.id,
                company: this.$store.getters['user/getCompany']
            }

        }
    }
</script>

<style scoped>

</style>
