<template>
    <li class="mb-2 b-b-1 mb-3 pb-3">
        <div class="d-flex justify-content-between">
            <div class="font-weight-bolder" v-html="highlightText"></div>
            <div>
                <app-button v-if="checkPermission(R_PROPERTY_OVERVIEW)" variant="link"
                            :button_type="null"
                            class="action_button"
                            @click="$emit('close')"
                            :to="{name: routes.RN_PROPERTY_OVERVIEW, params:{id: property.id}}">
                    {{$t("OVERVIEW")}}
                </app-button>
                <app-button v-if="checkPermission(R_PROPERTY_UNIT_LIST)"
                            variant="link"
                            :button_type="null"
                            class="action_button b-l-1 ml-2 pl-2"
                            @click="$emit('close')"
                            :to="{name: routes.RN_PROPERTY_UNIT_LIST, params:{id: property.id}}"
                            >
                    {{$t("UNITS")}}
                </app-button>
                <app-button
                    v-if="checkPermission(R_PROPERTY_CALENDAR_DEV)"
                    variant="link"
                    :button_type="null"
                    @click="$emit('close')"
                    class="action_button b-l-1 ml-2 pl-2"
                    :to="{name: routes.RN_PROPERTY_CALENDAR, params:{id: property.id}}">
                    {{$t("CALENDAR")}}
                </app-button>
            </div>
        </div>
        <ul class="pl-3 mt-2" v-if="property.hasOwnProperty('units') && property.units.length">
            <app-search-unit-item :keyword="keyword" :key="unit.id" :unit="unit"
                                  :property="property"
                                  @close="$emit('close')"
                                  v-for="unit in property.units"></app-search-unit-item>
        </ul>
    </li>
</template>

<script>

import AppSearchUnitItem from "@/components/app/search/property/AppSearchUnitItem";
import AppButton from "@/components/app/AppButton/AppButton";
import {R_PROPERTY_OVERVIEW, R_PROPERTY_CALENDAR_DEV, R_PROPERTY_UNIT_LIST} from "@/shared/route_permission";
import routes from '@/router/routeNames'
import {textFormatters} from "@/mixins/shared/helpers";

export default {
    name: "AppSearchPropertyItem",
    components: {AppButton, AppSearchUnitItem},
    mixins: [textFormatters],
    props: {
        property: {
            type: Object
        },
        keyword: {
            type: String
        }
    },
    data() {
        return {
            routes: routes,
            R_PROPERTY_OVERVIEW: R_PROPERTY_OVERVIEW,
            R_PROPERTY_CALENDAR_DEV: R_PROPERTY_CALENDAR_DEV,
            R_PROPERTY_UNIT_LIST: R_PROPERTY_UNIT_LIST
        }
    },
    computed: {
        highlightText() {
            return this.sanitizeText(this.property.name);
        }
    }
}
</script>

<style scoped>

</style>
