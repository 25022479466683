<template>
    <tr>
        <td>
            <app-select
                :value="selectedOccupancy.default"
                @input="emitChange('default', $event)"
                :options="yesNoOptions"
                :disabled="isDefault"
                :search-empty-item="false">
            </app-select>
        </td>
        <td>
            <app-number-input
                :min="1"
                :value="selectedOccupancy.min_guests"
                @input="emitChange('min_guests', $event)">
            </app-number-input>
        </td>
        <td>
            <app-number-input
                :min="1"
                :max="this.maximumGuests"
                :value="selectedOccupancy.max_guests"
                @input="emitChange('max_guests', $event)">
            </app-number-input>
        </td>
        <td>
            <app-number-input
                :allow-decimal="true"
                :value="selectedOccupancy.discount"
                @input="emitChange('discount', $event)"
                v-show="!isDefault">
            </app-number-input>
        </td>
        <td>
            <app-select
                :value="selectedOccupancy.discount_type"
                @input="emitChange('discount_type', $event)"
                :options="selectDiscountTypeOptions"
                value-field="value"
                text-field="text"
                v-show="previewRelationType"
                :search-empty-item="false">
            </app-select>
        </td>
        <td class="text-right">
            <app-button
                :v_show="!isDefault"
                :access_control_context="{function: C_UNIT_CALENDAR_OCCUPANCY_E, key: AC_UNIT_OCCUPANCY}"
                @AccessControlEvent="addToAccessControlCounter()"
                button_type="delete"
                :show_text="false"
                @click="deleteOccupancy"
                variant="link">
            </app-button>
        </td>
    </tr>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButton from "@/components/app/AppButton/AppButton";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {AC_UNIT_OCCUPANCY} from "@/mixins/AccessControl/AccessControlEnumeration";
import {C_UNIT_CALENDAR_OCCUPANCY_E} from "@/shared/component_permission";
import _cloneDeep from "lodash/cloneDeep";
import {listHelpers} from "@/mixins/shared/helpers";
import {FIX, PERC} from "@/shared/constants";

export default {
    name: "UnitOccupancyListItem",
    components: {
        AppNumberInput,
        AppButton,
        AppSelect,
    },
    mixins: [
        AccessControlComponent,
        listHelpers,
    ],
    props: {
        occupancy: {
            type: Object
        },
        maximumGuests: {
            type: Number,
            default: Number.POSITIVE_INFINITY,
            validator: value => {
                return value === Number.POSITIVE_INFINITY || (Number.isInteger(value) && value > 0)
            },
        },
    },
    data() {
        return {
            AC_UNIT_OCCUPANCY,
            C_UNIT_CALENDAR_OCCUPANCY_E,
            access_control_components: 1,
            selectDiscountTypeOptions: [
                {value: PERC, text: "%"},
                {value: FIX, text: "fix"},
            ],
            selectedOccupancy: {
                id: null,
                min_guests: null,
                max_guests: null,
                discount: null,
                discount_type: null,
                default: null,
                deleted: null
            },
        }
    },
    computed: {
        isDefault() {
            return this.selectedOccupancy.default === 1
        },
        previewRelationType() {
            return !this.isDefault && !Number.isNaN(this.selectedOccupancy.discount) && Number(this.selectedOccupancy.discount) === this.selectedOccupancy.discount
        },
    },
    methods: {
        deleteOccupancy() {
            this.$emit("delete", this.selectedOccupancy)
        },
        emitChange(key, value) {
            if (this.selectedOccupancy[key] === value) {
                return
            }
            let payload = {}
            this.$set(payload, key, value)
            this.$emit("update", payload)
        },
    },
    watch: {
        occupancy: {
            handler(value) {
                if (value) {
                    this.selectedOccupancy = _cloneDeep(value)
                }
            },
            immediate: true,
            deep: true,
        },
    },
}
</script>

<style scoped>

</style>
