<template>
    <b-row class="mb-2">
        <b-col cols="8">
            <b-input v-if="itemList" v-model="itemObject.label" :disabled="true" style="background-color: transparent;" type="text" @change="editItem"></b-input>
            <b-input v-if="newItem" v-model="newObject.label" v-on:keyup.enter="save" ref="Input"></b-input>
        </b-col>
        <b-col class="text-right pr-4" cols="4">
            <app-button v-if="itemList"
                        button_type="delete"
                        :show_text="false"
                        @click="deleteItem"
                        variant="link"
                        class="action_button">
            </app-button>
            <app-button v-if="newItem" button_type="new"
                        @click="save">
                {{ $t('ADD_ITEM') }}
            </app-button>
        </b-col>
    </b-row>
</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";

export default {
    name: "AppItemList",
    components: {AppButton},
    props: {
        itemList: Boolean,
        newItem: Boolean,
        itemObject: Object,
    },
    data() {
        return {
            newObject: {label: null},
            loading: false,
        }
    },
    methods: {
        deleteItem() {
            this.$emit('deleteItem', this.itemObject)
        },
        editItem() {
            this.$emit('editItem', this.itemObject)
        },
        save() {
            this.$emit('addItem', this.newObject)
            this.newObject.label = null
            this.$refs.Input.focus()
        }
    },
    created() {

    }
}
</script>

<style scoped>

</style>
