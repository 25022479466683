<template>
    <b-form @submit.prevent="save">
        <b-row>
            <b-col md="2" class="mb-4">
                <app-input-control :error-object="validation.workflow">
                    <template v-slot:input="data">
                        <label>{{ $t('WORKFLOW') }}</label>
                        <app-select :options="workflowList" v-model="formData.workflow_id" :state="data.state" :search-empty-item="false" disabled
                                    text-field="label"></app-select>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="2" class="mb-4">
                <app-input-control :error-object="validation.main_currency">
                    <template v-slot:input="data">
                        <label>{{ $t('MAIN_CURRENCY') }}</label>
                        <app-select :options="currencyList" v-model="formData.main_currency.id" :state="data.state" :search-empty-item="false" disabled
                                    text-field="code"></app-select>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="2" class="mb-4">
                <app-input-control :error-object="validation.working_currency">
                    <template v-slot:input="data">
                        <label>{{ $t('WORKING_CURRENCY') }}</label>
                        <app-select :options="currencyList" v-model="formData.working_currency.id" :state="data.state" :search-empty-item="false" disabled
                                    text-field="code"></app-select>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="2" class="mb-4">
                <app-input-control :error-object="validation.base_language">
                    <template v-slot:input="data">
                        <label>{{ $t('LANGUAGE') }}</label>
                        <app-select :options="languages" v-model="formData.base_language" :state="data.state" :search-empty-item="false">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>

        <b-row>
            <b-col md="6" class="mb-4">
                <app-button-submit :loading="saving" :c_permission="C_SETUP_GENERAL_E"></app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import AppInputControl from "@/components/app/AppInputControl";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {getWorkflowList} from "@/services/workflow";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {getCurrencyList} from "@/services/finance";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {C_SETUP_GENERAL_E} from "@/shared/component_permission";

    export default {
        name: "Index",
        components: {AppButtonSubmit, AppSelect, AppInputControl},
        mixins: [getErrorMessage],
        data() {
            return {
                workflowList: [],
                formData: {},
                saving: false,
                validation: {
                    workflow: {}
                },
                currencyList: [],
                C_SETUP_GENERAL_E
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            languages() {
                return Object.keys(this.$i18n.messages).map(item => {
                    return {
                        id: item,
                        name: item
                    }
                });
            },
        },
        methods: {
            save() {
                this.saving = true
                this.$store.dispatch('user/actionUpdateCompany', this.formData).then(response => {
                    notifySuccess()
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => this.saving = false)
            }
        },
        created() {
            const {main_currency, working_currency, base_language, workflow_id} = this.company
            this.formData = {main_currency, working_currency, base_language, workflow_id}
            getWorkflowList().then(response => {
                this.workflowList = response.data
            }, error => {
                this.showErrorMessages(error)
            })
            getCurrencyList().then(response => {
                this.currencyList = response.data
            }, error => {
                this.showErrorMessages(error)
            })
        }
    }
</script>

<style scoped>

</style>
