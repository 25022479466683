<template>
    <div class="dashboard-wrapper">
        <b-row>
            <b-col xl="6">
                <b-card no-body>
                    <b-card-header header-class="border-bottom-0 d-flex flex-column">
                        <div>
                            <app-button v-if="checkPermission(C_RESERVATION_ACCOMMODATION_SCHEDULER_EVENT_NEW)"
                                        button_type="new"
                                        class="pull-right"
                                        @click="addEvent">
                                {{ $t('ADD_ITEM') }}
                            </app-button>
                        </div>
                    </b-card-header>

                    <b-card-body>
                        <b-form @submit.prevent="getSchedulerEvents" class="filter mb-3">
                            <b-row>
                                <b-col xl="4" md="12" class="mb-3">
                                    <label>{{ $t('FROM') }}</label>
                                    <app-date-picker v-model="filter.ts_execute_from"></app-date-picker>
                                </b-col>

                                <b-col xl="4" md="12" class="mb-3">
                                    <label>{{ $t('TO') }}</label>
                                    <app-date-picker v-model="filter.ts_execute_to"></app-date-picker>
                                </b-col>

                                <b-col xl="4" md="12" class="mb-3">
                                    <label>{{ $t('STATUS') }}</label>
                                    <app-select v-model="filter.status"
                                                mode="multiselect"
                                                :options="statuses"
                                                text-field="label">
                                    </app-select>
                                </b-col>

                                <b-col xl="4" md="12" class="mb-3">
                                    <app-button-submit
                                        :inline="true"
                                        button_type="search"
                                        variant="primary"
                                        :loading="loadingEvents">
                                        {{ $t('SEARCH') }}
                                    </app-button-submit>

                                    <app-button-reset @click="onFilterReset" :disabled="loadingEvents"
                                                      :loading="loadingEvents" :inline="true" class="pl-2 ml-2">
                                    </app-button-reset>
                                </b-col>
                            </b-row>
                        </b-form>

                        <div v-if="!loadingEvents" class="timeline" ref="timeline">
                            <template v-for="(event, index) in events">
                                <div class="timeline-container"
                                     :class="{active: event.id && selectedEvent === event.id}">
                                    <b-tooltip :target="`icon-${index}`">
                                        {{ event.action.label }}
                                    </b-tooltip>

                                    <div class="timeline-icon" :id="`icon-${index}`">
                                        <i :class="ACTION_ICON_MAP[event.action.id]"></i>
                                    </div>

                                    <div class="timeline-content" ref="timelineContent">
                                        <template v-if="event.action_data">
                                            <div class="timeline-content-title">
                                                <div class="timeline-content-title-id">
                                                    <small>#{{ event.id }}</small>
                                                </div>
                                                <strong>{{ event.action_data.label }}</strong>
                                            </div>

                                            <div class="timeline-content-body" v-if="event.action_data.status.id !== RESERVATION_SCHEDULER_STATUS_NON_EXECUTABLE">
                                                <span class="mr-2">{{ $t('STATUS') }}:</span>
                                                <span class="badge">{{ event.action_data.status.label }}</span>
                                            </div>
                                        </template>

                                        <div class="timeline-content-footer">
                                            <span>
                                                {{ event.timestamp | date }} {{ event.timestamp | time }}
                                                {{ event.action_by ? `- ${event.action_by.name}` : '' }}
                                            </span>

                                            <app-button v-if="checkPermission(C_RESERVATION_ACCOMMODATION_SCHEDULER_EVENT_E) && event.action_data"
                                                        class="action_button"
                                                        variant="link" button_type="edit"
                                                        :show_text="false"
                                                        @click="openEventForm(event)">
                                            </app-button>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col xl="6" v-if="selectedEvent || (eventData && !eventData.id)">
                <transition name="fade">
                    <b-card key="form" v-if="eventData && !loadingEventData" no-body class="position-sticky"
                            style="top: 0">
                        <b-card-header
                            header-class="border-bottom-0 d-flex align-items-center justify-content-between pb-5">
                                <span class="header-2">
                                    {{ formCardHeader }}
                                </span>

                            <i
                                class="fa fa-close text-primary"
                                style="cursor: pointer"
                                @click="deselectEvent">
                            </i>
                        </b-card-header>

                        <b-card-body>
                            <div>
                                <b-form @submit.prevent="saveEvent">
                                    <b-row>
                                        <b-col xl="4" md="12" class="mb-4">
                                            <label>{{ $t('_DATE') }} & {{ $t('TIME').toLowerCase() }} (CET)</label>
                                            <app-input-control>

                                            </app-input-control>
                                            <app-date-picker
                                                :disabled="!eventData.editable"
                                                :value="eventMomentDate ? eventMomentDate.toDate() : null"
                                                :clear="false"
                                                :min-date="new Date()"
                                                @input="setEventDate">
                                            </app-date-picker>
                                        </b-col>

                                        <b-col xl="2" md="12" class="mb-4">
                                            <label>&zwnj;</label>
                                            <time-picker
                                                :clearable="false"
                                                :disabled="!eventData.editable"
                                                :value="eventMomentDate ? eventMomentDate.format('HH:mm') : null"
                                                @input="setEventTime">
                                            </time-picker>
                                        </b-col>

                                        <b-col v-if="!eventData.id" xl="4" md="12" class="mb-4">
                                            <label>{{ $t('ACTION') }}</label>
                                            <app-select
                                                v-model="eventData.action"
                                                :options="actions"
                                                :search-empty-item="false"
                                                set-first
                                                text-field="label"
                                                return-type="object">
                                            </app-select>
                                        </b-col>
                                    </b-row>

                                    <template v-if="eventData.action && eventData.action.id">
                                        <b-row>
                                            <b-col>
                                                <form-header>{{ eventData.action.label }}</form-header>
                                            </b-col>
                                        </b-row>

                                        <component :is="ACTION_COMPONENT_MAP[eventData.action.id]"
                                                   :validation="validation[eventData.action.id]"
                                                   :event-data="eventData" @update="eventData = $event">
                                        </component>
                                    </template>


                                    <div class="d-flex justify-content-between">
                                        <app-button-submit v-if="eventData.editable"
                                                           :loading="saving"></app-button-submit>

                                        <app-button-delete
                                            v-if="checkPermission(C_RESERVATION_ACCOMMODATION_SCHEDULER_EVENT_DELETE) && eventData.editable"
                                            @click="deleteDialogState = true">
                                        </app-button-delete>
                                    </div>


                                    <app-confirmation-dialog
                                        :show="deleteDialogState"
                                        @confirm="deleteEvent"
                                        :delete_title="true"
                                        @cancel="deleteDialogState=false">
                                    </app-confirmation-dialog>
                                </b-form>
                            </div>
                        </b-card-body>
                    </b-card>

                    <div v-else key="spinner" class="d-flex justify-content-center position-sticky" style="top: 50%">
                        <b-spinner variant="primary"></b-spinner>
                    </div>
                </transition>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    OBJECT_TYPE_NOTIFICATION,
    RES_SCHEDULER_ACTION_NOTIFICATION,
    RES_SCHEDULER_ACTION_TRANSACTION, RESERVATION_SCHEDULER_STATUS_NON_EXECUTABLE
} from "@/shared/constants";
import {
    createSchedulerEvent, deleteSchedulerEvent,
    fetchSchedulerEvent, getSchedulerActions,
    getSchedulerEvents,
    updateSchedulerEvent
} from "@/services/accommodation_reservation";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import moment from 'moment'
import {dataFormatters} from "@/mixins/shared/helpers";
import {fetchStatusList} from "@/services/status";
import {AR_SCHEDULER_NOTIFICATION_EVENT_TEMPLATE} from "@/shared/error_codes";
import {
    C_RESERVATION_ACCOMMODATION_SCHEDULER_EVENT_DELETE,
    C_RESERVATION_ACCOMMODATION_SCHEDULER_EVENT_E,
    C_RESERVATION_ACCOMMODATION_SCHEDULER_EVENT_NEW
} from "@/shared/component_permission";

const ACTION_ICON_MAP = {
    [RES_SCHEDULER_ACTION_NOTIFICATION]: 'fa fa-envelope',
    [RES_SCHEDULER_ACTION_TRANSACTION]: 'fa fa-money',
    'creation': 'fa fa-plus',
    'update': 'fa fa-pencil',
    'cancellation': 'fa fa-ban',
    'check_in': 'fa fa-sign-in',
    'check_out': 'fa fa-sign-out',
}

const ACTION_COMPONENT_MAP = {
    [RES_SCHEDULER_ACTION_NOTIFICATION]: 'ReservationAccommodationSchedulerNotificationForm',
    [RES_SCHEDULER_ACTION_TRANSACTION]: 'ReservationAccommodationSchedulerTransactionForm'
}

export default {
    name: "ReservationAccommodationScheduler",
    mixins: [getErrorMessage, dataFormatters],
    components: {
        "AppConfirmationDialog": () => import("@/components/app/form/AppConfirmationDialog"),
        "AppButtonDelete": () => import("@/components/app/AppButton/AppButtonDelete"),
        "AppInputControl": () => import("@/components/app/AppInputControl"),
        "AppButtonReset": () => import("@/components/app/AppButton/AppButtonReset"),
        "FormHeader": () => import("@/components/app/form/FormHeader"),
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
        "AppButtonSubmit": () => import("@/components/app/AppButton/AppButtonSubmit"),
        "TimePicker": () => import("@/components/app/datetime/TimePicker"),
        "AppDatePicker": () => import("@/components/app/datetime/AppDatePicker"),
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "ReservationAccommodationSchedulerNotificationForm": () => import("@/components/reservation/accommodation/forms/ReservationAccommodationSchedulerNotificationForm"),
        "ReservationAccommodationSchedulerTransactionForm": () => import("@/components/reservation/accommodation/forms/ReservationAccommodationSchedulerTransactionForm")
    },
    props: {
        reservation: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            events: [],
            ACTION_ICON_MAP, ACTION_COMPONENT_MAP,
            selectedEvent: null,
            loadingEventData: false,
            loadingEvents: false,
            eventData: null,
            saving: false,
            filter: {
                status: null,
                ts_execute_from: null,
                ts_execute_to: null
            },
            initialFilterState: {...this.filter},
            actions: [],
            statuses: [],
            deleteDialogState: false,
            validation: {
                [RES_SCHEDULER_ACTION_NOTIFICATION]: {
                    event_template: AR_SCHEDULER_NOTIFICATION_EVENT_TEMPLATE
                }
            },
            C_RESERVATION_ACCOMMODATION_SCHEDULER_EVENT_NEW,
            C_RESERVATION_ACCOMMODATION_SCHEDULER_EVENT_E,
            C_RESERVATION_ACCOMMODATION_SCHEDULER_EVENT_DELETE,
            RESERVATION_SCHEDULER_STATUS_NON_EXECUTABLE,
        }
    },
    computed: {
        formCardHeader() {
            return this.eventData && this.eventData.id
                ? `${this.$t('ACTION')} #${this.eventData.id}`
                : this.$t('NEW_ACTION')

        },
        eventMomentDate() {
            return this.eventData && this.eventData.ts_execute && moment.unix(this.eventData.ts_execute)
        }
    },
    watch: {
        reservation: {
            immediate: true,
            handler() {
                this.getSchedulerEvents()
            }
        },
    },
    methods: {
        getSchedulerEvents() {
            this.loadingEvents = true
            return getSchedulerEvents(this.reservation.id, {
                ...this.filter,
                ts_execute_from: this.filter.ts_execute_from && moment(this.filter.ts_execute_from).startOf('day').unix(),
                ts_execute_to: this.filter.ts_execute_to && moment(this.filter.ts_execute_to).endOf('day').unix(),
            }).then(response => {
                this.events = response.data
            }).finally(() => {
                this.loadingEvents = false
                this.$nextTick(() => {
                    if (this.events.length) {
                        this.setTimelineHeight()
                    }
                })
            })
        },
        setTimelineHeight() {
            // set timeline line height by deducting the height of the last box
            const lastTimelineElementHeight = this.$refs.timelineContent[this.events.length - 1].getBoundingClientRect().height
            this.$refs.timeline.style.setProperty('--timeline-line-height', `calc(100% - ${lastTimelineElementHeight}px)`)
        },
        openEventForm(event) {
            this.eventData = null
            this.selectedEvent = event.id
            this.loadingEventData = true
            fetchSchedulerEvent(event.id).then(response => {
                this.eventData = response.data
            }, error => this.showErrorMessages(error)).finally(() => this.loadingEventData = false)
        },
        setEventTime(time) {
            const date = this.eventMomentDate ? this.eventMomentDate.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
            this.eventData.ts_execute = moment(`${date} ${time}`).unix()
        },
        setEventDate(date) {
            const time = this.eventMomentDate ? this.eventMomentDate.format('HH:mm') : '00:00'
            this.eventData.ts_execute = moment(`${date} ${time}`).unix()
        },
        addEvent() {
            this.selectedEvent = null
            this.eventData = {
                id: null,
                ts_execute: moment().add({hour: 1}).set({minutes: 0}).unix(),
                action_data: null,
                editable: true,
            }
        },
        saveEvent() {
            this.saving = true
            const formData = this.formatObjectKeyNamePairs(this.eventData)
            const savePromise = this.eventData.id
                ? updateSchedulerEvent(this.eventData.id, formData)
                : createSchedulerEvent(this.reservation.id, formData)

            savePromise.then(response => {
                this.eventData = response.data
                this.selectedEvent = response.data.id
            }).then(() => {
                this.saving = false
                return this.getSchedulerEvents()
            }).catch(error => this.showErrorMessages(error, this.validation[eventData.action.id])).finally(() => this.saving = false)
        },
        deleteEvent() {
            this.deleteDialogState = false
            this.saving = true
            deleteSchedulerEvent(this.eventData.id).then(() => {
                this.saving = false
                this.deselectEvent()
                return this.getSchedulerEvents()
            }, error => this.showErrorMessages(error)).finally(() => this.saving = false)
        },
        onFilterReset() {
            this.filter = {...this.initialFilterState}
            this.getSchedulerEvents()
        },
        deselectEvent() {
            this.selectedEvent = null
            this.eventData = null
        }
    },
    created() {
        this.$emit('created', true)

        Promise.all([fetchStatusList(OBJECT_TYPE_NOTIFICATION), getSchedulerActions()])
            .then(([statusResponse, actionResponse]) => {
                this.statuses = statusResponse.data
                this.actions = actionResponse.data
            })
    }
}
</script>

<style scoped>

</style>
