<template>
    <div v-if="threadId" class="messages" style="height: 88%">
        <div class="messages_inbox">
            <b-form @submit.prevent="sendMessage">
                <b-row v-if="thread.hasOwnProperty('special_offer') && thread.special_offer.button_cancel && thread.special_offer.active_to">
                    <b-col class=" mb-3">
                        <div class="header-info pull-right">
                            {{$t('EXPIRES_AT')}}: {{thread.special_offer.active_to | timestamp}}
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="mb-3" v-if="thread.hasOwnProperty('special_offer') && thread.special_offer.buttons_show">
                        <b-tooltip v-if="!thread.special_offer.button_approve" :target="thread.id+'_approve'"
                                   :title="$t('ACTION_NOT_POSSIBLE',{status_label:thread.status.label})"></b-tooltip>
                        <b-tooltip v-if="!thread.special_offer.button_cancel" :target="thread.id+'_decline'"
                                   :title="$t('ACTION_NOT_POSSIBLE',{status_label:thread.status.label})"></b-tooltip>
                        <app-button :c_permission="C_INBOX_DECLINE" :disabled="!thread.special_offer.button_cancel" :id="thread.id+'_decline'" :loading="load_decline" class="pull-right" @click="decline">{{$t('WITHDRAW_OFFER')}}</app-button>
                        <app-button :c_permission="C_INBOX_APPROVE" :disabled="!thread.special_offer.button_approve" :id="thread.id+'_approve'" :loading="load_approve" class="mr-3 pull-right" @click="approve">{{$t('PRE_APPROVE')}}</app-button>
                        <app-button v-if="thread.special_offer.button_create" @click="createAside = true" class="pull-right mr-3" button_type="new">{{$t('CREATE_SPECIAL_OFFER')}}</app-button>
                    </b-col>
                    <b-col class="mb-3" v-if="thread.hasOwnProperty('pending_reservation') && (thread.pending_reservation.accept_button ||thread.pending_reservation.decline_button)">
                        <app-button v-if="thread.pending_reservation.decline_button" @click="declineAside = true" class="pull-right">{{$t('DECLINE')}}</app-button>
                        <app-button :loading="saving_accept" v-if="thread.pending_reservation.accept_button" @click="accept" class="pull-right mr-3">{{$t('ACCEPT')}}</app-button>

                    </b-col>
                    <b-col cols="12">
                        <label>{{$t("MESSAGE")}}</label>
                        <b-textarea rows="3" v-model="newText"></b-textarea>
                    </b-col>
                    <b-col class="mt-3 d-flex justify-content-between" cols="12">
                        <app-submit :loading="saving" :disabled="sendDisabled">{{$t("SEND")}}</app-submit>

                        <label v-if="!savingAttachment" for="attachment" class="cursor-pointer"><i
                                class="font-weight-bold fa fa-paperclip fa-lg action_button"
                                aria-hidden="true"></i></label>
                        <b-spinner v-else small variant="primary"></b-spinner>

                        <b-form-file id="attachment" accept=".jpg, .png, .gif" v-model="attachment" class="d-none"
                                     plain></b-form-file>

                    </b-col>
                </b-row>
            </b-form>
        </div>
        <div @scroll="loadMore" class="messages_list">
            <inbox-message-item :key="message.id" :is-last-unread="lastUnreadId === message.id" :message="message"
                                @preview="showGallery"
                                v-for="message in response.items"></inbox-message-item>

            <gallery :images="attachmentGallery" :index="previewAttachment" @close="previewAttachment = null">
                <template #close>
                    <i class="fa fa-close" style="color: white"></i>
                </template>
            </gallery>
        </div>
        <div v-if="loading" class="d-flex justify-content-center">
            <b-spinner small variant="primary"></b-spinner>
        </div>
        <app-aside :widths="['col-lg-4']" v-model="createAside">
            <template v-slot:header>
                {{$t('CREATE_SPECIAL_OFFER')}}
            </template>
            <inbox-create-special-offer :thread-object="thread"></inbox-create-special-offer>
        </app-aside>
        <app-aside :widths="['col-lg-3']" v-model="declineAside">
            <template v-slot:header>
                {{$t('DECLINE')}}
            </template>
            <inbox-decline-form @close="closeDecline" :thread-object="thread"></inbox-decline-form>
        </app-aside>
    </div>
</template>

<script>
    import {cloneDeep} from 'lodash'
    import {
        getThreadMessageList,
        createMessage,
        markAsRead,
        approveSpecialOffer,
        declineSpecialOffer, acceptPendingReservation
    } from '@/services/inbox/index'
    import InboxMessageItem from "@/components/inbox/InboxMessageItem";
    import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {EventBus, GE_TABLE_UPDATE_ROW} from "@/shared/EventBus";
    import {toast, notifySuccess} from '@/shared/plugins/toastr'
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import AppButton from "@/components/app/AppButton/AppButton";
    import VueGallery from 'vue-gallery';
    import {MESSAGE_STATUS_UNREAD} from '@/shared/constants'
    import {C_INBOX_APPROVE, C_INBOX_DECLINE} from '@/shared/component_permission'
    import AppAside from "@/components/app/form/AppAside";
    import InboxCreateSpecialOffer from "@/components/inbox/InboxCreateSpecialOffer";
    import InboxDeclineForm from "@/components/inbox/InboxDeclineForm";

    export default {
        name: "InboxMessages",
        components: {
            InboxDeclineForm,
            InboxCreateSpecialOffer, AppButton, AppSubmit, InboxMessageItem, 'gallery': VueGallery,AppAside},
        mixins: [getErrorMessage],
        props: {
            thread: {
                type: Number | Object,
                default: null
            }
        },
        data() {
            return {
                loading: false,
                saving: false,
                savingAttachment: false,
                previewAttachment: null,
                newText: "",
                attachment: null,
                response: {
                    page: 1,
                    total: null,
                    last_page: null,
                    items: []
                },
                acceptedFileType: [
                    "image/jpeg",
                    "image/png",
                    "image/gif",
                ],
                load_approve:false,
                load_decline:false,
                C_INBOX_DECLINE,
                C_INBOX_APPROVE,
                createAside:false,
                declineAside:false,
                saving_accept:false

            }
        },
        computed: {
            threadId() {
                return typeof this.thread === 'number' ? this.thread : this.thread.id
            },
            sendDisabled() {
                return this.newText.length === 0
            },
            attachmentGallery() {

                return this.response.items.filter(el => el.attachment !== null).map(elWithImg => {
                    return elWithImg.attachment
                })
            },
            lastUnreadId() {
                let unred = this.response.items.slice().reverse().find(msg => msg.status.id === MESSAGE_STATUS_UNREAD);
                if (unred) {
                    return unred.id
                }
                return -1
            }
        },
        watch: {
            attachment: {
                handler() {
                    this.sendAttachment()
                },
                deep: true
            },
            thread:{
                handler(object){
                    this.response.items = object.message_list ? object.message_list :[]
                }, immediate:true,
                    deep:true
            }
        },
        methods: {
            closeDecline(){
                this.declineAside = false
                this.$emit('refreshThread',this.threadId)
            },
            accept(){
                this.saving_accept = true
                acceptPendingReservation(this.threadId).then(()=>{
                    notifySuccess();
                    this.$emit('refreshThread',this.threadId)
                },error=>{
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                }).finally(()=>{
                    this.saving_accept = false
                })
            },
            approve(){
                this.load_approve = true
                approveSpecialOffer(this.threadId).then(()=>{
                    notifySuccess();
                    this.$emit('refreshThread',this.threadId)
                }, error=>{
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                }).finally(()=>{
                    this.load_approve = false
                })
            },
            decline(){
                this.load_decline = true
                declineSpecialOffer(this.threadId).then(() =>{
                    notifySuccess();
                    this.$emit('refreshThread',this.threadId)
                }, error=>{
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                }).finally(()=>{
                    this.load_decline = false
                })
            },
            getData() {
                this.loading = true
                getThreadMessageList({thread: this.threadId, page: this.response.page}).then(response => {
                    this.response.page = cloneDeep(response.data.page)
                    this.response.total = cloneDeep(response.data.total)
                    this.response.last_page = cloneDeep(response.data.last_page)
                    this.response.items = [...this.response.items, ...response.data.items]

                     markAsRead(this.threadId)
                }).finally(() => {
                    this.loading = false
                })

            },
            sendMessage() {
                if (!this.sendDisabled) {
                    this.saving = true


                    let formData = new FormData();
                    formData.append('message', this.newText);

                    createMessage(this.threadId, formData).then(response => {

                        this.response.items.unshift(response.data)
                        this.newText = ""
                        notifySuccess()
                        EventBus.$emit(GE_TABLE_UPDATE_ROW, {id: "inbox_table", callback: this.updateThreadData})
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }).finally(() => {
                        this.saving = false
                    })
                }

            },
            sendAttachment() {


                if (this.attachment && this.acceptedFileType.includes(this.attachment.type)) {
                    this.savingAttachment = true
                    let formData = new FormData();
                    formData.append('attachment', this.attachment);
                    formData.append('message', "");
                    createMessage(this.threadId, formData).then(response => {
                        this.response.items.unshift(response.data)

                        notifySuccess()
                        EventBus.$emit(GE_TABLE_UPDATE_ROW, {id: "inbox_table", callback: this.updateThreadData})
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }).finally(() => {
                        this.savingAttachment = false
                        this.attachment = null
                    })
                } else {
                    this.attachment = null
                }

            },
            updateThreadData(data) {

                let index = data.findIndex(el => el.id === this.threadId)
                let newData = cloneDeep(data)
                if (index > -1) {
                    let replacementItem = cloneDeep(data[index])
                    replacementItem.message_count = replacementItem.message_count + 1

                    if (this.response.items.length) {
                        replacementItem.last_message = cloneDeep(this.response.items[0])

                        replacementItem.updated_at = cloneDeep(this.response.items[0].ts_created)
                    }

                    newData.splice(index, 1, replacementItem)
                }
                return newData
            },
            showGallery(messageId) {

                let index = this.response.items.filter(el => el.attachment !== null).findIndex(elFiltered => elFiltered.id === messageId)
                if (index >= 0) {
                    this.previewAttachment = index
                }

            },
            loadMore() {
/*                var el = $event.srcElement

                if (!this.loading && (this.response.last_page === null || (this.response.last_page > this.response.page))) {
                    if (el.scrollTop >= (el.scrollHeight - el.clientHeight) - 100) {
                        this.response.page++
                        this.getData()
                    }
                }*/
            }
        },
        created(){
            markAsRead(this.threadId)
        }
    }
</script>

<style scoped>
</style>
