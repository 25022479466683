<template>
    <b-button style="white-space: nowrap;" @click.prevent="$emit('click')" size="sm" variant="primary" :disabled="loading">
        <i class="appEdit" :class="{'fa fa-download fa-fw' : !loading, 'fa fa-spinner fa-spin fa-fw' : loading}"
           aria-hidden="true"></i>
        <slot></slot>
    </b-button>
</template>

<script>
    export default {
        name: "AppDownload",
        props: {
            loading: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped>

</style>
