<template>
    <div>
        <marketplace-general-table></marketplace-general-table>
    </div>
</template>

<script>
import MarketplaceGeneralTable from "@/components/direct_booking/marketplace/general/MarketplaceGeneralTable";
export default {
    name: "Index",
    components:{MarketplaceGeneralTable}
}
</script>

<style scoped>

</style>
