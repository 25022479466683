<template>
    <span>
        <div v-if="!loading" class="filter-unit-sticky pb-4">
            <template v-if="!loading && options.length === 0">
                <app-no-data :heading="$t('MODULE_NOT_ACTIVE')" :tip="$t('MODULE_NEED_UNITS')" :show-tip="true">
                    <app-button button_type="new" @click="openUnitWizard" :access_control_context="{function:C_PROPERTY_NAVIGATION_HEADER_BUTTON,key:AC_PROPERTY_CALENDAR}"
                                @AccessControlEvent="addToAccessControlCounter()">
                        {{ $t('NEW_UNIT') }}
                    </app-button>
                </app-no-data>
            </template>
            <template v-else>
                <b-form class="filter unit-filter" @submit.prevent="search">
                    <b-row>
                        <b-col cols="12" md="6" lg="2">
                            <label>{{$t('PERIOD')}}</label>
                            <app-date-range-picker :max-date-diff="90" :max-date='maxDate' v-model="filter.dateRange"></app-date-range-picker>
                        </b-col>

                        <b-col v-if="options.length > 1" cols="6" sm="3" lg="2">
                            <label>{{$t('UNIT')}}</label>
                            <app-select mode="multiselect" text-field="name" :options="options"
                                        v-model="selected"></app-select>
                        </b-col>
                        <b-col cols="6" sm="3" lg="3" class="d-flex align-items-baseline">
                            <app-submit class="filter-submit-wrapper" :inline="true" :loading="loadingButton"
                                        :disabled="searchDisabled" variant="primary"
                                        button_type="search">{{ $t("SEARCH") }}
                            </app-submit>

                        </b-col>

                        <b-col class="d-flex align-items-end justify-content-lg-end">
                            <i class="fa fa-chevron-left availability_calendar--load-more ml-3" @click="loadMoreBack" v-if="showMoreBack" aria-hidden="true" ></i>

                            <i class="fa fa-chevron-right availability_calendar--load-more ml-3" aria-hidden="true" @click="loadMoreEvent"  v-else-if="showLoadMore"></i>

                        </b-col>
                    </b-row>
                </b-form>
                <div >
                    <app-search-data v-if="showSearchData"></app-search-data>
                </div>
            </template>
        </div>
              <app-aside :main-classes="['p-0']"  v-if="checkPermission(C_PROPERTY_CALENDAR_BULK_V)" v-model="groupEditAside"
                         :widths="['col-lg-4', 'col-md-5', 'col-sm-12', '40%']">
                        <template slot="header">{{$t("EDIT")}}</template>
                  <calendar-edit-form
                      mode="calendar-edit"
                      :selected-units="selected"
                      :property-id="property.id"
                      :single-property="true"
                      :contingent="false"
                      :periods="[filter.dateRange]"
                      @openNewReservation="groupEditAside = false"
                      :root-units="rootUnitList">
                    </calendar-edit-form>
                    </app-aside>
                    <app-aside v-if="checkPermission(C_PROPERTY_CALENDAR_SYNC_E)"
                               v-model="sync_slider" :widths="['col-xl-5','col-lg-7','col-md-8', 'col-sm-12', '40%']">
                        <template slot="header">{{$t("CALENDAR_SYNC")}}</template>
                        <unit-calendar-sync-form-v2
                            :distributions="distributions"
                            :restrictions="restrictions"
                            :units="options"
                            :default-selected-units="selected"
                            :default_date_range="filter.dateRange"></unit-calendar-sync-form-v2>
                    </app-aside>
                    <app-aside v-model="resAside">
                        <template slot="header">
                            {{$t('NEW_RESERVATION')}}
                        </template>
                        <new-accommodation-reservation-form :only-available-unit="onlyAvailableUnit"  :date-range="resData.dateRange"  :unit-id="resData.unit" :availableUnitList="availableUnitList"
                                                            @created="reloadData" :property-id="property.id" :redirect="false">
                        </new-accommodation-reservation-form>
                    </app-aside>

        <app-aside :widths="['col-lg-4', 'col-sm-12', '40%']" v-model="importExportSidebarState">
                <template slot="header">
                    <app-object-header :name="$t('PRICE_LIST')">
                    </app-object-header>
                </template>
                <import-export-prices-form :unit-list="unitIdList"
                                           mode="unit-calendar"
                                           @input="openImportExportSidebar = $event"
                                           @pricesImported="pricesImported"
                                           :property="property">
                </import-export-prices-form>
            </app-aside>
    </span>

</template>

<script>
    import SubmitButton from '@/components/app/AppButton/AppButtonSubmit'
    import AppSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import AppButton from '@/components/app/AppButton/AppButton'
    import AppDropdownCheckbox from '@/components/app/form/AppDropdownCheckbox'
    import AppAside from '@/components/app/form/AppAside'
    import {getDistributionManagers} from "@/services/distribution";
    import moment from 'moment'
    import {getUnitAvailableList, getUnitList} from "@/services/unit";
    import {
        C_PROPERTY_CALENDAR_AVAILIBILITY_V,
        C_PROPERTY_CALENDAR_PRICES_V,
        C_PROPERTY_CALENDAR_RESTRICTIONS_V,
        C_PROPERTY_CALENDAR_RM_SUGGESTION_AUTOSELECTED,
        C_PROPERTY_CALENDAR_RM_SUGGESTION_V,
        C_PROPERTY_CALENDAR_ROOM_STATUS_V,
        C_PROPERTY_CALENDAR_SYNC_E,
        C_PROPERTY_CALENDAR_SYNC_V,
        C_PROPERTY_CALENDAR_BULK_V,
        C_PROPERTY_NAVIGATION_HEADER_BUTTON
    } from "@/shared/component_permission";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {SALES_ENABLED, WORKFLOW_DIRECT_BOOKER} from "@/shared/constants";
    import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
    import AppNoData from "@/components/app/AppNoData";
    import routeNames from '@/router/routeNames'
    import AppSearchData from "@/components/app/AppSearchData";
    import {AC_PROPERTY_CALENDAR} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {cloneDeep} from 'lodash'
    import NewAccommodationReservationForm
        from "@/components/reservation/accommodation/forms/NewAccommodationReservationForm";
    import {
        EventBus,
        GE_CALENDAR_LOAD_ADD_MONTH,
        GE_CALENDAR_REFRESH_DATA,
        GE_OPEN_NEW_ACC_RES_ASIDE,
        GE_CALENDAR_OPEN_DISPLAY_SETTINGS_ASIDE,
        GE_CALENDAR_OPEN_GROUP_EDIT_ASIDE,
        GE_CALENDAR_OPEN_SYNC_ASIDE,
        GE_CALENDAR_OPEN_SYNC_LOADING,
        GE_OPEN_UNIT_WIZARD, GE_CALENDAR_SCROLL_POSITION, GE_CALENDAR_OPEN_IMPORT_EXPORT_PRICE_LIST_ASIDE,
    } from "@/shared/EventBus";
    import UnitCalendarSyncFormV2 from "@/components/unit/calendar_test/sync/UnitCalendarSyncFormV2";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
    import MainCalendarGroupEdit from "@/components/unit/calendar_opt/Aside/Bulk/MainCalendarGroupEdit";
    import AppObjectHeader from "@/components/app/AppObjectHeader";
    import ImportExportPricesForm from "@/components/pricing/ImportExportPricesForm";
    import CalendarEditForm from "@/components/calendar/aside/CalendarEditForm";


    export default {
        name: "UnitCalendarFilterV2",
        props:{
            showMoreBack:{
                type:Boolean,
                default:false
            },
            showLoadMore:{
                type:Boolean,
                default:false
            },
            dateArray:{
                type:Array,
                default(){
                    return []
                }
            }
        },
        components: {
            CalendarEditForm,
            ImportExportPricesForm,
            AppObjectHeader,
            MainCalendarGroupEdit,
            AppButtonReset,
            UnitCalendarSyncFormV2,
            NewAccommodationReservationForm,
            AppSearchData,
            AppNoData,
            AppDateRangePicker,
            AppSubmit,
            AppSelect,
            AppDropdownCheckbox,
            SubmitButton, AppAside, AppButton
        },
        data() {
            return {
                C_PROPERTY_CALENDAR_SYNC_E,
                C_PROPERTY_CALENDAR_SYNC_V,
                C_PROPERTY_CALENDAR_BULK_V,
                C_PROPERTY_NAVIGATION_HEADER_BUTTON,
                loading: true,
                loadingButton: false,
                options: [],
                optionsCloned: [],
                selected: [],
                view_elements: [],
                distributions: [],
                sync_slider: false,
                resAside: false,
                groupEditAside: false,
                sync_loading: false,
                showSearchData: true,
                filter: {
                    property_id: this.$route.params.id,
                    dateRange: {start: null, end: null},
                    contigent: 0,
                    limit: 1000
                },
                routeNames,
                AC_PROPERTY_CALENDAR,
                access_control_components: 1,
                access_control_fetch_key: AC_PROPERTY_CALENDAR,
                resData:{
                    unit:null,
                    dateRange:null,
                },
                onlyAvailableUnit:false,
                availableUnitList:[],
                loadAdditionalMonths: 0,
                first_next_date:null,
                first_back_date:null,
                unit_options:[],
                importExportSidebarState: false,
                rootUnitList: [],
            }
        },
        mixins: [AccessControlComponent],
        methods: {
            loadMoreBack(){
                this.first_back_date = this.filter.dateRange.start
                this.loadAdditionalMonths--
                this.filter.dateRange.start = this.formattedFullDateRange.start
                this.filter.dateRange.end = this.formattedFullDateRange.end
                this.loadAdditionalMonths = 0
                EventBus.$emit(GE_CALENDAR_LOAD_ADD_MONTH, {unitId: null, start:this.formattedFullDateRange.start,end:this.formattedFullDateRange.end})
            },
            loadMoreEvent() {
                this.first_next_date = this.filter.dateRange.end
                this.loadAdditionalMonths++
                this.filter.dateRange.start = this.formattedFullDateRange.start
                this.filter.dateRange.end = this.formattedFullDateRange.end
                this.loadAdditionalMonths = 0
                EventBus.$emit(GE_CALENDAR_LOAD_ADD_MONTH, {unitId: null, start:this.formattedFullDateRange.start,end:this.formattedFullDateRange.end})

            },
            resetFilter(manual) {
                this.loadAdditionalMonths = 0
                this.resetForm()
                if (manual) {
                    this.setFromStorage(false)
                } else {
                    this.setFromStorage(true)
                }

            },
            reloadData(reservationObject){
                let unitId =  reservationObject.unit.parent ? reservationObject.unit.parent : reservationObject.unit.id
                EventBus.$emit(GE_CALENDAR_REFRESH_DATA,{unitId})
                this.resAside = false

            },
            resetForm() {
                this.loadAdditionalMonths = 0
                let currentDate = new moment()
                this.view_elements = []
                this.selected = []

                if (this.options.length === 1) {
                    this.selected.push(this.options[0].id)
                }

                this.filter.dateRange.start = currentDate.format('YYYY-MM-DD')
                this.filter.dateRange.end = currentDate.add(3, 'M').format('YYYY-MM-DD')

                if (this.availibilityViewPermission) {
                    this.view_elements.push(C_PROPERTY_CALENDAR_AVAILIBILITY_V)
                }
                if (this.roomStatusViewPermission) {
                    this.view_elements.push(C_PROPERTY_CALENDAR_ROOM_STATUS_V)
                }
                if (this.pricesViewPermission) {
                    this.view_elements.push(C_PROPERTY_CALENDAR_PRICES_V)
                }
                if (this.restrictionsViewPermission) {
                    this.view_elements.push(C_PROPERTY_CALENDAR_RESTRICTIONS_V)
                }

                if (this.checkPermission(C_PROPERTY_CALENDAR_RM_SUGGESTION_AUTOSELECTED)) {
                    this.view_elements.push(C_PROPERTY_CALENDAR_RM_SUGGESTION_V)
                }
            },
            async setPredefinedOptions() {

                if (!this.options.find(item => item.id === Number(this.$route.query.unit))) {
                    this.filter.property_id = this.$route.params.id
                    await this.fetchUnitList()
                }

                let performSearchFlag = false
                if (this.$route.query.unit || (this.$route.query.start && this.$route.query.end)) {
                    if (Number(this.$route.query.unit) > 0) {
                        this.selected = [Number(this.$route.query.unit)]
                        performSearchFlag = true
                    } else if(this.$route.query.hasOwnProperty('all_unit')) {
                        this.selected = this.options.map(item=>{
                            return item.id
                        })
                        performSearchFlag = true
                    }

                    if (this.$route.query.start && this.$route.query.end) {
                        const start = moment(this.$route.query.start, 'YYYY-MM-DD', true)
                        const end = moment(this.$route.query.end, 'YYYY-MM-DD', true)

                        if (start.isValid() && end.isValid()) {
                            this.filter.dateRange.start = start.format('YYYY-MM-DD')
                            this.filter.dateRange.end = end.format('YYYY-MM-DD')
                            performSearchFlag = true
                        }
                    }

                    if (performSearchFlag) {
                        this.search()
                    }
                }
            },
            search() {
                this.loadAdditionalMonths = 0
                this.showSearchData = false
                this.loadingButton = true
                this.$emit('search', {
                    units: this.options.filter(item => this.selected.includes(item.id)),
                    date_range: this.filter.dateRange,
                    elements: this.view_elements
                })

                setTimeout(() => {
                    this.loadingButton = false
                }, 2500)
            },
            showSync() {
                this.sync_slider = true
            },
            async fetchUnitList() {
                await getUnitList({...this.filter, ...{company_id: this.company.id}}).then(response => {
                    // this.rootUnitList = response.data.items
                    // this.$emit("setRootUnitList", response.data.items)
                    if(response.data.items.length === 1){
                        this.search()
                        this.$emit('hideUnitHeader')
                    }
                    this.options = response.data.items.map(item => {
                        return {
                            ...item, ...{name: item.unit_type.name}
                        }
                    })
                    this.optionsCloned = cloneDeep(this.options)
                    this.resetFilter(false)
                    this.loading = false
                }, () => {
                    this.options = []
                    this.loading = false

                })
            },
            setFromStorage(set = false) {
                if (set) {
                    let storage = localStorage.getItem('unit_c_filter_view')
                    if (storage) {
                        this.view_elements = JSON.parse(storage)
                    }
                } else {
                    this.updateStorage(this.view_elements)
                }

            },
            updateStorage($event) {
                localStorage.setItem('unit_c_filter_view', JSON.stringify($event))
            },
            openUnitWizard() {
                EventBus.$emit(GE_OPEN_UNIT_WIZARD)
            },
            pricesImported(args) {
                const unitList = args.hasOwnProperty('unit_list') ? args.unit_list : []
                if (unitList.length > 0) {
                    unitList.forEach((unitId) => {
                        EventBus.$emit(GE_CALENDAR_REFRESH_DATA,{unitId: unitId})
                    })
                }
                this.importExportSidebarState = false
            },
        },
        computed: {
            lastIndexDate(){
                return this.dateArray.length >  0 ? this.dateArray.length - 1 : null
            },
            pixelsWide() {
                return 2.87 * 16
            },
            startingPosition() {
                return this.pixelsWide * this.lastIndexDate
            },
          maxDate(){
            const currentYear = new Date().getFullYear()
            let maxDate =  (currentYear + 3) + '-12-31'
            return new Date(maxDate)
          },
            formattedFullDateRange() {
                if (this.loadAdditionalMonths > 0) {

                    return {
                        start: moment(this.filter.dateRange.start).add(this.loadAdditionalMonths, 'months').format("YYYY-MM-DD"),
                        end: moment(this.filter.dateRange.end).add(this.loadAdditionalMonths, 'months').format("YYYY-MM-DD"),
                    }
                } else if(this.loadAdditionalMonths < 0 ){
                    return {
                        start: moment(this.filter.dateRange.start).subtract(1, 'months').format("YYYY-MM-DD"),
                        end: moment(this.filter.dateRange.end).subtract(1, 'months').format("YYYY-MM-DD"),
                    }
                }
                return this.filter.dateRange
            },
            searchDisabled() {
                return this.view_elements.length === 0 || this.selected.length === 0 || !this.filter.dateRange.start
            },
            availibilityViewPermission() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_AVAILIBILITY_V)
            },
            pricesViewPermission() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_PRICES_V)
            },
            restrictionsViewPermission() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_RESTRICTIONS_V)
            },
            rmSuggestionViewPermission() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_RM_SUGGESTION_V)
            },
            roomStatusViewPermission() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_CALENDAR_ROOM_STATUS_V)
            },
            view_options() {
                let options = []
                if (this.availibilityViewPermission) {
                    options.push({id: C_PROPERTY_CALENDAR_AVAILIBILITY_V, label: this.$t("AVAILABILITY")})
                }
                if (this.roomStatusViewPermission) {
                    options.push({id: C_PROPERTY_CALENDAR_ROOM_STATUS_V, label: this.$t("ROOM_STATUS")})
                }
                if (this.pricesViewPermission) {
                    options.push({id: C_PROPERTY_CALENDAR_PRICES_V, label: this.$t("RATES")})
                }
                if (this.restrictionsViewPermission) {
                    options.push({id: C_PROPERTY_CALENDAR_RESTRICTIONS_V, label: this.$t("RESTRICTIONS")})
                }

                if (this.workflowDB && this.rmSuggestionViewPermission) {
                    options.push({id: C_PROPERTY_CALENDAR_RM_SUGGESTION_V, label: this.$t("RATE_MANAGER")})
                }

                return options
            },
            restrictions() {
                const restrictionList = this.$store.getters['calendar/getRestrictions']
                if (restrictionList == null) {
                    return null
                }
                // filtering sales enabled to be removed
                let filteredRestrictions = {}
                Object.keys(restrictionList).forEach(restrictionId => {
                    if (Number(restrictionId) !== SALES_ENABLED) {
                        filteredRestrictions[restrictionId] = restrictionList[restrictionId]
                    }
                })
                return filteredRestrictions
            },
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            workflowDB() {
                return this.company && this.company.hasOwnProperty("workflow_id") && this.company.workflow_id == WORKFLOW_DIRECT_BOOKER
            },
            property() {
                return this.$store.getters['property/getProperty']
            },
            unitIdList() {
                return this.selected.length > 0 ? this.selected : (this.options.length > 0 ? this.options.map((unit) => {return unit.id}) : [])
            },
        },
        watch: {
            view_elements(val) {
                this.$emit('updateOptions', val)
            },
            '$route.query': {
                handler() {
                    this.setPredefinedOptions()
                },
                deep: true
            },
            first_next_date:{
                handler(value){
                    if(value){
                        setTimeout(()=>{
                            let indexDate = this.dateArray.findIndex(el=>{
                                return el.format('YYYY-MM-DD') === value
                            })
                            let calculatedPosition  = 45.92 * (indexDate+1)  // 45.92 cell width
                            EventBus.$emit(GE_CALENDAR_SCROLL_POSITION, {position: calculatedPosition})
                            document.querySelector('.main_calendar_test__holder--content-scroll').scrollLeft = calculatedPosition
                        },300)

                    }
                }
            },
            first_back_date:{
                handler(value){
                    if(value){
                        setTimeout(()=>{
                            let indexDate = this.dateArray.findIndex(el=>{
                                return el.format('YYYY-MM-DD') === value
                            })
                            let calculatedPosition  = 45.92 * (indexDate)  // 45.92 cell width

                            let new_position = calculatedPosition - document.querySelector('.main_calendar_test__holder--content-scroll').clientWidth
                            EventBus.$emit(GE_CALENDAR_SCROLL_POSITION, {position: new_position})

                            document.querySelector('.main_calendar_test__holder--content-scroll').scrollLeft = new_position
                        },300)
                    }
                }
            },

        },
        created(){
            this.access_control_general_context = {
                property: this.$route.params.id,
                company: this.$store.getters['user/getCompany']
            }
        },
        async mounted() {
            EventBus.$on(GE_OPEN_NEW_ACC_RES_ASIDE, (reqObject) => {
                if(reqObject){
                    this.resData.unit = reqObject.hasOwnProperty('unit_id') ? reqObject.unit_id : null
                    this.resData.dateRange = reqObject.hasOwnProperty('dateRange') ? reqObject.dateRange : null
                    this.onlyAvailableUnit = true
                    getUnitAvailableList({property:reqObject.property,date_from:reqObject.dateRange.start,date_to:reqObject.dateRange.end}).then(response => {
                        this.availableUnitList = response.data
                        this.resAside = true
                    })
                } else {
                    this.resAside = true
                }

            })


            EventBus.$on(GE_CALENDAR_OPEN_GROUP_EDIT_ASIDE, (value) => {
                if(value!=null){
                    this.groupEditAside = value
                } else {
                    this.groupEditAside = true
                }
            })

            EventBus.$on(GE_CALENDAR_OPEN_SYNC_ASIDE, () => {
                this.showSync()
            })

            EventBus.$on(GE_CALENDAR_OPEN_IMPORT_EXPORT_PRICE_LIST_ASIDE, () => {
                this.importExportSidebarState = true
            })


            await this.fetchUnitList()
            await this.setPredefinedOptions()

        }
    }
</script>

<style scoped>
    .filter-unit-sticky{
        position: -webkit-sticky;
        position: sticky;
        top: 3.45rem;
        z-index: 999;
        background: #ffffff;
        padding-top: 0.66rem;

    }
</style>
