<template>
    <div>
        <div class="table-wrapper">
            <table class="table">
                <thead>
                <tr>
                    <th class="table-header-label" scope="col">{{ $t("DEFAULT_LOS") }}</th>
                    <th class="table-header-from">{{ $t("FROM") }}</th>
                    <th class="table-header-to">{{ $t("TO") }}</th>
                    <th class="table-header-increase-value">+ / -</th>
                    <th class="table-header-increase-type">{{ $t("TYPE") }}</th>
                    <th class="table-header-actions text-right"></th>
                </tr>
                </thead>
                <tbody>
                <unit-los-list-item
                    @AccessControlEvent="addToAccessControlCounter"
                    @update="updateLos($event, index)"
                    @delete="removeLos(index)"
                    :los="los"
                    :key="index"
                    v-show="los.deleted !== 1"
                    v-for="(los, index) in losList_">
                </unit-los-list-item>
                <unit-los-list-item-new
                    @AccessControlEvent="addToAccessControlCounter"
                    @save="addLos"
                    :loading="loadingAddNew">
                </unit-los-list-item-new>
                </tbody>
            </table>
        </div>
        <b-row>
            <b-col class="mt-4">
                <submit-button
                    @AccessControlEvent="addToAccessControlCounter"
                    :access_control_context="{function: C_UNIT_CALENDAR_LOS_E, key: AC_UNIT_LOS}"
                    :v_show="checkPermission(C_UNIT_CALENDAR_LOS_E)"
                    @click="setupValidate"
                    :loading="loading">
                </submit-button>
            </b-col>
        </b-row>

        <app-confirmation-dialog
            :show="confirmationDialogState"
            @confirm="save"
            @cancel="confirmationDialogState = false"
            :title="$t('LOS_UPDATE_CONFIRMATION_TITLE')">
            {{ $t("LOS_UPDATE_CONFIRMATION_MSG") }}
        </app-confirmation-dialog>
    </div>
</template>

<script>
import UnitLosListItem from "@/components/unit/calendar/forms/los/UnitLosListItem";
import SubmitButton from "@/components/app/AppButton/AppButtonSubmit";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {pricingSetupValidate, updatePricingLosList} from "@/services/pricing";
import {getErrorMessage} from '@/mixins/error/getErrorMessage'
import {notifySuccess} from '@/shared/plugins/toastr'
import UnitLosListItemNew from "@/components/unit/calendar/forms/los/UnitLosListItemNew";
import {C_UNIT_CALENDAR_LOS_E} from "@/shared/component_permission";
import {AC_UNIT_LOS} from "@/mixins/AccessControl/AccessControlEnumeration"
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import _cloneDeep from "lodash/cloneDeep";

export default {
    name: "UnitLosList",
    components: {UnitLosListItemNew, UnitLosListItem, SubmitButton, AppConfirmationDialog},
    mixins: [getErrorMessage, AccessControlComponent],
    props: {
        losList: {
            type: Array | Object,
            default: () => {
                return []
            },
        },
        unit: {
            type: Object,
            required: true,
            validator: (value) => {
                return value && value.hasOwnProperty("id") && value.id
            },
        },
    },
    data() {
        return {
            C_UNIT_CALENDAR_LOS_E,
            loadingAddNew: false,
            confirmationDialogState: false,
            saving: false,
            loading: false,
            losList_: [],
            AC_UNIT_LOS,
            access_control_components: 3,
            access_control_fetch_key: AC_UNIT_LOS,
            changes: null,
        }
    },
    watch: {
        losList: {
            immediate: true,
            deep: true,
            handler(value) {
                this.losList_ = _cloneDeep(value)
                if (this.losList_.length) {
                    this.losList_.forEach((los) => {
                        this.$set(los, "deleted", 0)
                    })
                }
            },
        },
    },
    methods: {
        setupValidate() {
            this.loading = true
            let request = {
                unit: this.unit.id,
                validation_list: ["los"],
                los_list: this.getLosListRequest(),
            }
            pricingSetupValidate(request).then((response) => {
                this.changes = response.data.los
                if (this.changes.changes_detected) {
                    if (this.changes.should_open_pop_up_dialog) {
                        this.confirmationDialogState = true
                    } else {
                        this.save()
                    }
                }
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.loading = false
            })
        },
        updateLos(payload, index) {
            const previousLos = this.losList_[index]
            const los = {...previousLos, ...payload}
            if (los.default === 1 && (los.discount !== null || los.discount_type !== null)) {
                this.$set(los, "discount", null)
                this.$set(los, "discount_type", null)
            }
            if ((Number.isNaN(los.discount) || Number(los.discount) !== los.discount) && los.discount_type !== null) {
                this.$set(los, "discount_type", null)

            }
            this.$set(this.losList_, index, los)
            if (los.default === 1 && previousLos.default !== 1) {
                this.losList_.forEach((losItem, losIndex) => {
                    if (losIndex !== index) {
                        this.$set(losItem, "default", 0)
                        this.$set(losItem, "discount", null)
                        this.$set(losItem, "discount_type", null)
                    }
                })
            }
        },
        removeLos(index) {
            this.$set(this.losList_[index], "deleted", 1)
        },
        addLos(los) {
            this.loadingAddNew = true
            if (los.default === 1) {
                this.losList_.forEach((losItem, losIndex) => {
                    losItem.default = 0
                    losItem.discount = null
                    losItem.discount_type = null
                    this.$set(this.losList_, losIndex, losItem)
                })
            }
            let losId = "index_" + this.losList_.length
            this.losList_.push({...los, ...{id: losId, deleted: 0}})
            this.$nextTick(() => this.loadingAddNew = false)
        },
        getLosListRequest() {
            let losList = []
            this.losList_.forEach((los) => {
                let relationValue = los.discount
                if (relationValue !== null) {
                    relationValue = Number(parseFloat((typeof relationValue === "string" ? relationValue : relationValue.toString()))).toFixed(2)
                }
                if (isNaN(los.id)) {
                    if (los.deleted === 0) {
                        losList.push({...los, ...{discount: relationValue}})
                    }
                } else {
                    losList.push({...los, ...{discount: relationValue}})
                }
            })
            return losList
        },
        save() {
            this.saving = true
            let request = {
                unit: this.unit.id,
                los_list: this.getLosListRequest(),
            }
            updatePricingLosList(request)
                .then(response => {
                        this.$emit("update", {los_list: response.data.los_list, changes: this.changes})
                        notifySuccess()
                    },
                    error => {
                        this.showErrorMessages(error)
                    }).finally(() => {
                this.saving = false
                this.confirmationDialogState = false
            })
        }
    },
    created() {
        this.access_control_general_context = {
            unit: this.unit.id,
            company: this.$store.getters['user/getCompany']
        }
    },
}
</script>

<style scoped>
.table-wrapper {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.table-header-label {
    width: 25%;
    min-width: 7.5rem;
}

.table-header-from {
    width: 10%;
    min-width: 4.25rem;
}

.table-header-to {
    width: 10%;
    min-width: 4.25rem;
}

.table-header-increase-value {
    width: 15%;
    min-width: 5.25rem;
}

.table-header-increase-type {
    width: 10%;
    min-width: 4.5rem;
}

.table-header-actions {
    width: 30%;
    min-width: 6.25rem;
}
</style>
