import http from '../http'
import {
    MARKETPLACE_URL,
} from '@/services/endpoints'

export function getMarketplaceList(params){
    return http.request({
        url: MARKETPLACE_URL,
        method: 'get',
        params: params
    })
}

export function createMarketplace(data){
    return http.request({
        url: MARKETPLACE_URL,
        method: 'post',
        data: data
    })
}

export function deleteMarketplace(data){
    return http.request({
        url: MARKETPLACE_URL,
        method: 'delete',
        data: data
    })
}

export function getUnitTypeList(){
    return http.request({
        url: MARKETPLACE_URL + '/' + 'unit-type-list',
        method: 'get'
    })
}

export function getMarketplaceDescriptions(){
    return http.request({
        url: MARKETPLACE_URL + '/descriptions',
        method: 'get'
    })
}

export function getMarketplaceSetup(params){
    return http.request({
        url: MARKETPLACE_URL + '/setup',
        method: 'get',
        params: params
    })
}

export function updateMarketplaceSetup(data){
    return http.request({
        url: MARKETPLACE_URL,
        method: 'put',
        data: data
    })
}

export function getMarketplaceSetupValidation(params){
    return http.request({
        url:MARKETPLACE_URL + '/validation',
        method: 'get',
        params:params
    })
}

export function sendRequestForMarketplace(data){
    return http.request({
        url: MARKETPLACE_URL + '/request',
        method: 'post',
        data: data
    })
}

export function getMarketplaceRequest(params){
    return http.request({
        url:MARKETPLACE_URL + '/request',
        method: 'get',
        params: params
    })
}

export function setMarketplaceRequest(data){
    return http.request({
        url: MARKETPLACE_URL + '/set-request',
        method: 'post',
        data: data
    })
}

export function fetchMarketplaceWebsiteCompanies(params){
    return http.request({
        url: MARKETPLACE_URL + '/companies',
        method: 'get',
        params: params
    })
}

export function deleteMarketplaceWebsiteCompany(data){
    return http.request({
        url: MARKETPLACE_URL + '/companies',
        method: 'delete',
        data: data
    })
}

export function getMarketplaceWorkflowOptions(){
    return http.request({
        url: MARKETPLACE_URL + '/workflow',
        method: 'get'
    })
}

export function getUnitWorkflowOptions(){
    return http.request({
        url: MARKETPLACE_URL + '/unit-workflow',
        method: 'get'
    })
}

export function getMarketplaceUnitSetup(params){
    return http.request({
        url: MARKETPLACE_URL + '/unit-setup',
        method: 'get',
        params: params
    })
}

export function saveMarketplaceUnitActivation(data){
    return http.request({
        url: MARKETPLACE_URL + '/unit-setup',
        method: 'post',
        data: data
    })
}

export function sendRequestEmail(data){
    return http.request({
        url: MARKETPLACE_URL + '/request-email',
        method: 'post',
        data: data
    })
}

export function resolveRequestEmail(data){
    return http.request({
        url: MARKETPLACE_URL + '/resolve-email',
        method: 'post',
        data:data
    })
}
