<template>
    <div>
        <b-form class="mb-5" @submit.prevent="saveSetupType">
            <b-row>

                <b-col lg="6">
                    <app-input-control :error-object="validationErrorObject.name">
                        <template v-slot:input="data">
                            <label>{{ $t('NAME') }}</label>
                            <b-form-input :state="data.state" type="text" v-model.trim="setupObject.name"
                            >
                            </b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col lg="6">
                    <app-input-control :error-object="validationErrorObject.type">
                        <template v-slot:input="data">
                            <label class="label">{{ $t('TYPE') }}</label>
                            <app-select v-model="setupObject.type" :state="data.state" :options="type_options"
                                        value-field="id"
                                        text-field="title"></app-select>
                        </template>
                    </app-input-control>
                </b-col>

            </b-row>

            <b-row class="mt-4">
                <b-col lg="6">
                    <app-button-submit :loading="loader_setup"></app-button-submit>
                </b-col>
                <b-col lg="6" v-if="Object.keys(initialSetupObject).length > 0">
                    <app-button-delete
                        @click="showDeleteDialog=true"
                        class="pull-right">

                    </app-button-delete>
                </b-col>


            </b-row>
            <app-confirmation-dialog :show="showDeleteDialog" @confirm="deleteSetup"
                                     @cancel="showDeleteDialog=false" :delete_title="true"></app-confirmation-dialog>
        </b-form>
        <template v-if="Object.keys(initialSetupObject).length > 0">

            <b-row  v-if="rootBookingWindows.length > 0 && showChildForm">
                <b-col>
                    <table class="table b-table  ">
                        <thead>
                        <th>{{ $t('NAME') }}</th>
                        <th>{{ $t('INTERVAL_FROM') }}</th>
                        <th>{{ $t('INTERVAL_TO') }}</th>
                        <th></th>
                        </thead>
                        <tbody>
                        <template v-for="(bookingWindow,rootIndex) in rootBookingWindows">
                            <tr :key="rootIndex">
                                <td>
                                    <b-input v-model="bookingWindow.name"></b-input>

                                </td>
                                <td>
                                    <b-input v-model="bookingWindow.interval_from"></b-input>

                                <td>
                                    <b-input  v-model="bookingWindow.interval_to"></b-input>
                                </td>
                                <td>
                                    <app-button @click="deleteRootWindowItem(rootIndex,bookingWindow)"
                                                class="pull-right" button_type="delete"
                                                variant="link"
                                                :show_text="false"></app-button>
                                </td>
                            </tr>

                            <tr :key="childIndex+'_'+rootIndex" v-if="getChildListBWByRoot(bookingWindow).length > 0"
                                v-for="(childBooking,childIndex) in getChildListBWByRoot(bookingWindow)">
                                <td>
                                </td>
                                <td>
                                    <app-number-input :min="0"  @change="updateChildIntervalFrom(childBooking, $event)" :value="childBooking.interval_from"></app-number-input>

                                </td>
                                <td>
                                    <app-number-input :min="1" @change="updateChildIntervalTo(childBooking, $event)" :value="childBooking.interval_to"></app-number-input>
                                </td>
                                <td>
                                    <app-button @click="deleteChildBW(childBooking)" class="pull-right"
                                                button_type="delete" variant="link"
                                                :show_text="false"></app-button>
                                </td>
                            </tr>
                            <tr>
                                <td>

                                </td>
                                <td>

                                    <b-input  type="number" :ref="`new_interval_from_-${bookingWindow.id}`"  v-model="child_bw_interval_from[bookingWindow.id]"></b-input>

                                </td>
                                <td>
                                    <app-number-input
                                        v-model="child_bw_interval_to[bookingWindow.id]"></app-number-input>
                                </td>
                                <td>

                                    <app-button :disabled="!((child_bw_interval_to[bookingWindow.id] !=='undefined' && child_bw_interval_to[bookingWindow.id] !== null)  && (!child_bw_interval_from[bookingWindow.id] !== 'undefined' && !child_bw_interval_from[bookingWindow.id] !== null)) || child_bw_interval_from[bookingWindow.id] > bookingWindow.interval_to || child_bw_interval_to[bookingWindow.id] > bookingWindow.interval_to || child_bw_interval_to[bookingWindow.id] < bookingWindow.interval_from  || child_bw_interval_from[bookingWindow.id] < bookingWindow.interval_from"
                                                @keyup.enter="addNewChild(rootIndex)" @click="addNewChild(rootIndex)"
                                                button_type="new" class="pull-right">
                                        {{ $t('ADD_ITEM') }}
                                    </app-button>
                                </td>
                            </tr>

                        </template>

                        </tbody>

                    </table>

                </b-col>

            </b-row>
            <b-row  v-else>
                <b-col>
                    <table class="table b-table  ">
                        <thead>
                        <th>{{ $t('NAME') }}</th>
                        <th>{{ $t('INTERVAL_FROM') }}</th>
                        <th>{{ $t('INTERVAL_TO') }}</th>
                        <th></th>
                        </thead>
                        <tbody>
                        <tr :key="index+'_root'" v-if="rootBookingWindows.length > 0"
                            v-for="(rootBookingWindow, index) of rootBookingWindows">
                            <td>

                                <b-input v-model="rootBookingWindow.name"></b-input>
                            </td>
                            <td>
                                <app-number-input :min="0" v-model="rootBookingWindow.interval_from"></app-number-input>

                            </td>
                            <td>
                                <app-number-input :min="1" v-model="rootBookingWindow.interval_to"></app-number-input>
                            </td>
                            <td>
                                <app-button @click="deleteRootWindowItem(index,rootBookingWindow)" button_type="delete"
                                            :show_text="false"
                                            variant="link"
                                            class="action_button pull-right"></app-button>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <b-input ref="root_new_name" v-model="newRootBookingWindow.name"></b-input>
                            </td>
                            <td>
                                <app-number-input :min="0"
                                                  ref="new_root_interval_from"
                                                  v-model="newRootBookingWindow.interval_from"></app-number-input>
                            </td>
                            <td>
                                <app-number-input :min="1"
                                                  v-model="newRootBookingWindow.interval_to"></app-number-input>
                            </td>
                            <td>
                                <app-button

                                    :disabled="newRootBookingWindow.interval_from === null || newRootBookingWindow.interval_to === null || newRootBookingWindow.name === null || (newRootBookingWindow.interval_from < next_interval || newRootBookingWindow.interval_to <= newRootBookingWindow.interval_from)"
                                    @keyup.enter="addNewRootBookingWindow"
                                    @click="addNewRootBookingWindow" class="pull-right" button_type="new">
                                    {{ $t('ADD_ITEM') }}
                                </app-button>
                            </td>
                        </tr>
                        </tbody>

                    </table>

                </b-col>
            </b-row>
            <b-row>
                <b-col v-if="confirmationActive">
                    <app-button-submit @click="confirmation = true"
                                       :disabled="saveDisabled"></app-button-submit>
                </b-col>
                <b-col v-else>
                    <app-button-submit @click="save" :loading="loader_root_bw"
                                       :disabled="saveDisabled"></app-button-submit>
                </b-col>
            </b-row>
        </template>

        <b-modal style="overflow-y: auto;" v-model="confirmation" no-close-on-esc no-close-on-backdrop hide-header-close>
            <template slot="modal-header">
                <h5 class="modal-title" >{{$t('ACTIVATION_CONFIRMATION')}}</h5>
                <button type="button" aria-label="Close" class="close" @click="confirmation = false">×</button>
            </template>
            <slot><span class="font-weight-bold">{{ $t('ACTIVATE_BW_MESSAGE') }}</span></slot>
            <div slot="modal-footer">
                <app-button :loading="loader" class="mr-3" variant="primary" @click="confirmationSave">{{$t('CONFIRM')}}</app-button>
                <app-button @click="save">{{$t('SAVE_WITHOUT_ACTIVATION')}}</app-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    createBookingWindowSetup, deleteBookingWindowSetup,
    getBookingWindowSetup,
    getBookingWindowSetupType,
    getRevenueBookingWindow, setRootBookingWindow,
    updateBookingWindowSetup
} from "@/services/revenue_manager";
import AppButton from "@/components/app/AppButton/AppButton";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import FormHeader from "@/components/app/form/FormHeader";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import {toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {EventBus} from "@/shared/EventBus";
import {
    BOOKING_WINDOW_SETUP_NAME, BOOKING_WINDOW_SETUP_TYPE,
    PROPERTY_DATA_CATEGORIZATION_VALIDATION_FAILED,
    PROPERTY_DATA_NAME_VALIDATION_FAILED
} from "@/shared/error_codes";
import AppInputControl from "@/components/app/AppInputControl";

export default {
    name: "RevenueManagerBookingWindowForm",
    components: {
        AppInputControl,
        AppNumberInput,
        FormHeader, AppSelect, AppButtonSubmit, AppButtonDelete, AppConfirmationDialog, AppButton
    },
    mixins: [getErrorMessage],
    data() {
        return {
            validationErrorObject: {
                type: BOOKING_WINDOW_SETUP_TYPE,
                name: BOOKING_WINDOW_SETUP_NAME
            },
            initialSetupObject: {},
            setupObject: {
                name: null,
                active: 0,
                type: null
            },
            booking_window_list: [],
            rootBookingWindows: [],
            childBookingWindows: [],
            newRootBookingWindow: {
                name: null,
                interval_from: null,
                interval_to: null,
            },
            child_bw_interval_from: {},
            child_bw_interval_to: {},
            fetchData: false,

            activate_options: [
                {id: 1, name: this.$t('YES')},
                {id: 0, name: this.$t('NO')},
            ],
            type_options: [],
            loader: false,
            showDeleteDialog: false,
            loader_setup: false,
            loader_root_bw: false,
            deletedRootBWList: [],
            deletedChildrenList: [],
            tabIndex: 1,
            confirmation:false,
            next_interval:0

        }
    },
    props: {
        setup_id: {
            type: Number
        },
        type_booking_window: {
            type: Number | String
        },
        bookingWindowSetupList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    methods: {
        updateChildIntervalFrom(childBooking, value) {

            const index = this.childBookingWindows.findIndex(el => el.interval_from === childBooking.interval_from && el.interval_to === childBooking.interval_to)
            if (index > -1) {
                childBooking.interval_from = value
                this.$set(this.childBookingWindows, index, childBooking)
            }
        },
        updateChildIntervalTo(childBooking, value) {

            const index = this.childBookingWindows.findIndex(el => el.interval_from === childBooking.interval_from && el.interval_to === childBooking.interval_to)
            if (index > -1) {
                childBooking.interval_to = value
                this.$set(this.childBookingWindows, index, childBooking)
            }
        },
        disabledAddChild(bookingWindow){

           return    !this.child_bw_interval_to[bookingWindow.id] || !this.child_bw_interval_from[bookingWindow.id]

        },
        deleteChildBW(child) {
            const index = this.childBookingWindows.findIndex(el => el.interval_from === child.interval_from && el.interval_to === child.interval_to)
            this.deletedChildrenList.push(this.childBookingWindows[index])
            this.childBookingWindows.splice(index, 1)

        },
        confirmationSave(){
           this.confirmation = false
            const success = this.save(true)
        },
        save(activation) {
            let success = false
            this.confirmation = false
            if (this.rootBookingWindows.length === 0) {
                return
            }
            let request = {
                type: this.initialSetupObject.hasOwnProperty('booking_window_setup_type') ? this.initialSetupObject.booking_window_setup_type.id : this.setupObject.type,
                company: this.company.id,
                booking_windows: [],
                child_booking_windows: [],
            }
            for (const rootBookingWindow of this.rootBookingWindows) {
                request.booking_windows.push({
                    id: this.booking_window_list.length > 0 ? rootBookingWindow.id : null,
                    booking_window_setup: this.initialSetupObject.id,
                    name: rootBookingWindow.name,
                    interval_from: rootBookingWindow.interval_from,
                    interval_to: rootBookingWindow.interval_to,
                    delete: false,
                })
            }
            if (this.childBookingWindows.length > 0) {
                for (const childBW of this.childBookingWindows) {
                    request.child_booking_windows.push({
                        id: this.booking_window_list.length > 0 ? childBW.id : null,
                        booking_window_setup: this.initialSetupObject.id,
                        interval_from: childBW.interval_from,
                        interval_to: childBW.interval_to,
                        delete: false,
                    })
                }
            }
            if (this.deletedChildrenList.length > 0) {
                for (const deletedChildBW of this.deletedChildrenList) {
                    request.child_booking_windows.push({
                        id: this.booking_window_list.length > 0 ? deletedChildBW.id : null,
                        booking_window_setup: this.initialSetupObject.id,
                        interval_from: deletedChildBW.interval_from,
                        interval_to: deletedChildBW.interval_to,
                        delete: true,
                    })
                }
            }
            if (this.deletedRootBWList.length > 0) {
                for (const deletedRootBW of this.deletedRootBWList) {
                    request.booking_windows.push({
                        id: this.booking_window_list.length > 0 ? deletedRootBW.id : null,
                        booking_window_setup: this.initialSetupObject.id,
                        interval_from: deletedRootBW.interval_from,
                        interval_to: deletedRootBW.interval_to,
                        delete: true,
                    })
                }
            }
            this.loader_root_bw = true
            setRootBookingWindow(request).then(response => {
                success = true
                this.saved = true
                if(activation){

                    const request = {
                        company: this.company.id,
                        name: this.setupObject.name,
                        setup_type: this.setupObject.type,
                        active: 1
                    }

                    updateBookingWindowSetup(this.initialSetupObject.id,request).then(response => {

                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.getMessages(error),
                            'type': 'error',
                            'timeout': 3000
                        })
                    })
                }
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })
                EventBus.$emit('gl_reload_booking_windows')
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 3000
                })
            }).finally(() => {
                this.loader_root_bw = false
            })

        },
        getChildListBWByRoot(rootBW) {

            return this.childBookingWindows.length > 0 ? this.childBookingWindows.filter(el => el.interval_from >= rootBW.interval_from && el.interval_to <= rootBW.interval_to) : []

        },
        addNewChild(rootIndex) {
            const rootBookingWindow = this.rootBookingWindows[rootIndex]
            const insertObject = {
                id: null,
                booking_window_setup: this.initialSetupObject.id,
                interval_from: this.child_bw_interval_from[rootBookingWindow.id],
                interval_to: this.child_bw_interval_to[rootBookingWindow.id],
                delete: false
            }
            this.childBookingWindows.push(insertObject)
            this.childBookingWindows = this.childBookingWindows.sort(function (a, b) {
                return a.interval_from - b.interval_from;
            });
            this.child_bw_interval_from[rootBookingWindow.id] = null
            this.child_bw_interval_to[rootBookingWindow.id] = null

            this.$nextTick(() => {

                if(this.$refs["new_interval_from_-" + rootBookingWindow.id] && this.$refs["new_interval_from_-" + rootBookingWindow.id].length > 0){
                    this.$refs["new_interval_from_-" + rootBookingWindow.id][0].$el.focus()
                }

            })
        },
        saveSetupType() {
            this.loader_setup = true
            const request = {
                company: this.company.id,
                name: this.setupObject.name,
                setup_type: this.setupObject.type,
                active: this.setupObject.active
            }
            if (this.initialSetupObject.hasOwnProperty('id')) {
                updateBookingWindowSetup(this.initialSetupObject.id, request).then(response => {
                    EventBus.$emit('gl_reload_booking_windows')
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                    this.initialSetupObject.id = response.data
                    this.$emit('reload')
                }, error => {

                }).finally(() => {
                    this.loader_setup = false
                })

            } else {
                createBookingWindowSetup(request).then(response => {
                    EventBus.$emit('gl_reload_booking_windows')
                    this.$emit('changeHeader')
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                    this.initialSetupObject.id = response.data
                    this.$emit('reload')
                }, error => {
                }).finally(() => {
                    this.loader_setup = false
                })
            }


        },
        saveRootBW() {
            if (this.rootBookingWindows.length === 0) {
                return
            }
            let request = {
                company: this.company.id,
                booking_windows: []
            }
            for (const rootBookingWindow of this.rootBookingWindows) {
                request.booking_windows.push({
                    id: null,
                    booking_window_setup: this.initialSetupObject.id,
                    name: rootBookingWindow.name,
                    interval_from: rootBookingWindow.interval_from,
                    interval_to: rootBookingWindow.interval_to,
                    delete: false,
                })
            }
            this.loader_root_bw = true

            setRootBookingWindow(request).then(response => {

                this.saved = true
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })
                EventBus.$emit('gl_reload_booking_windows')
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 3000
                })
            }).finally(() => {
                this.loader_root_bw = false
            })

        },
        deleteSetup() {

            deleteBookingWindowSetup(this.initialSetupObject.id).then(response => {
                this.$emit('reload')
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })
                EventBus.$emit('gl_reload_booking_windows')
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 3000
                })
            }).finally(() => {
                this.showDeleteDialog = false
            })
        },
        addNewRootBookingWindow() {
            const newRootObject = {
                id: this.newRootBookingWindow.interval_from + '_' + this.newRootBookingWindow.interval_to,
                name: this.newRootBookingWindow.name,
                interval_from: this.newRootBookingWindow.interval_from,
                interval_to: this.newRootBookingWindow.interval_to
            }
            this.rootBookingWindows.push(newRootObject)
            this.rootBookingWindows = this.rootBookingWindows.sort(function (a, b) {
                return a.interval_from - b.interval_from;
            });
            const interval_half_value = Math.ceil((this.newRootBookingWindow.interval_to - this.newRootBookingWindow.interval_from) / 2)

            const interval_from_1 = this.newRootBookingWindow.interval_from
            const interval_to_1 = this.newRootBookingWindow.interval_from + interval_half_value

            const interval_from_2 = interval_to_1 + 1
            const interval_to_2 = this.newRootBookingWindow.interval_to

            this.childBookingWindows.push({
                interval_from: interval_from_1,
                interval_to: interval_to_1,
                root_id: newRootObject.id
            }, {interval_from: interval_from_2, interval_to: interval_to_2, root_id: newRootObject.id})
            const last_interval_to  =this.newRootBookingWindow.interval_to
            this.newRootBookingWindow.interval_from = null
            this.newRootBookingWindow.interval_to = null
            this.newRootBookingWindow.name = null
            this.next_interval = last_interval_to + 1
            this.$nextTick(() => {
                if(this.$refs.root_new_name){
                    this.$refs.root_new_name.$el.focus()
                }
            })
        },
        deleteRootWindowItem(index, rootBookingWindow) {
            const list = this.childBookingWindows.filter(el => el.root_id !== rootBookingWindow.id)
            this.childBookingWindows = list
            this.deletedRootBWList.push(this.rootBookingWindows[index])
            this.rootBookingWindows.splice(index, 1)

        },

        setData(data) {
            this.booking_window_list = data
            if (data.length > 0) {
                for (const rootBW of data) {
                    this.rootBookingWindows.push({
                        id: rootBW.id,
                        name: rootBW.title,
                        interval_from: rootBW.interval_from,
                        interval_to: rootBW.interval_to,
                        delete: false,
                    })
                    if (rootBW.hasOwnProperty('child_booking_windows') && rootBW.child_booking_windows.length > 0) {
                        for (const childBW of rootBW.child_booking_windows) {
                            let childObject = {...{root_id: rootBW.id, delete: false}, ...childBW}
                            this.childBookingWindows.push(childObject)
                        }
                    }
                }
            }
        },
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        }
    },
    computed: {
        showConfirmation() {
            const setupObject = this.bookingWindowSetupList.find(el => el.booking_window_setup_type.id === this.setupObject.type && el.active === 0)
            return setupObject ? false : true
        },
        computedRootBookingWindows() {
            return this.rootBookingWindows.filter(el => el.delete === false)
        },
        showChildForm() {
            let interval_list = []
            if (this.rootBookingWindows.length > 0) {
                for (const rootBW of this.rootBookingWindows) {
                    for (let i = rootBW.interval_from; i <= rootBW.interval_to; i++) {
                        interval_list.push(i)
                    }
                }
            }
            return interval_list.length === 366 ? true : false
        },
        saveDisabled() {
            let interval_list = []
            if (this.childBookingWindows.length > 0) {
                for (const childBW of this.childBookingWindows) {
                    for (let i = childBW.interval_from; i <= childBW.interval_to; i++) {
                        interval_list.push(i)
                    }

                }
            }
            return interval_list.length === 366 ? false : true
        },
        confirmationActive(){
            const activeType = this.bookingWindowSetupList && this.bookingWindowSetupList.length > 0 && this.bookingWindowSetupList.filter(el=>   el.booking_window_setup_type.id === this.setupObject.type && el.active)
            return activeType.length > 0 ? false : true
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },

    },
    watch: {
        setup_id: {
            handler(value) {
                if (value) {
                    getBookingWindowSetup({company: this.company.id, booking_window_setup: value}).then(response => {
                        if (response.data.length === 0) {
                            return
                        }
                        const object = response.data[0]
                        if (object) {
                            this.initialSetupObject = object
                            this.setupObject.active = object.active
                            this.setupObject.name = object.title
                            this.setupObject.type = object.booking_window_setup_type.id
                        }
                    })
                    getRevenueBookingWindow({company: this.company.id, booking_window_setup: value}).then(response => {


                        this.setData(response.data)

                    }).finally(() => {
                        this.fetchData = true
                    })
                }
            }, immediate: true
        },
        type_booking_window: {
            handler(value) {
                if (value) {
                    if (value === 'all') {
                        return
                    }
                    this.setupObject.type = value
                }
            }, immediate: true
        }
    },
    created() {
        getBookingWindowSetupType().then(response => {
            this.type_options = response.data.map(el=>{
                return {id:el.id, title:this.capitalize(el.title)}
            })
        })
    }
}
</script>

<style scoped>

</style>
