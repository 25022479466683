<template>
    <div>
        <website-amenities-grid-list :website_id="Number($route.params.id)" @copyAmenitiesDialog="confirmation_dialog_state=true"
                                     @newAmenity="newAmenity" @newCategory="newCategory=true" :refresh="refresh"></website-amenities-grid-list>
        <app-aside v-model="sidebar" :widths="['col-lg-4','col-sm-12', '40%']">
            <template slot="header">{{ $t('NEW_AMENITY') }}</template>
            <new-website-amenity :websiteId="Number($route.params.id)" :categoryId="categoryId"
                                 @saved="saved"></new-website-amenity>
        </app-aside>
        <app-aside v-model="newCategory" :widths="['col-lg-4','col-sm-12', '40%']">
            <template slot="header">
                <app-object-header
                    :name="$t('NEW_AMENITY_CATEGORY')">
                </app-object-header>
            </template>
            <new-website-category :websiteId="Number($route.params.id)"
                                  @saved="saved"></new-website-category>
        </app-aside>
        <app-confirmation-dialog
            :show="confirmation_dialog_state"
            @confirm="copyAmenities"
            @cancel="confirmation_dialog_state = false"
            :title="$t('COPY_CATEGORIES')">
            {{ $t('COPY_AMENITY_CATEGORIES_CONFIRMATION_TITLE') }}
        </app-confirmation-dialog>
    </div>
</template>

<script>
    import WebsiteAmenitiesGridList from "@/components/direct_booking/website/amenities/WebsiteAmenitiesGridList";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppAside from "@/components/app/form/AppAside";
    import NewWebsiteAmenity from "@/components/direct_booking/website/amenities/NewWebsiteAmenity";
    import AppObjectHeader from "@/components/app/AppObjectHeader";
    import NewWebsiteCategory from "@/components/direct_booking/website/amenities/NewWebsiteCategory";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import {copyAmenities} from "@/services/direct_booking/website";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {EventBus} from "@/shared/EventBus";

    export default {
        name: "Index",
        components: {
            AppConfirmationDialog,
            NewWebsiteCategory,
            AppObjectHeader, NewWebsiteAmenity, AppButtonSubmit, AppButton, WebsiteAmenitiesGridList, AppAside},
        data() {
            return {
                copyingAmenities: false,
                sidebar: false,
                categoryId: null,
                refresh: false,
                newCategory: false,
                confirmation_dialog_state: false
            }
        },
        methods: {
            newAmenity(data) {
                this.categoryId = data
                this.sidebar = true;
            },
            saved() {
                this.sidebar = false
                this.refresh = !this.refresh
            },
            copyAmenities() {
                this.loading = true;
                copyAmenities(Number(this.$route.params.id)).then(() => {
                    notifySuccess()
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.loading = false
                    this.refresh = !this.refresh
                    this.confirmation_dialog_state = false
                })
            },
        }
    }
</script>

<style scoped>

</style>
