<template>
    <div v-if="previewData">
        <p>{{ $t('SEND_TO') }}: {{ previewData.to }}</p>
        <p>{{ $t('EMAIL_SUBJECT') }}: {{ previewData.subject }}</p>
        <div class="border p-2" v-html="previewData.body"></div>
    </div>
</template>

<script>
export default {
    name: "PreviewTemplateSidebar",
    props: {
        previewData: {
            type: Object,
            default: null
        }
    }
}
</script>

<style scoped></style>
