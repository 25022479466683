
import {getTag} from "@/services/tag";

const state = {
    countryTag: {},
    tag: {},
    setRegionButton: false,
}

const getters = {
    getTag(state) {
        return state.tag
    },
    getCountryTag(state) {
        return state.countryTag
    },
}

const mutations = {
    setCountryTag(state, payload) {
        state.countryTag = payload
    },
    setRegionButton(state, payload){
        state.setRegionButton = payload
    }
}

const actions = {
    actionSetCountryTag({commit}, id) {
        return new Promise((resolve, reject) => {
            getTag(id).then(response => {
                commit('setCountryTag', response.data)
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
