<template>
    <div class="property-wrapper mb-0 res-no-border">
        <div class="card-header pt-0">
            <div class="header-caption mb-0" v-if="label">{{label}}</div>
            <h2 class="property-wrapper-padding mb-1" v-if="name">{{name}}</h2>
            <div class="header-info" v-if="description">{{description}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppObjectHeader",
        props: ['label', 'name', 'description']
    }
</script>

<style scoped>
    .res-no-border {
        box-shadow: none;
    }

    .res-no-border > div {
        border: none !important;
        padding-left: 0;
    }

    .card-header {
        min-height: 0 !important;
    }
</style>
