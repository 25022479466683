<template>
    <b-form @submit.prevent="setMappingList">
        <b-form-group>
            <label>{{$t('SELECT_UNIT')}}</label>
            <app-select v-model="selectedUnit" @input="setRatePlanList" :options="unitList"></app-select>
        </b-form-group>
        <div v-if="selectedUnit">

            <b-table small responsive :items="sorting(ratePlanList)" :fields="fields">
                <template v-slot:id="data">
                    <b-checkbox inline type="checkbox"
                                :key="data.item.id" :disabled="!checkPermission(permission)"
                                :unchecked-value="0"
                                :checked="value(data.item.id)"
                                :value="1"
                                switch
                                @change="addChangeLogByIndex(data.index, data.item.id,$event)"
                    ></b-checkbox>
                </template>
            </b-table>
        </div>
        <submit-button v-if="selectedUnit && checkPermission(permission)">{{$t('SAVE')}}</submit-button>

    </b-form>
</template>

<script>
    import {getPricingRatePlanList} from "@/services/pricing";
    import {getUnitPaginatedList} from '@/services/unit/index'
    import {
        getPropertyPromotionRatePlanMapping,
        setPropertyPromotionRatePlanMappingList
    } from '@/services/property/index'
    import SubmitButton from "@/components/app/AppButton/AppButtonSubmit"
    import {toast} from "@/shared/plugins/toastr";
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import AppSelect from "@/components/app/AppSelect/AppSelect";

    export default {
        name: "promotion-rate-plan-mapping-form",
        props: {
            promotion_id: {
                type: Number
            },
            permission: {
                type: Number,
                required: true
            }
        },
        mixin: [getErrorMessage],
        data() {
            return {
                selectedUnit: null,
                selectedRatePlan: [],
                editRatePlanList: [],
                unitList: [],
                ratePlanList: [],
                fields: [
                    {key: 'name', label: this.$t('NAME')},
                    {key: 'distribution.name', label: this.$t('DISTRIBUTION')},
                    {key: 'id', label: ''}
                ]
            }
        },
        components: {
            AppSelect,
            SubmitButton
        },
        methods: {
            value(id) {
                let index = this.selectedRatePlan.findIndex(element => Number(element.rate_plan_id) === Number(id))
                let item = this.selectedRatePlan[index]
                if (typeof item === 'undefined') {
                    return 0
                }

                return item.rate_plan_id ? 1 : 0

            },
            addChangeLogByIndex(indexItem, rate_plan_id, event) {
                let deleted = event === 1 ? 0 : 1
                let index = this.editRatePlanList.findIndex(element => element.rate_plan_id === rate_plan_id)
                if (index > -1) {
                    this.editRatePlanList.splice(index, 1)
                }
                // adding to update
                this.editRatePlanList.push({
                    rate_plan_id: rate_plan_id,
                    promotion_id: this.promotion_id,
                    deleted: deleted
                })
            },

            sorting(array) {
                // Set slice() to avoid to generate an infinite loop!

                return array.slice().sort(function (a, b) {
                    if (a.type) {
                        return a.type - b.type
                    }
                    if (a.distribution.name < b.distribution.name) {
                        return -1
                    }

                });
            },
            setRatePlanList(value) {
                this.ratePlanList = []
                if (value) {
                    getPricingRatePlanList({unit: this.selectedUnit, limit: 1000})
                        .then(response => {
                            if (response.data.items.length > 0) {
                                for (let item of response.data.items) {
                                    if (item.distribution.id !== null) {
                                        this.ratePlanList.push(item)
                                    }
                                }
                            }
                        })
                }
            },
            getUnitList(property_id) {
                getUnitPaginatedList({property_id: property_id, contigent: 0, limit: 1000})
                    .then((response) => {
                        let unit_list = response.data.items
                        if (unit_list.length > 0) {
                            for (let unit of unit_list) {
                                if (Number(unit.id) !== Number(this.$route.params.unit_id)) {
                                    this.unitList.push(unit)
                                }
                            }
                        }
                    }).catch(error => {
                    this.unitList = []
                })
            },
            getPromotionRatePlanMapping(promotion_id) {
                getPropertyPromotionRatePlanMapping(Number(this.$route.params.id), promotion_id).then(response => {
                    if (response.data.length > 0) {
                        for (let item of response.data) {
                            let object = {
                                rate_plan_id: item.rate_plan_id,
                                promotion_id: item.promotion_id
                            }
                            this.selectedRatePlan.push(object)
                        }
                    }
                })
            },
            setMappingList() {
                setPropertyPromotionRatePlanMappingList(Number(this.$route.params.id), Number(this.promotion_id), {list: this.editRatePlanList}).then(response => {
                    if (response.data.length > 0) {
                        for (let item of response.data) {
                            let object = {
                                rate_plan_id: item.rate_plan_id,
                                promotion_id: item.promotion_id
                            }
                            this.selectedRatePlan.push(object)
                        }
                    }
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                }, error => {
                    toast({
                        'title': "Action fail",
                        'message': this.getMessages(error),
                        'type': 'error',
                        'timeout': 2000
                    })
                })
            }
        },
        watch: {
            '$route.params.id': {
                async handler(property_id) {
                    this.getUnitList(property_id)
                },
                immediate: true,
                deep: true
            },
            promotion_id: {
                handler(promotion_id) {
                    this.getPromotionRatePlanMapping(promotion_id)
                },
                immediate: true,
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>
