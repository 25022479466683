<template>
    <div>
        <form @submit.prevent="search" class="filter" @reset.prevent="resetForm" autocomplete="off">
            <b-row>
                <b-col md="2" class="mb-3">
                    <label>{{ $t('NAME') }}</label>
                    <b-input v-model="filter.name" type="text"></b-input>
                </b-col>

                <b-col md="2" class="mb-3">
                    <label>{{ $t('ACCOUNT_MANAGER') }}</label>
                    <app-select mode="search" :search-on-create="true"
                                :search="searchAccManager" v-model="account_manager"
                                :search-clear="false"></app-select>
                </b-col>

                <b-col md="2" class="mb-3" v-if="!distribution">
                    <app-select :label="$t('DISTRIBUTION')" mode="multiselect" v-model="filter.distribution"
                                :options="distribution_list"
                                :search-clear="true"></app-select>
                </b-col>

                <b-col md="2" class="mb-3">
                    <label>{{ $t('PROPERTY') }}</label>
                    <app-select mode="search" text-field="name" v-model="property"
                                :search="searchProperty" :search-clear="true"></app-select>
                </b-col>

                <b-col md="2" class="mb-3">
                    <label>{{ $t('UNIT') }}</label>
                    <app-select :mode="searchMode" v-model="unit[searchMode]" :options="unitOptions"
                                :search="searchUnit"></app-select>
                </b-col>

<!--                <b-col md="2" class="mb-3" v-if="$route.name === routeNames.RN_SYSTEM_DISTRIBUTION_RATE_PLAN">-->
<!--                    <label>{{ $t('FILTER') }}</label>-->
<!--                    <app-select mode="select" :options="cmDistributionStatusList" text-field="label"-->
<!--                                v-model="filter.unit_active"></app-select>-->
<!--                </b-col>-->

                <b-col md="3" class="mb-3">
                    <app-button-submit class="filter-submit-wrapper" :inline="true" :disabled="searchLoader"
                                       :loading="loadingSubmit" variant="primary" button_type="search">
                        {{ $t("SEARCH") }}
                    </app-button-submit>
                    <app-button-reset :loading="loadingReset" :disabled="searchLoader" :inline="true"
                                      class="pl-2 ml-2"></app-button-reset>
                </b-col>
            </b-row>
        </form>
    </div>
</template>

<script>


import {getDistributionList} from "@/services/distribution";
import {cloneDeep} from "lodash";
import routeNames from '@/router/routeNames'
import {getUnitList} from "@/services/unit";
import {getList} from "@/services/acc_manager";
import {getPropertyList} from "@/services/property";

export default {
    name: "RatePlanFilter",
    components: {
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
        "AppButtonSubmit": () => import("@/components/app/AppButton/AppButtonSubmit"),
        "AppButtonReset": () => import("@/components/app/AppButton/AppButtonReset")
    },
    props: {
        unit_id: {
            type: Number,
            default: null
        },
        distribution: {
            type: Object,
            default: {id: null, name: null}
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            routeNames,
            filter: {
                name: null,
                distribution: [],
                company_id: null,
                unit_id: null,
                unit_active: null,
                account_manager: null,
                property_id: null
            },
            cmDistributionStatusList: [
                {id: 0, label: this.$t('INACTIVE')},
                {id: 1, label: this.$t('ACTIVE')}
            ],
            distribution_list: [],
            unit: {
                search: {id: null, name: null},
                multiselect: []
            },
            initialUnit: {},
            unitOptions: [],
            property: {id: null, name: null},
            account_manager: {id: null, name: null},
            loadingSubmit: false,
            loadingReset: false,
            searchLoader: false,
            initialFilter: null,
            searchMode: "search",
            reset: 0
        }

    },
    methods: {
        searchDistribution() {
            getDistributionList({company: this.company.id, for_reservation: 1, sorted: 1}).then(res => {
                this.distribution_list = res.data
            })
        },
        resetForm() {
            this.reset = 1
            this.filter = this.initialFilter
            this.property = {id: null, name: null}
            this.account_manager = {id: null, name: null}
            this.$emit('search', {...this.filter})
        },
        search() {
            this.reset = 0
            this.filter.company_id = this.company.id
            this.filter.unit = this.unit.search.id ? this.unit.search.id : this.unit.multiselect
            this.filter.property = this.property.id
            this.filter.account_manager = this.account_manager.id
            if (this.distribution && this.distribution.id) {
                this.filter.distribution = [this.distribution.id]
            } else {
                this.filter.distribution = null
            }
            this.$emit('search', {...this.filter})
        },
        searchUnit(value,type = null) {
            if (type === "multiselect") {
                getUnitList({
                    contigent: 0,
                    company_id: this.company.id,
                    property_id: this.property.id,
                    limit: 20,
                }).then(response => {
                    this.unitOptions = response.data.items
                })
            } else {
                return getUnitList({
                    contigent: 0,
                    company_id: this.company.id,
                    name: value,
                    property_id: this.property.id,
                    limit: 20
                }).then(response => {
                    return {data: response.data.items}
                })
            }
        },
        searchAccManager(value) {
            return getList({
                company_id: this.company.id,
            })
        },
        searchProperty(value) {
            return getPropertyList({
                name: value,
                company_id: this.company.id,
                account_manager: this.account_manager.id
            }).then(response => {
                return {data: response.data.items}
            })
        },
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        distributions: {
            get() {
                let filter = this.$store.getters['pricing/getSearchFilter']
                return filter.hasOwnProperty('distribution') && filter.distribution ? filter.distribution : []
            },
            set(value) {
                this.$store.commit('pricing/setSearchFilterItem', {item: 'distribution', value})
            }
        },
        name: {
            get() {
                let filter = this.$store.getters['pricing/getSearchFilter']
                return filter.hasOwnProperty('distribution') && filter.name ? filter.name : null
            },
            set(value) {
                this.$store.commit('pricing/setSearchFilterItem', {item: 'name', value})
            }
        },
    },
    watch: {
        'property.id': {
            handler(value) {
                this.unit = cloneDeep(this.initialUnit)
                if (value) {
                    this.searchUnit(null, "multiselect")
                    this.searchMode = "multiselect"
                } else {
                    this.searchMode = "search"
                }
            }
        },
        distribution:{
            handler() {
                this.search()
            }
        },
        loading:{
            handler(value){
                if(this.reset){
                    this.loadingReset = value
                } else {
                    this.loadingSubmit = value
                }
            }
        }
    },
    mounted() {
        if (this.distribution && this.distribution.id) {
            this.filter.distribution = [this.distribution.id]
        } else {
            this.filter.distribution = null
        }
        if (this.$route.name === routeNames.RN_SYSTEM_DISTRIBUTION_RATE_PLAN) {
            this.filter.unit_active = 1
        }
        this.initialFilter = cloneDeep(this.filter)
        this.initialUnit = cloneDeep(this.unit)
    },
    created() {
        this.searchDistribution()
    },
}
</script>

<style scoped>

</style>
