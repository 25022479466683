<template>
    <div class="dashboard-wrapper">
        <b-row>
            <b-col class="col-xl-4">
                <b-row>
                    <b-col class="col-xl-12">
                        <b-card no-body>
                            <b-card-header header-class="border-bottom-0 d-flex align-items-center">
                                <div>
                                    <span class="header-2">{{ $t("DETAILS") }}</span>
                                </div>
                            </b-card-header>

                            <b-card-body class="read-only">
                                <div class="items">
                                    <div class="item">
                                        <div class="item-label font-weight-bold">{{ $t("CREATED_AT") }}:</div>
                                        <div class="item-content text-right">
                                            <span>{{ calculationData.created_ts | timestamp }}</span>
                                        </div>
                                    </div>

                                    <div class="item">
                                        <div class="item-label font-weight-bold">{{ $t("START") }}:</div>
                                        <div class="item-content text-right">
                                            <span>{{ calculationData.start_date | date }}</span>
                                        </div>
                                    </div>

                                    <div class="item">
                                        <div class="item-label font-weight-bold">{{ $t("END") }}:</div>
                                        <div class="item-content text-right">
                                            <span>{{ calculationData.end_date | date }}</span>
                                        </div>
                                    </div>

                                    <div class="item">
                                        <div class="item-label font-weight-bold">{{ $t("TOTAL") }}:</div>
                                        <div class="item-content text-right">
                                            <span>{{ calculationData.total_f }}</span>
                                        </div>
                                    </div>

                                    <div class="item">
                                        <div class="item-label font-weight-bold">{{ $t("DUE_DATE") }}:</div>
                                        <div class="item-content text-right">
                                            <span>{{ calculationData.due_date | date }}</span>
                                        </div>
                                    </div>

                                    <div class="item">
                                        <div class="item-label font-weight-bold">{{ $t("STATUS") }}:</div>
                                        <div class="item-content text-right">
                                            <span>{{ calculationData.status.label || '' }}</span>
                                        </div>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>

                <b-row v-if="checkPermission(C_COMPANY_SUBSCRIPTION_CALCULATION_CANCEL) && isCalculationCancelable">
                    <b-col class="col-xl-12">
                        <b-card no-body>
                            <b-card-header header-class="border-bottom-0 d-flex flex-wrap justify-content-between align-items-center">
                                <div>
                                    <span class="header-2">{{ $t("STATUS") }}</span>
                                </div>
                            </b-card-header>

                            <b-card-body>
                                <app-button class="mr-3"
                                            @click="cancelCalculation()">
                                    {{ $t("CANCEL") }}
                                </app-button>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>

            <b-col class="col-xl-8">
                <b-row>
                    <b-col class="col-xl-12">
                        <b-card no-body>
                            <b-card-header header-class="border-bottom-0 d-flex flex-wrap justify-content-between align-items-center">
                                <div>
                                    <span class="header-2">{{ $t("INVOICES") }}</span>
                                </div>
                            </b-card-header>

                            <b-card-body>
                                <company-calculation-invoice-list v-if="hasInvoices"
                                                                  :table-data="calculationData.invoice"
                                ></company-calculation-invoice-list>
                                <app-no-data v-else
                                             :show-tip="false"
                                ></app-no-data>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <b-card no-body>
                            <b-card-header header-class="border-bottom-0 d-flex flex-wrap justify-content-between align-items-center">
                                <div>
                                    <span class="header-2">{{ $t("TRANSACTIONS") }}</span>
                                </div>
                            </b-card-header>

                            <b-card-body>
                                <company-calculation-transaction-list v-if="hasTransactions && allowedActionObject && loadedTransactions"
                                                                      :allowed-actions="allowedActionObject.transaction"
                                                                      :table-data="calculationData.transaction"
                                                                      @openTransactionForm="openTransactionForm"
                                ></company-calculation-transaction-list>
                                <app-no-data v-else-if="loadedTransactions"
                                             :show-tip="false"
                                ></app-no-data>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <app-aside v-model="transactionPanelState">
            <div slot="header">{{ transactionHeader }}</div>
            <transaction-form :transaction-object="transactionObject"
                              :object-type="OBJECT_TYPE_CALCULATION"
                              :object-id="calculationId"
                              @close="() => { this.transactionPanelState = false; } ">
            </transaction-form>
        </app-aside>
    </div>
</template>

<script>
    import AppNoData from "@/components/app/AppNoData";
    import AppAside from "@/components/app/form/AppAside";
    import AppButton from "@/components/app/AppButton/AppButton";
    import TransactionForm from "@/components/finance/credit_card/TransactionForm";
    import CompanyCalculationInvoiceList from "@/components/finance/company_calculation/list/CompanyCalculationInvoiceList";
    import CompanyCalculationTransactionList from "@/components/finance/company_calculation/list/CompanyCalculationTransactionList";
    import {AC_ACCOMMODATION_RESERVATION_TRANSACTION} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {
        C_COMPANY_SUBSCRIPTION_CALCULATION_CANCEL,
    } from "@/shared/component_permission";
    import {
        OBJECT_TYPE_CALCULATION,
        CALCULATION_STATUS_CANCELED,
        PG_ACTION_AUTHORIZE,
        PG_ACTION_PURCHASE,
        PG_ACTION_CAPTURE,
        PG_ACTION_REFUND,
        PG_ACTION_VOID,
        PG_ACTION_PAYMENT_ORDER,
    } from "@/shared/constants";
    import {
        F_CALCULATION_TRANSACTION_PURCHASE,
        F_CALCULATION_TRANSACTION_AUTHORIZE,
        F_CALCULATION_TRANSACTION_PAYMENT_ORDER,
        F_CALCULATION_TRANSACTION_CAPTURE,
        F_CALCULATION_TRANSACTION_REFUND,
        F_CALCULATION_TRANSACTION_VOID,
    } from "@/shared/function_permission";
    import {cancelSubscriptionStatement,} from "@/services/company";
    import {fetchAccessControlData} from "@/services/access";

    const permissionActionMap = {
        initial: {
            [F_CALCULATION_TRANSACTION_PURCHASE]: {id: PG_ACTION_PURCHASE, t: 'PURCHASE'},
            [F_CALCULATION_TRANSACTION_AUTHORIZE]: {id: PG_ACTION_AUTHORIZE, t: 'AUTHORIZE'},
            // [F_CALCULATION_TRANSACTION_PAYMENT_ORDER]: {id: PG_ACTION_PAYMENT_ORDER, t: 'PAYMENT_REQUEST'},
        },
        transaction: {
            [F_CALCULATION_TRANSACTION_CAPTURE]: {id: PG_ACTION_CAPTURE, t: 'CAPTURE'},
            [F_CALCULATION_TRANSACTION_REFUND]: {id: PG_ACTION_REFUND, t: 'REFUND'},
            [F_CALCULATION_TRANSACTION_VOID]: {id: PG_ACTION_VOID, t: 'VOID'},
        }
    }

    function generateACRequest(data) {
        let requestData = [];

        data.forEach(item => {
            for (const [key, value] of Object.entries(permissionActionMap.transaction)) {
                requestData.push({
                    uid: item.id,
                    transaction: item.id,
                    function: key
                })
            }
        })
        for (const [key, value] of Object.entries(permissionActionMap.initial)) {
            requestData.push({
                uid: null,
                function: key
            })
        }

        return requestData
    }

    export default {
        name: "CompanyCalculationForm",
        mixins: [getErrorMessage],
        components: {
            AppNoData,
            AppAside,
            AppButton,
            TransactionForm,
            CompanyCalculationInvoiceList,
            CompanyCalculationTransactionList
        },
        props: {
            company: {
                type: Number,
            },
            calculationId: {
                type: Number,
            },
            calculationData: {
                type: Object,
            },
        },
        data() {
            return {
                allowedActionObject: null,
                loadedTransactions: false,
                transactionObject: {},
                transactionHeader: '',
                transactionPanelState: false,
                C_COMPANY_SUBSCRIPTION_CALCULATION_CANCEL,
                CALCULATION_STATUS_CANCELED,
                OBJECT_TYPE_CALCULATION,
            }
        },
        computed: {
            isCalculationCancelable() {
                return this.calculationData.hasOwnProperty('status')
                    && this.calculationData.status
                    && this.calculationData.status.hasOwnProperty('id')
                    && this.calculationData.status.id !== CALCULATION_STATUS_CANCELED
            },
            hasInvoices() {
                return this.calculationData.hasOwnProperty('invoice')
                    && this.calculationData.invoice
                    && this.calculationData.invoice.length
            },
            hasTransactions() {
                return this.calculationData.hasOwnProperty('transaction')
                    && this.calculationData.transaction
                    && this.calculationData.transaction.length
            },
        },
        methods: {
            openTransactionForm({transaction = {}, action}) {
                const key = transaction.id ? 'transaction' : 'initial'
                this.transactionHeader = `${this.$t(permissionActionMap[key][action].t)} ${transaction.order_number || ''}`
                this.transactionObject = {
                    pg_action: permissionActionMap[key][action].id,
                    amount: transaction.amount || 0,
                    transaction: transaction.id || null,
                    calculation_id: this.calculationId,
                    company: this.company,
                }
                this.transactionPanelState = true
            },
            getActions(transactionList, permissionList) {
                let actionObject = {transaction: {}, initial: []}

                actionObject.initial = permissionList
                    .filter(item => !item.uid && item.visible === true && item.disabled === false)
                    .map(item => item.function)

                transactionList.forEach(transaction => {
                    actionObject.transaction[transaction.id] = permissionList
                        .filter(item => item.uid === transaction.id && item.visible === true && item.disabled === false)
                        .map(item => item.function)
                })

                return actionObject
            },
            cancelCalculation() {
                cancelSubscriptionStatement(this.company, this.calculationId).then(response => {
                    this.$emit('close')
                }, (error) => {
                    this.showErrorMessages(error)
                    this.$emit('close')
                }).catch(error => {
                    this.showErrorMessages(error)
                    this.$emit('close')
                })
            },
        },
        created() {
            if (this.hasTransactions) {
                fetchAccessControlData(AC_ACCOMMODATION_RESERVATION_TRANSACTION, {data: generateACRequest(this.calculationData.transaction), context: {company: this.company}}).then(acResponse => {
                    this.allowedActionObject = this.getActions(this.calculationData.transaction, acResponse.data)
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.loadedTransactions = true
                })
            }
        }
    }
</script>

<style scoped>
.item-label {
    white-space: nowrap;
}

.item-content > span {
    display: inline-block;
    min-width: 100px;
}
</style>
