<template>
    <div class="main_calendar_test__holder--content--pricing_list  animated fadeIn">
        <template v-for="(restriction, key) in cellDataPerRestriction">
            <div class="main_calendar_test__holder--content--pricing_list-item main_calendar_test--background_gray main_calendar_test-bb main_calendar_test__holder--content_dates"
                 ref="calendar_scroll_container" :style="`grid-template-columns: repeat(${dateList.length}, 2.87rem)`">
                <component v-for="(date, dateIndex) in dateList" :key="`${key}-${dateIndex}`"
                           v-if="restriction.data[dateIndex]"
                           :is="restriction.component"
                           :highlighted="isHighlighted(date)"
                           @click="openAside"
                           @startEndDrag="dragStartEnd(date)"
                           @drag="drag(date)"
                           @currentDrag="dragCurrent(date)"
                           @showSuggestionAside="showSuggestionAside(restriction.data[dateIndex].suggestion)"
                           :cell-data="restriction.data[dateIndex]">
                </component>
            </div>
        </template>
    </div>
</template>

<script>
    import TableRestrictionNumberCell
        from "@/components/unit/calendar_opt/Table/Restrictions/TableRestrictionNumberCell";
    import TableRestrictionPeriodCell
        from "@/components/unit/calendar_opt/Table/Restrictions/TableRestrictionPeriodCell";
    import {
        CLOSED_ON_ARRIVAL,
        CLOSED_ON_DEPARTURE,
        MAX_STAY,
        MIN_STAY,
        RELEASE_PERIOD,
        SALES_ENABLED
    } from "@/shared/constants";
    import {EventBus, GE_CALENDAR_OPEN_RESTRICTIONS, GE_SHOW_SUGGESTIONS_ASIDE} from "@/shared/EventBus";
    import {CalendarRowMixin} from "@/mixins/calendar/CalendarRowMixin";
    import moment from 'moment'

    const restrictionMap = (context) => {
        return {
            [MIN_STAY]: {
                component: 'TableRestrictionNumberCell',
                _function: context.getNumberCellData
            }
        }
    }

    export default {
        name: "TableRestrictionContentMinStay",
        mixins: [CalendarRowMixin],
        components: {TableRestrictionPeriodCell, TableRestrictionNumberCell},
        props: {
            scrollLeftPosition: {
                type: Number
            },
            dateList: {
                type: Array
            },
            restrictions: {
                type: Object
            },
            suggestions: {
                type: Object
            },
            unit: {
                type: Object
            },
            dateRange: {
                type: Object
            },
            ratePlan: {
                type: Number
            },
            shiftKeyValue: {
                type: Boolean
            },
        },
        data() {
            return {
                SALES_ENABLED,
                currentDragDate: null,
                dragPeriod: {
                    start: null,
                    end: null
                },
                CLOSED_ON_DEPARTURE, CLOSED_ON_ARRIVAL,
                cellDataPerRestriction: {},
            }
        },
        computed: {
            restrictionTypeList() {
                const restrictions = this.$store.getters['calendar/getRestrictions']
              if(restrictions && typeof restrictions === 'object' && Object.keys(restrictions).length > 0){
                  return Object.fromEntries(
                      Object
                          .entries(this.$store.getters['calendar/getRestrictions'])
                          .filter(([key, value]) => value.id !== SALES_ENABLED)
                  )
              }

            },
            momentDateRange() {
                return {
                    start: moment(this.dateRange.start),
                    end: moment(this.dateRange.end)
                }
            }
        },
        methods: {
            componentType(restriction) {
                if ([CLOSED_ON_ARRIVAL, CLOSED_ON_DEPARTURE].includes(restriction.id)) {
                    return 'TableRestrictionPeriodCell'
                }
                return 'TableRestrictionNumberCell'
            },
            dragCurrent(date) {
                this.currentDragDate = date
            },
            drag(date) {
                if (!this.dragPeriod.start) {
                    this.dragPeriod.start = date
                } else if (!this.dragPeriod.end) {
                    this.dragPeriod.end = date
                    this.openAside()
                }

            },
            dragStartEnd(date) {
                this.dragPeriod.start = date
                this.dragPeriod.end = date
                this.openAside()
            },
            openAside() {

                if (this.dragPeriod.start > this.dragPeriod.end) {
                    this.dragPeriod = {
                        start: this.dragPeriod.end,
                        end: this.dragPeriod.start
                    }
                }

                EventBus.$emit(GE_CALENDAR_OPEN_RESTRICTIONS, {
                    unitId: this.unit.id,
                    ratePlan: this.ratePlan,
                    dateRange: {
                        start: this.dragPeriod.start.format("YYYY-MM-DD"),
                        end: this.dragPeriod.end.format("YYYY-MM-DD")
                    }
                })
                this.dragPeriod.start = null
                this.dragPeriod.end = null
                this.currentDragDate = null
            },
            getDataPerRestriction(dates, restrictionTypes) {
                const dataPerType = {}

                if(typeof restrictionTypes === 'object' &&  Object.keys(restrictionTypes).length > 0){
                    for (const [key, restrictionType] of Object.entries(restrictionTypes)) {
                        const restrictionMeta = restrictionMap(this)[restrictionType.id]
                        if (restrictionMeta) {
                            dataPerType[restrictionType.id] = {}
                            dataPerType[restrictionType.id].component = restrictionMeta.component
                            dataPerType[restrictionType.id].data = restrictionMeta._function(dates, restrictionType)
                        }
                    }
                }

                return dataPerType
            },
            getNumberCellData(dates, restrictionType) {
                const component = 'TableRestrictionPeriodCell'
                return dates.map((date, cellIndex) => {
                    const dateF = date.format('YYYY-MM-DD')
                    const value = this.restrictions[dateF] && this.restrictions[dateF][`${this.ratePlan}-${restrictionType.id}`]
                    const suggestion = restrictionType.id === MIN_STAY && this.suggestions && this.suggestions[dateF] && this.suggestions[dateF]['type_2'] ? this.suggestions[dateF] : null;
                    const suggestionValue = suggestion && suggestion['type_2'] !== null ? parseInt(suggestion["type_2"]) : value;
                    const lastDayOfMonthClass =  date.date() === date.daysInMonth()
                    return {
                        lastDayOfMonthClass, value, suggestion, suggestionValue, component
                    }
                })
            },
            getVisualCellData(dates, restrictionType) {
                const component = 'TableRestrictionNumberCell'
                const weekdayValues = this.getVisualRestrictionValues(dates, restrictionType.id)
                return dates.map((date, cellIndex) => {
                    return {
                        lastDayOfMonthClass: date.date() === date.daysInMonth(),
                        weekdayClasses: this.getVisualCellClasses(date, weekdayValues[cellIndex], weekdayValues[cellIndex - 1], weekdayValues[cellIndex + 1]),
                        component
                    }
                })
            },
            getVisualCellClasses(date, value, valueBefore, valueAfter) {
                return {
                    'main_calendar_test--background_primary-3': !!value,
                    'main_calendar_test--background_warning': !value,
                    'main_calendar_test--status-start': (value !== valueBefore) || date.isSame(this.momentDateRange.start, 'day'),
                    'main_calendar_test--status-end': (value !== valueAfter) || date.isSame(this.momentDateRange.end, 'day')
                }
            },
            getVisualRestrictionValues(dates, restrictionId) {
                return dates.map(date => {
                    const dateF = date.format('YYYY-MM-DD')
                    let value = this.restrictions[dateF] && this.restrictions[dateF][`${this.ratePlan}-${restrictionId}`]
                    if (!value) {
                        return true
                    } else {
                        return !!value.split("").map(Number).includes(date.isoWeekday());
                    }
                })
            },
            isHighlighted(date) {
                return (this.shiftKeyValue && this.dragPeriod.start && this.currentDragDate)
                    && ((date >= this.dragPeriod.start && date <= this.currentDragDate) ||
                        (date <= this.dragPeriod.start && date >= this.currentDragDate)
                    )
            },
            showSuggestionAside(suggestion) {
                if (suggestion && suggestion.edit) {
                    EventBus.$emit(GE_SHOW_SUGGESTIONS_ASIDE, {
                        suggestion: suggestion['id_2'],
                        reportId: suggestion['reportId'],
                        unitId: this.unit.id
                    })
                }
            }
        },
        watch: {
            shiftKeyValue(val) {
                if (val === false) {
                    this.dragPeriod.start = null
                    this.dragPeriod.end = null
                    this.currentDragDate = null
                }
            },
            restrictions: {
                immediate: true,
                deep: true,
                handler(value) {
                    if (value) {
                        this.cellDataPerRestriction = this.getDataPerRestriction(this.dateList, this.restrictionTypeList)
                    }
                }
            }
        },
    }
</script>

<style scoped>

</style>
