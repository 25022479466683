import http from '../http'
import {INBOX} from "@/services/endpoints";

export function getThreadList(params) {
    return http.request({
        url: INBOX + '/list',
        method: 'get',
        params: params
    })
}


export function createMessage(threadId, data) {
    return http.request({
        url: `${INBOX}/${threadId}/message`,
        method: 'post',
        data: data
    })
}
export function createSpecialOffer(threadId, data) {
    return http.request({
        url: `${INBOX}/${threadId}/special-offer/create`,
        method: 'post',
        data: data
    })
}

export function getThreadMessageList(params) {
    return http.request({
        url: `${INBOX}/messages`,
        method: 'get',
        params: params
    })
}


export function getThreadTypeList(params) {
    return http.request({
        url: `${INBOX}/type-list`,
        method: 'get',
        params: params
    })
}

export function markAsRead(threadId) {
    return http.request({
        url: `${INBOX}/${threadId}/message/mark-as-read`,
        method: 'post',
    })
}

export function resyncThreads(threadListIDs) {
    return http.request({
        url: `${INBOX}/resync`,
        method: 'post',
        data: threadListIDs
    })
}


export function changeMessageStatus(threadListIDs) {
    return http.request({
        url: `${INBOX}/change-message-status`,
        method: 'post',
        data: threadListIDs
    })
}
export function fetchThread(threadId) {

    return http.request({
        url: `${INBOX}/${threadId}`,
        method: 'get',
    })
}
export function approveSpecialOffer(threadId) {

    return http.request({
        url: `${INBOX}/${threadId}/special-offer/approve`,
        method: 'post',
    })
}

export function declineSpecialOffer(threadId) {

    return http.request({
        url: `${INBOX}/${threadId}/special-offer/decline`,
        method: 'post',
    })
}
export function acceptPendingReservation(threadId){
    return http.request({
        url: `${INBOX}/${threadId}/pending-reservation/accept`,
        method: 'post',
    })
}
export function declinePendingReservation(threadId,data){
    return http.request({
        url: `${INBOX}/${threadId}/pending-reservation/decline`,
        method: 'post',
        data
    })
}
