<template>
    <form autocomplete="off">
        <b-row class="d-flex justify-content-center">
            <b-col md="6" lg="4" class="mb-4">
                <label>{{ $t("IMPORT_FROM") }}</label>
                <app-input-control :error-object="validationObject.distribution"
                                   :listening-for-event="listenForError">
                    <template v-slot:input="data">
                        <app-select
                            :state="data.state"
                            v-model="formData.distribution"
                            :disabled="formData.distribution !== null"
                            :search-empty-item="false"
                            :options="distributionList">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <template v-if="formData.distribution === BOOKING">
            <b-row class="d-flex justify-content-center">
                <b-col md="6" lg="4" class="mb-4">
                    <label>{{ $t("EXTERNAL_ID") }}</label>
                    <app-input-control :error-object="validationObject.external_id"
                                       :listening-for-event="listenForError">
                        <template v-slot:input="data">
                            <b-input
                                :state="data.state"
                                v-model="formData.external_id"
                                @blur="handleExternalIdEntry">
                            </b-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center"
                   v-if="importData && importData.hasOwnProperty('property_connected') && !importData.property_connected">
                <b-col md="6" lg="4" class="mb-4 text-center">
                    <app-button
                        @click="connectProperty">
                        {{ $t("CONNECT_PROPERTY") }}
                    </app-button>
                </b-col>
            </b-row>
        </template>

        <template v-else-if="formData.distribution === AIRBNB">
            <b-row class="d-flex justify-content-center">
                <b-col md="6" lg="4" class="mb-4">
                    <label>{{ $t("HOST_ID") }}</label>
                    <app-input-control :error-object="validationObject.host_id"
                                       :listening-for-event="listenForError">
                        <template v-slot:input="data">
                            <b-input
                                :state="data.state"
                                v-model="formData.host_id"
                                @blur="fetchData">
                            </b-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
            <template
                v-if="formData && formData.hasOwnProperty('host_connected') && !formData.host_connected && formData.hasOwnProperty('connect_data') && formData.connect_data && formData.connect_data.hasOwnProperty('connect_scopes')">
                <b-row class="d-flex justify-content-center">
                    <b-col md="6" lg="4" class="mb-4 text-center">
                        <div class="d-flex align-items-start flex-column bd-highlight"
                             :key="connectScopeIndex"
                             v-for="(connectScope, connectScopeIndex) in formData.connect_data.connect_scopes">
                            <b-form-checkbox
                                :disabled="connectScope.append === null || connectScope.append === ''"
                                v-model="connectScope.connected"
                                :unchecked-value="false"
                                :value="true">
                                {{ connectScope.label }}
                            </b-form-checkbox>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="d-flex justify-content-center">
                    <b-col md="6" lg="4" class="mb-4 text-center">
                        <app-button
                            @click="connectHost">
                            {{ $t("CONNECT_HOST") }}
                        </app-button>
                    </b-col>
                </b-row>
            </template>
        </template>
        <loading :show="loading"></loading>
    </form>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppInputControl from "@/components/app/AppInputControl";
import {AIRBNB, BOOKING} from "@/shared/constants";
import AppButton from "@/components/app/AppButton/AppButton";
import Loading from "vue-full-loading/src/Loading";
import {
    PROPERTY_IMPORT_AIRBNB_HOST_ID,
    PROPERTY_IMPORT_BOOKING_EXTERNAL_ID,
    PROPERTY_IMPORT_DISTRIBUTION_NOT_SET,
    PROPERTY_IMPORT_EXISTING_PROPERTY_STATUS_VALIDATION_FAILED,
    PROPERTY_IMPORT_NOT_UNIQUE_EXTERNAL_ID,
} from "@/shared/error_codes";

const FORM_DATA_APPLICABLE_IMPORT_DATA_KEY_LIST = [
    "property_connected",
    "host_connected",
    "connect_data",
    "unit_count",
]

export default {
    name: "ImportPropertyWizardSourceForm",
    components: {AppButton, AppInputControl, AppSelect, Loading},
    data() {
        return {
            distributionList: [
                {id: AIRBNB, name: "AirBnB"},
                {id: BOOKING, name: "Booking.com"},
            ],
            formData: {
                distribution: null,
                external_id: null,
                host_id: null,
            },
            validationObject: {
                distribution: {
                    input: [
                        PROPERTY_IMPORT_DISTRIBUTION_NOT_SET,
                    ],
                },
                external_id: {
                    input: [
                        PROPERTY_IMPORT_BOOKING_EXTERNAL_ID,
                        PROPERTY_IMPORT_NOT_UNIQUE_EXTERNAL_ID,
                        PROPERTY_IMPORT_EXISTING_PROPERTY_STATUS_VALIDATION_FAILED,
                    ],
                },
                host_id: {
                    input: [
                        PROPERTY_IMPORT_AIRBNB_HOST_ID,
                    ],
                },
            },
            BOOKING,
            AIRBNB,
            bookingConnectPropertyLink: "https://www.google.com/url?q=https://admin.booking.com/?groups_redirect%3D%252Fhotel%252Fhoteladmin%252Fextranet_ng%252Fmanage%252Fchannel-manager%252Fwidget.html?provider_id%3D979&sa=D&source=docs&ust=1660202529412459&usg=AOvVaw1XI5ZIOZgIiPg_1_lJXsqx",
            airbnbConnectHostLink: "",
        }
    },
    props: {
        importData: {
            type: Object,
        },
        distribution: {
            type: Number,
            default: null,
        },
        entity: {
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        listenForError: {
            type: Boolean,
            required: true,
        },
    },
    watch: {
        formData: {
            handler(value) {
                this.$emit("update", {source: value})
            },
            deep: true,
            immediate: true,
        },
        importData: {
            handler(value) {
                if (value && typeof value === "object") {
                    this.updateFormData(value)
                } else {
                    this.$delete(this.formData, "property_connected")
                    this.$delete(this.formData, "host_connected")
                    this.$delete(this.formData, "connect_data")
                    this.$delete(this.formData, "unit_count")
                }
            },
            deep: true,
            immediate: true,
        },
        distribution: {
            handler(value) {
                if (value) {
                    this.$set(this.formData, "distribution", value)
                }
            },
            immediate: true,
        },
        entity: {
            handler(value) {
                if (value) {
                    let formDataProperty = null
                    if (this.distribution) {
                        formDataProperty = this.distribution === AIRBNB ? "host_id" : "external_id"
                    }
                    if (formDataProperty !== null) {
                        this.$set(this.formData, formDataProperty, value)
                        this.fetchData()
                    }
                }
            },
            immediate: true,
        },
    },
    methods: {
        handleExternalIdEntry() {
            this.fetchData()
        },
        fetchData() {
            const request = this.prepareRequest()
            this.$emit("fetchData", request)
        },
        prepareRequest() {
            let request = {}
            if (this.formData.distribution === AIRBNB) {
                this.$set(request, "host_id", this.formData.host_id)
                this.$set(request, "options", {
                    general_data: true,
                    listings: true,
                    booking_settings: true,
                    listing_descriptions: true,
                    listing_rooms: true
                })
            } else if (this.formData.distribution === BOOKING) {
                this.$set(request, "external_id", this.formData.external_id)
            }
            return request
        },
        connectProperty() {
            window.addEventListener("focus", this.onWindowFocus)
            window.open(this.bookingConnectPropertyLink, "_blank");
        },
        connectHost() {
            this.prepareAirbnbConnectHostLink()

            window.open(this.airbnbConnectHostLink, "_blank");
        },
        prepareAirbnbConnectHostLink() {
            let connectScopes = []
            this.formData.connect_data.connect_scopes.forEach(connectScope => {
                if (connectScope.hasOwnProperty('connected') && connectScope.hasOwnProperty('append')) {
                    if (connectScope.connected && connectScope.append !== null && connectScope.append !== "") {
                        connectScopes.push(connectScope.append)
                    }
                }
            })
            this.airbnbConnectHostLink = this.formData.connect_data.connect_template.replace('[scope_list]', connectScopes.join(""))
        },
        updateFormData(importData) {
            for (const [key, value] of Object.entries(importData)) {
                if (FORM_DATA_APPLICABLE_IMPORT_DATA_KEY_LIST.indexOf(key) > -1) {
                    this.$set(this.formData, key, value)
                }
            }
        },
        onWindowFocus() {
            this.fetchData()
            window.removeEventListener("focus", this.onWindowFocus)
        },
    },
}
</script>

<style scoped>

</style>
