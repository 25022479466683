<template>
    <b-form>
        <b-row>
            <b-col v-if="hasPermission" xl="3" lg="3" md="6" class="mb-4">
                <label>{{$t('INTEGRATED')}}</label>
                <app-select :value="integrated.values.company_value" :options="select_integration_options" @input="updateIntegrated($event)">
                </app-select>
            </b-col>
            <template v-if="initial_value">
                <b-col  xl="3" lg="3" md="6" class="mb-4">
                    <label>Client id</label>
                    <b-input :value="loginDetails.values.login_details.client_id" @input="updateClientId($event)"></b-input>
                </b-col>
                <b-col xl="3" lg="3" md="6" class="mb-4">
                    <label>Client secret</label>
                    <b-input :value="loginDetails.values.login_details.client_secret" @input="updateClientSecret($event)"></b-input>
                </b-col>
            </template>
        </b-row>
    </b-form>
</template>

<script>
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {EventBus, GE_UPDATE_CM_SETUP_PARAMETERS} from "@/shared/EventBus";
    import {
        INTEGRATIONS_CM_SETUP_DISTRIBUTION_INTEGRATED,
        INTEGRATIONS_CM_SETUP_DISTRIBUTION_TA_LOGIN_DETAILS
    } from "@/shared/component_permission";

    export default {
        name: "TripAdvisor",
        props: {
            setupParameter: {
                type: Object
            },
            distribution:{
                type:Object
            }
        },
        components: {AppSelect},
        data() {
            return {
                integrated: null,
                loginDetails:null,
                initial_value:null,
                select_integration_options: [
                    {id: 1, name: this.$t('YES')},
                    {id: 0, name: this.$t('NO')},
                ]
            }
        },
        methods: {
            setValues(setupObject) {
                this.integrated = setupObject.parameters.find(el => el.parameter_id === 7)
                this.loginDetails = setupObject.parameters.find(el => el.parameter_id === 11)
                this.initial_value = Number(this.integrated.values.company_value)
                let hide= !this.initial_value && !this.hasPermission
                this.$emit('hideTaComponent', hide)
            },
            updateIntegrated(value){
                this.integrated.values.company_value = value
                EventBus.$emit(GE_UPDATE_CM_SETUP_PARAMETERS,{distribution:this.distribution.id, parameter_id:this.integrated.parameter_id,value})
            },
            updateClientId(value){

                this.loginDetails.values.login_details.client_id = value
                EventBus.$emit(GE_UPDATE_CM_SETUP_PARAMETERS,{distribution:this.distribution.id, parameter_id:this.loginDetails.parameter_id,value:this.loginDetails.values.login_details})
            },
            updateClientSecret(value){
                this.loginDetails.values.login_details.client_secret  = value
                EventBus.$emit(GE_UPDATE_CM_SETUP_PARAMETERS,{distribution:this.distribution.id, parameter_id:this.loginDetails.parameter_id,value:this.loginDetails.values.login_details})

            }
        },
        computed:{
            hasPermission() {
                return this.$store.getters['user/getPermission'](INTEGRATIONS_CM_SETUP_DISTRIBUTION_INTEGRATED)
            },
        },
        watch: {
            setupParameter: {
                handler(setupObject) {
                    this.setValues(setupObject)
                },
                immediate: true
            },

        },

    }
</script>

<style scoped>

</style>
