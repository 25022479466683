import http from '../http'
import {
    ADDITIONAL_SETUP_URL,
    COMPANY,
    COMPANY_SUBSCRIPTION_URL,
    PROPERTY_CANCELATION_POLICIES_URL,
    SUBSCRIPTION_URL
} from "@/services/endpoints";

export function getCompanyList(params) {
  return http.request({
    url: COMPANY,
    method: 'get',
    params
  })
}

export function fetchCompany(id) {
  return http.request({
    url: COMPANY + '/' + id,
    method: 'get'
  })
}

export function getTenantTypeList() {
    return http.request({
        url: COMPANY + '/tenant-type',
        method: 'get'
    })
}

export function updateCompany(id, data) {
    return http.request({
        url: COMPANY + '/' + id,
        method: 'put',
        data
    })
}

export function createCompany(data) {
    return http.request({
        url: COMPANY,
        method: 'post',
        data
    })
}

export function getActiveSubscription(id, params) {
    return http.request({
        url: COMPANY_SUBSCRIPTION_URL(id),
        method: 'get',
        params
    })
}

export function fetchActiveSubscription(id, subscriptionId) {
    return http.request({
        url: COMPANY_SUBSCRIPTION_URL(id) + '/' + subscriptionId,
        method: 'get'
    })
}

export function signContract(id, data) {
    return http.request({
        url: COMPANY_SUBSCRIPTION_URL(id) + '/contract',
        method: 'post',
        data: data
    })
}

export function updateContractDetails(id, subscriptionId, data) {
    return http.request({
        url: COMPANY_SUBSCRIPTION_URL(id) + '/' + subscriptionId + '/contract',
        method: 'put',
        data: data
    })
}

export function extendContract(id, subscriptionId, data) {
    return http.request({
        url: COMPANY_SUBSCRIPTION_URL(id) + '/' + subscriptionId + '/contract/extend',
        method: 'put',
        data: data
    })
}

export function extendDemoSubscription(id, subscriptionId, data) {
    return http.request({
        url: COMPANY_SUBSCRIPTION_URL(id) + '/' + subscriptionId + '/extend',
        method: 'put',
        data: data
    })
}

export function activateDemoSubscription(id) {
    return http.request({
        url: COMPANY_SUBSCRIPTION_URL(id) + '/activate',
        method: 'post'
    })
}

export function reactivateDemoSubscription(id, subscriptionId, data) {
    return http.request({
        url: COMPANY_SUBSCRIPTION_URL(id) + '/' + subscriptionId +  '/activate',
        method: 'put',
        data: data
    })
}

export function fetchSubscriptionPackage(id, packageId) {
    return http.request({
        url: COMPANY_SUBSCRIPTION_URL(id) + '/package/' + packageId,
        method: 'get'
    })
}

export function fetchSubscriptionInvoiceSetup(id) {
    return http.request({
        url: COMPANY_SUBSCRIPTION_URL(id) + '/invoice-setup',
        method: 'get'
    })
}

export function updateSubscriptionInvoiceSetup(id, data) {
    return http.request({
        url: COMPANY_SUBSCRIPTION_URL(id) + '/invoice-setup',
        method: 'post',
        data: data
    })
}

export function fetchSubscriptionPaymentType(id) {
    return http.request({
        url: COMPANY_SUBSCRIPTION_URL(id) + '/payment-type',
        method: 'get'
    })
}

export function updateSubscriptionPaymentType(id, data) {
    return http.request({
        url: COMPANY_SUBSCRIPTION_URL(id) + '/payment-type',
        method: 'post',
        data: data
    })
}

export function fetchSubscriptionStatements(id, params) {
    return http.request({
        url: COMPANY_SUBSCRIPTION_URL(id) + '/statement',
        method: 'get',
        params: params
    })
}

export function createSubscriptionStatement(id, data) {
    return http.request({
        url: COMPANY_SUBSCRIPTION_URL(id) + '/statement',
        method: 'post',
        data: data
    })
}

export function cancelSubscriptionStatement(id, statement_id) {
    return http.request({
        url: COMPANY_SUBSCRIPTION_URL(id) + '/statement/' + statement_id + '/cancel',
        method: 'put'
    })
}

export function getSystemCancellationPolicies(id) {
    return http.request({
        url: COMPANY + '/' + id + '/cancelation-policies',
        method: 'get'
    })
}

export function getSystemCancellationPolicyOptions(id) {
    return http.request({
        url: COMPANY + '/' + id + '/cancelation-policies/options',
        method: 'get'
    })
}

export function createSystemCancellationPolicy(id, data) {
    return http.request({
        url: COMPANY + '/' + id + '/cancelation-policies',
        method: 'post',
        data: data
    })
}

export function updateSystemCancellationPolicy(id, policyId, data) {
    return http.request({
        url: COMPANY + '/' + id + '/cancelation-policies/' + policyId,
        method: 'put',
        data: data
    })
}

export function deleteSystemCancellationPolicy(id, policyId) {
    return http.request({
        url: COMPANY + '/' + id + '/cancelation-policies/' + policyId,
        method: 'delete'
    })
}

export function getSystemSalesPeriods(id) {
    return http.request({
        url: COMPANY + '/' + id + '/sales-period-list',
        method: 'get'
    })
}

export function createSystemSalesPeriodSetup(id, data) {
    return http.request({
        url: COMPANY + '/' + id + '/sales-period',
        method: 'post',
        data: data
    })
}

export function updateSystemSalesPeriodSetup(id, salesPeriodId, data) {
    return http.request({
        url: COMPANY + '/' + id + '/sales-period/' + salesPeriodId,
        method: 'put',
        data: data
    })
}

export function createSystemSalesPeriod(id, salesPeriodId, data) {
    return http.request({
        url: COMPANY + '/' + id + '/sales-period/' + salesPeriodId + '/period',
        method: 'post',
        data: data
    })
}

export function updateSystemSalesPeriod(id, salesPeriodId, periodId, data) {
    return http.request({
        url: COMPANY + '/' + id + '/sales-period/' + salesPeriodId + '/period/' + periodId,
        method: 'put',
        data: data
    })
}

export function deleteSystemSalesPeriod(id, salesPeriodId, periodId) {
    return http.request({
        url: COMPANY + '/' + id + '/sales-period/' + salesPeriodId + '/period/' + periodId,
        method: 'delete'
    })
}

export function fetchAdditionalSetupData() {
    return http.request({
        url: ADDITIONAL_SETUP_URL,
        method: 'get'
    })
}

export function createAdditionalSetupData(data) {
    return http.request({
        url: ADDITIONAL_SETUP_URL,
        method: 'post',
        data: data
    })
}

export function updateSubscriptionFees(id, subscriptionId, data) {
    return http.request({
        url: COMPANY_SUBSCRIPTION_URL(id) + '/' + subscriptionId + '/fee',
        method: 'put',
        data
    })
}

export function setFeatureParameters(id, data) {
    return http.request({
        url: COMPANY + '/' + id + '/feature-parameters',
        method: 'put',
        data
    })
}

export function getWhiteLabelSetup(id) {
    return http.request({
        url: COMPANY + '/' + id + '/whitelabel-setup',
        method: 'get',
    })
}

export function getCompanyLogo(id) {
    return http.request({
        url: COMPANY + '/' + id +  '/logo' ,
        method: 'get'
    })
}

export function getCompanyLogoAlt(id) {
    return http.request({
        url: COMPANY + '/' + id +  '/logo-alt' ,
        method: 'get'
    })
}

export function getCompanyTemplate(slug) {
    return http.request({
        url: COMPANY + '/' + slug + '/template',
        method: 'get'
    })
}

export function setCompanyMailServer(id,data) {
    return http.request({
        url: COMPANY + '/' + id +  '/mail-server' ,
        method: 'put',
        data
    })
}

export function getMailServerSetup(id) {
    return http.request({
        url: COMPANY + '/' + id +  '/mail-server' ,
        method: 'get'
    })
}
