<template functional>
    <div @click.shift.exact="listeners.drag"
         @click.exact="listeners.startEndDrag"
         @mouseover.shift="listeners.currentDrag"

         class="availability_calendar--cell position-relative availability_calendar--availability-cell availability_calendar--poiner"
         :class="{'availability_calendar__bg-hover': props.highlighted, ...props.cellData.lastDayOfMonthClass}"
         :style="{'grid-column': props.cellData.column + '/ span 1'}">
        <div v-if="props.cellData.unit_data.availability.available_count > 0" class="justify-content-start pb-4 pl-1 availability_calendar_numeric-indicator">
            {{props.cellData.unit_data.availability.available_count}}
        </div>
        <div class="justify-content-center main_calendar--status">
            <template v-if="props.cellData.unit_data.availability.available_count > 0 && Number(props.cellData.room_status) === 1">
                <span v-if="props.cellData.display === 0 && props.cellData.unit_data.hasOwnProperty('default_rates') && props.cellData.unit_data.default_rates">
                  {{props.cellData.unit_data.default_rates }}
                </span>
                <span v-else-if="props.cellData.display === 1 && props.cellData.min_stay">
                  {{ props.cellData.min_stay }}
                </span>
                <i  v-else class="fa fa-circle availability_calendar_indicator-open"></i>
            </template>

            <i v-else class="fa fa-circle mr-1 main_calendar_test_indicator-close" ></i>

        </div>
        <div v-if="props.cellData.unit_data.availability.available_count ===0 && Number(props.cellData.room_status) === 1"
             class="justify-content-end pb-4 mr-1">
            <i class="fa fa-circle mr-1 availability_calendar_indicator-open " style="font-size: 0.2rem"></i>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PropertyCalendarItemContigentCell",
        props: {
            cellData: {
                type: Object
            },
            highlighted: {
                type: Boolean
            },
        },

    }
</script>

<style scoped>

</style>
