<template>
    <b-form @submit.prevent="save" v-if="company.workflow_id === WORKFLOW_BOOKER_TOOLS">
        <b-row>
            <b-col xl="3" lg="3" md="6" class="mb-4">
                <label>{{$t('PRICING_TYPE')}}</label>
                <app-select  v-model="pricingType.values.property_value"  :options="pricingType.options" :search-empty-item="false"></app-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button-submit :loading="save_loading"></app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {WORKFLOW_BOOKER_TOOLS} from "@/shared/constants";

    export default {
        name: "BookingSetupForm",
        props:{
          setupParameter:{
              type:Object
          },
            distribution:{
              type:Object
            },
            save_loading:{
                type:Boolean
            }
        },

        data() {
            return {

                pricingType: null,
                paymentType: null,
                WORKFLOW_BOOKER_TOOLS
            }
        },
        computed:{
            propertyObject() {
                return this.$store.getters['property/getProperty']
            },
            company(){
                return this.$store.getters['user/getCurrentCompany']
            }
        },
        methods: {
            setValues(setupObject) {
                this.pricingType = setupObject.parameters.find(el => el.parameter_id === 14)
            },
            save(){
                let requestPricingType = {
                    parameter:this.pricingType.parameter_id,
                    distribution: this.distribution.id,
                    property:{
                        id:this.propertyObject.id,
                        value:this.pricingType.values.property_value
                    }
                }
                this.$emit('updateParameter', {data:[requestPricingType]})
            }
        },
        components: {AppButtonSubmit, AppSelect},
        watch: {
            setupParameter: {
                handler(setupObject) {
                    this.setValues(setupObject)
                },
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>
