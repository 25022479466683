<template>
    <div>
        <tag-list :refresh="refresh" @openTagSidebar="openSidebar" @openNew="open"
                  @showButton="showButton=true" :not_tag_type="[TAG_TYPE_DESTINATION]" :edit_tag_sidebar="true"></tag-list>
        <app-aside v-model="tag_sidebar" :widths="['col-lg-4','col-sm-12', '40%']">
            <template slot="header">{{ $t('TAG') }}</template>
            <add-custom-tag :tag="tag" @saved="saved" :newTag="newTag"></add-custom-tag>
        </app-aside>
        <app-aside v-model="new_tag_sidebar" :widths="['col-lg-4','col-sm-12', '40%']">
            <template slot="header">{{ $t('NEW_TAG') }}</template>
            <new-custom-tag @saved="saved"></new-custom-tag>
        </app-aside>
    </div>
</template>

<script>
    import TagList from "@/components/tag/list/TagList";
    import {TAG_DESTINATION_TYPE_CITY, TAG_TYPE_CUSTOM, TAG_TYPE_DESTINATION} from "@/shared/constants";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AddTag from "@/components/tag/sidebar/AddTag";
    import AppAside from "@/components/app/form/AppAside";
    import AddCustomTag from "@/components/tag/sidebar/CustomTag";
    import {C_WEBSITE_TAG_NEW_V} from "@/shared/component_permission";
    import NewCustomTag from "@/components/tag/sidebar/NewCustomTag";
    import {EventBus} from "@/shared/EventBus";

    export default {
        name: "Index",
        components: {NewCustomTag, AddCustomTag, AddTag, AppButton, TagList, AppAside},
        data() {
            return {
                TAG_DESTINATION_TYPE_CITY, TAG_TYPE_CUSTOM,
                C_WEBSITE_TAG_NEW_V,TAG_TYPE_DESTINATION,
                tag_sidebar: false,
                parent_tag: null,
                refresh: false,
                tag: {},
                newTag: false,
                showButton: false,
                new_tag_sidebar: false
            }
        },
        methods: {
            open() {
                this.tag = null
                this.new_tag_sidebar = true
            },
            saved() {
                this.tag_sidebar = false;
                this.new_tag_sidebar = false
                this.refresh = !this.refresh
            },
            openSidebar(data) {
                this.newTag = false
                this.tag = data.item
                this.tag_sidebar = true
            }
        },
        created(){
            EventBus.$on('AddNewCustomTag', () => {
                this.open()
            })
        }
    }
</script>

<style scoped>

</style>
