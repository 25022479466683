<template>
    <div v-if="basicInfo !== null">
        <unit-basic-form @unitBasicSubmit="saveData" @deleteUnit="deleteData" :dataObject="basicInfo"></unit-basic-form>
        <app-aside v-if="canEditOccupancy"
                   :widths="['col-lg-5', 'col-sm-12', '50%']"
                   v-model="occupancySidebarPanelState"
                   @input="onOccupancySidebarPanelClose">
            <template slot="header">
                <app-object-header
                    :name="$t('EDIT_OCCUPANCY')">
                </app-object-header>
            </template>
            <unit-occupancy-list
                @update="onOccupancyUpdate"
                :unit="basicInfo"
                :occupancy-list="occupancyList"
                :maximum-guests="maximumGuests"
                enable-reset
                enable-suggestions>
            </unit-occupancy-list>
        </app-aside>
        <app-recalculate-rates-modal
            v-model="recalculateRatesModalState"
            @confirm="recalculateRates"
            @cancel="recalculateRatesModalState = false"
            :loading="recalculatingRates">
        </app-recalculate-rates-modal>
    </div>
</template>

<script>
    import {GET_UNIT_ITEM} from "@/store/store_types";
    import UnitBasicForm from '@/components/unit/basic/UnitBasicForm'
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import routeNames from '@/router/routeNames'
    import {deleteUnit} from "@/services/unit";
    import AppObjectHeader from "@/components/app/AppObjectHeader";
    import UnitOccupancyList from "@/components/unit/calendar/forms/occupancy/UnitOccupancyList";
    import {C_UNIT_CALENDAR_OCCUPANCY_E} from "@/shared/component_permission";
    import AppAside from "@/components/app/form/AppAside";
    import AppRecalculateRatesModal from "@/components/app/modal/AppRecalculateRatesModal";
    import {setPriceFromLosOccupancy} from "@/services/pricing";
    import _cloneDeep from "lodash/cloneDeep";

    export default {
        name: "Index",
        components: {
            AppRecalculateRatesModal,
            UnitOccupancyList,
            AppObjectHeader,
            UnitBasicForm,
            AppAside,
        },
        mixins: [getErrorMessage],
        data() {
            return {
                unitBasicInfoRequestData: null,
                occupancySidebarPanelState: false,
                occupancyList: [],
                recalculateRatesModalState: false,
                recalculatingRates: false,
                showRecalculateModal: false,
                updatedOccupancyList: [],
            }
        },
        methods: {
            saveData(object) {
                this.$store.dispatch('actionUpdateUnit', {id: this.$route.params.unit_id, data: object.data}).then((response) => {
                    if (response.data.hasOwnProperty("open_occupancy_sidebar") && response.data.open_occupancy_sidebar) {
                        this.unitBasicInfoRequestData = object.data
                        this.occupancyList = response.data.occupancy_list
                        this.occupancySidebarPanelState = true
                        return
                    }
                        notifySuccess()
                    }, error => {
                    this.showErrorMessages(error, object.validation)
                    }
                )
            },
            deleteData(id) {
                deleteUnit(id).then(() => {
                        notifySuccess()
                        this.$router.push({name: routeNames.RN_PROPERTY_UNIT_LIST})
                    }, error => {
                       this.showErrorMessages(error)
                    }
                )
            },
            onOccupancyUpdate(payload) {
                this.occupancySidebarPanelState = false
                this.updatedOccupancyList = payload.occupancy_list
                if (payload.hasOwnProperty("changes") && !!payload.changes && payload.changes.hasOwnProperty("price_relevant_change") && payload.changes.price_relevant_change) {
                    this.recalculateRatesModalState = true
                }
                this.saveData({data: this.unitBasicInfoRequestData})
            },
            onOccupancySidebarPanelClose() {
                if (this.showRecalculateModal) {
                    this.recalculateRatesModalState = true
                }
            },
            recalculateRates(payload) {
                this.recalculatingRates = true
                if (!payload.recalculate) {
                    this.$nextTick(() => {
                        this.recalculatingRates = false
                    })
                } else {
                    const request = {
                        previous_occupancy_list: this.occupancyList,
                        use_current_los: true,
                        override_all: payload.override_all
                    }
                    setPriceFromLosOccupancy(this.basicInfo.id, request).then(() => {
                        notifySuccess()
                    }, (error) => {
                        this.showErrorMessages(error)
                    }).finally(() => {
                        this.recalculatingRates = false
                    })
                }
            },
        },
        computed: {
            basicInfo() {
                return this.$store.getters[GET_UNIT_ITEM]('basic')
            },
            canEditOccupancy() {
                return this.checkPermission(C_UNIT_CALENDAR_OCCUPANCY_E)
            },
            maximumGuests() {
                return this.unitBasicInfoRequestData && typeof this.unitBasicInfoRequestData === "object" && this.unitBasicInfoRequestData.hasOwnProperty("max_guests")
                    ? this.unitBasicInfoRequestData.max_guests : null;
            },
        },
        watch: {
            recalculateRatesModalState: {
                handler(value) {
                    if (!value) {
                        this.occupancyList = _cloneDeep(this.updatedOccupancyList)
                    }
                },
            }
        },
    }
</script>


<style scoped>

</style>
