<template>
    <div>
        <app-table-v2

            :fields="fields"
            :search-on-create="true"
            :provider="getProvider"
            :limit-list="[DEFAULT_PAGINATION_LIMIT, 20, 50, 100]"
            @busyToggled="toggleBusy"
            :search-event="EVENT_TABLE_SEARCH"
            :filter="filter"
            :selectable="true"
        >
            <template v-slot:selectedContainer="data">
                <app-button class="mr-3" :loading="loading_act" @click="setMultiselectActivation(data.selected,'rates')">
                       {{ $t('ACTIVATE_RATES')  }}
                </app-button>
                <app-button class="mr-3" :loading="loading_act" @click="setMultiselectActivation(data.selected,'min_stay')">
                    {{ $t('ACTIVATE_MIN_STAY') }}
                </app-button>
                <app-button @click="groupNew(data.selected)" button_type="new">{{ $t('NEW_GROUP') }}</app-button>
                <app-button class="ml-3" @click="assignToFilter(data.selected)">{{ $t('ASSIGN_TO_GROUP') }}</app-button>


                <b-modal :title="$t('NEW_GROUP')" v-model="new_aside">

                    <b-form>

                        <b-row class="mb-2">
                            <b-col lg="12" class="mb-4">
                                <app-input-control :error-object="validationErrorObject.title">
                                    <template v-slot:input="data">
                                        <label>{{ $t('NAME') }}</label>
                                        <b-input :state="data.state" v-model="title_new_group"></b-input>
                                    </template>
                                </app-input-control>
                            </b-col>
                        </b-row>
                    </b-form>
                    <template v-slot:modal-footer>
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <div v-if="data.totalRows > 1 && data.totalRows > data.selected.length">
                                <b-checkbox v-model="selectAllForNewGroup">
                                    {{ $t("APPLY_TO_ALL_NUMBER", {number: data.totalRows}) }}
                                    <span v-if="data.selected.length === 1"
                                          class="d-block text-muted font-xs">({{ $t("ITEM_SELECTED", {value: data.selected.length}) }})</span>
                                    <span v-if="data.selected.length > 1"
                                          class="d-block text-muted font-xs">({{ $t("ITEMS_SELECTED", {value: data.selected.length}) }})</span>
                                </b-checkbox>

                            </div>
                            <app-button-submit :disabled="title_new_group === null" :loading="loading_new_group"
                                               @click="addNewGroupCheck(data.selected)"></app-button-submit>
                        </div>
                    </template>
                </b-modal>
                <b-modal :title="$t('ASSIGN_TO_GROUP')" v-model="aside">

                    <b-form>

                        <b-row class="mb-2">
                            <b-col lg="12" class="mb-4">
                                <label>{{ $t('GROUP') }}</label>
                                <app-select mode="search" text-field="title" value-field="id" :search="searchFilter"
                                            v-model="filterObject">

                                </app-select>
                            </b-col>
                        </b-row>

                    </b-form>
                    <template v-slot:modal-footer>
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <div v-if="data.totalRows > 1 && data.totalRows > data.selected.length">
                                <b-checkbox v-model="selectAllForAssign">
                                    {{ $t("APPLY_TO_ALL_NUMBER", {number: data.totalRows}) }}
                                    <span v-if="data.selected.length === 1"
                                          class="d-block text-muted font-xs">({{ $t("ITEM_SELECTED", {value: data.selected.length}) }})</span>
                                    <span v-if="data.selected.length > 1"
                                          class="d-block text-muted font-xs">({{ $t("ITEMS_SELECTED", {value: data.selected.length}) }})</span>
                                </b-checkbox>

                            </div>
                            <app-button-submit :loading="loading_assign" @click="assignToGroupCheck(data.selected)"
                                               :disabled="filterObject === null"></app-button-submit>
                        </div>
                    </template>
                </b-modal>
                <app-confirmation-dialog :title="$t('GROUP_CHANGE_CONFIRMATION_TITLE')" :show="confirmationNewGroup"
                                         @confirm="saveNewGroup(data.selected)" @cancel="confirmationNewGroup = false">
                    {{ $t('GROUP_CHANGE_CONFIRMATION') }}
                </app-confirmation-dialog>
                <app-confirmation-dialog :title="$t('GROUP_CHANGE_CONFIRMATION_TITLE')" :show="confirmationAssign"
                                         @confirm="saveAssignToGroup(data.selected)"
                                         @cancel="confirmationAssign = false">
                    {{ $t('GROUP_CHANGE_CONFIRMATION') }}
                </app-confirmation-dialog>
            </template>
            <template v-slot:title="data">
                <small class="text-muted d-block">
                    <b-link v-if="checkPermission(R_PROPERTY_BASIC) && data.item.property.id"
                            :to="{name: routeNames.RN_PROPERTY_BASIC, params: {id: data.item.property.id}}"
                            target="_blank">
                        {{ data.item.property.name }}
                    </b-link>
                    <template v-else>
                        {{ data.item.property.name }}
                    </template>
                </small>

                <b-link v-if="checkPermission(R_PROPERTY_UNIT_BASIC)"
                        :to="{name: routeNames.RM_PROPERTY_UNIT_BASIC, params: {id: data.item.property.id, unit_id: data.item.id}}"
                        target="_blank">
                    {{ data.item.title }}
                </b-link>
                <template v-else>
                    {{ data.item.title }}
                </template>
            </template>
            <template v-slot:filter="data">
                <app-button variant="link" @click="edit(data.item)">
                    {{ data.item.filter && data.item.filter.hasOwnProperty('title') ? data.item.filter.title : "" }}
                </app-button>
            </template>
            <template v-slot:active_rates="data">
                <b-tooltip v-if="!ac_response_active_rates"  triggers="hover" :target="data.item.id+'_rates'" :title="$t('ACTIVATION_UNIT_RM')"></b-tooltip>
                <span  :id="data.item.id+'_rates'">
                   <b-form-checkbox   :disabled="!ac_response_active_rates"  switch :unchecked-value="false" :value="true"
                                      @change="activationConfirmation(data.item.id,'rates', $event)"
                                      v-model="active_rates[data.item.id]"></b-form-checkbox>
                </span>
            </template>
            <template v-slot:active_min_stay="data">
                <b-tooltip v-if="!ac_response_active_min_stay"  triggers="hover"  :target="data.item.id+'_min_stay'" :title="$t('ACTIVATION_UNIT_RM')"></b-tooltip>
                <div :id="data.item.id+'_min_stay'">
                        <b-form-checkbox    :disabled="!ac_response_active_min_stay" switch :unchecked-value="false" :value="true"
                                            @change="activationConfirmation(data.item.id,'min_stay', $event)"
                                            v-model="active_min_stay[data.item.id]"
                        ></b-form-checkbox>
                </div>

            </template>
        </app-table-v2>
        <app-aside v-model="edit_aside" :widths="['col-lg-4']">
            <template v-slot:header>
                {{ $t('GROUP') }}
            </template>
            <revenue-manager-filter-form @close="closeAndUpdate" :id="editfilter.id"></revenue-manager-filter-form>
        </app-aside>
        <app-confirmation-dialog
            :title="activationObject.value === true || this.selected > 0 ?$t('ACTIVATION_CONFIRMATION_RATES') :$t('DEACTIVATION_CONFIRMATION_RATES')"
            :show="activationRatesConfirmationDialog"
            @cancel="activationRatesConfirmationDialog = false"
            @confirm="activateRatesRevenueManager"
        >
            <template v-if="activationObject.value === true">
                {{ $t('ACTIVATE_RM_UNIT_MESSAGE') }}
            </template>
            <template v-else>
                {{ $t('DEACTIVATE_RM_UNIT_MESSAGE') }}
            </template>
        </app-confirmation-dialog>

        <app-confirmation-dialog
            :title="activationObject.value === true || this.selected.length > 0 ?$t('ACTIVATION_CONFIRMATION_MIN_STAY') :$t('DEACTIVATION_CONFIRMATION_MIN_STAY')"
            :show="activationMinStayConfirmationDialog"
            @cancel="activationMinStayConfirmationDialog = false"
            @confirm="activateMinStayRevenueManager"
        >
            <template v-if="activationObject.value === true">
                {{ $t('ACTIVATE_RM_UNIT_MESSAGE') }}
            </template>
            <template v-else>
                {{ $t('DEACTIVATE_RM_UNIT_MESSAGE') }}
            </template>
        </app-confirmation-dialog>
    </div>
</template>

<script>
import RevenueManagerFilterForm from "@/components/revenue_manager/similar_listing/RevenueManagerFilterForm";
import AppButton from "@/components/app/AppButton/AppButton";
import AppTableV2 from "@/components/app/AppTableV2";
import AppAside from "@/components/app/form/AppAside";
import {EventBus} from "@/shared/EventBus";
import {
    ACTIVE_REVENUE_MANAGER_MINIMUM_STAY,
    ACTIVE_REVENUE_MANAGER_RATES,
    ACTIVE_REVENUE_MANAGER_RATES_EXTERNAL,
    DEFAULT_PAGINATION_LIMIT,
    DIRECT_BOOKER_COMPANY,
} from "@/shared/constants";
import {
    createRmFilterUnit,
    getRmFilterUnit,
    getRmFilterUnitById,
    rmFilterSetUnits,
    saveRevenueManagerSetup,
    searchRmUnits
} from "@/services/revenue_manager";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppInputControl from "@/components/app/AppInputControl";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import RevenueManagerGroupAssignToFilter
    from "@/components/revenue_manager/similar_listing/unit/RevenueManagerGroupAssignToFilter";
import RevenueManagerGroupFilterForm
    from "@/components/revenue_manager/similar_listing/unit/RevenueManagerGroupFilterForm";
import {FILTER_TITLE} from "@/shared/error_codes";
import routeNames from "@/router/routeNames";
import {R_PROPERTY_BASIC, R_PROPERTY_UNIT_BASIC} from "@/shared/route_permission";
import {toast} from "@/shared/plugins/toastr";
import {fetchAccessControlData} from "@/services/access";
import {AC_REVENUE_MANAGER_PROPERTY} from "@/mixins/AccessControl/AccessControlEnumeration";
import {
    F_REVENUE_MANAGER_UNIT_MIN_STAY_ACTIVATE,
    F_REVENUE_MANAGER_UNIT_RATES_ACTIVATE
} from "@/shared/function_permission";

export default {
    name: "RevenueMangerUnitsTable",
    props: {
        filter: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    mixins: [getErrorMessage],
    components: {
        RevenueManagerFilterForm,
        AppInputControl,
        AppConfirmationDialog,
        AppSelect,
        AppButtonSubmit,
        RevenueManagerGroupAssignToFilter, RevenueManagerGroupFilterForm, AppButton, AppTableV2, AppAside
    },
    data() {
        return {
            validationErrorObject: {
                title: FILTER_TITLE
            },
            routeNames,
            R_PROPERTY_BASIC,
            R_PROPERTY_UNIT_BASIC,
            aside: false,
            new_aside: false,
            filterObject: null,
            fields: [
                {key: 'id', label: this.$t('ID')},
                {key: 'title', label: this.$t('UNIT')},
                {key: 'property.city', label: this.$t('CITY')},
                {key: 'unit_type.label', label: this.$t('UNIT_TYPE')},
                {key: 'filter', label: this.$t('GROUP')},
                {key: 'active_rates', label: this.$t('RATES'), tdClass: 'action_button_w'},
                {key: 'active_min_stay', label: this.$t('MIN_STAY'), thStyle:'width:5rem', tdClass: 'action_button_w'}
            ],
            EVENT_TABLE_SEARCH: 'gl_revenue_manager_similar_units',
            DEFAULT_PAGINATION_LIMIT,
            selected: [],
            edit_aside: false,
            editObject: {
                title: null
            },
            editfilter: {},
            confirmationAssign: false,
            confirmationNewGroup: false,
            selectAllForNewGroup: false,
            selectAllForAssign: false,
            loading_assign: false,
            unit_ids: [],
            title_new_group: null,
            loading_new_group: false,
            edit_unit: [],
            active: {},
            active_rates: {},
            active_min_stay: {},
            activationRatesConfirmationDialog: false,
            activationMinStayConfirmationDialog:false,
            activationObject: {},
            loading_act: false,
            ac_response_active_rates:false,
            ac_response_active_min_stay:false
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    methods: {
        setMultiselectActivation(selected, type) {
            this.selected = selected.length > 0 ? selected.map(unit => {
                return unit.id
            }) : []
            if (type === 'rates') {
                this.activationRatesConfirmationDialog = true
            }
            if (type === 'min_stay') {
                this.activationMinStayConfirmationDialog = true
            }
        },
        async activationConfirmation(unit_id, type, value) {
            await this.$nextTick()

            this.activationObject = {
                unit_id,
                value
            }
            if (type === 'rates') {
                this.active_rates[unit_id] = !value
                this.activationRatesConfirmationDialog = true
            }
            if (type === 'min_stay') {
                this.active_min_stay[unit_id] = !value
                this.activationMinStayConfirmationDialog = true
            }
        },
        closeAndUpdate() {
            this.edit_aside = false;
            EventBus.$emit(this.EVENT_TABLE_SEARCH)
        },
        saveNewGroup(selected) {
            this.loading_new_group = true
            createRmFilterUnit({title: this.title_new_group}).then(response => {
                let request = {}
                if (this.selectAllForNewGroup) {
                    request = {...request, ...{filter: this.filter}}
                } else {
                    let units = selected.map(el => {
                        return el.id
                    })
                    request = {...request, ...{units}}
                }
                rmFilterSetUnits(response.data, request).then(response => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    EventBus.$emit(this.EVENT_TABLE_SEARCH)
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                }).finally(() => {
                    this.loading_new_group = false
                    this.title_new_group = null
                })
            })
        },
        assignToGroupCheck(selected) {
            const assignedFilterList = selected.filter(el => el.filter !== null)
            if (assignedFilterList.length > 0) {
                this.aside = false
                this.confirmationAssign = true
            } else {
                this.saveAssignToGroup(selected)
            }
        },
        addNewGroupCheck(selected) {
            const assignedFilterList = selected.filter(el => el.filter !== null)
            if (assignedFilterList.length > 0) {
                this.new_aside = false
                this.confirmationNewGroup = true
            } else {
                this.saveNewGroup(selected)
            }
        },
        searchFilter(value) {
            const req = {
                title: value,
                page: 1,
                perPage: 100,
                company: this.company.id
            }
            return getRmFilterUnit(req).then(response => ({data: response.data.items}))
        },
        saveAssignToGroup(selected) {
            this.loading_assign = true
            let units = []

            getRmFilterUnitById(this.filterObject.id).then(response => {
                const unit_list = response.data.unit_list
                unit_list.forEach(unit => {
                    units.push(unit.id)
                })
                let request = {}

                if (this.selectAllForAssign) {
                    let filterObject = this.filter
                    filterObject = {...filterObject, ...{company: this.company.id}}
                    request = {...request, ...{filter: filterObject}}
                } else {
                    selected.forEach(el => {
                        units.push(el.id)
                    })
                }
                request = {...request, ...{units}}
                rmFilterSetUnits(this.filterObject.id, request).then(response => {
                    EventBus.$emit(this.EVENT_TABLE_SEARCH)
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                }).finally(() => {
                    this.loading_assign = false
                    this.confirmationAssign = false
                    this.filterObject = null
                })
            })
        },
        assignToFilter(selected) {
            this.selected = selected.length > 0 ? selected.map(unit => {
                return unit.id
            }) : []
            this.aside = true
        },
        groupNew(selected) {
            this.selected = selected.length > 0 ? selected.map(unit => {
                return unit.id
            }) : []
            this.new_aside = true
        },
        toggleBusy($event) {
            this.$emit('busyToggled', $event)
        },
        activateRatesRevenueManager() {
            let request = {
                setup_list: []
            }
            if (this.selected.length > 0) {
                for (const unit_id of this.selected) {
                    if (this.company.id === DIRECT_BOOKER_COMPANY) {
                        request.setup_list.push({
                            setup_parameter: ACTIVE_REVENUE_MANAGER_RATES_EXTERNAL,
                            property: null,
                            unit: unit_id,
                            company: null,
                            value: true
                        })
                    } else {
                        request.setup_list.push({
                            setup_parameter: ACTIVE_REVENUE_MANAGER_RATES,
                            property: null,
                            unit: unit_id,
                            company: null,
                            value: true
                        })
                    }
                }
            } else {
                if (this.company.id === DIRECT_BOOKER_COMPANY) {
                    request.setup_list.push({
                        setup_parameter: ACTIVE_REVENUE_MANAGER_RATES_EXTERNAL,
                        property: null,
                        unit: this.activationObject.unit_id,
                        company: null,
                        value: this.activationObject.value
                    })
                } else {
                    request.setup_list.push({
                        setup_parameter: ACTIVE_REVENUE_MANAGER_RATES,
                        property: null,
                        unit: this.activationObject.unit_id,
                        company: null,
                        value: this.activationObject.value
                    })
                }
            }
            saveRevenueManagerSetup(request).then(response => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
                EventBus.$emit(this.EVENT_TABLE_SEARCH)
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
            }).finally(() => {
                this.selected = []
                this.activationRatesConfirmationDialog = false
            })
        },
        activateMinStayRevenueManager() {
            let request = {
                setup_list: []
            }
            if (this.selected.length > 0) {
                for (const unit_id of this.selected) {
                    request.setup_list.push({
                        setup_parameter: ACTIVE_REVENUE_MANAGER_MINIMUM_STAY,
                        property: null,
                        unit: unit_id,
                        company: null,
                        value: true
                    })
                }
            } else {
                request.setup_list.push({
                    setup_parameter: ACTIVE_REVENUE_MANAGER_MINIMUM_STAY,
                    property: null,
                    unit: this.activationObject.unit_id,
                    company: null,
                    value: this.activationObject.value
                })
            }
            saveRevenueManagerSetup(request).then(response => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
                EventBus.$emit(this.EVENT_TABLE_SEARCH)
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
            }).finally(() => {
                this.activationMinStayConfirmationDialog = false
                this.selected = []
            })
        },
        getProvider(filter) {
            let req = {
                ...this.filter,
                ...{
                    page: filter.currentPage,
                    perPage: filter.perPage,
                    order_by: filter.sortBy,
                    order_by_direction: filter.sortDirection,
                    company: this.company.id
                }
            }
            let provider = searchRmUnits(req)
            provider.then(response => {
                if (response.data.items.length > 0) {
                    for (const item of response.data.items) {
                        this.$set(this.active_rates, item.id, item.active_rates)
                        this.$set(this.active_min_stay, item.id, item.active_min_stay)
                    }
                }
            })
            return provider
        },
        edit(object) {
            this.edit_unit = []
            this.editObject = object
            this.edit_aside = true
            this.editfilter = object.filter
            this.edit_unit.push(object.id)
        },
    },
    watch: {
        filter: {
            handler(value) {
                EventBus.$emit(this.EVENT_TABLE_SEARCH)
                this.selected  = []
            },
        },
    },
    created() {
        const request = {
            data: [
                {
                    uid: F_REVENUE_MANAGER_UNIT_RATES_ACTIVATE,
                    function: F_REVENUE_MANAGER_UNIT_RATES_ACTIVATE,
                    key: AC_REVENUE_MANAGER_PROPERTY
                },
                {
                    uid: F_REVENUE_MANAGER_UNIT_MIN_STAY_ACTIVATE,
                    function: F_REVENUE_MANAGER_UNIT_MIN_STAY_ACTIVATE,
                    key: AC_REVENUE_MANAGER_PROPERTY
                },
            ],
            key: AC_REVENUE_MANAGER_PROPERTY,
            context: {company: this.company.id, property: this.filter.property}
        }
        fetchAccessControlData(AC_REVENUE_MANAGER_PROPERTY, request).then(response => {
            this.ac_response_active_rates = response.data[F_REVENUE_MANAGER_UNIT_RATES_ACTIVATE].visible
            this.ac_response_active_min_stay = response.data[F_REVENUE_MANAGER_UNIT_MIN_STAY_ACTIVATE].visible
        })
    }
}
</script>

<style scoped>

</style>
