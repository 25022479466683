<template>
    <div>
        <div class="table-wrapper">
            <table class="table">
                <thead>
                <tr>
                    <th class="table-header-period-range" scope="col">{{ this.$t('NAME') }}</th>
                    <th class="table-header-actions text-right" scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tag-item v-for="tag in tagList" :key="tag.id" v-if="tag.deleted === 0" :item-list="true" :tag="tag"
                          @deleteItem="deleteTag"></tag-item>
                <tag-item :new-item="true" @addItem="addTag"></tag-item>
                </tbody>
            </table>
        </div>
        <b-row>
            <b-col class="mt-4">
                <app-button-submit id="scrollToMe" @click="save" :loading="saving" :disabled="!tagList.length > 0" ref="saveButton"></app-button-submit>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import TagItem from "@/components/tag/sidebar/TagItem";
    import {TAG_DESTINATION_TYPE_REGION, TAG_TYPE_DESTINATION, TAG_DESTINATION_TYPE_COUNTRY} from "@/shared/constants";
    import {getTagsPaginated, saveDestinationTags, saveCustomTags} from "@/services/tag";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";

    export default {
        name: "AddTag",
        components: {TagItem, AppConfirmationDialog, AppButtonSubmit},
        mixins: [getErrorMessage],
        props: {
            parent_tag: {
                type: Number,
                default: null
            },
            destination_type: {
                type: String,
                default: null
            },
            tag_type: {
                type: Number,
                default: null
            },
            label:{
                type:String,
                default: null
            }
        },
        data() {
            return {
                saving: false,
                tagList: []
            }
        },
        methods: {
            save() {
                this.saving = true
                saveDestinationTags({list: this.tagList}).then(() => {
                    notifySuccess()
                    this.saving = false
                    this.$emit('saved')
                }).catch(error => {
                    this.saving = false
                    this.showErrorMessages(error)
                })

            },
            deleteTag(tag) {
                let index = this.tagList.findIndex(item => item.label === tag.label);
                this.tagList[index].deleted = 1;

                if (tag.new && tag.new === 1) {
                    this.tagList = this.tagList.filter(item =>
                        item.label !== tag.label
                    )
                }
            },
            addTag(tag) {
                this.tagList.push({
                    label: tag.label,
                    parent_id: this.parent_tag,
                    tag_type_id: this.tag_type,
                    company_id: this.company.id,
                    deleted: 0,
                    edited: 1,
                    new: 1
                })

                this.$nextTick(document.getElementById("scrollToMe").scrollIntoView());

            },
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
        }
    }
</script>

<style scoped>

</style>
