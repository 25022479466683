<template>
    <b-form @submit.prevent="save" v-if="formData.whitelabelActive">
        <b-row>
            <b-col>
                <form-header>Logo</form-header>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12" md="6" lg="4" class="mb-4">
                <app-image-input :label="$t('LOGO')"
                                 :tooltip="$t('WHITELABEL_RECOMMENDED_LOGO_SIZE')"
                                 :image="formData.logo_id"
                                 :preview="formData.logo_url"
                                 @input="formData.logo_id = $event"
                ></app-image-input>
            </b-col>
            <b-col sm="12" md="6" lg="4" class="mb-4">
                <app-image-input :label="$t('LOGO_ALT')"
                                 :tooltip="$t('WHITELABEL_RECOMMENDED_ALT_LOGO_SIZE')"
                                 :image="formData.logo_alt_id"
                                 :preview="formData.logo_alt_url"
                                 @input="formData.logo_alt_id = $event"
                ></app-image-input>
            </b-col>
        </b-row>

        <div v-if="whitelabel_level===WHITELABEL_ADVANCE">
            <b-row>
                <b-col>
                    <form-header>Mail server</form-header>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="12" md="6" lg="4" class="mb-4">
                    <app-input-control :error-object="validation.username">
                        <template v-slot:input="data">
                            <label>{{ $t('USERNAME') }}</label>
                            <b-input v-model="formData.mail_server.username"
                                     autocomplete="new-password" data-lpignore="true"
                                     :state="data.state"></b-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col sm="12" md="6" lg="4" class="mb-4">
                    <app-input-control :error-object="validation.password">
                        <template v-slot:input="data">
                            <label>{{ $t('PASSWORD') }}</label>
                            <b-input type="password" v-model="formData.mail_server.password"
                                     autocomplete="new-password" data-lpignore="true"
                                     :state="data.state"></b-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4" xl="2" class="mb-4">
                    <app-input-control :error-object="validation.driver">
                        <template v-slot:input="data">
                            <label>{{ $t('SERVER_DRIVER') }}</label>
                            <!--                            <b-input v-model="formData.mail_server.driver" :state="data.state"></b-input>-->
                            <app-select
                                :options="driverOptions"
                                v-model="formData.mail_server.driver"
                                value-field="id" text-field="label"
                                :state="data.state">
                            </app-select>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="4" xl="2" class="mb-4">
                    <app-input-control :error-object="validation.host">
                        <template v-slot:input="data">
                            <label>{{ $t('SERVER_HOST') }}</label>
                            <b-input v-model="formData.mail_server.host" :state="data.state"></b-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="4" xl="2" class="mb-4">
                    <app-input-control :error-object="validation.port">
                        <template v-slot:input="data">
                            <label>{{ $t('PORT') }}</label>
                            <b-input v-model="formData.mail_server.port" :state="data.state"></b-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="4" xl="2" class="mb-4">
                    <app-input-control :error-object="validation.encryption">
                        <template v-slot:input="data">
                            <label>{{ $t('ENCRYPTION') }}</label>
                            <!--                            <b-input v-model="formData.mail_server.encryption" :state="data.state"></b-input>-->
                            <app-select
                                :options="encryptionOptions"
                                v-model="formData.mail_server.encryption"
                                value-field="id" text-field="label"
                                :state="data.state">
                            </app-select>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
        </div>
        <b-row>
            <b-col class="mb-4">
                <app-button-submit :inline="true" :disabled="saving" :loading="saving">
                    {{ $t("SAVE") }}
                </app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import AppImageInput from "@/components/app/AppImageInput";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {notifySuccess} from "@/shared/plugins/toastr";
import AppAccessControl from "@/components/app/AppAccessControl";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {
    AC_COMPANY,
} from "@/mixins/AccessControl/AccessControlEnumeration";
import {C_COMPANY_WHITELABEL_SETUP} from "@/shared/function_permission";
import {
    TYPE_COMPANY_LOGO,
    TYPE_COMPANY_LOGO_ALTERNATIVE, TYPE_WEBSITE_HERO_IMAGE, WHITELABEL_ADVANCE
} from "@/shared/constants";
import {createDocument, deleteDocument} from "@/services/document";
import {cloneDeep, isObject} from "lodash";
import {uploadOrFetchWebsiteDocument} from "@/services/direct_booking/website";
import AppInputControl from "@/components/app/AppInputControl";
import FormHeader from "@/components/app/form/FormHeader";
import {getMailServerSetup, setCompanyMailServer} from "@/services/company";
import {
    COM_MAIL_SERVER_DRIVER_VALIDATION_FAILED, COM_MAIL_SERVER_ENCRYPTION_VALIDATION_FAILED,
    COM_MAIL_SERVER_HOST_VALIDATION_FAILED,
    COM_MAIL_SERVER_PASSWORD_VALIDATION_FAILED,
    COM_MAIL_SERVER_PORT_VALIDATION_FAILED,
    COM_MAIL_SERVER_USERNAME_VALIDATION_FAILED,
} from "@/shared/error_codes";

export default {
    name: "CompanyWhitelabelForm",
    components: {FormHeader, AppInputControl, AppAccessControl, AppButtonSubmit, AppSelect, AppImageInput},
    mixins: [getErrorMessage, AccessControlComponent],
    props: {
        company: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            access_control_fetch_key: AC_COMPANY,
            AC_COMPANY,
            C_COMPANY_WHITELABEL_SETUP,
            WHITELABEL_ADVANCE,
            formData: {
                logo_alt_id: null,
                logo_alt_url: null,
                logo_id: null,
                logo_url: null,
                whitelabelActive: 0,
                mail_server: {
                    username: null,
                    password: null,
                    driver: null,
                    host: null,
                    port: null,
                    encryption: null
                }
            },
            driverOptions: null,
            encryptionOptions: null,
            whitelabel_level: 1,
            saving: false,
            access_control_components: 1,
            initialData: null,
            validation: {
                username: COM_MAIL_SERVER_USERNAME_VALIDATION_FAILED,
                password: COM_MAIL_SERVER_PASSWORD_VALIDATION_FAILED,
                driver: COM_MAIL_SERVER_DRIVER_VALIDATION_FAILED,
                host: COM_MAIL_SERVER_HOST_VALIDATION_FAILED,
                port: COM_MAIL_SERVER_PORT_VALIDATION_FAILED,
                encryption: COM_MAIL_SERVER_ENCRYPTION_VALIDATION_FAILED,
            },
        }
    },
    methods: {
        save() {
            this.loading = true;
            this.saving = true

            let checkValue = Object.entries(this.formData.mail_server).every(([key, value]) => {
                return value === null || value === undefined || value === ""
            })

            this.handleImageSaving(this.formData.logo_id, this.formData.logo_alt_id).then(() => {
                if (this.whitelabel_level === WHITELABEL_ADVANCE) {
                    return setCompanyMailServer(this.company.id, {mail_server: this.formData.mail_server, deleted:checkValue})
                }
            }).then(response => {
                notifySuccess()
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => this.saving = false)

        },
        handleImageSaving(logo, logo_alt) {
            const promiseList = []

            if (logo && this.initialData.logo_id !== logo) {
                const data = new FormData()
                data.append('document', logo)
                promiseList.push(uploadOrFetchWebsiteDocument(this.company.id, TYPE_COMPANY_LOGO, data))
            } else if (!logo && this.initialData.logo_id) {
                promiseList.push(deleteDocument(this.company.id, TYPE_COMPANY_LOGO, this.initialData.logo_id))
            }

            if (logo_alt && this.initialData.logo_alt_id !== logo_alt) {
                const data = new FormData()
                data.append('document', logo_alt)
                promiseList.push(uploadOrFetchWebsiteDocument(this.company.id, TYPE_COMPANY_LOGO_ALTERNATIVE, data))
            } else if (!logo_alt && this.initialData.logo_alt_id) {
                promiseList.push(deleteDocument(this.company.id, TYPE_COMPANY_LOGO_ALTERNATIVE, this.initialData.logo_alt_id))
            }

            return Promise.all(promiseList.map(p => p.catch(e => this.showErrorMessages(e))))
        },
        getMailServerSetup() {
            getMailServerSetup(this.company_id).then(response => {
                this.driverOptions = response.data.driver
                this.encryptionOptions = response.data.encryption
                if (response.data.mail_server) {
                    this.formData.mail_server = response.data.mail_server
                }
            })
        }
    },
    computed: {
        company_id() {
            return this.$store.getters['user/getCompany']
        }
    },
    watch: {
        company: {
            handler(data) {
                if (data) {
                    this.formData.whitelabelActive = data.whitelabel_level ? 1 : 0
                    this.whitelabel_level = parseInt(data.whitelabel_level)
                    this.formData.logo_url = data.logo_url
                    this.formData.logo_id = data.logo_id
                    this.formData.logo_alt_id = data.logo_alt_id
                    this.formData.logo_alt_url = data.logo_alt_url

                    this.initialData = cloneDeep(this.formData)
                }
            },
            deep: true,
            immediate: true
        },
    },
    created() {
        this.getMailServerSetup()
    }
}
</script>

<style scoped>

</style>
