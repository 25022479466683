<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="load_data">
            <b-spinner variant="primary"></b-spinner>
        </div>
        <div v-else>
            <template v-if="filter_id !== null">
                <b-row class="mb-4">
                    <b-col>
                        <b-tooltip v-if="read_only" triggers="hover"
                                   :target="'tooltip-target-msg_revenue_new_group'"
                                   :title="read_only_message"
                        ></b-tooltip>
                        <app-button id="tooltip-target-msg_revenue_new_group"
                                    class="pull-right ml-3"
                                    @click="new_aside = true"
                                    :disabled="read_only"
                                    button_type="new"
                        >{{ $t('NEW_GROUP') }}
                        </app-button>

                        <b-tooltip v-if="read_only" triggers="hover"
                                   :target="'tooltip-target-msg_revenue_assign_to_group'"
                                   :title="read_only_message"
                        ></b-tooltip>
                        <app-button id="tooltip-target-msg_revenue_assign_to_group"
                                    class="pull-right"
                                    @click="aside = true"
                                    :disabled="read_only"
                        >{{ $t('ASSIGN_TO_GROUP') }}
                        </app-button>
                    </b-col>
                </b-row>

                <b-form @submit.prevent="save">
                    <b-row>
                        <b-col lg="5" class="mb-4">
                            <app-input-control :error-object="validationErrorObject.title">
                                <template v-slot:input="data">
                                    <label>{{ $t('NAME') }}</label>
                                    <b-input :state="data.state" v-model="title" :disabled="read_only"></b-input>
                                </template>
                            </app-input-control>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-tooltip v-if="read_only" triggers="hover"
                                   :target="'tooltip-target-msg_revenue_save_filter'"
                                   :title="read_only_message"
                        ></b-tooltip>
                        <app-button-submit id="tooltip-target-msg_revenue_save_filter"
                                           :disabled="read_only"
                                           :loading="loading"
                                           class="ml-3"
                        ></app-button-submit>

                        <b-tooltip v-if="read_only" triggers="hover"
                                   :target="'tooltip-target-msg_revenue_delete_filter'"
                                   :title="read_only_message"
                        ></b-tooltip>
                        <app-button-delete id="tooltip-target-msg_revenue_delete_filter"
                                           v-if="filter_id !== null"
                                           :disabled="read_only"
                                           class="ml-3"
                                           @click="deleteDialogState = true"
                        ></app-button-delete>
                    </b-row>

                    <app-confirmation-dialog
                        :show="deleteDialogState"
                        @confirm="deleteFilter"
                        @cancel="deleteDialogState=false"
                        :delete_title="true"
                    ></app-confirmation-dialog>
                </b-form>

                <b-form class="mt-5" @submit.prevent="saveUnits">
                    <b-row>
                        <b-col>
                            <table class="table b-table">
                                <thead>
                                <th width="70%">{{ $t('UNIT') }}</th>
                                <th></th>
                                </thead>
                                <tbody>
                                <tr :key="unit.id+'_'+index" v-for="(unit, index) in unit_list">
                                    <td>
                                        <small class="text-muted d-block">
                                            <b-link v-if="checkPermission(R_PROPERTY_BASIC) && unit.property.id"
                                                    :to="{name: routeNames.RN_PROPERTY_BASIC, params: {id: unit.property.id}}"
                                                    target="_blank">
                                                {{ unit.property.name }}
                                            </b-link>
                                            <template v-else>
                                                {{ unit.property.name }}
                                            </template>
                                        </small>
                                        <b-link v-if="checkPermission(R_PROPERTY_UNIT_BASIC)"
                                                :to="{name: routeNames.RM_PROPERTY_UNIT_BASIC, params: {id: unit.property.id, unit_id: unit.id}}"
                                                target="_blank">
                                            {{ unit.name }}
                                        </b-link>
                                        <template v-else>
                                            {{ unit.name }}
                                        </template>
                                    </td>
                                    <td>
                                        <b-tooltip v-if="read_only" triggers="hover"
                                                   :target="'tooltip-target-msg_revenue_filter_remove_unit_'+unit.id"
                                                   :title="read_only_message"
                                        ></b-tooltip>
                                        <app-button :id="'tooltip-target-msg_revenue_filter_remove_unit_'+unit.id"
                                                    @click="removeRow(index)"
                                                    variant="link"
                                                    button_type="delete"
                                                    class="pull-right"
                                                    :show_text="false"
                                                    :disabled="read_only"
                                        ></app-button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <app-select v-model="new_unit"
                                                    :search="searchUnitList"
                                                    :disabled="read_only"
                                                    mode="search"
                                                    text-field="name"
                                                    value-field="id"
                                        ></app-select>
                                    </td>
                                    <td>
                                        <b-tooltip v-if="read_only" triggers="hover"
                                                   :target="'tooltip-target-msg_revenue_filter_add_unit'"
                                                   :title="read_only_message"
                                        ></b-tooltip>
                                        <app-button :id="'tooltip-target-msg_revenue_filter_add_unit'"
                                                    :disabled="read_only || new_unit === null"
                                                    class="pull-right"
                                                    button_type="new"
                                                    @click="addNewRow"
                                        >{{ $t('ADD_ITEM') }}
                                        </app-button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-tooltip v-if="read_only" triggers="hover"
                                       :target="'tooltip-target-msg_revenue_save_units'"
                                       :title="read_only_message"
                            ></b-tooltip>
                            <app-button-submit id="tooltip-target-msg_revenue_save_units"
                                               :disabled="read_only || unit_list.length === 0"
                                               :loading="loader_unit"
                            ></app-button-submit>
                        </b-col>
                    </b-row>
                </b-form>
            </template>
            <app-no-data v-else>
                <b-tooltip v-if="read_only" triggers="hover"
                           :target="'tooltip-target-msg_revenue_new_group'"
                           :title="read_only_message"
                ></b-tooltip>
                <app-button id="tooltip-target-msg_revenue_new_group"
                            class="pull-right ml-3"
                            @click="new_aside = true"
                            :disabled="read_only"
                            button_type="new"
                >{{ $t('NEW_GROUP') }}
                </app-button>

                <b-tooltip v-if="read_only" triggers="hover"
                           :target="'tooltip-target-msg_revenue_assign_to_group'"
                           :title="read_only_message"
                ></b-tooltip>
                <app-button id="tooltip-target-msg_revenue_assign_to_group"
                            class="pull-right"
                            @click="aside = true"
                            :disabled="read_only"
                >{{ $t('ASSIGN_TO_GROUP') }}
                </app-button>
            </app-no-data>

            <b-modal :title="$t('NEW_GROUP')" v-model="new_aside">
                <b-form>
                    <b-row class="mb-2">
                        <b-col lg="12" class="mb-4">
                            <app-input-control :error-object="validationErrorObject.title">
                                <template v-slot:input="data">
                                    <label>{{ $t('NAME') }}</label>
                                    <b-input :state="data.state" v-model="title_new_group" :disabled="read_only"></b-input>
                                </template>
                            </app-input-control>
                        </b-col>
                    </b-row>
                </b-form>
                <template v-slot:modal-footer>
                    <div class="w-100 d-flex justify-content-between align-items-center">
                        <app-button-submit :loading="loading_new_group"
                                           :disabled="read_only || title_new_group === null"
                                           @click="addNewGroupCheck"
                        ></app-button-submit>
                    </div>
                </template>
            </b-modal>

            <app-confirmation-dialog :title="$t('GROUP_CHANGE_CONFIRMATION_TITLE')"
                                     :show="confirmationNewGroup"
                                     @confirm="saveNewGroup"
                                     @cancel="confirmationNewGroup = false">
                {{ $t('GROUP_CHANGE_CONFIRMATION') }}
            </app-confirmation-dialog>
            <app-confirmation-dialog :title="$t('GROUP_CHANGE_CONFIRMATION_TITLE')"
                                     :show="conftirmationAssign"
                                     @confirm="saveAssignToGroup"
                                     @cancel="conftirmationAssign = false">
                {{ $t('GROUP_CHANGE_CONFIRMATION') }}
            </app-confirmation-dialog>

            <b-modal :title="$t('ASSIGN_TO_GROUP')" v-model="aside">
                <b-form>
                    <b-row class="mb-2">
                        <b-col lg="12" class="mb-4">
                            <label>{{ $t('GROUP') }}</label>
                            <app-select v-model="filterObject"
                                        :disabled="read_only"
                                        :search="searchFilter"
                                        mode="search"
                                        text-field="title"
                                        value-field="id"
                            ></app-select>
                        </b-col>
                    </b-row>
                </b-form>
                <template v-slot:modal-footer>
                    <div class="w-100 d-flex justify-content-between align-items-center">
                        <app-button-submit :loading="loading_assign"
                                           :disabled="read_only || filterObject === null"
                                           @click="assignToGroupCheck"
                        ></app-button-submit>
                    </div>
                </template>
            </b-modal>
        </div>
    </div>
</template>

<script>
import AppAside from "@/components/app/form/AppAside";
import {FILTER_TITLE} from "@/shared/error_codes";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppInputControl from "@/components/app/AppInputControl";
import {
    createRmFilterUnit,
    deleteRmFilterUnit, getRmFilterUnit, getRmFilterUnitById,
    rmFilterSetUnits,
    searchRmUnits,
    updateRmFilterUnit
} from "@/services/revenue_manager";
import {toast} from "@/shared/plugins/toastr";
import {R_PROPERTY_BASIC, R_PROPERTY_UNIT_BASIC} from "@/shared/route_permission";
import routeNames from "@/router/routeNames";
import AppButton from "@/components/app/AppButton/AppButton";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import FormHeader from "@/components/app/form/FormHeader";
import AppNoData from "@/components/app/AppNoData";
import {EventBus} from "@/shared/EventBus";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";

export default {
    name: "RevenueManagerCompetitorForm",
    components: {
        AppNoData,
        FormHeader,
        AppSelect, AppButton, AppInputControl, AppButtonSubmit, AppButtonDelete, AppConfirmationDialog, AppAside
    },
    props: {
        id: {
            type: Number
        },
        unit_id: {
            type: Number
        },
        read_only: {
            type: Boolean,
            default: false
        },
        read_only_message: {
            type: String
        },
    },
    mixins: [getErrorMessage],
    data() {
        return {
            filter_id: null,
            title: null,

            deleteDialogState: false,
            loading: false,
            load_data: false,
            unit_list: [],
            new_unit: null,
            loader_unit: false,
            validationErrorObject: {
                title: FILTER_TITLE
            },
            R_PROPERTY_UNIT_BASIC,
            R_PROPERTY_BASIC,
            routeNames,
            new_aside: false,
            aside: false,
            title_new_group: null,
            loading_new_group: false,
            confirmationNewGroup: false,
            fetchData: false,
            filterObject: null,
            loading_assign: false,
            conftirmationAssign: false
        }
    },
    methods: {
        saveAssignToGroup() {
            this.loading_assign = true
            let units = [this.unit_id]

            getRmFilterUnitById(this.filterObject.id).then(response => {
                const unit_list = response.data.unit_list
                unit_list.forEach(unit => {
                    units.push(unit.id)
                })

                const request = {
                    units
                }

                rmFilterSetUnits(this.filterObject.id, request).then(response => {
                    EventBus.$emit('gl_revenue_setup_refresh_property')
                    this.fetchById(this.filterObject.id)
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })

                }).finally(() => {
                    this.loading_assign = false
                    this.conftirmationAssign = false
                    this.filterObject = null
                })
            })
        },
        assignToGroupCheck(selected) {
            if (this.filter_id !== null) {
                this.aside = false
                this.conftirmationAssign = true
            } else {
                this.saveAssignToGroup(selected)
            }
        },
        searchFilter(value) {
            const req = {
                title: value,
                page: 1,
                perPage: 100,
                company: this.company.id
            }
            return getRmFilterUnit(req).then(response => ({data: response.data.items}))
        },
        addNewGroupCheck() {
            if (this.filter_id !== null) {
                this.new_aside = false
                this.confirmationNewGroup = true
            } else {
                this.saveNewGroup()
            }
        },
        saveNewGroup() {
            this.loading_new_group = true
            createRmFilterUnit({title: this.title_new_group}).then(response => {
                let request = {
                    units: [this.unit_id]
                }
                const filter_id = response.data

                rmFilterSetUnits(filter_id, request).then(response => {
                    EventBus.$emit('gl_revenue_setup_refresh_property')
                    this.fetchById(filter_id)
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                }).finally(() => {
                    this.loading_new_group = false
                    this.title_new_group = null
                    this.confirmationNewGroup = false
                })
            }).finally(() => {
                this.new_aside = false
            })
        },
        addNewRow() {
            if (this.new_unit && this.new_unit.hasOwnProperty('id')) {
                this.unit_list.push({
                    id: this.new_unit.id,
                    name: this.new_unit.title,
                    property: {id: this.new_unit.property.id, name: this.new_unit.property.name}
                })
            }
            this.new_unit = null
        },
        removeRow(index) {
            this.unit_list.splice(index, 1)
        },
        searchUnitList(value) {
            let req = {
                name: value,
                page: 1,
                perPage: 50,
                company: this.company.id
            }

            return searchRmUnits(req).then(response => {
                const unitWithProperty = response.data.items.map(el => {
                    return {
                        id: el.id,
                        title: el.title,
                        property: el.property,
                        name: el.title + ' (' + el.property.name + ')'
                    }
                })
                return {data: unitWithProperty}
            })
        },
        saveUnits() {
            this.loader_unit = true
            const units = this.unit_list.map(el => {
                return el.id
            })
            rmFilterSetUnits(this.filter_id, {units}).then(response => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
                EventBus.$emit('gl_revenue_setup_refresh_property')
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
            }).finally(() => {
                this.loader_unit = false
            })
        },
        save() {
            this.loading = true
            if (this.id === null) {
                createRmFilterUnit({title: this.title, user: this.user.id}).then(response => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.filter_id = response.data
                    EventBus.$emit('gl_revenue_setup_refresh_property')
                }, error => {

                }).finally(() => {
                    this.loading = false
                })
            } else {
                updateRmFilterUnit(this.id, {title: this.title}).then(response => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.$emit('close')
                }, error => {

                }).finally(() => {
                    this.loading = false
                })
            }
        },
        deleteFilter() {
            deleteRmFilterUnit(this.id).then(response => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
                this.deleteDialogState = false
                this.filter_id = null
                EventBus.$emit('gl_revenue_setup_refresh_property')
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
                this.deleteDialogState = false
            }).finally(() => {
                this.laoding = false
            })
        },
        fetchById(id) {
            this.load_data = true
            getRmFilterUnitById(id).then(response => {
                this.unit_list = response.data.unit_list
                this.title = response.data.title
                this.filter_id = response.data.id
            }).finally(() => {
                this.fetchData = true
                this.load_data = false
            })
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        user() {
            return this.$store.getters['user/getUser']
        },
    },
    watch: {
        id: {
            handler(value) {
                if (value) {
                    this.fetchById(value)
                } else {
                    this.fetchData = true
                }
            }, immediate: true
        }
    }
}
</script>

<style scoped>

</style>
