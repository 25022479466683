<template>
    <form v-if="hasFilter" @submit.prevent="setPropertyList()" class="filter" autocomplete="off">
        <b-row>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t('NAME') }}</label>
                <b-input v-model="filter.name" type="text"></b-input>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t('TYPE') }}</label>
                <app-select v-model="filter.property_type" :options="property_type_options"
                            text-field="label"></app-select>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("CATEGORIZATION") }}</label>
                <app-select mode="multiselect" :options="categoryListOptions" v-model="filter.categorization"
                            :search-clear="true"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("COUNTRY") }}</label>
                <app-select mode="search" :search="searchCountry" v-model="filter.country"
                            :search-clear="true"></app-select>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("CITY") }}</label>
                <app-select mode="search" :search="searchCity" v-model="filter.city" :search-clear="true"></app-select>
            </b-col>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t('ADDRESS') }}</label>
                <b-input v-model="filter.address" type="text"></b-input>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <app-select :label="$t('ACCOUNT_MANAGER')" mode="search" :search-on-create="true"
                            :search="searchAccManager" v-model="filter.account_manager"
                            :search-clear="false"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("OWNER") }}</label>
                <app-select mode="search" :search="searchOwner" v-model="filter.owner" text-field="full_name"
                            :search-clear="true"></app-select>
            </b-col>


            <app-access-control tag="b-col" xl="2" lg="4" md="6" class="mb-3"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{function:C_PROPERTY_LIST_FILTER_STATUS_V,company: company_id, key:AC_PROPERTY_FILTER}">
                <app-select :label="$t('STATUS')" mode="multiselect" :options="status_list" text-field="label"
                            v-model="filter.status_id"></app-select>
            </app-access-control>
            <app-access-control tag="b-col" xl="2" lg="4" md="6" class="mb-3"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{function:C_PROPERTY_LIST_FILTER_STATUS_DATE,company: company_id, key:AC_PROPERTY_FILTER}">
                <label>{{ $t("_DATE") }}</label>
                <app-select :options="dateOptions" v-model="dateOptionValue"></app-select>
            </app-access-control>
            <app-access-control tag="b-col" xl="2" lg="4" md="6" class="mb-3"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{function:C_PROPERTY_LIST_FILTER_STATUS_DATE,company: company_id, key:AC_PROPERTY_FILTER}">
                <label>{{ $t("FROM") }}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="fromDate"
                                 :max-date="toDate"></app-date-picker>
            </app-access-control>
            <app-access-control tag="b-col" xl="2" lg="4" md="6" class="mb-3"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{function:C_PROPERTY_LIST_FILTER_STATUS_DATE,company: company_id, key:AC_PROPERTY_FILTER}">
                <label>{{ $t("TO") }}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="toDate" :min-date="fromDate"
                                 :from-date="fromDate"></app-date-picker>
            </app-access-control>

            <!--            <b-col v-if="isSetupReservationProcessDistributionPropertyRoute || isSetupFeesPlatformFeeDistributionPropertyRoute" xl="2" lg="4" md="6" class="mb-3">-->
            <!--                <app-select :label="$t('FILTER')" mode="select" :options="arProcessStatusList" text-field="label"-->
            <!--                            v-model="filter.ar_process_status"></app-select>-->
            <!--            </b-col>-->

            <b-col>
                <app-submit class="filter-submit-wrapper" :inline="true" :loading="loadingSubmit" :disabled="loading"
                            variant="primary"
                            button_type="search">{{ $t("SEARCH") }}
                </app-submit>

                <app-button-reset :loading="loadingReset" @click="resetForm" :inline="true" class="pl-2 ml-2"
                                  :disabled="loading"></app-button-reset>

            </b-col>
        </b-row>
    </form>

</template>

<script>
import {fetchStatusList} from '@/services/status/index'
import {OBJECT_TYPE_PROPERTY, STATUS_PROPERTY_COOPERATION_COMPLETED} from '@/shared/constants'
import AccountManagerSelectField from '@/components/account_manager/AccountManagerSelectField'
import CitySelect from '@/components/location/CitySelect'
import CountrySelect from '@/components/location/CountrySelect'
import Multiselect from 'vue-multiselect'
import AppSubmit from '@/components/app/AppButton/AppButtonSubmit'
import {getPropertyTypeList} from "@/services/property";
import {getCityList, getCountries} from "@/services/location";
import {getList} from "@/services/acc_manager";
import {getOwnerList} from "@/services/owner/index";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {
    C_PROPERTY_LIST_FILTER_STATUS_V,
    C_PROPERTY_LIST_FILTER_ACM_V,
    C_PROPERTY_LIST_FILTER_STATUS_DATE
} from "@/shared/component_permission";
import {AC_PROPERTY_FILTER} from "@/mixins/AccessControl/AccessControlEnumeration";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import AppAccessControl from "@/components/app/AppAccessControl";
import {EventBus} from "@/shared/EventBus";
import AppButton from "@/components/app/AppButton/AppButton";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import routeNames from '@/router/routeNames';
import AppDatePicker from "@/components/app/datetime/AppDatePicker";

export default {
    name: "PropertyFilter",

    components: {
        AppButtonReset,
        AppButton,
        AppAccessControl,
        AppSelect,
        AccountManagerSelectField, Multiselect, CitySelect, CountrySelect, AppSubmit, AppDatePicker
    },
    props: {
        hasFilter: {
            type: Boolean,
            default: true
        },
        distribution_id: {
            type: Number,
            default: null
        }
    },
    mixins: [AccessControlComponent],
    data() {
        return {
            routeNames,
            filterHidden: false,
            loading: false,
            loadingReset: false,
            statusList: [],
            status_list: [],
            status: [],
            categoryListOptions: [
                {id: 1, name: '1 *'},
                {id: 2, name: '2 *'},
                {id: 3, name: '3 *'},
                {id: 4, name: '4 *'},
                {id: 5, name: '5 *'},
            ],
            property_type_options: [],
            C_PROPERTY_LIST_FILTER_STATUS_V,
            C_PROPERTY_LIST_FILTER_ACM_V,
            C_PROPERTY_LIST_FILTER_STATUS_DATE,
            AC_PROPERTY_FILTER,
            access_control_fetch_key: AC_PROPERTY_FILTER,
            access_control_components: 4,
            dateOptions: [
                {id: 'last_status_change', name: this.$t('LAST_STATUS_CHANGE')},
            ],
            dateOptionValue: null,
            fromDate: null,
            toDate: null,
            filter: {
                page: 1,
                name: '',
                status_id: [],
                account_manager: {
                    id: null,
                    name: null
                },
                owner: {
                    id: null,
                    name: null
                },
                categorization: [],
                property_type: null,
                address: null,
                city: {
                    id: null,
                    name: null
                },
                country: {
                    id: null,
                    name: null
                },
                distribution: null
            },
            arProcessStatusList: [
                {id: 0, label: this.$t('INACTIVE')},
                {id: 1, label: this.$t('ACTIVE')}
            ],
            reset: 0,
            loadingSubmit: false
        }
    },
    computed: {
        company_id() {
            return this.$store.getters['user/getCompany']
        },
        dateObject() {
            return this.dateOptionValue ? {
                [`${this.dateOptionValue}_from`]: this.fromDate,
                [`${this.dateOptionValue}_to`]: this.toDate,
            } : {}
        },
        excludedPropertyStatusList() {
            let excludedList = []
            this.status_list.forEach(status => {
                if (status.id !== STATUS_PROPERTY_COOPERATION_COMPLETED) {
                    excludedList.push(status.id)
                }
            })
            return excludedList
        },
        isSetupReservationProcessDistributionPropertyRoute() {
            return this.$route.name === routeNames.RN_SYSTEM_DISTRIBUTION_PROPERTY
        },
        isSetupFeesPlatformFeeDistributionPropertyRoute() {
            return this.$route.name === routeNames.RN_SETUP_FEES_PLATFORM_FEE_DISTRIBUTION_PROPERTY
        },
    },
    methods: {
        setPropertyList() {
            this.reset = 0
            this.filter.distribution = this.distribution_id
            EventBus.$emit("PropertyFilterSearch", {...this.filter, ...this.dateObject})
        },
        searchPropertyType(value) {
            return getPropertyTypeList()
        },
        searchCountry(value) {
            return getCountries({
                name: value
            })
        },
        searchCity(value) {
            return getCityList({
                name: value,
                country: this.filter.country.id
            })
        },
        searchAccManager(value) {
            return getList({
                company_id: this.$store.getters['user/getCompany'],
            })
        },
        searchOwner(value) {
            return getOwnerList({
                name: value,
                company: this.$store.getters['user/getCompany'],
                account_manager: this.filter.account_manager.id
            })
        },
        resetForm() {
            this.reset = 1
            this.filter.name = ''
            this.filter.address = ''
            this.filter.property_type = null
            this.fromDate = null
            this.toDate = null
            this.dateOptionValue = null
            this.filter.categorization = []
            this.filter.status_id = []
            this.filter.country = {
                id: null,
                name: null
            }
            this.filter.city = {
                id: null,
                name: null
            }
            this.filter.account_manager = {
                id: null,
                name: null
            }
            this.filter.owner = {
                id: null,
                name: null
            }

            EventBus.$emit("PropertyFilterSearch", {...this.filter, ...this.dateObject})
        }
    },
    created() {
        getPropertyTypeList().then(response => {
            this.property_type_options = response.data
        }, () => {
            this.property_type_options = []
        })

        fetchStatusList(OBJECT_TYPE_PROPERTY).then(response => {
            this.status_list = response.data
            this.filter.status_id = this.excludedPropertyStatusList
        }, () => {
            this.status_list = []
            this.filter.status_id = []
        })
        this.access_control_general_context = {
            company: this.$store.getters['user/getCompany']
        }

        if (this.isSetupReservationProcessDistributionPropertyRoute || this.isSetupFeesPlatformFeeDistributionPropertyRoute) {
            this.filter.ar_process_status = 1
        }
    },
    watch: {
        loading: {
            handler(value) {
                if (this.reset) {
                    this.loadingReset = value
                } else {
                    this.loadingSubmit = value
                }
            }
        },
        distribution_id: {
            handler() {
                this.setPropertyList()
            }
        },
        dateOptionValue(value, oldValue) {
            if (oldValue !== null) {
                this.fromDate = null
                this.toDate = null
            }
        }
    },
    mounted() {
        EventBus.$on('propertyListLoading', event => {
            this.loading = event
        })
    },

    beforeDestroy() {
        EventBus.$off('propertyListLoading')
    },
}
</script>

<style>

</style>
