<template>
    <div>
        <app-table-v2 class="reservation_table" :fields="getTableFields" :filter="filter"
                      :provider="getData"
                      :search-event="EVENT_TABLE_SEARCH"
                      :limit-list="[10, 20, 50]"
                      :defaultLimit="20"
                      @busyToggled="toggleBusy"

        >

            <template v-slot:HEAD_selectableCheckbox="data">
                <div  class="tableSelectableCheckbox" v-if="hasCheckBox">
                    <b-checkbox size="sm" @change="selectAll($event)" v-model="selectAllValue" :inline="true" ></b-checkbox>
                </div>
            </template>

            <template v-slot:selectableCheckbox="data">
                <div  class="tableSelectableCheckbox" v-if="hasCheckBox && isSelectable(data.item)">
                    <b-checkbox  v-model="selectedRatePlans" @change="setSelectedItem($event, data.item)"
                                 :value="data.item.id" size="sm" :inline="true"></b-checkbox>
                </div>
            </template>

            <template v-slot:linked_to="data">
                <template v-if="data.item.parent && data.item.parent.id">
                    {{data.item.parent.name }} ({{data.item.parent.id}})
                </template>
                <template v-else>
                    {{null}}
                </template>
            </template>
            <template v-slot:linked_value="data">
                {{data.item.linked_value}} {{data.item.linked_type === 'perc'? '%' :
                data.item.linked_type}}
            </template>
            <template v-slot:indicator="data">
                <app-indicator :indicator_type="indicatorType(data.item)"></app-indicator>
            </template>
            <template v-slot:edit="data">

                <app-button
                    :c_permission="(data.item.rate_plan_category === SYSTEM_STANDARD_RATE || data.item.rate_plan_category === SYSTEM_NON_REFUNDABLE_RATE) ? C_UNIT_RATE_PLAN_SYSTEM_E :C_UNIT_RATE_PLAN_E"
                    variant="link"
                    button_type="edit"
                    :show_text="false"
                    @click="edit(data.item)">
                </app-button>
            </template>

            <template v-slot:container_selected_item="data">
                <div class="selectedContainer animated fadeIn" v-if="selectedRatePlans.length">
                    <div>
                        <h4 v-if="selectedRatePlans.length > 1">{{$t("ITEMS_SELECTED", {value: selectedRatePlans.length})}}</h4>
                        <h4 v-else-if="selectedRatePlans.length === 1">{{$t("ITEM_SELECTED")}}</h4>
                        <app-button class="action_button" variant="link" @click="deselectItems">{{$t("DESELECT")}}</app-button>
                    </div>

                    <div class="flex-grow-1 d-flex justify-content-end">
                        <slot v-bind="{selected: selectedRatePlans}" name="selectedContainer">

                            <app-button
                                v-if="checkPermission(C_UNIT_RATE_PLAN_LIST_COPY)"
                                @click="copyRatePlanListPanelState = true">
                                {{$t('COPY')}}
                            </app-button>
                            <app-button
                                v-if="checkPermission(C_UNIT_RATE_PLAN_LIST_CHANGE_LINKED_VALUE)"
                                @click="changeLinkedValuePanelState = true"
                                class="ml-3">
                                {{$t('CHANGE_LINKED_VALUE')}}
                            </app-button>
                            <app-button
                                v-if="checkPermission(C_UNIT_RATE_PLAN_LIST_DELETE)"
                                @click="displayDeleteDialog = true"
                                class="ml-3">
                                {{$t('DELETE')}}
                            </app-button>

                        </slot>
                    </div>
                </div>

            </template>

        </app-table-v2>

        <app-aside v-model="ratePlanAsidePanelState">
            <template slot="header">{{$t('RATE_PLAN')}}</template>
            <update-rate-plan-form :property="property" @ratePlanSaved="updateTable"
                                   :id="itemEdit" :rate="selected_plan" :unit_id="unit_id"></update-rate-plan-form>
        </app-aside>
        <app-aside v-model="newRatePlanPanelState">
            <template slot="header">{{$t('NEW_RATE_PLAN')}}</template>
            <new-rate-plan-form :property="property" :unit_id="unit_id"
                                @ratePlanSaved="updateTable"></new-rate-plan-form>
        </app-aside>
        <app-aside :widths="['col-lg-4', 'col-sm-12', '40%']" v-model="copyRatePlanState">
            <template slot="header">{{$t('APPLY_RATE_PLAN')}}</template>

            <copy-rate-plan-form :hasNrf="getSystemNonRef ? true : false"
                                 @ratePlanCopied="updateTable"
                                 :c_permission="C_UNIT_RATE_PLAN_APPLY_E"></copy-rate-plan-form>
        </app-aside>

        <app-aside v-model="copyRatePlanListPanelState"
                   :widths="['col-lg-3', 'col-sm-12', '30%']">
            <template slot="header">{{$t('COPY_RATE_PLANS_TO_UNITS')}}</template>
            <copy-rate-plan-list-form :rate-plan-list="selectedRatePlans"
                                      :property-id="Number($route.params.id)"
                                      :unit-id="unit_id"
                                      @copiedRatePlans="copiedRatePlans">
            </copy-rate-plan-list-form>
        </app-aside>

        <app-aside v-model="changeLinkedValuePanelState"
                   :widths="['col-lg-3', 'col-sm-12', '30%']">
            <template slot="header">{{$t('CHANGE_LINKED_VALUE')}}</template>
            <change-rate-plan-linked-value-form :rate-plan-list="selectedRatePlans"
                                                :unit-id="unit_id"
                                                :distribution="selectedDistributionList"
                                                @linkedValueSaved="updateTable">
            </change-rate-plan-linked-value-form>
        </app-aside>

        <app-aside v-model="copyRestrictionsPanelState">
            <template slot="header">
                {{$t('COPY_RESTRICTIONS')}}
            </template>
            <copy-restrictions-from-rate-plan-wizard :property-id="Number($route.params.id)"
                                                     :unit-id="unit_id"
                                                     @restrictionsCopied="copyRestrictionsPanelState = false">
            </copy-restrictions-from-rate-plan-wizard>
        </app-aside>

        <app-confirmation-dialog :show="displayDeleteDialog"
                                 :delete_title="true"
                                 @confirm="deleteRatePlans"
                                 @cancel="displayDeleteDialog = false">
        </app-confirmation-dialog>

<!--        <b-modal no-close-on-esc no-close-on-backdrop hide-header-close-->
<!--                 v-model="displayCopySummary"-->
<!--                 :title="$t('COPY_SUMMARY')">-->
<!--            {{copySummary}}-->
<!--            <div slot="modal-footer">-->
<!--                <app-button class="mr-2 ml-2 pl-2"-->
<!--                            @click="closeCopySummary">-->
<!--                    {{$t('OK')}}-->
<!--                </app-button>-->
<!--            </div>-->
<!--        </b-modal>-->
    </div>
</template>

<script>
    import AppIndicator from "@/components/app/indicators/AppIndicator";
    import AppTableV2 from "@/components/app/AppTableV2";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppAside from '@/components/app/form/AppAside';
    import {
        C_UNIT_RATE_PLAN_E, C_UNIT_RATE_PLAN_SYSTEM_E,
        C_UNIT_RATE_PLAN_APPLY_E,
        C_UNIT_RATE_PLAN_LIST_SELECTABLE,
        C_UNIT_RATE_PLAN_LIST_COPY,
        C_UNIT_RATE_PLAN_LIST_CHANGE_LINKED_VALUE,
        C_UNIT_RATE_PLAN_LIST_DELETE
    } from "@/shared/component_permission";
    import {
        RATE_PLAN_CATEGORY_DISTRIBUTION,
        SYSTEM_NON_REFUNDABLE_RATE,
        SYSTEM_STANDARD_RATE
    } from "@/shared/constants";
    import {
        EventBus,
        GE_RATE_PLAN_NRF_SIDE_PANEL,
        GE_RATE_PLAN_COPY_SIDE_PANEL,
        GE_RATE_PLAN_NEW_SIDE_PANEL, GE_RATE_PLAN_COPY_RESTRICTIONS_WIZARD_SIDE_PANEL
    } from "@/shared/EventBus";
    import {createSysytemNonRef, deleteRatePlanList, getPricingRatePlanList} from "@/services/pricing";
    import UpdateRatePlanForm from "@/components/pricing/rate_plan/UpdateRatePlanForm";
    import {cloneDeep} from "lodash";
    import {notifySuccess, toast} from "@/shared/plugins/toastr";
    import CopyRatePlanForm from "@/components/pricing/rate_plan/CopyRatePlanForm";
    import NewRatePlanForm from "@/components/pricing/rate_plan/NewRatePlanForm";
    import _findKey from "lodash/findKey";
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
    import CopyRatePlanListForm from "@/components/pricing/rate_plan/CopyRatePlanListForm";
    import ChangeRatePlanLinkedValueForm from "@/components/pricing/rate_plan/ChangeRatePlanLinkedValueForm";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import CopyRestrictionsFromRatePlanWizard
        from "@/components/pricing/rate_plan/restriction/CopyRestrictionsFromRatePlanWizard";

    export default {
        name: "RatePlanListTable",
        components: {
            CopyRestrictionsFromRatePlanWizard,
            AppAccessControl,
            AppConfirmationDialog,
            ChangeRatePlanLinkedValueForm,
            CopyRatePlanListForm,
            AppButtonDelete,
            NewRatePlanForm,
            CopyRatePlanForm,
            UpdateRatePlanForm, AppButton, AppTableV2, AppIndicator, AppAside
        },
        mixins: [getErrorMessage],
        props: {
            unit_id: {
                type: Number,
                default: null
            },
        },
        data() {
            return {
                SYSTEM_STANDARD_RATE,
                SYSTEM_NON_REFUNDABLE_RATE,
                C_UNIT_RATE_PLAN_SYSTEM_E,
                C_UNIT_RATE_PLAN_E,
                C_UNIT_RATE_PLAN_APPLY_E,
                C_UNIT_RATE_PLAN_LIST_SELECTABLE,
                C_UNIT_RATE_PLAN_LIST_COPY,
                C_UNIT_RATE_PLAN_LIST_CHANGE_LINKED_VALUE,
                C_UNIT_RATE_PLAN_LIST_DELETE,
                filter: {},
                fields: [
                    {key: 'selectableCheckbox', label: ''},
                    {key: 'id', label: 'Id'},
                    {key: 'name', label: this.$t('NAME'), sortable: true},
                    {key: 'rate_plan_type.name', label: this.$t('TYPE')},
                    // {key: 'linked_to', label: this.$t('LINKED_TO')},
                    {key: 'linked_value', label: this.$t('LINKED_VALUE')},
                    {key: 'distribution.name', label: this.$t('DISTRIBUTION'), sortable: true},
                    {key: 'cancellation_policy.name', label: this.$t('CANCELLATION_POLICY')},
                    {key: 'indicator', label: '', class: 'p-0'},
                    {key: 'edit', label: '', class: ' pl-2 text-right'}
                ],
                EVENT_TABLE_SEARCH: 'getRatePlanData',
                primaryKey: 'id',
                itemEdit: null,
                ratePlanAsidePanelState: false,
                newRatePlanPanelState: false,
                selected_plan: null,
                copyRatePlanState: false,
                rate_plan_list: [],
                loading: false,
                copyRatePlanListPanelState: false,
                changeLinkedValuePanelState: false,
                displayDeleteDialog: false,
                // displayCopySummary: false,
                selectedRatePlans: [],
                selectAllValue: false,
                // copySummary: null,
                copyRestrictionsPanelState: false,
            }


        },
        computed: {
            property() {
                return this.$store.getters['property/getProperty']
            },
            getSystemNonRef() {
                return this.$store.getters['getSystemNonRef']
            },
            getTableFields() {
                let fields = this.fields
                if (!this.hasCheckBox) {
                    fields = this.fields.filter(field => field.key !== "selectableCheckbox")
                }
                return fields
            },
            company() {
                return this.$store.getters['user/getCompany']
            },
            hasCheckBox() {
                if (!this.checkPermission(C_UNIT_RATE_PLAN_LIST_SELECTABLE)) {
                    return false
                }
                if (this.rate_plan_list.length) {
                    for(let itemObject of this.rate_plan_list) {
                        if (this.isSelectable(itemObject)) {
                            return true
                        }
                    }
                }
                return false
            },
            selectedDistributionList() {
                let return_list = []
                this.rate_plan_list.forEach(el => {
                    if (this.selectedRatePlans.includes(el.id) && !return_list.includes(el.distribution.id)) {
                        return_list.push(el.distribution.id)
                    }
                })
                return return_list
            },
        },
        methods: {
            indicatorType(item) {
                return item.rate_plan_category === SYSTEM_STANDARD_RATE ? 'indicator-primary' : item.rate_plan_category === SYSTEM_NON_REFUNDABLE_RATE ? 'indicator-warning' : null
            },
            edit(ratePlan) {
                this.itemEdit = ratePlan.id
                this.selected_plan = ratePlan
                this.ratePlanAsidePanelState = true

            },
            getData(filter) {
                let req = {
                    ...this.filter,
                    ...{
                        page: filter.currentPage,
                        perPage: filter.perPage,
                        order_by: filter.sortBy,
                        order_by_direction: filter.sortDirection,
                        unit: this.unit_id,
                    }
                }

                let provider = getPricingRatePlanList(req)
                provider.then(response => {
                    this.rate_plan_list = response.data.items
                    const selectedIds = [...this.selectedRatePlans, ...this.rate_plan_list.filter(item => {
                        return this.isSelectable(item) && !this.selectedRatePlans.includes(item[this.primaryKey])
                    }).map(item => {
                        return item[this.primaryKey]
                    })]
                    this.selectAllValue = selectedIds.length === this.selectedRatePlans.length
                })
                return provider
            },
            updateTable() {
                this.selectedRatePlans = []
                this.selectAllValue = false
                this.copyRatePlanState = false
                this.ratePlanAsidePanelState = false
                this.newRatePlanPanelState = false
                this.changeLinkedValuePanelState = false
                this.copyRestrictionsPanelState = false
                EventBus.$emit(this.EVENT_TABLE_SEARCH)
            },
            toggleBusy($event) {
                this.loading = $event
                EventBus.$emit('loadingRatePlanList', $event)
            },
            addSystemNonRef(object) {
                createSysytemNonRef(object)
                    .then(response => {
                            EventBus.$emit(this.EVENT_TABLE_SEARCH)
                            this.$store.commit('setSystemNonRef', 1)
                            if (response.data.items.length > 0) {
                                let index = _findKey(response.data.items, (el) => {
                                    return el.rate_plan_category === SYSTEM_NON_REFUNDABLE_RATE
                                })
                                if (typeof index === "undefined") {
                                    return null
                                }


                                this.ratePlanAsidePanelState = true
                                this.itemEdit = response.data.items[index].id
                                this.selected_plan = response.data.items[index]
                                EventBus.$emit('loadingNrf',true)
                            }
                        }
                    ).finally(() => {
                    EventBus.$emit('loadingNrf',false)
                })
            },
            isSelectable(ratePlan) {
                return ratePlan.rate_plan_category === RATE_PLAN_CATEGORY_DISTRIBUTION
            },
            setSelectedItem($event, el) {
                let index = this.selectedRatePlans.indexOf(el[this.primaryKey])
                if (index >= 0) {
                    this.selectedRatePlans.splice(index, 1)
                }
                if ($event) {
                    this.selectedRatePlans.push($event)
                    const selectedIds = [...this.selectedRatePlans, ...this.rate_plan_list.filter(item => {
                        return this.isSelectable(item) && !this.selectedRatePlans.includes(item[this.primaryKey])
                    }).map(item => {
                        return item[this.primaryKey]
                    })]
                    this.selectAllValue = selectedIds.length === this.selectedRatePlans.length
                } else {
                    this.selectAllValue = false
                }
            },
            selectAll($event) {
                this.rate_plan_list.forEach(el => {
                    if (this.isSelectable(el)) {
                        this.selectedRatePlans = []
                    }
                })
                if ($event) {
                    this.rate_plan_list.forEach(el => {
                        if (this.isSelectable(el)) {
                            this.selectedRatePlans.push(el.id)
                        }
                    })
                    this.selectAllValue = true
                } else {
                    this.selectAllValue = false
                }
            },
            deselectItems() {
                this.selectedRatePlans = []
                this.selectAllValue = false
            },
            deleteRatePlans() {
                let request = {
                    rate_plan_list: this.selectedRatePlans.map(el => Number(el)),
                    unit: this.unit_id
                }
                deleteRatePlanList(request).then(() => {
                    // notifySuccess()
                    toast({
                        'title': this.$t('NOTIFICATIONS.DELETE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.DELETE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                    this.updateTable()
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.displayDeleteDialog = false
                })
            },
            copiedRatePlans(copySummary) {
                this.copyRatePlanListPanelState = false
                this.updateTable()
                // this.copySummary = copySummary
                // this.displayCopySummary = true
            }
        },
        watch: {
            '$route.params.id': {
                handler(property_id) {
                    if (!this.property.hasOwnProperty('id')) {
                        this.$store.dispatch('property/actionSetProperty', property_id)
                    }
                },
                immediate: true
            },
        },
        mounted() {
            EventBus.$on('filterRatePlan', (filter) => {
                this.filter = cloneDeep(filter)
                EventBus.$emit(this.EVENT_TABLE_SEARCH)
            })
            EventBus.$on(GE_RATE_PLAN_NRF_SIDE_PANEL, (filter) => {
                this.addSystemNonRef(filter)
            })

            EventBus.$on(GE_RATE_PLAN_COPY_SIDE_PANEL, () => {
                this.copyRatePlanState = true
            })

            EventBus.$on(GE_RATE_PLAN_NEW_SIDE_PANEL, () => {
                this.newRatePlanPanelState = true
            })

            EventBus.$on(GE_RATE_PLAN_COPY_RESTRICTIONS_WIZARD_SIDE_PANEL, () => {
                this.copyRestrictionsPanelState = true
            })


        },
        beforeDestroy() {
            EventBus.$off('filterRatePlan')
            EventBus.$off(GE_RATE_PLAN_NRF_SIDE_PANEL)
            EventBus.$off(GE_RATE_PLAN_COPY_SIDE_PANEL)
            EventBus.$off(GE_RATE_PLAN_NEW_SIDE_PANEL)
            EventBus.$off(GE_RATE_PLAN_COPY_RESTRICTIONS_WIZARD_SIDE_PANEL)
        },
    }
</script>

<style scoped>

</style>
