import http from "@/services/http";
import {EVENT_URL} from "@/services/endpoints";

export function getEvents(params) {
    return http.request({
        url: EVENT_URL,
        method: 'get',
        params: params
    })
}

export function getEventTemplates(params) {
    return http.request({
        url: `${EVENT_URL}/template`,
        method: 'get',
        params: params
    })
}

export function getEventNotificationTemplates(params) {
    return http.request({
        url: `${EVENT_URL}/notification-template`,
        method: 'get',
        params: params
    })
}

export function getGroupedEventTemplates(params) {
    return http.request({
        url: `${EVENT_URL}/template-grouped`,
        method: 'get',
        params
    })
}

export function fetchEventTemplate(id) {
    return http.request({
        url: `${EVENT_URL}/template/${id}`,
        method: 'get'
    })
}

export function unlinkEventTemplate(id) {
    return http.request({
        url: `${EVENT_URL}/template/${id}/unlink`,
        method: 'put'
    })
}

export function linkEventTemplate(id,data) {
    return http.request({
        url: `${EVENT_URL}/template/${id}/link`,
        method: 'put',
        data
    })
}


export function getEventSendPointTypes() {
    return http.request({
        url: `${EVENT_URL}/send-point-types`,
        method: 'get'
    })
}

export function getEventPlaceholders(eventId) {
    return http.request({
        url: `${EVENT_URL}/${eventId}/placeholders`,
        method: 'get'
    })
}

export function updateEventTemplate(id, data) {
    return http.request({
        url: `${EVENT_URL}/template/${id}`,
        method: 'put',
        data
    })
}

export function deleteEventTemplate(id, data) {
    return http.request({
        url: `${EVENT_URL}/template/${id}`,
        method: 'delete',
        data
    })
}

export function createEventTemplate(data) {
    return http.request({
        url: `${EVENT_URL}/template`,
        method: 'post',
        data
    })
}

export function setEventActiveStatus(eventId,params) {
    return http.request({
        url: `${EVENT_URL}/template/${eventId}/activate`,
        method: 'put',
        params
    })
}

export function previewEventTemplateMail(data) {
    return http.request({
        url: `${EVENT_URL}/template/preview`,
        method: 'put',
        data: data
    })
}

export function getEventCondition(params) {
    return http.request({
        url: `${EVENT_URL}/send-point-conditions`,
        method: 'get',
        params
    })
}
