<template>
    <div class="property-wrapper mb-0 res-no-border d-flex justify-content-between">
        <div class="card-header">
            <div>
            <div class="header-caption">{{$t('ACCOMMODATION_RESERVATION')}}</div>
            <h2 class="property-wrapper-padding mb-2">
                {{reservation.reservation_code}}
            </h2>
            <div class="header-info" v-if="reservation.guest">
                {{reservation.check_in_date|date}} -
                {{reservation.check_out_date|date}} |
                {{reservation.guest.first_name}} {{reservation.guest.last_name}} |
                <template v-if="ac_response_value">
                {{reservation.channel.name}} |
                </template>
                {{reservation.status.name}}
                <template v-if="mode === 'reservation'">
                    | {{reservation.property.name}}
                </template>
                <template v-else-if="mode === 'inbox'">
                    | {{reservation.unit.name}}
                </template>
            </div>

            </div>
        </div>
        <div class="d-flex align-items-center pull-right" v-if="checkPermission(C_RESERVATION_ACCOMMODATION_TAG_V)">
            <template v-for="item in reservation.tag">
                <b-badge
                    variant="primary" pill
                    class="mr-1"
                    style="cursor: pointer; max-height: 20px; font-size:10.5px">
                    {{ item.label }}
                    <i @click="showDeleteCustomTagDialog(item,reservationDetailsObject)"
                       class="fa fa-times fa-fw ml-1"
                       aria-hidden="true"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_TAG_E)"></i>
                </b-badge>
            </template>
        </div>
        <app-confirmation-dialog :show="deleteCustomTagConfirmDialog"
                                 :delete_title="true"
                                 @confirm="deleteTagMapping()"
                                 @cancel="deleteCustomTagConfirmDialog = false">
        </app-confirmation-dialog>
    </div>
</template>

<script>
    import {AC_FEATURE_SETUP} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {
        C_RESERVATION_ACCOMMODATION_BOOKING_SITE,
        C_RESERVATION_ACCOMMODATION_TAG_V,
        C_RESERVATION_ACCOMMODATION_TAG_E,
    } from "@/shared/component_permission";
    import {fetchAccessControlData} from "@/services/access";
    import {deleteEntityTags} from "@/services/tag";
    import {OBJECT_TYPE_ACCOMMODATION_RESERVATION} from "@/shared/constants";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";

    export default {
        name: "ReservationAccommodationHeader",
        components: {AppConfirmationDialog, AppAccessControl},
        mixins: [AccessControlComponent],
        props: {
            reservation: {
                type: Object,
                required: true,
            },
            mode:{
                type:String,
                default:'reservation'
            }
        },
        data(){
            return {
                access_control_components: 1,
                access_control_fetch_key: AC_FEATURE_SETUP,
                ac_response_value:true,
                deleteCustomTagConfirmDialog: false,
                tagId: null,
                entityId: null,
                C_RESERVATION_ACCOMMODATION_TAG_V,
                C_RESERVATION_ACCOMMODATION_TAG_E,
            }
        },
        methods: {
            showDeleteCustomTagDialog(item) {
                this.tagId = item.tag_id
                this.entityId = item.entity_id
                this.deleteCustomTagConfirmDialog = true
            },
            deleteTagMapping() {
                deleteEntityTags(OBJECT_TYPE_ACCOMMODATION_RESERVATION, this.entityId, [this.tagId]).then((response) => {
                    notifySuccess()
                    if (this.reservation) {
                        let temp = this.reservation.tag
                        this.reservation.tag = temp.filter(el => el.tag_id !== this.tagId)
                    }
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.deleteCustomTagConfirmDialog = false
                })
            },
        },
        mounted(){
                let request = {
                    context:{
                        company: this.$store.getters['user/getCompany']
                    },
                    data:[]
                }
                request.data.push({
                    uid: C_RESERVATION_ACCOMMODATION_BOOKING_SITE,
                    function: C_RESERVATION_ACCOMMODATION_BOOKING_SITE
                })
                fetchAccessControlData(AC_FEATURE_SETUP, request).then(response => {
                    this.ac_response_value = response.data[C_RESERVATION_ACCOMMODATION_BOOKING_SITE].visible
                })
        }
    }
</script>

<style scoped>
    .res-no-border {
        box-shadow: none;
    }

    .res-no-border > div {
        border: none !important;
        padding-left: 0;
    }
</style>
