<template>
    <b-form @submit.prevent="save">
        <b-row>
            <b-col>
                <label>Link to</label>
                <app-select class="mb-4"
                            :options="templateEvents"
                            v-model="linkTo"
                            text-field="label"
                            :search-empty-item="false">
                </app-select>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <app-button-submit class="pull-right" :loading="saving">Link</app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>

import {getEventTemplates, linkEventTemplate} from "@/services/event";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {EventBus, GE_TABLE_REFRESH_SEARCH} from "@/shared/EventBus";
import {EVENT_TEMPLATE_TYPE_CONFIGURATION_TEMPLATE} from "@/shared/constants";

export default {
    name: "EventLinkingForm",
    components: {
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
        "AppButtonSubmit": () => import("@/components/app/AppButton/AppButtonSubmit"),
    },
    mixins: [getErrorMessage],
    props: {
        event: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            templateEvents: [],
            linkTo: null,
            saving: false,
            EVENT_TEMPLATE_TYPE_CONFIGURATION_TEMPLATE
        }
    },
    methods: {
        save() {
            this.saving = true
            let req = {
                system_template: this.linkTo
            }

            linkEventTemplate(this.event.id, req).then(response => {
                notifySuccess()
                this.$emit('save')
                EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => this.saving = false)
        },
        getEvent() {
            let req = {
                template_type: EVENT_TEMPLATE_TYPE_CONFIGURATION_TEMPLATE,
                company: this.company.id
            }
            getEventTemplates(req).then(res => {
                this.templateEvents = res.data.items
            })
        },
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    created() {
        this.getEvent()
    }
}
</script>

<style scoped>

</style>
