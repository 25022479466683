<template>
    <b-form @submit.prevent="save" v-if="checkPermission(C_USER_BASIC_V)" autocomplete="off">
        <b-row class>
            <b-col class="mb-4">
                <div class="profile-image-container mb-4">
                    <div>
                        <label style="cursor: pointer" for="file">{{$t("PROFILE_PHOTO")}}</label>
                        <input id="file" ref="file" type="file" @change="updateImage" accept="image/*" hidden>
                    </div>
                    <div @click="triggerFileInput" class="profile-image"
                         :style="{backgroundImage: 'url('+userData.profile_photo+')'}"></div>
                </div>
            </b-col>
        </b-row>

        <b-row>
            <b-col md="6" lg="4" class="mb-4">
                <app-input-control :error-object="validationErrorObject.first_name"
                                   :key="'first_name_' + userData.id">
                    <template v-slot:input="data">
                        <label>{{$t('FIRST_NAME')}}</label>
                        <b-form-input type="text"
                                      v-model="userData.first_name"
                                      :state="data.state">
                        </b-form-input>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="4" class="mb-4">
                <app-input-control :error-object="validationErrorObject.last_name"
                                   :key="'last_name_' + userData.id">
                    <template v-slot:input="data">
                        <label>{{$t('LAST_NAME')}}</label>
                        <b-form-input type="text"
                                      v-model="userData.last_name"
                                      :state="data.state">
                        </b-form-input>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="4" class="mb-4">
                <app-input-control :error-object="validationErrorObject.username"
                                   :key="'username_' + userData.id">
                    <template v-slot:input="data">
                        <label>{{$t('USERNAME_EMAIL')}}</label>
                        <b-form-input type="text" v-model="userData.username"
                                      :state="data.state">
                        </b-form-input>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="4" class="mb-4">
                <app-input-control :error-object="validationErrorObject.phone"
                                   :key="'phone_' + userData.id">
                    <template v-slot:input="data">
                        <label>{{$t('PHONE')}}</label>
                        <b-form-input type="text" v-model="userData.phone"
                                      :state="data.state">
                        </b-form-input>
                    </template>
                </app-input-control>
            </b-col>

            <b-col md="6" lg="4" class="mb-4">
                <label>{{$t('DATE_OF_BIRTH')}}</label>
                <app-date-picker v-model="userData.date_of_birth"></app-date-picker>
            </b-col>


            <b-col v-if="languages" md="6" lg="4" class="mb-4">
                <app-input-control :error-object="validationErrorObject.preferred_language"
                                   :key="'preferred_language_' + userData.id">
                    <template v-slot:input="data">
                        <label>{{$t('LANGUAGE')}}</label>
                        <app-select
                            :options="languages"
                            value-field="value"
                            text-field="text"
                            v-model="userData.prefered_language"
                            :state="data.state"
                            :search-empty-item="false">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <app-button-submit :loading="updating" :c_permission="c_permission"></app-button-submit>
            </b-col>

            <b-col md="6">
                <app-button-delete v-if="checkPermission(C_USER_BASIC_DELETE) && showDeleteButton" class="pull-right"
                                   @click="deleteDialogState=true"></app-button-delete>
                <app-confirmation-dialog :show="deleteDialogState" @confirm="deleteConfirm"
                                         @cancel="deleteDialogState=false"
                                         :delete_title="true"></app-confirmation-dialog>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import {notifySuccess, toast} from '@/shared/plugins/toastr';
    import {getErrorMessage} from '@/mixins/error/getErrorMessage';
    import AppButtonSubmit from '@/components/app/AppButton/AppButtonSubmit';
    import {C_USER_BASIC_DELETE, C_USER_BASIC_V} from "@/shared/component_permission";
    import {C_USER_BASIC_E} from '@/shared/component_permission'
    import FormHeader from "@/components/app/form/FormHeader";
    import GalleryUpload from "@/components/app/gallery/GalleryUpload";
    import {TYPE_PROFILE_PHOTO} from "@/shared/constants";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppDatePicker from "@/components/app/datetime/AppDatePicker";
    import AppInputControl from "@/components/app/AppInputControl";
    import {cloneDeep, isEqual} from "lodash";
    import {deleteUser, setProfilePicture, updateUser} from "@/services/user";
    import {
        USER_FIRST_NAME_REQUIRED,
        USER_LAST_NAME_REQUIRED,
        USER_PHONE_VALIDATION_FAILED,
        USER_PREFERRED_LANGUAGE_REQUIRED,
        USER_USERNAME_ALREADY_EXISTS,
        USER_USERNAME_EMAIL_VALIDATION_FAILED,
        USER_USERNAME_REQUIRED
    } from "@/shared/error_codes";
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import routeNames from "@/router/routeNames";

    export default {
        name: "UserBasicForm",
        mixins: [getErrorMessage],
        components: {
            AppConfirmationDialog,
            AppButtonDelete,
            AppDatePicker,
            AppSelect,
            GalleryUpload,
            FormHeader,
            AppButtonSubmit,
            AppInputControl
        },
        props: {
            userProp: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                updating: false,
                TYPE_PROFILE_PHOTO,
                newProfilePhoto: null,
                C_USER_BASIC_V,
                c_permission: C_USER_BASIC_E,
                initialUserData: null,
                userData: null,
                validationErrorObject: {
                    first_name: USER_FIRST_NAME_REQUIRED,
                    last_name: USER_LAST_NAME_REQUIRED,
                    phone: USER_PHONE_VALIDATION_FAILED,
                    preferred_language: USER_PREFERRED_LANGUAGE_REQUIRED,
                    username: {
                        input: [
                            USER_USERNAME_REQUIRED,
                            USER_USERNAME_EMAIL_VALIDATION_FAILED,
                            USER_USERNAME_ALREADY_EXISTS
                        ]
                    }
                },
                deleteDialogState: false,
                C_USER_BASIC_DELETE,
                deleting: false,
            }
        },
        computed: {
            languages() {
                return Object.keys(this.$i18n.messages).map(item => {
                    return {
                        text: item,
                        value: item
                    }
                })
            },
            user() {
                return this.userData
            },
            profile_photo() {
                return this.userData.profile_photo
            },
            user_id() {
                return this.userData.id
            },
            loggedUser() {
                return this.$store.getters['user/getUser']
            },
            dateOfBirth: {
                get() {
                    return this.userData.date_of_birth
                },
                set(val) {
                    this.userData.date_of_birth = val
                }
            },
            showDeleteButton() {
                return this.$route.params.id && Number(this.$route.params.id) !== Number(this.loggedUser.id)
            }
        },
        watch: {
            userProp: {
                handler(val) {
                    this.userData = cloneDeep(val)
                },
                deep: true,
                immediate: true
            },
        },
        methods: {
            updateImage(event) {
                let input = event.target;
                let file = input.files[0];
                if (input.files && file) {
                    this.readURL(file);
                    this.newProfilePhoto = file;
                    this.userData.profile_photo = this.newProfilePhoto;
                    this.uploadPhotoIfNew()
                }
            },
            save() {
                if (!isEqual(this.initialUserData, this.userData)) {
                    this.updating = true;

                    if (this.$store.getters['user/getUser'].id === this.user.id) {
                        this.$store.dispatch('user/actionUpdateUser', this.user).then(res => {
                            this.$emit('update', res.data);
                            this.initialUserData = cloneDeep(res.data);
                            toast({
                                'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                                'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                                'type': 'success',
                                'timeout': 3000
                            })
                        }, error => {
                            this.showErrorMessages(error, this.validationErrorObject);
                        }).finally(() => this.updating = false)
                    } else {
                        updateUser(this.userData.id, this.userData).then(res => {
                            this.$emit('update', res.data);
                            this.initialUserData = cloneDeep(res.data);
                            toast({
                                'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                                'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                                'type': 'success',
                                'timeout': 3000
                            })
                        }, error => {
                            this.showErrorMessages(error, this.validationErrorObject);
                        }).finally(() => this.updating = false)

                    }
                } else {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NO_CHANGE'),
                        'type': 'error',
                        'timeout': 3000
                    })
                }
            },
            uploadPhotoIfNew() {
                if (!this.newProfilePhoto) {
                    return Promise.resolve()
                }
                let data = new FormData();
                data.append('list[]', this.newProfilePhoto);
                if (this.$store.getters['user/getUser'].id === this.user.id) {
                    this.$store.dispatch('user/actionUpdateUser', this.user)
                }
                setProfilePicture(this.user_id, data).then(() => {
                    toast({
                        title: this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        message: this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        type: 'success',
                        timeout: 3000
                    })
                }, error => {
                    this.newProfilePhoto = null;
                    this.userData.profile_photo = this.profile_photo;
                    this.showErrorMessages(error)
                })
            },
            readURL(file) {
                if (file) {
                    let reader = new FileReader();
                    let context = this;
                    reader.onload = function (e) {
                        context.userData.profile_photo = e.target.result;
                    };
                    reader.readAsDataURL(file);
                }
            },
            triggerFileInput() {
                this.$refs.file.click()
            },
            deleteConfirm() {
                this.deleting = true
                deleteUser(this.$route.params.id).then(() => {
                    notifySuccess()
                    this.$router.push({name: routeNames.RN_USER_LIST})
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.deleting = false
                    this.deleteDialogState = false
                })
            },
        },
        created() {
            this.initialUserData = cloneDeep(this.userData);
        }
    }
</script>

<style scoped>
    .profile-image-container {
        width: fit-content;
    }

    .profile-image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 1px solid var(--color-primary);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        cursor: pointer;
    }
</style>
