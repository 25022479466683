import http from "@/services/http";
import {CURRENCY_EXCHANGE_FIXED_URL, CURRENCY_EXCHANGE_URL} from "@/services/endpoints";

// FIXED
export function getCurrencyExchangeFixedList(params) {
    return http.request({
        url: CURRENCY_EXCHANGE_FIXED_URL,
        method: 'GET',
        params
    })
}

export function fetchCurrencyExchangeFixed(id) {
    return http.request({
        url: CURRENCY_EXCHANGE_FIXED_URL + '/' + id,
        method: 'GET'
    })
}

export function createCurrencyExchangeFixed(data) {
    return http.request({
        url: CURRENCY_EXCHANGE_FIXED_URL,
        method: 'POST',
        data
    })
}

export function updateCurrencyExchangeFixed(id, data) {
    return http.request({
        url: CURRENCY_EXCHANGE_FIXED_URL + '/' + id,
        method: 'PUT',
        data
    })
}

export function deleteCurrencyExchangeFixed(id) {
    return http.request({
        url: CURRENCY_EXCHANGE_FIXED_URL + '/' + id,
        method: 'DELETE'
    })
}

export function fetchCurrencyExchangeRates(currency_id, {action = 1, with_fixed = false, company = null, date = null} = {}) {
    return http.request({
        url: `${CURRENCY_EXCHANGE_URL}/${currency_id}`,
        method: 'GET',
        params: {company, with_fixed, action, date}
    })
}
