import {isObject as _isObject, isEqual as _isEqual} from 'lodash'

export const fileHelpers = {
    methods: {
        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result)
                reader.onerror = error => reject(error)
            })
        },
        handleFileDownload(data, fileName, extension) {
            const url = window.URL.createObjectURL(data)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${fileName}.${extension}`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }
}

export const listHelpers = {
    computed: {
        yesNoOptions() {
            return [
                {id: 1, name: this.$t('YES')},
                {id: 0, name: this.$t('NO')},
            ]
        }
    }
}

export const dataFormatters = {
    methods: {
        /**
         * Recursively formats all
         *
         * {value: {id: x, label: y}}
         * AND
         * {value: {id: x, name: y}}
         * TO
         * {value: x}
         *
         * for data saving purposes
         *
         * @param object - variable of type Object
         * @param includedFields - list of additional object fields to format
         * @param excludedFields - list of excluded key-name pair objects
         * @returns object - formatted value of type Object
         * @returns any - if provided value is not an object, return the same value
         */
        formatObjectKeyNamePairs(object, {includedFields = [], excludedFields = []} = {}) {
            if (!_isObject(object) || !Array.isArray(includedFields) || !Array.isArray(excludedFields)) return object

            const keyLabelPair = ['id', 'label']
            const keyNamePair = ['id', 'name']

            const format = (data) => {
                return Array.isArray(data)
                    ? data.map(el => format(el))
                    : Object.entries(data).reduce((result, [key, value]) => {
                        if (_isObject(value)) {
                            const keys = Object.keys(value)
                            if (!excludedFields.includes(key) &&
                                (includedFields.includes(key) || _isEqual(keys, keyLabelPair) || _isEqual(keys, keyNamePair))
                            ) {
                                result[key] = value.id
                            } else {
                                result[key] = format(value)
                            }
                            return result
                        }
                        result[key] = value
                        return result
                    }, {})
            }

            return format(object)
        }
    }
}

export const textFormatters = {
    methods: {
        sanitizeText(text) {
            if (typeof text === 'number') {
                return text
            }
            return text && typeof text === 'string' && text.length > 0
                ? text.replace(/[\u00A0-\u9999<>\&]/g, function (character) {
                    return '&#' + character.charCodeAt(0) + ';';
                }) : ''
        },
        stripTags(text, allowedTags = [], allowedAttributes = {}) {
            let sanitizeOptions = {
                allowedTags: allowedTags,
                allowedAttributes: allowedAttributes,
            }

            return this.$sanitize(text, sanitizeOptions)
        },
    },
}
