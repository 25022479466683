import {
    getProperty,
    setProperty,
    createProperty,
    getPropertyPromotionList,
    deletePropertyPromotion,
    createPropertyPromotion,
    updatePropertyPromotion,
    getPropertyRmSetupList,
    deletePropertyRmSetup,
    createRmSetup,
    updateRmSetup,
    getPropertyList, createPropertyPdf
} from '@/services/property/index'
import {createPropertyWithOwner} from "@/services/property";

const state = {
    id: null,
    basic: {
      status:{}
    },
    cancellation_policies: [],
    promotion: {
        promotion_list: [],
        rate_plan_list: []
    },
    rm_setup_list: [],
    search: {
        list: {
            list_count: 0,
            list_items: [],
            list_loaded: false,
            last_page: 0
        },
        filter: {
            page: 1,
            limit: 10,
            name: '',
            status_id: null,
            company_id: null,
            account_manager: null,
            categorization: [],
            property_type: null,
            city: {
              id:null,
              name:null
            },
            country: {
              id:null,
              name:null
            },
        }
    },
    ajpes_connection: null
}


const getters = {
    getProperty(state) {
        return state.basic
    },
    getSearchFilter(state) {
        return state.search.filter
    },
    getSearchList(state) {
        return state.search.list.list_items
    },
    getSearchListCount(state) {
        return state.search.list.list_count
    },
    getSearchListLoaded(state) {
        return state.search.list.list_loaded
    },
    getSearchListLastPage(state) {
        return state.search.list.last_page
    },
    getAjpesConnection(state){
        return state.ajpes_connection
    },
    getCancellationPolicies(state) {
        return state.cancellation_policies
    },

}

const mutations = {
    setProperty(state, payload) {
        state.basic = payload
    },
    setPropertyStatusActivation(state, payload){
      state.active = payload
    },
    setSearchFilterItem(state, {item, value}) {
        state.search.filter[item] = value
    },
    setSearchList(state, list) {
        state.search.list.list_items = list
        state.search.list.list_loaded = true
    },
    setSearchListCount(state, count) {
        state.search.list.list_count = count
    },
    setSearchListLastPage(state, page) {
        state.search.list.last_page = page
    },
    setAjpesConnection(state, payload) {
        state.ajpes_connection = payload
    },
    setCancellationPolicies(state, payload) {
        state.cancellation_policies = payload
    },
}

const actions = {
    actionSetProperty({commit}, id) {
        return new Promise((resolve, reject) => {
            getProperty(id).then(response => {
                commit('setProperty', response.data)
                resolve(response);  // Let the calling function know that http is done. You may send some data back
            }, error => {
                // http failed, let the calling function know that action did not work out
                reject(error);
            })
        })
    },
    actionCreateProperty({commit}, data) {
        return new Promise((resolve, reject) => {
            createProperty(data).then(response => {
                commit('setProperty', response.data)
                resolve(response);  // Let the calling function know that http is done. You may send some data back
            }, error => {
                // http failed, let the calling function know that action did not work out
                reject(error);
            })
        })
    },
    actionCreatePropertyWithOwner({commit}, data){
      return new Promise((resolve , reject) => {
          createPropertyWithOwner(data).then(response => {
              commit('setProperty', response.data)
              resolve(response);  // Let the calling function know that http is done. You may send some data back
          }, error => {
              // http failed, let the calling function know that action did not work out
              reject(error);
          })
      })
    },
    actionUpdatePropertyBasic({commit}, {id, data}) {
        return new Promise((resolve, reject) => {
            setProperty(id, data).then(response => {
                commit('setItem', {path: 'property.basic', value: response.data}, {root: true})
                resolve(response);  // Let the calling function know that http is done. You may send some data back
            }, error => {
                // http failed, let the calling function know that action did not work out
                reject(error);
            })
        })
    },
    actionUpdatePropertyLocation({commit}, property_id) {
        return new Promise((resolve, reject) => {
            setProperty(property_id, {location: state.basic.location}).then(response => {
                commit('setItem', {path: 'property.basic', value: response.data}, {root: true})
                resolve(response);  // Let the calling function know that http is done. You may send some data back
            }, error => {
                // http failed, let the calling function know that action did not work out
                reject(error);
            })
        })
    },
    actionSetPropertyPromotionList({commit}, propertyId) {
        return new Promise((resolve, reject) => {
            getPropertyPromotionList(propertyId)
                .then((response) => {
                    commit('setItem', {path: 'property.promotion', value: response.data}, {root: true})
                    resolve(response)

                }).catch(error => {
                reject(error);
            })
        })
    },
    actionDeletePropertyPromotion({commit}, payload) {

        return new Promise((resolve, reject) => {
            deletePropertyPromotion(state.basic.id, payload.promotionId)
                .then((response) => {
                    commit('setItem', {path: 'property.promotion', value: response.data}, {root: true})
                    resolve(response)

                }).catch(error => {
                reject(error);
            })

        })
    },
    actionCreatePropertyPromotion({commit}, data) {
        return new Promise((resolve, reject) => {

            createPropertyPromotion(state.basic.id, data)
                .then((response) => {
                    commit('setItem', {path: 'property.promotion', value: response.data}, {root: true})
                    resolve(response)

                }).catch(error => {
                reject(error);
            })
        })
    },
    actionPropertyPromotion({commit}, payload) {
        return new Promise((resolve, reject) => {
            updatePropertyPromotion(state.basic.id, payload.promotionId, payload.data)
                .then((response) => {

                    commit('setItem', {path: 'property.promotion', value: response.data}, {root: true})
                    resolve(response)

                }).catch(error => {
                reject(error);
            })
        })
    },
    actionSetPropertyRmSetup({commit}, payload) {
        return new Promise((resolve, reject) => {
            getPropertyRmSetupList(payload.propertyId, {rm_filter_type: payload.rm_filter_type})
                .then((response) => {
                    commit('setItem', {path: 'property.rm_setup_list', value: response.data}, {root: true})
                    resolve(response)
                }).catch(error => {
                reject(error)
            })
        })
    },
    actionDeletePropertyRmSetup({commit}, payload) {
        return new Promise((resolve, reject) => {
            deletePropertyRmSetup(state.basic.id, payload.collectionId, {rm_filter_type: payload.rm_filter_type})
                .then((response) => {
                    commit('setItem', {path: 'property.rm_setup_list', value: response.data}, {root: true})
                    resolve(response)
                }).catch(error => {
                reject(error)
            })
        })
    },
    actionCreatePropertyRmSetup({commit}, payload) {
        return new Promise((resolve, reject) => {
            createRmSetup(state.basic.id, payload)
                .then((response) => {
                    commit('setItem', {path: 'property.rm_setup_list', value: response.data}, {root: true})
                    resolve(response)
                }).catch(error => {
                reject(error)
            })
        })
    },
    actionUpdateRmSetup({commit}, payload) {
        return new Promise((resolve, reject) => {
            updateRmSetup(state.basic.id, payload.id, payload)
                .then((response) => {
                    commit('setItem', {path: 'property.rm_setup_list', value: response.data}, {root: true})
                    resolve(response)
                }).catch(error => {
                reject(error)
            })
        })
    },
    actionSearch({commit, state}) {
        return new Promise((resolve, reject) => {
            getPropertyList(state.search.filter)
                .then((response) => {
                    commit('setSearchList', response.data.items)
                    commit('setSearchListCount', response.data.total)
                    commit('setSearchListLastPage', response.data.last_page)
                    resolve(response)
                }).catch(error => {
                reject(error)
            })
        })
    },
    ActionResetSearch({commit}) {
        commit("setSearchFilterItem", {item: "page", value: 1})
        commit("setSearchFilterItem", {item: "limit", value: 10})
        commit("setSearchFilterItem", {item: "name", value: null})
        commit("setSearchFilterItem", {item: "status_id", value: null})
        commit("setSearchFilterItem", {item: "account_manager", value: null})
        commit("setSearchFilterItem", {item: "categorization", value: []})
        commit("setSearchFilterItem", {item: "property_type", value: null})
        commit("setSearchFilterItem", {item: "city", value: {}})
        commit("setSearchFilterItem", {item: "country", value: {}})
    },
    ActionCreatePdf({commit, state}){
        return new Promise((resolve, reject) => {
            createPropertyPdf(state.basic.id).then(response => {

                commit('setItem', {path: 'property.basic.pdf', value: response.data}, {root: true})
                resolve(response);  // Let the calling function know that http is done. You may send some data back
            }, error => {
                // http failed, let the calling function know that action did not work out
                reject(error);
            })
        })

    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
