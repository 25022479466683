<template>
    <div>
        <inventory-default-value-form :object_id="unit_id" :object_type="OBJECT_TYPE_UNIT" :default-value-object="defaultValueObject">

        </inventory-default-value-form>

    </div>

</template>

<script>
    import DefaultRestrictionsForm from "@/components/app/restrictions/DefaultRestrictionsForm";
    import FormHeader from "@/components/app/form/FormHeader";
    import InventoryDefaultValueForm from "@/components/inventory_default/InventoryDefaultValueForm";
    import {OBJECT_TYPE_PROPERTY, OBJECT_TYPE_UNIT} from "@/shared/constants";
    import {getInventoryDefaultValues} from "@/services/property";

    export default {
        name: "Index",
        components: {InventoryDefaultValueForm, FormHeader, DefaultRestrictionsForm},
        data() {
            return {
                unit_id: this.$route.params.unit_id,
                OBJECT_TYPE_UNIT,
                defaultValueObject:{}
            }
        },
        methods:{
          save(){

          }
        },
        watch: {
            '$route.params.unit_id': {
                handler(value) {
                    if(!value){
                        return
                    }
                    this.unit_id = Number(value)
                    getInventoryDefaultValues({object:value, object_type:OBJECT_TYPE_UNIT}).then(response =>{
                        this.defaultValueObject = response.data
                    })
                },immediate:true
            }
        }
    }
</script>

<style scoped>

</style>
