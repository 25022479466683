<template>
    <b-row>
        <b-col class="translate-list-wrapper">
            <template v-if="placeholderOptions.enabled && !placeholderOptions.per_language && placeholderList.length > 0">
                <template v-if="placeholderOptions.collapsable">
                    <b-row>
                        <b-col class="mb-4">
                            <div class="border rounded">
                                <div class="d-flex justify-content-center align-items-center pt-1 pb-1 cursor-pointer"
                                     :class="{'border-bottom': placeholderDisplayState}"
                                     @click="placeholderDisplayState = !placeholderDisplayState">
                                    <span class="ml-auto">Placeholders</span>
                                    <i
                                        class="ml-auto mr-2 text-primary"
                                        :class="`fa fa-caret-${placeholderDisplayState ? 'up' : 'down'}`">
                                    </i>
                                </div>
                                <b-collapse id="placeholders" v-model="placeholderDisplayState">
                                    <div class="m-3">
                                        <b-badge :key="placeholderIndex"
                                                 class="mt-1 mb-1 mr-1 p-2 cursor-pointer" variant="primary"
                                                 pill
                                                 v-for="(placeholder, placeholderIndex) in placeholderList"
                                                 @click="insertPlaceholder(placeholder.value)">
                                            {{ placeholder.label }}
                                        </b-badge>
                                    </div>
                                </b-collapse>
                            </div>
                        </b-col>
                    </b-row>
                </template>
                <template v-else>
                    <b-row>
                        <b-col class="mb-4">
                            <b-badge :key="placeholderIndex"
                                     class="mt-1 mb-1 mr-1 p-2 cursor-pointer" variant="primary"
                                     pill
                                     v-for="(placeholder, placeholderIndex) in placeholderList"
                                     @click="insertPlaceholder(placeholder.value)">
                                {{ placeholder.label }}
                            </b-badge>
                        </b-col>
                    </b-row>
                </template>
            </template>

            <template v-if="component === `rich-text`">
                <template v-for="(lang, index) in languageList">
                    <template v-if="placeholderOptions.enabled && placeholderOptions.per_language && placeholderList.length > 0">
                        <template v-if="placeholderOptions.collapsable">
                            <b-row>
                                <b-col :class="index > 0 ? 'mt-4 mb-2' : 'mb-2'">
                                    <div class="border rounded">
                                        <div class="d-flex justify-content-center align-items-center pt-1 pb-1 cursor-pointer"
                                             :class="{'border-bottom': langPlaceholderDisplayState.hasOwnProperty(lang) ? langPlaceholderDisplayState[lang] : false}"
                                             @click="togglePlaceholderDisplayState(lang)">
                                            <span class="ml-auto">Placeholders</span>
                                            <i
                                                class="ml-auto mr-2 text-primary"
                                                :class="`fa fa-caret-${langPlaceholderDisplayState.hasOwnProperty(lang) && langPlaceholderDisplayState[lang] ? 'up' : 'down'}`">
                                            </i>
                                        </div>
                                        <b-collapse id="placeholders" v-model="langPlaceholderDisplayState.hasOwnProperty(lang) ? langPlaceholderDisplayState[lang] : false">
                                            <div class="m-3">
                                                <b-badge :key="placeholderIndex"
                                                         class="mt-1 mb-1 mr-1 p-2 cursor-pointer" variant="primary"
                                                         pill
                                                         v-for="(placeholder, placeholderIndex) in placeholderList"
                                                         @click="insertPlaceholder(placeholder.value, lang)">
                                                    {{ placeholder.label }}
                                                </b-badge>
                                            </div>
                                        </b-collapse>
                                    </div>
                                </b-col>
                            </b-row>
                        </template>
                        <template v-else>
                            <b-row>
                                <b-col :class="index > 0 ? 'mt-4 mb-2' : 'mb-2'">
                                    <b-badge :key="placeholderIndex"
                                             class="mt-1 mb-1 mr-1 p-2 cursor-pointer" variant="primary"
                                             pill
                                             v-for="(placeholder, placeholderIndex) in placeholderList"
                                             @click="insertPlaceholder(placeholder.value, lang)">
                                        {{ placeholder.label }}
                                    </b-badge>
                                </b-col>
                            </b-row>
                        </template>
                    </template>
                    <div :class="(!placeholderOptions.enabled || !placeholderOptions.per_language || placeholderList.length < 1) && index > 0 ? 'mt-2' : ''">
                        <label>{{ $t(`LANGUAGES.${lang}`) }}</label>
                        <app-wysiwyg-editor
                            v-model="translations_[lang]"
                            :editorOptions="richTextOptions"
                            :ref="'wysiwygEditor_' + lang"
                            @blur="onBlur($event, lang)">
                        </app-wysiwyg-editor>
                    </div>
                </template>
            </template>

            <template v-if="component === `input`">
                <template v-for="(lang, index) in languageList">
                    <template v-if="placeholderOptions.enabled && placeholderOptions.per_language && placeholderList.length > 0">
                        <template v-if="placeholderOptions.collapsable">
                            <b-row>
                                <b-col :class="index > 0 ? 'mt-4 mb-2' : 'mb-2'">
                                    <div class="border rounded">
                                        <div class="d-flex justify-content-center align-items-center pt-1 pb-1 cursor-pointer"
                                             :class="{'border-bottom': langPlaceholderDisplayState.hasOwnProperty(lang) ? langPlaceholderDisplayState[lang] : false}"
                                             @click="togglePlaceholderDisplayState(lang)">
                                            <span class="ml-auto">Placeholders</span>
                                            <i
                                                class="ml-auto mr-2 text-primary"
                                                :class="`fa fa-caret-${langPlaceholderDisplayState.hasOwnProperty(lang) && langPlaceholderDisplayState[lang] ? 'up' : 'down'}`">
                                            </i>
                                        </div>
                                        <b-collapse id="placeholders" v-model="langPlaceholderDisplayState.hasOwnProperty(lang) ? langPlaceholderDisplayState[lang] : false">
                                            <div class="m-3">
                                                <b-badge :key="placeholderIndex"
                                                         class="mt-1 mb-1 mr-1 p-2 cursor-pointer" variant="primary"
                                                         pill
                                                         v-for="(placeholder, placeholderIndex) in placeholderList"
                                                         @click="insertPlaceholder(placeholder.value, lang)">
                                                    {{ placeholder.label }}
                                                </b-badge>
                                            </div>
                                        </b-collapse>
                                    </div>
                                </b-col>
                            </b-row>
                        </template>
                        <template v-else>
                            <b-row>
                                <b-col :class="index > 0 ? 'mt-4 mb-2' : 'mb-2'">
                                    <b-badge :key="placeholderIndex"
                                             class="mt-1 mb-1 mr-1 p-2 cursor-pointer" variant="primary"
                                             pill
                                             v-for="(placeholder, placeholderIndex) in placeholderList"
                                             @click="insertPlaceholder(placeholder.value, lang)">
                                        {{ placeholder.label }}
                                    </b-badge>
                                </b-col>
                            </b-row>
                        </template>
                    </template>
                    <b-input-group :class="(!placeholderOptions.enabled || !placeholderOptions.per_language || placeholderList.length < 1) && index > 0 ? 'mt-2' : ''">
                        <b-form-input
                            v-model="translations_[lang]"
                            :maxlength="maxLength"
                            @blur="onBlur($event, lang)">
                        </b-form-input>

                        <template #append>
                            <b-input-group-text :style="appendStyle">
                                {{ $t(`LANGUAGES.${lang}`) }}
                            </b-input-group-text>
                        </template>
                    </b-input-group>
                </template>
            </template>

            <template v-if="component === `textarea`">
                <template v-for="(lang, index) in languageList">
                    <template v-if="placeholderOptions.enabled && placeholderOptions.per_language && placeholderList.length > 0">
                        <template v-if="placeholderOptions.collapsable">
                            <b-row :class="index > 0 ? 'mt-4' : ''">
                                <b-col class="mb-2">
                                    <div class="border rounded">
                                        <div class="d-flex justify-content-center align-items-center pt-1 pb-1 cursor-pointer"
                                             :class="{'border-bottom': langPlaceholderDisplayState.hasOwnProperty(lang) ? langPlaceholderDisplayState[lang] : false}"
                                             @click="togglePlaceholderDisplayState(lang)">
                                            <span class="ml-auto">Placeholders</span>
                                            <i
                                                class="ml-auto mr-2 text-primary"
                                                :class="`fa fa-caret-${langPlaceholderDisplayState.hasOwnProperty(lang) && langPlaceholderDisplayState[lang] ? 'up' : 'down'}`">
                                            </i>
                                        </div>
                                        <b-collapse id="placeholders" v-model="langPlaceholderDisplayState.hasOwnProperty(lang) ? langPlaceholderDisplayState[lang] : false">
                                            <div class="m-3">
                                                <b-badge :key="placeholderIndex"
                                                         class="mt-1 mb-1 mr-1 p-2 cursor-pointer" variant="primary"
                                                         pill
                                                         v-for="(placeholder, placeholderIndex) in placeholderList"
                                                         @click="insertPlaceholder(placeholder.value, lang)">
                                                    {{ placeholder.label }}
                                                </b-badge>
                                            </div>
                                        </b-collapse>
                                    </div>
                                </b-col>
                            </b-row>
                        </template>
                        <template v-else>
                            <b-row :class="index > 0 ? 'mt-4' : ''">
                                <b-col class="mb-2">
                                    <b-badge :key="placeholderIndex"
                                             class="mt-1 mb-1 mr-1 p-2 cursor-pointer" variant="primary"
                                             pill
                                             v-for="(placeholder, placeholderIndex) in placeholderList"
                                             @click="insertPlaceholder(placeholder.value, lang)">
                                        {{ placeholder.label }}
                                    </b-badge>
                                </b-col>
                            </b-row>
                        </template>
                    </template>
                    <b-input-group :class="(!placeholderOptions.enabled || !placeholderOptions.per_language || placeholderList.length < 1) && index > 0 ? 'mt-2' : ''">
                        <b-form-textarea
                            v-model="translations_[lang]"
                            :maxlength="maxLength" no-resize
                            :rows="rows"
                            @blur="onBlur($event, lang)">
                        </b-form-textarea>

                        <template #append>
                            <b-input-group-text :style="appendStyle">
                                <span style="transform: rotate(45deg); letter-spacing: 3px">
                                    {{ $t(`LANGUAGES.${lang}`) }}
                                </span>
                            </b-input-group-text>
                        </template>
                    </b-input-group>
                </template>
            </template>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "AppTranslationList",
    components: {
        "AppButtonDelete": () => import("@/components/app/AppButton/AppButtonSubmit"),
        "AppButtonSubmit": () => import("@/components/app/AppButton/AppButtonSubmit"),
        "AppWysiwygEditor": () => import("@/components/app/AppWysiwygEditor")
    },
    props: {
        languageList: {
            type: Array,
            default: () => [],
        },
        translations: {
            type: Object,
            default: () => ({})
        },
        component: {
            type: String,
            default: "input",
            validator: value => {
                return (["input", "textarea", "rich-text"]).includes(value)
            }
        },
        rows: {
            type: Number,
            default: 5,
            validator: value => {
                return value > 2
            }
        },
        maxLength: {
            type: Number,
            default: null
        },
        richTextOptions: {
            type: Object,
            default: null
        },
        placeholderList: {
            type: Array,
            default() {
                return []
            },
        },
        placeholderOptions: {
            type: Object,
            default() {
                return {
                    enabled: true,
                    collapsable: true,
                    per_language: true,
                }
            },
            validator: (value) => {
                return value.hasOwnProperty('enabled') && typeof value.enabled === 'boolean' &&
                value.hasOwnProperty('collapsable') && typeof value.collapsable === 'boolean' &&
                value.hasOwnProperty('per_language') && typeof value.per_language === 'boolean'
            },
        },
    },
    data() {
        return {
            longestTranslationLabel: null,
            translations_: {},
            focusLocation: {},
            cursorLang: null,
            placeholderDisplayState: false,
            langPlaceholderDisplayState: {},
        }
    },
    computed: {
        appendStyle() {
            return this.longestTranslationLabel ? `min-width: ${(this.longestTranslationLabel.length * this.appendWidthModifier)}rem` : ``
        },
        appendWidthModifier() {
            return this.component === "textarea" ? 0.85 : 0.65
        }
    },
    watch: {
        translations_: {
            deep: true,
            handler(data) {
                this.$emit('update', data)
            }
        },
        languageList: {
            immediate: true,
            handler(list) {
                if (list && list.length) {
                    this.longestTranslationLabel = list
                        .map(e => this.$t(`LANGUAGES.${e}`))
                        .sort((a, b) => (b.length - a.length))[0]
                } else {
                    this.longestTranslationLabel = null
                }
            }
        }
    },
    methods: {
        insertPlaceholder(placeholderValue, lang = null) {
            const currentCursorLang = lang !== null ? lang : (this.cursorLang !== null ? this.cursorLang : (this.languageList.length > 0 ? this.languageList[0] : null))
            if (currentCursorLang) {
                if (this.component === 'input' || this.component === 'textarea') {
                    const focusLocation = this.focusLocation.hasOwnProperty(currentCursorLang) ? this.focusLocation[currentCursorLang] : 0
                    const currentValue = this.translations_[currentCursorLang] || ''
                    this.$set(this.translations_, currentCursorLang, `${currentValue.slice(0, focusLocation)}${placeholderValue}${currentValue.slice(focusLocation)}`)
                    this.$set(this.focusLocation, currentCursorLang, focusLocation + placeholderValue.length)
                } else if (this.component === 'rich-text') {
                    const editorRef = 'wysiwygEditor_' + currentCursorLang
                    if (this.$refs[editorRef] && this.$refs[editorRef][0]) {
                        this.$refs[editorRef][0].insertTextOnCursor(placeholderValue)
                    }
                }
            }
        },
        onBlur(event, lang) {
            if (this.component === 'input' || this.component === 'textarea') {
                this.$set(this.focusLocation, lang, event.target.selectionStart)
            }
            this.cursorLang = lang
        },
        togglePlaceholderDisplayState(lang) {
            if (this.langPlaceholderDisplayState.hasOwnProperty(lang)) {
                this.$set(this.langPlaceholderDisplayState, lang, !this.langPlaceholderDisplayState[lang])
            } else {
                this.$set(this.langPlaceholderDisplayState, lang, true)
            }
        }
    },
    created() {
        if (this.translations) {
            this.translations_ = {...this.translations}
        }
    }
}
</script>

<style scoped>

</style>
