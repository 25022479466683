<template>
    <tr>
        <td style="width: 100%">
            <b-input v-model="values.name"></b-input>
            <validation-description :show="$v.values.name.$error">
                {{$t('FIELD_MANDATORY')}}
            </validation-description>

        </td>
        <td class="text-right">

            <app-button v-if="!edited"
                        @click="showDeleteDialog=true"
                        button_type="delete"
                        :show_text="false"
                        :disabled="disabled" :message="tooltip_message"
                        variant="link">
            </app-button>

            <app-button :disabled="disabled" :message="tooltip_message" :loading="loadingIcon" @click.native="submit" v-else></app-button>
        </td>
        <delete-confirm :show="showDeleteDialog" @confirm="deleteConfirm"
                        @cancel="showDeleteDialog=false" :delete_title="true"></delete-confirm>
    </tr>


</template>

<script>
    import AppButton from '@/components/app/AppButton/AppButton'
    import DeleteConfirm from '@/components/app/form/AppConfirmationDialog'
    import ValidationDescription from '@/components/app/form/AppValidationDescription'
    import {
        required, minLength
    } from 'vuelidate/lib/validators'


    export default {
        name: "contingent-item",
        props: {
            contingent: {
                type: Object
            },
            loading: {
                type: Number | Boolean,
                default: false
            },
            disabled:{
                type:Boolean,
                default:false
            },
            tooltip_message:{
                type:String
            }

        },
        data() {
            return {
                values: {
                    id: null,
                    name: null
                },
                showDeleteDialog: false,

            }
        },
        components: {
            AppButton,
            DeleteConfirm, ValidationDescription
        },
        computed: {
            loadingIcon() {
                return this.loading === this.contingent.id
            },
            edited() {
                if (this.values.name !== this.contingent.name) {
                    return true
                } else {
                    return false
                }
            }
        },
        methods: {
            submit() {
                this.$emit('updateContingent', this.values)
            },
            deleteConfirm() {
                this.$emit('deleteContingentUnit', this.values)
            },
        },
        validations: {
            values: {
                name: {
                    required,
                    minLength: minLength(3)
                }
            }
        },
        created() {
            this.values.id = this.contingent.id
            this.values.name = this.contingent.name
        }
    }
</script>

<style scoped>

</style>
