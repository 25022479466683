<template>
    <div v-if="(edit_id && policyLoaded) || !edit_id">
        <b-form @submit.prevent="save" autocomplete="off">
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <app-input-control :error-object="validationErrorObject.name">
                            <template v-slot:input="data">
                                <label>{{$t('NAME')}}</label>
                                <b-form-input v-model="cancellationPolicy.label"
                                              type="text"
                                              :state="data.state">
                                </b-form-input>
                            </template>
                        </app-input-control>
                    </b-form-group>
                </b-col>
            </b-row>
            <template v-if="!cancellationPolicy.system_policy || !cancellationPolicy.system_policy.id">
                <b-row>
                    <b-col sm="12">
                        <b-form-checkbox @change="updateFOC"
                                         :checked="cancellationPolicy.foc_cancelation"
                                         switch
                                         :value="1"
                                         :unchecked-value="0">
                            {{ $t('FOC_RESERVATION_CANCELATION') }}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row class="mt-4" v-if="system_policy && tagOptions.length > 0">
                    <b-col md="6" class="mb-3">
                        <label>{{$t('TAG')}}</label>
                        <app-select
                            v-model="cancellationPolicy.tag"
                            :options="tagOptions"
                            text-field="label"></app-select>
                    </b-col>
                </b-row>
                <b-row>
                    <app-access-control tag="b-col" class="mt-4"
                                        v-if="cancellationPolicy.id"
                                        :key="'toggle_custom_policy_text_enabled_' + cancellationPolicy.id"
                                        @AccessControlEvent="addToAccessControlCounter()"
                                        :access_control_context="{function: enableCustomTextFunction, key: access_control_fetch_key}">
                        <b-form-checkbox :checked="customTextEnabled"
                                         @change="setCustomTextEnabled"
                                         :value="true"
                                         :unchecked-value="false"
                                         switch>
                            {{ $t('CUSTOMIZE_POLICY_TEXT') }}
                        </b-form-checkbox>
                    </app-access-control>
                </b-row>
            </template>
            <cancellation-policy-text class="mt-4 mb-4"
                                      :policy-text="cancellationPolicyText"
                                      :policy-errors="cancellationPolicyErrors"
                                      :loading="textLoading"
                                      :custom-policy-text="cancellationPolicy.policy_text"
                                      :edit-disabled="Boolean(cancellationPolicy.system_policy && cancellationPolicy.system_policy.id)"
                                      :policy-name="cancellationPolicy.label"
                                      :show-update-text-message="updateCustomTextNotifyState"
                                      @customTextChanged="customTextChanged"
                                      ref="cancellation_policy_text">
            </cancellation-policy-text>
            <b-row class="mb-5">
                <b-col class="d-flex justify-content-between align-items-center">
                    <policy-save-button :loading="saving"
                                        :disabled="saveDisabled">
                    </policy-save-button>
                    <policy-delete @deletePolicy="$emit('deletePolicy', cancellationPolicy.id)"
                                   v-if="cancellationPolicy.id"
                                   :policy="cancellationPolicy">
                    </policy-delete>
                </b-col>
            </b-row>
        </b-form>
        <policy-condition-list v-if="cancellationPolicy.id || (cancellationPolicy.system_policy && cancellationPolicy.system_policy.id)"
                               ref="cancellation_condition_list"
                               :policy="cancellationPolicy"
                               :system_policy="system_policy"
                               :property_id="property_id"
                               @updateConditions="saveCondition"
                               @reloadText="reloadText">
        </policy-condition-list>

        <app-confirmation-dialog
            v-if="system_policy && cancellationPolicy.id"
            :show="systemPolicySaveConfirmationState"
            @confirm="confirmSystemPolicySave"
            @cancel="systemPolicySaveConfirmationState = false"
            :title="$t('SAVE_CONFIRMATION')">
            {{$t('SYSTEM_POLICY_LINKED_POLICIES_UPDATE_CONFIRMATION_MSG', {number: cancellationPolicy.linked_policies.length})}}
        </app-confirmation-dialog>

    </div>
</template>

<script>
import AppInputControl from "@/components/app/AppInputControl";
import PolicySaveButton from '@/components/app/AppButton/AppButtonSubmit'
import PolicyDelete from '@/components/property/cancelation_policies/policy/CancelationPolicyItemDelete'
import PolicyConditionList from '@/components/property/cancelation_policies/condition/CancelationConditionList'
import {notifySuccess, toast} from '@/shared/plugins/toastr'
import {createPropertyCancelationPolicy, updatePropertyCancelationPolicy} from "@/services/property";
import {createSystemCancellationPolicy, updateSystemCancellationPolicy} from "@/services/company";
import {
    CANCELLATION_POLICY_NAME_MIN_LENGTH,
    CANCELLATION_PREFERENCE_CUSTOM_TEXT_NOT_SET
} from "@/shared/error_codes";
import {
    fetchCancellationPolicy,
    fetchCancellationPolicyText
} from "@/services/cancellation_policy";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import CancellationPolicyText from "@/components/property/cancelation_policies/policy/CancellationPolicyText";
import AppTranslationInput from "@/components/app/translation/AppTranslationInput";
import {
    C_PROPERTY_CANCELLATION_POLICY_ENABLE_CUSTOM_TEXT,
    C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_ENABLE_CUSTOM_TEXT
} from "@/shared/component_permission";
import AppAccessControl from "@/components/app/AppAccessControl";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {AC_CANCELLATION_POLICY} from "@/mixins/AccessControl/AccessControlEnumeration";
import {getAllTags} from "@/services/tag";
import {TAG_TYPE_CANCELLATION_POLICY} from "@/shared/constants";

export default {
    name: "CancelationPolicyItemEdit",
    components: {
        AppAccessControl,
        AppTranslationInput,
        CancellationPolicyText,
        AppConfirmationDialog,
        AppSelect,
        AppInputControl,
        PolicyDelete,
        PolicySaveButton,
        PolicyConditionList
    },
    mixins: [getErrorMessage, AccessControlComponent],
    data() {
        return {
            validationErrorObject: {
                name: CANCELLATION_POLICY_NAME_MIN_LENGTH,
                customText: {
                    input: [CANCELLATION_PREFERENCE_CUSTOM_TEXT_NOT_SET]
                },
            },
            cancellationPolicy: {
                id: null,
                foc_cancelation: null,
                label: null,
                conditions: [],
                policy_text: null,
                system_policy: null,
                linked_policies: [],
                tag: null,
            },
            cancellationTag:{},
            freeCancellation: null,
            customTextEnabled: false,
            cancellationPolicyText: '',
            cancellationPolicyErrors: [],
            textLoading: false,
            saving: false,
            systemPolicySaveConfirmationState: false,
            policyLoaded: false,
            updateCustomTextNotifyState: false,
            enableCustomTextFunction: this.system_policy ? C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_ENABLE_CUSTOM_TEXT : C_PROPERTY_CANCELLATION_POLICY_ENABLE_CUSTOM_TEXT,
            access_control_fetch_key: AC_CANCELLATION_POLICY,
            access_control_components: 0,
            customFreeTextChanged: false,
            tagOptions: [],

        }
    },
    props: {
        edit_id: {
            type: Number,
            default: null
        },
        system_policy: {
            type: Boolean,
            default: false
        },
        property_id: {
            type: Number,
            default: null
        }
    },
    watch: {
        'cancellationPolicy.conditions': {
            handler() {
                if (this.cancellationPolicy.id !== null && this.cancellationPolicy.policy_text === null) {
                    let request = {policy_id: this.cancellationPolicy.id}
                    this.fetchPolicyText(request)
                }
            },
            deep: true,
            immediate: true,
        },
        cancellationPolicy: {
            handler(value) {
                if (value) {
                    this.access_control_components = ((value.system_policy !== undefined && value.system_policy !== null) && value.system_policy.id !== null) || value.id === null ? 0 : 1
                }
            },
            immediate: true,
        },
    },
    methods: {
        prepareCancellationPolicy(cancellationPolicy){
            if(cancellationPolicy && cancellationPolicy.hasOwnProperty('tag') && cancellationPolicy.tag){
                cancellationPolicy.tag = cancellationPolicy.tag.id
            }
            this.cancellationPolicy = cancellationPolicy
        },
        save() {
            if (this.system_policy && this.cancellationPolicy.id
                && (this.cancellationPolicy.foc_cancelation !== this.freeCancellation || this.customFreeTextChanged)
                && this.cancellationPolicy.linked_policies.length) {
                this.systemPolicySaveConfirmationState = true
            } else {
                let requestObject = {
                    label: this.cancellationPolicy.label,
                    foc_cancelation: this.cancellationPolicy.foc_cancelation,
                    tag: this.cancellationPolicy.tag
                }
                if (this.cancellationPolicy.id) {
                    if (this.customTextEnabled) {
                        let policyText = null
                        let cancellation_policy_text = this.$refs.cancellation_policy_text
                        if (cancellation_policy_text) {
                            policyText = cancellation_policy_text.$data.cancellationPolicyText
                        }
                        requestObject.policy_text = policyText
                    }
                }

                this.savePolicy(requestObject)
            }
        },
        updateFOC(e) {
            this.$set(this.cancellationPolicy, 'foc_cancelation', e)
        },
        saveCondition(policy) {
            this.$emit('savePolicy')
            this.prepareCancellationPolicy(policy)
        },
        fetchPolicyText(request) {
            this.textLoading = true
            fetchCancellationPolicyText(request).then(response => {
                this.cancellationPolicyText = response.data
                this.cancellationPolicyErrors = []
            }, error => {
                this.cancellationPolicyText = ''
                if (!error.response || !error.response.data) {
                    this.cancellationPolicyErrors = []
                }
                this.cancellationPolicyErrors = error.response.data.error_list
            }).finally(() => {
                this.textLoading = false
            })
        },
        reloadText(conditions_list) {
            if (this.cancellationPolicy.policy_text !== null) {
                this.updateCustomTextNotifyState = true
            }
            let request = {
                cancellation_condition_list: JSON.stringify(conditions_list),
                foc_cancelation: this.cancellationPolicy.foc_cancelation,
                validate_conditions: 1
            }
            this.fetchPolicyText(request)
        },
        confirmSystemPolicySave() {
            let requestObject = {
                label: this.cancellationPolicy.label,
                foc_cancelation: this.cancellationPolicy.foc_cancelation,
                tag: this.cancellationPolicy.tag
            }
            if (this.cancellationPolicy.id) {
                if (this.customTextEnabled) {
                    let policyText = null
                    let cancellation_policy_text = this.$refs.cancellation_policy_text
                    if (cancellation_policy_text) {
                        policyText = cancellation_policy_text.$data.cancellationPolicyText
                    }
                    requestObject.policy_text = policyText
                }
            }

            this.savePolicy(requestObject)
        },
        setCustomTextEnabled(event) {
            this.customTextEnabled = event
            let policyTextObject = null
            if (event) {
                this.textLoading = true
                policyTextObject = {
                    id: null,
                    label: this.cancellationPolicyText,
                    translations: {
                        en: null,
                    },
                }
                this.$set(this.cancellationPolicy, 'policy_text', policyTextObject)
                this.$nextTick(() => {
                    this.textLoading = false
                })
            } else {
                this.$set(this.cancellationPolicy, 'policy_text', policyTextObject)
                let conditions_list = []
                let cancellation_condition_list = this.$refs.cancellation_condition_list
                if (cancellation_condition_list) {
                    if (cancellation_condition_list.$refs.condition_items) {
                        cancellation_condition_list.$refs.condition_items.forEach(condition_item => {
                            let cancellation_condition_data = condition_item.$data.selected
                            if (cancellation_condition_data.deleted === 0) {
                                conditions_list.push(cancellation_condition_data)
                            }
                        })
                    }
                }
                let request = {
                    cancellation_condition_list: JSON.stringify(conditions_list),
                    foc_cancelation: this.cancellationPolicy.foc_cancelation,
                    validate_conditions: 1,
                }
                this.fetchPolicyText(request)
            }
        },
        savePolicy(request) {
            this.saving = true
            if (this.system_policy) {
                if (this.cancellationPolicy.id) {
                    updateSystemCancellationPolicy(this.company, this.cancellationPolicy.id, request).then(response => {
                        this.prepareCancellationPolicy(response.data)
                        this.freeCancellation = response.data.foc_cancelation
                        this.cancellationTag = response.data.tag
                        this.customTextEnabled = response.data.policy_text !== null
                            && response.data.policy_text.hasOwnProperty('id')
                            && response.data.policy_text.id !== null
                        this.$emit('savePolicy')
                        notifySuccess()
                    }, error => {
                        this.showErrorMessages(error, this.validationErrorObject)
                    }).finally(() => {
                        this.saving = false
                        this.systemPolicySaveConfirmationState = false
                    })
                } else {
                    createSystemCancellationPolicy(this.company, request).then(response => {
                        this.prepareCancellationPolicy(response.data)
                        this.freeCancellation = response.data.foc_cancelation
                        this.cancellationTag = response.data.tag
                        this.customTextEnabled = response.data.policy_text !== null
                            && response.data.policy_text.hasOwnProperty('id')
                            && response.data.policy_text.id !== null
                        this.$emit('savePolicy')
                        notifySuccess()
                    }, error => {
                        this.showErrorMessages(error, this.validationErrorObject)
                    }).finally(() => {
                        this.saving = false
                    })
                }
            } else {
                let propertyId = (this.property_id) ? this.property_id : this.$route.params.id
                if (this.cancellationPolicy.id) {
                    updatePropertyCancelationPolicy(propertyId, this.cancellationPolicy.id, request).then(response => {
                        this.cancellationPolicy = response.data
                        this.freeCancellation = response.data.foc_cancelation
                        this.cancellationTag = response.data.tag
                        this.customTextEnabled = response.data.policy_text !== null
                            && response.data.policy_text.hasOwnProperty('id')
                            && response.data.policy_text.id !== null
                        this.$emit('savePolicy')
                        notifySuccess()
                    }, error => {
                        this.showErrorMessages(error, this.validationErrorObject)
                    }).finally(() => {
                        this.saving = false
                    })
                } else {
                    createPropertyCancelationPolicy(propertyId, request).then(response => {
                        this.cancellationPolicy = response.data
                        this.freeCancellation = response.data.foc_cancelation
                        this.cancellationTag = response.data.tag
                        this.customTextEnabled = response.data.policy_text !== null
                            && response.data.policy_text.hasOwnProperty('id')
                            && response.data.policy_text.id !== null
                        this.$emit('savePolicy')
                        notifySuccess()
                    }, error => {
                        this.showErrorMessages(error, this.validationErrorObject)
                    }).finally(() => {
                        this.saving = false
                    })
                }
            }
        },
        customTextChanged() {
            this.updateCustomTextNotifyState = false
            this.customFreeTextChanged = true
        },
    },
    computed: {
        saveDisabled() {
            return this.cancellationPolicy.label === null || this.cancellationPolicy.label === ''
        },
        company() {
            return this.$store.getters[`user/getCompany`]
        },
    },
    created() {
        if (this.edit_id) {
            this.policyLoaded = false
            fetchCancellationPolicy(this.edit_id).then(response => {
                this.prepareCancellationPolicy(response.data)
                this.freeCancellation = response.data.foc_cancelation
                this.cancellationTag = response.data.tag
                this.customTextEnabled = response.data.policy_text !== null
                    && response.data.policy_text.hasOwnProperty('id')
                    && response.data.policy_text.id !== null
            }).finally(() => {
                this.policyLoaded = true
            })
        } else {
            this.cancellationPolicy.foc_cancelation = 0
            this.freeCancellation = 0
        }
        this.access_control_general_context = {
            company: this.company
        }
        getAllTags(TAG_TYPE_CANCELLATION_POLICY,{company: this.company}).then(response => {
            this.tagOptions = response.data
        })
    }
}
</script>

<style scoped>

</style>
