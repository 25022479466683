<template>
    <div class="app flex-row align-items-center">
        <div class="container">
            <b-row class="justify-content-center">
                <b-col md="6">
                    <b-card-group>
                        <b-card no-body class="p-0">
                            <b-card-header class="pl-0">
                                <span>{{$t('EASE_OF_BUSINESS')}}</span>
                                <h1>{{$t('FORGOT_PASSWORD')}}</h1>
                                <p> {{$t('ENTER_EMAIL_RESET')}}</p>
                            </b-card-header>
                            <b-card-body class="p-0">
                                <b-form autocomplete="off"  @submit.prevent="sendMail">
                                    <b-form-group class="mb-3">
                                        <app-input-control :error-object="validationErrorObject.email">
                                            <template v-slot:input="data">
                                                <b-input-group>
                                                    <b-input-group-prepend>
                                                        <b-input-group-text><i>@</i></b-input-group-text>
                                                    </b-input-group-prepend>
                                                    <b-form-input
                                                        type="text"
                                                        :state="data.state"
                                                        :placeholder="$t('EMAIL')"
                                                        v-model.trim="email">
                                                    </b-form-input>

                                                </b-input-group>
                                            </template>
                                        </app-input-control>
                                    </b-form-group>
                                    <b-row>
                                        <b-col cols="6">
                                            <app-submit :disabled="maintenance" button-class="button-min-width" :loading="show">{{$t('SEND')}}</app-submit>

                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-4">
                                        <b-col>
                                            <b-alert variant="danger" :show="maintenance">
                                                {{$t('MAINTENANCE_MESSAGE')}}
                                            </b-alert>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-3">
                                        <b-col v-if="showMessage" class="noty_close_with_button">
                                            <div role="alert" class="alert alert-success">
                                                <h5>{{$t('NOTIFICATIONS.RESET_PASSWORD.TITLE')}}</h5>
                                                <span data-notify="message">{{$t('NOTIFICATIONS.RESET_PASSWORD.MESSAGE')}}</span>
                                            </div>
                                        </b-col>
                                        <b-col v-if="showError">

                                            <div v-if="errorCode === 1000" role="alert" class="alert alert-danger">
                                                <span data-notify="message">{{$t('ACCOUNT_NOT_ACTIVE')}}</span>
                                            </div>
                                            <div v-else role="alert" class="alert alert-danger">
                                                <span data-notify="message">{{errorMessage}}</span>
                                            </div>

                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-card-body>
                        </b-card>
                    </b-card-group>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>

    import {required, email} from 'vuelidate/lib/validators'
    import {userPasswordReset} from "@/services/access";
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import AppValidationDescription from "@/components/app/form/AppValidationDescription";
    import {
        USER_VALIDATION_FAILED_EMAIL,
        USER_EMAIL_REQUIRED
    } from "@/shared/error_codes";
    import AppInputControl from "@/components/app/AppInputControl";
    import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";

    export default {
        name: "forget-password",
        components: {
            AppSubmit,
            AppInputControl,
            AppValidationDescription,
        },
        validations:{
            email:{
                required, email
            }
        },
        mixins:[getErrorMessage],
        data(){
            return{
                maintenance:false,
                email:null,
                show: false,
                label: 'Logging in...',
                overlay: true,
                message:"",
                showError:false,
                errorMessage:null,
                showMessage:false,
                errorCode:null,
                validationErrorObject: {
                    email: {
                        input: [USER_VALIDATION_FAILED_EMAIL, USER_EMAIL_REQUIRED]
                    }
                }
            }
        },
        methods:{
            sendMail(){
                this.show = true
                this.showMessage = false

                userPasswordReset({email:this.email,lang:this.$route.query.lang}).then(()=>{
                    this.show = false
                    this.email = null
                    this.showMessage = true
                    this.showError = false


                }, error=>{
                    this.handleErrorMessage(error)
                    this.show = false
                    this.showMessage = false
                })

            },
            handleErrorMessage(error){
                if(error.response.data.error_list.length){
                    for (let errorObject of error.response.data.error_list){
                        if(errorObject.code === USER_VALIDATION_FAILED_EMAIL.code || errorObject.code === USER_EMAIL_REQUIRED.code){
                         return
                        }
                        this.showError = true
                        this.errorCode = errorObject.code
                        this.errorMessage = errorObject.message
                    }
                }
            }
        },

        created(){
            if(this.$route.query.lang  === 'sl'){
                this.$i18n.locale = 'si'
            }  else  {
                this.$i18n.locale = this.$route.query.lang  ? this.$route.query.lang   : 'en'
            }
        },
        beforeRouteEnter(to, from, next) {
            document.body.classList.add('page-login');
            next()
        }
    }
</script>

<style scoped>

</style>
