<template>
    <b-form @submit.prevent="save" v-if="parameter">
        <b-row>
            <b-col>
                <app-button @click="addNewItem()" button_type="new"
                            class="pull-right">
                    {{ $t('NEW_ITEM') }}
                </app-button>
            </b-col>
        </b-row>
        <div v-for="(param,index) in parameter.complex_object.objects">
            <!--            <app-button-delete v-if="index>0" @click="unsetParam(param,index)" button_type="new"-->
            <!--                               class="pull-right">-->
            <!--            </app-button-delete>-->
            <reservation-process-data-transition-form :parameter="param" @resetData="resetValue(param, index)" @delete="unsetParam(param,index)"
                                                      :template="parameter.complex_object.template"></reservation-process-data-transition-form>
        </div>
        <app-button-submit :loading="saving"></app-button-submit>
    </b-form>
</template>

<script>

import {
    getDataTransitionParameter,
    saveReservationParameter
} from "@/services/accommodation_reservation";
import {notifySuccess} from "@/shared/plugins/toastr";
import {cloneDeep} from "lodash";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";

export default {
    name: "ReservationProcessDataTransition",
    components: {
        "AppButtonDelete": () => import("@/components/app/AppButton/AppButtonDelete"),
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "ReservationProcessDataTransitionForm": () => import("@/components/reservation/setup/ReservationProcessDataTransitionForm"),
        "AppNoData": () => import("@/components/app/AppNoData"),
        "AppButtonSubmit": () => import("@/components/app/AppButton/AppButtonSubmit"),
        "DistributionReservationParameterItem": () => import("@/components/channel_manager/distribution/DistributionReservationParameterItem")
    },
    mixins: [getErrorMessage],
    props: {
        parameter_id: {
            type: Number,
            default: null
        },
        owner_id: {
            type: Number,
            default: null
        },
        property_id: {
            type: Number,
            default: null
        },
        company_id: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            parameter: null,
            saving: false,
            event: null
        }
    },
    methods: {
        addNewItem() {
            let item = cloneDeep(this.parameter.complex_object.template)
            this.parameter.complex_object.objects.push(item[Object.keys(item)[0]])
        },
        resetValue(param, index) {
            let event = param.condition.event.value
            let item = cloneDeep(this.parameter.complex_object.template[event])
            item.condition.event.value = param.condition.event.value
            this.$set(this.parameter.complex_object.objects, index, cloneDeep(item))
        },
        unsetParam(item, index) {
            this.parameter.complex_object.objects.splice(index, 1)
        },
        save() {
            this.saving = true
            let params = cloneDeep(this.parameter)

            let paramsObject = params.complex_object
            if (paramsObject && paramsObject.type === 'custom') {
                let value = {}
                Object.entries(paramsObject.objects).map(([key, val]) => {
                    if (val && val.value !== undefined && val.value !== null) {
                        value[key] = val.value
                    } else {
                        value[key] = val ? val.value ? val.value : null : null
                    }
                })
                params.value_text = JSON.stringify(value)
            }
            if (paramsObject && paramsObject.type === 'composite') {
                let array = []
                for (const param of paramsObject.objects) {
                    let value = {}
                    Object.entries(param).map(([key, val]) => {
                        if (val && val.value !== undefined && val.value !== null) {
                            value[key] = val.value
                        } else {
                            value[key] = val ? val.value ? val.value : null : null
                        }
                    })
                    array.push(value)
                }
                params.value_text = JSON.stringify(array)
            }
            if (paramsObject && paramsObject.type === 'composite_custom') {
                let array = []
                for (const param of paramsObject.objects) {
                    let value = {}
                    Object.entries(param).map(([key, val]) => {
                        value[key] = {}
                        Object.entries(val).map(([subKey, subVal]) => {
                            if (subVal.hasOwnProperty('type') && subVal.type && subVal.type === 'custom') {
                                value[key][subKey] = {}
                                Object.entries(subVal.objects).map(([customKey, customVal]) => {
                                    value[key][subKey][customKey] = customVal ? customVal.value ? customVal.value : null : null
                                })
                            } else {
                                if (subVal && subVal.value !== undefined && subVal.value !== null) {
                                    value[key][subKey] = subVal.value
                                } else {
                                    value[key][subKey] = subVal ? subVal.value ? subVal.value : null : null
                                }
                            }
                        })
                    })
                    array.push(value)
                }
                params.value_text = JSON.stringify(array)
            }
            params.complex_object = null

            saveReservationParameter({item: params}).then(() => {
                notifySuccess()
                this.getReservationsParameter()
                this.$emit('saved')
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                    this.saving = false
                }
            )
        },
        getReservationsParameter() {
            let req = {
                owner_id: this.owner_id,
                property_id: this.property_id,
                company_id: this.company_id,
            }

            getDataTransitionParameter(this.parameter_id, req).then(response => {
                this.parameter = response.data
            })
        },
    },
    created() {
        this.getReservationsParameter()
    }
}
</script>

<style scoped>

</style>
