<template>
    <b-form @submit.prevent="save" v-if="tag.id">
        <b-row>
            <b-col class="mb-4">
                <app-input-control :error-object="validationObject.name">
                    <template v-slot:input="data">
                        <app-translation-input
                            :label="$t('NAME')"
                            :context-label="$t('CITY')"
                            :languages="tag.languages"
                            :lang_id="tag.lang_id"
                            v-model="tag.label"
                            :state="data.state"
                            :width="['col-lg-4','col-sm-12', '40%']"
                            @saved="refresh"
                        ></app-translation-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-4">
                <app-image-input
                    :image="tag.image.id"
                    :preview="tag.image.url"
                    @input="tag.image.id = $event"
                    :enable-delete="checkPermission(C_SETUP_TAG_E)"
                    class="pl-0 pr-0"></app-image-input>
            </b-col>
        </b-row>
        <b-row class="mb-4">
            <b-col>
                <app-button-submit v-if="checkPermission(C_SETUP_TAG_E)" :loading="saving"></app-button-submit>
            </b-col>
            <b-col>
                <app-button-delete
                    v-if="checkPermission(C_SETUP_TAG_E)"
                    @click="deleteItem"
                    class="pull-right">
                </app-button-delete>
            </b-col>
        </b-row>
        <app-confirmation-dialog
            :show="confirmation_dialog_state"
            @confirm="continueAction"
            @cancel="cancelAction"
            :title="$t('DELETE_TITLE')">
            <p>{{ $t('DELETE_MESSAGE', {name}) }}</p>
        </app-confirmation-dialog>
        <!--        <app-aside v-model="translate_sidebar" v-if="tag.lang_id">-->
        <!--            <template slot="header">-->
        <!--                <div class="header-caption">{{ $t('CITY') }}</div>-->
        <!--                <h2 class="property-wrapper-padding mb-2">-->
        <!--                    {{ tag.label }}-->
        <!--                </h2>-->
        <!--                <div class="header-info">{{ $t('NAME') }}</div>-->
        <!--            </template>-->
        <!--            <translate-template v-if="tag.lang_id" :languages="tag.languages" :lang_id="tag.lang_id"-->
        <!--                                @saved="saved"></translate-template>-->
        <!--        </app-aside>-->
    </b-form>
</template>

<script>
import AppTranslationInput from "@/components/app/translation/AppTranslationInput";
import {deleteTag, getTag, updateTag} from "@/services/tag";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
import AppImageInput from "@/components/app/AppImageInput";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {createDocument, deleteDocument} from "@/services/document";
import {TYPE_TAG_PHOTO} from "@/shared/constants";
import AppButton from "@/components/app/AppButton/AppButton";
import {notifySuccess} from "@/shared/plugins/toastr";
import AppAside from "@/components/app/form/AppAside";
import {C_SETUP_TAG_E} from "@/shared/component_permission";
import AppInputControl from "@/components/app/AppInputControl";
import {TAG_ALREADY_EXIST, TAG_LABEL_VALIDATION_FAIL} from "@/shared/error_codes";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {EventBus, GE_TABLE_REFRESH_SEARCH} from "@/shared/EventBus";
import {uploadOrFetchWebsiteDocument} from "@/services/direct_booking/website";
import _cloneDeep from "lodash/cloneDeep";

export default {
    name: "CityTag",
    mixins: [getErrorMessage],
    components: {
        AppInputControl,
        AppButton, AppImageInput, AppButtonDelete, AppButtonSubmit, AppTranslationInput, AppAside,
        AppConfirmationDialog
    },
    props: {
        tag_id: {
            type: Number,
            default: null,
        }
    },
    data() {
        return {
            C_SETUP_TAG_E,
            tag: {
                image: {
                    url: null
                }
            },
            initialTagData: null,
            systemLanguageList: null,
            saving: false,
            translate_sidebar: false,
            confirmation_dialog_state: false,
            name: null,
            validationObject: {
                name: {
                    input: [TAG_ALREADY_EXIST, TAG_LABEL_VALIDATION_FAIL],
                },
            }
        }
    },
    watch: {
        tag(value) {
            this.initialTagData = _cloneDeep(value)
        }
    },
    methods: {
        save() {
            this.saving = true

            let imagePromise = Promise.resolve()

            if (this.tag.image.id && this.initialTagData.image.id !== this.tag.image.id) {
                const data = new FormData()
                data.append('document', this.tag.image.id)
                imagePromise = uploadOrFetchWebsiteDocument(this.tag_id, TYPE_TAG_PHOTO, data)
            }

            if (!this.tag.image.id && this.initialTagData.image.id) {
                imagePromise = deleteDocument(this.tag.id, TYPE_TAG_PHOTO, this.initialTagData.image.id)
            }
            imagePromise.then(() => {
                return updateTag(this.tag.id, this.tag)
            }).then(() => {
                notifySuccess()
                this.$emit('saved')
            }).catch(error => {
                this.showErrorMessages(error, this.validationObject)
            }).finally(() => this.saving = false)
        },
        saved() {
            this.$emit('saved')
        },
        deleteItem() {
            this.confirmation_dialog_state = true
        },
        continueAction() {
            const deleteImagePromise = this.tag.image.id
                ? deleteDocument(this.tag.id, TYPE_TAG_PHOTO, this.initialTagData.image.id).then(() => this.tag.image.id = null)
                : Promise.resolve()

            return deleteImagePromise.then(() => {
                return deleteTag(this.tag.id)
            }).then(() => {
                notifySuccess()
                this.saving = false
                this.$emit('saved')
                this.confirmation_dialog_state = false
            }).catch(error => {
                this.saving = false
                this.confirmation_dialog_state = false
                this.showErrorMessages(error)
            })
        },
        cancelAction() {
            this.confirmation_dialog_state = false
        },
        getTag() {
            return getTag(this.tag_id).then(response => {
                this.tag = response.data
                this.name = response.data.label
                this.tag.languages = Object.values(response.data.languages)
            }, error => {
                this.showErrorMessages(error)
            })
        },
        edit() {
            this.translate_sidebar = true;
        },
        refresh() {
            EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
        }
    },
    mounted() {
        this.getTag()
    },
}
</script>

<style scoped>

</style>
