import http from '../http'
import {ACTIVITY_RESERVATION_URL} from "@/services/endpoints";

export function getActivityReservationList(params) {
    return http.request({
        url: ACTIVITY_RESERVATION_URL,
        method: 'GET',
        params
    })
}

export function downloadActivityConfirmationPdf(id, pdf_hash) {
    return http.request({
        url: 'adminapi/activity-reservation/'+ id + '/voucher-pdf?hash=' + pdf_hash,
        method: 'GET',
        responseType: 'blob',
    })
}
