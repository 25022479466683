<template>
    <b-col>
        <b-table small :fields="fields" :items="icalLinks">
            <template v-slot:link_name="data">
                <span class="mr-5">{{data.item.link_name}}</span>
            </template>
            <template v-slot:link_url="data">
                <span>{{data.item.link_url}}</span>
            </template>
            <template v-slot:sync="data">
                <div :id="'import_button_' + data.item.order_number" class="mr-1">
                    <app-button class="ml-2"
                                icon="refresh"
                                @click="importIcal(data.item.order_number, data.item)"
                                :show_text="false"
                                :loading="data.item.loading"
                                variant="link"
                    ></app-button>
                </div>
            </template>
            <template v-slot:last_manual="data">
                <span>{{data.item.last_manually_imported_at}}</span>
            </template>
            <template v-slot:last_automatic="data">
                <span>{{data.item.last_imported_at}}</span>
            </template>
        </b-table>
    </b-col>
</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";
import {pullIcalFeed} from "@/services/channel_manager";
import {notifySuccess} from "@/shared/plugins/toastr";
export default {
    name: "IcalImportLinks",
    components:{AppButton},
    props:{
        icalLinks:{
            type: Array,
            default: []
        },
        unit_id:{
            type: Number,
            default: null
        },
    },
    data(){
        return{
            fields: [
                {key: 'link_name', label: this.$t('FEED_NAME')},
                {key: 'link_url', label: this.$t('FEED_URL'), thStyle: {width: '70em !important'}},
                {key: 'last_manual', label: this.$t('LAST_MANUAL_IMPORT')},
                {key: 'last_automatic', label: this.$t('LAST_AUTOMATIC_IMPORT')},
                {key: 'sync', label:'',  class: 'text-right'}
            ],
        }
    },
    methods:{
        importIcal(order_number, ical){
            ical.loading = true
            pullIcalFeed({unit: this.unit_id, order_number: order_number}).then(response => {
                notifySuccess()
                ical.loading = false
            }, error => {
                this.showErrorMessages(error)
                ical.loading = false
            })
        },
    },
}
</script>

<style scoped>

.maxTableWidth{
    max-width: 2em;
}

</style>
