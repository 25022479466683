<template>
    <b-modal style="overflow-y: auto;" v-model="show" no-close-on-esc no-close-on-backdrop hide-header-close>
        <template v-if="this.title !== null || delete_title" slot="modal-header">
            <h5 class="modal-title" v-if="delete_title">{{ $t('DELETE_CONFIRMATION') }}</h5>
            <h5 class="modal-title" v-else>{{ this.title }}</h5>
            <button type="button" aria-label="Close" class="close" @click="cancelEvent">×</button>
        </template>

        <slot>
            <span class="font-weight-bold" v-if="message">{{ this.message }}</span>
            <span class="font-weight-bold" v-else>{{ $t('DELETE_DEFAULT_MSG') }}</span>
        </slot>

        <div slot="modal-footer">
            <slot name="modal-footer">
                <app-button :variant="variant" @click="confirmEvent" :loading="loading">{{ $t('CONFIRM') }}</app-button>
                <app-button class="mr-2 ml-2 pl-2" @click="cancelEvent">{{ $t('CANCEL') }}</app-button>
            </slot>
        </div>
    </b-modal>
</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";

export default {
    name: "AppConfirmationDialog",
    components: {AppButton},
    props: {
        show: {
            required: true,
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        },
        delete_title: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: null
        },
        id: {
            default: null
        },
        variant: {
            type: String,
            default: 'primary'
        }
    },
    data() {
        return {
            loading: false
        }
    },
    watch: {
        show(value) {
            // Reset button state on same modal re-enter
            if (value) {
                this.loading = false
            }
        },
    },
    mounted() {
        // Close modal with 'esc' key
        document.addEventListener("keydown", (e) => {
            if (e.keyCode == 27) {
                this.cancelEvent()
            }
        });
    },
    methods: {
        confirmEvent() {
            this.loading = true
            this.$emit('confirm', this.id)
        },
        cancelEvent() {
            this.$emit('cancel', this.id)
        }
    },
}
</script>

<style scoped>
.close:focus,
.close:active,
.close:focus {
    outline: none;
    box-shadow: none;
}
</style>
