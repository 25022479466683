<template>
    <b-form @submit.prevent="save">
        <div class="mb-4">
            <label>{{ $t("TYPE") }}</label>
            <app-input-control :error-object="validationObject.tag_type">
                <template v-slot:input="data">
                    <app-select v-model="tagType" :options="tagTypeOptions" text-field="label" :search-empty-item="false"></app-select>
                </template>
            </app-input-control>
        </div>
        <div class="mb-4">
            <label>{{ $t("NAME") }}</label>
            <app-input-control :error-object="validationObject.name">
                <template v-slot:input="data">
                    <b-input v-model="label" :state="data.state"></b-input>
                </template>
            </app-input-control>
        </div>
        <div class="mb-4">
            <app-translation-list :language-list="languageList" @update="updateTranslations">
            </app-translation-list>
        </div>
        <app-button-submit :loading="saving" ref="saveButton"></app-button-submit>
    </b-form>
</template>

<script>
import {saveCustomTags, getTagType} from "@/services/tag";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {getTranslateList} from "@/services/system";
import {TAG_ALREADY_EXIST, TAG_LABEL_VALIDATION_FAIL, TAG_TYPE_VALIDATION_FAIL} from "@/shared/error_codes";

export default {
    name: "NewCustomTag",
    components: {
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
        "AppButtonSubmit": () => import("@/components/app/AppButton/AppButtonSubmit"),
        "AppButtonDelete": () => import("@/components/app/AppButton/AppButtonDelete"),
        "AppAside": () => import( "@/components/app/form/AppAside"),
        "AppInputControl": () => import( "@/components/app/AppInputControl"),
        "AppTranslationInput": () => import( "@/components/app/translation/AppTranslationInput"),
        "AppTranslationList": () => import( "@/components/app/translation/AppTranslationList")
    },
    mixins: [getErrorMessage],
    data() {
        return {
            saving: false,
            deleting: false,
            translate_sidebar: false,
            label: null,
            languageList: null,
            translations: null,
            tagType: null,
            tagTypeOptions: null,
            validationObject: {
                name: {
                    input: [TAG_ALREADY_EXIST, TAG_LABEL_VALIDATION_FAIL],
                },
                tag_type: {
                    input: [TAG_ALREADY_EXIST, TAG_TYPE_VALIDATION_FAIL],
                },
            }
        }
    },
    methods: {
        save() {
            this.saving = true
            let tagList = []
            let tagObject = {
                label: this.label,
                tag_type_id: this.tagType,
                company_id: this.company.id,
                parent_id: null,
                translations: this.translations,
                deleted: 0
            }

            tagList.push(tagObject)

            saveCustomTags({list: tagList}).then(() => {
                notifySuccess()
                this.$emit('saved')
            }).catch(error => {
                this.showErrorMessages(error, this.validationObject)
            }).finally(() => this.saving = false)
        },
        saved() {
            this.$emit('saved')
        },
        getTranslateList() {
            getTranslateList().then(response => {
                this.languageList = response.data
            })
        },
        updateTranslations(value) {
            this.translations = {...value}
        },
        getTagTypes(){
            getTagType().then( response => {
                this.tagTypeOptions = response.data
            })
        }
    },
    mounted() {
        this.getTranslateList()
        this.getTagTypes()
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
}
</script>

<style scoped>

</style>
