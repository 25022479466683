<template>
    <form @submit.prevent="search" class="filter" autocomplete="off">
        <b-row>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t('NAME') }}</label>
                <b-input v-model="filter.name"
                         type="text"
                ></b-input>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t('TYPE') }}</label>
                <app-select v-model="filter.property_type"
                            :options="property_type_options"
                            mode="multiselect"
                            text-field="label"
                ></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("COUNTRY") }}</label>
                <app-select v-model="country"
                            mode="search"
                            :search="searchCountry"
                            :search-clear="true"
                ></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("CITY") }}</label>
                <app-select v-model="city"
                            mode="search"
                            :search="searchCity"
                            :search-clear="true"
                ></app-select>
            </b-col>

            <app-access-control tag="b-col" xl="2" lg="4" md="6" class="mb-3"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{function: C_PROPERTY_LIST_FILTER_STATUS_V, company: company.id, key: AC_PROPERTY_FILTER}">
                <app-select v-model="filter.property_status"
                            :options="status_list"
                            mode="multiselect"
                            text-field="label"
                            :label="$t('STATUS')"
                ></app-select>
            </app-access-control>

            <b-col xl="2" lg="3" md="6" class="mb-3">
                <label>{{ $t("FILTER") }}</label>
                <app-select v-model="rm_activate"
                            :options="condition_options"
                            mode="multiselect"
                            text-field="text"
                            value-field="value"
                ></app-select>
            </b-col>

            <b-col>
                <app-button-submit class="filter-submit-wrapper"
                                   :inline="true"
                                   :loading="loadingSubmit"
                                   :disabled="loading"
                                   variant="primary"
                                   button_type="search"
                >{{ $t("SEARCH") }}</app-button-submit>

                <app-button-reset class="pl-2 ml-2"
                                  :inline="true"
                                  :loading="loadingReset"
                                  :disabled="loading"
                                  @click="resetForm"
                ></app-button-reset>
            </b-col>
        </b-row>
    </form>

</template>

<script>
import AppSubmit from "@/components/app/AppSubmit";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {getPropertyTypeList} from "@/services/property";
import {getCityList, getCountries} from "@/services/location";
import {pickBy} from "lodash";
import AppAccessControl from "@/components/app/AppAccessControl";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {C_PROPERTY_LIST_FILTER_STATUS_V} from "@/shared/component_permission";
import {AC_PROPERTY_FILTER} from "@/mixins/AccessControl/AccessControlEnumeration";
import {fetchStatusList} from "@/services/status";
import {OBJECT_TYPE_PROPERTY, STATUS_PROPERTY_COOPERATION_COMPLETED} from "@/shared/constants";

export default {
    name: "RevenueManagerSetupPropertyFilter",
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    mixins: [AccessControlComponent],
    components: {AppAccessControl, AppButtonSubmit, AppSelect, AppButtonReset, AppSubmit},
    data() {
        return {
            C_PROPERTY_LIST_FILTER_STATUS_V,
            AC_PROPERTY_FILTER,
            filter: {
                name: null,
                property_type: [],
                rm_rates_active: null,
                rm_min_stay_active: null,
                property_status: []
            },
            rm_activate: [],
            city: {
                id: null,
                name: null
            },
            country: {
                id: null,
                name: null
            },
            loadingSubmit: false,
            loadingReset: false,
            property_type_options: [],
            status_list: [],
            condition_options: [
                {value: 1, text: this.$t("ACTIVE_FOR_RATES")},
                {value: 2, text: this.$t("INACTIVE_FOR_RATES")},
                {value: 3, text: this.$t("ACTIVE_FOR_MIN_STAY")},
                {value: 4, text: this.$t("INACTIVE_FOR_MIN_STAY")},
                // {value: 5, text: this.$t("RM_UNIT_NOT_ACTIVE_RATES")},
                // {value: 6, text: this.$t("RM_UNIT_NOT_ACTIVE_MIN_STAY")},
            ],
            reset: 0,
            access_control_fetch_key: AC_PROPERTY_FILTER
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        excludedPropertyStatusList() {
            let excludedList = []
            this.status_list.forEach(status => {
                if (status.id !== STATUS_PROPERTY_COOPERATION_COMPLETED) {
                    excludedList.push(status.id)
                }
            })
            return excludedList
        },
    },
    methods: {
        search() {
            this.reset = 0
            if (this.rm_activate.length > 0 && this.rm_activate.length < this.condition_options.length) {
                if (this.rm_activate.includes(1)) {
                    this.filter.rm_rates_active = 1
                } else if (this.rm_activate.includes(2)) {
                    this.filter.rm_rates_active = 0
                } else {
                    this.filter.rm_rates_active = null
                }

                if (this.rm_activate.includes(3)) {
                    this.filter.rm_min_stay_active = 1
                } else if (this.rm_activate.includes(4)) {
                    this.filter.rm_min_stay_active = 0
                } else {
                    this.filter.rm_min_stay_active = null
                }
            } else {
                this.filter.rm_rates_active = null
                this.filter.rm_min_stay_active = null
            }
            if (this.city.id) {
                this.filter = {...this.filter, ...{city: this.city.id}}
            }
            if (this.country.id) {
                this.filter = {...this.filter, ...{country: this.country.id}}
            }
            this.$emit('search', pickBy(this.filter, item => item !== null))
        },
        resetForm() {
            this.reset = 1
            this.country = {
                id: null,
                name: null
            }
            this.city = {
                id: null,
                name: null
            }
            this.filter = {
                name: null,
                property_type: [],
                rm_rates_active: null,
                rm_min_stay_active: null,
                property_status: []
            }
            this.rm_activate = []
            this.$emit('search', this.filter)
        },
        searchCountry(value) {
            return getCountries({
                name: value
            })
        },
        searchCity(value) {
            return getCityList({
                name: value,
                country: this.country.id
            })
        },
    },
    watch: {
        loading: {
            handler(value) {
                if (this.reset) {
                    this.loadingReset = value
                } else {
                    this.loadingSubmit = value
                }
            }
        },
        'country.id': {
            handler(value) {
                if (value === null) {
                    this.filter = {...this.filter, ...{country: null}}
                } else {
                    if (this.city.id && (!this.city.hasOwnProperty('country') || !this.city.country || !this.city.country.hasOwnProperty('id') || this.city.country.id !== value)) {
                        this.city = {
                            id: null,
                            name: null
                        }
                        this.filter = {...this.filter, ...{city: null}}
                    }
                }
            }
        },
        'city.id': {
            handler(value) {
                if (value === null) {
                    this.filter = {...this.filter, ...{city: null}}
                }
            }
        },
    },
    created() {
        getPropertyTypeList().then(response => {
            this.property_type_options = response.data
        }, () => {
            this.property_type_options = []
        })
        fetchStatusList(OBJECT_TYPE_PROPERTY).then(response => {
            this.status_list = response.data
            this.filter.property_status = this.excludedPropertyStatusList
        }, () => {
            this.status_list = []
            this.filter.property_status = []
        })
    }
}
</script>

<style scoped>

</style>
