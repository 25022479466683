<template>
    <div>
        <app-table-v2 :provider="getData" :fields="fields"
                      refresh-event="refreshColorCategoryTable" :search-on-create="true">
            <template v-slot:edit="data">
                <app-button variant="link"
                            button_type="edit"
                            @click="openEditCategory(data.item)"
                            :show_text="false">
                </app-button>
            </template>
            <template v-slot:colors="data">
                <div class="d-flex">
                    <div v-for="color in data.item.colors">
                        <b-tooltip :target="'color_'+color.id"
                                   placement="top"
                                   :title="color.hex"></b-tooltip>
                        <div :style="'background-color:'+color.hex+';'" class="color-pallet ml-1" :id="'color_'+color.id"></div>
                    </div>
                </div>
            </template>
        </app-table-v2>
        <app-aside v-model="newCategoryAside">
            <template slot="header"><app-object-header :name="$t('NEW_CATEGORY')"></app-object-header></template>
            <template v-slot="props">
                <appearance-new-color-category @category-saved="closeNewCategory"></appearance-new-color-category>
            </template>
        </app-aside>
        <app-aside v-model="editCategoryAside">
            <template slot="header"><app-object-header :name="$t('EDIT_CATEGORY')"></app-object-header></template>
            <template v-slot="props">
                <appearance-edit-color-category-form :category="categoryObject" @save-edit-category="closeEditCategory"></appearance-edit-color-category-form>
            </template>
        </app-aside>
    </div>
</template>

<script>

import AppTableV2 from "@/components/app/AppTableV2";
import AppButton from "@/components/app/AppButton/AppButton";
import AppAside from "@/components/app/form/AppAside";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import AppearanceNewColorCategory from "@/components/appearance/color_category/AppearanceNewColorCategory";
import AppearanceEditColorCategoryForm from "@/components/appearance/color_category/AppearanceEditColorCategoryForm";
import {getPaginatedThemeCategories} from "@/services/appearance";
import {EventBus} from "@/shared/EventBus";

export default {
    name: "AppearanceColorCategoryTable",
    components:{AppTableV2, AppButton, AppAside, AppObjectHeader, AppearanceNewColorCategory, AppearanceEditColorCategoryForm},
    data(){
        return{
            fields:[
                {key: 'id', label: this.$t('ID'),},
                {key: 'label', label: this.$t('NAME')},
                {key: 'colors', label: this.$t('COLORS')},
                {key: 'edit', label: '', class: 'text-right'}
            ],
            newCategoryAside: false,
            editCategoryAside: false,
            categoryObject:[]
        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods:{
        getData(){
            let req = {
                ...{
                    // page: filter.currentPage,
                    // perPage: filter.perPage,
                    // order_by: filter.sortBy,
                    company_id: this.company.id
                }
            }
            return getPaginatedThemeCategories(req)
        },
        openEditCategory(data){
            this.categoryObject = data
            this.editCategoryAside = true
        },
        closeNewCategory(){
            this.newCategoryAside = false
            EventBus.$emit('refreshColorCategoryTable')
        },
        closeEditCategory(){
            this.editCategoryAside = false
            EventBus.$emit('refreshColorCategoryTable')
        }
    },
    created() {
    },
    mounted() {
        EventBus.$on('AddNewCategory', () => {
            this.newCategoryAside = true
        })
    }
}
</script>

<style scoped>

.color-pallet{
    height:25px;
    width:25px;
    border-radius:15px;
    border:1px solid #ececec;
}

</style>
