<template>
    <div class="availability_calendar--cell d-flex align-items-center justify-content-center">
        <span class="availability_calendar-year" :class="!isOverflown ? 'mb-3' : ''">{{currentYear}}</span>

    </div>
</template>

<script>

    import {C_UNIT_CALENDAR_YEARLY_VIEW_V} from "@/shared/component_permission";

    export default {
        name: "CalendarYearCell",
        props: {
            dateList: {
                type: Array
            },
            scrollLeftPosition: {
                type: Number
            },
            isOverflown: {
                type:Boolean,
                default:false
            }
        },
        data() {
            return {
                currentYear: "2020",
            }
        },
        computed: {
            pixelsWide() {
                return 2.87 * 16
            },
            currentIndex() {
                return parseInt(this.scrollLeftPosition / this.pixelsWide)
            },
            classList() {
                if (this.checkPermission(C_UNIT_CALENDAR_YEARLY_VIEW_V)) {
                    return ['main_calendar-color_action', 'main_calendar--poiner']
                }
                return 'text-muted'
            }
        },
        methods: {
            calculateYear() {
                if (this.dateList.length > 0 && this.dateList.indexOf(this.currentIndex) === -1) {
                    let currYearMoment = this.dateList[this.currentIndex]

                    this.currentYear = currYearMoment ? parseInt(currYearMoment.format("YYYY")) : "2020"
                }
            }
        },
        watch: {
            scrollLeftPosition: {
                handler() {
                    this.calculateYear()
                },
                immediate: true
            },
            dateList:{
                handler(dates){
                    this.calculateYear()
                },deep:true
            }
        }
    }
</script>

<style scoped>

</style>
