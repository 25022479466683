<template>
    <div>
        <owner-reservation-setup :setup_parameter="SETUP_PARAMETER_DATA_TRANSITION_AFTER_EVENT"></owner-reservation-setup>
    </div>
</template>

<script>

import {SETUP_PARAMETER_DATA_TRANSITION_AFTER_EVENT} from "@/shared/constants";

export default {
    name: "Index",
    components: {
        "OwnerReservationSetup": () => import("@/components/owner/OwnerReservationSetup"),
    },
    data() {
        return {
            SETUP_PARAMETER_DATA_TRANSITION_AFTER_EVENT
        }
    },
}
</script>

<style scoped>

</style>
