<template>
    <b-form @submit.prevent="save">
        <b-row class="mb-4">
            <b-col>
                <label>{{ $t('NAME') }}</label>
                <b-input v-model="formData.label"></b-input>
            </b-col>
        </b-row>
        <b-row class="mb-4">
            <b-col>
                <app-translation-list :language-list="translateList"
                                      @update="updateTranslationsList"></app-translation-list>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button-submit :loading="saving"></app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import AppTranslationList from "@/components/app/translation/AppTranslationList";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {saveAmenity} from "@/services/direct_booking/website";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {getTranslateList} from "@/services/system";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";

    export default {
        name: "NewWebsiteAmenity",
        mixins: [getErrorMessage],
        components: {AppButtonSubmit, AppTranslationList},
        props: {
            websiteId: {
                type: Number,
                default: null
            },
            categoryId: {
                type: Number,
                default: null
            },
        },
        data() {
            return {
                formData: {
                    label: null,
                    translations: {}
                },
                categories: {},
                saving: false
            }
        },
        methods: {
            save() {
                this.saving = true
                let list = {}
                for (const [key, value] of Object.entries(this.formData.translations)) {
                    list[key] = value
                }

                this.formData.translations = list

                saveAmenity(this.websiteId, this.categoryId, JSON.stringify(this.formData)).then(response => {
                    notifySuccess()
                    this.$emit('saved')
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => this.saving = false)
            },
            updateTranslationsList(data) {
                this.formData.translations = data;
            },
        },
        computed:{
            website(){
                return this.$store.getters['website/getWebsite']
            },
            translateList() {
                return this.website.supported_languages.filter(el => el !== "en")
            }
        }
    }
</script>

<style scoped>

</style>
