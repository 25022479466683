<template>
    <div>
        <app-table-v2 :fields="computedFields"
                      :selectable="true"
                      :provider="getData" :show-limit="false"
                      @busyToggled="toggleBusy" :search-event="SEARCH_EVENT">
            <template v-slot:name="data">
                {{`${data.item.name} ${data.item.last_name}`}}
            </template>

            <template v-slot:type="data">
                <small class="d-block text-muted" v-if="data.item.invoicee_type.id">
                    {{data.item.invoicee_type.label}}
                </small>
                {{data.item.partner_type.name}}
            </template>

            <template v-slot:actions="data">
                <app-button variant="link" icon="edit" :show_text="false" @click="$emit('edit', data.item)"></app-button>
            </template>

            <template v-slot:selectedContainer="data">
                <app-button @click="openCopySidebar(data)"
                            class="mr-3">
                    {{$t('COPY')}}
                </app-button>

            </template>

        </app-table-v2>

        <app-aside :widths="['col-lg-4', 'col-sm-12', '30%']" v-model="copyClientSidebar">
            <template slot="header">
                {{ $t('COPY_PARTNER_TITLE') }}
                <h4 class="header-info" v-if="selectedClients.length > 1">{{$t("ITEMS_SELECTED", {value: selectedClients.length})}}</h4>
                <h4 class="header-info" v-else-if="selectedClients.length === 1">{{$t("ITEM_SELECTED")}}</h4>
            </template>

            <slot name="content">
                <b-form>
                    <template v-for="(selectedClient, index) in selectedClients">
                        <b-row>
                            <b-col xl="12" md="12" class="mb-4 font-weight-bold">{{ $t('ITEM') }} #{{ index + 1 }}</b-col>
                            <b-col xl="6" md="6" class="mb-4">
                                <label>{{ $t('FIRST_NAME') }}</label>
                                <b-input type="text" v-model="selectedClient.name"></b-input>
                            </b-col>
                            <b-col xl="6" md="6" class="mb-4">
                                <label>{{ $t('LAST_NAME') }}</label>
                                <b-input type="text" v-model="selectedClient.last_name"></b-input>
                            </b-col>
                        </b-row>
                        <hr>
                    </template>
                    <b-row>
                        <b-col xl="6" md="6" class="mb-4">
                            <app-button variant="primary" @busyToggled="toggleBusy" @click="copyClients">{{$t('COPY')}}</app-button>
                        </b-col>
                    </b-row>
                </b-form>
            </slot>
        </app-aside>
    </div>
</template>

<script>
    import AppTableV2 from "@/components/app/AppTableV2";
    import {getPartnerList, duplicatePartners} from "@/services/finance/partner";
    import {EventBus} from '@/shared/EventBus'
    import {cloneDeep} from "lodash";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {C_SETUP_FINANCE_PARTNERS_E} from "@/shared/component_permission";
    import AppAside from "@/components/app/form/AppAside";
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'

    export default {
        name: "PartnerList",
        components: {
            AppButton,
            AppTableV2,
            AppAside
        },
        mixins: [getErrorMessage],
        data() {
            return {
                SEARCH_EVENT: 'getPartners',
                fields: [
                    {key: 'id', label: this.$t('ID')},
                    {key: 'name', label: this.$t('NAME')},
                    {key: 'type', label: this.$t('TYPE')},
                    {key: 'city.label', label: this.$t('CITY')},
                    {key: 'address', label: this.$t('ADDRESS')},
                    {key: 'oib', label: this.$t('PERSONAL_ID_NUMBER')},
                    {key: 'iban', label: this.$t('IBAN')},
                    {key: 'email', label: this.$t('EMAIL')},
                    {
                        key: 'actions',
                        label: '',
                        class: 'ws-no-wrap text-right',
                        hasPermission: () => (this.checkPermission(C_SETUP_FINANCE_PARTNERS_E))
                    },
                ],
                filter: null,
                copyClientSidebar: false,
                selectedClients: [],
                req: null
            }
        },
        computed: {
            computedFields() {
                return this.fields.filter(f => !f.hasPermission || f.hasPermission())
            },
            company() {
                return this.$store.getters['user/getCurrentCompany']
            }
        },
        methods: {
            getData(req) {
                if (req) {
                    this.req = req
                } else {
                    req = this.req
                }
                return getPartnerList({
                    ...this.filter,
                    page: req.currentPage,
                    limit: req.perPage,
                    company: this.company.id,
                    order_by: 'id',
                    order_by_direction: 'DESC',
                })
            },
            toggleBusy($event) {
                EventBus.$emit('loading', $event)
            },
            openCopySidebar(selectedData) {
                this.selectedClients = []
                this.copyClientSidebar = true
                selectedData.selected.forEach(item => {
                    let formItem = {
                        id: item.id,
                        name: item.name,
                        last_name: item.last_name
                    }
                    this.selectedClients.push(formItem)
                })
            },
            async copyClients() {
                await duplicatePartners({
                    company: this.company.id,
                    partners: this.selectedClients,
                }).then(() => {
                    this.getData()
                    EventBus.$emit(this.SEARCH_EVENT)
                    this.copyClientSidebar = false
                }, (error) => {
                    this.showErrorMessages(error)
                })
            }
        },
        mounted() {
            EventBus.$on('searchPartners', filter => {
                if (filter) {
                    this.filter = cloneDeep(filter)
                }
                EventBus.$emit(this.SEARCH_EVENT)
            })
        },
        beforeDestroy() {
            EventBus.$off('searchPartners')
        }
    }
</script>

<style scoped>

</style>
