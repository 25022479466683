<template>
    <b-form @submit.prevent="save">
        <b-row>
            <b-col cols="12" :xl="inModal ? 12 : 4" class="mb-4">
                <app-input-control :error-object="validation.path">
                    <template v-slot:input="data">
                        <label>{{ $t('ROUTE') }}</label>
                        <b-input-group prepend="/">
                            <b-form-input
                                :disabled="pageData && !pageData.editable.path"
                                v-model="formData.path"
                                :state="data.state">
                            </b-form-input>
                        </b-input-group>
                    </template>
                </app-input-control>
            </b-col>

            <b-col cols="12" :xl="inModal ? 12 : 4" class="mb-4">
                <app-input-control :error-object="validation.title">
                    <template v-slot:input="data">
                        <app-translation-input
                            :label="$t('TITLE')"
                            :context-label="$t('PAGE')"
                            :state="data.state"
                            v-model="formData.title"
                            :lang_id="pageData && pageData.title.lang_id"
                            :languages="website.supported_languages">
                        </app-translation-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>

        <form-header>Meta</form-header>

        <b-row>
            <b-col cols="12" :xl="inModal ? 12 : 4" class="mb-4">
                <app-input-control>
                    <template v-slot:input="data">
                        <app-translation-input
                            :label="$t('DESCRIPTION')"
                            :context-label="$t('PAGE')"
                            component="textarea"
                            v-model="formData.description"
                            :lang_id="pageData && pageData.description.lang_id"
                            :languages="website.supported_languages"
                            :state="data.state">
                        </app-translation-input>
                    </template>
                </app-input-control>
            </b-col>

            <b-col cols="12" :xl="inModal ? 12 : 4" class="mb-4">
                <app-input-control>
                    <template v-slot:input="data">
                        <app-translation-input
                            :label="$t('KEYWORDS')"
                            :context-label="$t('PAGE')"
                            component="textarea"
                            v-model="formData.keywords"
                            :lang_id="pageData && pageData.keywords.lang_id"
                            :languages="website.supported_languages"
                            :state="data.state">
                        </app-translation-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <app-button-submit :loading="loading"></app-button-submit>
            </b-col>

            <b-col class="d-flex justify-content-end">
                <app-button-delete
                    v-if="pageData && pageData.deletable"
                    @click="deleteDialogState = true">
                </app-button-delete>

                <app-confirmation-dialog
                    :show="deleteDialogState"
                    @confirm="_delete"
                    :delete_title="true"
                    @cancel="deleteDialogState=false">
                </app-confirmation-dialog>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {DBWE_PAGE_PATH, DBWE_PAGE_PATH_ALREADY_EXISTS, DBWE_PAGE_TITLE} from "@/shared/error_codes";
import routeNames from "@/router/routeNames";

export default {
    name: "WebsitePageGeneralForm",
    mixins: [getErrorMessage],
    components: {
        "AppTranslationInput": () => import("@/components/app/translation/AppTranslationInput"),
        "AppConfirmationDialog": () => import("@/components/app/form/AppConfirmationDialog"),
        "AppButtonDelete": () => import("@/components/app/AppButton/AppButtonDelete"),
        "AppButtonSubmit": () => import("@/components/app/AppButton/AppButtonSubmit"),
        "FormHeader": () => import("@/components/app/form/FormHeader"),
        "AppInputControl": () => import("@/components/app/AppInputControl")
    },
    props: {
        pageData: {
            type: Object,
            default: null,
        },
        inModal: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            formData: {
                path: null,
                title: null,
                description: null,
                keywords: null,
            },
            validation: {
                path: {
                    input: [DBWE_PAGE_PATH],
                    toast: [DBWE_PAGE_PATH_ALREADY_EXISTS]
                },
                title: DBWE_PAGE_TITLE,
            },
            loading: false,
            deleteDialogState: false,
        }
    },
    computed: {
        website() {
            return this.$store.getters['website/getWebsite']
        }
    },
    methods: {
        save() {
            this.loading = true
            const pagePromise = this.pageData
                ? this.$store.dispatch('website/actionUpdateCurrentPage', this.formData)
                : this.$store.dispatch('website/actionCreatePage', this.formData)
            pagePromise.then(response => {
                notifySuccess()
                if (this.inModal) {
                    this.$emit('close')
                }

                if (!this.pageData) {
                    this.$router.push({
                        name: routeNames.RN_DIRECT_BOOKING_WEBSITE_PAGE,
                        params: {page_id: response.data.id}
                    })
                }
            }, error => {
                this.showErrorMessages(error, this.validation)
            }).finally(() => this.loading = false)
        },
        _delete() {
            this.loading = true
            this.$store.dispatch('website/actionDeletePage').then(() => {
                notifySuccess()
                this.$router.replace({name: routeNames.RN_DIRECT_BOOKING_WEBSITE_PAGES})
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.loading = false
                this.deleteDialogState = false
            })
        }
    },
    watch: {
        pageData: {
            immediate: true,
            handler(page) {
                if (!page) return
                this.formData.path = page.path
                this.formData.title = page.title.value
                this.formData.description = page.description.value
                this.formData.keywords = page.keywords.value
            }
        }
    }
}
</script>

<style scoped>

</style>
