<template>
    <b-card class="main-section-card">
        <div slot="header">
            <h2>{{ $t("PAYMENT_MESSAGES") }}</h2>
        </div>
        <b-form>
            <b-row>
                <b-col md="4">
                    <b-form-file
                        v-model="xmlFile"
                        :state="xmlFile ? true : null"
                        accept=".mt"
                        :placeholder="$t('UPLOAD_TEXT')"
                        :drop-placeholder="$t('DROP')">
                    </b-form-file>
                </b-col>
                <b-col>
                    <app-button @click="uploadFile" :disabled="!xmlFile" :loading="uploading">{{ $t('UPLOAD') }}
                    </app-button>
                </b-col>
            </b-row>
        </b-form>

        <payment-message-list-table @openPaymentMessage="openPaymentMessage"></payment-message-list-table>

        <app-aside v-model="paymentMessageAsideState"
                   :widths="['col-lg-12','col-sm-12', '100%']"
                   :header-classes="[]" full-width>
            <template slot="header">{{ $t('PAYMENT_MESSAGE') }}: {{ paymentMessageId }}</template>
            <payment-message :payment-message-id="paymentMessageId"
                             :payment-message-status="paymentMessageStatus"
                             @updateMessageNumber="updateMessageNumber"
            ></payment-message>
        </app-aside>
    </b-card>
</template>

<script>
import PaymentMessageListTable from "@/components/finance/payment_message/PaymentMessageListTable";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import AppButton from "@/components/app/AppButton/AppButton";
import {fileHelpers} from "@/mixins/shared/helpers";
import {uploadPaymentMessage} from "@/services/finance/payment_message";
import {EventBus, GE_LOADER_HIDE, GE_LOADER_SHOW} from "@/shared/EventBus";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {notifySuccess} from "@/shared/plugins/toastr";
import AppAside from "@/components/app/form/AppAside";
import PaymentMessage from "@/components/finance/payment_message/PaymentMessage";

export default {
    name: "Index",
    components: {PaymentMessage, AppButton, PaymentMessageListTable, AppAside},
    mixins: [fileHelpers, getErrorMessage],
    data() {
        return {
            xmlFile: null,
            uploading: false,
            paymentMessageId: null,
            paymentMessageAsideState: false,
            paymentMessageStatus: null,
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    methods: {
        updateMessageNumber() {
            EventBus.$emit('refreshPaymentMessageList')
        },
        uploadFile() {
            this.uploading = true
            let formData = new FormData();
            formData.append('statement', this.xmlFile)
            uploadPaymentMessage(formData).then(() => {
                this.xmlFile = null
                notifySuccess()
                EventBus.$emit('searchPaymentMessages')
            })
                .catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => this.uploading = false)
        },
        openPaymentMessage(item) {
            this.paymentMessageId = item.id
            this.paymentMessageStatus = item.status.id
            this.paymentMessageAsideState = true
        }
    }
}
</script>

<style scoped>

</style>
