<template>
    <div>
        <b-card no-body v-if="formData">
            <b-card-header header-class="border-bottom-0 d-flex align-items-center">
                <div>
                    <span class="header-2">{{ $t("DETAILS") }}</span>
                </div>

                <app-button variant="link" icon="edit" class="action_button ml-3"
                            v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_E)"
                            @click="editFinanceData" :show_text="false">
                </app-button>

            </b-card-header>
            <b-card-body class="read-only">
                <div class="items">
                    <div v-for="group in financeDetails">
                        <div class="item" v-for="item in group.items">
                            <div :class="item.style === 'bold' ? 'item-label font-weight-bold' : 'item-label'">
                                {{ item.text }}
                                <span class="item-label-sm"
                                      v-if="!!formData && formData.hasOwnProperty(item.type) && !!formData[item.type] && Number(formData[item.type].type) === PERC">
                                    ({{ roundDecimal(formData[item.type].value) }} %)
                            </span>:
                            </div>
                            <div class="item-content text-right"
                                 v-if="!!formData && formData.hasOwnProperty(item.value) && !!formData[item.value]">
                                <span>
                                    {{ formData[item.value].channel }} {{ formData.channel_currency.code }}
                                </span>
                                <span v-if="differentCurrency">
                                    {{ formData[item.value].company }} {{ company.main_currency.code }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <template v-if="formData.note">
                    <form-header v-if="checkPermission(C_RESERVATION_ACCOMMODATION_CHARGE_PROFILE)">
                        {{ $t('NOTES') }}
                    </form-header>
                    <div class="items">
                        <div class="item">
                            <div class="item-label">{{ $t("NOTE") }}:</div>
                            <div class="item-content" v-html="stripTags(formData.note, ['br'])"></div>
                        </div>
                    </div>
                </template>
                <template
                    v-if="formData.cancellation_preference.id !== null && checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_CANCELLATION_POLICY_V)">
                    <form-header>{{ $t('CANCELLATION_POLICY') }}
                        <app-button variant="link" icon="edit" class="action_button ml-3 mr-3"
                                    v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_CANCELLATION_POLICY_E)"
                                    @click="editCancellationPolicy" :show_text="false">
                        </app-button>
                        <app-button
                            v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_CANCELLATION_POLICY_APPLY)"
                            @click="applyPolicyAside = true" button_type="new" class="pull-right">
                            {{ $t('APPLY_POLICY') }}
                        </app-button>
                    </form-header>
                    <div class="items">
                        <div class="item" v-if="checkPermission(C_RESERVATION_ACCOMMODATION_CHARGE_PROFILE)">
                            <div class="item-label">{{ $t("NAME") }}:</div>
                            <div class="item-content">{{ formData.cancellation_preference.label }}</div>
                        </div>

                        <div class="item">
                            <div class="item-label">{{ $t("NOTE") }}:</div>
                            <div class="item-content" v-html="formData.cancellation_preference.description"></div>
                        </div>
                    </div>
                </template>
                <template
                    v-else-if="formData.cancellation_preference.id === null && checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_CANCELLATION_POLICY_V)">
                    <form-header>{{ $t('CANCELLATION_POLICY') }}
                    </form-header>
                    <div class="d-flex justify-content-center">
                        <app-button
                            v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_CANCELLATION_POLICY_APPLY)"
                            @click="applyPolicyAside = true" button_type="new">{{ $t('APPLY_POLICY') }}
                        </app-button>
                        <app-button
                            v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_CANCELLATION_POLICY_APPLY)"
                            @click="editCancellationPolicy" button_type="new" class="ml-3">{{ $t('NEW_POLICY') }}
                        </app-button>

                    </div>
                </template>
            </b-card-body>

            <app-aside :widths="['col-lg-4']" v-model="applyPolicyAside">
                <template v-slot:header>
                    {{ $t('APPLY_SYSTEM_POLICY_SIDEBAR_TITLE') }}
                </template>
                <cancellation-policy-apply-form @policySaved="reload" :reservation_id="reservation.id"
                                                :property_id="reservation.property.id"></cancellation-policy-apply-form>

            </app-aside>

            <app-aside v-model="financePanelState">
                <template slot="header">
                    {{ $t('EDIT') }}
                </template>
                <finance-details-edit-form
                    :reservation="reservation"
                    @close="financePanelState = false"
                    @update="updateData">
                </finance-details-edit-form>
            </app-aside>

            <app-aside v-model="cancellationPolicyAside">
                <template v-slot:header>
                    <template v-if="formData.cancellation_preference.id === null">
                        {{ $t("NEW_POLICY_SIDEBAR_TITLE") }}
                    </template>
                    <template v-else>
                        {{ $t("EDIT_POLICY_SIDEBAR_TITLE") }}
                    </template>
                </template>

                <cancellation-policy @policySaved="reload" :reservation_id="reservation.id"
                                     :edit_id="formData.cancellation_preference.id"
                                     :property_id="reservation.property.id">
                </cancellation-policy>
            </app-aside>
        </b-card>

    </div>

</template>

<script>
import {fetchAccommodationReservationFinance, getReservationVisibilitySetup} from "@/services/accommodation_reservation";
import {quillEditor} from 'vue-quill-editor'
import AppButton from "@/components/app/AppButton/AppButton";
import AppAside from "@/components/app/form/AppAside";
import {
    C_RESERVATION_ACCOMMODATION_FINANCE_E,
    C_RESERVATION_ACCOMMODATION_CHARGE_PROFILE,
    C_RESERVATION_ACCOMMODATION_FINANCE_CANCELLATION_POLICY_APPLY,
    C_RESERVATION_ACCOMMODATION_FINANCE_CANCELLATION_POLICY_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_CANCELLATION_POLICY_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_DETAILED_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_TOTAL_GROSS_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_TOTAL_NETT_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_OWNER_PART_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_COMMISSION_TOTAL_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_TOTAL_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_VAT_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_TOTAL_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_FIXED_AGENCY_COMMISSION_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_VAT_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_VAT_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_TRANSACTION_FEE_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_PLATFORM_FEE_V
} from "@/shared/component_permission";
import FormHeader from "@/components/app/form/FormHeader";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import CancellationPolicy from '@/components/reservation/accommodation/forms/CancellationPolicyForm';
import AppSelect from "@/components/app/AppSelect/AppSelect";
import CancellationPolicyApplyForm from "@/components/reservation/accommodation/forms/CancellationPolicyApplyForm";
import AppAccessControl from "@/components/app/AppAccessControl";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {AC_ACCOMMODATION_RESERVATION_FINANCE} from "@/mixins/AccessControl/AccessControlEnumeration";
import FinanceDetailsEditForm from "@/components/reservation/accommodation/forms/FinanceDetailsEditForm";
import {DIRECT_BOOKER_COMPANY,
    DISPLAY_SETUP_LOCATION_FINANCE_DETAILS,
    DISPLAY_SETUP_RESERVATION,
    DISPLAY_SETUP_RESERVATION_GROUPS, OBJECT_TYPE_ACCOMMODATION_RESERVATION} from '@/shared/constants';
import {PERC} from '@/shared/constants';
import {textFormatters} from "@/mixins/shared/helpers";

export default {
    name: "FinanceModule",
        components: {
            FinanceDetailsEditForm,
            AppAccessControl,
            CancellationPolicyApplyForm,
            AppSelect, FormHeader, AppButton, quillEditor, AppAside,CancellationPolicy},
        mixins: [getErrorMessage,AccessControlComponent, textFormatters],
        props: {
            reservation: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                formData: null,
                financeDetailsData: null,
                financeDetailsAllowed: [],
                C_RESERVATION_ACCOMMODATION_CHARGE_PROFILE,
                financePanelState: false,
                cancellationPolicyAside:false,
                C_RESERVATION_ACCOMMODATION_FINANCE_E,
                applyPolicyAside:false,
                C_RESERVATION_ACCOMMODATION_FINANCE_CANCELLATION_POLICY_APPLY,
                C_RESERVATION_ACCOMMODATION_FINANCE_CANCELLATION_POLICY_E,
                C_RESERVATION_ACCOMMODATION_FINANCE_CANCELLATION_POLICY_V,
                access_control_fetch_key: AC_ACCOMMODATION_RESERVATION_FINANCE,
                C_RESERVATION_ACCOMMODATION_FINANCE_DETAILED_V,
                PERC,
                DIRECT_BOOKER_COMPANY,
                C_RESERVATION_ACCOMMODATION_FINANCE_TOTAL_GROSS_V,
                C_RESERVATION_ACCOMMODATION_FINANCE_TOTAL_NETT_V,
                C_RESERVATION_ACCOMMODATION_FINANCE_OWNER_PART_V,
                C_RESERVATION_ACCOMMODATION_FINANCE_COMMISSION_TOTAL_V,
                C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_TOTAL_V,
                C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_V,
                C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_VAT_V,
                C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_TOTAL_V,
                C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_V,
                C_RESERVATION_ACCOMMODATION_FINANCE_FIXED_AGENCY_COMMISSION_V,
                C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_V,
                C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_VAT_V,
                C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_V,
                C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_VAT_V,
                C_RESERVATION_ACCOMMODATION_FINANCE_TRANSACTION_FEE_V,
                C_RESERVATION_ACCOMMODATION_FINANCE_PLATFORM_FEE_V
            }
        },
        computed: {
            financeDetails() {
                let show_list = []
                DISPLAY_SETUP_RESERVATION.forEach((displaySetup) => {
                    if (displaySetup.locations.includes(DISPLAY_SETUP_LOCATION_FINANCE_DETAILS) && this.financeDetailsAllowed.includes(displaySetup.value)) {
                        const listGroup = DISPLAY_SETUP_RESERVATION_GROUPS.find(listItem => listItem.value === displaySetup.group)
                        let listGroupIndex = show_list.findIndex(listItem => listItem.value === displaySetup.group)
                        if (listGroup && !(listGroupIndex > -1)) {
                            show_list.push({
                                value: listGroup.value,
                                text: this.$t(listGroup.text),
                                items: [],
                            })
                            listGroupIndex = show_list.findIndex(listItem => listItem.value === displaySetup.group)
                        }
                        if (listGroupIndex > -1 && show_list.hasOwnProperty(listGroupIndex)) {
                            show_list[listGroupIndex].items.push({
                                value: displaySetup.value,
                                text: this.$t(displaySetup.text),
                                style: displaySetup.style,
                                type: displaySetup.type
                            })
                        }
                    }
                })
                return show_list
            },
            company() {
                return this.$store.getters['user/getCurrentCompany'];
            },
            differentCurrency() {
                return this.company.main_currency.id !== this.formData.channel_currency.id
            },
        },
        methods: {
            roundDecimal(value){
             const roundValue = Number(value).toFixed(2)
              return roundValue
            },
            reload(){
                this.applyPolicyAside = false
                this.cancellationPolicyAside = false;
                this.$emit('reloadScheduler')
                this.$emit("reloadReservation")
                this.setData()
            },
            editFinanceData() {
                this.financePanelState = true
            },
            editCancellationPolicy(){
              this.cancellationPolicyAside = true
            },
            updateData(data) {
                if (this.formData.payment_status_id !== data.payment_status_id || this.formData.rate_plan.id !== data.rate_plan.id) {
                    this.$emit('reloadSetup')
                }
                if (this.formData.rate_plan.id !== data.rate_plan.id) {
                    this.$emit('reloadScheduler')
                }
                this.formData = data
                this.$emit('financialDetailsUpdated', data)
                this.$emit("reloadReservation")
                this.financePanelState = false
            },
            setData(){
                fetchAccommodationReservationFinance(this.reservation.id).then(response => {
                    this.formData = response.data
                }, error => {
                    this.showErrorMessages(error)
                })
            }
        },
        watch: {
          reservation: {
              deep: true,
              handler(reservationObject) {
                  fetchAccommodationReservationFinance(reservationObject.id).then(response => {
                      this.formData = response.data
                  }, error => {
                      this.showErrorMessages(error)
                  })
              }
          },
        },
        created() {
            this.setData()
            this.access_control_general_context = {
                company: this.company.id,
                reservation: this.reservation.id
            }
            getReservationVisibilitySetup(OBJECT_TYPE_ACCOMMODATION_RESERVATION).then(response => {
                this.financeDetailsAllowed = response.data
            }, error => this.showErrorMessages(error))
        }
    }
</script>

<style scoped>
    .item-label {
        white-space: nowrap;
    }

    .item-content > span {
        display: inline-block;
        min-width: 100px;
    }
</style>
