<template>
    <div>
        <div class="dashboard-wrapper">
            <b-card>
                <b-row>
                    <b-col md="10" lg="10" class="d-flex align-items-start flex-column">
                        <div class="header-2 mb-2"
                             style="min-height: 54px;">
                            <b-img style="width: 54px;"
                                   :src="distribution.icon"
                                   alt="logo">
                            </b-img>
                            <span class="ml-3">{{ distribution.name }}</span>
                        </div>
                    </b-col>
                    <b-col md="2" lg="2"
                           class="bl-lg-show pl-lg-3 pt-3  pt-lg-0 d-flex flex-column justify-content-end">
                        <app-button
                            class="pl-4 mb-4"
                            variant="primary"
                            :to="{name: routeNames.RN_SETUP_FEES_PLATFORM_FEE_DISTRIBUTION, params: {distribution_id: distribution.id}}">
                            {{ $t("SETTINGS") }}
                        </app-button>
                        <app-button
                            class="pl-4"
                            variant="primary"
                            @click="openSidePanel">
                            {{ $t("EDIT") }}
                        </app-button>
                    </b-col>
                </b-row>
            </b-card>
        </div>
        <app-aside v-model="sidePanelState"
                   :widths="['col-lg-12','col-sm-12', '100%']"
                   full-width>
            <template slot="header">
                <app-object-header
                    :name="distribution.name"
                    :label="$t('DISTRIBUTION')">
                </app-object-header>
            </template>
            <template v-if="channelList.length" slot="header_action_button">
                <div class="aside__header--action_button col-4 mr-3">
                    <label class="label">{{ $t('SALES_CHANNEL') }}</label>
                    <app-select
                        mode="select"
                        :options="channelList"
                        v-model="selectedChannel"
                        value-field="id"
                        text-field="label"
                        :search-empty-item="false">
                    </app-select>
                </div>
            </template>
            <platform-fee-edit-form
                :distribution-id="selectedChannel ? null : distribution.id"
                :channel-id="selectedChannel"
                :channel-manager-distribution-id="selectedChannel ? distribution.id : null"
                preset-current-year
                :object-id="company.id"
                :object-type-id="OBJECT_TYPE_COMPANY">
            </platform-fee-edit-form>
        </app-aside>
    </div>
</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppAside from "@/components/app/form/AppAside";
import PlatformFeeEditForm from "@/components/fee/platform_fee/PlatformFeeEditForm";
import {DISTRIBUTION_MANAGERS, OBJECT_TYPE_COMPANY} from "@/shared/constants";
import routeNames from "@/router/routeNames";
import {getDistributionChannelList} from "@/services/distribution";

export default {
    name: "PlatformFeeDistributionItem",
    components: {
        PlatformFeeEditForm,
        AppSelect,
        AppObjectHeader,
        AppButton,
        AppAside,
    },
    props: {
        distribution: {
            type: Object,
            required: true,
            validator: (value) => {
                return value &&
                    value.hasOwnProperty("icon") && value.icon && typeof value.icon === "string" &&
                    value.hasOwnProperty("name") && value.name && typeof value.name === "string"
            },
        },
        company: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            routeNames,
            OBJECT_TYPE_COMPANY,
            sidePanelState: false,
            channelList: [],
            selectedChannel: null
        }
    },
    methods: {
        openSidePanel() {
            this.sidePanelState = true
        },
        getChannelList() {
            getDistributionChannelList({distribution_id: this.distribution.id}).then((response) => {
                this.channelList = response.data
                this.selectedChannel = this.channelList[Object.keys(this.channelList)[0]].id
            })
        },
    },
    created() {
        if (DISTRIBUTION_MANAGERS.includes(this.distribution.id)) {
            this.getChannelList()
        }
    }
}
</script>

<style scoped>

</style>
