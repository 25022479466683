<template>
    <div>
        <promo-codes-filter @search="setFilter" :loading="loading" :website-table="true"></promo-codes-filter>
        <website-promo-code-table @busyToggled="toggleBusy" :filter="filter"></website-promo-code-table>
    </div>
</template>

<script>
import WebsitePromoCodeTable from "@/components/direct_booking/website/promo_codes/WebsitePromoCodeTable";
import PromoCodesFilter from "@/components/direct_booking/promo_codes/list/PromoCodesFilter";

export default {
    name: "Index",
    components:{WebsitePromoCodeTable, PromoCodesFilter},
    data(){
        return{
            filter: null,
            loading: false
        }
    },
    methods:{
        setFilter(values) {
            this.filter = values
        },
        toggleBusy($event) {
            this.loading = $event
        }
    }
}
</script>

<style scoped>

</style>
