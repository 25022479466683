<template>
    <!-- Primjer dashboarda -->
    <div class="dashboard-wrapper dashboard-wrapper--gray">
        <div class="content">
            <div>
                <!-- Dashboard car type 1 -->
                <div class="row">
                    <b-col xl="4" md="3">
                    <b-card class="card-stats"
                            header-class="d-flex justify-content-between align-items-baseline low">
                        <template v-slot:header>
                            <span class="card-title">Expenses</span>
                            <b-dropdown variant="link" no-caret>
                                <template v-slot:button-content>
                                    <i class="mr-1 fa fa-cog fa-fw"></i>
                                </template>
                                <b-dropdown-item-button>Action</b-dropdown-item-button>
                                <b-dropdown-item-button>Another action</b-dropdown-item-button>
                                <b-dropdown-item-button>Something else here</b-dropdown-item-button>
                            </b-dropdown>

                        </template>
                        <b-row>
                            <b-col>
                                <div class="numbers">
                                    <p class="header-2">Yearly</p>
                                    <div class="header-1">€77,392.04 <span
                                        class="change_indicator positive">12%</span>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col xl="4" md="3">
                    <b-card class="card-stats"
                            header-class="d-flex justify-content-between align-items-baseline low">
                        <template v-slot:header>
                            <span class="card-title">Expenses</span>
                            <b-dropdown variant="link" no-caret>
                                <template v-slot:button-content>
                                    <i class="mr-1 fa fa-cog fa-fw"></i>
                                </template>
                                <b-dropdown-item-button>Action</b-dropdown-item-button>
                                <b-dropdown-item-button>Another action</b-dropdown-item-button>
                                <b-dropdown-item-button>Something else here</b-dropdown-item-button>
                            </b-dropdown>

                        </template>
                        <b-row>
                            <b-col>
                                <div class="numbers">
                                    <p class="header-2">Yearly</p>
                                    <div class="header-1">€77,392.04 <span
                                        class="change_indicator negative">12%</span>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                </div>
                <!-- Dashboard car type 2 -->
                <div class="row">
                    <div class="col-md-4">
                        <div class="card card-stats">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="numbers">
                                            <div class="header-1">2,696</div>
                                            <p class="header-2">All conversions</p>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="large-icon text-right text-warning">
                                            <!-- ovdje ide ikona koja treba -->
                                            <i class="mr-1 fa fa-cog fa-fw"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8"></div>
                </div>
                <!-- Dashboard car type 4 -->
                <div class="row">
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-header border-bottom-0">
                                <div class="row">
                                    <div class="col-7">
                                        <span class="header-2">Overview Data</span>
                                        <p class="small-text mb-2">12 Jan 2019</p>
                                    </div>
                                    <div class="d-flex justify-content-md-end col-5">
                                        Place for date picker
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div id="chartPerformance" class="ct-chart">
                                    <svg xmlns:ct="http://gionkunz.github.com/chartist-js/ct" width="100%"
                                         height="250px" class="ct-chart-line" style="width: 100%; height: 250px;">
                                        <g class="ct-grids">
                                            <line y1="215" y2="215" x1="50" x2="539" class="ct-grid ct-vertical"></line>
                                            <line y1="186.42857142857142" y2="186.42857142857142" x1="50" x2="539"
                                                  class="ct-grid ct-vertical"></line>
                                            <line y1="157.85714285714286" y2="157.85714285714286" x1="50" x2="539"
                                                  class="ct-grid ct-vertical"></line>
                                            <line y1="129.28571428571428" y2="129.28571428571428" x1="50" x2="539"
                                                  class="ct-grid ct-vertical"></line>
                                            <line y1="100.71428571428571" y2="100.71428571428571" x1="50" x2="539"
                                                  class="ct-grid ct-vertical"></line>
                                            <line y1="72.14285714285714" y2="72.14285714285714" x1="50" x2="539"
                                                  class="ct-grid ct-vertical"></line>
                                            <line y1="43.571428571428584" y2="43.571428571428584" x1="50" x2="539"
                                                  class="ct-grid ct-vertical"></line>
                                            <line y1="15" y2="15" x1="50" x2="539" class="ct-grid ct-vertical"></line>
                                        </g>
                                        <g>
                                            <g class="ct-series ct-series-a">
                                                <path
                                                    d="M50,203.571C63.583,184.524,77.167,157.313,90.75,146.429C104.333,135.544,117.917,123.571,131.5,123.571C145.083,123.571,158.667,129.286,172.25,135C185.833,140.714,199.417,169.286,213,169.286C226.583,169.286,240.167,140.714,253.75,135C267.333,129.286,280.917,129.667,294.5,123.571C308.083,117.476,321.667,93.095,335.25,77.857C348.833,62.619,362.417,38.238,376,32.143C389.583,26.048,403.167,20.714,416.75,20.714C430.333,20.714,443.917,49.286,457.5,55C471.083,60.714,484.667,62.619,498.25,66.429"
                                                    class="ct-line"></path>
                                            </g>
                                        </g>
                                        <g class="ct-labels">
                                            <foreignObject style="overflow: visible;" x="50" y="220" width="40.75"
                                                           height="20"><span class="ct-label ct-horizontal ct-end"
                                                                             xmlns="http://www.w3.org/2000/xmlns/"
                                                                             style="width: 41px; height: 20px;">6pm</span>
                                            </foreignObject>
                                            <foreignObject style="overflow: visible;" x="90.75" y="220" width="40.75"
                                                           height="20"><span class="ct-label ct-horizontal ct-end"
                                                                             xmlns="http://www.w3.org/2000/xmlns/"
                                                                             style="width: 41px; height: 20px;">9pm</span>
                                            </foreignObject>
                                            <foreignObject style="overflow: visible;" x="131.5" y="220" width="40.75"
                                                           height="20"><span class="ct-label ct-horizontal ct-end"
                                                                             xmlns="http://www.w3.org/2000/xmlns/"
                                                                             style="width: 41px; height: 20px;">11pm</span>
                                            </foreignObject>
                                            <foreignObject style="overflow: visible;" x="172.25" y="220" width="40.75"
                                                           height="20"><span class="ct-label ct-horizontal ct-end"
                                                                             xmlns="http://www.w3.org/2000/xmlns/"
                                                                             style="width: 41px; height: 20px;">2am</span>
                                            </foreignObject>
                                            <foreignObject style="overflow: visible;" x="213" y="220" width="40.75"
                                                           height="20"><span class="ct-label ct-horizontal ct-end"
                                                                             xmlns="http://www.w3.org/2000/xmlns/"
                                                                             style="width: 41px; height: 20px;">4am</span>
                                            </foreignObject>
                                            <foreignObject style="overflow: visible;" x="253.75" y="220" width="40.75"
                                                           height="20"><span class="ct-label ct-horizontal ct-end"
                                                                             xmlns="http://www.w3.org/2000/xmlns/"
                                                                             style="width: 41px; height: 20px;">8am</span>
                                            </foreignObject>
                                            <foreignObject style="overflow: visible;" x="294.5" y="220" width="40.75"
                                                           height="20"><span class="ct-label ct-horizontal ct-end"
                                                                             xmlns="http://www.w3.org/2000/xmlns/"
                                                                             style="width: 41px; height: 20px;">2pm</span>
                                            </foreignObject>
                                            <foreignObject style="overflow: visible;" x="335.25" y="220" width="40.75"
                                                           height="20"><span class="ct-label ct-horizontal ct-end"
                                                                             xmlns="http://www.w3.org/2000/xmlns/"
                                                                             style="width: 41px; height: 20px;">5pm</span>
                                            </foreignObject>
                                            <foreignObject style="overflow: visible;" x="376" y="220" width="40.75"
                                                           height="20"><span class="ct-label ct-horizontal ct-end"
                                                                             xmlns="http://www.w3.org/2000/xmlns/"
                                                                             style="width: 41px; height: 20px;">8pm</span>
                                            </foreignObject>
                                            <foreignObject style="overflow: visible;" x="416.75" y="220" width="40.75"
                                                           height="20"><span class="ct-label ct-horizontal ct-end"
                                                                             xmlns="http://www.w3.org/2000/xmlns/"
                                                                             style="width: 41px; height: 20px;">11pm</span>
                                            </foreignObject>
                                            <foreignObject style="overflow: visible;" x="457.5" y="220" width="40.75"
                                                           height="20"><span class="ct-label ct-horizontal ct-end"
                                                                             xmlns="http://www.w3.org/2000/xmlns/"
                                                                             style="width: 41px; height: 20px;">4am</span>
                                            </foreignObject>
                                            <foreignObject style="overflow: visible;" x="498.25" y="220" width="40.75"
                                                           height="20"><span class="ct-label ct-horizontal ct-end"
                                                                             xmlns="http://www.w3.org/2000/xmlns/"
                                                                             style="width: 41px; height: 20px;"></span>
                                            </foreignObject>
                                            <foreignObject style="overflow: visible;" y="186.42857142857142" x="10"
                                                           height="28.571428571428573" width="30"><span
                                                class="ct-label ct-vertical ct-start"
                                                xmlns="http://www.w3.org/2000/xmlns/"
                                                style="height: 29px; width: 30px;">0</span></foreignObject>
                                            <foreignObject style="overflow: visible;" y="157.85714285714283" x="10"
                                                           height="28.571428571428573" width="30"><span
                                                class="ct-label ct-vertical ct-start"
                                                xmlns="http://www.w3.org/2000/xmlns/"
                                                style="height: 29px; width: 30px;">2.5</span></foreignObject>
                                            <foreignObject style="overflow: visible;" y="129.2857142857143" x="10"
                                                           height="28.571428571428562" width="30"><span
                                                class="ct-label ct-vertical ct-start"
                                                xmlns="http://www.w3.org/2000/xmlns/"
                                                style="height: 29px; width: 30px;">5</span></foreignObject>
                                            <foreignObject style="overflow: visible;" y="100.7142857142857" x="10"
                                                           height="28.571428571428584" width="30"><span
                                                class="ct-label ct-vertical ct-start"
                                                xmlns="http://www.w3.org/2000/xmlns/"
                                                style="height: 29px; width: 30px;">7.5</span></foreignObject>
                                            <foreignObject style="overflow: visible;" y="72.14285714285714" x="10"
                                                           height="28.57142857142857" width="30"><span
                                                class="ct-label ct-vertical ct-start"
                                                xmlns="http://www.w3.org/2000/xmlns/"
                                                style="height: 29px; width: 30px;">10</span></foreignObject>
                                            <foreignObject style="overflow: visible;" y="43.571428571428584" x="10"
                                                           height="28.571428571428555" width="30"><span
                                                class="ct-label ct-vertical ct-start"
                                                xmlns="http://www.w3.org/2000/xmlns/"
                                                style="height: 29px; width: 30px;">12.5</span></foreignObject>
                                            <foreignObject style="overflow: visible;" y="15" x="10"
                                                           height="28.571428571428584" width="30"><span
                                                class="ct-label ct-vertical ct-start"
                                                xmlns="http://www.w3.org/2000/xmlns/"
                                                style="height: 29px; width: 30px;">15</span></foreignObject>
                                            <foreignObject style="overflow: visible;" y="-15" x="10" height="30"
                                                           width="30"><span class="ct-label ct-vertical ct-start"
                                                                            xmlns="http://www.w3.org/2000/xmlns/"
                                                                            style="height: 30px; width: 30px;">17.5</span>
                                            </foreignObject>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-5 d-flex align-items-start flex-column">
                                        <p class="small-text">Lorem ipsum top</p>
                                        <span class="header-2 mb-auto">Lorem ipsum dolor naslov</span>
                                        <div>
                                            <a href="#" class="mr-3 br-2">Subitem 1</a>
                                            <a href="#" class="mr-3 br-2">Subitem 2</a>
                                            <a href="#" class="mr-3">Subitem 3</a>
                                        </div>
                                    </div>
                                    <div class="col-4" style="height:120px;">
                                        place for chart
                                    </div>
                                    <div class="col-3 d-flex align-items-start flex-column pl-4 bl-3">
                                        <span class="small-text">Lorem ipsum top</span>
                                        <span class="header-2 mb-auto">Lorem ipsuma dolor CTA</span>
                                        <button class="btn app-button-btn btn-primary btn-default">Button</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Dashboard car type 3 -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header border-bottom-0">
                                <div class="row">
                                    <div class="col-7">
                                        <span class="header-2">Overview Data</span>
                                        <p class="small-text mb-2">Unit example</p>
                                    </div>
                                    <div class="d-flex justify-content-md-end col-5">
                                        <div class="">
                                            Place for date picker
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <!-- Primjer generirane tablice -->
                                <div data-v-96c2b83e="" data-v-7985f1b5="" class="position-relative">
                                    <div data-v-96c2b83e="" class="table-responsive">
                                        <table role="table" aria-busy="false" aria-colcount="9"
                                               class="table b-table table-hover" id="__BVID__253">
                                            <thead role="rowgroup" class="">
                                            <tr role="row" class="">
                                                <th role="columnheader" scope="col" aria-colindex="1" class="">Id</th>
                                                <th role="columnheader" scope="col" aria-colindex="2" class="">Name</th>
                                                <th role="columnheader" scope="col" aria-colindex="3" class="">Type</th>
                                                <th role="columnheader" scope="col" aria-colindex="4" class="">
                                                    Categorization
                                                </th>
                                                <th role="columnheader" scope="col" aria-colindex="5" class="">Country
                                                </th>
                                                <th role="columnheader" scope="col" aria-colindex="6" class="">City</th>
                                                <th role="columnheader" scope="col" aria-colindex="7" class="">Account
                                                    manager
                                                </th>
                                                <th role="columnheader" scope="col" aria-colindex="8" class="">Status
                                                </th>
                                                <th role="columnheader" scope="col" aria-colindex="9" aria-label="Edit"
                                                    class="text-right"></th>
                                            </tr>
                                            </thead>
                                            <tbody role="rowgroup">
                                            <tr role="row" class="">
                                                <td role="cell" aria-colindex="1" class="">34</td>
                                                <td role="cell" aria-colindex="2" class="">Apartments Aura</td>
                                                <td role="cell" aria-colindex="3" class="">Apartment</td>
                                                <td role="cell" aria-colindex="4" class=""><i data-v-96c2b83e=""
                                                                                              aria-hidden="true"
                                                                                              class="fa fa-star-o fa-fw"></i><i
                                                    data-v-96c2b83e="" aria-hidden="true"
                                                    class="fa fa-star-o fa-fw"></i><i data-v-96c2b83e=""
                                                                                      aria-hidden="true"
                                                                                      class="fa fa-star-o fa-fw"></i><i
                                                    data-v-96c2b83e="" aria-hidden="true"
                                                    class="fa fa-star-o fa-fw"></i></td>
                                                <td role="cell" aria-colindex="5" class="">Croatia</td>
                                                <td role="cell" aria-colindex="6" class="">Dubrovnik</td>
                                                <td role="cell" aria-colindex="7" class="">Mario Pavlović</td>
                                                <td role="cell" aria-colindex="8" class="">active</td>
                                                <td role="cell" aria-colindex="9" class="text-right">
                                                    <div data-v-753a42d2="" data-v-96c2b83e="" class="d-inline"><span
                                                        data-v-753a42d2="" id="AppButton_276"><a data-v-753a42d2=""
                                                                                                 href="/property/34/overview"
                                                                                                 class="btn app-button-btn btn-link btn-default"
                                                                                                 target="_self"><i
                                                        data-v-753a42d2="" aria-hidden="true"
                                                        class="mr-1 fa fa-pencil-square-o fa-fw"></i></a></span></div>
                                                </td>
                                            </tr>
                                            <tr role="row" class="">
                                                <td role="cell" aria-colindex="1" class="">35</td>
                                                <td role="cell" aria-colindex="2" class="">Apartments Gravosa</td>
                                                <td role="cell" aria-colindex="3" class="">Apartment</td>
                                                <td role="cell" aria-colindex="4" class=""><i data-v-96c2b83e=""
                                                                                              aria-hidden="true"
                                                                                              class="fa fa-star-o fa-fw"></i><i
                                                    data-v-96c2b83e="" aria-hidden="true"
                                                    class="fa fa-star-o fa-fw"></i><i data-v-96c2b83e=""
                                                                                      aria-hidden="true"
                                                                                      class="fa fa-star-o fa-fw"></i>
                                                </td>
                                                <td role="cell" aria-colindex="5" class="">Croatia</td>
                                                <td role="cell" aria-colindex="6" class="">Dubrovnik</td>
                                                <td role="cell" aria-colindex="7" class="">Stijepo Vezilić</td>
                                                <td role="cell" aria-colindex="8" class="">active</td>
                                                <td role="cell" aria-colindex="9" class="text-right">
                                                    <div data-v-753a42d2="" data-v-96c2b83e="" class="d-inline"><span
                                                        data-v-753a42d2="" id="AppButton_289"><a data-v-753a42d2=""
                                                                                                 href="/property/35/overview"
                                                                                                 class="btn app-button-btn btn-link btn-default"
                                                                                                 target="_self"><i
                                                        data-v-753a42d2="" aria-hidden="true"
                                                        class="mr-1 fa fa-pencil-square-o fa-fw"></i></a></span></div>
                                                </td>
                                            </tr>
                                            <tr role="row" class="">
                                                <td role="cell" aria-colindex="1" class="">37</td>
                                                <td role="cell" aria-colindex="2" class="">Villa Samba</td>
                                                <td role="cell" aria-colindex="3" class="">Apartment</td>
                                                <td role="cell" aria-colindex="4" class=""><i data-v-96c2b83e=""
                                                                                              aria-hidden="true"
                                                                                              class="fa fa-star-o fa-fw"></i><i
                                                    data-v-96c2b83e="" aria-hidden="true"
                                                    class="fa fa-star-o fa-fw"></i><i data-v-96c2b83e=""
                                                                                      aria-hidden="true"
                                                                                      class="fa fa-star-o fa-fw"></i><i
                                                    data-v-96c2b83e="" aria-hidden="true"
                                                    class="fa fa-star-o fa-fw"></i></td>
                                                <td role="cell" aria-colindex="5" class="">Croatia</td>
                                                <td role="cell" aria-colindex="6" class="">Mlini</td>
                                                <td role="cell" aria-colindex="7" class="">Dario Korić</td>
                                                <td role="cell" aria-colindex="8" class="">active</td>
                                                <td role="cell" aria-colindex="9" class="text-right">
                                                    <div data-v-753a42d2="" data-v-96c2b83e="" class="d-inline"><span
                                                        data-v-753a42d2="" id="AppButton_302"><a data-v-753a42d2=""
                                                                                                 href="/property/37/overview"
                                                                                                 class="btn app-button-btn btn-link btn-default"
                                                                                                 target="_self"><i
                                                        data-v-753a42d2="" aria-hidden="true"
                                                        class="mr-1 fa fa-pencil-square-o fa-fw"></i></a></span></div>
                                                </td>
                                            </tr>
                                            <tr role="row" class="">
                                                <td role="cell" aria-colindex="1" class="">40</td>
                                                <td role="cell" aria-colindex="2" class="">Apartments Matuško</td>
                                                <td role="cell" aria-colindex="3" class="">Apartment</td>
                                                <td role="cell" aria-colindex="4" class=""><i data-v-96c2b83e=""
                                                                                              aria-hidden="true"
                                                                                              class="fa fa-star-o fa-fw"></i><i
                                                    data-v-96c2b83e="" aria-hidden="true"
                                                    class="fa fa-star-o fa-fw"></i><i data-v-96c2b83e=""
                                                                                      aria-hidden="true"
                                                                                      class="fa fa-star-o fa-fw"></i>
                                                </td>
                                                <td role="cell" aria-colindex="5" class="">Croatia</td>
                                                <td role="cell" aria-colindex="6" class="">Mlini</td>
                                                <td role="cell" aria-colindex="7" class="">Stijepo Vezilić</td>
                                                <td role="cell" aria-colindex="8" class="">active</td>
                                                <td role="cell" aria-colindex="9" class="text-right">
                                                    <div data-v-753a42d2="" data-v-96c2b83e="" class="d-inline"><span
                                                        data-v-753a42d2="" id="AppButton_315"><a data-v-753a42d2=""
                                                                                                 href="/property/40/overview"
                                                                                                 class="btn app-button-btn btn-link btn-default"
                                                                                                 target="_self"><i
                                                        data-v-753a42d2="" aria-hidden="true"
                                                        class="mr-1 fa fa-pencil-square-o fa-fw"></i></a></span></div>
                                                </td>
                                            </tr>
                                            <tr role="row" class="">
                                                <td role="cell" aria-colindex="1" class="">47</td>
                                                <td role="cell" aria-colindex="2" class="">Apartmani Bottega</td>
                                                <td role="cell" aria-colindex="3" class="">Apartment</td>
                                                <td role="cell" aria-colindex="4" class=""><i data-v-96c2b83e=""
                                                                                              aria-hidden="true"
                                                                                              class="fa fa-star-o fa-fw"></i><i
                                                    data-v-96c2b83e="" aria-hidden="true"
                                                    class="fa fa-star-o fa-fw"></i><i data-v-96c2b83e=""
                                                                                      aria-hidden="true"
                                                                                      class="fa fa-star-o fa-fw"></i>
                                                </td>
                                                <td role="cell" aria-colindex="5" class="">Croatia</td>
                                                <td role="cell" aria-colindex="6" class="">Dubrovnik</td>
                                                <td role="cell" aria-colindex="7" class="">Frano Rozić</td>
                                                <td role="cell" aria-colindex="8" class="">update completed</td>
                                                <td role="cell" aria-colindex="9" class="text-right">
                                                    <div data-v-753a42d2="" data-v-96c2b83e="" class="d-inline"><span
                                                        data-v-753a42d2="" id="AppButton_328"><a data-v-753a42d2=""
                                                                                                 href="/property/47/overview"
                                                                                                 class="btn app-button-btn btn-link btn-default"
                                                                                                 target="_self"><i
                                                        data-v-753a42d2="" aria-hidden="true"
                                                        class="mr-1 fa fa-pencil-square-o fa-fw"></i></a></span></div>
                                                </td>
                                            </tr>
                                            <tr role="row" class="">
                                                <td role="cell" aria-colindex="1" class="">55</td>
                                                <td role="cell" aria-colindex="2" class="">Villa Mia</td>
                                                <td role="cell" aria-colindex="3" class="">Apartment</td>
                                                <td role="cell" aria-colindex="4" class=""><i data-v-96c2b83e=""
                                                                                              aria-hidden="true"
                                                                                              class="fa fa-star-o fa-fw"></i><i
                                                    data-v-96c2b83e="" aria-hidden="true"
                                                    class="fa fa-star-o fa-fw"></i><i data-v-96c2b83e=""
                                                                                      aria-hidden="true"
                                                                                      class="fa fa-star-o fa-fw"></i>
                                                </td>
                                                <td role="cell" aria-colindex="5" class="">Croatia</td>
                                                <td role="cell" aria-colindex="6" class="">Dubrovnik</td>
                                                <td role="cell" aria-colindex="7" class="">Stjepo Burum</td>
                                                <td role="cell" aria-colindex="8" class="">update</td>
                                                <td role="cell" aria-colindex="9" class="text-right">
                                                    <div data-v-753a42d2="" data-v-96c2b83e="" class="d-inline"><span
                                                        data-v-753a42d2="" id="AppButton_341"><a data-v-753a42d2=""
                                                                                                 href="/property/55/overview"
                                                                                                 class="btn app-button-btn btn-link btn-default"
                                                                                                 target="_self"><i
                                                        data-v-753a42d2="" aria-hidden="true"
                                                        class="mr-1 fa fa-pencil-square-o fa-fw"></i></a></span></div>
                                                </td>
                                            </tr>
                                            <tr role="row" class="">
                                                <td role="cell" aria-colindex="1" class="">56</td>
                                                <td role="cell" aria-colindex="2" class="">Villa Soti</td>
                                                <td role="cell" aria-colindex="3" class="">Apartment</td>
                                                <td role="cell" aria-colindex="4" class=""><i data-v-96c2b83e=""
                                                                                              aria-hidden="true"
                                                                                              class="fa fa-star-o fa-fw"></i><i
                                                    data-v-96c2b83e="" aria-hidden="true"
                                                    class="fa fa-star-o fa-fw"></i><i data-v-96c2b83e=""
                                                                                      aria-hidden="true"
                                                                                      class="fa fa-star-o fa-fw"></i>
                                                </td>
                                                <td role="cell" aria-colindex="5" class="">Croatia</td>
                                                <td role="cell" aria-colindex="6" class="">Mlini</td>
                                                <td role="cell" aria-colindex="7" class="">Dario Korić</td>
                                                <td role="cell" aria-colindex="8" class="">update</td>
                                                <td role="cell" aria-colindex="9" class="text-right">
                                                    <div data-v-753a42d2="" data-v-96c2b83e="" class="d-inline"><span
                                                        data-v-753a42d2="" id="AppButton_354"><a data-v-753a42d2=""
                                                                                                 href="/property/56/overview"
                                                                                                 class="btn app-button-btn btn-link btn-default"
                                                                                                 target="_self"><i
                                                        data-v-753a42d2="" aria-hidden="true"
                                                        class="mr-1 fa fa-pencil-square-o fa-fw"></i></a></span></div>
                                                </td>
                                            </tr>
                                            <tr role="row" class="">
                                                <td role="cell" aria-colindex="1" class="">57</td>
                                                <td role="cell" aria-colindex="2" class="">Villa Vesna</td>
                                                <td role="cell" aria-colindex="3" class="">Apartment</td>
                                                <td role="cell" aria-colindex="4" class=""><i data-v-96c2b83e=""
                                                                                              aria-hidden="true"
                                                                                              class="fa fa-star-o fa-fw"></i><i
                                                    data-v-96c2b83e="" aria-hidden="true"
                                                    class="fa fa-star-o fa-fw"></i><i data-v-96c2b83e=""
                                                                                      aria-hidden="true"
                                                                                      class="fa fa-star-o fa-fw"></i>
                                                </td>
                                                <td role="cell" aria-colindex="5" class="">Croatia</td>
                                                <td role="cell" aria-colindex="6" class="">Dubrovnik</td>
                                                <td role="cell" aria-colindex="7" class="">Mario Pavlović</td>
                                                <td role="cell" aria-colindex="8" class="">active</td>
                                                <td role="cell" aria-colindex="9" class="text-right">
                                                    <div data-v-753a42d2="" data-v-96c2b83e="" class="d-inline"><span
                                                        data-v-753a42d2="" id="AppButton_367"><a data-v-753a42d2=""
                                                                                                 href="/property/57/overview"
                                                                                                 class="btn app-button-btn btn-link btn-default"
                                                                                                 target="_self"><i
                                                        data-v-753a42d2="" aria-hidden="true"
                                                        class="mr-1 fa fa-pencil-square-o fa-fw"></i></a></span></div>
                                                </td>
                                            </tr>
                                            <tr role="row" class="">
                                                <td role="cell" aria-colindex="1" class="">59</td>
                                                <td role="cell" aria-colindex="2" class="">Apartments Minerva</td>
                                                <td role="cell" aria-colindex="3" class="">Apartment</td>
                                                <td role="cell" aria-colindex="4" class=""><i data-v-96c2b83e=""
                                                                                              aria-hidden="true"
                                                                                              class="fa fa-star-o fa-fw"></i><i
                                                    data-v-96c2b83e="" aria-hidden="true"
                                                    class="fa fa-star-o fa-fw"></i><i data-v-96c2b83e=""
                                                                                      aria-hidden="true"
                                                                                      class="fa fa-star-o fa-fw"></i>
                                                </td>
                                                <td role="cell" aria-colindex="5" class="">Croatia</td>
                                                <td role="cell" aria-colindex="6" class="">Dubrovnik</td>
                                                <td role="cell" aria-colindex="7" class="">Đivo Bovolini</td>
                                                <td role="cell" aria-colindex="8" class="">update</td>
                                                <td role="cell" aria-colindex="9" class="text-right">
                                                    <div data-v-753a42d2="" data-v-96c2b83e="" class="d-inline"><span
                                                        data-v-753a42d2="" id="AppButton_380"><a data-v-753a42d2=""
                                                                                                 href="/property/59/overview"
                                                                                                 class="btn app-button-btn btn-link btn-default"
                                                                                                 target="_self"><i
                                                        data-v-753a42d2="" aria-hidden="true"
                                                        class="mr-1 fa fa-pencil-square-o fa-fw"></i></a></span></div>
                                                </td>
                                            </tr>
                                            <tr role="row" class="">
                                                <td role="cell" aria-colindex="1" class="">61</td>
                                                <td role="cell" aria-colindex="2" class="">Apartmani Farac - Dalmatinska
                                                    32
                                                </td>
                                                <td role="cell" aria-colindex="3" class="">Apartment</td>
                                                <td role="cell" aria-colindex="4" class=""><i data-v-96c2b83e=""
                                                                                              aria-hidden="true"
                                                                                              class="fa fa-star-o fa-fw"></i><i
                                                    data-v-96c2b83e="" aria-hidden="true"
                                                    class="fa fa-star-o fa-fw"></i><i data-v-96c2b83e=""
                                                                                      aria-hidden="true"
                                                                                      class="fa fa-star-o fa-fw"></i>
                                                </td>
                                                <td role="cell" aria-colindex="5" class="">Croatia</td>
                                                <td role="cell" aria-colindex="6" class="">Dubrovnik</td>
                                                <td role="cell" aria-colindex="7" class="">Đivo Bovolini</td>
                                                <td role="cell" aria-colindex="8" class="">active</td>
                                                <td role="cell" aria-colindex="9" class="text-right">
                                                    <div data-v-753a42d2="" data-v-96c2b83e="" class="d-inline"><span
                                                        data-v-753a42d2="" id="AppButton_393"><a data-v-753a42d2=""
                                                                                                 href="/property/61/overview"
                                                                                                 class="btn app-button-btn btn-link btn-default"
                                                                                                 target="_self"><i
                                                        data-v-753a42d2="" aria-hidden="true"
                                                        class="mr-1 fa fa-pencil-square-o fa-fw"></i></a></span></div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <b-row>
                <b-col>
                    <b-card>
                        <b-button class="mr-3" @click="showSuccess">Success toast (treba maknuti klasu secondary)</b-button>
                        <b-button class="mr-3" variant="danger" @click="showError">Error toast</b-button>
                        <b-button class="mr-3" variant="warning" @click="showWarning">Warning toast</b-button>
                        <b-button class="mr-3" variant="info" @click="showInfo">Info toast</b-button>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-card>
                        <b-button class="mr-3" variant='outline-success'>outline success</b-button>
                        <b-button class="mr-3" variant='outline-primary'>outline primary</b-button>
                        <b-button class="mr-3" variant='outline-secondary'>outline secondary</b-button>
                        <b-button class="mr-3" variant='outline-warning'>outline warning</b-button>
                        <b-button class="mr-3" variant='outline-danger'>outline danger</b-button>
                        <b-button class="mr-3" variant='outline-info'>outline info</b-button>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col xl="4" md="3">
                    <b-card class="card-stats"
                            header-class="d-flex justify-content-between align-items-baseline low">
                        <template v-slot:header>
                            <span class="card-title">Expenses</span>
                            <b-dropdown variant="link" no-caret>
                                <template v-slot:button-content>
                                    <i class="mr-1 fa fa-cog fa-fw"></i>
                                </template>
                                <b-dropdown-item-button>Action</b-dropdown-item-button>
                                <b-dropdown-item-button>Another action</b-dropdown-item-button>
                                <b-dropdown-item-button>Something else here</b-dropdown-item-button>
                            </b-dropdown>

                        </template>
                        <b-row>
                            <b-col>
                                <div class="numbers">
                                    <p class="header-2">Yearly</p>
                                    <div class="header-1">€77,392.04 <span
                                        class="change_indicator positive">12%</span>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col xl="4" md="3">
                    <b-card class="card-stats"
                            header-class="d-flex justify-content-between align-items-baseline low">
                        <template v-slot:header>
                            <span class="card-title">Expenses</span>
                            <b-dropdown no-caret>
                                <template v-slot:button-content>
                                    <i class="fa fa-cog fa-fw"></i>
                                </template>
                                <b-dropdown-item-button>Action</b-dropdown-item-button>
                                <b-dropdown-item-button>Another action</b-dropdown-item-button>
                                <b-dropdown-item-button>Something else here</b-dropdown-item-button>
                            </b-dropdown>

                        </template>
                        <b-row>
                            <b-col>
                                <div class="numbers">
                                    <p class="header-2">Yearly</p>
                                    <div class="header-1">€77,392.04 <span
                                        class="change_indicator positive">12%</span>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <!-- Dashboard elements -->
            <h1>Dashboard elements</h1>
            <!-- 1. Card header -->
            <b-row>
                <b-col xl="4" md="3">
                    <b-card class="card-stats"
                                header-class="d-flex justify-content-between align-items-baseline low">
                        <template v-slot:header>
                            <span class="card-title">Expenses</span>
                            <b-dropdown variant="link" no-caret>
                                <template v-slot:button-content>
                                    <i class="mr-1 fa fa-cog fa-fw"></i>
                                </template>
                                <b-dropdown-item-button>Action</b-dropdown-item-button>
                                <b-dropdown-item-button>Another action</b-dropdown-item-button>
                                <b-dropdown-item-button>Something else here</b-dropdown-item-button>
                            </b-dropdown>
                            <button class="btn btn-link card-drag"><!-- Drag button --></button>
                        </template>
                    </b-card>
                </b-col>
            </b-row>
            <!-- 2. Header 1 -->
            <b-row>
                <b-col xl="4" md="3">
                    <b-card class="card-stats"
                                header-class="d-flex justify-content-between align-items-baseline">
                        <b-row>
                            <b-col>
                                <div class="header-1">€77,392.04</div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <!-- 3. Numbers (inc/dec) -->
            <b-row>
                <b-col xl="4" md="3">
                    <b-card class="card-stats"
                                header-class="d-flex justify-content-between align-items-baseline">
                        <b-row>
                            <b-col>
                                <span class="change_indicator positive">12%</span>
                                <span class="change_indicator negative">12%</span>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <!-- 4. Header 2 -->
            <b-row>
                <b-col xl="4" md="3">
                    <b-card class="card-stats"
                                header-class="d-flex justify-content-between align-items-baseline">
                        <b-row>
                            <b-col>
                                <p class="header-2">Yearly</p>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <!-- 5. Large icon -->
            <b-row>
                <b-col xl="4" md="3">
                    <b-card class="card-stats"
                                header-class="d-flex justify-content-between align-items-baseline">
                        <b-row>
                            <b-col>
                                <div class="large-icon text-warning">
                                    <i class="mr-1 fa fa-cog fa-fw"></i>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <!-- 6. Small gray text -->
            <b-row>
                <b-col xl="4" md="3">
                    <b-card class="card-stats"
                                header-class="d-flex justify-content-between align-items-baseline">
                        <b-row>
                            <b-col>
                                <p class="small-text">12 Jan 2019</p>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <!-- 7. Item with border -->
            <b-row>
                <b-col xl="4" md="3">
                    <b-card class="card-stats"
                                header-class="d-flex justify-content-between align-items-baseline">
                        <b-row>
                            <b-col>
                                <a href="#" class="mr-3 br-2">Subitem 1</a>
                                <a href="#" class="mr-3 br-2">Subitem 2</a>
                                <a href="#" class="mr-3">Subitem 3</a>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>

    <!-- Primjer cardova -->
    <!-- <b-card-group columns class="column-4">
        <b-card>
          <div data-v-2e74fa3c="" class="list-group repeater-2"><div data-v-2e74fa3c="" class="list-group-item list-group-item-title font-weight-bold">
                        Bathroom
                        <span data-v-2e74fa3c="" class="badge ml-1 badge-success badge-pill">1.00</span><div data-v-50f57a6e="" data-v-2e74fa3c="" class="d-inline pull-right amenity-edit blue_action"><span data-v-50f57a6e="" id="AppButton_193"><button data-v-50f57a6e="" type="button" class="btn app-button-btn btn-link btn-default"><i data-v-50f57a6e="" aria-hidden="true" class="mr-1 fa fa-pencil-square-o fa-fw"></i></button></span></div></div><div data-v-2e74fa3c="" class="list-group-item list-group-item-success">Inventory</div><div data-v-2e74fa3c="" class="list-group"><div data-v-2e74fa3c="" class="list-group-item"><a data-v-2e74fa3c="" href="#" class="blue_action">+ Add Inventory</a></div></div></div>
        </b-card>

        <b-card>
          <div data-v-2e74fa3c="" class="list-group repeater-2"><div data-v-2e74fa3c="" class="list-group-item list-group-item-title font-weight-bold">
                        Kitchen
                        <span data-v-2e74fa3c="" class="badge ml-1 badge-success badge-pill">1.00</span><div data-v-50f57a6e="" data-v-2e74fa3c="" class="d-inline pull-right amenity-edit blue_action"><span data-v-50f57a6e="" id="AppButton_194"><button data-v-50f57a6e="" type="button" class="btn app-button-btn btn-link btn-default"><i data-v-50f57a6e="" aria-hidden="true" class="mr-1 fa fa-pencil-square-o fa-fw"></i></button></span></div></div><div data-v-2e74fa3c="" class="list-group-item list-group-item-success">Inventory</div><div data-v-2e74fa3c="" class="list-group"><div data-v-2e74fa3c="" class="list-group-item"><a data-v-2e74fa3c="" href="#" class="blue_action">+ Add Inventory</a></div></div></div>
        </b-card>

        <b-card>
          <div data-v-2e74fa3c="" class="list-group repeater-2"><div data-v-2e74fa3c="" class="list-group-item list-group-item-title font-weight-bold">
                        Living room
                        <span data-v-2e74fa3c="" class="badge ml-1 badge-success badge-pill">1.00</span><div data-v-50f57a6e="" data-v-2e74fa3c="" class="d-inline pull-right amenity-edit blue_action"><span data-v-50f57a6e="" id="AppButton_192"><button data-v-50f57a6e="" type="button" class="btn app-button-btn btn-link btn-default"><i data-v-50f57a6e="" aria-hidden="true" class="mr-1 fa fa-pencil-square-o fa-fw"></i></button></span></div></div><div data-v-2e74fa3c="" class="list-group-item list-group-item-success">Inventory</div><div data-v-2e74fa3c="" class="list-group-item"><span data-v-2e74fa3c="">Large Double Bed</span><span data-v-2e74fa3c="" class="badge pull-right badge-success badge-pill">1.00</span></div><div data-v-2e74fa3c="" class="list-group-item"><span data-v-2e74fa3c="">Single Sofa bed</span><span data-v-2e74fa3c="" class="badge pull-right badge-success badge-pill">1.00</span></div><div data-v-2e74fa3c="" class="list-group-item"><a data-v-2e74fa3c="" href="#" class="blue_action">+ Add Inventory</a></div></div>
        </b-card>

        <b-card>
          <div data-v-2e74fa3c="" class="list-group repeater-2"><div data-v-2e74fa3c="" class="list-group-item list-group-item-title font-weight-bold">
                        Living room
                        <span data-v-2e74fa3c="" class="badge ml-1 badge-success badge-pill">1.00</span><div data-v-50f57a6e="" data-v-2e74fa3c="" class="d-inline pull-right amenity-edit blue_action"><span data-v-50f57a6e="" id="AppButton_202"><button data-v-50f57a6e="" type="button" class="btn app-button-btn btn-link btn-default"><i data-v-50f57a6e="" aria-hidden="true" class="mr-1 fa fa-pencil-square-o fa-fw"></i></button></span></div></div><div data-v-2e74fa3c="" class="list-group-item list-group-item-success">Inventory</div><div data-v-2e74fa3c="" class="list-group-item"><span data-v-2e74fa3c="">Large Double Bed</span><span data-v-2e74fa3c="" class="badge pull-right badge-success badge-pill">1.00</span></div><div data-v-2e74fa3c="" class="list-group-item"><span data-v-2e74fa3c="">Single Sofa bed</span><span data-v-2e74fa3c="" class="badge pull-right badge-success badge-pill">1.00</span></div><div data-v-2e74fa3c="" class="list-group-item"><a data-v-2e74fa3c="" href="#" class="blue_action">+ Add Inventory</a></div></div>
        </b-card>

        <b-card>
          <div data-v-2e74fa3c="" class="list-group repeater-2"><div data-v-2e74fa3c="" class="list-group-item list-group-item-title font-weight-bold">
                        Living room
                        <span data-v-2e74fa3c="" class="badge ml-1 badge-success badge-pill">1.00</span><div data-v-50f57a6e="" data-v-2e74fa3c="" class="d-inline pull-right amenity-edit blue_action"><span data-v-50f57a6e="" id="AppButton_202"><button data-v-50f57a6e="" type="button" class="btn app-button-btn btn-link btn-default"><i data-v-50f57a6e="" aria-hidden="true" class="mr-1 fa fa-pencil-square-o fa-fw"></i></button></span></div></div><div data-v-2e74fa3c="" class="list-group-item list-group-item-success">Inventory</div><div data-v-2e74fa3c="" class="list-group-item"><span data-v-2e74fa3c="">Large Double Bed</span><span data-v-2e74fa3c="" class="badge pull-right badge-success badge-pill">1.00</span></div><div data-v-2e74fa3c="" class="list-group-item"><span data-v-2e74fa3c="">Single Sofa bed</span><span data-v-2e74fa3c="" class="badge pull-right badge-success badge-pill">1.00</span></div><div data-v-2e74fa3c="" class="list-group-item"><span data-v-2e74fa3c="">Single Sofa bed</span><span data-v-2e74fa3c="" class="badge pull-right badge-success badge-pill">1.00</span></div><div data-v-2e74fa3c="" class="list-group-item"><span data-v-2e74fa3c="">Single Sofa bed</span><span data-v-2e74fa3c="" class="badge pull-right badge-success badge-pill">1.00</span></div><div data-v-2e74fa3c="" class="list-group-item"><a data-v-2e74fa3c="" href="#" class="blue_action">+ Add Inventory</a></div></div>
        </b-card>

    </b-card-group> -->

    <!-- Primjer tablice -->
    <!-- <b-card v-cloak class="property-wrapper">
        <template slot="header">
            <h2>Devzone</h2>
        </template>
        <b-row>
            <b-col cols="12" v-for="i in 5">
                <b-row>
                    <b-col>
                        <form-header class="mb-0">
                            Label
                        </form-header>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col>
                        Table header
                    </b-col>
                </b-row>
                <b-table responsive hover :fields="fields" :items="items"></b-table>
            </b-col>
        </b-row>
    </b-card> -->
</template>

<script>

    import FormHeader from "@/components/app/form/FormHeader";
    import {toast} from '@/shared/plugins/toastr'

    export default {
        name: "Devzone",
        components: {FormHeader},
        data() {
            return {
                fields: [
                    {key: 'id', name: 'id'},
                    {key: 'name', name: 'name'},
                    {key: 'field1', name: 'field1'},
                    {key: 'field2', name: 'field2'},
                    {key: 'field3', name: 'field3'},

                ],
                items: [
                    {id: 1, name: 'test', field1: 'Field 1', field2: 'Field 2', field3: 'Field 3'},
                    {id: 1, name: 'test', field1: 'Field 1', field2: 'Field 2', field3: 'Field 3'},
                    {id: 1, name: 'test', field1: 'Field 1', field2: 'Field 2', field3: 'Field 3'},
                    {id: 1, name: 'test', field1: 'Field 1', field2: 'Field 2', field3: 'Field 3'},
                    {id: 1, name: 'test', field1: 'Field 1', field2: 'Field 2', field3: 'Field 3'},
                ]
            }
        },
        methods: {
            showSuccess() {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': "TEST MESSAGE",
                    'type': 'success',
                    'timeout': 300000
                })
            },
            showError() {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': "TEST MESSAGE",
                    'type': 'error',
                    'timeout': 300000
                })
            },
            showWarning() {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': "TEST MESSAGE",
                    'type': 'warning',
                    'timeout': 300000
                })
            },
            showInfo() {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': "TEST MESSAGE",
                    'type': 'info',
                    'timeout': 300000
                })
            }
        }
    }
</script>

<style scoped>

</style>
