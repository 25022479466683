import http from '../http'
import {APPEARANCE_URL} from "@/services/endpoints";

export function getThemeColors(params){
    return http.request({
        url: APPEARANCE_URL,
        method: 'get',
        params: params
    })
}

export function getAllThemeCategories(params){
    return http.request({
        url: APPEARANCE_URL + '/categories',
        method: 'get',
        params: params
    })
}

export function saveThemeColor(data){
    return http.request({
        url: APPEARANCE_URL,
        method: 'post',
        data:data
    })
}

export function deleteThemeColor(data){
    return http.request({
        url: APPEARANCE_URL,
        method: 'delete',
        data:data
    })
}

export function getPaginatedThemeCategories(params){
    return http.request({
        url: APPEARANCE_URL + '/paginate-categories',
        method: 'get',
        params: params
    })
}

export function saveThemeColorCategory(data){
    return http.request({
        url: APPEARANCE_URL + '/categories',
        method: 'post',
        data: data
    })
}

export function deleteThemeColorCategory(data){
    return http.request({
        url: APPEARANCE_URL + '/categories',
        method: 'delete',
        data:data
    })
}

export function getThemeFonts(params){
    return http.request({
        url: APPEARANCE_URL + '/fonts',
        method: 'get',
        params: params
    })
}

export function getThemeSystemFonts(){
    return http.request({
        url: APPEARANCE_URL + '/system-fonts',
        method: 'get'
    })
}

export function saveThemeFontFromSystem(params){
    return http.request({
        url: APPEARANCE_URL + '/fonts',
        method: 'post',
        params: params
    })
}

export function getThemeFontSources(){
    return http.request({
        url: APPEARANCE_URL + '/source-fonts',
        method: 'get'
    })
}

export function getThemeExternalFonts(params){
    return http.request({
        url: APPEARANCE_URL + '/external-fonts',
        method: 'get',
        params:params
    })
}

export function saveThemeExternalFonts(data){
    return http.request({
        url: APPEARANCE_URL + '/external-fonts',
        method: 'post',
        data: data
    })
}

export function fetchThemeFont(id){
    return http.request({
        url: APPEARANCE_URL + '/' + id + '/fonts',
        method: 'get',
    })
}

export function editThemeFont(id, data){
    return http.request({
        url: APPEARANCE_URL + '/' + id + '/fonts',
        method: 'put',
        data: data
    })
}

export function deleteThemeFont(id){
    return http.request({
        url: APPEARANCE_URL + '/' + id + '/fonts',
        method: 'delete'
    })
}

export function fetchThemeColor(id){
    return http.request({
        url: APPEARANCE_URL + '/' + id + '/color',
        method: 'get'
    })
}

export function getThemeItem(params){
    return http.request({
        url: APPEARANCE_URL + '/theme-item',
        method: 'get',
        params: params
    })
}

export function getAllThemeFonts(params){
    return http.request({
        url: APPEARANCE_URL + '/all-fonts',
        method: 'get',
        params: params
    })
}

export function getAllThemeColors(params){
    return http.request({
        url: APPEARANCE_URL + '/all-colors',
        method: 'get',
        params: params
    })
}

export function saveThemeSetup(data){
    return http.request({
        url: APPEARANCE_URL + '/theme-setup',
        method: 'post',
        data: data
    })
}
