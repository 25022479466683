<template>
    <b-form @submit.prevent="save">
        <b-row class="mb-4">
            <b-col>
                <app-input-control :error-object="validation.label">
                    <template v-slot:input="data">
                        <label>{{ $t('NAME') }}</label>
                        <b-input v-model="formData.label" :state="data.state"></b-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row class="mb-4">
            <b-col>
                <app-translation-list :language-list="translateList"
                                      @update="updateTranslationsList"></app-translation-list>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button-submit :loading="saving"></app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import { saveAmenityCategory} from "@/services/direct_booking/website";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppTranslationList from "@/components/app/translation/AppTranslationList";
    import AppInputControl from "@/components/app/AppInputControl";
    import {getTranslateList} from "@/services/system";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {EventBus} from "@/shared/EventBus";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {DB_AMENITY_CATEGORY_LABEL_EMPTY} from "@/shared/error_codes";

    export default {
        name: "NewWebsiteCategory",
        mixins: [getErrorMessage],
        props: {
            websiteId: {
                type: Number,
                default: null
            }
        },
        components: {AppTranslationList, AppButtonSubmit, AppButton, AppInputControl},
        data() {
            return {
                formData: {
                    label: null,
                    translations: {}
                },
                categories: {},
                saving : false,
                validation: {
                    label: DB_AMENITY_CATEGORY_LABEL_EMPTY
                },
            }
        },
        methods: {
            save() {
                this.saving = true
                let list = {}
                for (const [key, value] of Object.entries(this.formData.translations)) {
                    list[key] = value
                }

                this.formData.translations = list

                saveAmenityCategory(this.websiteId, JSON.stringify(this.formData)).then( response => {
                    notifySuccess()
                    this.$emit('saved')
                    EventBus.$emit('reloadWebsiteCategoryTable')
                    this.$store.commit('website/setAmenityCategory', true)
                }).catch(error => {
                    this.showErrorMessages(error, this.validation)
                }).finally(() => this.saving = false)
            },
            updateTranslationsList(data) {
                this.formData.translations = data;
            },
        },
        computed:{
            website(){
                return this.$store.getters['website/getWebsite']
            },
            translateList() {
                return this.website.supported_languages.filter(el => el !== "en")
            }
        }
    }
</script>

<style scoped>

</style>
