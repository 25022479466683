<template>
    <div>
        <promo-codes-filter @search="setFilter" :loading="loading"></promo-codes-filter>
        <promo-codes-table @busyToggled="toggleBusy" :filter="filter"></promo-codes-table>
    </div>
</template>

<script>

import PromoCodesTable from "@/components/direct_booking/promo_codes/list/PromoCodesTable";
import PromoCodesFilter from "@/components/direct_booking/promo_codes/list/PromoCodesFilter";

export default {
    name: "Index",
    components:{PromoCodesTable, PromoCodesFilter},
    data(){
        return{
            filter: null,
            loading: false
        }
    },
    methods:{
        setFilter(values) {
            this.filter = values
        },
        toggleBusy($event) {
            this.loading = $event
        }
    }
}
</script>

<style scoped>

</style>
