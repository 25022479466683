<template>
    <div>
        <appearance-color-filter></appearance-color-filter>
        <appearance-color-table></appearance-color-table>
    </div>
</template>

<script>
import AppearanceColorFilter from "@/components/appearance/color/AppearanceColorFilter";
import AppearanceColorTable from "@/components/appearance/color/AppearanceColorTable";

export default {
    name: "Index",
    components:{AppearanceColorTable,AppearanceColorFilter}
}
</script>

<style scoped>

</style>
