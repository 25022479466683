<template>
    <app-aside v-model="opened">
        <template slot="header">{{formHeader}}</template>

        <template>
            <b-row class="mb-3">
                <b-col lg="6">
                    <label class="label">{{$t("PERIOD")}}</label>
                    <app-date-range-picker :min-date="new Date()"
                                           :disabled-dates="disabledDates"
                                           @input="updateRange"
                                           :from-date="fromDate"
                                           v-model="current_date_range"
                    ></app-date-range-picker>
                </b-col>
                <b-col lg="6" class="mt-lg-4">

                    <b-table :items="date_ranges" :fields="fields">

                        <template v-slot:date="data">
                            {{data.item.start|date}} - {{data.item.end|date}}
                        </template>
                        <template v-slot:delete="data">
                            <AppButton @click="date_ranges.splice(data.index, 1)" button_type="delete"
                                       :show_text="false" variant="link"></AppButton>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col>

                    <b-tabs v-model="tab" nav-class="tab_navigation-horizontal" content-class="tab_content-horizontal"
                            pills align="left">
                        <b-tab :title="$t('AVAILABILITY')" v-if="previewAvailability" :key="0">
                            <calendar-aside-availability
                                :default-values="defaultValues"
                                :date-ranges="date_ranges"
                                :root-unit="rootUnit"
                                :contigent-unit="contigentUnit"
                                :contigent-mode="contigentMode"></calendar-aside-availability>
                        </b-tab>
                        <template v-if="contigentUnit === null">
                            <b-tab v-if="previewRoomStatus" :title="$t('ROOM_STATUS')" :key="1">
                                <calendar-aside-room-status :unit="rootUnit" :date-ranges="date_ranges"
                                                            :default-values="defaultValues"></calendar-aside-room-status>
                            </b-tab>
                            <b-tab :title="$t('RATES')" v-if="previewPrices" :key="2">
                                <calendar-aside-rates
                                    :unit="rootUnit"
                                    :los="los"
                                    :date-ranges="date_ranges"
                                    :ratePlan="ratePlan"
                                    :occupancy="occupancy"
                                    :default-values="defaultValues"></calendar-aside-rates>
                            </b-tab>
                            <b-tab :title="$t('RESTRICTIONS')" v-if="previewRestrictions" :key="3">
                                <calendar-aside-restrictions :unit="rootUnit" :date-ranges="date_ranges"
                                                             :default-values="defaultValues" :rate-plan="ratePlan"
                                                             :rate-plans="ratePlans"></calendar-aside-restrictions>
                            </b-tab>
                        </template>
                    </b-tabs>
                </b-col>
            </b-row>
        </template>

    </app-aside>
</template>

<script>
    import AppAside from "@/components/app/form/AppAside";
    import {
        EventBus,
        GE_CALENDAR_OPEN_AVAILABILITY,
        GE_CALENDAR_OPEN_PRICES, GE_CALENDAR_OPEN_RESTRICTIONS,
        GE_CALENDAR_OPEN_ROOM_STATUS, GE_LOADER_HIDE, GE_LOADER_SHOW
    } from "@/shared/EventBus";
    import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
    import moment from 'moment'
    import AppButton from "@/components/app/AppButton/AppButton";
    import CalendarAsideAvailability from "@/components/unit/main_calendar/Aside/Calendar/CalendarAsideAvailability";
    import CalendarAsideRoomStatus from "@/components/unit/main_calendar/Aside/Calendar/CalendarAsideRoomStatus";
    import CalendarAsideRates from "@/components/unit/main_calendar/Aside/Calendar/CalendarAsideRates";
    import CalendarAsideRestrictions from "@/components/unit/main_calendar/Aside/Calendar/CalendarAsideRestrictions";
    import {getUnitCalendarValues} from "@/services/unit";
    import {get} from 'lodash'
    import {
        C_PROPERTY_CALENDAR_AVAILIBILITY_V,
        C_PROPERTY_CALENDAR_PRICES_V, C_PROPERTY_CALENDAR_RESTRICTIONS_V, C_PROPERTY_CALENDAR_RM_SUGGESTION_V,
        C_PROPERTY_CALENDAR_ROOM_STATUS_V
    } from "@/shared/component_permission";

    export default {
        name: "CalendarAside",
        components: {
            CalendarAsideRestrictions,
            CalendarAsideRates,
            CalendarAsideRoomStatus, CalendarAsideAvailability, AppButton, AppDateRangePicker, AppAside
        },
        props: {
            rootUnit: {
                type: Object
            },
            los: {
                type: Array
            },
            occupancy: {
                type: Array
            },
            elements: {
                type: Array
            },
            ratePlans: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        data() {
            return {
                ratePlan: null,
                contigentUnit: null,
                contigentMode: true,
                defaultValues: null,
                opened: false,
                tab: null,
                tabs: [
                    C_PROPERTY_CALENDAR_AVAILIBILITY_V,
                    C_PROPERTY_CALENDAR_ROOM_STATUS_V,
                    C_PROPERTY_CALENDAR_PRICES_V,
                    C_PROPERTY_CALENDAR_RESTRICTIONS_V,
                ],
                current_date_range: {
                    start: null,
                    end: null
                },
                date_ranges: [],
                fields: [
                    {key: 'date', label: this.$t('SELECTED_PERIODS')},
                    {key: 'delete', label: "", class: 'text-right'},
                ]
            }
        },
        computed: {
            formHeader() {
                if (this.contigentUnit) {
                    return this.contigentUnit.name
                }
                if (this.rootUnit) {
                    return this.rootUnit.unit_type.name
                }
                return ''
            },
            disabledDates() {
                return this.date_ranges.map(item => {
                    return {
                        start: moment(item.start).toDate(),
                        end: moment(item.end).toDate(),
                    }
                })
            },
            fromDate() {

                if (this.date_ranges.length > 0) {
                    let lastItem = this.date_ranges[this.date_ranges.length - 1]
                    if (lastItem.end) {
                        return new moment(lastItem.end).add(1, 'day').toDate()
                    }
                }

                return undefined
            },
            previewAvailability() {
                return this.elements.includes(C_PROPERTY_CALENDAR_AVAILIBILITY_V)
            },
            previewRoomStatus() {
                return this.elements.includes(C_PROPERTY_CALENDAR_ROOM_STATUS_V)
            },
            previewPrices() {
                return this.elements.includes(C_PROPERTY_CALENDAR_PRICES_V)
            },
            previewRestrictions() {
                return this.elements.includes(C_PROPERTY_CALENDAR_RESTRICTIONS_V)
            },
            previewSuggestions() {
                return this.elements.includes(C_PROPERTY_CALENDAR_RM_SUGGESTION_V)
            },
            shownElements() {
                return this.tabs.filter(tab => this.elements.includes(tab))
            },
            CalValuesReqData() {
                return this.elements.map(el => {
                    if (el === C_PROPERTY_CALENDAR_AVAILIBILITY_V) {
                        return 'availability'
                    }
                    if (el === C_PROPERTY_CALENDAR_ROOM_STATUS_V) {
                        return 'room_status'
                    }
                    if (el === C_PROPERTY_CALENDAR_PRICES_V) {
                        return 'rates'
                    }
                    if (el === C_PROPERTY_CALENDAR_RESTRICTIONS_V) {
                        return 'restrictions'
                    }
                })
            }
        },
        methods: {
            updateRange(val) {
                this.date_ranges.push(val)
                this.current_date_range = {
                    start: null,
                    end: null
                }
            },
            setTabIndex(tabValue) {
                this.tab = this.shownElements.findIndex(el => el === tabValue)
            }
        },
        watch: {
            date_ranges: {
                handler(value) {
                    if (value.length) {

                        EventBus.$emit(GE_LOADER_SHOW)
                        getUnitCalendarValues(
                            this.contigentUnit ? this.contigentUnit.id : this.rootUnit.id,
                            {
                                periods: value,
                                contigent: this.contigentMode,
                                rate_plan: this.ratePlan,
                                show: this.CalValuesReqData
                            })
                            .then(response => {

                                this.defaultValues = response.data
                            }).finally(() => {
                            EventBus.$emit(GE_LOADER_HIDE)
                        })
                    }
                },
                immediate: true,
                deep: true
            }
        },
        created() {
            EventBus.$on(GE_CALENDAR_OPEN_AVAILABILITY, (data) => {

                if (!data.hasOwnProperty('unitId') || data.unitId !== this.rootUnit.id) {
                    return false
                }

                if (data.hasOwnProperty('contigentUnit')) {
                    this.contigentUnit = data.contigentUnit
                } else {
                    this.contigentUnit = null
                }

                if (data.hasOwnProperty('contigentMode')) {
                    this.contigentMode = data.contigentMode
                } else {
                    this.contigentMode = true
                }

                this.setTabIndex(C_PROPERTY_CALENDAR_AVAILIBILITY_V)

                this.date_ranges = []
                if (data.hasOwnProperty('dateRange')) {
                    this.date_ranges = []
                    this.date_ranges.push(data.dateRange)
                }
                if(this.rootUnit.hasOwnProperty('primary_rate_plan')){
                    this.ratePlan = this.rootUnit.primary_rate_plan.id
                }
                this.opened = true
            })

            EventBus.$on(GE_CALENDAR_OPEN_ROOM_STATUS, (data) => {
                if (!data.hasOwnProperty('unitId') || data.unitId !== this.rootUnit.id) {
                    return false
                }

                if (data.hasOwnProperty('dateRange')) {
                    this.date_ranges = []
                    this.date_ranges.push(data.dateRange)
                }
                this.contigentMode = true
                this.contigentUnit = null
                this.setTabIndex(C_PROPERTY_CALENDAR_ROOM_STATUS_V)
                this.ratePlan = this.rootUnit.primary_rate_plan.id
                this.opened = true
            })

            EventBus.$on(GE_CALENDAR_OPEN_PRICES, (data) => {
                if (!data.hasOwnProperty('unitId') || data.unitId !== this.rootUnit.id) {
                    return false
                }

                if (data.hasOwnProperty('dateRange')) {
                    this.date_ranges = []
                    this.date_ranges.push(data.dateRange)
                }

                this.contigentUnit = null
                this.ratePlan = this.rootUnit.primary_rate_plan.id
                this.setTabIndex(C_PROPERTY_CALENDAR_PRICES_V)
                this.contigentMode = true
                this.opened = true
            })

            EventBus.$on(GE_CALENDAR_OPEN_RESTRICTIONS, (data) => {
                if (!data.hasOwnProperty('unitId') || data.unitId !== this.rootUnit.id) {
                    return false
                }

                if (data.hasOwnProperty('dateRange')) {
                    this.date_ranges = []
                    this.date_ranges.push(data.dateRange)
                }

                if (data.hasOwnProperty('ratePlan')) {
                    this.ratePlan = data.ratePlan
                } else {
                    this.ratePlan = this.rootUnit.primary_rate_plan.id
                }
                this.setTabIndex(C_PROPERTY_CALENDAR_RESTRICTIONS_V)
                this.contigentMode = true
                this.contigentUnit = null
                this.opened = true
            })
        }

    }
</script>

<style scoped>

</style>
