<template>
    <div v-if="hasPermissionView">
        <b-form @submit.prevent="fetchCommission()" class="filter">
            <b-row>
                <b-col md="2" class="mb-3">
                    <label>{{$t('COMMISSION_TYPE')}}</label>
                    <app-select v-model="selectedCommissionType" :options="commissionType"></app-select>
                </b-col>
                <b-col md="2" class="mb-3">
                    <label>{{$t('YEAR')}}</label>
                    <app-select v-model="selectedYear" :options="yearsComputed"></app-select>
                </b-col>
                <b-col md="3" class="mb-3">
                    <app-submit :disabled="!selectedYear || !selectedCommissionType" class="filter-submit-wrapper"
                                :loading="loading" :inline="true" variant="primary"
                                button_type="search">{{ $t("SEARCH") }}
                    </app-submit>
                    <app-button-reset @click="resetForm" :disabled="!selectedYear || !selectedCommissionType || loading" :inline="true" class="pl-2 ml-2"></app-button-reset>
                </b-col>
                <b-col md="5" class="mb-3 d-flex align-items-end justify-content-end">
                    <app-button v-if="checkPermission(c_permission_e)" @click="copyAside=true">{{$t('COMMISSION_COPY_TITLE')}}</app-button>

                </b-col>
            </b-row>
        </b-form>
        <b-form @submit.prevent="saveCommissions">
            <b-row class="mb-3 mt-3" v-if="showTable && checkPermission(c_permission_e)">
                <b-tooltip v-if="selectedYear < currentYear"
                           triggers="hover"
                           :title="$t('EDIT_POSSIBLE_FOR_CURRENT_AND_FUTURE_YEARS')" target="tooltip-target-1">
                </b-tooltip>
                <b-col md="9" sm="6">
                    <commission-submit id="tooltip-target-1" :loading="loadingSave" :c_permission="c_permission_e" :disabled="selectedYear < currentYear"></commission-submit>
                </b-col>
                <b-col>
                    <b-input-group class="commission-copy_year-group pull-right">
                        <b-form-input class="commission-copy_year-input" type="number" v-model="commissionValueYear"
                                      :placeholder="$t('COMMISSION_FOR_ENTIRE_YER')" :disabled="selectedYear < currentYear"></b-form-input>
                        <b-input-group-append>
                            <b-btn variant="primary" @click="copyYearCommission" :disabled="selectedYear < currentYear">+</b-btn>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>


            <b-row>
                <b-col>
                    <table v-if="showTable" class="text-center">
                        <tbody>
                        <template v-for="(month, monthIndex) in 12">
                            <commission-row :disabled_commission_input="selectedYear < currentYear" :commission_type="selectedCommissionType" :save="save" :key="monthIndex"
                                            :month="month" :year="selectedYear" :permission="c_permission_e"
                                            :commissions="commissions"></commission-row>
                        </template>
                        </tbody>
                    </table>
                </b-col>
            </b-row>

        </b-form>
        <app-aside :widths="['col-lg-3', 'col-sm-12', '30%']" v-model="copyAside">
            <template slot="header">{{$t("COMMISSION_COPY_TITLE")}}</template>
            <copy-commission-form @close="reFetchCommission"></copy-commission-form>
        </app-aside>
    </div>
</template>
<script>

    import {getUnitCommissions, setUnitCommissions} from '@/services/unit/index';
    import CommissionSubmit from '@/components/app/AppButton/AppButtonSubmit';
    import CommissionRow from '@/components/unit/commissions/CommissionRow';
    import CopyCommissionForm from '@/components/unit/commissions/CopyCommissionsForm'
    import {toast} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {C_UNIT_COMMISSION_V, C_UNIT_COMMISSION_E} from "@/shared/component_permission";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import moment from 'moment'
    import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppAside from "@/components/app/form/AppAside";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";

    export default {
        components: {
            AppButtonReset,
            AppButton,
            AppSubmit,
            AppSelect,
            CommissionRow,
            CommissionSubmit,
            CopyCommissionForm,
            AppAside
        },
        data() {
            return {

                copyAside: false,
                loading: false,
                commissionType: [
                    {id: 'partner_percentage_commission_', name: 'Company commission (%)'},
                    {id: 'partner_fix_commission_', name: 'Company commission (fix)'},
                ],
                selectedCommissionType: null,
                selectedYear: null,
                currentMonthIteration: null,
                commissions: [],
                commissionValueYear: null,
                c_permission_e: C_UNIT_COMMISSION_E,
                save: false,
                loadingSave: false,
                currentYear: Number(new moment().format('YYYY'))
            }
        },
        mixins: [getErrorMessage],
        methods: {
            reFetchCommission() {
                this.copyAside = false
                this.fetchCommission()
            },
            resetForm() {
                this.selectedYear = null
                this.selectedCommissionType = null
            },

            fetchCommission() {
                if (this.selectedYear === null || this.selectedCommissionType === null) {
                    return
                }
                this.loading = true
                let searchParams = {
                    year: this.selectedYear,
                    commission_type: this.selectedCommissionType,
                }

                getUnitCommissions(this.$route.params.unit_id, searchParams)
                    .then(response => {
                        this.commissions = response.data
                        this.loading = false
                    }, () => {
                        this.loading = false
                    })
                this.commissionValueYear = null
                this.save = false

            },
            copyYearCommission() {
                let valueCopy = this.commissionValueYear;

                this.commissions.forEach(function (element) {
                    element.value = valueCopy;
                })
                this.commissionValueYear = null
            },

            saveCommissions() {

                let data = {}
                let validate = true;
                if (this.commissions.length > 0) {
                    this.commissions.forEach(function (el) {
                        data[el.label] = el.value
                        if (el.value === '' || el.value === null) {
                            validate = false;
                            return false
                        }
                    })
                }
                if (validate) {
                    let postData = {
                        year: this.selectedYear,
                        commissionData: data,
                        commission_type: this.selectedCommissionType,
                    }
                    this.loadingSave = true
                    setUnitCommissions(this.$route.params.unit_id, postData).then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 2000
                        })
                    }, error => {
                        toast({
                            'title': "Action fail",
                            'message': this.getMessages(error),
                            'type': 'error',
                            'timeout': 2000
                        })
                    }).finally(() => {
                        this.loadingSave = false
                    })
                } else {
                    alert('Please enter all fields.')
                }
                this.commissionValueYear = null
                this.save = true
            },
        },
        computed: {
            getCurrentMonthDays: {
                get(month) {
                    return new Date(this.selectedYear, month, 0).getDate();
                },
                set() {

                }
            },
            showTable() {
                return this.commissions.length > 0 && this.selectedYear && this.selectedCommissionType
            },
            hasPermissionView() {
                return this.$store.getters['user/getPermission'](C_UNIT_COMMISSION_V)
            },
            yearsComputed() {
                return [...Array(4)].map((a, b) => {
                    return {
                        id: Number(new moment().format('YYYY')) - 1 + b,
                        name: Number(new moment().format('YYYY')) - 1 + b
                    }
                })
            }
        },
        watch: {
            '$route.params.unit_id': function () {
                this.commissions = []
                this.commissionValueYear = null
                this.save = false
            },
            selectedCommissionType: {
                handler(value) {
                    this.commissionValueYear = null
                    this.save = false
                    this.commissions = []
                }
            },
            selectedYear: {
                handler(value) {
                    this.commissionValueYear = null
                    this.save = false
                    this.commissions = []
                }
            }
        }

    }
</script>
<style scoped>
</style>
