<template>
    <div v-if="hasPermissionView">
        <div v-if="dataFetched && propertyObject.hasOwnProperty('id')">
            <b-row>
                <b-col>
                    <b-col>
                        <b-row class="mb-2">

                            <app-button
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{property: propertyObject.id, function: C_PROPERTY_CM_PM_PROPERTY_CONNECT_V, distribution: distributionId, key: access_control_fetch_key}"
                                @click="aside_airbnb_setup = true"
                                variant="primary" class="mr-3">
                                {{$t('CONNECT_SETUP')}}
                            </app-button>
                            <app-button
                                :access_control_context="{property: propertyObject.id, function: C_PROPERTY_CM_PM_PROPERTY_PUBLISH_V, distribution: distributionId, key: access_control_fetch_key}"
                                @AccessControlEvent="addToAccessControlCounter()"
                                @click="createProperty"
                                button_type="general"
                                class="mr-3"
                                variant="primary">
                                {{$t('CREATE')}}
                            </app-button>
                            <app-button
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{property: propertyObject.id, function: C_PROPERTY_CM_PM_PROPERTY_SYNC_V, distribution: distributionId, key: access_control_fetch_key}"
                                class="mr-3" button_type="status"
                                @click="sync">Sync
                            </app-button>


                            <edit-property-map class="mr-3"
                                               @AccessControlEvent="addToAccessControlCounter()"
                                               :access_control_context="{property: propertyObject.id, function: C_PROPERTY_CM_PM_PROPERTY_MAPPING_V, distribution: distributionId, key: access_control_fetch_key}"
                                               variant="primary"
                                               @click="propertyMappingPanelState=true">{{$t('MAPPING')}}
                            </edit-property-map>
                            <app-button class="mr-3"
                                        @AccessControlEvent="addToAccessControlCounter()"
                                        :access_control_context="{property: propertyObject.id, function: C_PROPERTY_CM_PM_PROPERTY_HEADLINE_V, distribution: distributionId, key: access_control_fetch_key}"
                                        @click="openPanel"  variant="primary">
                                {{$t('HEADLINE')}}
                            </app-button>
                            <app-button class="mr-3"
                                        @AccessControlEvent="addToAccessControlCounter()"
                                        :access_control_context="{property: propertyObject.id, function: C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_V, distribution: distributionId, key: access_control_fetch_key}"
                                        @click="enablePropertyStatusActive(distributionId)">{{$t('ACTIVATE')}}
                            </app-button>
                            <app-access-control class="mr-3" tag="span" @AccessControlEvent="addToAccessControlCounter()"
                                                :access_control_context="{property: propertyObject.id, function: C_PROPERTY_CM_PM_PROPERTY_DEACTIVATE_V, distribution: distributionId, key: access_control_fetch_key}">
                                <template v-slot:default="props">
                                    <b-tooltip v-if="props.messages.length"
                                               triggers="hover"
                                               :title="props.messages[0].message" :target="'key_'+distributionId">
                                    </b-tooltip>
                                    <app-button
                                        :disabled="props.disabled"
                                        :id="'key_'+distributionId" @click="showDeactivateDialog=true">
                                        {{$t('DEACTIVATE')}}
                                    </app-button>
                                </template>
                            </app-access-control>
                            <app-button :c_permission="c_permission_property_status" :key="index"
                                        v-for="(status, index) in propertyStatusList"
                                        v-if="status.id !== currentStatus.id && mappingPropertyData.id && property_status_activation"
                                        variant="primary"
                                        button_type="string"
                                        class="mr-3"
                                        @click="changePropertyCmStatus(status.id)">
                                {{status.label}}
                            </app-button>
                            <app-button @AccessControlEvent="addToAccessControlCounter()"
                                        :access_control_context="{property: propertyObject.id, function: C_PROPERTY_CM_PM_PROPERTY_GALLERY_DOWNLOAD, distribution: distributionId, key: access_control_fetch_key}"
                                        @click="downloadPropertyDistributionGallery">
                                {{ $t('DOWNLOAD_GALLERY') }}
                            </app-button>

                        </b-row>
                    </b-col>
                    <app-access-control @AccessControlEvent="addToAccessControlCounter()"
                                        :access_control_context="{property: propertyObject.id, function: C_PROPERTY_CM_PM_PROPERTY_STATUS_V, distribution: distributionId, key: access_control_fetch_key}">
                        <b-row class="mb-2">
                            <div>
                                <span v-if="mappingPropertyData.property_eid && distributionId != AIRBNB"> {{$t('EXTERNAL_ID')}}: {{mappingPropertyData.property_eid}}</span>
                                <b-badge class="ml-1" v-if="currentStatus.status_eid" variant="primary">
                                    {{currentStatus.name}}
                                    ({{currentStatus.status_eid}})
                                </b-badge>
                            </div>
                        </b-row>
                    </app-access-control>
                </b-col>
                <app-aside v-model="propertyMappingPanelState">
                    <template slot="header">{{propertyObject ? propertyObject.name: null}}</template>
                    <property-mapping-edit-form :mappingProperty="mappingPropertyData"
                                                @saveMapping="savePropertyMapping"
                                                @deleteMapping="deletePropertyMapping"></property-mapping-edit-form>
                </app-aside>
            </b-row>
            <app-access-control
                @AccessControlEvent="addToAccessControlCounter()"
                :access_control_context="{property: propertyObject.id, function: C_PROPERTY_CM_PM_UNIT_LIST, distribution: distributionId, key: access_control_fetch_key}">
                <template v-if="dataFetched">
                    <form-header>{{$t('UNITS')}}</form-header>
                    <unit-wrapper :unitList="unitList" :unitMappingList="unitMappingList">

                    </unit-wrapper>
                </template>
            </app-access-control>
        </div>
        <modal-dialog :show="showDeactivateDialog" @confirm="disablePropertyStatusActive"
                      @cancel="showDeactivateDialog = false">
            {{$t('DEACTIVATE_PROPERTY_MESSAGE', {distribution_name: distributionObject.name})}}
        </modal-dialog>
        <app-aside :widths="['col-lg-4', 'col-sm-12', '40%']" v-model="headlineAsideState">
            <template slot="header">{{$t("HEADLINE")}}</template>
            <property-headline-edit-form></property-headline-edit-form>
        </app-aside>
        <app-aside :widths="['col-sm-12', '40%']" v-model="aside">
            <template slot="header">
                {{distributionObject.name}} {{$t('ACTIVATION').toLowerCase()}}
            </template>
            <air-bnb-activation-wizard v-if="distributionObject.id === AIRBNB" @activate="activate" :property_id="propertyObject.id" :distribution="distributionObject" :loader="loader"></air-bnb-activation-wizard>
            <distribution-activation-wizard v-else :distribution="distributionObject" @activate="activate"
                                            :property_id="propertyObject.id"
                                            :loader="loader"></distribution-activation-wizard>
        </app-aside>
        <app-aside :widths="['col-lg-4', 'col-sm-12', '40%']" v-model="aside_airbnb_setup">
            <template slot="header">
                {{ $t('CONNECT_SETUP') }}
            </template>
            <airbnb-connect-data-form :connectData="connect_button"></airbnb-connect-data-form>
        </app-aside>
    </div>
</template>

<script>

    import EditPropertyMap from "@/components/app/AppButton/AppButton"
    import FormHeader from "@/components/app/form/FormHeader";
    import {
        getDistributionPropertyMapping,
        propertyCreate,
        setPropertyCmStatus,
        propertySync,
        getObjectStatus,
        saveDistributionPropertyMapping,
        deleteDistributionPropertyMapping, activateProperty, downloadPropertyDistributionGallery
    } from '@/services/channel_manager/index'


    import {notifySuccess, toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import UnitProductManagement from '@/components/channel_manager/product_management/UnitProductManagement'
    import NoData from '@/components/app/AppNoData'
    import {getUnitPaginatedList} from '@/services/unit/index'
    import {BOOKING, EXPEDIA, AIRBNB, HOSTEL_WORLD, HOTEL_BEDS, AGODA} from "@/shared/constants";
    import {
        C_PROPERTY_CM_PM_PROPERTY_V,
        C_PROPERTY_CM_PM_PROPERTY_PUBLISH_V,
        C_PROPERTY_CM_PM_PROPERTY_SYNC_V,
        C_PROPERTY_CM_PM_PROPERTY_STATUS_V,
        C_PROPERTY_CM_PM_PROPERTY_STATUS_E,
        C_PROPERTY_CM_PM_PROPERTY_E,
        C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_V,
        C_PROPERTY_CM_PM_PROPERTY_DEACTIVATE_V,
        C_PROPERTY_CM_PM_PROPERTY_MAPPING_V,
        C_PROPERTY_CM_PM_PROPERTY_HEADLINE_V,
        C_PROPERTY_CM_PM_UNIT_LIST,
        C_PROPERTY_CM_PM_PROPERTY_CONNECT_V,
        C_PROPERTY_CM_PM_PROPERTY_CONNECT_TEST_V,
        C_PROPERTY_CM_PM_PROPERTY_GALLERY_DOWNLOAD,

    } from "@/shared/component_permission";
    import AppAside from '@/components/app/form/AppAside'
    import PropertyHeadlineEditForm from '@/components/channel_manager/headline/PropertyHeadlineForm'
    import PropertyMappingEditForm from '@/components/channel_manager/product_management/PropertyProductManagementEdit'
    import AppButton from '@/components/app/AppButton/AppButton'
    import {
        getUnitActivationStatusList,
        getPropertyActivationStatus,
        getUnitMappingList,
        disablePropertyActivation
    } from "@/services/channel_manager";
    import {AC_CHANNEL_MANAGER} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import ModalDialog from '@/components/app/form/AppConfirmationDialog'
    import {
        EventBus,
        GE_LOADER_HIDE,
        GE_LOADER_SHOW,
        GE_UPDATE_DATA_CHANNEL_MANAGER,
        GE_UPDATE_UNIT_CHANNEL_MANAGER
    } from "@/shared/EventBus";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import UnitWrapper from "@/components/channel_manager/product_management/UnitWrapper";
    import DistributionActivationWizard from "@/components/channel_manager/wizard/DistributionPropertyActivationWizard";
    import AirbnbConnectDataForm from "@/components/channel_manager/product_management/AirbnbConnectDataForm";
    import AirBnbActivationWizard from "@/components/channel_manager/wizard/AirBnbActivationWizard";


    export default {
        name: "property-product_management",
        data() {
            return {
                C_PROPERTY_CM_PM_PROPERTY_PUBLISH_V,
                C_PROPERTY_CM_PM_PROPERTY_SYNC_V,
                C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_V,
                C_PROPERTY_CM_PM_PROPERTY_DEACTIVATE_V,
                C_PROPERTY_CM_PM_PROPERTY_MAPPING_V,
                C_PROPERTY_CM_PM_PROPERTY_HEADLINE_V,
                C_PROPERTY_CM_PM_PROPERTY_STATUS_V,
                C_PROPERTY_CM_PM_UNIT_LIST,
                C_PROPERTY_CM_PM_PROPERTY_CONNECT_V,
                C_PROPERTY_CM_PM_PROPERTY_CONNECT_TEST_V,
                C_PROPERTY_CM_PM_PROPERTY_GALLERY_DOWNLOAD,
                BOOKING,
                EXPEDIA,
                AIRBNB,
                c_permission_property_edit: C_PROPERTY_CM_PM_PROPERTY_E,
                c_permission_property_status: C_PROPERTY_CM_PM_PROPERTY_STATUS_E,
                showOptions: false,
                mappingPropertyData: {
                    id: null,
                    property_id: null,
                    property_eid: null,
                },
                propertyStatusList: [],
                currentStatus: {
                    id: null,
                    name: null,
                    status_eid: null,
                },
                connect_button: {},
                show: false,
                dataFetched: false,
                distributionId: Number(this.$route.params.distribution_id),
                unitList: [],
                headlineAsideState: false,
                propertyMappingPanelState: false,
                property_status_activation: 0,
                access_control_fetch_key: AC_CHANNEL_MANAGER,
                showDeactivateDialog: false,
                access_control_components: 10,
                unitMappingList: [],
                loader: false,
                aside: false,
                contingent_mode: [EXPEDIA, BOOKING, HOSTEL_WORLD,HOTEL_BEDS,AGODA],
                distribution_with_wizard: [BOOKING, EXPEDIA,AIRBNB,HOTEL_BEDS,AGODA],
                aside_airbnb_setup:false,
            }
        },
        mixins: [getErrorMessage, AccessControlComponent],
        components: {
            AirBnbActivationWizard,
            AirbnbConnectDataForm,
            DistributionActivationWizard,
            UnitWrapper,
            AppAccessControl,
            EditPropertyMap,
            UnitProductManagement,
            NoData,
            AppAside,
            PropertyHeadlineEditForm,
            PropertyMappingEditForm,
            FormHeader,
            AppButton,
            ModalDialog
        },
        computed: {
            propertyObject() {
                return this.$store.getters['property/getProperty']
            },
            hasPermissionView() {
                return this.dataFetched && this.$store.getters['user/getPermission'](C_PROPERTY_CM_PM_PROPERTY_V)
            },

            distributionObject() {
                return this.$store.getters['channel_manager/getDistributionById'](this.distributionId)
            },
        },
        methods: {
            connectProperty() {
                window.open(this.connect_button.connect_url, "_blank");
            },
            openPanel() {
                this.headlineAsideState = true
            },
            createProperty() {
                EventBus.$emit(GE_LOADER_SHOW)
                propertyCreate({
                    company: this.propertyObject.company.id,
                    property: this.propertyObject.id,
                    distributions: [
                        Number(this.distributionId)
                    ]
                })
                    .then(() => {
                        toast({
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'type': 'success',
                            'timeout': 7000
                        })
                    }, error => {
                        toast({
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 7000
                        })
                    }).finally(() => {
                    EventBus.$emit(GE_LOADER_HIDE)
                })
            },
            changePropertyCmStatus(statusId) {
                EventBus.$emit(GE_LOADER_SHOW)
                setPropertyCmStatus({
                    distribution: this.$route.params.distribution_id,
                    property: this.$route.params.id,
                    status: statusId
                })
                    .then(() => {
                            toast({
                                'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                                'type': 'success',
                                'timeout': 7000
                            })
                            this.getCmPropertyStatus()
                        }
                        , error => {
                            toast({
                                'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                                'type': 'error',
                                'timeout': 7000
                            })
                        }).finally(() => {
                    EventBus.$emit(GE_LOADER_HIDE)
                })
            },

            sync() {
                EventBus.$emit(GE_LOADER_SHOW)
                propertySync({
                    company: this.propertyObject.company.id,
                    property: this.propertyObject.id,
                    distributions: [
                        this.distributionId
                    ]
                })
                    .then(() => {
                            toast({
                                'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                                'type': 'success',
                                'timeout': 7000
                            })
                        }
                        , error => {
                            toast({
                                'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                                'type': 'error',
                                'timeout': 7000
                            })
                        }).finally(() => {
                    EventBus.$emit(GE_LOADER_HIDE)
                })
            },
            async getCmPropertyStatus() {
                EventBus.$emit(GE_LOADER_SHOW)
                await getObjectStatus({
                    object: this.$route.params.id,
                    distribution: this.distributionId,
                    object_type: 10

                }).then(response => {
                    this.propertyStatusList = response.data.next_status_list
                    this.currentStatus = response.data.current_status
                }).finally(() => {
                    EventBus.$emit(GE_LOADER_HIDE)
                })
            },
            async getPropertyMapping(distribution) {
                EventBus.$emit(GE_LOADER_SHOW)
                await getDistributionPropertyMapping(distribution, this.$route.params.id).then(response => {
                    this.show = true
                    this.mappingPropertyData = response.data
                }).finally(() => {
                    EventBus.$emit(GE_LOADER_HIDE)
                })
            },
            savePropertyMapping(value) {
                this.propertyMappingPanelState = false
                saveDistributionPropertyMapping(Number(this.$route.params.distribution_id), this.propertyObject.id, {property_eid: value})
                    .then(response => {

                            toast({

                                'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                                'type': 'success',
                                'timeout': 7000
                            })

                            this.mappingPropertyData = response.data
                        }, error => {
                            toast({
                                'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                                'type': 'error',
                                'timeout': 6000
                            })
                        }
                    )
            },
            deletePropertyMapping(value) {
                this.propertyMappingPanelState = false
                deleteDistributionPropertyMapping(this.$route.params.distribution_id, value)
                    .then(() => {
                            toast({
                                'message': this.$t('NOTIFICATIONS.DELETE_SUCCESS.MESSAGE'),
                                'type': 'success',
                                'timeout': 7000
                            })
                            this.mappingPropertyData.property_eid = null
                            this.mappingPropertyData.id = null
                            this.showDeleteDialog = false
                        }, error => {
                            toast({
                                'message': this.$t('NOTIFICATIONS.DELETE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                                'type': 'error',
                                'timeout': 6000
                            })
                        }
                    )
            },
            getListUnitMapping(distribution, unitList) {
                let unit_ids = this.getUnitIds(unitList)
                if (unit_ids === null) {
                    this.unitMappingList = []
                    return
                }
                getUnitMappingList({unit: unit_ids, distribution}).then(response => {
                    this.unitMappingList = response.data
                })
            },
            getUnitIds(unitList) {
                let unitIds = []
                if (unitList.length === 0) {
                    return null
                }
                for (let unit of unitList) {
                    unitIds.push(unit.id)
                }
                return unitIds
            },
            getUnitActiveStatusList(distribution_id, unitList) {
                let unit_ids = this.getUnitIds(unitList)
                EventBus.$emit(GE_LOADER_SHOW)
                getUnitActivationStatusList({
                    property: Number(this.$route.params.id),
                    distribution: Number(distribution_id),
                    unit: unit_ids
                }).then(response => {
                    this.dataFetched = true
                    for (let unitObject of unitList) {
                        for (let status of response.data) {
                            if (Number(unitObject.id) === Number(status.unit)) {
                                unitObject.active = status.active
                            }
                        }
                    }
                    this.unitList = unitList
                }, () => {
                    this.unitList = unitList
                }).finally(() => {
                    EventBus.$emit(GE_LOADER_HIDE)
                })
            },
            getPropertyActivateStatus(distribution) {
                EventBus.$emit(GE_LOADER_SHOW)
                getPropertyActivationStatus({property: Number(this.$route.params.id), distribution})
                    .then(response => {
                        this.property_status_activation = response.data.active
                        this.connect_button = response.data.connect_button
                    }).finally(() => {
                    EventBus.$emit(GE_LOADER_HIDE)
                })
            },
            enablePropertyStatusActive(distribution) {
                if (this.distribution_with_wizard.includes(distribution)) {
                    this.aside = true
                    return
                } else {
                    this.activate({distribution, property:  Number(this.$route.params.id)})
                }
            },
            activate(request) {
                this.loader = true
                activateProperty(request).then(() => {
                    toast({
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                    this.aside = false
                    this.setData(this.distributionId)
                }, () => {
                    toast({
                        'title': this.$t('ERROR'),
                        'message': this.$t('ERROR_ACTIVATION'),
                        'type': 'error',
                        'timeout': 3000
                    })
                }).finally(() => {
                    this.loader = false
                })
            },
            disablePropertyStatusActive() {
                disablePropertyActivation({
                    property: Number(this.$route.params.id),
                    distribution: Number(this.distributionId)
                }).then(response => {
                    toast({
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                    this.property_status_activation = response.data.active
                    this.setData(this.distributionId)

                }, error => {
                    toast({
                        'message': this.getMessages(error),
                        'type': 'error',
                        'timeout': 2000
                    })
                })
                this.showDeactivateDialog = false
            },

            getUnitList(distribution_id) {
                EventBus.$emit(GE_LOADER_SHOW)
                let contingent = 1
                if (this.contingent_mode.includes(Number(distribution_id))) {
                    contingent = 0
                }
                getUnitPaginatedList({property_id: this.$route.params.id, contigent: contingent, limit: 1000})
                    .then(response => {
                        this.getUnitActiveStatusList(distribution_id, response.data.items)
                        this.getListUnitMapping(distribution_id, response.data.items)
                    }, () => {
                        this.unitList = []
                    }).finally(() => {
                    setTimeout(() => {
                        EventBus.$emit(GE_LOADER_HIDE)
                    }, 1000)

                })
            },
            setData(distribution_id) {
                this.dataFetched = false
                this.getPropertyActivateStatus(distribution_id)
                this.getPropertyMapping(distribution_id)
                this.getCmPropertyStatus(distribution_id)
                this.getUnitList(distribution_id)

            },
            downloadPropertyDistributionGallery() {
                EventBus.$emit(GE_LOADER_SHOW)
                const requestParameters = {distribution: this.distributionId, property: this.$route.params.id}
                const distributionName = typeof this.distributionObject === 'object' && this.distributionObject.hasOwnProperty('name') && this.distributionObject.name.trim().length > 0 ?
                    this.distributionObject.name.trim().replaceAll(/[\s-.]+/g, '_').toLowerCase() : 'distribution_' + this.distributionId
                const filename = 'property_' + this.$route.params.id + '_' + distributionName + '_gallery'
                downloadPropertyDistributionGallery(requestParameters, filename).then(() => {
                    notifySuccess()
                }, (error) => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    EventBus.$emit(GE_LOADER_HIDE)
                })
            },
        },
        mounted() {
            EventBus.$on(GE_UPDATE_DATA_CHANNEL_MANAGER, (distribution) => {
                this.setData(distribution)
            })
            EventBus.$on(GE_UPDATE_UNIT_CHANNEL_MANAGER, (data) => {
                let index = this.unitMappingList.findIndex(el => el.unit_id === data.unit_id)
                if (index > -1) {
                    this.$set(this.unitMappingList, index, data.unit_mapping)
                } else {
                    this.unitMappingList.push(data.unit_mapping)
                }
            })
        },
        watch: {
            '$route.params.distribution_id': {
                async handler(value) {
                    this.distributionId = Number(value)
                    await this.setData(value)

                },
                immediate: true
            },
        },
        beforeDestroy() {
            EventBus.$off(GE_UPDATE_UNIT_CHANNEL_MANAGER)
            EventBus.$off(GE_UPDATE_DATA_CHANNEL_MANAGER)
        }

    }
</script>

<style scoped>
    .input-width {
        max-width: 220px;
    }

    .status-m {
        margin-top: 0.6rem;
    }
</style>
