<template>
    <div>
        <div class="table-wrapper">
            <table class="table">
                <thead>
                <tr>
                    <th class="table-header-period-range" scope="col">{{ $t('PERIOD') }}</th>
                    <th class="table-header-period-range" scope="col"></th>
                    <th class="table-header-actions text-right" scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <period-rate-mask-item :key="period.period_start + '_' + period.deleted"
                                       v-for="(period, periodIndex) in periodList"
                                       v-show="period.deleted !== 1"
                                       :period="formattedPeriod(period)"
                                       @update="updatePeriod($event, periodIndex)"
                                       :wide_display="true"
                                       :disabled="visiblePeriodList.length === 1"
                                       @delPeriod="removePeriod($event, periodIndex)"></period-rate-mask-item>
                <period-rate-mask-item-new @save="addPeriod"
                                           :wide_display="true"
                                           :loading="loading_add_new"></period-rate-mask-item-new>
                </tbody>
            </table>
        </div>
    </div>

</template>

<script>

import {getErrorMessage} from '@/mixins/error/getErrorMessage'
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import SubmitButton from "@/components/app/AppButton/AppButtonSubmit";
import PeriodRateMaskItem from "@/components/pricing/rate_plan/period/PeriodRateMaskItem";
import PeriodRateMaskItemNew from "@/components/pricing/rate_plan/period/PeriodRateMaskItemNew";
import {C_UNIT_CALENDAR_PERIODS_E} from "@/shared/component_permission";
import {cloneDeep} from "lodash";
import moment from "moment";

export default {
    name: "PeriodRateMaskList",
    components: {
        PeriodRateMaskItem, PeriodRateMaskItemNew, SubmitButton, AppConfirmationDialog
    },
    mixins: [getErrorMessage],
    data() {
        return {
            fields: [
                {key: "period", label: this.$t('PERIOD')},
                {key: "linked-value", label: ""}
            ],
            loading_add_new: false,
            saving: false,
            confirmation_dialog_state: false,
            periods_data_list: [],
            C_UNIT_CALENDAR_PERIODS_E,
            periodList: []
        }
    },
    props: {
        unit_id: {
            type: Number,
            default: null
        },
        maskSetup: {
            type: Array
        }
    },
    computed: {
        visiblePeriodList() {
            return this.periodList.filter(period => {
                return !period.hasOwnProperty('deleted') || period.deleted === undefined || Number(period.deleted) === 0
            })
        },
    },
    watch: {
        periodList: {
            handler(value) {
                this.$emit('update', value)
            },
            deep: true,
        },
    },
    methods: {
        removePeriod(period, periodIndex) {
             this.periodList.splice(periodIndex, 1)
        },
        addPeriod(period) {
            this.loading_add_new = true
            let period_id = 'index_' + this.periodList.length
            this.periodList.push({
                id: period_id,
                period_start: period.start,
                period_end:  period.end,
                value: period.value,
                deleted: 0
            })
            this.$nextTick(() => this.loading_add_new = false)
        },
        save() {
            this.saving = true
            let period_list = []
            if (this.$refs.period_items) {
                this.$refs.period_items.forEach(period_item => {
                    let period_data = period_item.$data.selectedPeriod
                    let periodObject = {
                        id: period_data.id,
                        period_start: period_data.range.start,
                        period_end: period_data.range.end,
                        linked_value: period_data.linked_value,
                        deleted: period_data.deleted
                    }
                    if (isNaN(period_data.id)) {
                        if (period_data.deleted === 0) {
                            period_list.push(periodObject)
                        }
                    } else {
                        period_list.push(periodObject)
                    }
                })
            }
        },
        formattedPeriod(period) {
            let formattedPeriod = cloneDeep(period)
            formattedPeriod.period_start = moment(period.period_start, 'MM/DD')
            formattedPeriod.period_end = moment(period.period_end, 'MM/DD')
            return formattedPeriod
        },
        updatePeriod(period, index) {
            this.periodList[index] = period
             //this.$set(this.periodList, index, period)
        },
    },
    mounted() {
        this.periodList = cloneDeep(this.maskSetup)
    }
}
</script>

<style scoped>
.table-wrapper {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.table-header-period-range {
    width: 50%;
}

.table-header-actions {
    width: 50%;
}
</style>
