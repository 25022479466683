<template>
    <div class="position-relative">
        <b-row v-if="showNewButton">
            <b-col class="mb-4">
                <b-tooltip v-if="disabledNewGuest" :title="errorResponseMessage"
                           target="new_guest"
                           triggers="hover"></b-tooltip>
                <app-button id="new_guest" :disabled="disabledNewGuest" button_type="new" variant="primary"
                            @click="$emit('openGuestSideBar')" class="pull-right">{{$t('NEW_GUEST')}}
                </app-button>
            </b-col>
        </b-row>
        <app-table-v2  @fetched="updateItems" class="reservation_table" id="guestTable" :search-on-create="!hasFilter" :fields="filteredFields" :provider="getGuestListData"
                      :selectable="true"
                      :show-limit="hasFilter"
                      :show-sort="hasFilter"
                      :exportColumns="exportColumns"
                      @busyToggled="toggleBusy"
                      :filter="filter"
                      :maxExportLimit="1000"
                      :exportEndpoint="guestExportList"

        >
            <template v-slot:check_in_date="data">
                <small class="text-muted d-block">{{data.item.check_in_time|time}}</small>
                {{data.item.check_in_date|date}}
            </template>
            <template v-slot:check_out_date="data">
                <small class="text-muted d-block">{{data.item.check_out_time|time}}</small>
                {{data.item.check_out_date|date}}
            </template>
            <template v-slot:registered="data">
                <template v-if="data.item.registered">
                    {{$t('REGISTERED')}}
                </template>
                <template v-else-if="!data.item.registered">
                    {{$t('NOT_REGISTERED')}}
                </template>
            </template>
            <template v-slot:edit="data">
                <app-button
                    @click="editGuest(data.item.id)"
                    :c_permission="C_RESERVATION_ACCOMMODATION_GUEST_LIST_E"
                    variant="link"
                    button_type="edit"
                    :show_text="false">
                </app-button>
            </template>

            <template v-slot:tableNoData="props">
                <app-no-data :show-tip="false" :heading="$t('NO_SEARCH_RESULTS')">
                    <b-tooltip v-if="disabledNewGuest" :title="errorResponseMessage"
                               target="new_guest"
                               triggers="hover"></b-tooltip>
                    <app-button id="new_guest" :disabled="disabledNewGuest" button_type="new" v-if="fromReservation" @click="$emit('openGuestSideBar')">
                        {{ $t('NEW_GUEST') }}
                    </app-button>
                </app-no-data>
            </template>
        </app-table-v2>


        <app-aside :widths="['col-sm-6']" v-model="guest_aside_panel">
            <template slot="header">{{$t('GUEST')}}</template>
            <guest-form :formData="formData" :guest_id="guest_id"
                        @updateGuest="updateGuestList"></guest-form>
        </app-aside>
    </div>
</template>

<script>
    import AppAside from '@/components/app/form/AppAside'
    import AppNoData from "@/components/app/AppNoData";
    import AppSearchData from "@/components/app/AppSearchData";
    import AppButton from "@/components/app/AppButton/AppButton";
    import GuestForm from "@/components/guest/forms/GuestUpdateForm"
    import {C_RESERVATION_ACCOMMODATION_GUEST_LIST_E} from "@/shared/component_permission";
    import {getGuestFormData, getGuestList , guestExportList} from '@/services/guest/index'
    import {notifySuccess, toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import AppTableV2 from "@/components/app/AppTableV2";
    import {EventBus, GE_TABLE_SEARCH} from "@/shared/EventBus";


    export default {
        name: "guestListTable",
        components: {AppTableV2, AppButton, AppSearchData, AppNoData, AppAside, GuestForm},
        mixins: [getErrorMessage],
        props: {
            filter: {
                type: Object,
                default: null
            },
            hasFilter:{
                type: Boolean,
                default: true
            },
            fromReservation:{
                type:Boolean,
                default:false
            },
            disabledNewGuest:{
                type:Boolean,
                default:false
            },
            errorResponseMessage:{
                type:String,
            }
        },
        data() {
            return {
                C_RESERVATION_ACCOMMODATION_GUEST_LIST_E,
                fields: [
                    {key: 'id', label: this.$i18n.t('ID')},
                    {key: 'distribution_reservation_id', label: this.$i18n.t('RESERVATION_CODE')},
                    {key: 'first_name', label: this.$i18n.t('FIRST_NAME')},
                    {key: 'last_name', label: this.$i18n.t('LAST_NAME')},
                    {key: 'check_in_date', label: this.$i18n.t('CHECKIN'), sortable:true, tdClass:'remove_padding'},
                    {key: 'check_out_date', label: this.$i18n.t('CHECKOUT'), sortable:true, tdClass:'remove_padding'},
                    {key: 'country_of_residence', label: this.$i18n.t('COUNTRY')},
                    {key: 'email', label: this.$i18n.t('EMAIL')},
                    {key: 'phone', label: this.$i18n.t('PHONE')},
                    {key: 'registered', label: this.$i18n.t('STATUS')},
                    {key: 'edit', label: '', class: 'text-right'},
                ],
                exportColumns:[
                    {value: 'property_name', text: this.$t('PROPERTY')},
                    {value: 'owner_name', text: this.$t('OWNER')},
                    {value: 'facility_code', text: this.$t('RESERVATION_CODE')},
                    {value: 'period_start', text: this.$t('PERIOD_START')},
                    {value: 'period_end', text: this.$t('PERIOD_END')},
                    {value: 'guest_id', text: this.$t('ID')},
                    {value: 'register_status', text: this.$t('STATUS')},
                    {value: 'reservation_code', text: this.$t('RESERVATION_CODE')},
                    {value: 'check_in_date', text: this.$t('CHECKIN')},
                    {value: 'check_in_time', text: this.$t('CHECKIN_TIME')},
                    {value: 'check_out_date', text: this.$t('CHECKOUT')},
                    {value: 'check_out_date', text: this.$t('CHECKOUT_TIME')},
                    {value: 'guest_first_name', text: this.$t('FIRST_NAME')},
                    {value: 'guest_last_name', text: this.$t('LAST_NAME')},
                    {value: 'guest_email', text: this.$t('EMAIL')},
                    {value: 'guest_phone', text: this.$t('PHONE')},
                    {value: 'guest_date_of_birth', text: this.$t('DATE_OF_BIRTH')},
                    {value: 'guest_gender', text: this.$t('GENDER')},
                    {value: 'guest_citizenship', text: this.$t('CITIZENSHIP')},
                    {value: 'guest_document_number', text: this.$t('DOCUMENT_NUMBER')},
                    {value: 'guest_document_type', text: this.$t('DOCUMENT_TYPE')},
                    {value: 'guest_tax_payment_category', text: this.$t('TOURIST_TAX_PAYMENT_CATEGORY')},
                    {value: 'guest_tax_payment_value', text: this.$t('CITY_TAX')},
                    {value: 'guest_tax_payment_total', text: this.$t('TOTAL_VAT')},
                ],
                guest_aside_panel: false,
                guest_aside_panel_new: false,
                guest_id: null,
                owner_id: null,
                formData: {},
                omittedExportFields:[],
                showNewButton:false,
                guestExportList
            }
        },
        computed:{
            filteredFields(){
                return this.hasFilter ? this.fields : this.fields.filter(el => el.key !== 'distribution_reservation_id')
            }
        },
        methods: {
            updateItems(items){
                this.showNewButton = items.length > 0 && this.fromReservation
            },
            toggleBusy($event){
                this.$emit('busyToggled', $event)
            },
            updateGuestList() {
                this.guest_aside_panel = false
                EventBus.$emit(GE_TABLE_SEARCH)
            },
            editGuest(id) {
                this.guest_id = id
                getGuestFormData({guest: id}).then(response => {
                    this.formData = response.data
                    this.guest_aside_panel = true
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.getMessages(error),
                        'type': 'error',
                        'timeout': 6000
                    })
                })
            },
            getGuestListData(filter) {

                let req = {
                    ...this.filter,
                    ...{
                        page: filter.currentPage,
                        limit: filter.perPage,
                        orderBy: filter.sortBy,
                        orderDirection: filter.sortDirection
                    }
                }

                return getGuestList(req)
            }
        },

        watch: {
            filter: {
                handler() {
                    EventBus.$emit(GE_TABLE_SEARCH)
                },
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>
