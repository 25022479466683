<template>
    <div class="main_calendar_test__holder--content--pricing_list main_calendar_test-bb animated fadeIn">
        <template  v-for="(losItem, indexLos) in los">
            <template  v-for="occupancyItem in occupancy">
                <div ref="calendar_scroll_container" :class="classLosItem(indexLos)" class="main_calendar__holder--content--pricing_list-item" :style="`grid-template-columns: repeat(${dateList.length}, 2.87rem)`">
                    <table-rates-cell
                        :key="`rp_${ratePlan}_los_${losItem.id}_occ_${occupancyItem.id}_day_${dateIndex}`"
                        v-for="(date, dateIndex) in dateList"
                        :cell-data="getObjectData(date,losItem, occupancyItem)"
                        :highlighted="isHighlighted(date)"
                        @startEndDrag="dragStartEnd(date)"
                        @drag="drag(date)"
                        @currentDrag="dragCurrent(date)"
                     >
                    </table-rates-cell>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    import TableRatesCell from "@/components/unit/calendar_opt/Table/Rates/TableRatesCell";
    import {EventBus, GE_CALENDAR_OPEN_PRICES} from "@/shared/EventBus";
    import {CalendarRowMixin} from "@/mixins/calendar/CalendarRowMixin";
    import {C_PROPERTY_CALENDAR_PRICES_STARTING_PRICES} from "@/shared/component_permission";
    export default {
        name: "TableRatesLosAndOccupancyContent",
        mixins:[CalendarRowMixin],
        components: {TableRatesCell},
        props: {
            scrollLeftPosition:{
                type: Number
            },
            los: {
                type: Array
            },
            occupancy: {
                type: Array
            },
            dateList: {
                type: Array
            },
            pricing: {
                type: Object
            },
            suggestions: {
                type: Object
            },
            unit: {
                type: Object
            },
            dateRange: {
                type: Object
            },
            ratePlan: {
                type: Number
            },
            shiftKeyValue: {
                type: Boolean
            },
            pricingDefaults: {
                type: Object,
                default() {
                    return null
                },
            }
        },
        data() {
            return {
                currentDragDate: null,
                dragPeriod: {
                    start: null,
                    end: null
                },
                cellObjectData:[]

            }
        },
        methods: {

            isHighlighted(date){

                return (this.shiftKeyValue && this.dragPeriod.start && this.currentDragDate)
                    && ((date >= this.dragPeriod.start && date <= this.currentDragDate) ||
                        (date <= this.dragPeriod.start && date >= this.currentDragDate)
                    )
            },
            classLosItem(indexLos){

                return  this.isOdd(indexLos) ? 'main_calendar_test--background_gray' : ''

            },
            isOdd(num) { return num % 2;},
            dragCurrent(date) {
                this.currentDragDate = date
            },
            drag(date) {
                if (this.ratePlan === this.unit.rate_plan) {
                    if (!this.dragPeriod.start) {
                        this.dragPeriod.start = date
                    } else if (!this.dragPeriod.end) {
                        this.dragPeriod.end = date
                        this.openAside()
                    }
                }
            },
            dragStartEnd(date) {
                this.dragPeriod.start = date
                this.dragPeriod.end = date
                this.openAside()
            },
            openAside() {
                if (this.ratePlan === this.unit.rate_plan) {
                    if (this.dragPeriod.start > this.dragPeriod.end) {
                        this.dragPeriod = {
                            start: this.dragPeriod.end,
                            end: this.dragPeriod.start
                        }
                    }

                    EventBus.$emit(GE_CALENDAR_OPEN_PRICES, {
                        unitId: this.unit.id,
                        tab: 2,
                        dateRange: {
                            start: this.dragPeriod.start.format("YYYY-MM-DD"),
                            end: this.dragPeriod.end.format("YYYY-MM-DD")
                        }
                    })
                }
                this.dragPeriod.start = null
                this.dragPeriod.end = null
                this.currentDragDate = null
            },
            getObjectData(date, los, occupancy){
                    const price = this.getPrice(date,los,occupancy)
                    const startingPrice = this.getStartingPrice(date, los, occupancy)
                    return {
                        price: price ? price : null,
                        starting_price: startingPrice ? startingPrice : null,
                        lastDayOfMonthClass: {'main_calendar_test-br_green': date.date() === date.daysInMonth()},
                        los,
                        occupancy
                    }
            },
            getPrice(date,los,occupancy){

                if (!this.pricing) {
                    return null
                }
                let pricingItem = this.pricing[date.format("YYYY-MM-DD")]

                if (!pricingItem) {
                    return null
                }

                let pricingObject = pricingItem[`${this.ratePlan}-${los.id}-${occupancy.id}`]
                return pricingObject ? pricingObject : null
            },
            getStartingPrice(date, los, occupancy) {
                if (!this.pricingDefaults || Number(los.default) !== 1 || Number(occupancy.default) !== 1 || !this.checkPermission(C_PROPERTY_CALENDAR_PRICES_STARTING_PRICES)) {
                    return null
                }
                let pricingItem = this.pricingDefaults[date.format("YYYY-MM-DD")]

                if (!pricingItem) {
                    return null
                }

                const amount = pricingItem[`${los.id}-${occupancy.id}`]
                return amount ? amount : null
            },
        },
        watch: {
            shiftKeyValue(val) {
                if (val === false) {
                    this.dragPeriod.start = null
                    this.dragPeriod.end = null
                    this.currentDragDate = null
                }
            },

        },
        created() {
         //   this.populateData()

        }
    }
</script>

<style scoped>

</style>
