<template>
    <b-modal style="overflow-y: auto;" v-model="value" @hide="updateValue" no-close-on-esc hide-header-close>
        <div slot="modal-header">
            <h5 class="modal-title">
                <slot name="title">
                    {{$t('STATUS_CHANGE_CONFIRMATION')}}
                </slot>
            </h5>
        </div>

        <slot name="content">
            <span>
                {{$t('CHANGE_STATUS_TRANSITION_MSG',{current, next})}}
            </span>
        </slot>

        <b-form-textarea v-if="showMessageBox"
                         class="mt-2 mb-2"
                         :placeholder="$t('MESSAGE')"
                         :value="message"
                         @input="updateMessage($event)"
                         rows="2">
        </b-form-textarea>

        <div slot="modal-footer">
            <app-button :loading="loader" class="mr-3" variant="primary" @click="confirm">{{$t('CONFIRM')}}</app-button>
            <app-button @click="cancel">{{$t('CANCEL')}}</app-button>
        </div>
    </b-modal>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";

    export default {
        name: "app-status-change-confirmation-dialog",
        components: {AppButton},
        props: {
            value: Boolean,
            id: {
                default: null
            },
            current: {
                default: null
            },
            next: {
                default: null
            },
            showMessageBox: {
                type: Boolean,
                default: false
            },
            loader:{
                type:Boolean
            }
        },
        data() {
            return {
                message: null
            }
        },
        methods: {
            confirm() {
                this.$emit('confirm', this.id)
                this.message = null
            },
            cancel() {
                this.$emit('cancel', this.id)
                this.message = null
            },
            updateMessage(value) {
                this.$emit('updatedMessage', value)
            },
            updateValue(event) {
                if (event.trigger === 'backdrop') {
                    event.preventDefault()
                    this.$emit('input', false)
                }
            }
        }
    }
</script>

<style scoped>

</style>
