<template>
    <div>
        <app-table-v2
                id="inbox_table"
                class="reservation_table"
                search-event="InboxSearchTable"
                :selectable="true"
                @busyToggled="handleBusy"
                :additional-sort-options="additionalSortOptions"
                :fields="fields" :provider="getData" :search-on-create="true">
            <template v-slot:distribution="data">
                {{ data.item.distribution.name}}
            </template>
            <template v-slot:reservation.checkin_date="data">


                    <small class="text-muted d-block" v-if="data.item.reservation.hasOwnProperty('num_nights') && data.item.reservation.num_nights">

                        {{data.item.reservation.num_nights}} {{ data.item.reservation.num_nights > 1 ? $t('NIGHTS').toLowerCase() :
                        $t('NIGHT').toLowerCase()}} {{data.item.reservation.checkin_time ? `| ${formatTime(data.item.reservation.checkin_time)}` :
                        ''}}

                    </small>
                        <span v-if="data.item.reservation.checkin_date">
                             {{ data.item.reservation.checkin_date|date | date(false) }} - {{ data.item.reservation.checkout_date|date}}
                        </span>

            </template>
            <template v-slot:type="data">
                {{data.item.type.label}}
            </template>
            <template v-slot:subject="data">
                <small class="d-block text-muted">{{data.item.type.label}}</small>
                <div v-if="_has(data.item, 'last_message')">{{getShortMessage(data.item.last_message)}}</div>
            </template>

            <template v-slot:status="data">

                <div v-if="_has(data.item, 'last_message.status.name')">{{data.item.last_message.status.name}}</div>
            </template>

            <template v-slot:timestamp="data">
                <small class="text-muted d-block">
                    {{data.item.updated_at | time}}
                </small>
                {{data.item.updated_at|date}}
            </template>
            <template v-slot:edit="data">
                <app-button
                        @click="showAside(data.item)"
                        variant="link"
                        icon="edit"
                        button-class="action_button"
                        :show_text="false">
                </app-button>
            </template>

            <template v-slot:selectedContainer="data">
                <app-button @click="syncMessages(data.selected)" :loading="syncLoading" class="mr-3">
                    {{$t('SYNC')}}
                </app-button>
                <app-button @click="showModal=true">
                    {{$t("STATUS")}}
                </app-button>

                <b-modal :title="$t('STATUS')" v-model="showModal">
                    <div class="d-flex justify-content-center">
                        <b-form-radio-group
                                v-model="readUnreadAction"
                                :options="readUnreadActionOptions"
                                name="radio-inline"
                        ></b-form-radio-group>
                    </div>
                    <template v-slot:modal-footer>
                        <div class="w-100 d-flex justify-content-end align-items-center">
                            <app-button @click="saveReadUnreadAction(data.selected)" :loading="readUnreadLoading"
                                        :disabled="!readUnreadAction" class="mr-3">
                                {{$t('SAVE')}}
                            </app-button>
                        </div>
                    </template>
                </b-modal>
            </template>


        </app-table-v2>

        <app-aside :widths="['col-xl-4','col-lg-4', 'col-sm-12']" v-model="asideSupport" :scroll="false" >
            <template slot="header" v-if="_has(threadObject, 'distribution.name')">

                <inbox-header :object="threadObject "></inbox-header>

            </template>
            <b-row>

                <b-col lg="12" v-if="threadObject">
                    <inbox-messages @refreshThread="getThreadObject" :thread="threadObject"></inbox-messages>
                </b-col>
            </b-row>
        </app-aside>
        <app-aside :widths="['col-xl-7','col-lg-8', 'col-sm-12', '40%']"  v-model="aside" :scroll="false" >
            <template slot="header" v-if="_has(threadObject, 'distribution.name')">

                <inbox-header :object="threadObject "></inbox-header>

            </template>
            <b-row>
                <b-col lg="6" class="pb-3" v-if="_has(threadObject, 'show.unit') && threadObject.show.unit">
                    <div class="inbox_res" style="height: 88%">

                        <information-module mode="inbox" :threadObject="threadObject" :unit-id="threadObject.unit.id"></information-module>
                    </div>
                </b-col>
                <b-col lg="6" v-if="threadObject">
                    <inbox-messages @refreshThread="getThreadObject" :thread="threadObject"></inbox-messages>
                </b-col>
            </b-row>
        </app-aside>
        <app-aside :widths="['col-xl-7','col-lg-8', 'col-sm-12', '40%']" v-model="asideWithRes" :scroll="false">
            <template slot="header">
                <reservation-accommodation-header
                    mode="inbox"
                    :reservation="reservationObject">
                </reservation-accommodation-header>

            </template>
            <b-row>
                <b-col lg="6" class="pb-3"
                       v-if="_has(threadObject, 'show.reservation') && threadObject.show.reservation">
                <div class="inbox_res">
                    <information-module mode="inbox" :threadObject="threadObject" :reservation-id="reservationObject.id"></information-module>
                </div>

                </b-col>
                <b-col lg="6" v-if="threadObject">
                    <inbox-messages :thread="threadObject"></inbox-messages>
                </b-col>
            </b-row>


        </app-aside>
    </div>
</template>

<script>
    import AppTableV2 from "@/components/app/AppTableV2";
    import {getThreadList, resyncThreads, changeMessageStatus,fetchThread} from "@/services/inbox";
    import {EventBus, GE_TABLE_REFRESH_SEARCH} from '@/shared/EventBus'
    import {notifySuccess} from '@/shared/plugins/toastr'
    import AppButton from "@/components/app/AppButton/AppButton";
    import {cloneDeep} from 'lodash'
    import AppAside from "@/components/app/form/AppAside";
    import InboxMessages from "@/components/inbox/InboxMessages";
    import {has, get} from "lodash"
    import InboxReservation from "@/components/inbox/InboxReservation";
    import {MESSAGE_STATUS_READ, MESSAGE_STATUS_UNREAD} from "@/shared/constants";
    import InboxProperty from "@/components/inbox/InboxProperty";
    import {fetchAccommodationReservation} from "@/services/accommodation_reservation";
    import {getProperty} from "@/services/property";
    import ReservationAccommodationHeader from "@/components/reservation/accommodation/ReservationAccommodationHeader";
    import InformationModule from "@/components/reservation/accommodation/modules/InformationModule";
    import InboxHeader from "@/components/inbox/InboxHeader";

    export default {
        name: "InboxTable",
        components: {
            InboxHeader,
            InformationModule,
            ReservationAccommodationHeader,
            InboxProperty, InboxReservation, InboxMessages, AppAside, AppButton, AppTableV2},
        props: {
            filter: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                showModal: false,
                readUnreadAction: null,
                readUnreadActionOptions: [
                    {text: this.$t("MARK_AS_UNREAD"), value: MESSAGE_STATUS_UNREAD},
                    {text: this.$t("MARK_AS_READ"), value: MESSAGE_STATUS_READ},
                ],
                readUnreadLoading: false,
                syncLoading: false,
                aside: false,
                asideWithRes: false,
                asideWidth: ['col-lg-3', 'col-sm-12', '40%'],
                reqFilter: {},
                fields: [
                    {key: 'id', label: this.$t('ID')},
                    {key: 'distribution', label: this.$t('DISTRIBUTION'), tdClass: 'remove_padding'},
                    {key: 'property.name', label: this.$t('PROPERTY')},
                    {key: 'reservation.reservation_code', label: this.$t('RESERVATION_CODE')},
                    {key: 'reservation.checkin_date', label: this.$t('PERIOD')},
                    {key: 'subject', label: this.$t('SUBJECT'), tdClass: 'remove_padding'},
                    {key: 'status', label: this.$t('STATUS')},
                    {key: 'timestamp', label: this.$t('LAST_CHANGE'), tdClass: 'remove_padding'},
                    {key: 'edit', label: "", class: 'text-right ws-no-wrap'},
                ],
                reservationObject:{},
                propertyObject:{},
                threadObject:{},
                headerObject:{},
                additionalSortOptions: [
                    {key: 'id', label: this.$t('ID')},
                    {key: 'arrival_at', label: this.$t('CHECK_IN')},
                    {key: 'departure_at', label: this.$t('CHECK_OUT')},
                    {key: 'updated_at', label: this.$t('LAST_CHANGE')},
                ],
                support_type:6,
                asideSupport:false,
                STATUS_UNREAD:50,
                STATUS_CREATED:52
            }
        },
        computed: {

            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            searchOnCreate() {
                return !!Object.keys(this.filter).length
            }
        },
        methods: {
            formatTime(timestamp) {
                return this.$options.filters.time(timestamp)
            },
            getData(filter) {

                let req = {
                    page: filter.currentPage,
                    perPage: filter.perPage,
                    status: filter.hasOwnProperty('status') ? filter.status : [this.STATUS_UNREAD,this.STATUS_CREATED],
                    order_by: filter.sortBy,
                    order_by_direction: filter.sortDirection,
                    company: this.company.id,
                    ...this.reqFilter,
                }
                return getThreadList(req)
            },
            getShortMessage(msg) {
                if (msg.attachment) {
                    return "[" + this.$t("IMAGE") + "]"
                }
                if (msg.text && msg.text.length > 60) {
                    return msg.text.substr(0, 60) + '...'
                }

                return msg.text
            },
            getThreadObject(thread_id){
                fetchThread(thread_id).then(response=>{
                    this.threadObject = response.data
                })
            },
            showAside(thread) {
                fetchThread(thread.id).then(response=>{
                    this.threadObject = response.data
                    if (this.threadObject.show.reservation && this.threadObject.type.id !== this.support_type) {
                        fetchAccommodationReservation(thread.reservation.id).then(response => {
                            this.reservationObject = cloneDeep(response.data)
                            this.asideWithRes = true
                        })
                    } else if(this.threadObject.type.id === this.support_type){
                        this.asideSupport = true
                    }else{
                        this.aside = true
                    }
                })
            },

            handleBusy($event) {
                EventBus.$emit("InboxSearchToggle", $event)
            },
            _has(object, path) {
                return has(object, path)
            },
            _get(object, path) {
                return get(object, path)
            },
            syncMessages(selected) {
                this.syncLoading = true
                resyncThreads({thread: selected.map(el => el.id)}).then(() => {
                    notifySuccess()
                }).finally(() => {
                    this.syncLoading = false
                })
            },
            saveReadUnreadAction(selected) {
                this.readUnreadLoading = true

                changeMessageStatus({
                    thread: selected.map(el => el.id),
                    change_status: this.readUnreadAction
                }).then(() => {
                    EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
                    notifySuccess()
                }).finally(() => {
                    this.readUnreadLoading = false
                    this.showModal = false
                })
            }
        },
        watch:{
          filter:{
              handler(value){
                  this.reqFilter = cloneDeep(value)
              },immediate:true
          }
        },
        mounted() {
            EventBus.$on("InboxSearchEvent", filter => {
               // this.reqFilter = cloneDeep(filter)
                this.reqFilter = filter
                EventBus.$emit("InboxSearchTable")
            })
        },
        beforeDestroy() {
            EventBus.$off('InboxSearchEvent')
            EventBus.$off('InboxSearchTable')
        }

    }
</script>

<style scoped>
    .distributionPhoto {
        width: 1.5rem;
        height: auto;
    }
</style>
