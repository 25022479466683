<template>
    <div v-if="show && hasViewPermission" :key="componentKey">
        <b-row>
            <b-col class="mb-4">
                <app-button  :c_permission="c_permission_e"  :access_control_context="{function:c_permission_e,key:AC_UNIT_AMENITY}"
                             @AccessControlEvent="addToAccessControlCounter()" variant="primary" button_type="new"
                            @click="editSidebar(null)" class="pull-right">{{$t('NEW_ROOM')}}
                </app-button>
            </b-col>
        </b-row>
        <unit-amenity-list  @AccessControlEvent="addToAccessControlCounter()" @editRoom="editSidebar" class="mt-3"></unit-amenity-list>

        <app-aside v-model="amenitySidebarState">
            <template v-if="edit_id === null" slot="header">{{$t("NEW_ROOM")}}</template>
            <template v-else slot="header">{{$t("ROOM")}}</template>

            <amenity-room-form @deleteRoom="deleteRoom" @createRoom="editSidebar($event);componentKey+=1"
                               :room_id="edit_id"></amenity-room-form>
            <amenity-inventory-list :key="edit_id" v-if="edit_id" :room_id="edit_id"></amenity-inventory-list>
        </app-aside>
    </div>
</template>

<script>
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import AppButton from '@/components/app/AppButton/AppButton'
    import AppAside from '@/components/app/form/AppAside'
    import AmenityRoomForm from '@/components/unit/amenity/UnitAmenityRoomForm'
    import UnitAmenityList from '@/components/unit/amenity/UnitAmenityList'
    import AmenityInventoryList from '@/components/unit/amenity/UnitRoomInventoryList'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {toast} from '@/shared/plugins/toastr'
    import {AC_UNIT_AMENITY} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {C_UNIT_AMENITY_V, C_UNIT_AMENITY_E} from "@/shared/component_permission";


    export default {
        name: "Index",
        components: {
            AppAside, UnitAmenityList, AmenityRoomForm, AmenityInventoryList, AppButton
        },
        mixins: [getErrorMessage,AccessControlComponent],
        data() {
            return {
                edit_id: null,
                amenitySidebarState: false,
                show: false,
                c_permission_e: C_UNIT_AMENITY_E,
                access_control_fetch_key:AC_UNIT_AMENITY,
                AC_UNIT_AMENITY,
                access_control_components:2,
                componentKey:1
            }
        },
        computed: {
            amenityList() {
                return this.$store.getters['amenity/getUnitAmenity']
            },
            hasViewPermission() {
                return this.$store.getters['user/getPermission'](C_UNIT_AMENITY_V)
            }
        },
        methods: {

            editSidebar(id) {
                this.edit_id = id
                this.amenitySidebarState = true
            },
            deleteRoom() {
                this.amenitySidebarState = false
                this.$store.dispatch('amenity/actionDeleteUnitRoom', {
                    unit_id: this.$route.params.unit_id,
                    room_id: this.edit_id
                }).then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }
                )
            },

        },
        created() {

        },
        watch: {
            '$route.params.unit_id': {
                handler(value) {
                    this.access_control_general_context = {
                        unit: value,
                        company: this.$store.getters['user/getCompany']
                    }
                    this.$store.dispatch('amenity/actionSetUnitAmenity', value).then(() => {
                        this.$store.dispatch('amenity/actionGetUnitAmenityOptions', value)
                        this.show = true
                    })
                },
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>
