<template>
  <div>
    <app-table-v2
        :fields="fields"
        :search-on-create="false"
        :provider="getProvider"
        :limit-list="[DEFAULT_PAGINATION_LIMIT, 20, 50, 100]"
        @busyToggled="toggleBusy"
        :search-event="EVENT_TABLE_SEARCH"
        :filter="filter"
    >
      <template v-slot:user="data">
        {{ data.item.user_created.first_name }} {{ data.item.user_created.last_name }}
      </template>
      <template v-slot:edit="data">
        <app-button class="pull-right" variant="link" button_type="edit" :show_text="false"
                    @click="edit(data.item.id)"></app-button>
      </template>
    </app-table-v2>
    <app-aside v-model="aside" :widths="['col-lg-4']">
      <template v-slot:header>
          {{$t('GROUP')}}
      </template>
      <revenue-manager-filter-form @close="reload"  :id="filter_id"></revenue-manager-filter-form>
    </app-aside>
  </div>
</template>

<script>
import {getRmFilterUnit} from "@/services/revenue_manager";
import AppTableV2 from "@/components/app/AppTableV2";
import AppButton from "@/components/app/AppButton/AppButton";
import AppAside from "@/components/app/form/AppAside";
import {DEFAULT_PAGINATION_LIMIT} from "@/shared/constants";
import {EventBus, GE_REVENUE_MANAGER_FILTER_NEW} from "@/shared/EventBus";
import RevenueManagerFilterForm from "@/components/revenue_manager/similar_listing/RevenueManagerFilterForm";

export default {
  name: "RevenueManagerSimilarListingTable",
  components: {RevenueManagerFilterForm, AppButton, AppTableV2, AppAside},
  props: {
    filter: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      EVENT_TABLE_SEARCH: 'gl_search_revenue_manager_similar',
      EventBus,
      DEFAULT_PAGINATION_LIMIT,
      fields: [
        {key: 'id', label: this.$t('ID')},
        {key: 'title', label: this.$t('NAME')},
        {key: 'unit_count', label: this.$t('GROUPED_UNIT_COUNT'), tdClass: 'text-center', thClass: 'text-center'},
        {key: 'edit', label: ''}
      ],
      aside: false,
      filter_id:null
    }
  },
  computed:{
    company() {
      return this.$store.getters['user/getCurrentCompany']
    }
  },
  methods: {
    reload(){
      this.aside = false
      EventBus.$emit(this.EVENT_TABLE_SEARCH)
    },
    getProvider(filter) {
      let req = {
        ...this.filter,
        ...{
          page: filter.currentPage,
          perPage: filter.perPage,
          order_by: filter.sortBy,
          order_by_direction: filter.sortDirection,
          company: this.company.id
        }
      }
      return getRmFilterUnit(req)
    },
    edit(id) {
      this.aside = true
      this.filter_id = id
    },
    toggleBusy($event) {
      this.$emit('busyToggled', $event)
    },
  },
  mounted(){
    EventBus.$on(GE_REVENUE_MANAGER_FILTER_NEW, ()=>{
      this.aside = true
      this.filter_id = null
    })
  },
  watch: {
    filter: {
      handler(value) {
        EventBus.$emit(this.EVENT_TABLE_SEARCH)
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>
