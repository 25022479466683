<template>
    <app-aside
        :main-classes="['p-0']"
        v-model="asideState"
        v-if="itemEditMode"
        :widths="width">

        <!-- panel header -->
        <template slot="header">
            <app-object-header
                :name="$t('EDIT')">
            </app-object-header>
        </template>
        <!-- panel header -->
        <b-form>
            <!-- units -->
            <b-row>
                <b-col class="mb-4">
                    <label>{{ $t("UNIT") }}</label>
                    <app-select
                        v-if="unitOptionList.length > 0"
                        mode="multiselect"
                        set-first
                        v-model="selectedUnitList"
                        :options="unitOptionList"
                        :disabled="selectUnitsDisabled">
                    </app-select>
                    <b-input
                        v-else
                        :value="previewUnitName"
                        disabled>
                    </b-input>
                </b-col>
            </b-row>
            <!-- units -->

            <!-- periods -->
            <b-row>
                <b-col lg="12">
                    <div class="table-wrapper">
                        <table class="table">
                            <thead>
                            <tr>
                                <th class="table-header-period-range" scope="col">{{ $t("PERIOD") }}</th>
                                <th class="table-header-actions text-right" scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <period-item
                                :key="periodIndex"
                                v-for="(period, periodIndex) in datePeriodList"
                                :period="period"
                                disabled
                                @delPeriod="removePeriod(periodIndex)"
                                :disabled-dates="disabledDates">
                            </period-item>
                            <period-item-new
                                @save="addPeriod"
                                :max-date="maxDate"
                                :from-date="fromDate"
                                :loading="addPeriodLoading"
                                :disabled-dates="disabledDates"
                                :add-button="false">
                            </period-item-new>
                            </tbody>
                        </table>
                    </div>
                </b-col>
            </b-row>
            <!-- periods -->

            <!-- weekdays -->
            <b-row>
                <b-col lg="12" md="12" class="mb-4">
                    <label>{{ $t("WEEKDAY_APPLY_CHANGES_TO") }}</label>
                    <app-select
                        mode="multiselect"
                        v-model="selectedDays"
                        :options="weekdayOptions"
                        value-field="value"
                        text-field="text">
                        <template v-slot:text
                                  v-if="selectedDays.length > 1 && selectedDays.length < 7">
                            <span>{{ selectedDaysPreviewText }}</span>
                        </template>
                    </app-select>
                </b-col>
            </b-row>
            <!-- weekdays -->

            <template v-if="(singleUnit || selectedUnitList.length > 1) && formLoaded">

                <b-row>
                    <b-col>
                        <b-tabs
                            v-model="tab"
                            nav-class="tab_navigation-horizontal"
                            content-class="tab_content-horizontal"
                            pills align="left">
                            <b-tab
                                :title="!contingent_ && this.previewRoomStatus ? $t('AVAILABILITY_AND_UNIT_STATUS') : $t('AVAILABILITY')"
                                v-if="previewAvailability">
                                <form-header
                                    v-if="!contingent_ && this.previewRoomStatus"
                                    :headerClass="'mt-0 mb-4'">
                                    {{ $t("AVAILABILITY") }}
                                </form-header>
                                <calendar-edit-availability-form
                                    class="mb-4"
                                    :unit="workingUnit"
                                    :units="selectedUnitList"
                                    :default-values="singleUnit ? defaultValues : null"
                                    :periods="datePeriodList"
                                    :root-unit="rootUnit"
                                    :contingent-mode="contingentModeEnabled"
                                    :single-unit="singleUnit"
                                    :allowed-weekday="selectedDays">
                                </calendar-edit-availability-form>
                                <template v-if="!contingent_ && previewRoomStatus">
                                    <hr class="horizontal-line">
                                    <form-header
                                        :headerClass="'mt-4 mb-4'">
                                        {{ $t("ROOM_STATUS") }}
                                    </form-header>
                                    <calendar-edit-room-status-form
                                        class="mt-4"
                                        :unit="firstRootUnit"
                                        :units="selectedUnitList"
                                        :periods="datePeriodList"
                                        :default-values="defaultValues"
                                        :single-unit="singleUnit"
                                        :allowed-weekday="selectedDays">
                                    </calendar-edit-room-status-form>
                                </template>
                            </b-tab>
                            <template v-if="!contingent_">
                                <b-tab
                                    :title="$t('RATES')"
                                    v-if="previewPrices">
                                    <calendar-edit-rates-form
                                        :unit="firstRootUnit"
                                        :selected-unit-list="selectedUnitList"
                                        :single-unit="singleUnit"
                                        :single-property="singleProperty"
                                        :unit-list="rootUnitList"
                                        :los-list="losList"
                                        :occupancy-list="occupancyList"
                                        :periods="datePeriodList"
                                        :default-values="singleUnit ? defaultValues : null"
                                        :rates="singleUnit ? [] : rates"
                                        :allowed-weekday="selectedDays">
                                    </calendar-edit-rates-form>
                                </b-tab>
                                <b-tab
                                    :title="$t('RESTRICTIONS')"
                                    v-if="previewRestrictions">
                                    <calendar-edit-restrictions-form
                                        :unit="firstRootUnit"
                                        :selected-unit-list="selectedUnitList"
                                        :periods="datePeriodList"
                                        :single-unit="singleUnit"
                                        :single-property="singleProperty"
                                        :unit-list="rootUnitList"
                                        :default-values="defaultValues"
                                        :rate-plan-id="ratePlanId ? ratePlanId : (firstRootUnit ? firstRootUnit.primary_rate_plan.id : null)"
                                        :rate-plans="ratePlanList"
                                        :allowed-weekday="selectedDays">
                                    </calendar-edit-restrictions-form>
                                </b-tab>
                            </template>
                        </b-tabs>
                    </b-col>
                </b-row>

                <b-row class="mt-5" v-if="previewNewReservation">
                    <b-col class="d-flex justify-content-center">
                        <app-button
                            button_type="new"
                            class="mr-3"
                            variant="primary"
                            @click="openNewReservation">
                            {{ $t("NEW_RESERVATION") }}
                        </app-button>
                    </b-col>
                </b-row>

            </template>

        </b-form>
    </app-aside>
    <b-form v-else>
        <!-- units -->
        <b-row>
            <b-col class="mb-4">
                <label>{{ $t("UNIT") }}</label>
                <app-select
                    v-if="unitOptionList.length > 0"
                    mode="multiselect"
                    set-first
                    v-model="selectedUnitList"
                    :options="unitOptionList"
                    :disabled="selectUnitsDisabled">
                </app-select>
                <b-input
                    v-else
                    :value="previewUnitName"
                    disabled>
                </b-input>
            </b-col>
        </b-row>
        <!-- units -->

        <!-- periods -->
        <b-row>
            <b-col lg="12">
                <div class="table-wrapper">
                    <table class="table">
                        <thead>
                        <tr>
                            <th class="table-header-period-range" scope="col">{{ $t("PERIOD") }}</th>
                            <th class="table-header-actions text-right" scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <period-item
                            :key="periodIndex"
                            v-for="(period, periodIndex) in datePeriodList"
                            :period="period"
                            disabled
                            @delPeriod="removePeriod(periodIndex)"
                            :disabled-dates="disabledDates">
                        </period-item>
                        <period-item-new
                            @save="addPeriod"
                            :max-date="maxDate"
                            :from-date="fromDate"
                            :loading="addPeriodLoading"
                            :disabled-dates="disabledDates"
                            :add-button="false">
                        </period-item-new>
                        </tbody>
                    </table>
                </div>
            </b-col>
        </b-row>
        <!-- periods -->

        <!-- weekdays -->
        <b-row>
            <b-col lg="12" md="12" class="mb-4">
                <label>{{ $t("WEEKDAY_APPLY_CHANGES_TO") }}</label>
                <app-select
                    mode="multiselect"
                    v-model="selectedDays"
                    :options="weekdayOptions"
                    value-field="value"
                    text-field="text">
                    <template v-slot:text
                              v-if="selectedDays.length > 1 && selectedDays.length < 7">
                        <span>{{ selectedDaysPreviewText }}</span>
                    </template>
                </app-select>
            </b-col>
        </b-row>
        <!-- weekdays -->

        <template v-if="(singleUnit || selectedUnitList.length > 1) && formLoaded">
            <b-row>
                <b-col>
                    <b-tabs
                        v-model="tab"
                        nav-class="tab_navigation-horizontal"
                        content-class="tab_content-horizontal"
                        pills align="left">
                        <b-tab
                            :title="!contingent_ && this.previewRoomStatus ? $t('AVAILABILITY_AND_UNIT_STATUS') : $t('AVAILABILITY')"
                            v-if="previewAvailability">
                            <form-header
                                v-if="!contingent_"
                                :headerClass="'mt-0 mb-4'">
                                {{ $t("AVAILABILITY") }}
                            </form-header>
                            <calendar-edit-availability-form
                                class="mb-4"
                                :unit="workingUnit"
                                :units="selectedUnitList"
                                :default-values="singleUnit ? defaultValues : null"
                                :periods="datePeriodList"
                                :root-unit="rootUnit"
                                :contingent-mode="contingentModeEnabled"
                                :single-unit="singleUnit"
                                :allowed-weekday="selectedDays">
                            </calendar-edit-availability-form>
                            <template v-if="!contingent_ && previewRoomStatus">
                                <hr class="horizontal-line">
                                <form-header
                                    :headerClass="'mt-4 mb-4'">
                                    {{ $t("ROOM_STATUS") }}
                                </form-header>
                                <calendar-edit-room-status-form
                                    class="mt-4"
                                    :unit="firstRootUnit"
                                    :units="selectedUnitList"
                                    :periods="datePeriodList"
                                    :default-values="defaultValues"
                                    :single-unit="singleUnit"
                                    :allowed-weekday="selectedDays">
                                </calendar-edit-room-status-form>
                            </template>
                        </b-tab>
                        <template v-if="!contingent_">
                            <b-tab
                                :title="$t('RATES')"
                                v-if="previewPrices">
                                <calendar-edit-rates-form
                                    :unit="firstRootUnit"
                                    :selected-unit-list="selectedUnitList"
                                    :single-unit="singleUnit"
                                    :single-property="singleProperty"
                                    :unit-list="rootUnitList"
                                    :los-list="losList"
                                    :occupancy-list="occupancyList"
                                    :periods="datePeriodList"
                                    :default-values="singleUnit ? defaultValues : null"
                                    :rates="singleUnit ? [] : rates"
                                    :allowed-weekday="selectedDays">
                                </calendar-edit-rates-form>
                            </b-tab>
                            <b-tab
                                :title="$t('RESTRICTIONS')"
                                v-if="previewRestrictions">
                                <calendar-edit-restrictions-form
                                    :unit="firstRootUnit"
                                    :selected-unit-list="selectedUnitList"
                                    :periods="datePeriodList"
                                    :single-unit="singleUnit"
                                    :single-property="singleProperty"
                                    :unit-list="rootUnitList"
                                    :default-values="defaultValues"
                                    :rate-plan-id="ratePlanId ? ratePlanId : (firstRootUnit ? firstRootUnit.primary_rate_plan.id : null)"
                                    :rate-plans="ratePlanList"
                                    :allowed-weekday="selectedDays">
                                </calendar-edit-restrictions-form>
                            </b-tab>
                        </template>
                    </b-tabs>
                </b-col>
            </b-row>
            <b-row class="mt-5" v-if="previewNewReservation">
                <b-col class="d-flex justify-content-center">
                    <app-button
                        button_type="new"
                        class="mr-3"
                        variant="primary"
                        @click="openNewReservation">
                        {{ $t("NEW_RESERVATION") }}
                    </app-button>
                </b-col>
            </b-row>
        </template>

    </b-form>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import PeriodItem from "@/components/app/Period/PeriodItem";
import PeriodItemNew from "@/components/app/Period/PeriodItemNew";
import AppInlineCheckboxGroup from "@/components/app/form/checbox_group/AppInlineCheckboxGroup";
import {getUnit, getUnitCalendarValues, getUnitList} from "@/services/unit";
import FormHeader from "@/components/app/form/FormHeader";
import CalendarEditRoomStatusForm from "@/components/calendar/aside/CalendarEditRoomStatusForm";
import {
    C_PROPERTY_CALENDAR_AVAILIBILITY_E as C_PROPERTY_CALENDAR_AVAILABILITY_E,
    C_PROPERTY_CALENDAR_AVAILIBILITY_V,
    C_PROPERTY_CALENDAR_BULK_AVAILABILITY_E,
    C_PROPERTY_CALENDAR_BULK_RATES_E,
    C_PROPERTY_CALENDAR_BULK_RESTRICTIONS_E,
    C_PROPERTY_CALENDAR_BULK_SALES_PERIODS_E,
    C_PROPERTY_CALENDAR_PRICES_E,
    C_PROPERTY_CALENDAR_PRICES_V,
    C_PROPERTY_CALENDAR_RESTRICTIONS_E,
    C_PROPERTY_CALENDAR_RESTRICTIONS_V,
    C_PROPERTY_CALENDAR_ROOM_STATUS_E,
    C_PROPERTY_CALENDAR_ROOM_STATUS_V,
    C_RESERVATION_ACCOMMODATION_NEW,
} from "@/shared/component_permission";
import CalendarEditAvailabilityForm from "@/components/calendar/aside/CalendarEditAvailabilityForm";
import {
    EventBus, GE_CALENDAR_OPEN_AVAILABILITY,
    GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR, GE_CALENDAR_OPEN_PRICES, GE_CALENDAR_OPEN_RESTRICTIONS,
    GE_CALENDAR_OPEN_ROOM_STATUS,
    GE_LOADER_HIDE,
    GE_LOADER_SHOW, GE_OPEN_NEW_ACC_RES_ASIDE
} from "@/shared/EventBus";
import {getCalendarValues} from "@/services/property";
import CalendarEditRatesForm from "@/components/calendar/aside/CalendarEditRatesForm";
import CalendarEditRestrictionsForm from "@/components/calendar/aside/CalendarEditRestrictionsForm";
import moment from "moment";
import {cloneDeep, isEqual} from "lodash";
import AppAside from "@/components/app/form/AppAside";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import AppButton from "@/components/app/AppButton/AppButton";

export default {
    name: "CalendarEditForm",
    components: {
        AppButton,
        AppObjectHeader,
        CalendarEditRestrictionsForm,
        CalendarEditRatesForm,
        CalendarEditAvailabilityForm,
        CalendarEditRoomStatusForm,
        FormHeader,
        AppInlineCheckboxGroup,
        PeriodItemNew,
        PeriodItem,
        AppSelect,
        AppAside,
    },
    data() {
        return {
            contingentUnit: null,
            selectedUnitList: [],
            rootUnitList: [],
            datePeriodList: [],
            addPeriodLoading: false,
            selectedDays: [1, 2, 3, 4, 5, 6, 0],
            defaultValues: null,
            losList: {},
            occupancyList: {},
            rates: [],
            ratePlanList: [],
            ratePlanId: null,
            tab: null,
            tabs: [
                C_PROPERTY_CALENDAR_AVAILIBILITY_V,
                C_PROPERTY_CALENDAR_PRICES_V,
                C_PROPERTY_CALENDAR_RESTRICTIONS_V,
            ],
            contingent_: this.contingent,
            contingentMode: false,
            asideState: false,
            calendarValuesGetDisabled: false,
            rootUnitListLoaded: false,
            calendarValuesLoaded: false,
            initialUnits: [],
        }
    },
    props: {
        periods: {
            type: Array,
            default: () => {
                return []
            },
        },
        selectedUnits: {
            type: Array,
            default: () => {
                return []
            },
        },
        propertyId: {
            type: Number,
            default: null,
        },
        rootUnit: {
            type: Object,
            default: () => {
                return null
            },
        },
        ratePlans: {
            type: Array,
            default: () => {
                return []
            },
        },
        los: {
            type: Array,
            default: () => {
                return []
            },
        },
        occupancy: {
            type: Array,
            default: () => {
                return []
            },
        },
        contingent: {
            type: Boolean,
            default: false,
        },
        singleProperty: {
            type: Boolean,
            default: true,
        },
        mode: {
            type: String,
            default: "calendar-item-edit",
            validator: (value) => {
                return [
                    "calendar-item-edit",
                    "calendar-edit",
                    "main-calendar-item-edit"
                ].indexOf(value) > -1
            },
        },
        elements: {
            type: Array,
            default: () => {
                return []
            },
        },
        width: {
            type: Array,
            default: () => {
                return ["col-lg-4", "col-md-5", "col-sm-12", "40%"]
            },
        },
        rootUnits: {
            type: Array,
            default: () => {
                return null
            },
        }
    },
    computed: {
        selectUnitsDisabled() {
            return !this.propertyId || this.rootUnitList.length === 1 || this.contingent_ || !this.singleProperty
        },
        unitOptionList() {
            if (!this.contingent_ && this.singleProperty) {
                return this.rootUnitList
            }

            let options = []
            if (!this.singleUnit && this.selectedUnits.length > 0) {
                this.selectedUnits.forEach(unitId => {
                    options.push({
                        id: unitId,
                        name: "unit_" + unitId,
                    })
                })
            }
            return options
        },
        singleUnit() {
            return this.selectedUnitList.length === 1 || (this.selectedUnitList.length === 0 && !!this.contingentUnit)
        },
        workingUnit() {
            let unit = this.contingentUnit
            unit = !unit && !this.contingent_ && this.singleUnit ? this.firstRootUnit : unit
            return unit
        },
        firstRootUnit() {
            return !this.contingent_ && this.selectedUnitList.length > 0 ? this.rootUnitList.find(unit => unit.id === this.selectedUnitList[0]) : null
        },
        previewUnitName() {
            let unitName = this.contingentUnit && this.contingentUnit.name ? this.contingentUnit.name : null
            unitName = !unitName && this.rootUnit && this.rootUnit.unit_type && this.rootUnit.unit_type.name ? this.rootUnit.unit_type.name : unitName
            return unitName ? unitName : ""
        },
        maxDate() {
            const currentYear = new Date().getFullYear()
            let maxDate = (currentYear + 3) + "-12-31"
            return new Date(maxDate)
        },
        fromDate() {
            let periodList = this.datePeriodList
            if (periodList.length > 0) {
                let lastItem = periodList[periodList.length - 1]
                if (lastItem.end) {
                    return new moment(lastItem.end).add(1, 'day').toDate()
                }
            }

            return undefined
        },
        disabledDates() {
            let dateList = this.datePeriodList.filter(item => item.start && item.end)
            return dateList.map(item => {
                return {
                    start: moment(item.start).toDate(),
                    end: moment(item.end).toDate(),
                }
            })
        },
        weekdayOptions() {
            let data = []
            for (const value of [1, 2, 3, 4, 5, 6, 0]) {
                data.push({text: this.getWeekday(value), value: Number(value)})
            }

            return data
        },
        sameLos() {
            if (this.singleUnit || !this.singleProperty) {
                return false
            }
            let unitIdList = this.losList ? Object.keys(this.losList) : []
            if (unitIdList.length === 0) {
                return true
            }
            /*if (unitIdList.length === 0 || unitIdList.length !== this.selectedUnitList.length) {
                return false
            }*/
            const firstUnitId = unitIdList[0]
            const compareAgainstLos = this.losList[firstUnitId]
            for (const [unitId, los] of Object.entries(this.losList)) {
                if (unitId !== firstUnitId) {
                    if (compareAgainstLos.length !== los.length) {
                        return false
                    }
                    for (let i = 0; i < compareAgainstLos.length; i++) {
                        let match = false
                        for (let j = 0; j < los.length; j++) {
                            if (match) {
                                continue
                            }
                            if (compareAgainstLos[i].min_stay === los[j].min_stay && compareAgainstLos[i].max_stay === los[j].max_stay) {
                                match = true
                            }
                        }
                        if (!match) {
                            return false
                        }
                    }
                }
            }
            return true
        },
        formLoaded() {
            /*if (this.shouldLoadCalendarValues && !this.calendarValuesLoaded) {
                return false
            }*/
            if (this.mode === "calendar-edit") {
                return !this.contingent_ && this.singleProperty ? this.rootUnitListLoaded && this.rootUnitList.length > 0 && this.firstRootUnit : true
            }
            return true
        },
        shouldLoadCalendarValues() {
            return this.singleProperty
        },
        previewAvailability() {
            return (this.mode === "calendar-edit" || this.elements.includes(C_PROPERTY_CALENDAR_AVAILIBILITY_V)) && this.checkPermission(this.editAvailabilityPermission)
        },
        previewRoomStatus() {
            return (this.mode === "calendar-edit" || this.elements.includes(C_PROPERTY_CALENDAR_ROOM_STATUS_V)) && this.checkPermission(this.editRoomStatusPermission)
        },
        previewPrices() {
            return (this.mode === "calendar-edit" || this.elements.includes(C_PROPERTY_CALENDAR_PRICES_V)) && this.checkPermission(this.editRatesPermission)
        },
        previewRestrictions() {
            return (this.mode === "calendar-edit" || this.elements.includes(C_PROPERTY_CALENDAR_RESTRICTIONS_V)) && this.checkPermission(this.editRestrictionsPermission) && (this.singleUnit || this.sameLos)
        },
        shownElements() {
            return this.mode === "calendar-edit" ? this.tabs : this.tabs.filter(tab => this.elements.includes(tab))
        },
        editAvailabilityPermission() {
            return C_PROPERTY_CALENDAR_AVAILABILITY_E
            // return this.mode === "calendar-item-edit" ? C_PROPERTY_CALENDAR_AVAILABILITY_E : C_PROPERTY_CALENDAR_BULK_AVAILABILITY_E
        },
        editRoomStatusPermission() {
            return C_PROPERTY_CALENDAR_ROOM_STATUS_E
            // return this.mode === "calendar-item-edit" ? C_PROPERTY_CALENDAR_ROOM_STATUS_E : C_PROPERTY_CALENDAR_BULK_SALES_PERIODS_E
        },
        editRatesPermission() {
            return C_PROPERTY_CALENDAR_PRICES_E
            // return this.mode === "calendar-item-edit" ? C_PROPERTY_CALENDAR_PRICES_E : C_PROPERTY_CALENDAR_BULK_RATES_E
        },
        editRestrictionsPermission() {
            return C_PROPERTY_CALENDAR_RESTRICTIONS_E
            // return this.mode === "calendar-item-edit" ? C_PROPERTY_CALENDAR_RESTRICTIONS_E : C_PROPERTY_CALENDAR_BULK_RESTRICTIONS_E
        },
        showDataList() {
            if (this.mode === "calendar-edit") {
                return this.contingent_ ? ["availability"] : [
                    "availability",
                    "rates",
                    "restrictions",
                ]
            }

            return this.elements.map(el => {
                if (el === C_PROPERTY_CALENDAR_AVAILIBILITY_V) {
                    return "availability"
                }
                if (!this.contingent_ && el === C_PROPERTY_CALENDAR_PRICES_V) {
                    return "rates"
                }
                if (!this.contingent_ && el === C_PROPERTY_CALENDAR_RESTRICTIONS_V) {
                    return "restrictions"
                }
            }).filter(el => !!el)
        },
        contingentModeEnabled() {
            if (this.contingent_ || this.selectedUnitList.length === 0) {
                return false
            }
            for (let i = 0; i < this.selectedUnitList.length; i++) {
                let unit = this.rootUnitList.find(unit => unit.id === this.selectedUnitList[i])
                if (!unit || !unit.hasOwnProperty("contigent") || !unit.contigent || typeof unit.contigent !== "object" || !unit.contigent.hasOwnProperty("is_contigent") || unit.contigent.is_contigent <= 1) {
                    return false
                }
            }

            return true
        },
        previewNewReservation() {
            if (this.checkPermission(C_RESERVATION_ACCOMMODATION_NEW) && this.singleUnit && this.tab === this.shownElements.findIndex(el => el === C_PROPERTY_CALENDAR_AVAILIBILITY_V) && this.defaultValues && this.defaultValues.hasOwnProperty("reservation") && this.defaultValues.reservation && this.defaultValues.reservation.hasOwnProperty("allowed")) {
                return this.defaultValues.reservation.allowed
            }
            return false
        },
        itemEditMode() {
            return ["calendar-item-edit", "main-calendar-item-edit"].indexOf(this.mode) >= 0
        },
        selectedDaysPreviewText() {
            let text = ""
            this.weekdayOptions.forEach(weekdayOption => {
                if (this.selectedDays.indexOf(weekdayOption.value) > -1) {
                    text += text.length > 0 ? (", " + weekdayOption.text) : weekdayOption.text
                }
            })
            return text
        }
    },
    watch: {
        datePeriodList: {
            handler(value) {
                if (value.length > 0) {
                    if (this.singleUnit) {
                        this.getSingleUnitCalendarValues(value)
                    } else {
                        this.getMultipleUnitsCalendarValues(value)
                    }
                }
            },
            deep: true,
        },
        selectedUnits: {
            handler(value) {
                if (isEqual(this.initialUnits, value)) {
                    return
                }
                this.selectedUnitList = cloneDeep(value)
                this.initialUnits = cloneDeep(value)
            },
            deep: true,
            immediate: true,
        },
        selectedUnitList: {
            handler(value) {
                this.clearLosOccupancyList()
                this.ratePlanId = null
                // this.contingentMode = this.contingentModeEnabled
                if (value.length === 1) {
                    if (this.contingent_) {
                        this.setContingentUnit()
                    } else if (this.datePeriodList.length > 0) {
                        this.getSingleUnitCalendarValues(this.datePeriodList)
                    }
                } else {
                    this.getMultipleUnitsCalendarValues(this.datePeriodList)
                }
            },
            deep: true,
            immediate: true,
        },
        contingentUnit: {
            handler(value) {
                if (value && typeof value === "object" && value.hasOwnProperty("id") && value.id && this.contingent_) {
                    this.getSingleUnitCalendarValues(this.datePeriodList)
                }
            },
            deep: true,
            immediate: true,
        },
        /*contingent_: {
            handler(value) {
                if (value) {
                    this.contingentMode = false
                }
            },
            immediate: true,
        },*/
        asideState: {
            handler(value) {
                if (!value && this.itemEditMode) {
                    this.ratePlanList = []
                    this.ratePlanId = null
                    this.contingentUnit = null
                    this.rootUnitList = []
                    this.selectedUnitList = []
                    this.datePeriodList = []
                    this.calendarValuesGetDisabled = false
                    this.rootUnitListLoaded = false
                    this.selectedDays = [1, 2, 3, 4, 5, 6, 0]
                }
            },
        },
        previewRestrictions: {
            handler(value) {
                if (value) {
                    this.$store.dispatch("shared/actionSetRestrictions")
                }
            },
            immediate: true,
        },
    },
    methods: {
        addPeriod(period) {
            this.addPeriodLoading = true
            let period_id = 'index_' + this.datePeriodList.length
            this.datePeriodList.push({
                id: period_id,
                start: period.start,
                end: period.end,
                deleted: 0,
            })
            this.$nextTick(() => {
                this.addPeriodLoading = false
            })
        },
        removePeriod(index) {
            this.datePeriodList.splice(index, 1)
        },
        getWeekday(number) {
            switch (Number(number)) {
                case 1:
                    return this.$t("DATE.DAYS.MONDAY")
                case 2:
                    return this.$t("DATE.DAYS.TUESDAY")
                case 3:
                    return this.$t("DATE.DAYS.WEDNESDAY")
                case 4:
                    return this.$t("DATE.DAYS.THURSDAY")
                case 5:
                    return this.$t("DATE.DAYS.FRIDAY")
                case 6:
                    return this.$t("DATE.DAYS.SATURDAY")
                case 0:
                    return this.$t("DATE.DAYS.SUNDAY")
            }
        },
        getRootUnitList() {
            if (!this.propertyId || this.contingent_) {
                return
            }

            this.calendarValuesGetDisabled = true
            if (this.rootUnits && Array.isArray(this.rootUnits) && this.rootUnits.length > 0) {
                this.rootUnitList = cloneDeep(this.rootUnits)
                this.rootUnitListLoaded = true
                if (this.singleUnit) {
                    this.getSingleUnitCalendarValues(this.datePeriodList, true)
                } else {
                    this.getMultipleUnitsCalendarValues(this.datePeriodList, true)
                }
                this.$nextTick(() => {
                    this.calendarValuesGetDisabled = false
                })
                return
            }

            let request = {
                property_id: this.propertyId,
                contigent: 0,
                limit: 9999,
            }
            getUnitList(request).then(response => {
                this.rootUnitList = response.data.items
            }).finally(() => {
                this.rootUnitListLoaded = true
                if (this.singleUnit) {
                    this.getSingleUnitCalendarValues(this.datePeriodList, true)
                } else {
                    this.getMultipleUnitsCalendarValues(this.datePeriodList, true)
                }
                this.$nextTick(() => {
                    this.calendarValuesGetDisabled = false
                })
            })
        },
        setContingentUnit() {
            const unitId = this.selectedUnitList.length === 1 ? this.selectedUnitList[0] : null
            if (!unitId) {
                this.contingentUnit = null
                return
            }
            getUnit(unitId).then((response) => {
                this.contingentUnit = response.data
            }, () => {
                this.contingentUnit = null
            })
        },
        getSingleUnitCalendarValues(periods, ignoreIsDisabled = false) {
            if ((!ignoreIsDisabled && this.calendarValuesGetDisabled) || periods.length === 0) {
                return
            }
            const unitId = this.contingent_ ? (this.contingentUnit ? this.contingentUnit.id : null) : (this.selectedUnitList.length === 1 ? this.selectedUnitList[0] : null)
            if (!unitId || (!this.contingent_ && (!this.rootUnitListLoaded || !this.firstRootUnit))) {
                return
            }
            let ratePlanId = this.itemEditMode ? this.ratePlanId : null
            ratePlanId = !ratePlanId && this.contingent_ && this.rootUnit ? this.rootUnit.primary_rate_plan.id : ratePlanId
            ratePlanId = !ratePlanId && this.firstRootUnit ? this.firstRootUnit.primary_rate_plan.id : ratePlanId
            if (this.showDataList.indexOf("additional_data") < 0) {
                this.showDataList.push("additional_data")
            }
            if (!this.contingent_ && this.showDataList.indexOf("room_status") < 0) {
                this.showDataList.push("room_status")
            }
            if (["calendar-edit", "main-calendar-item-edit"].indexOf(this.mode) >= 0 || (this.rootUnit && this.rootUnit.id !== unitId)) {
                if (this.showDataList.indexOf("all_rate_plans") < 0) {
                    this.showDataList.push("all_rate_plans")
                }
            }
            const request = {
                periods: periods.map(period => {
                    return {
                        start: period.start,
                        end: period.end,
                    }
                }),
                contigent: this.contingentModeEnabled,
                rate_plan: ratePlanId,
                show: this.showDataList
            }
            this.calendarValuesLoaded = false
            EventBus.$emit(GE_LOADER_SHOW)
            getUnitCalendarValues(unitId, request).then(response => {
                if (this.mode === "calendar-item-edit" && this.rootUnit && this.rootUnit.id === unitId) {
                    this.ratePlanList = cloneDeep(this.ratePlans)
                    this.$set(this.losList, unitId, this.los)
                    this.$set(this.occupancyList, unitId, this.occupancy)
                } else {
                    this.ratePlanList = response.data.rate_plan_list
                    this.$set(this.losList, unitId, response.data.los)
                    this.$set(this.occupancyList, unitId, response.data.occupancy)
                }
                this.defaultValues = response.data
            }).finally(() => {
                EventBus.$emit(GE_LOADER_HIDE)
                this.calendarValuesLoaded = true
            })
        },
        getMultipleUnitsCalendarValues(periods, ignoreIsDisabled = false) {
            if (this.selectedUnitList.length < 2) {
                this.rates = []
                this.losList = {}
                this.occupancyList = {}
                return
            }
            if ((!ignoreIsDisabled && this.calendarValuesGetDisabled) || periods.length === 0 || !this.singleProperty) {
                return
            }
            this.calendarValuesLoaded = false
            EventBus.$emit(GE_LOADER_SHOW)
            let ratePlanId = this.firstRootUnit ? this.firstRootUnit.primary_rate_plan.id : null
            let show = this.singleProperty ? this.sameLos ? ["rates", "units", "restrictions", "additional_data", "rate_plans", "room_status"] : ["units", "room_status"] : ["units"]
            getCalendarValues({rate_plan: ratePlanId, show: show, unit: this.selectedUnitList, periods}).then((response) => {
                if (response.data && response.data.hasOwnProperty("rates")) {
                    this.rates = response.data.rates
                }
                if (response.data && response.data.hasOwnProperty("units")) {
                    const units = response.data.units
                    units.forEach(unit => {
                        const unitId = unit.id
                        this.$set(this.losList, unitId, unit.los)
                        this.$set(this.occupancyList, unitId, unit.occupancy)
                    })
                }
                this.defaultValues = response.data
            }).finally(() => {
                EventBus.$emit(GE_LOADER_HIDE)
                this.calendarValuesLoaded = true
            })
        },
        setTabIndex(tabValue) {
            this.tab = this.shownElements.findIndex(el => el === tabValue)
        },
        openNewReservation() {
            let propertyId = this.propertyId ? this.propertyId : null
            propertyId = !propertyId && this.$route.params.id ? this.$route.params.id : propertyId
            propertyId = !propertyId && this.rootUnit && this.rootUnit.hasOwnProperty("property_id") ? this.rootUnit.property_id : propertyId
            let unitId = this.contingentUnit ? this.contingentUnit.id : null
            // unitId = !unitId && this.rootUnit ? this.rootUnit.id : unitId
            unitId = !unitId && this.firstRootUnit ? this.firstRootUnit.id : unitId
            const request = {
                unit_id: unitId,
                property: propertyId,
                dateRange: this.datePeriodList.length > 0 ? this.datePeriodList[0] : {},
            }
            EventBus.$emit(GE_OPEN_NEW_ACC_RES_ASIDE, request)
            this.$emit("openNewReservation")
            this.asideState = false
        },
        clearLosOccupancyList() {
            if (this.selectedUnitList.length === 0) {
                this.losList = {}
                this.occupancyList = {}
                return
            }

            Object.keys(this.losList).forEach(unitId => {
                if (this.selectedUnitList.findIndex(selectedUnitId => {
                    return Number(selectedUnitId) === Number(unitId)
                }) < 0) {
                    this.$delete(this.losList, unitId)
                }
            })

            Object.keys(this.occupancyList).forEach(unitId => {
                if (this.selectedUnitList.findIndex(selectedUnitId => {
                    return Number(selectedUnitId) === Number(unitId)
                }) < 0) {
                    this.$delete(this.occupancyList, unitId)
                }
            })
        },
    },
    created() {
        if (this.periods.length > 0) {
            this.periods.forEach(period => {
                if (period.start && period.end) {
                    this.datePeriodList.push({
                        id: "index_" + this.datePeriodList.length,
                        start: period.start,
                        end: period.end,
                        deleted: 0,
                    })
                }
            })
        }

        if (this.propertyId && this.mode === "calendar-edit") {
            this.getRootUnitList()
        }
    },
    mounted() {
        if (this.itemEditMode && this.checkPermission(C_PROPERTY_CALENDAR_ROOM_STATUS_E)) {
            EventBus.$on(GE_CALENDAR_OPEN_ROOM_STATUS, (data) => {
                if (!data || typeof data !== "object" || !data.hasOwnProperty("unitId") && !this.rootUnit && !this.rootUnit.hasOwnProperty("id")) {
                    return
                }
                if (data.unitId === this.rootUnit.id) {
                    this.calendarValuesGetDisabled = true
                    if (data.hasOwnProperty("dateRange")) {
                        this.datePeriodList = []
                        this.datePeriodList.push(data.dateRange)
                    }
                    this.contingent_ = false
                    this.contingentUnit = null
                    /*if (data.hasOwnProperty("contigentMode")) {
                        this.contingentMode = data.contigentMode
                    } else {
                        this.contingentMode = true
                    }*/
                    this.selectedUnitList = []
                    this.selectedUnitList.push(data.unitId)
                    this.setTabIndex(C_PROPERTY_CALENDAR_AVAILIBILITY_V)
                    this.ratePlanId = this.rootUnit.primary_rate_plan.id
                    this.getRootUnitList()
                    this.asideState = true
                    this.$nextTick(() => {
                        this.calendarValuesGetDisabled = false
                    })
                }
            })
        }


        if (this.itemEditMode && this.checkPermission(C_PROPERTY_CALENDAR_AVAILABILITY_E)) {
            EventBus.$on(GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR, (data) => {
                if (!data || typeof data !== "object" || !data.hasOwnProperty("unitId") || !this.rootUnit || !this.rootUnit.hasOwnProperty("id")) {
                    return
                }
                if (this.rootUnit && this.rootUnit.hasOwnProperty("id") && data.unitId === this.rootUnit.id) {
                    this.calendarValuesGetDisabled = true
                    if (data.hasOwnProperty("dateRange")) {
                        this.datePeriodList = []
                        this.datePeriodList.push(data.dateRange)
                    }

                    if (data.hasOwnProperty("contigentUnit")) {
                        this.contingentUnit = data.contigentUnit
                    } else {
                        this.contingentUnit = null
                    }

                    this.contingent_ = this.contingentUnit !== null

                    /*if (data.hasOwnProperty("contigentMode")) {
                        this.contingentMode = data.contigentMode
                    } else {
                        this.contingentMode = true
                    }*/

                    if (this.rootUnit.hasOwnProperty("primary_rate_plan")) {
                        this.ratePlanId = this.rootUnit.primary_rate_plan.id
                    }

                    this.setTabIndex(C_PROPERTY_CALENDAR_AVAILIBILITY_V)

                    if (!this.contingent_) {
                        this.contingentUnit = null
                        this.selectedUnitList = []
                        this.selectedUnitList.push(data.unitId)
                        this.getRootUnitList()
                    } else {
                        this.getSingleUnitCalendarValues(this.datePeriodList, true)
                    }

                    this.asideState = true
                    this.$nextTick(() => {
                        this.calendarValuesGetDisabled = false
                    })
                }
            })
        }

        if (this.itemEditMode && this.checkPermission(C_PROPERTY_CALENDAR_PRICES_E)) {
            EventBus.$on(GE_CALENDAR_OPEN_PRICES, (data) => {
                if (!data || typeof data !== "object" || !data.hasOwnProperty("unitId") && !this.rootUnit && !this.rootUnit.hasOwnProperty("id")) {
                    return
                }
                if (data.unitId === this.rootUnit.id) {
                    this.calendarValuesGetDisabled = true
                    if (data.hasOwnProperty("dateRange")) {
                        this.datePeriodList = []
                        this.datePeriodList.push(data.dateRange)
                    }

                    this.contingentUnit = null
                    this.contingent_ = false
                    this.ratePlanId = this.rootUnit.primary_rate_plan.id
                    this.setTabIndex(C_PROPERTY_CALENDAR_PRICES_V)
                    /*if (data.hasOwnProperty("contigentMode")) {
                        if (data.hasOwnProperty("contigentMode")) {
                            this.contingentMode = data.contigentMode
                        } else {
                            this.contingentMode = true
                        }
                    }*/

                    this.selectedUnitList = []
                    this.selectedUnitList.push(data.unitId)
                    this.getRootUnitList()

                    this.asideState = true
                    this.$nextTick(() => {
                        this.calendarValuesGetDisabled = false
                    })
                }
            })
        }


        if (this.itemEditMode && this.checkPermission(C_PROPERTY_CALENDAR_RESTRICTIONS_E)) {
            this.$store.dispatch("shared/actionSetRestrictions")
            EventBus.$on(GE_CALENDAR_OPEN_RESTRICTIONS, (data) => {
                if (!data || typeof data !== "object" || !data.hasOwnProperty("unitId") && !this.rootUnit && !this.rootUnit.hasOwnProperty("id")) {
                    return
                }
                if (data.unitId === this.rootUnit.id) {
                    this.calendarValuesGetDisabled = true
                    if (data.hasOwnProperty("dateRange")) {
                        this.datePeriodList = []
                        this.datePeriodList.push(data.dateRange)
                    }

                    if (data.hasOwnProperty("ratePlan")) {
                        this.ratePlanId = data.ratePlan
                    } else {
                        this.ratePlanId = this.rootUnit.primary_rate_plan.id
                    }
                    this.contingent_ = false
                    /*if (data.hasOwnProperty("contigentMode")) {
                        this.contingentMode = data.contigentMode
                    } else {
                        this.contingentMode = true
                    }*/

                    this.setTabIndex(C_PROPERTY_CALENDAR_RESTRICTIONS_V)
                    this.contingentUnit = null
                    this.selectedUnitList = []
                    this.selectedUnitList.push(data.unitId)
                    this.getRootUnitList()

                    this.asideState = true
                    this.$nextTick(() => {
                        this.calendarValuesGetDisabled = false
                    })
                }
            })
        }
    },
    /*beforeDestroy() {
        if (this.itemEditMode) {
            EventBus.$off(GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR)
            EventBus.$off(GE_CALENDAR_OPEN_ROOM_STATUS)
            EventBus.$off(GE_CALENDAR_OPEN_PRICES)
            EventBus.$off(GE_CALENDAR_OPEN_RESTRICTIONS)
        }
    },*/
}
</script>

<style scoped>
.horizontal-line {
    margin-bottom: 2.15rem;
}

.table-header-period-range {
    width: 60%;
}

.table-header-actions {
    width: 40%;
}
</style>
