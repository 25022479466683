<template>
    <div>
        <appearance-font-table></appearance-font-table>
    </div>
</template>

<script>

import AppearanceFontTable from "@/components/appearance/font/AppearanceFontTable";

export default {
    name: "Index",
    components:{AppearanceFontTable},
}
</script>

<style scoped>

</style>
