<template>
    <b-nav class="nav-inline flex-row" :pills="pills" :small="small">
        <template v-if="back">
            <b-nav-item link-classes="nav_back_button action_button" exact :to="back">{{$t("BACK")}}</b-nav-item>
        </template>
        <component :key="index"
                   @AccessControlEvent="AccessControlCheck($event, navElement)"
                   :navElement="navElement"
                   :access_control_context="getContext(navElement)"
                   v-for="(navElement, index) in navElements"
                   :is="navElement.children ? 'NavigationDropdown' : 'NavigationItem' ">
        </component>
    </b-nav>
</template>

<script>

    import NavigationDropdown from './NavigationDropdown'
    import NavigationItem from './NavigationItem'
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";

    export default {
        name: "Navigation",
        mixins: [AccessControlComponent],
        components: {
            NavigationDropdown, NavigationItem
        },
        props: {
            navElements: {
                type: Array,
                required: true
            },
            pills: {
                type: Boolean,
                default: false
            },
            small: {
                type: Boolean,
                default: false
            },
            back: {
                type: Object,
                default: () => {
                    return undefined
                }
            },
            access_control_context: {
                type: Object,
                default: null
            },
            AcFetchKey: {
                default: null
            },
            context: {
                default: null,
            }
        },
        data() {
            return {
                activeTab: 0,
                windowWidth: window.innerWidth,
                //this.access_control_components
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', () => {
                    this.windowWidth = window.innerWidth
                });
            })
        },
        methods: {
            countElements(element) {
                let counter = 0
                if (element) {
                    if (element.hasOwnProperty('route') && element.hasOwnProperty('access_control_context')) {
                        counter++
                    } else if (element.hasOwnProperty('children')) {
                        element.children.forEach(elementItem => {
                            counter += this.countElements(elementItem)
                        })
                    } else if (element && element.length) {
                        element.forEach(elementItem => {
                            counter += this.countElements(elementItem)
                        })
                    }
                }

                return counter
            },
            getContext(navElement) {
                if (navElement.hasOwnProperty('access_control_context')) {
                    return {
                        ...navElement.access_control_context,
                        ...{
                            key: this.access_control_fetch_key
                        }
                    }
                }
                return null
            },
            AccessControlCheck($event, navElement) {
                if (navElement.hasOwnProperty('access_control_context') || $event.hasOwnProperty('access_control_context')) {
                    this.addToAccessControlCounter()
                }
            }
        },
        created() {
            this.access_control_components = this.countElements(this.navElements)
            this.access_control_fetch_key = this.AcFetchKey
            this.access_control_general_context = this.context
        }
    }
</script>

<style scoped>

</style>
