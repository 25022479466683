<template>
    <div class="availability_sidebar d-flex align-items-center position-relative availability_calendar_contingent-sticky">
        <div class="availability_sidebar-select">
            <b-form-checkbox :unchecked-value="false" :value="true"  @change="updateSelected" v-model="selected" size="sm" class="mt-2 pr-0 mr-0" ></b-form-checkbox>
        </div>
        <span @click="handleClick"
              v-if="checkPermission(R_PROPERTY_CALENDAR_DEV)"
              :id="tooltipTarget"
              class="availability_sidebar-contigent-name "
              :class="classList"><span @click="redirectToPropertyCalendar"class="availability_calendar--poiner">

         <div @click="redirectToPropertyCalendar" class="mt-1 mb-1 action_button justify-content-start availability_calendar_numeric-indicator" :style="!showPropertyItem? 'height: 0.24rem' : ''" style="font-weight: normal; margin-top:9px!important;">
               <template v-if="showPropertyItem">
                          {{ property.name }}
               </template>

        </div>
            <span class="action_button" @click="redirectToPropertyUnitCalendar">
                    {{ shortUnitName }} ({{ Object.keys(unit.contigent).length + 1 }})
            </span>
                   <b-tooltip  custom-class="my-tooltip-class" :target="'tooltip-target_los_occupancy_unit-'+unit.id" >
                    <template v-if="this.unit && this.unit.hasOwnProperty('los') && this.unit.los && this.unit.los.length > 1 && defaultLosLabel">{{$t("STAY").toLocaleLowerCase() }} {{defaultLosLabel}}</template>

                <template v-if="this.unit && this.unit.hasOwnProperty('los') && this.unit.los && this.unit.los.length > 1 &&  defaultLosLabel"> / </template>{{$t("OCCUPANCY_PERSONS").toLocaleLowerCase()}}
                {{defaultOccupancyLabel}})
                </b-tooltip>

        </span>
        </span>
        <div v-else class="availability_sidebar-contigent-name">
            <span @click="handleClick"
                  :id="tooltipTarget"
                  :class="classList"><span class="availability_calendar--poiner">

         <div class="mt-1 mb-1 justify-content-start availability_calendar_numeric-indicator" :style="!showPropertyItem? 'height: 0.24rem' : ''" style="font-weight: normal; margin-top:9px!important;">
               <template v-if="showPropertyItem">
                          {{ property.name }}
               </template>

        </div>
            <span>
                    {{ shortUnitName }} ({{ Object.keys(unit.contigent).length + 1 }})
            </span>
                   <b-tooltip  custom-class="my-tooltip-class" :target="'tooltip-target_los_occupancy_unit-'+unit.id" >
                    <template v-if="this.unit && this.unit.hasOwnProperty('los') && this.unit.los && this.unit.los.length > 1 && defaultLosLabel">{{$t("STAY").toLocaleLowerCase() }} {{defaultLosLabel}}</template>

                <template v-if="this.unit && this.unit.hasOwnProperty('los') && this.unit.los && this.unit.los.length > 1 &&  defaultLosLabel"> / </template>{{$t("OCCUPANCY_PERSONS").toLocaleLowerCase()}}
                {{defaultOccupancyLabel}})
                </b-tooltip>

        </span>
        </span>
        </div>
        <i v-if="unit.hasOwnProperty('tags') && this.unit.tags.length > 0" @click="openTagMapping" :id="'tooltip-target-tag-'+ unit.id" class="fa fa-tag ml-1 mr-1 pull-right tooltip-help mb-4"></i>
        <i v-if="!display" :id="'tooltip-target_help-'+unit.id" class=" fa fa-question-circle-o ml-1 pull-right tooltip-help  mr-2 mb-4"></i>
        <b-tooltip  custom-class="my-tooltip-class" :target="'tooltip-target_help-'+unit.id" >
            <template v-if="this.unit && this.unit.hasOwnProperty('los') && this.unit.los && this.unit.los.length > 1 && defaultLosLabel">{{$t("STAY").toLocaleLowerCase() }} {{defaultLosLabel}}</template>

            <template v-if="this.unit && this.unit.hasOwnProperty('los') && this.unit.los && this.unit.los.length > 1 &&  defaultLosLabel"> / </template>{{$t("OCCUPANCY_PERSONS").toLocaleLowerCase()}}
            {{defaultOccupancyLabel}}
        </b-tooltip>
        <b-tooltip v-if="showShortName" placement="bottom" :target="tooltipTarget" triggers="hover">
            {{unit && unit.hasOwnProperty('unit_type') && unit.unit_type ? unit.unit_type.name : '' }}
        </b-tooltip>
    </div>
</template>

<script>
import router from "@/router";
import routeName from "@/router/routeNames";
import {EventBus, GE_CALENDAR_SELECT_UNIT} from "@/shared/EventBus";
import {R_PROPERTY_CALENDAR_DEV} from "@/shared/route_permission";

export default {
    name: "PropertyCalendarSidebarContigentItem",
    props: {
        display:{
            type:Number,
            default:0
        },
        unit: {
            type: Object
        },
        dateRange: {
            type: Object
        },
        property: {
            type: Object
        },
        selectedUnits:{
            type:Array,
            default(){
                return []
            }
        },
        showPropertyItem:{
            type:Boolean,
            default:true
        }
    },

    computed: {

        classList() {
            if(this.showPropertyItem){
                return {
                    "availability_calendar--dropdown-up": this.show,
                    "availability_calendar--dropdown-down": !this.show,
                }
            } else {
                return {
                    "availability_calendar--dropdown-up1": this.show,
                    "availability_calendar--dropdown-down1": !this.show,
                }
            }

        },
        showShortName() {
            return this.unit.unit_type.name.length > this.shortNameCharacters
        },
        shortUnitName() {
            if (this.showShortName) {
                return this.unit.unit_type.name.substr(0, this.shortNameCharacters) + '...'
            }
            return this.unit.unit_type.name
        },
        tooltipTarget() {
            return `tooltip_full_unit_type_name-${this.unit.id}`
        },
        defaultLosLabel(){
            if(Object.keys(this.unit.los).length  > 1){
                if(!this.unit.los.max_stay){
                    return this.unit.los.min_stay+'+'
                }
                if(this.unit.los.min_stay === this.unit.los.max_stay){
                    return this.unit.los.min_stay
                }
                if(this.unit.los.min_stay !== this.unit.los.max_stay){
                    return this.unit.los.min_stay + '-' +this.unit.los.max_stay
                }
            }

            return null
        },
        defaultOccupancyLabel(){
            if(Object.keys(this.unit.occupancy).length  > 0){

                if(!this.unit.occupancy.max_guests){
                    return this.unit.occupancy.min_guests+'+'
                }
                if(this.unit.occupancy.min_guests === this.unit.occupancy.max_guests){
                    return this.unit.occupancy.min_guests
                }
                if(this.unit.occupancy.min_guests !== this.unit.occupancy.max_guests){
                    return this.unit.occupancy.min_guests + '-' +this.unit.occupancy.max_guests
                }

            }
            return null
        },

    },
    data() {
        return {
            show: false,
            shortNameCharacters: 50,
            selected:false,
            R_PROPERTY_CALENDAR_DEV
        }
    },
    methods: {
        openTagMapping(){
            EventBus.$emit('ge_open_tag_mapping',{unit:this.unit.id})
        },
        updateSelected(value){
            this.selected = value
            EventBus.$emit(GE_CALENDAR_SELECT_UNIT,{unit:this.unit.id,value,contingent_item:false})
        },
        handleClick() {
            this.show = !this.show
            this.$emit('show',this.show)
        },
        redirectToPropertyCalendar() {
            let routerData = router.resolve({
                name: routeName.RN_PROPERTY_CALENDAR,
                params: {id: this.property.id},
                query: {start: this.dateRange.start, end: this.dateRange.end,all_unit:true}
            })
            window.open(routerData.href, '_blank')
        },
        redirectToPropertyUnitCalendar(){
            let routerData = router.resolve({
                name: routeName.RN_PROPERTY_CALENDAR,
                params: {id: this.property.id},
                query: {start: this.dateRange.start, end: this.dateRange.end, unit: this.unit.id}
            })
            window.open(routerData.href, '_blank')
        }
    },
    watch:{
        selectedUnits:{
            handler(selectedObjectList){
                this.selected = selectedObjectList.find(el=> el.unit === this.unit.id && el.contingent_item === false) ? true : false
            },immediate:true,
            deep:true
        },
        selected:{
            handler(value){
                if(value){
                    this.show = true
                    this.$emit('show',true)
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
