import {BOOKER_TOOLS_DOMAIN, USER_DATA} from '@/shared/constants.js'
import {getUser, updateUser, getPermissions, getPresets} from '@/services/user/index'
import {app} from '@/main'
import moment from 'moment'


import _set from "lodash/set";
import _get from "lodash/get";
import router from '@/router/index'
import routeName from '@/router/routeNames'
import i18n from "@/locale/index";
import {LS_PRESETS, MOMENT_LOCALE_MAPPINGS} from "@/shared/constants";
import {updateCompany} from "@/services/company";

const state = {
    userData: {
        id: null,
        username: null,
        company: {
            id: null,
            name: null,
            level: null,
            template: null
        },
        date_of_birth: null,
        email: null,
        first_name: null,
        last_name: null,
        phone: null,
        prefered_language: null,
        max_inactivity: null,
        profile_photo: null,
        profile_photo_thumb: null,
        additional_setup: null,
        whitelabel_level: null
    },
    permissions: localStorage.getItem('permissions') ? localStorage.getItem('permissions') : [],
    current_company_id: localStorage.getItem('current_company_id'),
    current_company: JSON.parse(localStorage.getItem('current_company')),
    timeout: null
}

const mutations = {
    setUser(state, payload) {
        state.userData = payload

        localStorage.setItem(USER_DATA, JSON.stringify(payload))
        localStorage.setItem('language', payload.prefered_language)

        if (localStorage.getItem('current_company_id') === null) {
            state.current_company_id = payload.company.id
            localStorage.setItem('current_company_id', payload.company.id)
        }

        if (localStorage.getItem('current_company') === null) {
            state.current_company = payload.company
            localStorage.setItem('current_company', JSON.stringify(payload.company))
        }

        i18n.locale = payload.prefered_language
        if (MOMENT_LOCALE_MAPPINGS.hasOwnProperty(payload.prefered_language)) {
            moment.locale(MOMENT_LOCALE_MAPPINGS[payload.prefered_language]);
        }

        localStorage.setItem('additional_setup', payload.additional_setup)

    },
    setCurrentCompanyId(state, payload) {
        state.current_company_id = payload
        localStorage.setItem('current_company_id', payload)
    },
    setCurrentCompany(state, payload) {
        state.current_company = payload
        localStorage.setItem('current_company', JSON.stringify(payload))
    },
    setUserItem(state, payload) {
        _set(state, payload.path, payload.value)
    },
    setPermissions(state, payload) {
        state.permissions = payload
        localStorage.setItem('permissions', JSON.stringify(payload))
    },
    resetTimeout(state) {

        if (state.timeout !== null) {
            clearTimeout(state.timeout)
        }

        if (router.currentRoute.name !== routeName.RN_LOGIN && router.currentRoute.name !== routeName.RN_W_LOGIN && state.userData.max_inactivity !== null && state.userData.max_inactivity > 0) {
            let domain = window.location.host;
            let route = {
                name: routeName.RN_LOGIN,
                query: {redirect: router.currentRoute.fullPath}
            }

            if (domain !== BOOKER_TOOLS_DOMAIN) {
                route.name = routeName.RN_W_LOGIN
            }

            state.timeout = setTimeout(() => {
                localStorage.clear();
                router.replace(route)
            }, state.userData.max_inactivity * 60000);
        }

    }
}

const getters = {
    getUser: function (state) {
        return state.userData
    },
    getUserItem(state) {
        return payload => _get(state, payload, null)
    },
    getPermissions: function (state) {
        return state.permissions
    },
    getPermission: function (state) {
        return permission_id => state.permissions.includes(permission_id)
    },
    getCompany: function (state) {
        return state.current_company_id
    },
    getCurrentCompany: function (state) {
        return state.current_company
    }
}

const actions = {
    actionSetUser({commit, dispatch}) {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem(USER_DATA) && localStorage.getItem(USER_DATA) !== '') {
                const user = JSON.parse(localStorage.getItem(USER_DATA))

                commit('setUser', user)
                commit('resetTimeout')
                resolve(user);
            } else {
                getUser().then(response => {
                    commit('setUser', response.data)
                    commit('resetTimeout')
                    getPresets().then(response => {
                        localStorage.setItem(LS_PRESETS, JSON.stringify(response.data))
                    })

                    resolve(response.data);
                }, error => {
                    reject(error);
                })
            }
        })
    },
    actionUpdateUser({commit}, data) {
        return new Promise((resolve, reject) => {
            updateUser(state.userData.id, data).then(response => {
                commit('setUser', response.data)
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    actionSetUserPermissions({commit}) {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem('permissions') && localStorage.getItem('permissions') !== '') {
                const permissions = JSON.parse(localStorage.getItem('permissions'))

                commit('setPermissions', permissions)
                commit('resetTimeout')
                resolve(permissions);
            } else {
                getPermissions().then(response => {
                    let result = response.data.map(item => item.id)

                    commit('setUserItem', {path: 'permissions', value: result})
                    localStorage.setItem('permissions', JSON.stringify(result))
                    resolve(response);
                }, error => {
                    reject(error);
                })
            }
        })

    },

    actionSetUserItem({commit}, payload) {
        commit('setUserItem', payload)
    },

    actionSetCurrentCompanyId({commit}, id) {
        commit('setCurrentCompanyId', id)
    },
    actionSetCurrentCompany({commit}, companyObject) {
        commit('setCurrentCompany', companyObject)
    },
    actionRedirectToParent() {
        // iframe redirect to parent
        if (window.location !== window.parent.location) {
            window.parent.location.replace(window.location.href)
        }
    },
    actionUpdateCompany({commit}, data) {
        return updateCompany(state.current_company.id, data).then(response => {
            commit('setCurrentCompany', response.data)
            return response.data
        }).catch(error => {
            return Promise.reject(error)
        })
    },
}

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true
}
