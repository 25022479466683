<template>
    <div>
        <app-table-v2 :fields="computedFields"
                      :exportColumns="exportColumns"
                      :selectable="true"
                      :paginated="false"
                      :provider="getData"
                      :default-limit="defaultLimit"
                      :show-limit="showLimit"
                      :filter="{}"
                      :http="{methods: 'GET', url: null}"
                      :search-event="SEARCH_EVENT"
                      :search-on-create="true">
            <template v-slot:created_date="data">
                <small class="text-muted d-block">
                    {{ data.item.created_ts | time }}
                </small>
                <div class="ws-no-wrap">
                    {{ data.item.created_ts | date }}
                </div>
            </template>

            <template v-slot:delivery_date="data">
                {{ data.item.delivery_date | date }}
            </template>

            <template v-slot:due_date="data">
                {{ data.item.due_date | date }}
            </template>

            <template v-slot:invoice_type="data">
                <small class="text-muted d-block">
                    {{ data.item.system_invoice_type.label }}
                </small>
                {{ data.item.invoice_type.name }}
            </template>

            <template v-slot:actions="data">
                <app-button v-if="data.item.hasOwnProperty('export') && data.item.export && data.item.export.hasOwnProperty('id') && data.item.export.id"
                            variant="link"
                            icon="pdf"
                            :show_text="false"
                            @click="downloadPDF(data.item.id)">
                </app-button>
            </template>
        </app-table-v2>
    </div>
</template>

<script>
    import AppAside from "@/components/app/form/AppAside";
    import AppTableV2 from "@/components/app/AppTableV2";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {downloadInvoicePDF, downloadReportXls, exportPdfInvoice} from "@/services/finance";
    import {fileHelpers} from "@/mixins/shared/helpers";
    import moment from "moment/moment";

    export default {
        name: "CompanyCalculationInvoiceList",
        mixins: [getErrorMessage, fileHelpers],
        components: {AppAside, AppButton, AppTableV2},
        props: {
            defaultLimit: {
                type: Number,
                default: 100
            },
            showLimit: {
                type: Boolean,
                default: false
            },
            tableData: {
                type: Array,
                default: []
            },
        },
        data() {
            return {
                fields: [
                    {key: 'id', label: this.$t('ID')},
                    {key: 'hr_number', label: this.$t('NUMBER'), class: 'ws-no-wrap'},
                    {key: 'invoice_type', label: this.$t('TYPE'), tdClass: ''},
                    // {key: 'invoicee', label: this.$t('RECIPIENT'), class: 'w-10'},
                    {key: 'amount_f', label: this.$t('AMOUNT'), tdClass: 'ws-no-wrap text-right'},
                    {key: 'status.label', label: this.$t('STATUS')},
                    {key: 'delivery_date', label: this.$t('DELIVERY_DATE')},
                    {key: 'due_date', label: this.$t('DUE_DATE')},
                    {key: 'created_date', label: this.$t('DATE_CREATED')},
                    {key: 'actions', label: '', class: 'text-right'},
                ],
                exportColumns: [
                    {value: 'id', text: 'Id'},
                    {value: 'hr_number', text: this.$t('NUMBER')},
                    {value: 'description', text: this.$t('DESCRIPTION')},
                    {value: 'invoice_type.name', text: this.$t('TYPE')},
                    // {value: 'invoicee.first_name', text: this.$t('RECIPIENT')},
                    {value: 'amount_f', text: this.$t('AMOUNT')},
                    {value: 'status.label', text: this.$t('STATUS')},
                    {value: 'delivery_date', text: this.$t('DELIVERY_DATE')},
                    {value: 'due_date', text: this.$t('DUE_DATE')},
                    {value: 'created_ts', text: this.$t('DATE_CREATED')}
                ],
                SEARCH_EVENT: 'getCalculationInvoices',
            }
        },
        computed: {
            computedFields() {
                return this.fields
            },
        },
        methods: {
            getData(req) {
                return new Promise((resolve, reject) => {
                    resolve({
                        data: this.tableData
                    });
                })
            },
            downloadPDF(id) {
                downloadInvoicePDF(id).then(response => {
                    this.handleFileDownload(response, id, 'pdf')
                }, (error) => {
                    this.showErrorMessages(error)
                }).catch((error) => {
                    this.showErrorMessages(error)
                })
            },
        },
    }
</script>

<style scoped>

</style>
