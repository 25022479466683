<template>
    <div>
        <unit-calendar-filter-v2
            @hideUnitHeader="show_header = false"
            :date-array="dateArray"
            :show-load-more="showLoadMore"
            :show-more-back="showMoreBack"
            @search="filterSearch"
            @updateOptions="updateOptions"
            @setRootUnitList="setRootUnitList">
        </unit-calendar-filter-v2>

        <b-row>
            <b-col :key="unit.id" cols="12" class="mt-0" v-for="unit in units">
                <main-calendar-table :date-array="dateArray" :scrollLeftPosition="scrollLeftPosition"  @showMoreBack="updateMoreBack" :property="property" :show-header="show_header"  :unit="unit" :elements="elements"
                                     :root-unit-list="rootUnitList"
                                     :date-range="date_range"></main-calendar-table>
            </b-col>
        </b-row>

        <app-aside v-model="aside_reservation" :widths="['col-lg-12','col-sm-12', '100%']" full-width
                   header-styles="position: sticky; top: 0; z-index: 999; background-color:#fff"
                   :header-classes="[]">
            <template slot="header">
                <reservation-accommodation-header :reservation="reservation"></reservation-accommodation-header>
            </template>

            <reservation-details :reservation-id="reservation.id"
                                 @update="reservation=$event" @saved="reload">
            </reservation-details>
        </app-aside>

        <app-aside :widths="['col-sm-12', '40%']" v-model="aside">
            <template slot="header">{{$t('NEW_UNIT')}}</template>
            <unit-wizard v-if="property" :property="property"></unit-wizard>
        </app-aside>
    </div>
</template>

<script>
    import AppAside from "@/components/app/form/AppAside";
    import MainCalendarTable from "@/components/unit/calendar_opt/MainCalendarTable";
    import {cloneDeep} from 'lodash'
    import {mapActions} from 'vuex'
    import VuexPropertyCalendar from '@/store/modules/property/calendar'
    import {
        EventBus,
        GE_CALENDAR_OPEN_RESERVATIONS,
        GE_OPEN_UNIT_WIZARD,
        GE_CALENDAR_REFRESH_DATA,
        GE_CALENDAR_LOAD_ADD_MONTH,
        GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR,
        GE_CALENDAR_SHOW_MORE,
        GE_CALENDAR_SCROLL_POSITION,
        GE_CALENDAR_OPEN_ROOM_STATUS,
        GE_CALENDAR_OPEN_PRICES,
        GE_CALENDAR_OPEN_RESTRICTIONS,
    } from "@/shared/EventBus";
    import CalendarReservationPreview
        from "@/components/unit/calendar_opt/Aside/Reservation/CalendarReservationPreview";
    import UnitCalendarFilterV2 from "@/components/unit/calendar_test/filter/UnitCalendarFilterV2";
    import UnitWizard from "@/components/unit/new/UnitWizard";
    import InboxReservation from "@/components/inbox/InboxReservation";
    import ReservationDetails from "@/components/reservation/accommodation/ReservationDetails";
    import ReservationAccommodationHeader from "@/components/reservation/accommodation/ReservationAccommodationHeader";
    import moment from "moment";

    export default {
        name: "Index",
        components: {
            ReservationAccommodationHeader,
            ReservationDetails,
            InboxReservation,
            UnitWizard, UnitCalendarFilterV2, CalendarReservationPreview, MainCalendarTable, AppAside
        },
        data() {
            return {
                units: [],
                date_range: {
                    start: null,
                    end: null
                },
                elements: [],
                aside_reservation: false,
                aside: false,
                reservation: {},
                showMoreBack:false,
                showLoadMore:false,
                show_header:true,
                scrollLeftPosition: 0,
                loadAdditionalMonths: 0,
                rootUnitList: [],
            }
        },
        watch:{
            scrollLeftPosition:{
                handler(value){
                    if(value === 0){
                        this.showMoreBack = true
                    } else {
                        this.showMoreBack = false
                    }
                }
            }
        },
        methods: {
            ...mapActions('calendar', ['actionSetRestrictions']),
            filterSearch(value) {
                if (value.hasOwnProperty('date_range')) {
                    this.date_range = cloneDeep(value.date_range)
                }
                if (value.hasOwnProperty('elements')) {
                    this.elements = cloneDeep(value.elements)
                }
                if (value.hasOwnProperty('units')) {
                    this.units = cloneDeep(value.units)
                }
            },
            updateOptions(val) {
                this.elements = cloneDeep(val)
                this.units = cloneDeep(this.units)
                this.date_range = cloneDeep(this.date_range)
            },
            reload(reservationObject){
                let unitId =  reservationObject.unit.parent ? reservationObject.unit.parent : reservationObject.unit.id
                EventBus.$emit(GE_CALENDAR_REFRESH_DATA,{unitId})
                this.aside_reservation = false
            },
            updateMoreBack(value){
                this.showMoreBack =value
            },
            setRootUnitList(rootUnitList) {
                this.rootUnitList = rootUnitList
            },
        },
        computed: {
            dateArray() {
                let dateArray = [];
                let currentDate = moment(this.date_range.start);
                let stopDate = moment(this.date_range.end).add(this.loadAdditionalMonths, 'months');
                while (currentDate <= stopDate) {
                    dateArray.push(currentDate.clone())
                    currentDate.add({day: 1});
                }
                return dateArray;
            },
            property() {
                return this.$store.getters['property/getProperty']
            },
        },
        mounted() {
            EventBus.$on(GE_OPEN_UNIT_WIZARD, () => {
                this.aside = true
            })
            EventBus.$on(GE_CALENDAR_SCROLL_POSITION, (data) => {
                this.scrollLeftPosition = data.position
            })
            EventBus.$on(GE_CALENDAR_SHOW_MORE,(value) => {
                this.showLoadMore = value
            })
        },
        created() {
            if (!(this.$store && this.$store.state && this.$store.state['calendar'])) {
                this.$store.registerModule('calendar', VuexPropertyCalendar)
                this.actionSetRestrictions()
            }

            EventBus.$on(GE_CALENDAR_OPEN_RESERVATIONS, (reservation) => {
                this.reservation = reservation
                this.aside_reservation = true

            })
            EventBus.$on(GE_CALENDAR_LOAD_ADD_MONTH, (data) => {
                this.date_range.start = data.start
                this.date_range.end = data.end
            })
        },
        destroyed() {
            this.$store.unregisterModule('calendar')
        },
        beforeDestroy(){
            EventBus.$off(GE_CALENDAR_REFRESH_DATA)
            EventBus.$off(GE_OPEN_UNIT_WIZARD)
            EventBus.$off(GE_CALENDAR_OPEN_RESERVATIONS)
            EventBus.$off(GE_CALENDAR_SCROLL_POSITION)
            EventBus.$off(GE_CALENDAR_SHOW_MORE)

            EventBus.$off(GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR)
            EventBus.$off(GE_CALENDAR_OPEN_ROOM_STATUS)
            EventBus.$off(GE_CALENDAR_OPEN_PRICES)
            EventBus.$off(GE_CALENDAR_OPEN_RESTRICTIONS)
        }
    }
</script>

<style scoped>

</style>
