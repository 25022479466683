<template>
    <owner-basic-form></owner-basic-form>
</template>

<script>
    import OwnerBasicForm from '@/components/owner/basic/OwnerBasicForm'

    export default {
        name: "Index",
        components:{
            OwnerBasicForm
        }
    }
</script>

<style scoped>

</style>
