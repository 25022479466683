import http from '../http'
import {
    ACCOMMODATION_RESERVATION,
    ACCOMMODATION_RESERVATION_OLD, ARPS, DISTRIBUTION_URL,
    DOCUMENT_URL,
    INVENTORY_INFO_URL, STATUS_LIST_URL
} from "@/services/endpoints";

// old endpoints
export function getReservations(data) {
    return http.request({
        url: ACCOMMODATION_RESERVATION_OLD + '/src',
        method: 'POST',
        data: data
    }).then(response => {
        return http.request({
            url: ACCOMMODATION_RESERVATION_OLD + '/src/' + response.data.id,
            method: 'GET',
            params: {
                results: 999
            }
        })
    })
}

// new
export function getAccommodationReservationList(params, cancelToken = null) {
    return http.request({
        url: ACCOMMODATION_RESERVATION,
        cancelToken: cancelToken,
        method: 'GET',
        params
    })
}

export function fetchAccommodationReservation(id, params = null) {
    return http.request({
        url: ACCOMMODATION_RESERVATION + '/' + id,
        method: 'GET',
        params
    })
}

export function fetchReuseAccommodationReservation(id, params = null) {
    return http.request({
        url: ACCOMMODATION_RESERVATION + '/' + id + '/reuse',
        method: 'GET',
        params
    })
}

export function fetchAccommodationReservationFinance(res_id) {
    return http.request({
        url: ACCOMMODATION_RESERVATION + '/' + res_id + '/finance',
        method: 'GET'
    })
}

export function fetchAccommodationReservationNotes(id) {
    return http.request({
        url: ACCOMMODATION_RESERVATION + '/' + id + '/notes',
        method: 'GET'
    })
}

export function updateAccommodationReservationNotes(id, data) {
    return http.request({
        url: ACCOMMODATION_RESERVATION + '/' + id + '/notes',
        method: 'PUT',
        data
    })
}

export function updateAccommodationReservationHeader(id, data) {
    return http.request({
        url: ACCOMMODATION_RESERVATION + '/' + id,
        method: 'PUT',
        data
    })
}

export function updateUnknownReservation(id, data) {
    return http.request({
        url: ACCOMMODATION_RESERVATION + '/unknown/' + id,
        method: 'PUT',
        data
    })
}

export function updateAccommodationReservationFinance(id, data) {
    return http.request({
        url: ACCOMMODATION_RESERVATION + '/' + id + '/finance',
        method: 'PUT',
        data
    })
}

export function createAccommodationReservation(data) {
    return http.request({
        url: ACCOMMODATION_RESERVATION,
        method: 'POST',
        data
    })
}

export function getAccommodationReservationCCData(id) {
    return http.request({
        url: ACCOMMODATION_RESERVATION + '/' + id + '/credit-card-data',
        method: 'GET'
    })
}

export function saveAccommodationReservationCCData(id, data) {
    return http.request({
        url: ACCOMMODATION_RESERVATION + '/' + id + '/credit-card-data',
        method: 'POST',
        data
    })
}

export function getAccommodationReservationTransactionList(id) {
    return http.request({
        url: ACCOMMODATION_RESERVATION + '/' + id + '/transaction',
        method: 'GET'
    })
}
export function getInventoryInfo(params){
    return http.request({
        url: INVENTORY_INFO_URL,
        method: 'GET',
        params
    })
}
export function getAccommodationReservationTransactionValuesList(id) {
    return http.request({
        url: ACCOMMODATION_RESERVATION + '/' + id + '/get-transaction-values',
        method: 'GET'
    })
}

export function createAccommodationReservationTransaction(id, data) {
    return http.request({
        url: ACCOMMODATION_RESERVATION + '/' + id + '/transaction',
        method: 'POST',
        data
    })
}

export function fetchAccommodationReservationInfo(id) {
    return http.request({
        url: ACCOMMODATION_RESERVATION + '/' + id + '/info',
        method: 'GET'
    })
}

export function reportAccommodationReservation(id, params) {
    return http.request({
        url: ACCOMMODATION_RESERVATION + '/' + id + '/report',
        method: 'POST',
        params
    })
}

export function groupNotify(data) {
    return http.request({
        url: ACCOMMODATION_RESERVATION_OLD + '/group-notify',
        method: 'POST',
        data: data
    })
}
export function updateReservationCancellationPolicy(reservation_id,data){
    return http.request({
        url:ACCOMMODATION_RESERVATION +'/'+reservation_id+'/finance/cancellation-policy',
        method:'put',
        data
    })
}
export function applyToReservationCancellationPolicy(reservation_id,data){
    return http.request({
        url:ACCOMMODATION_RESERVATION +'/'+reservation_id+'/finance/apply-cancellation-policy',
        method:'post',
        data
    })
}

export function groupExport(data, filename) {
    return http.request({
        url: ACCOMMODATION_RESERVATION_OLD + '/group-export',
        method: 'POST',
        responseType: 'blob',
        data: data
    }).then((response) => {
        let blob = new Blob([response], {type: 'application/xlsx'});

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename + `.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
}

export function getSchedulerEvents(id, params) {
    return http.request({
        url: `${ACCOMMODATION_RESERVATION}/${id}/scheduler`,
        method: 'GET',
        params
    })
}

export function fetchSchedulerEvent(id) {
    return http.request({
        url: `${ACCOMMODATION_RESERVATION}/scheduler/${id}`,
        method: 'GET'
    })
}

export function createSchedulerEvent(reservationId, data) {
    return http.request({
        url: `${ACCOMMODATION_RESERVATION}/${reservationId}/scheduler`,
        method: 'POST',
        data
    })
}

export function updateSchedulerEvent(id, data) {
    return http.request({
        url: `${ACCOMMODATION_RESERVATION}/scheduler/${id}`,
        method: 'PUT',
        data
    })
}

export function deleteSchedulerEvent(id) {
    return http.request({
        url: `${ACCOMMODATION_RESERVATION}/scheduler/${id}`,
        method: 'DELETE'
    })
}

export function calculateAccommodationReservationPrice(params) {
    return http.request({
        url: ACCOMMODATION_RESERVATION + '/calculate-price',
        method: 'get',
        params
    })
}
export function getSchedulerActions() {
    return http.request({
        url: `${ACCOMMODATION_RESERVATION}/scheduler/actions`,
        method: 'GET'
    })
}

export function getReservationSetup(id) {
    return http.request({
        url: `${ACCOMMODATION_RESERVATION}/${id}/setup`,
        method: 'GET'
    })
}

export function getReservationSetupOptions(id) {
    return http.request({
        url: `${ACCOMMODATION_RESERVATION}/${id}/setup-options`,
        method: 'GET'
    })
}

export function updateReservationSetup(id,data) {
    return http.request({
        url: `${ACCOMMODATION_RESERVATION}/${id}/setup`,
        method: 'PUT',
        data
    })
}

export function updateARProcessSetupList(data) {
    return http.request({
        url: `${ACCOMMODATION_RESERVATION}/process/setup/list`,
        method: 'PUT',
        data
    })
}

export function getReservationParameter(id,params) {
    return http.request({
        url: `${ARPS}/${id}`,
        method: 'get',
        params
    })
}

export function saveReservationParameter(data) {
    return http.request({
        url: `${ARPS}`,
        method: 'put',
        data
    })
}

export function getDataTransitionParameter(id,params) {
    return http.request({
        url: `${ARPS}/data-transition`,
        method: 'get',
        params
    })
}


export function setSetupBatch(data) {
    return http.request({
        url: ACCOMMODATION_RESERVATION +'/setup-batch',
        method: 'post',
        data
    })
}

export function fetchFinanceValues(id, params) {
    return http.request({
        url: `${ACCOMMODATION_RESERVATION}/${id}/finance-values`,
        method: 'get',
        params
    })
}

export function getReservationVisibilitySetup(object_type_id) {
      return http.request({
              url: `adminapi/feature-setup/data-visibility-setup?object_type=${object_type_id}`,
              method: 'get',
            })
    }
export function bulkUpdateFinance(data) {
    return http.request({
        url: ACCOMMODATION_RESERVATION +'/update-finance-batch',
        method: 'post',
        data
    })
}
