<template>
    <b-form @submit.prevent>
        <form-wizard  ref="wizard_airbnb_activation_distribution" :startIndex="currentIndex" transition="fade-in"
                      color=var(--color-primary) errorColor=var(--color-warning)
                     name="distributionPropertyWizard" title="" subtitle=""
                     @on-validate="handleNextStep"
                     @on-complete="connectProperty">
            <tab-content name="object" :title="$t('CONNECT_SETUP')">
                        <b-col md="6"></b-col>
                        <b-col md="6">
                            <div class="d-flex align-items-start flex-column bd-highlight" :key="index" v-for="(item,index) in connectData.connect_scopes" >

                                <b-form-checkbox  :disabled="item.append === ''" v-model="item.connected"   :unchecked-value="false"
                                                  :value="true" > {{item.label}}</b-form-checkbox>


                            </div>
                        </b-col>

            </tab-content>

            <button class="btn btn-outline-primary" slot="prev">{{$t('PREVIOUS')}}</button>
            <button class="btn btn-primary" slot="next" :disabled="loader">
                <i v-if="loader "
                   class="mr-1 fa fa-spinner fa-spin fa-fw"
                   aria-hidden="true">
                </i>
                {{$t('NEXT')}}
            </button>
            <button class="btn btn-primary" :disabled="loader" slot="finish">
                <i v-if="loader "
                   class="mr-1 fa fa-spinner fa-spin fa-fw"
                   aria-hidden="true">
                </i>
                {{$t('FINISH')}}
            </button>
        </form-wizard>
        <loading :show="show_loader"></loading>
    </b-form>
</template>

<script>

    import {toast} from "@/shared/plugins/toastr";
    import {EventBus, GE_LOADER_HIDE, GE_LOADER_SHOW, V_ERROR} from "@/shared/EventBus";
    import RepeaterHeader from "@/components/app/form/RepeaterHeader";
    import Loading from "vue-full-loading/src/Loading";
    import FormHeader from "@/components/app/form/FormHeader";

    import AppButton from "@/components/app/AppButton/AppButton";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {FormWizard, TabContent} from "vue-form-wizard";
    import ConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
    import {cloneDeep, has, get} from 'lodash'
    import {getPropertyActivationStatus, getPropertyActivationStatusList} from "@/services/channel_manager";

    export default {
        name: "AirBnbActivationWizard",
        components: {
            RepeaterHeader,
            Loading,
            FormHeader,
            AppButton,
            AppSelect, FormWizard, TabContent, ConfirmationDialog
        },
        props: {
            distribution: {
                type: Object,
            },
            property_id: {
                type: Number
            },
            loader: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                show_loader: false,
                currentIndex: 0,
                object_exist: 0,
                connectData:{},
                initialConnectData:{},
                load_data:false,
                scope_list:[],
                link:null,
                link_replace:''
            }
        },
        computed: {
            company_id() {
                return this.$store.getters['user/getCompany']
            },
            propertyObject(){
                return this.$store.getters['property/getProperty']
            }
        },
        methods: {
            connectProperty() {
                window.open(this.link_replace, "_blank");
            },



            handleNextStep(isValid, tabIndex) {
                if (!isValid) {
                    return
                }
                EventBus.$emit(V_ERROR, [])
                this.currentIndex = tabIndex++
            },
            getConnectData(distribution) {
                this.load_data = false
                EventBus.$emit(GE_LOADER_SHOW)
                getPropertyActivationStatus({property: Number(this.$route.params.id), distribution})
                    .then(response => {
                        this.connectData = response.data.connect_button
                        this.link  = this.connectData.connect_template
                        this.initialConnectData = cloneDeep(this.connectData)
                    }).finally(() => {
                        this.load_data = true
                    EventBus.$emit(GE_LOADER_HIDE)
                })
            },
        },
        watch:{
            distribution:{
                handler(object){
                    this.getConnectData(object.id)
                }, immediate:true
            },
            'connectData.connect_scopes':{
                handler(list){
                    this.scope_list = []
                    if(list.length){
                        list.forEach(item=>{
                            if(item.hasOwnProperty('connected') && item.hasOwnProperty('append')){

                            }
                            if(item.connected && item.append !== ""){
                                this.scope_list.push(item.append)
                            }
                        })
                    }
                    if(this.scope_list.length){
                        this.link_replace = this.link.replace('[scope_list]',this.scope_list)
                    } else {
                        this.link_replace = this.link.replace('[scope_list]','')
                    }

                },deep:true
            },
            scope_list:{
                handler(list){
                    if(list.length > 0 && this.link){
                        let link = this.link.replace('[scope_list]',list)
                        this.link_replace = link.replace(',','')
                    }
                }, immediate:true
            }
        }

    }
</script>

<style scoped>
    .centered {
        position: fixed; /* or absolute */
        top: 50%;
        left: 50%;
    }
</style>
