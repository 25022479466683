<template>
    <div>
        <b-row v-if="contactList.length">
            <b-col md="auto" class="ml-auto mb-4">
                <app-button :c_permission="c_permission_e" button_type="new" variant="primary"
                            @click="newContact">{{$t('NEW_CONTACT')}}
                </app-button>
            </b-col>
        </b-row>

        <b-table v-if="contactList.length && !loader" hover :fields="contactFields" :items="contactList">
            <template v-slot:contact_event="data">
                <b-row>
                    <b-badge :key="index" v-for="(event, index) in data.item.contact_event"
                             variant="primary" class="ml-1">{{event.label}}
                    </b-badge>
                </b-row>
            </template>

            <template v-slot:edit="data">
                <app-button :c_permission="c_permission_e" variant="link" :show_text="false" button_type="edit"
                            @click="editContact(data.item)"></app-button>
            </template>
        </b-table>
        <app-no-data v-else-if="contactList.length === 0 && !loader" :show-tip="false" >
            <app-button :c_permission="c_permission_e" button_type="new" variant="primary"
                        @click="newContact">{{$t('NEW_CONTACT')}}
            </app-button>
        </app-no-data>

        <app-aside v-model="newContactPanelState">
            <template slot="header">{{$t('NEW_CONTACT')}}</template>
            <contact-form @closeNewContactAside="closeAndReloadContacts"
                          :newContact="contactObject" :object_type="object_type"
                          :object_id="object_id"></contact-form>
        </app-aside>

    </div>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import {C_OWNER_CONTACT_E} from "@/shared/component_permission";
    import {getNewOwnerContactList} from "@/services/owner";
    import {OBJECT_TYPE_COMPANY} from "@/shared/constants";
    import AppAside from "@/components/app/form/AppAside";
    import ContactForm from '@/components/contacts/ContactForm'
    import FormHeader from "@/components/app/form/FormHeader";
    import AppNoData from "@/components/app/AppNoData";

    export default {
        name: "ContactsList",
        components: {AppNoData, FormHeader, AppButton, AppAside, ContactForm},
        props: {
            object_type: {
                type: Number,
                required: true
            },
            object_id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                c_permission_e: C_OWNER_CONTACT_E,
                newContactPanelState: false,
                contactObject: null,
                contactFields: [
                    {key: 'channel.label', label: this.$t('CHANNEL')},
                    {key: 'contact', label: this.$t('CONTACT')},
                    {key: 'description', label: this.$t('DESCRIPTION')},
                    {key: 'contact_event', label: this.$t('USE_FOR')},
                    {key: 'edit', label: '', class: 'text-right'}
                ],
                contactList: [],
                OBJECT_TYPE_COMPANY,
                loader:false,
            }
        },
        methods: {
            newContact() {
                this.newContactPanelState = true
                this.contactObject = null
            },
            editContact(contactObject) {
                this.newContactPanelState = true
                this.contactObject = contactObject
            },
            closeAndReloadContacts(data) {
                this.newContactPanelState = false
                this.contactList = data
            }
        },
        watch: {
            object_id: {
                handler(value) {
                    this.loader = true
                    getNewOwnerContactList({
                        object_type: this.object_type,
                        object: value
                    }).then(res => {
                        this.contactList = res.data
                    }).finally(()=>{
                        this.loader = false
                    })
                },
                immediate: true
            }
        },
    }
</script>

<style scoped>

</style>
