<template>
    <b-form @submit.prevent="save" autocomplete="off">
        <b-row class="mb-3">
            <b-col cols="12" lg="4" class="mb-3">
                <label>{{$t('COUNTRY_DESTINATION')}}</label>
                <app-select text-field="label" :options="countryTagOptions"
                            v-model="countryTag" mode="multiselect">
                </app-select>
            </b-col>
            <b-col cols="12" lg="4" class="mb-3">
                <label>{{$t('REGION_DESTINATION')}}</label>
                <app-select text-field="label" :options="regionTagOptions"
                            v-model="regionTag" mode="multiselect">
                </app-select>
            </b-col>
            <b-col cols="12" lg="4" class="mb-3">
                <app-input-control :error-object="validation.destination">
                    <template v-slot:input="data">
                        <label>{{$t('CITY_DESTINATION')}}</label>
                        <app-select text-field="label" :options="tagOptions" :search-empty-item="false"
                                    v-model="selectedTag" mode="select" :state="data.state">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row v-if="mode !== 'withoutSave' && hasPermission(C_UNIT_ACTIVATION_DESTINATION_SAVE_V)">
            <b-col class="mb-4">
                <app-button-submit :inline="true" :disabled="loading" :loading="loading">
                    {{ $t("SAVE") }}
                </app-button-submit>
            </b-col>
        </b-row>
        <app-confirmation-dialog
            :show="confirmation_dialog_state"
            @confirm="continueAction"
            @cancel="confirmation_dialog_state=false"
            :title="$t('DESTINATION_TAGGING.TITLE')">
            <p>{{$t('DESTINATION_TAGGING.MSG')}}</p>
            <p>{{$t('DESTINATION_TAGGING.MSG_CONF')}}</p>
        </app-confirmation-dialog>
    </b-form>
</template>

<script>

import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppInputControl from "@/components/app/AppInputControl";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {
    bulkSaveEntityTags,
    getAllTags,
    getExistingTagsList,
    getTagsPaginated,
    saveTagsForPropertyDestination
} from "@/services/tag";
import {
    TAG_TYPE_DESTINATION,
    OBJECT_TYPE_UNIT,
    OBJECT_TYPE_PROPERTY,
    TAG_DESTINATION_TYPE_CITY,
    TAG_DESTINATION_TYPE_COUNTRY, TAG_DESTINATION_TYPE_REGION
} from '@/shared/constants'
import {NO_TAG_ITEM_SELECTED, TAG_ID_VALIDATION_FAIL} from '@/shared/error_codes'
import {notifySuccess} from "@/shared/plugins/toastr";
import {getWebsiteTags} from "@/services/direct_booking/website";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {EventBus, GE_TABLE_REFRESH_SEARCH} from "@/shared/EventBus";
import {C_UNIT_ACTIVATION_DESTINATION_SAVE_V} from "@/shared/component_permission";

export default {
    name: "DestinationTaggingForm",
    mixins: [getErrorMessage],
    components:{
        AppSelect,
        AppButtonSubmit,
        AppInputControl,
        AppConfirmationDialog
    },

    props:{
        selected:{
            type:Array
        },
        distribution_id:{
            type: Number,
            default: null
        },
        company_id:{
            type: Number,
            default: null
        },
        mode:{
            type: String,
            default: null
        },
        unit_id:{
            type: Number,
            default: null
        }
    },

    computed:{
        hasPermission() {
            return this.$store.getters['user/getPermission']
        }
    },

    data(){
        return{
            selectedTag: null,
            tagOptions: [],
            selectedUnits: [],
            loading: false,
            confirmation_dialog_state: false,
            isSame: true,
            checkTagList: false,
            countryTagOptions: [],
            regionTagOptions: [],
            countryTag: null,
            regionTag: null,
            validation: {
                destination: TAG_ID_VALIDATION_FAIL
            },
            NO_TAG_ITEM_SELECTED,
            TAG_DESTINATION_TYPE_CITY,
            C_UNIT_ACTIVATION_DESTINATION_SAVE_V
        }
    },

    methods:{
        save(){
            if (this.isSame === false){
                this.confirmation_dialog_state = true
            }else{
                this.loading = true
                if (this.mode === 'withoutSave' && this.selectedTag){
                    saveTagsForPropertyDestination({unit_list: this.selectedUnits, object_type: OBJECT_TYPE_PROPERTY, tag_id: this.selectedTag}).
                    then(() => {
                        notifySuccess()
                        this.$emit('destinationSaved')
                        EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
                    }).catch(error => {
                        this.showErrorMessages(error, this.validation)
                    }).finally(() => this.loading = false )
                }
                if (this.mode === null){
                    saveTagsForPropertyDestination({unit_list: this.selectedUnits, object_type: OBJECT_TYPE_PROPERTY, tag_id: this.selectedTag, override_validation: true}).
                    then(() => {
                        notifySuccess()
                        this.$emit('destinationSaved')
                        EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
                    }).catch(error => {
                        this.showErrorMessages(error, this.validation)
                    }).finally(() => this.loading = false )
                }
            }
        },
        continueAction(){
            this.confirmation_dialog_state = false
            this.isSame = true
            this.save()
        },
    },
    watch:{
        checkTagList:{
            handler(){
                if (this.checkTagList){
                    let req = {
                        page: 1,
                        perPage: 10,
                        destination_type: this.TAG_DESTINATION_TYPE_CITY,
                        company: this.company_id,
                        tag_type: TAG_TYPE_DESTINATION,
                    }

                    getTagsPaginated(req).then(response => {
                        this.destinationTagOptions = response.data.items
                    })
                }
            }
        },
        countryTag:{
            handler(value){
                let req = {
                    page: 1,
                    perPage: 999,
                    destination_type: TAG_DESTINATION_TYPE_REGION,
                    company: this.company_id,
                    country_parent_id: value,
                    tag_type: TAG_TYPE_DESTINATION,
                }
                getTagsPaginated(req).then(response => {
                    this.regionTagOptions = response.data.items
                })

                let reqCity = {
                    distribution_id: this.distribution_id,
                    destination_type: TAG_DESTINATION_TYPE_CITY,
                    company: this.company_id,
                    tag_type:TAG_TYPE_DESTINATION,
                    country: value,
                    children_only: true
                }
                getWebsiteTags(reqCity).then(response => {
                    this.tagOptions = response.data
                })
            }
        },
        regionTag:{
            handler(value){
                let req = {
                    distribution_id: this.distribution_id,
                    destination_type: TAG_DESTINATION_TYPE_CITY,
                    company: this.company_id,
                    tag_type:TAG_TYPE_DESTINATION,
                    region_parent_id: value,
                    children_only: true
                }
                getWebsiteTags(req).then(response => {
                    this.tagOptions = response.data
                })
            }
        },
    },

    created() {
        let destinationTypes = [TAG_DESTINATION_TYPE_CITY, TAG_DESTINATION_TYPE_COUNTRY, TAG_DESTINATION_TYPE_REGION]

        getWebsiteTags({distribution_id: this.distribution_id, tag_type:TAG_TYPE_DESTINATION, company_id:this.company_id, children_only: true}).then(response => {
            if (response.data.length > 0){
                this.tagOptions = response.data
            }else {
                this.checkTagList = true
            }
        })

        destinationTypes.forEach(el => {
            let req = {
                page: 1,
                perPage: 999,
                destination_type: el,
                company: this.company_id,
                tag_type: TAG_TYPE_DESTINATION,
            }

            getTagsPaginated(req).then(response => {
                // if (el === TAG_DESTINATION_TYPE_CITY){this.tagOptions = response.data.items}
                if (el === TAG_DESTINATION_TYPE_COUNTRY){this.countryTagOptions = response.data.items}
                if (el === TAG_DESTINATION_TYPE_REGION){this.regionTagOptions = response.data.items}
            })
        })
        if (this.unit_id){
            this.selectedUnits = [this.unit_id]
        }else{
            this.selected.forEach(el => {
                this.selectedUnits.push(el.id)
            })
        }
        // getExistingTagsList(OBJECT_TYPE_UNIT, {entity_id: this.selectedUnits, tag_type_id: TAG_TYPE_DESTINATION, destinationSearch:true}).then(response => {
        //     let data = response.data.length
        //     if (data > 0){
        //         this.isSame = false
        //     }
        // })
    },

    mounted() {
        EventBus.$on('saveActivationDestinationTagging', $event => {
            this.save()
        })
    },

    beforeDestroy() {
        EventBus.$off('saveActivationDestinationTagging')
    }
}
</script>

<style scoped>

</style>
