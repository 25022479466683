<template>
    <div class="date_list main_calendar_test__holder--content_dates" ref="calendar_scroll_container">
        <table-date-cell :key="dateIndex" :date="date" :index="dateIndex"
                         :position="scrollLeftPosition"
                         v-for="(date, dateIndex) in dateList"></table-date-cell>
    </div>
</template>

<script>

import TableDateCell from "@/components/unit/calendar_opt_old/Table/Dates/TableDateCell";
import {CalendarRowMixin} from "@/mixins/calendar/CalendarRowMixin";

export default {
    name: "TableDates",
    components: {TableDateCell},
    mixins: [CalendarRowMixin],
    props: {
        dateList: {
            type: Array
        },
        unit: {},
        scrollLeftPosition: {
            type: Number
        }

    },
    data() {
        return {
            currentYear: null
        }
    },
}
</script>

<style scoped>

</style>

