<template>
    <form @submit.prevent="save">
        <b-row class="text-muted">
            <b-col class="col-6">
                <b-form-group>
                    <b-form-radio-group
                        stacked
                        class="main_calendar-custom-radio"
                        v-model="selected"
                        :options="options"
                        name="room_status"
                    ></b-form-radio-group>
                </b-form-group>
            </b-col>

        </b-row>
        <b-row>
            <b-col>
                <app-button-submit :disabled="selected === null || dateRanges.length === 0 || allowedWeekday.length === 0"
                                   :loading="loading_button"></app-button-submit>
            </b-col>
        </b-row>
    </form>
</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {saveRoomStatus, saveRoomStatusTest} from "@/services/pricing/restriction";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {toast} from "@/shared/plugins/toastr";
    import {EventBus, GE_CALENDAR_LOAD_ROOM_STATUS, GE_CALENDAR_REFRESH_DATA} from "@/shared/EventBus";


    export default {
        name: "CalendarAsideRoomStatus",
        components: {AppButtonSubmit},
        props: {
            unit: {
                type: Object
            },
            dateRanges: {
                type: Array
            },
            defaultValues: {
                type: Object,
                default: null
            },
            allowedWeekday:{
                type:Array
            }
        },
        mixins: [getErrorMessage],
        data() {
            return {
                selected: null,
                options: [
                    {text: this.$t("OPEN_UNIT"), value: 1},
                    {text: this.$t("CLOSE_UNIT"), value: 0}
                ],
                loading_button: false,

            }
        },
        methods: {
            save() {
                this.loading_button = true
                let request = {
                    periods: [],
                    rate_plan: [this.unit.primary_rate_plan.id],
                    value: this.selected,
                    allowed_weekday:this.allowedWeekday
                }

                this.dateRanges.forEach(periodItem => {
                    request.periods.push({
                        from: periodItem.start,
                        to: periodItem.end
                    })
                })

                saveRoomStatusTest(this.unit.id, request).then(response => {

                    EventBus.$emit(GE_CALENDAR_REFRESH_DATA, {unitId: this.unit.id})


                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 6000
                    })
                    this.loading_button = false


                }, (error) => {

                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 10000
                    })
                    this.loading_button = false


                })
            }
        },
        watch: {
            defaultValues: {
                handler(defaultValue) {
                    if (defaultValue && defaultValue.hasOwnProperty('room_status')) {
                        this.selected = defaultValue.room_status
                    }
                },
                immediate: true,
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>
