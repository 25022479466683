<template>
    <b-form @submit.prevent="save">
        <app-access-control @AccessControlEvent="addToAccessControlCounter()" class="pl-0"
                            :access_control_context="{key: access_control_fetch_key, function: C_COMPANY_WHITELABEL_SETUP}"
                            v-slot:default="props">
            <b-row>
                <b-col>
                    <form-header>{{ $t('WHITELABEL') }}</form-header>
                </b-col>
            </b-row>
            <b-row class="pl-0 mb-4">
                <b-col sm="6" md="4" lg="3">
                    <label>{{ $t('TYPE') }}</label>
                    <app-select
                        v-model="formData[WHITELABEL]"
                        :options="levelList"
                        text-field="label"
                        mode="select">>
                    </app-select>
                </b-col>
            </b-row>
        </app-access-control>
        <b-row>
            <b-col>
                <form-header>{{ $t('GUEST_BACKOFFICE') }}</form-header>
            </b-col>
        </b-row>
        <b-row class="mb-4">
            <b-col sm="12" md="6" lg="3">
                <b-checkbox
                    v-model="formData[GUEST_BACKOFFICE_ENABLED]"
                    switch
                    :unchecked-value="0"
                    :value="1"
                >
                    <label>{{ $t('ENABLE_DISABLE') }}</label>
                </b-checkbox>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <form-header>{{ $t('MARKETPLACE') }}</form-header>
            </b-col>
        </b-row>
        <b-row class="mb-4">
            <b-col sm="12" md="6" lg="3">
                <b-checkbox
                    v-model="formData[MARKETPLACE_ENABLED]"
                    switch
                    :unchecked-value="0"
                    :value="1"
                >
                    <label>{{ $t('ENABLE_DISABLE') }}</label>
                </b-checkbox>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <form-header>{{ $t('IMPORT_ICAL') }}</form-header>
            </b-col>
        </b-row>
        <b-row class="mb-4">
            <b-col sm="12" md="6" lg="3">
                <b-checkbox
                    v-model="formData[ICAL_IMPORT_ENABLED]"
                    switch
                    :unchecked-value="0"
                    :value="1"
                >
                    <label>{{ $t('ENABLE_DISABLE') }}</label>
                </b-checkbox>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <form-header>{{ $t('HIDE_SALES_CHANNEL') }}</form-header>
            </b-col>
        </b-row>
        <b-row class="mb-4">
            <b-col sm="12" md="6" lg="3">
                <b-checkbox
                    v-model="formData[HIDE_SALES_CHANNEL_TO_PROPERTY_OWNER]"
                    switch
                    :unchecked-value="0"
                    :value="1"
                >
                    <label>{{ $t('ENABLE_DISABLE') }}</label>
                </b-checkbox>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <app-button-submit :inline="true" :disabled="saving" :loading="saving">
                    {{ $t("SAVE") }}
                </app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import {getWhiteLabelSetup, setFeatureParameters} from "@/services/company";
import {AC_COMPANY} from "@/mixins/AccessControl/AccessControlEnumeration";
import {C_COMPANY_WHITELABEL_SETUP} from "@/shared/function_permission";
import AppAccessControl from "@/components/app/AppAccessControl";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import _cloneDeep from "lodash/cloneDeep";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import FormHeader from "@/components/app/form/FormHeader";
import {notifySuccess} from "@/shared/plugins/toastr";


const HIDE_SALES_CHANNEL_TO_PROPERTY_OWNER = 59
const MARKETPLACE_ENABLED = 94
const WHITELABEL = 98
const ICAL_IMPORT_ENABLED = 101
const GUEST_BACKOFFICE_ENABLED = 160

const paramConstants = {
    WHITELABEL,
    HIDE_SALES_CHANNEL_TO_PROPERTY_OWNER,
    ICAL_IMPORT_ENABLED,
    MARKETPLACE_ENABLED,
    GUEST_BACKOFFICE_ENABLED
}

export default {
    name: "CompanyFeatureSetupForm",
    mixins: [getErrorMessage, AccessControlComponent],
    components: {FormHeader, AppButtonSubmit, AppSelect, AppAccessControl},
    props: {
        company: {
            type: Object
        }
    },

    data() {
        return {
            ...paramConstants,
            access_control_fetch_key: AC_COMPANY,
            AC_COMPANY,
            C_COMPANY_WHITELABEL_SETUP,
            formData: {
                [MARKETPLACE_ENABLED]: 0,
                [WHITELABEL]: 0,
                [ICAL_IMPORT_ENABLED]: 0,
                [HIDE_SALES_CHANNEL_TO_PROPERTY_OWNER]: 0,
                [GUEST_BACKOFFICE_ENABLED]: 0

            },
            levelSelect: 1,
            levelList: null,
            saving: false,
            access_control_components: 1,
            initialData: null
        }
    },
    methods: {
        getLevels() {
            getWhiteLabelSetup(this.company.id).then(res => {
                this.levelList = res.data
            })
        },
        save() {
            this.saving = true

            setFeatureParameters(this.company.id, this.formData).then(response => {
                this.$emit('update', response.data)
                notifySuccess()
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => this.saving = false)
        }
    },
    watch: {
        company: {
            handler(data) {
                if (data) {
                    this.formData[WHITELABEL] = data.whitelabel_level
                    this.formData[MARKETPLACE_ENABLED] = data.markeplace_enabled
                    this.formData[ICAL_IMPORT_ENABLED] = data.ical_enabled
                    this.formData[HIDE_SALES_CHANNEL_TO_PROPERTY_OWNER] = data.hide_sales_channel
                    this.formData[GUEST_BACKOFFICE_ENABLED] = data.guest_backoffice_enabled
                }
            },
            deep: true,
            immediate: true
        },
    },
    created() {
        this.getLevels()
        this.access_control_components = 1
        this.access_control_general_context = {
            company: this.company.id
        }
    }
}
</script>

<style scoped>

</style>
