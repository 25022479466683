<template>
    <div>
        <app-table-v2 :fields="computedFields"
                      :provider="getData"
                      :default-limit="defaultLimit"
                      :show-limit="showLimit"
                      @busyToggled="toggleBusy" :search-event="SEARCH_EVENT">
            <template v-slot:account_manager="data">
                <template v-if="data.item.property.account_manager">
                    {{data.item.property.account_manager.name}} {{data.item.property.account_manager.last_name}}
                </template>
            </template>

            <template v-slot:client_type="data">
                <template v-if="data.item.property.crm_client_type">
                    {{data.item.property.crm_client_type.name}}
                </template>
            </template>

            <template v-slot:period="data">
                {{data.item.start_date | date}} - {{data.item.end_date | date}}
            </template>

            <template v-slot:actions="data">
                <app-button variant="link" button_type="edit" :show_text="false"
                            @click="$emit('showSuggestions', {reportId: data.item.id, propertyId: data.item.property.id})"></app-button>
            </template>
        </app-table-v2>
    </div>
</template>

<script>
    import {getRateManagerReport} from "@/services/rate_manager";
    import {EventBus} from "@/shared/EventBus";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {cloneDeep} from "lodash";
    import AppTableV2 from "@/components/app/AppTableV2";
    import AppButton from "@/components/app/AppButton/AppButton";

    export default {
        name: "RateManagerListTable",
        components: {AppButton, AppTableV2},
        mixins: [getErrorMessage],
        props: {
            defaultLimit: {
                type: Number,
                default: 10
            },
            showLimit: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                fields: [
                    {key: 'id', label: 'ID'},
                    {key: 'account_manager', label: this.$t('ACCOUNT_MANAGER')},
                    {key: 'property.name', label: this.$t('PROPERTY')},
                    {key: 'client_type', label: this.$t('CLIENT_TYPE')},
                    {key: 'period', label: this.$t('PERIOD')},
                    {key: 'active_suggestion_count', label: this.$t('ACTIVE'), class: 'text-center'},
                    {key: 'accepted_suggestion_count', label: this.$t('ACCEPTED'), class: 'text-center'},
                    {key: 'declined_suggestion_count', label: this.$t('DECLINED'), class: 'text-center'},
                    {key: 'changed_suggestion_count', label: this.$t('CHANGED'), class: 'text-center'},
                    {key: 'actions', label: '', class: 'text-right'},
                ],
                SEARCH_EVENT: 'getReports'
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            computedFields() {
                return this.fields
            }
        },
        methods: {
            getData(req) {
                return getRateManagerReport({
                    page: req.currentPage,
                    limit: req.perPage,
                    ...this.filter,
                    company: this.company.id,
                    order_by: 'id',
                    order_by_direction: 'desc'
                })
            },
            toggleBusy($event) {
                EventBus.$emit('loadingReports', $event)
            }
        },
        mounted() {
            EventBus.$on('searchReports', filter => {
                if (filter) {
                    this.filter = cloneDeep(filter)
                }
                EventBus.$emit(this.SEARCH_EVENT)
            })
        },
        beforeDestroy() {
            EventBus.$off('searchReports')
        }
    }
</script>

<style scoped>

</style>
