<template>
    <b-form @submit.prevent="savePoi" v-if="poiItem !== null">
        <b-row>
            <b-col lg="4">
                <b-form-group>
                    <label>{{$t('DISTANCE')}}</label>
                    <b-input-group>
                        <b-form-input v-model.number="distance"  id="distance"></b-form-input>
                        <b-input-group-append>
                            <b-form-select style="background-color:#f0f3f5;" v-model="distanceType"
                                           :options="distanceTypeOptions">
                            </b-form-select>
                        </b-input-group-append>
                    </b-input-group>
                    <validation-description
                            :show="this.descriptionStateRule('distanceType') || this.descriptionStateRule('distance')">
                        {{$t('FIELD_MANDATORY')}}
                    </validation-description>
                </b-form-group>

            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col sm="12">
                <b-form-group>
                    <label>{{$t('DESCRIPTION')}}</label>
                    <b-form-textarea v-model="description" :rows="3"></b-form-textarea>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col sm="6">
                <PoiSubmit :loading="saveState"></PoiSubmit>
            </b-col>
            <b-col sm="6">
                <poi-delete :loading="deleteState" @click="showDeleteDialog=true" class="pull-right"></poi-delete>
            </b-col>
        </b-row>

        <poi-delete-dialog :show="showDeleteDialog" @confirm="deletePoi" @cancel="showDeleteDialog=false" :delete_title="true">
        </poi-delete-dialog>
    </b-form>
</template>

<script>
    import {required} from 'vuelidate/lib/validators'
    import {getValidationRule} from '@/mixins/validation/getValidationRule'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import PoiSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import PoiDelete from '@/components/app/AppButton/AppButtonDelete'
    import ValidationDescription from '@/components/app/form/AppValidationDescription'
    import {toast} from '@/shared/plugins/toastr'
    import PoiDeleteDialog from '@/components/app/form/AppConfirmationDialog'
    import {setPropertyPoi, deletePropertyPoi} from '@/services/property/index'

    export default {
        name: "PropertyPoiForm",
        components: {
            PoiSubmit, PoiDelete, ValidationDescription, PoiDeleteDialog
        },
        props: ['poiItem'],
        mixins: [getValidationRule, getErrorMessage],
        data() {
            return {
                distance: this.poiItem.distance,
                distanceType: this.poiItem.distance_type,
                description: this.poiItem.description,
                distanceTypeOptions: [
                    {value: null, text: '-'},
                    {value: "m", text: 'Meter'},
                    {value: "km", text: 'Kilometer'}
                ],
                showDeleteDialog: false,
                saveState: false,
                deleteState: false,
            }
        },
        methods: {
            savePoi() {

                this.$v.$touch();
                if ((this.$v.$error)) {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('VALIDATION_FAILED'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return
                }
                this.saveState = true


                setPropertyPoi(this.$route.params.id, {
                    data: [
                        {
                            id: this.poiItem.id,
                            distance: this.distance,
                            distance_type: this.distanceType,
                            description: this.description
                        }
                    ]
                }).then((response) => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })
                        this.$emit('poiSaved', response.data)
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }
                ).finally(this.saveState = false)
            },
            deletePoi() {
                this.deleteState = true


                deletePropertyPoi(this.$route.params.id, {id: this.poiItem.id}).then((response) => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.DELETE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.DELETE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })
                        this.showDeleteDialog = false
                        this.$emit('poiSaved', response.data)
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }
                ).finally(this.deleteState = false)
            },

        },
        validations: {
            distance: {
                required
            },
            distanceType: {
                required
            }
        },
    }
</script>

<style scoped>

</style>
