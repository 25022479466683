import { render, staticRenderFns } from "./AppearanceNewFontForm.vue?vue&type=template&id=092dbedb&scoped=true&"
import script from "./AppearanceNewFontForm.vue?vue&type=script&lang=js&"
export * from "./AppearanceNewFontForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "092dbedb",
  null
  
)

export default component.exports