<template>
    <li class="d-flex justify-content-between mb-1">
        <div v-html="highlightText"></div>
        <app-button
            v-if="checkPermission(R_PROPERTY_CALENDAR_DEV)"
            variant="link"
            :button_type="null"
            @click="$emit('close')"
            class="action_button ml-2"
            :to="{name: routes.RN_PROPERTY_CALENDAR, params:{id: property.id}, query:{unit:unit.id}}">
            {{$t("CALENDAR")}}
        </app-button>

    </li>
</template>

<script>
    import routes from '@/router/routeNames'
    import AppButton from "@/components/app/AppButton/AppButton";

    import {R_PROPERTY_CALENDAR_DEV} from "@/shared/route_permission";
    import {textFormatters} from "@/mixins/shared/helpers";

    export default {
        name: "AppSearchUnitItem",
        components: {AppButton},
        mixins: [textFormatters],
        props:{
            property: {
                type: Object
            },
            unit: {
                type: Object
            },
            keyword: {
                type: String
            }
        },
        data(){
            return {
                R_PROPERTY_CALENDAR_DEV: R_PROPERTY_CALENDAR_DEV,
                routes:routes
            }
        },
        computed:{
            highlightText(){
                return this.sanitizeText(this.unit.name);
            }
        }
    }
</script>

<style scoped>

</style>
