<template>
    <tr>
        <td>
            <b-input v-if="itemList" v-model="tag.label" type="text" @change="editItem"></b-input>
            <b-input v-if="newItem" v-model="newTag.label" v-on:keydown.enter="save" ref="tagInput"></b-input>
        </td>
        <td class="text-right">
            <app-button v-if="itemList"
                        button_type="delete"
                        :show_text="false"
                        @click="deleteItem"
                        variant="link">
            </app-button>
            <app-button v-if="newItem" :disabled="disableAddItem" button_type="new"
                        @click="save">
                {{ $t('ADD_ITEM') }}
            </app-button>
        </td>
    </tr>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";

    export default {
        name: "TagItem",
        components: {AppButton},
        props: {
            itemList: Boolean,
            newItem: Boolean,
            tag: Object,
        },
        data() {
            return {
                newTag: {label: null},
                loading: false,
            }
        },
        methods: {
            deleteItem() {
                this.$emit('deleteItem', this.tag)
            },
            editItem(){
                this.$emit('editItem', this.tag)
            },
            save() {
                if(this.newTag.label) {
                    this.$emit('addItem', this.newTag)
                    this.newTag.label = null
                    this.$refs.tagInput.focus()
                }
            }
        },
        computed:{
            disableAddItem(){
                return !this.newTag.label
            }
        }
    }
</script>

<style scoped>

</style>
