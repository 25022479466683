<template>
    <form>
        <div v-for="category in appearance">
            <form-header>{{category.label}}</form-header>
            <b-row class="mb-4">
                <b-col lg="6" xl="3" md="6" class="mb-4" v-for="item in category.items">
                    <label>{{item.label}}</label>
                    <app-select v-if="item.type.id === APPEARANCE_TYPE_COLOR"
                                mode="search"
                                v-model="item.value"
                                :search-empty-item="false"
                                :search="searchColors"
                                text-field="label"
                    >
                        <template v-slot:text="data">
                            <div class="d-flex align-items-center">
                                <template v-if="data.item.category && data.item.category.id && data.item.category.label">[{{data.item.category.label}}] &nbsp;</template>
                                <template v-else-if="data.item.category && data.item.category.id">[Category {{data.item.category.id}}] &nbsp;</template>
                                {{data.item.label}}
                                <div v-if="data.item.label" class="ml-2"
                                     :style="'background-color:'+data.item.hex+';height:15px;width:15px;border-radius:15px;border:1px solid #ececec'"
                                ></div>
                            </div>
                        </template>
                    </app-select>
                    <app-select v-if="item.type.id === APPEARANCE_TYPE_FONT"
                                mode="search"
                                v-model="item.value"
                                :search-empty-item="false"
                                :search="searchFonts"
                                text-field="label"
                    >
                        <template v-slot:text="data">
                            <span>{{ data.item.label + ' ' + '(' + data.item.name + ')'}}</span>
                        </template>
                    </app-select>
                </b-col>
            </b-row>
        </div>
        <app-button @click="save" :loading="loading">{{$t('SAVE')}}</app-button>
    </form>
</template>

<script>

import FormHeader from "@/components/app/form/FormHeader";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButton from "@/components/app/AppButton/AppButton";
import {getAllThemeColors, getAllThemeFonts, getThemeItem, saveThemeSetup} from "@/services/appearance";
import {APPEARANCE_TYPE_COLOR, APPEARANCE_TYPE_FONT} from "@/shared/constants";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {EventBus, GE_LOADER_HIDE, GE_LOADER_SHOW} from "@/shared/EventBus";

export default {
    name: "AppearanceForm",
    components: {FormHeader, AppSelect, AppButton},
    mixins: [getErrorMessage],
    computed: {
        company(){
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    data(){
        return{
            appearance: [],
            fontOptions: [],
            colorOptions: [],
            items: [],
            loading: false,
            APPEARANCE_TYPE_FONT,
            APPEARANCE_TYPE_COLOR
        }
    },
    props:{
        appClientId:{
            type: Number,
            required: true
        },
        appClientTypeId:{
            type: Number,
            required: true
        }
    }
    ,
    methods:{
        getData(){
            getThemeItem({app_client_type: this.appClientTypeId, app_client: this.appClientId}).then(response => {
                this.appearance = response.data
            })
        },
        save(){
            this.prepareData()
            this.loading = true
            EventBus.$emit(GE_LOADER_SHOW)
            saveThemeSetup({
                app_client_type: this.appClientTypeId,
                app_client: this.appClientId,
                items: this.items
            }).then(response => {
                notifySuccess()
                this.getData()
                EventBus.$emit(GE_LOADER_HIDE)
                this.loading = false
            }, error => {
                this.showErrorMessages(error)
                this.loading = false
                EventBus.$emit(GE_LOADER_HIDE)
            })
        },
        prepareData(){
            this.appearance.forEach(el => {
                el.items.forEach(item => {
                    this.items.push({
                        'theme_item_id': item.id,
                        'theme_color_id': item.type.id === APPEARANCE_TYPE_COLOR ? item.value.id : null,
                        'theme_font_id': item.type.id === APPEARANCE_TYPE_FONT ? item.value.id : null
                    })
                })
            })
        },
        searchColors(value){
            return getAllThemeColors({name: value, company_id: this.company.id}).then(response => {
                return {data: response.data}
            })
        },
        searchFonts(value){
            return getAllThemeFonts({name: value, company_id: this.company.id}).then(response => {
                return{data:response.data}
            })
        }
    },
    created() {
        this.getData()
    }
}
</script>

<style scoped>

</style>
