<template>
    <div>
        <b-row>
            <b-col cols="12"
                   class="col-xxl-6"
                   :key="index"
                   v-for="(distribution, index) in distributionList">
                <platform-fee-distribution-item
                    :distribution="distribution"
                    :company="company">
                </platform-fee-distribution-item>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {getDistributionList} from "@/services/distribution";
import PlatformFeeDistributionItem from "@/components/fee/platform_fee/PlatformFeeDistributionItem";

export default {
    name: "Index",
    components: {PlatformFeeDistributionItem},
    data() {
        return {
            distributionList: [],
        }
    },
    methods: {
        getDistributionList() {
            const request = {
                company: this.company.id,
                for_reservation_process: 1,
            }
            getDistributionList(request).then((response) => {
                this.distributionList = response.data
            })
        },
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    created() {
        this.getDistributionList()
    },
}
</script>

<style scoped>

</style>
