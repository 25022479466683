<template>
    <div>
        <guest-check-in-e-visitor v-if="country === CROATIA"
                                  :property="property"
                                  :data_loaded="data_loaded"
                                  :deleted_flag="deleted"
                                  :accessData="accessData"
                                  @setData="saveGuestAccessData"
                                  @deleteData="deleteData">
        </guest-check-in-e-visitor>
        <guest-check-in-id-reader v-else-if="country === SERBIA"
                                  :property="property"
                                  :data_loaded="data_loaded"
                                  :deleted_flag="deleted"
                                  :accessData="accessData"
                                  @setData="saveGuestAccessData"
                                  @deleteData="deleteData">

        </guest-check-in-id-reader>
        <guest-check-in-ajpes v-else-if="country === SLOVENIA"
                              :property="property"
                              :deleted_flag="deleted"
                              :data_loaded="data_loaded"
                              :accessData="accessData"
                              @setData="saveGuestAccessData"
                              @deleteData="deleteData" >

        </guest-check-in-ajpes>
        <app-no-data v-else  :tip="$t('MODULE_NOT_AVAILABLE_FOR_COUNTRY')">

        </app-no-data>

    </div>
</template>

<script>


    import GuestCheckInEVisitor from "@/components/guest/checkin/GuestCheckInEVisitor";
    import GuestCheckInAjpes from "@/components/guest/checkin/GuestCheckInAjpes";
    import GuestCheckInIdReader from "@/components/guest/checkin/GuestCheckInIdReader";
    import {CROATIA, SERBIA, SLOVENIA} from "@/shared/constants";
    import {setGuestAccessData, deleteAccessData} from "@/services/guest";
    import {toast} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import AppNoData from "@/components/app/AppNoData";

    export default {

        name: "index",
        data() {
            return {
                CROATIA,
                SERBIA,
                SLOVENIA,
                owner_list: [],
                data_loaded:false,
                deleted:false,
                accessData:{}
            }
        },
        mixins:[getErrorMessage],
        components: {
            AppNoData,
            GuestCheckInAjpes,
            GuestCheckInEVisitor,
            GuestCheckInIdReader
        },

        computed: {
            property() {
                return this.$store.getters['property/getProperty']
            },
            country() {
                return this.property.hasOwnProperty('location') ? this.property.location.country.id : null
            },
        },
        methods: {
            saveGuestAccessData(object) {
                this.data_loaded  = true
                setGuestAccessData(this.property.id, object).then(response => {
                    this.accessData = response.data
                    this.deleted =false
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                    this.data_loaded  = false
                }, () => {
                    this.data_loaded  = false
                })
            },
            deleteData(id){
                deleteAccessData(this.property.id, id).then(() =>{
                    this.deleted = true
                    toast({
                        'title': this.$t('NOTIFICATIONS.DELETE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.DELETE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                }, error =>{
                    toast({
                        'title': this.$t('NOTIFICATIONS.DELETE_ERROR.TITLE'),
                        'message': this.getMessages(error),
                        'type': 'error',
                        'timeout': 2000
                    })
                })
            }
        }
    }
</script>

<style scoped>

</style>
