<template>
    <tr>
        <td :class="{'pl-0': wide_display}">
            <app-date-range-picker format="MM/DD" :min-date="firstDayCurrentYear" :max-date="endDayCurrentYear" v-model="selectedPeriod.range"></app-date-range-picker>
        </td>
        <td :class="{'pl-0': wide_display, 'pr-0': wide_display}">
            <b-form-input v-model="selectedPeriod.value" :step="0.01">  </b-form-input>
        </td>
        <td class="text-right" :class="{'pr-0': wide_display}">
            <app-new-button :disabled="!selectedPeriod.range || !selectedPeriod.value" button_type="new"
                            @click="save"
                            :loading="loading">
                {{$t('ADD_ITEM')}}
            </app-new-button>
        </td>
    </tr>
</template>

<script>
import AppNewButton from '@/components/app/AppButton/AppButton'
import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
import moment from 'moment'

export default {
    name: "PeriodRateMaskItemNew",
    data() {
        return {
            selectedPeriod: {
                id: null,
                range: {
                    start: null,
                    end: null
                },
                value: null,
                deleted: null
            },

        }
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        wide_display: {
            type: Boolean,
            default: false
        }
    },
    components: {
        AppDateRangePicker,
        AppNewButton,
    },
    computed: {
        firstDayCurrentYear() {
            return moment().startOf('year').format('YYYY-MM-DD')
        },
        endDayCurrentYear() {
            return moment().endOf('year').format('YYYY-MM-DD')
        }
    },
    watch: {
        loading: {
            handler(value) {
                if (!value) {
                    this.selectedPeriod.range.start = null
                    this.selectedPeriod.range.end = null
                    this.selectedPeriod.value = null
                }
            },
            immediate: true
        }
    },
    methods: {
        save() {
            let period = {
                start: this.formatDate(this.selectedPeriod.range.start),
                end: this.formatDate(this.selectedPeriod.range.end),
                value: this.selectedPeriod.value
            }
            this.$emit('save', period)
        },
        formatDate(value) {
            if (value) {
                let date = new Date(value)
                return moment(date).format('MM/DD')
            }
        },
    }
}
</script>

<style scoped>

</style>
