<template>
    <div>
        <app-table-v2
            :selectable="true"
            class="reservation_table"
            :fields="tableFields"
            :http="{methods:'GET', url:TRANSACTION_LIST_URL}"
            :provider="getData"
            :exportColumns="exportColumns"
            :limit-list="[DEFAULT_PAGINATION_LIMIT, 20, 50, 100]"
            @busyToggled="toggleBusy"
            search-event="ge_get_transaction"
            :additional-sort-options="additionalSortOptions"
        >

            <template v-slot:order_number="data">
                {{ data.item.order_number }}
                <small class="text-muted d-block">
                    {{ data.item.approval_number }}
                </small>
            </template>

            <template v-slot:reservation="data">
                <template v-if="data.item.reservation">
                    <b-link @click="() => {reservationAsideObject={id: data.item.reservation.id};reservationAsideState=true}">{{data.item.reservation.reservation_code}}
                    </b-link>
                </template>

            </template>
            <template v-slot:guest_invoice="data">
                <template v-if="data.item.invoices">
                    <span :key="index" v-if="Object.keys(invoices).length > 0 && invoices[data.item.id] && invoices[data.item.id].length > 0 && invoice.client.client_type && Number(invoice.client.client_type.id) === GUEST_INVOICE" v-for="(invoice,index) in invoices[data.item.id]">
                            <b-link @click="() => {invoiceDetailsObject={id: invoice.id,hr_number:invoice.hr_number,description:invoice.description};invoiceDetailsAsideState=true}" >
                               {{invoice.hr_number}}
                              </b-link>
                        <template v-if="(invoices[data.item.id].length - 1) !== index && index !== 0">,</template>
                    </span>

                </template>

            </template>
            <template v-slot:owner_invoice="data">
                <template v-if="data.item.invoices">
                    <span :key="index" v-if="Object.keys(invoices).length > 0 && invoices[data.item.id] && invoices[data.item.id].length > 0 && invoice.client.client_type && Number(invoice.client.client_type.id) === OWNER_INVOICE" v-for="(invoice,index) in invoices[data.item.id]">
                            <b-link @click="() => {invoiceDetailsObject={id: invoice.id,hr_number:invoice.hr_number,description:invoice.description};invoiceDetailsAsideState=true}" >
                               {{invoice.hr_number}}
                              </b-link>
                        <template v-if="(invoices[data.item.id].length - 1) !== index && index !== 0">,</template>
                    </span>

                </template>

            </template>
            <template v-slot:amount="data">
                {{data.item.amount}} {{data.item.currency.name}}
            </template>
            <template v-slot:timestamp="data">
                {{data.item.timestamp|timestamp}}
            </template>
            <template v-slot:credit_card_number="data">
                <small class="text-muted d-block">
                    ####{{data.item.credit_card_number}}
                </small>
                {{data.item.credit_card_type}}
            </template>
            <template v-slot:edit="data">
                <app-button @click="edit(data.item)" variant="link" button_type="edit" :show_text="false"></app-button>
            </template>

            <template v-slot:selectedContainer="data">

                <app-button
                            @click="exportModal=true">
                    {{$t("EXPORT")}}
                </app-button>

                <b-modal :title="$t('EXPORT')" v-model="exportModal">
                    <b-checkbox :checked="true" @change="updateSelectAllExport">
                        {{$t("SELECT_ALL")}}
                    </b-checkbox>
                    <hr class="p-0 m-0 mb-1 mt-1">
                    <b-checkbox-group class="two_col_checkbox_group" v-model="exportActionSelected" stacked
                                      :options="exportColumns"></b-checkbox-group>
                    <template v-slot:modal-footer>
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <div v-if="data.totalRows > 1 && data.totalRows > data.selected.length">
                                <b-checkbox v-model="selectAllForExport">
                                    {{$t("APPLY_TO_ALL_NUMBER", {number:data.totalRows})}}
                                    <span v-if="data.selected.length === 1" class="d-block text-muted font-xs">({{$t("ITEM_SELECTED", {value: data.selected.length})}})</span>
                                    <span v-if="data.selected.length > 1" class="d-block text-muted font-xs">({{$t("ITEMS_SELECTED", {value: data.selected.length})}})</span>
                                </b-checkbox>
                                <small v-if="data.totalRows > maxExportLimit" class="text-muted">
                                    {{$t('EXPORT_MAX_LIMIT_WARNING', {num:maxExportLimit})}}
                                </small>
                            </div>
                            <app-button @click="exportData(data)" :loading="exportLoading"
                                        :disabled="exportActionSelected.length === 0" class="mr-3">
                                {{$t('EXPORT')}}
                            </app-button>
                        </div>
                    </template>

                </b-modal>
            </template>

        </app-table-v2>
        <app-aside v-model="asideTransaction" :widths="['col-lg-6']">
            <template v-slot:header>
                {{$t('EDIT')}}
            </template>
            <a-r-transaction-table
                v-if="edit_transaction && transactionList.length && allowedActionObject && loadedTransactions"
                :table-data="transactionList"
                :allowed-actions="allowedActionObject.transaction"
                :reservation="edit_transaction.reservation"
                @openForm="showTransactionForm"
                >
            </a-r-transaction-table>
            <app-no-data v-if="transactionList.length === 0 && loadedTransactions"
                         :show-tip="false"></app-no-data>
        </app-aside>
        <app-aside v-model="transactionPanelState">
            <div slot="header">{{transactionHeader}}</div>
            <transaction-form v-if="edit_transaction" :transaction-object="transactionObject"
                              :object-type="OBJECT_TYPE_ACCOMMODATION_RESERVATION"
                              :object-id="edit_transaction.reservation.id"
                              @close="()=>{this.transactionPanelState=false;getData();}">
            </transaction-form>
        </app-aside>
        <app-aside v-model="reservationAsideState" :widths="['col-lg-12','col-sm-12', '100%']" full-width
                   header-styles="position: sticky; top: 0; z-index: 999; background-color:#fff">
            <template slot="header">
                <reservation-accommodation-header :reservation="reservationAsideObject"></reservation-accommodation-header>
            </template>
            <reservation-details :reservation-id="reservationAsideObject.id" tab="general" @update="reservationAsideObject=$event"></reservation-details>
        </app-aside>

        <app-aside v-model="invoiceDetailsAsideState">
            <template slot="header">
                <app-object-header :label="$t('INVOICE')"
                                   :name="invoiceDetailsObject && invoiceDetailsObject.hr_number"
                                   :description="invoiceDetailsObject && invoiceDetailsObject.description">
                </app-object-header>
            </template>
            <invoice-details :invoice-id="invoiceDetailsObject && invoiceDetailsObject.id" @close="closeDetails"></invoice-details>
        </app-aside>
    </div>
</template>

<script>
import AppTableV2 from "@/components/app/AppTableV2";
import {fetchTransaction, getTransactions} from "@/services/finance/transaction";
import {EventBus, GE_TABLE_REFRESH_SEARCH} from "@/shared/EventBus";
import AppButton from "@/components/app/AppButton/AppButton";
import AppAside from "@/components/app/form/AppAside";
import ARTransactionTable from "@/components/reservation/accommodation/list/ARTransactionTable";
import AppNoData from "@/components/app/AppNoData";
import {
    F_TRANSACTION_AUTHORIZE,
    F_TRANSACTION_CAPTURE,
    F_TRANSACTION_PURCHASE,
    F_TRANSACTION_REFUND,
    F_TRANSACTION_VOID,
} from "@/shared/function_permission";
import {fetchAccessControlData} from "@/services/access";
import {AC_ACCOMMODATION_RESERVATION_TRANSACTION} from "@/mixins/AccessControl/AccessControlEnumeration";
import {getAccommodationReservationTransactionValuesList} from "@/services/accommodation_reservation";
import {groupExport} from "@/services/finance/transaction"
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import TransactionForm from "@/components/finance/credit_card/TransactionForm";
import ReservationAccommodationHeader from "@/components/reservation/accommodation/ReservationAccommodationHeader";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import InvoiceDetails from "@/components/finance/invoices/InvoiceDetails";
import ReservationDetails from "@/components/reservation/accommodation/ReservationDetails";
import moment from "moment";
import {notifySuccess, toast} from "@/shared/plugins/toastr";
import {
    OBJECT_TYPE_ACCOMMODATION_RESERVATION,
    DEFAULT_PAGINATION_LIMIT,
    TRANSACTION_LIST_URL,
    PG_ACTION_PURCHASE,
    PG_ACTION_AUTHORIZE,
    PG_ACTION_CAPTURE,
    PG_ACTION_REFUND,
    PG_ACTION_VOID,
} from "@/shared/constants";

const permissionActionMap = {
    initial: {
        [F_TRANSACTION_PURCHASE]: {id: PG_ACTION_PURCHASE, t: 'PURCHASE'},
        [F_TRANSACTION_AUTHORIZE]: {id: PG_ACTION_AUTHORIZE, t: 'AUTHORIZE'},
    },
    transaction: {
        [F_TRANSACTION_CAPTURE]: {id: PG_ACTION_CAPTURE, t: 'CAPTURE'},
        [F_TRANSACTION_REFUND]: {id: PG_ACTION_REFUND, t: 'REFUND'},
        [F_TRANSACTION_VOID]: {id: PG_ACTION_VOID, t: 'VOID'},
    }
}
function generateACRequest(data) {
    let requestData = [];

    data.forEach(item => {
        for (const [key, value] of Object.entries(permissionActionMap.transaction)) {
            requestData.push({
                uid: item.id,
                transaction: item.id,
                function: key
            })
        }
    })
    for (const [key, value] of Object.entries(permissionActionMap.initial)) {
        requestData.push({
            uid: null,
            function: key
        })
    }

    return requestData
}
export default {
    name: "TransactionTable",
    props:{
        filter:{
            type:Object,
        }
    },
     mixins: [getErrorMessage],
    data() {
        return {
            DEFAULT_PAGINATION_LIMIT,
            TRANSACTION_LIST_URL,
            OBJECT_TYPE_ACCOMMODATION_RESERVATION,
            exportModal:false,
            asideTransaction:false,
            list:[],
            transactionList:[],
            tableFields: [
                {key: 'id', label: this.$t('ID')},
                {key: 'order_number', label: this.$t('NUMBER') + ' / ' + this.$t('APPROVAL_CODE')},
                {key: 'credit_card_number', label: this.$t('CC_DATA')},
                {key: 'type.label', label: this.$t('TYPE')},
                {key: 'status.name', label: this.$t('STATUS')},
                {key: 'reservation', label: this.$t('RESERVATION_CODE')},
                {key: 'owner_invoice', label: this.$t('OWNER_INVOICE')},
                {key: 'guest_invoice', label: this.$t('GUEST_INVOICE')},
                {key: 'amount', label: this.$t('AMOUNT')},
                {key: 'timestamp', label: this.$t('CREATED_AT')},
                {key: 'edit', label: "", class: 'text-right ws-no-wrap'},
            ],
            exportColumns:[
                {value: 'transaction_id', text: 'Id'},
                {value: 'order_number', text: this.$t('NUMBER')},
                {value: 'cc_data', text: this.$t('CC_DATA')},
                {value: 'transaction_type', text: this.$t('TYPE')},
                {value: 'transaction_status', text: this.$t('STATUS')},
                {value: 'distribution_code', text: this.$t('RESERVATION_CODE')},
                {value: 'owner_invoice', text: this.$t('OWNER_INVOICE')},
                {value: 'guest_invoice', text: this.$t('GUEST_INVOICE')},
                {value: 'transaction_amount', text: this.$t('AMOUNT')},
                {value: 'transaction_timestamp', text: this.$t('CREATED_AT')}
            ],
            additionalSortOptions: [
                {key: 'created_at', label: this.$t('CREATED_AT')},
            ],
            edit_transaction:null,
            allowedActionObject:{},
            loadingTransaction:false,
            loadedTransactions:false,
            transactionValuesList:[],
            transactionPanelState:false,
            transactionHeader: '',
            transactionObject: {},
            reservationAsideState:false,
            reservationAsideObject:{},
            invoices:{},
            GUEST_INVOICE:2,
            OWNER_INVOICE:1,
            invoiceDetailsAsideState:false,
            invoiceDetailsObject:null,
            exportActionSelected: [],
            exportLoading:false,
            maxExportLimit: 1000,
            selectAllForExport:false
        }
    },
    components: {
        ReservationDetails,
        InvoiceDetails,
        AppObjectHeader,
        ReservationAccommodationHeader,
        TransactionForm, AppNoData, ARTransactionTable, AppButton, AppTableV2,AppAside},
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods: {
        clearFilterNull(){
            const formatedFilter = {}
           for(const[key,value] of Object.entries(this.filter)){
               if((Array.isArray(value) &&  value.length === 0) || value === null){
                   continue;
               }
               formatedFilter[key] = value
           }
           return formatedFilter
        },
        getActionFilter(selected, limit = 20) {
            const filter =  this.clearFilterNull()
            if (this.selectAllForExport) {
                return {...filter, perPage: limit, company: this.company.id}
            }
            return {
                transaction: selected.map(el => {
                    return el.id
                })
            }
        },
        async exportData(data) {
            this.exportLoading = true
            let filter=  this.getActionFilter(data.selected, this.maxExportLimit)

            await groupExport({...{filter}, ...data.sort, ...{columns: this.exportActionSelected}}, this.$t("TRANSACTIONS", {date: moment().format('YYYY-MM-DD')})).then(response => {
                notifySuccess()
            }, error => {
                toast({
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: error}),
                    'type': 'error',
                    'timeout': 60000
                })
            })
            this.exportLoading = false
            this.exportModal = false
        },
        updateSelectAllExport($event) {
            if ($event) {
                this.exportActionSelected = this.exportColumns.map(el => el.value)
            } else {
                this.exportActionSelected = []
            }
        },
        closeDetails(){
            this.invoiceDetailsObject = null
            this.invoiceDetailsAsideState = false
            EventBus.$emit('ge_get_transaction')
        },
        showTransactionForm({transaction = {}, action}) {
            const key = transaction.id ? 'transaction' : 'initial'
            this.transactionHeader = `${this.$t(permissionActionMap[key][action].t)} ${transaction.order_number || ''}`

            let recommendedValues
            if (transaction.id) {
                recommendedValues = this.transactionValuesList.find(el => el.action === permissionActionMap[key][action].id && el.transaction === transaction.id) || {}
            } else {
                recommendedValues = this.transactionValuesList.find(el => el.action === permissionActionMap[key][action].id) || {}
            }

            this.transactionObject = {
                pg_action: permissionActionMap[key][action].id,
                amount: recommendedValues.amount || transaction.amount || 0,
                transaction: transaction.id,
                next_status: recommendedValues.next_status || null
            }
            this.transactionPanelState = true
        },
        edit(transaction){
            this.edit_transaction = transaction
            this.getTransactionById(transaction.id)
            this.asideTransaction = true
        },
        getTransactionById(transaction_id){
            this.loadingTransaction = true
            this.loadedTransactions = false
            fetchTransaction(transaction_id).then(response=>{
                this.transactionList = response.data
                if(response.data && response.data.length){
                    fetchAccessControlData(AC_ACCOMMODATION_RESERVATION_TRANSACTION, {data: generateACRequest(response.data)}).then(acResponse=>{
                        this.allowedActionObject = this.getActions(this.transactionList, acResponse.data)
                    }, error => this.showErrorMessages(error)).finally(()=>{
                        this.loadingTransaction = false
                        this.loadedTransactions = true
                    })
                }
            }, error => this.showErrorMessages(error))
            if(this.edit_transaction.reservation.id !== null){
                getAccommodationReservationTransactionValuesList(this.edit_transaction.reservation.id).then(response => {
                    this.transactionValuesList = response.data
                }, error => this.showErrorMessages(error)).finally(() => this.loadedValues = true)
            } else  {
                this.loadedValues = true
            }

        },
        getActions(transactionList, permissionList) {
            let actionObject = {transaction: {}, initial: []}

            actionObject.initial = permissionList
                .filter(item => !item.uid && item.visible === true && item.disabled === false)
                .map(item => item.function)

            transactionList.forEach(transaction => {
                actionObject.transaction[transaction.id] = permissionList
                    .filter(item => item.uid === transaction.id && item.visible === true && item.disabled === false)
                    .map(item => item.function)
            })

            return actionObject
        },
        getData(filter){

            let req = {
                ...this.filter,
                ...{
                    page: filter.currentPage,
                    perPage: filter.perPage,
                    order_by: filter.sortBy,
                    order_by_direction: filter.sortDirection,
                    company: this.company.id
                }
            }
            const provider = getTransactions(req)
            provider.then(response=>{
                this.invoices = {}
                this.list = response.data.items
                if(this.list.length > 0){
                    for(const object of this.list){
                        if(object.hasOwnProperty('invoices') && object.invoices && object.invoices.length > 0){
                                this.invoices[object.id] = object.invoices
                        }
                    }
                }
            })
            return provider
        },
        toggleBusy($event) {
            this.$emit('busyToggled', $event)
        },
    },
    watch: {
        filter: {
            handler(value) {
                EventBus.$emit('ge_get_transaction')
            },deep:true
        },
    },
    created() {
        this.exportActionSelected = this.exportColumns.map(el => el.value)
    }
}
</script>

<style scoped>

</style>
