<template>
    <b-card v-if="Property.id && Owner.id" v-cloak class="property-wrapper">
        <template slot="header">
            <div class="d-flex justify-content-between flex-column flex-md-row">
                <div>
                    <div class="header-caption">{{$t('PROPERTY')}}</div>
                    <h2 v-if="Property">{{Property.name}}</h2>
                </div>

                <div>
                    <div class="header-caption pl-0 pl-md-2 mt-3 mt-md-0">{{$t('OWNER')}}</div>
                    <app-quick-link :routes="OwnerQuickList" :value="Owner.id"></app-quick-link>
                </div>
            </div>

            <Navigation :back="backButtonRoute" :nav-elements="list"></Navigation>
        </template>

        <b-row>
            <b-col lg="12">
                <page-transition transition-type="slide">
                    <router-view v-cloak></router-view>
                </page-transition>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
    import routeNames from '@/router/routeNames'
    import PageTransition from '@/components/app/transition/AppTransition'
    import Navigation from "@/components/app/navigation/Navigation";
    import {getPropertyOwners} from "@/services/owner";
    import AppQuickLink from "@/components/app/AppQuickLink";
    import HeaderButton from "@/components/app/header_button/HeaderButton";

    export default {
        name: "Index",
        components: {
            HeaderButton,
            AppQuickLink,
            Navigation,
            PageTransition
        },
        data() {
            return {
                Rn: routeNames,
                PropertyObject: null,
                owner_list: [],
                backButtonRoute: {name: routeNames.RN_PROPERTY_OWNER_LIST, params: {id: this.$route.params.id}},
                list: [
                    {
                        route: {name: routeNames.RN_PROPERTY_OWNER_BASIC},
                        label: this.$t(`BASIC`),
                    },
                    {
                        route: {name: routeNames.RN_PROPERTY_OWNER_FINANCE},
                        label: this.$t('FINANCE'),
                    },
                    {
                        route: {name: routeNames.RN_PROPERTY_OWNER_DOCUMENTS},
                        label: this.$t(`DOCUMENTS`),
                    }
                ],
            }
        },
        computed: {
            Property() {
                return this.$store.getters['property/getProperty']
            },
            Owner() {
                return this.$store.getters['owner/getOwner']
            },
            OwnerQuickList() {
                return this.owner_list.map(owner => {
                    return {id: owner.id, name: this.$route.name, label: owner.name, params: {owner_id: owner.id}}
                })
            }
        },
        watch: {
            '$route.params.id': {
                handler(value) {

                    if (!this.Property.hasOwnProperty('id') || this.Property.id !== Number(value)) {
                        this.$store.dispatch('property/actionSetProperty', value)
                    }

                    getPropertyOwners(value).then(response => {
                        this.owner_list = response.data
                    }, () => {
                        this.owner_list = []
                    })
                },
                immediate: true
            },
            '$route.params.owner_id': {
                handler(value) {

                    if (!this.Owner.hasOwnProperty('id') || this.Owner.id !== Number(value)) {
                        this.$store.dispatch('owner/actionSetOwner', {
                            propertyId: this.$route.params.id,
                            ownerId: this.$route.params.owner_id
                        })
                    }


                },
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>
