<template>
    <div :class="occupancy.length >1 && los.length > 1 ? 'main_calendar_test__holder--sidebar--pricing' : 'main_calendar_test__holder--sidebar--pricing-one-item'" class="main_calendar_test-bb animated fadeIn">
        <template v-for="losItem in los">
            <template v-for="occupancyItem in occupancy">
                <div v-if="los.length >1" class="main_calendar_test--cell d-flex align-items-center justify-content-center" :class="{'main_calendar_test--background_gray': losItem.default && occupancyItem.default}">
                    <span class="pr-1">{{losItem.min_stay}}</span>
                    <span v-if="losItem.max_stay && losItem.max_stay > losItem.min_stay"> - {{losItem.max_stay}}</span>
                    <span v-else-if="!losItem.max_stay">+</span>
                    <span class="ml-2"
                          v-if="checkPermission(C_PROPERTY_CALENDAR_PRICES_LOS_LINKED_VALUE) && Number(losItem.default) !== 1 && Number(losItem.discount) !== 0">
                        ({{ Number(losItem.discount_type) === PERC ? (Number(losItem.discount) > 0 ? '+' + losItem.discount + '%' : losItem.discount + '%') : (Number(losItem.discount) > 0 ? '+' + losItem.discount : losItem.discount) }})
                    </span>
                </div>

                <div v-if="occupancy.length > 1" class="main_calendar_test--cell d-flex align-items-center justify-content-center" :class="{'main_calendar_test--background_gray': losItem.default && occupancyItem.default}">
                    <span class="pr-1">{{occupancyItem.min_guests}}</span>
                    <span v-if="occupancyItem.max_guests && occupancyItem.max_guests > occupancyItem.min_guests"> - {{occupancyItem.max_guests}}</span>
                    <span v-else-if="!occupancyItem.max_guests">+</span>
                    <span class="ml-2"
                          v-if="checkPermission(C_PROPERTY_CALENDAR_PRICES_OCCUPANCY_LINKED_VALUE) && Number(occupancyItem.default) !== 1 && Number(occupancyItem.discount) !== 0">
                        ({{ Number(occupancyItem.discount_type) === PERC ? (Number(occupancyItem.discount) > 0 ? '+' + occupancyItem.discount + '%' : occupancyItem.discount + '%') : (Number(occupancyItem.discount) > 0 ? '+' + occupancyItem.discount : occupancyItem.discount) }})
                    </span>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    import {PERC} from "@/shared/constants";
    import {
        C_PROPERTY_CALENDAR_PRICES_LOS_LINKED_VALUE,
        C_PROPERTY_CALENDAR_PRICES_OCCUPANCY_LINKED_VALUE
    } from "@/shared/component_permission";

    export default {
        name: "TableRatesLosAndOccupancySidebar",
        props: {
            los: {
                type: Array
            },
            occupancy: {
                type: Array
            }
        },
        data() {
            return {
                PERC,
                C_PROPERTY_CALENDAR_PRICES_LOS_LINKED_VALUE,
                C_PROPERTY_CALENDAR_PRICES_OCCUPANCY_LINKED_VALUE,
            }
        }

    }
</script>

<style scoped>

</style>
