// PARTNER
import http from "@/services/http";
import {FINANCE_PARTNER_URL} from "@/services/endpoints";

export function getPartnerTypeList() {
    return http.request({
        url: FINANCE_PARTNER_URL + '/fetch-partner-type-list',
        method: 'GET'
    })
}

export function getInvoiceeTypeList() {
    return http.request({
        url: FINANCE_PARTNER_URL + '/fetch-invoicee-type-list',
        method: 'GET'
    })
}

export function getPartnerList(params) {
    return http.request({
        url: FINANCE_PARTNER_URL + '/fetch-partner-list',
        method: 'GET',
        params
    })
}

export function fetchPartner(id) {
    return http.request({
        url: FINANCE_PARTNER_URL + '/' + id,
        method: 'GET'
    })
}

export function createPartner(data) {
    return http.request({
        url: FINANCE_PARTNER_URL,
        method: 'POST',
        data
    })
}

export function updatePartner(id, data) {
    return http.request({
        url: FINANCE_PARTNER_URL + '/' + id,
        method: 'PUT',
        data
    })
}
export function duplicatePartners(data) {
    return http.request({
        url: FINANCE_PARTNER_URL + '/duplicate',
        method: 'POST',
        data
    })
}
