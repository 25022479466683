<template>
        <b-row>
            <b-col cols="12" class="col-xxl-6" :key="index" v-for="(distribution, index) in distributionList">
                <distribution-item
                    :distribution_statistic="getDistributionStatisticById(distribution.id)"
                    @refreshData="setData(property_id)"
                    @AccessControlEvent="addToAccessControlCounter()"
                    :distribution="distribution"
                    :property_id="property_id">
                </distribution-item>
            </b-col>
        </b-row>
</template>

<script>
    import routeNames from '@/router/routeNames'
    import EditDistribution from '@/components/app/AppButton/AppButton'
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppButton from "@/components/app/AppButton/AppButton"
    import {
        getPropertyActivationStatusList,
        getDashboardUnitList
    } from "@/services/channel_manager";
    import {getDistributionList} from "@/services/distribution";

    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {AC_CHANNEL_MANAGER} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {
        C_PROPERTY_CM_PM_PROPERTY_DEACTIVATE_V,
        C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_V,
        C_PROPERTY_CM_SYNC_LOG_V,
        C_PROPERTY_CM_PM_PROPERTY_V

    } from "@/shared/component_permission";
    import loading from 'vue-full-loading'
    import DistributionItem from '@/components/channel_manager/distribution/DistributionItem'


    export default {
        name: "distribution",
        data() {
            return {
                routeNames,
                AC_CHANNEL_MANAGER,
                C_PROPERTY_CM_PM_PROPERTY_DEACTIVATE_V,
                C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_V,
                C_PROPERTY_CM_SYNC_LOG_V,
                C_PROPERTY_CM_PM_PROPERTY_V,

                fields: [
                    {key: 'id', label: 'Id'},
                    {key: 'name', label: this.$i18n.t('NAME')},
                    {key: 'edit', label: ''},
                ],
                statusConnectButton: [],
                activationStatusList: [],
                distributionList: [],
                property_id: null,
                access_control_fetch_key: AC_CHANNEL_MANAGER,
                unit_status_distribution_list: []

            }
        },
        mixins: [getErrorMessage, AccessControlComponent],
        components: {
            AppButtonSubmit,
            EditDistribution,
            AppButton,
            loading,
            DistributionItem

        },
        computed: {

            getCompanyId() {
                return this.$store.getters['user/getCompany']
            },

        },
        methods: {
            getDistributionStatisticById(id){
                let data = []
                if (this.unit_status_distribution_list.length > 0) {
                    this.unit_status_distribution_list.forEach(el => {
                        if(Number(el.distribution) === Number(id)){
                            data = {
                                active:el.active,
                                inactive:el.inactive
                            }

                        }
                    })
                }
                return data
            },
            getActivationStatusList(property_id, distributions) {

                let distributionIds = []
                this.distributionList = []

                for (let object of distributions) {
                    distributionIds.push(object.id)
                }
                let postData = {
                    property: property_id,
                    distribution: distributionIds
                }

                getPropertyActivationStatusList(postData).then(response => {
                    for (let distribution of distributions) {
                        if (response.data.length > 0) {
                            for (let status of response.data) {
                                if (status.distribution === distribution.id) {
                                    distribution.active = status.active
                                }
                            }
                        }
                        this.distributionList.push(distribution)
                    }

                }, () => {
                    this.distributionList = []
                })
            },

            setData(property_id) {
                getDistributionList({company: Number(this.getCompanyId)}).then(response => {
                        this.access_control_components = response.data.length
                        if (response.data.length > 0) {
                            let distribution_ids = []
                            for (let distribution of response.data) {
                                distribution_ids.push(distribution.id)
                            }
                            this.getActivationStatusList(property_id, response.data)
                            this.getUnitStatusStatistics(property_id, distribution_ids)
                        }
                    }
                )
            },
            getUnitStatusStatistics(property_id, distribution_list) {
                getDashboardUnitList({property: property_id, distribution: distribution_list}).then(response => {
                    this.unit_status_distribution_list = response.data
                }, () => {
                    this.unit_status_distribution_list = []
                })
            },

        },

        watch: {
            '$route.params.id': {
         async  handler(property_id) {
                    this.property_id = Number(property_id)
              await  this.setData(property_id)
                },
                immediate: true,
            }
        },

    }
</script>

<style scoped>

</style>
