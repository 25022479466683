<template>
    <div>
        <app-table-v2 :provider="getData" :fields="fields"
                      :show-limit="false"
                      :search-on-create="true"
                      @busyToggle="busyToggle"
                      refresh-event="refreshWebsiteTable"
                    >
            <template v-slot:slug="data">
                <b-link :href="'https://'+data.item.slug"
                        target="_blank"
                >{{data.item.slug}}</b-link>
            </template>
            <template v-slot:marketplace="data">
                <b-checkbox
                    v-model="data.item.marketplace"
                    switch
                    @change="editMarketplace(data.item, $event)">
                </b-checkbox>
            </template>
            <template v-slot:logo="data">
                <b-img v-if="data.item.logo" :src="data.item.logo"
                       height="15px" style="max-width: 140px"></b-img>
            </template>
            <template v-slot:edit="data">
                <app-button
                    variant="link"
                    button_type="edit"
                    :show_text="false"
                    :to="{name: routeNames.RN_DIRECT_BOOKING_WEBSITE, params:{id: data.item.id}}">
                </app-button>
            </template>
            <template v-slot:tableNoData="props">
                <app-no-data :show-tip="false">
                    <app-button button_type="new" @click="$emit('openNewWebsite')"
                                v-if="checkPermission(C_WEBSITE_NEW)">
                        {{ $t('WEBSITE_NEW') }}
                    </app-button>
                </app-no-data>
            </template>
        </app-table-v2>
        <app-confirmation-dialog
            :show="confirmation_dialog_state"
            @confirm="continueAction"
            @cancel="cancelAction"
            :title="$t('MARKETPLACE')">
            {{ $t('MARKETPLACE_DEACTIVATE') }}
        </app-confirmation-dialog>
        <app-aside v-model="activationWizard" :widths="['col-sm-12', '50%']">
            <template slot="header"><app-object-header :label="websiteSlug" :name="$t('MARKETPLACE_ACTIVATION')"></app-object-header></template>
            <template v-slot="props">
                <marketplace-activation-wizard :website-id="marketplaceWebsiteId"></marketplace-activation-wizard>
            </template>
        </app-aside>
    </div>
</template>

<script>
import AppTableV2 from "@/components/app/AppTableV2";
import {getWebsiteList} from "@/services/direct_booking/website";
import routeNames from "@/router/routeNames";
import AppButton from "@/components/app/AppButton/AppButton";
import {C_WEBSITE_MARKETPLACE_ACTIVATE, C_WEBSITE_NEW} from '@/shared/component_permission';
import {AC_WEBSITE} from "@/mixins/AccessControl/AccessControlEnumeration";
import AppNoData from "@/components/app/AppNoData";
import {createMarketplace, deleteMarketplace} from "@/services/marketplace";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {EventBus} from "@/shared/EventBus";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {fetchAccessControlData} from "@/services/access";
import AppAside from "@/components/app/form/AppAside";
import MarketplaceActivationWizard from "@/components/direct_booking/marketplace/MarketplaceActivationWizard";
import AppObjectHeader from "@/components/app/AppObjectHeader";

export default {
    name: "WebsiteTable",
    components: {AppNoData, AppButton, AppTableV2, AppConfirmationDialog, AppAside, MarketplaceActivationWizard, AppObjectHeader},
    mixins:[getErrorMessage],
    props: {
        company: {
            type: Number
        }
    },
    data() {
        return {
            C_WEBSITE_NEW,
            filter: {},
            routeNames,
            busyToggle: false,
            confirmation_dialog_state: false,
            deleteId: [],
            activationWizard: false,
            websiteSlug: null,
            marketplaceWebsiteId: null,
            fields: [
                {key: 'id', label: this.$t('ID'),},
                {key: 'website', label: this.$t('NAME')},
                {key: 'slug', label: this.$t('SLUG')},
                {key: 'distribution.name', label: this.$t('DISTRIBUTION')},
                {key: 'channel.name', label: this.$t('SALES_CHANNEL')},
                {key: 'template.name', label: this.$t('TEMPLATE')},
                {key: 'marketplace', label: this.$t('MARKETPLACE')},
                {key: 'logo', label: ''},
                {key: 'edit', label: '', class: 'text-right'},
            ],
        }
    },
    methods: {
        getData(filter) {
            let provider
            let req = {
                perPage: 999,
                order_by: filter.sortBy,
                company: this.company
            }

            provider = getWebsiteList(req)
            provider.then(response => {
                if (response.data.items && response.data.items.length > 0) this.$emit('showButton', true)
                else this.$emit('showButton', false)
            })
            return provider
        },
        editMarketplace(item, event){
            this.$nextTick(() => {
                if (event){
                    this.marketplaceWebsiteId = null
                    this.marketplaceWebsiteId = item.id
                    this.websiteSlug = null
                    this.websiteSlug = item.slug
                    this.activationWizard = true
                }else {
                    this.deleteId = []
                    this.deleteId.push(item.id)
                    this.confirmation_dialog_state = true
                }
            })
        },
        continueAction(){
            this.busyToggle = false
            deleteMarketplace({website_id: this.deleteId}).then(response => {
                notifySuccess()
                this.busyToggle = false
                this.confirmation_dialog_state = false
                EventBus.$emit('refreshWebsiteTable')
            }, error => {
                this.busyToggle = false
                this.confirmation_dialog_state = false
                this.showErrorMessages(error)
            })
        },
        cancelAction(){
            this.confirmation_dialog_state = false
            EventBus.$emit('refreshWebsiteTable')
        }
    },
    mounted(){
        let request = {
            context:{
                company: this.$store.getters['user/getCurrentCompany'].id
            },
            data:[]
        }
        request.data.push({
            uid: 'marketplace',
            function: C_WEBSITE_MARKETPLACE_ACTIVATE
        })
        fetchAccessControlData(AC_WEBSITE, request).then(response => {
            if (response.data.marketplace.visible === false){
                this.fields.forEach((el, index) => {
                    if (el.key === 'marketplace'){
                        this.fields.splice(index, 1)
                    }
                })
            }
        })
    },
    watch:{
        activationWizard:{
            handler(){
                if (this.activationWizard === false){
                    EventBus.$emit('refreshWebsiteTable')
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
