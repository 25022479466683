<template>
    <b-card v-cloak class="property-wrapper" v-if="company_id">
        <template slot="header">
            <h2 class="property-wrapper-padding">{{$t('SETTINGS')}}</h2>
            <app-button v-if="routeIsCancellationPolicies && hasPermission(C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_NEW)"
                       button_type="new"
                       variant="primary"
                       @click="openNewPolicy"
                       class="pull-right">{{$t('NEW_POLICY')}}
            </app-button>
            <app-button v-if="routeIsAppearance && hasPermission(C_APPEARANCE_NEW_COLOR_V)"
                        class="pull-right mt-4"
                        button_type="new"
                        @click="openNewColorModal"
            >{{ $t('NEW_COLOR') }}</app-button>
            <app-button v-if="routeIsAppearanceCategory && hasPermission(C_APPEARANCE_NEW_CATEGORY_V)"
                        class="pull-right mt-4"
                        button_type="new"
                        @click="openNewCategoryModal"
            >{{$t('NEW_CATEGORY')}}</app-button>
            <app-button v-if="routeIsFonts && hasPermission(C_APPEARANCE_NEW_FONT_V)"
                        class="pull-right mt-4"
                        button_type="new"
                        @click="openNewFontModal"
            >{{$t('NEW_FONT')}}</app-button>
            <app-button v-if="routeIsTags && hasPermission(C_SETUP_TAG_E)"
                        class="pull-right mt-4"
                        button_type="new"
                        @click="openNewTag"
            >{{$t('NEW_TAG')}}</app-button>
            <Navigation :key="'fin_system_settings'+componentKey"  :access_control_context="accessControlContext"
                        :ac-fetch-key="AC_SYSTEM_SETTINGS_NAVIGATION" :nav-elements="navElements">
            </Navigation>

        </template>

        <router-view>

        </router-view>
    </b-card>
</template>

<script>
    import routeNames from "@/router/routeNames";
    import Navigation from "@/components/app/navigation/Navigation";
    import {
        AC_COMPANY_NAVIGATION,
        AC_SYSTEM_SETTINGS_NAVIGATION
    } from "@/mixins/AccessControl/AccessControlEnumeration";
    import {
        R_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICIES,
        R_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES,
        R_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS,
        R_SETUP_FINANCE_INVOICE_CREATION,
        R_SETUP_FINANCE_VAT,
        R_SETUP_FINANCE_ITEM_DESCRIPTION,
        R_SETUP_FINANCE_INVOICE_TYPES,
        R_SETUP_FINANCE_CE_FIXED,
        R_SETUP_FINANCE_PARTNERS,
        R_SETUP_REGISTER_SETUP,
        F_SETUP_GUEST_BACKOFFICE, R_SETUP_FEES_PLATFORM_FEE
    } from "@/shared/route_permission";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {EventBus, GE_CANCELLATION_POLICY_NEW, GE_SYSTEM_FINANCE_SETTINGS} from "@/shared/EventBus";
    import {F_REVENUE_MANAGER, F_DIRECT_BOOKING, F_COMPANY_WHITELABEL, F_COMPANY_WHITELABEL_GUEST_BACKOFFICE_APPEARANCE, F_COMPANY_WHITELABEL_BOOKER_TOOLS_APPEARANCE } from "@/shared/function_permission";
    import {C_APPEARANCE_NEW_COLOR_V, C_APPEARANCE_NEW_CATEGORY_V, C_APPEARANCE_NEW_FONT_V, C_SETUP_TAG_E, C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_NEW} from "@/shared/component_permission";

    export default {
        name: "Index",
        components: {AppButton, Navigation},
        data() {
            return {
                accessControlContext: {
                    key: 1
                },
                AC_SYSTEM_SETTINGS_NAVIGATION,
                componentKey:1,
                C_APPEARANCE_NEW_COLOR_V,
                C_APPEARANCE_NEW_CATEGORY_V,
                C_APPEARANCE_NEW_FONT_V,
                C_SETUP_TAG_E,
                C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_NEW
            }
        },
        computed:{
            company_id(){
                return this.$store.getters['user/getCompany']
            },
            show(){
                return this.$route.name === routeNames.RN_SETUP_DESTINATION_TAG_REGION || this.$route.name === routeNames.RN_SETUP_DESTINATION_TAG_CITY;

            },
            routeIsCancellationPolicies() {
                return this.$route.name === routeNames.RN_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICIES
            },
            routeIsAppearance() {
                return this.$route.name === routeNames.RN_SYSTEM_APPEARANCE_COLORS
            },
            routeIsAppearanceCategory(){
                return this.$route.name === routeNames.RN_SYSTEM_APPEARANCE_COLOR_CATEGORY
            },
            routeIsFonts(){
                return this.$route.name === routeNames.RN_SYSTEM_APPEARANCE_FONTS
            },
            routeIsTags(){
                return this.$route.name === routeNames.RN_SETUP_CUSTOM_TAG
            },
            hasPermission() {
                return this.$store.getters['user/getPermission']
            },
            navElements(){
                return [
                    {route: {name: routeNames.RN_SETUP_GENERAL}},
                    {
                        label: this.$t('FINANCE'),
                        children: [
                            {
                                route: {name: routeNames.RN_SETUP_FINANCE_INVOICE_CREATION},
                                access_control_context: {company: this.company_id, function:R_SETUP_FINANCE_INVOICE_CREATION,  key: AC_SYSTEM_SETTINGS_NAVIGATION}
                            },
                            {
                                route: {name: routeNames.RN_SETUP_FINANCE_VAT},
                                access_control_context: {company: this.company_id, function:R_SETUP_FINANCE_VAT,  key: AC_SYSTEM_SETTINGS_NAVIGATION}
                            },
                            {
                                route: {name: routeNames.RN_SETUP_FINANCE_ITEM_DESCRIPTION},
                                access_control_context: {company: this.company_id, function:R_SETUP_FINANCE_ITEM_DESCRIPTION,  key: AC_SYSTEM_SETTINGS_NAVIGATION}
                            },
                            {
                                route: {name: routeNames.RN_SETUP_FINANCE_INVOICE_TYPES},
                                access_control_context: {company: this.company_id, function:R_SETUP_FINANCE_INVOICE_TYPES,  key: AC_SYSTEM_SETTINGS_NAVIGATION}
                            },
                            {
                                route: {name: routeNames.RN_SETUP_FINANCE_CE_FIXED},
                                access_control_context: {company: this.company_id, function:R_SETUP_FINANCE_CE_FIXED,  key: AC_SYSTEM_SETTINGS_NAVIGATION}
                            },
                            {
                                route: {name: routeNames.RN_SETUP_FINANCE_PARTNERS},
                                access_control_context: {company: this.company_id, function:R_SETUP_FINANCE_PARTNERS,  key: AC_SYSTEM_SETTINGS_NAVIGATION}
                            },
                        ]
                    },
                    {
                        label: this.$t("FEES"),
                        children: [
                            {
                                route: {name: routeNames.RN_SETUP_FEES_PLATFORM_FEE},
                                access_control_context: {company: this.company_id, function: R_SETUP_FEES_PLATFORM_FEE,  key: AC_SYSTEM_SETTINGS_NAVIGATION},
                            },
                        ],
                    },
                    {

                        route: {name: routeNames.RN_SETUP_REGISTER_SETUP},
                        access_control_context: {company: this.company_id, function:R_SETUP_REGISTER_SETUP,  key: AC_SYSTEM_SETTINGS_NAVIGATION}
                    },
                    {
                        label: this.$t('SYSTEM_TEMPLATES'),
                        children: [
                            {
                                route: {name: routeNames.RN_SETUP_SYSTEM_EVENTS_PRESET},
                                access_control_context: {company: this.company_id, function:R_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES,  key: AC_SYSTEM_SETTINGS_NAVIGATION}
                            },
                            {
                                route: {name: routeNames.RN_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICIES},
                                access_control_context: {company: this.company_id, function:R_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICIES,  key: AC_SYSTEM_SETTINGS_NAVIGATION}
                            },
                            {
                                route: {name: routeNames.RN_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS},
                            },

                        ]
                    },
                    {
                        label: this.$t('WHITELABEL'),
                        children: [
                            {
                                route: {name: routeNames.RN_SYSTEM_WHITELABEL_GENERAL},
                                access_control_context: {company: this.company_id, function:F_COMPANY_WHITELABEL,  key: AC_SYSTEM_SETTINGS_NAVIGATION}
                            },
                            {
                                route: {name: routeNames.RN_SYSTEM_WHITELABEL_BOOKER_TOOLS_APPEARANCE},
                                access_control_context: {company: this.company_id, function:F_COMPANY_WHITELABEL_BOOKER_TOOLS_APPEARANCE,  key: AC_SYSTEM_SETTINGS_NAVIGATION}
                            },
                            {
                                route: {name: routeNames.RN_SYSTEM_WHITELABEL_GUEST_BACKOFFICE_APPEARANCE},
                                access_control_context: {company: this.company_id, function:F_COMPANY_WHITELABEL_GUEST_BACKOFFICE_APPEARANCE,  key: AC_SYSTEM_SETTINGS_NAVIGATION}
                            },
                        ]
                    },
                    {
                        label: this.$t('APPEARANCE'),
                        children: [
                            {
                                route: {name: routeNames.RN_SYSTEM_APPEARANCE_COLORS},
                            },
                            {
                                route: {name: routeNames.RN_SYSTEM_APPEARANCE_COLOR_CATEGORY}
                            },
                            {
                                route: {name: routeNames.RN_SYSTEM_APPEARANCE_FONTS}
                            },
                        ]
                    },
                    {
                        route: {name: routeNames.RN_SETUP_CUSTOM_TAG},
                    },
                    {
                        label: this.$t('RESERVATION'),
                        children: [
                            {
                                route: {name: routeNames.RN_SYSTEM_RESERVATION_PROCESS},
                            },
                            {
                                route: {name: routeNames.RN_SYSTEM_RESERVATION_OWNER_STATEMENT},
                            },
                            {
                                route: {name: routeNames.RN_SYSTEM_RESERVATION_CURRENCY},
                            },
                            {
                                route: {name: routeNames.RN_SYSTEM_RESERVATION_PROCESS_NOTE_SETUP},
                            },
                            {
                                route: {name: routeNames.RN_SYSTEM_RESERVATION_PROCESS_DATA_TRANSITION},
                            }
                        ]
                    },
                    {
                        label: this.$t('AUTOMATIC_COMMUNICATION'),
                        children: [
                            {
                                route: {name: routeNames.RN_SETUP_EVENTS_SYSTEM},
                            },
                            {
                                route: {name: routeNames.RN_SETUP_SYSTEM_EVENTS_PROPERTY_OVERVIEW}
                            },
                        ]
                    },
                    {
                        route: {name: routeNames.RN_SYSTEM_GUEST_BACKOFFICE},
                        access_control_context: {company: this.company_id, function:F_SETUP_GUEST_BACKOFFICE,  key: AC_SYSTEM_SETTINGS_NAVIGATION}
                    }
                ]
            }
        },
        methods:{
            openNewPolicy(){
                EventBus.$emit(GE_CANCELLATION_POLICY_NEW)
            },
            openNewColorModal(){
                EventBus.$emit('AddNewColor')
            },
            openNewCategoryModal(){
                EventBus.$emit('AddNewCategory')
            },
            openNewFontModal(){
                EventBus.$emit('AddNewFont')
            },
            openNewTag(){
                EventBus.$emit('AddNewCustomTag')
            }
        },
        mounted(){
            EventBus.$on(GE_SYSTEM_FINANCE_SETTINGS, ()=>{
                this.componentKey++
            })
        },
        beforeLeave(){
            EventBus.$off(GE_SYSTEM_FINANCE_SETTINGS)
        }
    }
</script>

<style scoped>

</style>
