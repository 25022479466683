<template>
    <b-form @submit.prevent="save" v-if="cancellationPolicy">
        <b-row>
            <b-col md="6">
                <b-form-group>
                    <label>{{$t('CANCELLATION_POLICY')}}</label>
                    <b-form-input :disabled="true" :value="cancellationPolicy.label ? cancellationPolicy.label : ''"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="6">
                <b-form-group>
                    <label>{{$t('SYSTEM_CANCELLATION_POLICY')}}</label>
                    <app-select mode="search"
                                :search="getPossibleSystemPolicyList"
                                :search-on-create="true"
                                text-field="label"
                                :search-empty-item="false"
                                v-model="systemPolicy">
                    </app-select>
                </b-form-group>
            </b-col>
        </b-row>
        <template v-if="systemPolicy">
            <cancellation-policy-text class="mt-3 mb-3"
                                      :loading="textLoading"
                                      :policy-text="policyText"
                                      :policy-errors="policyErrors"
                                      :custom-policy-text="customPolicyText"
                                      :edit-disabled="true">
            </cancellation-policy-text>
            <b-row class="mt-3>">
                <b-col>
                    <b-form-group>
                        <b-form-checkbox v-model="applyName"
                                         switch
                                         :value="1"
                                         :unchecked-value="0">
                            {{$t('APPLY_SYSTEM_POLICY_NAME')}}
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-row>
        </template>
        <b-row class="mt-3">
            <b-col>
                <app-button-submit :disabled="!systemPolicy">
                    {{$t('SAVE')}}
                </app-button-submit>
            </b-col>
        </b-row>
        <app-confirmation-dialog :show="showLinkSystemPolicyConfirmation"
                                 @confirm="linkPolicy"
                                 @cancel="showLinkSystemPolicyConfirmation = false"
                                 :title="$t('LINK_CONFIRMATION_TITLE')">
            {{$t('LINK_SYSTEM_POLICY_CONFIRMATION_MSG')}}
        </app-confirmation-dialog>
    </b-form>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import CancellationPolicyText from "@/components/property/cancelation_policies/policy/CancellationPolicyText";
import {
    fetchCancellationPolicy,
    fetchCancellationPolicyText,
    getPossibleSystemPolicies,
    linkSystemPolicy
} from "@/services/cancellation_policy";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
export default {
    name: "CancellationPolicyLinkSystemPolicy",
    components: {AppConfirmationDialog, CancellationPolicyText, AppButtonSubmit, AppSelect},
    mixins: [getErrorMessage],
    props: {
        policyId: {
            type: Number
        },
        company: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            systemPolicy: null,
            applyName: null,
            showLinkSystemPolicyConfirmation: false,
            textLoading: false,
            policyText: '',
            policyErrors: [],
            customPolicyText: null,
            cancellationPolicy: null,
        }
    },
    watch: {
        systemPolicy: {
            handler(value) {
                if (value && value.id) {
                    this.applyName = 1
                    this.textLoading = true
                    this.customPolicyText = value.policy_text
                    if (value.policy_text) {
                        this.$nextTick(() => {
                            this.textLoading = false
                        })
                    } else {
                        fetchCancellationPolicyText({policy_id: value.id, validate_conditions: 1}).then(response => {
                            this.policyText = response.data
                            this.policyErrors = []
                        }, error => {
                            this.policyText = ''
                            if (!error.response || !error.response.data) {
                                this.policyErrors = []
                            }
                            this.policyErrors = error.response.data.error_list
                        }).finally(() => {
                            this.textLoading = false
                        })
                    }
                }
            },
            immediate: true,
        }
    },
    methods: {
        save() {
            this.showLinkSystemPolicyConfirmation = true
        },
        linkPolicy() {
            linkSystemPolicy(this.policyId, {system_policy: this.systemPolicy.id, apply_name: this.applyName}).then(() => {
                notifySuccess()
                this.$emit('linkPolicy')
            }, error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.showLinkSystemPolicyConfirmation = false
            })
        },
        getPossibleSystemPolicyList() {
            return getPossibleSystemPolicies({company: this.company})
        },
    },
    created() {
        fetchCancellationPolicy(this.policyId).then(response => {
            this.cancellationPolicy = response.data
        })
    }
}
</script>

<style scoped>

</style>
