<template>
    <tr>
        <td>
            <app-select
                :value="selectedLos.default"
                @input="emitChange('default', $event)"
                :options="yesNoOptions"
                :disabled="isDefault"
                :search-empty-item="false">
            </app-select>
        </td>
        <td>
            <app-number-input
                :min="1"
                :value="selectedLos.min_stay"
                @input="emitChange('min_stay', $event)">
            </app-number-input>
        </td>
        <td>
            <app-number-input
                :min="1"
                :value="selectedLos.max_stay"
                @input="emitChange('max_stay', $event)">
            </app-number-input>
        </td>
        <td>
            <app-number-input
                :allow-decimal="true"
                :value="selectedLos.discount"
                @input="emitChange('discount', $event)"
                v-show="!isDefault">
            </app-number-input>
        </td>
        <td>
            <app-select
                :value="selectedLos.discount_type"
                @input="emitChange('discount_type', $event)"
                :options="selectDiscountTypeOptions"
                value-field="value"
                text-field="text"
                v-show="previewRelationType"
                :search-empty-item="false">
            </app-select>
        </td>
        <td class="text-right">
            <app-button
                :v_show="!isDefault"
                :access_control_context="{function: C_UNIT_CALENDAR_LOS_E, key: AC_UNIT_LOS}"
                @AccessControlEvent="addToAccessControlCounter()"
                button_type="delete"
                :show_text="false"
                @click="deleteLos"
                variant="link">
            </app-button>
        </td>
    </tr>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {C_UNIT_CALENDAR_LOS_E} from "@/shared/component_permission";
import AppButton from "@/components/app/AppButton/AppButton";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import {AC_UNIT_LOS} from "@/mixins/AccessControl/AccessControlEnumeration";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import _cloneDeep from "lodash/cloneDeep";
import {listHelpers} from "@/mixins/shared/helpers";
import {FIX, PERC} from "@/shared/constants";

export default {
    name: "UnitLosListItem",
    components: {
        AppSelect,
        AppNumberInput,
        AppButton,
    },
    props: {
        los: {
            type: Object,
        },
    },
    mixins: [
        AccessControlComponent,
        listHelpers,
    ],
    data() {
        return {
            AC_UNIT_LOS,
            selectDiscountTypeOptions: [
                {value: PERC, text: "%"},
                {value: FIX, text: "fix"},
            ],
            selectedLos: {
                id: null,
                min_stay: null,
                max_stay: null,
                discount: null,
                discount_type: null,
                default: null,
                deleted: null
            },
            C_UNIT_CALENDAR_LOS_E,
            access_control_components: 1,
        }
    },
    computed: {
        isDefault() {
            return this.selectedLos.default === 1
        },
        previewRelationType() {
            return !this.isDefault && !Number.isNaN(this.selectedLos.discount) && Number(this.selectedLos.discount) === this.selectedLos.discount
        },
    },
    methods: {
        deleteLos() {
            this.$emit("delete", this.selectedLos)
        },
        emitChange(key, value) {
            if (this.selectedLos[key] === value) {
                return
            }
            let payload = {}
            this.$set(payload, key, value)
            this.$emit("update", payload)
        },
    },
    watch: {
        los: {
            handler(value) {
                if (value) {
                    this.selectedLos = _cloneDeep(value)
                }
            },
            immediate: true,
            deep: true,
        },
    },
}
</script>

<style scoped>

</style>
