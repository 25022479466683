<template>
    <b-form @submit.prevent="save">

        <b-row class="mb-2">
            <b-col lg="12" class="mb-4">
                <label>{{$t('GROUP')}}</label>
                <app-select mode="search" text-field="title" value-field="id" :search="searchFilter" v-model="filter">

                </app-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="6">
                <app-button-submit :disabled="!saveEnabled" :loading="loading"></app-button-submit>
            </b-col>
            <b-col lg="6">
                <app-button-delete @click="deleteConfirmationDialog = true" v-if="filterEdit" class="pull-right" ></app-button-delete>
            </b-col>
        </b-row>
        <app-confirmation-dialog :show="deleteConfirmationDialog" @confirm="deleteFilterOnUnit"  @cancel="deleteConfirmationDialog = false">

        </app-confirmation-dialog>
    </b-form>
</template>

<script>
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import FormHeader from "@/components/app/form/FormHeader";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {getRmFilterUnit, getRmFilterUnitById, rmFilterSetUnits} from "@/services/revenue_manager";
import {toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {EventBus} from "@/shared/EventBus";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";

export default {
    name: "RevenueManagerGroupAssignToFilter",
    components: {AppConfirmationDialog, AppSelect, AppButtonDelete, AppButtonSubmit, FormHeader},
    mixins: [getErrorMessage],
    props: {
        unit_ids: {
            type: Array,
            default() {
                return []
            }
        },
        filterEdit:{
            type:Object
        }
    },
    data() {
        return {
            loading: false,
            filter: null,
            unit_list:[],
            deleteConfirmationDialog:false,
            saveEnabled:false
        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    methods: {
        searchFilter(value){
            const req = {
                title:value,
                page: 1,
                perPage: 100,
                company: this.company.id
            }
            return getRmFilterUnit(req).then(response => ({data: response.data.items}))
        },
        save() {
            this.loading = true
            let units = this.unit_list.map(el=>{
                return el.id
            })
            this.unit_ids.forEach(id=>{
                units.push(id)
            })

            rmFilterSetUnits(this.filter.id,{units}).then(response =>{
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
                this.$emit('close')
                EventBus.$emit('gl_revenue_manager_similar_units')
            },error=>{
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
            }).finally(()=>{
                this.loading = false
            })
        },
        deleteFilterOnUnit(){
            const units = this.unit_list.filter(el=>!this.unit_ids.includes(el.id)).map(item=>{
                return item.id
            })
            rmFilterSetUnits(this.filter.id,{units}).then(response =>{
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
                this.$emit('close')
                EventBus.$emit('gl_revenue_manager_similar_units')
            },error=>{
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
            }).finally(()=>{
                this.loading = false
                this.deleteConfirmationDialog = false
            })

        },
        fetchById(id){
            this.unit_list = []
            getRmFilterUnitById(id).then(response=>{
                this.unit_list = response.data.unit_list
                this.saveEnabled = true
            })
        }
    },
    watch:{
        filter:{
            handler(object){
            this.saveEnabled = false
                if(object){
                    this.fetchById(object.id)
                }
            }
        },
        filterEdit:{
            handler(object){
                if(object){
                    this.filter = object
                }
            },immediate:true
        }
    }
}
</script>

<style scoped>

</style>
