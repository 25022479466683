<template>
    <transition :duration="800" name="fade">
        <small v-if="show" :class="'text-' + type">
            <slot>&nbsp;</slot>
        </small>
    </transition>
</template>
<script>
    export default {
        name: "AppValidationDescription",
        props: {
            'type': {
                type: String,
                default: 'danger'
            },
            'show': {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped>

</style>
