<template>
    <form v-if="colorObject">
        <b-row class="mb-4">
            <b-col lg="12">
                <app-translation-input :languages="supportedLanguages" v-model="colorObject.label"
                                       :label="$t('NAME')"
                                       :lang_id="colorObject.lang_id"></app-translation-input>
            </b-col>
        </b-row>
        <b-row class="mb-4">
            <b-col lg="12">
                <label>{{$t('COLOR')}}</label>
                <b-input v-model="colorObject.hex" type="color"></b-input>
            </b-col>
        </b-row>
        <b-row class="mb-4">
            <b-col lg="12">
                <label>{{$t('CATEGORY')}}</label>
                <app-select v-model="colorObject.category"
                            :options="categoryOptions"
                            mode="select"
                            return-type="object"
                            text-field="label"
                ></app-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button @click="save" :loading="loading">{{$t('SAVE')}}</app-button>
            </b-col>
<!--            <b-col class="d-flex flex-lg-row-reverse">-->
<!--                <app-button-delete :loading="deleteLoading" @click="deleteDialogState = true"></app-button-delete>-->
<!--            </b-col>-->
        </b-row>
        <app-confirmation-dialog
            :show="deleteDialogState"
            @confirm="deleteColor"
            @cancel="deleteDialogState=false"
            :delete_title="true"
        ></app-confirmation-dialog>
    </form>
</template>

<script>

import AppButton from "@/components/app/AppButton/AppButton";
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import AppTranslationInput from "@/components/app/translation/AppTranslationInput";
import {deleteThemeColor, fetchThemeColor, getAllThemeCategories, saveThemeColor} from "@/services/appearance";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {getSystemLanguageList, updateTranslations} from "@/services/system";

export default {
    name: "AppearanceEditColor",
    mixins:[getErrorMessage],
    components:{AppButton, AppSelect, AppButtonDelete, AppConfirmationDialog, AppTranslationInput},
    props:{
        color:{
            type: Object,
            default: null
        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    data(){
        return{
            categoryOptions: [],
            loading: false,
            deleteLoading: false,
            deleteDialogState: false,
            supportedLanguages: [],
            colorObject: null
        }
    },
    methods:{
        save(){
            this.loading = true
            updateTranslations(this.colorObject.lang_id, {"en": this.colorObject.label}).then(response => {
            }, error => {
                this.showErrorMessages(error)
            })
            saveThemeColor({name: this.colorObject.label, color: this.colorObject.hex, company_id: this.company.id, category: this.colorObject.category ? this.colorObject.category.id : null, id: this.colorObject.id}).then(response => {
                notifySuccess()
                this.loading = false
                this.$emit('saved-edit-color')
            }, error => {
                this.showErrorMessages(error)
                this.loading = false
            })
        },
        deleteColor(){
            this.deleteLoading = true
            deleteThemeColor({id: this.color.id}).then(response => {
                this.deleteLoading = false
                notifySuccess()
                this.$emit('saved-edit-color')
            }, error => {
                this.deleteLoading = false
                this.showErrorMessages(error)
            })
        }
    },
    created() {
        getAllThemeCategories({company_id: this.company.id}).then(response => {
            this.categoryOptions = response.data
        })
        getSystemLanguageList().then(response => {
            response.data.forEach(el => {this.supportedLanguages.push(el.name)})
        })
        fetchThemeColor(this.color.id).then(response => {
            this.colorObject = response.data
        })
    }
}
</script>

<style scoped>

</style>
