<template>
    <div v-show="financeVisible !== null" class="dashboard-wrapper">
        <b-row>
            <b-col class="col-xl-4" v-show="financeVisible || ownerVisible">
                <b-row>
                    <app-access-control :access_control_context="{function: C_RESERVATION_ACCOMMODATION_FINANCE,  key: access_control_fetch_key}"
                                        @AccessControlEvent="addToAccessControlCounter()"
                                        @updated="financeVisible=$event.visible">
                        <finance-module @AccessControlEvent="addToAccessControlCounter()"
                                        :reservation="reservation"
                                        @financialDetailsUpdated="$emit('update', $event)"
                                        @reloadSetup="$emit('reloadSetup')"
                                        @reloadScheduler="$emit('reloadScheduler')"
                                        @reloadReservation="$emit('reloadReservation')">
                        </finance-module>
                    </app-access-control>
                </b-row>

                <b-row>
                    <app-access-control :access_control_context="{function: C_RESERVATION_ACCOMMODATION_OWNER,  key: access_control_fetch_key}"
                                        @AccessControlEvent="addToAccessControlCounter()"
                                        @updated="ownerVisible=$event.visible">
                        <owner-module :reservation="reservation"></owner-module>
                    </app-access-control>
                </b-row>
            </b-col>

            <b-col :class="[`col-xl-${financeVisible ? '8': '12'}`]">
                <b-row v-if="checkPermission(C_RESERVATION_ACCOMMODATION_MODULES_INVOICES)">
                    <b-col class="col-xl-12">
                        <invoice-module :reservation="reservation">
                        </invoice-module>
                    </b-col>
                </b-row>

                <b-row v-if="checkPermission(C_RESERVATION_ACCOMMODATION_MODULES_CARD)">
                    <b-col>
                        <card-module :reservation="reservation"
                                     @update="$emit('update', $event)"
                                     @reloadScheduler="$emit('reloadScheduler')">
                        </card-module>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import FinanceModule from "@/components/reservation/accommodation/modules/FinanceModule";
    import CardModule from "@/components/reservation/accommodation/modules/TransactionModule";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {
        AC_ACCOMMODATION_RESERVATION_FINANCE,
    } from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {
        C_RESERVATION_ACCOMMODATION_FINANCE,
        C_RESERVATION_ACCOMMODATION_MODULES_CARD,
        C_RESERVATION_ACCOMMODATION_MODULES_INVOICES, C_RESERVATION_ACCOMMODATION_OWNER
    } from "@/shared/component_permission";
    import OwnerModule from "@/components/reservation/accommodation/modules/OwnerModule";

    export default {
        name: "Index",
        components: {
            OwnerModule,
            AppAccessControl,
            CardModule,
            InvoiceModule: () => import("@/components/reservation/accommodation/modules/InvoiceModule"),
            FinanceModule,
        },
        mixins: [AccessControlComponent],
        props: {
            reservation: {
                type: Object,
                default() {
                    return {}
                }
            },
        },
        data() {
            return {
                access_control_general_context: null,
                C_RESERVATION_ACCOMMODATION_FINANCE,
                access_control_components: 2,
                access_control_fetch_key: AC_ACCOMMODATION_RESERVATION_FINANCE,
                financeVisible: null,
                ownerVisible: null,
                C_RESERVATION_ACCOMMODATION_MODULES_CARD,
                C_RESERVATION_ACCOMMODATION_MODULES_INVOICES,
                C_RESERVATION_ACCOMMODATION_OWNER
            }
        },
        created() {
            this.$emit('created', true)
            this.access_control_general_context = {reservation: this.reservation.id}
        }
    }
</script>

<style scoped>


</style>
