<template>
    <div v-if="hasPermissionPromotionView">
        <b-row class="mb-4" v-if="promotionList.length">
            <b-col>
                <AppButton button_type="new" v-if="checkPermission(C_PROPERTY_ACTION_PROMOTION_LAST_MINUTE_NEW)"
                           class="pull-right ml-3" @click="newPromotion(2)">
                    {{$t('NEW_LAST_MINUTE')}}
                </AppButton>
                <AppButton  button_type="new" variant="primary" v-if="checkPermission(C_PROPERTY_ACTION_PROMOTION_EARLY_BOOKER_NEW)"
                           class="pull-right" @click="newPromotion(1)">{{$t('NEW_EARLY_BOOKER')}}
                </AppButton>
            </b-col>
        </b-row>
        <b-row >
            <b-col v-if="promotionList.length">
                <b-table  :fields="fields" responive hover :items="promotionList">
                    <template v-slot:discount="data">
                        {{data.item.discount}} {{data.item.discount_type === 1 ? "%" : "fix"}}
                    </template>
                    <template v-slot:min_stay_from_rate_plan="data">
                        {{data.item.min_stay_from_rate_plan ? ratePlan(data.item.min_stay_from_rate_plan) : '' }}
                    </template>
                    <template v-slot:edit="data">
                        <app-button :show_text="false" button_type="mapping" variant="link" @click="mappingRatePlan(data.item.id)"></app-button>
                        <promotion-edit :show_text="false" button_type="edit" variant="link"
                                        v-if="checkPermission(c_permission_e)"
                                        @click="editPromotion(data.index, data.item.type)"></promotion-edit>

                    </template>
                </b-table>
            </b-col>
            <b-col v-else-if="(!loading && promotionList.length === 0)" >
                <app-no-data   :show-tip="false">
                    <AppButton button_type="new" v-if="checkPermission(C_PROPERTY_ACTION_PROMOTION_LAST_MINUTE_NEW)"
                               class="pull-right ml-3" @click="newPromotion(2)">
                        {{$t('NEW_LAST_MINUTE')}}
                    </AppButton>
                    <AppButton  button_type="new" variant="primary" v-if="checkPermission(C_PROPERTY_ACTION_PROMOTION_EARLY_BOOKER_NEW)"
                                class="pull-right" @click="newPromotion(1)">{{$t('NEW_EARLY_BOOKER')}}
                    </AppButton>
                </app-no-data>
            </b-col>

        </b-row>

        <app-aside v-model="promotionAsidePanelState">
            <template slot="header">{{$t('PROMOTION')}}</template>
            <promotion-mapping-rate-plan-form v-if="showRatePlanMapping" :promotion_id="promotion_id" :permission="c_permission_e"></promotion-mapping-rate-plan-form>

            <promotion-early-booker-update-form @promotionSaved="promotionAsidePanelState=false"
                                                v-if="showEarlyBookerForm && !showLastMinuteForm"
                                                :promotionItem="promotionEditing"
                                                :ratePlanList="ratePlanList">
            </promotion-early-booker-update-form>

            <promotion-early-booker-new-form @promotionSaved="promotionAsidePanelState=false"
                                             v-if="showEarlyBookerNewForm && !showLastMinuteNewForm"
                                             :ratePlanList="ratePlanList">

            </promotion-early-booker-new-form>
            <promotion-last-minute-update-form @promotionSaved="promotionAsidePanelState=false"
                                               v-if="showLastMinuteForm && !showEarlyBookerForm"
                                               :promotionItem="promotionEditing"
                                               :ratePlanList="ratePlanList">
            </promotion-last-minute-update-form>
            <promotion-last-minute-new-form @promotionSaved="promotionAsidePanelState=false"
                                            v-if="showLastMinuteNewForm && !showEarlyBookerNewForm "
            ></promotion-last-minute-new-form>
        </app-aside>

    </div>
</template>

<script>
    import PromotionEdit from '@/components/app/AppButton/AppButton'
    import DeletePromotion from '@/components/app/AppButton/AppButtonDelete'
    import DeletePromotionDialog from '@/components/app/form/AppConfirmationDialog'
    import {PROMOTION_EARLY_BOOK, PROMOTION_LAST_MINUTE} from "@/shared/constants"
    import AppAside from '@/components/app/form/AppAside'
    import PromotionEarlyBookerUpdateForm from '@/components/property/promotions/PromotionEarlyBookerUpdateForm'
    import PromotionEarlyBookerNewForm from '@/components/property/promotions/PromotionEarlyBookerNewForm'
    import PromotionLastMinuteUpdateForm from '@/components/property/promotions/PromotionLastMinuteUpdateForm'
    import PromotionLastMinuteNewForm from '@/components/property/promotions/PromotionLastMinutNewForm'
    import _findKey from 'lodash/findKey'
    import {
        C_PROPERTY_ACTION_PROMOTION_E, C_PROPERTY_ACTION_PROMOTION_EARLY_BOOKER_NEW,
        C_PROPERTY_ACTION_PROMOTION_LAST_MINUTE_NEW,
        C_PROPERTY_ACTION_PROMOTION_V
    } from "@/shared/component_permission";
    import AppButton from '@/components/app/AppButton/AppButton'
    import PromotionMappingRatePlanForm from '@/components/property/promotions/PromotionRatePlanMappingForm'
    import AppNoData from "@/components/app/AppNoData";

    export default {
        name: "index",
        data() {
            return {
                fields: [
                    {key: 'id', label: 'Id'},
                    {
                        key: 'name', label: this.$t('NAME'), thStyle: {
                            font: 12
                        }
                    },
                    {key: 'discount', label: this.$t('DISCOUNT'), class: 'header-promotion'},
                    {key: 'book_from', label: this.$t('BOOK_FROM'), class: 'header-promotion'},
                    {key: 'book_to', label: this.$t('BOOK_TO')},
                    {key: 'check_in_from', label: this.$t('CHECK_IN_FROM')},
                    {key: 'check_in_to', label: this.$t('CHECK_IN_TO')},
                    {key: 'min_days_advance', label: this.$t('MIN_DAYS_BEFORE_CHECK_IN')},
                    {key: 'max_days_advance', label: this.$t('MAX_DAYS_BEFORE_CHECK_IN')},
                    {key: 'min_stay', label: this.$t('MIN_STAY')},
                    {key: 'min_stay_from_rate_plan', label: this.$t('MIN_STAY_FROM_RATE_PLAN')},
                    {key: 'edit', label: '',tdClass: 'text-right'},
                ],
                showDeleteDialog: false,
                promotionAsidePanelState: false,
                promotionEditing: false,
                showEarlyBookerForm: false,
                showLastMinuteForm: false,
                showEarlyBookerNewForm: false,
                showLastMinuteNewForm: false,
                showRatePlanMapping:false,
                c_permission_e: C_PROPERTY_ACTION_PROMOTION_E,
                promotion_id:null,
                loading:false,
                C_PROPERTY_ACTION_PROMOTION_LAST_MINUTE_NEW,
                C_PROPERTY_ACTION_PROMOTION_EARLY_BOOKER_NEW
            }
        },
        components: {
            AppNoData,
            PromotionEarlyBookerUpdateForm,
            PromotionEdit,
            DeletePromotion,
            DeletePromotionDialog,
            AppAside,
            PromotionLastMinuteUpdateForm,
            PromotionEarlyBookerNewForm,
            PromotionLastMinuteNewForm,
            AppButton,
            PromotionMappingRatePlanForm
        },
        computed: {
            promotionList() {
                return this.$store.getters.getItem('property.promotion.promotion_list')
            },
            ratePlanList() {

                let data = []
                let list = this.$store.getters.getItem('property.promotion.rate_plan_list')
                if (list.length === 0) {
                    return
                }
                list.forEach(function (element) {
                    data.push({id: element.id, name: element.unit_name + ': ' + element.rate_plan_name})
                })
                return data
            },
            hasEditPermission() {
                return this.$store.getters['user/getPermission'](this.c_permission_e)
            },
            hasPermissionPromotionView() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_ACTION_PROMOTION_V)
            }
        },
        methods: {
            mappingRatePlan(id){
                this.promotion_id = id
                this.promotionAsidePanelState = true
                this.showRatePlanMapping = true
                this.showEarlyBookerForm = false
                this.showLastMinuteForm = false
                this.showEarlyBookerNewForm = false
                this.showLastMinuteNewForm = false
            },
            editPromotion(index, type) {

                this.promotionAsidePanelState = true
                this.promotionEditing = this.promotionList[index]

                if (type === PROMOTION_EARLY_BOOK) {
                    this.showEarlyBookerForm = true
                    this.showLastMinuteForm = false
                    this.showEarlyBookerNewForm = false
                    this.showLastMinuteNewForm = false
                } else if (type === PROMOTION_LAST_MINUTE) {
                    this.showLastMinuteForm = true
                    this.showEarlyBookerForm = false
                    this.showEarlyBookerNewForm = false
                    this.showLastMinuteNewForm = false
                }
            },
            newPromotion(type) {
                this.promotionAsidePanelState = true
                if (type === PROMOTION_EARLY_BOOK) {
                    this.showEarlyBookerNewForm = true
                    this.showLastMinuteNewForm = false
                    this.showEarlyBookerForm = false
                    this.showLastMinuteForm = false

                } else if (type === PROMOTION_LAST_MINUTE) {
                    this.showLastMinuteNewForm = true
                    this.showEarlyBookerNewForm = false
                    this.showEarlyBookerForm = false
                    this.showLastMinuteForm = false
                }
            },
            ratePlan(rate_plan_id) {
                let ratePlanList = this.$store.getters.getItem('property.promotion.rate_plan_list')
                let newElementKey = _findKey(ratePlanList, (el) => {
                    return el.id === rate_plan_id
                })
                if (typeof newElementKey === "undefined") {
                    return null
                }
                return ratePlanList[newElementKey].unit_name + ': ' + ratePlanList[newElementKey].rate_plan_name
            },
        },

        created() {
            this.loading = true
            this.$store.dispatch('property/actionSetPropertyPromotionList', this.$route.params.id).finally(()=>{
                this.loading = false
            })
        }
    }
</script>

<style>


</style>
