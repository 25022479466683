<template>
    <div>
        <label>{{restriction.label}}</label>
        <app-select :has-message="has_tooltip_message" :message="message" :disabled="disabled"   :value="value" @input="emitValue" :options="allowed_values"></app-select>
    </div>

</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";

export default {
name: "SelectRestrictionFormElementNew",
    components: {AppSelect},
    props: {
        allowed_values: {
            type: Array,
            default(){
                return []
            }
        },
        value: {
            type: String | Number,
            default: null
        },
        restriction: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        },
        color_border: {
            type: Boolean,
            default: false
        },
        has_tooltip_message:{
            type:Boolean,
            default:false
        },
        message:{
            type:String,
        }
    },


    methods: {
        emitValue(val) {
            this.$emit("input", val)
        }
    }
}
</script>

<style scoped>

</style>
