<template>
    <div>
        <label>{{ restriction.label }}</label>
        <app-select
            mode="multiselect"
            :has-message="has_tooltip_message"
            :message="message"
            :disabled="disabled"
            :value="value_"
            @input="onMultiselectInput"
            :options="options">
            <template v-slot:text
                      v-if="value_.length > 1 && value_.length < 7">
                <span>{{ selectedDaysPreviewText }}</span>
            </template>
        </app-select>
    </div>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";

export default {
    name: "MultiselectRestrictionFormElement",
    components: {AppSelect},
    props: {
        allowed_values: {
            type: Object,
        },
        value: {
            type: String,
            default: "",
        },
        restriction: {
            type: Object,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        has_tooltip_message: {
            type: Boolean,
            default: false,
        },
        message: {
            type: String,
        },
    },
    data() {
        return {}
    },
    computed: {
        value_() {
            if (this.value === null || this.value === "") {
                return []
            }
            let value = this.value.split("")
            if (value.length === 0) {
                return ["1", "2", "3", "4", "5", "6", "7"]
            }
            return value.filter(day => Number(day) > 0)
        },
        options() {
            let options = []
            for (const key in this.allowed_values) {
                options.push({name: this.getWeekday(key), id: key})
            }
            return options
        },
        selectedDaysPreviewText() {
            let text = ""
            this.options.forEach(weekdayOption => {
                if (this.value_.indexOf(weekdayOption.id) > -1) {
                    text += text.length > 0 ? (", " + weekdayOption.name) : weekdayOption.name
                }
            })
            return text
        }
    },
    methods: {
        onMultiselectInput(event) {
            if (
                ((this.value === null || this.value === '') && event.length === 0) ||
                this.value === event.join("")
            ) {
                return
            }
            this.$emit("input", event.length === 0 ? "0" : event.join(""))
        },
        getWeekday(number) {
            switch (Number(number)) {
                case 1:
                    return this.$t('DATE.DAYS.MONDAY')
                case 2:
                    return this.$t('DATE.DAYS.TUESDAY')
                case 3:
                    return this.$t('DATE.DAYS.WEDNESDAY')
                case 4:
                    return this.$t('DATE.DAYS.THURSDAY')
                case 5:
                    return this.$t('DATE.DAYS.FRIDAY')
                case 6:
                    return this.$t('DATE.DAYS.SATURDAY')
                case 7:
                    return this.$t('DATE.DAYS.SUNDAY')
            }
        },
    },
}
</script>

<style scoped>

</style>
