<template>
    <div class="app">
        <AppHeader fixed>
            <SidebarToggler class="d-lg-none" display="md" mobile/>
            <b-link class="navbar-brand" to="/">
                <img class="navbar-brand-full" :src="logo" width="140" height="15"
                     >
                <img class="navbar-brand-minimized" :src="logo_alt" width="30" height="30"
                     alt="Booker Tools Logo">
            </b-link>
            <SidebarToggler class="d-md-down-none" display="lg"/>
            <div class="d-none d-lg-block col-md-6 ">
                <app-search v-if="checkPermission(C_SEARCH_V) || true"></app-search>
                <!--                <div class="input-group site_search_wrapper">-->
                <!--                    <span class="input-group-prepend">-->
                <!--                    <button class="btn" type="button">-->
                <!--                    <i class="icon-magnifier icons"></i> </button>-->
                <!--                    </span>-->
                <!--                    <input id="input-search" size="sm" placeholder="Search" class="site_search">-->
                <!--                </div>-->
            </div>
            <!-- Control items in top bar -->
            <!-- <div class="col-md-3">
                <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="false">Home</a>
                </li>
                <li class="nav-item">
                <a class="nav-link active show" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="true">Profile</a>
                </li>
                <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#messages" role="tab" aria-controls="messages" aria-selected="false">Messages</a>
                </li>
                </ul>
            </div> -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item class="">
                    <DefaultHeaderDropdown/>
                </b-nav-item>
            </b-navbar-nav>
            <!--<AsideToggler class="d-none d-lg-block"/>-->
        </AppHeader>
        <div class="app-body">
            <AppSidebar fixed>
                <SidebarHeader/>
                <SidebarForm/>
                <SidebarNav :navItems="navigation_list"></SidebarNav>
                <b-button @click="gotToHelpLink" class="btn-logout"><i class="fa fa-question-circle-o" aria-hidden="true"></i>{{$t("HELP")}}</b-button>
                <b-button @click="logout" class="btn-logout"><i class="fa fa-power-off" aria-hidden="true"></i>{{$t("LOGOUT")}}
                </b-button>
                <SidebarFooter/>
            </AppSidebar>
            <main class="main">
                <!-- Control items in breadcrumb bar -->
                <!-- <div class="control_set flex">
                    <div class="set set-1">
                        <a href="javascript:void(0)" class="prev_item disabled"><i class="fa fa-mail-reply fa-lg"></i></a>
                        <a href="javascript:void(0)" class="next_item disabled"><i class="fa fa-mail-forward fa-lg"></i></a>
                    </div>
                    <div class="set set-2">
                        <a href="javascript:void(0)" class="folder_view"><i class="fa fa-folder fa-lg"></i></a>
                        <a href="javascript:void(0)" class="grid_view"><i class="fa fa-th fa-lg"></i></a>
                    </div>
                    <div class="set set-3">
                        <a href="javascript:void(0)" class="settings"><i class="fa icon-settings fa-lg"></i></a>
                        <a href="javascript:void(0)" class="question"><i class="fa icon-question fa-lg"></i></a>
                        <a href="javascript:void(0)" class="preview" v-b-tooltip.hover title="Tooltip content"><i class="fa fa-camera fa-lg"></i></a>
                    </div>
                    <div class="set set-4">
                        <a href="javascript:void(0)" class="export textual-hibryd"><i class="icon-share"></i> Export</a>
                        <a href="javascript:void(0)" class="save textual">Save</a>
                        <a href="javascript:void(0)" class="btn app-button-submit btn-primary btn-sm">CTA small</a>
                    </div>
                </div>-->
                <!-- Breadcrumb -->
                <!-- <AppBreadcrumb :list="list"/> -->
                <div class="container-fluid">
                    <page-transition>
                        <router-view class="mainContent-wrapper"></router-view>
                    </page-transition>
                </div>
            </main>
            <AppAside fixed>
                <!--aside-->
                <DefaultAside/>
            </AppAside>
            <app-side :widths="['col-lg-12', 'col-sm-12', '100%']" v-model="showAdditionalSetupWizard"
                      :disable_close="true">
                <template slot="header" v-if="checkPermission(C_COMPANY_ADDITIONAL_SETUP_E)">{{$t('INITIAL_SYSTEM_SETTINGS')}}
                </template>
                <additional-setup-wizard>
                </additional-setup-wizard>
            </app-side>
        </div>
<!--        <TheFooter>-->
<!--            &lt;!&ndash;footer&ndash;&gt;-->

<!--            <div class="mr-auto">-->
<!--                <a href="https://direct-booker.com" target="_blank">Booker tools</a> &copy; 2021-->
<!--            </div>-->
<!--            <div class="ml-auto">-->
<!--            </div>-->
<!--        </TheFooter>-->
        <loading :show="loaderCounter > 0"></loading>
    </div>
</template>

<script>
    import nav from '@/_nav'
    import {
        Header as AppHeader,
        SidebarToggler,
        Sidebar as AppSidebar,
        SidebarFooter,
        SidebarForm,
        SidebarHeader,
        SidebarMinimizer,
        Aside as AppAside,
        AsideToggler,
        Footer as TheFooter,
        // Breadcrumb
    } from '@coreui/vue'
    import SidebarNav from '@/components/app/navigation/SidebarNav/SidebarNav'
    import DefaultAside from './DefaultAside'
    import DefaultHeaderDropdown from './DefaultHeaderDropdown'
    import PageTransition from '@/components/app/transition/AppTransition'
    import AppBreadcrumb from '@/components/app/breadcrumb/AppBreadcrumb'
    import AppSearch from "@/components/app/search/AppSearch";
    import AppSide from "@/components/app/form/AppAside";
    import {cloneDeep} from 'lodash'

    import AdditionalSetupWizard from "@/components/company/setup/AdditionalSetupWizard";
    import {C_SEARCH_V, C_COMPANY_ADDITIONAL_SETUP_E} from '@/shared/component_permission'
    import loading from 'vue-full-loading'
    import {EventBus, GE_LOADER_SHOW, GE_LOADER_HIDE, GE_MAIN_NAVIGATION_AC_RELOAD} from '@/shared/EventBus'
    import {getPresets} from "@/services/user";
    import {fetchAccessControlData} from "@/services/access";
    import {
        AC_MAIN_NAVIGATION
    } from "@/mixins/AccessControl/AccessControlEnumeration";
    import {getCompanyLogo, getCompanyLogoAlt} from "@/services/company";
    import {toast} from "@/shared/plugins/toastr";

    export default {
        name: 'DefaultContainer',
        components: {
            AppSearch,
            AsideToggler,
            AppHeader,
            AppSidebar,
            AppAside,
            TheFooter,
            AppBreadcrumb,
            DefaultAside,
            DefaultHeaderDropdown,
            SidebarForm,
            SidebarFooter,
            SidebarToggler,
            SidebarHeader,
            SidebarNav,
            SidebarMinimizer,
            PageTransition,
            AppSide,
            AdditionalSetupWizard,
            loading
        },
        data() {
            return {
                loaderCounter: 0,
                navList: [],
                showAdditionalSetupWizard: false,
                C_SEARCH_V: C_SEARCH_V,
                C_COMPANY_ADDITIONAL_SETUP_E,
                EventBus: EventBus,
                GE_LOADER_SHOW: GE_LOADER_SHOW,
                GE_LOADER_HIDE: GE_LOADER_HIDE,
                property_list_count:0,
                ac_response:{},
                logo: null,
                logo_alt: null
            }
        },
        computed: {
            navigation_list() {
                if (this.company) {
                    return this.navList.items.reduce((result, el) => {
                        if (!el.children && this.checkRoutePermission(el)) {
                            result.push(el);
                        }
                        if (el.children) {
                            let childElementsFiltered = el.children.filter(elChild => {
                                return this.checkRoutePermission(elChild)
                            })
                            if (childElementsFiltered.length > 0) {
                                if (childElementsFiltered.length === 1) {
                                    let childrenObject = childElementsFiltered[0]
                                    let item = {
                                        name: childrenObject.hasOwnProperty('caption') ? childrenObject.caption : childrenObject.name,
                                        url: childrenObject.url,
                                        icon: childrenObject.icon,
                                    }
                                    result.push(item)
                                } else {
                                    result.push({...el, ...{children: childElementsFiltered}})
                                }
                            }
                        }
                        return result;
                    }, []);
                } else {
                    return [];
                }
            },
            additionalSetup() {
                return this.$store.getters['user/getUserItem']('userData.additional_setup')
            },
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            user() {
                return this.$store.getters['user/getUser']
            },
        },
        watch: {
            additionalSetup: {
                handler(value) {
                    if (value === 0) {
                        this.showAdditionalSetupWizard = true
                    } else if (value === 1) {
                        this.showAdditionalSetupWizard = false
                    }
                },
                immediate: true
            },
            property_list_count:{
                handler(value){
                    if(!value){
                        getPresets().then(response => {
                            this.property_list_count =  response.data.properties
                        })
                    }
                }, immediate:true
            },
        },
        methods: {
            logout() {
                this.$store.dispatch('auth/actionLogout')
            },
            gotToHelpLink() {
                window.open('https://booker-tools.gitbook.io/booker-tools-newsletters/', "_blank");
            },
            checkAccess(routeMeta){
                if(routeMeta.hasOwnProperty('function')){
                    if(Object.keys(this.ac_response).length > 0){
                        let acObject = this.ac_response[routeMeta.permission_id]
                        if(acObject){
                            return acObject.visible
                        }
                    }
                } else {
                    return true
                }
            },
            checkRoutePermission(navItem) {
                if (!navItem.url) return
                const routeMeta = this.$router.resolve(navItem.url).route.meta || {}
                const hasPermission = (typeof (routeMeta.permission_id) === 'undefined' || this.checkPermission(routeMeta.permission_id))
                const isCorrectWorkflow = (typeof (routeMeta.workflow_id) === 'undefined' || routeMeta.workflow_id === this.company.workflow_id)
                return hasPermission && isCorrectWorkflow && this.checkAccess(routeMeta)

            },
            getAccessControlData(navList){
                let list =[]
                if(!navList || !navList.hasOwnProperty('items')){
                    return
                }
                navList.items.forEach(navItem => {
                    const routeMeta = this.$router.resolve(navItem.url).route.meta || {}
                    if(routeMeta.hasOwnProperty('function')){
                        list.push(routeMeta)
                    }
                    if(navItem.hasOwnProperty('children') && navItem.children.length > 0){
                        for(const childNavItem of navItem.children){
                            const routeMetaChild =  childNavItem.hasOwnProperty('url') && this.$router.resolve(childNavItem.url).route.meta || {}
                            if(routeMetaChild.hasOwnProperty('function')){
                                list.push(routeMetaChild)
                            }
                        }
                    }
                })
                let request = {
                    context:{
                        company: this.company && this.company.hasOwnProperty('id') ? this.company.id : null
                    },
                    data:[]
                }
                list.forEach(item => {
                        request.data.push({
                            uid: item.permission_id,
                            function: item.function
                        })
                })
                return fetchAccessControlData(AC_MAIN_NAVIGATION, request)
                .then(response => {
                    this.ac_response = response.data
                })
            },
            getLogo(){
                getCompanyLogo(this.company.id).then( res => {
                    this.logo=res
                })
                getCompanyLogoAlt(this.company.id).then( res => {
                    this.logo_alt=res
                })
            },
            setTemplate() {
                let template = this.user.company.template
                const root = document.querySelector(':root');
                Object.entries(template.colors).forEach(([key, value]) => {
                    root.style.setProperty(key, value)
                })
            }
        },
        mounted() {
            if (this.user.company.whitelabel_level && this.user.company.whitelabel_level == 2) {
                this.setTemplate()
            }
            this.getLogo()
            EventBus.$on(GE_MAIN_NAVIGATION_AC_RELOAD, () => {
                this.getAccessControlData(nav)
            })
            /*this.unwatch = this.$watch('$route.query', (queryParams) => {
                    const queryParamKeys = Object.keys(queryParams)
                    if (queryParamKeys.length > 0) {
                        if (queryParamKeys.indexOf("one_time_error") >= 0 && queryParams.one_time_error && typeof queryParams.one_time_error === "string") {
                            let oneTimeError = queryParams.one_time_error
                            oneTimeError = atob(oneTimeError)
                            if (oneTimeError && typeof oneTimeError === "string") {
                                let oneTimeErrorObject = JSON.parse(oneTimeError)
                                if (oneTimeErrorObject && typeof oneTimeErrorObject === "object") {
                                    toast({
                                        "title": "",
                                        "message": "",
                                        "type": 'error',
                                        "timeout": null,
                                        "time_out_close": false,
                                    })
                                }
                            }
                        }
                    }
                },
                {immediate: true})*/
        },
        created() {
            this.getAccessControlData(nav)
            this.navList = cloneDeep(nav)
            EventBus.$on(GE_LOADER_SHOW, () => {
                this.loaderCounter++
            })

            EventBus.$on(GE_LOADER_HIDE, () => {
                this.loaderCounter--
            })
        },
        beforeRouteEnter(to, from, next) {

            if (window.location !== window.parent.location) {
                window.parent.location.replace(to.fullPath)
            } else {
                next(() => {
                    document.body.classList.remove('page-login')
                    document.body.classList.remove('page-w-login')
                })
            }
        },
    }
</script>
<style>
    .app-body {
        overflow-x: visible;
    }
</style>
