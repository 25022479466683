<template>
    <div >
        <div class="d-flex justify-content-center align-items-center" v-if="loading">
            <b-spinner variant="primary"></b-spinner>
        </div>
        <template v-else>
            <b-row class="mt-5" v-if="booking_window_list.length > 0">
                <b-col sm="12" :key="index" v-for="(bookingWindow, index) in booking_window_list">
                    <b-card class="repeater-1 card-action">
                        <div  class="card-header" style="min-height: 3.5rem;!important; padding: 0 0 0 10px!important;">
                            <b-row>
                                <b-col>
                                    <repeater-header class="d-flex align-items-center">
                                        {{ bookingWindow.title }}
                                        <app-button @click="openAside(bookingWindow)"
                                                    class="action_button"
                                                    button_type="edit"
                                                    variant="link"
                                                    :show_text="false"></app-button>
                                    </repeater-header>
                                </b-col>
                            </b-row>
                        </div>

                        <div   class="p-0 card-body-action " v-if="showTableHeader(bookingWindow)" >
                            <table class="table b-table table-sm">
                                <thead>
                                <th>{{ $t('BOOKING_WINDOW') }}</th>
                                <th v-if="type_action === ACTION_TYPE_MIN_PRICE">{{ $t('CONDITION') }}</th>
                                <th>{{ $t('ACTION') }}</th>
                                <template v-if="type_action === ACTION_TYPE_MIN_PRICE">
                                    <th>{{$t("LOWER_LIMIT")}} (%)</th>
                                    <th>{{$t("UPPER_LIMIT")}} (%)</th>
                                    <th>{{$t('RECOMMENDATION_DAY_OFFSET')}}</th>
                                </template>
                                <th>{{$t('PRIORITY')}}</th>
                                <th>{{$t('VALUE')}} <span v-if="type_action === ACTION_TYPE_MIN_PRICE">(%)</span></th>
                                </thead>
                                <tbody>
                                <template v-for="(childBooking,index) in bookingWindow.child_booking_windows">
                                    <tr :key="childBooking.id+'_'+actionSetup.id"
                                        v-for="actionSetup in getActionByBookingWindow(childBooking.id)">
                                        <td>{{ childBooking.interval_from + '-' + childBooking.interval_to }}</td>
                                        <td v-if="type_action === ACTION_TYPE_MIN_PRICE">{{ getConditionNameById(actionSetup.condition) }}</td>
                                        <td>{{ getActionNameById(actionSetup.action) }}</td>
                                        <template v-if="type_action === ACTION_TYPE_MIN_PRICE">
                                            <td>{{ actionSetup.maximum_decrease }}</td>
                                            <td>{{ actionSetup.maximum_increase }}</td>
                                            <td>{{ actionSetup.day_interval_forward }}</td>
                                            <!--                                        <td>{{ actionSetup.day_interval_backward }}</td>-->
                                        </template>

                                        <td>{{ actionSetup.priority }}</td>
                                        <td>{{ actionSetup.value }}</td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>

                    </b-card>
                </b-col>
            </b-row>
        </template>


        <app-no-data :heading="$t('MODULE_NOT_ACTIVE')" :tip="$t('MODULE_NEED_BOOKING_WINDOWS')" :show-tip="true" v-else>

        </app-no-data>

        <app-aside v-model="aside" :widths="['col-lg-7','col-sm-12', '40%']">
            <template v-slot:header>
                <div class="property-wrapper mb-0 no-border">
                    <div class="card-header">
                        <div class="header-caption">{{$t('BOOKING_WINDOW')}}</div>
                        <h2 class="property-wrapper-padding mb-2">
                            {{rootBWObject.title }}
                        </h2>
                    </div>
                </div>
            </template>

            <revenue-manager-setup-action-form-new :root-booking-window="rootBWObject"
                                                   :type_action="type_action"
                                                   :object_id="object_id"
                                                   :mode="mode"
                                                   :read_only="read_only"
                                                   :read_only_message="read_only_message"
                                                   @close="reload"
            ></revenue-manager-setup-action-form-new>
        </app-aside>
    </div>


</template>

<script>
import AppAside from "@/components/app/form/AppAside";
import {getActionSetup, getRevenueBookingWindow,} from "@/services/revenue_manager";
import AppTableV2 from "@/components/app/AppTableV2";
import AppButton from "@/components/app/AppButton/AppButton";
import AppNoData from "@/components/app/AppNoData";
import RevenueManagerSetupActionForm from "@/components/revenue_manager/setup/RevenueManagerSetupActionForm";
import {EventBus, GE_REVENUE_MANAGER_ACTION_NEW} from "@/shared/EventBus";
import {ACTION_TYPE_MIN_STAY} from '@/shared/constants';
import RepeaterHeader from "@/components/app/form/RepeaterHeader";
import RevenueManagerSetupActionFormNew from "@/components/revenue_manager/setup/RevenueManagerSetupActionFormNew";
import {ACTION_TYPE_MIN_PRICE} from '@/shared/constants';

export default {
    name: "RevenueManagerSetupActionTable",
    components: {
        RevenueManagerSetupActionFormNew,
        RepeaterHeader, RevenueManagerSetupActionForm, AppNoData, AppButton, AppTableV2, AppAside
    },
    props: {
        mode: {
            type: String,
            default: 'company'
        },
        type_action: {
            type: Number,
            default: 1
        },
        object_id: {
            type: Number
        },
        read_only: {
            type: Boolean,
            default: false
        },
        read_only_message: {
            type: String
        },
    },
    data() {
        return {
            loading:false,
            ACTION_TYPE_MIN_PRICE,
            rootBWObject:{},
            ACTION_TYPE_MIN_STAY,
            aside: false,
            action_setup: {},
            fields: [
                {key: 'booking_window', label: 'Booking window'},
                {key: 'condition', label: this.$t('CONDITION')},
                {key: 'action', label: this.$t('ACTION')},
                {key: 'maximum_increase', label: this.$t(("UPPER_LIMIT"))}+' (%)',
                {key: 'maximum_decrease', label: this.$t('LOWER_LIMIT')}+' (%)',
                {key: 'day_interval_forward', label: this.$t('DAY_INTERVAL_FORWARD')},
                {key: 'day_interval_backward', label: this.$t('DAY_INTERVAL_BACKWARD')},
                {key: 'priority', label: 'Priority'},
                {key: 'value', label: 'Value'},
                {key: 'edit', label: ''},

            ],
            data: [],
            booking_window_list: [],
            child_booking_window_list: [],
            conditions: [],
            actions: [],
            fetchData: false
        }
    },
    computed:{
        company(){
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    methods: {
        openAside(rootBookingWindow){
            this.rootBWObject = rootBookingWindow
            this.aside = true
        },
        getActionByBookingWindow(booking_window_id) {
            const list = this.data.filter(el => el.booking_window === booking_window_id)
            return list
        },
        showTableHeader(rootBookingWindow){
            const  childOfRootBookingWindowIds = rootBookingWindow.child_booking_windows.map(el => {
                return el.id
            })
            const child =  this.data.find(el => childOfRootBookingWindowIds.includes(el.booking_window))
            return child ? true :false
        },
        edit(action_id) {

        },
        reload() {
            this.aside = false
            this.getProvider(this.object_id, false)
        },
        editAside(actionSetup) {
            this.aside = true
            this.action_setup = actionSetup
        },
        setChildBookingWindows() {
            if (this.booking_window_list.length > 0) {
                for (const rootBookingWindow of this.booking_window_list) {
                    if (rootBookingWindow.hasOwnProperty('child_booking_windows') && rootBookingWindow.child_booking_windows.length > 0) {
                        rootBookingWindow.child_booking_windows.forEach(el => {
                            el.title = el.interval_from + ' - ' + el.interval_to
                            this.child_booking_window_list.push(el)
                        })
                    }
                }
            }
        },
        getBookingWindowNameById(id) {
            let name = ''
            if (this.child_booking_window_list.length > 0) {
                const bookingWindowObject = this.child_booking_window_list.find(el => Number(el.id) === Number(id))
                if (bookingWindowObject) {
                    name = bookingWindowObject.interval_from + '-' + bookingWindowObject.interval_to
                }
            }
            return name
        },
        getConditionNameById(condition_id) {
            let name = ''
            if (this.conditions.length > 0) {
                const conditionObject = this.conditions.find(el => Number(el.id) === Number(condition_id))
                if (conditionObject) {
                    name = conditionObject.title
                }
            }
            return name
        },
        getActionNameById(action_id) {
            let name = ''
            if (this.actions.length > 0) {
                const actionObject = this.actions.find(el => Number(el.id) === Number(action_id))
                if (actionObject) {
                    name = actionObject.title
                }
            }
            return name
        },
        getProvider(value, fetch) {
            this.loading = true
            if (fetch) {
                this.fetchData = false
            }

            let request = {
                company:this.company.id
            }
            if (this.mode === 'unit') {
                request = {...request, ...{unit: value, type: this.type_action}}
            } else if (this.mode === 'property') {
                request = {...request, ...{property: value, type: this.type_action}}
            } else {
                request = {...request, ...{company: value, type: this.type_action}}
            }
            getActionSetup(request).then(response => {

                EventBus.$emit('gl_count_actions', response.data.items.length)
                this.data = response.data.items.filter(el => el[this.mode] === this.object_id)
                this.$emit('data', this.data)
                this.conditions = response.data.conditions
                this.actions = response.data.actions
                this.setChildBookingWindows()
            }).finally(() => {
                this.fetchData = true
                this.loading = false
            })
        }
    },
    mounted() {
        EventBus.$on('ge_reload_action_table', () => {
            this.reload()
        })
    },
    beforeDestroy() {
        EventBus.$off('ge_reload_action_table')
    },
    watch: {
        object_id: {
            handler(value) {
                this.getProvider(value, true)
                getRevenueBookingWindow({company:this.company.id, type:this.type_action, active: 1}).then(response=>{
                    this.booking_window_list = response.data
                })
            }, immediate: true
        },
/*        type_action:{
            handler(value) {
                this.getProvider(this.object_id, true)
                getRevenueBookingWindow({company:this.company.id, type:this.type_action}).then(response=>{
                    this.booking_window_list = response.data
                })
            }, immediate: true
        }*/
    },


}
</script>
<style scoped  type="text/css">

.no-border {
    box-shadow: none;
}

.no-border > div {
    border: none !important;
    padding-left: 0;
}
</style>
