<template>

    <b-row v-if="hasPermissionView && unit">

        <b-col>
            <div slot="header">
                <b-row>
                    <b-col>
                        <repeater-header class="mb-2">{{solo_unit_type ? solo_unit_type.type_name :''}}
                            <app-button
                                :c_permission="c_permission_contingent_setup_e"
                                class="action_button"
                                :show_text="false"
                                variant="link"
                                button_type="edit"
                                @click="contingentAsideState=true"></app-button>
                        </repeater-header>

                    </b-col>
                </b-row>
            </div>

            <b-row>
                <b-col>
                    <b-table responive hover :items="contingentUnitList"
                             :fields="fields">
                        <template v-slot:top-row="data">
                            <td>
                                <small v-if="companyHasAdsIntegrated === true && unit.hasOwnProperty('external_id') && unit.external_id" :title="$t('EXTERNAL_ID')" class="text-muted d-block">
                                    {{unit.external_id}}
                                </small>
                                {{unit.id}}
                            </td>
                            <td>{{unit.name}}</td>
                            <td>{{unit.owner.name}}</td>
                            <td class="text-right mr-0 pr-0"><app-indicator  indicator_type="indicator-primary"></app-indicator></td>
                            <td  class="text-right button-column ">
                                <edit-button
                                    :access_control_context="{function:C_UNIT_CONTINGENT_SETUP_ATTACH_E,key:AC_UNIT_CONTINGENT}"
                                    @AccessControlEvent="addToAccessControlCounter()"
                                    @click="attachAside=true"
                                    button-class="action_button"
                                    variant="link" icon="compress"
                                    :show_text="false">
                                    {{$t("ATTACH")}}
                                </edit-button>
                            </td>
                        </template>
                        <template v-slot:id="data">
                            <small v-if="companyHasAdsIntegrated === true && data.item.hasOwnProperty('external_id') && data.item.external_id" :title="$t('EXTERNAL_ID')" class="text-muted d-block">
                                {{data.item.external_id}}
                            </small>
                            {{data.item.id}}
                        </template>
                        <template v-slot:edit="data">
                            <app-button
                                         :access_control_context="{function:C_UNIT_CONTINGENT_SETUP_DETACH_E,key:AC_UNIT_CONTINGENT}"
                                         @AccessControlEvent="addToAccessControlCounter()"
                                         @click="itemEdit=data.item;showDetachDialog=true; group_name = data.item.name"
                                         variant="link" icon="expand" :show_text="false">
                            </app-button>


                            <edit-button variant="link" :show_text="false" button_type="edit"
                                         :to="{name: routeNames.RM_PROPERTY_UNIT_BASIC, params:{unit_id: data.item.id}}"></edit-button>
                        </template>
                    </b-table>

                    <app-aside v-model="contingentAsideState">
                        <template slot="header">{{$t('GROUP_UNITS')}}</template>
                        <unit-contingent-form @updateContingentList="updateList" :solo_unit_type="solo_unit_type"
                                              :contingent_unit_list="contingentUnitList"
                                              @updateSolo="updateSolo"></unit-contingent-form>
                    </app-aside>
                    <delete-dialog :loading="detachLoading" v-if="itemEdit" :show="showDetachDialog" @confirm="detachUnit()"
                                   @cancel="showDetachDialog=false" :title="$t('REMOVE_UNIT_GROUP_CONFIRMATION')">
                        {{$t("DETACH_DIALOG_MESSAGE")}}
                        <b-form-group class="mt-2 mr-2" >
                               <app-input-control :error-object="validationErrorObject.group_name">
                                   <template v-slot:input="data">
                                       <label class="label">{{$t('GROUP_NAME')}}</label>
                                       <b-form-input :state="data.state" v-model="group_name"></b-form-input>
                                   </template>
                                </app-input-control>
                        </b-form-group>
                       <!-- <b-form-textarea class="mt-2 mb-2" :placeholder="$t('HEADLINE')"
                                         rows="2"></b-form-textarea>-->
                    </delete-dialog>
                    <app-aside v-model="attachAside">
                        <template slot="header">{{$t('ATTACH')}}: {{unit.name}}</template>
                        <unit-attach-to-parent-form @updateContingentList="attachAside=false;updateList()"
                                                    :unit="unit"></unit-attach-to-parent-form>
                    </app-aside>
                </b-col>
            </b-row>

        </b-col>
    </b-row>

</template>

<script>
    import {getUnitPaginatedList, getSoloUnitType, dettachUnit} from '@/services/unit/index'
    import routeNames from '@/router/routeNames'
    import AppButton from '@/components/app/AppButton/AppButton'
    import AppAside from '@/components/app/form/AppAside'
    import UnitContingentForm from '@/components/unit/contingent/UnitContingentForm'
    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import DeleteButton from '@/components/app/AppButton/AppButtonDelete'
    import DeleteDialog from '@/components/app/form/AppConfirmationDialog'
    import {
        C_UNIT_CONTINGENT_SETUP_V,
        C_UNIT_CONTINGENT_SETUP_E,
        C_UNIT_CONTINGENT_SETUP_ATTACH_E,
        C_UNIT_CONTINGENT_SETUP_DETACH_E
    } from "@/shared/component_permission";
    import EditButton from '@/components/app/AppButton/AppButton'
    import FormHeader from "@/components/app/form/FormHeader";
    import RepeaterHeader from "@/components/app/form/RepeaterHeader";
    import UnitAttachToParentForm from "@/components/unit/contingent/UnitAttachToParentForm";
    import AppIndicator from "@/components/app/indicators/AppIndicator";
    import AppInputControl from "@/components/app/AppInputControl";
    import {UNIT_DATA_GROUP_NAME_VALIDATION_FAILED} from "@/shared/error_codes";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {AC_UNIT_CONTINGENT} from "@/mixins/AccessControl/AccessControlEnumeration";

    export default {
        name: "index",
        data() {
            return {
                c_permission_contingent_setup_e: C_UNIT_CONTINGENT_SETUP_E,
                C_UNIT_CONTINGENT_SETUP_ATTACH_E: C_UNIT_CONTINGENT_SETUP_ATTACH_E,
                contingentUnitList: [],
                routeNames,
                fields: [
                    {key: 'id', label: this.$t('ID'), class: 'w-10'},
                    {key: 'name', label: this.$t('NAME')},
                    {key: 'owner.name', label: this.$t('OWNER')},
                    {key: 'indicator', label: '', class: 'text-right pr-0 mr-0'},
                    {key: 'edit', label: '', tdClass: 'text-right button-column'},
                ],
                contingentAsideState: false,
                showDeleteDialog: false,
                showDetachDialog: false,
                attachAside: false,
                itemEdit: null,
                solo_unit_type: {},
                group_name:null,
                detachLoading:false,
                validationErrorObject:{
                    group_name:UNIT_DATA_GROUP_NAME_VALIDATION_FAILED
                },
                access_control_fetch_key:AC_UNIT_CONTINGENT,
                AC_UNIT_CONTINGENT,
                C_UNIT_CONTINGENT_SETUP_DETACH_E

            }
        },
        mixins: [getErrorMessage,AccessControlComponent],
        components: {
            AppInputControl,
            AppIndicator,
            UnitAttachToParentForm,
            RepeaterHeader,
            FormHeader,
            AppButton,
            AppAside,
            UnitContingentForm,
            DeleteButton,
            DeleteDialog,
            EditButton
        },
        computed: {
            hasPermissionView() {
                return this.$store.getters['user/getPermission'](C_UNIT_CONTINGENT_SETUP_V)
            },
            property() {
                return this.$store.getters['property/getProperty']
            },
            unit() {
                return this.$store.getters['getUnit']
            },
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            companyHasAdsIntegrated() {
                return !!(this.company && this.company.hasOwnProperty('ads_integrated') && this.company.ads_integrated)
            },
        },
        methods: {
            updateSolo(value) {
                this.fetchSoloUnitType(Number(this.$route.params.unit_id))
            },
            updateList(value) {
                this.getContingentUnitList(Number(this.$route.params.unit_id))
            },
            getContingentUnitList(unitId) {
                getUnitPaginatedList({property_id: this.property.id, parent: unitId, limit: 1000})
                    .then(response => {
                        this.contingentUnitList = response.data.items
                        this.access_control_components = response.data.items.length
                    })
            },
            fetchSoloUnitType(unitId) {
                getSoloUnitType(unitId).then(response => {
                    this.solo_unit_type = response.data
                })

            },
            detachUnit() {

                this.detachLoading = true
                dettachUnit(this.itemEdit.id,{group_name:this.group_name, property:this.property.id}).then(() => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.updateList()
                    this.itemEdit = null
                    this.detachLoading = false
                }, error => {
                    this.showErrorMessages(error, this.validationErrorObject);
                    this.detachLoading = false
                })
            }
        },
        watch: {
            '$route.params.unit_id': {
                handler(UnitId) {
                    this.access_control_general_context = {
                        unit: UnitId,
                        company: this.$store.getters['user/getCompany']
                    }
                    this.getContingentUnitList(UnitId)
                    this.fetchSoloUnitType(UnitId)

                },
                immediate: true
            }
        }

    }
</script>

<style scoped>
.button-column{
    width:4.3rem;
}
</style>
