<template>
    <div>
        <b-form autocomplete="off" @submit.prevent="setAccessData">
            <b-row>
                <b-col cols="4" class="mb-4">
                    <app-select :label="$t('OWNER')" v-model="owner" :options="owner_list"></app-select>
                </b-col>

            </b-row>
            <b-row v-if="owner" class="mb-3">
                <b-col sm="12" md="6" lg="4">
                    <app-input-control :error-object="validationErrorObject.facility_code">
                        <template v-slot:input="data">
                                <label>{{$t('FACILITY_CODE')}}</label><a <i class=" fa fa-question-circle-o ml-1 pull-right tooltip-help  mr-3 mt-1" @click="show = true"></i>
                                <b-form-input v-model="objectAccessData.facility_code" type="text"> </b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col sm="12" md="6" lg="4">
                    <app-input-control :error-object="validationErrorObject.username">
                        <template v-slot:input="data">
                                <label>{{$t('USERNAME')}}</label>
                                <b-form-input v-model="objectAccessData.username" type="text"></b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col sm="12" md="6" lg="4">
                    <app-input-control :error-object="validationErrorObject.password">
                        <template v-slot:input="data">
                                <label>{{$t('PASSWORD')}}</label>
                                <b-form-input v-model="objectAccessData.password" type="password"></b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
            <b-row v-if="owner" class="mt-4">
                <b-col md="6">
                    <app-submit :loading="data_loaded" :disabled="disableSave"></app-submit>
                </b-col>
                <b-col md="6" v-if="objectAccessData.hasOwnProperty('id') && objectAccessData.id !== null">
                    <app-button-delete class="pull-right" @click="deleteDialogShow=true"></app-button-delete>
                </b-col>
                <delete-confirm :show="deleteDialogShow" @confirm="deleteConfirm" @cancel="deleteDialogShow=false" :delete_title="true"></delete-confirm>
            </b-row>
        </b-form>
        <b-modal v-model="show" size="xl" :title="$t('EVISITOR_INSTRUCTIONS')" hide-footer>
            <b-col>
                <img :src="EVISITOR_DESCRIPTION_IMG_URL" alt="Descriptions" width="100%">
            </b-col>

        </b-modal>
    </div>
</template>

<script>
    import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {getPropertyOwners} from "@/services/owner";
    import {EVisitorCro} from "@/shared/constants";
    import {getGuestAccessData} from "@/services/guest";
    import AppInputControl from "@/components/app/AppInputControl";
    import {
        GUEST_ACCESS_DATA_USERNAME_VALIDATION_FAILED,
        GUEST_ACCESS_DATA_PASSWORD_VALIDATION_FAILED,
        GUEST_ACCESS_DATA_FACILITY_CODE_VALIDATION_FAILED
    } from "@/shared/error_codes";
    import FormHeader from "@/components/app/form/FormHeader";
    import {toast} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
    import DeleteConfirm from '@/components/app/form/AppConfirmationDialog'
    import {cloneDeep} from "lodash";
    import {EVISITOR_DESCRIPTION_IMG_URL} from "@/shared/constants";
    import {EventBus, GE_LOADER_SHOW, GE_LOADER_HIDE} from "@/shared/EventBus";

    export default {
        name: "guest-check-in-e-visitor",
        components: {
            AppButtonDelete,
            AppButton,
            FormHeader,
            AppInputControl,
            AppSelect,
            AppSubmit,
            DeleteConfirm
        },
        props: {
            property: {
                type: Object
            },
            data_loaded:{
                type:Boolean,
                default:false
            },
            deleted_flag:{
                type:Boolean,
                default:false
            },
            accessData:{
                type:Object
            }

        },
        mixins:[getErrorMessage],
        data() {
            return {
                show:false,
                owner_list: [],
                owner: null,
                objectAccessData: {
                    facility_code: null,
                    username: null,
                    password: null,
                },
                validationErrorObject: {
                    facility_code: {
                        input: [GUEST_ACCESS_DATA_FACILITY_CODE_VALIDATION_FAILED]
                    },
                    username: {
                        input: [GUEST_ACCESS_DATA_USERNAME_VALIDATION_FAILED]
                    },
                    password: {
                        input: [GUEST_ACCESS_DATA_PASSWORD_VALIDATION_FAILED]
                    },

                },
                disableSave:false,
                deleteDialogShow:false,
                EVISITOR_DESCRIPTION_IMG_URL

            }
        },
        watch: {
            owner: {
                handler(value) {
                    EventBus.$emit(GE_LOADER_SHOW)
                    getGuestAccessData(this.property.id, {owner: value, connection:EVisitorCro}).then(response => {

                            this.disableSave = false
                            this.objectAccessData = {connection: EVisitorCro, ...response.data}
                        }, error=>{
                        this.resetData()
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.getMessages(error),
                            'type': 'error',
                            'timeout': 3000
                        })
                        }
                    ).finally(() => {
                        EventBus.$emit(GE_LOADER_HIDE)
                    })
                }
            },
            deleted_flag:{
                handler(value){
                    if(value){
                        this.resetData()
                    }
                }
            },
            accessData:{
                handler(value){
                    this.objectAccessData = {connection: EVisitorCro, ...cloneDeep(value)}
                }
            }
        },
        methods: {
            setAccessData() {
                this.objectAccessData = {owner: this.owner, ...this.objectAccessData}
                this.$emit('setData', this.objectAccessData)
            },
            resetData(){
                this.objectAccessData.id = null
               this.objectAccessData.facility_code = null
               this.objectAccessData.username = null
               this.objectAccessData.password = null
            },
            deleteConfirm(){
                this.$emit('deleteData', this.objectAccessData.id)
                this.deleteDialogShow =false


            }
        },
        created() {
            getPropertyOwners(this.property.id).then(response => {
                this.owner_list = response.data
                if(this.owner_list.length === 1){
                    this.owner = this.owner_list[0].id
                }
            })
        }
    }
</script>

<style scoped>
.tooltip-help{
    color: #6F6EFE;
    cursor: pointer;
}
</style>
