<template>
    <div>
        <tag-list :destination_type="TAG_DESTINATION_TYPE_REGION" :parent_tag="parseInt($route.params.id)"
                  :refresh="refresh" :tag_type="TAG_TYPE_DESTINATION" @openNew="tag_sidebar=true" @showButton="showButton=true"></tag-list>
        <app-aside v-model="tag_sidebar" :widths="['col-lg-4','col-sm-12', '40%']">
            <template slot="header">{{ $t('NEW_REGION') }}</template>
            <add-tag :parent_tag="parseInt($route.params.id)" :destination_type="TAG_DESTINATION_TYPE_REGION" :tag_type="TAG_TYPE_DESTINATION"
                     @saved="saved" :label="$t('REGION')"></add-tag>
        </app-aside>
    </div>
</template>

<script>

    import TagList from "@/components/tag/list/TagList";
    import {TAG_DESTINATION_TYPE_REGION, TAG_TYPE_DESTINATION} from "@/shared/constants";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AddTag from "@/components/tag/sidebar/AddTag";
    import AppAside from "@/components/app/form/AppAside";
    import {EventBus} from "@/shared/EventBus";

    export default {
        name: "Index.Vue",
        components: {AddTag, AppButton, TagList, AppAside},
        data() {
            return {
                TAG_DESTINATION_TYPE_REGION, TAG_TYPE_DESTINATION,
                tag_sidebar: false,
                parent_tag: this.$route.params.id,
                refresh: false,
                showButton: false
            }
        },
        methods: {
            open() {
                this.tag_sidebar = true;
            },
            setParentTag(tag) {
                this.parent_tag = tag
            },
            saved() {
                this.tag_sidebar = false;
                this.refresh = !this.refresh
            }
        },
        computed: {
            tag() {
                return this.parent_tag
            }
        },
        mounted() {
            EventBus.$on('openAddNewTag', () => {
                this.tag_sidebar = true
            })
        },
        beforeDestroy() {
            EventBus.$off('openAddNewTag')
        },
        watch: {
            '$route.params.id': {
                handler(id) {
                    this.refresh = !this.refresh
                }
            }
        }
    }
</script>

<style scoped>

</style>
