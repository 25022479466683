<template>
    <b-table small responsive :items="ratePlanList" :fields="fields">
        <template v-slot:mapping="data">
            <app-select :value="ratePlanEidValue(data.item.id)" @input="update(data.item.id, $event)"
                        :options="externalRatePlanList">
            </app-select>
        </template>
    </b-table>
</template>
<script>
    import {getPricingRatePlanList} from "@/services/pricing";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {getChannelRatePlanList} from "@/services/channel_manager";
    import {EXPEDIA,BOOKING} from "@/shared/constants";
    import {EventBus, GE_LOADER_SHOW, GE_LOADER_HIDE} from "@/shared/EventBus";

    export default {
        components: {AppSelect},
        name: "unit-mapping-item",
        props: {
            unit: {
                type: Object
            },
            unit_eid: {},

            property_id: {
                type: Number
            },
            property_eid: {},
            distribution: {
                type: Object
            },
            propertyExternalRatePlanList: {
                type: Array
            },
            value: {
                type: Number
            },
        },
        data() {
            return {
                ratePlanMapping: [],
                ratePlanList: [],
                externalRatePlanList: [],
                fields: [
                    {key: 'id'},
                    {key: 'name', label: this.$t('RATE_PLAN')},
                    {key: 'mapping', label: this.$t('EXTERNAL_RATE_PLAN'), tdClass: 'w-50'}
                ]
            }
        },

        methods: {
            getRatePlans(unit_id) {
                getPricingRatePlanList({unit: unit_id, distribution: this.distribution.id}).then(response => {
                    this.ratePlanList = response.data.items
                }, () => {
                    this.ratePlanList = []
                })
            },
            update(rate_plan_id, rate_plan_eid) {
                let ratePlanExtObject = this.externalRatePlanList.find(item => item.id === rate_plan_eid)
                let index = this.ratePlanMapping.findIndex(el => el.rate_plan_id === rate_plan_id)
                if (index > -1) {
                    this.ratePlanMapping[index].rate_plan_eid = rate_plan_eid
                    this.ratePlanMapping[index].external_name = typeof ratePlanExtObject !== 'undefined' ? ratePlanExtObject.external_name : null
                } else {
                    let object = {
                        rate_plan_id: rate_plan_id,
                        rate_plan_eid: rate_plan_eid,
                        external_name: ratePlanExtObject.external_name
                    }
                    this.ratePlanMapping.push(object)
                }
                this.$emit('updateUnitRatePlan', this.ratePlanMapping)

            },
            ratePlanEidValue(rate_plan_id) {

                let mappingObject = this.ratePlanMapping.find(el => el.rate_plan_id === rate_plan_id)
                if (typeof mappingObject !== 'undefined' && mappingObject.hasOwnProperty('rate_plan_eid')) {
                    return mappingObject.rate_plan_eid
                } else {
                    return null
                }
            },
            getChannelUnitRatePlan(unit_eid) {
                EventBus.$emit(GE_LOADER_SHOW)
                getChannelRatePlanList({
                    distribution: this.distribution.id,
                    property: this.property_id,
                    property_eid: this.property_eid,
                    unit: this.unit.id,
                    unit_eid: unit_eid
                }).then(response => {
                    this.externalRatePlanList = []
                    if (response.data.length > 0) {
                        for (let item of response.data) {
                            let object = {
                                id: item.id,
                                name: item.name + ' (' + item.id + ')',
                                external_name: item.name
                            }
                            this.externalRatePlanList.push(object)
                        }
                    }
                }).finally(() => {
                    EventBus.$emit(GE_LOADER_HIDE)
                })
            },
        },
        watch: {
            unit_eid: {
                handler(value) {
                    this.getRatePlans(this.unit.id)
                    this.getChannelUnitRatePlan(value)
                },
                immediate: true
            }
        },

    }

</script>

<style scoped>

</style>
