<template>
    <div v-if="checkUserAccount">
        <b-form @submit.prevent="submit" v-if="checkPermission(C_USER_PASSWORD_V)">
            <b-row>
                <b-col md="6" lg="3" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.old_password">
                        <template v-slot:input="data">
                            <label>{{ $t('OLD_PASSWORD') }}</label>
                            <b-form-input type="password"
                                          v-model="formData.old_password"
                                          :state="data.state">
                            </b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="6" lg="3" >
                    <app-input-control :error-object="validationErrorObject.new_password">
                        <template v-slot:input="data">
                            <label>{{ $t('NEW_PASSWORD') }}</label>
                            <b-form-input type="password"
                                          v-model="formData.new_password"
                                          :state="data.state">
                            </b-form-input>
                            <password v-model="formData.new_password" :strength-meter-only="true"/>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="6" lg="3" class="mb-3">
                    <app-input-control :error-object="validationErrorObject.new_password_repeat">
                        <template v-slot:input="data">
                            <label>{{ $t('REPEAT_NEW_PASSWORD') }}</label>
                            <b-form-input type="password"
                                          v-model="formData.new_password_repeat"
                                          :state="data.state">
                            </b-form-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <save-button :loading="updating" :c_permission="C_USER_PASSWORD_E">{{$t("SAVE")}}</save-button>
                </b-col>
            </b-row>
        </b-form>
    </div>
    <div v-else>
        <b-row>
            <b-col>
                <b-button
                    v-if="checkPermission(C_USER_PASSWORD_RESET)"
                    variant="primary"
                    :loading="updating"
                    @click="resetPassword">
                    {{$t ("RESET_PASSWORD") }}
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>

    import AppInputControl from "@/components/app/AppInputControl";
    import SaveButton from '@/components/app/AppButton/AppButtonSubmit';
    import {C_USER_PASSWORD_E, C_USER_PASSWORD_V, C_USER_PASSWORD_RESET} from '@/shared/component_permission'
    import {updateUserPassword} from "@/services/user";
    import {pick} from 'lodash'
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {toast} from "@/shared/plugins/toastr";
    import AppValidationDescription from "@/components/app/form/AppValidationDescription";
    import Password from 'vue-password-strength-meter'
    import {cloneDeep} from "lodash";
    import {userPasswordReset} from "@/services/access";
    import {
        USER_OLD_PASSWORD_REQUIRED,
        USER_OLD_PASSWORD_VALIDATION_FAILED,
        USER_VALIDATION_FAILED_PASSWORD_MIN_LENGTH, USER_VALIDATION_FAILED_PASSWORD_MUST_CONTAIN_NUMBERS_AND_LETTERS,
        USER_VALIDATION_FAILED_PASSWORD_MUST_CONTAIN_NUMBER_UPPERCASE_AND_LOWERCASE_LETTER,
        USER_VALIDATION_FAILED_PASSWORD_NOT_SAME,
        USER_VALIDATION_FAILED_REPEAT_PASSWORD_REQUIRED
    } from "@/shared/error_codes";

    export default {
        name: "UserPasswordForm",
        components: {
            AppInputControl,
            AppValidationDescription,
            SaveButton,
            Password
        },
        mixins: [getErrorMessage],
        data() {
            return {
                C_USER_PASSWORD_V,
                C_USER_PASSWORD_E,
                C_USER_PASSWORD_RESET,
                formData: {
                    old_password: null,
                    new_password: null,
                    new_password_repeat: null
                },
                validationErrorObject: {
                    old_password: {
                        input: [
                            USER_OLD_PASSWORD_REQUIRED,
                            USER_OLD_PASSWORD_VALIDATION_FAILED
                        ]
                    },
                    new_password: {
                        input: [USER_VALIDATION_FAILED_PASSWORD_MIN_LENGTH,USER_VALIDATION_FAILED_PASSWORD_MUST_CONTAIN_NUMBER_UPPERCASE_AND_LOWERCASE_LETTER,USER_VALIDATION_FAILED_PASSWORD_MUST_CONTAIN_NUMBERS_AND_LETTERS]
                    },
                    new_password_repeat: {
                        input: [
                            USER_VALIDATION_FAILED_REPEAT_PASSWORD_REQUIRED,
                            USER_VALIDATION_FAILED_PASSWORD_NOT_SAME
                        ]
                    }
                },
                userData: {},
                updating: false
            }
        },
        props: {
            userProp: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        watch: {
            userProp: {
                handler(val) {
                    this.userData = cloneDeep(val)
                },
                deep: true,
                immediate: true
            }
        },
        computed: {
            user() {
                return this.userData;
            },
            checkUserAccount() {
                return Number(this.$route.params.id) === this.$store.getters[`user/getUser`].id;
            }
        },
        methods: {
            submit() {
                this.updating = true;
                updateUserPassword(pick(this.formData, ['new_password', 'old_password', 'new_password_repeat']))
                    .then(() => {
                        toast({
                            title: this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            message: this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            type: 'success',
                            timeout: 3000
                        })
                    }, error => {
                        this.formData.new_password = '';
                        this.formData.old_password = null;
                        this.formData.new_password_repeat = null;
                        this.showErrorMessages(error, this.validationErrorObject);
                    })
                    .finally(() => {
                        this.updating = false;
                    })
            },
            resetPassword() {
                this.updating = true
                userPasswordReset({email:this.userData.email}).then(()=>{
                    toast({
                        'title': this.$t('NOTIFICATIONS.RESET_PASSWORD.TITLE'),
                        'message': this.$t('NOTIFICATIONS.RESET_PASSWORD.MESSAGE'),
                        'type': 'success'
                    });
                }, error=>{
                    toast({
                        'title': this.$t('NOTIFICATIONS.RESET_PASSWORD_FAIL.TITLE'),
                        'message': this.$t('NOTIFICATIONS.RESET_PASSWORD_FAIL.MESSAGE',{value:this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    });
                }).finally(() => {
                    this.updating = false;
                })
            }
        }
    }
</script>

<style scoped>

</style>
