<template>
    <b-row class="mt-3 ml-0 ml-md-1">
        <b-col cols="6" xl="6" class="mb-4">
            <app-translation-input
                :label="$t('TITLE')"
                :context-label="$t(`${navPosition}_NAVIGATION`.toUpperCase())"
                v-model="navItem_.label.value"
                :lang_id="navItem_.label.lang_id"
                :languages="website.supported_languages">
            </app-translation-input>
        </b-col>

        <b-col cols="6" xl="6" class="mb-4">
            <label>{{ $t('TYPE') }}</label>
            <app-select
                v-model="navItem_.type"
                :options="navigationTypes"
                :search-empty-item="false">
            </app-select>
        </b-col>

        <template v-if="navItem_.type === EXTERNAL">
            <b-col cols="12" xl="12" class="mb-4">
                <label>URL</label>
                <b-form-input v-model="navItem_.url"></b-form-input>
            </b-col>
        </template>

        <template v-else>
            <b-col cols="12" xl="6" class="mb-4">
                <label>{{ $t('PAGE') }}</label>
                <app-select
                    v-model="navItem_.page_id"
                    :options="navigationPages"
                    text-field="formattedLabel"
                    :search-empty-item="false">
                </app-select>
            </b-col>
        </template>
    </b-row>
</template>

<script>
import {cloneDeep} from "lodash";

const WEBSITE = 1
const EXTERNAL = 2

export default {
    name: "WebsiteNavigationItemForm",
    components: {
        "AppTranslationInput": () => import("@/components/app/translation/AppTranslationInput"),
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
    },
    props: {
        navPosition: {
            type: String,
            required: true,
            validator: value => {
                return ['footer', 'header'].includes(value)
            }
        },
        navItem: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            navItem_: cloneDeep(this.navItem),
            WEBSITE,
            EXTERNAL,
            navigationTypes: [
                {id: WEBSITE, name: this.$t('WEBSITE')},
                {id: EXTERNAL, name: this.$t('EXTERNAL')}
            ],
        }
    },
    computed: {
        website() {
            return this.$store.getters['website/getWebsite']
        },
        navigationPages() {
            return this.$store.getters['website/getNavigationPages'].filter(page => page.navigable)
        }
    },
    watch: {
        navItem_: {
            deep: true,
            handler(navItem) {
                this.$emit('update', {...navItem, visible: true})
            }
        }
    }
}
</script>

<style scoped>

</style>
