<template>
    <b-form @submit.prevent="saveFee">

        <b-row>
          <b-col sm="6">
            <b-form-group>
                <label>{{$t('TYPE')}}</label>
              <b-form-input disabled type="text" id="type" v-model="feeObject.label"></b-form-input>
            </b-form-group>
          </b-col>
            <b-col sm="6">
                <b-form-group>
                    <label>{{$t('INCLUDED_IN_PRICE')}}</label>
                    <app-select
                        text-field="text"
                        value-field="value"
                        v-model="feeObject.included_in_price"
                        @change="$v.feeObject.included_in_price.$touch"
                        :state="$v.feeObject.included_in_price.$dirty && $v.feeObject.included_in_price.$invalid ? false : null"
                        :options="includedPrice"
                    ></app-select>
                    <validation-description
                            :show="$v.feeObject.included_in_price.$dirty && $v.feeObject.included_in_price.$invalid ? false : null">
                        {{$t('FIELD_MANDATORY')}}
                    </validation-description>
                </b-form-group>
            </b-col>
          <b-col sm="6">
            <b-form-group>
                <label>{{$t('PAYMENT_TYPE')}}</label>
                <app-select value-field="key" text-field="label" v-model="feeObject.charge_type"  :options="charge_type_list"
                             @change="$v.feeObject.charge_type.$touch"
                             :state="$v.feeObject.charge_type.$dirty && $v.feeObject.charge_type.$invalid ? false : null"></app-select>

            </b-form-group>
          </b-col>
            <b-col sm="6">
                <b-form-group>
                  <label>{{$t('AMOUNT')}}</label>
                  <b-input-group :append="amountType">
                    <b-form-input  v-model="feeObject.amount"></b-form-input>
                  </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col sm="6" md="6" lg="6">
                <FeeSubmit :disabled="$v.$invalid"></FeeSubmit>
            </b-col>
            <b-col sm="6" md="6" lg="6">
                <fee-delete v-if="feeObject.charge_type"  :loading="deleteState" @click="showDeleteDialog=true" class="pull-right"></fee-delete>
            </b-col>
        </b-row>
        <fee-delete-dialog :show="showDeleteDialog" @confirm="deleteFee" @cancel="showDeleteDialog = false" :delete_title="true"></fee-delete-dialog>
    </b-form>
</template>

<script>
    import FeeSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import FeeDelete from '@/components/app/AppButton/AppButtonDelete'
    import FeeDeleteDialog from '@/components/app/form/AppConfirmationDialog'
    import ValidationDescription from '@/components/app/form/AppValidationDescription'
    import {required} from 'vuelidate/lib/validators'
    import {setUnitFee,deleteUnitFee} from '@/services/unit/index'
    import {GET_UNIT_ITEM} from "@/store/store_types";
    import AppSelect from "@/components/app/AppSelect/AppSelect";

    export default {
        name: "unit-fee-form",
        props:{
            item:{
                type:Object
            },
            charge_type_list:{
                type:Array
            }
        },
        mixins: [getErrorMessage],
        data() {
            return {
                includedPrice: [
                    {value: 1, text: this.$t('YES')},
                    {value: 0, text: this.$t('NO')},
                ],
                saveState: false,
                showDeleteDialog: false,
                deleteState: false,
                feeObject:{
                    fee_type:null,
                    label:null,
                    included_in_price:null,
                    amount:null,
                    charge_type:null
                }
            }
        },
        validations: {
            feeObject:{
                included_in_price:{
                  required
                },
                amount:{
                  required
                },
                charge_type:{
                    required
                }
            }
        },

        components: {
            AppSelect,
            FeeSubmit,
            FeeDelete,
            FeeDeleteDialog,
            ValidationDescription
        },
        computed: {
            getCompanyInfo(){
                return this.$store.getters[GET_UNIT_ITEM]('basic.company')
            },
            amountType(){
                if(this.chargeType(this.feeObject.charge_type)){
                    if(this.chargeType(this.feeObject.charge_type).is_fixed){
                        return this.getCompanyInfo.working_currency.name
                    } else {
                        return '%'
                    }
                } else {
                    return ''
                }
            }
        },
        methods: {
            chargeType(key) {
                return this.charge_type_list.find(data => data.key === key);
            },
            saveFee() {
                setUnitFee(Number(this.$route.params.unit_id), this.feeObject)
                .then(() => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.$emit('feeSaved')
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                }).finally()
            },
            deleteFee() {
                deleteUnitFee(Number(this.$route.params.unit_id), {fee_type: this.feeObject.fee_type}).then(() => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.$emit('feeSaved')
                    this.showDeleteDialog = false
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                })
            }
        },
        created(){
            this.feeObject.fee_type = this.item.id
            this.feeObject.label = this.item.label
            this.feeObject.included_in_price = this.item.fee.included_in_price
            this.feeObject.amount = this.item.fee.amount
            this.feeObject.charge_type = this.item.fee.charge_type
        }
    }
</script>

<style scoped>

</style>
