<template>
    <b-form autocomplete="false" @submit.prevent="save" v-if="formData">
        <b-row>
            <app-access-control md="6" lg="4" class="mb-4"
                                :access_control_context="{function:C_PROPERTY_NAME,key:AC_PROPERTY_GENERAL}"
                                @AccessControlEvent="addToAccessControlCounter()">
                <template v-slot="props">

                    <b-tooltip v-if="props.messages.length"
                               triggers="hover"
                               :title="props.messages[0].message" :target="'property_name_'+C_PROPERTY_NAME">
                    </b-tooltip>
                    <app-input-control :error-object="validationErrorObject.name">
                        <template v-slot:input="data">
                            <label>{{$t('NAME')}}</label>
                            <span :id="'property_name_'+C_PROPERTY_NAME" class="d-flex">
                                        <b-form-input
                                            :disabled="props.disabled"
                                            :state="data.state"
                                            type="text"
                                            v-model.trim="formData.name"
                                            name="property_name">
                            </b-form-input>
                            </span>
                        </template>
                    </app-input-control>
                </template>

            </app-access-control>

            <app-access-control md="6" lg="4" xl="2" class="mb-4"
                                :access_control_context="{function:C_PROPERTY_CATEGORIZATION,key:AC_PROPERTY_GENERAL}"
                                @AccessControlEvent="addToAccessControlCounter()">
                <template v-slot="props">
                    <app-input-control :error-object="validationErrorObject.categorization">
                        <template v-slot:input="data">

                               <label>{{$t('CATEGORIZATION')}}</label>
                               <app-select :has-message="true" :message="getAcMessage(props)"  :disabled="props.disabled" v-model="formData.categorization"
                                           :options="categorizationOptions" :state="data.state"></app-select>


                        </template>
                    </app-input-control>
                </template>
            </app-access-control>


            <app-access-control md="6" lg="4" xl="2" class="mb-4"
                                :access_control_context="{function:C_PROPERTY_UNIT_NUMBER,key:AC_PROPERTY_GENERAL}"
                                @AccessControlEvent="addToAccessControlCounter()">
                <template v-slot="props">
                    <b-tooltip v-if="props.messages.length"
                               triggers="hover"
                               :title="props.messages[0].message"
                               :target="'property_unit_number_'+C_PROPERTY_UNIT_NUMBER">
                    </b-tooltip>
                    <app-input-control :error-object="validationErrorObject.unit_number">
                        <template v-slot:input="data">
                            <label>{{$t('UNIT_NUMBER')}}</label>
                            <span :id="'property_unit_number_'+C_PROPERTY_UNIT_NUMBER" class="d-flex">
                                <b-form-input
                                    :disabled="props.disabled"
                                    :state="data.state"
                                    name="unit_number"
                                    v-model="formData.unit_number">
                                </b-form-input>
                            </span>


                        </template>
                    </app-input-control>
                </template>
            </app-access-control>
            <app-access-control md="6" lg="4" xl="2" class="mb-4"
                                :access_control_context="{function:C_PROPERTY_TYPE,key:AC_PROPERTY_GENERAL}"
                                @AccessControlEvent="addToAccessControlCounter()">
                <template v-slot="props">
                    <app-input-control :error-object="validationErrorObject.property_type">
                        <template v-slot:input="data">

                            <label>{{$t('TYPE')}}</label>
                            <app-select :has-message="true" :message="getAcMessage(props)" :disabled="props.disabled" :state="data.state" mode="search"
                                        :search="searchPropertyType" text-field="label" :search-on-create="true"
                                        v-model="formData.property_type"></app-select>
                        </template>
                    </app-input-control>
                </template>
            </app-access-control>
            <b-col md="6" lg="4" xl="2" class="mb-4">

                <label>{{$t('YEAR_OF_CONSTRUCTION')}}</label>
                <b-form-input
                    name="year_of_construction"
                    type="number"
                    v-model="formData.year_of_construction">
                </b-form-input>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <form-header>{{$t('LOCATION')}}</form-header>
            </b-col>
        </b-row>


        <b-row>
            <app-access-control md="6" lg="4" class="mb-4"
                                :access_control_context="{function:C_PROPERTY_LOCATION,key:AC_PROPERTY_GENERAL}"
                                @AccessControlEvent="addToAccessControlCounter()">
                <template v-slot="props">

                    <app-input-control :error-object="validationErrorObject.location.address">
                        <template v-slot:input="data">
                            <b-tooltip v-if="props.messages.length"
                                       triggers="hover"
                                       :title="props.messages[0].message" :target="'property_address_'+C_PROPERTY_LOCATION">
                            </b-tooltip>
                            <label>{{$t('ADDRESS')}}</label>
                            <span :id="'property_address_'+C_PROPERTY_LOCATION" class="d-flex">
                            <b-form-input
                                :disabled="props.disabled"
                                :state="data.state"
                                type="text"
                                v-model="formData.location.address"></b-form-input>
                            </span>
                        </template>
                    </app-input-control>
                </template>
            </app-access-control>
            <app-access-control md="6" lg="4" class="mb-4"
                                :access_control_context="{function:C_PROPERTY_LOCATION,key:AC_PROPERTY_GENERAL}"
                                @AccessControlEvent="addToAccessControlCounter()">
                <template v-slot="props">
                    <app-input-control :error-object="validationErrorObject.location.address_no">
                        <template v-slot:input="data">
                            <b-tooltip v-if="props.messages.length"
                                       triggers="hover"
                                       :title="props.messages[0].message" :target="'street_number_'+C_PROPERTY_LOCATION">
                            </b-tooltip>
                            <label>{{$t('STREET_NUMBER')}}</label>
                            <span :id="'street_number_'+C_PROPERTY_LOCATION" class="d-flex">
                            <b-form-input
                                :disabled="props.disabled"
                                :state="data.state"
                                type="text"
                                v-model="formData.location.address_no"></b-form-input>
                            </span>
                        </template>
                    </app-input-control>
                </template>
            </app-access-control>
            <app-access-control md="6" lg="4" class="mb-4"
                                :access_control_context="{function:C_PROPERTY_LOCATION,key:AC_PROPERTY_GENERAL}"
                                @AccessControlEvent="addToAccessControlCounter()">
                <template v-slot="props">
                    <app-input-control :error-object="validationErrorObject.location.postal_number">
                        <template v-slot:input="data">
                            <b-tooltip v-if="props.messages.length"
                                       triggers="hover"
                                       :title="props.messages[0].message" :target="'zip_'+C_PROPERTY_LOCATION">
                            </b-tooltip>
                            <label>{{$t('ZIP')}}</label>
                            <span :id="'zip_'+C_PROPERTY_LOCATION" class="d-flex">
                            <b-form-input
                                :disabled="props.disabled"
                                :state="data.state"
                                type="text"
                                v-model="formData.location.postal_number"></b-form-input>
                            </span>
                        </template>
                    </app-input-control>
                </template>
            </app-access-control>
            <app-access-control md="6" lg="4" class="mb-4"
                                :access_control_context="{function:C_PROPERTY_LOCATION,key:AC_PROPERTY_GENERAL}"
                                @AccessControlEvent="addToAccessControlCounter()">
                <template v-slot="props">
                    <app-input-control :error-object="validationErrorObject.location.city">
                        <template v-slot:input="data">
                            <label>{{$t("CITY")}}</label>
                            <app-select :has-message="true" :message="getAcMessage(props)" :disabled="props.disabled" :state="data.state" mode="search"
                                        :search="searchCity" v-model="formData.location.city"
                                        :search-clear="true"></app-select>
                        </template>
                    </app-input-control>
                </template>
            </app-access-control>
            <app-access-control md="6" lg="4" class="mb-4"
                                :access_control_context="{function:C_PROPERTY_LOCATION,key:AC_PROPERTY_GENERAL}"
                                @AccessControlEvent="addToAccessControlCounter()">
                <template v-slot="props">
                    <app-input-control :error-object="validationErrorObject.location.county">
                        <template v-slot:input="data">
                            <label>{{$t("COUNTY")}}</label>
                            <b-input disabled :value="formData.location.county.name" :state="data.state"></b-input>
                        </template>
                    </app-input-control>
                </template>
            </app-access-control>
            <app-access-control md="6" lg="4" class="mb-4"
                                :access_control_context="{function:C_PROPERTY_LOCATION,key:AC_PROPERTY_GENERAL}"
                                @AccessControlEvent="addToAccessControlCounter()">
                <template v-slot="props">
                    <app-input-control :error-object="validationErrorObject.location.country">
                        <template v-slot:input="data">
                            <label>{{$t("COUNTRY")}}</label>
                            <b-input disabled :value="formData.location.country.name" :state="data.state"></b-input>
                        </template>
                    </app-input-control>
                </template>
            </app-access-control>

            <app-access-control md="6" lg="4" xl="2" class="mb-4"
                                :access_control_context="{function:C_PROPERTY_BASIC_MICROLOCATION_V,company:company_id,key:AC_PROPERTY_GENERAL}"
                                @AccessControlEvent="addToAccessControlCounter()">
                <label>{{$t("REGION")}}</label>
                <b-input disabled :value="formData.location.region.name"></b-input>
            </app-access-control>
            <app-access-control md="6" lg="4" xl="2" class="mb-4"
                                :access_control_context="{function:C_PROPERTY_BASIC_MICROLOCATION_V,company:company_id,key:AC_PROPERTY_GENERAL}"
                                @AccessControlEvent="addToAccessControlCounter()">
                <app-select :label="$t('MICROLOCATION')" :options="microlocation_options"
                            v-model="formData.location.microlocation.id"
                            :search-on-create="true"></app-select>
            </app-access-control>
        </b-row>
        <b-row>
            <b-col>
                <form-header>{{$t('GEOLOCATION')}}</form-header>
            </b-col>
        </b-row>

        <app-access-control tag="b-row"
                            :access_control_context="{function:C_PROPERTY_GEOLOCATION,company:company_id,key:AC_PROPERTY_GENERAL}"
                            @AccessControlEvent="addToAccessControlCounter()">
            <template v-slot="props">
                <b-col md="4" xl="2" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.location.latitude">
                        <template v-slot:input="data">
                            <b-tooltip v-if="props.messages.length"
                                       triggers="hover"
                                       :title="props.messages[0].message" :target="'latitude_'+C_PROPERTY_GEOLOCATION">
                            </b-tooltip>
                            <label>{{$t('LATITUDE')}}</label>
                            <span :id="'latitude_'+C_PROPERTY_GEOLOCATION" class="d-flex">
                            <b-form-input :disabled="props.disabled" :state="data.state"
                                          v-model="formData.location.latitude"></b-form-input>
                            </span>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="4" xl="2" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.location.longitude">
                        <template v-slot:input="data">
                            <b-tooltip v-if="props.messages.length"
                                       triggers="hover"
                                       :title="props.messages[0].message" :target="'longitude'+C_PROPERTY_GEOLOCATION">
                            </b-tooltip>
                            <label>{{$t('LONGITUDE')}}</label>
                            <span :id="'longitude'+C_PROPERTY_GEOLOCATION" class="d-flex">
                            <b-form-input :disabled="props.disabled"
                                          v-model="formData.location.longitude" :state="data.state"
                            ></b-form-input>
                            </span>
                        </template>
                    </app-input-control>
                </b-col>
            </template>
        </app-access-control>

        <b-row>
            <b-col class="mb-4">
                <g-map :lat="formData.location.latitude" :lng="formData.location.longitude"
                       @gmapLocationChange="updateFormLocation"></g-map>
            </b-col>
        </b-row>


        <b-row>
            <b-col md="6">
                <submit-button  :loading="saving" :c_permission="c_permission"></submit-button>
            </b-col>
            <b-col md="6">
                <app-access-control tag="div" @AccessControlEvent="addToAccessControlCounter()"
                                    :access_control_context="{function:C_PROPERTY_DELETE, key: AC_PROPERTY_GENERAL}">
                    <template v-slot:default="props">
                        <b-tooltip v-if="props.messages.length"
                                   class="mb-5"
                                   triggers="hover"
                                   :title="props.messages[0].message" :target="'key_'+property.id">
                        </b-tooltip>
                        <div>
                            <delete-button :id="'key_'+property.id" :disabled="props.disabled"
                                           :c_permission="C_PROPERTY_DELETE" class="pull-right"
                                           @click="deleteDialogShow=true"></delete-button>
                        </div>

                    </template>


                </app-access-control>

                <delete-confirm :show="deleteDialogShow" @confirm="deleteConfirm"
                                @cancel="deleteDialogShow=false" :delete_title="true"></delete-confirm>
            </b-col>
        </b-row>


    </b-form>
</template>

<script>
    import SubmitButton from '@/components/app/AppButton/AppButtonSubmit'
    import DeleteButton from '@/components/app/AppButton/AppButtonDelete'
    import DeleteConfirm from '@/components/app/form/AppConfirmationDialog'
    import ValidationDescription from '@/components/app/form/AppValidationDescription'
    import {
        C_PROPERTY_BASIC_E,
        C_PROPERTY_NAME,
        C_PROPERTY_TYPE,
        C_PROPERTY_UNIT_NUMBER,
        C_PROPERTY_CATEGORIZATION,
        C_PROPERTY_LOCATION,
        C_PROPERTY_GEOLOCATION
    } from "@/shared/component_permission";
    import FormHeader from "@/components/app/form/FormHeader";
    import {

        getCityMapping,
        getMicrolocations,
    } from '@/services/location/index'
    import GMap from '@/components/app/map/GoogleMap'
    import {toast, notifySuccess} from '@/shared/plugins/toastr'
    import {deleteProperty, getPropertyTypeList} from "@/services/property";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import routeNames from '@/router/routeNames'
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {cloneDeep} from "lodash";
    import AppInputControl from "@/components/app/AppInputControl";
    import {
        C_PROPERTY_BASIC_REGION_V,
        C_PROPERTY_BASIC_MICROLOCATION_V,
        C_PROPERTY_DELETE
    } from "@/shared/component_permission";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {AC_PROPERTY_GENERAL} from "@/mixins/AccessControl/AccessControlEnumeration";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {

        PROPERTY_DATA_NAME_VALIDATION_FAILED,
        PROPERTY_DATA_ADDRESS_VALIDATION_FAILED,
        PROPERTY_DATA_ADDRESS_NO_VALIDATON_FAILED,
        PROPERTY_DATA_LATITUDE_VALIDATION_FAILED,
        PROPERTY_DATA_LONGITUDE_VALIDATION_FAILED,
        PROPERTY_DATA_POSTAL_NUMBER_VALIDATION_FAILED,
        PROPERTY_DATA_CITY_VALIDATION_FAILED,
        PROPERTY_DATA_COUNTY_VALIDATION_FAILED,
        PROPERTY_DATA_COUNTRY_VALIDATION_FAILED,
        PROPERTY_DATA_CATEGORIZATION_VALIDATION_FAILED,
        PROPERTY_DATA_UNIT_NUMBER_VALIDATION_FAILED,
        PROPERTY_DATA_PROPERTY_TYPE_VALIDATION_FAILED,
        PROPERTY_DATA_INVALID_LATITUDE_VALIDATION_FAILED,
        PROPERTY_DATA_INVALID_LONGITUDE_VALIDATION_FAILED,
        PROPERTY_DATA_UNIT_NUMBER_NUMERIC_VALIDATION_FAILED


    } from "@/shared/error_codes";
    import AppButton from "@/components/app/AppButton/AppButton";

    export default {
        name: "PropertyGeneralForm",
        components: {
            AppButton,
            AppAccessControl,
            AppInputControl,
            AppSelect,
            FormHeader,
            SubmitButton,
            ValidationDescription,
            DeleteButton,
            DeleteConfirm,
            GMap
        },
        mixins: [getErrorMessage, AccessControlComponent],
        data() {
            return {
                c_permission: C_PROPERTY_BASIC_E,
                saving: false,
                deleteDialogShow: false,
                microlocation_options: [],
                categorizationOptions: [
                    {id: 1, name: '1 *'},
                    {id: 2, name: '2 *'},
                    {id: 3, name: '3 *'},
                    {id: 4, name: '4 *'},
                    {id: 5, name: '5 *'},
                ],
                formData: null,
                AC_PROPERTY_GENERAL,
                C_PROPERTY_BASIC_REGION_V,
                C_PROPERTY_BASIC_MICROLOCATION_V,
                C_PROPERTY_NAME,
                C_PROPERTY_TYPE,
                C_PROPERTY_DELETE,
                C_PROPERTY_CATEGORIZATION,
                C_PROPERTY_UNIT_NUMBER,
                C_PROPERTY_LOCATION,
                C_PROPERTY_GEOLOCATION,
                access_control_components: 14,
                access_control_fetch_key: AC_PROPERTY_GENERAL,
                validationErrorObject: {
                    name: PROPERTY_DATA_NAME_VALIDATION_FAILED,
                    categorization: PROPERTY_DATA_CATEGORIZATION_VALIDATION_FAILED,
                    property_type: PROPERTY_DATA_PROPERTY_TYPE_VALIDATION_FAILED,
                    unit_number: {
                        input: [PROPERTY_DATA_UNIT_NUMBER_VALIDATION_FAILED, PROPERTY_DATA_UNIT_NUMBER_NUMERIC_VALIDATION_FAILED]
                    },
                    location: {
                        address: PROPERTY_DATA_ADDRESS_VALIDATION_FAILED,
                        address_no: PROPERTY_DATA_ADDRESS_NO_VALIDATON_FAILED,
                        postal_number: PROPERTY_DATA_POSTAL_NUMBER_VALIDATION_FAILED,
                        city: PROPERTY_DATA_CITY_VALIDATION_FAILED,
                        county: PROPERTY_DATA_COUNTY_VALIDATION_FAILED,
                        country: PROPERTY_DATA_COUNTRY_VALIDATION_FAILED,
                        latitude: {
                            input: [PROPERTY_DATA_LATITUDE_VALIDATION_FAILED, PROPERTY_DATA_INVALID_LATITUDE_VALIDATION_FAILED]
                        },
                        longitude: {
                            input: [PROPERTY_DATA_LONGITUDE_VALIDATION_FAILED, PROPERTY_DATA_INVALID_LONGITUDE_VALIDATION_FAILED]
                        }
                    }
                }
            }
        },
        computed: {
            property() {
                return this.$store.getters['property/getProperty']
            },
            company_id() {
                return this.$store.getters['user/getCompany']
            },
        },
        methods: {
            populateFormData() {
                this.formData = cloneDeep({
                    name: this.$store.getters.getItem('property.basic.name'),
                    unit_number: this.$store.getters.getItem('property.basic.unit_number'),
                    categorization: this.$store.getters.getItem('property.basic.categorization'),
                    property_type: this.$store.getters.getItem('property.basic.property_type'),
                    year_of_construction: this.$store.getters.getItem('property.basic.year_of_construction'),
                    location: this.$store.getters.getItem('property.basic.location'),
                })
            },
            getAcMessage(props){
                return props.messages.length > 0 ? props.messages[0].message:''
            },
            save() {
                this.saving = true

                this.$store.dispatch('property/actionUpdatePropertyBasic', {
                    id: this.$route.params.id,
                    data: this.formData
                }).then(() => {
                        notifySuccess()
                        this.saving = false
                    }, () => {

                        this.saving = false
                    }
                )


            },
            updateFormLocation(data) {
                this.formData.location.latitude = data.latitude
                this.formData.location.longitude = data.longitude
                if (
                    (this.formData.location.address === '' || this.formData.location.address === null) &&
                    (this.formData.location.address_no === '' || this.formData.location.address_no === null) &&
                    (this.formData.location.postal_number === '' || this.formData.location.postal_number === null)
                ) {
                    this.formData.location.address = data.street
                    this.formData.location.address_no = data.street_number
                    this.formData.location.postal_number = data.postal_code
                }
            },
            deleteConfirm() {

                deleteProperty(this.$route.params.id)
                    .then(() => {
                            toast({
                                'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                                'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                                'type': 'success',
                                'timeout': 3000
                            })
                            this.$router.push({name: routeNames.RN_PROPERTY_LIST})
                        }, error => {
                            toast({
                                'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                                'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                                'type': 'error',
                                'timeout': 6000
                            })

                        }
                    ).then(() => {

                })
            },
            searchCity(value) {
                return getCityMapping({
                    name: value
                })
            },
            searchMicrolocation(city_id) {
                getMicrolocations({city: city_id}).then(response => {
                    this.microlocation_options = response.data
                }, () => {
                    this.microlocation_options = []
                })
            },
            searchPropertyType(value) {
                return getPropertyTypeList()
            }
        },
        watch: {
            'formData.location.city': {
                handler(value, oldValue) {

                    if (value && value.hasOwnProperty('id')) {

                        if (oldValue && oldValue.hasOwnProperty('id') && oldValue.id !== value.id) {
                            this.formData.location.microlocation.id = null
                        }
                        this.searchMicrolocation(value.id)
                        if (value.hasOwnProperty('country')) {
                            this.formData.location.country = value.country
                        }
                        if (value.hasOwnProperty('county')) {
                            this.formData.location.county = value.county
                        }
                        if (value.hasOwnProperty('region')) {
                            this.formData.location.region = value.region
                        }
                    }
                    if (value && value.hasOwnProperty('id') && !value.id) {
                        this.formData.location.county.id = null
                        this.formData.location.county.name = null
                        this.formData.location.country.id = null
                        this.formData.location.country.name = null
                        this.formData.location.region.id = null
                        this.formData.location.region.name = null
                    }
                },
                deep: true,
                immediate: true
            },

        },
        created() {
            if (this.property.hasOwnProperty('id')) {
                this.populateFormData()
            } else {
                this.$store.dispatch('property/actionSetProperty', this.$route.params.id).then(() => {
                    this.populateFormData()
                })
            }
            this.access_control_general_context = {
                property: this.$route.params.id,
                company: this.$store.getters['user/getCompany']
            }
        },
    }
</script>

<style scoped>
    .disable-tooltip-name {
        pointer-events: none;
        font-size: 22px;
        padding: 10px;
    }
</style>
