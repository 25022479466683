import http from "@/services/http";
import {DB_WEBSITE_URL, TAG_URL, TAG_URL_STRING} from "@/services/endpoints";

export  function getTagMapping(params){
    return http.request({
        url: '/adminapi/tag/mapping-list',
        method: 'get',
        params
    })
}

export function getTag(id,params) {
    return http.request({
        url: TAG_URL(id) ,
        method: 'get',
        params
    })
}

export function getAllTags(id,params) {
    return http.request({
        url: TAG_URL(id) + '/list',
        method: 'get',
        params
    })
}

export function getTagsPaginated(params) {
    return http.request({
        url: '/adminapi/tag/paginate-list',
        method: 'get',
        params
    })
}

export function saveDestinationTags(data) {
    return http.request({
        url: '/adminapi/tag/destination',
        method: 'post',
        data: data
    })
}

export function saveCustomTags(data) {
    return http.request({
        url: '/adminapi/tag/custom',
        method: 'post',
        data: data
    })
}

export function updateTag(id,data) {
    return http.request({
        url: '/adminapi/tag/' + id,
        method: 'put',
        data: data
    })
}

export function deleteTag(id) {
    return http.request({
        url: '/adminapi/tag/' + id,
        method: 'delete'
    })
}

export function getExistingTags(object_type_id, entity_id) {
    return http.request({
        url: TAG_URL(object_type_id) + '/' + entity_id,
        method: 'get',
    })
}

export function getExistingTagsList(object_type_id, data) {
    return http.request({
        url: TAG_URL(object_type_id) + '/entity-list',
        method: 'post',
        data: data,
    })
}

export function updateExistingTagsList(object_type_id, entityIdList) {
    return http.request({
        url: TAG_URL(object_type_id) + '/entity-list',
        method: 'put',
        data: {list: entityIdList}
    })
}

export function createEntityTags(object_type_id, entity_id, tagIdList) {
    return http.request({
        url: TAG_URL(object_type_id) + '/' + entity_id,
        method: 'post',
        data: {list: tagIdList}
    })
}

export function createEntityTagsList(object_type_id, tag_id, entityIdList,){
    return http.request({
        url: TAG_URL(object_type_id) + '/' + tag_id + '/entity-list',
        method: 'post',
        data: {list: entityIdList}
    })
}

export function deleteEntityTags(object_type_id, entity_id, tagIdList) {
    return http.request({
        url: TAG_URL(object_type_id) + '/' + entity_id + '/single',
        method: 'delete',
        data: {list: tagIdList}
    })
}
export function bulkSaveEntityTags(object_type_id, data){
    return http.request({
        url: TAG_URL(object_type_id),
        method: 'post',
        data
    })
}
export function bulkDeleteEntityTags(object_type_id, data){
    return http.request({
        url: TAG_URL(object_type_id),
        method: 'delete',
        data
    })
}

export function deleteEntityTagsList(object_type_id, tag_id, entityList) {
    return http.request({
        url: TAG_URL(object_type_id) + '/' + tag_id + '/delete-list',
        method: 'delete',
        data: {list: entityList}
    })
}

export function overrideTagForEntityList(object_type_id, tag_id, entityList) {
    return http.request({
        url: TAG_URL(object_type_id) + '/' + tag_id + '/override-tag',
        method: 'post',
        data: {entity_id: entityList}
    })
}

export function removeTagsForEntityList(object_type_id, tag_type_id, entityList) {
    return http.request({
        url: TAG_URL(object_type_id) + '/' + tag_type_id + '/remove-tags',
        method: 'delete',
        data: {entity_id: entityList}
    })
}

export function saveTagsForPropertyDestination(params){
    return http.request({
        url: TAG_URL_STRING + '/destination-property',
        method: 'post',
        params: params
    })
}

export function createAndMapDestinationTags(params) {
    return http.request({
        url: TAG_URL_STRING + '/create-map-destinations',
        method: "post",
        params: params
    })
}

export function getTagType() {
    return http.request({
        url: '/adminapi/tag/type',
        method: 'get'
    })
}


