<template>
    <b-card class="property-wrapper">
        <template slot="header">

            <h2 class="property-wrapper-padding">{{$t('CHANNEL_MANAGER')}}</h2>

            <template v-if="isCmSetupRoute">
                <span class="header-button-list">
                    <app-button class="pull-right"
                                button_type="new"
                                :v_show="checkPermission(INTEGRATIONS_CM_DISTRIBUTION_SETUP_HOMEAWAY_STANDARD_NEW)"
                                :access_control_context="{function: INTEGRATIONS_CM_DISTRIBUTION_SETUP_HOMEAWAY_STANDARD_NEW, key: access_control_fetch_key}"
                                @AccessControlEvent="addToAccessControlCounter()"
                                @click="EventBusNotify">
                        {{ $t('NEW_DISTRIBUTION') }}
                    </app-button>
                </span>
            </template>

            <navigation  :nav-elements="list" ></navigation>
        </template>
        <page-transition transition-type="slide">
            <router-view></router-view>
        </page-transition>
    </b-card>
</template>

<script>
    import PageTransition from '@/components/app/transition/AppTransition'
    import routeNames from '@/router/routeNames'
    import Navigation from "@/components/app/navigation/Navigation";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {EventBus, GE_OPEN_HOMEAWAY_4_2_DISTRIBUTION_CREATION_WIZARD} from "@/shared/EventBus";
    import {INTEGRATIONS_CM_DISTRIBUTION_SETUP_HOMEAWAY_STANDARD_NEW} from "@/shared/component_permission";
    import {AC_DISTRIBUTION} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";

    export default {
        name: "Index",
        components: {AppButton, Navigation,PageTransition},
        mixins: [AccessControlComponent],
        data() {
            return {
                distributions:[],
                list: [
                    {   label:this.$t('DASHBOARD'),
                        route: {name: routeNames.RN_CHANNEL_MANAGER_DASHBOARD},
                    },
                    {
                        label:this.$t('LOG'),
                        route: {name: routeNames.RN_CHANNEL_MANAGER_LOG},
                    },
                    {
                        label:this.$t('SETUP'),
                        route: {name: routeNames.RN_CHANNEL_MANAGER_SETUP},
                    },
                ],
                INTEGRATIONS_CM_DISTRIBUTION_SETUP_HOMEAWAY_STANDARD_NEW,
                access_control_components: 1,
                access_control_fetch_key: AC_DISTRIBUTION,
            }
        },
        computed:{

            backButtonRoute() {
                return {name: routeNames.RN_CHANNEL_MANAGER_DASHBOARD}
            },
            getCompanyId() {
                return this.$store.getters['user/getCompany']
            },
            isCmSetupRoute() {
                return this.$route.name === routeNames.RN_CHANNEL_MANAGER_SETUP
            }
        },
        methods: {
            EventBusNotify() {
                EventBus.$emit(GE_OPEN_HOMEAWAY_4_2_DISTRIBUTION_CREATION_WIZARD)
            }
        },
        created(){
            this.$store.dispatch('channel_manager/actionSetFilterDistributionList', {company: Number(this.getCompanyId)}).then(() => {
                let distributionList = this.$store.getters['channel_manager/getDistributionList']
                this.distributions = []
                if (distributionList.length > 0) {
                    for (let distributionId of distributionList) {
                        let distribution = this.$store.getters['channel_manager/getDistributionById'](distributionId)
                        this.distributions.push(distribution)
                    }
                }
            })

            this.access_control_general_context = {
                company: this.getCompanyId,
            }
        }
    }
</script>

<style scoped>

</style>
