<template>
    <div>
        <app-table-v2 :fields="computedFields"
                      :provider="getData"
                      @busyToggled="toggleBusy"
                      :limit-list="[10, 20, 50]"
                      :paginated="paginate"
                      :showSearchMessages="showSearchMessages"
                      :search-on-create="searchOnCreate"
                      :selectable="selectable"
                      :search-event="EVENT_TABLE_SEARCH"
                      :loading="loading">
            <template v-slot:contact_channel="data">
                <div v-if="data.item.contact_channel">
                    {{ data.item.contact_channel.label }}
                </div>
            </template>

            <template v-slot:property="data">
                <b-link v-if="data.item.property"
                        :to="{name: routes.RN_PROPERTY_EVENT_TEMPLATES, params:{id: data.item.property.id}}">
                    {{ data.item.property.label }}
                </b-link>
            </template>

            <template v-slot:event="data">
                <div v-if="data.item.event">
                    {{ data.item.event.label }}
                </div>
            </template>

            <template v-slot:recipient_type="data">
                <div v-if="data.item.recipient_type">
                    {{ data.item.recipient_type.label }}
                </div>
            </template>

            <template v-for="(type, index) in ['active_for', 'inactive_for']" v-slot:[type]="data">
                <span :id="`${data.item.id}_${type}`" class="cursor-pointer">
                    {{ data.item[type].length }}
                </span>

                <b-popover :target="`${data.item.id}_${type}`" placement="top" triggers="hover">
                    <div class="ws-no-wrap" :key="`${index}-${itemIndex}`" v-for="(item, itemIndex) in data.item[type]">
                        {{ item.label }} ({{ $t(item.type.toUpperCase()) }})
                    </div>
                </b-popover>
            </template>

            <template v-slot:send_points="data">
                <span :id="`${data.item.id}_send_points`" style="cursor: pointer"> {{
                        data.item.send_points.length
                    }} </span>
                <b-popover :target="`${data.item.id}_send_points`" placement="top" triggers="hover">
                    <div :key="itemIndex"
                         v-for="(item, itemIndex) in data.item.send_points">
                        {{ formatSendPointLabel(item) }}
                    </div>
                </b-popover>
            </template>

            <template v-slot:active="data">
                <template v-if="data.item.permissions && data.item.permissions.activate">
                    <b-checkbox
                        v-model="data.item.active"
                        switch
                        @click.native.prevent="openActiveStateModal(data)">
                    </b-checkbox>
                </template>

                <template v-else>
                    <span v-if="data.item.active === null">N/A</span>
                    <i v-else-if="data.item.active" class="fa fa-check text-success" aria-hidden="true"></i>
                    <i v-else class="fa fa-ban text-danger" aria-hidden="true"></i>
                </template>
            </template>

            <template v-slot:action="data">
                <app-button
                    :show_text="false"
                    variant="link"
                    icon="preview"
                    @click="$emit('preview', data.item)">
                </app-button>
                <app-button
                    v-if="data.item.property && !data.item.linked_to"
                    :show_text="false"
                    variant="link"
                    icon="compress"
                    @click="linkTemplate(data)">
                </app-button>
                <app-button
                    v-if="data.item.property && data.item.linked_to"
                    :show_text="false"
                    variant="link"
                    icon="expand"
                    @click="unlinkTemplate(data)">
                </app-button>
                <template v-if="data.item.permissions">
                    <app-button
                        v-if="data.item.permissions.edit" :show_text="false" variant="link" icon="edit"
                        @click="$emit('edit', data.item)">
                    </app-button>
                </template>
            </template>
        </app-table-v2>
        <app-confirmation-dialog
            :show="showActivateModal"
            @confirm="toggleState"
            @cancel="showActivateModal = false"
            :title="$t('TEMPLATE')" v-model="showActivateModal"> {{ $t('TEMPLATE_MODAL_ACTIVE_STATE_CONFIRM') }}
        </app-confirmation-dialog>
    </div>
</template>

<script>
import {EventBus} from "@/shared/EventBus";
import {getEventTemplates, setEventActiveStatus} from "@/services/event";
import routes from '@/router/routeNames'
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {EVENT_SEND_POINT_TYPE_ON_EVENT} from "@/shared/constants";
import moment from 'moment';

export default {
    name: "EventTemplateTable",
    components: {
        "AppConfirmationDialog": () => import("@/components/app/form/AppConfirmationDialog"),
        "PreviewTemplateSidebar": () => import("@/components/event/PreviewTemplateSidebar"),
        "AppTableV2": () => import("@/components/app/AppTableV2"),
        "AppButton": () => import("@/components/app/AppButton/AppButton")
    },
    mixins: [getErrorMessage],
    props: {
        filter: {
            type: Object
        },
        items: {
            type: Array,
            default: null
        },
        paginate: {
            type: Boolean,
            default: true
        },
        omittedFields: {
            type: Array,
            default: () => {
                return []
            }
        },
        showSearchMessages: {
            type: Boolean,
            default: true
        },
        searchOnCreate: {
            type: Boolean,
            default: false
        },
        selectable: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            routes: routes,
            tableFields: [
                {key: 'id', label: this.$i18n.t('ID')},
                {key: 'event', label: this.$i18n.t('EVENT')},
                {key: 'contact_channel', label: this.$i18n.t('CHANNEL')},
                {key: 'label', label: this.$i18n.t('NAME')},
                {key: 'recipient_type', label: this.$i18n.t('RECIPIENT')},
                {key: 'property', label: this.$i18n.t('PROPERTY')},
                {key: 'active', label: this.$i18n.t('ACTIVE')},
                {key: 'active_for', label: this.$i18n.t('ACTIVE_FOR')},
                {key: 'inactive_for', label: this.$i18n.t('INACTIVE_FOR')},
                {key: 'send_points', label: this.$i18n.t('SCHEDULED_FOR')},
                {key: 'action', label: "", class: 'text-right'},
            ],
            EVENT_TABLE_SEARCH: 'getPropertyEventsData',
            item: false,
            showActivateModal: false,
            loadingActive: false
        }
    },
    methods: {
        getData(filter) {
            if (this.items !== null) {
                if (this.paginate) {
                    return Promise.resolve({items: this.items})
                } else {
                    return Promise.resolve({data: this.items})
                }
            } else {
                let req = {
                    ...this.filter,
                    ...{
                        page: filter.currentPage,
                        perPage: filter.perPage,
                    }
                }
                return getEventTemplates(req)
            }
        },
        toggleBusy($event) {
            this.$emit('busyToggled', $event)
        },
        linkTemplate(data) {
            this.$emit('linkEvent', data.item)
        },
        unlinkTemplate(data) {
            this.$emit('unlinkEvent', data.item)
        },
        openActiveStateModal(data) {
            this.showActivateModal = true
            this.item = data.item
        },
        toggleState() {
            this.loadingActive = true

            setEventActiveStatus(this.item.id, {active: !this.item.active ? 1 : 0}).then(() => {
                this.item.active = !this.item.active
                notifySuccess()
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.showActivateModal = false
                this.loadingActive = false
            })
        },
        formatSendPointLabel({type, days, hour, minute, condition}) {
            if (type.id === EVENT_SEND_POINT_TYPE_ON_EVENT) {
                return type.label
            } else {
                const time = moment().set({hour, minute}).format('LT')
                let label = `${days} ${this.$tc('COUNTABLE.DAY', days)} ${type.label} ${this.$t('AT')} ${time}`.toLowerCase()

                if (condition && condition.label) {
                    label += " - " + condition.label
                }
                return label
            }
        },
    },
    computed: {
        computedFields() {
            return this.tableFields.filter(el => !this.omittedFields.includes(el.key))
        }
    },
    watch: {
        filter: {
            handler() {
                EventBus.$emit(this.EVENT_TABLE_SEARCH)
            },
            deep: true
        },
        items: {
            handler() {
                EventBus.$emit(this.EVENT_TABLE_SEARCH)
            },
            deep: true
        }
    },
    created() {
    }
}
</script>

<style scoped>

</style>
