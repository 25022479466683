<template>
    <div>
        <b-form :key="'form_'+formKey" @submit.prevent="activateCashRegister">
            <app-access-control :access_control_context="{function: C_SETUP_FINANCE_ACTIVATION_REGISTER_SETUP_E, company: company.id, key: AC_SYSTEM_SETUP_FINANCE}"
                                @AccessControlEvent="addToAccessControlCounter()"
                                tag="span">
                <template v-slot:default="props">
                    <app-no-data :heading="$t('MODULE_NOT_ACTIVE')" :tip="message" :show-tip="true"  v-if="!company.country.id || company.country.id === 189">

                    </app-no-data>
                    <app-button-submit  v-else  :loading="loading">{{$t('ACTIVATE')}}</app-button-submit>

                </template>
            </app-access-control>


        </b-form>
    </div>
</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {createRegisterSetup} from "@/services/finance/setup";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {GE_SYSTEM_FINANCE_SETTINGS, EventBus} from "@/shared/EventBus";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {C_SETUP_FINANCE_ACTIVATION_REGISTER_SETUP_E} from "@/shared/component_permission";
    import {AC_SYSTEM_SETUP_FINANCE} from "@/mixins/AccessControl/AccessControlEnumeration";
    import AppNoData from "@/components/app/AppNoData";
    import routeNames from "@/router/routeNames"

    export default {
        name: "Index",
        components: {AppNoData, AppAccessControl, AppButtonSubmit},
        mixins:[getErrorMessage,AccessControlComponent],
        data(){
            return {
                C_SETUP_FINANCE_ACTIVATION_REGISTER_SETUP_E,
                loading:false,
                access_control_components:1,
                access_control_fetch_key:AC_SYSTEM_SETUP_FINANCE,
                AC_SYSTEM_SETUP_FINANCE,
                show:true,
                formKey:1
            }
        },
        methods:{
            activateCashRegister(){

                this.loading = true
                createRegisterSetup(this.company.id).then(()=>{

                    this.$router.push({
                        name: routeNames.RN_SETUP_GENERAL,

                    })
                    notifySuccess()
                    EventBus.$emit(GE_SYSTEM_FINANCE_SETTINGS)
                    this.show = false
                }).catch(error => {

                    this.showErrorMessages(error)
                }).finally(() => this.loading = false )
            }
        },
        computed:{

            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            targetTooltip(){
              return   'activate_cr_key_'+this.company.id
            },
            message(){
              if(!this.company.country.id){
                  return this.$t('MODULE_NEED_COUNTRY')
              } else if(this.company.country.id === 189){
                  return this.$t('MODULE_NOT_AVAILABLE_FOR_COUNTRY')
              }
            }
        }
    }
</script>

<style scoped>

</style>
