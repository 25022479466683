<template>
    <b-form @submit.prevent="save" v-if="objectIds">
        <b-row class="mt-3 mb-3">
            <b-col>
                <app-select :options="websites"
                            text-field="website"
                            :search-empty-item="false"
                            v-model="selectedWebsite"
                ></app-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button @click="save" :loading="loader">{{$t('SAVE')}}</app-button>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import {getWebsiteList} from "@/services/direct_booking/website";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButton from "@/components/app/AppButton/AppButton";
import {createPromoCodeFilter} from "@/services/promo_codes";
import {PROMO_CODE_WEBSITE} from "@/shared/constants";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {EventBus} from "@/shared/EventBus";

export default {
    name: "PromoCodeApplyOnWebsite",
    components:{AppSelect, AppButton},
    mixins:[getErrorMessage],
    props:{
        objectIds:{
            type: Array,
            default: null,
        }
    },
    data(){
        return{
            websites:[],
            selectedWebsite: [],
            loader: false,
            PROMO_CODE_WEBSITE
        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    methods:{
        save(){
            this.loader = true
            createPromoCodeFilter({promo_id: this.objectIds, parameter_id: PROMO_CODE_WEBSITE, object_id: this.selectedWebsite }).then(response => {
                notifySuccess()
                this.loader = false
                EventBus.$emit('reloadPromoCodes')
                this.$emit('websiteApplied')
            }, error => {
                this.loader = false
                this.showErrorMessages(error)
            })
        }
    },
    created(){
        getWebsiteList({company: this.company.id}).then(response => {
            this.websites = response.data.items
        })
    }
}
</script>

<style scoped>

</style>
