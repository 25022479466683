<template>
    <i  v-if="indicator_type" class="fa fa-circle m-0" :class="indicatorClass"></i>
</template>

<script>
    export default {
        name: "AppIndicator",
        props:{
            indicator_type:{
                type:String
            }
        },
        computed:{
            indicatorClass(){
                if(this.indicator_type ==="indicator-primary"){
                    return 'system-indicator-primary'
                }
                if(this.indicator_type ==="indicator-warning"){
                    return 'system-indicator-warning'
                }
                if(this.indicator_type === 'indicator-danger'){
                    return  'system-indicator-danger'
                }
                if(this.indicator_type === 'indicator-res-canceled'){
                    return 'system-indicator-res-canceled'
                }
                if(this.indicator_type === 'indicator-warning-yellow'){
                    return 'system-indicator-warning-yellow'
                }
            }
        }
    }
</script>

<style scoped>

</style>
