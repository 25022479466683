<template>
    <div
            class="main_calendar_test__holder--content-scroll sticky-top"
            ref="calendar_scroll_container"

            @scroll="onScroll"
    >
        <div
                :class="{'main_calendar_test-br_green': Number(date.format('D')) === date.daysInMonth()}"
                v-for="date in dateList">
        </div>
    </div>
</template>

<script>
import {EventBus, GE_CALENDAR_SCROLL_POSITION, GE_CALENDAR_SHOW_MORE} from "@/shared/EventBus";
    import {CalendarRowMixin} from "@/mixins/calendar/CalendarRowMixin";
    import {debounce} from 'lodash'

    export default {
        name: "TableScrollRow",
        mixins: [CalendarRowMixin],
        props: {
            dateList: {
                type: Array
            },
            scrollLeftPosition: {
                type: Number
            }
        },
        data(){
          return {
              calculatedPosition:null
          }
        },/*
        computed:{
            lastIndexDate(){
                return this.dates.length >  0 ? this.dates.length - 1 : null
            },
            pixelsWide() {
                return 2.87 * 16
            },
            startingPosition() {
                return this.pixelsWide * this.lastIndexDate
            },

        },*/
        methods: {
            showMoreHandler() {

                if (this.$refs.calendar_scroll_container) {
                    if (this.$refs.calendar_scroll_container.scrollWidth === this.$refs.calendar_scroll_container.clientWidth) {
                            EventBus.$emit(GE_CALENDAR_SHOW_MORE, true)
                            return
                    }

                    if (this.$refs.calendar_scroll_container.scrollLeft === (this.$refs.calendar_scroll_container.scrollWidth - this.$refs.calendar_scroll_container.clientWidth)) {

                            EventBus.$emit(GE_CALENDAR_SHOW_MORE, true)
                            return
                    }
                }

                EventBus.$emit(GE_CALENDAR_SHOW_MORE, false)
            },
            onScroll($event) {
                const _this = this;
                _this.event = $event
                EventBus.$emit(GE_CALENDAR_SCROLL_POSITION, {position: this.event.target.scrollLeft})
                this.showMoreHandler()
       /*         debounce(function() {
                    EventBus.$emit(GE_CALENDAR_SCROLL_POSITION, {position: this.event.target.scrollLeft})
                //    this.showMoreHandler()
                }, 30).call(_this);*/

            }
        },
        mounted() {
          //  this.showMoreHandler()
        }
    }
</script>

<style scoped>

</style>
