<template>
    <div v-cloak class="search_message mt-5">

        <b-img class="search_init_icon" src="/img/icons/document_icon.png"></b-img>
        <h4 class="text-primary font-weight-light">{{h3}}</h4>
        <h6 class="font-weight-light mt-2" v-if="showTip">{{h6}}</h6>
        <div :class="showTip ? 'slot-content' : 'mt-2'">
            <slot></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: "AppNoData",
        props: {
            icon: {
                type: String,
                default: 'info'
            },

            heading: {
                type: String,
                default: null
            },
            tip: {
                type: String,
                default: null
            },
            showTip: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            h3() {
                return this.heading !== null ? this.heading : this.$t('NO_SEARCH_RESULTS')
            },
            h6() {
                return this.tip !== null ? this.tip : this.$t('NO_RESULTS_FOUND_TIP')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .slot-content{
        margin-top: 2.2rem;
    }
    .search_message{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        & img {
            height: auto;
            width: 12rem;
            margin-bottom: 2rem;
            margin-top: 2rem;
        }
    }

</style>
