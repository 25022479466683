<template>
    <div>
        <property-preview-gallery
            :tag_type="tag_type"
            :tag_permission_v="C_PROPERTY_GALLERY_TAG_V"
            :object_type="object_type"
            :c_permission_e="c_permission_e"
            :ac_key="AC_PROPERTY_GALLERY"
            :ac_key_a="AC_PROPERTY_GALLERY_A"
            :c_permission_v="c_permission_v"
            :c_permission_active="c_permission_active"
            :object-id="objectId"
            :object-name="objectName"
            :document-type-id="documentTypeId"
            document-type=".png,.jpg,.jpeg"></property-preview-gallery>
    </div>
</template>

<script>
    import PropertyPreviewGallery from '@/components/app/gallery/GalleryProperty'
    import {TYPE_PROPERTY_PHOTO} from '@/shared/constants'
    import {C_PROPERTY_GALLERY_V, C_PROPERTY_GALLERY_E, C_PROPERTY_GALLERY_TAG_V, C_PROPERTY_GALLERY_ACTIVE} from "@/shared/component_permission";
    import {TAG_TYPE_IMAGE} from "@/shared/constants";
    import {OBJECT_TYPE_DOCUMENT} from "@/shared/constants";
    import {AC_PROPERTY_GALLERY, AC_PROPERTY_GALLERY_A} from "@/mixins/AccessControl/AccessControlEnumeration";

    export default {
        name: "Index",
        components: {
            PropertyPreviewGallery
        },
        data() {
            return {
                documentTypeId: TYPE_PROPERTY_PHOTO,
                c_permission_v: C_PROPERTY_GALLERY_V,
                c_permission_e: C_PROPERTY_GALLERY_E,
                c_permission_active: C_PROPERTY_GALLERY_ACTIVE,
                C_PROPERTY_GALLERY_TAG_V,
                tag_type: TAG_TYPE_IMAGE,
                object_type: OBJECT_TYPE_DOCUMENT,
                AC_PROPERTY_GALLERY,
                AC_PROPERTY_GALLERY_A
            }
        },
        computed: {
            objectId() {
                return Number(this.$route.params.id)
            },
            objectName() {
                return this.$t("PROPERTY_GALLERY_OBJECT_NAME", {value: this.$store.getters.getItem('property.basic.name')})
            }
        }
    }
</script>

<style scoped>

</style>
