<template>
    <b-form class="filter" @submit.prevent="searchAdvanced">
        <b-row>
            <template v-if="cities_count > 1">
                <b-col lg="12" class="mb-3" v-if="preloadCityList">
                    <label>{{$t("CITY")}}</label>
                    <app-select mode="multiselect" :options="cityList"  v-model="city" ></app-select>
                </b-col>
                <b-col v-else lg="12" class="mb-3" >
                    <label>{{$t("CITY")}}</label>
                    <app-select mode="search" :search="searchCity" v-model="city" :search-clear="true"></app-select>
                </b-col>
            </template>
            <b-col lg="12" class="mb-3" v-if="property_count > 1">
                <label>{{$t('PROPERTY')}}</label>

                <app-select v-if="preloadPropertyList || (propertyList.length > 0 && propertyList.length <= DEFAULT_PAGINATION_LIMIT)" mode="multiselect" :options="propertyList" set-first
                            v-model="property" ></app-select>
                <app-select v-else  mode="search" :search="searchProperty" v-model="property"></app-select>

            </b-col>
            <b-col   lg="12" class="mb-4"   v-if="destinationTagOptions.length > 0">
                <label>{{ $t('DESTINATION_TAG') }}</label>
                <app-select value-field="id" text-field="label" v-model="destination_tags" :options="destinationTagOptions" mode="multiselect"></app-select>
            </b-col>
            <b-col v-if="customTagOptions.length > 0"   lg="12" class="mb-4">
                <label>{{ $t('CUSTOM_TAG') }}</label>
                <app-select value-field="id" text-field="label" v-model="custom_tags" :options="customTagOptions" mode="multiselect"></app-select>
            </b-col>

            <b-col cols="12"  class="mb-4">
                <label>{{$t('PERIOD')}}</label>
                <app-date-range-picker :min-date="new Date()" v-model="period"></app-date-range-picker>

            </b-col>

            <b-col cols="12"  class="mb-4">
                <label>{{$t('ADULTS')}}</label>
               <app-number-input :controls="true" :min="1" :nullable="false" v-model="guest_adults"></app-number-input>
            </b-col>
            <b-col cols="12" class="mb-4">
                <label>{{$t('CHILDREN')}}</label>
                <app-number-input :controls="true" :min="0" :nullable="false" v-model="guest_children"></app-number-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-checkbox
                    inline
                    id="ignore_restrictions"
                    v-model="ignore_restrictions"
                    name="ignore_restrictions"
                    :value="true"
                    :unchecked-value="false">
                    {{ $t('IGNORE_RESTRICTIONS') }}
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button-submit :loading="loader" :disabled="disabledSearch" class="filter-submit-wrapper" :inline="true"
                                                  variant="primary"
                                                  button_type="search">{{ $t("SEARCH") }}
                </app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
import AppNumberInput from "@/components/app/form/AppNumberInput";

import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {getCityList} from "@/services/location";
import AppAccessControl from "@/components/app/AppAccessControl";
import {AC_MAIN_CALENDAR} from "@/mixins/AccessControl/AccessControlEnumeration";
import {MAIN_CALENDAR_FILTER_CITY,MAIN_CALENDAR_FILTER_PREDEFINED_CITY} from "@/shared/component_permission";
import {DEFAULT_PAGINATION_LIMIT, LS_PRESETS} from "@/shared/constants";
import {getPropertyList} from "@/services/property";
import {getPresets} from "@/services/user";

export default {
    name: "AdvancedFilter",
    props:{
      loader:{
          type:Boolean,
          default:false
      },
        customTagOptions :{
          type:Array
        },
        destinationTagOptions:{
          type:Array
        }
    },
    mixins:[AccessControlComponent],
    components: {AppAccessControl, AppSelect, AppDatePicker, AppNumberInput, AppDateRangePicker, AppButtonSubmit},
    data() {
        return {
            preloadPropertyList:false,
            preloadCityList:false,
            propertyList: [],
            DEFAULT_PAGINATION_LIMIT,
            MAIN_CALENDAR_FILTER_CITY,
            MAIN_CALENDAR_FILTER_PREDEFINED_CITY,
            AC_MAIN_CALENDAR,
            guest_adults: 1,
            guest_children:0,
            ignore_restrictions: false,
            city:null,
            property:null,
            period: {
                start: null,
                end: null
            },
            // access_control_fetch_key: AC_MAIN_CALENDAR,
            // access_control_components: 2,
            cityList:[],
            destination_tags:[],
            custom_tags:[],
            cities_count:0,
            property_count:0
        }
    },
    computed:{
      disabledSearch(){
          return this.period.start === null || this.period.end == null
      },
      company() {
            return this.$store.getters['user/getCurrentCompany']
      },
    },
    methods:{
        searchProperty(value){
            let request = {name:value, company_id:this.company_id}
            if(this.city !== null){
                const city =  typeof  this.city  === 'object' && this.city.hasOwnProperty('id') ? this.city.id : this.city
                request = {...request,...{city}}
            }
            return getPropertyList(request).then(response => {
                return {data:response.data.items}
            })
        },
        getCities(){
            getCityList({
                user_cities: true,
            }).then(response=>{
                this.cityList = response.data
            })
        },
        searchCity(value) {
            return getCityList({
                name: value,
            })
        },
        searchAdvanced(){
            let req = {
                adults:this.guest_adults +'+',
                children:this.guest_children ? this.guest_children +'+' : null,
                available:true,
                ignore_restrictions:this.ignore_restrictions,
                check_in:this.period.start,
                check_out:this.period.end,
                company: this.company.id
            }
            if(this.property !== null){
                req = {...req,...{property:typeof this.property === 'object' && this.property.hasOwnProperty('id') ? this.property.id : this.property}}
            }
            if(this.city !== null){
                const city = typeof this.city === 'object' &&  this.city.hasOwnProperty('id') ? this.city.id :  this.city
                req = {...req,...{city}}
            }
            if(this.destination_tags.length > 0){
                req = {...req,...{destination:this.destination_tags}}
            }
            if(this.custom_tags.length > 0){
                req = {...req,...{custom_tag:this.custom_tags}}
            }
            this.$emit('advancedSearch', req)
        }
    },
    watch:{
        city:{
            handler(value){

                let city = null
                if(typeof value === 'object' && value.hasOwnProperty('id')){
                    city = value.id
                } else {
                    city = value.length > 0 ? value : null
                }
                let req = { company_id:this.company.id}
                if(city){
                    req = {...req,...{city}}
                }
                getPropertyList(req).then(response => {
                    this.$nextTick(()=>{
                        const data = response.data &&  response.data.hasOwnProperty('items') ? response.data.items : []
                        if(data.length <= DEFAULT_PAGINATION_LIMIT){

                            this.propertyList = data.map(el=>{
                                return {id:el.id,name:el.name}
                            })
                        } else {
                            this.propertyList = []
                        }
                    })
                })
                }
            }
    },
    created() {
        let storagePresets = localStorage.getItem(LS_PRESETS)
        if (storagePresets) {
            storagePresets = JSON.parse(storagePresets)
        } else {
            getPresets().then(response => {
                localStorage.setItem(LS_PRESETS, JSON.stringify(response.data))
                storagePresets = localStorage.getItem(LS_PRESETS)

            })
        }
        if(storagePresets && typeof storagePresets === 'object'){
            if(storagePresets.hasOwnProperty('properties')){
                this.property_count = storagePresets.properties
                if (this.property_count === 0) {
                    this.empty_initial_list = true
                }
                if (this.property_count === 1) {
                    this.showPropertyItem = false
                }
                if(this.property_count <= DEFAULT_PAGINATION_LIMIT){
                    this.property = null
                    this.preloadPropertyList = true

                    getPropertyList({ company_id:this.company.id}).then(response => {
                        this.$nextTick(()=>{
                            const data = response.data &&  response.data.hasOwnProperty('items') ? response.data.items : []
                            this.propertyList = data.map(el=>{
                                return {id:el.id,name:el.name}
                            })
                        })

                    })
                }
            }

            if (storagePresets.hasOwnProperty('cities')) {
                this.cities_count = storagePresets.cities
                if (storagePresets.cities <= DEFAULT_PAGINATION_LIMIT) {
                    this.getCities()
                    this.preloadCityList = true
                }
            }
        }
        this.getCities()
    }

}
</script>

<style scoped>

</style>
