<template>
    <form @submit.prevent="search" @reset.prevent="reset" class="filter">
        <b-row class="align-items-end">
            <b-col md="2" class="mb-3">
                <label>{{$t('VALIDATION')}}</label>
                <app-select
                    v-model="filter.validation_list"
                    :options="validationListOptions">
                </app-select>
            </b-col>

            <b-col md="3" class="mb-3">
                <app-button-submit :inline="true" :loading="loading" variant="primary" button_type="search">
                    {{$t("SEARCH") }}
                </app-button-submit>
                <app-button-reset :disabled="loading" :inline="true"  class="pl-2 ml-2"></app-button-reset>
            </b-col>
        </b-row>
    </form>
</template>

<script>
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {cloneDeep} from "lodash";
    import {EventBus} from "@/shared/EventBus";

    export default {
        name: "HealthMonitorFilter",
        components: {AppButtonSubmit, AppSelect, AppButtonReset},
        data() {
            return {
                initialFilter:null,
                filter: {
                    validation_list: ""
                },
                validationListOptions: [
                    {id: 1, name: this.$t('SALES_PERIODS')},
                    {id: 2, name: this.$t('LENGTH_OF_STAY') + ' / ' + this.$t('OCCUPANY_PERSONS')},
                    {id: 3, name: this.$t('CANCELLATION_AND_PREPAYMENT')},
                    {id: 4, name: this.$t('SYSTEM_CANCELATION_POLICIES')}
                ],
                loading: false,
                loaderReset: false
            }
        },
        methods: {
            reset() {
                this.filter = cloneDeep(this.initialFilter)
            },
            search() {
                EventBus.$emit('searchHealthReports', this.filter)
            },

        },
        computed: {
            company() {
                return this.$store.getters['user/getCompany']
            },
            disabledSearch() {
                return this.loading
            }
        },
        mounted() {
            this.initialFilter = cloneDeep(this.filter)
            EventBus.$on('loadingHealthReports', (value) => {
                this.loading = value
            })
        }
    }
</script>

<style scoped>

</style>
