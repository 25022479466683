<template>
        <b-form @submit.prevent="save">
            <b-row>
                <b-col cols="12" class="mb-4">
                    <label>{{$t('CANCELLATION_POLICY')}}</label>
                    <app-select @change="updatePropertyCP" :disabled="company_cancellation_policy !== null" value-field="id" text-field="label" v-model="property_cancellation_policy" :options="property_cancellation_policy_options"></app-select>
                </b-col>
                <b-col cols="12" class="mb-4">
                    <label>{{$t('SYSTEM_CANCELLATION_POLICY')}}</label>
                    <app-select search-input @change="updateCompanyCP" value-field="id" text-field="label" :disabled="property_cancellation_policy !== null" v-model="company_cancellation_policy" :options="company_cancellation_policy_options"></app-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <app-button-submit :loading="loading" :disabled="company_cancellation_policy === null && property_cancellation_policy === null"></app-button-submit>
                </b-col>
            </b-row>
        </b-form>
</template>

<script>
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {getPropertyCancelationPolicies, getPropertySystemCancelationPolicies} from "@/services/property";
import {getPossibleSystemPolicies} from "@/services/cancellation_policy";
import {applyToReservationCancellationPolicy} from "@/services/accommodation_reservation";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
export default {
name: "CancellationPolicyApplyForm",
    components: {AppButtonSubmit, AppSelect},
    props:{
        reservation_id: {
            type:Number
        },
        property_id:{
            type:Number
        }
    },
    mixins: [getErrorMessage],
    data(){
        return {
            property_cancellation_policy:null,
            company_cancellation_policy:null,
            company_cancellation_policy_options:[],
            property_cancellation_policy_options:[],
            loading:false
        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    methods:{
        updatePropertyCP(){
            this.company_cancellation_policy = null
        },
        updateCompanyCP(){
            this.property_cancellation_policy = null
        },
        save(){
            this.loading = true
            const cancellation_policy = this.property_cancellation_policy !== null ? this.property_cancellation_policy :  this.company_cancellation_policy !== null ? this.company_cancellation_policy : null
            applyToReservationCancellationPolicy(this.reservation_id,{cancellation_policy}).then(()=>{
                notifySuccess()
                this.$emit('policySaved')
            },error=>{
                this.showErrorMessages(error)
            }).finally(()=>{
                this.loading = false
            })
        },
        getPropertyCancellationPolicyList(){
            getPropertyCancelationPolicies(this.property_id).then(response => {
                this.property_cancellation_policy_options = response.data.map(el=>{
                    const for_cancelation = el.foc_cancelation === 1 ?  this.$t('YES') : this.$t('NO')
                    return {id:el.id, label: el.label + ' ( '+ this.$t('FOC_RESERVATION_CANCELATION')+': ' + for_cancelation+')'}
                })
            }, () => {
                this.property_cancellation_policy_options = response.data
            })

        },
        getCompanyCancellationPolicyList(){

            getPossibleSystemPolicies({company: this.company.id}).then(response => {
                this.company_cancellation_policy_options = response.data.map(el=>{
                    const for_cancelation = el.foc_cancelation === 1 ?  this.$t('YES') : this.$t('NO')
                    return {id:el.id, label: el.label + ' ( '+ this.$t('FOC_RESERVATION_CANCELATION')+': ' + for_cancelation+')'}
                })
            }, () => {
                this.company_cancellation_policy_options = []
            })
        },
    },
    created() {
        this.getCompanyCancellationPolicyList()
        this.getPropertyCancellationPolicyList()
    }
}
</script>

<style scoped>

</style>
