<template>
    <form @submit.prevent="search" @reset.prevent="reset" class="filter">
        <b-row class="align-items-end">
            <b-col md=2 class="mb-3">
                <label>{{$t('ACCOUNT_MANAGER')}}</label>
                <app-select mode="multiselect"
                            :options="acmList"
                            v-model="filter.account_manager" @input="searchProperties"></app-select>
            </b-col>

            <b-col md=2 class="mb-3">
                <label>{{$t('PROPERTY')}}</label>
                <app-select :disabled="!propertyList.length"
                            :options="propertyList"
                            v-model="filter.property" :search-input="true"></app-select>
            </b-col>

            <b-col md=2 class="mb-3">
                <label>{{$t('CLIENT_TYPE')}}</label>
                <app-select mode="multiselect" :options="clientTypeList"
                            v-model="filter.client_type"></app-select>
            </b-col>

            <b-col md="3" class="mb-3">
                <app-button-submit :inline="true" :loading="loading" variant="primary" button_type="search">
                    {{$t("SEARCH") }}
                </app-button-submit>
                <app-button-reset :disabled="loading" :inline="true"  class="pl-2 ml-2"></app-button-reset>
            </b-col>
        </b-row>
    </form>
</template>

<script>
    import {EventBus} from "@/shared/EventBus";
    import {cloneDeep, debounce} from "lodash";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {getList as getAccountManagerList} from "@/services/acc_manager";
    import {DEFAULT_PAGINATION_LIMIT, LS_PRESETS} from "@/shared/constants";
    import {getClientList, getPropertyList} from "@/services/property";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";

    export default {
        name: "RateManagerFilter",
        components: {AppButtonReset, AppSelect, AppButtonSubmit},
        data() {
            return {
                initialFilter: null,
                filter: {
                    account_manager: [],
                    property: [],
                    client_type: [],
                },
                loading: false,
                acmList: [],
                propertyList: [],
                clientTypeList: [],
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
        },
        methods: {
            search() {
                EventBus.$emit('searchReports', this.filter)
            },
            reset() {
                this.filter = cloneDeep(this.initialFilter)
            },
            searchProperties: debounce(function () {
                if (this.filter.account_manager.length) {
                    getPropertyList({
                        account_manager: this.filter.account_manager,
                        company_id: this.company.id,
                        limit: 9999,
                        order_by: 'name'
                    }).then(response => {
                        this.propertyList = response.data.items
                    })
                } else {
                    this.propertyList = []
                    this.filter.property = []
                }
            }, 800)
        },
        created() {
            getAccountManagerList({company_id: this.company.id}).then(response => {
                this.acmList = response.data
            })
            getClientList().then(response => {
                this.clientTypeList = response.data
            })
        },
        mounted() {
            this.initialFilter = cloneDeep(this.filter)
            EventBus.$on('loadingReports', (value) => {
                this.loading = value
            })
        },
        beforeDestroy() {
            EventBus.$off('loadingReports')
        }
    }
</script>

<style scoped>

</style>
