<template>
        <div class="content">
            <b-row>
                <b-col>
                    <slot name="pageTop"></slot>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <slot name="body1"></slot>
                </b-col>
                <b-col>
                    <slot name="body2"></slot>
                </b-col>
                <b-col>
                    <slot name="body3"></slot>
                </b-col>
            </b-row>
            <b-row>
                <b-col >
                    <slot name="pageBottom"></slot>
                </b-col>
            </b-row>
        </div>
</template>

<script>
    export default {
        name: "LayoutV1",
    }
</script>

<style scoped>

</style>
