<template>
    <form>
        <b-row>
            <b-col md="6" lg="4" class="mb-4">
                <label>{{ $t('WORKING_HOURS') }}</label>
                <b-form-textarea
                    :placeholder="workingHoursPlaceholder"
                    rows="4"
                    v-model="formData[WORKING_HOURS]">
                </b-form-textarea>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="d-flex align-items-end mb-4">
                <label class="mr-3 font-weight-bold">{{ $t('MAP_LOCATION') }}</label>
                <b-form-checkbox
                    v-model="includeLocation" @input="onIncludeLocationInput"
                    :value="1" :unchecked-value="0">
                </b-form-checkbox>
            </b-col>
        </b-row>

        <b-row  v-if="includeLocation">
            <b-col md="4" class="mb-4">
                <app-input-control :error-object="validationObject.lat">
                    <template v-slot:input="data">
                        <label>{{$t('LATITUDE')}}</label>
                        <b-form-input :state="data.state" lazy v-model="formData[COORDINATES].lat"></b-form-input>
                    </template>
                </app-input-control>

            </b-col>
            <b-col md="4" class="mb-4">
                <app-input-control :error-object="validationObject.lng">
                    <template v-slot:input="data">
                        <label>{{$t('LONGITUDE')}}</label>
                        <b-form-input :state="data.state" lazy v-model="formData[COORDINATES].lng"></b-form-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>

        <transition name="fade" v-if="includeLocation">
            <google-map
                :lat="formData[COORDINATES].lat || 33.410963"
                :lng="formData[COORDINATES].lng || -40.92738"
                :initial-zoom="3"
                @gmapLocationChange="updateFormLocation">
            </google-map>
        </transition>
    </form>
</template>

<script>

import FormHeader from "@/components/app/form/FormHeader";
import GoogleMap from "@/components/app/map/GoogleMap";
import AppInputControl from "@/components/app/AppInputControl";
import {
    WEBSITE_TITLE,WEBSITE_LATITUDE_VALIDATION,
    WEBSITE_LONGITUDE_VALIDATION
} from "@/shared/error_codes";
import {EventBus, V_ERROR} from "@/shared/EventBus";

const COORDINATES = 74
const WORKING_HOURS = 75

const paramConstants = {
    COORDINATES,
    WORKING_HOURS
}

export default {
    name: "WebsiteWizardContactForm",
    components: {AppInputControl, FormHeader, GoogleMap},
    props: {
        setupData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            ...paramConstants,
            formData: {
                [COORDINATES]: null,
                [WORKING_HOURS]: null,
            },
            includeLocation: 0,
            workingHoursPlaceholder: 'Mon – Fri  //  00:00 – 00:00\r\nSat – Sun  //   00:00 – 00:00',
            validationObject: {
                lat:{
                    input:[WEBSITE_LATITUDE_VALIDATION],
                },
                lng:{
                    input:[WEBSITE_LONGITUDE_VALIDATION],
                }
            }
        }
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler(data) {
                this.$emit('update', {contact: data})
            }
        }
    },
    methods: {
        onIncludeLocationInput() {
            this.formData[COORDINATES] = {lat: null, lng: null}
        },
        updateFormLocation(data) {
            this.formData[COORDINATES].lat = parseFloat(data.latitude)
            this.formData[COORDINATES].lng = parseFloat(data.longitude)
            EventBus.$emit(V_ERROR, [])
        }
    }
}
</script>

<style scoped>

</style>
