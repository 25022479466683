<template>
    <div class="d-flex flex-column w-100 justify-content-center align-items-center mt-5">
        <b-row>
            <b-col class="d-flex flex-column align-items-center">
                <slot name="title"></slot>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <slot name="body" class="mt-4"></slot>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <slot name="subtitle"></slot>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="mt-4">
                <slot name="actions"></slot>
                <app-button v-if="showCloseButton" class="ml-3" @click="onClose">{{ $t('CLOSE') }}</app-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import AppButton from "@/components/app/AppButton/AppButton";
import {EventBus} from "@/shared/EventBus";

export default {
    name: "AppWizardComplete",
    components: {
        AppButton
    },
    props:{
        showCloseButton:{
            type:Boolean,
            default:true
        }
    },
    methods: {
        onClose() {
            EventBus.$emit('gl_close_wizard')
            this.$emit('close')
        }
    },
}
</script>

<style scoped>

</style>
