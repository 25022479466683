<template>
    <div>

    </div>
</template>

<script>
    import {confirmRegistration} from "@/services/access";
    import {TOKEN, REFRESH_TOKEN, TOKEN_EXPIRES} from '@/shared/constants'
    import {toast} from '@/shared/plugins/toastr'
    import routeName from '@/router/routeNames'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {LoginPageHr, LoginPageEn} from "@/shared/constants";

    export default {
        name: "confirm-password",
        data() {
            return {

                routeName
            }
        },
        mixins:[getErrorMessage],
        methods: {
            activateUser(company_id, token) {
                confirmRegistration(company_id, token).then(response => {

                    const now = new Date()
                    const expirationDate = new Date(now.getTime() + response.data.expires_in)
                    localStorage.setItem(TOKEN, response.data.access_token)
                    localStorage.setItem(REFRESH_TOKEN, response.data.refresh_token)
                    localStorage.setItem(TOKEN_EXPIRES, expirationDate)
                    this.$store.commit('auth/setToken', response.data.access_token)
                    this.$store.dispatch('user/actionSetUser').then(() => {

                        this.$store.dispatch('user/actionSetUserPermissions').then(() => {
                            toast({
                                'title': this.$t('NOTIFICATIONS.USER_ACTIVATE_SUCCESS.TITLE'),
                                'message': this.$t('NOTIFICATIONS.USER_ACTIVATE_SUCCESS.MESSAGE'),
                                'type': 'success'
                            })
                            this.$router.replace({name: routeName.RN_HOME})
                        })

                    })

                }, () => {
                    if(this.$i18n.locale === 'hr'){
                        window.location.href = LoginPageHr
                    } else {
                        window.location.href = LoginPageEn
                    }



                })
            }
        },
        watch: {
            '$route.params.token': {
                handler(token) {
                    this.activateUser(Number(this.$route.params.company_id), token)
                },
                immediate: true,
                deep: true
            }
        },
        created(){
            if(this.$route.query.lang  === 'sl'){
                this.$i18n.locale = 'si'
            }  else  {
                this.$i18n.locale = this.$route.query.lang  ? this.$route.query.lang   : 'en'
            }
        }
    }
</script>

<style scoped>

</style>
