import http from "@/services/http";
import {GUEST_BACKOFFICE_SETUP_URL} from "@/services/endpoints";

export function getGuestBackofficeSetup(params) {
    return http.request({
        url: GUEST_BACKOFFICE_SETUP_URL  + '/parameters',
        method: 'get',
        params
    })
}

export function setGuestBackofficeSetup(data) {
    return http.request({
        url: GUEST_BACKOFFICE_SETUP_URL + '/parameters',
        method: 'put',
        data
    })
}
