<template>
    <div>
        <b-form @submit.prevent="sync">
            <b-row>
                <b-col cols="12">
                    <div class="table-wrapper">
                        <table class="table">
                            <thead>
                            <tr>
                                <th class="table-header-period-range" scope="col">{{ $t('PERIOD') }}</th>
                                <th class="table-header-actions text-right" scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <period-item :disabled="true" @delPeriod="sync_date_ranges.splice(index, 1)"
                                         :key="index" v-for="(period,index) in sync_date_ranges"
                                         :period="period"
                                         ref="period_items" :disabled-dates="disabledDates"></period-item>
                            <period-item-new @save="addPeriod"
                                             :max-date="maxDate"
                                             :from-date="fromDate"
                                             :disabled-dates="disabledDates"
                                             :add-button="false"></period-item-new>
                            </tbody>
                        </table>
                    </div>
                </b-col>

            </b-row>
            <b-row>
                <b-col lg="6">
                    <div if="units.length > 1" class="mb-4">
                        <label>{{ $t("UNIT") }}</label>
                        <app-select mode="multiselect" v-model="sync_units" :options="units"></app-select>
                    </div>

                    <b-col>
                        <b-row>
                            <b-col>
                                <form-header>{{ $t("CALENDAR_SYNC_OPTIONS") }}</form-header>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-checkbox
                                    id="syncAvailability"
                                    v-model="sync_availability"
                                    name="syncAvailability-1"
                                    :value="true"
                                    :unchecked-value="false">
                                    {{ $t("AVAILABILITY") }}
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-checkbox
                                    id="syncPrices"
                                    v-model="sync_prices"
                                    name="syncAvailability-1"
                                    :value="true"
                                    :unchecked-value="false">
                                    {{ $t("PRICES") }}
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-checkbox
                                    id="syncRoomStatus"
                                    v-model="sync_room_status"
                                    name="syncRoomStatus-1"
                                    :value="true"
                                    :unchecked-value="false">
                                    {{ $t("ROOM_STATUS") }}
                                </b-form-checkbox>

                            </b-col>

                        </b-row>
                        <b-row v-if="restrictions !== null">
                            <b-col class="mb-4">
                                <b-form-checkbox
                                    inline
                                    id="select_all_restrictions"
                                    v-model="select_all_restrictions"
                                    name="select_all_restrictions-1"
                                    @change="toggleRestrictions"
                                    :value="true"
                                    :unchecked-value="false">
                                    {{ $t("RESTRICTIONS") }} ({{ $t("SELECT_ALL") }})
                                </b-form-checkbox>
                                <b-form-checkbox-group
                                    id="restriction-checkbox"
                                    class="ml-4"
                                    stacked
                                    value-field="id"
                                    text-field="label"
                                    v-model="sync_restrictions"
                                    :options="restrictions"
                                    name="flavour-1"
                                ></b-form-checkbox-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-col>
                <b-col lg="6">
                    <div v-if="distributions.length > 0">
                        <b-row>
                            <b-col>
                                <form-header :header-class="units.length > 1 ? 'mt-4 mb-4' : 'mb-4'">
                                    {{ $t("DISTRIBUTION") }}
                                </form-header>
                            </b-col>
                        </b-row>
                        <div class="main_calendar_test_distribution-sync mb-4" style="height: 31rem">
                            <b-row class="main_calendar_test_distribution-sync-item">
                                <b-col>
                                    <b-form-checkbox v-model="select_all_distribution">{{ $t('SELECT_ALL') }}
                                    </b-form-checkbox>
                                    <hr>
                                </b-col>
                            </b-row>
                            <b-row class="main_calendar_test_distribution-sync-item" :key="index"
                                   v-for="(distribution, index) in distributions">
                                <b-col>
                                    <b-form-checkbox
                                        :id="`syncDistributions${index}`"
                                        name="syncDistributions-1"
                                        @change="selectAllDistributionUnit(distribution, $event)"
                                        v-model="select_all_dist_unit[distribution.id]"
                                    >
                                        {{ distribution.name }}
                                    </b-form-checkbox>
                                    <b-form-checkbox-group
                                        ref="distribution-unit-checkbox-list"
                                        stacked
                                        :id="'distribution-unit-checkbox-list_'+distribution.id"
                                        value-field="id"
                                        text-field="name"
                                        :options="distributionUnits(distribution.id)"
                                        name="distribution-unit--checkbox-list"
                                        v-model="sync_distributions[distribution.id]"
                                        class="ml-4"
                                    >
                                    </b-form-checkbox-group>
                                    <hr>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <app-button-submit button-class="btn-primary" :loading="saving" :disabled="saveDisabled">
                        {{ $t("SYNC") }}
                    </app-button-submit>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import AppButtonSubmit from '@/components/app/AppButton/AppButtonSubmit'
import AppDeleteButton from '@/components/app/AppButton/AppButtonDelete'
import MultiSelect from 'vue-multiselect'

import {syncCalendar} from "@/services/distribution/sync";
import {getErrorMessage} from '@/mixins/error/getErrorMessage'
import {toast} from '@/shared/plugins/toastr'

import moment from 'moment'
import AppSelect from "@/components/app/AppSelect/AppSelect";
import FormHeader from "@/components/app/form/FormHeader";
import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
import AppButton from "@/components/app/AppButton/AppButton";
import {cloneDeep} from 'lodash'
import {EventBus, GE_CALENDAR_OPEN_SYNC_LOADING} from "@/shared/EventBus";
import {getDistributionManagersTest} from "@/services/distribution";
import PeriodItem from "@/components/app/Period/PeriodItem";
import PeriodItemNew from "@/components/app/Period/PeriodItemNew";
import {CLOSED_ON_ARRIVAL, CLOSED_ON_DEPARTURE} from "@/shared/constants";

export default {
    name: "UnitCalendarSyncFormV2",
    components: {
        PeriodItemNew,
        PeriodItem,
        AppButton,
        AppDateRangePicker,
        FormHeader,
        AppSelect,
        AppDeleteButton, MultiSelect, AppButtonSubmit
    },
    props: {
        units: {
            type: Array
        },
        defaultSelectedUnits: {
            type: Array
        },
        default_date_range: {
            default: () => {
                return {start: null, end: null}
            },
            type: Object
        },
        restrictions: {
            default: () => {
                return []
            },
            type: Array | Object
        },
    },
    mixins: [getErrorMessage],
    data() {
        return {
            date_range: {start: null, end: null},
            select_all_restrictions: false,
            sync_date_ranges: [],
            sync_units: [],
            sync_distributions: {},
            sync_availability: false,
            sync_prices: false,
            sync_room_status: false,
            sync_restrictions: [],
            saving: false,
            fields: [
                {key: 'date', label: this.$t('SELECTED_PERIODS')},
                {key: 'delete', label: "", class: 'text-right'},
            ],
            distributions: [],
            disableDistribution: true,
            select_all_distribution: false,
            select_all_dist_unit: {}
        }
    },
    computed: {
        fromDate() {
            let periodList = this.sync_date_ranges.filter(el => el.deleted !== 1)
            if (periodList.length > 0) {
                let lastItem = periodList[periodList.length - 1]
                if (lastItem.end) {
                    return new moment(lastItem.end).add(1, 'day').toDate()
                }
            }

            return undefined
        },
        maxDate() {
            const currentYear = new Date().getFullYear()
            let maxDate = (currentYear + 3) + '-12-31'
            return new Date(maxDate)
        },
        saveDisabled() {
            let select_distribution_unit = false
            for (const [key, value] of Object.entries(this.sync_distributions)) {
                if (value.length > 0) {
                    select_distribution_unit = true
                }
            }

            return this.sync_units.length === 0
                || !select_distribution_unit
                || this.sync_date_ranges.length === 0
                || (!this.sync_availability && !this.sync_prices && !this.sync_room_status && this.sync_restrictions.length === 0)
        },
        sortedPeriods() {
            return this.sync_date_ranges.map(period => {
                return {
                    from: period.start,
                    to: period.end
                }
            }).sort((a, b) => new Date(a.from) - new Date(b.from))
        },
        companyId() {
            return this.units[this.units.length - 1].company.id
        },
        propertyId() {
            return this.units[this.units.length - 1].property.id
        },
        disabledDates() {
            return this.sync_date_ranges.map(item => {
                return {
                    start: moment(item.start).toDate(),
                    end: moment(item.end).toDate(),
                }
            })
        }
    },
    methods: {
        selectAllDistributionUnit(distributionObject, value) {
            this.select_all_dist_unit[distributionObject.id] = value
            if (value) {
                this.sync_distributions[distributionObject.id] = distributionObject.units.map(item => {
                    return item.id
                })
            } else {
                this.sync_distributions[distributionObject.id] = []
            }
        },
        updateDateList({newValue, oldValue}) {
            let dates = this.sync_date_ranges
            const index = dates.findIndex(el => el.start === oldValue.start && oldValue.end)
            if (index > -1) {
                dates[index].start = newValue.start
                dates[index].end = newValue.end
            }
            this.sync_date_ranges = dates
        },
        addPeriod(period) {
            this.sync_date_ranges.push({
                start: period.start,
                end: period.end,
            })
        },

        formatFields() {
            this.sync_distributions = {}
            this.select_all_dist_unit = {}
            if (this.distributions.length > 0) {
                for (let distribution of this.distributions) {
                    this.$set(this.sync_distributions, distribution.id, [])
                    this.$set(this.select_all_dist_unit, distribution.id, false)

                }
            }
        },
        distributionUnits(distribution_id) {
            const distributionObject = this.distributions.find(item => item.id === distribution_id)
            return distributionObject && distributionObject.hasOwnProperty('units') ? distributionObject.units : []
        },

        toggleRestrictions() {
            this.sync_restrictions = []
            if (!this.select_all_restrictions) {
                Object.keys(this.restrictions).forEach(key => {
                    this.sync_restrictions.push(this.restrictions[key].id)
                })
            }
        },
        sync() {
            this.saving = true
            let distribution_units = []
            let distribution_ids = []
            for (const [distribution_id, units] of Object.entries(this.sync_distributions)) {
                if (units.length > 0) {
                    distribution_ids.push(distribution_id)
                    distribution_units.push({id: distribution_id, units})
                }
            }


            let request = {
                sync_options: {
                    availability: this.sync_availability,
                    unit_status: this.sync_room_status,
                    rate: this.sync_prices,
                    restriction: this.sync_restrictions,
                },
                units: this.sync_units,
                distributions: distribution_ids,
                distribution_units,
                periods: this.sortedPeriods,
            }


            syncCalendar(request).then(() => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 6000
                })
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
            }).finally(() => {
                this.saving = false
            })
        },
        setDistributions(unit_ids) {
            if (unit_ids.length > 0) {
                getDistributionManagersTest({
                    active: 1,
                    unit: unit_ids
                }).then(response => {
                    this.distributions = response.data
                    this.formatFields()
                    this.disableDistribution = false
                })
            } else {
                this.disableDistribution = true
                this.distributions = []
            }

        }
    },
    mounted() {
        if (this.default_date_range.start !== null && this.default_date_range.end !== null) {
            this.sync_date_ranges.push(this.default_date_range)
        }

        this.sync_units = cloneDeep(this.defaultSelectedUnits)
    },
    watch: {
        sync_units: {
            handler(unit_ids) {
                this.setDistributions(unit_ids)
            }
        },
        select_all_distribution: {
            handler(value) {
                if (value) {
                    for (const distributionId in this.sync_distributions) {
                        this.$set(this.select_all_dist_unit, distributionId, true)
                        let distributionObject = this.distributions.find(el => el.id === Number(distributionId))
                        this.sync_distributions[distributionId] = distributionObject.units.map(item => {
                            return item.id
                        })
                    }
                } else {
                    for (const distributionId in this.sync_distributions) {
                        this.$set(this.select_all_dist_unit, distributionId, false)
                        this.sync_distributions[distributionId] = []
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
