<template>
    <div>
        <b-table v-if="list.length" :items="list" hover responsive :fields="fields">
            <template v-slot:self_issue="data">
                <i v-if="data.item.self_issue" class="fa fa-check text-success" aria-hidden="true"></i>
                <i v-else class="fa fa-ban text-danger" aria-hidden="true"></i>
            </template>
            <template v-slot:vat_system="data">
                <i v-if="data.item.vat_system" class="fa fa-check text-success" aria-hidden="true"></i>
                <i v-else class="fa fa-ban text-danger" aria-hidden="true"></i>
            </template>
            <template v-slot:edit="data">
                <app-button variant="link"
                            button_type="edit"
                            :show_text="false"
                            :to="{name: routeNames.RN_PROPERTY_OWNER_BASIC, params: {owner_id: data.item.id}}">
                </app-button>
            </template>
        </b-table>
        <no-data v-if="!loading && list.length === 0" :show-tip="false">
            <app-button button_type="new"
                       @click="aside=true">
                {{ $t('NEW_OWNER') }}
            </app-button>
        </no-data>
        <app-aside  :widths="['col-sm-12', '40%']" v-model="aside">
            <template slot="header">{{$t('NEW_OWNER')}}</template>
            <owner-wizard :property-id="Number(this.$route.params.id)"></owner-wizard>
        </app-aside>
    </div>
</template>

<script>

    import routeNames from '@/router/routeNames'
    import NoData from '@/components/app/AppNoData'
    import AppButton from '@/components/app/AppButton/AppButton'
    import {R_PROPERTY_OWNER_BASIC} from "@/shared/route_permission";
    import {EventBus, GE_OPEN_OWNER_WIZARD} from "@/shared/EventBus";
    import AppAside from "@/components/app/form/AppAside";
    import OwnerWizard from "@/components/owner/new/OwnerWizard";

    export default {
        name: "Index",
        components: {
            OwnerWizard,
            NoData, AppButton, AppAside
        },
        data() {
            return {
                routeNames,
                r_permission_basic: R_PROPERTY_OWNER_BASIC,
                fields: [
                    {key: 'id', label: this.$t('ID')},
                    {key: 'name', label: this.$t('FIRST_NAME_LAST_NAME')},
                    {key: 'oib', label: this.$t('VAT_NUMBER')},
                    {key: 'registration_number', label: this.$t('REGISTRATION_NUMBER')},
                    {key: 'person_type', label: this.$t('ENTITY_TYPE')},
                    {key: 'self_issue', label: this.$t('SELF_ISSUE')},
                    {key: 'vat_system', label: this.$t('REGISTERED_FOR_VAT')},
                    {key: 'edit', label: '', tdClass: 'text-right'},
                ],
                loading: true,
                list: [],
                aside: false
            }
        },
        methods: {
            goTo(id) {
                this.$router.push({name: routeNames.RN_PROPERTY_OWNER_BASIC, params: {owner_id: id}})
            }
        },
        created() {
            this.$store.dispatch('owner/actionSetOwnerList', this.$route.params.id).then((response) => {
                this.list = response.data
                this.loading = false
            }, () => {
                this.list = []
                this.loading = false
            })
        },
        mounted() {
            EventBus.$on(GE_OPEN_OWNER_WIZARD, () => {
                this.aside = true
            })
        }
    }
</script>

<style scoped>

</style>
