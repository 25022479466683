<template>
    <b-form @submit.prevent>
        <form-wizard ref="wizard" :startIndex="currentIndex" transition="fade-in" color=var(--color-primary) errorColor=var(--color-warning)
                     name="propertyWizard" title="" subtitle=""
                     @on-validate="handleNextStep"
                     @on-complete="save">

            <tab-content name="basic" :title="$t('BASIC')"
                         :before-change="() => validate('basic')">
                <b-row>
                    <b-col md="6" lg="4" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.basic.name">
                            <template v-slot:input="data">
                                <label>{{$t("NAME")}}</label>
                                <b-form-input v-model="basic.name" :state="data.state"></b-form-input>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col md="6" lg="4" xl="2" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.basic.property_type">
                            <template v-slot:input="data">
                                <label>{{$t('TYPE')}}</label>
                                <app-select mode="search" :search-on-create="true" text-field="label"
                                            :search="searchPropertyType" v-model="basic.property_type"
                                            :state="data.state"></app-select>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col md="6" lg="4" xl="2" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.basic.categorization">
                            <template v-slot:input="data">
                                <label>{{$t('CATEGORIZATION')}}</label>
                                <app-select :options="categorization" text-field="text" value-field="value"
                                            v-model="basic.categorization" :state="data.state"></app-select>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
            </tab-content>
            <tab-content name="location" :title="$t('LOCATION')"
                         :before-change="() => validate('location')">
                <b-row>
                    <b-col>
                        <form-header>{{$t('LOCATION')}}</form-header>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="4" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.location.address">
                            <template v-slot:input="data">
                                <label>{{$t('ADDRESS')}}</label>
                                <b-form-input type="text" v-model="location.address" :state="data.state"></b-form-input>
                            </template>
                        </app-input-control>

                    </b-col>
                    <b-col md="2" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.location.address_no">
                            <template v-slot:input="data">
                                <label>{{$t('STREET_NUMBER')}}</label>
                                <b-form-input type="text" v-model="location.address_no"
                                              :state="data.state"></b-form-input>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col md="2" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.location.postal_number">
                            <template v-slot:input="data">
                                <label>{{$t('ZIP')}}</label>
                                <b-form-input type="text" v-model="location.postal_number"
                                              :state="data.state"></b-form-input>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col md="6" lg="4" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.location.city">
                            <template v-slot:input="data">
                                <label>{{$t('CITY')}}</label>
                                <app-select :state="data.state" mode="search" :search="searchCity"
                                            v-model="location.city" :search-clear="true"></app-select>
                            </template>
                        </app-input-control>

                    </b-col>
                    <b-col md="6" lg="4" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.location.county">
                            <template v-slot:input="data">
                                <label>{{$t('COUNTY')}}</label>
                                <b-input disabled :value="location.county.name" :state="data.state"></b-input>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col md="6" lg="4" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.location.country">
                            <template v-slot:input="data">
                                <label>{{$t("COUNTRY")}}</label>
                                <b-input disabled :value="location.country.name" :state="data.state"></b-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <form-header>{{$t('GEOLOCATION')}}</form-header>
                    </b-col>
                </b-row>
                <b-row>

                    <b-col md="4" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.location.latitude">
                            <template v-slot:input="data">
                                <label>{{$t('LATITUDE')}}</label>
                                <b-form-input lazy v-model="location.latitude" :state="data.state"></b-form-input>
                            </template>
                        </app-input-control>

                    </b-col>
                    <b-col md="4" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.location.longitude">
                            <template v-slot:input="data">
                                <label>{{$t('LONGITUDE')}}</label>
                                <b-form-input lazy v-model="location.longitude" :state="data.state"></b-form-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">

                        <g-map :lng="location.longitude" :lat="location.latitude"
                               @gmapLocationChange="updateFormLocation"></g-map>
                    </b-col>
                </b-row>

            </tab-content>
            <tab-content name="owner" :title="$t('OWNER')" :before-change="() => validate('owner')">
                <b-row>
                    <b-col md="6" lg="4" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.owner.name">
                            <template v-slot:input="data">

                                <label>{{$t("FIRST_NAME_LAST_NAME")}}</label>
                                <b-input v-model="owner.name" :state="data.state"></b-input>

                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col md="6" lg="4" class="mb-4">
                        <app-input-control>
                            <template v-slot:input="data">
                                <label>{{$t("ENTITY_TYPE")}}</label>
                                <app-select :search-empty-item="false" v-model="owner.person_type.id"
                                            :options="finance_options"
                                            :state="data.state"></app-select>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col md="6" lg="4" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.owner.email">
                            <template v-slot:input="data">
                                <label>{{$t("EMAIL")}}</label>
                                <b-input v-model="owner.email" :state="data.state"></b-input>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col md="6" lg="4" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.owner.phone">
                            <template v-slot:input="data">
                                <label>{{$t("PHONE_MOBILE")}}</label>
                                <b-input v-model="owner.phone" :state="data.state"></b-input>
                            </template>
                        </app-input-control>
                    </b-col>

                </b-row>
            </tab-content>
            <tab-content name="advanced" :title="$t('UNITS')" :before-change="() => validate('advanced')">

                <b-row>
                    <b-col md="6" lg="4" xl="2" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.advanced.unit_number">
                            <template v-slot:input="data">
                                <label>{{$t('UNIT_NUMBER')}}</label>
                                <b-form-input type="number" v-model="advanced.unit_number"
                                              :state="data.state"></b-form-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>

            </tab-content>


            <button class="btn btn-outline-primary" slot="prev">{{$t('PREVIOUS')}}</button>
            <button class="btn btn-primary" slot="next">{{$t('NEXT')}}</button>
            <button class="btn btn-primary" slot="finish">{{$t('FINISH')}}</button>

        </form-wizard>
    </b-form>
</template>
<script>
    import {FormWizard, TabContent} from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import GMap from '@/components/app/map/GoogleMap'
    import {toast} from '@/shared/plugins/toastr'
    import ValidationDescription from '@/components/app/form/AppValidationDescription'
    import RouteNames from '@/router/routeNames'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {getCityList, getCityMapping, getCounties, getCountries} from "@/services/location";
    import FormHeader from "@/components/app/form/FormHeader";
    import {getPropertyTypeList} from "@/services/property";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppInputControl from "@/components/app/AppInputControl";
    import {
        OWNER_VALIDATION_NAME_VALIDATION_FAIL,
        OWNER_VALIDATION_PERSON_TYPE_VALIDATION_FAIL,
        OWNER_VALIDATION_CONTACT_PHONE_VALIDATION_FAIL,
        OWNER_VALIDATION_CONTACT_EMAIL_VALIDATION_FAIL,
        PROPERTY_DATA_NAME_VALIDATION_FAILED,
        PROPERTY_DATA_ADDRESS_VALIDATION_FAILED,
        PROPERTY_DATA_ADDRESS_NO_VALIDATON_FAILED,
        PROPERTY_DATA_LATITUDE_VALIDATION_FAILED,
        PROPERTY_DATA_LONGITUDE_VALIDATION_FAILED,
        PROPERTY_DATA_POSTAL_NUMBER_VALIDATION_FAILED,
        PROPERTY_DATA_CITY_VALIDATION_FAILED,
        PROPERTY_DATA_COUNTY_VALIDATION_FAILED,
        PROPERTY_DATA_COUNTRY_VALIDATION_FAILED,
        PROPERTY_DATA_CATEGORIZATION_VALIDATION_FAILED,
        PROPERTY_DATA_UNIT_NUMBER_VALIDATION_FAILED,
        PROPERTY_DATA_PROPERTY_TYPE_VALIDATION_FAILED,
        PROPERTY_DATA_INVALID_LATITUDE_VALIDATION_FAILED,
        PROPERTY_DATA_INVALID_LONGITUDE_VALIDATION_FAILED,
        unit_data_invalid_latitude_validation_failed,
        unit_data_invalid_longitude_validation_failed


    } from "@/shared/error_codes";
    import {EventBus, V_ERROR} from "@/shared/EventBus";
    import {validationCreatePropertyWithOwner} from "@/services/property";
    import {getPresets} from "@/services/user";
    import {LS_PRESETS} from "@/shared/constants";

    export default {
        name: "PropertyWizard",
        components: {
            AppInputControl,
            AppSelect,
            FormHeader,
            FormWizard,
            TabContent,
            GMap,

            ValidationDescription
        },
        mixins: [getErrorMessage],

        data() {
            return {
                currentIndex: 0,
                propertyTypeList: [],
                basic: {
                    name: null,
                    property_type: {
                        id: null,
                        name: null
                    },
                    categorization: null,
                },
                categorization: [
                    {value: 1, text: '1 *'},
                    {value: 2, text: '2 *'},
                    {value: 3, text: '3 *'},
                    {value: 4, text: '4 *'},
                    {value: 5, text: '5 *'}
                ],
                finance_options: [
                    {id: 1, name: this.$t('NATURAL_ENTITY')},
                    {id: 2, name: this.$t('LEGAL_ENTITY')},

                ],
                location: {
                    address: null,
                    address_no: null,
                    postal_number: null,
                    city: {
                        id: null,
                        name: null
                    },
                    county: {
                        id: null,
                        name: null
                    },
                    country: {
                        id: null,
                        name: null
                    },
                    region: {
                        id: null,
                        name: null
                    },
                    latitude: null,
                    longitude: null
                },
                advanced: {
                    unit_number: null,
                },
                owner: {
                    name: null,
                    person_type: {
                        id: 1
                    },
                    email: null,
                    phone: null
                },
                validationErrorObject: {
                    basic: {
                        name: {
                            input: [PROPERTY_DATA_NAME_VALIDATION_FAILED]
                        },
                        categorization: {
                            input: [PROPERTY_DATA_CATEGORIZATION_VALIDATION_FAILED]
                        },
                        property_type: {
                            input: [PROPERTY_DATA_PROPERTY_TYPE_VALIDATION_FAILED]
                        }
                    },
                    location: {
                        address: {
                            input: [PROPERTY_DATA_ADDRESS_VALIDATION_FAILED]
                        },
                        address_no: {
                            input: [PROPERTY_DATA_ADDRESS_NO_VALIDATON_FAILED]
                        },
                        postal_number: {
                            input: [PROPERTY_DATA_POSTAL_NUMBER_VALIDATION_FAILED]
                        },
                        city: {
                            input: [PROPERTY_DATA_CITY_VALIDATION_FAILED]
                        },
                        county: {
                            input: [PROPERTY_DATA_COUNTY_VALIDATION_FAILED]
                        },
                        country: {
                            input: [PROPERTY_DATA_COUNTRY_VALIDATION_FAILED]
                        },

                        latitude: {
                            input: [PROPERTY_DATA_LATITUDE_VALIDATION_FAILED, PROPERTY_DATA_INVALID_LATITUDE_VALIDATION_FAILED,unit_data_invalid_latitude_validation_failed]
                        },
                        longitude: {
                            input: [PROPERTY_DATA_LONGITUDE_VALIDATION_FAILED, PROPERTY_DATA_INVALID_LONGITUDE_VALIDATION_FAILED,unit_data_invalid_longitude_validation_failed]
                        }
                    },
                    owner: {
                        name: {
                            input: [OWNER_VALIDATION_NAME_VALIDATION_FAIL]
                        },
                        email: {
                            input: [OWNER_VALIDATION_CONTACT_EMAIL_VALIDATION_FAIL]
                        },
                        phone: {
                            input: [OWNER_VALIDATION_CONTACT_PHONE_VALIDATION_FAIL]
                        },
                        person_type: {
                            input: [OWNER_VALIDATION_PERSON_TYPE_VALIDATION_FAIL]
                        }
                    },
                    advanced: {
                        unit_number: {
                            input: [PROPERTY_DATA_UNIT_NUMBER_VALIDATION_FAILED],
                        }
                    },
                },
                error_list: []
            }
        },
        methods: {
            searchCity(value) {
                return getCityMapping({
                    name: value
                })
            },


            updateFormLocation(data) {
                this.location.latitude = data.latitude
                this.location.longitude = data.longitude

                if (
                    (this.location.address === '' || this.location.address === null) &&
                    (this.location.address_no === '' || this.location.address_no === null) &&
                    (this.location.postal_number === '' || this.location.postal_number === null)
                ) {
                    this.location.address = data.street
                    this.location.address_no = data.street_number
                    this.location.postal_number = data.postal_code
                }
            },
            prepareRequest() {
                const request = {
                    name: this.basic.name,
                    categorization: this.basic.categorization,
                    property_type: this.basic.property_type,
                    unit_number: this.advanced.unit_number,
                    location: this.location,
                    owner: this.owner,
                    company_id: this.$store.getters['user/getCompany']
                }
                return request
            },
            validate(step) {

                return validationCreatePropertyWithOwner(this.prepareRequest()).then(() => {
                    return true
                }, error => {
                    if (this.validationErrorObject.hasOwnProperty(step)) {
                        return this.serverStepValidation(this.validationErrorObject[step], error.response.data.error_list)
                    }
                })

            },
            serverStepValidation(stepObject, errorList) {
                if (Array.isArray(errorList) && errorList.length) {
                    const serverErrorCodes = errorList.map(list => Number(list.code))
                    for (const [filedName, errorObject] of Object.entries(stepObject)) {
                        for (const [type, typeErrors] of Object.entries(errorObject)) {
                            for (let error of typeErrors) {
                                if (serverErrorCodes.includes(error.code)) {
                                    return false
                                }
                            }
                        }
                    }
                }
                return true
            },
            handleNextStep(isValid, tabIndex) {
                if (!isValid) {
                    return
                }
                EventBus.$emit(V_ERROR, [])
                this.currentIndex = tabIndex++
                localStorage.setItem('property_wizard_temp', JSON.stringify(this.$data))
            },
            save() {
                return this.$store.dispatch('property/actionCreatePropertyWithOwner', this.prepareRequest()).then((response) => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })
                        localStorage.removeItem('property_wizard_temp')
                    getPresets().then(response => {
                        localStorage.setItem(LS_PRESETS, JSON.stringify(response.data))
                    })
                        this.$router.push({name: RouteNames.RN_PROPERTY_BASIC, params: {id: response.data.id}})
                    }, error => {
                        if (error.response.data.error_list.length > 0) {
                            for (let errorObject of error.response.data.error_list) {
                                if (errorObject.code === PROPERTY_DATA_CATEGORIZATION_VALIDATION_FAILED.code) {
                                    this.$refs.wizard.navigateToTab(0)
                                    this.$refs.wizard.tabs[0].validationError = 'error'
                                    break
                                }
                            }
                        }
                    }
                )
            },
            searchPropertyType(value) {
                return getPropertyTypeList()
            }
        },
        watch: {
            'location.city': {
                handler(value) {
                    if (value && value.hasOwnProperty('id') && value.id) {

                        if (value.hasOwnProperty('country')) {
                            this.location.country = value.country
                        }
                        if (value.hasOwnProperty('county')) {
                            this.location.county = value.county
                        }
                        if (value.hasOwnProperty('region')) {
                            this.location.region = value.region
                        }
                    } else if (value.hasOwnProperty('id') && !value.id) {

                        this.location.county.id = null
                        this.location.county.name = null
                        this.location.country.id = null
                        this.location.country.name = null
                        this.location.region.id = null
                        this.location.region.name = null
                    }
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>

<style scoped>
    h5 {
        margin-top: 2rem;
    }


</style>
