<template>
    <div class="dashboard-wrapper dashboard-wrapper--gray" v-if="company">
        <app-access-control tag="span"
                            :access_control_context="{function: C_DASHBOARD_GENERAL_V,key: AC_DASHBOARD}"
                            @AccessControlEvent="addToAccessControlCounter()">
            <general-dashboard :company="company"></general-dashboard>
        </app-access-control>
        <app-access-control tag="span"
                            :access_control_context="{function: C_DASHBOARD_LAYOUT_ONE_V,key: AC_DASHBOARD}"
                            @AccessControlEvent="addToAccessControlCounter()">
            <dashboard-layout-v1 :company="company"></dashboard-layout-v1>
        </app-access-control>
    </div>
</template>

<script>

    import {
        C_DASHBOARD_GENERAL_V,
        C_DASHBOARD_LAYOUT_ONE_V
    } from '@/shared/component_permission'
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {
        AC_DASHBOARD,
    } from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import GeneralDashboard from "@/components/dashboard/GeneralDashboard";
    import DashboardLayoutV1 from "@/components/dashboard/DashboardLayoutV1";


    export default {
        name: 'dashboard',
        components: {
            DashboardLayoutV1,
            GeneralDashboard,
            AppAccessControl,
        },
        mixins: [AccessControlComponent],
        data() {
            return {
                C_DASHBOARD_GENERAL_V,
                C_DASHBOARD_LAYOUT_ONE_V,
                AC_DASHBOARD,
                access_control_fetch_key: AC_DASHBOARD,
                access_control_components: 2,
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            }
        },
        created() {
            this.access_control_general_context = {
                company: this.$store.getters['user/getCompany']
            }
        },
    }
</script>
