<template>
    <div
         :class="{'main_calendar-br_green': Number(date.format('D')) === date.daysInMonth()}&& display_work_mode === MULTI_UNIT_VIEW ? 'd-flex flex-column justify-content-end content_cell position-relative' : 'd-flex flex-column justify-content-center content_cell position-relative'">
        <div v-if="showMonth && display_work_mode === MULTI_UNIT_VIEW"
             class="main_calendar__date_header--year animated fadeIn ml-1">
            {{date.format('MMMM')}}
        </div>

        <div :class="{isWeekend} && 'main_calendar__date_header--date_monthly ml-1'">{{date | dayNumber}}</div>
        <div class="main_calendar__date_header--day ml-1" :class="isWeekend">{{date | dayString}}</div>
    </div>
</template>

<script>
import {MULTI_UNIT_VIEW} from "@/shared/constants";

export default {
    name: "PropertyCalendarDateCellTest",
    props:{
        date:{
            type:Object
        },
        index: {
            type: Number
        },
        scrollLeftPosition: {
            type: Number
        },
        display_work_mode:{
            type: Number,
            required: true
        }
    },
    data(){
        return{
            MULTI_UNIT_VIEW
        }
    },
    computed:{
        isWeekend() {
            if ([0,6].includes(this.date.day())) {
                return 'main_calendar__date_header--weekend'
            }
            return ''
        },
        pixelsWide() {
            return 2.87 * 16
        },
        myStartingPos() {
            return this.pixelsWide * this.index
        },
        myEndingPos() {
            return this.pixelsWide * (this.index + 1)
        },

        calculatedPosition() {
            return this.index !== 1 && this.myStartingPos <= (this.scrollLeftPosition + this.pixelsWide) && this.myEndingPos >= (this.scrollLeftPosition + this.pixelsWide)
        },

        showMonth() {
            return this.index === 0 || this.date.date() === 1 || this.calculatedPosition
        }
    }
}
</script>

<style scoped>

</style>
