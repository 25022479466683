<template>
    <div>
        <b-form @submit.prevent="save" v-if="tagObject.id">
            <b-row class="mb-4">
                <b-col cols="4">
                    <app-input-control :error-object="validationObject.name">
                        <template v-slot:input="data">
                            <app-translation-input
                                :label="$t('NAME')"
                                :context-label="header"
                                v-model="tagObject.label"
                                :languages="tagObject.languages"
                                :lang_id="tagObject.lang_id"
                                :width="['col-lg-4','col-sm-12', '40%']"
                                :state="data.state"
                            ></app-translation-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="4 mt-2">
                    <app-image-input
                        :image="tagObject.image.id"
                        :preview="tagObject.image.url"
                        @input="tagObject.image.id = $event"
                        :enable-delete="checkPermission(C_SETUP_TAG_E)"
                        :key="tagObject.id"
                        class="pl-0 pr-0"></app-image-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mt-4">
                    <app-button-submit v-if="checkPermission(C_SETUP_TAG_E)" :loading="loading"
                                       ref="saveButton"></app-button-submit>
                </b-col>
                <b-col class="mt-4">
                    <app-button-delete
                        v-if="checkPermission(C_SETUP_TAG_E)"
                        @click="deleteItem"
                        class="pull-right">
                    </app-button-delete>
                </b-col>
            </b-row>
            <app-confirmation-dialog
                :show="confirmation_dialog_state"
                @confirm="continueAction"
                @cancel="cancelAction"
                :title="$t('DELETE_TITLE')">
                <p>{{ $t('DELETE_MESSAGE', {name}) }}</p>
            </app-confirmation-dialog>
        </b-form>
    </div>
</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
import AppImageInput from "@/components/app/AppImageInput";
import {deleteDocument} from "@/services/document";
import {TYPE_TAG_PHOTO} from "@/shared/constants";
import AppTranslationInput from "@/components/app/translation/AppTranslationInput";
import AppAside from "@/components/app/form/AppAside";
import {deleteTag, getTag, updateTag} from "@/services/tag";
import {notifySuccess} from "@/shared/plugins/toastr";
import routeNames from "@/router/routeNames";
import {C_SETUP_TAG_E} from "@/shared/component_permission";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppInputControl from "@/components/app/AppInputControl";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {
    TAG_ALREADY_EXIST,
    TAG_LABEL_VALIDATION_FAIL,
} from "@/shared/error_codes";
import routeName from "@/router/routeNames";
import _cloneDeep from "lodash/cloneDeep"
import {uploadOrFetchWebsiteDocument} from "@/services/direct_booking/website";

export default {
    name: "Index",
    mixins: [getErrorMessage],
    components: {
        AppInputControl,
        AppTranslationInput,
        AppImageInput,
        AppButtonDelete,
        AppButtonSubmit,
        AppButton,
        AppAside,
        AppConfirmationDialog
    },
    data() {
        return {
            C_SETUP_TAG_E,
            loading: false,
            systemLanguageList: null,
            initialTagData: null,
            tagObject: {
                image: {
                    url: null
                }
            },
            tag_id: null,
            header: null,
            showLoading: false,
            overlay: true,
            confirmation_dialog_state: false,
            name: null,
            validationObject: {
                name: {
                    input: [TAG_ALREADY_EXIST, TAG_LABEL_VALIDATION_FAIL],
                }
            }
        }
    },
    watch: {
        '$route.params.region_id': {
            handler() {
                this.getTagObject()
            }
        },
        '$route.params.id': {
            handler() {
                this.getTagObject()
            }
        },
        tagObject(value) {
            this.initialTagData = _cloneDeep(value)
        }
    },
    methods: {
        save() {
            this.loading = true

            let imagePromise = Promise.resolve()

            if (this.tagObject.image.id && this.initialTagData.image.id !== this.tagObject.image.id) {
                const data = new FormData()
                data.append('document', this.tagObject.image.id)
                imagePromise = uploadOrFetchWebsiteDocument(this.tag_id, TYPE_TAG_PHOTO, data)
            }

            if (!this.tagObject.image.id && this.initialTagData.image.id) {
                imagePromise = deleteDocument(this.tagObject.id, TYPE_TAG_PHOTO, this.initialTagData.image.id)
            }

            imagePromise.then(() => {
                return updateTag(this.tagObject.id, this.tagObject)
            }).then(response => {
                notifySuccess()
                this.tagObject = response.data
                if (this.$route.name === routeName.RN_SETUP_DESTINATION_TAG_GENERAL) {
                    this.$store.dispatch('tag/actionSetCountryTag', this.tagObject.id)
                }
            }).catch(error => {
                this.showErrorMessages(error, this.validationObject)
            }).finally(() => this.loading = false)
        },
        deleteItem() {
            this.confirmation_dialog_state = true
        },
        continueAction() {
            const deleteImagePromise = this.tagObject.image.id
                ? deleteDocument(this.tagObject.id, TYPE_TAG_PHOTO, this.initialTagData.image.id).then(() => this.tagObject.image.id = null)
                : Promise.resolve()

            return deleteImagePromise.then(() => {
                return deleteTag(this.tagObject.id)
            }).then(() => {
                notifySuccess()
                this.confirmation_dialog_state = false
                if (this.$route.params.region_id) {
                    this.$router.push({
                        name: routeNames.RN_SETUP_DESTINATION_TAG_COUNTRY_ID,
                        params: {id: this.$route.params.id}
                    })
                } else if (this.$route.params.id) {
                    this.$router.push({name: routeNames.RN_SETUP_DESTINATION_TAG_COUNTRY})
                }
            }).catch(error => {
                this.loading = false
                this.confirmation_dialog_state = false
                this.showErrorMessages(error)
            })
        },
        cancelAction() {
            this.confirmation_dialog_state = false
        },
        getTagObject() {
            if (this.$route.params.id) {
                this.tag_id = this.$route.params.id
                this.header = this.$t('COUNTRY')
            }
            if (this.$route.params.region_id) {
                this.tag_id = this.$route.params.region_id
                this.header = this.$t('REGION')
            }

            getTag(this.tag_id).then(response => {
                this.tagObject = response.data
                this.name = response.data.label
            }, error => {
                this.showErrorMessages(error)
            })
        }
    },
    mounted() {
        this.getTagObject()
    }
}
</script>

<style scoped>

</style>
