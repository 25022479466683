<template>
    <guest-backoffice-form
        :company="company"
        entity_type="company"
        @update="updateFormData">
    </guest-backoffice-form>
</template>

<script>
import GuestBackofficeForm from "@/components/guest_backoffice/GuestBackofficeForm";

export default {
    name: "Index",
    components: {GuestBackofficeForm},
    props: {
        company: {
            type: Object
        }
    },
    methods:{
        updateFormData(data){
            this.$emit('update', data)
        }
    }
}
</script>

<style scoped>

</style>
