<template>
<div>
    <b-form>
        <b-row>
            <b-col class="mb-3">
                <h4>
                    {{$t("ITEMS_SELECTED", {value: entity_id.length})}}
                </h4>
            </b-col>
        </b-row>
        <b-row v-if="loaded">
            <b-col>
                <b-tabs v-model="tab" nav-class="tab_navigation-horizontal" content-class="tab_content-horizontal"
                        pills align="left">
                    <b-tab :title="$t('DESTINATION_TAG')">
                        <b-form v-if="destinationTagOptions.length > 0" @submit.prevent="saveDestination">
                            <b-row>
                                <b-col class="mb-3" lg="4" v-if="destination.length > 0" :key="destinationIndex" v-for="(destinationObject, destinationIndex) in destinationTagOptions">
                                    <b-card no-body>

                                        <b-list-group class="repeater-2">
                                            <b-list-group-item class="list-group-item-title font-weight-bold">
                                                <form-header>{{destinationObject.label}}
                                                    <b-form-checkbox :unchecked-value="0"
                                                                     :value="1"
                                                                     :id="'destination'+destinationIndex"
                                                                     inline
                                                                     @change="updateDestination(destinationIndex, $event)"
                                                                     v-model="destination[destinationIndex].value"
                                                                     class="pull-right m-0 p-0"
                                                    >
                                                    </b-form-checkbox>
                                                </form-header>
                                            </b-list-group-item>
                                        </b-list-group>
                                    </b-card>
                                </b-col>
                            </b-row>
                                <b-row>
                                    <b-col>
                                        <app-button-submit :loading="loading" :disabled="saveDestinationDisabled"></app-button-submit>
                                    </b-col>
                                </b-row>

                        </b-form>

                    </b-tab>
                    <b-tab :title="$t('CUSTOM_TAG')">
                        <b-form v-if="customTagOptions.length > 0"  @submit.prevent="saveCustomTag">
                               <b-row>
                                   <b-col class="mb-3" lg="4"  v-if="customTagOptions.length > 0" :key="customTagIndex" v-for="(customTagObject, customTagIndex) in customTagOptions">
                                       <b-card no-body>
                                           <b-list-group class="repeater-2">
                                               <b-list-group-item class="list-group-item-title font-weight-bold">
                                                   <form-header>{{customTagObject.label}}
                                                       <b-form-checkbox :unchecked-value="0"
                                                                        :value="1"
                                                                        :id="'custm_tag'+customTagIndex"
                                                                        inline
                                                                        v-model="custom_tag[customTagIndex].value"
                                                                        class="pull-right m-0 p-0"
                                                       >
                                                       </b-form-checkbox>
                                                   </form-header>
                                               </b-list-group-item>
                                           </b-list-group>
                                       </b-card>
                                   </b-col>
                               </b-row>

                            <b-row>
                                <b-col>
                                    <app-button-submit :disabled="saveCustomTagDisabled"  :loading="loading"></app-button-submit>
                                </b-col>
                            </b-row>
                        </b-form>
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
    </b-form>

</div>
</template>

<script>
import {TAG_TYPE_CUSTOM, TAG_TYPE_DESTINATION} from "@/shared/constants";
import {
    bulkDeleteEntityTags, bulkSaveEntityTags,
    createEntityTags,
    deleteEntityTags,
    getAllTags,
    getExistingTags,
    getTagMapping
} from "@/services/tag";
import FormHeader from "@/components/app/form/FormHeader";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {toast} from "@/shared/plugins/toastr";

export default {
    name: "TagMapping",
    components: {AppButtonSubmit, FormHeader},
    props:{
        entity_id:{
            type:Array,
            default(){
                return []
            }
        },
        object_type_id:{
            type:Number
        }
    },
    data(){
        return  {
            destinationTagOptions:[],
            destination:[],
            custom_tag:[],
            customTagOptions:[],
            tab:0,
            loading:false,
            db_data_destination:[],
            db_data_custom_tag:[],
            loaded:false
        }
    },
    computed:{
        saveDestinationDisabled() {
            if (!this.hasNewValueDestination && !this.hasDeleteValueDestination) {
                return true
            }
            return false

        },
        saveCustomTagDisabled(){
            if (!this.hasNewValueCustom && !this.hasDeleteValueCustom) {
                return true
            }
            return false
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        TagDestinationIds() {
            let TagIds = []
            this.destination.forEach(item => {
                if(item.value === 1){
                    TagIds.push(item.id)
                }
            })
            return TagIds
        },
        hasNewValueDestination() {
            let hasChange = false
            if (this.TagDestinationIds.length <= this.db_data_destination.length) {
                this.TagDestinationIds.forEach(el => {
                    if (!this.db_data_destination.includes(el)) {
                        hasChange = true
                    }
                })
            } else {
                hasChange = true
            }
            return hasChange
        },

        hasDeleteValueDestination() {
            let returnValue = false
            if (this.entity_id.length === 0) {
                returnValue = false
            } else {
                this.db_data_destination.forEach(el => {
                    if (!this.TagDestinationIds.includes(el)) {
                        returnValue = true
                    }
                })
            }
            return returnValue
        },
        hasDeleteValueCustom() {
            let returnValue = false
            if (this.entity_id.length === 0) {
                returnValue = false
            } else {
                this.db_data_custom_tag.forEach(el => {
                    if (!this.TagCustomIds.includes(el)) {
                        returnValue = true
                    }
                })
            }
            return returnValue
        },
        TagCustomIds() {
            let TagIds = []
            this.custom_tag.forEach(item => {
                if(item.value === 1){
                    TagIds.push(item.id)
                }
            })
            return TagIds
        },
        hasNewValueCustom() {
            let hasChange = false
            if (this.TagCustomIds.length <= this.db_data_custom_tag.length) {
                this.TagCustomIds.forEach(el => {
                    if (!this.db_data_custom_tag.includes(el)) {
                        hasChange = true
                    }
                })
            } else {
                hasChange = true
            }
            return hasChange
        },
    },
    methods:{
        updateDestination(index, value){

            this.destination.forEach((el,index)=> {
                    this.$set(this.destination, index, {id:el.id, value:0})
            })
            this.destination[index].value =value
        },
        saveDestination() {
            this.loading = true
            let err = false
            this.setDeleteDestination().then(() => {
                if (this.hasNewValueDestination) {
                    bulkSaveEntityTags(this.object_type_id,{entity_id: this.entity_id,list:this.TagDestinationIds,tag_type_id:1}).then(response => {
                        if (this.entity_id.length === 1) {
                            this.setData(response)
                        }
                    }, error=>{
                        err = true
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }).finally(()=>{
                        this.loading = false
                        this.$emit('refreshData')
                    })
                }
                if (!err) {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                }

            })
             },
        setDeleteDestination() {
            return new Promise((resolve) => {
                if (this.entity_id.length === 0) {
                    resolve()
                    return
                }

                let deleteIds = []
                this.db_data_destination.forEach(id => {
                    if (!this.TagDestinationIds.includes(id)) {
                        deleteIds.push(id)
                    }
                })
                if (deleteIds.length === 0) {
                    resolve()
                    return
                }
                bulkDeleteEntityTags(this.object_type_id, {entity_id: this.entity_id,list:deleteIds}).then(response => {

                }).finally(()=>{
                    if(!this.hasNewValueDestination){
                        this.loading = false
                        this.$emit('refreshData')
                    }
                })
                resolve()

            })
        },
        setDeleteCustomTag(){
            return new Promise((resolve) => {
                if (this.entity_id.length === 0) {
                    resolve()
                    return
                }

                let deleteIds = []
                this.db_data_custom_tag.forEach(id => {
                    if (!this.TagCustomIds.includes(id)) {
                        deleteIds.push(id)
                    }
                })
                if (deleteIds.length === 0) {
                    resolve()
                    return
                }
                    bulkDeleteEntityTags(this.object_type_id, {entity_id: this.entity_id,list:deleteIds}).then(response => {
                        this.setData(response)
                    }).finally(()=>{
                        if(!this.hasNewValueCustom){
                            this.loading = false
                            this.$emit('refreshData')
                        }
                    })

                resolve()

            })
        },
        saveCustomTag(){
            this.loading = true
            let err = false
            this.setDeleteCustomTag().then(() => {
                if (this.hasNewValueCustom) {
                    bulkSaveEntityTags(this.object_type_id,{entity_id: this.entity_id,list:this.TagCustomIds}).then(response => {
                            if (this.entity_id.length === 1) {
                                this.setData(response)
                            }
                        },error=>{
                        err = true
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }).finally(()=>{
                            this.loading = false
                            this.$emit('refreshData')
                        })
                }
                if (!err) {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                }

            })
        },
        getTagList(type){

            let request = {
                company:this.company.id
            }
            if(type === TAG_TYPE_DESTINATION){
                request = {...request,...{children_only:true}}
            }
            getAllTags(type,request).then(response=>{
                if(type === TAG_TYPE_DESTINATION){
                    this.destinationTagOptions = response.data
                    this.destination = response.data.map(el =>{
                        return {id:el.id, value:0}
                    })
                }
                if(type === TAG_TYPE_CUSTOM){
                    this.customTagOptions = response.data
                    this.custom_tag =  response.data.map(el =>{
                        return {id:el.id, value:0}
                    })
                }
            })

        },

        fetchUnitTags(){
            getTagMapping({object_type_id:this.object_type_id, entity_id:this.entity_id}).then(response => {
                if(response.data && response.data.length > 0){
                    this.setData(response)
                }
            }).finally(()=>{
                this.loaded = true
            })
        },
        setData(response){
            if(response && response.hasOwnProperty('data') && response.data.length > 0){
                const destination_value = response.data.filter(el => el.tag_type === 1).map(item=>{
                    return item.id
                })
                this.db_data_destination = destination_value
                const custom_tag_value = response.data.filter(el => el.tag_type === 2).map(item=> {
                    return item.id
                })
                this.db_data_custom_tag = custom_tag_value
                if(this.destination.length > 0){
                    this.destination.forEach((el, destinationIndex)=>{
                        if(destination_value.includes(el.id)){
                            this.$set(this.destination, destinationIndex, {id:el.id,value:1})
                        } else  {
                            this.$set(this.destination, destinationIndex, {id:el.id,value:0})
                        }
                    })
                }
                if(this.custom_tag.length > 0){
                    this.custom_tag.forEach((el, customIndex)=>{
                        if(custom_tag_value.includes(el.id)){
                            this.$set(this.custom_tag, customIndex, {id:el.id,value:1})
                        } else {
                            this.$set(this.custom_tag, customIndex, {id:el.id,value:0})
                        }
                    })
                }
            }
        }

    },
    created() {
        this.getTagList(TAG_TYPE_CUSTOM)
        this.getTagList(TAG_TYPE_DESTINATION)

        setTimeout(()=>{

                this.fetchUnitTags()

        },500)




    }
}
</script>

<style scoped>

</style>
