<template>
    <div v-if="controls && !disabled" class="number_control" >
        <button type="button" class="number_control__button" @click.prevent="decreaseValue(stepValue)">
            <span>&#8722;</span>
        </button>

        <input type="number" class="number_control__input" :class="[classInvalid,classInput, align ? `text-${align}` : '']"
               ref="numberInput" @keypress="check" @focusout="clear"
               v-model="numeric_value" :disabled="disabled" @wheel.prevent="changeOnWheel">

        <button type="button" class="number_control__button" @click.prevent="increaseValue(stepValue)">
            <span>&#43;</span>
        </button>
    </div>

    <div v-else>
        <b-input-group>
            <template v-slot:prepend v-if="prepend">
                <b-input-group-text class="custom-number-input-control">
                    <slot name="append">
                        {{prepend}}
                    </slot>
                </b-input-group-text>
            </template>
            <input type="text" step=".000001" class="form-control" :class="[classInvalid,classInput, align ? `text-${align}` : '']"
                   :placeholder="computedPlaceholder"
                   ref="numberInput"
                   :tabindex="tabIndex"
                   v-model="numeric_value"
                   @keypress="check"
                   @focusout="clear"
                   @change="$emit('change',numeric_value)"
                   :disabled="disabled" title=""
                   @wheel.prevent="changeOnWheel">
            <template v-slot:append v-if="append">
                <b-input-group-text class="custom-number-input-control">
                    <slot name="append">
                        {{append}}
                    </slot>
                </b-input-group-text>
            </template>
        </b-input-group>
    </div>
</template>

<script>
    function countDecimals(value) {
        const string = String(value)

        if (string.indexOf('.') < 0) {
            return 0
        }

        return string.split(".")[1].length || 0;
    }

    export default {
        name: "AppNumberInput",
        props: {
            value: {
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            },
            step: {
                type: Number,
                default: 1
            },
            nullable: {
                type: Boolean,
                default: true
            },
            max: {
                type: Number,
                default: Number.POSITIVE_INFINITY
            },
            min: {
                type: Number,
                default: Number.NEGATIVE_INFINITY
            },
            controls: {
                type: Boolean,
                default: false
            },
            allowDecimal: {
                type: Boolean,
                default: false
            },
            decimals: {
                type: Number,
                default: 2
            },
            prepend: {
                type: [Boolean, String],
                default: null
            },
            append: {
                type: [Boolean, String],
                default: null
            },
            state: {
                default: null
            },
            align: {
                type: String,
                default: null
            },
            placeholder: {
                type: String,
                default: ''
            },
            tabIndex:{
                type:String|Number
            },
            classInput:{
                type:String,

            },
            scrollWheelChange: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                numeric_value: '',
                lastKey: null,
            }
        },
        computed: {
            stepValue() {
                return this.allowDecimal ? Number(this.step) : Math.ceil(Number(this.step))
            },
            classInvalid() {
                return {
                    'custom-number-input': this.controls,
                    'form-control number-invalid': this.state === false && this.controls,
                    'is-invalid': this.state === false && !this.controls,
                }
            },
            computedPlaceholder() {
                return this.placeholder
            }
        },
        watch: {
            numeric_value(newValue, oldValue) {
                if (typeof newValue === 'string' && newValue.trim() === "-" && this.lastKey === "-") return

                if (newValue === null || newValue === undefined || newValue === "" || newValue === " " || newValue === "-") {
                    if (this.nullable) {
                        this.$emit('input', null)
                    } else {
                        this.numeric_value = this.min !== Number.NEGATIVE_INFINITY ? this.min : oldValue ? oldValue : 0;
                        this.$refs.numberInput.select()
                    }
                } else if (newValue < this.min || newValue > this.max) {
                    this.numeric_value = oldValue
                } else if (countDecimals(newValue) > this.decimals) {
                    this.numeric_value = Number(newValue).toFixed(this.decimals)
                } else {
                    this.$emit('input', Number(newValue))
                }
            },
            value(value) {
                this.numeric_value = value
            }
        },
        methods: {
            check(e) {
                const value = this.numeric_value || this.numeric_value === 0 ? String(this.numeric_value) : ""
                this.lastKey = e.key

                if (!e.key.match(/[0-9.-]/)) {
                    e.preventDefault()
                }

                if (e.key === 'e') {
                    e.preventDefault()
                }

                if (this.min >= 0 && e.key === '-') {
                    e.preventDefault()
                }

                if (!this.allowDecimal && e.key === '.') {
                    e.preventDefault()
                }

                if (this.allowDecimal && e.key === '.' && (value.match(/\./g) || []).length > 0) {
                    e.preventDefault()
                }

                if (this.min < 0 && e.key === '-' && (value.match(/-/g) || []).length > 0) {
                    e.preventDefault()
                }

                if (e.key === "." && value.trim().length === 0) {
                    e.preventDefault()
                }

                if (e.key === "." && value.trim().length === 1 && value.indexOf("-") === 0) {
                    e.preventDefault()
                }
            },
            clear() {
                this.numeric_value = this.value === null ? " " : this.value;
            },
            increaseValue(step) {
                this.numeric_value = Number(this.numeric_value) + step
            },
            decreaseValue(step) {
                this.numeric_value = Number(this.numeric_value) - step
            },
            changeOnWheel({deltaY}) {
                if (!this.disabled && this.scrollWheelChange && this.$refs.numberInput === document.activeElement) {
                    if (deltaY > 0) {
                        this.decreaseValue(this.stepValue)
                    } else if (deltaY < 0) {
                        this.increaseValue(this.stepValue)
                    }
                }
            }
        },
        created() {
            this.numeric_value = this.value
        }
    }
</script>

<style scoped lang="scss">
    .custom-number-input {
        text-align: center;

        &-control {
            cursor: pointer;
        }
    }

    .number-invalid {
        border-width: 1px;
        border-style: solid;
        border-color: rgb(236, 81, 81);
        border-image: initial;
    }
</style>
