<template>
    <div :class="inline ? 'display-inline' : ''">
    <b-button
        :class="ButtonClass"
        type="reset"
        @click="$emit('click')"
        :disabled="isDisabled"
        class="app-button-reset"
    >
        <i v-if="icon" :class="icon" aria-hidden="true"></i>
        {{ $t("RESET") }}
    </b-button>
    </div>
</template>

<script>
    export default {
        name: "AppButtonReset",
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: null
            },
            inline: {
                type: Boolean,
                default: false
            },
            ButtonClass: {
                type: String,
                default: ""
            },
            c_permission: {
                type: Number,
                default: null
            },
            disabled:{
                type:Boolean,
                default:false
            }
        },
        data(){
            return {
                componentId: null,
                icons: {
                    loading: "fa fa-spinner fa-spin",
                    reset: "fa fa-undo",
                }
            }
        },
        computed: {
            icon() {
                if (this.loading) {
                    return this.icons.loading
                }
                return this.icons.reset
            },
            hasPermission() {
                if (this.c_permission !== null) {
                    return this.$store.getters['user/getPermission'](this.c_permission)
                }
                return true
            },
            isDisabled() {
                return this.disabled || this.loading || !this.hasPermission
            },
        },
        created() {
            this.componentId = this.id
            if (this.componentId === null) {
                this.componentId = 'AppButton_' + this._uid
            }
        }
    }
</script>

<style scoped>
    .app-button-reset {
        white-space: nowrap;
    }

    .display-inline {
        display: inline-block;
    }
</style>
