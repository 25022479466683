<template>
    <div class="mini_calendar">
        <h5 class="month_header mb-0 text-capitalize">
            {{ monthDateStart.format('MMMM') }}
        </h5>
        <div class="weekday">
            <div>{{ $t("DATE.DAYS.MONDAY") }}</div>
            <div>{{ $t("DATE.DAYS.TUESDAY") }}</div>
            <div>{{ $t("DATE.DAYS.WEDNESDAY") }}</div>
            <div>{{ $t("DATE.DAYS.THURSDAY") }}</div>
            <div>{{ $t("DATE.DAYS.FRIDAY") }}</div>
            <div>{{ $t("DATE.DAYS.SATURDAY") }}</div>
            <div>{{ $t("DATE.DAYS.SUNDAY") }}</div>

        </div>
        <div class="datelist">
            <year-calendar-item-cell
                :key="index"
                :unit="unit"
                :availability="availability"
                :roomStatus="roomStatus"
                :isContigent="isContigent"
                :day="day"
                :days="days"
                :dayIndex="index"
                :column="index+1"
                v-if="day.showCell"
                v-for="(day, index) in cellObjectList"></year-calendar-item-cell>

        </div>
    </div>
</template>

<script>
import moment from 'moment'
import YearCalendarItemCell from "@/components/unit/calendar_opt/Table/YearCalendar/YearCalendarItemCell";
import {DISTRIBUTION_PHOTO} from "@/services/endpoints";
import i18n from "@/locale";
import {C_RESERVATION_ACCOMMODATION_BOOKING_SITE} from "@/shared/component_permission";
import {fetchAccessControlData} from "@/services/access";
import {AC_FEATURE_SETUP} from "@/mixins/AccessControl/AccessControlEnumeration";

export default {
    name: "YearCalendarItem",
    components: {YearCalendarItemCell},
    props: {
        year: {
            type: Number
        },
        month: {
            type: Number
        },
        availability: {
            type: Object
        },
        roomStatus: {
            type: Object
        },
        unit: {
            type: Object
        },
        isContigent: {
            type: Boolean
        },
        reservations: {
            type: Array
        },
        ac_response_value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            days: [],
            cellObjectList: [],
        }
    },
    methods: {
        getCellObjectList(dates) {

            if (Array.isArray(dates) && dates.length > 0) {
                return dates.map((date, cellIndex) => {

                    const dayBefore = date.clone().subtract(1, 'days')
                    const dayAfter = date.clone().add(1, 'days')
                    const dayBeforeF = dayBefore.format('YYYY-MM-DD')
                    const dayAfterF = dayAfter.format('YYYY-MM-DD')
                    const dateF = date.format('YYYY-MM-DD')

                    const reservationDataObject = this.isContigent ? {} : this.getCellObjectReservationData(date, dayBefore, dayAfter, cellIndex)

                    const colspan = this.contigent ? 1 : Math.min(7, this.getCellObjectColumnSpan(reservationDataObject, date, cellIndex,))

                    const showCell = this.isContigent ? true : this.calculateShowCell(reservationDataObject, date, dateF, colspan)

                    // const label = this.isContingentContainerRow && this.availabilityForUnit[dateF]  ? this.availabilityForUnit[dateF].available_count : ''
                    // const note =  !this.isContingentContainerRow && this.availabilityForUnit[dateF].hasOwnProperty('noteObject') ? this.availabilityForUnit[dateF].noteObject : null
                    // const width = this.getWidth(reservationDataObject, colspan, cellIndex+1)
                    // const min_guest_width = 0.78
                    const column = cellIndex + 1
                    const isCheckInThisWeek = reservationDataObject ? reservationDataObject.reservation ?
                        moment(reservationDataObject.reservation.check_in_date).isoWeek() === date.isoWeek()
                        && moment(reservationDataObject.reservation.check_in_date).format('MM')===date.format('MM')
                        : false : false
                    const isCheckOutThisWeek = reservationDataObject ? reservationDataObject.reservation ?
                        moment(reservationDataObject.reservation.check_out_date).isoWeek() === date.isoWeek()
                        && moment(reservationDataObject.reservation.check_out_date).format('MM')===date.format('MM')
                        : false : false
                    const hasReservationOnDayBefore = reservationDataObject ? reservationDataObject.hasReservationOnDayBefore : 0

                    return {
                        date,
                        // label,
                        showCell,
                        colspan,
                        isCheckInThisWeek,
                        isCheckOutThisWeek,
                        popup_title: reservationDataObject.reservation ? reservationDataObject.reservation.check_in_date + ' - ' + reservationDataObject.reservation.check_out_date : null,
                        // lastDayOfMonthClass: {'main_calendar-br_green': date.date() === date.daysInMonth()},
                        // reservationClasses: this.getReservationClasses(this.availabilityForUnit[dayBeforeF], reservationDataObject),
                        hasReservationOnDayBefore: reservationDataObject ? reservationDataObject.hasReservationOnDayBefore : 0,
                        hasReservationOnDayAfter: reservationDataObject ? reservationDataObject.hasReservationOnDayAfter : 0,
                        //width:width,
                        // guest_width: (width-3) > min_guest_width ? width-3 : reservationDataObject && reservationDataObject.hasReservationOnDayBefore ? min_guest_width : width - 3,
                        reservation: reservationDataObject.reservation ? reservationDataObject.reservation : null,
                        showResPopup: {show: false, enter: false},
                        showNotePopup: {show: false, enter: false},
                        // note:this.formatNote(note),
                        translations: this.getTranslations(),
                        // note_highlight:this.setNoteKey(this.availabilityForUnit[dateF], note),
                        availabilityObject: this.availabilityForUnit[dateF],
                        // unit:this.unit.id,
                        // isContigent: this.isContigent,
                        //showNumericSpan: this.showNumSpan(this.availabilityForUnit[dateF]),
                        // showOpenIndicator:this.showGreenIndicator(this.availabilityForUnit[dateF])
                        widthGuest: this.widthGuest(hasReservationOnDayBefore, reservationDataObject.reservation, colspan, column),
                        show_channel_data: this.ac_response_value
                    }
                })
            } else {
                return []
            }
        },
        widthGuest(hasReservationOnDayBefore, reservation, colspan, column) {
            let width = 0
            if (reservation && reservation.num_nights === 1) {
                width = (colspan * 2.5) + 0.2
                return width
            } else if (column === 1 && reservation) {
                width = colspan * 2.5 + 0.8
                return width
            } else {
                width = colspan * 2.5
                return width
            }
        },
        getCellObjectColumnSpan({reservation, hasReservationOnDay}, date, cellIndex) {
            if (reservation && reservation.hasOwnProperty('num_nights')) {
                let checkInDate = moment(reservation.check_in_date)
                const checkOutDate = moment(reservation.check_out_date)
                const endOfMonth = moment(date).endOf('month')
                const startOfMonth = moment(date).startOf('month')

                if (checkInDate.format('YYYY-MM-DD') < startOfMonth.format('YYYY-MM-DD')) {
                    checkInDate = moment(startOfMonth)
                }

                const remainingDays = checkOutDate.diff(checkInDate, 'days')
                // calculated days till end of week + start day
                const daysUntilWeekend = 7 - checkInDate.isoWeekday() + 1


                if(checkInDate.format('YYYY-MM-DD')===endOfMonth.format('YYYY-MM-DD')) return 1

                const daysUntilMonth = endOfMonth.diff(date, 'days') + 1
                if (date.isoWeekday() === 1 && date === checkOutDate) {
                    return 1
                }

                if (date.isoWeekday() === 1 && date < checkOutDate) {
                    let day_diff = checkOutDate.diff(date, 'days')
                    if (daysUntilMonth < day_diff){
                        return daysUntilMonth
                    }
                    if (day_diff > 7) {
                        return 7
                    } else {
                        return day_diff
                    }

                }

                if (date.isoWeekday() === 7) {
                    return 1
                }
                if (reservation.num_nights > daysUntilWeekend) {
                    if (daysUntilMonth < daysUntilWeekend) return daysUntilMonth
                    if (remainingDays < daysUntilWeekend) return remainingDays
                    return daysUntilWeekend;
                }
                if (reservation.num_nights > daysUntilMonth) {
                    if (remainingDays < daysUntilMonth) return remainingDays
                    return daysUntilMonth;
                }

                if (remainingDays < reservation.num_nights) return remainingDays
                return reservation.num_nights
            }

            if (hasReservationOnDay && !reservation) {
                return 0
            }

            return 1
        },

        calculateShowCell(reservation, date, dateF, colspan) {
            if (colspan > 0) {
                if (this.availabilityForUnit[dateF].reservation.length === 0) {
                    return true
                }
                if (reservation && reservation.reservation) {
                    if (date.format('YYYY-MM-DD') === reservation.reservation.check_in_date || date.isoWeekday() === 1){
                        return true
                    }

                    const startOfMonth = moment(date).startOf('month')
                    if (date.format('YYYY-MM-DD') === startOfMonth.format('YYYY-MM-DD')){
                        return true
                    }

                }
                if(!reservation.hasReservationOnDay && (reservation && !reservation.reservation)){
                    return true
                }
                return false
            }
            return true

        },
        getCellObjectReservationData(date, dayBefore, dayAfter, cellIndex) {
            const reservationDataObject = {
                reservation: null,
                hasReservationOnDayBefore: null,
                hasReservationOnDayAfter: null,
                hasReservationOnDay: null,
                hasOneNightReservation: null
            }

            let reservation = null

            reservation = this.reservations.find(r => date >= moment(r.check_in_date) && date < moment(r.check_out_date))


            if (reservation) {
                if (reservation.distribution) {
                    reservation.channel_icon = DISTRIBUTION_PHOTO(reservation.distribution)
                } else {
                    reservation.channel_icon = DISTRIBUTION_PHOTO(0)
                }
                reservation.formatted_name = `${(reservation.first_name ? reservation.first_name.substr(0, 1) + '. ' : '')}${reservation.last_name}`
                reservation.formated_name_count = reservation.formatted_name.length
                reservation.formatted_check_in_time = this.formatTime(reservation.check_in_time)
                reservation.formatted_check_in_date = this.formatDate(reservation.check_in_date)
                reservation.formatted_check_out_date = this.formatDate(reservation.check_out_date)
                reservation.formatted_created_date = this.formatDate(reservation.created_date) + ' ' + this.formatTime(reservation.created_date)
                reservation.formatted_updated_date = this.formatDate(reservation.updated_date) + ' ' + this.formatTime(reservation.updated_date)
            }

            reservationDataObject.reservation = reservation
            reservationDataObject.hasReservationOnDayBefore = !!this.reservations.find(r => (dayBefore >= moment(r.check_in_date) && dayBefore < moment(r.check_out_date)))
            reservationDataObject.hasReservationOnDayAfter = !!this.reservations.find(r => (dayAfter >= moment(r.check_in_date) && dayAfter < moment(r.check_out_date)))
            reservationDataObject.hasReservationOnDay = !!this.reservations.find(r => (date >= moment(r.check_in_date) && date < moment(r.check_out_date)))

            return reservationDataObject
        },

        showNumSpan(availabilityObject) {
            const available = availabilityObject.available ? 1 : 0
            if (this.isContingentContainerRow) {
                const available_contingent = availabilityObject.available_count > 1 || (availabilityObject.available_count > 0 && !availabilityObject.open_for_sale) ? true : false
                return {
                    available_count: available_contingent,
                    room_status: availabilityObject.available_count === 0 && availabilityObject.open_for_sale
                }
            } else if (this.contigent.length === 0 && this.isRoot) {
                return {
                    available_count: available && !availabilityObject.open_for_sale,
                    room_status: !available && availabilityObject.open_for_sale
                }
            } else {
                return {
                    available_count: false,
                    room_status: false
                }
            }
        },
        formatTime(value) {
            let format = i18n.t('DATE.HOUR_MIN_FORMAT')

            if (format === null) {
                format = "HH:mm"
            }

            if (value) {
                if (typeof value === 'number') {
                    return moment.unix(value).format(format)
                } else if (typeof value === 'string') {
                    if ((new Date(value)).getTime() > 0) {
                        return moment(value).format(format)
                    } else {
                        return moment(value, "HH:mm").format(format)
                    }
                }
            } else {
                return value
            }
        },
        formatDate(value) {
            const year = true
            if (!value) return

            let format = (year) ? i18n.t('DATE.DATE_FORMAT') : i18n.t('DATE.DATE_FORMAT_SANS_YEAR')

            if (format === null) {
                format = "YYYY-MM-DD"
            }

            if (typeof value === 'number') {
                return moment.unix(value).format(format)
            }

            return moment(String(value)).format(format)
        },
        formatTimeStamp(value) {
            let format = i18n.t('DATE.TIMESTAMP_FORMAT')
            if (format === null) {
                format = "YYYY-MM-DD HH:mm"
            }
            return moment(String(value)).format(format)
        },
        getTranslations() {
            return {
                RESERVATION_CODE: this.$t('RESERVATION_CODE'),
                SALES_CHANNEL: this.$t('SALES_CHANNEL'),
                GUEST: this.$t('GUEST'),
                ADULTS: this.$t('ADULTS'),
                CHILDREN: this.$t('CHILDREN'),
                BABIES: this.$t('BABIES'),
                TOTAL: this.$t('TOTAL'),
                ARRIVAL_TIME: this.$t('ARRIVAL_TIME'),
                BOOKED_AT: this.$t('BOOKED_AT'),
                UPDATED_AT: this.$t('UPDATED_AT'),
                NOTE: this.$t('NOTE'),
                CLOSED_AT: this.$t('CLOSED_AT'),
                USER: this.$t('USER')
            }
        },
        getUnitNote(availabilityObject) {
            let noteObject = null
            if (availabilityObject.hasOwnProperty('note')) {
                noteObject = availabilityObject.note
            }
            return noteObject
        },
    },
    computed: {
        monthDateStart() {
            return moment().set({year: this.year, month: this.month}).startOf('month')
        },
        availabilityForUnit() {
            return Object.entries(this.availability).reduce((acc, [key, value]) => {
                acc[key] = {
                    ...value,
                    available: value.available_unit.includes(this.unit.id),
                    available_count: value ? value.available_unit.length : null,
                    noteObject: this.getUnitNote(value),
                    open_for_sale: value.open_for_sale
                }

                return acc
            }, {})
        }
    },

    mounted() {
        this.days = [...Array(this.monthDateStart.daysInMonth())].map((_, i) => {
            return this.monthDateStart.clone().add(i, 'day')
        })
        //this.cellObjectList = this.getCellObjectList(this.days)
    },
    watch: {
        availability: {
            immediate: true,
            handler(value) {
                if (Object.keys(value).length && this.days.length) {
                    this.cellObjectList = []
                    this.days = [...Array(this.monthDateStart.daysInMonth())].map((_, i) => {
                        return this.monthDateStart.clone().add(i, 'day')
                    })
                    this.cellObjectList = this.getCellObjectList(this.days)
                }
            }
        },
    }
}
</script>

<style scoped>

</style>
