<template>
    <a :class="classList" v-bind="attributes" tabindex="-1" v-on:click.stop.prevent v-if="isDisabled">
        <i :class="classIcon"></i> <span>{{$t(name)}}</span>
        <b-badge v-if="badge && badge.text" :variant="badge.variant">{{badge.text}}</b-badge>
    </a>
    <a :href="url" :class="classList" v-bind="attributes" v-else-if="isExternalLink">
        <i :class="classIcon"></i> {{$t(name)}}
        <b-badge v-if="badge && badge.text" :variant="badge.variant">{{badge.text}}</b-badge>
    </a>
    <router-link :to="url" :class="classList" @click.native="handleClick" v-bind="attributes" v-else>
        <template v-if="icon.includes('fas')">
         <!--   {{icon.substring(3,50).trim()}}-->
            <font-awesome-icon class="nav-icon" :icon="icon.substring(3,50).trim()" />
        </template>
        <i v-else :class="classIcon"></i> {{$t(name)}}
        <b-badge v-if="badge && badge.text" :variant="badge.variant">{{badge.text}}</b-badge>
    </router-link>
</template>

<script>
    export default {
        name: 'sidebar-nav-link',
        props: {
            name: {
                type: String,
                default: ''
            },
            url: {
                type: String | Object,
                default: ''
            },
            icon: {
                type: String,
                default: ''
            },
            badge: {
                type: Object,
                default: () => {
                }
            },
            variant: {
                type: String,
                default: ''
            },
            classes: {
                type: [String, Array, Object],
                default: ''
            },
            attributes: {
                type: Object,
                default: () => {
                    return Object.create(null)
                }
            }
        },
        computed: {
            classList() {
                return [
                    'nav-link',
                    this.linkVariant,
                    ...this.disabledClasses,
                    ...this.attrClasses,
                    ...this.itemClasses,
                    ...this.isUrlActive,
                ]
            },
            classIcon() {
                return [
                    'nav-icon',
                    this.icon
                ]
            },
            linkVariant() {
                return this.variant ? `nav-link-${this.variant}` : ''
            },
            itemClasses() {
                return this.getClassArray(this.classes)
            },
            attrClasses() {
                return this.getClassArray(this.attributes.class)
            },
            disabledClasses() {
                return this.isDisabled ? 'disabled'.split(' ') : []
            },
            isDisabled() {
                return Boolean(this.attributes.disabled)
            },
            isExternalLink() {
                return typeof this.url === 'string' && Boolean(this.url.substring(0, 4) === 'http')
            },
            isUrlActive() {
                if (this.resolvedUrl && this.$route.matched.some(matchedUrl => matchedUrl.path === this.resolvedUrl.href)) {
                    return ['active']
                }
                return []
            },
            resolvedUrl() {
                return this.$router.resolve(this.url)
            }
        },
        methods: {
            getClassArray(classes) {
                return !classes ? [] : typeof classes === 'string' || classes instanceof String ? classes.split(' ') : Array.isArray(classes) ? classes : Object.keys(classes).filter(i => classes[i])
            },
            handleClick() {

                this.$emit('click', this.url)
            }
        }
    }
</script>
