import http from '../http'
import {SYSTEM_DATA_URL, SYSTEM_SETTINGS_URL} from "@/services/endpoints";


export function getSystemLanguageList(params = null) {
    return http.request({
        url: SYSTEM_DATA_URL + '/language',
        method: 'get',
        params: params
    })
}

export function getTranslateList() {
    return http.request({
        url: SYSTEM_DATA_URL + '/language/translate/list',
        method: 'get',
    })
}

export function getTranslations(id, params) {
    return http.request({
        url: SYSTEM_DATA_URL + '/translations/' + id,
        method: 'get',
        params
    })
}

export function updateTranslations(id, data) {
    return http.request({
        url: SYSTEM_DATA_URL + '/translations/' + id,
        method: 'put',
        data: data
    })
}

export function getChargePersonType(id, params) {
    return http.request({
        url: SYSTEM_DATA_URL + '/charge-person-type',
        method: 'get',
        params
    })
}

export function getSystemFees(params) {
    return http.request({
        url: SYSTEM_SETTINGS_URL + '/fees',
        method: 'get',
        params,
    })
}

export function saveSystemFees(data) {
    return http.request({
        url: SYSTEM_SETTINGS_URL + '/fees',
        method: 'post',
        data,
    })
}

export function copySystemFees(data) {
    return http.request({
        url: SYSTEM_SETTINGS_URL + '/fees/copy',
        method: 'post',
        data,
    })
}
