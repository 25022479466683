<template>
    <b-form @submit.prevent>
        <form-wizard ref="wizard" :startIndex="currentIndex" transition="fade-in" color=var(--color-primary) errorColor=var(--color-warning)
                     name="marketplaceWizard" title="" subtitle=""
                     @on-complete="save"
                     v-if="finish === false">
            <tab-content name="general" :title="$t('GENERAL')" :before-change="() => validateSelected()">
                <form>
                    <b-row>
                        <b-col>
                            <app-input-control :error-object="validation.description">
                                <template v-slot:input="data">
                                    <label>{{ $t('DESCRIPTION') }}</label>
                                    <b-form-textarea
                                        rows="4"
                                        v-model="websiteDesc"
                                        maxlength="1000"
                                        :state="data.state"
                                    >
                                    </b-form-textarea>
                                </template>
                            </app-input-control>
                        </b-col>
                        <b-col>
                            <app-input-control :error-object="validation.tos">
                                <template v-slot:input="data">
                                    <label>{{ $t('TERMS_OF_SERVICE') }}</label>
                                    <b-form-textarea
                                        rows="4"
                                        v-model="tos"
                                        :state="data.state"
                                    >
                                    </b-form-textarea>
                                </template>
                            </app-input-control>
                        </b-col>
                    </b-row>
                    <b-row class="mt-4">
                        <b-col md="6">
                            <app-input-control :error-object="validation.logo">
                                <template v-slot:input="data">
                                    <app-image-input :label="$t('LOGO')"
                                                     :image="logo.logo_id"
                                                     :preview="logo.logo_url"
                                                     :state-validation="data.state"
                                                     @input="logo.logo_id = $event"></app-image-input>
                                </template>
                            </app-input-control>
                        </b-col>
                        <b-col md="6">
                            <app-input-control :error-object="validation.workflow">
                                <template v-slot:input="data">
                                    <label>{{ $t("WORKFLOW") }}</label>
                                    <app-select :options="marketplaceOptions"
                                                v-model="workflow"
                                                :state="data.state"
                                                text-field="label"
                                                :search-empty-item="false"
                                    ></app-select>
                                </template>
                            </app-input-control>
                        </b-col>
                        <b-col></b-col>
                    </b-row>
                </form>
            </tab-content>
            <tab-content name="rules" :title="$t('PARTNER_ACTIVATION_RULES')" :before-change="() => validateSelected()">
                <form>
                    <b-row class="mb-4">
                        <b-col>
                            <label>{{ $t('COUNTRY') }}</label>
                            <app-select mode="multiselect" text-field="name"
                                        :options="countryList" v-model="country">
                            </app-select>
                        </b-col>
                        <b-col>
                            <label>{{ $t('CITY') }}</label>
                            <app-select mode="multiselect" text-field="name"
                                        v-model="city" :options="cityList">
                            </app-select>
                        </b-col>
                        <b-col>
                            <label>{{ $t('MIN_NUMBER_OF_UNITS') }}</label>
                            <b-input type="number" v-model="number_of_units"></b-input>
                        </b-col>
                    </b-row>
                </form>
            </tab-content>
            <button class="btn btn-outline-primary" slot="prev" :disabled="loadingFinnish">{{ $t('PREVIOUS') }}</button>
            <button class="btn btn-primary" slot="next">
                {{ $t('NEXT') }}
            </button>
            <button class="btn btn-primary" slot="finish" :disabled="loadingFinnish">
                <i v-if="loadingFinnish"
                   class="mr-1 fa fa-spinner fa-spin fa-fw"
                   aria-hidden="true">
                </i>
                {{ $t('FINISH') }}
            </button>
        </form-wizard>
        <app-wizard-complete v-if="finish" :showCloseButton="false" class="wizard-complete">
            <template v-slot:title>
                <b-img class="search_init_icon" style="height: 15rem" src="/img/icons/document_icon.png"></b-img>
            </template>
            <template v-slot:body>
                <h4 style="padding-top: 2rem" class="text-primary font-weight-light">
                    {{ $t('ACTIVATED_MARKETPLACE') }}</h4>
            </template>
        </app-wizard-complete>
    </b-form>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import {EventBus, V_ERROR} from "@/shared/EventBus";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppWizardComplete from "@/components/app/Wizard/AppWizardComplete";
import AppImageInput from "@/components/app/AppImageInput";
import AppInputControl from "@/components/app/AppInputControl";
import {getCityList, getCountries} from "@/services/location";
import {
    createMarketplace,
    getMarketplaceDescriptions,
    getMarketplaceSetup, getMarketplaceWorkflowOptions,
    getUnitTypeList
} from "@/services/marketplace";
import {
    MARKETPLACE_WEBSITE_ACTIVATION,
    MARKETPLACE_UNIT_ACTIVATION,
    MARKETPLACE_DESCRIPTION,
    MARKETPLACE_TOS,
    MARKETPLACE_WORKFLOW, TYPE_WEBSITE_LOGO, TYPE_WEBSITE_FAVICON
} from "@/shared/constants";
import {
    MARKETPLACE_DESCRIPTION_LENGTH_NOT_VALID,
    MARKETPLACE_DESCRIPTION_NOT_FOUND,
    MARKETPLACE_LOGO_NOT_FOUND,
    MARKETPLACE_TERMS_OF_SERVICE_LENGTH_NOT_VALID,
    MARKETPLACE_TOS_NOT_FOUND,
    MARKETPLACE_WORKFLOW_NOT_FOUND
} from "@/shared/error_codes";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {notifySuccess} from "@/shared/plugins/toastr";
import FormHeader from "@/components/app/form/FormHeader";
import {uploadOrFetchWebsiteDocument} from "@/services/direct_booking/website";

export default {
    name: "MarketplaceActivationWizard",
    components: {
        FormWizard,
        TabContent,
        AppSelect,
        AppWizardComplete,
        FormHeader,
        AppImageInput,
        AppInputControl,
    },
    mixins: [getErrorMessage],
    props: {
        websiteId: {
            type: Number,
            default: null
        },
        distributionId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            currentIndex: 0,
            finish: false,
            loadingFinnish: false,
            countryList: [],
            country: [],
            cityList: [],
            city: [],
            number_of_units: null,
            unitTypes: [],
            unit_type: [],
            leadDescriptions: [],
            lead_desc: [],
            descData: [],
            description: [],
            rulesData: [],
            houseRules: [],
            workflow: null,
            rates: [],
            images: null,
            location: false,
            descriptionPlaceholder: this.$t('DESCRIPTION_MARKETPLACE'),
            websiteDesc: null,
            tosPlaceholder: this.$t('TOS_DESCRIPTION'),
            tos: null,
            marketplaceOptions: [],
            MARKETPLACE_WORKFLOW,
            ratePlanOptions: [
                {id: 1, name: 'Standard rate'},
                {id: 2, name: 'Non-refundable rate'}
            ],
            logo: {
                logo_id: null,
                logo_url: null
            },
            logoValidation: false,
            initialLogoId: null,
            validation: {
                logo: MARKETPLACE_LOGO_NOT_FOUND,
                workflow: MARKETPLACE_WORKFLOW_NOT_FOUND,
                description: [MARKETPLACE_DESCRIPTION_NOT_FOUND, MARKETPLACE_DESCRIPTION_LENGTH_NOT_VALID],
                tos: {
                    input: [MARKETPLACE_TOS_NOT_FOUND, MARKETPLACE_TERMS_OF_SERVICE_LENGTH_NOT_VALID]
                },
            },
        }
    },
    methods: {
        handleNextStep(isValid, tabIndex) {
            if (!isValid) {
                return
            }
            EventBus.$emit(V_ERROR, [])
            this.currentIndex = tabIndex++
            localStorage.setItem('marketplace_activation_wizard_temp', JSON.stringify(this.$data))
        },
        save() {
            this.loadingFinnish = true
            this.handleImageSave()
            createMarketplace({
                website_id: this.websiteId,
                data: this.prepareData(),
                unit_data: this.prepareUnitActivation()
            }).then(response => {
                notifySuccess()
                this.finish = true
                EventBus.$emit('refreshWebsiteTable')
                this.loadingFinnish = false
            }, error => {
                this.showErrorMessages(error)
                this.loadingFinnish = false
            })
        },
        validateSelected() {
            this.logo.logo_id ? this.logoValidation = true : this.logoValidation = false
            return createMarketplace({
                step: true,
                logo: this.logoValidation,
                description: this.websiteDesc,
                tos: this.tos,
                workflow: this.workflow
            }).then(() => {
                return true
            }, error => {
                this.showErrorMessages(error, this.validation)
                return false
            })
        },
        handleImageSave() {
            if (!this.logo.logo_id || this.logo.logo_id === this.initialLogoId) {
                return
            }
            const fileData = new FormData()
            fileData.append('document', this.logo.logo_id)
            uploadOrFetchWebsiteDocument(this.websiteId, TYPE_WEBSITE_LOGO, fileData).then(() => {
            }, error => {
                this.showErrorMessages(error)
            })
            uploadOrFetchWebsiteDocument(this.websiteId, TYPE_WEBSITE_FAVICON, fileData).then(() => {
            }, error => {
                this.showErrorMessages(error)
            })
        },
        prepareData() {
            return [
                {
                    id: MARKETPLACE_WEBSITE_ACTIVATION,
                    workflow: {id: this.workflow, initial_status: null},
                    activation_rules: {
                        country: this.country,
                        city: this.city,
                        unit: this.number_of_units === null ? null : {min_number: this.number_of_units},
                    }
                },
                {
                    id: MARKETPLACE_DESCRIPTION,
                    lang: this.websiteDesc,
                    lang_id: null
                },
                {
                    id: MARKETPLACE_TOS,
                    lang: this.tos,
                    lang_id: null
                }
            ]
        },
        prepareUnitActivation() {
            return [
                {
                    id: MARKETPLACE_UNIT_ACTIVATION,
                    activation_rules: {
                        type: this.unit_type,
                        description: {
                            lead_description: this.lead_desc,
                            description: this.description,
                            house_rules: this.houseRules
                        },
                        image: {min_number: this.images},
                        rate_plan: {type: this.rates},
                    }
                }
            ]
        }
    },
    created() {
        getCountries({user_country: true}).then(response => {
            this.countryList = response.data
        })
        getCityList({user_cities: true}).then(response => {
            this.cityList = response.data
        })
        getUnitTypeList().then(response => {
            this.unitTypes = response.data
        })
        getMarketplaceDescriptions().then(response => {
            this.descData = response.data
        })

        getMarketplaceWorkflowOptions().then(response => {
            this.marketplaceOptions = response.data
        })

        getMarketplaceSetup({website_id: this.websiteId}).then(response => {
            if (response.data !== null) {
                response.data.forEach(el => {
                    if (el.id === 'website_activation') {
                        this.country = el.activation_rules.country
                        this.city = el.activation_rules.city
                        this.number_of_units = el.activation_rules.unit === null ? null : el.activation_rules.unit.min_number
                        this.workflow = el.workflow.id
                    }
                    if (el.id === 'description') {
                        this.websiteDesc = el.lang
                    }
                    if (el.id === 'tos') {
                        this.tos = el.lang
                    }
                    if (el.id === 'logo') {
                        this.logo.logo_id = el.logo_id
                        this.logo.logo_url = el.logo_url
                    }
                })
            }
            this.initialLogoId = this.logo.logo_id
        })
    },
    watch: {
        country: {
            handler() {
                getCityList({user_cities: true, country: this.country}).then(response => {
                    this.cityList = response.data
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
