<template>
    <b-form @submit.prevent="save" v-if="formData">
        <b-row v-if="changeCompanyPermission">
            <b-col xl="6" class="mb-4">
                <label>{{ $t('COMPANY') }}</label>
                <app-select mode="search"
                            v-model="company"
                            :search="searchCompany"
                            :search-empty-item="true"></app-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="6" class="mb-4">
                <label>{{ $t('PROPERTY') }}</label>
                <app-select mode="search"
                            v-model="property"
                            :disabled="changeCompanyPermission && !company.id"
                            :search="searchProperty"
                            :search-on-create="preloadPropertyList" set-first :search-empty-item="true">
                    <template v-slot:text="data" v-if="viewPropertyNamesWithIds">
                        {{ data.item && data.item.id ? data.item.name + " (" + data.item.id + ")" : null}}
                    </template>
                </app-select>
            </b-col>
            <b-col xl="6" class="mb-4">
                <app-input-control :error-object="validation.unit">
                    <template v-slot:input="data">
                        <label>{{ $t('UNIT') }}</label>
                        <app-select :disabled="!unitList.length" :options="unitList" :state="data.state" set-first
                                    :search-empty-item="true"
                                    v-model="formData.unit.id"></app-select>
                    </template>
                </app-input-control>
            </b-col>
            <b-col xl="6" class="mb-4">
                <app-input-control :error-object="validation.rate_plan">
                    <template v-slot:input="data">
                        <label>{{ $t('RATE_PLAN') }}</label>
                        <app-select :disabled="!ratePlanList.length"
                                    :options="ratePlanList"
                                    :state="data.state"
                                    set-first
                                    :search-empty-item="true"
                                    v-model="formData.rate_plan.id">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>
            <b-col xl="6" class="mb-4">
                <app-input-control :error-object="validation.period">
                    <template v-slot:input="data">
                        <label>{{ $t("PERIOD") }}</label>
                        <app-date-range-picker v-model="period" :state="data.state"></app-date-range-picker>
                    </template>
                </app-input-control>
            </b-col>
            <b-col xl="6" class="mb-4">
                <app-input-control :error-object="validation.status">
                    <template v-slot:input="data">
                        <label>{{ $t("STATUS") }}</label>
                        <app-select v-model="formData.status.id"
                                    :options="statusList"
                                    :state="data.state"
                                    :search-empty-item="false"
                                    text-field="label">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>
            <b-col xl="6" class="mb-4">
                <app-input-control>
                    <template v-slot:input="data">
                        <label>{{ $t("CURRENCY") }}</label>
                        <app-select mode="search" :search="searchCurrency" search-on-create text-field="code"
                                    :search-empty-item="false"
                                    v-model="formData.channel_currency" :state="data.state">
                        </app-select>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-4">
                <label class="label">{{ $t('NOTE') }}</label>
                <quill-editor v-model="note"
                              :options="{modules: {toolbar: false}}"
                              disabled class="finance-qe">
                </quill-editor>
            </b-col>
        </b-row>
        <template v-if="viewMappingPermission">
            <b-row>
                <b-col>
                    <b-table class="table b-table" hover responsive
                             :fields="mappingTableFields"
                             :items="mappingList"
                             show-empty>

                        <template v-slot:empty="data">
                            <div v-cloak class="empty_table_content mt-2">
                                <h4 class="text-primary font-weight-light">{{ $t("NO_RESULTS_FOUND") }}</h4>
                            </div>
                        </template>

                        <template v-slot:company="data">
                            {{ data.item.company.name }}
                        </template>

                        <template v-slot:property="data">
                            <template v-if="data.item.property">
                                <small class="text-muted d-block" v-if="data.item.property.hasOwnProperty('active_mapping') && !data.item.property.active_mapping">
                                    <!--
                                    <span class="mr-1">{{ data.item.property.id }}</span>
                                    -->
                                    <b-badge
                                        variant="primary"
                                        pill
                                        class="inactive-tag">
                                        {{ data.item.property.incorrect_mapping ? $t("INCORRECT_MAPPING") :  $t("MAPPING_NOT_ACTIVE")}}
                                    </b-badge>
                                </small>
                                <!--
                                <small class="text-muted d-block" v-else>
                                    {{ data.item.property.id }}
                                </small>
                                -->
                                {{ data.item.property.name }}
                            </template>
                        </template>

                        <template v-slot:unit="data">
                            <template v-if="data.item.unit">
                                <small class="text-muted d-block" v-if="!data.item.unit.active_mapping">
                                    <b-badge
                                        variant="primary"
                                        pill
                                        class="inactive-tag">
                                        {{ data.item.unit.incorrect_mapping ? $t("INCORRECT_MAPPING") :  $t("MAPPING_NOT_ACTIVE")}}
                                    </b-badge>
                                </small>
                                {{ data.item.unit.name }}
                            </template>
                        </template>

                        <template v-slot:rate_plan="data">
                            <template v-if="data.item.rate_plan">
                                <small class="text-muted d-block" v-if="!data.item.rate_plan.active_mapping">
                                    <b-badge
                                        variant="primary"
                                        pill
                                        class="inactive-tag">
                                        {{ data.item.rate_plan.incorrect_mapping ? $t("INCORRECT_MAPPING") :  $t("MAPPING_NOT_ACTIVE")}}
                                    </b-badge>
                                </small>
                                {{ data.item.rate_plan.name }}
                            </template>
                        </template>

                    </b-table>
                </b-col>
            </b-row>
        </template>
        <b-row>
            <b-col class="mt-4">
                <app-button-submit :loading="saving" :disabled="loading"></app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>

import FormHeader from "@/components/app/form/FormHeader";
import {
    fetchAccommodationReservation, fetchAccommodationReservationNotes,
    updateUnknownReservation
} from "@/services/accommodation_reservation";
import {fetchStatusList} from "@/services/status";
import {
    DEFAULT_PAGINATION_LIMIT,
    LS_PRESETS,
    OBJECT_TYPE_ACCOMMODATION_RESERVATION, RATE_PLAN_CATEGORY_DISTRIBUTION,
    RES_NOTE_TYPE_PRIVATE
} from "@/shared/constants";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {getForResolve} from "@/services/property";
import {getUnitList} from "@/services/unit";
import AppInputControl from "@/components/app/AppInputControl";
import {AR_PERIOD, AR_STATUS, AR_UNIT} from "@/shared/error_codes";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
import {notifySuccess} from "@/shared/plugins/toastr";
import AppButton from "@/components/app/AppButton/AppButton";
import {quillEditor} from 'vue-quill-editor'
import {getRatePlanList} from "@/services/pricing";
import {getCompanyList} from "@/services/company";
import {getCurrencyList} from "@/services/finance";
import {F_COMPANY_SWITCH} from "@/shared/function_permission";
import {
    C_RESERVATION_ACCOMMODATION_GENERAL_MAPPING,
    C_RESERVATION_ACCOMMODATION_GENERAL_UNKNOWN_PROPERTY_SEARCH_NAME_INCLUDING_ID
} from "@/shared/component_permission";
import AppNoData from "@/components/app/AppNoData";

export default {
    name: "ReservationAccommodationResolveForm",
    components: {
        AppNoData,
        AppButton, AppDateRangePicker, AppButtonSubmit, AppInputControl, AppSelect, quillEditor, FormHeader},
    mixins: [getErrorMessage],
    props: {
        reservationId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            formData: null,
            property: {id: null, name: null},
            company: {id: null, name: null},
            loading: false,
            saving: false,
            preloadPropertyList: false,
            ac_response_value: false,
            statusList: [],
            unitList: [],
            note: '',
            validation: {
                unit: AR_UNIT,
                status: AR_STATUS,
                period: AR_PERIOD,
                rate_plan: {},
            },
            ratePlanList: [],
            mappingList: [],
            mappingTableFields: [
                {key: "company", label: this.$t("COMPANY")},
                {key: "property.id", label: this.$t("ID")},
                {key: "property", label: this.$t("PROPERTY")},
                {key: "unit", label: this.$t("UNIT")},
                {key: "rate_plan", label: this.$t("RATE_PLAN")},
            ],
        }
    },
    computed: {
        period: {
            get() {
                return {
                    start: this.formData.check_in_date,
                    end: this.formData.check_out_date
                }
            },
            set({start, end}) {
                this.formData.check_in_date = start
                this.formData.check_out_date = end
            }
        },
        changeCompanyPermission() {
            return this.$store.getters['user/getPermission'](F_COMPANY_SWITCH)
        },
        viewMappingPermission() {
            return this.$store.getters['user/getPermission'](C_RESERVATION_ACCOMMODATION_GENERAL_MAPPING)
        },
        viewPropertyNamesWithIds() {
            return this.$store.getters['user/getPermission'](C_RESERVATION_ACCOMMODATION_GENERAL_UNKNOWN_PROPERTY_SEARCH_NAME_INCLUDING_ID)
        },
    },
    watch: {
        'formData.unit.id': {
            handler(value, oldValue) {
                if (value === null) {
                    this.ratePlanList = []
                    this.$set(this.formData, 'rate_plan', {id: null, name: null})
                    return
                }
                if (oldValue === null || oldValue === undefined) {
                    this.getRatePlanList()
                    return
                }
                if (oldValue !== value) {
                    this.$set(this.formData, 'rate_plan', {id: null, name: null})
                    this.getRatePlanList()
                }
            },
        },
        company: {
            handler(value, oldValue) {
                if (oldValue.id && (value.id === null || value.id !== oldValue.id)) {
                    this.property = {id: null, name: null}
                    this.$set(this.formData, 'unit', {id: null, name: null})
                    this.$set(this.formData, 'rate_plan', {id: null, name: null})
                }
            },
        }
    },
    methods: {
        searchCurrency() {
            return getCurrencyList()
        },
        save() {
            this.saving = true
            let data = this.formData
            if (this.company && this.company.id) {
                data.company_id = this.company.id
            }
            updateUnknownReservation(this.reservationId, data).then(response => {
                notifySuccess()
                this.$emit('saved', response.data)
            }, error => this.showErrorMessages(error, this.validation)).finally(() => this.saving = false)
        },
        searchProperty(value) {
            const companyId = this.changeCompanyPermission ? this.company.id : this.formData.company_id
            return getForResolve(undefined, {name: value, company: companyId})
        },
        searchCompany(value) {
            return getCompanyList({name: value}).then(res => {
                return {data: res.data.items}
            })
        },
        getRatePlanList() {
            const request = {
                contingent_unit: this.formData.unit.id,
                distribution: this.formData.distribution.id,
                category_ids: RATE_PLAN_CATEGORY_DISTRIBUTION,
                perPage: 1000,
            }
            getRatePlanList(request).then((response) => {
                this.ratePlanList = this.formData.distribution.id !== null ? response.data.items : response.data.items.map((ratePlan) => ({
                        id: ratePlan.id,
                        name: ratePlan.distribution.id !== null ? ratePlan.name + ' (' + ratePlan.distribution.name + ')' : ratePlan.name
                    })
                )
            })
        },
        setMappingList() {
            if (!this.formData || !this.formData.hasOwnProperty("mapping") || !this.formData.mapping || typeof this.formData.mapping !== "object") {
                return
            }
            const companyList = this.formData.mapping.hasOwnProperty("company") ? this.formData.mapping.company : []
            const propertyList = this.formData.mapping.hasOwnProperty("property") && this.formData.mapping.property ? this.formData.mapping.property.entities : []
            const unitList = this.formData.mapping.hasOwnProperty("unit") && this.formData.mapping.unit ? this.formData.mapping.unit.entities : []
            let ratePlanList = []
            if (this.formData.mapping.hasOwnProperty("rate_plan") && this.formData.mapping.rate_plan && Array.isArray(this.formData.mapping.rate_plan) && this.formData.mapping.rate_plan.length > 0) {
                this.formData.mapping.rate_plan.forEach(ratePlanMappingObject => {
                    const ratePlanEid = ratePlanMappingObject.eid
                    const ratePlans = ratePlanMappingObject.entities
                    if (ratePlans.length > 0) {
                        ratePlans.forEach(ratePlan => {
                            let ratePlanItem = {...ratePlan, ...{eid: ratePlanEid}}
                            ratePlanList.push(ratePlanItem)
                        })
                    }
                })
            }

            if (companyList.length < 1) {
                return
            }

            companyList.forEach(company => {
                if (propertyList.length > 0) {
                    propertyList.forEach(property => {
                        if (property.company_id === company.id) {
                            if (unitList.length > 0) {
                                let propertyUnitFound = false
                                unitList.forEach(unit => {
                                    if (unit.property_id === property.id) {
                                        propertyUnitFound = true
                                        let rootUnitId = !unit.root_unit_id ? unit.id : unit.root_unit_id
                                        if (ratePlanList.length > 0) {
                                            const unitRatePlanList = ratePlanList.filter((ratePlan => ratePlan.unit_id === rootUnitId))
                                            const mappingListItem = this.getMappingListItem(company, property, unit, unitRatePlanList)
                                            if (mappingListItem) {
                                                this.mappingList.push(mappingListItem)
                                            }
                                        } else {
                                            const mappingListItem = this.getMappingListItem(company, property, unit)
                                            if (mappingListItem) {
                                                this.mappingList.push(mappingListItem)
                                            }
                                        }
                                    }
                                })
                                if (!propertyUnitFound) {
                                    const mappingListItem = this.getMappingListItem(company, property)
                                    if (mappingListItem) {
                                        this.mappingList.push(mappingListItem)
                                    }
                                }
                            } else {
                                const mappingListItem = this.getMappingListItem(company, property)
                                if (mappingListItem) {
                                    this.mappingList.push(mappingListItem)
                                }
                            }
                        }
                    })
                }
                else {
                    const mappingListItem = this.getMappingListItem(company)
                    if (mappingListItem) {
                        this.mappingList.push(mappingListItem)
                    }
                }
            })
        },
        getMappingListItem(company, property = null, unit = null, ratePlan = null) {
            if (
                !this.changeCompanyPermission && !property && !unit && (!ratePlan || ratePlan.length === 0)
            ) {
                return null
            }
            let mappingListItem = {}
            this.$set(mappingListItem, "company", company)
            this.$set(mappingListItem, "property", property)
            this.$set(mappingListItem, "unit", unit)
            this.$set(mappingListItem, "rate_plan", !ratePlan || ratePlan.length > 1 ? null : ratePlan[0])
            return mappingListItem
        }
    },
    created() {
        fetchStatusList(OBJECT_TYPE_ACCOMMODATION_RESERVATION).then(response => {
            this.statusList = response.data
        })
        fetchAccommodationReservationNotes(this.reservationId).then(response => {
            const note = response.data.find(el => el.id === RES_NOTE_TYPE_PRIVATE)
            this.note = note ? note.content : ''
        })
        fetchAccommodationReservation(this.reservationId).then(response => {
            this.formData = response.data
            this.setMappingList()
            if (response.data.property.id) {
                getUnitList({property_id: response.data.property.id, limit: 1000}).then(res => {
                    this.unitList = res.data.items
                })
                this.property = {...response.data.property}
                this.company = {...response.data.company}
                this.formData.unit = {...response.data.unit}
            }
        }).then(() => {
            this.$watch('property.id', function (value) {
                this.formData.unit = {id: null, name: null}
                this.unitList = []
                if (value) {
                    this.loading = true
                    getUnitList({property_id: value, limit: 1000}).then(res => {
                        this.unitList = res.data.items
                    }).finally(() => this.loading = false)
                } else {
                    this.unitList = []
                }
            })
        })
        if (!this.changeCompanyPermission) {
            let storagePresets = localStorage.getItem(LS_PRESETS)
            if (storagePresets) {
                storagePresets = JSON.parse(storagePresets)
                if (storagePresets.hasOwnProperty('properties') && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                    this.preloadPropertyList = true
                }
            }
        }
    }
}
</script>

<style scoped>
    .inactive-tag {
        cursor: pointer;
        font-size: 90%;
    }
    .empty_table_content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
</style>
