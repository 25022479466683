<template>
    <form v-if="loading" class="mt-4">
        <b-tabs v-if="loading" v-model="activeTab" nav-class="tab_navigation-horizontal"
                content-class="tab_content-horizontal" pills
                justified class="mb-1">
            <b-tab :title="$t('GENERAL')">
                <b-row class="mb-4">
                    <b-col>
                        <app-translation-input :languages="supportedLanguages" v-model="description.lang"
                                               :label="$t('DESCRIPTION')" component="textarea" :max-length="1000"
                                               :lang_id="description.lang_id" :rows="12"></app-translation-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <app-translation-input :languages="supportedLanguages" v-model="tos.lang"
                                               :label="$t('TERMS_OF_SERVICE')" component="textarea"
                                               :lang_id="description.lang_id" :rows="12"></app-translation-input>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab :title="$t('PARTNER_ACTIVATION_RULES')">
                <b-row class="mb-4">
                    <b-col cols="12" lg="6">
                        <label>{{$t('WORKFLOW')}}</label>
                        <app-select :search-empty-item="false"
                                    text-field="label"
                                    :options="workflowOptions"
                                    v-model="workflow"
                        ></app-select>
                    </b-col>
                </b-row>
                <b-row class="mb-4">
                    <b-col cols="12" lg="6">
                        <label>{{$t('COUNTRY')}}</label>
                        <app-select mode="multiselect" text-field="name"
                                    value-field="id"
                                    :options="countryList" v-model="advertising.country">
                        </app-select>
                    </b-col>
                </b-row>
                <b-row class="mb-4">
                    <b-col cols="12" lg="6">
                        <label>{{$t('CITY')}}</label>
                        <app-select mode="multiselect" text-field="name"
                                    v-model="advertising.city" :options="cityList">
                        </app-select>
                    </b-col>
                </b-row>
                <b-row class="mb-4">
                    <b-col cols="12" lg="6">
                        <label>{{$t('MIN_NUMBER_OF_UNITS')}}</label>
                        <b-input type="number" v-model="advertising.unit.min_number"></b-input>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab :title="$t('UNIT_ACTIVATION_RULES')">
                <div class="mt-0 pt-0">
                    <marketplace-unit-activation-rules :website-id="websiteId" :distribution-id="distributionId"></marketplace-unit-activation-rules>
                </div>
            </b-tab>
        </b-tabs>
        <b-row class="mt-4">
            <b-col>
                <app-button :loading="loadingSave" @click="save">{{$t('SAVE')}}</app-button>
            </b-col>
        </b-row>
    </form>
</template>

<script>
import {
    createMarketplace,
    getMarketplaceDescriptions,
    getMarketplaceSetup, getMarketplaceWorkflowOptions,
    getUnitTypeList,
    updateMarketplaceSetup
} from "@/services/marketplace";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButton from "@/components/app/AppButton/AppButton";
import AppTranslationInput from "@/components/app/translation/AppTranslationInput";
import FormHeader from "@/components/app/form/FormHeader";
import MarketplaceUnitActivationRules from "@/components/direct_booking/marketplace/MarketplaceUnitActivationRules";
import {getCityList, getCountries} from "@/services/location";
import {getSystemLanguageList} from "@/services/system";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {MARKETPLACE_WORKFLOW} from "@/shared/constants";
import {EventBus} from "@/shared/EventBus";
import routeNames from "@/router/routeNames";

export default {
    name: "MarketplaceEditGeneralTab",
    mixins:[getErrorMessage],
    components:{
        AppSelect,
        AppButton,
        AppTranslationInput,
        FormHeader,
        MarketplaceUnitActivationRules
    },
    props:{
        websiteId:{
            type: Number,
            default:null
        },
        distributionId:{
            type: Number,
            default: null
        }
    },
    data(){
        return{
            advertising: [],
            description: [],
            tos: [],
            countryList: [],
            cityList: [],
            unitTypes: [],
            descData: [],
            loading: false,
            loadingSave: false,
            descriptionPlaceholder: this.$t('DESCRIPTION_MARKETPLACE'),
            tosPlaceholder: this.$t('TOS_DESCRIPTION'),
            supportedLanguages: [],
            workflowOptions: [],
            MARKETPLACE_WORKFLOW,
            workflow:[],
            initial_status: null,
            ratePlanOptions: [
                {id: 1, name: 'Standard rate'},
                {id: 2, name: 'Non-refundable rate'}
            ],
            tabs: [
                {name: 'general', route: {name: routeNames.RN_RESERVATION_ACCOMMODATION_GENERAL}},
                {name: 'finance', route: {name: routeNames.RN_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD},},
                {name: 'notes', route: {name: routeNames.RN_RESERVATION_ACCOMMODATION_NOTES}},
                {name: 'guests', route: {name: routeNames.RN_RESERVATION_ACCOMMODATION_GUESTS}},
                {name: 'inbox', route: {name: routeNames.RN_RESERVATION_ACCOMMODATION_INBOX}},
                {name: 'services', route: {name: routeNames.RN_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES}},
            ],
            activeTab: 0,
        }
    },
    methods:{
        save(){
            this.loadingSave = true
            EventBus.$emit('save_unit_activation_setup')
            createMarketplace({website_id: this.websiteId, data: this.prepareData()}).then(response => {
                notifySuccess()
                this.loadingSave = false
                this.$emit('editSaved')
            }, error => {
                this.loadingSave = false
                this.showErrorMessages(error)
            })
        },
        prepareData(){
            return [
                {id: 'website_activation', workflow: {id: this.workflow, initial_status: this.initial_status}, activation_rules: this.advertising},
                {id: 'description', lang: this.description.lang, lang_id: this.description.lang_id},
                {id: 'tos', lang: this.tos.lang, lang_id: this.tos.lang_id}
            ]
        },
        changeLocation(event){
            this.unit_activation.location = event
        },
        changeRates(event){
            this.unit_activation.rates = event
        }
    },
    created() {
        getMarketplaceSetup({website_id: this.websiteId}).then(response => {
            response.data.forEach(el => {
                el.id === 'website_activation' ? this.advertising = el.activation_rules : ''
                el.id === 'website_activation' ? this.workflow = el.workflow.id : ''
                el.id === 'website_activation' ? this.initial_status = el.workflow.initial_status : ''
                el.id === 'description' ? this.description = el : ''
                el.id === 'tos' ? this.tos = el : ''
            })
            this.loading = true
        })
        getMarketplaceWorkflowOptions().then(response => {
            this.workflowOptions = response.data
        })
        getCountries({user_country: true}).then(response => {
            this.countryList = response.data
        })
        getCityList({user_cities: true}).then(response=>{
            this.cityList = response.data
        })
        getUnitTypeList().then(response => {
            this.unitTypes = response.data
        })
        getMarketplaceDescriptions().then(response => {
            this.descData = response.data
        })
        getSystemLanguageList().then(response => {
            response.data.forEach(el => {this.supportedLanguages.push(el.name)})
        })
        if (this.loading) {
            let tabIndex = this.computedTabs.findIndex(el => el.name === this.tab)
            this.activeTab = tabIndex > -1 ? tabIndex : this.activeTab
        }
    },
    watch:{
        'advertising.country':{
            handler(){
                getCityList({user_cities: true, country: this.advertising.country}).then(response=>{
                    this.cityList = response.data
                })
            },
            deep:true
        }
    }
}
</script>

<style scoped>

</style>
