<template>
    <b-row>
        <b-col class="d-flex">
            <b-form-checkbox
                :checked="allOptionsSelected()"
                @change="toggleAllOptions"
                :value="true"
                :unchecked-value="false"
                inline
                class="b-r-1 pr-1 mr-1">
                {{ $t("SELECT_ALL") }}
            </b-form-checkbox>
            <b-form-checkbox-group
                v-model="selected"
                :options="options"
                :value-field="valueField"
                :text-field="textField"
                class="ml-0">
            </b-form-checkbox-group>
        </b-col>
    </b-row>
</template>

<script>

import {cloneDeep} from "lodash";

export default {
    name: "AppInlineCheckboxGroup",
    props: {
        options: {
            type: Array,
        },
        value: {
            type: Array,
            default() {
                return []
            },
        },
        valueField: {
            type: String,
            default: "value",
        },
        textField: {
            type: String,
            default: "text",
        },
    },
    data() {
        return {
            selected: [],
        }
    },
    watch: {
        selected: {
            handler(value) {
                this.$emit("input", value)
            },
            deep: true,
        },
    },
    methods: {
        allOptionsSelected() {
            let selected = true
            this.options.forEach(option => {
                selected = selected && this.selected.indexOf(option[this.valueField]) >= 0
            })
            return selected
        },
        toggleAllOptions(event) {
            this.selected.splice(0)
            if (event) {
                this.options.forEach(option => {
                    this.selected.push(option[this.valueField])
                })
            }
        },
    },
    mounted() {
        this.selected = cloneDeep(this.value)
    },
}
</script>

<style scoped>

</style>
