<template>
    <b-card class="animated fadeIn" no-body>
        <b-card-header header-class="border-bottom-0 d-flex flex-wrap justify-content-between align-items-center">
            <div>
                <span class="header-2">{{$t("UPCOMING_DEPARTURES")}}</span>
            </div>

            <b-form-radio-group
                id="departureDays"
                class="checkbox_tab"
                v-model="days"
                :options="options"
                button-variant="outline-primary"
                buttons
                name="departureDays"
            ></b-form-radio-group>

        </b-card-header>
        <b-card-body>
            <template>
                <app-table-v2 search-event="reloadDashboardCheckoutTable" :provider="getData" :search-on-create="true"
                              :default-limit="5"
                              :show-limit="false" class="reservation_table" :fields="computedFields">

                    <template v-slot:reservation_code="data">
                        <div>
                            <small v-if="data.item.tag && checkPermission(C_RESERVATION_ACCOMMODATION_TAG_V)" class="text-muted d-block">
                                <b-badge
                                    variant="primary" pill
                                    v-for="item in data.item.tag"
                                    class="mr-1 mb-1"
                                    style="cursor: pointer; font-size: 90%">
                                    {{ item.label }}
                                    <i  v-if="checkPermission(C_RESERVATION_ACCOMMODATION_TAG_E)" @click="showDeleteCustomTagDialog(item)"
                                        class="fa fa-times fa-fw ml-1"
                                        aria-hidden="true"></i>
                                </b-badge>
                            </small>
                            {{data.item.reservation_code}}
                        </div>
                    </template>

                    <template v-slot:source="data">
                        <template v-if="Array.prototype.includes.call(HAS_CHANNEL_DATA, data.item.distribution.id)">
                            <small class="text-muted d-block">
                                {{data.item.distribution.name}}
                            </small>
                            {{data.item.channel.name}}
                        </template>
                        <template v-else-if="!data.item.distribution.id">
                            {{data.item.channel.name}}
                        </template>
                        <template v-else>
                            {{data.item.distribution.name}}
                        </template>
                    </template>
                    <template v-slot:check_out_date="data">
                        <small class="text-muted d-block">
                            {{data.item.num_nights}} {{ data.item.num_nights > 1 ? $t('NIGHTS').toLowerCase() :
                            $t('NIGHT').toLowerCase()}} {{data.item.check_out_time ? `|
                            ${formatTime(data.item.check_out_time)}` :
                            ''}}
                            <template v-if="data.item.transfer">
                                |
                                <b-link v-if="checkPermission(R_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES)"
                                        @click="showReservationDetails(data.item, 'services')">
                                    <i class="fa fa-car" style="color: #6F6EFE"></i>
                                </b-link>
                                <i v-else class="fa fa-car" style="color: #6F6EFE"></i>
                            </template>
                        </small>

                        <b-link v-if="checkPermission(R_PROPERTY_CALENDAR_DEV) && data.item.unit.id && data.item.property.id"
                                :to="getCalendarLink(data.item)"
                                target="_blank">
                            {{ data.item.check_out_date | date }}
                        </b-link>
                        <template v-else>
                            {{ data.item.check_out_date | date }}
                        </template>
                    </template>

                    <template v-slot:guest="data">
                        <small class="text-muted d-block">
                            {{data.item.num_guests}} {{ data.item.num_guests > 1 ? $t('GUESTS').toLowerCase() :
                            $t('GUEST').toLowerCase() }}
                        </small>

                        <b-link
                            v-if="checkPermission(C_RESERVATION_ACCOMMODATION_MODULES_INFO) && data.item.unit.id"
                            @click="showInfo(data.item)">
                            {{data.item.guest.name}}
                        </b-link>
                        <template v-else>
                            {{data.item.guest.name}}
                        </template>
                    </template>

                    <template v-slot:unit_name="data">
                        <small class="text-muted d-block">
                            <b-link v-if="checkPermission(R_PROPERTY_BASIC) && data.item.property.id"
                                    :to="{name: routeNames.RN_PROPERTY_BASIC, params: {id: data.item.property.id}}"
                                    target="_blank">
                                {{data.item.property.name}}
                            </b-link>
                            <template v-else>
                                {{data.item.property.name}}
                            </template>
                        </small>

                        <b-link v-if="checkPermission(R_PROPERTY_UNIT_BASIC) && data.item.unit.id"
                                :to="{name: routeNames.RM_PROPERTY_UNIT_BASIC, params: {id: data.item.property.id, unit_id: data.item.unit.id}}"
                                target="_blank">
                            {{data.item.unit.name}}
                        </b-link>
                        <template v-else>
                            {{data.item.unit.name}}
                        </template>
                    </template>

                    <template v-slot:total="data">
                        <small class="text-muted d-block" v-if="company.main_currency.id !== data.item.channel_currency.id">
                            {{data.item.channel_total}}
                        </small>

                        <b-link v-if="checkPermission(R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD)"
                                @click="showReservationDetails(data.item, 'finance')">
                            {{data.item.company_total}}
                        </b-link>
                        <template v-else>
                            {{data.item.company_total}}
                        </template>
                    </template>

                    <template v-slot:edit="data">
                        <app-button
                            v-if="checkPermission(R_RESERVATION_ACCOMMODATION_GENERAL)"
                            variant="link"
                            icon="edit"
                            :show_text="false" @click="showReservationDetails(data.item)">
                        </app-button>
                    </template>

                    <template v-slot:initialMessage>&nbsp;</template>
                    <template v-slot:noDataMessage>
                        {{$t("NO_DEPARTURES_FOR_VAL", {value:options.find(el => el.value ===
                        days).text.toLowerCase()})}}
                    </template>
                </app-table-v2>
            </template>
        </b-card-body>

        <app-aside v-model="infoSidebarState">
            <template slot="header">
                <reservation-accommodation-header
                    :reservation="reservationDetailsObject">
                </reservation-accommodation-header>
            </template>

            <information-module :reservation-id="infoReservationId"></information-module>
        </app-aside>

        <app-aside v-model="reservationDetailsAsideState" :widths="['col-lg-12','col-sm-12', '100%']" full-width
                   :header-classes="[]" header-styles="position: sticky; top: 0; z-index: 999; background-color:#fff">
            <template slot="header">
                <reservation-accommodation-header :reservation="reservationDetailsObject"></reservation-accommodation-header>
            </template>
            <reservation-details-component :reservation-id="reservationDetailsObject.id"
                                           @update="reservationDetailsObject=$event"
                                           :tab="reservationDetailsTab"></reservation-details-component>
        </app-aside>
        <app-confirmation-dialog :show="deleteCustomTagConfirmDialog"
                                 :delete_title="true"
                                 @confirm="deleteTagMapping()"
                                 @cancel="deleteCustomTagConfirmDialog = false">
        </app-confirmation-dialog>
    </b-card>
</template>

<script>
    import routeNames from '@/router/routeNames'
    import AppButton from "@/components/app/AppButton/AppButton";
    import moment from 'moment'
    import {getAccommodationReservationList} from "@/services/accommodation_reservation";
    import {
        STATUS_CONFIRMED,
        HAS_CHANNEL_DATA,
        WORKFLOW_DIRECT_BOOKER,
        OBJECT_TYPE_ACCOMMODATION_RESERVATION
    } from "@/shared/constants";
    import AppTableV2 from "@/components/app/AppTableV2";
    import {EventBus} from "@/shared/EventBus";
    import {
        C_RESERVATION_ACCOMMODATION_LIST_TOTAL_V,
        C_RESERVATION_ACCOMMODATION_MODULES_INFO, C_RESERVATION_ACCOMMODATION_TAG_E,
        C_RESERVATION_ACCOMMODATION_TAG_V
    } from "@/shared/component_permission";
    import {
        R_PROPERTY_BASIC,
        R_PROPERTY_CALENDAR_DEV,
        R_PROPERTY_UNIT_BASIC,
        R_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES,
        R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD,
        R_RESERVATION_ACCOMMODATION_GENERAL
    } from "@/shared/route_permission";
    import ReservationDetailsComponent from '@/components/reservation/accommodation/ReservationDetails';
    import AppAside from "@/components/app/form/AppAside";
    import ReservationAccommodationHeader from "@/components/reservation/accommodation/ReservationAccommodationHeader";
    import InformationModule from "@/components/reservation/accommodation/modules/InformationModule";
    import {TableRule} from "@/mixins/Table/TableRule";
    import {deleteEntityTags} from "@/services/tag";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";

    export default {
        name: "DashboardCheckoutReservationList",
        mixins: [TableRule],
        props: {
            property: {
                type: Number,
                default: null
            },
            company: {
                type: Object,
                default: null
            }
        },
        components: {
            AppConfirmationDialog,
            InformationModule,
            ReservationAccommodationHeader, AppTableV2, AppButton, ReservationDetailsComponent, AppAside},
        data() {
            return {
                options: [
                    {text: this.$t("TODAY"), value: 1},
                    {text: this.$t("TOMORROW",), value: 2},
                    {text: this.$t("NEXT_DAYS", {value: 7}), value: 7},
                ],
                items: [],
                fields: [
                    {key: 'reservation_code', label: this.$t("RESERVATION_CODE"), tdClass: 'td_max_width_10'},
                    {key: 'source', label: this.$t('SALES_CHANNEL'), tdClass: 'remove_padding'},
                    {key: 'check_out_date', label: this.$t("CHECKOUT"), tdClass: 'remove_padding text-nowrap'},
                    {key: 'guest', label: this.$t("GUEST"), tdClass: 'remove_padding'},
                    {key: 'unit_name', label: this.$t("UNIT"), tdClass: 'remove_padding td_max_width_20'},
                    {key: 'total', label: this.$t("TOTAL"), tdClass: 'remove_padding text-right'},
                    {key: 'edit', label: '', tdClass: 'text-right'},
                ],
                exception_fields:[
                    {key: 'unit_name', label: this.$t("UNIT"), tdClass: 'remove_padding td_max_width_20'},
                    {key: 'total', label: this.$t("TOTAL"), tdClass: 'remove_padding text-right'},
                    {key: 'edit', label: '', tdClass: 'text-right'},
                ],
                days: 1,
                listCount: null,
                lastPage: null,
                loaded: false,
                routeNames,
                HAS_CHANNEL_DATA,
                omittedFields: [],
                R_RESERVATION_ACCOMMODATION_GENERAL,
                reservationDetailsObject: {},
                reservationDetailsAsideState: false,
                reservationDetailsTab: null,
                R_PROPERTY_BASIC,
                R_PROPERTY_UNIT_BASIC,
                R_PROPERTY_CALENDAR_DEV,
                R_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD,
                C_RESERVATION_ACCOMMODATION_MODULES_INFO,
                WORKFLOW_DIRECT_BOOKER,
                infoReservationId: null,
                infoSidebarState: false,
                R_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES,
                C_RESERVATION_ACCOMMODATION_TAG_V,
                C_RESERVATION_ACCOMMODATION_TAG_E,
                list: [],
                tagId: null,
                entityId: null,
                deleteCustomTagConfirmDialog: false,
            }
        },
        computed: {
            companyId() {
                if (this.company && this.company.hasOwnProperty('id')) {
                    return this.company.id
                }
                return null
            },
            computedFields() {
                return this.getFields(this.list, {fields: this.fields.filter(el => !this.omittedFields.includes(el.key)),exception_fields:this.exception_fields})
            },
            currentCompany() {
                return this.$store.getters['user/getCurrentCompany']
            }
        },
        methods: {
            showInfo(reservation) {
                this.reservationDetailsObject = reservation
                this.infoReservationId = reservation.id
                this.infoSidebarState = true
            },
            getData(filter) {
                let datesReq = {
                    perPage: filter.perPage,
                    page: filter.currentPage,
                    check_out_from_date: null,
                    check_out_to_date: null,
                    order_by: 'check_in_to_date',
                    status: [STATUS_CONFIRMED],
                    property: this.property,
                    company: this.companyId
                }

                if (this.days === 1) {
                    datesReq.check_out_from_date = moment().format("YYYY-MM-DD")
                    datesReq.check_out_to_date = moment().format("YYYY-MM-DD")
                }

                if (this.days === 2) {
                    datesReq.check_out_from_date = moment().add(1, 'days').format("YYYY-MM-DD")
                    datesReq.check_out_to_date = moment().add(1, 'days').format("YYYY-MM-DD")
                }

                if (this.days === 7) {
                    datesReq.check_out_from_date = moment().format("YYYY-MM-DD")
                    datesReq.check_out_to_date = moment().add(7, 'days').format("YYYY-MM-DD")
                }

                let provider =  getAccommodationReservationList(datesReq)
                provider.then(response =>{
                    this.list = response.data.items
                })
                return provider
            },
            showReservationDetails(reservation, tab = null) {
                if (tab) {
                    this.reservationDetailsTab = tab
                }
                this.reservationDetailsObject = reservation
                this.reservationDetailsAsideState = true
            },
            getCalendarLink(reservation) {
                return {
                    name: routeNames.RN_PROPERTY_CALENDAR,
                    params: {id: reservation.property.id},
                    query: {
                        unit: reservation.unit.parent_id ? reservation.unit.parent_id : reservation.unit.id,
                        start: moment(reservation.check_in_date).subtract(15, 'days').format('YYYY-MM-DD'),
                        end: moment(reservation.check_in_date).add(15, 'days').format('YYYY-MM-DD')
                    }
                }
            },
            formatTime(timestamp) {
                return this.$options.filters.time(timestamp)
            },
            showDeleteCustomTagDialog(item) {
                this.tagId = item.tag_id
                this.entityId = item.entity_id
                this.deleteCustomTagConfirmDialog = true
            },
            deleteTagMapping() {
                deleteEntityTags(OBJECT_TYPE_ACCOMMODATION_RESERVATION, this.entityId, [this.tagId]).then((response) => {
                    notifySuccess()
                    EventBus.$emit('reloadDashboardCheckoutTable')
                    if (this.reservationDetailsObject && Object.keys(this.reservationDetailsObject).length > 0) {
                        let temp = this.reservationDetailsObject.tag
                        this.reservationDetailsObject.tag = temp.filter(el => el.tag_id !== this.tagId)
                    }
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.deleteCustomTagConfirmDialog = false
                })
            },
        },
        watch: {
            days: {
                handler() {
                    EventBus.$emit('reloadDashboardCheckoutTable')
                }
            },

            property: {
                handler() {
                    EventBus.$emit('reloadDashboardCheckoutTable')
                }
            }
        },
        created() {
            if (!this.checkPermission(C_RESERVATION_ACCOMMODATION_LIST_TOTAL_V)) {
                this.omittedFields.push('total')
            }
        }
    }
</script>

<style scoped>
</style>
