<template>
    <div v-if="hasPermissionView">

            <app-access-control tag="div" @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{function:C_UNIT_KEY_PICKUP_E, key: AC_UNIT_KEY_PICKUP}">
                <template v-slot:default="props">
                    <form-header>
                        <b-tooltip v-if="props.messages.length"
                                   class="mb-5"
                                   triggers="hover"
                                   :title="props.messages[0].message" :target="'key_'+C_UNIT_KEY_PICKUP_E">
                        </b-tooltip>
                        <b-form-group :id="'key_'+C_UNIT_KEY_PICKUP_E" >
                            <b-checkbox  :disabled="props.disabled" v-model="checked" switch @change="deleteData">
                                {{$t("KEY_PICKUP")}}
                            </b-checkbox>
                        </b-form-group>

                    </form-header>
                </template>
            </app-access-control>

        <b-form @submit.prevent="save">
            <template v-if="checked">
                <b-row>
                    <b-col>
                        <form-header>{{$t("LOCATION")}}</form-header>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="4" class="mb-4">

                        <label>{{$t('ADDRESS')}}</label>
                        <b-form-input
                            type="text"
                            v-model="address"></b-form-input>
                        <validation-description :show="$v.address.$dirty && $v.address.$invalid">
                            {{$t('FIELD_MANDATORY')}}
                        </validation-description>

                    </b-col>
                    <b-col md="6" lg="4" xl="1" class="mb-4">

                        <label>{{$t('STREET_NUMBER')}}</label>
                        <b-form-input
                            type="text"
                            v-model="address_no"></b-form-input>
                        <validation-description :show="$v.address_no.$dirty && $v.address_no.$invalid">
                            {{$t('FIELD_MANDATORY')}}
                        </validation-description>

                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <form-header>{{$t("GEOLOCATION")}}</form-header>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="4" xl="2" class="mb-4">

                        <label>{{$t('LATITUDE')}}</label>
                        <b-form-input
                            lazy
                            v-model="latitude"></b-form-input>
                        <validation-description :show="$v.latitude.$dirty && $v.latitude.$invalid">
                            {{$t('COORDINATE_VALIDATION')}}
                        </validation-description>
                    </b-col>
                    <b-col md="4" xl="2" class="mb-4">

                        <label>{{$t('LONGITUDE')}}</label>
                        <b-form-input
                            lazy
                            v-model="longitude"></b-form-input>
                        <validation-description :show="$v.longitude.$dirty && $v.longitude.$invalid">
                            {{$t('COORDINATE_VALIDATION')}}
                        </validation-description>

                    </b-col>

                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <g-map :lat="latitude" :lng="longitude"
                               @gmapLocationChange="updateFormLocation"></g-map>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <submit-location :loading="saveLoader" @AccessControlEvent="addToAccessControlCounter()"
                                         :access_control_context="{function:C_UNIT_KEY_PICKUP_E, key: AC_UNIT_KEY_PICKUP}" :c_permission="c_permission_e"></submit-location>
                    </b-col>
                </b-row>
            </template>

        </b-form>
    </div>
</template>

<script>
    import GMap from '@/components/app/map/GoogleMap'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import SubmitLocation from '@/components/app/AppButton/AppButtonSubmit'
    import {required, minLength, decimal, minValue, maxValue} from 'vuelidate/lib/validators'
    import {getValidationRule} from '@/mixins/validation/getValidationRule'
    import ValidationDescription from '@/components/app/form/AppValidationDescription'
    import {toast} from '@/shared/plugins/toastr'
    import {GET_UNIT_ITEM, ACTION_SET_UNIT_ITEM} from '@/store/store_types'
    import {C_UNIT_KEY_PICKUP_V, C_UNIT_KEY_PICKUP_E} from "@/shared/component_permission";
    import FormHeader from "@/components/app/form/FormHeader";
    import AppAccessControl from "@/components/app/AppAccessControl";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {AC_UNIT_KEY_PICKUP} from "@/mixins/AccessControl/AccessControlEnumeration";


    export default {
        name: "Index",
        components: {
            AppAccessControl,
            FormHeader,
            GMap, SubmitLocation, ValidationDescription
        },
        mixins: [getErrorMessage, getValidationRule,AccessControlComponent],
        data() {
            return {
                c_permission_e: C_UNIT_KEY_PICKUP_E,
                C_UNIT_KEY_PICKUP_E,
                checked: false,
                AC_UNIT_KEY_PICKUP,
                access_control_fetch_key: AC_UNIT_KEY_PICKUP,
                saveLoader:false
            }
        },
        computed: {
            hasPermissionView() {
                return this.$store.getters['user/getPermission'](C_UNIT_KEY_PICKUP_V)
            },
            displayForm() {
                return this.address
            },
            longitude: {
                get() {
                    return this.$store.getters[GET_UNIT_ITEM]('basic.key_pickup.longitude')
                },
                set(val) {
                    this.$store.dispatch(ACTION_SET_UNIT_ITEM, {path: 'basic.key_pickup.longitude', value: val})
                }
            },
            latitude: {
                get() {
                    return this.$store.getters[GET_UNIT_ITEM]('basic.key_pickup.latitude')
                },
                set(val) {
                    this.$store.dispatch(ACTION_SET_UNIT_ITEM, {path: 'basic.key_pickup.latitude', value: val})
                }
            },
            address: {
                get() {
                    return this.$store.getters[GET_UNIT_ITEM]('basic.key_pickup.address')
                },
                set(val) {
                    this.$store.dispatch(ACTION_SET_UNIT_ITEM, {path: 'basic.key_pickup.address', value: val})
                }
            },
            address_no: {
                get() {
                    return this.$store.getters[GET_UNIT_ITEM]('basic.key_pickup.address_no')
                },
                set(val) {
                    this.$store.dispatch(ACTION_SET_UNIT_ITEM, {path: 'basic.key_pickup.address_no', value: val})
                }
            }
        },
        methods: {
            updateFormLocation(data) {
                this.latitude = data.latitude
                this.longitude = data.longitude

                if (
                    (this.address === '' || this.address === null) &&
                    (this.address_no === '' || this.address_no === null)

                ) {
                    this.address = data.street
                    this.address_no = data.street_number
                }
            },
            deleteData($event) {
                if (!$event) {
                    let object = {
                        id: this.$route.params.unit_id,
                        data: {
                            key_pickup: {
                                address: null,
                                address_no: null,
                                latitude: null,
                                longitude: null,
                            }
                        }
                    }
                    this.$store.dispatch('actionDeleteUnitKeyPickup', object).then(() => {
                            toast({
                                'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                                'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                                'type': 'success',
                                'timeout': 3000
                            })

                        }, error => {
                            toast({
                                'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                                'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                                'type': 'error',
                                'timeout': 6000
                            })
                        }
                    )
                }
            },
            save() {
                this.$v.$touch();
                if ((this.$v.$error)) {
                    toast({
                        'title': "",
                        'message': this.$t('VALIDATION_FAILED'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return
                }

                let object = {
                    id: this.$route.params.unit_id,
                    data: {key_pickup: this.$store.getters[GET_UNIT_ITEM]('basic.key_pickup')}
                }
                this.saveLoader = true
                this.$store.dispatch('actionUpdateUnitKeyPickup', object).then(() => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })

                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }
                ).finally(()=>{
                    this.saveLoader = false
                })
            }
        },
        validations: {
            address: {
                required,
                minLength: minLength(3)
            },
            address_no: {
                required
            },
            latitude: {
                required,
                minLength: minLength(6),
                decimal,
                minValue: minValue(-90),
                maxValue: maxValue(90)
            },
            longitude: {
                required,
                minLength: minLength(6),
                decimal,
                minValue: minValue(-180),
                maxValue: maxValue(180)
            }
        },
        watch: {
            address: {
                handler(value) {
                    if (value) {
                        this.checked = true
                        this.access_control_components = 2

                    } else {
                        this.access_control_components = 1
                    }
                },
                immediate: true
            }
        },
        created(){

            this.access_control_general_context = {
                unit: this.$route.params.unit_id,
                company: this.$store.getters['user/getCompany']
            }
        }

    }
</script>

<style scoped>

</style>
