import http from '../http'
import {
    PROMO_CODES_URL,
} from '@/services/endpoints'

export function getPromoCodes(params){
    return http.request({
        url: PROMO_CODES_URL,
        method: 'get',
        params
    })
}

export function fetchPromoCode(id){
    return http.request({
        url: PROMO_CODES_URL + '/' + id,
        method: 'get'
    })
}

export function fetchPromoCategories(params){
    return http.request({
        url: PROMO_CODES_URL + '/category',
        method: 'get',
        params: params
    })
}

export function updatePromoCode(id, data){
    return http.request({
        url: PROMO_CODES_URL + '/' + id,
        method: 'put',
        data: data
    })
}

export function createPromoCode(data){
    return http.request({
        url: PROMO_CODES_URL,
        method: 'post',
        data: data
    })
}

export function deletePromoCode(id){
    return http.request({
        url: PROMO_CODES_URL + '/' + id,
        method: 'delete'
    })
}

export function fetchPromoCategory(id){
    return http.request({
        url: PROMO_CODES_URL + '/category/' + id,
        method: 'get'
    })
}

export function updatePromoCategory(id, data){
    return http.request({
        url: PROMO_CODES_URL + '/category/' + id,
        method: 'put',
        data: data
    })
}

export function createPromoCategory(data){
    return http.request({
        url: PROMO_CODES_URL + '/category',
        method: 'post',
        data: data
    })
}

export function deleteListPromoCodes(data){
    return http.request({
        url: PROMO_CODES_URL,
        method: 'delete',
        data: data
    })
}

export function updatePromoCodes(data){
    return http.request({
        url: PROMO_CODES_URL,
        method: 'put',
        data: data
    })
}

export function deleteCategory(id){
    return http.request({
        url: PROMO_CODES_URL + '/category/' + id,
        method: 'delete'
    })
}

export function updatePromoCodeCategory(data){
    return http.request({
        url: PROMO_CODES_URL + '/category',
        method: 'put',
        data: data
    })
}

export function createPromoCodeFilter(data){
    return http.request({
        url:PROMO_CODES_URL + '/filter',
        method: 'post',
        data:data
    })
}

export function deletePromoCodeFilter(data){
    return http.request({
        url:PROMO_CODES_URL + '/filter',
        method: 'delete',
        data: data
    })
}
