<template>
    <b-card v-cloak v-if="unit" class="property-wrapper">
        <template slot="header">
            <div class="d-flex justify-content-between flex-column flex-md-row">
                <div>
                    <div class="header-caption">{{$t('PROPERTY')}}</div>
                    <h2>{{unit.property.name}}</h2>
                </div>

                <div>
                    <div class="header-caption pl-0 pl-md-2 mt-3 mt-md-0">{{$t('UNIT')}}</div>
                    <app-quick-link :value="parentUnit ? parentUnit.id :unit.id"  :routes="unitQuickList"></app-quick-link>
                </div>
            </div>

            <Navigation :ac-fetch-key="AC_UNIT_NAVIGATION" :back="backButton" :nav-elements="unit_nav"></Navigation>
        </template>
        <page-transition transition-type="slide">
            <router-view v-if="data_loaded"></router-view>
        </page-transition>
    </b-card>
</template>

<script>

    import routeNames from '@/router/routeNames'
    import PageTransition from '@/components/app/transition/AppTransition'
    import Navigation from "@/components/app/navigation/Navigation";
    import {getUnitPaginatedList} from "@/services/unit";
    import AppQuickLink from "@/components/app/AppQuickLink";
    import {AC_UNIT_NAVIGATION} from "@/mixins/AccessControl/AccessControlEnumeration";
    import * as routeFunction from '@/shared/route_permission'
    import {EventBus, GE_UPDATE_QUICK_UNIT_LIST} from "@/shared/EventBus";

    export default {
        name: "Index",
        components: {
            AppQuickLink,
            Navigation,
            PageTransition
        },
        data() {
            return {
                AC_UNIT_NAVIGATION,
                Rn: routeNames,
                unitItem: null,
                parentUnit: null,
                data_loaded: false,
                contigentAllowed: [
                    routeNames.RM_PROPERTY_UNIT_BASIC,
                    routeNames.RM_PROPERTY_UNIT_GALLERY,
                    routeNames.RM_PROPERTY_UNIT_KEY_PICKUP,
                ],
                backButtonRoute: {name: routeNames.RN_PROPERTY_UNIT_LIST, params: {id: this.$route.params.id}},

                filter: {
                    page: 1,
                    limit: 999,
                    property_id: null,
                    contigent: 0,
                    company_id: null,
                    name: '',
                    type: null,
                    owner: null
                },
                unitList: []
            }
        },
        computed: {

            list() {
                return [
                    {
                        route: {name: routeNames.RM_PROPERTY_UNIT_BASIC},
                    },
                    {
                        route: {name: routeNames.RM_PROPERTY_UNIT_KEY_PICKUP},
                    },
                    {
                        route: {name: routeNames.RM_PROPERTY_UNIT_GALLERY},
                    },
                    {
                        route: {name: routeNames.RM_PROPERTY_UNIT_AMENITY},
                    },
                    /* {
                         route: {name: routeNames.RM_PROPERTY_UNIT_HEADLINE},
                         label: this.$t(`HEADLINE`),
                         show: true,
                     },*/
                    {
                        route: {name: routeNames.RM_PROPERTY_UNIT_DESCRIPTIONS},
                    },
                    {
                        label: this.$t(`ADDITIONAL_FEES_CHARGES`),
                        children: [
                            {
                                route: {name: routeNames.RM_PROPERTY_UNIT_COMMISSIONS},
                                access_control_context: {
                                    unit: this.unit.id,
                                    function: routeFunction.R_PROPERTY_UNIT_COMMISIONS,
                                    key: AC_UNIT_NAVIGATION
                                }
                            },
                            {
                                route: {name: routeNames.RM_PROPERTY_UNIT_DAMAGE_DEPOSIT},
                            },
                            {
                                route: {name: routeNames.RM_PROPERTY_UNIT_FEES},
                            },

                        ]
                    },
                    {
                        label: this.$t(`RATES_AVAILABILITY`),
                        children: [
                            {
                                route: {name: routeNames.RM_PROPERTY_UNIT_RATE_PLANS},
                            },
                            {
                                route: {name: routeNames.RM_PROPERTY_UNIT_PRICES},
                                access_control_context: {
                                    unit: this.unit.id,
                                    function: routeFunction.R_PROPERTY_UNIT_BULK_CALENDAR,
                                    key: AC_UNIT_NAVIGATION
                                }


                            },
                            {
                                route: {name: routeNames.RM_PROPERTY_UNIT_DEFAULT_RESTRICTIONS},
                            },
                        ]
                    },
                    {
                        route: {name: routeNames.RM_PROPERTY_UNIT_CONTINGENT_LIST},
                    }
                ]
            },
            unit() {
                return this.unitItem
            },
            backButton() {

                if (this.unit.contigent.parent) {
                    return {
                        name: routeNames.RM_PROPERTY_UNIT_CONTINGENT_LIST,
                        params: {id: this.$route.params.id, unit_id: this.unit.contigent.parent}
                    }
                } else {
                    return this.backButtonRoute
                }
            },
            unit_nav() {
                // if (this.unit && this.unit.contigent.parent > 0 && !this.contigentAllowed.includes(routeName)) {
                //     return false
                // }
                if (this.unit.contigent.parent > 0) {
                    return this.list.filter(item => item.route && this.contigentAllowed.includes(item.route.name))
                }
                return this.list
            },
            unitQuickList() {

                if (this.unit.contigent.parent > 0) {
                    return this.unitList.map(unit => {
                        return {
                            id: unit.id,
                            name: routeNames.RM_PROPERTY_UNIT_CONTINGENT_LIST,
                            label: unit.name,
                            params: {unit_id: unit.id}
                        }
                    })
                }
                return this.unitList.map(unit => {
                    return {id: unit.id, name: this.$route.name, label: unit.name, params: {unit_id: unit.id}}
                })
            },
        },
        watch: {
            '$route.params.unit_id': {
                handler(UnitId) {
                    this.data_loaded = false
                    this.$store.dispatch('actionSetUnit', UnitId).then(response => {
                        this.unitItem = response.data
                        if (!this.unitItem.contigent.parent) {
                            this.parentUnit = this.unitItem
                        }
                        this.data_loaded = true
                    }, () => {
                        this.data_loaded = true
                    })
                },
                immediate: true
            },
            '$route.params.id': {
                handler(value) {
                    this.filter.property_id = value
                    getUnitPaginatedList(this.filter).then(response => {
                        this.unitList = response.data.items
                    }, () => {
                        this.unitList = []
                    })
                },
                immediate: true
            }
        },
        created() {
            EventBus.$on(GE_UPDATE_QUICK_UNIT_LIST, (unit) => {
                getUnitPaginatedList(this.filter).then(response => {
                    this.unitList = response.data.items
                    this.parentUnit = unit
                }, () => {
                    this.unitList = []
                })
            })
            this.$store.dispatch('pricing/actionResetRatePlanSearch')
        }
    }
</script>

<style scoped>

</style>
