<template>
    <div v-if="room">
        <b-row>
            <b-col>
                <h4 class="mt-5">{{$t('INVENTORY')}}</h4>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>{{$t('TYPE')}}</th>
                            <th>{{$t('QUANTITY')}}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <inventory-form @inventoryDeleted="deleteNotify" v-if="room.inventory.length > 0"
                                        :key="inventoryItem.id" v-for="inventoryItem in room.inventory"
                                        :room_id="room_id" :inventory="inventoryItem"></inventory-form>


                        <inventory-form :room_id="room_id"></inventory-form>
                        </tbody>
                    </table>
                </div>
            </b-col>
        </b-row>

    </div>
</template>

<script>

    import InventoryForm from '@/components/unit/amenity/UnitRoomInventoryForm'

    export default {
        name: "UnitRoomInventoryList",
        props: {
            room_id: {
                type: Number
            }
        },
        components: {
            InventoryForm
        },
        data() {
            return {
                showDeleteDialog: false,
                deleteItemId: null,
                options: []
            }
        },
        computed: {
            room() {
                return this.$store.getters['amenity/getRoom'](this.room_id)
            }
        },
        methods: {
            deleteNotify() {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
            }
        }
    }
</script>

<style scoped>

</style>
