<template>
    <b-form @submit.prevent="save" v-if="reservationParams">
        <template v-if="reservationParams.params">
            <b-row :key="index + '_parameter_' + param.setup_parameter_id" v-for="(param, index) in reservationParams.params">
                <b-col md="12" lg="12" class="mb-4">
                    <distribution-reservation-parameter-item :parameter="param"
                                                             @update="setParameter($event, index)"
                                                             :supported-languages="supportedLanguages">
                    </distribution-reservation-parameter-item>
                </b-col>
            </b-row>
            <b-row v-if="reservationParams.applyTo && !rate_plan_id">
                <b-col md="12" lg="12" class="mb-4">
                    <label>{{ distribution_id ? $t("DISTRIBUTION") : $t("SALES_CHANNEL") }}</label>
                    <b-row>
                        <b-col md="4" lg="4" v-for="(distribution, index) in reservationParams.applyTo">
                            <b-checkbox :key="index"
                                        switch
                                        v-model="applyList"
                                        :value="distribution.id"
                                        :disabled="distribution.id===distribution_id || distribution.id===channel_id"
                            >{{ distribution.label }}
                            </b-checkbox>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <app-button-submit
                :loading="saving"
                @click="save"
            ></app-button-submit>
        </template>
        <app-no-data v-else>
        </app-no-data>
    </b-form>
</template>

<script>
import {getDistributionReservationParams} from "@/services/distribution";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {getSystemLanguageList} from "@/services/system";
import {updateARProcessSetupList} from "@/services/accommodation_reservation";
import {notifySuccess} from "@/shared/plugins/toastr";


export default {
    name: "DistributionReservationSidebar",
    mixins: [getErrorMessage],
    components: {
        "AppButtonSubmit": () => import("@/components/app/AppButton/AppButtonSubmit"),
        "DistributionReservationParameterItem": () => import("@/components/channel_manager/distribution/DistributionReservationParameterItem"),
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
        "AppNoData": () => import("@/components/app/AppNoData"),
        "AppButton": () => import("@/components/app/AppButton/AppButton")
    },
    data() {
        return {
            showAside: false,
            reservationParams: null,
            saving: false,
            applyList: [],
            supportedLanguages: []
        }
    },
    props: {
        distribution_id: {
            type: Number,
            default: null
        },
        distribution_context: {
            type: Number,
            default: null
        },
        company_id: {
            type: Number,
            default: null
        },
        property_id: {
            type: Number,
            default: null
        },
        owner_id: {
            type: Number,
            default: null
        },
        unit_id: {
            type: Number,
            default: null
        },
        rate_plan_id: {
            type: Number,
            default: null
        },
        channel_id: {
            type: Number,
            default: null
        }
    },
    methods: {
        getReservationsParams() {
            let req = {
                company_context: this.company.id,
                distribution_context: this.distribution_context,
                distribution_id: this.distribution_id,
                channel_id: this.channel_id,
                property_id: this.property_id,
                owner_id: this.owner_id,
                company_id: this.company_id,
                unit_id: this.unit_id,
                rate_plan_id: this.rate_plan_id,
            }

            getDistributionReservationParams(req).then(response => {
                this.reservationParams = response.data
            })
        },
        save() {
            this.saving = true
            let setupParameterList = []
            this.reservationParams.params.forEach((parameter) => {
                if (parameter.complex_object && typeof parameter.complex_object === 'object' && parameter.complex_object.hasOwnProperty('type')) {
                    if (parameter.complex_object.type === 'custom') {
                        let parameterValue = {}
                        for (const [key, val] of Object.entries(parameter.complex_object.objects)) {
                            if (val && val.value !== null && val.value !== "") {
                                parameterValue[key] = val.value
                            } else {
                                parameterValue[key] = val ? (val.value ? val.value : null) : null
                            }
                        }
                        setupParameterList.push({...parameter, ...{value_text: JSON.stringify(parameterValue), complex_object: null}})
                    } else if (parameter.complex_object.type === 'composite') {
                        let parameterValue = []
                        for (const parameterObject of parameter.complex_object.objects) {
                            let parameterItemValue = {}
                            for (const [key, val] of Object.entries(parameterObject)) {
                                if (val && val.value !== null && val.value !== "") {
                                    parameterItemValue[key] = val.value
                                } else {
                                    parameterItemValue[key] = val ? (val.value ? val.value : null) : null
                                }
                            }
                            parameterValue.push(parameterItemValue)
                        }
                        setupParameterList.push({...parameter, ...{value_text: JSON.stringify(parameterValue), complex_object: null}})
                    } else {
                        setupParameterList.push({...parameter, ...{complex_object: null}})
                    }
                } else {
                    setupParameterList.push({...parameter, ...{complex_object: null}})
                }
            })

            let req = {
                distribution_context: this.distribution_context,
                distribution_id: this.distribution_id,
                company_context: this.company.id,
                property_id: this.property_id,
                company_id: this.company_id,
                unit_id: this.unit_id,
                rate_plan_id: this.rate_plan_id,
                channel_id: this.channel_id,
                list: setupParameterList,
                apply_to: this.applyList
            }

            updateARProcessSetupList(req).then(() => {
                notifySuccess()
                this.$emit('saved')
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                    this.saving = false
                }
            )
        },
        getSupportedLanguages() {
            getSystemLanguageList().then(response => {
                response.data.forEach(el => {
                    this.supportedLanguages.push(el.name)
                })
            })
        },
        setParameter(parameterValue, index) {
            this.$set(this.reservationParams.params, index, parameterValue)
        },
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    watch: {
        channel_id: {
            handler(val) {
                if (val) {
                    this.applyList = []
                    this.applyList.push(this.channel_id)
                    this.getReservationsParams()
                }
            }
        }
    },
    created() {
        this.getReservationsParams()
        // this.getSupportedLanguages()
        if (this.distribution_id) {
            this.applyList.push(this.distribution_id)
        }
        if (this.channel_id) {
            this.applyList.push(this.channel_id)
        }
    }
}
</script>

<style scoped>

</style>
