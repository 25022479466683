<template>
    <div>
        <b-row>
            <b-col class="mb-3 d-flex justify-content-end align-items-baseline">
                <app-button button_type="new" variant="primary" @click="edit({})"
                            v-if="C_SETUP_FINANCE_PARTNERS_NEW">
                    {{ $t("NEW_CLIENT") }}
                </app-button>
            </b-col>
        </b-row>

        <partner-filter></partner-filter>
        <partner-list @edit="edit"></partner-list>

        <app-aside v-model="sidebarState">
            <template slot="header">
                <app-object-header v-if="partnerObject.id" :label="$t('CLIENT')"
                                   :description="partnerObject.invoicee_type && partnerObject.invoicee_type.label"
                                   :name="`${partnerObject.name} ${partnerObject.last_name}`">
                </app-object-header>
                <template v-else>
                    {{$t('NEW_CLIENT')}}
                </template>
            </template>
            <partner-form :partner-object="partnerObject" @saved="refresh"></partner-form>
        </app-aside>
    </div>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import {C_SETUP_FINANCE_PARTNERS_NEW} from "@/shared/component_permission";
    import PartnerList from "@/components/finance/setup/partner/PartnerList";
    import PartnerFilter from "@/components/finance/setup/partner/PartnerFilter";
    import {cloneDeep} from "lodash";
    import PartnerForm from "@/components/finance/setup/partner/PartnerForm";
    import AppAside from "@/components/app/form/AppAside";
    import AppObjectHeader from "@/components/app/AppObjectHeader";
    import {EventBus, GE_TABLE_REFRESH_SEARCH} from "@/shared/EventBus";

    export default {
        name: "Index",
        components: {AppObjectHeader, PartnerForm, PartnerFilter, PartnerList, AppButton, AppAside},
        data() {
            return {
                C_SETUP_FINANCE_PARTNERS_NEW,
                sidebarState: false,
                partnerObject: {},
            }
        },
        methods: {
            edit(partnerObject) {
                this.partnerObject = cloneDeep(partnerObject)
                this.sidebarState = true
            },
            refresh() {
                this.sidebarState = false
                EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
            }
        }
    }
</script>

<style scoped>

</style>
