import http from '../http'
import {API_CONFIG} from '../config'
import {AUTH_URL, MAGIC_LINK_URL} from '@/services/endpoints'

export function getToken(authData) {
    return http.request({
        url: AUTH_URL,
        method: 'POST',
        headers: {
            'Authorization': ""
        },
        data: {
            username: authData.username,
            password: authData.password,
            grant_type: 'password',
            client_id: API_CONFIG.CLIENT_ID,
            client_secret: API_CONFIG.CLIENT_SECRET,
            scope: '',
            lang:authData.lang
        }
    })
}

export function getMagicLinkToken(token) {
    return http.request({
        url: MAGIC_LINK_URL + '/' + token,
        method: 'GET',
        headers: {
            'Authorization': ""
        }
    })
}
