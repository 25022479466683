<template>
    <div
        class="main_calendar_test--cell d-flex align-items-center position-relative main_calendar_test--poiner animated fadeIn main_calendar_test-bb"
        :class="cellClassList" @click="handleClick">
        <span v-if="showAvailabilityString"
              :class="classList">
            {{$t("AVAILABILITY_AND_UNIT_STATUS")}}
      <template v-if="contigents && contigents.length > 1"> ({{contigents.length + 1}})</template>
        </span>
        <span :id="tooltipTarget" v-else :class="classList">{{shortUnitName}}
        </span>
        <b-tooltip v-if="!showAvailabilityString && showShortName" placement="bottom" :target="tooltipTarget" triggers="hover">
            {{unit.name}}
        </b-tooltip>
    </div>
</template>

<script>
    export default {
        name: "TableAvailabilitySidebar",
        props: {
            unit: {
                type: Object
            },
            contigents: {
                type: Array
            },
            isRoot: {
                type: Boolean,
                default: true
            },
            show: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                shortNameCharacters: 115
            }
        },
        computed: {
            showAvailabilityString(){
                return this.isRoot
            },
            tooltipTarget(){
                return `tooltip_full_unit_name-${this.unit.id}`
            },
            showShortName() {
                return this.unit.name.length > this.shortNameCharacters
            },
            shortUnitName() {
                if (this.showShortName) {
                    return this.unit.name.substr(0, this.shortNameCharacters) + '...'
                }
                return this.unit.name
            },
            cellClassList() {
                let list = 'main_calendar_test--background_gray main_calendar_test__holder--sidebar-h1'
                if (!this.isRoot) {
                    list = 'main_calendar_test__holder--sidebar-h2'
                }
                return list
            },
            classList() {
                let list = ''

                if (this.isRoot && this.contigents.length > 0) {
                    if (this.show) {
                        list += ' main_calendar_test--dropdown-down'
                    } else {
                        return list += ' main_calendar_test--dropdown-up'
                    }
                }
                return list
            }
        },
        methods: {
            handleClick() {
                if(this.isRoot && this.contigents.length > 0){
                    this.$emit('show')
                }

            }
        }
    }
</script>

<style scoped>

</style>
