<template>
    <div>
        <app-no-data v-if="noData" :heading="$t('NO_CATEGORIES')">
            <app-button
                button_type="new"
                variant="primary"
                class="pull-right align-self-end"
                @click="newCategory">
                {{ $t("NEW_CATEGORY") }}
            </app-button>
        </app-no-data>
        <app-table-v2 v-if="table" search-event="reloadPromoCategory" :provider="getData" :fields="fields"
                      :show-limit="true"
                      :search-on-create="true">
            <template v-slot:edit="data">
                <app-button
                    variant="link"
                    button_type="edit"
                    :show_text="false"
                    @click="openEdit(data)"
                ></app-button>
            </template>
        </app-table-v2>
        <app-aside :widths="['col-lg-4', '10%']" v-model="editAside">
            <template slot="header">
                {{$t('CATEGORY')}}
            </template>
            <promo-category-edit :category-id="categoryId" @categoryChanged="closeEdit"></promo-category-edit>
        </app-aside>
        <app-aside :widths="['col-lg-4', '10%']" v-model="newAside">
            <template slot="header">
                {{$t('NEW_CATEGORY')}}
            </template>
            <promo-category-new @categoryCreated="closeNew"></promo-category-new>
        </app-aside>
    </div>
</template>

<script>
import {fetchPromoCategories} from "@/services/promo_codes";
import AppTableV2 from "@/components/app/AppTableV2";
import AppButton from "@/components/app/AppButton/AppButton";
import AppAside from "@/components/app/form/AppAside";
import AppNoData from "@/components/app/AppNoData";
import PromoCategoryEdit from "@/components/direct_booking/promo_codes/category/PromoCategoryEdit";
import PromoCategoryNew from "@/components/direct_booking/promo_codes/category/PromoCategoryNew";
import {EventBus} from "@/shared/EventBus";

export default {
    name: "PromoCategoryTable",
    components:{AppTableV2, AppButton, AppAside, PromoCategoryEdit, PromoCategoryNew, AppNoData},
    data(){
        return{
            editAside: false,
            newAside: false,
            table: false,
            noData: false,
            categoryId: null,
            categoryName: null,
            fields:[
                {key: 'id', label: this.$t('ID')},
                {key: 'name', label: this.$t('NAME')},
                {key: 'edit', label:'', class: 'text-right'}
            ],
        }
    },
    methods:{
        getData(){
            return fetchPromoCategories({table_data: true})
        },
        openEdit(data){
            this.editAside = true
            this.categoryId = data.item.id
            this.categoryName = data.item.name
        },
        closeEdit(){
            this.editAside = false
        },
        closeNew(){
            this.newAside = false
            EventBus.$emit('newCategoryVisible', true)
            this.table = true
            this.noData = false
        },
        newCategory(){
            this.newAside = true
        }
    },
    created() {
        fetchPromoCategories().then(response => {
            if (response.data.length > 0){
                this.table = true
                EventBus.$emit('newCategoryVisible', true)
            }else {
                this.noData = true
                EventBus.$emit('newCategoryVisible', false)
            }
        })
    },
    mounted() {
        EventBus.$on('newPromoCategory', () => {
            this.newAside = !this.newAside
        })
    }
}
</script>

<style scoped>

</style>
