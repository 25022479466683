<template>


    <b-form-group>
        <label>{{$t('ACCOUNT_MANAGER')}}</label>

        <b-form-select
                value-field="id"
                text-field="name"
                :size="size"
                @input="$emit('input', $event)"
                :value="value"
                :options="options">
        </b-form-select>
    </b-form-group>
</template>

<script>
    import {getList} from '@/services/acc_manager/index'

    export default {
        name: "AccountManagerSelectField",
        props: {
            value: {
                default: null
            },
            size: {
                default: "md"
            }
        },
        data() {
            return {
                options: [],
            }
        },
        computed:{
          filter(){
              return {
                  company_id : this.$store.getters['user/getCompany']
              }
          }
        },
        created() {
            getList(this.filter).then(response => {
                    this.options = response.data
                    this.options.unshift({id: null, name: '-'})
                }, () => {
                    this.options = []
                }
            )
        }
    }
</script>

<style scoped>

</style>
