<template>
    <div>
        <unit-activation-filter @search="setFilter" :loading="loading"></unit-activation-filter>
        <unit-activation-table :filter="filter" @busyToggled="toggleBusy"></unit-activation-table>
        <app-aside v-model="activateUnit" :widths="['col-sm-12', '80%']">
            <template slot="header" v-if="titleVisible">
                <app-object-header :label="website.slug" :name="$t('UNIT_ACTIVATION')"></app-object-header>
            </template>
            <template v-slot="props">
                <activation-wizard :distribution-id="website.distribution.id"
                                   :website-id="website.id"
                                   :company-id="website.company_id"
                                   @finishActivation="showTitle"
                                   @closeActivationWizard="closeWizard"></activation-wizard>
            </template>
        </app-aside>
    </div>
</template>

<script>

import UnitActivationFilter from "@/components/direct_booking/website/unit_activation/UnitActivationFilter";
import UnitActivationTable from "@/components/direct_booking/website/unit_activation/UnitActivationTable";
import AppAside from "@/components/app/form/AppAside";
import ActivationWizard from "@/components/direct_booking/website/unit_activation/ActivationWizard";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import {EventBus} from "@/shared/EventBus";
import {cloneDeep} from "lodash";

export default {
    name: "Index",
    components: {
        UnitActivationFilter,
        UnitActivationTable,
        AppAside,
        ActivationWizard,
        AppObjectHeader
    },

    data() {
        return {
            titleVisible: true,
            filter: null,
            loading: false
        }
    },

    computed: {
        website() {
            return this.$store.getters['website/getWebsite']
        },

        activateUnit: {
            get() {
                return this.$store.state.website.activateUnits
            },
            set(value) {
                this.$store.commit('website/setActivationAsideState', value)
            }
        }
    },
    methods: {
        closeWizard() {
            this.activateUnit = false
        },
        showTitle() {
            this.titleVisible = false
        },
        setFilter(values) {
            this.filter = cloneDeep(values)
        },
        toggleBusy($event) {
            this.loading = $event
        }
    },
    watch: {
        '$route.query.activate': {
            handler(value) {
                if (value) this.activateUnit = true
            },
            immediate: true,
        },
        activateUnit: {
            handler() {
                if (!this.activateUnit) {
                    EventBus.$emit('refreshActivationUnitTable')
                    EventBus.$emit('deselectActivationUnitTable')
                }
            }
        }
    }

}
</script>

<style scoped>

</style>
