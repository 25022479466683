<template>
    <div>
        <appearance-form :app-client-type-id="APP_CLIENT_TYPE_GUEST_BACKOFFICE"
                         :app-client-id="parseInt(companyId)"></appearance-form>
    </div>
</template>

<script>

import AppearanceForm from "@/components/appearance/AppearanceForm";
import {APP_CLIENT_TYPE_GUEST_BACKOFFICE} from "@/shared/constants";

export default {
    name: "Index",
    components: {AppearanceForm},
    data() {
        return {
            APP_CLIENT_TYPE_GUEST_BACKOFFICE,

        }
    },

    computed: {
        companyId() {
            return this.$store.getters['user/getCompany']
        }
    }
}
</script>

<style scoped>

</style>
