<template>
    <property-settings-form
        :property="property"
        @update="updateFormData">
    </property-settings-form>
</template>

<script>
import PropertySettingsForm from "@/components/property/settings/PropertySettingsForm" ;

export default {
    name: "Index",
    components: {PropertySettingsForm},
    props: {
        property: {
            type: Object
        }
    },
    methods:{
        updateFormData(data){
            this.$emit('update', data)
        }
    }
}
</script>

<style scoped>

</style>
