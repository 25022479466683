<template>
  <b-form @submit.prevent="copyRatePlanList">
      <b-form-group>
        <label>{{$t('SELECT_UNIT')}}</label>
          <app-select v-model="selectedUnit" @input="setRatePlanList" :options="unitList"></app-select>
      </b-form-group>
      <div v-if="selectedUnit">

        <b-table small  responsive :items="sorting(ratePlanList)" :fields="fields">
          <template v-slot:id="data">
            <b-checkbox inline type="checkbox"
                        :key="data.item.id"
                        :value="data.item.id"
                        v-model="selectedRatePlan"
                        switch
            ></b-checkbox>
          </template>
        </b-table>
      </div>
      <submit-button :loading="loading" v-if="selectedUnit" :disabled="selectedRatePlan.length === 0">{{$t('APPLY_RATE_PLAN')}}</submit-button>

  </b-form>
</template>

<script>
  import {getPricingRatePlanList} from "@/services/pricing";
  import {getUnitPaginatedList} from '@/services/unit/index'
  import SubmitButton from "@/components/app/AppButton/AppButtonSubmit"
  import {toast} from "@/shared/plugins/toastr";
  import {getErrorMessage} from '@/mixins/error/getErrorMessage'
  import _findKey from 'lodash/findKey'
  import AppSelect from "@/components/app/AppSelect/AppSelect";

  export default {
    name: "copy-rate-plan-form",
      props:{
          hasNrf:{
              type:Boolean,
              default:false
          }
      },
    data() {
      return {
        loading:false,
        selectedUnit: null,
        selectedRatePlan: [],
        unitList: [],
        ratePlanList: [],
        fields:[
          {key:'name', label:this.$t('NAME')},
          {key:'distribution.name',label: this.$t('DISTRIBUTION')},
          {key:'id', label:''}
        ]
      }
    },
    mixin:[getErrorMessage],
    components: {
        AppSelect,
      SubmitButton
    },
    computed:{
      property(){
        return this.$store.getters['property/getProperty']
      },
    },
    methods: {
      sorting(array) {
        // Set slice() to avoid to generate an infinite loop!

        return array.slice().sort(function (a, b) {
          if (a.type) {
            return a.type - b.type
          }
          if (a.distribution.name < b.distribution.name) {
            return -1
          }

        });
      },
      setRatePlanList(value) {
        this.ratePlanList = []
        if (value) {
          getPricingRatePlanList({unit: this.selectedUnit, limit: 1000})
            .then(response => {
              if (response.data.items.length > 0) {
                for (let item of response.data.items) {
                  if (item.distribution.id !== null) {
                      if(!this.hasNrf){
                          if(item.parent.category !== 2){
                              this.ratePlanList.push(item)
                          }
                      } else{
                          this.ratePlanList.push(item)
                      }
                  }
                }
              }
            })
        }
      },
      getUnitList() {
        getUnitPaginatedList({property_id: this.property.id, contigent: 0, limit: 1000})
          .then((response) => {
            let unit_list = response.data.items
            if (unit_list.length > 0) {
              for (let unit of unit_list){
                if(Number(unit.id) !== Number(this.$route.params.unit_id)){
                  this.unitList.push(unit)
                }
              }
            }
          }).catch(error => {
              this.unitList = []
        })
      },
      copyRatePlanList(){
       this.loading = true
       this.$store.dispatch('pricing/actionCopyRatePlans',{unit: this.$route.params.unit_id,
         rate_plan_ids: this.selectedRatePlan})
          .then(() => {
              this.loading = false
            this.$emit('ratePlanCopied')
              toast({
                'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                'type': 'success',
                'timeout': 2000
              })
              this.$emit('ratePlanSaved')
            }, error => {
              this.loading = false
              toast({
                'title': "Action  fail",
                'message': this.getMessages(error),
                'type': 'error',
                'timeout': 2000
              })
            }
          ).finally(() => {
        })
      }
    },
    created() {
      this.getUnitList()
    }
  }
</script>

<style scoped>

</style>
