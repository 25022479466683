<template>
    <div>
        <b-form v-if="show_los_occ_form" @submit.prevent="save">
            <b-row>
                <b-col>
                    <table style="width: 100%" class="table">
                        <thead>
                        <tr>
                            <th v-if="los.length > 1">{{ $t("LENGTH_OF_STAY") }}</th>
                            <th v-if="occupancy.length >1">{{ $t("OCCUPANY_PERSONS") }}</th>
                            <th class="text-center">{{ $t('PRICE') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="losItem in los">
                            <tr :key="losItem.min_stay + '_' + occupancyItem.min_guests"
                                v-for="occupancyItem in occupancy">
                                <td v-if="los.length > 1">
                                    {{ losItem.min_stay }}
                                    <span v-if="losItem.max_stay"> - {{ losItem.max_stay }}</span>
                                    <span v-else>+</span>
                                </td>
                                <td v-if="occupancy.length >1">
                                    {{ occupancyItem.min_guests }}
                                    <span v-if="occupancyItem.max_guests"> - {{ occupancyItem.max_guests }}</span>
                                    <span v-else>+</span>
                                </td>
                                <td class="mb-0 mt-0 pb-2 pt-2">
                                    <app-number-input :min="1" v-model="amount[losItem.id][occupancyItem.id]"
                                                      :prepend="currency"></app-number-input>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mb-4">
                    <app-submit :disabled="savePriceDisabled" :loading="loading"></app-submit>
                </b-col>
            </b-row>
        </b-form>
        <b-form @submit.prevent="saveRelativePrice">
            <div>
                <b-row>
                    <b-col class="mb-4">
                        <label>{{ $t('LABEL_VALUE') }}</label>
                        <b-input-group>
                            <b-input type="number" v-model="value"></b-input>
                            <b-input-group-append>
                                <app-select
                                    v-model="selectedType"
                                    :options="selectDiscountTypeOptions"
                                    :search-empty-item="false"
                                    value-field="value"
                                    text-field="text"
                                >
                                </app-select>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <app-submit :disabled="saveRelativePriceDisabled" :loading="loading_relative"></app-submit>
                    </b-col>
                </b-row>
            </div>

        </b-form>
    </div>

</template>

<script>
import AppNumberInput from "@/components/app/form/AppNumberInput";
import {PERC, FIX} from "@/shared/constants";
import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {saveGroupPrices, saveGroupPricesRelative} from "@/services/unit/index";

import {EventBus, GE_CALENDAR_LOAD_PRICES, GE_CALENDAR_OPEN_GROUP_EDIT_ASIDE} from "@/shared/EventBus";
import {toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {differenceWith, isEqual} from "lodash";

export default {
    name: "MainCalendarGroupRatesForm",
    components: {AppSubmit, AppNumberInput, AppSelect},
    mixins: [getErrorMessage],
    props: {
        units: {
            type: Array
        },
        periods: {
            type: Array
        },
        allowedWeekday: {
            type: Array
        },
        selected_unit_ids: {
            type: Array
        },
        rates:{
            type:Array,
            default(){
                return []
            }
        }
    },
    data() {
        return {
            value_type: PERC, // 0 -> percentage increase, 1 fix increase, 2 enter prices
            value: null,
            loading: false,
            loading_relative: false,
            selectedType: 1,
            selectDiscountTypeOptions: [
                {value: PERC, text: '%'},
                {value: FIX, text: 'fix'},
            ],
            los: [],
            occupancy: [],
            show_los_occ_form: false,
            amount: {}
        }
    },
    computed: {
        saveRelativePriceDisabled() {
            let periodList = this.periods.filter(item => item.deleted !== 1 && item.start && item.end);
            return this.value === null || periodList.length === 0 || this.selected_unit_ids.length === 0 || this.allowedWeekday.length === 0
        },
        savePriceDisabled(){
            let periodList = this.periods.filter(item => item.deleted !== 1 && item.start && item.end);
            let exist_value = false
            for(const losItem of this.los) {
               for(const occupancyItem of this.occupancy) {
                   if(this.amount[losItem.id][occupancyItem.id] !== null){
                       exist_value = true
                       break
                   }
                }
            }
            return !exist_value  || periodList.length === 0 || this.selected_unit_ids.length === 0 || this.allowedWeekday.length === 0
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        showLosOccupancyRate() {
            return false
        },
        currency() {
            return this.company.hasOwnProperty('working_currency') ? this.company.working_currency.code : null
        },
    },
    methods: {
        populateData(units,rates) {
            if (units.length === 0) {
                return
            }
            //first unit
            const unitObject = units[0]
            this.los = unitObject.los.map(el => {
                return {
                    id: 'los_' + el.min_stay + '_' + el.max_stay,
                    min_stay: el.min_stay,
                    max_stay: el.max_stay

                }
            })

            this.occupancy = unitObject.occupancy.map(el => {
                return {
                    id: 'occupancy_' + el.min_guests + '_' + el.max_guests,
                    min_guests: el.min_guests,
                    max_guests: el.max_guests

                }
            })
            if (this.occupancy.length > 0 && this.los.length > 0) {
                this.los.forEach(losItem => {
                    this.$set(this.amount, losItem.id, {})
                    this.occupancy.forEach(occupancyItem => {
                        this.$set(this.amount[losItem.id], occupancyItem.id, null)

                    })
                })
            }
            if(rates.length > 0){
                rates.forEach(el =>{
                    const los_id = 'los_'+el.los.min_stay+'_'+el.los.max_stay
                    const occupancy_id = 'occupancy_'+el.occupancy.min_guests+'_'+el.occupancy.max_guests
                    this.amount[los_id][occupancy_id] = el.amount
                })
            }
        },
        setVisibilityLosOccupancyForm(units) {
            let equal_los_occ = false
            if (units.length > 0) {
                if(units.length === 1){
                    this.show_los_occ_form = true
                    return
                }
                const firstUnitObject = units[0]
                const los_f = firstUnitObject.los.map(obj => {
                    return {
                        min_stay: obj.min_stay,
                        max_stay: obj.max_stay
                    }
                })

                const occupancy_f = firstUnitObject.occupancy.map(obj => {
                    return {
                        min_guests: obj.min_guests,
                        max_guests: obj.max_guests
                    }
                })

                for (let unitObject of units) {
                    let unit_los_map = unitObject.los.map(obj => {
                        return {
                            min_stay: obj.min_stay,
                            max_stay: obj.max_stay
                        }
                    })
                    let unit_occupancy_map = unitObject.los.map(obj => {
                        return {
                            min_guests: obj.min_guests,
                            max_guests: obj.max_guests
                        }
                    })
                    if (differenceWith(los_f, unit_los_map, isEqual).length > 0 || differenceWith(occupancy_f, unit_occupancy_map, isEqual).length > 0) {
                        equal_los_occ = false
                        break
                    } else {
                        equal_los_occ = true
                    }

                }
            }
            this.show_los_occ_form = equal_los_occ
        },
        preparePricingRequest() {
            let prepare_request = []
            for (const [losKey, occupancy] of Object.entries(this.amount)) {
                const losObject = this.los.find(el => el.id === losKey)
                for (const [occupancyKey, value] of Object.entries(occupancy)) {
                    const occupancyObject = this.occupancy.find(el => el.id === occupancyKey)
                    prepare_request.push({
                        los: {
                            min_stay: losObject.min_stay,
                            max_stay: losObject.max_stay
                        },
                        occupancy: {
                            min_guests: occupancyObject.min_guests,
                            max_guests: occupancyObject.max_guests
                        },
                        amount: value
                    })
                }
            }
            return prepare_request
        },
        save() {
            this.loading = true
            this.preparePricingRequest()
            let periodList = this.periods.filter(item => item.deleted !== 1 && item.start && item.end);
            saveGroupPrices({
                periods: periodList.map(el => {
                    return {
                        from: el.start,
                        to: el.end
                    }
                }),
                allowed_weekday: this.allowedWeekday,
                unit: this.selected_unit_ids,
                pricing: this.preparePricingRequest()
            }).then(() => {
                this.selected_unit_ids.forEach(unitId => {
                    EventBus.$emit(GE_CALENDAR_LOAD_PRICES, {
                        value: null,
                        unitId: unitId
                    })
                })
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 6000
                })
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 10000
                })
            }).finally(() => {
                this.loading = false
            })
        },
        saveRelativePrice() {
            this.loading_relative = true
            let periodList = this.periods.filter(item => item.deleted !== 1 && item.start && item.end);
            saveGroupPricesRelative({
                periods: periodList.map(el => {
                    return {
                        from: el.start,
                        to: el.end
                    }
                }),
                allowed_weekday: this.allowedWeekday,
                value_type: this.selectedType,
                value: this.value,
                unit: this.selected_unit_ids
            }).then(() => {
                this.value = null
                this.selected_unit_ids.forEach(unitId => {
                    EventBus.$emit(GE_CALENDAR_LOAD_PRICES, {
                        value: null,
                        unitId: unitId
                    })
                })
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 6000
                })
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 10000
                })
            }).finally(() => {
                this.loading_relative = false
            })
        }
    },
    watch: {
        selected_unit_ids:{
            handler(unit_ids){
                this.$nextTick(()=>{
                    let units = this.units.filter(el => unit_ids.includes(el.id))
                    this.populateData(units)
                    this.setVisibilityLosOccupancyForm(units)
                })
            }
        },
        units:{
            handler(unitList){
                this.$nextTick(()=>{
                    let units = unitList.filter(el => this.selected_unit_ids.includes(el.id))
                    this.populateData(units,this.rates)
                    this.setVisibilityLosOccupancyForm(units)
                })
            }, immediate:true
        },
  /*      rates:{
            handler(rateList){
                this.$nextTick(()=>{
                    if(rateList.length > 0){
                        rateList.forEach(el =>{
                            const los_id = 'los_'+el.los.min_stay+'_'+el.los.max_stay
                            const occupancy_id = 'occupancy_'+el.occupancy.min_guests+'_'+el.occupancy.max_guests
                            this.amount[los_id][occupancy_id] = el.amount
                        })
                    }
                })
            }
        }*/
    }

}
</script>

<style scoped>

</style>
