<template>
    <div>
        <b-card class="repeater-1">
            <div slot="header">
                <b-row class="mb-2">
                    <b-col :lg="distributionId === ICAL ? '9' : '6'">
                        <repeater-header :headerClass="'mt-0 mb-0'">
                            {{ unit.name }}
                        </repeater-header>
                        <div class="pl-3"
                             v-if="mappingUnitData.hasOwnProperty('unit_eid') && mappingUnitData.unit_eid && distributionId !== ICAL">
                            {{ $t('EXTERNAL_ID') }}: {{ mappingUnitData.unit_eid }}
                            <app-access-control tag="span" @AccessControlEvent="addToAccessControlCounter()"
                                                :access_control_context="{unit: unit.id, function: C_PROPERTY_CM_PM_UNIT_STATUS, distribution: distributionId, key: AC_CHANNEL_MANAGER_UNIT}">
                                <b-badge class="ml-1" v-if="unitStatus && unitStatus.status_eid" variant="primary">
                                    {{ unitStatus.name }}
                                    ({{ unitStatus.status_eid }})
                                </b-badge>
                            </app-access-control>
                        </div>
                        <app-access-control v-else tag="div" class="pl-3"
                                            @AccessControlEvent="addToAccessControlCounter()"
                                            :access_control_context="{unit: unit.id, function: C_PROPERTY_CM_PM_UNIT_STATUS, distribution: distributionId, key: AC_CHANNEL_MANAGER_UNIT}">
                            <b-badge class="ml-1" v-if="unitStatus && unitStatus.status_eid" variant="primary">
                                {{ unitStatus.name }}
                                ({{ unitStatus.status_eid }})
                            </b-badge>
                        </app-access-control>
                        <div v-if="distributionId === ICAL && icalExportLinks" class="pl-3">
                            <b-row>
                                <b-col>
                                    {{ icalExportLinks }}
                                    <app-button variant="link"
                                                button_type="copy"
                                                class="action_button"
                                                :show_text="false"
                                                @click="copyToClipboard(icalExportLinks)">
                                    </app-button>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                    <b-col :lg="distributionId === ICAL ? '3' : '6'">
                        <div class="mb-0 pl-3">
                            <app-access-control @AccessControlEvent="addToAccessControlCounter()"
                                                tag="div"
                                                :access_control_context="{unit:unit.id, function:C_PROPERTY_CM_PM_UNIT_ACTIVATE_V,distribution:distributionId,key:AC_CHANNEL_MANAGER_UNIT}">
                                <template v-slot:default="props">
                                    <b-tooltip v-if="props.messages.length"
                                               triggers="hover"
                                               :title="props.messages[0].message" :target="'key_'+unit.id">
                                    </b-tooltip>
                                    <app-button
                                        :id="'key_'+unit.id"
                                        :disabled="props.disabled"
                                        class="pull-right ml-3" @click="enableUnitStatusActive(distributionId)">
                                        <span v-if="distributionId === ICAL">{{ $t('EXPORT_ACTIVATE') }}</span>
                                        <span v-else>{{ $t('ACTIVATE') }}</span>
                                    </app-button>
                                </template>
                            </app-access-control>
                            <app-access-control @AccessControlEvent="addToAccessControlCounter()"
                                                tag="div"
                                                :access_control_context="{unit:unit.id, function:C_PROPERTY_CM_PM_UNIT_ACTIVATE_WITH_WIZARD,distribution:distributionId,key:AC_CHANNEL_MANAGER_UNIT}">
                                <template v-slot:default="props">
                                    <b-tooltip v-if="props.messages.length"
                                               triggers="hover"
                                               :title="props.messages[0].message" :target="'key_'+unit.id">
                                    </b-tooltip>
                                    <app-button
                                        :id="'key_'+unit.id"
                                        :disabled="props.disabled"
                                        class="pull-right ml-3" @click="activateWithWizard()">{{ $t('ACTIVATE') }}
                                    </app-button>
                                </template>
                            </app-access-control>
                            <app-access-control @AccessControlEvent="addToAccessControlCounter()"
                                                tag="div"
                                                :access_control_context="{unit:unit.id, function:C_PROPERTY_CM_PM_UNIT_ACTIVATE_WITH_SIDEBAR, distribution:distributionId, key:AC_CHANNEL_MANAGER_UNIT}">
                                <template v-slot:default="props">
                                    <app-button
                                        :id="'single_activation_'+unit.id"
                                        class="pull-right ml-3"
                                        @click="singleActivationActivate(unit.id)">
                                        {{ $t('ACTIVATE') }}
                                    </app-button>
                                </template>
                            </app-access-control>
                            <app-access-control
                                class="pull-right"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{unit:unit.id, function:C_PROPERTY_CM_PM_UNIT_DEACTIVATE_V,distribution:distributionId,key:AC_CHANNEL_MANAGER_UNIT}"
                                tag="div">
                                <template v-slot:default="props">
                                    <b-tooltip v-if="props.messages.length"
                                               triggers="hover"
                                               :title="props.messages[0].message" :target="'key_deactivate_'+unit.id">
                                    </b-tooltip>
                                    <app-button :disabled="props.disabled" @click="showDeactivateDialog = true"
                                                :id="'key_deactivate_'+unit.id">
                                        <span v-if="distributionId === ICAL">{{ $t('EXPORT_DEACTIVATE') }}</span>
                                        <span v-else>{{ $t('DEACTIVATE') }}</span>
                                    </app-button>
                                </template>
                            </app-access-control>
                            <app-button
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{unit:unit.id, function:C_PROPERTY_CM_PM_UNIT_HEADLINE_V,distribution:distributionId,key:AC_CHANNEL_MANAGER_UNIT}"
                                variant="primary" class="pull-right mr-3"
                                @click="showUnitHeadlineForm">{{ $t('HEADLINE') }}
                            </app-button>
                            <app-button
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{unit:unit.id, function:C_PROPERTY_CM_PM_UNIT_MAPPING_V,distribution:distributionId,key:AC_CHANNEL_MANAGER_UNIT}"
                                :c_permission="c_permission_unit_mapping" variant="primary"
                                class="pull-right mr-3"
                                @click="showUnitRateMapForm">
                                {{ $t('MAPPING') }}
                            </app-button>
                            <app-button @AccessControlEvent="addToAccessControlCounter()"
                                        :access_control_context="{unit:unit.id, function:C_PROPERTY_CM_PM_UNIT_SYNC_V,distribution:distributionId,key:AC_CHANNEL_MANAGER_UNIT}"
                                        :c_permission="c_permission_unit_sync"
                                        v-if="hasPermissionUnitSyncView "
                                        button_type="status" class="pull-right mr-3"
                                        @click="syncUnit">Sync
                            </app-button>
                            <app-access-control
                                class="pull-right"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{unit:unit.id, function:C_PROPERTY_CM_PM_IMPORT_ICAL_E,distribution:distributionId,key:AC_CHANNEL_MANAGER_UNIT}"
                                tag="div">
                                <template v-slot:default="props">
                                    <app-button v-if="distributionId === ICAL"
                                                class="pull-right mr-3"
                                                @click="icalAside = true"
                                    >{{ $t('IMPORT_ICAL') }}
                                    </app-button>
                                </template>
                            </app-access-control>

                            <template v-if="distributionId === ICAL">
                                <app-button @AccessControlEvent="addToAccessControlCounter()"
                                            :access_control_context="{unit:unit.id, function:C_PROPERTY_CM_PM_UNIT_PUBLISH_V,distribution:distributionId,key:AC_CHANNEL_MANAGER_UNIT}"
                                            v-if="hasPermissionUnitPublishView"
                                            :c_permission="c_permission_unit_publish"
                                            variant="primary" button_type="general"
                                            class="pull-right mr-3"
                                            @click="unitActivateAndCreate(distributionId)">
                                    {{ $t('EXPORT_CREATE') }}
                                </app-button>
                            </template>
                            <template v-else>
                                <app-button @AccessControlEvent="addToAccessControlCounter()"
                                            :access_control_context="{unit:unit.id, function:C_PROPERTY_CM_PM_UNIT_PUBLISH_V,distribution:distributionId,key:AC_CHANNEL_MANAGER_UNIT}"
                                            v-if="hasPermissionUnitPublishView"
                                            :c_permission="c_permission_unit_publish"
                                            variant="primary" button_type="general"
                                            class="pull-right mr-3"
                                            @click="unitCreate">
                                    {{ $t('CREATE') }}
                                </app-button>
                            </template>

                            <app-access-control
                                class="pull-right"
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{unit:unit.id, function:C_PROPERTY_CM_PM_UNIT_SETUP,distribution:distributionId,key:AC_CHANNEL_MANAGER_UNIT}"
                                tag="div">
                                <template v-slot:default="props">
                                    <app-button v-if="customUnitSetupDistributions.includes(distributionId)"
                                                variant="primary"
                                                class="pull-right mr-3"
                                                @click="showDistributionUnitSetup">
                                        {{ $t('SETUP') }}
                                    </app-button>
                                </template>
                            </app-access-control>

                            <app-button
                                @AccessControlEvent="addToAccessControlCounter()"
                                :access_control_context="{unit: unit.id, function: C_PROPERTY_CM_PM_UNIT_GALLERY_DOWNLOAD, distribution: distributionId, key: AC_CHANNEL_MANAGER_UNIT}"
                                variant="primary"
                                class="pull-right mr-3"
                                @click="downloadUnitDistributionGallery">
                                {{ $t('DOWNLOAD_GALLERY') }}
                            </app-button>

                        </div>
                    </b-col>
                </b-row>
            </div>
            <b-row
                v-if="mappingUnitData.hasOwnProperty('unit_eid') && mappingUnitData.unit_eid && mappingUnitData.hasOwnProperty('rate_plan_mapping')&& mappingUnitData.rate_plan_mapping.length > 0 && distributionHasRatePlan.includes(Number(distributionId))">

                <rate-plan-mapping :mappingRateData="mappingUnitData.rate_plan_mapping">
                </rate-plan-mapping>
            </b-row>
            <app-access-control
                @AccessControlEvent="addToAccessControlCounter()"
                :access_control_context="{unit:unit.id, function:C_PROPERTY_CM_PM_IMPORT_ICAL_E,distribution:distributionId,key:AC_CHANNEL_MANAGER_UNIT}"
                tag="div">
                <template v-slot:default="props">
                    <b-row v-if="distributionId === ICAL && icalImportObject">
                        <ical-import-links :ical-links="icalImportObject" :unit_id="unit.id"
                                           :key="icalTableKey"></ical-import-links>
                    </b-row>
                </template>
            </app-access-control>

            <app-aside v-model="unitRateMappingPanelState">
                <template slot="header">{{ unit ? unit.name : null }} ({{ unit ? unit.id : null }})</template>
                <unit-product-management-edit
                    :externalUnitList="externalUnitList"
                    :externalRatePlanList="externalRatePlanList"
                    @updateUnitMapping="updateUnitMapping"
                    @updateRateMapping="updateRateMapping"
                    :unit="unit"
                    :mappingUnitData="mappingUnitData">
                </unit-product-management-edit>
            </app-aside>
            <app-aside :widths="['col-lg-4', 'col-sm-12', '40%']" v-model="unitHeadlinePanelState">
                <template slot="header">{{ $t("HEADLINE") }}</template>
                <unit-headline-edit-form :unit-id="unit.id" :distribution-id="distributionId">
                </unit-headline-edit-form>
            </app-aside>
            <app-aside :widths="['col-sm-12', '40%']" v-model="aside">
                <template slot="header">
                    {{ distributionObject.name }} {{ $t('ACTIVATION').toLowerCase() }}
                </template>
                <distribution-unit-activation-wizard :distribution_id="distributionId "
                                                     :unit="unit" :property="property"
                                                     @activate="activate">
                </distribution-unit-activation-wizard>
            </app-aside>
            <app-aside v-model="singleAside">
                <template slot="header">
                    <app-object-header :label="property.name"
                                       :name="distributionObject.name + ' ' + $t('ACTIVATION').toLowerCase()"
                                       :description="unit.name"
                    ></app-object-header>
                </template>
                <single-activation-form :object-id="unit_id"
                                        :distribution_id="distributionId"
                                        :company_id="distributionObject.company_id"
                                        @singleActivated="singleActivated">
                </single-activation-form>
            </app-aside>
            <app-aside v-model="icalAside">
                <template slot="header">
                    <app-object-header :label="property.name"
                                       :name="$t('IMPORT_ICAL')"
                                       :description="unit.name"></app-object-header>
                </template>
                <unit-management-ical-export-form :unit_id="unit.id" :distribution_id="distributionId"
                                                  @import_saved="closeIcalExport"></unit-management-ical-export-form>
            </app-aside>
        </b-card>
        <modal-dialog :show="showDeactivateDialog" @confirm="disableUnitStatusActive"
                      @cancel="showDeactivateDialog = false">
            {{ $t('DEACTIVATE_UNIT_MESSAGE', {website_domain: distributionObject.name}) }}
        </modal-dialog>
        <app-aside v-model="distributionUnitSetupAside">
            <template slot="header">{{ $t('SETUP') }}
                <div class="header-info mt-2">{{ unit.name }}</div>
            </template>
            <component :is="'Distribution'+distributionId+'UnitSetupForm'"
                       :unit="unit"
                       :distribution_id="distributionId"
                        @updateParameter="updateParameter">
            </component>
        </app-aside>
    </div>
</template>

<script>
import {
    activateUnit,
    disableUnitActivation,
    downloadPropertyDistributionGallery,
    getChannelRatePlanList,
    getChannelUnitList,
    unitCmSync,
    unitCreate,
    updateSetupParameters
} from "@/services/channel_manager/index";
import NoData from '@/components/app/AppNoData'
import AppAside from '@/components/app/form/AppAside'
import UnitProductManagementEdit from '@/components/channel_manager/product_management/UnitProductManagementEdit'
import SingleActivationForm from "@/components/direct_booking/website/unit_activation/SingleActivationForm";
import RatePlanMapping from '@/components/channel_manager/product_management/RatePlanMapping'
import AppObjectHeader from "@/components/app/AppObjectHeader";
import UnitManagementIcalExportForm from "@/components/channel_manager/product_management/UnitManagementIcalExportForm";
import IcalImportLinks from "@/components/channel_manager/product_management/IcalImportLinks";
import {notifySuccess, toast} from '@/shared/plugins/toastr'
import {getErrorMessage} from '@/mixins/error/getErrorMessage'
import {
    C_PROPERTY_CM_PM_UNIT_ACTIVATE_TEST_V,
    C_PROPERTY_CM_PM_UNIT_ACTIVATE_V,
    C_PROPERTY_CM_PM_UNIT_ACTIVATE_WITH_SIDEBAR,
    C_PROPERTY_CM_PM_UNIT_ACTIVATE_WITH_WIZARD,
    C_PROPERTY_CM_PM_UNIT_DEACTIVATE_V,
    C_PROPERTY_CM_PM_UNIT_HEADLINE_V,
    C_PROPERTY_CM_PM_UNIT_MAPPING_E,
    C_PROPERTY_CM_PM_UNIT_MAPPING_V,
    C_PROPERTY_CM_PM_UNIT_PUBLISH_E,
    C_PROPERTY_CM_PM_UNIT_PUBLISH_V,
    C_PROPERTY_CM_PM_UNIT_SYNC_E,
    C_PROPERTY_CM_PM_UNIT_SYNC_V,
    C_PROPERTY_CM_PM_IMPORT_ICAL_E,
    C_PROPERTY_CM_PM_UNIT_GALLERY_DOWNLOAD,
    C_PROPERTY_CM_PM_UNIT_STATUS,
    C_PROPERTY_CM_PM_UNIT_SETUP
} from "@/shared/component_permission";
import UnitHeadlineEditForm from '@/components/channel_manager/headline/UnitHeadlineForm'
import {
    AGODA,
    AIRBNB,
    BOOKING,
    EXPEDIA,
    HOSTEL_WORLD,
    HOTEL_BEDS,
    ICAL,
    TRIP_ADVISOR
} from "@/shared/constants";
import RepeaterHeader from "@/components/app/form/RepeaterHeader";
import AppButton from "@/components/app/AppButton/AppButton"
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {AC_CHANNEL_MANAGER_UNIT} from "@/mixins/AccessControl/AccessControlEnumeration";
import ModalDialog from '@/components/app/form/AppConfirmationDialog'
import AppAccessControl from "@/components/app/AppAccessControl";
import {
    EventBus,
    GE_LOADER_HIDE,
    GE_LOADER_SHOW,
    GE_UPDATE_DATA_CHANNEL_MANAGER,
    GE_UPDATE_EXT_RATE_PLAN_CHANNEL_MANAGER,
} from "@/shared/EventBus";
import DistributionUnitActivationWizard from "@/components/channel_manager/wizard/DistributionUnitActivationWizard";
import AirBnbUnitSetupForm from "@/components/channel_manager/setup/form_per_distribution/AirBnbUnitSetupForm";
import FormHeader from "@/components/app/form/FormHeader";

export default {
    name: "UnitProductManagement",
    props: {
        unit: {
            type: Object,
        },
        mappingUnitData: {
            type: Object
        },
        icalExportLinks: {
            type: String,
            default: ''
        },
        icalImportObject: {
            type: Array,
            default: null
        },
        icalTableKey: {
            type: Number,
            default: 0
        },
        unitStatus: {
            type: Object,
            default() {
                return null
            },
        },
    },
    data() {
        return {
            C_PROPERTY_CM_PM_UNIT_PUBLISH_V,
            C_PROPERTY_CM_PM_UNIT_SYNC_V,
            C_PROPERTY_CM_PM_UNIT_ACTIVATE_V,
            C_PROPERTY_CM_PM_UNIT_DEACTIVATE_V,
            C_PROPERTY_CM_PM_UNIT_MAPPING_V,
            C_PROPERTY_CM_PM_UNIT_HEADLINE_V,
            C_PROPERTY_CM_PM_UNIT_ACTIVATE_TEST_V,
            C_PROPERTY_CM_PM_UNIT_ACTIVATE_WITH_SIDEBAR,
            C_PROPERTY_CM_PM_UNIT_ACTIVATE_WITH_WIZARD,
            C_PROPERTY_CM_PM_IMPORT_ICAL_E,
            C_PROPERTY_CM_PM_UNIT_SETUP,
            c_permission_unit_publish: C_PROPERTY_CM_PM_UNIT_PUBLISH_E,
            c_permission_unit_sync: C_PROPERTY_CM_PM_UNIT_SYNC_E,
            c_permission_unit_mapping: C_PROPERTY_CM_PM_UNIT_MAPPING_E,
            C_PROPERTY_CM_PM_UNIT_GALLERY_DOWNLOAD,
            C_PROPERTY_CM_PM_UNIT_STATUS,
            unitRateMappingPanelState: false,
            unitHeadlinePanelState: false,
            distributionUnitSetupAside: false,
            externalUnitList: [],
            externalRatePlanList: [],
            distributionId: null,
            headline: null,
            unit_status_active: 0,
            AC_CHANNEL_MANAGER_UNIT,
            BOOKING,
            EXPEDIA,
            HOSTEL_WORLD,
            TRIP_ADVISOR,
            AIRBNB,
            ICAL,
            access_control_components: 13,
            access_control_context: {key: AC_CHANNEL_MANAGER_UNIT},
            showDeactivateDialog: false,
            aside: false,
            singleAside: false,
            icalAside: false,
            unit_id: null,
            icalCheck: true,
            tableRefresh: false,
            distributionHasRatePlan: [BOOKING, EXPEDIA, HOTEL_BEDS, AGODA],
            distribution_with_wizard: [AIRBNB, BOOKING, EXPEDIA, AGODA, AGODA],
            customUnitSetupDistributions: [
                AIRBNB,
            ]
        }
    },
    mixins: [getErrorMessage, AccessControlComponent],
    components: {
        FormHeader,
        DistributionUnitActivationWizard,
        AppAccessControl,
        RepeaterHeader,
        NoData,
        UnitProductManagementEdit,
        RatePlanMapping,
        UnitHeadlineEditForm,
        AppAside,
        AppButton,
        ModalDialog,
        SingleActivationForm,
        AppObjectHeader,
        UnitManagementIcalExportForm,
        IcalImportLinks,
        'Distribution5UnitSetupForm': AirBnbUnitSetupForm,
    },
    computed: {
        hasPermissionUnitPublishView() {
            return this.$store.getters['user/getPermission'](C_PROPERTY_CM_PM_UNIT_PUBLISH_V)
        },
        hasPermissionUnitSyncView() {
            return this.$store.getters['user/getPermission'](C_PROPERTY_CM_PM_UNIT_SYNC_V)
        },
        property() {
            return this.$store.getters['property/getProperty']
        },
        distributionObject() {
            return this.$store.getters['channel_manager/getDistributionById'](this.distributionId)
        },
    },
    methods: {
        activate(request) {
            EventBus.$emit(GE_LOADER_SHOW)
            activateUnit(request).then(() => {
                toast({
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })
                this.aside = false
                EventBus.$emit(GE_UPDATE_DATA_CHANNEL_MANAGER, this.distributionId)
            }, error => {
                toast({
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 2000
                })
            }).finally(() => {
                EventBus.$emit(GE_LOADER_HIDE)
            })
        },
        channelUnitList() {
            this.externalUnitList = []
            EventBus.$emit(GE_LOADER_SHOW)
            getChannelUnitList({
                distribution: this.distributionId,
                property: this.$route.params.id
            }).then(response => {
                if (response.data.length > 0) {
                    response.data.forEach(item => {
                        let responseObject = {
                            id: item.id,
                            name: item.name + ' (' + item.id + ')'
                        }
                        this.externalUnitList.push(responseObject)
                    })
                }
            }).finally(() => {
                EventBus.$emit(GE_LOADER_HIDE)
            })
        },
        getChannelUnitRatePlan() {
            EventBus.$emit(GE_LOADER_SHOW)
            this.externalRatePlanList = []
            getChannelRatePlanList({
                distribution: this.distributionId,
                property: Number(this.$route.params.id),
                unit: Number(this.unit.id)
            }).then(response => {
                if (response.data.length > 0) {

                    for (let item of response.data) {
                        let object = {
                            id: item.id,
                            name: item.name,
                            name_show: item.name + ' (' + item.id + ')'
                        }
                        object.id = object.id !== null ? object.id.replace(/\r?\n|\r/g, "") : null
                        this.externalRatePlanList.push(object)
                    }
                }
            }).finally(() => {
                EventBus.$emit(GE_LOADER_HIDE)
            })
        },
        showUnitRateMapForm() {
            this.channelUnitList()
            this.getChannelUnitRatePlan()
            this.unitRateMappingPanelState = true
        },

        updateRateMapping(data) {
            this.mappingUnitData.rate_plan_mapping = data
        },
        unitCreate() {
            let postData = {
                company: this.property.company.id,
                property: this.property.id,
                distributions: [
                    Number(this.$route.params.distribution_id)
                ],
                items: [
                    {
                        unit: Number(this.unit.id)
                    }
                ]
            }
            EventBus.$emit(GE_LOADER_SHOW)
            unitCreate(postData)
                .then(() => {

                        toast({
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'type': 'success',
                            'timeout': 7000
                        })
                        EventBus.$emit(GE_UPDATE_DATA_CHANNEL_MANAGER, this.distributionId)

                    }, error => {
                        toast({
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 1000
                        })
                    }
                ).finally(() => {
                EventBus.$emit(GE_LOADER_HIDE)
            })
        },
        unitActivateAndCreate(distribution) {
            EventBus.$emit(GE_LOADER_SHOW)
            activateUnit({distribution, property: this.property.id, unit: this.unit.id, create_unit: true}).then(() => {
                this.aside = false
                toast({
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'type': 'success',
                    'timeout': 7000
                })
                EventBus.$emit(GE_UPDATE_DATA_CHANNEL_MANAGER, this.distributionId)
            }, error => {
                toast({
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 2000
                })
            }).finally(() => {
                EventBus.$emit(GE_LOADER_HIDE)
            })
        },
        closeIcalExport() {
            this.icalAside = false
            EventBus.$emit(GE_UPDATE_DATA_CHANNEL_MANAGER, this.distributionId)
        },
        syncUnit() {

            let postData = {
                company: this.property.company.id,
                property: this.property.id,
                distributions: [
                    Number(this.$route.params.distribution_id)
                ],
                items: [
                    {
                        unit: Number(this.unit.id)
                    }
                ]
            }
            EventBus.$emit(GE_LOADER_SHOW)
            unitCmSync(postData)
                .then(() => {
                        toast({
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'type': 'success',
                            'timeout': 7000
                        })
                        EventBus.$emit(GE_UPDATE_DATA_CHANNEL_MANAGER, this.distributionId)
                    }
                    , error => {
                        toast({
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 7000
                        })
                    }
                ).finally(() => {
                EventBus.$emit(GE_LOADER_HIDE)
            })
        },
        showUnitHeadlineForm() {
            this.unitHeadlinePanelState = true
        },
        showDistributionUnitSetup() {
            this.distributionUnitSetupAside = true
        },
        enableUnitStatusActive(distribution) {
            this.activate({distribution, property: this.property.id, unit: this.unit.id})
        },
        activateWithWizard() {
            this.aside = true
        },
        disableUnitStatusActive() {
            disableUnitActivation({
                property: Number(this.$route.params.id),
                distribution: this.distributionId,
                unit: this.unit.id
            }).then(response => {
                toast({
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 2000
                })
                this.unit_status_active = response.data.active
                EventBus.$emit(GE_UPDATE_DATA_CHANNEL_MANAGER, this.distributionId)

            }, error => {
                toast({
                    'message': this.getMessages(error),
                    'type': 'error',
                    'timeout': 2000
                })
            })
            this.showDeactivateDialog = false
        },
        updateUnitMapping(object) {
            if (!object) {
                this.mappingUnitData = {}

            }
            this.mappingUnitData = object

        },
        singleActivationActivate(unit_id) {
            this.unit_id = unit_id
            this.singleAside = true
        },
        singleActivated() {
            this.singleAside = false
            EventBus.$emit(GE_UPDATE_DATA_CHANNEL_MANAGER, this.distributionId)
        },
        updateParameter(request) {
            EventBus.$emit(GE_LOADER_SHOW)
            updateSetupParameters(request).then(() => {
                notifySuccess()
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.distributionUnitSetupAside = false
                EventBus.$emit(GE_LOADER_HIDE)
            })
        },
        copyToClipboard(iCal) {
            if (window.clipboardData && window.clipboardData.setData) {
                return this.clipboardData.setData("Text", iCal)
            } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
                let input = document.createElement("input")
                document.body.appendChild(input)
                input.visibility = 'hidden'
                input.value = iCal
                input.style.position = "fixed"
                input.focus()
                input.select()
                try {
                    document.execCommand("copy")  // Security exception may be thrown by some browsers.
                    toast({
                        //title: this.$t('NOTIFICATIONS.COPY_TO_CLIPBOARD_SUCCESS.TITLE'),
                        message: this.$t('NOTIFICATIONS.COPY_TO_CLIPBOARD_SUCCESS.MESSAGE'),
                        type: 'success',
                        timeout: 3000
                    })
                    return true
                } catch (ex) {
                    console.warn("Copy to clipboard failed.", ex)
                    toast({
                        //title: this.$t('NOTIFICATIONS.COPY_TO_CLIPBOARD_FAIL.TITLE'),
                        message: this.$t('NOTIFICATIONS.COPY_TO_CLIPBOARD_FAIL.MESSAGE'),
                        type: 'success',
                        timeout: 3000
                    })
                    return false
                } finally {
                    document.body.removeChild(input)
                }
            }
        },
        downloadUnitDistributionGallery() {
            EventBus.$emit(GE_LOADER_SHOW)
            const requestParameters = {
                distribution: this.distributionId,
                property: this.$route.params.id,
                unit: this.unit.id
            }
            const distributionName = typeof this.distributionObject === 'object' && this.distributionObject.hasOwnProperty('name') && this.distributionObject.name.trim().length > 0 ?
                this.distributionObject.name.trim().replaceAll(/[\s-.]+/g, '_').toLowerCase() : 'distribution_' + this.distributionId
            const filename = 'property_' + this.$route.params.id + '_' + distributionName + '_gallery'
            downloadPropertyDistributionGallery(requestParameters, filename).then(() => {
                notifySuccess()
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                EventBus.$emit(GE_LOADER_HIDE)
            })
        },
    },
    mounted() {

        EventBus.$on(GE_UPDATE_EXT_RATE_PLAN_CHANNEL_MANAGER, (data) => {
            if (Number(data.unit_id) === Number(this.unit.id) && data.distribution_id === EXPEDIA) {
                this.getChannelUnitRatePlan()
            }
        })
    },
    watch: {
        '$route.params.distribution_id': {
            handler(value) {
                this.distributionId = Number(value)
            },
            immediate: true
        },
        unit: {
            handler(object) {
                this.unit_status_active = object.active ? object.active : 0
            }, immediate: true
        },
        icalAside: {
            handler() {
                if (this.icalAside === false) {
                    this.$emit('refreshTable')
                }
            }
        }
    },
    beforeDestroy() {
        EventBus.$off(GE_UPDATE_EXT_RATE_PLAN_CHANNEL_MANAGER)
    }

}
</script>

<style scoped>

</style>
