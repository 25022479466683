<template>
    <div>
        <div class="dashboard-wrapper">
            <b-card>
                <b-row>
                    <b-col md="10" lg="10" class="d-flex align-items-start flex-column">
                        <div class="header-2 mb-2" style="min-height: 54px;">
                            <b-img style="width: 54px;" :src="distribution.icon"
                                   alt="logo"></b-img>
                            <span class="ml-3">{{ distribution.name }}</span>
                        </div>
                    </b-col>
                    <b-col md="2" lg="2"
                           class="bl-lg-show pl-lg-3 pt-3  pt-lg-0 d-flex flex-column justify-content-end">
                        <app-button
                            class="pl-4 mb-4"
                            variant="primary"
                            :to="{name: routeNames.RN_SYSTEM_DISTRIBUTION, params: {distribution_id: distribution.id}}">
                            {{ $t('SETTINGS') }}
                        </app-button>
                        <app-button
                            class="pl-4"
                            variant="primary"
                            @click="openAside()"> {{ $t('EDIT') }}
                        </app-button>
                    </b-col>
                </b-row>
            </b-card>
        </div>
        <app-aside v-model="showAside">
            <template slot="header">
                <app-object-header
                    :name="distribution.name"
                    :label="$t('DISTRIBUTION')">
                </app-object-header>
            </template>
            <template v-if="channelList.length" slot="header_action_button">
                <div class="aside__header--action_button col-4 mr-3">
                    <label class="label">{{ $t('SALES_CHANNEL') }}</label>
                    <app-select mode="select" :options="channelList" v-model="selectedChannel" value-field="id" text-field="label" :search-empty-item="false"></app-select>
                </div>
            </template>
            <distribution-reservation-sidebar :distribution_id="selectedChannel ? null : distribution.id" :distribution_context="selectedChannel ? distribution.id : null" :company_id="company.id" :channel_id="selectedChannel"
                                              @saved="showAside=false"></distribution-reservation-sidebar>
        </app-aside>
    </div>
</template>

<script>

import routeNames from "@/router/routeNames";
import {getDistributionChannelList} from "@/services/distribution";
import {DISTRIBUTION_MANAGERS, NEXTPAX} from "@/shared/constants";

export default {
    name: "DistributionReservationItem",
    components: {
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
        "AppObjectHeader": () => import("@/components/app/AppObjectHeader"),
        "DistributionReservationSidebar": () => import("@/components/channel_manager/distribution/DistributionReservationSidebar"),
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "AppAside": () => import("@/components/app/form/AppAside")
    },
    props: {
        distribution: {
            type: Object
        },
    },
    data() {
        return {
            routeNames,
            showAside: false,
            channelList: [],
            selectedChannel: null
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods: {
        getChannelList() {
            getDistributionChannelList({distribution_id: this.distribution.id}).then(response => {
                this.channelList = response.data
                this.selectedChannel = this.channelList[Object.keys(this.channelList)[0]].id
            })
        },
        openAside() {
            if (DISTRIBUTION_MANAGERS.includes(this.distribution.id)) {
                this.getChannelList()
            }
            this.showAside = true
        }
    },
    created() {
        if (DISTRIBUTION_MANAGERS.includes(this.distribution.id)) {
            this.getChannelList()
        }
    }
}
</script>

<style scoped>

</style>
