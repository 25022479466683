<template>
    <div class="position-relative">
        <b-table v-if="tableData.length" :hover="true" :responsive="true" :items="tableData" :fields="permissionFields" :busy="isBusy">

            <template v-slot:role="data">
                <span v-for="(role, index) in data.item.role">
                    {{ role.name }}{{ index !== data.item.role.length-1 ? ', ' : '' }}
                </span>
            </template>

            <template v-slot:action_buttons="data">
                <app-button variant="link"
                            button_type="edit"
                            :show_text="false"
                            :to="{name: routeNames.RN_USER_BASIC, params:{id: data.item.id}}">
                </app-button>
            </template>
        </b-table>

        <app-search-data v-if="!searchDone && !isBusy"></app-search-data>
        <app-no-data v-if="!tableData.length && searchDone" :heading="$t('NO_SEARCH_RESULTS')"></app-no-data>
        <app-pagination
            :listCount="totalRows"
            :per-page="filter.limit"
            v-model="currentPage"
            @input="getTableData(filter)"
            :last-page="lastPage"></app-pagination>
    </div>
</template>

<script>
    import routeNames from "@/router/routeNames";
    import AppSearchData from "@/components/app/AppSearchData";
    import AppNoData from "@/components/app/AppNoData";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {isEmpty} from "lodash";
    import {getUserList} from "@/services/user";
    import {C_USER_ROLES_V} from "@/shared/component_permission";
    import AppPagination from "@/components/app/filter/AppPagination";
    export default {
        name: "UserListTable",
        components: {AppPagination, AppButton, AppNoData, AppSearchData},
        props: {
            filter: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                routeNames,
                tableData: [],
                fields: [
                    {key: 'id', label: 'Id'},
                    {key: 'first_name', label: this.$t('FIRST_NAME')},
                    {key: 'last_name', label: this.$t('LAST_NAME')},
                    {key: 'username', label: this.$t('USERNAME_EMAIL')},
                    {key: 'role', label: this.$t('ROLE')},
                    {key: 'action_buttons', label: '', class: 'text-right'},
                ],
                isBusy: false,
                totalRows: 0,
                searchDone: false,
                lastPage: 0
            }
        },
        computed: {
            currentPage: {
                get() {
                    return this.filter.page
                },
                set(value) {
                    this.filter.page = value
                }
            },
            permissionFields() {
                if (this.$store.getters['user/getPermission'](C_USER_ROLES_V)) {
                    return this.fields
                } else {
                    return this.fields.filter(item => item.key !== 'action_buttons')
                }
            }
        },
        watch: {
            filter(newFilter) {
                if (isEmpty(newFilter)) {
                    this.tableData = []
                    this.totalRows = 0
                    this.searchDone = true
                } else {
                    this.getTableData(newFilter)
                }
            }
        },
        methods: {
            getTableData(filter) {
                this.isBusy = true
                getUserList(filter).then(res => {
                    this.tableData = res.data.items
                    this.totalRows = res.data.total
                    this.lastPage = res.data.last_page
                    this.isBusy = false
                    this.searchDone = true
                    this.$emit('isLoading', false)
                })
            },
        }
    }
</script>

<style scoped>

</style>
