<template>
    <div v-if="canViewPlatformFees">
        <b-form autocomplete="off">
            <b-row>
                <b-col md="2" sm="4" class="mb-3">
                    <label>{{ $t("FEE") }}</label>
                    <app-select
                        v-model="selectedPlatformFeeType"
                        :options="platformFeeTypes"
                        :search-empty-item="false">
                    </app-select>
                </b-col>
            </b-row>
            <b-row v-if="!!selectedPlatformFeeType">
                <b-col md="2" sm="4" class="mb-3">
                    <label>{{ $t("YEAR") }}</label>
                    <app-select
                        v-model="selectedYear"
                        :options="computedYears"
                        :search-empty-item="false">
                    </app-select>
                </b-col>
                <b-col>
                    <app-button
                        v-if="canCopyPlatformFees"
                        class="pull-right filter-submit-wrapper"
                        inline
                        @click="openCopySidebarForm">
                        {{ $t("COPY_BOOKING_FEES") }}
                    </app-button>
                </b-col>
            </b-row>
        </b-form>
        <div v-if="loading" class="d-flex justify-content-center mt-4">
            <b-spinner variant="primary"></b-spinner>
        </div>
        <b-form @submit.prevent="save"
                autocomplete="off"
                v-if="showTable">
            <b-row>
                <b-col lg="2" md="2" sm="4">
                    <b-form-group>
                        <label>{{ $t("TYPE") }}</label>
                        <app-select
                            v-model="platformFeeValueType"
                            :options="valueTypeOptions"
                            :disabled="selectedYearInPast"
                            :search-empty-item="false"
                            value-field="value"
                            text-field="text">
                        </app-select>
                    </b-form-group>
                </b-col>
                <b-col lg="8" md="7" sm="3"></b-col>
                <b-col lg="2" md="3" sm="5">
                    <b-form-group>
                        <label>{{ $t("SET_VALUE_FOR_ENTIRE_YEAR") }}</label>
                        <b-input-group class="commission-copy_year-group pull-right">
                            <b-form-input
                                class="commission-copy_year-input"
                                type="number"
                                step="0.01"
                                min="0"
                                no-wheel
                                v-model="platformFeeValueEntireYear"
                                :disabled="selectedYearInPast || !platformFeeValueType">
                            </b-form-input>
                            <b-input-group-append>
                                <b-btn
                                    style="min-width: unset"
                                    variant="primary"
                                    @click="setPlatformFeeForEntireYear"
                                    :disabled="selectedYearInPast || !platformFeeValueType || !platformFees || (!!platformFeeValueEntireYear && platformFeeValueEntireYear < 0)">
                                    +
                                </b-btn>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col>
                    <table class="text-center" style="min-width: 100%; display: block; overflow: auto;">
                        <tbody>
                        <template v-for="(month, monthIndex) in 12">
                            <app-commission-row
                                :edit-disabled="!canEditPlatformFees || selectedYearInPast || !platformFeeValueType"
                                :key="monthIndex"
                                :month="month"
                                :year="selectedYear"
                                :commissions="platformFees"
                                @update="updatePlatformFees">
                            </app-commission-row>
                        </template>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
            <b-row class="mt-4" v-if="canEditPlatformFees">
                <b-tooltip v-if="selectedYearInPast"
                           triggers="hover"
                           :title="$t('EDIT_POSSIBLE_FOR_CURRENT_AND_FUTURE_YEARS')"
                           target="tooltip-target-1">
                </b-tooltip>
                <b-col>
                    <app-button-submit
                        id="tooltip-target-1"
                        class="pull-right"
                        :loading="saving"
                        :disabled="selectedYearInPast || !platformFeeValueType || !platformFees">
                    </app-button-submit>
                </b-col>
            </b-row>
        </b-form>
        <app-aside
            v-model="copyPlatformFeesSidebarState">
            <template slot="header">
                <app-object-header
                    :name="$t('COPY_BOOKING_FEES')">
                </app-object-header>
            </template>
            <platform-fee-copy-form
                :saving="copyingPlatformFees"
                :distribution-id="distributionId"
                :channel-id="channelId"
                :distribution-list="distributionList"
                :channel-list="channelList"
                @save="copyPlatformFees">
            </platform-fee-copy-form>
        </app-aside>
    </div>
</template>

<script>
import {
    COMMISSION_TYPE_PLATFORM_FEE,
    COMMISSION_TYPE_PLATFORM_FEE_OUTBOUND,
    DISTRIBUTION_MANAGERS,
    FIX,
    OBJECT_TYPE_COMPANY,
    OBJECT_TYPE_PROPERTY,
    OBJECT_TYPE_UNIT,
    PERC,
} from "@/shared/constants";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import AppButton from "@/components/app/AppButton/AppButton";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import moment from "moment";
import {copySystemFees, getSystemFees, saveSystemFees} from "@/services/system";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {
    C_SETUP_FEES_PLATFORM_FEE_COPY,
    C_SETUP_FEES_PLATFORM_FEE_E,
    C_SETUP_FEES_PLATFORM_FEE_V
} from "@/shared/component_permission";
import AppCommissionRow from "@/components/app/commission/AppCommissionRow";
import AppAside from "@/components/app/form/AppAside";
import AppObjectHeader from "@/components/app/AppObjectHeader";
import PlatformFeeCopyForm from "@/components/fee/platform_fee/PlatformFeeCopyForm";
import {getDistributionChannelList, getDistributionList} from "@/services/distribution";

export default {
    name: "PlatformFeeEditForm",
    mixins: [
        getErrorMessage,
    ],
    components: {
        PlatformFeeCopyForm,
        AppObjectHeader,
        AppCommissionRow,
        AppButtonSubmit,
        AppButton,
        AppButtonReset,
        AppSelect,
        AppAside,
    },
    props: {
        objectId: {
            type: Number,
            required: true,
            validator: (value) => {
                return Number.isInteger(value) && value > 0
            },
        },
        objectTypeId: {
            type: Number,
            required: true,
            validator: (value) => {
                return [
                    OBJECT_TYPE_COMPANY,
                    OBJECT_TYPE_PROPERTY,
                    OBJECT_TYPE_UNIT,
                ].indexOf(value) > -1
            },
        },
        distributionId: {
            type: Number,
            default: null,
            validator: (value) => {
                return value === null || (Number.isInteger(value) && value > 0)
            },
        },
        channelId: {
            type: Number,
            default: null,
            validator: (value) => {
                return value === null || (Number.isInteger(value) && value > 0)
            },
        },
        presetYear: {
            type: Number,
            default: null,
            validator: (value) => {
                return value === null || [...Array(4)].map((a, b) => {
                    return Number(new moment().format("YYYY")) - 1 + b
                }).indexOf(value) > -1
            },
        },
        presetCurrentYear: {
            type: Boolean,
            default: false,
        },
        channelManagerDistributionId: {
            type: Number,
            default: null,
            validator: (value) => {
                return value === null || (Number.isInteger(value) && value > 0)
            },
        },
        presetPlatformFeeType: {
            type: Number | null,
            default: COMMISSION_TYPE_PLATFORM_FEE,
            validator: (value) => {
                return value === null || [
                    COMMISSION_TYPE_PLATFORM_FEE,
                    COMMISSION_TYPE_PLATFORM_FEE_OUTBOUND,
                ].indexOf(value) > -1
            },
        },
    },
    data() {
        return {
            selectedYear: null,
            platformFees: null,
            loading: false,
            saving: false,
            platformFeeValueType: null,
            valueTypeOptions: [
                {value: PERC, text: "%"},
                {value: FIX, text: "fix"},
            ],
            platformFeeValueEntireYear: null,
            copyPlatformFeesSidebarState: false,
            copyingPlatformFees: false,
            distributionList: null,
            channelList: null,
            selectedPlatformFeeType: null,
            platformFeeTypes: [
                {id: COMMISSION_TYPE_PLATFORM_FEE, name: this.$t("BOOKING_FEE")},
                {id: COMMISSION_TYPE_PLATFORM_FEE_OUTBOUND, name: this.$t("BOOKING_FEE_MARKUP")},
            ],
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        computedYears() {
            return [...Array(4)].map((a, b) => {
                return {
                    id: Number(new moment().format("YYYY")) - 1 + b,
                    name: Number(new moment().format("YYYY")) - 1 + b
                }
            })
        },
        currentYear() {
            return Number(new moment().format("YYYY"))
        },
        startOfSelectedYear() {
            return !!this.selectedYear ? this.selectedYear.toString() + "-01-01" : null
        },
        endOfSelectedYear() {
            return !!this.selectedYear ? this.selectedYear.toString() + "-12-31" : null
        },
        showTable() {
            return this.platformFees && Object.keys(this.platformFees).length > 0 && !!this.selectedYear && !!this.selectedPlatformFeeType
        },
        canViewPlatformFees() {
            return this.checkPermission(C_SETUP_FEES_PLATFORM_FEE_V)
        },
        canEditPlatformFees() {
            return this.checkPermission(C_SETUP_FEES_PLATFORM_FEE_E)
        },
        canCopyPlatformFees() {
            return this.checkPermission(C_SETUP_FEES_PLATFORM_FEE_COPY)
        },
        selectedYearInPast() {
            return this.selectedYear < this.currentYear
        },
    },
    methods: {
        fetchPlatformFees() {
            if (!this.selectedPlatformFeeType || !this.selectedYear || this.loading) {
                return
            }
            this.platformFees = null
            this.loading = true
            let request = {
                commission_type: this.selectedPlatformFeeType,
                object: this.objectId,
                object_type: this.objectTypeId,
                period_start: this.startOfSelectedYear,
                period_end: this.endOfSelectedYear,
            }
            if (this.distributionId) {
                this.$set(request, "distribution", this.distributionId)
            }
            if (this.channelId) {
                this.$set(request, "channel", this.channelId)
            }
            getSystemFees(request).then((response) => {
                this.preparePlatformFees(response.data.setup)
            }, () => {
                this.platformFees = null
            }).finally(() => {
                this.loading = false
            })
        },
        save() {
            this.saving = true
            let request = {
                object: this.objectId,
                object_type: this.objectTypeId,
                commission_type: this.selectedPlatformFeeType,
            }
            if (this.distributionId) {
                this.$set(request, "distribution", this.distributionId)
            }
            if (this.channelId) {
                this.$set(request, "channel", this.channelId)
            }
            this.$set(request, "setup", this.preparePlatformFeesSetupSaveRequest())
            saveSystemFees(request).then((response) => {
                notifySuccess()
                this.preparePlatformFees(response.data.setup)
                this.$emit("saved")
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.saving = false
            })
        },
        preparePlatformFees(platformFeeSetupList) {
            const setValues = Array.isArray(platformFeeSetupList) && platformFeeSetupList.length > 0
            this.platformFees = {}
            let platformFeeValueTypeList = []
            for (let month = 1; month <= 12; month++) {
                let numberOfDaysInMonth = this.getNumberOfDaysInMonth(month)
                month = month > 9 ? month.toString() : "0" + month.toString()
                for (let day = 1; day <= numberOfDaysInMonth; day++) {
                    day = day > 9 ? day.toString() : "0" + day.toString()
                    let date = this.selectedYear.toString() + "-" + month + "-" + day
                    let values = setValues ? this.findPlatformFeeValuesForDate(platformFeeSetupList, date) : {
                        value: null,
                        value_type: null,
                    }
                    if (!!values.value_type && platformFeeValueTypeList.indexOf(values.value_type) < 0) {
                        platformFeeValueTypeList.push(values.value_type)
                    }
                    this.$set(this.platformFees, date, values)
                }
            }
            this.platformFeeValueType = platformFeeValueTypeList.length === 1 && !!platformFeeValueTypeList[0] ? platformFeeValueTypeList[0] : null
        },
        preparePlatformFeesSetupSaveRequest() {
            let setup = []
            let firstDateSameValues = null
            let currentValue = null
            let currentValueType = null
            for (const [date, values] of Object.entries(this.platformFees)) {
                const valueType = values.value !== null ? (!!values.value_type ? values.value_type : this.platformFeeValueType) : null
                if (!firstDateSameValues) {
                    currentValue = values.value
                    currentValueType = valueType
                    firstDateSameValues = date
                } else {
                    if (values.value === currentValue && valueType === currentValueType) {
                        if (date === this.selectedYear.toString() + "-12-31") {
                            setup.push({
                                period_start: firstDateSameValues,
                                period_end: date,
                                value: currentValue,
                                value_type: currentValueType,
                            })
                        }
                    } else {
                        setup.push({
                            period_start: firstDateSameValues,
                            period_end: moment(date, "YYYY-MM-DD").subtract(1, "days").format("YYYY-MM-DD"),
                            value: currentValue,
                            value_type: currentValueType,
                        })
                        if (date === this.selectedYear.toString() + "-12-31") {
                            setup.push({
                                period_start: date,
                                period_end: date,
                                value: values.value,
                                value_type: valueType,
                            })
                        }
                        currentValue = values.value
                        currentValueType = valueType
                        firstDateSameValues = date
                    }
                }
            }
            return setup
        },
        getNumberOfDaysInMonth(month) {
            return new Date(this.selectedYear, month, 0).getDate()
        },
        findPlatformFeeValuesForDate(platformFeeSetupList, date) {
            const setup = platformFeeSetupList.find(platformFeeSetup => platformFeeSetup.period_start <= date && platformFeeSetup.period_end >= date)
            return {
                value: !!setup ? setup.value : null,
                value_type: !!setup ? setup.value_type : null,
            }
        },
        updatePlatformFees(payload) {
            if (payload.values.value !== null) {
                this.$set(payload.values, "value_type", !!payload.values.value_type ? payload.values.value_type : this.platformFeeValueType)
            } else {
                this.$set(payload.values, "value_type", null)
            }
            this.$set(this.platformFees, payload.date, payload.values)
        },
        setPlatformFeeForEntireYear() {
            const values = {
                value: this.platformFeeValueEntireYear,
                value_type: this.platformFeeValueType,
            }
            Object.keys(this.platformFees).forEach((date) => {
                this.$set(this.platformFees, date, values)
            })
            this.platformFeeValueEntireYear = null
        },
        openCopySidebarForm() {
            this.getDistributionChannelList()
            this.copyPlatformFeesSidebarState = true
        },
        copyPlatformFees(payload) {
            this.copyingPlatformFees = true
            let request = {
                commission_type: this.selectedPlatformFeeType,
                source: {
                    year: payload.source_year,
                    object_type: this.objectTypeId,
                    object: this.objectId,
                },
                destination: {
                    object_type: this.objectTypeId,
                    objects: [this.objectId],
                    years: payload.destination_years,
                    distributions: payload.hasOwnProperty("destination_distributions") ? payload.destination_distributions : null,
                    channels: payload.hasOwnProperty("destination_channels") ? payload.destination_channels : null,
                },

            }
            if (!!this.distributionId) {
                this.$set(request.source, "distribution", this.distributionId)
            } else {
                this.$set(request.source, "channel", this.channelId)
            }
            copySystemFees(request).then(() => {
                notifySuccess()
                this.copyingPlatformFees = false
                this.fetchPlatformFees()
                this.copyPlatformFeesSidebarState = false
            }, (error) => {
                this.copyingPlatformFees = false
                this.showErrorMessages(error)
            })
        },
        getDistributionChannelList() {
            if (!!this.channelManagerDistributionId && DISTRIBUTION_MANAGERS.includes(this.channelManagerDistributionId)) {
                this.getChannelList()
                return
            }
            this.getDistributionList()
        },
        getDistributionList() {
            if (!!this.distributionList) {
                return
            }
            const request = {
                company: this.company.id,
                for_reservation_process: 1,
            }
            getDistributionList(request).then((response) => {
                this.distributionList = response.data
            }, () => {
                this.distributionList = null
            })
        },
        getChannelList() {
            if (!!this.channelList) {
                return
            }
            const request = {
                distribution_id: this.channelManagerDistributionId,
            }
            getDistributionChannelList(request).then((response) => {
                this.channelList = response.data
            }, () => {
                this.channelList = null
            })
        },
    },
    watch: {
        selectedYear: {
            handler(value) {
                this.platformFees = null
                this.platformFeeValueType = null
                if (!value) {
                    return
                }
                this.fetchPlatformFees()
            },
        },
        platformFeeValueType: {
            handler(value) {
                if (!this.platformFees) {
                    return
                }
                Object.keys(this.platformFees).forEach((date) => {
                    this.$set(this.platformFees[date], "value_type", value)
                })
            },
            immediate: true,
        },
        channelId: {
            handler(value) {
                this.platformFees = null
                this.platformFeeValueType = null
                if (!value) {
                    this.selectedYear = null
                    return
                }
                if (this.selectedYear) {
                    this.fetchPlatformFees()
                }
            },
        },
        selectedPlatformFeeType: {
            handler(value) {
                this.platformFees = null
                this.platformFeeValueType = null
                if (!value) {
                    return
                }
                this.fetchPlatformFees()
            },
        },
    },
    created() {
        this.selectedPlatformFeeType = !!this.presetPlatformFeeType ? this.presetPlatformFeeType : null
        this.selectedYear = !!this.selectedPlatformFeeType ? (!!this.presetYear ? this.presetYear : (this.presetCurrentYear ? this.currentYear : null)) : null
    },
}
</script>

<style scoped>

</style>
