<template>
    <b-form @submit.prevent="save">
        <template v-if="form_loading">
            <b-row>
                <b-col class="d-flex justify-content-center">
                    <b-spinner variant="primary"></b-spinner>
                </b-col>
            </b-row>
        </template>
        <template v-else>
            <b-row>
                <b-col cols="12" class="mb-4">
                    <label>{{$t("MONTH")}} / {{$t("YEAR")}}</label>
                    <app-select :disabled="mode_edit" v-model="year_month" :options="year_month_options"></app-select>
                    <app-validation-description :show="$v.$dirty && $v.year_month.$error">{{$t("FIELD_MANDATORY")}}
                    </app-validation-description>
                </b-col>
                <b-col cols="12" class="mb-4">
                    <label>{{$t("STATUS")}}</label>
                    <app-select v-model="request.status.id" :options="status_options"></app-select>
                    <app-validation-description :show="$v.$dirty && $v.request.status.id.$error">{{$t("FIELD_MANDATORY")}}
                    </app-validation-description>
                </b-col>

            </b-row>
            <b-row>
                <b-col md="3" class="mb-4">
                    <label>{{$t("NUM_OF_AUX_BEDS")}}</label>
                    <app-number-input v-model="request.beds_additional" :controls="true" :min="0"></app-number-input>
                </b-col>
                <b-col md="3" class="mb-4">
                    <label>{{$t("UNITS_SOLD")}}</label>
                    <app-number-input v-model="request.units_sold" :controls="true" :min="0"></app-number-input>
                </b-col>
                <b-col md="3" class="mb-4">
                    <label>{{$t("NUM_OPEN_DAYS")}}</label>
                    <app-number-input v-model="request.days_open_in_month" :controls="true" :min="0"></app-number-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mb-4">
                    <app-submit :loading="loading" variant="primary"></app-submit>
                </b-col>
            </b-row>
        </template>
    </b-form>

</template>

<script>
    import {cloneDeep} from 'lodash'
    import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppNumberInput from "@/components/app/form/AppNumberInput";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import moment from 'moment'
    import {required} from 'vuelidate/lib/validators'
    import {toast} from "@/shared/plugins/toastr";
    import AppValidationDescription from "@/components/app/form/AppValidationDescription";
    import {setMonthlyReport, getMonthlyReportOptions} from "@/services/guest";
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'

    export default {
        name: "GuestMonthlyReportForm",
        components: {AppValidationDescription, AppSelect, AppNumberInput, AppSubmit},
        mixins: [getErrorMessage],
        props: {
            property_id: {
                type: Number
            }
        },
        data() {
            return {
                request: {
                    "id": null,
                    "owner": {
                        "id": null,
                        "name": null
                    },
                    "id_no": null,
                    "year": null,
                    "month": null,
                    "status": {
                        "id": null,
                        "label": null
                    },
                    "beds_additional": 0,
                    "units_sold": 0,
                    "days_open_in_month": 0,
                    "ts_insert": null,
                    "ts_update": null
                },
                mode_edit: null,
                year_month: null,
                loading: false,
                form_loading: false,

            }
        },
        computed: {
            status_options() {
                return Object.keys(this.$t("AJPES_MONTHLY_REPORT_STATUS_LIST")).map(key => {
                    return {
                        id: key,
                        name: this.$t("AJPES_MONTHLY_REPORT_STATUS_LIST." + key)
                    }
                })
            },
            year_month_options() {
                let currentYear = moment().year();
                let currentMonth = moment().month();
                let values = []
                let years = [
                    new moment().subtract(1, 'Y').format('YYYY'),
                    new moment().format('YYYY')
                ]
                years.forEach(yearItem => {
                    for (let monthItem = 1; monthItem <= 12; monthItem++) {
                        if (currentYear === Number(yearItem) && monthItem > currentMonth) {
                            continue;
                        }
                        values.push({id: monthItem + '/' + yearItem, name: monthItem + '/' + yearItem})
                    }
                })
                return values.reverse()
            },

        },
        methods: {
            formatYearMonth(data) {
                let monthYearData = data.split("/")
                if (monthYearData.length === 2) {
                    this.request.month = Number(monthYearData[0])
                    this.request.year = Number(monthYearData[1])
                }
                this.getFormData(this.request.year, this.request.month)
            },
            getFormData(year, month) {
                this.form_loading = true
                getMonthlyReportOptions(this.property_id, {year: year, month: month}).then(response => {
                    this.request.units_sold = response.data.units_sold
                }, () => {
                    this.request.units_sold = 0
                }).finally(() => {
                    this.form_loading = false
                })
            },
            save() {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    toast({
                        'title': "",
                        'message': this.$t('VALIDATION_FAILED'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return false
                }
                this.loading = true
                setMonthlyReport(this.property_id, this.request).then(response => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 2000
                    })
                    this.$emit('save', response.data)
                    this.loading = false
                }, error => {
                    toast({
                        'title': "Action fail",
                        'message': this.getMessages(error),
                        'type': 'error',
                        'timeout': 2000
                    })
                    this.$emit('load')
                    this.loading = false
                })
            },
            setYearMonth() {
                if (this.request.year && this.request.month) {
                    this.year_month = this.request.month + '/' + this.request.year
                }
            }
        },
        watch: {
            year_month(value) {
                this.formatYearMonth(value)
            }
        },
        validations: {
            year_month: {
                required
            },
            request: {
                status: {
                    id: {
                        required
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
