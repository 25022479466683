<template>
    <b-card v-if="isVisible &&!loading">
        <b-row>
            <b-col sm="12" md="6" xl="8" class="mb-3 mb-md-0 d-flex flex-column justify-content-between">
                <p class="header-2 mb-3 mb-xl-5">{{ this.currentStatus !== null ? this.currentStatus.label : ''}}</p>
                <div class="d-flex  flex-wrap">
                    <app-button :disabled="!hasPermission" :key="statusIndex"
                                v-for="(statusTransition, statusIndex) in list"
                                button-class="mr-3 mt-2"
                                @click="changeStatus(statusTransition)" variant="primary"> {{ statusTransition.label}}
                    </app-button>

                    <app-status-change-confirmation-dialog :loader="submitLoader" v-model="showChangeStatusDialog" :current="currentStatus.label"
                                                       :next="selectedNextStatus" @updatedMessage="updateMessage"
                                                       @confirm="submit" show-message-box
                                                       @cancel="showChangeStatusDialog = false">
                    </app-status-change-confirmation-dialog>
                </div>
            </b-col>
            <b-col sm="12" md="6" xl="4"
                   class="d-flex align-items-start flex-column justify-content-end bl-md-show pl-md-3">
                <app-button @click="showLogs=true">
                    {{$t("STATUS_HISTORY")}}
                </app-button>
                <app-button class="mt-2" v-if="currentStatus && hasMessageViewPermission"
                            @click="showStatusLogs=true">{{$t("UPDATE_CHANGES")}}
                </app-button>

                <property-status-message v-model="showStatusLogs"
                                         :disabled="hasNoMessageEditPermission"
                                         v-if="currentStatus && hasMessageViewPermission"></property-status-message>
            </b-col>
        </b-row>

        <app-aside v-model="showLogs">

            <template slot="header">{{$t("STATUS_HISTORY")}}</template>
            <b-row>
                <b-col>
                    <b-table responsive :items="logs" hover :fields="logFields">
                        <template v-slot:comment="data"><span class="status-comment" v-html="sanitizeText(data.item.comment)"></span></template>
                        <template v-slot:timestamp="data">{{data.item.timestamp | timestamp}}</template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <app-button :loading="logButtonLoading" @click="loadMoreLogs"
                                v-if="this.last_page !== null && this.page < this.last_page">{{$t("LOAD_MORE")}}
                    </app-button>
                </b-col>
            </b-row>


        </app-aside>
    </b-card>

</template>

<script>
    import {getStatuses, setStatus, validateStatus} from '@/services/property/index'
    import {saveUnitPriceFromPrp} from '@/services/pricing/index'
    import {getUnitList} from "@/services/unit";
    import AppButton from '@/components/app/AppButton/AppButton'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import {toast} from '@/shared/plugins/toastr'
    import NoDataView from '@/components/app/AppNoData'
    import {
        C_PROPERTY_STATUS_E,
        C_PROPERTY_STATUS_MESSAGE_V,
        C_PROPERTY_STATUS_MESSAGE_E
    } from "@/shared/component_permission";
    import AppValidationDescription from '@/components/app/form/AppValidationDescription'

    import AppStatusChangeConfirmationDialog from '@/components/app/AppStatusChangeConfirmationDialog'
    import {
        STATUS_PROPERTY_UPDATE,
        STATUS_PROPERTY_UPDATE_COMPLETE,
    } from "@/shared/constants";
    import {getStatusLogs} from "@/services/status";
    import AppAside from '@/components/app/form/AppAside'
    import PropertyStatusMessage from '@/components/property/status/PropertyStatusMessage'
    import FormHeader from "@/components/app/form/FormHeader";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {textFormatters} from "@/mixins/shared/helpers";

    export default {
        name: "PropertyStatus",
        components: {
            AppButton,
            NoDataView,
            AppValidationDescription,
            AppStatusChangeConfirmationDialog,
            AppAside,
            PropertyStatusMessage,
            FormHeader

        },
        mixins: [getErrorMessage,AccessControlComponent, textFormatters],
        props:{
            access_control_context: {
                type: Object,
                default: null
            },
        },
        data() {
            return {
                submitLoader:false,
                c_permission: C_PROPERTY_STATUS_E,
                list: [],
                error_list: [],
                message: null,
                loading: false,
                showChangeStatusDialog: false,
                selectedNextStatus: null,
                selectedStatus: null,
                logs: [],
                page: 1,
                last_page: null,
                limit: 5,
                showLogs: false,
                showStatusLogs: false,
                logButtonLoading: false,
                logFields: [
                    {key: 'status.label', label: this.$t('STATUS')},
                    {key: 'user.name', label: this.$t('USER')},
                    {key: 'comment', label: this.$t('MESSAGE'), class: 'w-50'},
                    {key: 'timestamp', label: this.$t('TIMESTAMP'), class: 'text-right'},
                ],
                prpMessage: {Message: null, ImportantChange: 0}
            }
        },

        methods: {

            submit() {
                this.submitLoader = true

                setStatus(this.$route.params.id, this.selectedStatus, {message: this.message})
                    .then(response => {
                        this.list = response.data
                        this.selectedStatus = null
                        this.message = null
                        this.$store.dispatch('property/actionSetProperty', this.$route.params.id)
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 5000
                        })
                    }, error => {
                        toast({message: this.getMessages(error), type: "error", time_out_close:false})

                        this.error_list = error.response.data.error_list
                    }).finally(()=>{
                    this.showChangeStatusDialog = false
                    this.submitLoader = false
                })
            },
            changeStatus(status) {
                this.selectedStatus = status.id
                this.selectedNextStatus = status.label
                this.showChangeStatusDialog = true
            },
            updateMessage(value) {
                this.message = value
            },
            getLogs() {
                this.logButtonLoading = true
                getStatusLogs(this.$route.params.id, {page: this.page, limit: this.limit}).then(response => {
                    response.data.items.forEach(el => {
                        this.logs.push(el)
                        this.page = response.data.page
                        this.last_page = response.data.last_page
                        this.logButtonLoading = false
                    }, () => {
                        this.logButtonLoading = false
                    })
                })
            },
            loadMoreLogs() {
                this.page = this.page + 1
                this.getLogs()
            },

        },
        computed: {
            isVisible() {
                return this.AccessControlVisible
            },
            currentStatus() {
                return this.$store.getters.getItem('property.basic.status')
            },
            hasPermission() {
                return this.$store.getters['user/getPermission'](this.c_permission)
            },
            hasMessageViewPermission() {
                return (this.currentStatus.id === STATUS_PROPERTY_UPDATE || this.currentStatus.id === STATUS_PROPERTY_UPDATE_COMPLETE)
                    && this.$store.getters['user/getPermission'](C_PROPERTY_STATUS_MESSAGE_V)
            },
            hasNoMessageEditPermission() {
                return this.currentStatus.id !== STATUS_PROPERTY_UPDATE || !this.$store.getters['user/getPermission'](C_PROPERTY_STATUS_MESSAGE_E)
            }
        },
        watch: {
            showLogs: {
                handler(val) {
                    if (val && this.logs.length === 0) {
                        this.getLogs()
                    }
                },
                immediate: true
            },
            '$route.params.id': {
                handler(id) {
                    getStatuses(id).then(response => {
                        this.list = response.data
                    }, () => {
                        this.list = []
                    }).then(() => {
                        this.loading = false
                    })
                },
                immediate: true
            },
            '$route.query':{
                handler(query){
                    if(query.hasOwnProperty('sidebar')){
                        this.showStatusLogs = query.sidebar === 'notes'
                        this.showLogs = query.sidebar === 'logs'
                    }
                }, immediate:true
            }
        },

    }
</script>

<style scoped>
    .status-comment{
        white-space: pre-line;
        line-height: 1.8rem;
    }
</style>
