<template>
    <div>
        <div v-if="show_type"
             class="main_calendar_test--cell main_calendar_test--background_gray d-flex flex-column justify-content-around animated fadeIn"
             style="height: 2.87rem">

            <div class="main_calendar_test__holder--sidebar-h1 position-relative" :id="'tooltip-target-'+ratePlan.id">
                <template v-if="!displayPlatformFee">
                    <b-img class="distribution-image-icon" fluid :src="distributionPhotoUrl(ratePlan)"></b-img>
                    {{ ratePlan.name }}
                    <template
                        v-if="show_type && ratePlan.hasOwnProperty('linked_type') && ratePlan.hasOwnProperty('linked_value') && ratePlan.linked_type && Number(ratePlan.linked_value) !== 0">
                        ({{ ratePlan.linked_value > 0 ? '+' : '' }}{{ ratePlan.linked_value }}
                        {{ formatLinkedType(ratePlan.linked_type) }})
                    </template>
                    <app-button class="action_button ml-2" variant="link" button_type="edit"
                                @click="$emit('editRP', ratePlan.id)" :show_text="false"></app-button>
                    <app-button class="action_button ml-1" variant="link" button_type="minus"
                                @click="$emit('remove', ratePlan.id)" :show_text="false"></app-button>
                </template>
                <template v-else>
                    <table>
                        <tbody>
                        <tr>
                            <td rowspan="2" style="text-align: left">
                                <b-img class="distribution-image-icon" fluid
                                       :src="distributionPhotoUrl(ratePlan)"></b-img>
                            </td>
                            <td class="rate-plan-name-platform-fee-cell">
                                {{ $t("BOOKING_FEE") }}
                            </td>
                            <td v-if="(show_type && ratePlan.hasOwnProperty('linked_type') && ratePlan.hasOwnProperty('linked_value') && ratePlan.linked_type && Number(ratePlan.linked_value) !== 0) || displayPlatformFee"
                                class="linked-value-platform-fee-values-cell">
                                <template v-if="displayPlatformFee && !!platformFeeFormattedValueType">
                                    ({{ platformFeeLabel > 0 ? "+" : "" }}{{ platformFeeLabel }}
                                    {{ platformFeeFormattedValueType }})
                                </template>
                                <template v-else-if="displayPlatformFee">
                                    ({{ platformFeeLabel }})
                                </template>
                            </td>
                            <td rowspan="2">
                                <app-button class="action_button ml-2" variant="link" button_type="edit"
                                            @click="$emit('editRP', ratePlan.id)" :show_text="false"></app-button>
                                <app-button class="action_button ml-1" variant="link" button_type="minus"
                                            @click="$emit('remove', ratePlan.id)" :show_text="false"></app-button>
                            </td>
                        </tr>
                        <tr>
                            <td class="rate-plan-name-platform-fee-cell">
                                {{ ratePlan.name }}
                            </td>
                            <td v-if="(show_type && ratePlan.hasOwnProperty('linked_type') && ratePlan.hasOwnProperty('linked_value') && ratePlan.linked_type && Number(ratePlan.linked_value) !== 0) || displayPlatformFee"
                                class="linked-value-platform-fee-values-cell">
                                <template
                                    v-if="show_type && ratePlan.hasOwnProperty('linked_type') && ratePlan.hasOwnProperty('linked_value') && ratePlan.linked_type && Number(ratePlan.linked_value) !== 0">
                                    ({{ ratePlan.linked_value > 0 ? '+' : '' }}{{ ratePlan.linked_value }}
                                    {{ formatLinkedType(ratePlan.linked_type) }})
                                </template>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </template>
            </div>

            <div v-if="los.length > 1 || occupancy.length > 1" class="d-flex flex-nowrap justify-content-around">
                <div v-if="los.length > 1" class="main_calendar_test__holder--sidebar-h3 text-muted">
                    {{ $t("LENGTH_OF_STAY") }}
                </div>
                <div v-if="occupancy.length > 1" class="main_calendar_test__holder--sidebar-h3 text-muted">
                    {{ $t("OCCUPANY_PERSONS") }}
                </div>

            </div>
        </div>
        <div v-else class="main_calendar_test main_calendar_test-bb rate-header d-flex  justify-content-start pl-4">
            <div :id="'tooltip-target-'+ratePlan.id">
                <b-img class="distribution-image-icon" fluid :src="distributionPhotoUrl(ratePlan)"></b-img>
                {{ ratePlan.name }}
                <template
                    v-if="show_type && ratePlan.hasOwnProperty('linked_type') && ratePlan.hasOwnProperty('linked_value') && ratePlan.linked_type && Number(ratePlan.linked_value) !== 0">
                    ({{ ratePlan.linked_value > 0 ? '+' : '' }}{{ ratePlan.linked_value }}
                    {{ formatLinkedType(ratePlan.linked_type) }})
                </template>
            </div>

            <app-button class="action_button ml-2" variant="link" button_type="edit"
                        @click="$emit('editRP', ratePlan.id)" :show_text="false"></app-button>
            <app-button class="action_button ml-1" variant="link" button_type="minus"
                        @click="$emit('remove', ratePlan.id)" :show_text="false"></app-button>


        </div>
    </div>

</template>

<script>
import {has} from "lodash";
import {DISTRIBUTION_PHOTO} from "@/services/endpoints";
import AppButton from "@/components/app/AppButton/AppButton";
import {FIX, PERC} from "@/shared/constants";

export default {
    name: "TableRatePlanHeader",
    components: {AppButton},
    props: {
        ratePlan: {
            type: Object
        },
        show_type: {
            type: Boolean,
            default: true
        },
        los: {
            type: Array,
            default() {
                return []
            }
        },
        occupancy: {
            type: Array,
            default() {
                return []
            }
        },
        showPlatformFee: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        formatLinkedType(linked_type) {
            return linked_type === 'perc' ? '%' : 'fix'
        },
        distributionPhotoUrl(ratePlan) {
            if (has(ratePlan, 'distribution')) {
                return DISTRIBUTION_PHOTO(ratePlan.distribution)
            }
            return DISTRIBUTION_PHOTO(0)
        },
    },
    computed: {
        platformFeeValuesByType() {
            let platformFeeValuesByType = {}
            let platformFeesWithoutType = false
            if (this.ratePlan.hasOwnProperty("platform_fee_setup") && Array.isArray(this.ratePlan.platform_fee_setup)) {
                this.ratePlan.platform_fee_setup.forEach((platformFeeSetup) => {
                    if (!!platformFeeSetup.value_type_id) {
                        let value = platformFeeSetup.value
                        value = value === null ? "0.00" : value
                        if (!platformFeeValuesByType.hasOwnProperty(platformFeeSetup.value_type_id)) {
                            this.$set(platformFeeValuesByType, platformFeeSetup.value_type_id, {
                                "min": value,
                                "max": value,
                            })
                        } else {
                            if (Number(value) < Number(platformFeeValuesByType[platformFeeSetup.value_type_id]["min"])) {
                                this.$set(platformFeeValuesByType[platformFeeSetup.value_type_id], "min", value)
                            }
                            if (Number(value) > Number(platformFeeValuesByType[platformFeeSetup.value_type_id]["max"])) {
                                this.$set(platformFeeValuesByType[platformFeeSetup.value_type_id], "max", value)
                            }
                        }
                    } else {
                        platformFeesWithoutType = true
                    }
                })
                if (platformFeesWithoutType && Object.keys(platformFeeValuesByType).length > 0) {
                    Object.keys(platformFeeValuesByType).forEach((key) => {
                        this.$set(platformFeeValuesByType[key], "min", "0.00")
                    })
                }
            }
            return platformFeeValuesByType
        },
        platformFeeLabel() {
            const keys = Object.keys(this.platformFeeValuesByType)
            if (keys.length === 2) {
                return this.$t("MIXED_VALUE_TYPES")
            } else if (keys.length === 1) {
                const label = this.platformFeeValuesByType[keys[0]].min === this.platformFeeValuesByType[keys[0]].max
                    ? this.platformFeeValuesByType[keys[0]].min : this.platformFeeValuesByType[keys[0]].min.toString() + " - " + this.platformFeeValuesByType[keys[0]].max.toString()
                return [0.00, 0].indexOf(Number(label)) < 0 ? label : null
            }
            return null
        },
        displayPlatformFee() {
            return this.showPlatformFee && !!this.platformFeeLabel
        },
        platformFeeFormattedValueType() {
            const keys = Object.keys(this.platformFeeValuesByType)
            return keys.length === 1 ? this.formatLinkedType(Number(keys[0]) === PERC ? "perc" : "fix") : null;
        },
    },
}
</script>

<style scoped>
.rate-header {


    height: 2.87rem;
    background: rgb(245, 249, 250);
    border-bottom: 1px solid #EAEAEA;
    padding-top: 0.69rem;
    font-size: .75rem;
    font-weight: 700;
    color: #52728b;
    max-width: 24rem;
    overflow: hidden;
    text-overflow: ellipsis;

}

.distribution-image-icon {
    width: .9rem;
    height: .9rem;
    margin-bottom: .3rem;
    margin-top: .3rem;
}

.rate-plan-name-platform-fee-cell {
    max-width: 9rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.linked-value-platform-fee-values-cell {
    max-width: 7rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
