<template>
    <div v-if="!typeData.length && !loadingData">
        <slot name="noData">
        </slot>
    </div>
    <b-form v-else @submit.prevent="save">
        <b-card-group columns class="column-4">
            <b-card :key="typeIndex" v-for="(type, typeIndex) in typeData">
                <b-list-group class="repeater-2">
                    <b-list-group-item class="list-group-item-title font-weight-bold">
                        <form-header>{{ type.label }}
                            <app-button
                                v-if="checkPermission(C_WEBSITE_TYPE_E)"
                                variant="link"
                                button_type="edit"
                                :show_text="false"
                                class="action_button"
                                @click="open(type)">
                            </app-button>
                            <b-form-checkbox :unchecked-value="0"
                                             :value="1"
                                             inline
                                             v-model="type.value"
                                             class="pull-right m-0 p-0"
                                             @change="selectAll(typeIndex, $event)">
                            </b-form-checkbox>
                        </form-header>
                    </b-list-group-item>
                    <template v-if="type.mapped.length">
                        <b-list-group-item :key="mappedIndex"
                                           v-for="(mappedItem, mappedIndex) in type.mapped">
                            <b-form-checkbox :unchecked-value="0"
                                             v-model="mappedItem.value"
                                             @change="addChangeLogByIndex(mappedIndex, typeIndex, $event)"
                                             :value="1"
                                             inline>
                                {{mappedItem.label}}
                            </b-form-checkbox>
                        </b-list-group-item>
                    </template>
                </b-list-group>
            </b-card>
        </b-card-group>
        <app-button :disabled="saveDisabled" @click="save" :loading="loading">{{$t('SAVE')}}</app-button>
        <app-aside v-model="openAside">
            <template slot="header">
                <app-object-header
                    :label="$t('CATEGORY')"
                    :name="label"
                    :description="$t('NAME')">
                </app-object-header>
            </template>
            <website-unit-type :unit-type="unitType"></website-unit-type>
        </app-aside>
        <app-aside v-model="mapTypeAside" :widths="['col-lg-4','col-sm-12', '40%']">
            <template slot="header">
                <app-object-header
                    :label="openTypeData.label"
                    :name="$t('NAME')"
                    :description="$t('UNIT_TYPE')">
                </app-object-header>
            </template>
                <website-edit-unit-type
                    :unit-type="openTypeData"
                    :website-id="websiteId"
                    @removeMapping="reMapData"
                    @mappingSaved="savedMapping"
                    @translationSaved="savedTranslation">
                </website-edit-unit-type>
        </app-aside>
    </b-form>
</template>

<script>
    import WebsiteAmenityCategory from "@/components/direct_booking/website/amenities/WebsiteAmenityCategory";
    import AppObjectHeader from "@/components/app/AppObjectHeader";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppTableV2 from "@/components/app/AppTableV2";
    import AppAside from "@/components/app/form/AppAside";
    import {getUnitType, saveUnitTypeMapping} from "@/services/direct_booking/website";
    import WebsiteUnitType from "@/components/direct_booking/website/unit_type/WebsiteUnitType";
    import WebsiteEditUnitType from "@/components/direct_booking/website/unit_type/WebsiteEditUnitType";
    import FormHeader from "@/components/app/form/FormHeader";
    import {EventBus, GE_LOADER_HIDE, GE_LOADER_SHOW} from "@/shared/EventBus";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {
        C_WEBSITE_TYPE_E, C_WEBSITE_TYPE_NEW_V
    } from "@/shared/component_permission";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";

    export default {
        name: "WebsiteUnitTypeTable",
        mixins: [getErrorMessage],
        components: {WebsiteUnitType, WebsiteAmenityCategory, AppObjectHeader, AppButton, AppTableV2, AppAside, FormHeader, WebsiteEditUnitType},
        props: {
            websiteId: {
                type: Number,
                default: null,
            },
            reloadOnCreate: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loading:false,
                C_WEBSITE_TYPE_E,
                C_WEBSITE_TYPE_NEW_V,
                openAside: false,
                label: "",
                unitType: {},
                typeData: [],
                openTypeData: [],
                editList: [],
                saveList: [],
                mapTypeAside: false,
                checked: 1,
                saveDisabled: true,
                loadingData: false
            }
        },
        methods: {
            save(){
                this.loading = true
                saveUnitTypeMapping(this.websiteId, {list: this.saveList}).
                then(() => {
                    notifySuccess()
                    this.getData()
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => this.loading = false)
            },
            open(value) {
                this.openTypeData = value
                this.mapTypeAside = true
            },
            getData(){
                this.loadingData = true
                EventBus.$emit(GE_LOADER_SHOW)
                getUnitType(this.websiteId).then(response => {
                    this.typeData = response.data
                    this.$emit('onGetData', this.typeData.length)
                }).finally( () => {
                    EventBus.$emit(GE_LOADER_HIDE)
                    this.loadingData = false
                })
            },
            addChangeLogByIndex(i, typeIndex, event) {

                let deleted = event === 1 ? 0 : 1
                let typeId = this.typeData[typeIndex]['mapped'][i]['id']

                this.$nextTick(() => {
                    let list = this.typeData[typeIndex].mapped
                    if (list.some(e => e.value === 0)) {
                        this.typeData[typeIndex].value = 0
                    } else {
                        this.typeData[typeIndex].value = 1
                    }
                })

                // find index if there is already the same element in array and remove it
                let index = this.editList.findIndex(element => element.type_id === typeId)
                if (index > -1) {
                    this.editList.splice(index, 1)
                }

                // adding to update
                typeId.deleted = deleted
                typeId.eid = typeIndex
                typeId.id = this.typeData[typeIndex]['mapped'][i]['id']['unit_type_id']
                let type = {}
                type.deleted = deleted
                type.eid = this.typeData[typeIndex]['id']
                type.id = this.typeData[typeIndex]['mapped'][i]['id']['unit_type_id']
                this.editList.push(typeId)
                this.saveList.push(type)
            },

            selectAll(typeIndex, event) {
                let forLength = this.typeData[typeIndex]['mapped'].length
                for (let i = 0; i < forLength; i++) {
                    this.addChangeLogByIndex(i, typeIndex, event)
                    this.typeData[typeIndex]['mapped'][i]['value'] = event
                }
            },
            reMapData(value){
                value.forEach(el => {
                    el.check_delete = 1
                })
                saveUnitTypeMapping(this.websiteId, {list: value}).then(response => {
                    this.getData()
                })
            },
            savedMapping(){
                this.getData()
                this.mapTypeAside = false
            },
            savedTranslation(){
                this.getData()
                this.mapTypeAside = false
            }
        },
        watch:{
            reloadOnCreate:{
                handler(){
                        this.getData()

                }
            },
            saveList:{
                handler(){
                    if (this.saveList.length > 0){
                        this.saveDisabled = false
                    }
                }
            }
        },
        created() {
            this.getData()
        }
    }
</script>

<style scoped>

</style>
