// COMPONENTS
export const C_USER_BASIC_V = 1307
export const C_USER_BASIC_E = 1308
export const C_USER_BASIC_DELETE = 1967
export const C_USER_PASSWORD_V = 1310
export const C_USER_PASSWORD_E = 1311
export const C_USER_PASSWORD_RESET = 1694
export const C_USER_ROLES = 1639
export const C_USER_ROLES_V = 1640
export const C_USER_ROLES_E = 1641
export const C_USER_NOTIFICATIONS = 1989
export const C_USER_NOTIFICATIONS_V = 1987
export const C_USER_NOTIFICATIONS_E = 1986
export const C_USER_CASH_REGISTER_SETUP_SAVE = 2005
export const C_USER_CASH_REGISTER_SETUP_ADDITIONAL_PROPERTY_SAVE = 2006
export const C_PROPERTY_LIST_FILTER_V = 1317
export const C_PROPERTY_NEW_V = 1319
export const C_PROPERTY_BASIC_V = 1321
export const C_PROPERTY_BASIC_E = 1322
export const C_PROPERTY_DELETE = 2082;

export const C_PROPERTY_PDF_V = 1324
export const C_PROPERTY_PDF_E = 1325
export const C_PROPERTY_PDF_SEND = 1965
export const C_PROPERTY_PDF_NEW_VERSION = 2106

export const C_PROPERTY_STATUS_V = 1338
export const C_PROPERTY_STATUS_E = 1339
export const C_PROPERTY_STATUS_MESSAGE_V = 1524
export const C_PROPERTY_STATUS_MESSAGE_E = 1525


export const C_PROPERTY_CANCELATION_POLICY_V = 1342
export const C_PROPERTY_CANCELATION_POLICY_E = 1343
export const C_PROPERTY_CANCELLATION_POLICY_NEW = 2403
export const C_PROPERTY_CANCELLATION_POLICY_APPLY_SYSTEM_POLICY = 2404
export const C_PROPERTY_CANCELATION_POLICY_LINK_SYSTEM_POLICY = 2119
export const C_PROPERTY_CANCELATION_POLICY_UNLINK_SYSTEM_POLICY = 2120
export const C_PROPERTY_CANCELLATION_POLICY_ENABLE_CUSTOM_TEXT = 2276
export const C_PROPERTY_GALLERY_V = 1344
export const C_PROPERTY_GALLERY_E = 1345
export const C_PROPERTY_GALLERY_ACTIVE = 2143;
export const C_PROPERTY_GALLERY_DESCRIPTION = 2112;
export const C_UNIT_GALLERY_IMAGE_DESCRIPTION = 2113;
export const C_PROPERTY_GALLERY_TAG_V = 1564
export const C_PROPERTY_POI_V = 1346
export const C_PROPERTY_POI_E = 1347
export const C_PROPERTY_AMENITY_V = 1348
export const C_PROPERTY_AMENITY_E = 1349

export const C_PROPERTY_DESCRIPTIONS_V = 1350
export const C_PROPERTY_DESCRIPTIONS_E = 1351
export const C_PROPERTY_DESCRIPTIONS_PROPERTY_E = 1957
export const C_PROPERTY_DESCRIPTIONS_AREA_E = 1958
export const C_PROPERTY_DESCRIPTIONS_ACTIVITIES_E = 1959
export const C_PROPERTY_DESCRIPTIONS_SUMMARY_E = 1960
export const C_PROPERTY_DESCRIPTIONS_ACCESS_E = 1961
export const C_PROPERTY_DESCRIPTIONS_GETTING_AROUND_E = 1962
export const C_PROPERTY_DESCRIPTIONS_NOTES_V = 1963
export const C_PROPERTY_DESCRIPTIONS_NOTES_E = 1964

export const C_PROPERTY_CRM_V = 1352
export const C_PROPERTY_CRM_E = 1353
export const C_PROPERTY_CRM_CUSTOMER_GROUP_V = 2402;
export const C_PROPERTY_DETAILS_V = 1354
export const C_PROPERTY_DETAILS_E = 1355
export const C_PROPERTY_DETAILS_PAYMENT_METHOD = 1939
export const C_PROPERTY_BOOKING_SITES_V = 1356
export const C_PROPERTY_BOOKING_SITES_E = 1357
export const C_PROPERTY_DECLINED_CC_V = 1358
export const C_PROPERTY_DECLINED_CC_E = 1359
export const C_PROPERTY_ACTION_PROMOTION_V = 1362
export const C_PROPERTY_ACTION_PROMOTION_E = 1363
export const C_PROPERTY_ACTION_PROMOTION_LAST_MINUTE_NEW = 1925
export const C_PROPERTY_ACTION_PROMOTION_EARLY_BOOKER_NEW = 1926
export const C_PROPERTY_ACTION_ANALYTIC_V = 1364
export const C_PROPERTY_ACTION_ANALYTIC_E = 1365

export const C_PROPERTY_CALENDAR_AVAILIBILITY_V = 1469
export const C_PROPERTY_CALENDAR_AVAILIBILITY_E = 1470
export const C_PROPERTY_CALENDAR_PRICES_V = 1473
export const C_PROPERTY_CALENDAR_PRICES_E = 1474
export const C_PROPERTY_CALENDAR_RESTRICTIONS_V = 1475
export const C_PROPERTY_CALENDAR_RESTRICTIONS_E = 1476
export const C_PROPERTY_CALENDAR_RM_SUGGESTION_V = 1519
export const C_PROPERTY_CALENDAR_RM_SUGGESTION_E = 1520
export const C_PROPERTY_CALENDAR_RM_SUGGESTION_AUTOSELECTED = 1977
export const C_PROPERTY_CALENDAR_ROOM_STATUS_V = 1530
export const C_PROPERTY_CALENDAR_ROOM_STATUS_E = 1531
export const C_PROPERTY_CALENDAR_SYNC_E = 1548
export const C_PROPERTY_CALENDAR_SYNC_V = 1845
export const C_PROPERTY_CALENDAR_PRICES_RATE_PLANS_V = 1995
export const C_PROPERTY_CALENDAR_PRICES_CALCULATE = 2031
export const C_PROPERTY_CALENDAR_RESTRICTIONS_RATE_PLANS_V = 1996
export const C_PROPERTY_CALENDAR_BULK_V = 2035
export const C_PROPERTY_CALENDAR_BULK_SALES_PERIODS_E = 2033
export const C_PROPERTY_CALENDAR_BULK_RATES_E = 2034
export const C_PROPERTY_CALENDAR_BULK_AVAILABILITY_E = 2133
export const C_PROPERTY_CALENDAR_BULK_RESTRICTIONS_E = 2534

export const C_PROPERTY_CALENDAR_PRICES_IMPORT_EXPORT_V = 2409
export const C_PROPERTY_CALENDAR_PRICES_IMPORT_EXPORT_IMPORT_PRICES = 2410


//main calendar
export const MAIN_CALENDAR_V = 2074;
export const MAIN_CALENDAR_FILTER_CITY = 2139;
export const MAIN_CALENDAR_AVAILABILITY_QUOTE = 2138;
export const MAIN_CALENDAR_FILTER_PREDEFINED_CITY = 2142;
export const MAIN_CALENDAR_FILTER_GROUP = 2215;
export const MAIN_CALENDAR_LOG_V = 2216;
export const C_PROPERTY_CONTACTS_V = 1652
export const C_PROPERTY_CONTACTS_E = 1653

export const C_PROPERTY_CM_PM_PROPERTY_V = 1486
export const C_PROPERTY_CM_PM_PROPERTY_E = 1487
export const C_PROPERTY_CM_PM_PROPERTY_CONNECT_V = 1489
export const C_PROPERTY_CM_PM_PROPERTY_CONNECT_TEST_V = 2052
export const C_PROPERTY_CM_PM_PROPERTY_CONNECT_E = 1490
export const C_PROPERTY_CM_PM_PROPERTY_PUBLISH_V = 1492
export const C_PROPERTY_CM_PM_PROPERTY_PUBLISH_E = 1493
export const C_PROPERTY_CM_PM_PROPERTY_SYNC_V = 1510
export const C_PROPERTY_CM_PM_PROPERTY_SYNC_E = 1511
export const C_PROPERTY_CM_PM_PROPERTY_STATUS_V = 1513
export const C_PROPERTY_CM_PM_PROPERTY_STATUS_E = 1514
export const C_PROPERTY_CM_PM_UNIT_PUBLISH_V = 1495
export const C_PROPERTY_CM_PM_UNIT_PUBLISH_E = 1496
export const C_PROPERTY_CM_PM_UNIT_SYNC_V = 1498
export const C_PROPERTY_CM_PM_UNIT_SYNC_E = 1499
export const C_PROPERTY_CM_PM_UNIT_MAPPING_V = 1501
export const C_PROPERTY_CM_PM_UNIT_MAPPING_E = 1502
export const C_PROPERTY_CM_PM_QUEUE_V = 1527
export const C_PROPERTY_CM_PM_QUEUE_E = 1528
export const C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_V = 1657
export const C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_TEST_V = 1858
export const C_PROPERTY_CM_PM_PROPERTY_DEACTIVATE_V = 1659
export const C_PROPERTY_CM_PM_PROPERTY_HEADLINE_V = 1671
export const C_PROPERTY_CM_PM_PROPERTY_HEADLINE_NEW = 2361
export const C_PROPERTY_CM_PM_PROPERTY_HEADLINE_ASSIGN = 2362
export const C_PROPERTY_CM_PM_PROPERTY_HEADLINE_TRANSLATE = 2363
export const C_PROPERTY_CM_PM_PROPERTY_MAPPING_V = 1674
export const C_PROPERTY_CM_SYNC_LOG_V = 1682;
export const C_PROPERTY_CM_PM_UNIT_ACTIVATE_TEST_V = 1909;
export const C_PROPERTY_CM_PM_UNIT_LIST = 1506;
export const C_PROPERTY_CM_PM_UNIT_ACTIVATE_WITH_SIDEBAR = 2313;
export const C_PROPERTY_CM_PM_UNIT_ACTIVATE_WITH_WIZARD = 2312;
export const C_PROPERTY_CM_PM_IMPORT_ICAL_E = 2317
export const C_PROPERTY_CM_PM_PROPERTY_GALLERY_DOWNLOAD = 2472
export const C_PROPERTY_CM_PM_UNIT_STATUS = 2499

export const C_PROPERTY_CM_PM_UNIT_ACTIVATE_V = 1662
export const C_PROPERTY_CM_PM_UNIT_DEACTIVATE_V = 1665
export const C_PROPERTY_CM_PM_UNIT_HEADLINE_V = 1668
export const C_PROPERTY_CM_PM_UNIT_NEW_HEADLINE_V = 2323
export const C_PROPERTY_CM_PM_UNIT_ASSIGN_HEADLINE_V = 2324
export const C_PROPERTY_CM_PM_UNIT_HEADLINE_TRANSLATE = 2360

export const C_PROPERTY_CM_PM_UNIT_GALLERY_DOWNLOAD = 2473
export const C_PROPERTY_CM_PM_UNIT_SETUP = 2537


export const C_PROPERTY_BE_V = 1604
export const C_PROPERTY_BE_E = 1605


export const C_PROPERTY_BASIC_REGION_V = 1733;
export const C_PROPERTY_BASIC_MICROLOCATION_V = 1736;
export const C_PROPERTY_NAME = 2097;
export const C_PROPERTY_TYPE = 2098;
export const C_PROPERTY_UNIT_NUMBER = 2099;
export const C_PROPERTY_CATEGORIZATION = 2100;
export const C_PROPERTY_LOCATION = 2101;
export const C_PROPERTY_GEOLOCATION = 2102;

export const C_PROPERTY_EVENT_TEMPLATE_V = 1685
export const C_PROPERTY_EVENT_TEMPLATE_E = 1686
export const C_PROPERTY_EVENT_TEMPLATE_NEW = 1692
export const C_PROPERTY_EVENT_TEMPLATE_APPLY = 1816
export const C_PROPERTY_EVENT_TEMPLATE_ACTIVATE = 1817
export const C_PROPERTY_EVENT_TEMPLATE_DELETE = 1979
export const C_PROPERTY_EVENT_TEMPLATE_LINK_SYSTEM_TEMPLATE = 2135
export const C_PROPERTY_EVENT_TEMPLATE_UNLINK_SYSTEM_TEMPLATE = 2136


export const C_PROPERTY_USER_ACCESS_V = 1767
export const C_PROPERTY_USER_ACCESS_E = 1768
export const C_PROPERTY_USER_ACCESS_OWNER_NEW = 1769
export const C_PROPERTY_USER_ACCESS_PROPERTY_MANAGER_NEW = 1770
export const C_PROPERTY_USER_ACCESS_USER_NEW = 1771

export const C_PROPERTY_ICAL_V = 1786
export const C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_PRICING_TYPE_V = 1905
export const C_PROPERTY_CM_PM_PROPERTY_ACTIVATE_PAYMENT_TYPE_V = 1924;
export const F_CM_CONNECT_EXISTING_PROPERTY = 2054;
export const F_CM_PULL_PROPERTY_RESERVATIONS = 2075;
export const F_CM_PULL_UNIT_RESERVATIONS = 2076;
// property filter
export const C_PROPERTY_LIST_FILTER_STATUS_V = 1714;
export const C_PROPERTY_LIST_FILTER_ACM_V = 1715;
export const C_PROPERTY_LIST_FILTER_STATUS_DATE = 2530

//property navigation
export const C_PROPERTY_NAVIGATION_OWNERS_V = 1718
export const C_PROPERTY_NAVIGATION_BOOKING_SITES_V = 1721
export const C_PROPERTY_NAVIGATION_PROMOTIONS_V = 1724
export const C_PROPERTY_NAVIGATION_RATE_MANAGER_V = 1727
export const C_PROPERTY_NAVIGATION_CRM_V = 1730
export const C_PROPERTY_NAVIGATION_EMAIL_TEMPLATES_V = 1750;
export const C_PROPERTY_NAVIGATION_GALLERY_V = 2144;
export const C_PROPERTY_NAVIGATION_OLD_GALLERY_V = 2145;
export const C_PROPERTY_NAVIGATION_CM_V = 1847;
export const C_PROPERTY_NAVIGATION_OWNER_RATE_MANAGER = 1861;
export const C_PROPERTY_NAVIGATION_HEADER_BUTTON = 2103;


// unit general
export const C_UNIT_BASIC_TYPE_V = 1791;
export const C_UNIT_BASIC_MAX_ADULTS_V = 1794;
export const C_UNIT_BASIC_MAX_BABIES_V = 1797;
export const C_UNIT_BASIC_MAX_GUESTS_V = 1831;
export const C_UNIT_BASIC_SIZE_V = 1800;
export const C_UNIT_BASIC_NUM_BEDROOMS_V = 1803;
export const C_UNIT_BASIC_FLOOR_LEVEL_V = 1806;


export const C_UNIT_LIST_FILTER_V = 1382
export const C_UNIT_NEW_V = 1383
export const C_UNIT_BASIC_V = 1384
export const C_UNIT_BASIC_E = 1385
export const C_UNIT_BASIC_D = 1654
export const C_UNIT_LOCATION_V = 1386
export const C_UNIT_LOCATION_E = 1387
export const C_UNIT_GALLERY_V = 1388
export const C_UNIT_GALLERY_E = 1389
export const C_UNIT_GALLERY_TAG_V = 1566
export const C_UNIT_AMENITY_V = 1390
export const C_UNIT_AMENITY_E = 1391
export const C_UNIT_HEADLINE_V = 1392
export const C_UNIT_HEADLINE_E = 1393
export const C_UNIT_DECRIPTION_V = 1394
export const C_UNIT_PROPERTY_GALLERY_B = 2154;

export const C_UNIT_DESCRIPTION_E = 1395
export const C_UNIT_DESCRIPTION_NOTES_E = 1934
export const C_UNIT_DESCRIPTION_CONFIRMATION_EMAIL_BODY_E = 1933
export const C_UNIT_DESCRIPTION_POOL_E = 1932
export const C_UNIT_DESCRIPTION_GUEST_INTERACTION_E = 1931
export const C_UNIT_DESCRIPTION_HOUSE_RULES_E = 1930
export const C_UNIT_DESCRIPTION_GUEST_ACCESS_INVENTORY_E = 1929
export const C_UNIT_DESCRIPTION_SPACE_E = 1928
export const C_UNIT_DESCRIPTION_UNIT_SUMMARY_E = 1927

export const C_UNIT_KEY_PICKUP_V = 1396
export const C_UNIT_KEY_PICKUP_E = 1397
export const C_UNIT_COMMISSION_V = 1398
export const C_UNIT_COMMISSION_E = 1399
export const C_UNIT_DAMAGE_DEPOSIT_V = 1400
export const C_UNIT_DAMAGE_DEPOSIT_E = 1401
export const C_UNIT_FEE_V = 1402
export const C_UNIT_FEE_E = 1403
export const C_UNIT_RATE_PLAN_V = 1404
export const C_UNIT_RATE_PLAN_E = 1405
export const C_UNIT_RATE_PLAN_SYSTEM_E = 2045
export const C_UNIT_RATE_PLAN_TURN_ON_NRF_E = 1549
export const C_UNIT_RATE_PLAN_TURN_ON_NRF_V = 1567
export const C_UNIT_RATE_PLAN_TURN_ON_NRF_MODAL_E = 2132;
export const C_UNIT_RATE_PLAN_APPLY_E = 1550
export const C_UNIT_RATE_PLAN_APPLY_V = 1568
export const C_UNIT_RATE_PLAN_NEW_E = 1559
export const C_UNIT_RATE_PLAN_NEW_V = 1569
export const C_UNIT_RATE_PLAN_TYPE_V = 1687
export const C_UNIT_RATE_PLAN_PARENT_V = 1688
export const C_UNIT_RATE_PLAN_LINKED_VALUE_V = 1689
export const C_PROPERTY_CALENDAR_RATE_PLAN_V = 1478
export const C_UNIT_RATE_PLAN_RESTRICTIONS = 2109;
export const C_UNIT_RATE_PLAN_COPY_RESTRICTIONS_E = 2134;
export const C_UNIT_RATE_PLAN_LOS_V = 1676
export const C_UNIT_RATE_PLAN_APPLY_MIN_STAY_V = 1849
export const C_UNIT_RATE_PLAN_CHARGE_PROFILE_V = 1850
export const C_UNIT_RATE_PLAN_COMMUNICATION_TYPE = 2290
export const C_UNIT_RATE_PLAN_LIST_SELECTABLE = 2122;
export const C_UNIT_RATE_PLAN_LIST_COPY = 2123;
export const C_UNIT_RATE_PLAN_LIST_CHANGE_LINKED_VALUE = 2124;
export const C_UNIT_RATE_PLAN_LIST_DELETE = 2125;
export const C_UNIT_CALENDAR_V = 1406
export const C_UNIT_CALENDAR_SAVE = 1407
export const C_UNIT_CALENDAR_LOS_V = 1536
export const C_UNIT_CALENDAR_LOS_E = 1537
export const C_UNIT_CALENDAR_OCCUPANCY_V = 1539
export const C_UNIT_CALENDAR_OCCUPANCY_E = 1540
export const C_UNIT_CALENDAR_OCCUPANCY_SUGGESTIONS = 2558
export const C_UNIT_CALENDAR_PERIODS_V = 1542
export const C_UNIT_CALENDAR_PERIODS_E = 1543
export const C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_V = 1545
export const C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_E = 1546
export const C_UNIT_CALENDAR_RESTRICTIONS_E = 1936
export const C_UNIT_CALENDAR_YEARLY_VIEW_V = 2009
export const C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_MAKE = 2094
export const C_UNIT_CALENDAR_PERIOD_SUGGESTIONS_ACQUISITION = 2095
export const C_UNIT_CALENDAR_PERIODS_APPLY = 2096
export const C_UNIT_CALENDAR_DAILY_ADJUSTMENT_SAVE = 2192
export const C_UNIT_CALENDAR_DAILY_ADJUSTMENT_V = 2191
export const C_UNIT_CALENDAR_PRICE_IMPORT = 2390
export const C_UNIT_CALENDAR_PRICE_IMPORT_EXPORT = 2391
export const C_UNIT_RATE_PLAN_MASK_SETUP = 2504
export const C_UNIT_CALENDAR_COPY_ACQUISITION = 2528
export const C_UNIT_CALENDAR_COPY_UPDATE = 2529
export const C_UNIT_CALENDAR_PLATFORM_FEE_V = 2573
export const C_UNIT_CALENDAR_PLATFORM_FEE_DISCLAIMER = 2574

export const C_PROPERTY_CALENDAR_REVENUE_MANAGER_SETUP = 2478
export const C_PROPERTY_CALENDAR_PRICES_STARTING_PRICES = 2479
export const C_PROPERTY_CALENDAR_PRICES_LOS_LINKED_VALUE = 2481
export const C_PROPERTY_CALENDAR_PRICES_OCCUPANCY_LINKED_VALUE = 2483

export const C_UNIT_REVENUE_MANAGER_MIN_VALUES_V = 2147
export const C_UNIT_REVENUE_MANAGER_MIN_VALUES_SAVE = 2148
export const C_UNIT_REVENUE_MANAGER_ACTION_V = 2189
export const F_REVENUE_MANAGER_BOOKING_WINDOW_SETUP_RATE_ACTIVATION = 2212
export const F_REVENUE_MANAGER_BOOKING_WINDOW_SETUP_MIN_STAY_ACTIVATION = 2213

export const C_UNIT_CONTINGENT_SETUP_V = 1552
export const C_UNIT_CONTINGENT_SETUP_E = 1553
export const C_UNIT_CONTINGENT_SETUP_ATTACH_E = 1897
export const C_UNIT_CONTINGENT_SETUP_DETACH_E = 1898
export const C_UNIT_DEFAULT_RESTRICTIONS_V = 1557
export const C_UNIT_DEFAULT_RESTRICTIONS_E = 1558

export const C_OWNER_NEW_V = 1414
export const C_OWNER_BASIC_V = 1415
export const C_OWNER_BASIC_E = 1416
export const C_OWNER_DOCUMENT_V = 1417
export const C_OWNER_DOCUMENT_E = 1418
export const C_OWNER_CONTACT_V = 1419
export const C_OWNER_CONTACT_E = 1420
export const C_OWNER_USER_V = 1421
export const C_OWNER_USER_E = 1422
export const C_OWNER_FINANCE_V = 1482
export const C_OWNER_FINANCE_E = 1483
export const C_OWNER_FINANCE_EXTERNAL_ID_V = 1561
export const C_OWNER_FINANCE_EXTERNAL_ID_E = 1562


export const C_COMPANY_LIST_V = 1578

export const C_COMPANY_BASIC_V = 1581
export const C_COMPANY_BASIC_E = 1582

export const C_COMPANY_CONTACTS_V = 1584
export const C_COMPANY_CONTACTS_E = 1585

export const C_COMPANY_FINANCE_V = 1587
export const C_COMPANY_FINANCE_E = 1588
export const C_SETUP_FINANCE_ACTIVATION_REGISTER_SETUP_E = 2079;

export const C_COMPANY_SUBSCRIPTION_E = 1600
export const C_COMPANY_SUBSCRIPTION_ACTIVATE_DEMO = 2205
export const C_COMPANY_SUBSCRIPTION_REACTIVATE_DEMO = 2206
export const C_COMPANY_SUBSCRIPTION_EXTEND_DEMO = 2207
export const C_COMPANY_SUBSCRIPTION_SIGN_CONTRACT = 2208
export const C_COMPANY_SUBSCRIPTION_EXTEND_CONTRACT = 2209
export const C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS = 2137
export const C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_E = 2230
export const C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_FEE_PERCENT = 2219
export const C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_FEE_PERCENT_V = 2220
export const C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_FEE_PERCENT_E = 2221
export const C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_CANCELLATION_FEE = 2222
export const C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_CANCELLATION_FEE_V = 2223
export const C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_CANCELLATION_FEE_E = 2224
export const C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_MAX_YEARLY_AMOUNT = 2225
export const C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_MAX_YEARLY_AMOUNT_V = 2226
export const C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_MAX_YEARLY_AMOUNT_E = 2227
export const C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_CURRENT_TOTAL_AMOUNT = 2228
export const C_COMPANY_SUBSCRIPTION_CONTRACT_DETAILS_CURRENT_TOTAL_AMOUNT_V = 2229
export const C_COMPANY_SUBSCRIPTION_PAYMENT_TYPE_V = 2513
export const C_COMPANY_SUBSCRIPTION_PAYMENT_TYPE_E = 2514
export const C_COMPANY_SUBSCRIPTION_INVOICE_CREATION_SETUP_V = 2515
export const C_COMPANY_SUBSCRIPTION_INVOICE_CREATION_SETUP_E = 2516
export const C_COMPANY_SUBSCRIPTION_CALCULATION_CREATE = 2517
export const C_COMPANY_SUBSCRIPTION_CALCULATION_CANCEL = 2518

// RESERVATION

// ACCOMMODATION
export const C_RESERVATION_ACCOMMODATION_NEW = 1848

export const C_RESERVATION_ACCOMMODATION_GENERAL_V = 1617
export const C_RESERVATION_ACCOMMODATION_GENERAL_E = 1618
export const C_RESERVATION_ACCOMMODATION_GENERAL_UNKNOWN_E = 2002
export const C_RESERVATION_ACCOMMODATION_GENERAL_MAPPING = 2553
export const C_RESERVATION_ACCOMMODATION_GENERAL_UNKNOWN_PROPERTY_SEARCH_NAME_INCLUDING_ID = 2556

export const C_RESERVATION_ACCOMMODATION_GUEST_LIST_V = 1623
export const C_RESERVATION_ACCOMMODATION_GUEST_LIST_E = 1624

export const C_RESERVATION_ACCOMMODATION_LIST_SELECTABLE = 1923
export const C_RESERVATION_ACCOMMODATION_LIST_TOTAL_V = 1966
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_EMAIL = 2000
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT = 2001
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT_COLUMN_HOST_NOTE = 2012
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT_COLUMN_PROFILE_NAME = 2013
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT_COLUMN_PROFILE_TEXT = 2014
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT_COLUMN_SALES_CHANNEL_COMMISSION = 2015
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT_COLUMN_AGENCY_COMMISSION = 2016
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT_COLUMN_TRANSACTION_FEE = 2017
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT_COLUMN_TOTAL_COMMISSION = 2018
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT_COLUMN_OWNER_PART = 2019
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT_COLUMN_OWNER_PART_MAIN_CURRENCY = 2020
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT_COLUMN_PAYMENT_STATUS = 2021
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT_COLUMN_DISTRIBUTION_COMMISSION = 2386
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT_COLUMN_DISTRIBUTION_COMMISSION_VAT = 2387
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT_COLUMN_ADDITIONAL_COMMISSION = 2388
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT_COLUMN_ADDITIONAL_COMMISSION_VAT = 2389
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT_FOR_OWNER_COLUMN_TOTAL_GROSS = 2405
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_MIGRATION = 2406
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_EXPORT_COLUMN_FIXED_AGENCY_COMMISSION = 2500
export const C_RESERVATION_ACCOMMODATION_LIST_GROUP_FINANCE_UPDATE = 2533
export const C_RESERVATION_ACCOMMODATION_COPY_RESERVATION = 2348
export const C_RESERVATION_ACCOMMODATION_EXPORT_CURRENCY_OPTIONS = 2535

export const C_RESERVATION_ACCOMMODATION_NOTE_V = 1627
export const C_RESERVATION_ACCOMMODATION_NOTE_E = 1628
export const C_RESERVATION_ACCOMMODATION_NOTE_HOST_V = 1864
export const C_RESERVATION_ACCOMMODATION_NOTE_HOST_E = 1865
export const C_RESERVATION_ACCOMMODATION_NOTE_PRIVATE_V = 1866
export const C_RESERVATION_ACCOMMODATION_NOTE_PRIVATE_E = 1867
export const C_RESERVATION_ACCOMMODATION_COMMENT_GUEST_V = 1868
export const C_RESERVATION_ACCOMMODATION_COMMENT_GUEST_E = 1869
export const C_RESERVATION_ACCOMMODATION_COMMENT_RESERVATION_V = 1870
export const C_RESERVATION_ACCOMMODATION_COMMENT_RESERVATION_E = 1871

export const C_RESERVATION_ACCOMMODATION_CC_V = 1827
export const C_RESERVATION_ACCOMMODATION_CC_E = 1828
export const C_RESERVATION_ACCOMMODATION_CC_INVALID = 1997
export const C_RESERVATION_ACCOMMODATION_CC_CANCEL = 1999

export const C_RESERVATION_ACCOMMODATION_FINANCE_E = 1956

export const C_RESERVATION_ACCOMMODATION_CHARGE_PROFILE = 1874 // TODO rename
export const C_RESERVATION_ACCOMMODATION_PROPERTY = 1877
export const C_RESERVATION_ACCOMMODATION_UNIT = 1878
export const C_RESERVATION_ACCOMMODATION_PERIOD = 1879
export const C_RESERVATION_ACCOMMODATION_ARRIVAL_TIME = 1880
export const C_RESERVATION_ACCOMMODATION_DEPARTURE_TIME = 1882
export const C_RESERVATION_ACCOMMODATION_CURRENCY = 1883
export const C_RESERVATION_ACCOMMODATION_TOTAL = 1884
export const C_RESERVATION_ACCOMMODATION_GUEST_FIRST_NAME = 1885
export const C_RESERVATION_ACCOMMODATION_GUEST_LAST_NAME = 1886
export const C_RESERVATION_ACCOMMODATION_GUEST_COUNTRY = 1887
export const C_RESERVATION_ACCOMMODATION_GUEST_EMAIL = 1888
export const C_RESERVATION_ACCOMMODATION_GUEST_PHONE = 1889
export const C_RESERVATION_ACCOMMODATION_ADULTS = 1890
export const C_RESERVATION_ACCOMMODATION_CHILDREN = 1891
export const C_RESERVATION_ACCOMMODATION_BABIES = 1892
export const C_RESERVATION_ACCOMMODATION_DISTRIBUTION = 1893
export const C_RESERVATION_ACCOMMODATION_BOOKING_SITE = 1894
export const C_RESERVATION_ACCOMMODATION_STATUS = 1895
export const C_RESERVATION_ACCOMMODATION_SAVE = 1904
export const C_RESERVATION_ACCOMMODATION_RATE_PLAN = 1907
export const C_RESERVATION_ACCOMMODATION_FINANCE = 1918
export const C_RESERVATION_ACCOMMODATION_OWNER = 2060
export const C_RESERVATION_ACCOMMODATION_EXCHANGE_RATE = 2084
export const C_RESERVATION_ACCOMMODATION_MODULES_CARD = 1938
export const C_RESERVATION_ACCOMMODATION_MODULES_INFO = 1944
export const C_RESERVATION_ACCOMMODATION_MODULES_INVOICES = 2024
export const C_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES_TRANSFERS_V = 1943
export const C_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES_ACTIVITIES_V = 1947
export const C_RESERVATION_ACCOMMODATION_TAG_V = 2352
export const C_RESERVATION_ACCOMMODATION_TAG_E = 2353
export const C_RESERVATION_ACCOMMODATION_CM_STATUS = 2354
export const C_RESERVATION_ACCOMMODATION_ADD_EXISTING_USER = 2358
export const C_RESERVATION_ACCOMMODATION_SEND_NOTIFICATION = 2364

export const C_RESERVATION_ACCOMMODATION_SCHEDULER_EVENT_NEW = 2399;
export const C_RESERVATION_ACCOMMODATION_SCHEDULER_EVENT_E = 2400;
export const C_RESERVATION_ACCOMMODATION_SCHEDULER_EVENT_DELETE = 2401;

export const C_RESERVATION_ACCOMMODATION_FILTER_SELF_ISSUE = 2047
export const C_RESERVATION_ACCOMMODATION_FILTER_OWNER = 2048
export const C_RESERVATION_ACCOMMODATION_FILTER_INVOICE = 2049
export const C_RESERVATION_ACCOMMODATION_FILTER_ADVANCE = 2050
export const C_RESERVATION_ACCOMMODATION_FILTER_COST_CENTER = 2051
export const C_RESERVATION_ACCOMMODATION_FILTER_ACCOUNT_MANAGER = 2061
export const C_RESERVATION_ACCOMMODATION_FILTER_USER = 2350
export const C_RESERVATION_ACCOMMODATION_FILTER_TAG = 2351

export const C_RESERVATION_ACCOMMODATION_INVOICES_OWNER_V = 1911
export const C_RESERVATION_ACCOMMODATION_INVOICES_ADMIN_V = 1912

export const C_RESERVATION_ACCOMMODATION_FINANCE_CANCELLATION_POLICY_V = 2279
export const C_RESERVATION_ACCOMMODATION_FINANCE_CANCELLATION_POLICY_E = 2280
export const C_RESERVATION_ACCOMMODATION_FINANCE_CANCELLATION_POLICY_APPLY = 2281
export const C_RESERVATION_ACCOMMODATION_FINANCE_DETAILED_V = 2294
export const C_RESERVATION_ACCOMMODATION_FINANCE_DETAILED_E = 2295

export const C_RESERVATION_ACCOMMODATION_FINANCE_TOTAL_GROSS_V = 2414
export const C_RESERVATION_ACCOMMODATION_FINANCE_TOTAL_GROSS_E = 2415

export const C_RESERVATION_ACCOMMODATION_FINANCE_TOTAL_NETT_V = 2417
export const C_RESERVATION_ACCOMMODATION_FINANCE_TOTAL_NETT_E = 2418

export const C_RESERVATION_ACCOMMODATION_FINANCE_OWNER_PART_V = 2420

export const C_RESERVATION_ACCOMMODATION_FINANCE_COMMISSION_TOTAL_V = 2423

export const C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_TOTAL_V = 2426

export const C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_V = 2429
export const C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_E = 2430

export const C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_VAT_V = 2432
export const C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_VAT_E = 2433

export const C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_TOTAL_V = 2435

export const C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_V = 2438
export const C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_E = 2439

export const C_RESERVATION_ACCOMMODATION_FINANCE_FIXED_AGENCY_COMMISSION_V = 2441
export const C_RESERVATION_ACCOMMODATION_FINANCE_FIXED_AGENCY_COMMISSION_E = 2442

export const C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_TOTAL_V = 2459

export const C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_V = 2444
export const C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_E = 2445

export const C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_VAT_V = 2447
export const C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_VAT_E = 2448

export const C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_TOTAL_V = 2462

export const C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_V = 2450
export const C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_E = 2451

export const C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_VAT_V = 2453
export const C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_VAT_E = 2454

export const C_RESERVATION_ACCOMMODATION_FINANCE_TRANSACTION_FEE_V = 2456
export const C_RESERVATION_ACCOMMODATION_FINANCE_TRANSACTION_FEE_E = 2457

export const C_RESERVATION_ACCOMMODATION_FINANCE_RATE_PLAN_E = 2466
export const C_RESERVATION_ACCOMMODATION_FINANCE_PAYMENT_STATUS_E = 2469

export const C_RESERVATION_ACCOMMODATION_FINANCE_FETCH_FINANCIAL_VALUES = 2470

export const C_RESERVATION_ACCOMMODATION_INFORMATION_GUEST_BACKOFFICE = 2412

export const C_RESERVATION_ACCOMMODATION_FINANCE_PLATFORM_FEE_E = 2560
export const C_RESERVATION_ACCOMMODATION_FINANCE_PLATFORM_FEE_V = 2561


export const C_RESERVATION_FIELDS_OBJECT = {
    C_RESERVATION_ACCOMMODATION_CHARGE_PROFILE,
    C_RESERVATION_ACCOMMODATION_PROPERTY,
    C_RESERVATION_ACCOMMODATION_UNIT,
    C_RESERVATION_ACCOMMODATION_PERIOD,
    C_RESERVATION_ACCOMMODATION_ARRIVAL_TIME,
    C_RESERVATION_ACCOMMODATION_DEPARTURE_TIME,
    C_RESERVATION_ACCOMMODATION_CURRENCY,
    C_RESERVATION_ACCOMMODATION_TOTAL,
    C_RESERVATION_ACCOMMODATION_GUEST_FIRST_NAME,
    C_RESERVATION_ACCOMMODATION_GUEST_LAST_NAME,
    C_RESERVATION_ACCOMMODATION_GUEST_COUNTRY,
    C_RESERVATION_ACCOMMODATION_GUEST_EMAIL,
    C_RESERVATION_ACCOMMODATION_GUEST_PHONE,
    C_RESERVATION_ACCOMMODATION_ADULTS,
    C_RESERVATION_ACCOMMODATION_CHILDREN,
    C_RESERVATION_ACCOMMODATION_BABIES,
    C_RESERVATION_ACCOMMODATION_DISTRIBUTION,
    C_RESERVATION_ACCOMMODATION_BOOKING_SITE,
    C_RESERVATION_ACCOMMODATION_STATUS,
    C_RESERVATION_ACCOMMODATION_SAVE,
    C_RESERVATION_ACCOMMODATION_RATE_PLAN,
    C_RESERVATION_ACCOMMODATION_FINANCE,
    C_RESERVATION_ACCOMMODATION_EXCHANGE_RATE,
    C_RESERVATION_ACCOMMODATION_CM_STATUS
}

// SETUP
export const C_SETUP_GENERAL_V = 1835
export const C_SETUP_GENERAL_E = 1836

export const C_SETUP_FINANCE_VAT_V = 1634
export const C_SETUP_FINANCE_VAT_E = 1635
export const C_SETUP_FINANCE_VAT_NEW = 1756

export const C_SETUP_FINANCE_ITEM_DESCRIPTION_V = 1644
export const C_SETUP_FINANCE_ITEM_DESCRIPTION_E = 1645
export const C_SETUP_FINANCE_ITEM_DESCRIPTION_NEW = 1757

export const C_SETUP_FINANCE_INVOICE_TYPES_V = 1648
export const C_SETUP_FINANCE_INVOICE_TYPES_E = 1649
export const C_SETUP_FINANCE_INVOICE_TYPES_NEW = 1758

export const C_SETUP_FINANCE_INVOICE_CREATION_V = 1754
export const C_SETUP_FINANCE_INVOICE_CREATION_E = 1755
export const C_SETUP_FINANCE_INVOICE_CREATION_NEW = 1759

export const C_SETUP_FINANCE_CE_FIXED_V = 1774
export const C_SETUP_FINANCE_CE_FIXED_E = 1775
export const C_SETUP_FINANCE_CE_FIXED_NEW = 1776

export const C_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES_V = 1819
export const C_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES_E = 1820
export const C_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES_NEW = 1821
export const C_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES_DELETE = 1980

export const C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_V = 1839
export const C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_E = 1840
export const C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_NEW = 1841
export const C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_ENABLE_CUSTOM_TEXT = 2277
export const C_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICY_TAG = 2527

export const C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_V = 1842
export const C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_E = 1843
export const C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_NEW = 1844

export const C_SETUP_FINANCE_PARTNERS_E = 2064
export const C_SETUP_FINANCE_PARTNERS_NEW = 2065
export const C_SETUP_FINANCE_PARTNERS_FIELDS_EID = 2067

export const C_INVOICE_FIELDS_PAYMENT_TYPE = 2070

export const C_SETUP_TAG_E = 2249
export const C_SETUP_GUEST_BACKOFFICE_V = 2492
export const C_SETUP_GUEST_BACKOFFICE_E = 2493

export const C_SETUP_FEES_PLATFORM_FEE_V = 2569
export const C_SETUP_FEES_PLATFORM_FEE_E = 2570
export const C_SETUP_FEES_PLATFORM_FEE_COPY = 2571

// ADDITIONAL SETUP
export const C_COMPANY_ADDITIONAL_SETUP_SET_CURRENCY = 1902
export const C_COMPANY_ADDITIONAL_SETUP_SET_FIXED_EXCHANGE_RATE = 1903
export const C_COMPANY_ADDITIONAL_SETUP_SET_CITY = 1914
export const C_COMPANY_ADDITIONAL_SETUP_SET_COUNTRY = 1915
export const C_COMPANY_ADDITIONAL_SETUP_E = 1916

// DASHBOARD
export const C_TOTAL_INCOME_V = 1678
export const C_NIGHTS_V = 1764
export const C_CHECKIN_RESERVATIONS_V = 1778
export const C_CHECKOUT_RESERVATIONS_V = 1779
export const C_LATEST_RESERVATIONS_V = 1782
export const C_QUICK_ACTIONS_GRID_V = 1784
export const C_DASHBOARD_CONTRACT = 2110
export const C_DASHBOARD_CONTRACT_V = 2111
export const C_DASHBOARD_GENERAL_V = 2126
export const C_DASHBOARD_LAYOUT_ONE_V = 2127
export const C_DASHBOARD_OPEN_INVOICE_V = 2128
export const C_DASHBOARD_CHECK_INVOICE_V = 2129
export const C_DASHBOARD_RESERVATION_NO_INVOICE_V = 2130
export const C_DASHBOARD_CANCELED_RESERVATION_INVOICE_V = 2131
export const C_DASHBOARD_PROPERTY_DOCUMENT = 2526

// TEMPLATE
export const C_SEARCH_V = 1876


// TRANSFERS
export const C_TRANSFER_RESERVATION_E = 1951
export const C_TRANSFER_RESERVATION_EXPORT = 1954
export const C_TRANSFER_RESERVATION_ADD_RETURN_TRANSFER  = 2552

// Rate manager
export const C_RATE_MANAGER_SUGGESTIONS_CHANGED_E = 1994

//cm type
export const CM_SETUP_DISTRIBUTION = 2028;
export const CM_SETUP_DISTRIBUTION_INTEGRATED = 2029;
export const CM_SETUP_DISTRIBUTION_INTEGRATED_WITH_SETUP = 2271
export const CM_SETUP_DISTRIBUTION_INTEGRATED_WITHOUT_SETUP = 2272
export const CM_SETUP_DISTRIBUTION_TA_LOGIN_DETAILS = 2030;
export const CM_SETUP_DISTRIBUTION_HOTEL_BEDS_LOGIN_DETAILS = 2055;
export const CM_SETUP_DISTRIBUTION_EXPEDIA_PROVIDER_NAME = 2056;
export const CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY = 2057;
export const CM_SETUP_DISTRIBUTION_AGODA_CHAIN_CODE = 2081
export const CM_SETUP_DISTRIBUTION_ADVERTISER_ID = 2261
export const CM_SETUP_DISTRIBUTION_ADVERTISER_ID_V = 2282
export const CM_SETUP_DISTRIBUTION_ADVERTISER_ID_E = 2283
export const CM_SETUP_DISTRIBUTION_MERCHANT_NAME = 2262
export const CM_SETUP_DISTRIBUTION_MERCHANT_NAME_V = 2284
export const CM_SETUP_DISTRIBUTION_MERCHANT_NAME_E = 2285
export const CM_SETUP_DISTRIBUTION_RENTAL_AGREEMENT = 2263
export const CM_SETUP_DISTRIBUTION_RENTAL_AGREEMENT_V = 2286
export const CM_SETUP_DISTRIBUTION_RENTAL_AGREEMENT_E = 2287
export const CM_SETUP_DISTRIBUTION_HOME_AWAY_4_2_SETUP = 2264
export const CM_SETUP_DISTRIBUTION_HOME_AWAY_4_2_SETUP_V = 2288
export const CM_SETUP_DISTRIBUTION_HOME_AWAY_4_2_SETUP_E = 2289
export const CM_SETUP_DISTRIBUTION_HOME_AWAY_4_2_SETUP_ADVERTISERS_CONTENT_INDEX = 2292
export const CM_SETUP_DISTRIBUTION_HOME_AWAY_4_2_SETUP_PROPERTY_HIERARCHY = 2305
export const CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_ID_V = 2333
export const CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_LEGAL_CONTACT_EMAIL = 2335
export const CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_LEGAL_CONTACT_NAME = 2336
export const CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_LEGAL_NAME = 2337
export const CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_PREFERRED_LANGUAGE = 2338
export const CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_WEBSITE = 2339
export const CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_INVITE_PARTNER = 2340
export const CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_RESEND_PARTNER_INVITE = 2342
export const CM_SETUP_DISTRIBUTION_BOOKING_LEGAL_ENTITY_HAS_LEGAL_ENTITY_ID = 2345

export const INTEGRATIONS_CM_DISTRIBUTION_SETUP_HOMEAWAY_STANDARD = 2255;
export const INTEGRATIONS_CM_DISTRIBUTION_SETUP_HOMEAWAY_STANDARD_V = 2256;
export const INTEGRATIONS_CM_DISTRIBUTION_SETUP_HOMEAWAY_STANDARD_NEW = 2257;
export const INTEGRATIONS_CM_DISTRIBUTION_SETUP_HOMEAWAY_STANDARD_E = 2258;

export const INTEGRATIONS_CM_DISTRIBUTION_SETUP_EDIT = 2293


// inbox
export const C_INBOX_APPROVE = 2089
export const C_INBOX_DECLINE = 2090


// payment gateway
export const INTEGRATIONS_PG_PAYMENT_GATEWAY = 2179
export const INTEGRATIONS_PG_SETUP_ACTIVATE = 2181
export const INTEGRATIONS_PG_SETUP_DEACTIVATE = 2182
export const INTEGRATIONS_PG_SETUP_EDIT = 2183
export const INTEGRATIONS_PG_SETUP_AUTOMATIC_CHARGE_SETUP_AUTOMATIC_CHARGE = 2185
export const INTEGRATIONS_PG_SETUP_AUTOMATIC_CHARGE_SETUP_AUTOMATIC_CHARGE_CRON = 2186


//revenue maanger
export const REVENUE_MANAGER_COMPANY_ACTIVATION = 2188
export const C_REVENUE_MANAGER_LOCK_PRICE_V = 2265

export const F_REVENUE_MANAGER_RATE_ACTIVATION = 2193;
export const F_REVENUE_MANAGER_MIN_STAY_ACTIVATION = 2194;
export const F_REVENUE_MANAGER_ADD_BOOKING_WINDOW_RATES = 2195;
export const F_REVENUE_MANAGER_ADD_BOOKING_WINDOWS = 2197;
export const F_REVENUE_MANAGER_ADD_BOOKING_WINDOW_MIN_STAY = 2196;
export const F_REVENUE_MANAGER_RATE_ACTIVATION_INPUT = 2200;
export const F_REVENUE_MANAGER_MIN_STAY_ACTIVATION_INPUT = 2201;

export const F_REVENUE_MANAGER_PROPERTY_ACTIVATION_MIN_STAY = 2231;
export const F_REVENUE_MANAGER_COMPANY_ACTIVATION_MIN_STAY = 2232;
export const F_REVENUE_MANAGER_PROPERTY_MIN_STAY_V = 2233;

export const F_REVENUE_MANAGER_PROPERTY_RATES_V = 2236;
export const F_REVENUE_MANAGER_COMPANY_ACTIVATION_RATES = 2235;
export const F_REVENUE_MANAGER_PROPERTY_ACTIVATION_RATES = 2234;


// Direct Booking
// Website
export const C_WEBSITE_NEW = 2199
export const C_UNIT_ACTIVATION_CHARGE_PROFILE_V = 2218
export const C_WEBSITE_TAG_APPLY_DESTINATIONS = 2241
export const C_UNIT_ACTIVATION_BUTTON = 2242
export const C_WEBSITE_TAG_NEW_V = 2243
export const C_WEBSITE_COPY_AMENITY_CATEGORIES = 2244
export const C_WEBSITE_AMENITY_NEW_V = 2245
export const C_WEBSITE_AMENITY_CATEGORY_NEW_V = 2246
export const C_WEBSITE_AMENITY_E = 2247
export const C_WEBSITE_E = 2248
export const C_WEBSITE_ACTIVATE_TAG_E = 2250
export const C_WEBSITE_COPY_UNIT_TYPE = 2251
export const C_WEBSITE_NON_REF_EXISTS = 2252
export const C_CLEAR_CACHE_PERMISSION = 2253
export const WEBSITE_PAGES_FILTERS = 2238;
export const C_WEBSITE_TYPE_E = 2266
export const C_WEBSITE_TYPE_NEW_V = 2267
export const C_WEBSITE_MARKETPLACE_ACTIVATE = 2302
export const C_UNIT_ACTIVATION_ROLES_PERMISSION = 2311
export const C_UNIT_ACTIVATION_EDIT_V = 2318
export const C_UNIT_ACTIVATION_HEADLINE_V = 2319
export const C_UNIT_ACTIVATION_TAG_V = 2320
export const C_UNIT_ACTIVATION_DESTINATION_V = 2321
export const C_UNIT_ACTIVATION_BOOK_OPTIONS_V = 2322
export const C_UNIT_ACTIVATION_DESTINATION_SAVE_V = 2325
export const C_UNIT_ACTIVATION_TAG_SAVE_V = 2326
export const C_UNIT_ACTIVATION_BOOKING_OPTIONS_SAVE_V = 2327
export const C_WEBSITE_CLEAR_PAGE_CACHE = 2494

//Marketplace
export const MARKETPLACE_ACTIVATION = 2316

//Appearance
export const C_APPEARANCE_NEW_COLOR_V = 2329
export const C_APPEARANCE_NEW_CATEGORY_V = 2331
export const C_APPEARANCE_NEW_FONT_V = 2332

//New calendar
export const NEW_CALENDAR_V = 2498

// finance

// payment order
export const C_FINANCE_PAYMENT_ORDER_CHECK = 2547
export const C_FINANCE_PAYMENT_ORDER_REGENERATE = 2548

// payment message
export const C_FINANCE_PAYMENT_MESSAGES_MESSAGE_NUMBER_E = 2542

