import {fetchCompany, getCompanyTemplate} from "@/services/company";

const state = {
    company: {},
    template: {},
}

const getters = {
    getCompany(state) {
        return state.company
    },
    getTemplate(state) {
        return state.template
    }
}

const mutations = {
    setCompany(state, payload) {
        state.company = payload
    },
    setTemplate(state, payload) {
        state.template = payload
    }
}

const actions = {

    actionSetCompany({commit}, id) {
        return new Promise((resolve, reject) => {
            fetchCompany(id).then(response => {
                commit('setCompany', response.data)
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    actionSetCompanyTemplate({commit}, id) {
        return new Promise((resolve, reject) => {
            getCompanyTemplate(id).then(response => {
                commit('setTemplate', response.data)
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
