<template>
    <div>

        <label v-if="label">{{ label }}</label>

        <div class="float-right">
            <i
                @click="edit"
                v-if="(lang_id || translations) && languageList.length && !disabled"
                class="fa fa-language action_button mr-3"
                style="cursor:pointer">
            </i>

            <slot name="icons"></slot>
        </div>

        <div v-if="!disabled && placeholderList.length > 0">
            <template v-if="placeholderOptions.collapsable">
                <b-row>
                    <b-col class="mb-2">
                        <div class="border rounded">
                            <div class="d-flex justify-content-center align-items-center pt-1 pb-1 cursor-pointer"
                                 :class="{'border-bottom': placeholderDisplayState}"
                                 @click="placeholderDisplayState = !placeholderDisplayState">
                                <span class="ml-auto">Placeholders</span>
                                <i
                                    class="ml-auto mr-2 text-primary"
                                    :class="`fa fa-caret-${placeholderDisplayState ? 'up' : 'down'}`">
                                </i>
                            </div>
                            <b-collapse id="placeholders" v-model="placeholderDisplayState">
                                <div class="m-3">
                                    <b-badge :key="placeholderIndex"
                                             class="mt-1 mb-1 mr-1 p-2 cursor-pointer" variant="primary"
                                             pill
                                             v-for="(placeholder, placeholderIndex) in placeholderList"
                                             @click="insertPlaceholder(placeholder.value)">
                                        {{ placeholder.label }}
                                    </b-badge>
                                </div>
                            </b-collapse>
                        </div>
                    </b-col>
                </b-row>
            </template>
            <template v-else>
                <b-row>
                    <b-col class="mb-2">
                        <b-badge :key="placeholderIndex"
                                 class="mt-1 mb-1 mr-1 p-2 cursor-pointer" variant="primary"
                                 pill
                                 v-for="(placeholder, placeholderIndex) in placeholderList"
                                 @click="insertPlaceholder(placeholder.value)">
                            {{ placeholder.label }}
                        </b-badge>
                    </b-col>
                </b-row>
            </template>
        </div>

        <template v-if="component === `input`">
            <b-form-input type="text"
                          :value="value"
                          @input="$emit('input', $event)"
                          @blur="onBlur"
                          :state="state"
                          :maxlength="maxLength" :disabled="disabled">
            </b-form-input>
        </template>

        <template v-if="component === `textarea`">
            <b-form-textarea
                :value="value"
                @input="$emit('input', $event)"
                @blur="onBlur"
                :state="state" :rows="rows" :maxlength="maxLength" :disabled="disabled">
            </b-form-textarea>
        </template>

        <template v-if="component === `rich-text`">
            <app-wysiwyg-editor :value="value"
                                @input="$emit('input', $event)"
                                ref="wysiwygEditor"
                                :editorOptions="richTextOptions"
                                :disabled="disabled"
                                :max-height="maxHeight"
                                @focus="$emit('focus', $event)"
                                @blur="$emit('blur', $event)">
            </app-wysiwyg-editor>
        </template>

        <app-aside v-model="translateAsideState" v-if="lang_id || translations" :widths="width">
            <template slot="header">
                <app-object-header
                    :label="contextLabel"
                    :name="label"
                    :description="sidebarDescription !== null ? sidebarDescription : value">
                </app-object-header>
            </template>

            <b-form @submit.prevent="saveOrClose">
                <app-translation-list
                    :component="component"
                    :language-list="languageList"
                    :translations="translations_"
                    :rows="rows" :max-length="maxLength"
                    :richTextOptions="richTextOptions"
                    :placeholder-list="placeholderList"
                    :placeholder-options="translationListPlaceholderOptions"
                    @update="update">
                </app-translation-list>

                <app-button-submit class="mt-4" :loading="loading">
                    {{ translations ? $t('CLOSE') : $t('SAVE') }}
                </app-button-submit>
            </b-form>
        </app-aside>
    </div>
</template>

<script>
import {getTranslations, updateTranslations} from "@/services/system";
import {notifySuccess} from "@/shared/plugins/toastr";
import AppButton from "@/components/app/AppButton/AppButton";

export default {
    name: "AppTranslationInput",
    components: {
        AppButton,
        "AppWysiwygEditor": () => import("@/components/app/AppWysiwygEditor"),
        "AppObjectHeader": () => import("@/components/app/AppObjectHeader"),
        "AppTranslationList": () => import("@/components/app/translation/AppTranslationList"),
        "AppButtonSubmit": () => import("@/components/app/AppButton/AppButtonSubmit"),
        "AppAside": () => import("@/components/app/form/AppAside")
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        languages: {
            type: Array,
            default: () => []
        },
        lang_id: {
            type: Number,
            default: null
        },
        value: {
            type: String,
            default: null
        },
        contextLabel: {
            type: String,
            default: null
        },
        sidebarDescription: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        component: {
            type: String,
            default: "input",
            validator: value => {
                return (["input", "textarea", "rich-text"]).includes(value)
            }
        },
        state: {
            type: Boolean,
            default: null
        },
        rows: {
            type: Number,
            default: 5,
            validator: value => {
                return value > 2
            }
        },
        maxLength: {
            type: Number,
            default: null
        },
        translations: {
            type: Object,
            default: null,
        },
        richTextOptions: {
            type: Object,
            default: null
        },
        width: {
            default: () => {
                return ['col-lg-5', 'col-sm-12', '40%']
            }
        },
        placeholderList: {
            type: Array,
            default: () => []
        },
        maxHeight: {
            type: Boolean,
            default: false
        },
        insertPlaceholderValue: {
            type: String,
            default: null
        },
        placeholderOptions: {
            type: Object,
            default() {
                return {
                    collapsable: false,
                }
            },
            validator: (value) => {
                return value.hasOwnProperty('collapsable') && typeof value.collapsable === 'boolean' &&
                    (!value.hasOwnProperty('initial_display_state') || typeof value.initial_display_state === 'boolean') &&
                    (!value.hasOwnProperty('translations') ||
                        (
                            typeof value.translations === 'object' &&
                            (!value.translations.hasOwnProperty('enabled') || typeof value.translations.enabled === 'boolean') &&
                            (!value.translations.hasOwnProperty('collapsable') || typeof value.translations.collapsable === 'boolean') &&
                            (!value.translations.hasOwnProperty('per_language') || typeof value.translations.per_language === 'boolean')
                        )
                    )
            },
        },
    },
    data() {
        return {
            loading: false,
            translateAsideState: false,
            translations_: {},
            focusLocation: 0,
            placeholderDisplayState: this.placeholderOptions.hasOwnProperty('initial_display_state') ? this.placeholderOptions.initial_display_state : false,
            translationListPlaceholderOptions: {
                enabled: this.placeholderOptions.hasOwnProperty('translations') && this.placeholderOptions.translations.hasOwnProperty('enabled') ? this.placeholderOptions.translations.enabled : this.placeholderList.length > 0,
                collapsable: this.placeholderOptions.hasOwnProperty('translations') && this.placeholderOptions.translations.hasOwnProperty('collapsable') ? this.placeholderOptions.translations.collapsable : true,
                per_language: this.placeholderOptions.hasOwnProperty('translations') && this.placeholderOptions.translations.hasOwnProperty('per_language') ? this.placeholderOptions.translations.per_language : true,
            },
        }
    },
    computed: {
        languageList() {
            return this.languages.filter(el => el !== "en")
        }
    },
    watch: {
        translations: {
            immediate: true,
            deep: true,
            handler(translations) {
                if (translations) {
                    this.translations_ = {...translations}
                }
            }
        },
        insertPlaceholderValue: {
            handler(placeholderValue) {
                if (placeholderValue !== null) {
                    this.insertPlaceholder(placeholderValue)
                }
            }
        }
    },
    methods: {
        saveOrClose() {
            if (this.translations) {
                this.translateAsideState = false
            } else {
                this.loading = true
                updateTranslations(this.lang_id, this.translations_).then(() => {
                    notifySuccess()
                    this.translateAsideState = false
                    this.$emit('saved')
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => this.loading = false)
            }
        },
        edit() {
            this.translateAsideState = true;
        },
        update(translations) {
            if (this.translations) {
                this.$emit('updateTranslations', {...translations})
            } else {
                this.translations_ = {...translations}
            }
        },
        insertPlaceholder(placeholderValue) {
            if (this.component === 'input' || this.component === 'textarea') {
                this.$emit('insertPlaceholder', {
                    placeholder_value: placeholderValue,
                    focus_location: this.focusLocation,
                })
                this.$nextTick(() => {
                    this.focusLocation += placeholderValue.length
                })
            } else if (this.component === 'rich-text') {
                this.$refs.wysiwygEditor.insertTextOnCursor(placeholderValue)
            }
        },
        onBlur(event) {
            this.$emit('blur', event)
            if (this.component === 'input' || this.component === 'textarea') {
                this.focusLocation = event.target.selectionStart;
            }
        }
    },
    created() {
        if (this.lang_id) {
            getTranslations(this.lang_id, {languages: this.languageList}).then(response => {
                this.translations_ = response.data
            })
        }
    }
}
</script>

<style scoped>

</style>
