<template>
    <div v-if="distribution">
        <property-filter :has-filter="hasFilter" :distribution_id="distribution.id" ></property-filter>
        <property-list-table :has-filter="hasFilter" :empty_initial_list="empty_initial_list"
                             :distribution_id="distribution.id" :selectable="false"></property-list-table>
    </div>
</template>

<script>


import {DEFAULT_PAGINATION_LIMIT, LS_PRESETS} from "@/shared/constants";
import {getDistribution} from "@/services/distribution";
import {cloneDeep} from "lodash";

export default {
    name: "Index",
    components: {
        "PropertyFilter": () => import("@/components/property/list/PropertyFilter"),
        "PropertyListTable": () => import("@/components/property/list/PropertyListTable"),
    },
    data() {
        return {
            loading: false,
            hasFilter: true,
            empty_initial_list: false,
            distribution: null
        }
    },
    methods: {
        setFilter(values) {
            this.sort = values.sort
            this.filter = values.filter
        },
        toggleBusy($event) {
            this.loading = $event
        },
    },
    created() {
        let storagePresets = localStorage.getItem(LS_PRESETS)
        if (storagePresets) {
            storagePresets = JSON.parse(storagePresets)

            if (storagePresets.hasOwnProperty('properties') && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                if (storagePresets.properties === 0) {
                    this.empty_initial_list = true
                }
                this.hasFilter = false
            }
        }
    },
    watch: {
        '$route.params.distribution_id': {
            handler(value) {
                if (value) {
                    getDistribution(value).then(res => {
                        this.distribution = cloneDeep(res.data)
                    })
                }
            },
            immediate: true
        },
    }
}
</script>

<style scoped>

</style>
