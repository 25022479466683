<template>
    <div v-if="distribution">
        <owner-filter @search="search" :loading="loading" :distribution="distribution.id"></owner-filter>
        <owner-table :filter="filter" @busyToggled="toggleBusy" :distribution_id="distribution.id"></owner-table>
    </div>
</template>

<script>


import {cloneDeep} from "lodash";
import {getDistribution} from "@/services/distribution";

export default {
    name: "Index",
    components: {
        "OwnerFilter": () => import("@/components/owner/list/OwnerFilter"),
        "OwnerTable": () => import("@/components/owner/list/OwnerTable")
    },
    data() {
        return {
            filter: {},
            loading: false,
            distribution: null
        }
    },
    methods: {
        search(value) {
            this.filter = cloneDeep(value)
            this.loading = true
        },
        toggleBusy($event) {
            this.loading = $event
        },
    },
    watch: {
        '$route.params.distribution_id': {
            handler(value) {
                if (value) {
                    getDistribution(value).then(res => {
                        this.distribution = cloneDeep(res.data)
                    })
                }
            },
            immediate: true
        },
    }
}
</script>

<style scoped>

</style>
