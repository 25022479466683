<template>
    <div
            class="main_calendar_test--cell main_calendar_test--background_gray d-flex flex-column justify-content-around
            main_calendar_test--poiner main_calendar_test-bb main_calendar_test-br_green main_calendar_test-bl  animated fadeIn">
        <div class="main_calendar_test__holder--sidebar-h1 position-relative main_calendar_test_default-height-row">
            <b-tooltip :target="'restriction_key' +unit.id"  custom-class="my-tooltip-class">
                {{restrictionLabel}}
            </b-tooltip>
            <i :id="'restriction_key' +unit.id" class=" fa fa-question-circle-o ml-1 pull-right tooltip-help tooltip-help-normal mt-1 "></i>
            <div class="mt-3">
                <span :class="openClass" @click="handleClick">{{$t("RESTRICTIONS")}}</span>

                <span style="font-size: .8rem" class="main_calendar_test-color_action ml-2" v-if="checkPermission(C_PROPERTY_CALENDAR_RESTRICTIONS_RATE_PLANS_V) && show"
                      @click="modalShow=true"><i class="fa fa-plus fa-fw" aria-hidden="true"></i></span>
            </div>
        </div>
        <b-modal scrollable v-if="checkPermission(C_PROPERTY_CALENDAR_RESTRICTIONS_RATE_PLANS_V)" v-model="modalShow" :title="$t('RATE_PLAN_SELECTION')">
            <b-form>
                <b-row>
                    <b-col  lg="12"   :key="distributionRate.id" v-for="(distributionRate, distributionName) in listFormatted" >
                        <b-form-checkbox  @change="updateValue($event, distributionName)" v-if="distributionName !== 'system'">
                            {{distributionName}}
                        </b-form-checkbox>
                            <b-form-checkbox-group
                                ref="rateplan_restriction-checkbox-list"
                                stacked
                                :id="'rateplan_restriction-checkbox-list_'+distributionRate[0].id"
                                value-field="id"
                                text-field="name"
                                :options="distributionRate"
                                name="rateplan-restriction-checkbox-list"
                                v-model="selected"
                                :class="distributionName !=='system' ? 'ml-4':''"
                            >

                            </b-form-checkbox-group>

                        <hr>
                    </b-col>
                </b-row>
            </b-form>
            <div slot="modal-footer">
                <app-button  @click="update" >{{$t('CONFIRM')}}</app-button>
                <app-button class="mr-2 ml-2 pl-2"  @click="modalShow =false">{{$t('CANCEL')}}</app-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import {C_PROPERTY_CALENDAR_RESTRICTIONS_RATE_PLANS_V} from '@/shared/component_permission'
    import AppButton from "@/components/app/AppButton/AppButton";
    import {MIN_STAY} from "@/shared/constants";

    export default {
        name: "TableRestrictionSidebar",
        components: {AppButton},
        props: {
            ratePlans: {
                type: Array,
                default: () => {
                    return []
                }
            },
            show: {
                type: Boolean,
                default: false
            },
            unselected:{
                type:Array,
                default: () => {
                    return []
                }
            },
            unit:{
                type:Object
            }
        },
        data() {
            return {
                modalShow: false,
                selected: [],
                C_PROPERTY_CALENDAR_RESTRICTIONS_RATE_PLANS_V: C_PROPERTY_CALENDAR_RESTRICTIONS_RATE_PLANS_V,
                rp_list:[]
            }
        },
        computed: {
            openClass() {
                return this.show ? 'main_calendar_test--dropdown-down' : 'main_calendar_test--dropdown-up'
            },
            restrictions() {
                return this.$store.getters['calendar/getRestrictions']
            },
            restrictionLabel() {
                let label = null
                for (const [key, value] of Object.entries(this.restrictions)) {
                    if (value.id === MIN_STAY) {
                        label = value.label
                        break
                    }
                }
                return label
            },
            listFormatted() {

                let   ratePlanList =  this.ratePlans.map(item => {
                    return {...item, ...{name: item.name}}
                })

                let list = {}

                ratePlanList.forEach(currRatePlan => {
                    if (currRatePlan.distribution_name === null) {
                        if (!list.hasOwnProperty('system')) {
                            list['system'] = []
                        }
                        list['system'].push(currRatePlan)
                    } else {
                        if (!list.hasOwnProperty(currRatePlan.distribution_name)) {
                            list[currRatePlan.distribution_name] = []
                        }
                        list[currRatePlan.distribution_name].push(currRatePlan)
                    }
                })
                return list
            },
        },
        methods: {
            updateValue(value, distribution_name){

                let distributionRatePlanSelected = this.ratePlans.filter(el=>el.distribution_name === distribution_name).map(el=>{
                    return el.id
                })
                if(distributionRatePlanSelected.length === 0){
                    return
                }
                if(value){

                    distributionRatePlanSelected.forEach(id =>{
                        if(!this.selected.includes(id)){
                            this.selected.push(id)
                        }
                    })
                } else {
                    let filtered = this.selected.filter(id => !distributionRatePlanSelected.includes(id))
                    this.selected = filtered
                }

            },
            handleClick() {
                this.$emit('click')
            },
            update() {
                this.$emit('updateRatePlanListRestrictions', this.selected)
                this.modalShow = false
            }
        },
        watch:{

            unselected:{
                handler(list){
                    if(list.length > 0){
                        list.forEach(el =>{
                            let index = this.selected.findIndex(item=>item === el)
                            if(typeof  index === 'undefined'){
                                return
                            }
                            this.selected.splice(index,1)
                        })
                    }
                },immediate:true,
            }
        }
    }
</script>

<style scoped>

</style>
