<template>
    <b-form @submit.prevent="save" autocomplete="off">
        <b-row>
            <b-col md="6" lg="6" class="mb-4">
                <app-input-control :error-object="validationErrorObject.first_name">
                    <template v-slot:input="data">
                        <label>{{$t('FIRST_NAME')}}</label>
                        <b-form-input
                            type="text"
                            v-model="user.first_name"
                            :state="data.state">
                        </b-form-input>
                    </template>
                </app-input-control>
            </b-col>
            <b-col md="6" lg="6" class="mb-4">
                <app-input-control :error-object="validationErrorObject.last_name">
                    <template v-slot:input="data">
                        <label>{{$t('LAST_NAME')}}</label>
                        <b-form-input
                            type="text"
                            v-model="user.last_name"
                            :state="data.state">
                        </b-form-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="6" lg="6" class="mb-4">
                <app-input-control :error-object="validationErrorObject.username">
                    <template v-slot:input="data">
                        <label>{{$t('USERNAME_EMAIL')}}</label>
                        <b-form-input
                            type="text"
                            v-model="user.email"
                            :state="data.state">
                        </b-form-input>
                    </template>
                </app-input-control>
            </b-col>
            <b-col md="6" lg="6" class="mb-4">
                <app-input-control :error-object="validationErrorObject.phone">
                    <template v-slot:input="data">
                        <label>{{$t('PHONE')}}</label>
                        <b-form-input type="text" v-model="user.phone"
                                      :state="data.state">
                        </b-form-input>
                    </template>
                </app-input-control>
            </b-col>
        </b-row>

        <b-row v-if="roleData">
            <b-col class="mb-4">
                <b-checkbox v-for="(role, index) in roleData" :key="index" class="mb-1"
                            v-model="user.roles" switch
                            :value="role.id" @change="validatePMRoles"
                            :disabled="role.disabled">{{role.name}}
                </b-checkbox>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="mb-4">
                <app-button-submit :disabled="roleData && !user.roles.length" :loading="loading">{{$t('SAVE')}}</app-button-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppInputControl from "@/components/app/AppInputControl";
    import {toast} from "@/shared/plugins/toastr";
    import {addNewUser, getPossibleRoles} from "@/services/user";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {
        USER_FIRST_NAME_REQUIRED,
        USER_LAST_NAME_REQUIRED,
        USER_PHONE_VALIDATION_FAILED,
        USER_USERNAME_ALREADY_EXISTS,
        USER_USERNAME_EMAIL_VALIDATION_FAILED,
        USER_USERNAME_REQUIRED
    } from "@/shared/error_codes";
    import {ROLE_CHECKIN, ROLE_CLEANING, ROLE_PROPERTY_MANAGER_NEW, ROLE_PROPERTY_OWNER_NEW} from "@/shared/constants";
    import {cloneDeep} from "lodash";

    export default {
        name: "UserNewForm",
        components: {
            AppButtonSubmit,
            AppInputControl
        },
        mixins: [getErrorMessage],
        props: {
            roles: {
                type: Array,
                default: null
            },
        },
        data() {
            return {
                user: {
                    first_name: null,
                    last_name: null,
                    email: null,
                    phone: null,
                    company: null,
                    roles: [],
                },
                validationErrorObject: {
                    first_name: USER_FIRST_NAME_REQUIRED,
                    last_name: USER_LAST_NAME_REQUIRED,
                    username: {
                        input: [
                            USER_USERNAME_REQUIRED,
                            USER_USERNAME_EMAIL_VALIDATION_FAILED,
                            USER_USERNAME_ALREADY_EXISTS
                        ]
                    },
                    phone: USER_PHONE_VALIDATION_FAILED
                },
                ROLE_PROPERTY_MANAGER_NEW,
                roleData: null,
                loading: false
            }
        },
        methods: {
            save() {
                this.loading = true
                let requestObject = this.getRequestObject()
                if (!requestObject) return
                addNewUser(requestObject).then(res => {
                    this.$emit('addUser', res.data);
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                }, error => {
                    this.showErrorMessages(error, this.validationErrorObject);
                }).finally(() => {
                  this.loading = false
                })
            },
            getRequestObject() {
                let requestObject
                if (this.user.roles) {
                    if (this.user.roles.length) {
                        requestObject = {
                            'first_name': this.user.first_name,
                            'last_name': this.user.last_name,
                            'email': this.user.email,
                            'phone': this.user.phone,
                            'company': this.user.company,
                            'roles': this.user.roles
                        }
                    } else return null
                } else {
                    requestObject = this.user
                }
                return requestObject
            },
            validatePMRoles(value) {
                this.$nextTick(() => {
                    if (value === ROLE_PROPERTY_MANAGER_NEW || value === ROLE_PROPERTY_OWNER_NEW) {
                        this.user.roles = [value]
                    } else {
                        this.user.roles = this.user.roles.filter(e => Number(e) !== ROLE_PROPERTY_MANAGER_NEW)
                        this.user.roles = this.user.roles.filter(e => Number(e) !== ROLE_PROPERTY_OWNER_NEW)
                    }
                })
            }
        },
        created() {
            this.user.company = this.$store.getters[`user/getCompany`];
            this.user.roles = cloneDeep(this.roles)

            if (this.roles && this.roles.includes(ROLE_PROPERTY_MANAGER_NEW) && this.roles.length) {
                this.user.roles = []
                getPossibleRoles({roles: this.roles}).then(response => {
                    this.roleData = response.data.reduce((acc, val) => {
                        acc[val.id] = {
                            id: val.id,
                            name: val.name,
                            disabled: false,
                        }
                        return acc
                    }, {})

                })
            } else if (!this.roles) {
              this.user.roles = []
              getPossibleRoles({company_id: this.$store.getters[`user/getCompany`]}).then(response => {
                this.roleData = response.data.reduce((acc, val) => {
                  acc[val.id] = {
                    id: val.id,
                    name: val.name,
                    disabled: false,
                  }
                  return acc
                }, {})

              })
            }
        }
    }
</script>

<style scoped>

</style>
