<template>
    <router-view v-if="show" @click="resetTimeout"></router-view>
</template>

<script>
    import routeName from '@/router/routeNames'

    export default {
        name: 'app',
        data() {
            return {
                show: false,
            }
        },
        created() {

            if (!this.$route.query.token) {
                this.$store.dispatch('auth/actionAutoLogin').then(() => {
                    if ((this.$router.currentRoute.name === routeName.RN_LOGIN || this.$router.currentRoute.name === routeName.RN_W_LOGIN ||this.$router.currentRoute.name === routeName.RN_REGISTER ||
                        this.$router.currentRoute.name === routeName.RN_RESET_PASSWORD || this.$router.currentRoute.name === routeName.RN_W_RESET_PASSWORD || this.$router.currentRoute.name === routeName.RN_CHANGE_PASSWORD ||
                        this.$router.currentRoute.name === routeName.RN_ACTIVATE_USER) || this.$router.currentRoute.name === routeName.PUBLIC_PROPERTY_RESET) {
                        this.show = true
                        return
                    }
                    Promise.all([
                        this.$store.dispatch('user/actionSetUser'),
                        this.$store.dispatch('user/actionSetUserPermissions')
                    ]).then(() => {
                        this.show = true
                    }, () => {
                        this.show = true
                    })


                }, () => {
                    if (this.$router.currentRoute.name !== routeName.RN_LOGIN) {
                        this.show = true
                        this.$router.replace({
                            name: routeName.RN_LOGIN,
                            query: {redirect: this.$router.currentRoute.fullPath}

                        })
                    }
                })
            } else {
                this.show = true
            }

            document.addEventListener('click', this.resetTimeout);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.resetTimeout);
        },
        methods: {
            resetTimeout() {
                this.$store.commit('user/resetTimeout')
            },
        },

    }
</script>

<style lang="scss">
    // CoreUI Icons Set
    @import '~@coreui/icons/css/coreui-icons.min.css';
    /* Import Font Awesome Icons Set */
    $fa-font-path: '~font-awesome/fonts/';
    @import '~font-awesome/scss/font-awesome.scss';
    /* Import Simple Line Icons Set */
    $simple-line-font-path: '~simple-line-icons/fonts/';
    @import '~simple-line-icons/scss/simple-line-icons.scss';
    /* Import Flag Icons Set */
    @import '~flag-icon-css/css/flag-icon.min.css';
    /* Import Bootstrap Vue Styles */
    @import '~bootstrap-vue/dist/bootstrap-vue.css';
    // Import Main styles for this application
    @import 'assets/scss/style';
</style>
