<template>
    <b-form v-if="filteredSites && filteredSites.length > 0" @submit.prevent="saveData">
        <b-checkbox @change="selectAll">{{$t("SELECT_ALL")}}</b-checkbox>
        <b-form-checkbox-group switches v-model="selected">
            <b-table :items="filteredSites" :fields="fields">
                <template v-slot:edit="data">
                    <b-checkbox :value="data.item.id"></b-checkbox>
                </template>
            </b-table>
        </b-form-checkbox-group>

        <save-list :loading="saveState"></save-list>
    </b-form>
</template>

<script>
    import {getAllPropertyBookingSites} from '@/services/property/index'
    import SaveList from '@/components/app/AppSubmit'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'

    import {toast} from '@/shared/plugins/toastr'
    import {updatePropertyBookingSites} from '@/services/property/index'

    export default {
        name: "PropertyAllBookingSitesForm",
        components: {
            SaveList
        },
        mixins: [getErrorMessage],
        data() {
            return {
                list: [],
                fields: [
                    {key: 'name', label: this.$t('NAME')},
                    {key: 'edit', label: '', class: 'text-right'}
                ],
                selected: [],
                saveState: false
            }
        },
        computed: {
            filteredSites() {
                return this.list.filter(el => el.active === false)
            }
        },
        methods: {
            selectAll(value) {
                if (value) {
                    this.selected = []
                    this.selected = this.list.map(item => {
                        return item.id
                    })
                } else {
                    this.selected = []
                }
            },
            saveData() {
                if (this.selected.length === 0) {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('SELECT_AT_LEAST_ONE'),
                        'type': 'error',
                        'timeout': 6000
                    })
                    return
                }


                let updates = []
                this.selected.forEach(el => {
                    updates.push({id: el})
                })

                updatePropertyBookingSites(this.$route.params.id, {data: updates}).then((response) => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })
                        this.$emit('allSitesSaved', response.data)
                    }, error => {
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                            'type': 'error',
                            'timeout': 6000
                        })
                    }
                ).finally(this.saveState = false)

            }
        },
        created() {
            getAllPropertyBookingSites(this.$route.params.id).then(response => {
                    this.list = response.data
                },
                () => {
                    this.list = []

                }
            )
        }
    }
</script>

<style scoped>

</style>
