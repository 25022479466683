<template>
    <div>
        <log-filter :property_count="property_count" :revenue-manager-log="revenueManagerLog" @search="setFilter" :loading="loading"></log-filter>
        <log-table :property_count="property_count" @busyToggled="toggleBusy"  :filter="filter"></log-table>
    </div>
</template>

<script>
import LogFilter from "@/components/logs/LogFilter";
import LogTable from "@/components/logs/LogTable";
import {DEFAULT_PAGINATION_LIMIT, LS_PRESETS} from "@/shared/constants";

export default {
    name: "Logs",
    components: {LogTable, LogFilter},
    props:{
        revenueManagerLog:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            loading: false,
            filter: {},
            has_filter_property:true,
            property_count:null
        }
    },
    methods: {
        setFilter(values) {

            this.filter = values
            if(this.revenueManagerLog && this.$route.query.hasOwnProperty('revenue_manager_log')){
                this.filter = {...this.filter,...{revenue_manager_log:this.$route.query.revenue_manager_log}}
            }
        },
        toggleBusy($event) {
            this.loading = $event
        }
    },
    created(){
        let storagePresets = localStorage.getItem(LS_PRESETS)
        if (storagePresets) {
            storagePresets = JSON.parse(storagePresets)
            this.property_count = storagePresets.hasOwnProperty('properties') ? storagePresets.properties : 0
        }
    }
}
</script>

<style scoped>

</style>
