<template>
    <form  @submit.prevent="setAvailability">
        <b-row>
            <b-col class="mb-3">
                <b-form-radio-group
                    class="main_calendar-custom-radio"
                    v-model="availability"
                    stacked
                    :options="radio_options"
                    id="radios-open-close-availability"
                    name="radios-open-close-availability"
                ></b-form-radio-group>
            </b-col>
        </b-row>
        <b-row class="mb-4" v-if="availability === 0">
            <b-col>
                <label>{{$t('NOTE')}}</label>
                <b-textarea rows="2" v-model="note"></b-textarea>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-button-submit :disabled="isDisabled"
                            :loading="loading"></app-button-submit>
            </b-col>
        </b-row>
    </form>

</template>

<script>
import AppNumberInput from "@/components/app/form/AppNumberInput";
import AppSubmit from "@/components/app/AppSubmit";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import { setUnitBulkAvailabilityTest} from "@/services/unit";
import {EventBus, GE_CALENDAR_LOAD_AVAILABILITY, GE_CALENDAR_LOAD_PRICES} from "@/shared/EventBus";
import {toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
export default {
    name: "MainCalendarGroupAvailabilityForm",
    components: {AppButtonSubmit, AppSubmit, AppNumberInput},
    mixins:[getErrorMessage],
    props:{
        units: {
            type: Array
        },
        periods: {
            type: Array
        },
        allowedWeekday:{
            type:Array
        }
    },
    data(){
        return {
            av_mode_radio_value:null,
            minValue:null,
            maxValue:null,
            availability:null,
            loading:false,
            note:null,
            radio_options: [
                {text: this.$t("AVAILABILITY_UNIT_CALENDAR_OPEN"), value: 1},
                {text: this.$t("AVAILABILITY_UNIT_CALENDAR_CLOSED"), value: 0},
            ],

        }
    },
    methods:{
        setAvailability(){

            this.loading = true
            let periodList = this.periods.filter(item => item.deleted !== 1 && item.start && item.end);
            setUnitBulkAvailabilityTest({
                periods: periodList.map(el => {
                    return {
                        start: el.start,
                        end: el.end
                    }
                }),
                allowed_weekday: this.allowedWeekday,
                unit: this.units,
                value:this.availability,
                note:this.note
            }).then(() => {
                this.units.forEach(unitId => {
                    EventBus.$emit(GE_CALENDAR_LOAD_AVAILABILITY, {unitId: unitId})
                })
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 6000
                })
            }, error => {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 10000
                })
            }).finally(() => {
                this.loading = false
            })

        }

    },
    computed:{
        isDisabled(){
            return this.availability === null || this.periods.filter(item => item.deleted !== 1 && item.start && item.end).length === 0 || this.units.length === 0 || this.allowedWeekday.length === 0
        },
    }
}
</script>

<style scoped>

</style>
