<template>
    <div>
        <b-card v-if="distribution">
            <app-no-data v-if="property_count === 0" :heading="$t('MODULE_NOT_ACTIVE')"
                         :tip="$t('MODULE_NEED_PROPERTIES')" :show-tip="true">
                <new-button v-if="checkPermission(R_PROPERTY_NEW)" button_type="new" variant="primary"
                            class="pull-right ml-3" @click="property_aside = true">
                    {{ $t('NEW_PROPERTY') }}
                </new-button>
                <app-button
                    :access_control_context="{function: C_PROPERTY_IMPORT_FROM_OTA, key: access_control_fetch_key}"
                    @AccessControlEvent="addToAccessControlCounter()"
                    class="pull-right ml-3"
                    @click="importPropertyModalState = true">
                    {{ $t("IMPORT_FROM_OTA") }}
                </app-button>
            </app-no-data>
            <app-no-data v-if="property_count === 1 && root_unit_count === 0 && property"
                         :heading="$t('MODULE_NOT_ACTIVE')"
                         :tip="$t('MODULE_NEED_UNITS')" :show-tip="true"
            >
                <app-button button_type="new"
                            @click="unit_aside = true">
                    {{ $t('NEW_UNIT') }}
                </app-button>
            </app-no-data>
            <app-no-data v-if="property_count > 1 && root_unit_count === 0"
                         :heading="$t('MODULE_NOT_ACTIVE')" :show-tip="false"
            >
                <app-button v-if="checkPermission(R_PROPERTY_LIST)" variant="link"
                            :button_type="null"
                            class="action_button"
                            @click="$emit('close')"
                            :to="{name: routes.RN_PROPERTY_LIST}">
                    {{ $t('MODULE_NEED_UNITS') }}
                </app-button>
            </app-no-data>
            <b-modal
                v-model="importPropertyModalState"
                :title="$t('IMPORTANT')">
                <p>{{ $t("IMPORT_FROM_DISTRIBUTION", {"distribution": "Booking.com"}) }}</p>
                <ul>
                    <li>{{ $t("IMPORT_PROPERTY_FROM_BOOKING_DISCLAIMER_1") }}</li>
                    <li>{{ $t("IMPORT_PROPERTY_ADDITIONAL_COSTS_DISCLAIMER") }}</li>
                </ul>
                <app-button
                    @click="importFromDistribution(BOOKING)">
                    {{ $t("IMPORT") }}
                </app-button>
                <br/>
                <br/>
                <p>{{ $t("IMPORT_FROM_DISTRIBUTION", {"distribution": "AirBnB"}) }}</p>
                <ul>
                    <li>{{ $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_1") }}</li>
                    <li>{{
                            $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_2", {
                                "min_image_width": 2048,
                                "min_image_height": 1536
                            })
                        }}
                    </li>
                    <li>{{ $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_3") }}</li>
                    <li>{{ $t("IMPORT_PROPERTY_ADDITIONAL_COSTS_DISCLAIMER") }}</li>
                </ul>
                <app-button
                    @click="importFromDistribution(AIRBNB)">
                    {{ $t("IMPORT") }}
                </app-button>
                <template slot="modal-footer">
                    <template slot="modal-ok">
                    </template>
                </template>
            </b-modal>
            <unit-filter v-if="property_count !== 0 && root_unit_count !== 0"
                         :distribution="distribution.id"></unit-filter>
            <unit-list-table v-if="property_count !== 0 && root_unit_count !== 0"
                             :distribution="distribution"></unit-list-table>
        </b-card>
        <app-aside v-model="property_aside" :widths="['col-sm-12', '40%']">
            <template slot="header">{{ $t('NEW_PROPERTY') }}</template>
            <property-wizard></property-wizard>
        </app-aside>
        <app-aside v-model="unit_aside" :widths="['col-sm-12', '40%']">
            <template slot="header">{{ $t('NEW_UNIT') }}</template>
            <unit-wizard :property="property" :property-route="false"></unit-wizard>
        </app-aside>
        <app-aside
            v-model="importPropertyWizardState"
            :widths="['col-lg-12', '100%']">
            <template slot="header">{{ $t('IMPORT_FROM_OTA') }}</template>
            <import-property-wizard
                @importProperty="closeImportWizard"
                @close="closeImportWizard"
                :distribution="importDistribution"
                :entity="importEntity">
            </import-property-wizard>
        </app-aside>
    </div>
</template>

<script>
import UnitFilter from "@/components/unit/list/UnitFilter";
import UnitListTable from "@/components/unit/list/UnitListTable";
import {getDistribution} from "@/services/distribution";
import NewButton from '@/components/app/AppButton/AppButton';
import AppButton from "@/components/app/AppButton/AppButton";
import AppNoData from "@/components/app/AppNoData";
import PropertyWizard from "@/components/property/new/PropertyWizard";
import UnitWizard from "@/components/unit/new/UnitWizard";
import ImportPropertyWizard from "@/components/property/import/ImportPropertyWizard";
import AppAside from "@/components/app/form/AppAside";
import {C_PROPERTY_IMPORT_FROM_OTA, R_PROPERTY_NEW, R_PROPERTY_LIST} from "@/shared/route_permission";
import {AIRBNB, BOOKING, LS_PRESETS} from "@/shared/constants";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {AC_PROPERTY_IMPORT} from "@/mixins/AccessControl/AccessControlEnumeration";
import {getPresets} from "@/services/user";
import {getProperty} from "@/services/property";
import routes from '@/router/routeNames'


export default {
    name: "Index",
    components: {
        UnitListTable,
        UnitFilter,NewButton, AppNoData, PropertyWizard, ImportPropertyWizard, UnitWizard, AppAside, AppButton
    },
    mixins: [AccessControlComponent],
    data() {
        return {
            hasFilter: true,
            emptyInitialList: false,
            distribution: null,
            R_PROPERTY_NEW,
            C_PROPERTY_IMPORT_FROM_OTA,
            R_PROPERTY_LIST,
            property_aside: false,
            unit_aside: false,
            importPropertyModalState: false,
            importPropertyWizardState: false,
            BOOKING,
            AIRBNB,
            access_control_components: 1,
            access_control_fetch_key: AC_PROPERTY_IMPORT,
            importDistribution: null,
            importEntity: null,
            property_count: 0,
            root_unit_count: 0,
            property: null,
            routes
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    methods: {
        importPropertyTermsConfirmed() {
            this.importPropertyModalState = false
            this.importPropertyWizardState = true
        },
        importFromDistribution(distributionId) {
            this.importDistribution = distributionId
            this.importPropertyTermsConfirmed()
        },
        closeImportWizard() {
            this.importPropertyWizardState = false
            this.importDistribution = null
            this.importEntity = null
            this.$router.replace({"query": null})
        },
    },
    watch: {
        "$route.params.distribution_id": {
            handler(value) {
                if (value) {
                    getDistribution(value).then((response) => {
                        this.distribution = response.data
                    }, () => {
                        this.distribution = null
                    })
                }
            },
            immediate: true,
        },
    },
    created() {
        let storagePresets = localStorage.getItem(LS_PRESETS)
        if (storagePresets) {
            storagePresets = JSON.parse(storagePresets)
        } else {
            getPresets().then(response => {
                localStorage.setItem(LS_PRESETS, JSON.stringify(response.data))
                storagePresets = response.data
            })
        }
        if (storagePresets && typeof storagePresets === 'object') {
            if (storagePresets.hasOwnProperty('properties')) {
                this.property_count = storagePresets.properties
            }
            if (storagePresets.hasOwnProperty('properties_with_units')) {
                this.property_unit_count = storagePresets.properties_with_units
            }
            if (storagePresets.hasOwnProperty('root_units')) {
                this.root_unit_count = storagePresets.root_units
            }
            if (this.property_count === 1 && this.root_unit_count === 0) {
                getProperty(storagePresets.hasOwnProperty('single_property_id') && storagePresets.single_property_id).then((response) => {
                    this.property = response.data
                });
            }
        }

        this.access_control_general_context = {
            company: this.company.id
        }
    }
}
</script>

<style scoped>

</style>
