<template>
    <div>
        <div>
            <app-button v-for="data in transitions" @click="resolveTransition(data)">{{data.label}}</app-button>
        </div>
    </div>
</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";
import {getUnitTransitionStatus} from "@/services/direct_booking/website";
import {getUnit} from "@/services/unit";

export default {
    name: "ResolveRequestForm",
    components:{AppButton},
    props:{
        distribution_id:{
            type: Number,
            default: null
        },
        status_eid:{
            type: String,
            default: null
        },
        object_id:{
            type: Number,
            default: null
        }
    },
    data(){
        return{
            transitions:[]
        }
    },
    methods:{
        resolveTransition(data){
        }
    },
    created() {
        getUnitTransitionStatus({distribution_id: this.distribution_id, status_eid: this.status_eid}).then(response => {
            this.transitions = response.data
        })
    }
}
</script>

<style scoped>

</style>
