<template>
    <gmap-map @click="emitLocation" :center="mapPosition" :zoom="zoom" style="height: 400px" :options="mapOptions">
        <gmap-marker
                :position="markerPosition"
                :clickable="false"
                :draggable="true"
                @dragend="emitLocation"></gmap-marker>
    </gmap-map>

</template>
<script>
    /* global google */
    import Vue from 'vue'
    import * as VueGoogleMaps from 'vue2-google-maps'

    Vue.use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyAqlFMrke8ePbFEpdyWwlYzPut_2W5arXU'
        }
    })

    export default {
        name: "GoogleMap",
        props: {
            lat: {
                default: 0
            },
            lng: {
                default: 0
            },
            initialZoom: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                latitude: 0,
                longitude: 0,
                zoom: 3,
                mapOptions: {
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    styles: [
                        {
                            "featureType": "administrative",
                            "elementType": "all",
                            "stylers": [{"visibility": "on"}]
                        },
                        {
                            "featureType": "poi",
                            "stylers": [{"visibility": "on"}]
                        },
                        {
                            "featureType": "poi.attraction",
                            "stylers": [{"visibility": "off"}]
                        },
                        {
                            "featureType": "poi.school",
                            "stylers": [{"visibility": "off"}]
                        },
                        {
                            "featureType": "poi.business",
                            "stylers": [{"visibility": "off"}]
                        },
                    ]
                }
            }
        },
        computed: {
            markerPosition() {
                return {
                    lat: parseFloat(this.latitude),
                    lng: parseFloat(this.longitude)
                }
            },
            mapPosition() {
                return {
                    lat: this.latitude,
                    lng: this.longitude
                }
            }
        },
        methods: {
            emitLocation(event) {

                // this.longitude = event.latLng.lng()
                // this.latitude = event.latLng.lat()

                this.longitude = parseFloat(event.latLng.lng().toString().slice(0,9))
                this.latitude  = parseFloat(event.latLng.lat().toString().slice(0,9))

                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({
                    latLng: {
                        lat: this.latitude,
                        lng: this.longitude
                    }
                }, (responses) => {
                    var response = {
                        'street': null,
                        'street_number': null,
                        'postal_code': null,
                        'city': null,
                        'region': null,
                        'county': null,
                        'country': null,
                        'latitude': null,
                        'longitude': null,
                    }

                    response.street_number = this.filterResponse(responses, 'street_number')
                    response.street = this.filterResponse(responses, 'route')
                    response.city = this.filterResponse(responses, 'locality')
                    response.county = this.filterResponse(responses, 'administrative_area_level_1')
                    response.region = this.filterResponse(responses, 'administrative_area_level_2')
                    response.country = this.filterResponse(responses, 'country')
                    response.postal_code = this.filterResponse(responses, 'postal_code')
                    response.latitude = this.latitude.toString()
                    response.longitude = this.longitude.toString()
                    this.$emit('gmapLocationChange', response)
                })
            },
            filterResponse(responses, type) {

                let returnValue = null

                if (responses && responses.length > 0 && responses[0].address_components && responses[0].address_components.length > 0) {
                    let components = responses[0].address_components
                    components.forEach((item) => {
                        if (item.types.indexOf(type) > -1) {
                            returnValue = item.long_name
                        }
                    });
                }
                return returnValue
            }
        },
        watch: {
            lat: function (newVal) {
                if (newVal > 0) {
                    this.zoom = this.initialZoom || 15
                }
                this.latitude = parseFloat(newVal)

                let pos = {
                    latLng: {
                        lng: () => {
                            return this.longitude
                        },
                        lat: () => {
                            return this.latitude
                        }
                    }
                }
                // mjenjat ce se logika pa je zasad zakomentirano
                // this.emitLocation(pos)

            },
            lng: function (newVal) {
                this.longitude = parseFloat(newVal)
                let pos = {
                    latLng: {
                        lng: () => {
                            return this.longitude
                        },
                        lat: () => {
                            return this.latitude
                        }
                    }
                }
// mjenjat ce se logika pa je zasad zakomentirano
                //  this.emitLocation(pos)

            }
        },
        mounted() {
            this.latitude = Number(this.lat)
            this.longitude = Number(this.lng)

            if (this.lat > 0) {
                this.zoom = this.initialZoom || 15
            }
        },
    }
</script>

<style scoped>

</style>
