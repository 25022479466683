<template>
    <div>
        <property-general-form v-if="showBasicComponent"></property-general-form>
    </div>
</template>

<script>

    import {C_PROPERTY_BASIC_V} from "@/shared/component_permission";
    import PropertyGeneralForm from "@/components/property/basic/PropertyGeneralForm";

    export default {
        name: "Index",

        components: {
            PropertyGeneralForm
        },
        computed: {
            showBasicComponent() {
                return this.$store.getters['user/getPermission'](C_PROPERTY_BASIC_V)
            }
        },
    }
</script>

<style scoped>

</style>
