<template>
    <editor ref="editor"
            :value="value"
            @input="$emit('input', $event)"
            @blur="$emit('blur', $event)"
            @focus="$emit('focus', $event)"
            class="wysiwyg-editor"
            :options="options"
            :class="computedClasses"
            :disabled="disabled">
    </editor>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor'
import _cloneDeep from 'lodash/cloneDeep'

export default {
    name: "AppWysiwygEditor",
    components: {
        "Editor": quillEditor
    },
    props: {
        value: {
            type: String,
            default: null,
        },
        editorOptions: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        maxHeight: {
            type: Boolean,
            default: false
        },
        state: {
            default: null
        }
    },
    data() {
        return {
            options: null,
        }
    },
    computed: {
        editor() {
            return this.$refs.editor.quill
        },
        computedClasses() {
            return {
                'max-height': this.maxHeight,
                'border-danger': this.state === false
            }
        }
    },
    methods: {
        insertTextOnCursor(text) {
            let selection = this.editor.getSelection(true)
            this.editor.insertText(selection.index, text)
        }
    },
    created() {
        if (this.editorOptions) {
            this.options = _cloneDeep(this.editorOptions)
        }
    }
}
</script>

<style scoped lang="scss"></style>
