import { render, staticRenderFns } from "./MarketplaceEditGeneralTab.vue?vue&type=template&id=646a34ab&scoped=true&"
import script from "./MarketplaceEditGeneralTab.vue?vue&type=script&lang=js&"
export * from "./MarketplaceEditGeneralTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "646a34ab",
  null
  
)

export default component.exports