import http from "@/services/http";
import {getAccessControlUrl} from "@/mixins/AccessControl/AccessControlEnumeration";

export const AccessControlComponent = {
    data() {
        return {
            access_control_components: 1,
            access_control_components_counter: 1,
            access_control_fetch_key: null,
            access_control_general_context: null,
        }
    },
    computed: {
        AccessControlData() {
            if (
                (typeof this.access_control_context !== 'undefined' && this.access_control_context !== null)
                && (this.access_control_context.hasOwnProperty('key') && this.access_control_context.key !== null)

            ) {
                return {...{uid: this._uid}, ...this.access_control_context}
            }
            return null
        },
        AccessControlDisabled() {
            return this.$store.getters['access_control/isDisabled'](this._uid)
        },
        AccessControlVisible() {
            return this.access_control_context === null || this.$store.getters['access_control/isVisible'](this._uid)
        },
        AccessControlMessage() {
            return this.$store.getters['access_control/message'](this._uid)
        }
    },
    methods: {
        addToAccessControlCounter() {
            this.access_control_components_counter--
        },
        fetchAccessControlData(key) {
            http.request({
                url: getAccessControlUrl(key),
                method: 'POST',
                data: {
                    data: this.$store.getters['access_control/getComponentItems'](key), key, context: this.access_control_general_context
                }
            }).then(response => {
                this.$store.dispatch('access_control/actionSetComponentResponseData', response.data)
            }).finally(this.access_control_components_counter = this.access_control_components)
        }
    },
    watch: {
        access_control_components_counter: {
            handler(value) {
                if (value === 0) {
                    this.$emit('AccessControlEvent')
                    if (this.access_control_fetch_key && getAccessControlUrl(this.access_control_fetch_key)) {
                        this.fetchAccessControlData(this.access_control_fetch_key)
                    }
                }
            }
        },
        access_control_components: {
            handler(value) {
                this.access_control_components_counter = value

                if (this.AccessControlData !== null) {
                    this.$store.dispatch('access_control/actionSetComponent', this.AccessControlData)
                    this.addToAccessControlCounter()
                }
            }
        }
    },
    mounted() {

        this.access_control_components_counter = this.access_control_components

        if (this.AccessControlData !== null) {
            this.$store.dispatch('access_control/actionSetComponent', this.AccessControlData)
            this.addToAccessControlCounter()
        }
    },
    beforeDestroy() {
        if(this.access_control_fetch_key){
            this.$store.dispatch('access_control/actionClear', this.access_control_fetch_key)
        }
    }
}
