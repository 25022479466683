<template>
    <div>
        <b-card>
            <div class="header-2 mb-4">{{ $t("UNPAID_INVOICES") }}</div>
            <div v-if="numberOfInvoices.length===0">
                <label>{{ $t("NO_DATA_FOR_PREVIEW") }}</label>
            </div>
            <div v-else>
                <div class="d-flex justify-content-start align-items-center" v-if="loading">
                    <b-spinner variant="primary"></b-spinner>
                </div>
                <div v-for="invoice in numberOfInvoices">
                    <div class="d-flex justify-content-between" v-if="invoice.total>0">
                        <div class="d-flex flex-column justify-content-between">
                            <label>{{ invoice.invoice_type }}</label>
                        </div>
                        <b-link :to="openLink(invoice.invoice_type_id)"
                                target= '_blank'>
                            {{ invoice.total }}
                        </b-link>
                    </div>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>

import {INVOICE_STATUS_COMPLETED, INVOICEE_FILTER_LIST} from "@/shared/constants";
import moment from 'moment'
import AppButton from "@/components/app/AppButton/AppButton";
import routeNames from "@/router/routeNames";
import FormHeader from "@/components/app/form/FormHeader";
import RepeaterHeader from "@/components/app/form/RepeaterHeader";
import {getOpenInvoice} from "@/services/dashboard";
import routes from '@/router/routeNames'

export default {
    name: "DashboardOpenInvoice",
    components: {RepeaterHeader, FormHeader, AppButton},
    props: {
        company: {
            type: Number,
        },
        property: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            routes: routes,
            INVOICE_STATUS_COMPLETED,
            INVOICEE_FILTER_LIST,
            loading: false,
            numberOfInvoices: {},
        }
    },
    methods: {
        openLink(invoice_type_id) {
            return {
                name: routes.RN_INVOICE_LIST,
                query:{
                    invoice_subtype: [Number(invoice_type_id)],
                    status: [INVOICE_STATUS_COMPLETED],
                    total_from: 0,
                    date: 'due_date',
                    partner_type: INVOICEE_FILTER_LIST,
                    to: this.date,
                }
            }
        },
        getData() {
            this.loading = true
            let req = {
                company: this.company,
                status: [INVOICE_STATUS_COMPLETED],
                total_from: 0,
                partner_type: INVOICEE_FILTER_LIST,
                due_date_to: moment().format("YYYY-MM-DD"),
            }

            getOpenInvoice(req).then(response => {
                this.numberOfInvoices = response.data.filter(el => el.total > 0)
            })


            this.loading = false
        }
    },
    computed:{
        date(){
            return moment().format("YYYY-MM-DD")
        }
    },
    mounted() {
        this.getData()
    },
}
</script>

<style scoped>

</style>
