<template>
    <div>
        <vue-gallery :images="imageRoutes" :index="selectedImageIndex" @close="selectedImageIndex = null">
            <template #close>
                <i class="fa fa-close" style="color: white"></i>
            </template>
        </vue-gallery>

        <gallery-upload
            class="mb-4"
            @uploadSuccess="getWebsiteGalleryImages"
            :object-id="website.id"
            :document-type-id="TYPE_WEBSITE_GALLERY"
            document-type=".png,.jpg,.jpeg,.svg">
        </gallery-upload>

        <form @submit.prevent>
            <b-row class="align-items-center">
                <b-col md="3" class="mb-4">
                    <label>{{ $t('DESCRIPTION') }}</label>
                    <b-form-input v-model="search" @keydown.enter="getWebsiteGalleryImages(search)"></b-form-input>
                </b-col>
                <b-col class="align-self-end mb-4">
                    <app-button @click="getWebsiteGalleryImages(search)" :loading="loading" :disabled="saving">
                        {{ $t('SEARCH') }}
                    </app-button>
                </b-col>
            </b-row>
        </form>

        <app-no-data v-if="!loading && !imageList.length"></app-no-data>

        <div class="row" v-if="imageList.length">
            <gallery-item
                v-for="(image, index) in imageList" :key="image.id"
                :item="image"
                @imageClicked="selectedImageIndex = index"
                @textfieldEdit="value => setImageDescription(image, value)"
                @removeDocument="deleteImages([image])"
                @copyCheck="image.checked = $event"
                :loading="image.loading"
                :checkboxSelectedList="selectedImageIds">
            </gallery-item>
        </div>

        <b-row v-if="imageList.length">
            <b-col>
                <app-button :loading="saving" :disabled="loading || !updatedImages.length"
                            @click="updateImages(updatedImages)">
                    {{ $t('SAVE') }}
                </app-button>
            </b-col>
        </b-row>

        <div class="mt-3 availability_calendar-selectedContainer animated fadeIn" v-if="selectedImages.length">
            <div>
                <h4 v-if="selectedImages.length > 1">{{ $t("ITEMS_SELECTED", {value: selectedImages.length}) }}</h4>
                <h4 v-else>{{ $t("ITEM_SELECTED") }}</h4>
                <app-button
                    @click="deselectImages"
                    class="action_button"
                    variant="link">{{ $t("DESELECT") }}
                </app-button>
            </div>

            <div class="d-flex">
                <app-button id="deleteButton" @click="deleteImages(selectedImages)" :loading="saving" class="mr-3">
                    {{ $t("DELETE") }}
                </app-button>
            </div>
        </div>
    </div>
</template>

<script>
import {TYPE_WEBSITE_GALLERY} from "@/shared/constants";
import {deleteDocumentList, getDocumentList, updateDocumentList} from "@/services/document";
import {notifySuccess} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";

export default {
    name: "WebsiteGallery",
    mixins: [getErrorMessage],
    components: {
        "AppNoData": () => import("@/components/app/AppNoData"),
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "GalleryItem": () => import("@/components/app/gallery/GalleryItem"),
        "GalleryUpload": () => import("@/components/app/gallery/GalleryUpload"),
        "VueGallery": () => import("vue-gallery")
    },
    data() {
        return {
            TYPE_WEBSITE_GALLERY,
            imageList: [],
            selectedImageIndex: null,
            search: null,
            loading: false,
            saving: false,
            updatedImages: [],
        }
    },
    computed: {
        website() {
            return this.$store.getters['website/getWebsite']
        },
        selectedImages() {
            return this.imageList.filter(el => el.checked)
        },
        selectedImageIds() {
            return this.selectedImages.map(el => el.id)
        },
        imageRoutes() {
            return this.imageList.map(el => el.route)
        }
    },
    methods: {
        getWebsiteGalleryImages(description = null) {
            this.updatedImages = []
            this.loading = true
            return getDocumentList(this.website.id, TYPE_WEBSITE_GALLERY, {description}).then(response => {
                this.imageList = response.data.map(el => ({...el, checked: false, loading: false}))
            }).finally(() => this.loading = false)
        },
        setImageDescription(imageObject, value) {
            imageObject.description = value
            if (!this.updatedImages.find(el => el.id === imageObject.id)) {
                this.updatedImages.push(imageObject)
            }
        },
        updateImages(imageObjects) {
            this.saving = true
            imageObjects.forEach(el => el.loading = true)
            return updateDocumentList(this.website.id, TYPE_WEBSITE_GALLERY, {list: imageObjects}).then(() => {
                this.updatedImages = []
                notifySuccess()
            }).finally(() => {
                imageObjects.forEach(el => el.loading = false)
                this.saving = false
            })
        },
        deleteImages(imageObjects) {
            const imageIds = imageObjects.map(el => el.id)

            imageObjects.forEach(el => el.loading = true)
            this.saving = true
            return deleteDocumentList(this.website.id, TYPE_WEBSITE_GALLERY, imageIds).then(() => {
                this.imageList = this.imageList.filter(el => !imageIds.includes(el.id))
                this.updatedImages = this.updatedImages.filter(el => !imageIds.includes(el.id))
                notifySuccess()
                this.saving = false
            }, error => {
                this.showErrorMessages(error)
                //repeat search to cover edge case where some images were successfully deleted
                if (imageIds.length > 1) {
                    this.getWebsiteGalleryImages(this.search)
                } else {
                    this.saving = false
                }
            }).finally(() => this.imageList.forEach(el => el.loading = false))
        },
        deselectImages() {
            this.imageList.forEach(el => el.checked = false)
        }
    },
    created() {
        this.getWebsiteGalleryImages()
    }
}
</script>

<style scoped>

</style>
