<template>
    <b-card class="main-section-card">
        <div slot="header">
            <h2>{{ $t("OWNER_STATEMENTS") }}</h2>
            <app-button v-if="checkPermission(F_CALCULATION_CREATE)" button_type="new" variant="primary"
                        class="pull-right" @click="()=>{asidePanelState=true}">
                {{ $t("NEW_STATEMENT") }}
            </app-button>
        </div>
        <owner-calculation-filter></owner-calculation-filter>
        <owner-calculation-table @showCalculation="showCalculation"></owner-calculation-table>

        <app-aside v-model="calculationAsideState">
            <template slot="header">
                <app-object-header :label="$t('OWNER_STATEMENT')" :name="calculationId"></app-object-header>
            </template>
            <owner-calculation-form :calculation-id="calculationId"
                                    @close="() => {this.calculationAsideState=false; reloadTable()}"></owner-calculation-form>
        </app-aside>

        <app-aside v-model="asidePanelState">
            <template slot="header">{{$t("NEW_STATEMENT")}}</template>
            <owner-calculation-generate-form
                @created="closeAsideAndReload">
            </owner-calculation-generate-form>
        </app-aside>
    </b-card>
</template>

<script>
    import OwnerCalculationFilter from "@/components/finance/owner_calculation/OwnerCalculationFilter";
    import OwnerCalculationTable from "@/components/finance/owner_calculation/OwnerCalculationTable";
    import AppAside from "@/components/app/form/AppAside";
    import OwnerCalculationForm from "@/components/finance/owner_calculation/OwnerCalculationForm";
    import {EventBus, GE_TABLE_REFRESH_SEARCH} from "@/shared/EventBus";
    import AppObjectHeader from "@/components/app/AppObjectHeader";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {F_CALCULATION_CREATE} from "@/shared/function_permission"
    import OwnerCalculationGenerateForm from "@/components/finance/owner_calculation/OwnerCalculationGenerateForm";

    export default {
        name: "Index",
        components: {
            OwnerCalculationGenerateForm,
            AppButton,
            AppObjectHeader,
            OwnerCalculationForm, OwnerCalculationTable, OwnerCalculationFilter, AppAside
        },
        data() {
            return {
                calculationId: null,
                calculationAsideState: false,
                F_CALCULATION_CREATE,
                asidePanelState: false,
            }
        },
        methods: {
            showCalculation(id) {
                this.calculationId = id
                this.calculationAsideState = true
            },
            closeAsideAndReload() {
                this.asidePanelState = false
                this.reloadTable()
            },
            reloadTable() {
                EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
            }
        }
    }
</script>

<style scoped>

</style>
