import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth/auth'
import user from './modules/user/user'
import property from './modules/property/property'
import unit from './modules/unit/index'
import owner from './modules/owner/owner'
import shared from './modules/shared/index'
import channel_manager from './modules/channel_manager/channel_manager'
import access_control from './modules/access_control/access_control'
import company from './modules/company/company'
import website from './modules/website/website'
import tag from './modules/tag/tag'

//import calendar from './modules/property/calendar'

import _get from 'lodash/get'
import _set from 'lodash/set'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    getters: {
        getItem: (state) => (path, default_value) => {
            return _get(state, path, default_value)
        }
    },
    mutations: {
        setItem(state, {path, value}) {
            _set(state, path, value)
        }
    },
    actions: {
        actionSetItem({commit}, payload) {
            commit('setItem', payload)
        },
    },
    modules: {
        auth, user, property, unit, owner, channel_manager, shared, access_control,company, website, tag
    }
})
