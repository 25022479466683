<template>
    <b-card v-if="company" class="property-wrapper">
        <template slot="header">
            <div class="header-caption">{{$t('ORGANIZATION')}}</div>
            <h2 class="property-wrapper-padding">{{ company.name }}</h2>
            <template v-if="isCompanyAccountRoute || isCompanyInvoiceListRoute">
                <header-button-company
                    :company="company">
                </header-button-company>
            </template>
            <Navigation :access_control_context="{key:AC_COMPANY_NAVIGATION}"
                        :context="generalContext"
                        :ac-fetch-key="AC_COMPANY_NAVIGATION"
                        :nav-elements="navElements"></Navigation>
        </template>

        <page-transition transition-type="slide">
            <router-view :company="company"
                         :object_type="OBJECT_TYPE_COMPANY"
                         :object_id="parseInt($route.params.id)"
                         v-cloak
                         @update="company = $event"></router-view>
        </page-transition>
    </b-card>
</template>

<script>
    import Navigation from '@/components/app/navigation/Navigation'
    import PageTransition from '@/components/app/transition/AppTransition'
    import {fetchCompany} from "@/services/company";
    import routeNames from "@/router/routeNames";
    import {OBJECT_TYPE_COMPANY} from "@/shared/constants";
    import HeaderButtonCompany from "@/components/app/header_button/HeaderButtonCompany";
    import {AC_COMPANY_NAVIGATION} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {F_COMPANY_WHITELABEL} from "@/shared/function_permission";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";

    export default {
        name: "Index",
        mixins:[AccessControlComponent],
        components: {
            HeaderButtonCompany,
            Navigation, PageTransition
        },
        data() {
            return {
                company: null,
                name: '',
                navElements: [
                    {
                        route: {name: routeNames.RN_COMPANY_BASIC},
                    },
                    {
                        route: {name: routeNames.RN_COMPANY_CONTACTS},
                    },
                    {
                        route: {name: routeNames.RN_COMPANY_SUBSCRIPTION},
                    },
                    {
                        route: {name: routeNames.RN_COMPANY_INVOICE_LIST},
                    },
                    {
                        route: {name: routeNames.RN_COMPANY_SETTINGS},
                    }
                ],
                OBJECT_TYPE_COMPANY,
                AC_COMPANY_NAVIGATION
            }
        },
        watch: {
            '$route.params.id': {
                handler(val) {
                    fetchCompany(val).then(res => {
                        this.company = res.data
                        this.name = res.data.name
                    })
                },
                immediate: true
            }
        },
        computed: {
            isCompanyAccountRoute() {
                return this.$route.name === routeNames.RN_COMPANY_SUBSCRIPTION
            },
            isCompanyInvoiceListRoute() {
                return this.$route.name === routeNames.RN_COMPANY_INVOICE_LIST
            },
            company_id(){
                return this.$store.getters['user/getCompany']
            },
            generalContext(){
                return {
                    company: this.$store.getters['user/getCompany']
                }
            },
        }
    }
</script>

<style scoped>

</style>
