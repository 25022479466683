<template>
    <div>
        <b-row class="mb-4">
            <b-col xxl="4" xl="6" lg="8" md="12">
                <div class="d-flex justify-content-between align-items-center">
                    <label>{{ label }}</label>
                    <b-row class="md-4">
                        <div class="d-flex align-items-end mb-1">
                            <label>{{$t('DISABLED')}}</label>
                            <b-form-checkbox
                                v-model="disableNewItem"
                                :checked-value="true" :unchecked-value="false">
                            </b-form-checkbox>
                        </div>
                        <app-button button_type="new"
                                    v-if="addNewItem"
                                    @click="addItemToList"
                                    :disabled="disableNewItem">
                            {{ $t('NEW_ITEM') }}
                        </app-button>
                    </b-row>
                </div>
            </b-col>
        </b-row>

        <draggable v-model="linkList_" handle=".handle">
            <div v-for="(item, index) in linkList_" :key="index" v-if="!disableNewItem" class="mb-2">
                <b-row :class="{'mb-4': index === linkList_.length - 1}">
                    <b-col xxl="4" xl="6" lg="8" md="12">
                        <div class="d-flex align-items-center">
                            <b-button block variant="outline-primary" class="text-left"
                                      @click="item.visible=!item.visible">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <i class="fa fa-bars mr-2 handle" style="cursor: move"></i>

                                        <b-badge pill variant="primary" class="mr-1 number-badge">
                                            {{ index + 1 }}
                                        </b-badge>
                                        <template v-if="socialLinks && item.id">
                                            {{ socialMediaObjects[item.id].label }}
                                        </template>
                                        <template v-else>
                                            {{ item.label }}
                                        </template>
                                    </div>
                                    <i :class="`fa fa-caret-${item.visible ? 'up' : 'down'}`"></i>
                                </div>
                            </b-button>

                            <app-button
                                class="ml-2"
                                button_type="delete"
                                :show_text="false"
                                variant="link"
                                @click="linkList_.splice(index, 1)">
                            </app-button>
                        </div>
                    </b-col>
                </b-row>

                <b-collapse v-model="item.visible" :id="`${item}-${index}`">
                    <b-row class="mt-3 ml-0 ml-md-1 mr-md-1">
                        <template v-if="socialLinkList && socialLinkList.length">
                            <b-col xxl="2" xl="2" lg="4" md="12" class="mb-3">
                                <label>{{ $t('PLATFORM') }}</label>
                                <app-select
                                    v-model="item.id" text-field="label" :search-empty-item="false"
                                    :options="socialLinkList">
                                </app-select>
                            </b-col>
                            <b-col xxl="4" xl="6" lg="8" md="12" class="mb-3">
                                <label>Handle</label>
                                <b-input-group
                                    :prepend="item.id ? `${socialMediaObjects[item.id].base_url}/` : null">
                                    <b-form-input v-model="item.value"
                                                  :disabled="!item.id"></b-form-input>
                                </b-input-group>
                            </b-col>
                        </template>
                        <template v-else>
                            <b-col xxl="2" xl="2" lg="4" md="12" class="mb-3">
                                <label>{{ $t('TITLE') }}</label>
                                <b-form-input v-model="item.label"></b-form-input>
                            </b-col>
                            <b-col xxl="4" xl="6" lg="8" md="12" class="mb-3">
                                <label>URL</label>
                                <b-form-input v-model="item.value"
                                              :disabled="!item.label">
                                </b-form-input>
                            </b-col>
                        </template>
                    </b-row>
                </b-collapse>
            </div>
        </draggable>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import AppButton from "@/components/app/AppButton/AppButton";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import FormHeader from "@/components/app/form/FormHeader";
import {cloneDeep} from "lodash";

export default {
    name: "LinkList",
    components: {
        AppSelect,
        AppButton,
        draggable,
        FormHeader,
    },
    props: {
        linkList: {
            type: Array,
            default() {
                return []
            }
        },
        socialLinks: {
            type: Boolean,
            default: true,
        },
        socialLinkList: {
            type: Array,
            default() {
                return []
            },
        },
        addNewItem: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            linkList_: cloneDeep(this.linkList),
            disableNewItem: this.disabled,
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        socialMediaObjects() {
            return this.socialLinkList.reduce((result, current) => {
                result[current.id] = current
                return result
            }, {})
        },
    },
    watch: {
        linkList_: {
            deep: true,
            handler(linkList) {
                this.$emit('update', linkList)
            }
        }
    },
    methods: {
        addItemToList() {
            const item = this.socialLinks ? {id: null, value: null, visible: true} : {}
            const elementId = `${item}-${this.linkList_.length}`
            this.linkList_.forEach(i => i.visible = false)
            this.linkList_.push(item)
            this.$nextTick(() => {
                document.getElementById(elementId).scrollIntoView()
            })
        },
    }
}
</script>
