<template>
    <form @submit.prevent="search" @reset.prevent="reset" class="filter">
        <b-row class="align-items-end">
            <b-col md="3" class="mb-3">
                <label>{{$t('UNIT')}}</label>
                <app-select mode="multiselect" :options="unitList" v-model="filter.unit" set-first></app-select>
            </b-col>

            <b-col md="3" class="mb-3">
                <label>{{$t('STATUS')}}</label>
                <app-select mode="multiselect" :options="statusList" v-model="filter.status" set-first text-field="label"></app-select>
            </b-col>

            <b-col md="6" class="mb-3 d-flex">
                <app-button-submit :inline="true" variant="primary" button_type="search" :loading="loading">
                    {{$t("SEARCH") }}
                </app-button-submit>
                <app-button-reset class="ml-3 pl-2"></app-button-reset>
            </b-col>
        </b-row>
    </form>
</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {EventBus} from "@/shared/EventBus";
    import {cloneDeep} from "lodash";
    import {getUnitList} from "@/services/unit";
    import {
        RM_SUGGESTION_STATUS_ACTIVE,
        RM_SUGGESTION_STATUS_CHANGED,
        RM_SUGGESTION_STATUS_DECLINED
    } from "@/shared/constants";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";

    export default {
        name: "RateManagerSuggestionFilter",
        components: {AppButtonReset, AppSelect, AppButtonSubmit},
        props: {
            reportId: {
                type: Number,
                required: true
            },
            propertyId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                initialFilter: null,
                filter: {
                    report: this.reportId,
                    unit: [],
                    status: [],
                },
                statusList: [
                    {id: RM_SUGGESTION_STATUS_ACTIVE, label: this.$t('ACTIVE')},
                    {id: RM_SUGGESTION_STATUS_CHANGED, label: this.$t('CHANGED')},
                    {id: RM_SUGGESTION_STATUS_DECLINED, label: this.$t('DECLINED')},
                ],
                loading: false,
                unitList: []
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
        },
        methods: {
            search() {
                EventBus.$emit('searchSuggestions', this.filter)
            },
            reset() {
                this.filter = cloneDeep(this.initialFilter)
            },
        },
        created() {
            getUnitList({property_id: this.propertyId}).then(response => {
                this.unitList = response.data.items
            })
        },
        mounted() {
            this.initialFilter = cloneDeep(this.filter)
            EventBus.$on('loadingSuggestions', (value) => {
                this.loading = value
            })
        },
        beforeDestroy() {
            EventBus.$off('loadingSuggestions')
        }
    }
</script>

<style scoped>

</style>
