<template>
    <div>
        <app-no-data v-if="empty_initial_list" :show-tip="false">
            <new-button v-if="checkPermission(R_PROPERTY_NEW)" button_type="new" variant="primary"
                        class="pull-right ml-3" @click="property_aside = true">
                {{ $t('NEW_PROPERTY') }}
            </new-button>
            <app-button
                :access_control_context="{function: C_PROPERTY_IMPORT_FROM_OTA, key: access_control_fetch_key}"
                @AccessControlEvent="addToAccessControlCounter()"
                class="pull-right ml-3"
                @click="importPropertyModalState = true">
                {{ $t("IMPORT_FROM_OTA") }}
            </app-button>
        </app-no-data>
        <event-template-filter v-if="!empty_initial_list" @search="setFilter" :loading="loading">
        </event-template-filter>
        <event-template-table v-if="!empty_initial_list" @busyToggled="toggleBusy" :filter="filter" @linkEvent="openLinkForm"
                              @unlinkEvent="openUnlinkModal" @edit="openTemplateForm" @preview="openPreviewForm">
        </event-template-table>
        <b-modal
            v-model="importPropertyModalState"
            :title="$t('IMPORTANT')">
            <p>{{ $t("IMPORT_FROM_DISTRIBUTION", {"distribution": "Booking.com"}) }}</p>
            <ul>
                <li>{{ $t("IMPORT_PROPERTY_FROM_BOOKING_DISCLAIMER_1") }}</li>
                <li>{{ $t("IMPORT_PROPERTY_ADDITIONAL_COSTS_DISCLAIMER") }}</li>
            </ul>
            <app-button
                @click="importFromDistribution(BOOKING)">
                {{ $t("IMPORT") }}
            </app-button>
            <br/>
            <br/>
            <p>{{ $t("IMPORT_FROM_DISTRIBUTION", {"distribution": "AirBnB"}) }}</p>
            <ul>
                <li>{{ $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_1") }}</li>
                <li>{{
                        $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_2", {
                            "min_image_width": 2048,
                            "min_image_height": 1536
                        })
                    }}
                </li>
                <li>{{ $t("IMPORT_PROPERTY_FROM_AIRBNB_DISCLAIMER_3") }}</li>
                <li>{{ $t("IMPORT_PROPERTY_ADDITIONAL_COSTS_DISCLAIMER") }}</li>
            </ul>
            <app-button
                @click="importFromDistribution(AIRBNB)">
                {{ $t("IMPORT") }}
            </app-button>
            <template slot="modal-footer">
                <template slot="modal-ok">
                </template>
            </template>
        </b-modal>
        <app-aside :widths="['col-sm-12', '40%']" v-model="property_aside">
            <template slot="header">{{ $t('NEW_PROPERTY') }}</template>
            <property-wizard></property-wizard>
        </app-aside>
        <app-aside
            v-model="importPropertyWizardState"
            :widths="['col-lg-12', '100%']">
            <template slot="header">{{ $t('IMPORT_FROM_OTA') }}</template>
            <import-property-wizard
                @importProperty="closeImportWizard"
                @close="closeImportWizard"
                :distribution="importDistribution"
                :entity="importEntity">
            </import-property-wizard>
        </app-aside>
        <app-aside v-model="linkModal"
                   :widths="['col-lg-4','col-sm-12', '40%']">
            <template slot="header">
                <app-object-header :label="$t('EVENT')" :name="eventTemplate && eventTemplate.label"></app-object-header>
            </template>
            <event-linking :event="eventTemplate" @save="linkModal = false"></event-linking>
        </app-aside>
        <app-aside v-model="templateFormState">
            <template #header>
                <app-object-header
                    :name="selectedTemplate ? $t('COMMUNICATION_RULE') : $t('NEW_COMMUNICATION_RULE')">
                </app-object-header>
            </template>

            <event-template-form
                @saved="onSaved"
                :event-template-id="selectedTemplate && selectedTemplate.id">
            </event-template-form>
        </app-aside>

        <app-aside v-model="templatePreviewState">
            <template slot="header">
                <app-object-header :name="$t('PREVIEW')"></app-object-header>
            </template>
            <preview-template-sidebar :previewData="previewData"></preview-template-sidebar>
        </app-aside>

        <app-confirmation-dialog
            :show="unlinkModal"
            :title="$t('EVENT')"
            @cancel="unlinkModal = false"
            @confirm="unlinkTemplate">
            {{ $t('UNLINK_SYSTEM_EMAIL_TEMPLATE_CONFIRMATION_MSG') }}
        </app-confirmation-dialog>
    </div>
</template>

<script>

import {previewEventTemplateMail, unlinkEventTemplate} from "@/services/event";
import {notifySuccess} from "@/shared/plugins/toastr";
import {EventBus, GE_TABLE_REFRESH_SEARCH} from "@/shared/EventBus";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import NewButton from '@/components/app/AppButton/AppButton';
import AppNoData from "@/components/app/AppNoData";
import PropertyWizard from "@/components/property/new/PropertyWizard";
import ImportPropertyWizard from "@/components/property/import/ImportPropertyWizard";
import {C_PROPERTY_IMPORT_FROM_OTA, R_PROPERTY_NEW} from "@/shared/route_permission";
import {AIRBNB, BOOKING, DEFAULT_PAGINATION_LIMIT, LS_PRESETS} from "@/shared/constants";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {AC_PROPERTY_IMPORT} from "@/mixins/AccessControl/AccessControlEnumeration";


export default {
    name: "Index",
    components: {
        "PreviewTemplateSidebar": () => import("@/components/event/PreviewTemplateSidebar"),
        "AppConfirmationDialog": () => import("@/components/app/form/AppConfirmationDialog"),
        "EventTemplateForm": () => import("@/components/event/EventTemplateForm"),
        "EventLinking": () => import("@/components/event/EventLinkingForm"),
        "AppObjectHeader": () => import("@/components/app/AppObjectHeader"),
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
        "EventTemplateTable": () => import("@/components/event/EventTemplateTable"),
        "EventTemplateFilter": () => import("@/components/event/EventTemplateFilter"),
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "AppAside": () => import("@/components/app/form/AppAside"),
        NewButton, AppNoData, PropertyWizard, ImportPropertyWizard
    },
    mixins: [getErrorMessage, AccessControlComponent],
    data() {
        return {
            filter: null,
            loading: false,
            linkModal: false,
            unlinkModal: false,
            eventTemplate: {label: null},
            reservationSidebarState: false,
            templateFormState: false,
            selectedTemplate: null,
            templatePreviewState: false,
            previewData: null,
            R_PROPERTY_NEW,
            C_PROPERTY_IMPORT_FROM_OTA,
            property_aside: false,
            empty_initial_list: false,
            importPropertyModalState: false,
            importPropertyWizardState: false,
            BOOKING,
            AIRBNB,
            access_control_components: 1,
            access_control_fetch_key: AC_PROPERTY_IMPORT,
            importDistribution: null,
            importEntity: null,
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    methods: {
        setFilter(values) {
            this.filter = values
        },
        toggleBusy($event) {
            this.loading = $event
        },
        openUnlinkModal(data) {
            this.eventTemplate = data
            this.unlinkModal = true
        },
        unlinkTemplate() {
            if (!this.eventTemplate && !this.eventTemplate.id) return

            unlinkEventTemplate(this.eventTemplate.id).then(() => {
                notifySuccess()
                this.$emit('saved')
                this.unlinkModal = false
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.loading = false
                this.unlinkModal = false
                this.eventTemplate = {label: null}
                EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
            })
        },
        openLinkForm(data) {
            this.eventTemplate = data
            this.linkModal = true
        },
        openTemplateForm(template = null) {
            this.selectedTemplate = template
            this.templateFormState = true
        },
        onSaved() {
            this.templateFormState = false
            this.selectedTemplate = null
            this.$emit('saved')
        },
        openPreviewForm(data) {
            this.templatePreviewState = true
            previewEventTemplateMail({event_template_id: data.id}).then(res => {
                this.previewData = res.data
            })
        },
        importPropertyTermsConfirmed() {
            this.importPropertyModalState = false
            this.importPropertyWizardState = true
        },
        importFromDistribution(distributionId) {
            this.importDistribution = distributionId
            this.importPropertyTermsConfirmed()
        },
        closeImportWizard() {
            this.importPropertyWizardState = false
            this.importDistribution = null
            this.importEntity = null
            this.$router.replace({"query": null})
        },
    },
    created() {
        let storagePresets = localStorage.getItem(LS_PRESETS)
        if (storagePresets) {
            storagePresets = JSON.parse(storagePresets)

            if (storagePresets.hasOwnProperty('properties') && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                if (storagePresets.properties === 0) {
                    this.empty_initial_list = true
                }
            }
        }
        this.access_control_general_context = {
            company: this.company.id
        }
    }
}
</script>

<style scoped>

</style>
