
export const GET_UNIT_ITEM = "getUnitItem"
export const SET_UNIT_ITEM = "setUnitItem"
export const ACTION_SET_UNIT_ITEM = "actionSetUnitItem"

export const SET_UNIT_DESCRIPTIONS = 'setUnitDescriptions'
export const GET_UNIT_DESCRIPTIONS = 'getunitDescriptions'
export const ACTION_SET_UNIT_DESCRIPTIONS = 'actionSetUnitDescriptions'
export const ACTION_SAVE_UNIT_DESCRIPTIONS = 'actionSaveUnitDescriptions'


export const SET_UNIT_DAMAGE_DEPOSIT = 'setUnitDamageDeposit'
export const GET_UNIT_DAMAGE_DEPOSIT = 'getUnitDamageDeposit'
export const ACTION_SET_UNIT_DAMAGE_DEPOSIT = 'actionSetUnitDamageDeposit'
export const ACTION_SAVE_UNIT_DAMAGE_DEPOSIT = 'actionSaveUnitDamageDeposit'
export const ACTION_DELETE_UNIT_DAMAGE_DEPOSIT = 'actionDeleteUnitDamageDeposit'







