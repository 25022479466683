<template>
    <div>
        <b-row>
            <b-col class="mb-4">
                <template v-if="eventData">
                    <div class="timeline-content-title">
                        <div class="timeline-content-title-id">
                            <small>#{{ eventData.id }}</small>
                        </div>
                        <strong>{{ eventData.action_data.payment_action.label }}</strong>
                    </div>

                    <div class="timeline-content-body">
                        <span class="mr-2">{{ $t('STATUS') }}:</span>
                        <span class="badge">{{ eventData.status.label }}</span>
                    </div>
                </template>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "ReservationAccommodationSchedulerTransactionForm",
    props: {
        eventData: {
            type: Object
        },
        validation: {
            type: Object
        }
    },
    data() {
        return {
            eventData_: null
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
}
</script>


<style lang="scss" scoped>

@import "../../../../assets/scss/variables";

.badge {
    background: $theme-color_primary;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
    color: white;
}
</style>
