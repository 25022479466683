<template>
    <div>
        <UnitPreviewGallery
            :object-id="objectId"
            :document-type-id="documentTypeId"
            document-type=".png,.jpg,.jpeg"
            :copy-component="true"
            :copy-objects="copyList"
            :tag_type="tag_type"
            :tag_permission_v="C_UNIT_GALLERY_TAG_V"
            :object_type="object_type"
            :c_permission_v="c_permission_v"
            :c_permission_e="c_permission_e"
            :c_permission_active="c_permission_active"
            :c_permission_button="c_permission_button"
            :object-name="objectName"
            :ac_key="AC_PROPERTY_GALLERY"
            :ac_key_a="AC_PROPERTY_GALLERY_A"
        >
        </UnitPreviewGallery>
    </div>
</template>

<script>
    import UnitPreviewGallery from '@/components/app/gallery/GalleryProperty'
    import {TYPE_UNIT_PHOTO} from '@/shared/constants'
    import {getUnitPaginatedList} from '@/services/unit/index'
    import {C_UNIT_GALLERY_V, C_UNIT_GALLERY_E, C_UNIT_GALLERY_TAG_V, C_PROPERTY_GALLERY_ACTIVE, C_UNIT_PROPERTY_GALLERY_B} from "@/shared/component_permission";
    import {TAG_TYPE_IMAGE} from "@/shared/constants";
    import {OBJECT_TYPE_DOCUMENT} from "@/shared/constants";
    import {GET_UNIT_ITEM} from '@/store/store_types'
    import {AC_PROPERTY_GALLERY, AC_PROPERTY_GALLERY_A} from "@/mixins/AccessControl/AccessControlEnumeration";

    export default {
        name: "Index",
        components: {
            UnitPreviewGallery
        },
        data() {
            return {
                documentTypeId: TYPE_UNIT_PHOTO,
                c_permission_v: C_UNIT_GALLERY_V,
                c_permission_e: C_UNIT_GALLERY_E,
                c_permission_active: C_PROPERTY_GALLERY_ACTIVE,
                c_permission_button: C_UNIT_PROPERTY_GALLERY_B,
                C_UNIT_GALLERY_TAG_V,
                tag_type: TAG_TYPE_IMAGE,
                object_type: OBJECT_TYPE_DOCUMENT,
                units: [],
                filter: {
                    page: 1,
                    // contigent: 0,
                    property_id: this.$route.params.id
                },
                AC_PROPERTY_GALLERY,
                AC_PROPERTY_GALLERY_A
            }
        },
        computed: {
            objectId() {
                return Number(this.$route.params.unit_id)
            },
            copyList() {
                return this.units.filter(unit => unit.id !== this.objectId)
            },
            objectName() {
                return this.$t("UNIT_GALLERY_OBJECT_NAME", {value: this.$store.getters[GET_UNIT_ITEM]('basic.name')})
            }
        },
        created() {
            getUnitPaginatedList(this.filter).then(response => {
                    this.units = response.data.items
                },
                () => {
                    this.units = []
                })
        }
    }
</script>

<style scoped>

</style>
