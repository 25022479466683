import http from '../../http'
import {
    ACCOMMODATION_RESERVATION_OLD,

    TRANSACTION_URL
} from "@/services/endpoints";


export function getTransactionStatus() {
    return http.request({
        url: TRANSACTION_URL + '/status',
        method: 'GET',
    })
}
export function getTransactionType() {
    return http.request({
        url: TRANSACTION_URL + '/type',
        method: 'GET',
    })
}
export function getTransactions(params){
    return http.request({
        url: TRANSACTION_URL,
        method: 'GET',
        params
    })
}
export function fetchTransaction(transaction_id){
    return http.request({
        url: TRANSACTION_URL+'/'+transaction_id,
        method: 'GET',
    })
}
export function groupExport(data, filename) {


    return http.request({
        url: TRANSACTION_URL + '/export',
        method: 'POST',
        responseType: 'blob',
        data: data
    }).then((response) => {
        let blob = new Blob([response], {type: 'application/xlsx'});

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename + `.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
}
