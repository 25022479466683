<template>
    <b-modal
        scrollable
        v-model="value"
        :title="$t('RECALCULATE_PRICES')"
        :no-close-on-esc="!closeOnEscape"
        :hide-header-close="!showHeaderClose"
        @hide="onHide">
        <b-form>
            <b-row class="mb-3 ml-2">
                <b-col class="mb-3">
                    {{ $t("RECALCULATE_PRICES_DIALOG") }}
                    <b-form-radio-group
                        v-model="recalculate"
                        :options="yesNoOptions"
                        id="radios-calculate_prices"
                        name="radios-calculate_prices"
                        value-field="id"
                        text-field="name"
                        stacked
                        class="main_calendar-custom-radio mt-2">
                    </b-form-radio-group>
                </b-col>
            </b-row>
            <b-row class="mb-3 ml-2" v-if="showOverrideAll">
                <b-col class="mb-3">
                    {{ $t("OVERRIDE_ALL") }}
                    <b-form-radio-group
                        v-model="overrideAll"
                        :options="yesNoOptions"
                        id="radios-override_all"
                        name="radios-override_all"
                        value-field="id"
                        text-field="name"
                        stacked
                        class="main_calendar-custom-radio mt-2">
                    </b-form-radio-group>
                </b-col>
            </b-row>
        </b-form>
        <div slot="modal-footer">
            <app-button
                :loading="loading"
                :disabled="confirmDisabled"
                @click="onConfirm">
                {{ $t("SAVE") }}
            </app-button>
            <app-button
                class="mr-2 ml-2 pl-2"
                :disabled="loading"
                @click="onCancel">
                {{ $t("CANCEL") }}
            </app-button>
        </div>
    </b-modal>
</template>

<script>
import {listHelpers} from "@/mixins/shared/helpers";
import AppButton from "@/components/app/AppButton/AppButton";

export default {
    name: "AppRecalculateRatesModal",
    components: {AppButton},
    mixins: [
        listHelpers,
    ],
    data() {
        return {
            recalculate: null,
            overrideAll: null,
        }
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        closeOnEscape: {
            type: Boolean,
            default: false,
        },
        showHeaderClose: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        showOverrideAll() {
            return !!this.recalculate
        },
        confirmDisabled() {
            return this.recalculate === null || (!!this.recalculate && this.overrideAll === null)
        },
    },
    methods: {
        onConfirm() {
            this.$emit("confirm", {recalculate: !!this.recalculate, override_all: !!this.overrideAll})
        },
        onCancel() {
            this.$emit("cancel")
        },
        onHide(event) {
            if (event.trigger === "backdrop") {
                event.preventDefault()
                this.$emit("input", false)
            }
        }
    },
    watch: {
        loading: {
            handler(value) {
                if (!value) {
                    this.$emit("input", false)
                }
            },
        },
        value: {
            handler() {
                this.recalculate = null
                this.overrideAll = null
            },
        },
    },
}
</script>

<style scoped>

</style>
