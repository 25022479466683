<template>
    <b-dropdown-item
        v-if="isVisible && hasPermissionForRoute"
        :to="item.route">
        <i v-if="item.icon" :class="item.icon" aria-hidden="true"></i>
        {{$t($router.resolve({name: item.route.name}).route.meta.t)}}
    </b-dropdown-item>
</template>

<script>
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";

    export default {
        name: "NavigationDropdownItem",
        mixins: [AccessControlComponent],
        props: {
            item: {
                type: Object
            },
            access_control_context: {
                type: Object,
                default: null
            },
        },
        computed: {
            isVisible() {
                return  this.AccessControlVisible
            },
            isDisabled() {
                return this.AccessControlDisabled
            },
            hasPermissionForRoute() {
                let routerObject = {name: this.item.route.name}
                if (this.item.route.params) {
                    routerObject.params = this.item.route.params
                }
                const permissionId = this.$router.resolve(routerObject).route.meta.permission_id
                if (typeof permissionId === "undefined" || permissionId === null) {
                    this.$emit('show', true)
                    return true
                }

                let permissionChecked = this.checkPermission(permissionId)
                this.$emit('show', permissionChecked)
                return permissionChecked
            },
        }
    }
</script>

<style scoped>

</style>
