<template>
    <div>
        <b-form @submit.prevent="save">
            <b-row>
                <b-col md="6" class="mb-3">
                    <label>{{$t('OWNER')}}</label>
                    <app-select mode="search" :search="searchOwner"
                                @input="mapOwnerToCalculation">
                        <template v-slot:text="data">
                            {{data.item && data.item.full_name}}
                        </template>
                    </app-select>
                </b-col>

                <b-col md="6" class="mb-3">
                    <label>{{$t('MONTH')}}</label>
                    <app-select :options="monthYearOptions" v-model="period" return-type="object"
                                :search-empty-item="false"
                                text-field="label">
                    </app-select>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-badge v-for="(owner, index) in mappedOwnerList" pill
                             class="mr-1"
                             @click="removeMappedOwner(owner.id)">
                        {{owner.full_name}}
                    </b-badge>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="d-flex align-items-end justify-content-end mt-3">
                    <app-button-submit :loading="saving" :disabled="saveDisabled"></app-button-submit>
                </b-col>
            </b-row>
        </b-form>
    </div>

</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import {getOwnerList} from "@/services/owner";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {createOwnerCalculation} from "@/services/finance/owner_calculation";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import moment from "moment"
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";

    const MAX_BACKWARDS_MONTHS = 5

    export default {
        name: "OwnerCalculationGenerateForm",
        components: {AppSelect, AppButtonSubmit},
        mixins: [getErrorMessage],
        data() {
            return {
                mappedOwnerList: [],
                saving: false,
                period: null,
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            monthYearOptions() {
                let momentObject = moment()
                let list = []
                for (let i = 0; i <= MAX_BACKWARDS_MONTHS; i++) {
                    momentObject.subtract({month: 1})
                    list.push({
                        id: i,
                        label: `${momentObject.format('MM')}/${momentObject.year()}`,
                        start: momentObject.startOf('month').format('YYYY-MM-DD'),
                        end: momentObject.endOf('month').format('YYYY-MM-DD')
                    })
                }
                return list
            },
            saveDisabled() {
                return !this.mappedOwnerList.length
            }
        },
        methods: {
            save() {
                this.saving = true
                createOwnerCalculation({
                    company: this.company.id,
                    owner: this.mappedOwnerList.map(owner => owner.id),
                    start_date: this.period.start,
                    end_date: this.period.end
                }).then(() => {
                    notifySuccess()
                    this.$emit('created')
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => this.saving = false)
            },
            searchOwner(value) {
                return getOwnerList({name: value, company: this.company.id, limit: 20})
            },
            mapOwnerToCalculation(owner) {
                if (!this.mappedOwnerList.find(o => o.id === owner.id)) {
                    this.mappedOwnerList.push(owner)
                }
            },
            removeMappedOwner(ownerId) {
                this.mappedOwnerList.splice(this.mappedOwnerList.findIndex(el => el.id === ownerId), 1)
            },
        },
        created() {
            this.period = this.monthYearOptions[0]
        }
    }
</script>

<style scoped>
    .badge {
        font-size: 0.75rem;
        cursor: pointer
    }

    .badge:hover {
        background-color: rgba(217, 23, 23, 0.5)
    }
</style>
