<template>
    <div>
        <b-form v-if="list.length"
                @submit.prevent="save">
            <b-tabs content-class="tab_content" pills vertical>
                <b-tab :title="amenityCategory.label" :key="amenityCategoryIndex1"
                       v-for="(amenityCategory, amenityCategoryIndex1) in list" @click="dataTag(amenityCategory)">
                    <b-row>
                        <b-col v-if="!hasData(amenityCategory.id)">
                            <app-button v-if="checkPermission(C_WEBSITE_AMENITY_NEW_V)" button_type="new" class="mb-4 pull-right" @click="newAmenity(amenityCategory.id)">
                                {{ $t('NEW_AMENITY') }}
                            </app-button>
                        </b-col>
                        <b-col v-if="hasData(amenityCategory.id)">
                            <app-no-data>
                                <app-button v-if="checkPermission(C_WEBSITE_AMENITY_NEW_V)" button_type="new" class="mb-4 pull-right" @click="newAmenity(amenityCategory.id)">
                                    {{ $t('NEW_AMENITY') }}
                                </app-button>
                            </app-no-data>
                        </b-col>
                    </b-row>
                    <b-card-group columns class="column-4">
                        <b-card :key="amenityIndex1" v-for="(amenity, amenityIndex1) in amenityCategory.amenities">
                            <b-list-group class="repeater-2">
                                <b-list-group-item class="list-group-item-title font-weight-bold">
                                    <form-header>{{ amenity.label }}
                                        <app-button
                                            v-if="checkPermission(C_WEBSITE_AMENITY_E)"
                                            variant="link"
                                            button_type="edit"
                                            :show_text="false"
                                            class=" action_button"
                                            @click="openAside(amenity.id,amenityCategory.id)"
                                        >
                                        </app-button>
                                        <b-form-checkbox v-if="amenity.mapping.length > 0"
                                                         :unchecked-value="0"
                                                         :value="1"
                                                         inline
                                                         class="pull-right m-0 p-0"
                                                         v-model="list[amenityCategoryIndex1].amenities[amenityIndex1].value"
                                                         @change="selectAll(amenityCategoryIndex1, amenityIndex1, $event)">
                                        </b-form-checkbox>
                                    </form-header>
                                </b-list-group-item>
                                <b-list-group-item v-for="(amenityMap, mappingIndex) in amenity.mapping"
                                                   :key="mappingIndex">
                                    <b-form-checkbox
                                        @change="addChangeLogByIndex(amenityCategoryIndex1,  mappingIndex, amenityIndex1, $event)"
                                        :id="'amenity_' + amenity.id + '_unit_' + amenityMap.id"
                                        :name="'amenity_' + amenity.id + '_unit_' + amenityMap.id"
                                        :unchecked-value="0"
                                        :value="1"
                                        v-model="list[amenityCategoryIndex1].amenities[amenityIndex1].mapping[mappingIndex].value">
                                        {{ amenityMap.label }}
                                    </b-form-checkbox>
                                </b-list-group-item>
                            </b-list-group>
                        </b-card>
                    </b-card-group>
                </b-tab>
            </b-tabs>
            <b-row>
                <b-col class="mt-4">
                    <app-button-submit v-if="saveButtonVisible" :loading="saving"></app-button-submit>
                </b-col>
            </b-row>
        </b-form>
        <app-aside v-model="aside" :widths="['col-lg-4','col-sm-12', '40%']">
            <template slot="header">{{ $t('AMENITY') }}</template>
            <website-amenity :amenityId="amenityId" :websiteId="website_id" :categoryId="categoryId"
                             @saved="saved"></website-amenity>
        </app-aside>
        <app-no-data v-if="!loading && !list.length" :show-tip="true" :heading="$t('MODULE_NOT_ACTIVE')" :tip="$t('NEED_AMENITY_CATEGORY')">

                <app-button v-if="checkPermission(C_WEBSITE_AMENITY_CATEGORY_NEW_V)" class="col-1" button_type="new" @click="openNewCategoryAside">
                    {{ $t('NEW_AMENITY_CATEGORY') }}
                </app-button>
                <app-button
                    :access_control_context="{function:C_WEBSITE_COPY_AMENITY_CATEGORIES,key:AC_WEBSITE}"
                    @AccessControlEvent="addToAccessControlCounter()"
                    @click="copyAmenitiesDialog">
                    {{ $t('COPY_CATEGORIES') }}
                </app-button>
        </app-no-data>
    </div>
</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import FormHeader from "@/components/app/form/FormHeader";
    import AppAside from "@/components/app/form/AppAside";
    import {getAmenityCategories, mapAmenity, updateAmenity} from "@/services/direct_booking/website";
    import AppButton from "@/components/app/AppButton/AppButton";
    import WebsiteAmenity from "@/components/direct_booking/website/amenities/WebsiteAmenity";
    import {notifySuccess} from "@/shared/plugins/toastr";
    import AppNoData from "@/components/app/AppNoData";
    import {
        C_WEBSITE_AMENITY_NEW_V,
        C_WEBSITE_AMENITY_E,
        C_WEBSITE_COPY_AMENITY_CATEGORIES, C_WEBSITE_AMENITY_CATEGORY_NEW_V
    } from "@/shared/component_permission";
    import {AC_WEBSITE} from "@/mixins/AccessControl/AccessControlEnumeration";
    import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {
        EventBus, GE_CALENDAR_OPEN_AVAILABILITY_SIDEBAR, GE_CALENDAR_OPEN_RESERVATIONS,
        GE_CALENDAR_REFRESH_DATA,
        GE_LOADER_HIDE,
        GE_LOADER_SHOW,
        GE_OPEN_UNIT_WIZARD
    } from "@/shared/EventBus";
    import {cloneDeep} from "lodash";

    export default {
        name: "WebsiteAmenitiesGridList",
        mixins: [AccessControlComponent,getErrorMessage],
        components: {AppNoData, WebsiteAmenity, AppButton, AppButtonSubmit, FormHeader, AppAside },
        props: {
            website_id: Number,
            refresh: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                C_WEBSITE_AMENITY_NEW_V,
                C_WEBSITE_AMENITY_E,
                C_WEBSITE_COPY_AMENITY_CATEGORIES,
                AC_WEBSITE, C_WEBSITE_AMENITY_CATEGORY_NEW_V,
                list: [],
                editList: [],
                loading: true,
                aside: false,
                amenityId: null,
                categoryId: null,
                saving: false,
                emptyData: [],
                saveButtonVisible: true,
                access_control_fetch_key: AC_WEBSITE,
                access_control_components:1,
                overlay: true
            }
        },
        methods: {
            openAside(amenity, category) {
                this.amenityId = amenity
                this.categoryId = category
                this.aside = true;
            },
            saved() {
                this.aside = false;
                this.getData()
            },
            addChangeLogByIndex(amenityCategoryIndex, mappingIndex, amenityIndex, event) {
                let deleted = event === 1 ? 0 : 1
                let amenityMappingObject = this.list[amenityCategoryIndex]['amenities'][amenityIndex]['mapping'][mappingIndex]

                this.$nextTick(() => {
                    let list = this.list[amenityCategoryIndex].amenities[amenityIndex].mapping
                    if (list.some(e => e.value === 0)) {
                        this.list[amenityCategoryIndex].amenities[amenityIndex].value = 0
                    } else {
                        this.list[amenityCategoryIndex].amenities[amenityIndex].value = 1
                    }
                })

                let index = this.editList.findIndex(el => {
                    return el.amenity_id === amenityMappingObject.amenity_id
                })

                if (index > -1) {
                    this.editList[index].deleted = deleted
                } else {
                    this.editList.push({
                        id: amenityMappingObject.id,
                        amenity_eid: Number(amenityMappingObject.amenity_eid),
                        amenity_id: amenityMappingObject.amenity_id,
                        distribution_manager_id: amenityMappingObject.distribution_manager_id,
                        deleted: deleted
                    })
                }
            },
            selectAll(amenityCategoryIndex, amenityIndex, event) {
                this.list[amenityCategoryIndex]['amenities'][amenityIndex].value = 1
                let forLength = this.list[amenityCategoryIndex]['amenities'][amenityIndex]['mapping'].length
                for (let i = 0; i < forLength; i++) {
                    this.addChangeLogByIndex(amenityCategoryIndex, i, amenityIndex, event)
                    this.list[amenityCategoryIndex]['amenities'][amenityIndex].mapping[i].value = event
                }
            },
            newAmenity(data) {
                this.$emit('newAmenity', data)
            },
            getData() {
                this.loading = true
                EventBus.$emit(GE_LOADER_SHOW)
                this.list = [];
                let req = {
                    page: 1,
                    perPage: 999,
                }
                getAmenityCategories(this.website_id,req).then(response => {
                    this.list = response.data.items
                    if (response.data.items.length){
                        this.$store.commit('website/setAmenityCategory', true)
                    }
                    this.saveButtonVisible = response.data.items.length > 0 && response.data.items[0].amenities.length > 0;
                    response.data.items.forEach(el =>{
                        if (el.amenities.length === 0){
                            this.emptyData.push(el.id)
                        }
                    })
                }).catch(error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.loading = false
                    EventBus.$emit(GE_LOADER_HIDE)
                })
            },
            save() {
                this.saving = true
                if (this.editList.length > 0) {
                    mapAmenity(this.website_id, {list: this.editList}).then(() => {
                        notifySuccess()
                        this.getData()
                        this.$emit('saved')
                    }).catch(error => {
                        this.showErrorMessages(error)
                    }).finally(() => this.saving = false)
                }
            },
            hasData(value){
                return this.emptyData.includes(value)
            },
            dataTag(value){
                this.saveButtonVisible = value.amenities.length !== 0;
            },
            openNewCategoryAside(){
                this.$emit('newCategory')
            },
            copyAmenitiesDialog(){
                this.$emit('copyAmenitiesDialog')
            }
        },
        created() {
            this.getData()
        },
        watch: {
            refresh: {
                handler() {
                    this.getData()
                    this.emptyData = []
                }
            }
        },
        mounted(){
            EventBus.$on("reloadWebsiteCategoryTable",() => {
                this.getData()
                this.emptyData = []
            })
        },
        beforeDestroy(){
            EventBus.$off("reloadWebsiteCategoryTable")
        }
    }
</script>

<style scoped>

</style>
