<template>
    <div>
        <b-form @submit.prevent="save">
            <b-row class="pl-0">
                <b-col class="mb-4">
                    <label>{{ $t('NAME') }}</label>
                    <b-input v-model="unitType.label"></b-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="mb-4">
                    <app-button-submit>{{ $t("SAVE") }}</app-button-submit>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    export default {
        name: "WebsiteUnitType",
        components: {AppButtonSubmit},
        props:{
            unitType:{
                type:Object,
                default:null,
            }
        },
        methods:{
            save(){

            }
        }
    }
</script>

<style scoped>

</style>
