import http from '../http'
import {DESCRIPTION_URL} from "@/services/endpoints";

export function getDescriptionList(params) {
    return http.request({
        url: DESCRIPTION_URL,
        method: 'get',
        params
    })
}
