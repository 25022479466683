<template>
    <div class="position-relative">
        <b-table v-if="tableData.length" :hover="true" :responsive="true" :items="tableData" :fields="tableFields" :busy="isBusy">
            <template v-slot:actions="data">
                <app-button v-if="data.item.status.id !== INVOICE_STATUS_CANCELED"
                            variant="link"
                            button_type="download"
                            :show_text="false"
                            :loading="downloadXmlLoading.hasOwnProperty(data.item.id) ? downloadXmlLoading[data.item.id] : false"
                            @click="downloadXml(data.item.id)">
                </app-button>
                <app-button v-if="data.item.status.id !== INVOICE_STATUS_CANCELED"
                            variant="link"
                            button_type="excel"
                            :show_text="false"
                            :loading="downloadXlsLoading.hasOwnProperty(data.item.id) ? downloadXlsLoading[data.item.id] : false"
                            @click="downloadXls(data.item.id)">
                </app-button>
                <app-button variant="link"
                            button_type="edit"
                            :show_text="false"
                            @click="editPaymentOrder(data.item.id)">
                </app-button>
            </template>
        </b-table>
        <app-search-data v-if="!searchDone && !isBusy && !tableData.length"></app-search-data>
        <app-no-data v-if="!tableData.length && searchDone" :show-tip="false"></app-no-data>
        <app-pagination
            :last-page="lastPage"
            :list-count="totalRows"
            :per-page="filter.per_page"
            v-model="currentPage"
            @input="getTableData(filter)">
        </app-pagination>
    </div>
</template>

<script>
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppSearchData from "@/components/app/AppSearchData";
    import AppNoData from "@/components/app/AppNoData";
    import AppPagination from "@/components/app/filter/AppPagination";
    import {isEmpty} from "lodash";
    import {
        getPaymentOrderList,
        paymentOrderDownloadXls,
        paymentOrderDownloadXml
    } from "@/services/finance";
    import {INVOICE_STATUS_CANCELED} from "@/shared/constants";
    import {toast} from "@/shared/plugins/toastr";

    export default {
        name: "PaymentOrderList",
        components: {
            AppButton,
            AppSearchData,
            AppNoData,
            AppPagination
        },
        props: {
            filter: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                INVOICE_STATUS_CANCELED,
                tableData: [],
                tableFields: [
                    {key: 'id', label: this.$i18n.t('ID')},
                    {key: 'name', label: this.$i18n.t('LABEL')},
                    {key: 'amount', label: this.$i18n.t('PAYOUT')},
                    {key: 'invoice_count', label: this.$i18n.t('INVOICES')},
                    {key: 'status.label', label: this.$i18n.t('STATUS')},
                    {key: 'timestamp_created', label: this.$i18n.t('CREATED_AT')},
                    {key: 'actions', label: '', class: 'text-right'}
                ],
                isBusy: false,
                searchDone: false,
                totalRows: 0,
                lastPage: 0,
                downloadXmlLoading: {},
                downloadXlsLoading: {},
            }
        },
        computed: {
            currentPage: {
                get() {
                    return this.filter.page
                },
                set(value) {
                    this.filter.page = value
                }
            }
        },
        watch: {
            filter(value) {
                if (isEmpty(value)) {
                    this.tableData = []
                    this.totalRows = 0
                    this.searchDone = true
                } else {
                    this.getTableData(value)
                }
            }
        },
        methods: {
            getTableData(filter) {
                this.isBusy = true
                getPaymentOrderList(filter).then(response => {
                    this.tableData = response.data.items
                    this.totalRows = response.data.total
                    this.lastPage = response.data.last_page
                    this.isBusy = false
                    this.searchDone = true
                    this.$emit('isLoading', false)
                })
            },
            downloadXml(payment_order_id) {
                this.$set(this.downloadXmlLoading, payment_order_id, true)
                paymentOrderDownloadXml(payment_order_id).then(response => {
                    let blob = new Blob([response], {type: 'text/xml'});
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${payment_order_id}.xml`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }).catch(() => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.DOWNLOAD_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.DOWNLOAD_ERROR.MESSAGE', {value: this.$t('CONTACT_ADMINISTRATOR')}),
                        'type': 'error',
                        'timeout': 2000
                    })
                }).finally(() => {
                    this.$set(this.downloadXmlLoading, payment_order_id, false)
                })
            },
            downloadXls(payment_order_id) {
                this.$set(this.downloadXlsLoading, payment_order_id, true)
                paymentOrderDownloadXls(payment_order_id).then(response => {
                    let blob = new Blob([response], {type: 'application/vnd.ms-excel'});
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${payment_order_id}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }).catch(() => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.DOWNLOAD_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.DOWNLOAD_ERROR.MESSAGE', {value: this.$t('CONTACT_ADMINISTRATOR')}),
                        'type': 'error',
                        'timeout': 2000
                    })
                }).finally(() => {
                    this.$set(this.downloadXlsLoading, payment_order_id, false)
                })
            },
            editPaymentOrder(paymentOrderId) {
                this.$emit('editPaymentOrder', paymentOrderId)
            },
        }
    }
</script>

<style scoped>

</style>
