<template>
    <b-col xs="6" sm="6" md="6" lg="6" class="mt-2">
        <b-card header-class="p-0" body-class="p-0">

        <div slot="header" class="image-item"
             :style="{ backgroundImage: 'url(' + item.link + ')' }">
            <i @click="clickedImg" class="fa fa-search-plus fa-shading fa-fw p-1 m-1 image-zoom" aria-hidden="true"></i>
            <i @click="removeItem()" v-b-popover.hover="item" class="fa fa-shading fa-times fa-fw p-1 m-1 image-delete"
               aria-hidden="true"></i>
        </div>
        </b-card>
    </b-col>
</template>

<script>
    export default {
        name: "guest-photo",
        props:{
            item:{
                type:Object
            }
        },
        methods:{
            removeItem() {
                if (!this.editDisabled) {
                    this.$emit('removeDocument')
                }
            },
            clickedImg() {
                this.$emit('imageClicked')
            },
        }
    }
</script>

<style scoped>
    .image-item {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        width: 100%;
        overflow: hidden;
        min-height: 10rem;
    }

    .image-zoom {
        position: absolute;
        left: 0;
        top: 0;
        color: white;
        cursor: pointer;
    }

    .image-delete {
        position: absolute;
        color: white;
        cursor: pointer;
        right: 0;
        top: 0;
    }

    .document-checkbox {
        position: absolute;
        right: 0;
        bottom: 5px;

    }

    .document-item i {
        color: black;
    }

    .document-item {
        position: relative;
        min-height: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .fa-shading{
        text-shadow: 0 0 8px black;
    }
</style>
