<template>
    <b-form class="filter mb-4" @submit.prevent="search">
        <b-row>
            <b-col cols="12" md="6" lg="2">
                <label>{{$t('PERIOD')}}</label>
                <app-date-range-picker :max-date-diff="90" v-model="filter.dateRange"></app-date-range-picker>
            </b-col>
            <b-col cols="6" sm="3" lg="3" class="d-flex align-items-baseline">
                <app-submit class="filter-submit-wrapper" :inline="true" :loading="loadingButton"
                            variant="primary"
                            button_type="search">{{ $t("SEARCH") }}
                </app-submit>

            </b-col>
            <div class="d-flex justify-content-end availability_calendar__container--load_more" >
                <button v-if="showLoadMore" class="animated fadeIn">
                    <i class="fa fa-forward" aria-hidden="true" @click="loadMoreEvent"></i>
                </button>
            </div>
        </b-row>

    </b-form>
</template>

<script>
    import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
    import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import moment from 'moment'
    export default {
        name: "CalendarFilter",
        props:{
            dateRange:{
                type:Object
            },
            showLoadMore: {
                type:Boolean,
                default:true
            },
            loadingButton:{
                type:Boolean,
                default:false
            }
        },
        data(){
          return{

              filter:{
                  dateRange:{
                      start:null,
                      end:null
                  }
              }
          }
        },
        components: {AppSubmit, AppDateRangePicker},
        computed:{

        },
        methods:{
            search(){
                this.$emit('search', this.filter.dateRange)
            },
            loadMoreEvent() {
                this.$emit('loadAddMonth')
            },


        },
        watch:{
            dateRange:{
                handler(object){
                    this.filter.dateRange = object
                }, immediate:true,
                   deep:true
            },
            loadAdditionalMonths(value) {
                if (value === 0) {
                    return false
                }
                let end = moment(this.filter.dateRange.end).add(value, 'months').format("YYYY-MM-DD")
                let start = moment(end).subtract(1, 'months').add(1, 'day').format("YYYY-MM-DD")
                this.filter.dateRange.start = start
                this.filter.dateRange.end = end

            },
        }

    }
</script>

<style scoped>

</style>
