<template>
    <b-form v-if="list.length" @submit.prevent="save">

        <b-row>
            <b-col class="mb-4">
                <b-form-checkbox
                    inline
                    id="select_all_units"
                    name="select_all_restrictions-1"
                    @change="toggleAll"
                    :value="true"
                    :unchecked-value="false">{{$t("SELECT_ALL")}}
                </b-form-checkbox>
                <b-form-checkbox-group
                    id="unit-checkbox"
                    class="ml-4"
                    stacked
                    value-field="id"
                    text-field="name"
                    v-model="selected"
                    :options="list"
                    name="unit-checkbox"
                ></b-form-checkbox-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <app-submit :disabled="selected.length === 0" :loading="loading"></app-submit>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import {attachUnitsToRoot, getUnitPaginatedList, updateUnit} from "@/services/unit";
    import {toast} from '@/shared/plugins/toastr'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";

    export default {
        name: "UnitAttachToParentForm",
        components: {AppSubmit},
        props: {
            unit: {
                type: Object
            }
        },
        mixins: [getErrorMessage],
        data() {
            return {
                selected: [],
                list: [],
                loading: false
            }
        },
        methods: {
            toggleAll($event) {
                this.selected = []
                if ($event) {
                    this.selected = this.list.map(el => el.id)
                }
            },
            save() {

                this.loading = true
                attachUnitsToRoot(this.unit.id, {units:this.selected}).then(() => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.$emit('updateContingentList')
                }, error => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                })
            }
        },
        created() {

            getUnitPaginatedList({
                page: 1,
                limit: 999,
                contigent: 0,
                root_without_contigent: true,
                property_id: this.unit.property.id
            }).then(response => {
                if (response && response.data && response.data.items) {
                    this.list = response.data.items.filter(el => this.unit.id !== el.id)
                }
            }, () => {
                this.list = []
            })
        }
    }
</script>

<style scoped>

</style>
