import { render, staticRenderFns } from "./MarketplaceRequestForm.vue?vue&type=template&id=b232be82&scoped=true&"
import script from "./MarketplaceRequestForm.vue?vue&type=script&lang=js&"
export * from "./MarketplaceRequestForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b232be82",
  null
  
)

export default component.exports