<template>
    <form @submit.prevent="search" class="filter" autocomplete="off">
        <b-row>
            <b-col md="2" class="mb-3">
                <label>{{$t('DISTRIBUTION')}}</label>
                <app-select :options="distributionList" set-first v-model="filter.distribution"></app-select>
            </b-col>
            <b-col v-if="hasProperty" md="2" class="mb-3">
                <label>{{$t('PROPERTY')}}</label>
                <app-select mode="search" :search-on-create="preloadPropertyList"  set-first v-model="property" :search="searchProperty"></app-select>
            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{$t('RESERVATION_CODE')}}</label>
                <b-input v-model="filter.reservation_code" type="text"></b-input>
            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{$t('TYPE')}}</label>
                <app-select :options="typeListOptions" set-first v-model="filter.from_guest"></app-select>
            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{$t('SCORE')}}</label>
                <app-select :options="scoreListOptions" :value="score" @input="updateScore($event)"></app-select>
            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{$t('STATUS')}}</label>
                <app-select :options="statusList" set-first v-model="filter.status"></app-select>
            </b-col>
            <b-col md="3" class="mb-3">
                <app-submit class="filter-submit-wrapper" :inline="true" :disabled="loading" :loading="loader_submit"  variant="primary"
                            button_type="search">{{ $t("SEARCH") }}
                </app-submit>
                <app-button-reset @click="resetForm" :disabled="loading"  :inline="true"  :loading="loader_reset" class="pl-2 ml-2" ></app-button-reset>
            </b-col>
        </b-row>

    </form>
</template>

<script>
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
    import {DEFAULT_PAGINATION_LIMIT, LS_PRESETS, OBJECT_TYPE_UNIT} from "@/shared/constants";
    import {getPropertyList} from "@/services/property";
    import {getDistributionList} from "@/services/distribution";
    import {getReviewStatusList} from "@/services/review";
    import {cloneDeep} from "lodash";
    export default {
        name: "AccommodationReviewFilter",
        components: {AppButtonReset, AppSubmit, AppSelect},
        props:{
            loading:{
                type:Boolean,
                default:false
            },
            hasProperty: {
                type:Boolean,
                default:true
            },
            distributionList:{
                type:Array
            }
        },
        data(){
            return {
                filter:{
                    property:[],
                    distribution:[],
                    reservation_code:null,
                    from_guest:null,
                    score:null,
                    status:null,
                    object_type:OBJECT_TYPE_UNIT
                },
                property:{
                    id:null,
                    name:null
                },
                score:null,
                initialFilter:null,
                statusList:[],

                scoreListOptions: [
                    {id: 1, name: '1 - 2',interval:{from:1,to:2}},
                    {id: 2, name: '2 - 3',interval:{from:2,to:3}},
                    {id: 3, name: '3 - 4',interval:{from:3,to:4}},
                    {id: 4, name: '4 - 5', interval: {from:4, to:5}},
                    {id: 5, name: '5 - 6', interval: {from:5, to:6}},
                    {id: 6, name: '6 - 7', interval: {from:6, to:7}},
                    {id: 7, name: '7 - 8', interval: {from:7, to:8}},
                    {id: 8, name: '8 - 9', interval: {from:8, to:9}},
                    {id: 9, name: '9 - 10', interval: {from:9, to:10}},
                ],
                typeListOptions:[
                    {id:1, name:'Guest to host'},
                    {id:0, name: 'Host to guest'}
                ],
                distributionOptions:[],
                preloadPropertyList:false,
                reset:false,
                loader_reset:false,
                loader_submit:false
            }
        },
        computed:{
            company() {
                return this.$store.getters['user/getCompany']
            },
        },

        methods:{
            search(){
                this.reset = 0
                this.$emit('search', {...this.filter, company:this.company})
            },
            resetForm(){
                this.reset = 1
                this.score = null
                this.property = this.hasProperty ?  {} : this.property
                this.filter = cloneDeep(this.initialFilter)
                this.$emit('search', {...this.filter,company:this.company})
            },
            searchProperty(value) {
                return getPropertyList({name: value, company_id: this.company}).then(response => {
                    return {data: response.data.items}
                })
            },
            updateScore(value){
                this.score = value
                let scoreObject =  this.scoreListOptions.find(item=> item.id === value)
                this.filter.score_from = scoreObject.interval.from
                this.filter.score_to = scoreObject.interval.to
            }
        },
        watch:{
          loading:{
              handler(value){
                  if(this.reset){
                      this.loader_reset = value
                  } else{
                      this.loader_submit = value
                  }
              }
          },
          property:{
              handler(object){
                  if(object.hasOwnProperty('id') && object.id){
                      this.filter.property = object.id
                  } else {
                      this.filter.property = null
                  }
              }
          },
            '$route.params.id':{
              handler(value){
                  this.filter.property = value
              }, immediate:true
            }
        },
        created() {
            this.initialFilter = cloneDeep(this.filter)
            let storagePresets = localStorage.getItem(LS_PRESETS)
            if (storagePresets) {
                storagePresets = JSON.parse(storagePresets)
                if (storagePresets.hasOwnProperty('properties') && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                    this.preloadPropertyList = true
                }
            }

            getReviewStatusList().then(response => {
                this.statusList = response.data
            })
        }
    }
</script>

<style scoped>

</style>
