<template>
    <b-form @submit.prevent>
        <form-wizard :startIndex="currentIndex" transition="fade-in" color=var(--color-primary) errorColor=var(--color-warning)
                     name="organizationWizard" title="" subtitle=""
                     :nextButtonText="$t('NEXT')"
                     @on-validate="handleNextStep"
                     :backButtonText="$t('PREVIOUS')" :finishButtonText="$t('FINISH')" @on-complete="save">
            <tab-content name="basic" :title="$t('BASIC')"
                         :before-change="() => validate('basic')">
                <b-row>
                    <b-col md="6" lg="4" class="mb-4">
                        <label>{{$t("NAME")}}</label>
                        <b-input v-model.trim="basic.name"></b-input>
                        <validation-description :show="$v.basic.$dirty && $v.basic.name.$invalid">
                            {{$t("FIELD_MANDATORY")}}
                        </validation-description>
                    </b-col>

                    <b-col md="6" lg="4" class="mb-4">
                        <label>{{$t('TYPE')}}</label>
                        <app-select mode="search"
                                    v-model="basic.tenant_type"
                                    :search-on-create="true"
                                    :search="searchTenantType" text-field="label">

                        </app-select>
                        <validation-description :show="$v.basic.$dirty && $v.basic.tenant_type.$invalid">
                            {{$t('FIELD_MANDATORY')}}
                        </validation-description>
                    </b-col>

                    <b-col md="6" lg="4" class="mb-4">
                        <label>{{$t('VAT_NUMBER')}}</label>
                        <b-form-input type="text" v-model.trim="basic.vat_id"></b-form-input>
                        <validation-description :show="$v.basic.$dirty && $v.basic.vat_id.$invalid">
                            {{$t('FIELD_MANDATORY')}}
                        </validation-description>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="6" lg="4" class="mb-4">
                        <label>{{$t('COUNTRY')}}</label>
                        <app-select mode="search"
                                    v-model="basic.country"
                                    :search="searchCountry" :search-clear="true">
                        </app-select>
                        <validation-description :show="$v.basic.$dirty && $v.basic.country.$invalid">
                            {{$t('FIELD_MANDATORY')}}
                        </validation-description>
                    </b-col>

                    <b-col md="6" lg="4" class="mb-4">
                        <label>{{$t('CITY')}}</label>
                        <app-select mode="search"
                                    v-model="basic.city"
                                    :search="searchCity" :search-clear="true">
                        </app-select>
                        <validation-description :show="$v.basic.$dirty && $v.basic.city.$invalid">
                            {{$t('FIELD_MANDATORY')}}
                        </validation-description>
                    </b-col>

                    <b-col md="6" lg="4" class="mb-4">
                        <label>{{$t('ADDRESS')}}</label>
                        <b-form-input
                            type="text"
                            v-model.trim="basic.address">
                        </b-form-input>
                        <validation-description :show="$v.basic.$dirty && $v.basic.address.$invalid">
                            {{$t('FIELD_MANDATORY')}}
                        </validation-description>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="6" lg="4" class="mb-4">
                        <label>{{$t('REGISTRATION_NUMBER')}}</label>
                        <b-form-input
                            type="text"
                            v-model.trim="basic.registration_number">
                        </b-form-input>
                    </b-col>

                    <b-col md="6" lg="4" class="mb-4">
                        <label>{{$t('AGENCY_ID')}}</label>
                        <b-form-input
                            type="text"
                            v-model.trim="basic.agency_id_code">
                        </b-form-input>
                    </b-col>

                    <b-col md="6" lg="4" class="mb-4">
                        <label>{{$t('LEGAL_REPRESENTATIVE')}}</label>
                        <b-form-input
                            type="text"
                            v-model.trim="basic.legal_representative">
                        </b-form-input>
                    </b-col>
                </b-row>

            </tab-content>

            <tab-content name="finance" :title="$t('FINANCE')"
                         :before-change="() => validate('finance')">

                <b-row>
                    <b-col md="6" lg="4" class="mb-4">
                        <label>{{ $t('BILLING_ACCOUNT') }}</label>
                        <b-form-input
                            type="text"
                            v-model.trim="finance.billing_account">
                        </b-form-input>
                        <validation-description :show="$v.finance.$dirty && $v.finance.billing_account.$invalid">
                            {{$t('FIELD_MANDATORY')}}
                        </validation-description>
                    </b-col>

                    <b-col md="6" lg="4" class="mb-4">
                        <label>IBAN</label>
                        <b-form-input
                            type="text"
                            v-model.trim="finance.iban">
                        </b-form-input>
                        <validation-description :show="$v.finance.$dirty && $v.finance.iban.$invalid">
                            {{$t('FIELD_MANDATORY')}}
                        </validation-description>
                    </b-col>

                    <b-col md="6" lg="4" class="mb-4">
                        <label>{{ $t('SWIFT') }}</label>
                        <b-form-input
                            type="text"
                            v-model.trim="finance.bic">
                        </b-form-input>
                    </b-col>

                </b-row>
                <b-row>

                    <b-col  md="6" lg="4" class="mb-4">
                        <label>{{$t('CURRENCY')}}</label>
                        <app-select mode="search" v-model="finance.currency"
                                    :search-on-create="true"
                                    :search="searchCurrency" text-field="code"
                        ></app-select>
                        <validation-description :show="$v.finance.$dirty && $v.finance.currency.$invalid">
                            {{$t('FIELD_MANDATORY')}}
                        </validation-description>
                    </b-col>


                        <b-col  md="4" lg="1" class="mb-4">
                            <label>{{ $t('VAT') }}</label>
                            <b-form-checkbox
                                switch
                                v-model="finance.vat_system"
                                :value="1"
                                :unchecked-value="0">
                            </b-form-checkbox>
                        </b-col>

                        <b-col  md="4" lg="1" class="mb-4" v-if="finance.vat_system">
                            <label>{{$t('VAT_PERCENTAGE')}}</label>
                            <b-input-group prepend="%">
                                <b-form-input
                                    type="number"
                                    step=".01"
                                    v-model="finance.vat_perc">
                                </b-form-input>
                                <validation-description :show="$v.finance.$dirty && $v.finance.vat_perc.$invalid">
                                    <span v-if="!$v.finance.vat_perc.required">{{$t('FIELD_MANDATORY')}}</span>
                                    <span
                                        v-else>{{$t('V.MIN_VALUE', {value: $v.finance.vat_perc.$params.minValue.min})}}</span>
                                </validation-description>
                            </b-input-group>
                        </b-col>

                </b-row>
            </tab-content>

        </form-wizard>
    </b-form>
</template>

<script>

    import {FormWizard, TabContent} from 'vue-form-wizard'
    import {toast} from "@/shared/plugins/toastr";
    import RouteNames from '@/router/routeNames'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import ValidationDescription from '@/components/app/form/AppValidationDescription'
    import {required, minValue} from 'vuelidate/lib/validators'
    import {createCompany, getTenantTypeList} from "@/services/company";
    import {getCityList, getCountries} from "@/services/location";
    import requiredIf from "vuelidate/src/validators/requiredIf";
    import {getCurrencyList} from "@/services/finance";
    import AppSelect from "@/components/app/AppSelect/AppSelect";

    export default {
        name: "CompanyWizard",
        components: {
            AppSelect,
            FormWizard,
            TabContent,
            ValidationDescription
        },
        mixins: [getErrorMessage],
        data() {
            return {
                currentIndex: 0,
                basic: {
                    tenant_type: {
                        id: null,
                        label: null
                    },
                    country: {
                        id: null,
                        name: null
                    },
                    city: {
                        id: null,
                        name: null
                    }
                },
                finance: {
                    vat_system: 0,
                    vat_perc: null,
                    currency: {
                        id: null,
                        code: null
                    }
                }
            }
        },
        watch: {
            'finance.vat_system': {
                handler() {
                    if (!this.finance.vat_system) {
                        this.finance.vat_perc = null
                    } else {
                        this.finance.vat_perc = 0
                    }
                }
            }
        },
        methods: {
            searchTenantType() {
                return getTenantTypeList()
            },
            searchCity(value) {
                if (value) {
                    return getCityList({
                        name: value,
                        country: this.basic.country.id
                    })
                }
            },
            searchCountry(value) {
                return getCountries({
                    name: value
                })
            },
            searchCurrency() {
                return getCurrencyList()
            },
            validate(validation) {
                this.$v[validation].$touch()
                return !this.$v[validation].$invalid
            },
            handleNextStep(isValid, tabIndex) {
                if (!isValid) {
                    toast({
                        'title': this.$t('VALIDATION_FAILED'),
                        'message': null,
                        'type': 'error',
                        'timeout': 6000
                    })
                    return
                }
                this.currentIndex = tabIndex++
                localStorage.setItem('organization_wizard_temp', JSON.stringify(this.$data))
            },
            save() {
                createCompany({...this.basic, ...this.finance}).then(res => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    localStorage.removeItem('organization_wizard_temp')
                    this.$router.push({name: RouteNames.RN_COMPANY_BASIC, params: {id: res.data.id}})
                }, err => {
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(err)}),
                        'type': 'error',
                        'timeout': 6000
                    })
                })
            }
        },
        validations: {
            basic: {
                name: {required},
                tenant_type: {
                    id: {required}
                },
                vat_id: {required},
                country: {
                    id: {required}
                },
                city: {
                    id: {required}
                },
                address: {required},
            },
            finance: {
                billing_account: {required},
                iban: {required},
                vat_perc: {
                    required: requiredIf(function () {
                        return this.finance.vat_system
                    }),
                    minValue: minValue(0)
                },
                currency: {
                    id: {required}
                }
            }
        }
    }
</script>

<style scoped>

</style>
