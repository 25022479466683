<template>
    <div>
        <b-form @submit.prevent="save" autocomplete="off" v-if="formLoaded">
            <b-row>
                <b-col lg="12" class="mb-3" v-for="subscriptionPaymentType in subscriptionPaymentTypeOptions" :key="subscriptionPaymentType.key">
                    <label>{{ subscriptionPaymentType.label }}</label>
                    <app-select :search-empty-item="false"
                                :disabled="!checkPermission(C_COMPANY_SUBSCRIPTION_INVOICE_CREATION_SETUP_E)"
                                :options="getInvoiceCreationSetupOptionsList(subscriptionPaymentType.id)"
                                :value="getInvoiceCreationCurrentSetupFormDataValue(subscriptionPaymentType.key)"
                                @input="(value) => setInvoiceCreationCurrentSetupFormDataValue(subscriptionPaymentType.key, value)"
                                value-field="id"
                                text-field="description"
                    ></app-select>
                    <validation-description :show="$v.formData.$dirty && $v.formData[subscriptionPaymentType.key].$invalid">
                        {{$t('FIELD_MANDATORY')}}
                    </validation-description>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <app-button-submit v-if="checkPermission(C_COMPANY_SUBSCRIPTION_INVOICE_CREATION_SETUP_E)"
                                       class="filter-submit-wrapper"
                                       :inline="true"
                                       variant="primary"
                                       :loading="loading">
                    </app-button-submit>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppDatePicker from "@/components/app/datetime/AppDatePicker";
import AppInputControl from "@/components/app/AppInputControl";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import ValidationDescription from '@/components/app/form/AppValidationDescription'
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {
    fetchSubscriptionInvoiceSetup,
    updateSubscriptionInvoiceSetup,
} from "@/services/company";
import {
    SUBSCRIPTION_PAYMENT_TYPE_PAYMENT_LINK,
    PAYMENT_TYPE_BANK_TRANSFER,
    PAYMENT_TYPE_RECURRING_CARD_PAYMENT,
} from "@/shared/constants";
import {notifySuccess, toast} from "@/shared/plugins/toastr";
import {required} from 'vuelidate/lib/validators'
import {
    C_COMPANY_SUBSCRIPTION_INVOICE_CREATION_SETUP_E,
} from "@/shared/component_permission";

export default {
    name: "InvoiceCreationSetupForm",
    mixins: [getErrorMessage],
    components: {
        AppNumberInput,
        AppInputControl,
        AppDatePicker,
        AppButtonSubmit,
        AppSelect,
        AppButton,
        ValidationDescription,
    },
    props: {
        company: {
            type: Number,
        },
    },
    data() {
        return {
            subscriptionCurrentSetupList: [],
            subscriptionInvoiceSetupList: [],
            subscriptionPaymentTypeOptions: [
                {label: this.$t("PAYMENT_TYPE_PAYMENT_LINK"), id: SUBSCRIPTION_PAYMENT_TYPE_PAYMENT_LINK, key: 'payment_type_payment_link_value'},
                {label: this.$t("PAYMENT_TYPE_BANK_TRANSFER"), id: PAYMENT_TYPE_BANK_TRANSFER, key: 'payment_type_bank_transfer_value'},
                // {label: this.$t("PAYMENT_TYPE_RECURRING_CARD_PAYMENT"), id: PAYMENT_TYPE_RECURRING_CARD_PAYMENT, key: 'payment_type_recurring_card_payment_value'},
            ],
            formData: {
                payment_type_payment_link_value: true,
                payment_type_bank_transfer_value: true,
                payment_type_recurring_card_payment_value: true,
            },
            loading: false,
            subscriptionDataLoaded: false,
            C_COMPANY_SUBSCRIPTION_INVOICE_CREATION_SETUP_E,
        }
    },
    validations: {
        formData: {
            payment_type_payment_link_value: {
                required,
            },
            payment_type_bank_transfer_value: {
                required,
            },
            payment_type_recurring_card_payment_value: {
                required,
            },
        },
    },
    computed: {
        formLoaded() {
            return this.subscriptionDataLoaded
        },
    },
    methods: {
        save() {
            this.$v.$touch();
            if ((this.$v.$error)) {
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('VALIDATION_FAILED'),
                    'type': 'error',
                    'timeout': 6000
                })
                return
            }

            let requestData = {
                setup: this.subscriptionPaymentTypeOptions.map(subscriptionPaymentType => {
                    return {
                        payment_type: subscriptionPaymentType.id,
                        invoice_creation_setup: this.formData[subscriptionPaymentType.key],
                    }
                })
            }

            this.loading = true
            updateSubscriptionInvoiceSetup(this.company, requestData).then(response => {
                notifySuccess()
                this.subscriptionInvoiceSetupList = response.data.hasOwnProperty('invoice_creation_setup') && response.data.invoice_creation_setup ? response.data.invoice_creation_setup : []
                this.subscriptionCurrentSetupList = response.data.hasOwnProperty('current_setup') && response.data.current_setup ? response.data.current_setup : []
                this.setInvoiceCreationCurrentSetupFormData()
            }, error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.loading = false
            })
        },
        fetchInvoiceCreationSetup() {
            this.loading = true
            fetchSubscriptionInvoiceSetup(this.company).then(response => {
                this.subscriptionInvoiceSetupList = response.data.hasOwnProperty('invoice_creation_setup') && response.data.invoice_creation_setup ? response.data.invoice_creation_setup : []
                this.subscriptionCurrentSetupList = response.data.hasOwnProperty('current_setup') && response.data.current_setup ? response.data.current_setup : []
                this.setInvoiceCreationCurrentSetupFormData()
                this.subscriptionDataLoaded = true
            }, () => {
                this.subscriptionDataLoaded = false
            }).finally(() => {
                this.loading = false
            })
        },
        setInvoiceCreationCurrentSetupFormData() {
            this.subscriptionPaymentTypeOptions.forEach(subscriptionPaymentType => {
                const subscriptionPaymentTypeCurrentSetup = this.subscriptionCurrentSetupList.find(setup => setup.payment_type === subscriptionPaymentType.id)
                if (subscriptionPaymentTypeCurrentSetup && subscriptionPaymentTypeCurrentSetup.hasOwnProperty('invoice_creation_setup') && subscriptionPaymentTypeCurrentSetup.invoice_creation_setup) {
                    this.formData[subscriptionPaymentType.key] = subscriptionPaymentTypeCurrentSetup.invoice_creation_setup
                } else {
                    this.formData[subscriptionPaymentType.key] = null
                }
            })
        },
        setInvoiceCreationCurrentSetupFormDataValue(key, value) {
            this.formData[key] = value
        },
        getInvoiceCreationCurrentSetupFormDataValue(key) {
            if (this.formData.hasOwnProperty(key) && this.formData[key]) {
                return this.formData[key]
            }
            return null
        },
        getInvoiceCreationSetupOptionsList(payment_type) {
            return this.subscriptionInvoiceSetupList.filter(setup => {
                return setup.hasOwnProperty('available_payment_type') && setup.available_payment_type.map(paymentType => paymentType.id).includes(payment_type)
            }).map(setup => {
                return {
                    id: setup.id,
                    description: setup.description,
                }
            })
        },
    },
    created() {
        this.fetchInvoiceCreationSetup()
    },
}
</script>

<style scoped>

.custom-radio {
    display: block !important;
    min-height: 25px;
}

</style>
