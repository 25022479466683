<template>
    <div>
        <app-button id="apply_to_all_restriction_popup" name="apply_to_all_restriction_popup" @click="show=true">{{$t('APPLY_TO_ALL')}}</app-button>

        <b-modal  v-model="show" target="apply_to_all_restriction_popup"  custom-class="general-restriction-panel" triggers="click blur" placement="right">

            <b-form>
                <b-row :key="restriction.id" v-for="(restriction) in restrictions">
                    <b-col v-if="restriction.id < 4" class="mb-4">
                        <component
                            :restriction="restriction" v-model="items[restriction.id]"
                            :allowed_values="getAllowedValues(restriction)"
                            :is="restriction.allowed_values.element_type + 'Element'"></component>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col v-if="restriction.id == CLOSED_ON_ARRIVAL || restriction.id == CLOSED_ON_DEPARTURE"
                           v-for="(restriction, restIndex) in restrictions" :key="restIndex">
                        <arrival-departure-days-form-element :select_all="select_all" :key="restriction.id"
                                                             :restriction="restriction"
                                                             :inline="false"
                                                             :allowed_values="getAllowedValues(restriction)"
                                                             v-model="items[restriction.id]"></arrival-departure-days-form-element>
                    </b-col>
                </b-row>

            </b-form>
            <div slot="modal-footer">
                 <app-button @click="applyToAll">{{$t('APPLY')}}</app-button>
            </div>
        </b-modal>
    </div>


</template>

<script>

    import ArrivalDepartureDaysFormElement
        from '@/components/unit/calendar/forms/restriction/ArrivalDepartureDaysFormElement'
    import {
        CLOSED_ON_ARRIVAL,
        CLOSED_ON_DEPARTURE,
        SALES_ENABLED,
        RELEASE_PERIOD,
        SYSTEM_NON_REFUNDABLE_RATE
    } from "@/shared/constants";
    import AppButton from '@/components/app/AppButton/AppButton'
    import SelectRestrictionFormElementNew
        from "@/components/unit/calendar/forms/restriction/SelectRestrictionFormElementNew";

    export default {
        name: "restriction-apply-to-all-drop-down",
        props: {
            selected_rate_plan: {
                type: Object,
            },
            restrictions: {
                type: Array
            }
        },
        data() {
            return {
                show:false,
                CLOSED_ON_ARRIVAL,
                CLOSED_ON_DEPARTURE,
                RELEASE_PERIOD,
                items: [],
                select_all: false,
            }
        },
        components: {
            'selectElement': SelectRestrictionFormElementNew,
            ArrivalDepartureDaysFormElement, AppButton
        },
        methods: {
            getWeekday(number) {
                switch (Number(number)) {
                    case 1:
                        return this.$t('DATE.DAYS.MONDAY')
                    case 2:
                        // code block
                        return this.$t('DATE.DAYS.TUESDAY')
                    case 3:
                        return this.$t('DATE.DAYS.WEDNESDAY')
                    case 4:
                        return this.$t('DATE.DAYS.THURSDAY')
                    case 5:
                        return this.$t('DATE.DAYS.FRIDAY')
                    case 6:
                        return this.$t('DATE.DAYS.SATURDAY')
                    case 7:
                        return this.$t('DATE.DAYS.SUNDAY')
                }
            },
            getAllowedValues(restriction){
                if(restriction.id == CLOSED_ON_ARRIVAL || restriction.id == CLOSED_ON_DEPARTURE){
                    let data = []
                    for (const key in restriction.allowed_values.allowed_values) {

                        data.push({text: this.getWeekday(key), value: key,notEnabled: this.disabled })
                    }
                    return data
                }else {
                    return Object.keys(restriction.allowed_values.allowed_values).map(key => {
                        return {
                            id: Number(key),
                            name: key,
                        }
                    })
                }

            },
            disabled(){
                if(this.selected_rate_plan.rate_plan_category  === SYSTEM_NON_REFUNDABLE_RATE){

                }
            },
            applyToAll() {

                if (this.items.length === 0) {
                    return
                }
                this.items.forEach((value, key) => {
                    if ((key === RELEASE_PERIOD && value === 0) || value) {
                        this.$store.dispatch('pricing/actionApplyToAll', {
                            type: key,
                            selected_rate_plan: this.selected_rate_plan.id,
                            value
                        })
                    }
                })
                this.items = []
                this.select_all = false
                this.show = false
            }
        }
    }
</script>

<style scoped>

    .general-restriction-panel {
        width: 440px;
    }
</style>
