<template>
    <router-link tag="li" class="nav-item nav-dropdown" :to="url" disabled>
        <div class="nav-link nav-dropdown-toggle" @click="handleClick"><i :class="classIcon"></i> {{$t(name)}}</div>
        <ul class="nav-dropdown-items">
            <slot></slot>
        </ul>
    </router-link>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                default: ''
            },
            url: {
                type: String | Object,
                default: ''
            },
            icon: {
                type: String,
                default: ''
            }
        },
        computed: {
            classIcon() {
                return [
                    'nav-icon',
                    this.icon
                ]
            }
        },
        methods: {
            handleClick(e) {
                e.preventDefault()
                e.target.parentElement.classList.toggle('open')
            }
        }
    }
</script>

<style scoped lang="css">
    .nav-link {
        cursor: pointer;
    }
</style>
