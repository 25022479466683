<template>
    <div>
        <b-form @submit.prevent="save" autocomplete="off">
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <app-input-control :error-object="validationErrorObject.name">
                            <template v-slot:input="data">
                                <label>{{$t('NAME')}}</label>
                                <b-form-input v-model="salesPeriod.name"
                                              type="text"
                                              :state="data.state">
                                </b-form-input>
                            </template>
                        </app-input-control>
                    </b-form-group>
                </b-col>
                <b-col xl="6" md="8" sm="12">
                    <b-form-group>
                        <label>{{$t('COUNTRY')}}</label>
                        <app-select mode="search"
                                    v-model="salesPeriod.country"
                                    :search="getCountriesList"
                                    value-field="id"
                                    text-field="name"
                                    :search-clear="true">
                        </app-select>
                    </b-form-group>
                </b-col>
                <b-col xl="6" md="8" sm="12">
                    <b-form-group>
                        <label>{{$t('REGION')}}</label>
                        <app-select :disabled="!salesPeriod.country.id"
                                    mode="select"
                                    v-model="salesPeriod.county.id"
                                    :options="regions"
                                    value-field="id"
                                    text-field="name"
                                    @input="setCountyName($event)">
                        </app-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mb-5">
                <app-button-submit v-if="!periodSetupSaved"
                                   :loading="saving">
                </app-button-submit>
            </b-row>
        </b-form>
        <sales-periods v-if="salesPeriod.id"
                       :salesPeriod="salesPeriod"
                       @deletePeriod="deletePeriod"
                       @periodSaved="periodSaved">
        </sales-periods>
    </div>
</template>

<script>
    import AppInputControl from "@/components/app/AppInputControl";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import SalesPeriods from "@/components/system_templates/sales_periods/SalesPeriods";
    import {getCounties, getCountries} from "@/services/location";
    import {fetchSalesPeriod} from "@/services/sales_periods";
    import {createSystemSalesPeriodSetup, updateSystemSalesPeriodSetup} from "@/services/company";
    import {toast} from "@/shared/plugins/toastr";
    import {SALES_PERIOD_NAME_REQUIRED} from "@/shared/error_codes";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";

    export default {
        name: "SalesPeriodEditForm",
        components: {
            AppInputControl,
            AppSelect,
            AppButtonSubmit,
            SalesPeriods
        },
        mixins: [getErrorMessage],
        data() {
            return {
                salesPeriod: {
                    id: null,
                    name: null,
                    company_id: null,
                    sales_periods: [],
                    county: {
                        id: null,
                        name: null
                    },
                    country: {
                        id: null,
                        name: null
                    }
                },
                regions: [],
                saving: false,
                periodSetupSaved: false,
                validationErrorObject: {
                    name: SALES_PERIOD_NAME_REQUIRED
                }
            }
        },
        props: {
            edit_id: {
                default: null
            }
        },
        watch: {
            'salesPeriod.country.id': {
                handler(value) {
                    if (value) {
                        this.getCountiesList(value)
                    } else {
                        this.salesPeriod.county.id = null
                        this.salesPeriod.county.name = null
                    }
                }
            }
        },
        methods: {
            save() {
                this.saving = true
                let requestObject = {
                    name: this.salesPeriod.name,
                    region_id: this.salesPeriod.county.id
                }
                if (this.edit_id) {
                    updateSystemSalesPeriodSetup(this.salesPeriod.company_id, this.edit_id, requestObject).then(response => {
                        this.setSalesPeriod(response.data)
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })
                        this.$emit('save')
                    }, error => {
                        this.showErrorMessages(error, this.validationErrorObject)
                    }).finally(() => {
                        this.saving = false
                    })
                } else {
                    createSystemSalesPeriodSetup(this.salesPeriod.company_id, requestObject).then(response => {
                        this.setSalesPeriod(response.data)
                        toast({
                            'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                            'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                            'type': 'success',
                            'timeout': 3000
                        })
                        this.periodSetupSaved = true
                        this.$emit('save')
                    }, error => {
                        this.showErrorMessages(error, this.validationErrorObject)
                    }).finally(() => {
                        this.saving = false
                    })
                }
            },
            deletePeriod(salesPeriod) {
                this.setSalesPeriod(salesPeriod)
                this.$emit('save')
            },
            periodSaved(salesPeriod) {
                this.setSalesPeriod(salesPeriod)
                this.$emit('save')
            },
            getCountriesList(value) {
                return getCountries({name: value})
            },
            getCountiesList(value) {
                getCounties({country: value}).then(response => {
                    this.regions = response.data
                })
            },
            setCountyName(e) {
                if (e) {
                    this.salesPeriod.county.name = this.regions.find(el => el.id === e).name
                } else {
                    this.salesPeriod.county.name = null
                }
            },
            setSalesPeriod(value) {
                if (value.county) {
                    this.salesPeriod = value
                } else {
                    this.salesPeriod.id = value.id
                    this.salesPeriod.name = value.name
                    this.salesPeriod.company_id = value.company_id
                    this.salesPeriod.sales_periods = value.sales_periods
                    this.salesPeriod.country = this.$store.getters['user/getCurrentCompany'].country
                }
            }
        }, created() {
            if (this.edit_id) {
                fetchSalesPeriod(this.edit_id).then(response => {
                    this.setSalesPeriod(response.data)
                })
            }
            else {
                this.salesPeriod.company_id = this.$store.getters[`user/getCompany`]
                this.salesPeriod.country = this.$store.getters['user/getCurrentCompany'].country
            }
        }
    }
</script>

<style scoped>

</style>
