<template>
    <b-card v-cloak class="property-wrapper">
        <template slot="header">
            <div class="d-flex justify-content-between flex-column flex-md-row">
                <h2 class="property-wrapper-padding">{{$t('LOYALTY_PROGRAM')}}</h2>
                <app-button
                    v-if="routeIsList"
                    button_type="new"
                    variant="primary"
                    class="pull-right mt-4"
                    @click="newPromoCode">
                    {{ $t("NEW_PROMO_CODE") }}
                </app-button>
                <app-button
                    v-if="routeIsCategory && categoryVisible"
                    button_type="new"
                    variant="primary"
                    class="pull-right mt-4"
                    @click="newCategory">
                    {{ $t("NEW_CATEGORY") }}
                </app-button>
            </div>
            <Navigation
                :nav-elements="navigation">
            </Navigation>
        </template>
        <app-transition transition-type="slide">
            <router-view v-cloak></router-view>
        </app-transition>
    </b-card>
</template>

<script>

import Navigation from "@/components/app/navigation/Navigation";
import AppTransition from "@/components/app/transition/AppTransition";
import AppButton from "@/components/app/AppButton/AppButton";
import routeNames from "@/router/routeNames";
import routeName from "@/router/routeNames";
import {EventBus} from "@/shared/EventBus";

export default {
    name: "Index",
    components:{Navigation, AppTransition, AppButton},
    data(){
        return{
            categoryVisible: false,
            navigation:[
                {route: {name: routeNames.RN_DIRECT_BOOKING_PROMO_CODES_LIST}},
                {route: {name: routeNames.RN_DIRECT_BOOKING_PROMO_CODES_CATEGORY}},
            ]
        }
    },
    computed:{
        routeIsList(){
            return this.$route.name === routeName.RN_DIRECT_BOOKING_PROMO_CODES_LIST
        },
        routeIsCategory(){
            return this.$route.name === routeName.RN_DIRECT_BOOKING_PROMO_CODES_CATEGORY
        }
    },
    methods:{
        newPromoCode(){
            EventBus.$emit('newPromoCode', true)
        },
        newCategory(){
            EventBus.$emit('newPromoCategory', true)
        }
    },
    mounted() {
        EventBus.$on('newCategoryVisible', (value) =>{
            this.categoryVisible = value
        })
    }
}
</script>

<style scoped>

</style>
