<template>
    <form @submit.prevent="search" class="filter" autocomplete="off">
        <b-row>
            <b-col md="2" class="mb-3">
                <label>{{ $t('DISTRIBUTION') }}</label>
                <app-select v-model="filter.distribution"
                            :options="distributionOptions"
                            mode="multiselect">
                </app-select>
            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{ $t('PROPERTY') }}</label>
                <app-select mode="search" :search="searchProperty" set-first v-model="property"></app-select>
            </b-col>
            <b-col md="2" class="mb-3">
                <label>{{ $t('RESERVATION_CODE') }}</label>
                <b-input v-model.trim="filter.reservation_code"></b-input>
            </b-col>

            <b-col lg="2">
                <label>{{ $t('TYPE') }}</label>
                <app-select v-model="filter.type"
                            :options="threadTypeOptions"
                            text-field="label"
                            mode="multiselect">
                </app-select>
            </b-col>

            <b-col lg="2">
                <label>{{ $t('STATUS') }}</label>
                <app-select v-model="filter.status"
                            :options="statusOptions"
                            text-field="label"
                            mode="multiselect">
                </app-select>
            </b-col>
            <b-col xl="2" lg="4" md="6">
                <label>{{ $t("_DATE") }}</label>
                <app-select :options="dateOptions" v-model="dateOptionValue"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("FROM") }}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="fromDate" :max-date="toDate"></app-date-picker>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("TO") }}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="toDate" :min-date="fromDate"
                                 :from-date="fromDate"></app-date-picker>
            </b-col>
            <!--            <b-col lg="2">
                            <label>{{$t('LAST_CHANGE')}}</label>
                            <app-date-range-picker v-model="filter.period"></app-date-range-picker>
                        </b-col>-->


            <b-col md="3" class="mb-3">
                <app-submit class="filter-submit-wrapper" :inline="true" :loading="loading" :disabled="disabledSearch"
                            variant="primary"
                            button_type="search">{{ $t("SEARCH") }}
                </app-submit>
                <app-button-reset @click="resetForm" :disabled="disabledSearch" :loading="loaderReset" :inline="true"
                                  class="pl-2 ml-2"></app-button-reset>

            </b-col>
        </b-row>
    </form>
</template>

<script>
import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {EventBus} from "@/shared/EventBus";
import {
    AIRBNB,
    BOOKING,
    DEFAULT_PAGINATION_LIMIT,
    LS_PRESETS,
    OBJECT_TYPE_MESSAGE,
    MESSAGE_STATUS_CREATED,
    MESSAGE_STATUS_UNREAD
} from '@/shared/constants'

import {getDistributionList} from "@/services/distribution";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {getPropertyList} from "@/services/property";
import {fetchStatusList} from '@/services/status/index'
import {getThreadTypeList} from "@/services/inbox";
import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import AppDatePicker from "@/components/app/datetime/AppDatePicker";

export default {
    name: "InboxFilter",
    components: {AppDatePicker, AppButtonReset, AppDateRangePicker, AppSelect, AppSubmit},
    data() {
        return {
            loading: false,
            property: {id: null, name: null},
            filter: {
                distribution: [],
                status: [MESSAGE_STATUS_CREATED, MESSAGE_STATUS_UNREAD],
                type: [],
                property: [],
                reservation_code: null,

            },
            distributionOptions: [],
            propertyOptions: [],
            statusOptions: [],
            threadTypeOptions: [],
            preloadPropertyList: false,
            loaderReset: false,
            reset: 0,
            dateOptionValue: null,
            toDate: null,
            fromDate: null,
            dateOptions: [
                {id: 'arrival', name: this.$t('CHECK_IN')},
                {id: 'departure', name: this.$t('CHECK_OUT')},
                {id: 'period', name: this.$t('LAST_CHANGE')},
            ],
        }
    },
    methods: {
        search() {
            this.reset = 0

            let request = this.filter
            if(this.dateOptionValue === 'period'){
                const period = {
                    start:this.fromDate,
                    end:this.toDate
                }
               request = {...request,...{period}}
            }
            if(this.dateOptionValue === 'departure'){

                const departure = {
                    start:this.fromDate,
                    end:this.toDate
                }
                request = {...request,...{departure}}
            }
            if(this.dateOptionValue === 'arrival'){
                const arrival = {
                    start:this.fromDate,
                    end:this.toDate
                }
                request = {...request,...{arrival}}
            }
            EventBus.$emit("InboxSearchEvent", request)
        },
        resetForm() {
            this.reset = 1
            this.filter.distribution = []
            this.filter.type = []
            this.filter.property = []
            this.filter.reservation_code = null
            this.fromDate = null
            this.toDate = null
            this.property = {id: null, name: null}
            this.filter.status = []

            EventBus.$emit("InboxSearchEvent", this.filter)
        },
        searchProperty(value) {
            return getPropertyList({name: value, company_id: this.company}).then(response => {
                return {data: response.data.items}
            })
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCompany']
        },
        disabledSearch() {
            return this.loading && this.loaderReset
        },
        dateObject() {
            return this.dateOptionValue ? {
                [`${this.dateOptionValue}_from_date`]: this.fromDate,
                [`${this.dateOptionValue}_to_date`]: this.toDate,
            } : {}
        }
    },
    mounted() {
        EventBus.$on("InboxSearchToggle", (event) => {
            if (this.reset) {
                this.loaderReset = event
            } else {
                this.loading = event
            }
        })
    },
    watch: {
        property: {
            handler(data) {
                if (data.hasOwnProperty('id')) {
                    this.filter.property = data.id
                }
            },
        },
    },
    created() {
        getDistributionList({company: this.company, for_reservation: 1, sorted: 1}).then(res => {
            this.distributionOptions = res.data.filter(el => el.id === AIRBNB || el.id === BOOKING)
        })
        fetchStatusList(OBJECT_TYPE_MESSAGE).then(res => {
            this.statusOptions = res.data
        })

        getThreadTypeList({perPage: 1000}).then(res => {
            this.threadTypeOptions = res.data.items
        })

        let storagePresets = localStorage.getItem(LS_PRESETS)
        if (storagePresets) {
            storagePresets = JSON.parse(storagePresets)
            if (storagePresets.hasOwnProperty('properties') && storagePresets.properties <= DEFAULT_PAGINATION_LIMIT) {
                this.searchProperty().then(response => {
                    this.propertyOptions = response.data
                })
            }
        }

    }
}
</script>

<style scoped>

</style>
