<template>
  <b-form @submit.prevent="updatePromotion" >
    <b-row class="mb-3">
      <b-col sm="6">
        <b-form-group>
          <label>{{$t('NAME')}}</label>
          <b-form-input  id="name" name="name" v-model="name"
                         placeholder="Enter name"
                        @change="$v.name.$touch"
                        :state="$v.name.$dirty && $v.name.$invalid ? false : null"
          ></b-form-input>
          <validation-description :show="$v.name.$dirty && $v.name.$invalid">
            Min input: 5 characters
          </validation-description>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group>
          <label>{{$t('DISCOUNT')}}</label>
          <b-form-input  id="discount" name="discount" v-model="discount"
                         @change="$v.discount.$touch"
                         :state="$v.discount.$dirty && $v.discount.$invalid ? false : null"
          ></b-form-input>
            <validation-description :show="$v.discount.$dirty && $v.discount.$invalid">
                {{$t('FIELD_NUMERIC')}}
            </validation-description>
        </b-form-group>
      </b-col>

      <b-col sm="2">
        <b-form-group>
          <label>{{$t('TYPE')}}</label>
            <app-select :options="discountTypeOptions" v-model="discountType"   @change="$v.discountType.$touch"></app-select>
          <validation-description :show="$v.discountType.$dirty && $v.discountType.$invalid">
            {{$t('FIELD_MANDATORY')}}
          </validation-description>
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group>
          <label>{{$t('MIN_DAYS_BEFORE_CHECK_IN')}}</label>
          <b-form-input  id="min_days_advance" name="min_days_advance" v-model.number="minDaysAdvance"
                         @change="$v.minDaysAdvance.$touch"
                         :state="$v.minDaysAdvance.$dirty && $v.minDaysAdvance.$invalid ? false : null"
          ></b-form-input>
          <validation-description :show="$v.minDaysAdvance.$dirty && $v.minDaysAdvance.$invalid">
            {{$t('FIELD_NUMERIC')}}
          </validation-description>
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group>
          <label>{{$t('MAX_DAYS_BEFORE_CHECK_IN')}}</label>
          <b-form-input  type="number" id="max_days_advance" name="max_days_advance" v-model.number="maxDaysAdvance"
                         @change="$v.maxDaysAdvance.$touch"
                         :state="$v.maxDaysAdvance.$dirty && $v.maxDaysAdvance.$invalid ? false : null"
          ></b-form-input>
          <validation-description :show="$v.maxDaysAdvance.$dirty && $v.maxDaysAdvance.$invalid">
            {{$t('FIELD_NUMERIC')}}
          </validation-description>
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group>
          <label>{{$t('CHECK_IN_FROM_TO')}}</label>
            <app-date-range-picker v-model='checkInDate'></app-date-range-picker>

            <validation-description :show="$v.checkInDate.$dirty && $v.checkInDate.$invalid">
                {{$t('FIELD_MANDATORY')}}
            </validation-description>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6">
        <promotion-submit></promotion-submit>
      </b-col>
      <b-col sm="6">
        <promotion-delete :loading="deleteState" @click="showDeleteDialog=true" class="pull-right"></promotion-delete>
      </b-col>
    </b-row>

    <promotion-delete-dialog :show="showDeleteDialog" @confirm="deletePromotion" @cancel="showDeleteDialog=false"  :delete_title="true">
    </promotion-delete-dialog>
  </b-form>
</template>

<script>

  import PromotionSubmit from '@/components/app/AppButton/AppButtonSubmit'
  import PromotionDelete from '@/components/app/AppButton/AppButtonDelete'
  import PromotionDeleteDialog from '@/components/app/form/AppConfirmationDialog'
  import {toast} from '@/shared/plugins/toastr'
  import {required, minLength, minValue,integer,numeric} from 'vuelidate/lib/validators'
  import ValidationDescription from '@/components/app/form/AppValidationDescription'
  import moment from 'moment'
  import AppSelect from "@/components/app/AppSelect/AppSelect";
  import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
    export default {
      name: "promotion-last-minute-update-form",
      props:['promotionItem'],
      data() {
        return {
          id:this.promotionItem.id,
          name:this.promotionItem.name,
          discount: this.promotionItem.discount,
          discountType:this.promotionItem.discount_type,
          minDaysAdvance:this.promotionItem.min_days_advance,
          maxDaysAdvance:this.promotionItem.max_days_advance,

          showDeleteDialog: false,
          deleteState: false,
          discountTypeOptions: [
            {id: 1, name: '%'},
            {id: 2, name: 'fix'},
          ],
          checkInDate:{
            start: this.promotionItem.check_in_from,
            end: this.promotionItem.check_in_to
          },
            today: moment(new Date()).format('YYYY-MM-DD'),
        }
      },
      computed:{

        inputState() {
          if (!this.checkinDateFrom || !this.checkinDateTo) {
            return {
              type: 'is-danger',
              message: 'Date required.',
            };
          }
          return {
            type: 'is-primary',
            message: '',
          };
        },
      },
      validations:{
        name:{
          required,
          minLength: minLength(5),
        },
        discount:{
          required,integer
        },
        discountType:{
          required,
        },

        minDaysAdvance:{
          required,
          numeric
        },
        maxDaysAdvance:{
          required,
          numeric
        },
          checkInDate:{
            start:{required},
            end:{required}
          }

      },
      components:{
        AppDateRangePicker,
        PromotionSubmit,
        PromotionDelete,
        PromotionDeleteDialog,
        ValidationDescription,
        AppSelect

      },
      methods:{
        updatePromotion() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                toast({
                    'title': "",
                    'message': this.$t('VALIDATION_FAILED'),
                    'type': 'error',
                    'timeout': 6000
                })
                return

            }
          let data = {
            discount: this.discount,
              discount_type: this.discountType,
            min_days_advance: this.minDaysAdvance,
            max_days_advance: this.maxDaysAdvance,
            checkin_date_from:moment(this.checkInDate.start).format('YYYY-MM-DD'),
            checkin_date_to: moment(this.checkInDate.end).format('YYYY-MM-DD'),

          }

          this.$store.dispatch('property/actionPropertyPromotion',{id: this.$route.params.id, promotionId: this.id, data:data})
            .then(() => {
              toast({
                'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                'type': 'success',
                'timeout': 2000
              })
              this.$emit('promotionSaved')
            }, error => {
              toast({
                'title': "Action fail",
                'message': this.getMessages(error),
                'type': 'error',
                'timeout': 2000
              })
            })
        },
        deletePromotion() {
          this.$store.dispatch('property/actionDeletePropertyPromotion', {id: this.$route.params.id, promotionId: this.id})
            .then(() => {
              toast({
                'title': this.$t('NOTIFICATIONS.DELETE_SUCCESS.TITLE'),
                'message': this.$t('NOTIFICATIONS.DELETE_SUCCESS.MESSAGE'),
                'type': 'success',
                'timeout': 2000
              })
              this.$emit('promotionSaved')
              this.showDeleteDialog = false
            }, error => {
              toast({
                'title': "Action fail",
                'message': this.getMessages(error),
                'type': 'error',
                'timeout': 2000
              })
            })
        },

      }
    }
</script>

<style scoped>

</style>
