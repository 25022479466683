<template>
    <div>
        <app-table :items="items" :fields="fields" :busy="loading">
            <template v-slot:status="data">
                {{data.item.status.label}}
            </template>

            <template v-slot:cc="data">
                <small class="text-muted d-block">
                    ####{{data.item.cc_digits}}
                </small>
                {{data.item.cc_type.name}}
            </template>

            <template v-slot:created_date="data">
                <small class="text-muted d-block">
                    {{data.item.created_date | time}}
                </small>
                {{data.item.created_date | date}}
            </template>

            <template v-slot:payment_link="data">
                <app-button variant="link"
                            v-if="data.item.payment_link"
                            button_type="copy"
                            class="action_button ml-2"
                            :show_text="false"
                            @click="copyToClipboard(data.item.payment_link)"
                ></app-button>
            </template>

            <template v-slot:actions="data">
<!--                <app-button variant="link" button_type="edit" :show_text="false" v-if="allowedActions[data.item.id].length"-->
<!--                            @click="$emit('openForm', {transaction: data.item, actions: allowedActions[data.item.id]})">-->
<!--                </app-button>-->

                <template v-if="allowedActions[data.item.id].length">
                    <app-button :key="data.item.id" v-for="action in allowedActions[data.item.id]"
                                variant="link" :icon="icons[action]" :show_text="false"
                                @click="$emit('openForm', {transaction: data.item, action})"></app-button>
                </template>
            </template>
        </app-table>
    </div>
</template>

<script>
    import AppTable from "@/components/app/AppTable";
    import AppAside from "@/components/app/form/AppAside";
    import AppNumberInput from "@/components/app/form/AppNumberInput";
    import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import TransactionForm from "@/components/finance/credit_card/TransactionForm";
    import {F_TRANSACTION_CAPTURE, F_TRANSACTION_REFUND, F_TRANSACTION_VOID} from "@/shared/function_permission";
    import {toast} from "@/shared/plugins/toastr";

    const icons = {
        [F_TRANSACTION_CAPTURE]: 'handshake',
        [F_TRANSACTION_REFUND]: 'undo',
        [F_TRANSACTION_VOID]: 'cancel'
    }

    export default {
        name: "ARTransactionTable",
        components: {TransactionForm, AppSelect, AppButton, AppButtonSubmit, AppNumberInput, AppTable, AppAside},
        props: {
            reservation: {
                type: Object,
                required: true
            },
            allowedActions: {
                type: Object,
                required: true
            },
            tableData: {
                type: Array,
                required: true
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                transactionList: [],
                fields: [
                    {key: 'id', label: this.$t('ID')},
                    {key: 'order_number', label: this.$t('NUMBER')},
                    {key: 'cc', label: this.$t('CC_DATA')},
                    {key: 'status', label: this.$t('STATUS')},
                    {key: 'type.label', label: this.$t('TYPE')},
                    {key: 'amount_formatted', label: this.$t('AMOUNT')},
                    {key: 'created_date', label: this.$t('CREATED_AT')},
                    {key: 'payment_link', label: ''},
                ],
                icons
            }
        },
        computed: {
            company() {
                return this.$store.getters['user/getCurrentCompany']
            },
            items() {
                return this.tableData.map((item, index) => {
                    const prev = this.tableData[index-1]
                    let _rowVariant = item.status.code !== '0000' ? ' table-text-danger' : ''
                    _rowVariant += prev && prev.order_number === item.order_number ? ' table-link-border' : ''
                    return {
                        ...item,
                        _rowVariant,
                        _cellVariants: {id: (prev && prev.order_number === item.order_number) ? 'link' : ''}
                    }
                })
            }
        },
        methods: {
            copyToClipboard(link){
                if (window.clipboardData && window.clipboardData.setData) {
                    return this.clipboardData.setData("Text", link)
                }
                else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
                    let input = document.createElement("input")
                    document.body.appendChild(input)
                    input.visibility = 'hidden'
                    input.value = link
                    input.style.position = "fixed"
                    input.focus()
                    input.select()
                    try {
                        document.execCommand("copy")  // Security exception may be thrown by some browsers.
                        toast({
                            message: this.$t('NOTIFICATIONS.COPY_TO_CLIPBOARD_SUCCESS.MESSAGE'),
                            type: 'success',
                            timeout: 3000
                        })
                        return true
                    }
                    catch (ex) {
                        console.warn("Copy to clipboard failed.", ex)
                        toast({
                            message: this.$t('NOTIFICATIONS.COPY_TO_CLIPBOARD_FAIL.MESSAGE'),
                            type: 'success',
                            timeout: 3000
                        })
                        return false
                    }
                    finally {
                        document.body.removeChild(input)
                    }
                }
            },
        },
        created() {
            let showActions = Object.entries(this.allowedActions).reduce((acc, [key, value]) => {
                return value.length > 0 || acc
            }, false)

            if (showActions) {
                this.fields.push({key: 'actions', label: '', class: 'text-right'})
            }
        }
    }
</script>

<style scoped>

</style>
