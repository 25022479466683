<template>
    <form @submit.prevent="save">
        <b-row class="text-muted">
            <b-col>
                <b-form-group>
                    <b-form-radio-group
                        stacked
                        class="main_calendar-custom-radio fit-content-width"
                        v-model="selected"
                        :options="options"
                        name="room_status">
                    </b-form-radio-group>
                </b-form-group>
            </b-col>

        </b-row>
        <b-row>
            <b-col>
                <app-button-submit
                    :disabled="saveDisabled"
                    :loading="loading">
                </app-button-submit>
            </b-col>
        </b-row>
    </form>
</template>

<script>
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {saveGroupRoomStatus, saveRoomStatusTest} from "@/services/pricing/restriction";
import {EventBus, GE_CALENDAR_LOAD_AVAILABILITY,} from "@/shared/EventBus";
import {notifySuccess} from "@/shared/plugins/toastr";

export default {
    name: "CalendarEditRoomStatusForm",
    components: {AppButtonSubmit},
    props: {
        unit: {
            type: Object,
            default: () => {
                return null
            },
        },
        units: {
            type: Array,
            default: () => {
                return []
            },
        },
        singleUnit: {
            type: Boolean,
            default: true,
        },
        periods: {
            type: Array,
        },
        defaultValues: {
            type: Object,
            default: null,
        },
        allowedWeekday: {
            type: Array,
        },
    },
    mixins: [getErrorMessage],
    data() {
        return {
            selected: null,
            options: [
                {text: this.$t("OPEN_UNIT"), value: 1},
                {text: this.$t("CLOSE_UNIT"), value: 0},
            ],
            loading: false,

        }
    },
    computed: {
        saveDisabled() {
            return this.selected === null || this.periods.length === 0 || this.allowedWeekday.length === 0
        }
    },
    methods: {
        save() {
            this.loading = true
            let request = {
                periods: [],
                value: this.selected,
                allowed_weekday: this.allowedWeekday
            }

            this.periods.forEach(period => {
                request.periods.push({
                    from: period.start,
                    to: period.end
                })
            })

            if (this.singleUnit) {
                this.saveSingleUnitRoomStatus(request)
            } else {
                this.saveMultipleUnitsRoomStatus(request)
            }
        },
        saveSingleUnitRoomStatus(request) {
            this.$set(request, "rate_plan", [this.unit.primary_rate_plan.id])
            saveRoomStatusTest(this.unit.id, request).then(() => {
                EventBus.$emit(GE_CALENDAR_LOAD_AVAILABILITY, {unitId: this.unit.id})
                notifySuccess()
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.loading = false
            })
        },
        saveMultipleUnitsRoomStatus(request) {
            this.$set(request, "unit", this.units)
            saveGroupRoomStatus(request).then(() => {
                this.units.forEach(unitId => {
                    EventBus.$emit(GE_CALENDAR_LOAD_AVAILABILITY, {unitId: unitId})
                })
                notifySuccess()
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.loading = false
            })
        }
    },
    watch: {
        defaultValues: {
            handler(defaultValue) {
                if (defaultValue && defaultValue.hasOwnProperty("room_status")) {
                    this.selected = defaultValue.room_status
                } else {
                    this.selected = null
                }
            },
            deep: true,
            immediate: true,
        },
        periods: {
            handler() {
                if (!this.singleUnit) {
                    this.selected = null
                }
            },
            deep: true,
            immediate: true,
        },
        units: {
            handler(value) {
                if (value.length > 1) {
                    this.selected = null
                }
            },
            deep: true,
            immediate: true,
        },
    },
}
</script>

<style scoped>
.fit-content-width {
    width: fit-content;
}
</style>
