<template>
    <form>
        <b-row class="mt-4">
            <b-col lg="6">
                <label>{{$t('FONT_SOURCE')}}</label>
                <app-select :options="typeOptions"
                            v-model="fontType"
                            text-field="label"
                            :search-empty-item="false"></app-select>
            </b-col>
        </b-row>
        <div v-if="fontType === 1" :key="system_key">
            <b-row class="mt-4">
                <b-col>
                    <app-input-control :error-object="systemValidation.system_name">
                        <template v-slot:input="data">
                            <label>{{$t('NAME')}}</label>
                            <b-input v-model="system.name" :state="data.state"></b-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col>
                    <app-input-control :error-object="systemValidation.system_font">
                        <template v-slot:input="data">
                            <label>{{$t('FONT')}}</label>
                            <app-select :options="systemFonts"
                                        v-model="system.font"
                                        :search-empty-item="false"
                                        text-field="name"
                                        :state="data.state"
                            ></app-select>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <app-button @click="saveSystem" :loading="systemLoading">{{$t('SAVE')}}</app-button>
                </b-col>
            </b-row>
        </div>
        <div v-if="fontType === 2" :key="external_key">
            <b-row class="mt-4">
                <b-col>
                    <app-input-control :error-object="validation.external_name">
                        <template v-slot:input="data">
                            <label>{{$t('NAME')}}</label>
                            <b-input v-model="external.name" :state="data.state"></b-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col>
                    <app-input-control :error-object="validation.source">
                        <template v-slot:input="data">
                            <label>{{$t('SOURCE')}}</label>
                            <app-select v-model="external.source"
                                        :options="sourceOptions"
                                        text-field="name"
                                        :search-empty-item="false"
                                        :set-first="true"
                                        :disabled="true"
                                        :state="data.state"
                            ></app-select>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <app-input-control :error-object="validation.external_font">
                        <template v-slot:input="data">
                            <label>{{$t('FONT')}}</label>
                            <app-select v-model="external.font"
                                        :options="externalFonts"
                                        :disabled="external.source===null"
                                        text-field="label"
                                        mode="search"
                                        return-type="object"
                                        :search="searchExternalFonts"
                                        :state="data.state"
                            ></app-select>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col>
                    <app-input-control :error-object="validation.fallback">
                        <template v-slot:input="data">
                            <label>{{$t('FONT_FALLBACK')}}</label>
                            <app-select :options="systemFonts"
                                        v-model="external.fallback"
                                        :search-empty-item="false"
                                        text-field="name"
                                        :state="data.state"
                            ></app-select>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <app-button @click="saveExternalFont" :loading="externalLoading">{{$t('SAVE')}}</app-button>
                </b-col>
            </b-row>
        </div>
    </form>
</template>

<script>

import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButton from "@/components/app/AppButton/AppButton";
import AppInputControl from "@/components/app/AppInputControl";
import {
    getThemeExternalFonts,
    getThemeFontSources,
    getThemeSystemFonts, saveThemeExternalFonts,
    saveThemeFontFromSystem
} from "@/services/appearance";
import {
    APPEARANCE_EXTERNAL_FONT_MISSING,
    APPEARANCE_FONT_EXTERNAL_NAME_MISSING, APPEARANCE_FONT_FALLBACK_MISSING,
    APPEARANCE_FONT_NAME_NOT_FOUND, APPEARANCE_FONT_SOURCE_MISSING,
    APPEARANCE_SYSTEM_FONT_NOT_FOUND
} from "@/shared/error_codes";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {notifySuccess} from "@/shared/plugins/toastr";

export default {
    name: "AppearanceNewFontForm",
    components:{AppSelect, AppButton, AppInputControl},
    mixins:[getErrorMessage],
    data(){
        return {
            typeOptions:[
                {id: 1, label: this.$t('SYSTEM')},
                {id: 2, label: this.$t('EXTERNAL')}
            ],
            fontType: null,
            systemFonts:[],
            externalFonts: [],
            sourceOptions: [],
            systemLoading: false,
            externalLoading: false,
            system:{
                name: null,
                font: null
            },
            external:{
                source: null,
                fallback: null,
                name: null,
                font: null
            },
            validation:{
                external_name: APPEARANCE_FONT_EXTERNAL_NAME_MISSING,
                fallback: APPEARANCE_FONT_FALLBACK_MISSING,
                source: APPEARANCE_FONT_SOURCE_MISSING,
                external_font: APPEARANCE_EXTERNAL_FONT_MISSING
            },
            systemValidation:{
                system_name: APPEARANCE_FONT_NAME_NOT_FOUND,
                system_font: APPEARANCE_SYSTEM_FONT_NOT_FOUND,
            },
            system_key: 0,
            external_key: 0
        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods:{
        saveSystem(){
            this.systemLoading = true
            let req = {
                ...this.system,
                company_id: this.company.id
            }
            saveThemeFontFromSystem(req).then(response => {
                notifySuccess()
                this.$emit('font-saved')
                this.systemLoading = false
            }, error => {
                this.systemLoading = false
                this.showErrorMessages(error, this.systemValidation)
            })
        },
        saveExternalFont(){
            this.externalLoading = true
            let req = {
                ...this.external,
                company_id: this.company.id
            }
            saveThemeExternalFonts(req).then(response => {
                notifySuccess()
                this.externalLoading = false
                this.$emit('font-saved')
            }, error => {
                this.externalLoading = false
                this.showErrorMessages(error, this.validation)
            })
        },
        searchExternalFonts(value){
            return getThemeExternalFonts({source_id: this.external.source, name: value}).then(response => {
                return {data: response.data}
            })
        }
    },
    created() {
        getThemeSystemFonts().then(response => {
            this.systemFonts = response.data
        })
        getThemeFontSources().then(response => {
            this.sourceOptions = response.data
        })
    },
    watch:{
        fontType:{
            handler(){
                this.external.fallback = null
                this.external.name = null
                this.external.font = null
                this.system.name = null
                this.system.font = null
                this.system_key += 1
                this.external_key += 1
            }
        }
    }
}
</script>

<style scoped>

</style>
