import http from '../http'
import qs from 'qs'
import {
    API_URL,
    UNIT_URL,
    UNIT_LIST_PAGINATE_URL,
    UNIT_AMENITY_URL,
    UNIT_COMMISSIONS_URL,
    UNIT_DAMAGE_DEPOSIT_URL,
    UNIT_DESCRIPTIONS_URL,
    UNIT_FEES_URL, UNIT_FEE_DELETE_URL,
    UNIT_COPY_COMMISSIONS_URL,
    UNIT_LIST_URL, UNIT_STATUS_URL, UNIT_AVAILIBILITY_URL, UNIT_ID_URL
} from '@/services/endpoints'

export function getUnit(id) {
    return http.request({
        url: UNIT_URL + '/' + id,
        method: 'get',
    })
}

export function updateUnit(id, data) {
    return http.request({
        url: UNIT_URL + '/' + id,
        method: 'put',
        data: data
    })
}
export function updateUnitKeyPickUp(id, data) {
    return http.request({
        url: UNIT_URL + '/' + id + '/key-pickup',
        method: 'put',
        data: data
    })
}

export function deleteUnit(id) {
    return http.request({
        url: UNIT_URL + '/' + id,
        method: 'delete'
    })
}

export function createUnit(data) {
    return http.request({
        url: UNIT_URL,
        method: 'post',
        data: data
    })
}

export function getUnitRoomAndInventoryOptions(id) {
    return http.request({
        url: UNIT_AMENITY_URL(id) + '-options',
        method: 'get',
    })
}

export function getUnitRoomAndInventory(id) {
    return http.request({
        url: UNIT_AMENITY_URL(id),
        method: 'get',
    })
}

export function createUnitRoom(id, data) {
    return http.request({
        url: UNIT_AMENITY_URL(id),
        method: 'post',
        data: data
    })
}

export function updateUnitRoom(id, room_id, data) {
    return http.request({
        url: UNIT_AMENITY_URL(id) + '/' + room_id,
        method: 'put',
        data: data
    })
}

export function deleteUnitRoom(id, room_id) {
    return http.request({
        url: UNIT_AMENITY_URL(id) + '/' + room_id,
        method: 'delete'
    })
}

export function createUnitInventory(id, room_id, data) {
    return http.request({
        url: UNIT_AMENITY_URL(id) + '/' + room_id + '/inventory',
        method: 'post',
        data: data
    })
}

export function updateUnitInventory(id, room_id, inventoryId, data) {
    return http.request({
        url: UNIT_AMENITY_URL(id) + '/' + room_id + '/inventory/' + inventoryId,
        method: 'put',
        data: data
    })
}

export function deleteUnitInventory(id, room_id, inventoryId) {
    return http.request({
        url: UNIT_AMENITY_URL(id) + '/' + room_id + '/inventory/' + inventoryId,
        method: 'delete'
    })
}


export function getUnitDescriptionList(id) {
    return http.request({
        url: UNIT_DESCRIPTIONS_URL(id),
        method: 'get',
    })
}

export function getUnitCommissions(id, searchParams) {
    return http.request({
        url: UNIT_COMMISSIONS_URL(id),
        method: 'post',
        data: searchParams,
    })
}

export function getUnitFeeList(id) {
    return http.request({
        url: UNIT_FEES_URL(id),
        method: 'get',
    })
}

export function setUnitFee(id, postData) {
    return http.request({
        url: '/adminapi/unit/' + id + '/fee',
        method: 'put',
        data: postData,
    })
}

export function deleteUnitFee(id, postData) {
    return http.request({
        url: UNIT_FEE_DELETE_URL(id),
        method: 'delete',
        data: postData
    })
}

export function getUnitDamageDeposit(id) {
    return http.request({
        url: UNIT_DAMAGE_DEPOSIT_URL(id),
        method: 'get'
    })
}

export function setUnitDescriptions(id, postData) {
    return http.request({
        url: '/adminapi/unit/' + id + '/descriptions',
        method: 'put',
        data: postData,
    })
}

export function setUnitDamageDeposit(id, postData) {
    return http.request({
        url: '/adminapi/unit/' + id + '/damage-deposit',
        method: 'put',
        data: postData,
    })
}

export function setUnitCommissions(id, postData) {
    return http.request({
        url: '/adminapi/unit/' + id + '/commissions',
        method: 'put',
        data: postData
    })
}

export function getUnitTypeList(id) {
    return http.request({
        url: '/adminapi/property/' + id + '/unit-type-list',
        method: 'get'
    })
}

export function copyUnitCommissions(id, postData) {
    return http.request({
        url: UNIT_COPY_COMMISSIONS_URL(id),
        method: 'post',
        data: postData
    })
}

export function deleteUnitDamageDeposit(id, postData) {
    return http.request({
        url: UNIT_DAMAGE_DEPOSIT_URL(id),
        method: 'delete',
        data: postData
    })
}

export function getUnitList(data) {
    return http.request({
        url: UNIT_LIST_URL,
        method: 'post',
        data: data
    })
}

export function getUnitPaginatedList(postData) {
    return http.request({
        url: UNIT_LIST_PAGINATE_URL,
        method: 'post',
        data: postData
    })
}

export function getStatuses(id) {
    return http.request({
        url: UNIT_STATUS_URL(id),
        method: 'get'
    })
}

export function setStatus(id, status_transition_id, data) {
    return http.request({
        url: UNIT_STATUS_URL(id) + '/' + status_transition_id,
        method: 'post',
        data: data
    })
}

export function getUnitLos() {
    return http.request({})
}

export function getUnitAvailibility(id, data) {
    return http.request({
        url: UNIT_AVAILIBILITY_URL(id),
        method: 'get',
        params: data
    })
}

export function setUnitAvailibility(data) {
    return http.request({
        url: API_URL + '/unit/calendar-availability',
        method: 'post',
        data: data
    })
}

export function setUnitBulkAvailabilityTest(data) {
    return http.request({
        url: API_URL + '/unit/bulk-availability',
        method: 'post',
        data: data
    })
}

export function getUnitPrice(id, data) {
    return http.request({
        url: UNIT_ID_URL(id) + '/price',
        method: 'get',
        params: data
    })
}

export function setUnitPrice(id, data) {
    return http.request({
        url: UNIT_ID_URL(id) + '/cm-price',
        method: 'post',
        data: data
    })
}

export function getUnitRestrictions(id, data) {
    return http.request({
        url: UNIT_ID_URL(id) + '/restriction',
        method: 'get',
        params: data
    })
}

export function setUnitRestrictions(id, data) {
    return http.request({
        url: UNIT_ID_URL(id) + '/cm-restriction',
        method: 'post',
        data: data
    })
}
export function setUnitRestrictionsTest(id, data) {
    return http.request({
        url: UNIT_ID_URL(id) + '/cm-restriction-test',
        method: 'post',
        data: data
    })
}

export function setContingentUnits(id, data) {
    return http.request({
        url: UNIT_ID_URL(id) + '/contingent',
        method: 'post',
        data: data
    })
}

export function createSoloUnitType(unitId, postData) {
    return http.request({
        url: UNIT_ID_URL(unitId) + '/solo-type',
        method: 'post',
        data: postData
    })
}

export function updateSoloUnitType(id, unitId, postData) {
    return http.request({
        url: UNIT_ID_URL(unitId) + '/solo-type/' + id,
        method: 'put',
        data: postData
    })
}

export function getSoloUnitType(unitId) {
    return http.request({
        url: UNIT_ID_URL(unitId) + '/solo-type',
        method: 'get',
    })
}


export function getUnitCalendarValues(unitId, data) {
    return http.request({
        url: UNIT_ID_URL(unitId) + '/calendar/values',
        method: 'post',
        data: data
    })
}
export function validationCreateUnit(postData) {

    return http.request({
        url: UNIT_URL+'-validation',
        method: 'post',
        data: postData
    })
}

export function attachUnitsToRoot(unitId, data) {

    return http.request({
        url: UNIT_ID_URL(unitId)+'/attach-units-to-root',
        method: 'post',
        data: data
    })
}

export function dettachUnit(unitId, data) {

    return http.request({
        url: UNIT_ID_URL(unitId)+'/dettach-unit',
        method: 'post',
        data
    })
}

export function updateName(unitId, data) {

    return http.request({
        url: UNIT_ID_URL(unitId)+'/update-name',
        method: 'put',
        data: data
    })
}

export function fetchCalendarData(unitId, params) {
    return http.request({
        url: UNIT_ID_URL(unitId)+'/calendar',
        method: 'get',
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    })
}
export function fetchAvailability(postData) {

    return http.request({
        url: UNIT_URL+'/availability',
        method: 'post',
        data: postData
    })
}
export function fetchNote(id,params){
    return http.request({
        url: UNIT_URL+'/'+id+'/note',
        method: 'get',
        params
    })
}
export function saveGroupPrices(data) {
    //Room Status
    return http.request({
        url: UNIT_URL + '/bulk-cm-price',
        method: 'post',
        data,
    })
}
export function saveGroupPricesRelative(data){
    return http.request({
        url: UNIT_URL + '/bulk-cm-price-relative',
        method: 'post',
        data,
    })
}
export function getUnitAvailableList(params){
    return http.request({
        url: UNIT_URL + '-available-list',
        method: 'get',
        params,
    })
}
export function getUnitRevenueManagerSetup(unit_id){
    return http.request({
        url:UNIT_ID_URL(unit_id) + '/revenue-manager-setup',
        method: 'get'
    })
}
export function setRevenueManagerSetup(unit_id,data){
    return http.request({
        url:UNIT_ID_URL(unit_id) + '/revenue-manager-setup',
        method: 'put',
        data
    })
}
export function getInventoryLog(data){
    return http.request({
        url: UNIT_URL + '/inventory-log',
        method: 'post',
        data,
    })
}
export function getInventoryLogByDate(date, params){
    return http.request({
        url: UNIT_URL + '/inventory-log/'+date,
        method: 'get',
        params,
    })
}

export function getAllUnitTypeList(){
    return http.request({
        url: UNIT_URL + '/unit-type',
        method: 'get'
    })
}

export function savePriceForAllLosesAndOccupancies(data) {
    return http.request({
        url: UNIT_URL + '/bulk-cm-price-ranges',
        method: 'post',
        data,
    })
}
