<template>
    <div>
        <event-tabs :event-data="eventData"
                    :event-template-type-id="EVENT_TEMPLATE_TYPE_CONFIGURATION"
                    :show-property-template="true"
                    :property-id="propertyId"
                    :isTemplate="false"
                    :loading="loading" @saved="getData">
        </event-tabs>
    </div>
</template>

<script>
import {CONTACT_CHANNEL_E_MAIL, EVENT_TEMPLATE_TYPE_CONFIGURATION} from "@/shared/constants";
import {getGroupedEventTemplates} from "@/services/event";

export default {
    name: "Index",
    components: {
        "EventTabs": () => import("@/components/event/EventTabs")
    },
    data() {
        return {
            eventData: [],
            propertyId: null,
            EVENT_TEMPLATE_TYPE_CONFIGURATION,
            loading: true
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    watch: {
        '$route.params.id': {
            handler(value) {
                this.propertyId = Number(value)
            },
            immediate: true
        }
    },
    methods: {
        getData() {
            this.loading = true
            getGroupedEventTemplates({
                company: this.company.id,
                template_type: EVENT_TEMPLATE_TYPE_CONFIGURATION,
                property: this.propertyId,
                contact_channel: CONTACT_CHANNEL_E_MAIL
            }).then(response => {
                this.eventData = response.data
            }).finally(() => {
                this.loading = false
            })
        }
    },
    created() {
        this.getData()
    }
}
</script>

<style scoped>

</style>
