export const getValidationRule = {
    methods: {
        inputStateRule(element){
            return this.$v[element].$dirty && this.$v[element].$invalid ? false : null
        },
        descriptionStateRule(element){
           return this.$v[element].$dirty && this.$v[element].$invalid
        }
    }
}
