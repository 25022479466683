<template>
    <tr>
        <td style="width: 80%">
            <app-date-range-picker :format="format" v-model="selectedPeriod.range" :disabled="disabled"
                                   :min-date="minDate" :max-date="maxDate"
                                   :disabled-dates="disabledDatesList"></app-date-range-picker>
        </td>
        <td class="text-right" style="width: 20%">
            <AppButton
                button_type="delete"
                :show_text="false"
                @click="deletePeriod"
                variant="link">
            </AppButton>
        </td>
    </tr>
</template>

<script>

    import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
    import AppButton from "@/components/app/AppButton/AppButton";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_E, C_UNIT_CALENDAR_PERIODS_E} from "@/shared/component_permission";
    import {cloneDeep} from "lodash";
    import moment from 'moment'

    export default {
        name: "PeriodItem",
        props: {
            period: {
                type: Object,
                default: () => {
                    return {
                        id: null,
                        start: null,
                        end: null,
                        deleted: null
                    }
                }
            },
            disabledDates: {
                type: Array,
                default: null,
            },
            disabled: {
                type: Boolean,
                default: false
            },
            minDate: {
                default: null
            },
            maxDate: {
                default: null
            },
            format:{
                type:String,
                default:null
            }
        },
        components: {
            AppDateRangePicker,
            AppButton,
        },
        mixins: [getErrorMessage],
        data() {
            return {
                currentYear: moment().format('Y'),
                selectedPeriod: {
                    id: null,
                    range: {
                        start: null,
                        end: null
                    },
                    deleted: null
                },
                saving: false,
                disabledDatesList: null,
                C_UNIT_CALENDAR_PERIODS_E,
                C_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS_E
            }
        },
        methods: {
            deletePeriod() {
                this.selectedPeriod.deleted = 1
                this.$emit('delPeriod', this.selectedPeriod)
            },

            // invoka se na input ali resetiralo je glavni date picker na kalendaru pa je disejblan
            // updateDateRange(){
            //     this.$emit('updatePeriodList', this.selectedPeriod)
            // }
        },

        watch: {
            period: {
                handler(value) {
                    if (value) {
                        if (this.period.start && this.period.end) {
                            this.selectedPeriod = cloneDeep({
                                id: this.period.id,
                                range: {
                                    start: new moment(this.period.start, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                                    end: new moment(this.period.end, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                                },
                                deleted: this.period.deleted
                            })
                        }
                    }
                },
                deep: true,
                immediate: true
            },
            'selectedPeriod.range':{
              handler(newValue, oldValue){
                  this.$emit('updatePeriodList', {oldValue,newValue})
              }
            },
            disabledDates: {
                handler(value) {
                    this.disabledDatesList = cloneDeep(value)
                },
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>
