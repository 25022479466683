<template>
<div>
  <table>
    <tbody>
    <tr>
      <td></td>
    </tr>
    </tbody>
  </table>
</div>
</template>

<script>
    export default {
        name: "unit-bulk-calendar-rm-statistics"
    }
</script>

<style scoped>

</style>
