<template>
    <div>
        <form-wizard v-if="setupData && !websiteCreated" ref="wizard" :startIndex="0" transition="fade-in" color=var(--color-primary) errorColor=var(--color-warning)
                     title="" subtitle=""
                     @on-complete="onComplete">
            <tab-content :title="$t('GENERAL')" :beforeChange="() => validateStep('general')">
                <website-wizard-general-form
                    :setup-data="setupData"
                    ref="general"
                    @update="updateFormData">
                </website-wizard-general-form>
            </tab-content>

            <tab-content :title="$t('KEY_VISUALS')" :beforeChange="() => validateStep('key_visuals')">
                <website-wizard-key-visuals-form
                    :setup-data="setupData"
                    :supported-languages="supportedLanguages"
                    ref="key_visuals"
                    @update="updateFormData">
                </website-wizard-key-visuals-form>
            </tab-content>

            <tab-content :title="$t('CONTACT')" :beforeChange="() => validateStep('contact')">
                <website-wizard-contact-form
                    :setup-data="setupData"
                    ref="contact"
                    @update="updateFormData">
                </website-wizard-contact-form>
            </tab-content>

            <tab-content :title="$t('UNIT_ACTIVATION_RULES')">
                <marketplace-unit-activation-rules @update="updateFormData">
                </marketplace-unit-activation-rules>
            </tab-content>

            <button class="btn btn-outline-primary" slot="prev">{{ $t('PREVIOUS') }}</button>
            <button class="btn btn-primary" slot="next" :disabled="loading">
                <i v-if="loading"
                   class="mr-1 fa fa-spinner fa-spin fa-fw"
                   aria-hidden="true">
                </i>{{ $t('NEXT') }}
            </button>
            <button class="btn btn-primary" slot="finish" :disabled="loading">
                <i v-if="loading"
                   class="mr-1 fa fa-spinner fa-spin fa-fw"
                   aria-hidden="true">
                </i>
                {{ $t('FINISH') }}
            </button>
        </form-wizard>

        <app-wizard-complete class="wizard-complete" v-if="websiteCreated" @close="$emit('close', {reload: true})" :showCloseButton="false">
            <template v-slot:title>
                <b-img class="search_init_icon" src="/img/icons/document_icon.png"></b-img>
            </template>

            <template v-slot:body>
                <h4 style="padding-top: 2rem" class="text-primary font-weight-light">{{$t('WEBSITE_CREATED')}}</h4>
            </template>

            <template v-slot:subtitle>
                <h6 class="font-weight-light mt-2"><b-link class="mt-2" :href="`https://${website.slug}`" target="_blank">{{`https://${website.slug}`}}</b-link></h6>
            </template>

            <template v-slot:actions>
                <app-button @click="goToUnitActivation">{{ $t('ACTIVATE_UNITS') }}</app-button>
                <!--                <app-button @click="goToPageList" class="ml-3">{{$t('VIEW_PAGES')}}</app-button>-->
            </template>
        </app-wizard-complete>
    </div>

</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import WebsiteWizardGeneralForm from "@/components/direct_booking/website/new/WebsiteWizardGeneralForm";
import WebsiteWizardKeyVisualsForm from "@/components/direct_booking/website/new/WebsiteWizardKeyVisualsForm";
import MarketplaceUnitActivationRules from "@/components/direct_booking/marketplace/MarketplaceUnitActivationRules";
import {
    createWebsite,
    createWebsitePageDefaultContent,
    getWebsiteWizardSetup,
    uploadOrFetchWebsiteDocument
} from "@/services/direct_booking/website";
import WebsiteWizardContactForm from "@/components/direct_booking/website/new/WebsiteWizardContactForm";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {
    TYPE_WEBSITE_LOGO,
    TYPE_WEBSITE_FAVICON,
    TYPE_WEBSITE_HERO_IMAGE,
    TYPE_WEBSITE_GALLERY
} from "@/shared/constants";
import _omit from 'lodash/omit'
import {notifySuccess, toast} from "@/shared/plugins/toastr";
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import AppWizardComplete from "@/components/app/Wizard/AppWizardComplete";
import AppButton from "@/components/app/AppButton/AppButton";
import routeNames from "@/router/routeNames";

const SUPPORTED_LANGUAGES = 37

export default {
    name: "WebsiteWizard",
    components: {
        AppButton,
        AppWizardComplete,
        WebsiteWizardContactForm,
        WebsiteWizardKeyVisualsForm,
        WebsiteWizardGeneralForm,
        MarketplaceUnitActivationRules,
        FormWizard, TabContent
    },
    mixins: [getErrorMessage],
    data() {
        return {
            formData: {
                general: {},
                contact: {},
                key_visuals: {},
                unit_activation_rules: {}
            },
            successfulStepFormData: {},
            setupData: null,
            loading: false,
            websiteCreated: false,
            website: null,
        }
    },
    computed: {
        supportedLanguages() {
            return (this.formData.general[SUPPORTED_LANGUAGES] && this.formData.general[SUPPORTED_LANGUAGES].filter(el => el !== "en")) || []
        },
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods: {
        validateStep(step) {
            if (_isEqual(this.formData[step], this.successfulStepFormData[step])) {
                return true
            }

            this.loading = true
            return createWebsite({...this.formData[step], step}).then(() => {
                this.successfulStepFormData[step] = _cloneDeep(this.formData[step])
                return true
            }, error => {
                this.showErrorMessages(error, this.$refs[step].$data.validationObject)
                return false
            }).finally(() => this.loading = false)
        },
        onComplete() {
            this.loading = true
            createWebsite({
                company: this.company.id,
                ...this.formData.general,
                ...this.formData.contact,
                unit_activation_rules: this.formData.unit_activation_rules,
                ..._omit(this.formData.key_visuals, ['hero_image', 'logo']),
            }).then(response => {
                this.website = response.data
                // upload images
                const {hero_image, logo} = this.formData.key_visuals
                const promiseList = []

                if (hero_image) {
                    const data = new FormData()
                    data.append('document', hero_image)
                    promiseList.push(uploadOrFetchWebsiteDocument(this.website.id, TYPE_WEBSITE_HERO_IMAGE, data))
                    promiseList.push(uploadOrFetchWebsiteDocument(this.website.id, TYPE_WEBSITE_GALLERY, data))
                }

                if (logo) {
                    const data = new FormData()
                    data.append('document', logo)
                    promiseList.push(uploadOrFetchWebsiteDocument(this.website.id, TYPE_WEBSITE_LOGO, data))
                    promiseList.push(uploadOrFetchWebsiteDocument(this.website.id, TYPE_WEBSITE_FAVICON, data))
                }

                // Image upload fail should not prevent redirect to website and success message
                return Promise.all(promiseList.map(p => p.catch(e => toast({
                    title: this.$t('NOTIFICATIONS.UPLOAD_ERROR.TITLE'),
                    message: this.$t('NOTIFICATIONS.UPLOAD_ERROR.MESSAGE'),
                    type: 'error',
                    timeout: 6000
                }))))
            }).then(() => {
                return createWebsitePageDefaultContent(this.website.id).catch(error => this.showErrorMessages(error))
            }).then(() => {
                notifySuccess()
                this.websiteCreated = true
                this.$emit('created')
            }).catch(error => {
                this.showErrorMessages(error)
            }).finally(() => this.loading = false)
        },
        updateFormData(data) {
            this.formData = {...this.formData, ...data}
        },
        goToUnitActivation() {
            this.$router.push({
                name: routeNames.RN_DIRECT_BOOKING_WEBSITE_UNIT_ACTIVATION,
                params: {id: this.website.id},
                query: {activate: "true"}
            })
        },
        goToPageList() {
            this.$router.push({name: routeNames.RN_DIRECT_BOOKING_WEBSITE_PAGES, params: {id: this.website.id}})
        }
    },
    created() {
        this.loading = true
        getWebsiteWizardSetup().then(response => {
            this.setupData = response.data
        }).finally(() => {
            this.loading = false
        })
    }
}
</script>

<style scoped>

.search_init_icon{
    height: auto;
    width: 12rem;
}

.wizard-complete{
    padding-top: 12%;
}

</style>
