import { render, staticRenderFns } from "./CompanyCalculationTransactionList.vue?vue&type=template&id=c35c486a&scoped=true&"
import script from "./CompanyCalculationTransactionList.vue?vue&type=script&lang=js&"
export * from "./CompanyCalculationTransactionList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c35c486a",
  null
  
)

export default component.exports