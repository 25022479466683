<template>
    <event-tabs :event-data="eventData"
                :event-template-type-id="EVENT_TEMPLATE_TYPE_CONFIGURATION"
                :template-form-label="$t('COMMUNICATION_RULE')"
                :loading="loading" @saved="getData">
    </event-tabs>
</template>

<script>
import {getGroupedEventTemplates} from "@/services/event";
import {
    CONTACT_CHANNEL_E_MAIL,
    EVENT_TEMPLATE_TYPE_CONFIGURATION,
    EVENT_TEMPLATE_TYPE_CONFIGURATION_TEMPLATE
} from "@/shared/constants";

export default {
    name: "Index",
    components: {
        "EventTabs": () => import("@/components/event/EventTabs")
    },
    data() {
        return {
            eventData: [],
            EVENT_TEMPLATE_TYPE_CONFIGURATION,
            loading: true
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        }
    },
    methods: {
        getData() {
            this.loading = true
            getGroupedEventTemplates({
                company: this.company.id,
                template_type: EVENT_TEMPLATE_TYPE_CONFIGURATION,
                contact_channel: CONTACT_CHANNEL_E_MAIL
            }).then(response => {
                this.eventData = response.data
            }).finally(() => {
                this.loading = false
            })
        }
    },
    created() {
        this.getData()
    }
}
</script>

<style scoped>

</style>
