import { render, staticRenderFns } from "./AppearanceEditColor.vue?vue&type=template&id=dce83844&scoped=true&"
import script from "./AppearanceEditColor.vue?vue&type=script&lang=js&"
export * from "./AppearanceEditColor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dce83844",
  null
  
)

export default component.exports