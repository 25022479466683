<template>
    <b-card class="main-section-card">
        <div slot="header">
            <h2>{{ $t("COUNTRIES") }}</h2>
            <AppButton v-if="showButton"
                       @click="open"
                       button_type="new"
                       variant="primary"
                       class="pull-right">{{ $t('NEW_COUNTRY') }}
            </AppButton>
        </div>
        <tag-list :destination_type="TAG_DESTINATION_TYPE_COUNTRY" :refresh="refresh"
                  :tag_type="TAG_TYPE_DESTINATION" @openNew="tag_sidebar=true" @showButton="showButton=true"></tag-list>
        <app-aside v-model="tag_sidebar" :widths="['col-lg-4','col-sm-12', '40%']">
            <template slot="header">{{ $t('NEW_COUNTRY') }}</template>
            <add-tag :destination_type="TAG_DESTINATION_TYPE_COUNTRY" :tag_type="TAG_TYPE_DESTINATION" @saved="saved"
                     :label="$t('COUNTRY')"></add-tag>
        </app-aside>
    </b-card>
</template>

<script>
    import TagList from "@/components/tag/list/TagList";
    import {TAG_DESTINATION_TYPE_COUNTRY, TAG_TYPE_DESTINATION} from "@/shared/constants";
    import AppButton from "@/components/app/AppButton/AppButton";
    import AddTag from "@/components/tag/sidebar/AddTag";
    import AppAside from "@/components/app/form/AppAside";

    export default {
        name: "Index",
        components: {AddTag, AppButton, TagList, AppAside},
        data() {
            return {
                TAG_DESTINATION_TYPE_COUNTRY, TAG_TYPE_DESTINATION,
                tag_sidebar: false,
                refresh: false,
                parent_tag: null,
                showButton: false,
            }
        },
        methods: {
            open() {
                this.tag_sidebar = true;
            },
            saved() {
                this.tag_sidebar = false;
                this.refresh = !this.refresh
            },
        }
    }
</script>

<style scoped>

</style>
