<template>
    <b-form @submit.prevent>
        <form-wizard :startIndex="currentIndex" transition="fade-in" color=var(--color-primary) errorColor=var(--color-warning)
                     name="unitWizard"
                     title="" subtitle=""
                     @on-validate="handleNextStep"
                     @on-complete="save">
            <tab-content name="basic" :title="$t('BASIC')"
                         :before-change="() => validate('basic')">
                <b-row>
                    <b-col md="4" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.basic.name">
                            <template v-slot:input="data">
                                <label>{{$t("NAME")}}</label>
                                <b-input :state="data.state" :value="basic.name" @input="updateName"></b-input>
                            </template>
                        </app-input-control>
                    </b-col>

                    <b-col md="4" lg="3" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.basic.type">
                            <template v-slot:input="data">
                                <label>{{$t('TYPE')}}</label>
                                <app-select mode="search" :search="getUnitTypes" :search-on-create="true" text-field="label" :state="data.state"
                                            v-model="basic.type"></app-select>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col md="4" lg="3" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.basic.owner">
                            <template v-slot:input="data">
                                <label>{{$t('OWNER')}}</label>
                                <app-select :disabled="disableOwner" :state="data.state" mode="search" :search="getPropertyOwnerList" :search-on-create="true"
                                            v-model="basic.owner"></app-select>
                            </template>

                        </app-input-control>

                    </b-col>
                </b-row>
            </tab-content>
            <tab-content name="layout" :title="$t('LAYOUT')"
                         :before-change="() => validate('layout')">

                <b-row>
                    <b-col md="3" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.layout.size">
                            <template v-slot:input="data">
                                <label>{{$t('SIZE')}}</label>
                                <b-input-group>
                                    <b-form-input  :state="data.state" v-model="layout.size"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>m<sup>2</sup></b-input-group-text>
                                    </b-input-group-append>
                                </b-input-group>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col md="3" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.layout.number_of_bedrooms">
                            <template v-slot:input="data">
                                <label>{{$t("NUMBER_OF_BEDROOMS")}}</label>
                                <b-input :state="data.state" v-model="layout.number_of_bedrooms"></b-input>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col md="3" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.layout.floor_level">
                            <template v-slot:input="data">
                                <label>{{$t("FLOOR_LEVEL")}}</label>
                                <b-input :state="data.state" v-model="layout.floor_level"></b-input>
                            </template>
                        </app-input-control>

                    </b-col>
                </b-row>
                <b-row>

                    <b-col md="3" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.layout.max_adults">
                            <template v-slot:input="data">
                                <label>{{$t("MAX_ADULTS")}}</label>
                                <b-input :state="data.state"  v-model.number="layout.max_adults" min="1"></b-input>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col md="3" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.layout.max_children">
                            <template v-slot:input="data">
                                <label>{{$t("MAX_CHILDREN")}}</label>
                                <b-input :state="data.state"  v-model.number="layout.max_children" min="0"></b-input>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col md="3" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.layout.max_babies">
                            <template v-slot:input="data">
                                <label>{{$t("MAX_BABIES")}}</label>
                                <b-input :state="data.state"  v-model.number="layout.max_babies" min="0"></b-input>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col md="3" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.layout.max_guests">
                            <template v-slot:input="data">
                                <label>{{$t("MAX_GUESTS")}}</label>
                                <b-input :state="data.state" :disabled="true"  v-model.number="layout.max_guests"></b-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
            </tab-content>
            <tab-content name="location" :title="$t('LOCATION')"
                         :before-change="() => validate('location')">


                <b-row>
                    <b-col>
                        <form-header>{{$t('LOCATION')}}</form-header>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="4" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.location.address">
                            <template v-slot:input="data">
                                <label>{{$t('ADDRESS')}}</label>
                                <b-form-input :state="data.state" type="text" v-model="location.address"></b-form-input>
                            </template>
                        </app-input-control>


                    </b-col>
                    <b-col md="2" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.location.address_no">
                            <template v-slot:input="data">
                                <label>{{$t('STREET_NUMBER')}}</label>
                                <b-form-input :state="data.state" type="text" v-model="location.address_no"></b-form-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <form-header>{{$t("GEOLOCATION")}}</form-header>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="4" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.location.latitude">
                            <template v-slot:input="data">
                                <label>{{$t('LATITUDE')}}</label>
                                <b-form-input :state="data.state" lazy v-model="location.latitude"></b-form-input>
                            </template>
                        </app-input-control>

                    </b-col>
                    <b-col md="4" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.location.longitude">
                            <template v-slot:input="data">
                                <label>{{$t('LONGITUDE')}}</label>
                                <b-form-input :state="data.state" lazy v-model="location.longitude"></b-form-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <g-map :lat="location.latitude" :lng="location.longitude"
                               @gmapLocationChange="updateFormLocation"></g-map>
                    </b-col>
                </b-row>
            </tab-content>
            <tab-content name="group_units" :title="$t('GROUP_UNITS')"
                         :before-change="() => validate('group_units')">
                <b-row>
                    <b-col md="3" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.group_units.group_name">
                            <template v-slot:input="data">
                                <label>{{$t('GROUP_NAME')}}</label>
                                <b-form-input :state="data.state" type="text" v-model="group_units.group_name"></b-form-input>
                            </template>
                        </app-input-control>
                    </b-col>
                    <b-col md="3" class="mb-4">
                        <app-input-control :error-object="validationErrorObject.group_units.number_of_units">
                            <template v-slot:input="data">
                                <label>{{$t('NUMBER_OF_UNITS')}}</label>
                                <b-form-input
                                    type="text"
                                    :state="data.state"
                                    v-model="group_units.number_of_units"></b-form-input>
                            </template>
                        </app-input-control>
                    </b-col>
                </b-row>
            </tab-content>
            <button class="btn btn-outline-primary" slot="prev">{{$t('PREVIOUS')}}</button>
            <button class="btn btn-primary" slot="next">{{$t('NEXT')}}</button>
            <button class="btn btn-primary" slot="finish">{{$t('FINISH')}}</button>
        </form-wizard>
    </b-form>
</template>

<script>
    import {FormWizard, TabContent} from 'vue-form-wizard'
    import GMap from '@/components/app/map/GoogleMap'
    import {toast} from '@/shared/plugins/toastr'
    import ValidationDescription from '@/components/app/form/AppValidationDescription'
    import routeNames from '@/router/routeNames'
    import {getErrorMessage} from '@/mixins/error/getErrorMessage'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import {getUnitTypeList, setContingentUnits, createSoloUnitType, validationCreateUnit} from '@/services/unit/index'
    import {getPropertyOwners} from '@/services/owner/index'
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import FormHeader from "@/components/app/form/FormHeader";
    import {

        PROPERTY_DATA_NAME_VALIDATION_FAILED,
        PROPERTY_DATA_UNIT_TYPE_VALIDATION_FAILED,
        PROPERTY_DATA_NUMBER_BEDROOMS_VALIDATION_FAILED,
        UNIT_DATA_OWNER_IS_MANDATORY_VALIDATION_FAILED,
        PROPERTY_DATA_SIZE_VALIDATION_FAILED,
        PROPERTY_DATA_FLOOR_VALIDATION_FAILED,
        PROPERTY_DATA_MAX_ADULTS_VALIDATION_FAILED,
        PROPERTY_DATA_MAX_BABIES_VALIDATION_FAILED,
        PROPERTY_DATA_MAX_CHILDREN_VALIDATION_FAILED,
        PROPERTY_DATA_MAX_GUESTS_VALIDATION_FAILED,
        UNIT_DATA_LATITUDE_VALIDATION_FAILED,
        UNIT_DATA_LONGITUDE_VALIDATION_FAILED,
        UNIT_DATA_ADDRESS_NO_VALIDATION_FAILED,
        UNIT_DATA_NUMBER_OF_UNIT_VALIDATION_FAILED,
        UNIT_DATA_GROUP_NAME_VALIDATION_FAILED,
        UNIT_DATA_ADDRESS_VALIDATION_FAILED,
        UNIT_DATA_INVALID_LATITUDE_VALIDATION_FAILED,
        UNIT_DATA_INVALID_LONGITUDE_VALIDATION_FAILED,
        PROPERTY_DATA_MAX_ADULTS_MANDATORY_FIELD,
        PROPERTY_DATA_MAX_GUESTS_MANDATORY_FIELD,
        PROPERTY_DATA_SIZE_MANDATORY_FIELD,
        PROPERTY_DATA_FLOOR_MANDATORY_FIELD,
        PROPERTY_DATA_NUMBER_BEDROOMS_MANDATORY_FIELD,
        UNIT_DATA_NUMBER_OF_UNIT_MANDATORY_FIELD,
        PROPERTY_DATA_MAX_CHILDREN_MANDATORY_FIELD,
        PROPERTY_DATA_MAX_BABIES_MANDATORY_FIELD

    } from "@/shared/error_codes";
    import AppInputControl from "@/components/app/AppInputControl";
    import {EventBus, V_ERROR} from "@/shared/EventBus";
    import {LS_PRESETS} from "@/shared/constants";
    export default {
        name: "unit-wizard",
        mixins: [getErrorMessage],
        props: {
            property: {
                type: Object,
                default: null
            },
            propertyRoute: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                disableOwner:false,
                unitTypeList: [],
                ownerList: [],
                currentIndex: 0,
                basic: {
                    name: null,
                    type: null,
                    owner: null
                },
                layout: {
                    size: null,
                    number_of_bedrooms: null,
                    floor_level: null,
                    max_guests: null,
                    max_adults: 1,
                    max_children: 0,
                    max_babies: 0
                },
                location: {
                    latitude: null,
                    longitude: null,
                    address: null,
                    address_no: null
                },
                group_units: {
                    group_name: null,
                    number_of_units: 1
                },
                validationErrorObject:{
                    basic:{
                        name:{
                            input:[PROPERTY_DATA_NAME_VALIDATION_FAILED]
                        },
                        type: {
                            input:[PROPERTY_DATA_UNIT_TYPE_VALIDATION_FAILED]
                        },
                        owner: {
                            input:[UNIT_DATA_OWNER_IS_MANDATORY_VALIDATION_FAILED]
                        }

                    },
                    layout:{
                        number_of_bedrooms:{
                            input:[PROPERTY_DATA_NUMBER_BEDROOMS_VALIDATION_FAILED,PROPERTY_DATA_NUMBER_BEDROOMS_MANDATORY_FIELD]
                        },
                        floor_level: {
                            input:[PROPERTY_DATA_FLOOR_VALIDATION_FAILED,PROPERTY_DATA_FLOOR_MANDATORY_FIELD]
                        },
                        size: {
                            input:[PROPERTY_DATA_SIZE_VALIDATION_FAILED,PROPERTY_DATA_SIZE_MANDATORY_FIELD]
                        },
                        max_adults:{
                            input:[PROPERTY_DATA_MAX_ADULTS_VALIDATION_FAILED, PROPERTY_DATA_MAX_ADULTS_MANDATORY_FIELD]
                        },
                        max_children:{
                            input:[PROPERTY_DATA_MAX_CHILDREN_VALIDATION_FAILED,PROPERTY_DATA_MAX_CHILDREN_MANDATORY_FIELD]
                        },
                        max_babies:{
                            input:[PROPERTY_DATA_MAX_BABIES_VALIDATION_FAILED, PROPERTY_DATA_MAX_BABIES_MANDATORY_FIELD]
                        },
                        max_guests:{
                            input:[PROPERTY_DATA_MAX_GUESTS_VALIDATION_FAILED,PROPERTY_DATA_MAX_GUESTS_MANDATORY_FIELD]
                        },
                    },
                    location:{
                        address: {
                            input:[UNIT_DATA_ADDRESS_VALIDATION_FAILED]
                        },
                        address_no: {
                            input:[UNIT_DATA_ADDRESS_NO_VALIDATION_FAILED]
                        },
                        latitude: {
                            input:[UNIT_DATA_LATITUDE_VALIDATION_FAILED, UNIT_DATA_INVALID_LATITUDE_VALIDATION_FAILED]
                        },
                        longitude:{
                            input:[UNIT_DATA_LONGITUDE_VALIDATION_FAILED, UNIT_DATA_INVALID_LONGITUDE_VALIDATION_FAILED]
                        },
                    },
                    group_units:{
                        group_name:{
                            input:[UNIT_DATA_GROUP_NAME_VALIDATION_FAILED]
                        },
                        number_of_units:{
                            input:[UNIT_DATA_NUMBER_OF_UNIT_VALIDATION_FAILED,UNIT_DATA_NUMBER_OF_UNIT_MANDATORY_FIELD]
                        }

                    }

                }
            }
        },
        components: {
            AppInputControl,
            AppSelect,
            FormHeader,
            FormWizard,
            TabContent,
            GMap,
            ValidationDescription
        },
        methods: {
            updateName(value) {

                this.basic.name = value

                if (value.includes(this.property.name)) {
                    value = value.replace(this.property.name, '').trim()
                    value = value.replace(/-/g, '').trim();
                }
                this.group_units.group_name = value

            },
            updateFormLocation(data) {
                this.location.latitude = data.latitude
                this.location.longitude = data.longitude

                if (
                    (this.location.address === '' || this.location.address === null) &&
                    (this.location.address_no === '' || this.location.address_no === null)

                ) {
                    this.location.address = data.street
                    this.location.address_no = data.street_number
                }
            },

            handleNextStep(isValid, tabIndex) {

                if (!isValid) {
                    return
                }
                EventBus.$emit(V_ERROR, [])
                this.currentIndex = tabIndex++
                localStorage.setItem('unit_wizard_temp', JSON.stringify(this.$data))
            },
            getUnitTypes() {
                return getUnitTypeList(this.propertyRoute ? this.$route.params.id : this.property.id)
            },
            sort(array) {
                // Set slice() to avoid to generate an infinite loop!
                return array.slice().sort(function (a, b) {

                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
            },
            getPropertyOwnerList() {
                let promise = getPropertyOwners(this.propertyRoute ? this.$route.params.id : this.property.id)

                promise.then(response => {
                    if (response.data.length === 1) {
                        this.basic.owner = response.data[0]
                        this.disableOwner = true
                    }
                })

                return promise
            },
            prepareRequest() {
                let data = {
                    name: this.basic.name,
                    max_guests: this.layout.max_guests,
                    max_adults: this.layout.max_adults,
                    max_babies: this.layout.max_babies,
                    max_children: this.layout.max_children,
                    floor: this.layout.floor_level,
                    size: this.layout.size,
                    number_bedrooms: this.layout.number_of_bedrooms,
                    property: {
                        id: this.propertyRoute ? this.$route.params.id : this.property.id,
                    },
                    contigent: {
                        max_units: this.group_units.number_of_units,
                        unit_group_name: this.group_units.group_name
                    },
                    location: {
                        address: this.location.address,
                        address_no: this.location.address_no,
                        latitude: this.location.latitude,
                        longitude: this.location.longitude
                    },
                    owner: this.basic.owner,
                    unit_type: this.basic.type,
                    number_of_unit: this.group_units.number_of_units,
                    group_name:this.group_units.group_name

                }
                return data
            },
            save() {

                let request = this.prepareRequest()
                this.$store.dispatch('actionCreateUnit', request).then(response => {
                        setContingentUnits(response.data.id, {number_of_units: this.group_units.number_of_units - 1}).then(() => {
                            this.saveSoloType(response.data.id)
                            localStorage.removeItem(LS_PRESETS)
                            toast({
                                'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                                'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                                'type': 'success',
                                'timeout': 3000
                            })
                            this.$router.push({
                                name: routeNames.RM_PROPERTY_UNIT_BASIC,
                                params: {id: response.data.property.id, unit_id: response.data.id}
                            })
                        })
                    }, error => {
                        let errorObject = {...this.validationErrorObject.basic, ...this.validationErrorObject.layout, ...this.validationErrorObject.location,  ...this.validationErrorObject.group_units}
                        this.showErrorMessages(error, errorObject)
                    }
                )
            },
            saveContingents(unitId) {
                setContingentUnits(unitId, {number_of_units: this.group_units.number_of_units}).then(() => {
                    return true
                }, () => {
                    return false
                })
            },
            saveSoloType(unitId) {
                createSoloUnitType(unitId, {name: this.group_units.group_name})
            },
            validate(step){
                return validationCreateUnit(this.prepareRequest()).then(() => {
                    return true
                }, error => {
                    if (this.validationErrorObject.hasOwnProperty(step)) {
                        return this.serverStepValidation(this.validationErrorObject[step], error.response.data.error_list)
                    }
                })
            },
            serverStepValidation(stepObject, errorList) {
                if (Array.isArray(errorList) && errorList.length) {
                    const serverErrorCodes = errorList.map(list => Number(list.code))
                    for (const [filedName, errorObject] of Object.entries(stepObject)) {
                        for (const [type, typeErrors] of Object.entries(errorObject)) {
                            for (let error of typeErrors) {
                                if (serverErrorCodes.includes(error.code)) {
                                    return false
                                }
                            }
                        }
                    }
                }
                return true
            },
        },
        created() {
            this.getPropertyOwnerList()
        },
        watch: {
            property: {
                handler(value) {
                    if (value === null || !value.hasOwnProperty('location')) {
                        return
                    }
                    this.location.latitude = value.location.latitude
                    this.location.longitude = value.location.longitude
                    this.location.address = value.location.address
                    this.location.address_no = value.location.address_no


                },
                deep: true,
                immediate: true
            },
            'layout.max_adults': {
                handler(value) {
                    this.layout.max_guests = Number(value) + Number(this.layout.max_children)
                },
                immediate: true
            },
            'layout.max_children': {
                handler(value) {
                    this.layout.max_guests = value + this.layout.max_adults
                }
            }
        }
    }
</script>

<style scoped>

</style>
