<template>
    <b-form v-if="hasPermissionView" @submit.prevent="savePropertyDescriptions">
        <b-tabs content-class="tab_content" pills vertical>
            <b-tab :key="index" v-for="(description, index)  in list">
                <template slot="title">
                    {{description.label}}
                </template>
                <description-text-area :description="description"
                                       :disabled="!checkPermission(permissions_e[description.name])"
                                       :id="description.name"
                                       :name="description.name"
                                       @update="updateValue(index, $event)">
                </description-text-area>
            </b-tab>
        </b-tabs>
        <b-row>
            <b-col class="mt-4" v-if="checkPermission(c_permission_e)">
                <description-submit :loading="saving">
                </description-submit>
            </b-col>
        </b-row>
    </b-form>
</template>
<script>
    import DescriptionSubmit from '@/components/app/AppButton/AppButtonSubmit'
    import {notifySuccess} from "@/shared/plugins/toastr";
    import {getErrorMessage} from "@/mixins/error/getErrorMessage";
    import {getPropertyDescriptionList, setPropertyDescription} from '@/services/property/index'
    import {
        C_PROPERTY_DESCRIPTIONS_V,
        C_PROPERTY_DESCRIPTIONS_E,
        C_PROPERTY_DESCRIPTIONS_PROPERTY_E,
        C_PROPERTY_DESCRIPTIONS_AREA_E,
        C_PROPERTY_DESCRIPTIONS_ACTIVITIES_E,
        C_PROPERTY_DESCRIPTIONS_SUMMARY_E,
        C_PROPERTY_DESCRIPTIONS_ACCESS_E,
        C_PROPERTY_DESCRIPTIONS_GETTING_AROUND_E,
        C_PROPERTY_DESCRIPTIONS_NOTES_E,
    } from "@/shared/component_permission";
    import DescriptionTextArea from "@/components/description/DescriptionTextArea";

    const permissions_e = {
        'property_desc': C_PROPERTY_DESCRIPTIONS_PROPERTY_E,
        'area': C_PROPERTY_DESCRIPTIONS_AREA_E,
        'nearby_activities': C_PROPERTY_DESCRIPTIONS_ACTIVITIES_E,
        'summary': C_PROPERTY_DESCRIPTIONS_SUMMARY_E,
        'access': C_PROPERTY_DESCRIPTIONS_ACCESS_E,
        'getting_around': C_PROPERTY_DESCRIPTIONS_GETTING_AROUND_E,
        'other': C_PROPERTY_DESCRIPTIONS_NOTES_E,
    }

    export default {
        name: "Index",
        data() {
            return {
                list: [],
                c_permission_v: C_PROPERTY_DESCRIPTIONS_V,
                c_permission_e: C_PROPERTY_DESCRIPTIONS_E,
                permissions_e,
                saving: false,
            }
        },
        mixins: [getErrorMessage],
        components: {
            DescriptionTextArea,
            DescriptionSubmit,
        },
        methods: {
            updateValue(index, value) {
                this.$set(this.list, index, value)
            },
            savePropertyDescriptions() {
                this.saving = true
                let request = {}
                this.list.map((item) => {
                    request[item.name] = item.value
                    /*let translations = item.hasOwnProperty('translations') && item.translations !== null ? item.translations : null
                    if (translations !== null) {
                        let requestFieldName = 'field_' + item.name + '_translations'
                        request[requestFieldName] = {}
                        for (const [lang, value] of Object.entries(translations)) {
                            request[requestFieldName][lang] = value
                        }
                    }*/
                })

                setPropertyDescription(this.$route.params.id, request).then((response) => {
                    this.list = response.data
                    notifySuccess()
                }, error => {
                    this.showErrorMessages(error)
                }).finally(() => {
                    this.saving = false
                })

            }
        },
        computed: {
            hasPermissionView() {
                return this.$store.getters['user/getPermission'](this.c_permission_v)
            }
        },
        watch: {
            '$route.params.id': {
                handler(property_id) {
                    getPropertyDescriptionList(property_id).then(response => {
                            this.list = response.data
                        }, () => {
                            this.list = []
                        })
                },
                immediate: true
            }
        }
    }

</script>
<style scoped>

</style>
