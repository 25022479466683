<template>
    <form @submit.prevent="search" class="filter" autocomplete="off">
        <b-row>
            <b-col md="4" lg="2" class="mb-3">
                <label>{{$t("GUEST")}}</label>
                <b-input v-model="filter.name"></b-input>
            </b-col>
            <b-col md="4" lg="2" class="mb-3">
                <label>{{$t("DRIVER")}}</label>
                <app-select mode="search" text-field="name" v-model="driver" :search="getDrivers"></app-select>
            </b-col>
            <b-col md="4" lg="2" class="mb-3">
                <label>{{$t("STATUS")}}</label>
                <app-select mode="multiselect" :options="statusList" text-field="name"
                            v-model="filter.status"></app-select>
            </b-col>

            <b-col md="2">
                <label>{{$t("_DATE")}}</label>
                <app-select :options="dateOptions" v-model="filter.date.type"></app-select>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{$t("FROM")}}</label>
                <app-date-picker :disabled="!filter.date.type" v-model="filter.date.start" :max-date="filter.date.end"></app-date-picker>
            </b-col>

            <b-col md="2" class="mb-3">
                <label>{{$t("TO")}}</label>
                <app-date-picker :disabled="!filter.date.type" v-model="filter.date.end" :min-date="filter.date.start" :from-date="filter.date.start"></app-date-picker>
            </b-col>
            <b-col md="3" class="mb-3">
                <app-submit class="filter-submit-wrapper" :inline="true" :loading="loading" variant="primary"
                            button_type="search">{{ $t("SEARCH") }}
                </app-submit>
                <app-button-reset @click="resetForm" :disabled="loading" :inline="true"  class="pl-2 ml-2"  ></app-button-reset>
            </b-col>
        </b-row>

    </form>
</template>

<script>
    import {EventBus} from "@/shared/EventBus";
    import AppSubmit from "@/components/app/AppButton/AppButtonSubmit";
    import AppDateRangePicker from "@/components/app/datetime/AppDateRangePicker";
    import AppSelect from "@/components/app/AppSelect/AppSelect";
    import {getDriverList} from "@/services/transfer";
    import AppDatePicker from "@/components/app/datetime/AppDatePicker";
    import AppButtonReset from "@/components/app/AppButton/AppButtonReset";

    export default {
        name: "TransferListFilter",
        components: {AppButtonReset, AppDatePicker, AppSelect, AppDateRangePicker, AppSubmit},
        data() {
            return {
                filter: {
                    date: {
                        start: null,
                        end: null,
                        type: null
                    },
                    name: null,
                    status: []
                },
                driver: {
                    id: null,
                    name: null
                },
                loading: false
            }
        },
        computed: {
            statusList() {
                return [
                    {
                        id: "CONFIRMED",
                        name: this.$t("STATUS_CONFIRMED")
                    },
                    {
                        id: "CANCELED",
                        name: this.$t("STATUS_CANCELED")
                    }
                ]
            },
            dateOptions() {
                return [
                    {id: 'created', name: this.$t('BOOKED_AT')},
                    {id: 'date', name: this.$t('_DATE')},
                ]
            }
        },
        methods: {
            search() {
                EventBus.$emit('transferListFilter', {
                    ...this.filter, ...{
                        driver: this.driver.id
                    }
                })
            },
            getDrivers(name) {
                return getDriverList({name: name}).then(response => {
                    return {data: response.data.items}
                })
            },
            resetForm() {
                this.filter.date = {
                    start: null,
                    end: null,
                    type: null
                }
                this.driver = {
                    id: null,
                    name: null
                }
                this.filter.name = null
                this.filter.status = []
            }
        }
    }
</script>

<style scoped>

</style>
