<template>
    <div>
        <company-calculation-list :company="company.id"
                                  :refreshListRequested="reloadCalculationListRequests"
                                  @refresh="() => { reloadCalculationList() }"
        ></company-calculation-list>

        <app-aside v-model="createStatementPanelState"
                   :widths="['col-lg-3 col-sm-5', '40%']">
            <template slot="header">
                {{ $t("NEW_STATEMENT") }}
            </template>
            <company-calculation-generate-form :company="company.id"
                                               @created="() => { this.createStatementPanelState = false; reloadCalculationList() }"
            ></company-calculation-generate-form>
        </app-aside>

        <app-aside v-model="invoiceCreationSetupPanelState">
            <template slot="header">
                {{ $t('INVOICE_CREATION_SETUP') }}
            </template>
            <invoice-creation-setup-form :company="company.id"
                                         @close="invoiceCreationSetupPanelState = false"
            ></invoice-creation-setup-form>
        </app-aside>

        <app-aside v-model="paymentTypePanelState">
            <template slot="header">
                {{ $t('PAYMENT_TYPE') }}
            </template>
            <payment-type-form :company="company.id"
                               :refreshListRequested="reloadCreditCardListRequests"
                               @close="paymentTypePanelState = false"
                               @addCreditCard="handleAddCreditCard"
                               @editCreditCard="handleEditCreditCard"
                               @showCreditCard="handleShowCreditCard"
            ></payment-type-form>
        </app-aside>

        <app-aside v-model="creditCardAsidePanelState">
            <template slot="header">
                {{ ccPanelHeader }}
            </template>
            <credit-card-form :object-type="OBJECT_TYPE_COMPANY"
                              :object-id="company.id"
                              :chd-id="editCreditCardId"
                              :chd-data="editCreditCardData"
                              :edit="creditCardEdit"
                              :maximum-requirements="false"
                              @created="reloadCreditCardList()"
                              @updated="reloadCreditCardList()"
                              @close="creditCardAsidePanelState = false"
            ></credit-card-form>
        </app-aside>
    </div>
</template>

<script>
    import CompanyCalculationGenerateForm from "@/components/finance/company_calculation/form/CompanyCalculationGenerateForm";
    import CompanyCalculationList from "@/components/finance/company_calculation/list/CompanyCalculationList";
    import InvoiceCreationSetupForm from "@/components/company/subscription/forms/InvoiceCreationSetupForm";
    import PaymentTypeForm from "@/components/company/subscription/forms/PaymentTypeForm";
    import CreditCardForm from "@/components/finance/credit_card/CreditCardForm";
    import AppAside from "@/components/app/form/AppAside";
    import {
        CALCULATION_TYPE_MONTHLY_SYSTEM_USAGE,
        OBJECT_TYPE_COMPANY,
    } from "@/shared/constants";
    import {
        EventBus,
        GE_SUBSCRIPTION_INVOICE_CREATION_SETUP,
        GE_SUBSCRIPTION_PAYMENT_TYPE,
        GE_SUBSCRIPTION_CALCULATION_CREATE,
    } from "@/shared/EventBus";

    export default {
        name: "Index",
        components: {
            CompanyCalculationList,
            CompanyCalculationGenerateForm,
            InvoiceCreationSetupForm,
            PaymentTypeForm,
            CreditCardForm,
            AppAside,
        },
        props: {
            company: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                createStatementPanelState: false,
                invoiceCreationSetupPanelState: false,
                paymentTypePanelState: false,
                creditCardAsidePanelState: false,
                creditCardEdit: false,
                editCreditCardData: null,
                editCreditCardId: null,
                reloadCalculationListRequests: 1,
                reloadCreditCardListRequests: 1,
                OBJECT_TYPE_COMPANY,
            }
        },
        computed: {
            ccPanelHeader() {
                return this.creditCardEdit && this.editCreditCardId ? this.$t('EDIT_CC_DATA')
                    : this.creditCardEdit ? this.$t('CREATE_CC_DATA')
                        : this.$t('CC_DATA')
            },
        },
        methods: {
            handleAddCreditCard() {
                this.editCreditCard = null
                this.editCreditCardId = null
                this.creditCardEdit = true
                this.creditCardAsidePanelState = true
            },
            handleShowCreditCard(creditCard) {
                this.editCreditCard = creditCard
                this.editCreditCardId = creditCard.hasOwnProperty('id') ? creditCard.id : null
                this.creditCardEdit = false
                this.creditCardAsidePanelState = true
            },
            handleEditCreditCard(creditCard) {
                this.editCreditCard = creditCard
                this.editCreditCardId = creditCard.hasOwnProperty('id') ? creditCard.id : null
                this.creditCardEdit = true
                this.creditCardAsidePanelState = true
            },
            reloadCreditCardList() {
                this.editCreditCard = null
                this.editCreditCardId = null
                this.creditCardEdit = false
                this.creditCardAsidePanelState = false
                this.reloadCreditCardListRequests++
            },
            reloadCalculationList() {
                this.reloadCalculationListRequests++
            },
        },
        mounted() {
            EventBus.$on(GE_SUBSCRIPTION_PAYMENT_TYPE, () => {
                this.paymentTypePanelState = true
            })
            EventBus.$on(GE_SUBSCRIPTION_INVOICE_CREATION_SETUP, () => {
                this.invoiceCreationSetupPanelState = true
            })
            EventBus.$on(GE_SUBSCRIPTION_CALCULATION_CREATE, () => {
                this.createStatementPanelState = true
            })
        },
    }
</script>

<style scoped>

</style>
